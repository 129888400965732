import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BookingService } from 'src/app/booking/booking/core/booking.service';

import { BookingViewSettingsService } from './booking-view-settings.service';

@Component({
  selector: 'tmt-booking-view-settings',
  templateUrl: './booking-view-settings.component.html',
})
export class BookingViewSettingsComponent implements OnInit {
  public conditionsCount: number;
  public conditionCountTitle: string;
  public form: UntypedFormGroup = this.fb.group({
    show: null,
    resourceRequestShown: false,
  });

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    public bookingService: BookingService,
    private fb: UntypedFormBuilder,
    private bookingViewSettingsService: BookingViewSettingsService,
  ) {}

  public ngOnInit(): void {
    this.form.patchValue(this.bookingViewSettingsService.settings, {
      emitEvent: false,
    });
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.bookingViewSettingsService.propagateSettingsChange(
          this.form.value,
        );
      });
  }
}
