import { Injectable, OnDestroy } from '@angular/core';

import _ from 'lodash';
import { DurationLike, Interval } from 'luxon';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Guid } from 'src/app/shared/helpers/guid';

import { DataService } from 'src/app/core/data.service';
import { LogService } from 'src/app/core/log.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { FteScheduleService } from 'src/app/core/fte-schedule.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import {
  BookingEntry,
  BookingResource,
} from 'src/app/shared/models/entities/resources/booking-entry.model';
import { BookingDetailEntry } from 'src/app/shared/models/entities/resources/booking-detail-entry.model';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { DateHours } from 'src/app/shared/models/entities/date-hours.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  ScheduleNavigationContext,
  ScheduleNavigationService,
  Slot,
  SlotGroup,
} from 'src/app/shared-features/schedule-navigation';

import { ResourceRequestService } from 'src/app/resource-requests/shared/resource-request/resource-request.service';
import { ResourceViewGroupLineEntry } from 'src/app/projects/card/project-resources/models/project-resources-view.model';
import {
  BookingEntryHours,
  EventItem,
  ResourceRequestDateEntries,
  ResourceRequestEvents,
  ResourceRequestMode,
  TotalsCalcMode,
} from 'src/app/resource-requests/shared/resource-request/resource-request.interface';
import { BookingMode } from 'src/app/shared/models/enums/booking-mode.enum';
import { AppService } from 'src/app/core/app.service';
import { ResourceRequestSchedule } from 'src/app/resource-requests/shared/calendar/models/resource-request-schedule.model';

@Injectable()
export class ResourceRequestCalendarService implements OnDestroy {
  public interval: Interval;
  public planningScale: PlanningScale;
  public valueMode: ValueMode;
  public slots: Slot[];
  public slotGroups: SlotGroup[];
  public schedule: ResourceRequestSchedule[];
  public fteSchedule: DateHours[];
  public mode: ResourceRequestMode;
  /**
   * Object with DateHours[] by key or resourceId
   * 'request' is key for requested dates.
   * 'result' is key for result dates
   * */
  public entries: ResourceRequestDateEntries = {
    request: [],
    result: [],
  };
  public requestDateHours: DateHours[];
  public resourceIds: Set<string> = new Set();
  public bookingEntries: BookingEntry[] = [];
  public totalsCalcMode: TotalsCalcMode = null;
  public bookingMode: BookingMode;

  private resourcePlanEntries: DateHours[];
  private entriesQueueId = Guid.generate();
  private entriesToSave: BookingEntryHours[] = [];
  private readonly widthByScale: Record<PlanningScale, number> = {
    [PlanningScale.Day]: 45,
    [PlanningScale.Week]: 55,
    [PlanningScale.Month]: 65,
    [PlanningScale.Quarter]: null,
    [PlanningScale.Year]: null,
  };
  private readonly shiftByScale: Record<PlanningScale, DurationLike> = {
    [PlanningScale.Day]: { weeks: 2 },
    [PlanningScale.Week]: { weeks: 10 },
    [PlanningScale.Month]: { month: 5 },
    [PlanningScale.Quarter]: null,
    [PlanningScale.Year]: null,
  };
  private readonly destroyed$ = new Subject<void>();

  public get leftTableWidth(): number {
    return 300;
  }

  public get rightTableWidth(): number | null {
    if (!this.slots) {
      return null;
    }

    return this.widthByScale[this.planningScale] * this.slots.length + 1;
  }

  constructor(
    private resourceRequestService: ResourceRequestService,
    private scheduleNavigationService: ScheduleNavigationService,
    private fteScheduleService: FteScheduleService,
    private blockUIService: BlockUIService,
    private logService: LogService,
    private freezeTableService: FreezeTableService,
    private dataService: DataService,
    private savingQueueService: SavingQueueService,
    appService: AppService,
  ) {
    this.init();
    this.initSubscribers();
    this.bookingMode = appService.session.configuration.bookingMode;
  }

  public ngOnDestroy(): void {
    this.savingQueueService.save();
    this.destroyed$.next();
  }

  public listenEvents(): Observable<EventItem> {
    return this.resourceRequestService.event$;
  }

  /**
   * Checks for deviations of the request from the current estimate
   *
   * @return If found deviation, return `true`, otherwise `false`
   */
  public checkIfHasDeviation(): boolean {
    if (
      !this.resourcePlanEntries ||
      (!this.resourceRequestService.request?.teamMemberId &&
        !this.resourceRequestService.requestTemplate?.teamMemberId)
    ) {
      return false;
    }

    const difference = _.differenceWith(
      this.requestDateHours
        .filter((el) => el.hours)
        .map((el) => ({ date: el.date, hours: +el.hours.toFixed(2) })),
      this.resourcePlanEntries.map((el) => ({
        date: el.date,
        hours: +el.hours.toFixed(2),
      })),
      _.isEqual,
    );

    const intersection = _.intersectionWith(
      this.requestDateHours
        .filter((el) => el.hours)
        .map((el) => ({ date: el.date, hours: +el.hours.toFixed(2) })),
      this.resourcePlanEntries.map((el) => ({
        date: el.date,
        hours: +el.hours.toFixed(2),
      })),
      _.isEqual,
    );

    return (
      !!difference.length ||
      intersection.length !== this.resourcePlanEntries.length
    );
  }

  /** Reloads frame data */
  public async resetEntries(): Promise<void> {
    if (this.resourceRequestService.request?.id) {
      await this.resetRequirementEntries().catch(() =>
        this.loadFrame(null, true),
      );
    }

    this.loadFrame(null, true);
  }

  /**
   * Loads frame of requested hours. By default uses current data.
   *
   * @param direction If `null`, using current interval
   * @param withReload Indicates whether to reload frame data.
   */
  public async loadFrame(
    direction: 'left' | 'right' | null,
    withReload = true,
  ): Promise<void> {
    this.blockUIService.start();

    const loadingInterval: Interval = this.getLoadingInterval(direction);
    this.logService.debug(`Load new interval: ${loadingInterval.toISODate()}`);

    const promises: [Promise<DateHours[]>, Promise<DateHours[]> | DateHours[]] =
      [
        firstValueFrom(
          this.fteScheduleService.getFteSchedule(
            this.planningScale,
            this.interval.start.toISODate(),
            this.interval.end.toISODate(),
          ),
        ),
        withReload
          ? this.resourceRequestService.request?.id
            ? this.getRequirementEntriesRequest()
            : this.getResourcePlanEntries()
          : this.requestDateHours,
      ];

    [this.fteSchedule, this.requestDateHours] = await Promise.all(promises);

    this.requestDateHours.sort((a, b) => (a.date > b.date ? 1 : -1));

    this.resourcePlanEntries = withReload
      ? await this.getResourcePlanEntries()
      : this.resourcePlanEntries;

    this.updateDates();
    this.rebuildAllEntries();

    // NOTE: Handles by `booking.service` in another mode
    if (direction && (this.mode === 'create' || this.mode === 'menuCreate')) {
      setTimeout(() => {
        this.freezeTableService.disableMutationObserver();

        if (direction === 'left') {
          this.freezeTableService.scrollToLeft();
        } else {
          this.freezeTableService.scrollToRight();
        }

        setTimeout(() => {
          this.freezeTableService.enableMutationObserver();
          this.freezeTableService.redraw();
        }, 500);
      }, 10);
    }

    this.resourceRequestService.setEvent(ResourceRequestEvents.loadFrame);
    this.blockUIService.stop();
  }

  /**
   * Add entry to saving queue.
   *
   * @param entry DateHours.
   *
   * */
  public addEntryToQueue(entry: DateHours): void {
    const queueEntry = {
      date: entry.date,
      hours: entry.hours ?? 0,
    };

    const queueEntryIndex = this.entriesToSave.findIndex(
      (el) => el.date === queueEntry.date,
    );

    if (queueEntryIndex > -1) {
      this.entriesToSave[queueEntryIndex].hours = queueEntry.hours;
    } else {
      this.entriesToSave.push(queueEntry);
    }

    const scale = _.clone(this.planningScale);

    this.savingQueueService.addToQueue(this.entriesQueueId, () =>
      this.updateRequirementEntries(scale),
    );
  }

  /**
   * Saves additional values when form's `mode` is `'create'`
   *
   * @param entry DateHours.
   *
   * */
  public addEntryToRequest(entry: DateHours): void {
    const entryIndex = this.requestDateHours.findIndex(
      (el) => el.date === entry.date,
    );

    if (entryIndex > -1) {
      this.requestDateHours[entryIndex].hours = entry.hours;
    } else {
      this.requestDateHours.push(entry);
      this.requestDateHours.sort((a, b) => (a.date > b.date ? 1 : -1));
    }
  }

  /**
   * Sets booking entries and runs rebuild method for them.
   *
   * @param bookingEntries Booking entries.
   * @param resetResourceIds determines needs of reset resourceIds.
   *
   * */
  public setBookingEntries(
    bookingEntries: BookingEntry[],
    resetResourceIds?: boolean,
  ): void {
    this.entries = {
      request: [],
      result: [],
    };
    this.bookingEntries = bookingEntries;
    this.rebuildAllEntries();
    this.resourceRequestService.setEvent(ResourceRequestEvents.loadFrame);
    this.resourceRequestService.setEvent(
      ResourceRequestEvents.loadedBookingEntry,
    );

    if (resetResourceIds) {
      this.resourceIds.clear();
    }
  }

  /**
   * Sets resource ids.
   *
   * @param ids id collection.
   */
  public initResourceIds(ids: string[]): void {
    ids.forEach((id) => {
      this.resourceIds.add(id);
    });
  }

  /**
   * Adds booking entry to resource request.
   *
   * @param resource Resource.
   * @param schedule Resource schedule.
   *
   * */
  public addBookingEntry(resource: NamedEntity, schedule: DateHours[]): void {
    this.resourceIds.add(resource.id);

    this.resourceRequestService.setEvent<BookingResource>(
      ResourceRequestEvents.addedBookingEntry,
      {
        ...resource,
        schedule,
      },
    );
  }

  /** Deletes booking entry from resource request.
   *
   * @param bookingId BookingEntry ID.
   * @param resourceId Resource ID.
   */
  public removeBookingEntry(bookingId: string, resourceId: string): void {
    if (bookingId && this.entries[bookingId]) {
      delete this.entries[bookingId];
    }

    this.resourceIds.delete(resourceId);
    this.resourceRequestService.setEvent(
      ResourceRequestEvents.removedBookingEntry,
      resourceId,
    );
  }

  /**
   * Gets total from result line.
   *
   * @param mode If mode is `assistant` returns deviation from request otherwise return sum of selected resource.
   *
   * @returns hours.
   * */
  public getResultTotal(mode: ResourceRequestMode): number {
    let resultTotal =
      mode === 'assistant'
        ? -this.requestDateHours.reduce(
            (total, value) => total + value.hours,
            0,
          )
        : 0;

    resultTotal += this.bookingEntries.reduce(
      (total, booking) => total + booking.bookedHours,
      0,
    );

    return +resultTotal.toFixed(2);
  }

  /** Inits settings */
  public init(): void {
    this.scheduleNavigationService.init(ScheduleNavigationContext.Booking);
    this.planningScale = this.scheduleNavigationService.planningScale;
    this.valueMode = this.scheduleNavigationService.valueMode;
    this.interval = this.scheduleNavigationService.getInterval(
      this.planningScale,
    );
    this.updateDates();
  }

  /**
   * Emits line toggled event.
   *
   * @param id resource id
   * @param isBottomMode booking assistant mode (bottom or top).
   */
  public setLineToggledEvent(id: string, isBottomMode: boolean): void {
    this.resourceRequestService.setEvent(
      ResourceRequestEvents.bookingEntryLineToggled,
      {
        toggledIndex:
          Array.from(this.resourceIds).findIndex((item) => item === id) ?? 0,
        toggledArea: isBottomMode ? 'bottom' : 'top',
      },
    );
  }

  private setValueMode(valueMode: ValueMode): void {
    this.valueMode = valueMode;
    this.loadFrame(null);
  }

  private setPlanningScale(planningScale: PlanningScale): void {
    this.planningScale = planningScale;

    this.interval = this.scheduleNavigationService.getInterval(
      this.planningScale,
    );

    this.updateDates();
    this.loadFrame(null);
  }

  private updateDates(): void {
    const slotInfo = this.scheduleNavigationService.getSlots(
      this.interval,
      this.planningScale,
    );

    this.slots = slotInfo.slots;
    this.slotGroups = slotInfo.groups;
  }

  private buildEntries(
    key: string,
    dateHours: BookingDetailEntry[] | DateHours[],
    schedule?: DateHours[],
  ): void {
    this.entries[key] ??= [];
    this.entries[key].length = 0;

    this.slots.forEach((slot) => {
      const slotDate = slot.date.toISODate();

      const entry = {
        date: slotDate,
      } as ResourceViewGroupLineEntry;

      const dataFteSchedule = this.fteSchedule?.find(
        (s) => s.date === slotDate,
      );

      entry.limitHours = 999;
      entry.hours = dateHours.find((el) => el.date === slotDate)?.hours ?? 0;
      entry.nonWorking = dataFteSchedule?.hours === 0;
      entry.fteHours = dataFteSchedule?.hours;
      entry.scheduleHours =
        (key === 'request' || key === 'result'
          ? dataFteSchedule?.hours
          : schedule.find((el) => el.date === slotDate)?.hours) ?? 0;

      this.entries[key].push(entry);
    });
  }

  private rebuildAllEntries(): void {
    this.buildEntries('result', this.requestDateHours);
    this.buildEntries('request', this.requestDateHours);
    this.bookingEntries.forEach((bookingEntry) => {
      this.buildEntries(
        bookingEntry.id,
        bookingEntry.detailEntries,
        bookingEntry.resource?.schedule,
      );

      if (this.totalsCalcMode) {
        this.resourceRequestService.setEvent<string>(
          ResourceRequestEvents.updateBookingEntry,
          bookingEntry.resource?.id ?? bookingEntry.resourceId,
        );
      }
    });
  }

  /**
   * Gets new interval depending on direction.
   * Also modifies service's current interval to this new interval.
   *
   * @param direction If `null`, using current interval
   * @returns Luxon `Interval`
   */
  private getLoadingInterval(direction: 'left' | 'right' | null): Interval {
    let loadingInterval: Interval;

    if (!direction) {
      loadingInterval = this.interval;
    } else {
      const shift: DurationLike = this.shiftByScale[this.planningScale];

      loadingInterval =
        direction === 'left'
          ? Interval.fromDateTimes(
              this.interval.start.minus(shift),
              this.interval.start.minus({ days: 1 }),
            )
          : Interval.fromDateTimes(
              this.interval.end.plus({ days: 1 }),
              this.interval.end.plus(shift),
            );

      this.interval =
        direction === 'left'
          ? this.interval.set({
              start: this.interval.start.minus(shift),
            })
          : this.interval.set({
              end: this.interval.end.plus(shift),
            });

      if (this.planningScale === PlanningScale.Month && direction === 'right') {
        loadingInterval = loadingInterval.set({
          end: loadingInterval.end.endOf('month'),
        });
        this.interval = this.interval.set({
          end: this.interval.end.endOf('month'),
        });
      }
    }

    return loadingInterval;
  }

  private initSubscribers(): void {
    this.scheduleNavigationService.previous$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.loadFrame('left', false);
      });

    this.scheduleNavigationService.next$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.loadFrame('right', false);
      });

    this.scheduleNavigationService.jump$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((date) => {
        this.interval = this.scheduleNavigationService.getInterval(
          this.planningScale,
          date,
        );
        this.loadFrame(null, false);
      });

    this.scheduleNavigationService.planningScale$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((planningScale) => {
        this.resourceRequestService.setEvent(
          ResourceRequestEvents.pendingFrame,
        );
        this.setPlanningScale(planningScale);
      });

    this.scheduleNavigationService.valueMode$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((valueMode) => {
        this.resourceRequestService.setEvent(
          ResourceRequestEvents.pendingFrame,
        );
        this.setValueMode(valueMode);
      });
  }

  private updateRequirementEntries(
    scale: PlanningScale,
  ): Observable<DateHours[]> {
    const entries = _.cloneDeep(this.entriesToSave);
    this.entriesToSave.length = 0;

    return this.dataService
      .collection('ResourceRequests')
      .entity(this.resourceRequestService.request.id)
      .action('UpdateRequirementEntries')
      .execute({ scale, entries });
  }

  private async getResourcePlanEntries(): Promise<DateHours[]> {
    if (
      !this.resourceRequestService.request?.teamMemberId &&
      !this.resourceRequestService.requestTemplate?.teamMemberId
    ) {
      return [];
    }

    return await firstValueFrom(
      this.dataService
        .collection('ProjectTeamMembers')
        .entity(
          this.resourceRequestService.requestTemplate?.teamMemberId ??
            this.resourceRequestService.request?.teamMemberId,
        )
        .function('GetGroupedResourcePlanEntries')
        .query<DateHours[]>({
          scale: `WP.PlanningScale'${this.planningScale}'`,
        }),
    );
  }

  private async getRequirementEntriesRequest(): Promise<DateHours[]> {
    return await firstValueFrom(
      this.dataService
        .collection('ResourceRequests')
        .entity(this.resourceRequestService.request.id)
        .function('GetGroupedRequirementEntries')
        .query<DateHours[]>({
          scale: `WP.PlanningScale'${this.planningScale}'`,
        }),
    );
  }

  private async resetRequirementEntries(): Promise<void> {
    await firstValueFrom(
      this.dataService
        .collection('ResourceRequests')
        .entity(this.resourceRequestService.request.id)
        .action('UpdateFromProject')
        .execute(),
    );
  }
}
