import { List } from '../models/inner/list';
import {
  GridColumnType,
  GridComponentColumn,
} from '../models/inner/grid-column.interface';
import { TotalType } from '../models/inner/total-type';
import { FinancialAmountReadonlyCellComponent } from 'src/app/projects/card/shared/financial-amount-cell/financial-amount-readonly-cell.component';
import { FinancialDateReadonlyCellComponent } from 'src/app/projects/card/shared/financial-date-cell/financial-date-readonly-cell.component';
import { FinancialDescriptionCellComponent } from 'src/app/projects/card/shared/financial-description-cell/financial-description-cell.component';
import { FinancialDescriptionReadonlyCellComponent } from 'src/app/projects/card/shared/financial-description-cell/financial-description-readonly-cell.component';
import { FinancialTaskCellComponent } from 'src/app/projects/card/shared/financial-task-cell/financial-task-cell.component';
import { FinancialNonNullableDateCellComponent } from 'src/app/projects/card/shared/financial-date-cell/financial-non-nullable-date-cell.component';
import { FinancialNonNullableAmountCellComponent } from 'src/app/projects/card/shared/financial-amount-cell/financial-non-nullable-amount-cell.component';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ProjectRevenueEstimatesList: List = {
  version: 2,
  name: 'projectRevenue',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'projectTask',
          visibleByDefault: true,
          width: 400,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'date',
          visibleByDefault: true,
          width: 135,
        },
        {
          column: 'amount',
          visibleByDefault: true,
          width: 140,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: { column: 'date', reverse: false },
    },
  ],
  columns: [
    <GridComponentColumn>{
      name: 'date',
      fixedWidth: true,
      type: GridColumnType.Component,
      component: FinancialNonNullableDateCellComponent,
      readonlyComponent: FinancialDateReadonlyCellComponent,
      header: 'projects.projects.card.revenue.columns.date.header',
      hint: 'projects.projects.card.revenue.columns.date.hint',
    },

    <GridComponentColumn>{
      name: 'amount',
      fixedWidth: true,
      type: GridColumnType.Component,
      controlType: 'currency',
      component: FinancialNonNullableAmountCellComponent,
      readonlyComponent: FinancialAmountReadonlyCellComponent,
      header: 'projects.projects.card.revenue.columns.amount.header',
      hint: 'projects.projects.card.revenue.columns.amount.hint',
      contentType: GridColumnType.Currency,
      availableTotals: [TotalType.Sum],
    },
    <GridComponentColumn>{
      name: 'description',
      type: GridColumnType.Component,
      component: FinancialDescriptionCellComponent,
      readonlyComponent: FinancialDescriptionReadonlyCellComponent,
      header: 'shared.props.description',
      hint: 'shared.props.description',
    },
    <GridComponentColumn>{
      name: 'projectTask',
      type: GridColumnType.Component,
      component: FinancialTaskCellComponent,
      fixedWidth: true,
      header: 'projects.projects.card.revenue.columns.task',
      hint: 'projects.projects.card.revenue.columns.task',
    },
  ],
};
