import { Injectable } from '@angular/core';
import { AppConfig } from '../shared/models/app-config.model';
import configData from '../../../config.json';
import { merge } from 'lodash';
import { environment } from 'src/environments/environment';

/**
 * Сервис предназначен для загрузки конфигурации клиента
 * и предоставления конфигурации через статическое свойство.
 */
@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  /** Конфигурация клиента. */
  static config: AppConfig;

  private jsonFile = `/assets/config.json`;

  /** Загрузка конфигурации. */
  public load(): Promise<void> {
    if (!environment.onprem) {
      AppConfigService.config = configData;
      return Promise.resolve();
    }

    return fetch(this.jsonFile, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'ngsw-bypass': 'true',
      },
    })
      .then(
        (response) => response.json(),
        (response: any) => {
          const error = `Could not load file '${
            this.jsonFile
          }': ${JSON.stringify(response)}`;
          console.error(error);
        },
      )
      .then(
        (response: AppConfig) => {
          console.log('Config is loaded.');
          AppConfigService.config = merge(configData ?? {}, response);
        },
        () => null,
      );
  }
}
