<!--Строка суммарных значений-->
<tr class="group" [attr.data-test-resourceId]="group.resource?.id">
  @for (total of group.totals; track total) {
    <td
      class="total-cell"
      [ngClass]="{
        'non-working': total.nonWorking,
        positive: total.hours > 0,
        negative: total.hours < 0
      }"
      [attr.data-test-date]="total.date"
    >
      <span>
        {{ total.hours > 0 ? '+' : null
        }}{{ total.hours | plannerFormat: valueMode : total.scheduleHours : total.fteHours }}
      </span>
      @if (isRequirementOverAvailable(total.hours, $index)) {
        <i
          [ngbTooltip]="'projects.projects.card.resourceRequirements.hints.requirement' | translate"
          tooltipClass="tooltip-max-content"
          class="bi bi-exclamation-triangle-fill text-danger"
        ></i>
      }
    </td>
  }
</tr>
<ng-container *ngIf="group.isExpanded">
  <tr *ngIf="group.resource?.type === 'User'" [attr.data-test-resourceId]="group.resource?.id">
    <td
      title="{{ entry.title }}"
      *ngFor="let entry of group.availabilityHours; trackBy: trackById; index as dayIndex"
      class="availability-cell"
      [ngClass]="{ 'non-working': entry.nonWorking, 'has-value': entry.hasValue }"
      [attr.data-test-date]="entry?.date"
    >
      {{ entry.hours | plannerFormat: service.valueMode : entry.scheduleHours : entry.fteHours }}
    </td>
  </tr>
  <tr
    class="booking"
    *ngIf="group.resource?.type === 'User'"
    [attr.data-test-resourceId]="group.resource?.id"
  >
    <td
      title="{{ entry.title }}"
      *ngFor="let entry of group.bookedHours; trackBy: trackById; index as dayIndex"
      class="booking-cell"
      [ngClass]="{ 'non-working': entry.nonWorking, 'has-value': entry.hasValue }"
      [attr.data-test-date]="entry?.date"
    >
      {{ entry.hours | plannerFormat: service.valueMode : entry.scheduleHours : entry.fteHours }}
    </td>
  </tr>
  <tr class="estimate" [attr.data-test-resourceId]="group.resource?.id">
    <td
      *ngFor="let total of group.estimateTotals; trackBy: trackById"
      class="estimate-cell"
      [ngClass]="{ 'non-working': total.nonWorking }"
      [attr.data-test-date]="total.date"
    >
      {{ total.hours | plannerFormat: valueMode : total.scheduleHours : total.fteHours }}
    </td>
  </tr>
</ng-container>

<ng-container *ngIf="group.isExpanded && group.isEstimateExpanded">
  <!--Строки данных-->
  <tr
    class="line"
    *ngFor="let line of group.tasks; trackBy: trackById; index as lineIndex"
    [attr.data-test-taskId]="line.taskId"
  >
    <td
      *ngFor="
        let entry of $any(formLines.at(lineIndex))?.controls;
        trackBy: trackByValueId;
        index as dayIndex
      "
      wpPlannerCell
      class="cell"
      [valueMode]="valueMode"
      [formControl]="entry"
      [lineIndex]="lineIndex"
      [dayIndex]="dayIndex"
      [scale]="scale"
      [propagationMode]="propagationMode.onExitFromEditing"
      [attr.data-test-date]="entry.value?.date"
      [allowClearInReadonly]="projectCardService.isEntryBlocked(entry)"
    ></td>
  </tr>

  <tr *ngIf="!group.tasks.length">
    <td [attr.colspan]="service.slots.length" class="empty-cell text-body-secondary">
      {{ 'projects.projects.card.resources.messages.thereAreNoTasks' | translate }}
    </td>
  </tr>
</ng-container>
