import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewRef,
  Injector,
} from '@angular/core';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { UntypedFormGroup } from '@angular/forms';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';

@Component({
  selector: 'wp-member-resource-cell',
  templateUrl: './member-resource-cell.component.html',
  styleUrls: ['./member-resource-cell.component.scss'],
})
export class MemberResourceCellComponent implements GridTemplateCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue?: unknown;

  public readonly resourceType = ResourceType;

  constructor(
    public service: GridService,
    public ref: ChangeDetectorRef,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
  ) {
    service.detectChanges$.subscribe(() => {
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
  }

  public getName() {
    const value = this.formGroup.value;
    return value.resource?.name ?? value.description;
  }

  public openUserInfo() {
    const userId = this.formGroup.value.resource.id;
    const target = document.getElementById(
      `user-${this.formGroup.value.resource.id}`,
    );
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }
}
