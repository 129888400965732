import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { shareReplay } from 'rxjs/operators';
import { FinancialAccount } from '../shared/models/entities/finance/financial-account.model';

@Injectable({ providedIn: 'root' })
export class FinancialAccountsService {
  constructor(private data: DataService) {}

  /** Загруженный список видов работ, доступных для автора текущего таймшита. */
  private accounts: ReadonlyArray<FinancialAccount>;

  public accounts$ = this.getAvailableAccounts().pipe(shareReplay());

  /** Возвращает список доступных видов работ.  */
  private getAvailableAccounts(): Observable<ReadonlyArray<FinancialAccount>> {
    return new Observable((subscriber) => {
      if (this.accounts) {
        subscriber.next(this.accounts);
      }

      this.data
        .collection('FinancialAccounts')
        .query<FinancialAccount[]>({
          select: ['id', 'name'],
          filter: { isActive: true },
          expand: {
            type: {
              select: ['id', 'code', 'name'],
            },
          },
        })
        .subscribe((accounts) => {
          this.accounts = accounts;
          subscriber.next(this.accounts);
        });
    });
  }
}
