import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { HistoryLogListComponent } from './list/history-log-list.component';
import { HISTORY_LOG } from 'src/app/shared/lists/history-log.list';
import { HistoryLogFilterComponent } from './list/history-log-filter/history-log-filter.component';
import { HistoryLogCardComponent } from 'src/app/settings-app/history-log/card/history-log-card.component';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    HistoryLogListComponent,
    HistoryLogFilterComponent,
    HistoryLogCardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.historyLog',
          url: '/history-log',
          component: HistoryLogListComponent,
          params: {
            navigation: 'settings.historyLog',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: HISTORY_LOG },
          ],
        },
        {
          name: 'settings.historyLogCard',
          params: {
            navigation: 'settings.historyLog',
            route: ROUTE_DEFAULT_PARAMS,
            routeMode: null,
          },
          url: '/history-log/{entityId:guid}?{navigation}&{route}',
          component: HistoryLogCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class HistoryLogModule {}
