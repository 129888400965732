import { Component, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/project-resources.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo/undo-redo.service';

@Component({
  selector: 'wp-project-resources',
  template: ` <ui-view></ui-view> `,
  providers: [ProjectResourceService],
})
export class ProjectResourcesComponent implements OnInit, OnDestroy {
  constructor(
    private undoRedoService: UndoRedoService,
    private service: ProjectResourceService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.service.undoRedoSessionId = await firstValueFrom(
      this.undoRedoService.startUndoRedoSession(),
    );
  }

  public ngOnDestroy(): void {
    this.undoRedoService.abortUndoRedoSession(this.service.undoRedoSessionId);
  }
}
