import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { RateMatrixService } from 'src/app/settings-app/rate-matrix/card/rate-matrix.service';
import {
  Analytics,
  analytics,
} from 'src/app/settings-app/rate-matrix/card/structure-change-modal/rate-matrix-structure.model';
import { RateMatrixLine } from 'src/app/settings-app/rate-matrix/model/rate-matrix-line.model';
import { RateMatrixStructureCollection } from 'src/app/settings-app/rate-matrix/model/rate-matrix-structure.enum';
import { Guid } from 'src/app/shared/helpers/guid';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-matrix-rate-lines',
  templateUrl: './matrix-rate-lines.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatrixRateLinesComponent implements OnInit {
  @Input() matrixRate: RateMatrixLine;
  @Input() entityId: string;

  public isSaving: boolean;
  public form: UntypedFormGroup;
  public rateMatrixStructureCollection = RateMatrixStructureCollection;
  public queryAnalytics: Partial<Record<Analytics, any>> = {};

  private matrixRatesCollection = this.data.collection('RateMatrixLines');
  private destroyRef = inject(DestroyRef);

  constructor(
    public rateMatrixService: RateMatrixService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    analytics.forEach((key) => {
      this.queryAnalytics[key] = null;
    });
    this.prepareForm(this.matrixRate?.id);
    if (this.matrixRate) {
      this.form.patchValue(this.matrixRate);
    } else {
      this.updateControlsAvailability();
    }
  }

  /** Dismisses changes and closes modal. */
  public cancel(): void {
    this.activeModal.dismiss();
  }

  /** Saves data and closes modal. */
  public ok(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    if (this.matrixRate) {
      this.editMatrixRate();
    } else {
      this.addMatrixRate();
    }
  }

  /** Returns modal header. */
  public getHeader(): string {
    return this.matrixRate
      ? 'settings.rateMatrices.creation.modal.editing'
      : 'settings.rateMatrices.creation.modal.header';
  }

  /** Returns action button name. */
  public getActionBtnName(): string {
    return this.matrixRate ? 'shared.actions.save' : 'shared.actions.create';
  }

  private prepareForm(id?: string): void {
    const formStructure = {} as any;
    formStructure.id = id ?? Guid.generate();
    formStructure.rate = null;
    this.rateMatrixService.rateMatrixStructure.forEach((key, index) => {
      formStructure[key] = index
        ? null
        : (formStructure[key] = [null, Validators.required]);
    });
    this.form = this.fb.group(formStructure);

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateControlsAvailability();
      });

    this.form.controls['role']?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val: NamedEntity) => {
        this.form.controls['competence']?.reset();
        if (val) {
          this.queryAnalytics.competence = {
            filter: [{ roleId: { type: 'guid', value: val?.id } }],
          };
        }
      });
    this.form.controls['level']?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val: NamedEntity) => {
        this.form.controls['grade']?.reset();
        if (val) {
          this.queryAnalytics.grade = {
            filter: [{ levelId: { type: 'guid', value: val?.id } }],
          };
        }
      });
  }

  /** Disables and enables controls depending on previous control value. */
  private updateControlsAvailability(): void {
    for (
      let i = 1;
      i < this.rateMatrixService.rateMatrixStructure.length;
      i++
    ) {
      const previousControl =
        this.form.controls[this.rateMatrixService.rateMatrixStructure[i - 1]];
      const currentControl =
        this.form.controls[this.rateMatrixService.rateMatrixStructure[i]];
      if (previousControl.value) {
        currentControl.enable({ emitEvent: false });
      } else {
        for (
          let j = i;
          j < this.rateMatrixService.rateMatrixStructure.length;
          j++
        ) {
          this.form.controls[
            this.rateMatrixService.rateMatrixStructure[j]
          ].disable({
            emitEvent: false,
          });
        }
        return;
      }
    }
  }

  /** Adds matrix rate. */
  private addMatrixRate(): void {
    const formData = this.form.value;
    const data = {
      id: formData.id,
      rateMatrixId: this.entityId,
      roleId: formData.role?.id ?? null,
      competenceId: formData.competence?.id ?? null,
      locationId: formData.location?.id ?? null,
      levelId: formData.level?.id ?? null,
      gradeId: formData.grade?.id ?? null,
      legalEntityId: formData.legalEntity?.id ?? null,
      resourcePoolId: formData.resourcePool?.id ?? null,
      rate: formData.rate?.value ?? null,
    };

    this.matrixRatesCollection.insert(data).subscribe({
      next: () => {
        this.isSaving = false;
        this.notification.successLocal(
          'settings.rateMatrices.creation.modal.messages.created',
        );
        this.activeModal.close(formData);
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.isSaving = false;
        this.cdr.detectChanges();
      },
    });
  }

  /** Edits matrix rate. */
  private editMatrixRate(): void {
    const formData = this.form.value;
    const data = {
      rateMatrixId: this.entityId,
      roleId: formData.role?.id ?? null,
      competenceId: formData.competence?.id ?? null,
      locationId: formData.location?.id ?? null,
      levelId: formData.level?.id ?? null,
      gradeId: formData.grade?.id ?? null,
      legalEntityId: formData.legalEntity?.id ?? null,
      resourcePoolId: formData.resourcePool?.id ?? null,
      rate: formData.rate?.value ?? null,
    };

    this.matrixRatesCollection
      .entity(formData.id)
      .patch(data)
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.notification.successLocal(
            'settings.rateMatrices.creation.modal.messages.edited',
          );
          this.activeModal.close(formData);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
          this.cdr.detectChanges();
        },
      });
  }
}
