import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { auditTime } from 'rxjs/operators';

@Injectable()
export class WidgetService {
  public rebuild$ = new Subject<void>();

  private reflowSubject = new Subject<void>();
  public reflow$ = this.reflowSubject.asObservable().pipe(auditTime(16));

  public rebuild() {
    this.rebuild$.next();
  }

  public reflow() {
    this.reflowSubject.next();
  }
}
