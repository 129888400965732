<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

<form
  class="modal-form"
  novalidate
  [formGroup]="form"
  ngDraggable
  autocomplete="off"
  [hidden]="isLoading"
>
  <div class="modal-header">
    <h3 class="modal-title">{{ getHeader() | translate }}</h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group" tmtIndicator>
      <label>{{ 'shared.props.name' | translate }}</label>
      <wp-text-box
        formControlName="name"
        placeholder="{{ 'shared.props.name' | translate }}"
        [readonly]="false"
      />
    </div>
    <div class="form-group">
      <label>{{ 'shared.props.description' | translate }}</label>
      <wp-multiline-text-box
        formControlName="description"
        placeholder="{{ 'shared.props.description' | translate }}"
      />
    </div>

    <hr />

    <div class="form-group" [formGroup]="form.get('initialRate')">
      <label>{{ 'projects.clients.card.tariffs.columns.value.header' | translate }}</label>
      <wp-currency-box type="currency" formControlName="value" />
      <small class="text-body-secondary">{{
        'projects.clients.card.tariffs.modal.props.rateOnStart.help' | translate
      }}</small>
    </div>

    <div class="form-group">
      <tmt-tariff-rates [rates]="rates" [readonly]="readonly" />
    </div>

    <div class="form-group form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
      <input id="isActive" formControlName="isActive" type="checkbox" class="form-check-input" />
      <label class="form-check-label" for="isActive">
        {{ 'shared.props.isActive.label' | translate }}
      </label>
    </div>
  </div>

  <div class="modal-footer">
    <button
      *ngIf="!readonly"
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
