<wp-loading-indicator [state]="state"></wp-loading-indicator>

<div [hidden]="state !== 'Ready'">
  <div class="toolbar-container pt-0">
    <button type="button" class="btn btn-default" (click)="addDomain()">
      <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
      {{ 'settings.settings.domains.actions.addDomain' | translate }}
    </button>
  </div>

  <wp-grid
    [formArray]="domains"
    [options]="gridOptions"
    [readonly]="readonly"
    style="width: 600px; display: block"
  ></wp-grid>
</div>
