import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Filter } from '../../models/filter/filter-model';
import { ReportField } from '../../models/source-description/report-field.model';
import { AnalyticsService } from 'src/app/core/analytics.service';
import { ReportSourceDescription } from '../../models/source-description/report-source-description.model';
import { StringHelper } from 'src/app/shared/helpers/string-helper';
import { Criterion } from './criterion.model';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'wp-report-filters',
  templateUrl: './report-filters.component.html',
  styleUrls: ['./report-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportFiltersComponent implements OnInit, OnChanges {
  @Input() reportTypeCode: string;
  @Input() filters: Filter[];
  @Output() onFiltersChanged = new EventEmitter<Filter[]>();
  @Output() onTitleChanged = new EventEmitter<string>();

  public criteria: Criterion[] = [];
  public fieldPickerIsVisible = false;

  public sourceFields: ReportField[];
  public sourceFieldsForPicker: ReportField[];

  public indicatorTitle: string;
  sourceDescription: ReportSourceDescription;
  public allowInactiveControl = new UntypedFormControl(false);
  public sourceForm: UntypedFormGroup;

  private destroyRef = inject(DestroyRef);

  constructor(
    private translate: TranslateService,
    private analyticsService: AnalyticsService,
    private formBuilder: FormBuilder,
  ) {}

  public trackByName = (index: number, item: any) => item.name;

  public addCriterion(name: string) {
    const field = this.sourceFields.find((f) => f.name === name);
    this.fieldPickerIsVisible = false;
    this.criteria.push({
      name: field.name,
      field,
      conditions: [],
    });
    this.updateIndicator();
    this.propagateChanges();
    this.updateSourceFields();
    this.sourceForm.patchValue({ source: null });
  }

  public removeCriterion(criterion) {
    const index = this.criteria.indexOf(criterion);
    this.criteria.splice(index, 1);
    this.updateIndicator();
    this.propagateChanges();
    this.updateSourceFields();
  }

  private getUpdatedFilters(): Filter[] {
    const filters: Filter[] = [];

    this.criteria.forEach((criterion) => {
      // Saved criterion
      const sCriterion = { key: criterion.name, value: [] };

      criterion.conditions.forEach((condition) => {
        sCriterion.value.push({
          operator: condition.operator,
          value: condition.value,
        });
      });
      filters.push(sCriterion);
    });

    return filters;
  }

  public criterionChanged(criterion: Criterion, index: number) {
    this.criteria[index].conditions = criterion.conditions;
    this.propagateChanges();
  }

  public propagateChanges() {
    this.onFiltersChanged.emit(this.getUpdatedFilters());
  }

  private updateIndicator() {
    if (this.criteria.length === 0) {
      this.indicatorTitle = this.translate.instant(
        'analytics.reportFilters.noFiltersDefined',
      );
    } else {
      const p = { count: this.criteria.length };
      this.indicatorTitle = StringHelper.declOfNum(this.criteria.length, [
        this.translate.instant(
          'analytics.reportFilters.criteriaCountForms.v1',
          p,
        ),
        this.translate.instant(
          'analytics.reportFilters.criteriaCountForms.v2',
          p,
        ),
        this.translate.instant(
          'analytics.reportFilters.criteriaCountForms.v3',
          p,
        ),
      ]);
    }

    this.onTitleChanged.emit(this.indicatorTitle);
  }

  updateSourceFields() {
    this.sourceFields = this.sourceDescription.allFields.filter(
      (field) => !field.isCalculated,
    );

    this.sourceFieldsForPicker = this.sourceDescription.allFields.filter(
      (field) =>
        !field.isCalculated &&
        (!this.sourceDescription.isTimeLine || !field.isTimeLineField),
    );
  }

  private loadSourceDescription() {
    if (!this.reportTypeCode) {
      return;
    }

    return this.analyticsService
      .getSourceDescription(this.reportTypeCode)
      .subscribe((description) => {
        this.sourceDescription = description;
        this.updateSourceFields();
        this.parseFilters();
      });
  }

  private parseFilters() {
    this.criteria = [];

    if (this.filters) {
      this.filters.forEach((filter: Filter) => {
        const sourceField = this.sourceFields.find(
          (f) => f.name === filter.key,
        );
        if (!sourceField) {
          return;
        }
        const criterion: Criterion = {
          name: filter.key,
          field: sourceField,
          conditions: cloneDeep(filter.value),
        };
        this.criteria.push(criterion);
      });
    }
    this.updateIndicator();
  }

  ngOnChanges(): void {
    this.loadSourceDescription();
    this.updateIndicator();
  }

  ngOnInit(): void {
    this.loadSourceDescription();
    this.updateIndicator();
    this.sourceForm = this.formBuilder.group({
      source: null,
    });
    this.sourceForm
      .get('source')
      .valueChanges.pipe(
        filter((v) => v),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value: ReportField) => {
        this.addCriterion(value.name);
      });
  }
}
