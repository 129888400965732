import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { saveAs } from 'file-saver';

import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';

import { restrictedFormats } from 'src/app/shared-features/comments/model/attachment.model';
import { Attachment } from 'src/app/shared-features/comments/model/attachment.model';
import { AttachmentItem } from 'src/app/shared/components/features/viewer/viewer.interface';
import { ViewerComponent } from 'src/app/shared/components/features/viewer/viewer.component';
import { SortService } from 'src/app/shared/components/features/sort/core/sort.service';
import { SortDirection } from 'src/app/shared-features/comments/model/sort-direction.enum';

import { FileWithId } from './comments.service';
import { CommentsDataService } from './comments-data.service';

@Injectable()
export class AttachmentsManagerService {
  public attachments: Attachment[] = [];
  private commentsAttachmentsSubject = new BehaviorSubject([]);
  public commentsAttachments$ = this.commentsAttachmentsSubject.asObservable();

  private attachmentDeletedSubject = new Subject<Attachment>();
  public attachmentDeleted$ = this.attachmentDeletedSubject.asObservable();

  public readonly restrictedFormats: string[];

  private collection: string;
  private entityId: string;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private dataService: CommentsDataService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private modal: NgbModal,
    private sortService: SortService,
  ) {
    this.restrictedFormats = restrictedFormats;
  }

  /**
   * Deletes attachment of existing comments on success confirm message.
   *
   * @param attachment Attachment.
   *
   * */
  public deleteAttachment(attachment: Attachment): void {
    this.messageService.confirmLocal('shared.deleteConfirmation').then(() =>
      this.dataService
        .deleteAttachment(this.collection, this.entityId, attachment.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.attachmentDeletedSubject.next(attachment);
        }),
    );
  }

  /** Method that init service. */
  public init(collection: string, entityId: string) {
    this.collection = collection;
    this.entityId = entityId;

    this.sortService.sortDirection$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((direction: SortDirection) => {
        // TODO duplicate sort
        this.attachments = this.attachments.sort((prev, next) => {
          if (direction === SortDirection.newest) {
            return new Date(prev.created) > new Date(next.created) ? -1 : 1;
          }
          return new Date(prev.created) < new Date(next.created) ? -1 : 1;
        });
      });
  }

  /** Loads attachments from comments. */
  public reload(): void {
    this.dataService
      .getAttachments(this.collection, this.entityId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: Attachment[]) => {
        this.attachments = data;
        this.commentsAttachmentsSubject.next(data);
      });
  }

  /**
   * Get attachment.
   *
   * @param attachment Attachment.
   *
   * @return Blob in promise.
   * */
  public async getAttachment(attachment: Attachment): Promise<Blob> {
    return await firstValueFrom(
      this.dataService.downloadAttachment(
        this.collection,
        this.entityId,
        attachment.id,
      ),
    );
  }

  /**
   * Download attachment.
   *
   * @param attachment Attachment.
   *
   * */
  public async downloadAttachment(attachment: Attachment): Promise<void> {
    const result = await this.getAttachment(attachment);

    if (result) {
      saveAs(result, attachment.fileName || 'Attachment');
    }
  }

  /**
   * Check if file name is in the allowed extension format.
   *
   * @param files FileList from upload event.
   *
   * @returns true if format is valid
   *
   * //TODO: check directive dndZoneDirective and ngx-dropdown
   * */
  public isValidFormat(value: { listFiles?: FileList; file?: File }): boolean {
    if (value.listFiles.length) {
      for (let i = 0; i < value?.listFiles.length; i++) {
        const parsedName = value?.listFiles.item(i).name.split('.');

        if (
          parsedName.length < 2 ||
          this.restrictedFormats.includes(parsedName.pop().toLowerCase())
        ) {
          this.notificationService.error(
            this.translateService.instant(
              'shared.messages.attachmentWrongFormat',
            ),
          );
          return false;
        }
      }
    } else {
      const parsedName = value?.file.name.split('.');
      if (
        parsedName.length < 2 ||
        this.restrictedFormats.includes(parsedName.pop().toLowerCase())
      ) {
        this.notificationService.error(
          this.translateService.instant(
            'shared.messages.attachmentWrongFormat',
          ),
        );
        return false;
      }
    }

    return true;
  }

  /**
   * Open attachments viewer.
   *
   * @param attachments list of files.
   * @param activeItemId id of first file for show.
   * @param fullscreen Indicates whether to open viewer in fullscreen mode.
   *
   * */
  public openViewer(
    attachments: Attachment[] | FileWithId[],
    activeItemId?: string,
    fullscreen = true,
  ): void {
    const modalRef = this.modal.open(ViewerComponent, {
      fullscreen: !!fullscreen,
      modalDialogClass: fullscreen ? 'modal-transparent' : 'modal-overflow',
      size: !fullscreen ? 'xl' : null,
    });

    const items: AttachmentItem[] = attachments.map((value) => {
      if (value instanceof File) {
        return {
          id: (value as FileWithId).id,
          fileName: value.name,
          data: value,
        };
      }

      return {
        ...value,
        url: '',
        dataLazyLoad: () => this.getAttachment(value),
      };
    });

    (modalRef.componentInstance as ViewerComponent).params = {
      attachments: items,
      activeId: activeItemId,
      outsideClickCallback: () => modalRef.close(),
    };
  }
}
