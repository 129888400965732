import { DestroyRef, Injectable, inject } from '@angular/core';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TimelineGraph } from 'src/app/projects/card/project-tasks/shared/models/timeline-graph.model';
import {
  ProjectTask,
  ProjectTaskDependency,
  ProjectTaskDependencyType,
} from 'src/app/shared/models/entities/projects/project-task.model';
import { ProjectTasksDataService } from './project-tasks-data.service';
import { CreateDependencyType } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/models/create-dependency-type.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class ProjectTaskDependenciesService {
  private allowedLeftMarkerTaskIdsSubject = new BehaviorSubject<string[]>(null);
  public allowedLeftMarkerTaskIds$ =
    this.allowedLeftMarkerTaskIdsSubject.asObservable();

  private allowedRightMarkerTaskIdsSubject = new BehaviorSubject<string[]>(
    null,
  );
  public allowedRightMarkerTaskIds$ =
    this.allowedRightMarkerTaskIdsSubject.asObservable();

  private isCreatingDependencySubject = new BehaviorSubject<boolean>(false);
  public isCreatingDependency$ =
    this.isCreatingDependencySubject.asObservable();

  private subscriptions: Subscription[] = [];
  private dependencyGraph: TimelineGraph;

  private get defaultAllowedMarkerTaskIds(): string[] {
    return this.dataService.tasks
      ?.filter((task) => task.leadTaskId)
      .map((task) => task.id);
  }

  private destroyRef = inject(DestroyRef);

  constructor(private dataService: ProjectTasksDataService) {
    this.dataService.syncDependencies$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.syncDependencyGraph());
  }

  /**
   * Sets taskIds list of left or right allowed dependency markers.
   *
   * @param taskIds allowed task ids
   * @param markerType type of the setting markers
   */
  public setAllowedMarkerTaskIds(taskIds: string[], markerType: string) {
    if (markerType === 'left') {
      this.allowedLeftMarkerTaskIdsSubject.next(taskIds);
    }
    if (markerType === 'right') {
      this.allowedRightMarkerTaskIdsSubject.next(taskIds);
    }
  }

  /** Sets default taskIds list of allowed dependency markers. */
  public setDefaultAllowedMarkerTaskIds() {
    this.allowedLeftMarkerTaskIdsSubject.next(this.defaultAllowedMarkerTaskIds);
    this.allowedRightMarkerTaskIdsSubject.next(
      this.defaultAllowedMarkerTaskIds,
    );
  }

  /** Turns on/turn off creating dependency property. */
  public setIsCreatingDependency(isCreating: boolean) {
    this.isCreatingDependencySubject.next(isCreating);
  }

  /**
   * Returns allowed predecessor of dependent task ids for selected task by it id.
   *
   * @param taskId - target task id
   * @param taskType - target task type
   * @param excludeExistingDependencies - exclude task ids, which already are in dependencies
   * @param targetTasks - get allowed dependencies from this collection
   * @returns task ids allowed for dependency creating
   */
  public getAllowedDependencyTaskIds(
    taskId: string,
    taskType: 'predecessor' | 'dependent',
    excludeExistingDependencies = false,
    targetTasks?: ProjectTask[],
  ): string[] {
    let task: ProjectTask;
    let allowedTaskIds: string[];

    if (targetTasks) {
      task = targetTasks.find((t) => t.id === taskId);
      allowedTaskIds = targetTasks.filter((t) => t.leadTaskId).map((t) => t.id);
    } else {
      task = this.dataService.tasks.find((t) => t.id === taskId);
      allowedTaskIds = this.defaultAllowedMarkerTaskIds;
    }

    const isLeadTask = this.dataService.checkIsLeadTask(taskId, targetTasks);

    // Filter all child task
    if (isLeadTask) {
      const childTaskIds = this.getAllChildTasks(taskId, targetTasks).map(
        (t) => t.id,
      );
      allowedTaskIds = _.difference(allowedTaskIds, childTaskIds);
    }

    // Filter all summary task
    const summaryTaskIds = this.getAllSummaryTasks(taskId, targetTasks).map(
      (t) => t.id,
    );
    allowedTaskIds = _.difference(allowedTaskIds, summaryTaskIds);

    if (excludeExistingDependencies) {
      // Filter existing dependencies (predecessor and dependent tasks)
      const predecessorTaskIds = task?.dependencies.map(
        (dependency) => dependency.predecessorId,
      );
      const dependentTaskIds = _.cloneDeep(this.dataService.tasks)
        .filter((t) =>
          t.dependencies.find(
            (dependency) => dependency.predecessorId === taskId,
          ),
        )
        .map((t) => t.id);
      const existingDependencyTaskIds: string[] = [
        ...predecessorTaskIds,
        ...dependentTaskIds,
      ];
      allowedTaskIds = _.difference(allowedTaskIds, existingDependencyTaskIds);
    }

    // Remove marker ids which provides circular dependency
    allowedTaskIds = this.removeCircularDependencyMarkers(
      taskId,
      taskType,
      allowedTaskIds,
      targetTasks,
    );

    return allowedTaskIds;
  }

  /**
   * Filters allowed dependency markers based target task and dependency creating type.
   *
   * @param taskId target task id for markers filtering
   * @param dependencyType type of dependency creating
   */
  public filterDependencyMarkers(
    taskId: string,
    dependencyType: CreateDependencyType,
  ) {
    let allowedTaskIds = [];
    switch (dependencyType) {
      case CreateDependencyType.fromTaskStart:
        allowedTaskIds = this.getAllowedDependencyTaskIds(
          taskId,
          'dependent',
          true,
        );
        this.setAllowedMarkerTaskIds([], 'left');
        this.setAllowedMarkerTaskIds(allowedTaskIds, 'right');
        break;
      case CreateDependencyType.fromTaskEnd:
        allowedTaskIds = this.getAllowedDependencyTaskIds(
          taskId,
          'predecessor',
          true,
        );
        this.setAllowedMarkerTaskIds(allowedTaskIds, 'left');
        this.setAllowedMarkerTaskIds([], 'right');
        break;
    }
  }

  /**
   *  Determines if task is allowed to increase its level considering existing
   *  dependencies and returns corresponding boolean value. If any existing
   *  dependency within branch became circular, increase is not allowed.
   *
   * @param taskId - task under test.
   * @param tasks - project tasks after increase.
   * @param graph - dependency graph after increase.
   */
  public checkIfIncreaseAllowed(
    taskId: string,
    tasks: ProjectTask[],
    graph: TimelineGraph,
  ): boolean {
    const task = tasks.find((t) => t.id === taskId);
    const childTasks = this.getAllChildTasks(task.id, tasks);
    const branchTasks = [task, ...childTasks];
    const anyDependencyIsCircular = branchTasks.some((bt) =>
      bt.dependencies.some((d) =>
        graph.checkIfPathExists(bt.id, d.predecessorId),
      ),
    );
    return !anyDependencyIsCircular;
  }

  /**
   *  Determines if task is allowed to decrease its level considering existing
   *  dependencies and returns corresponding boolean value. If any existing
   *  dependency within branch became circular, decrease is not allowed.
   *
   * @param taskId - task under test.
   * @param tasks - project tasks after decrease.
   * @param graph - dependency graph after decrease.
   */
  public checkIfDecreaseAllowed(
    taskId: string,
    tasks: ProjectTask[],
    graph: TimelineGraph,
  ): boolean {
    const task = tasks.find((t) => t.id === taskId);
    const leadTask = tasks.find((t) => t.id === task.leadTaskId);
    const childTasks = this.getAllChildTasks(leadTask.id, tasks);
    const branchTasks = [leadTask, ...childTasks];
    const anyDependencyIsCircular = branchTasks.some((bt) =>
      bt.dependencies.some((d) =>
        graph.checkIfPathExists(bt.id, d.predecessorId),
      ),
    );
    return !anyDependencyIsCircular;
  }

  /** Checks has task dependent tasks.
   *
   * @param taskId task id for checking
   * @returns availability of the dependent tasks for the project task
   */
  public checkHasDependentTasks(taskId: string): boolean {
    return !!this.dataService.tasks.find((task) =>
      task.dependencies.find(
        (dependency) => dependency.predecessorId === taskId,
      ),
    );
  }

  /**
   * Returns date limited by task own dependencies and it summary tasks dependencies.
   *
   * @param task target task
   * @param exceptionTaskIds Exclude dependencies of predecessor tasks of this array.
   * @returns min allowed date for target task
   */
  public findMinStartAllowedDate(
    task: ProjectTask,
    exceptionTaskIds?: string[],
  ): DateTime {
    /** Return min start allowed date by dependency chain. */
    const findDateByDependencies = (handleTask: ProjectTask): string => {
      if (handleTask.dependencies.length) {
        const predecessorTasks = this.dataService.tasks.filter((t) =>
          handleTask.dependencies
            .map((dependency) => dependency.predecessorId)
            .filter((predecessorId) => {
              if (exceptionTaskIds?.length) {
                return !exceptionTaskIds.includes(predecessorId);
              } else {
                return true;
              }
            })
            .includes(t.id),
        );

        return _.max([
          ...predecessorTasks.map((predecessorTask) => predecessorTask.endDate),
        ]);
      } else {
        return null;
      }
    };

    /** Returns min start allowed date by dependency chain of summary tasks. */
    const findDateBySummaryTask = (handleTask: ProjectTask): string | null => {
      if (!handleTask.leadTaskId) {
        return null;
      }

      const leadTask = this.dataService.tasks.find(
        (t) => t.id === handleTask.leadTaskId,
      );
      if (exceptionTaskIds?.includes(leadTask?.id)) {
        return null;
      }

      if (!leadTask.dependencies.length) {
        return findDateBySummaryTask(leadTask);
      }

      const predecessorTasks = this.dataService.tasks.filter((t) =>
        leadTask.dependencies
          .map((dependency) => dependency.predecessorId)
          .includes(t.id),
      );

      return _.max([
        ...predecessorTasks.map((predecessorTask) => predecessorTask.endDate),
      ]);
    };

    const dateByDependencies = findDateByDependencies(task);
    const dateBySummaryTask = findDateBySummaryTask(task);

    const minDate = DateTime.fromISO(
      _.max([dateByDependencies, dateBySummaryTask]),
    ).plus({ days: 1 });

    return minDate;
  }

  public buildGraph(tasks: ProjectTask[]): TimelineGraph {
    const withImplicit = this.getTasksWithNewImplicitDependencies(tasks);
    return new TimelineGraph(withImplicit);
  }

  /** Synchronizes current task dependencies state with the graph. */
  public syncDependencyGraph() {
    const projectTasksWithImplicitDependencies =
      this.getTasksWithNewImplicitDependencies(this.dataService.tasks);
    this.dependencyGraph = new TimelineGraph(
      projectTasksWithImplicitDependencies,
    );
  }

  /**
   * Returns all child task chain in the flat array.
   *
   * @param leadTaskId - id of the task for which searches child tasks
   * @param targetTasks - project tasks array for search (default uses array from data service)
   * @returns all child tasks
   */
  public getAllChildTasks(
    leadTaskId: string,
    targetTasks?: ProjectTask[],
  ): ProjectTask[] {
    const tasks = targetTasks ? targetTasks : this.dataService.tasks;
    if (!this.dataService.checkIsLeadTask(leadTaskId, targetTasks)) {
      return [];
    }

    const findFirstLevelChildren = (taskId: string) =>
      tasks.filter((t) => t.leadTaskId === taskId);

    return findFirstLevelChildren(leadTaskId).flatMap((childTask) => [
      childTask,
      ...this.getAllChildTasks(childTask.id, targetTasks),
    ]);
  }

  /**
   * Removes marker ids which provides circular dependency from allowed dependency markers.
   *
   * @param taskId id of the task which initiated of task dependency markers filtering
   * @param taskType type of the initiated task
   * @param allowedTaskIds current allowed task Ids
   * @param targetTasks - remove circular dependencies from this collection
   * @returns filtered task ids
   */
  private removeCircularDependencyMarkers(
    taskId: string,
    taskType: 'predecessor' | 'dependent',
    allowedTaskIds: string[],
    targetTasks?: ProjectTask[],
  ): string[] {
    const taskIdsToFilter = [];

    let targetTaskId: string;
    let predecessorId: string;
    let dependencyGraph: TimelineGraph;

    if (targetTasks) {
      dependencyGraph = new TimelineGraph(
        this.getTasksWithNewImplicitDependencies(targetTasks),
      );
    } else {
      dependencyGraph = this.dependencyGraph;
    }

    allowedTaskIds.forEach((id) => {
      switch (taskType) {
        case 'dependent':
          targetTaskId = taskId;
          predecessorId = id;
          break;
        case 'predecessor':
          targetTaskId = id;
          predecessorId = taskId;
          break;
      }

      const willCauseCycle = dependencyGraph.checkIfPathExists(
        targetTaskId,
        predecessorId,
      );

      if (willCauseCycle) {
        taskIdsToFilter.push(id);
      }
    });

    allowedTaskIds = _.difference(allowedTaskIds, taskIdsToFilter);
    return allowedTaskIds;
  }

  /**
   * Returns all summary task chain in the flat array.
   *
   * @param taskId - id of the task for which searches summary tasks.
   * @param targetTasks - project tasks array for search (default uses array from data service).
   * @param excludeMain - determines whether to exclude main task from resulting tasks.
   * @returns all parent tasks.
   */
  private getAllSummaryTasks(
    taskId: string,
    targetTasks?: ProjectTask[],
    excludeMain = false,
  ): ProjectTask[] {
    const tasks = targetTasks ? targetTasks : this.dataService.tasks;
    const task = tasks.find((t) => t.id === taskId);
    if (!task.leadTaskId) {
      return [];
    }

    const findFirstLevelSummaryTask = (childTask: ProjectTask) => [
      tasks.find((t) => t.id === childTask.leadTaskId),
    ];

    let summaryTasks = findFirstLevelSummaryTask(task).flatMap(
      (summaryTask) => [
        summaryTask,
        ...this.getAllSummaryTasks(summaryTask.id, targetTasks),
      ],
    );
    if (excludeMain) {
      summaryTasks = summaryTasks.filter((st) => !!st.leadTaskId);
    }
    return summaryTasks;
  }

  /**
   * Recursively traverses hierarchy and builds implicit dependencies for tasks that
   * will be moved.
   * - if task has predecessor, then predecessor's branch tasks are also predecessors
   * - if child has predecessor, then upper has dependency as well
   * - if upper has predecessor, then child has dependency as well
   *
   * Mapping is needed to not pollute passed tasks with implicit dependencies.
   *
   * @param tasks Hierarchy of tasks to check
   * @return tasks with implicit dependencies
   */
  private getTasksWithNewImplicitDependencies(
    tasks: ProjectTask[],
  ): ProjectTask[] {
    const tasksWithImplicitDependencies = [] as ProjectTask[];
    const findTasksThatWillForceToMove = (task: ProjectTask) => {
      // NOTE: Finds predecessors' branch tasks
      const predecessorIds = task.dependencies.map((d) => d.predecessorId);
      const predecessors = tasks.filter((t) => predecessorIds.includes(t.id));
      const predecessorsUpper = predecessors.flatMap((p) =>
        this.getAllSummaryTasks(p.id, tasks, true),
      );
      const predecessorsChildren = predecessors.flatMap((p) =>
        this.getAllChildTasks(p.id, tasks),
      );
      const predecessorsBranch = [
        ...predecessorsUpper,
        ...predecessorsChildren,
      ].filter((pt) => !!pt.leadTaskId);

      // NOTE: Finds upper predecessor's branch tasks
      const upper = this.getAllSummaryTasks(task.id, tasks).filter(
        (ut) => !!ut.leadTaskId,
      );
      const upperPredecessorIds = upper
        .flatMap((ut) => ut.dependencies)
        .map((d) => d.predecessorId);
      const upperPredecessors = tasks.filter((t) =>
        upperPredecessorIds.includes(t.id),
      );
      const upperPredecessorsUpper = upperPredecessors.flatMap((p) =>
        this.getAllSummaryTasks(p.id, tasks),
      );
      const upperPredecessorsChildren = upperPredecessors.flatMap((p) =>
        this.getAllChildTasks(p.id, tasks),
      );
      const upperPredecessorsBranch = [
        ...upperPredecessorsUpper,
        ...upperPredecessors,
        ...upperPredecessorsChildren,
      ].filter((pt) => !!pt.leadTaskId);

      // NOTE: Finds children outer predecessor's branch tasks
      const children = this.getAllChildTasks(task.id, tasks);
      const childrenIds = children.map((c) => c.id);
      const childrenOuterPredecessorIds = children
        .flatMap((ut) => ut.dependencies)
        .map((d) => d.predecessorId)
        .filter((pid) => !childrenIds.includes(pid));
      const childrenOuterPredecessors = tasks.filter((t) =>
        childrenOuterPredecessorIds.includes(t.id),
      );
      const childrenOuterPredecessorsUpper = childrenOuterPredecessors.flatMap(
        (p) => this.getAllSummaryTasks(p.id, tasks),
      );
      const childrenOuterPredecessorsChildren =
        childrenOuterPredecessors.flatMap((p) =>
          this.getAllChildTasks(p.id, tasks),
        );
      const childrenOuterPredecessorsBranch = [
        ...childrenOuterPredecessorsUpper,
        ...childrenOuterPredecessors,
        ...childrenOuterPredecessorsChildren,
      ].filter((pt) => !!pt.leadTaskId);

      return _.uniqBy(
        [
          ...predecessorsBranch,
          ...upperPredecessorsBranch,
          ...childrenOuterPredecessorsBranch,
        ],
        (pt) => pt.id,
      );
    };

    const buildImplicitDependencies = (task: ProjectTask) => {
      const subTasks = tasks.filter((t) => t.leadTaskId === task.id);
      subTasks.forEach((st) => buildImplicitDependencies(st));
      if (!task.leadTaskId) {
        return;
      }

      const movedBy = findTasksThatWillForceToMove(task);
      const currDependencies = task.dependencies.map(
        (d) =>
          ({
            predecessorId: d.predecessorId,
            type: d.type,
          }) as ProjectTaskDependency,
      );
      const implicitDependencies = movedBy.map((m) => ({
        predecessorId: m.id,
        type: ProjectTaskDependencyType.FinishToStart,
      }));
      tasksWithImplicitDependencies.push({
        id: task.id,
        dependencies: [...currDependencies, ...implicitDependencies],
      } as ProjectTask);
    };
    const mainTask = tasks.find((task) => !task.leadTaskId);
    buildImplicitDependencies(mainTask);
    return tasksWithImplicitDependencies;
  }
}
