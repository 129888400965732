<wp-loading-indicator [loading]="loading"></wp-loading-indicator>

<form class="modal-form" novalidate ngDraggable *ngIf="!loading">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.projects.card.team.assignUser.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body" [formGroup]="form" [hidden]="loading">
    <div class="form-group">
      <label>{{ 'projects.projects.card.team.assignUser.properties.generic' | translate }}</label>
      <p>{{ member.resource?.name }}</p>
    </div>

    <hr />

    <div class="form-check mb-3">
      <input formControlName="hasRole" type="checkbox" class="form-check-input" id="hasRole" />
      <label class="form-check-label" for="hasRole">
        {{ 'projects.projects.card.team.assignUser.properties.hasRole' | translate }}&nbsp;{{
          role?.name
        }}
      </label>
    </div>

    <div class="form-group">
      <label>{{ 'projects.projects.card.team.assignUser.properties.user' | translate }}</label>
      <wp-user-box
        formControlName="resource"
        [query]="resourceFilter"
        placeholder="{{ 'projects.projects.card.team.assignUser.properties.user' | translate }}"
      ></wp-user-box>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'projects.projects.card.team.assignUser.actions.assign' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
