<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

<form class="modal-form" novalidate ngDraggable [hidden]="isLoading">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'shared.errorMessageModalHeader' | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <p class="pt-1">{{ error?.message }}</p>
    <p *ngFor="let timesheet of timesheets">
      <tmt-state-badge [state]="timesheet.state"></tmt-state-badge>
      &nbsp;
      <a uiSref="timesheet" [uiParams]="{ entityId: timesheet.id }" (click)="cancel()">{{
        timesheet.name
      }}</a>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
