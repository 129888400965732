<h1
  *ngIf="hasName"
  style="margin-top: 0; margin-bottom: 20px"
  [hidden]="isEditMode"
  [ngClass]="{ editable: isEditable }"
>
  <span (click)="onClickHeader()">{{ cardName }}</span>
</h1>

<div class="editor" *ngIf="isEditMode">
  <div class="form-group">
    <wp-text-box
      [formControl]="textControl"
      style="width: 300px"
      placeholder="{{ 'shared.props.name' | translate }}"
    ></wp-text-box>
  </div>
  <button
    class="btn btn-primary"
    wpButtonSpinner
    [isBusy]="isNameSaving"
    [disabled]="textControl.invalid"
    (click)="save()"
  >
    {{ 'shared.actions.save' | translate }}
  </button>

  <button class="btn btn-default" [disabled]="isNameSaving" (click)="cancel()">
    {{ 'shared.actions.cancel' | translate }}
  </button>
</div>
