import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { ReportField } from '../../models/source-description/report-field.model';
import { BaseViewField } from '../../models/view-settings/base-view-field.model';
import { NotificationService } from 'src/app/core/notification.service';

/** Компонента редактирования поля (в представлении). */
@Component({
  selector: 'wp-field-properties-modal',
  templateUrl: './field-properties-modal.component.html',
  styleUrls: ['./field-properties-modal.component.scss'],
})
export class FieldPropertiesModalComponent implements OnInit {
  @Input() field: BaseViewField;
  @Input() sourceField: ReportField;

  public form = this.fb.group({
    title: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
  });

  constructor(
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
  ) {}

  public ok() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }
    this.activeModal.close(this.form.value.title);
  }

  /** Закрыть диалог. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ngOnInit(): void {
    const title = this.field.customTitle
      ? this.field.customTitle
      : this.sourceField.title;
    this.form.controls['title'].setValue(title);
  }
}
