import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';

/** Loading indicator. */
@Component({
  selector: 'wp-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent {
  loading$ = new Subject<boolean>();
  timeoutId: any;

  @Input()
  set loading(value: boolean) {
    clearTimeout(this.timeoutId);
    if (!value) {
      this.loading$.next(false);
    } else {
      this.timeoutId = setTimeout(() => this.loading$.next(true), 300);
    }
  }

  @Input() set state(state: CardState) {
    this.loading = state === CardState.Loading;
  }

  @Input() set progress(value: number) {
    this.style['width'] = `${value}%`;
  }

  public style: Dictionary<string> = {};
}
