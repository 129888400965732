import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SortDirection } from 'src/app/shared-features/comments/model/sort-direction.enum';

@Injectable({
  providedIn: 'root',
})
/**
 *  Service for sort component management
 *  Provide this service in component where you use sort component
 *
 * */
export class SortService {
  private sortDirectionSubject = new BehaviorSubject<SortDirection>(
    SortDirection.newest,
  );

  /** Observable that emit current sort direction */
  public sortDirection$ = this.sortDirectionSubject.asObservable();
  private visibilitySubject = new BehaviorSubject<boolean>(true);

  /** Observable that emit current sort component visibility */
  public visibility$ = this.visibilitySubject.asObservable();

  private _sortDirection: SortDirection = SortDirection.newest;

  /** Function that returns current sort direction */
  public get sortDirection(): SortDirection {
    return this._sortDirection;
  }

  /** Function that change current sort direction */
  public set sortDirection(direction: SortDirection) {
    this._sortDirection = direction;
    this.sortDirectionSubject.next(direction);
  }

  /** Function that toggle current sort direction */
  public toggleDirection() {
    if (this._sortDirection === SortDirection.oldest) {
      this.sortDirection = SortDirection.newest;
      return;
    }
    this.sortDirection = SortDirection.oldest;
  }

  /** Function that show sort component */
  public show() {
    this.visibilitySubject.next(true);
  }

  /** Function that hide sort component */
  public hide() {
    this.visibilitySubject.next(false);
  }
}
