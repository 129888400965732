import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { NamingLocalizationComponent } from './naming-localization.component';
import { NamingLocalizationModalComponent } from './naming-localization-modal/naming-localization-modal.component';

@NgModule({
  declarations: [NamingLocalizationComponent, NamingLocalizationModalComponent],
  imports: [CommonModule, SharedModule],
  exports: [NamingLocalizationComponent],
})
export class NamingLocalizationModule {}
