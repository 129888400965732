import { Component } from '@angular/core';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { Toolbar } from 'src/app/shared/components/features/grid/toolbar';
import { ProjectBillingService } from '../project-billing.service';

@Component({
  selector: 'wp-project-billing-toolbar',
  templateUrl: './project-billing-toolbar.component.html',
})
export class ProjectBillingToolbarComponent extends Toolbar {
  constructor(
    public service: GridService,
    public projectBillingService: ProjectBillingService,
  ) {
    super();
  }

  public addEntry() {
    this.service.execute('addEntry');
  }

  public recognize() {
    this.service.execute('recognize');
  }
}
