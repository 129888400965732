import { Component, Input } from '@angular/core';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';

@Component({
  selector: 'wp-state-cell',
  styleUrls: ['./state-cell.component.scss'],
  templateUrl: './state-cell.component.html',
})
export class StateCellComponent {
  @Input() value: any;
  @Input() column: GridColumn;
}
