import { Injectable } from '@angular/core';
import { Guid } from 'src/app/shared/helpers/guid';
import { cloneDeep } from 'lodash';
import { ValueMode } from '../models/value-mode.enum';
import { TranslateService } from '@ngx-translate/core';
import { PlanningScale } from '../../../shared/models/enums/planning-scale.enum';
import { Slot } from '../models/slot.model';
import { DatePipe } from '@angular/common';
import { DateTime, Interval } from 'luxon';

/** Сервис управления представлением планировщика. */
@Injectable()
export class PlannerViewService {
  public dates: Interval;

  constructor(private translate: TranslateService) {}

  /** Возвращает наименование текущего периода. */
  public getPeriodTitle(dates: Interval, planningScale: PlanningScale): string {
    let header = '';

    if (planningScale === PlanningScale.Day) {
      header = dates.start.toFormat('LLLL yyyy');
      header = header.substring(0, 1).toUpperCase() + header.substring(1);
    }

    if (planningScale === PlanningScale.Week) {
      const sh = dates.start.toLocaleString(DateTime.DATE_MED);
      const eh = dates.end.toLocaleString(DateTime.DATE_MED);
      header =
        sh.substring(0, 1).toUpperCase() +
        sh.substring(1) +
        ' - ' +
        eh.substring(0, 1).toUpperCase() +
        eh.substring(1);
    }

    if (planningScale === PlanningScale.Month) {
      const sh = dates.start.toFormat('LLLL yyyy');
      const eh = dates.end.toFormat('LLLL yyyy');
      header =
        sh.substring(0, 1).toUpperCase() +
        sh.substring(1) +
        ' - ' +
        eh.substring(0, 1).toUpperCase() +
        eh.substring(1);
    }

    return header;
  }

  /** Возвращает набор слотов для представления. */
  public getSlots(dates: Interval, planningScale: PlanningScale): Slot[] {
    this.dates = cloneDeep(dates);

    const slots: Slot[] = [];

    let date: DateTime;
    if (planningScale === PlanningScale.Day) {
      date = dates.start;
      while (dates.end.startOf('day') >= date.startOf('day')) {
        slots.push({
          id: Guid.generate(),
          hint: date.toLocaleString(DateTime.DATE_FULL),
          maxDuration: 24,
          date: date.toISODate(),
          stamp: date.toSeconds(),
          header: date.toFormat('dd'),
          today: date.startOf('day').equals(DateTime.now().startOf('day')),
        });

        date = date.plus({ days: 1 });
      }
    }

    if (planningScale === PlanningScale.Week) {
      date = dates.start;

      while (dates.end.startOf('day') >= date.startOf('day')) {
        slots.push({
          id: Guid.generate(),
          hint: date.toLocaleString(DateTime.DATE_FULL),
          stamp: date.toSeconds(),
          date: date.toISODate(),
          maxDuration: 24 * 7,
          header: date.toFormat('dd.LL'),
          today:
            DateTime.now().startOf('day') >= date &&
            DateTime.now().startOf('day') <= date.endOf('week'),
        });

        date = date.plus({ days: 7 });
      }
    }

    if (planningScale === PlanningScale.Month) {
      date = dates.start;
      while (dates.end.startOf('day') >= date.startOf('day')) {
        slots.push({
          id: Guid.generate(),
          hint: date.toFormat('LLLL yyyy'),
          stamp: date.toSeconds(),
          date: date.toISODate(),
          maxDuration: date.endOf('month').day * 24,
          header: date.toFormat('MM.yy'),
          today:
            DateTime.now().startOf('day') >= date &&
            DateTime.now().startOf('day') <= date.endOf('month'),
        });
        date = date.plus({ months: 1 });
      }
    }
    return slots;
  }

  /** Возвращает период для текущих настроек. */
  public getPeriodDates(
    baseDate: DateTime,
    offset: number,
    planningScale: PlanningScale,
  ): Interval {
    let startPeriod: DateTime;
    let endPeriod: DateTime;

    if (planningScale === PlanningScale.Day) {
      const currentMonthFirstDay = baseDate.startOf('month');

      startPeriod = currentMonthFirstDay.plus({ months: offset });

      endPeriod = startPeriod.endOf('month');
    }

    if (planningScale === PlanningScale.Week) {
      const slidePeriod = 4;
      const weeksCountInPeriod = 15;

      const firstDay = baseDate.startOf('week');

      startPeriod = firstDay.plus({ weeks: offset * slidePeriod });

      endPeriod = startPeriod.plus({ weeks: weeksCountInPeriod }).endOf('week');
    }

    if (planningScale === PlanningScale.Month) {
      const slidePeriod = 1;
      const monthsCountInPeriod = 12;

      const firstDay = baseDate.startOf('month');

      startPeriod = firstDay.plus({ months: offset * slidePeriod });

      endPeriod = startPeriod
        .plus({ months: monthsCountInPeriod })
        .endOf('month');
    }

    return Interval.fromDateTimes(startPeriod, endPeriod);
  }

  public getValueModeName(valueMode: ValueMode): string {
    switch (valueMode) {
      case ValueMode.Percentage:
        return this.translate.instant('shared.planner.valueMode.percentage');
      case ValueMode.Hours:
        return this.translate.instant('shared.planner.valueMode.hours');
      case ValueMode.FTE:
        return this.translate.instant('shared.planner.valueMode.fte');
      case ValueMode.Days:
        return this.translate.instant('shared.planner.valueMode.days');
      default:
        return null;
    }
  }

  public getPlanningScaleName(planningScale: PlanningScale): string {
    return this.translate.instant(`enums.planningScale.${planningScale}`);
  }
}
