import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { EmployeeCardService } from './employee-card.service';
import { StateService } from '@uirouter/core';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { UserCertificatesService } from 'src/app/employees/card/employee-main/user-certificates/user-certificates.service';
import { EmployeeMainService } from 'src/app/employees/card/employee-main/employee-main.service';
import { EmployeeTimeOffBalanceService } from 'src/app/employees/card/employee-time-off-balance/employee-time-off-balance.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { User } from 'src/app/shared/models/entities/settings/user.model';

@Component({
  selector: 'tmt-employee-card',
  templateUrl: './employee-card.component.html',
  providers: [
    EmployeeMainService,
    EmployeeCardService,
    EmployeeTimeOffBalanceService,
    UserCertificatesService,
  ],
})
export class EmployeeCardComponent implements OnDestroy, OnInit {
  public tabs: TabLink[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    public state: StateService,
    public service: EmployeeCardService,
  ) {}

  ngOnInit(): void {
    this.service.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (user: User) => {
        const currentStatePrefix = this.state.current.name.split('.')[0];

        this.tabs = [
          {
            state: `${currentStatePrefix}.main`,
            header: 'employees.card.tabs.main',
          },
        ];

        if (user.totalsViewAllowed) {
          this.tabs.push({
            state: `${currentStatePrefix}.experienceOverview`,
            header: 'employees.card.tabs.experienceOverview',
          });
          this.tabs.push({
            state: `${currentStatePrefix}.timeOffBalance`,
            header: 'employees.card.tabs.timeOffBalance',
          });
        }

        if (user.totalsViewAllowed) {
          this.tabs.push({
            state: `${currentStatePrefix}.overview`,
            header: 'employees.card.tabs.overview',
          });
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.service.dispose();
  }
}
