<ui-view></ui-view>

<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-underline mt-3">
  <li [ngbNavItem]="'activity'">
    <a ngbNavLink>
      {{ 'shared.comments.tabs.activity' | translate }}
    </a>
    <div *ngbNavContent class="wide-tab-container mt-3">
      <wp-comments-tab
        [entityId]="project.id"
        [collection]="commentedEntityCollectionType.Projects"
      ></wp-comments-tab>
    </div>
  </li>
  <li [ngbNavItem]="'attachments'">
    <a ngbNavLink>
      {{ 'shared.comments.tabs.attachments' | translate }}
    </a>
    <div *ngbNavContent class="wide-tab-container mt-3">
      <wp-attachments-tab
        [entityId]="project.id"
        [collection]="commentedEntityCollectionType.Projects"
      ></wp-attachments-tab>
    </div>
  </li>
  <li [ngbNavItem]="'linkedTasks'">
    <a ngbNavLink>
      {{ 'base.workflow.tab' | translate }}
    </a>
    <tmt-entity-workflow-tasks
      *ngbNavContent
      [entityId]="project.id"
      class="mt-2 d-block"
    ></tmt-entity-workflow-tasks>
  </li>
  <li [ngbNavItem]="'stateHistory'">
    <a ngbNavLink>
      {{ 'base.stateHistory.tab' | translate }}
    </a>
    <tmt-state-history
      [entityId]="project.id"
      *ngbNavContent
      class="mt-2 d-block"
    ></tmt-state-history>
  </li>
  <wp-sort class="ms-auto"></wp-sort>
</ul>

<div [ngbNavOutlet]="nav"></div>
