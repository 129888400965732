import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';

import { DndZoneDirective } from './shared/dnd-zone.directive';
import { CommentsInputComponent } from './shared/comments-input/comments-input.component';
import { CommentsAttachmentComponent } from './shared/comments-attachment/comments-attachment.component';
import { CommentComponent } from './shared/comment/comment.component';
import { CommentsTabComponent } from './shared/tabs/comments-tab/comments-tab.component';
import { AttachmentsTabComponent } from './shared/tabs/attachments-tab/attachments-tab.component';

@NgModule({
  declarations: [
    CommentsInputComponent,
    DndZoneDirective,
    CommentsAttachmentComponent,
    CommentsTabComponent,
    AttachmentsTabComponent,
    CommentComponent,
  ],
  exports: [CommentsTabComponent, AttachmentsTabComponent],
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
})
export class CommentsModule {}
