<tmt-form-header [name]="invoiceTemplateForm.value.name"></tmt-form-header>
<wp-loading-indicator [state]="state"></wp-loading-indicator>
<tmt-not-found [state]="state"></tmt-not-found>
<wp-data-not-saved-indicator [form]="invoiceTemplateForm"></wp-data-not-saved-indicator>

<form [formGroup]="invoiceTemplateForm" [hidden]="state !== 'Ready'" class="form">
  <div class="row">
    <div class="col-xs-12 col-lg-10">
      <h3 style="margin-top: 15px" class="text-uppercase">
        {{ 'settings.invoiceTemplates.card.props.groupMain' | translate }}
      </h3>
      <hr />
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6 col-lg-4">
      <div class="form-group">
        <label>{{ 'shared.props.name' | translate }}</label>
        <wp-text-box
          formControlName="name"
          placeholder="{{ 'shared.props.name' | translate }}"
        ></wp-text-box>
      </div>

      <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
        <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
        <label class="form-check-label" for="isActive">
          {{ 'shared.props.isActive.label' | translate }}
        </label>
      </div>

      <div class="form-group" style="padding-top: 10px">
        <p *ngIf="invoiceTemplateForm.value.isDefault" class="form-control-static">
          <strong
            ><i aria-hidden="true" class="bi bi-check-circle"></i>&nbsp;{{
              'settings.invoiceTemplates.card.props.usingByDefault' | translate
            }}</strong
          >
        </p>
      </div>
    </div>

    <div class="col-xs-6 col-lg-4"></div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-lg-10">
      <h3 style="margin-top: 15px" class="text-uppercase">
        {{ 'settings.invoiceTemplates.card.props.groupRequisites' | translate }}
      </h3>
      <hr />
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6 col-lg-4">
      <div class="form-group">
        <label>{{ 'settings.invoiceTemplates.card.props.companyName' | translate }}</label>
        <wp-text-box
          formControlName="companyName"
          placeholder="{{ 'settings.invoiceTemplates.card.props.companyName' | translate }}"
        ></wp-text-box>
      </div>

      <div class="form-group">
        <label>{{ 'settings.invoiceTemplates.card.props.companyAddress' | translate }}</label>
        <wp-text-box
          formControlName="companyAddress"
          placeholder="{{ 'settings.invoiceTemplates.card.props.companyAddress' | translate }}"
        ></wp-text-box>
      </div>

      <div class="form-group">
        <label>{{ 'settings.invoiceTemplates.card.props.companyPhone' | translate }}</label>
        <wp-text-box
          formControlName="companyPhone"
          placeholder="{{ 'settings.invoiceTemplates.card.props.companyPhone' | translate }}"
        ></wp-text-box>
      </div>

      <div class="form-group">
        <label>{{ 'settings.invoiceTemplates.card.props.paymentRequisites' | translate }}</label>
        <wp-multiline-text-box
          formControlName="paymentRequisites"
          [rows]="5"
          placeholder="{{ 'settings.invoiceTemplates.card.props.paymentRequisites' | translate }}"
        ></wp-multiline-text-box>
      </div>
    </div>

    <div class="col-xs-6 col-lg-4">
      <div class="form-group" style="vertical-align: bottom">
        <label>{{ 'settings.invoiceTemplates.card.props.logo' | translate }}</label>

        <img
          [hidden]="logoIsLoading || !hasLogo"
          [src]="logo$ | async"
          style="max-width: 250px; display: block"
          class="img-thumbnail"
        />
        <div [hidden]="readonly || hasLogo || logoIsLoading" style="width: 250px">
          <ngx-dropzone
            #drop
            style="height: 130px"
            [multiple]="false"
            accept=".png,.jpg,.jpeg"
            maxFileSize="500000"
            (change)="onFileAdded($event)"
          >
            <ngx-dropzone-label>
              <button class="btn btn-default">
                {{ 'shared.actions.select' | translate }}
              </button>
              <br /><br />
              <span class="text-body-secondary"> {{ 'shared.orDragFileHere' | translate }}</span>
            </ngx-dropzone-label>
          </ngx-dropzone>
          <wp-loading-indicator [loading]="logoIsLoading"></wp-loading-indicator>
          <p class="text-body-secondary">
            {{ 'settings.invoiceTemplates.card.props.logoRequirements' | translate }}
          </p>
        </div>

        <button class="btn btn-default mt-2" (click)="removeLogo()" *ngIf="hasLogo">
          {{ 'settings.invoiceTemplates.card.actions.removeLogo' | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6 col-lg-4">
      <div class="form-group">
        <label>{{ 'settings.invoiceTemplates.card.props.signatureNames' | translate }}</label>
        <wp-multiline-text-box
          formControlName="signatureNames"
          [rows]="4"
          placeholder="{{ 'settings.invoiceTemplates.card.props.signatureNames' | translate }}"
        ></wp-multiline-text-box>
      </div>
    </div>

    <div class="col-xs-6 col-lg-4">
      <div class="form-group">
        <label>{{ 'settings.invoiceTemplates.card.props.signaturePosts' | translate }}</label>
        <wp-multiline-text-box
          formControlName="signaturePosts"
          [rows]="4"
          placeholder="{{ 'settings.invoiceTemplates.card.props.signaturePosts' | translate }}"
        ></wp-multiline-text-box>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-lg-10">
      <h3 style="margin-top: 15px" class="text-uppercase">
        {{ 'settings.invoiceTemplates.card.props.groupFormat' | translate }}
      </h3>
      <hr />
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6 col-lg-4">
      <div class="form-group" style="width: 300px">
        <label>{{ 'settings.invoiceTemplates.card.props.pageSize' | translate }}</label>
        <wp-select-box
          formControlName="pageSize"
          [values]="pageSizes"
          placeholder="{{ 'settings.invoiceTemplates.card.props.pageSize' | translate }}"
        ></wp-select-box>
      </div>
    </div>

    <div class="col-xs-6 col-lg-4">
      <div class="form-group" style="width: 300px">
        <label>{{ 'settings.invoiceTemplates.card.props.pageOrientation' | translate }}</label>
        <wp-select-box
          formControlName="pageOrientation"
          [values]="pageOrientations"
          placeholder="{{ 'settings.invoiceTemplates.card.props.pageOrientation' | translate }}"
        ></wp-select-box>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="useVat">
    <div class="col-xs-12 col-lg-10">
      <h3 style="margin-top: 15px" class="text-uppercase">
        {{ 'settings.invoiceTemplates.card.props.groupTax' | translate }}
      </h3>
      <hr />
    </div>
  </div>

  <div class="form-group" style="width: 300px" *ngIf="useVat">
    <label>{{ 'settings.invoiceTemplates.card.props.vat' | translate }}</label>
    <wp-select-box
      formControlName="vatRate"
      collection="vatRates"
      placeholder="{{ 'settings.invoiceTemplates.card.props.vat' | translate }}"
    ></wp-select-box>
  </div>

  <div class="actions" *ngIf="!readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
