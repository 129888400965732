<wp-form-header
  name="{{ 'projects.analytics.projectSummary' | translate }}"
  [hasAutosave]="false"
  [hasName]="false"
  (reloaded)="service.reload()"
></wp-form-header>

<div
  wpFreezeTable
  leftWidthStrategy="byTable"
  rightWidthStrategy="byTable"
  class="disable-selecting project-summary"
>
  <tmt-project-summary-toolbar name="toolbar"></tmt-project-summary-toolbar>

  <div name="left">
    <div name="scroll-table-header">
      <table class="table wp-nested-table" [ngStyle]="{ width: leftTableWidth + 'px' }">
        <colgroup>
          <!-- Arrow -->
          <col class="column__arrow" />
          <!-- Group Name -->
          <col class="column__group-name" />
          <!-- Subgroup Name -->
          <col class="column__subgroup-name" />
        </colgroup>
        <thead>
          <tr>
            <th class="trim" colspan="2" rowspan="2">
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ 'projects.projectSummary.columns.project' | translate }}</span>
                <button
                  *ngIf="!(service.loading$ | async)"
                  class="btn px-1 py-1"
                  (click)="service.toggleAllProjects()"
                >
                  <small class="toggle-all text-body-secondary">
                    <i
                      class="bi"
                      [class.bi-chevron-double-right]="service.isAllExpanded"
                      [class.bi-chevron-double-down]="!service.isAllExpanded"
                    ></i>
                    {{
                      (service.isAllExpanded
                        ? 'shared.actions.collapseAll'
                        : 'shared.actions.expandAll'
                      ) | translate
                    }}
                  </small>
                </button>
              </div>
            </th>
            <th class="text-center" colspan="3" rowspan="2">Σ</th>
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table wp-nested-table" [ngStyle]="{ width: leftTableWidth + 'px' }">
        <colgroup>
          <col class="column__arrow" />
          <col class="column__group-name" />
        </colgroup>

        @for (summaryPageDto of summaryDataService.summaryPages; track summaryPageDto.id) {
          <tbody tmtProjectSummaryLeftGroup [summaryPageDto]="summaryPageDto"></tbody>
        } @empty {
          <tbody *ngIf="!(service.loading$ | async)">
            <tr class="no-data__row">
              <td [attr.colspan]="2"></td>
            </tr>
          </tbody>
        }
      </table>
    </div>

    <div name="scroll-table-footer"></div>
  </div>

  <div name="right" [ngStyle]="{ 'margin-left': leftTableWidth + 'px' }">
    <div name="scroll-table-header">
      <table
        class="table wp-nested-table"
        [ngStyle]="{ width: service.getDataTableWidth() + 'px' }"
      >
        <colgroup>
          @for (slot of service.slots; track slot.id) {
            <col />
          }
        </colgroup>
        <thead>
          <tr>
            @for (group of service.slotGroups; track group.id) {
              <th [attr.colspan]="group.slotsCount" title="{{ group.hint }}">
                {{ group.header }}
              </th>
            }
          </tr>
          <tr>
            @for (slot of service.slots; track slot.id) {
              <th class="slot" [ngClass]="{ 'slot-active': slot.today }" title="{{ slot.hint }}">
                {{ slot.header }}
              </th>
            }
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table
        class="table wp-nested-table"
        [ngStyle]="{ width: service.getDataTableWidth() + 'px' }"
      >
        <colgroup>
          @for (slot of service.slots; track slot.id) {
            <col />
          }
        </colgroup>

        @for (summaryPageDto of summaryDataService.summaryPages; track summaryPageDto.id) {
          <tbody tmtProjectSummaryRightGroup [summaryPageDto]="summaryPageDto"></tbody>
        } @empty {
          <tbody *ngIf="!(service.loading$ | async)">
            <tr class="no-data__row">
              <td class="text-body-secondary" [attr.colspan]="service?.slots?.length">
                {{ noDataMessageKey | translate | uppercase }}
              </td>
            </tr>
          </tbody>
        }
      </table>
    </div>

    <div name="scroll-table-footer"></div>
  </div>
</div>

<wp-loading-indicator [loading]="service.loading$ | async"></wp-loading-indicator>
