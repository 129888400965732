<tmt-form-header [name]="client?.name" [isEditable]="client?.editAllowed" [saveFn]="saveName">
</tmt-form-header>

<tmt-not-found [state]="service.state$ | async"></tmt-not-found>

<wp-loading-indicator [state]="service.state$ | async"></wp-loading-indicator>

<ng-container *ngIf="(service.state$ | async) === 'Ready'">
  <div class="subheader">
    <div class="subheader__props">
      <div class="subheader__prop">
        <span class="subheader__label"
          >{{ 'projects.clients.card.props.manager' | translate }}:</span
        >
        <div class="subheader__value" id="manager">
          <button type="button" (click)="openUserInfo()" class="btn btn-link btn-link-content">
            {{ client?.manager?.name }}
          </button>
        </div>
      </div>
    </div>
    <div class="subheader__controls">
      <div class="btn-group" role="group">
        <div
          *ngIf="client?.editAllowed"
          title="{{ 'projects.clients.card.states.hint' | translate }}"
          ngbDropdown
          class="btn-group"
          placement="bottom-left"
          style="z-index: 1"
        >
          <button type="button" class="btn btn-default" ngbDropdownToggle>
            {{ getClientStateTitle() | translate }}
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="selectState(true)">
              {{ 'projects.clients.card.states.active' | translate }}
            </button>
            <button ngbDropdownItem (click)="selectState(false)">
              {{ 'projects.clients.card.states.closed' | translate }}
            </button>
          </div>
        </div>

        <button
          *ngIf="!client?.editAllowed"
          type="button"
          title="{{ 'projects.clients.card.states.hint' | translate }}"
          class="btn btn-default bt-info"
        >
          {{ getClientStateTitle() | translate }}
        </button>

        <button
          type="button"
          class="btn btn-default btn-indicator"
          title="{{ 'projects.clients.card.props.actualHours' | translate }}"
        >
          <i class="bi bi-clock" aria-hidden="true"></i>&nbsp;
          <span
            [wpLoadingValue]="(service.clientTotal$ | async)?.actualHours | wpWork: true : true"
            [loading]="!(service.clientTotal$ | async)"
          ></span>
        </button>
      </div>
    </div>
  </div>

  <div style="position: relative">
    <ul class="nav nav-tabs">
      <li class="nav-item" *ngFor="let tab of tabs">
        <a
          class="nav-link"
          [ngClass]="{ active: (uiRouterGlobals.current?.name ?? '').indexOf(tab.state) > -1 }"
          [uiSref]="tab.state"
          [uiParams]="{ routeMode: 'keep' }"
          [attr.data-test]="tab.state"
          >{{ tab.header | translate }}</a
        >
      </li>
    </ul>

    <button type="button" class="btn btn-link settings-menu" (click)="openSettings()">
      {{ 'projects.clients.card.settings' | translate }}
    </button>
    <ui-view></ui-view>
  </div>
</ng-container>
