import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { DateTime } from 'luxon';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import { GridColumnType } from 'src/app/shared/models/inner/grid-column.interface';
import { TotalType } from 'src/app/shared/models/inner/total-type';

@Component({
  selector: 'tmt-grid-test',
  templateUrl: './grid-test.component.html',
  styleUrls: ['./grid-test.component.scss'],
  providers: [GridService],
})
export class GridTestComponent implements OnInit {
  public rows: FormArray = this.fb.array([]);
  public options: GridOptions = {
    css: 'wp-nested-table',

    clientTotals: true,

    view: {
      name: 'test',

      columns: [
        {
          name: 'name',
          header: 'Name',
          hint: 'Name',
          type: GridColumnType.String,
          total: TotalType.Count,
        },
        {
          name: 'value1',
          header: 'Value 1',
          hint: 'Value 1',
          type: GridColumnType.String,
        },
        {
          name: 'value2',
          header: 'Value 2',
          hint: 'Value 2',
          type: GridColumnType.String,
        },
      ],
    },
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    for (let i = 0; i <= 100; i++) {
      this.rows.push(
        this.fb.group({
          name: `Name ${i}`,
          value1: DateTime.now().toISODate(),
          value2: `Value ${i * 1000}`,
        }),
      );
    }
  }
}
