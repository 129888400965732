import { NamedEntity } from '../named-entity.model';
import { Entity } from '../entity.model';
import { TimesheetTemplate } from '../settings/timesheet-template.model';
import { DateHours } from '../date-hours.model';
import { State } from '../state.model';
import { ProjectCostCenter } from 'src/app/projects/card/project-cost-centers/models/project-cost-center.model';
import { ProjectTariff } from 'src/app/projects/card/project-tariffs/models/project-tariff.model';

/** Таймшит. */
export class Timesheet extends NamedEntity {
  /** Draft state Id. */
  static draftId = '827154c8-b827-4ebc-a847-851bd357976f';

  /** Approved state Id. */
  static approvedId = '4b6b3001-0766-41ac-8382-9ec1b85f2265';

  dateFrom: string;
  dateTo: string;
  dueDate: string;
  approved: string;

  user: NamedEntity;
  legalEntity: NamedEntity;
  state: State;
  billableDuration: number;
  nonBillableDuration: number;
  schedule: DateHours[];
  timeSheetLines: TimesheetLine[];
  timeOffRequests: TimeOffRequest[];
  template: TimesheetTemplate;
}

/** Строка таймшита. */
export interface TimesheetLine extends Entity {
  date?: string;
  orderNumber: number;
  rowVersion?: string;
  project: any;
  projectTask: any;

  activity: NamedEntity;
  role: NamedEntity;
  projectCostCenter: ProjectCostCenter;
  projectTariff: ProjectTariff;
  timeAllocations: TimeAllocation[];

  // Customizable properties
  stringValue1?: string;
  stringValue2?: string;
  stringValue3?: string;
  stringValue4?: string;
  stringValue5?: string;

  decimalValue1?: number;
  decimalValue2?: number;
  decimalValue3?: number;
  decimalValue4?: number;
  decimalValue5?: number;

  dateValue1?: string;
  dateValue2?: string;
  dateValue3?: string;
  dateValue4?: string;
  dateValue5?: string;

  lookupValue1?: NamedEntity;
  lookupValue2?: NamedEntity;
  lookupValue3?: NamedEntity;
  lookupValue4?: NamedEntity;
  lookupValue5?: NamedEntity;

  integerValue1?: number;
  integerValue2?: number;
  integerValue3?: number;
  integerValue4?: number;
  integerValue5?: number;
}

/** Распределение времени (ячейка таймшита). */
export class TimeAllocation extends Entity {
  date: string;
  duration: number;
  comments: string;

  // Customizable properties
  stringValue1?: string;
  stringValue2?: string;
  stringValue3?: string;
  stringValue4?: string;
  stringValue5?: string;

  decimalValue1?: number;
  decimalValue2?: number;
  decimalValue3?: number;
  decimalValue4?: number;
  decimalValue5?: number;

  dateValue1?: string;
  dateValue2?: string;
  dateValue3?: string;
  dateValue4?: string;
  dateValue5?: string;

  lookupValue1?: NamedEntity;
  lookupValue2?: NamedEntity;
  lookupValue3?: NamedEntity;
  lookupValue4?: NamedEntity;
  lookupValue5?: NamedEntity;

  integerValue1?: number;
  integerValue2?: number;
  integerValue3?: number;
  integerValue4?: number;
  integerValue5?: number;
}

/** Сведения об отсутствии в таймшите. */
export class TimeOffRequest extends Entity {
  timeOffType: NamedEntity;
  state: State;
  timeAllocations: TimeAllocation[];
}
