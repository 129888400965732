import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { TrendsKpiComponent } from './trends-kpi.component';
import { OverviewSwitcherModule } from 'src/app/shared/components/features/overview/overview-switcher/overview-switcher.module';

@NgModule({
  declarations: [TrendsKpiComponent],
  imports: [CommonModule, SharedModule, OverviewSwitcherModule],
  exports: [TrendsKpiComponent],
})
export class TrendsKpiModule {}
