<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

<form class="modal-form" novalidate [formGroup]="form" ngDraggable [hidden]="isLoading">
  <div class="modal-header">
    <h3 class="modal-title">{{ headerTitle }}</h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" [hidden]="!grantsAreAllowed">
      <li [ngbNavItem]>
        <a ngbNavLink>{{ 'analytics.dashboards.settings.tabs.properties' | translate }}</a>
        <ng-template ngbNavContent>
          <wp-dashboard-properties [form]="form"></wp-dashboard-properties>
        </ng-template>
      </li>
      <li [ngbNavItem] [hidden]="!grantsAreAllowed">
        <a ngbNavLink>{{ 'analytics.dashboards.settings.tabs.access' | translate }}</a>
        <ng-template ngbNavContent>
          <wp-dashboard-access [formArray]="form.controls.grants"></wp-dashboard-access>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" [ngClass]="{ 'pt-3': grantsAreAllowed }"></div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ okTitle }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
