import { Injectable } from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Injectable()
export class TimesheetPeriodsFilterService extends FilterService {
  public override placeholder = 'settings.timesheetPeriods.list.filter';
  public override views = [
    { code: 'all', local: 'shared.filter.allEntities' },
    { code: 'active', local: 'shared.filter.onlyActiveEntities' },
  ];

  public override getODataFilter() {
    const result: any[] = [];

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        or: {
          periodType: { 'tolower(name)': { contains: text } },
          periodScope: { 'tolower(name)': { contains: text } },
        },
      });
    }

    if (this.values.view && this.values.view.code === 'active') {
      result.push({ isActive: true });
    }

    return result;
  }

  protected override getDefaultValues(): any {
    return {
      text: '',
      view: this.views[0],
    };
  }

  public getDatePeriodParams(): void {
    return null;
  }
}
