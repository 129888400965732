<div [hidden]="disabled" class="input-group">
  <input
    #editor
    [type]="type"
    [maxlength]="maxLength"
    class="form-control"
    [formControl]="formControl"
    [attr.title]="getTitle()"
    [attr.placeholder]="placeholder"
    (focus)="onFocus()"
    (blur)="onBlur()"
    autocomplete="wptextbox"
  />
  <span
    #clearBtn
    class="input-clear"
    title="{{ 'shared.hintClearControlButton' | translate }}"
    (click)="clear()"
    [hidden]="!formControl.value"
    >&times;</span
  >
</div>
<p *ngIf="disabled && formControl.value" class="trim" title="{{ formControl.value }}">
  {{ formControl.value }}
</p>
<p *ngIf="disabled && !formControl.value" class="text-body-secondary text-uppercase">
  {{ 'shared.empty' | translate }}
</p>
