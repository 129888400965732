export class DragHandler {
  /* Флаг бы ли нажата ли клавиша мыши */
  mouseDown = false;
  /* Буфер положения мыши */
  mouseX = null;
  mouseY = null;
  /* Флаг происходит ли в данный момент resizing или drag */
  isChanging = false;

  reset = () => {
    this.mouseDown = false;
    this.isChanging = false;
    this.mouseX = null;
    this.mouseY = null;
  };
}
