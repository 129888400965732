import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import _ from 'lodash';
import { NotificationService } from 'src/app/core/notification.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Exception } from 'src/app/shared/models/exception';
import { DataService } from 'src/app/core/data.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectVersionCardService } from '../../core/project-version-card.service';
import { MassOperationHelper } from 'src/app/shared/helpers/mass-operation.helper';
import { ProjectTariffsClientTariffFilterService } from './filter/project-tariffs-client-tariff-filter.service';
import { ProjectTariffsService } from '../services/project-tariffs.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { ClientTariff } from 'src/app/clients/shared/client-tariff.model';
import {
  ProjectTariff,
  ProjectTariffRate,
} from '../models/project-tariff.model';
import {
  TariffRate,
  TariffRatesHelper,
} from 'src/app/shared/modules/tariff-rates/helpers/tariff-rates.helper';

@Component({
  selector: 'tmt-project-tariffs-client-tariff-modal',
  templateUrl: './project-tariffs-client-tariff-modal.component.html',
  styleUrl: './project-tariffs-client-tariff-modal.component.scss',
  providers: [
    {
      provide: FilterService,
      useClass: ProjectTariffsClientTariffFilterService,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectTariffsClientTariffModalComponent implements OnInit {
  @ViewChild('leftTbl') private leftTbl: ElementRef;

  @Input() projectId: string;
  @Input() organizationId: string;

  public isSaving$ = new BehaviorSubject<boolean>(false);
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public leftTableStyles: Record<string, string> = {};
  public availableTariffs: ClientTariff[] = [];
  public selectedTariffs: ClientTariff[] = [];

  private destroyed$ = new Subject<void>();

  constructor(
    private filterService: FilterService,
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private dataService: DataService,
    private projectVersionCardService: ProjectVersionCardService,
    private projectTariffsService: ProjectTariffsService,
  ) {}

  ngOnInit(): void {
    this.loadTariffsFromOrganization();

    this.filterService.values$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.loadTariffsFromOrganization());
  }

  /* Resizes nested table. */
  public resizeLeftTbl(): void {
    this.leftTableStyles['display'] = 'table';
    this.leftTableStyles['width'] =
      (<HTMLElement>this.leftTbl.nativeElement).getBoundingClientRect().width +
      'px';
  }

  /**
   * Adds tariff to selected tariffs list.
   *
   * @param tariff tariff for adding to selected tariffs list.
   * @param index tariff's index for removing from available tariffs list.
   */
  public selectTariff(tariff: ClientTariff, index: number): void {
    this.selectedTariffs.push(tariff);
    this.selectedTariffs = _.sortBy(this.selectedTariffs, ['name']);
    this.availableTariffs.splice(index, 1);
  }

  /**
   * Removes tariff from selected tariffs list.
   *
   * @param tariff tariff for adding to available tariffs list.
   * @param index tariff's index for removing from selected tariffs list.
   */
  public removeTariff(tariff: ClientTariff, index: number): void {
    this.availableTariffs.push(tariff);
    this.selectedTariffs.splice(index, 1);

    this.availableTariffs = _.sortBy(this.availableTariffs, ['name']);
  }

  /* Adds tariffs to project from organization. */
  public addTariffsFromOrganization(): void {
    const tariffs: ProjectTariff[] = [];

    this.selectedTariffs.forEach((tariff: ClientTariff) => {
      const projectTariff: Partial<ProjectTariff> = {
        id: Guid.generate(),
        name: tariff.name,
        description: tariff.description,
        isActive: tariff.isActive,
        assignments: [],
        rates: tariff.rates.map((rate) => {
          const newRate: Partial<ProjectTariffRate> = {
            ...rate,
            id: Guid.generate(),
          };

          return newRate as ProjectTariffRate;
        }),
      };

      ProjectVersionUtil.setEntityRootPropertyId(
        this.projectVersionCardService.projectVersion,
        projectTariff,
        this.projectId,
      );

      tariffs.push(projectTariff as ProjectTariff);
    });

    this.isSaving$.next(true);

    const massOperationHelper = new MassOperationHelper(
      tariffs.map((tariff: ProjectTariff) =>
        this.dataService.collection('ProjectTariffs').insert(tariff),
      ),
      { takeUntil: takeUntil(this.destroyed$) },
    );
    massOperationHelper
      .start()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((progress) => {
        if (progress === 100) {
          const successActionsCount = massOperationHelper.completedCount;
          if (massOperationHelper.errors.length) {
            if (
              successActionsCount &&
              massOperationHelper.errors.find(
                (error) =>
                  error.result.code === Exception.Common_NameMustBeUnique.code,
              )
            ) {
              this.notificationService.warningLocal(
                'projects.projects.tariffs.addClientsTariffsModal.messages.addedOnlyUnique',
              );
            }

            const uniqueErrors = new Set();
            massOperationHelper.errors.forEach((error) => {
              uniqueErrors.add(error.result?.message || 'shared.unknownError');
            });
            uniqueErrors.forEach((error: string) => {
              this.notificationService.error(error);
            });
          } else if (successActionsCount === tariffs.length) {
            this.notificationService.successLocal(
              'projects.projects.tariffs.addClientsTariffsModal.messages.added',
            );
          }
          this.activeModal.close();
          this.isSaving$.next(false);
        }
      });
  }

  private getCurrencyCode(actualRate: TariffRate): string | null {
    return (
      this.projectTariffsService.currencies.find(
        (c) => c.id === actualRate?.currencyId,
      )?.alpha3Code ?? null
    );
  }

  /* Loads organization's tariffs from API. */
  private loadTariffsFromOrganization(): void {
    this.availableTariffs = [];
    this.isLoading$.next(true);

    const query: Record<string, any> = {
      select: ['id', 'name', 'isActive', 'description'],
      expand: {
        rates: {
          select: ['id', 'value', 'effectiveDate', 'currencyId'],
        },
      },
      filter: this.filterService.getODataFilter(),
    };

    this.dataService
      .collection('Organizations')
      .entity(this.organizationId)
      .collection('OrganizationTariffs')
      .query<ClientTariff[]>(query)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data: ClientTariff[]) => {
          this.availableTariffs = data;
          this.availableTariffs.forEach((tariff: ClientTariff) => {
            const actualRate = TariffRatesHelper.getActualRate(tariff.rates);
            Object.defineProperty(tariff, 'actualRate', {
              enumerable: false,
              writable: true,
              value: {
                value: actualRate.value,
                currencyCode: this.getCurrencyCode(actualRate),
                effectiveDate: actualRate.effectiveDate,
              },
            });
          });
          this.isLoading$.next(false);
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isLoading$.next(false);
        },
      });
  }

  /* Closes modal. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
