import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'wp-text-box-test',
  templateUrl: './text-box-test.component.html',
})
export class TextBoxTestComponent {
  form = this.fb.group({
    text: ['', Validators.required],
  });

  public formInputParams = this.fb.group({
    readonly: [false],
  });

  constructor(private fb: UntypedFormBuilder) {}

  setValue() {
    this.form.setValue({
      text: 'Test 1',
    });
  }
}
