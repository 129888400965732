<form class="modal-form" novalidate [formGroup]="creationForm" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.rateMatrices.creation.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group" tmtIndicator>
      <label class="control-label">{{ 'shared.props.name' | translate }}</label>
      <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}" />
    </div>

    <div class="form-group" tmtIndicator style="width: 300px">
      <label class="control-label">{{
        'settings.rateMatrices.card.rateMatrixType' | translate
      }}</label>
      <wp-select-box
        formControlName="type"
        [collection]="'RateMatrixTypes'"
        placeholder="{{ 'settings.rateMatrices.card.rateMatrixType' | translate }}"
      />
    </div>

    <div class="form-group">
      <label class="control-label">{{ 'shared.columns.effectiveDate.title' | translate }}</label>
      <wp-date-box formControlName="effectiveDate" />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
