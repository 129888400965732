<form class="modal-form" novalidate [formGroup]="creationForm" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ getHeader() | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group" tmtIndicator>
      <label class="control-label">{{ 'shared2.props.name' | translate }}</label>
      <wp-text-box formControlName="name" placeholder="{{ 'shared2.props.name' | translate }}" />
    </div>

    <div class="form-group w-150" tmtIndicator>
      <label>{{ 'shared2.props.code' | translate }}</label>
      <wp-text-box formControlName="code" placeholder="{{ 'shared2.props.code' | translate }}" />
    </div>

    <div class="form-group">
      <label>{{ 'shared2.props.description' | translate }}</label>
      <wp-multiline-text-box
        formControlName="description"
        placeholder="{{ 'shared2.props.description' | translate }}"
      />
    </div>

    <div class="form-group d-flex">
      <div class="form-check" title="{{ 'shared2.props.isActive.hint' | translate }}">
        <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
        <label class="form-check-label" for="isActive">
          {{ 'shared2.props.isActive.value' | translate }}
        </label>
      </div>

      @if (creationForm.getRawValue().isSystem) {
        <p><i class="bi bi-gear ms-5"></i>&nbsp;{{ 'shared2.props.isSystem' | translate }}</p>
      }
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
      [isDisabled]="creationForm.value.isSystem"
    >
      {{ getActionBtnName() | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared2.actions.cancel' | translate }}
    </button>
  </div>
</form>
