import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { ActivityCardComponent } from './card/activity-card.component';
import { ActivityListComponent } from './list/activity-list.component';
import { ActivityCreationComponent } from './creation/activity-creation.component';
import { ActivityList } from 'src/app/shared/lists/activity.list';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    ActivityCardComponent,
    ActivityListComponent,
    ActivityCreationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.activities',
          url: '/activities',
          component: ActivityListComponent,
          params: {
            navigation: 'settings.activities',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: ActivityList },
          ],
        },

        {
          name: 'settings.activity',
          params: {
            navigation: 'settings.activities',
          },
          url: '/activities/{entityId:guid}',
          component: ActivityCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class ActivitiesModule {}
