import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  DestroyRef,
  inject,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { BookingEntry } from 'src/app/shared/models/entities/resources/booking-entry.model';

import { BookingService } from 'src/app/booking/booking/core/booking.service';
import { BookingDataService } from 'src/app/booking/booking/core/booking-data.service';
import { ProjectItem } from 'src/app/booking/booking/shared/booking-project-add/booking-project-add.interface';
@Component({
  selector: 'tmt-booking-project-add',
  templateUrl: './booking-project-add.component.html',
  styleUrls: ['./booking-project-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingProjectAddComponent implements OnInit {
  @Input() public resource: NamedEntity;
  @Input() public popupId: string;

  public ready = false;
  public pending = false;
  public isShowAlert = false;
  public projects: ProjectItem[] = [];
  public form = this.fb.group({
    projects: [null],
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    public bookingService: BookingService,
    public bookingDataService: BookingDataService,
    private infoPopupService: InfoPopupService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.form.controls['projects'].valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: ProjectItem) => {
        this.isShowAlert = value === null ? false : !value.hasTeamMember;
        this.cdr.markForCheck();
      });

    this.getProjects();
  }

  /** Create booking entry */
  public addBookingEntry(): void {
    this.pending = true;

    this.bookingDataService
      .createBookingEntry(
        this.resource.id,
        this.getControlValue().id,
        this.bookingService.settings.planningScale,
      )
      .subscribe({
        next: (result: BookingEntry) => {
          result.project ||= this.getControlValue();
          this.pending = false;
          this.cdr.markForCheck();
          this.bookingService.addNewBookingEntry(result);
          this.cancel();
        },
        error: (error) => {
          this.pending = false;
          this.cdr.markForCheck();
          this.bookingService.errorHandlerOnSave(
            error,
            this.resource,
            this.getControlValue(),
          );
        },
      });
  }

  /** Close popup */
  public cancel(): void {
    this.infoPopupService.close(this.popupId);
  }

  /** Get selected project from select-box */
  public getControlValue(): ProjectItem {
    return this.form.controls['projects'].value;
  }

  private getProjects(): void {
    this.bookingDataService
      .getAvailableProjects(this.resource.id)
      .subscribe((result: ProjectItem[]) => {
        this.projects = result;
        this.ready = true;
        this.cdr.markForCheck();
        this.infoPopupService.update();
      });
  }
}
