import {
  Component,
  OnInit,
  Input,
  DestroyRef,
  inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Constants } from 'src/app/shared/globals/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationService } from 'src/app/core/navigation.service';
import { Competence } from 'src/app/shared/models/entities/settings/competence.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'tmt-competence-card',
  templateUrl: './competence-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetenceCardComponent implements OnInit {
  @Input() entityId: string;
  public readonly: boolean;
  public competenceForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    roleId: ['', Validators.required],
    code: ['', Validators.maxLength(Constants.formCodeMaxLength)],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: false,
  });
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public isSaving$ = new BehaviorSubject<boolean>(false);

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private actionService: ActionPanelService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
    ]);

    this.load();

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  /** Saves competence. */
  public save(): void {
    this.competenceForm.markAllAsTouched();

    if (this.competenceForm.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving$.next(true);
    this.actionService.action('save').start();

    this.dataService
      .collection('Competences')
      .entity(this.entityId)
      .patch(this.competenceForm.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.competenceForm.markAsPristine();
          this.isSaving$.next(false);
          this.actionService.action('save').stop();
          this.notificationService.successLocal(
            'settings.competences.card.messages.saved',
          );
        },
        error: (error: any) => {
          this.isSaving$.next(false);
          this.actionService.action('save').stop();
          this.notificationService.error(error.message);
        },
      });
  }

  /* Loads competences list. */
  private load(): void {
    this.state$.next(CardState.Loading);
    this.competenceForm.markAsPristine();
    this.competenceForm.markAsUntouched();
    this.dataService
      .collection('Competences')
      .entity(this.entityId)
      .get<Competence>()
      .subscribe({
        next: (competence: Competence) => {
          this.competenceForm.patchValue(competence);
          this.readonly = !competence.editAllowed;
          this.actionService.action('save').isShown = !this.readonly;
          this.readonly
            ? this.competenceForm.disable()
            : this.competenceForm.enable();
          this.state$.next(CardState.Ready);

          this.navigationService.addRouteSegment({
            id: competence.id,
            title: competence.name,
          });
        },
        error: (error: any) => {
          this.state$.next(CardState.Error);
          this.notificationService.error(error.message);
        },
      });
  }

  /* Reloads view. */
  private reload(): void {
    if (!this.competenceForm.dirty) {
      this.load();
    } else {
      this.messageService
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }
}
