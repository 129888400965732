export enum Language {
  ru = 'Ru',
  en = 'En',
}

export interface LocalString {
  language: Language;
  value: string;
}

// TODO: remove this after OData select fix
export class LocalStringHelper {
  /**
   * Gets translate from `LocalString`.
   *
   * @param translates translates collection.
   * @param language current system language.
   * @returns translate or `null`.
   */
  public static getTranslate(
    translates: LocalString[],
    language: string,
  ): string | undefined {
    return translates.find((translate) =>
      language.toLowerCase().includes(translate.language.toLowerCase()),
    )?.value;
  }
}
