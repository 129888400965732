import { Injectable, Type } from '@angular/core';
import { TimeOffScheduleFiltersComponent } from './time-off-schedule-filters.component';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';

@Injectable()
export class TimeOffScheduleFiltersService extends FilterService {
  public override component: Type<FilterDetails> =
    TimeOffScheduleFiltersComponent;

  public override hasDetails = true;
  public override hasViewSelector = false;
  public override storageName = 'time-off-schedule';
  public override placeholder = 'team.timeOffSchedule.filters.text.placeholder';

  protected override getDefaultValues = (): any => ({
    department: null,
    onlySubordinates: false,
    level: null,
    location: null,
    resourcePool: null,
    text: '',
  });

  public formatTextValue(text: string): string | null {
    const clearText = this.getClearText(text);
    return clearText.length >= this.minStringLengthForFilter ? clearText : null;
  }
}
