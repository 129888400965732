<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ getHeader() | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <form class="form" [formGroup]="form">
      <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs mb-15">
        <li [ngbNavItem]>
          <a ngbNavLink>{{ 'settings.workflows.card.function.tabs.main' | translate }}</a>
          <ng-template ngbNavContent>
            <div class="form-group w-75" tmtIndicator>
              <label>{{ 'settings.workflows.card.function.name' | translate }}</label>
              <wp-text-box
                formControlName="name"
                placeholder="{{ 'settings.workflows.card.function.name' | translate }}"
              ></wp-text-box>
            </div>

            <div class="form-group" *ngIf="this.workflowFunction?.type === functionType.task">
              <label>
                {{ 'settings.workflows.card.function.nameLocalization' | translate }}
              </label>
              <wp-naming-localization [labelStrings]="labelStrings"></wp-naming-localization>
            </div>

            <div
              class="form-group w-75"
              tmtIndicator
              *ngIf="this.workflowFunction?.type === functionType.start"
            >
              <wp-workflow-next-function
                [functionId]="workflowFunction.id"
                [nextFunctionId]="nextFunctionId"
              >
              </wp-workflow-next-function>
            </div>
            <div
              class="form-group w-75"
              tmtIndicator
              *ngIf="this.workflowFunction?.type === functionType.task"
            >
              <label class="group m-0">{{
                'settings.workflows.card.function.performers' | translate
              }}</label>
              <wp-performers
                class="p-0"
                [performerLines]="form.controls.performers"
                [performerTypes]="['role', 'group', 'user']"
                [readonly]="false"
                [lifecycleId]="lifecycleId"
                [performerTypeName]="performerTypeNames.workflowFunction"
              ></wp-performers>
            </div>

            <div class="form-group w-75" *ngIf="this.workflowFunction?.type === functionType.task">
              <label class="group m-0">{{
                'settings.workflows.card.function.actions' | translate
              }}</label>
              <wp-workflow-actions
                class="p-0"
                [actions]="workflowFunctionTask.actions"
                [formGroup]="actionsFormGroup"
              >
              </wp-workflow-actions>
            </div>

            <div
              class="form-group"
              tmtIndicator
              *ngIf="workflowFunction?.type === functionType.task"
            >
              <label class="group m-0">{{
                'settings.workflows.card.function.transitions' | translate
              }}</label>

              <wp-workflow-transitions
                class="p-0"
                [actions]="actionsFormGroup.value"
                [functionId]="workflowFunction.id"
                [transitions]="workflowFunctionTask.transitions"
                [transitionsArray]="transitionsFormArray"
              >
              </wp-workflow-transitions>
            </div>

            <div
              class="form-group w-50"
              tmtIndicator
              *ngIf="this.workflowFunction?.type === functionType.start"
            >
              <label>{{ 'settings.workflows.card.function.moveToState' | translate }}</label>
              <wp-select-box
                formControlName="moveToStateId"
                [isIdMode]="true"
                [values]="states$"
                placeholder="{{ 'settings.workflows.card.function.moveToState' | translate }}"
              >
              </wp-select-box>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem] *ngIf="workflowFunction?.type === functionType.task">
          <a ngbNavLink>{{ 'settings.workflows.card.function.tabs.skipTasks' | translate }}</a>
          <ng-template ngbNavContent>
            <ng-container formArrayName="conditionsForSkipping">
              <label class="group my-2">
                {{ 'settings.workflows.card.function.skipConditionHeader' | translate }}
              </label>

              <p
                class="text-uppercase text-body-secondary my-4"
                *ngIf="conditionsForSkipping.length == 0"
              >
                {{ 'settings.workflows.card.function.noConditions' | translate }}
              </p>

              <ng-container
                *ngFor="
                  let conditionsForSkippingForm of conditionsForSkipping.controls;
                  let i = index
                "
              >
                <p class="or-label text-uppercase my-2" *ngIf="i > 0">
                  {{ 'settings.workflows.card.function.or' | translate }}
                </p>
                <div class="condition" [formGroup]="conditionsForSkippingForm">
                  <div class="row">
                    <div class="d-flex flex-column">
                      <div class="d-flex align-items-baseline justify-content-between my-2">
                        <wp-select-box
                          class="condition-select"
                          [values]="workflowFunctions"
                          placeholder="{{
                            'settings.workflows.card.function.function' | translate
                          }}"
                          formControlName="functionId"
                        ></wp-select-box>
                        <p class="m-0 p-0 my-2">
                          {{ 'settings.workflows.card.function.withResult' | translate }}
                        </p>
                        <wp-select-box
                          class="condition-select"
                          [values]="getActions(conditionsForSkippingForm.value.functionId)"
                          placeholder="{{ 'settings.workflows.card.function.action' | translate }}"
                          formControlName="actionId"
                        ></wp-select-box>
                        <i class="remove-condition bi bi-trash3" (click)="removeCondition(i)"></i>
                      </div>
                      <div class="form-group form-check my-2" tmtIndicator>
                        <input
                          formControlName="considerEntityVersion"
                          type="checkbox"
                          class="form-check-input"
                          id="{{ 'considerEntityVersion ' + i }}"
                        />
                        <label for="{{ 'considerEntityVersion ' + i }}">{{
                          'settings.workflows.card.function.notChanged' | translate
                        }}</label>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <button type="button" class="btn btn-link px-0" (click)="addSkipCondition()">
              {{ 'settings.workflows.card.function.addCondition' | translate }}
            </button>
            <label class="group my-2">
              {{ 'settings.workflows.card.function.or' | translate }}
            </label>
            <div class="form-group form-check">
              <input
                formControlName="skipIfPerformerIsInitiator"
                type="checkbox"
                class="form-check-input"
                id="isInitiator"
              />
              <label for="isInitiator">{{
                'settings.workflows.card.function.isInitiator' | translate
              }}</label>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      style="width: 75px"
      wpButtonSpinner
    >
      {{ 'shared.actions.ok' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
