import { Component, OnInit, Input, Inject } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { ViewSettings } from '../../shared/models/view-settings/view-settings.model';
import { ReportUserSettingsService } from './report-user-settings/report-user-settings.service';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { ReportCardService } from './report-card.service';
import { PivotTableService } from '../../shared/pivot-table/pivot-table.service';
import { PivotRenderService } from '../../shared/pivot-table/pivot-render.service';
import { PivotDataService } from '../../shared/pivot-table/pivot-data.service';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { ReportPeriodService } from '../../shared/report-period/report-period.service';
import { UserSettings } from 'src/app/analytics/shared/models/user-settings.model';

@Component({
  selector: 'wp-report-card',
  templateUrl: './report-card.component.html',
  providers: [
    FreezeTableService,
    AutosaveService,
    ReportPeriodService,
    ReportUserSettingsService,
    ReportCardService,
    PivotRenderService,
    PivotDataService,
    PivotTableService,
  ],
})
export class ReportCardComponent implements OnInit {
  public buildMode: boolean;
  public readonly: boolean;
  public isRunShown: boolean;

  public readyToRun: boolean;
  public hasNotColumns: boolean;
  public periodIsRequired: boolean;

  constructor(
    @Inject('entityId') public entityId: string,
    private hotkeysService: HotkeysService,
    public service: ReportCardService,
    public reportUserSettingsService: ReportUserSettingsService,
    private app: AppService,
    private data: DataService,
    private actionService: ActionPanelService,
    public autosave: AutosaveService,
    public pivotTableService: PivotTableService,
  ) {
    this.hotkeysService.add(
      new Hotkey('f8', (event: KeyboardEvent): boolean => {
        if (this.isRunShown) {
          this.reRun();
          return false;
        }
      }),
    );
  }

  public saveName = (name: string) =>
    this.data
      .collection('Reports')
      .entity(this.service.reportId)
      .patch({ name });

  public reRun() {
    this.reportUserSettingsService.save().then(
      () => {
        this.isRunShown = false;
        this.pivotTableService.run();
      },
      () => null,
    );
  }

  public reload() {
    this.reportUserSettingsService.save().then(
      () => {
        this.service.load(this.entityId);
      },
      () => null,
    );
  }

  private updateUI(viewSettings: ViewSettings) {
    this.periodIsRequired =
      this.service.sourceDescription &&
      this.service.sourceDescription.isTimeLine &&
      this.service.sourceDescription.hasPeriod &&
      !this.reportUserSettingsService.userSettings.period &&
      this.reportUserSettingsService.userSettings.filters?.length === 0;

    this.readyToRun =
      !!this.service.report.reportType &&
      (!this.periodIsRequired ||
        this.reportUserSettingsService.userSettings.period);

    this.hasNotColumns =
      !viewSettings ||
      ((viewSettings.columnFields?.length ?? 0) === 0 &&
        (viewSettings.valueFields?.length ?? 0) === 0 &&
        (viewSettings.columnGroupFields?.length ?? 0) === 0 &&
        (viewSettings.rowGroupFields?.length ?? 0) === 0);

    this.actionService.action('settings').isShown =
      this.service.report.editAllowed;

    this.actionService.action('saveAs').isShown =
      this.readyToRun &&
      this.app.checkPermission('Report', 'My', PermissionType.Modify);

    this.actionService.action('downloads').isShown =
      !this.buildMode || this.readyToRun;
    this.actionService.action('delete').isShown =
      !this.buildMode && this.service.report.deleteAllowed;
  }

  ngOnInit() {
    this.buildMode = !this.entityId;

    // Установка главного меню.
    this.actionService.set([
      {
        title: 'analytics.reports.card.actions.settings.title',
        hint: 'analytics.reports.card.actions.settings.hint',
        name: 'settings',
        iconClass: 'bi bi-table',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.service.openSettings(),
      },
      {
        name: 'downloads',
        title: null,
        hint: null,
        iconClass: 'bi bi-download',
        isDropDown: true,
        isBusy: false,
        isVisible: false,
        actions: [
          {
            name: 'downloadExcelReport',
            title: 'analytics.reports.card.actions.downloadExcelReport.title',
            hint: 'analytics.reports.card.actions.downloadExcelReport.title',
            isVisible: true,
            isBusy: false,
            handler: () => this.service.downloadExcelPivot(),
          },
          {
            name: 'downloadExcelSource',
            title: 'analytics.reports.card.actions.downloadExcelSource.title',
            hint: 'analytics.reports.card.actions.downloadExcelSource.title',
            isBusy: false,
            isVisible: true,
            handler: () => this.service.downloadExcelSource(),
          },
        ],
      },
      {
        name: 'saveAs',
        title: 'analytics.reports.card.actions.saveAs.title',
        hint: 'analytics.reports.card.actions.saveAs.hint',
        isDropDown: false,
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.service.saveAs(),
      },
      {
        name: 'delete',
        title: 'analytics.reports.card.actions.delete.title',
        hint: 'analytics.reports.card.actions.delete.title',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.service.delete(),
      },
    ]);

    this.service.load(this.entityId);

    this.service.report$.subscribe((report) => {
      this.isRunShown = false;
      const viewSettings = JSON.parse(report.viewSettings);

      if (report.reportType) {
        this.reportUserSettingsService.init(
          report.id,
          JSON.parse(report.userSettings),
        );

        this.pivotTableService.initForReport(
          report.id,
          viewSettings,
          this.reportUserSettingsService.userSettings,
        );
      } else {
        this.service.openSettings();
      }

      this.updateUI(viewSettings);

      if (this.readyToRun) {
        this.pivotTableService.run();
      }

      this.reportUserSettingsService.changes$.subscribe(() => {
        this.updateUI(viewSettings);
        this.isRunShown = true;
      });
    });

    this.pivotTableService.sorting$.subscribe((sorting) => {
      this.reportUserSettingsService.saveSorting(sorting);
    });

    this.pivotTableService.resizing$.subscribe((param) => {
      this.reportUserSettingsService.resizeColumn(param[0], param[1]);
    });
  }
}
