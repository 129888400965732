import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { ProjectTariffsClientTariffFilterComponent } from './project-tariffs-client-tariff-filter.component';

@Injectable()
export class ProjectTariffsClientTariffFilterService extends FilterService {
  public override component: Type<FilterDetails> =
    ProjectTariffsClientTariffFilterComponent;
  public override hasViewSelector = false;
  public override placeholder =
    'projects.projects.tariffs.addClientsTariffsModal.filter.ph';

  protected override getDefaultValues(): Record<string, any> {
    return {
      text: '',
      isActive: true,
    };
  }

  public override getODataFilter(): Record<string, any>[] {
    const result: Record<string, any>[] = [
      {
        isActive: true,
      },
    ];

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text.trim());

      result.push({
        or: [
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(name)': { contains: text } },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(description)': { contains: text } },
        ],
      });
    }
    return result;
  }
}
