<div [formGroup]="form" ngbDropdown class="d-inline-block">
  <button
    type="button"
    class="btn btn-default without-caret"
    ngbDropdownToggle
    title="{{ 'projects.projectSummary.filter.properties.viewSettings.title' | translate }}"
  >
    <i class="bi bi-eye" aria-hidden="true"></i>
  </button>
  <div ngbDropdownMenu class="p-3">
    @for (control of kpiControls; track control) {
      <div class="form-check">
        <input
          [id]="control"
          [formControlName]="control"
          class="form-check-input"
          type="checkbox"
        />
        <label [for]="control" class="form-check-label text-nowrap">
          {{ 'projects.projectSummary.filter.properties.viewSettings.' + control | translate }}
        </label>
      </div>
    }
  </div>
</div>
