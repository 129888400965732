import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { Guid } from 'src/app/shared/helpers/guid';
import { AuthProviderType } from 'src/app/shared/models/enums/auth-provider-type.enum';
import { DataService } from 'src/app/core/data.service';
import { AuthProvider } from 'src/app/shared/models/entities/settings/auth-provider.model';
import { NotificationService } from 'src/app/core/notification.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { AppService } from 'src/app/core/app.service';

@Component({
  selector: 'wp-settings-auth-create-provider-modal',
  templateUrl: './settings-auth-create-provider-modal.component.html',
  styleUrls: ['./settings-auth-create-provider-modal.component.scss'],
})
export class SettingsAuthCreateProviderModalComponent implements OnInit {
  public providerName: string;
  public isSaving: boolean;

  public providerToEdit = null;

  public providerForm = this.formBuilder.group({
    id: Guid.generate(),
    type: AuthProviderType.OpenId,
    name: ['', [Validators.required]],
    openIdConfiguration: this.formBuilder.group({
      authority: ['', [Validators.required]],
      clientId: ['', [Validators.required]],
      clientSecret: ['', [Validators.required]],
    }),
  });

  public isOpenIdConfVisible =
    this.providerForm.value.type === AuthProviderType.OpenId;

  public readonly: boolean;
  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private app: AppService,
  ) {}

  public cancel() {
    this.activeModal.dismiss();
  }

  public ok() {
    if (this.readonly) {
      this.activeModal.dismiss();
      return;
    }
    this.providerForm.markAllAsTouched();
    if (!this.providerForm.valid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }
    const authProvider = {
      id: this.providerForm.value.id,
      type: this.providerForm.value.type,
      name: this.providerForm.value.name,
      openIdConfiguration: JSON.stringify({
        authority: this.providerForm.value.openIdConfiguration.authority,
        clientId: this.providerForm.value.openIdConfiguration.clientId,
        clientSecret: this.providerForm.value.openIdConfiguration.clientSecret,
      }),
    };
    this.isSaving = true;
    if (!this.providerToEdit) {
      this.data
        .collection('AuthProviders')
        .insert(authProvider)
        .subscribe(() => {
          this.isSaving = false;
          this.activeModal.close();
        });
      return;
    }
    this.data
      .collection('AuthProviders')
      .entity(this.providerToEdit.id)
      .update(authProvider)
      .subscribe(() => {
        this.isSaving = false;
        this.activeModal.close();
      });
  }

  ngOnInit(): void {
    this.readonly = !this.app.checkPermission(
      'AuthProvider',
      'All',
      PermissionType.Modify,
    );

    if (this.readonly) {
      this.providerForm.disable();
    }

    if (this.providerToEdit) {
      this.providerForm.setValue(this.providerToEdit);
      this.providerName = this.providerToEdit.name;
    }

    this.providerForm.controls.type.valueChanges.subscribe((providerType) => {
      const opedIdControls =
        this.providerForm.controls.openIdConfiguration.controls;

      this.isOpenIdConfVisible = providerType === AuthProviderType.OpenId;

      if (providerType === AuthProviderType.OpenId) {
        Object.values(opedIdControls).forEach((control) => {
          control.setValidators(Validators.required);
        });
      } else {
        Object.values(opedIdControls).forEach((control) => {
          control.removeValidators(Validators.required);
        });
      }
    });
  }
}
