<div class="group d-flex flex-nowrap">
  <button
    class="btn btn-default"
    title="{{ 'shared.undoRedo.buttons.undoTitle' | translate }} {{ hotkey.undo | hotkey: true }}"
    (click)="service.undoRequest()"
    data-test="undo"
  >
    <i class="bi bi-arrow-90deg-left"></i>
  </button>

  <button
    class="btn btn-default"
    title="{{ 'shared.undoRedo.buttons.redoTitle' | translate }} {{ hotkey.redo | hotkey: true }}"
    (click)="service.redoRequest()"
    data-test="redo"
  >
    <i class="bi bi-arrow-90deg-right"></i>
  </button>
</div>
