import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { TimeOffRequest } from 'src/app/shared/models/entities/base/time-off-request.model';

@Component({
  selector: 'wp-intersected-timesheets-modal',
  templateUrl: './intersected-timesheets-modal.component.html',
})
export class IntersectedTimesheetsComponent implements OnInit {
  @Input() request: TimeOffRequest;
  @Input() error: Exception;

  public isLoading = true;
  public timesheets: any[] = [];

  constructor(
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
    private data: DataService,
  ) {}

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  private load() {
    this.data
      .collection('TimeSheets')
      .query({
        select: ['id', 'name'],
        expand: ['state'],
        filter: {
          state: {
            code: { ne: 'Draft' },
          },

          userId: { type: 'guid', value: this.request.user.id },
          dateFrom: { le: { type: 'raw', value: this.request.finishDate } },
          dateTo: { ge: { type: 'raw', value: this.request.startDate } },
        },
      })
      .subscribe({
        next: (timesheets: any[]) => {
          this.isLoading = false;
          this.timesheets = timesheets;
        },
        error: (error: Exception) => {
          this.isLoading = false;
          this.notification.error(error.message);
        },
      });
  }

  ngOnInit(): void {
    this.load();
  }
}
