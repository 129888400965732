@if (list.length) {
  <div
    class="tree-list"
    (keydown)="onKeyDown($event)"
    (blur)="this.dropSelectedIndex()"
    (focus)="onFocus($event)"
    tabindex="0"
  >
    <ng-container *ngTemplateOutlet="elements; context: { $implicit: list }" />
  </div>

  <ng-template #elements let-data>
    @for (item of data; track item) {
      <div
        [class]="itemClass"
        [class.focused]="item.id === focusedItem?.id"
        [class.active]="item.id === selectedItem?.id"
        [id]="'item-' + item.id"
        [attr.data-id]="item.id"
        [style.padding-left]="item.level * padding + 'px'"
        (mouseenter)="focusItem(item)"
      >
        <button class="btn" [class.hidden]="!item.list?.length" (click)="toggleList(item)">
          <i
            class="bi"
            [class.bi-chevron-right]="!item.isExpanded"
            [class.bi-chevron-down]="item.isExpanded"
          >
          </i>
        </button>

        <div class="tree-list__title trim" (click)="onClick($event, item)">
          {{ item.name }}
        </div>
      </div>

      @if (item.list?.length && item.isExpanded) {
        <ng-container *ngTemplateOutlet="elements; context: { $implicit: item.list }" />
      }
    }
  </ng-template>
} @else {
  <div class="p-1 text-center">{{ 'shared.noOptions' | translate }}</div>
}
