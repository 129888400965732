import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Subject, Subscription } from 'rxjs';
import {
  ResourceViewGroup,
  ResourceViewGroupLine,
  ResourceViewGroupLineEntry,
  ResourceViewGroupLineTotal,
  ResourceViewTeamMember,
} from 'src/app/projects/card/project-resources/models/project-resources-view.model';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { sumBy, uniqBy } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { Slot } from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { Exception } from 'src/app/shared/models/exception';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { Guid } from 'src/app/shared/helpers/guid';
import {
  ResourcePlanData,
  ResourcePlanEntry,
  ResourcePlanGroupData,
  ResourcePlanTaskData,
  TaskInfo,
} from '../../models/project-resources-data.model';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectResourcesHelper } from 'src/app/projects/card/project-resources/shared/core/project-resources.helper';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/project-resources.service';

@Injectable()
export class ProjectResourcesForecastCalendarDataService implements OnDestroy {
  // Cached Data
  public groups: ResourceViewGroup[] = [];
  public teamMembers: ResourceViewTeamMember[] = [];
  public otherActualGroup: ResourceViewGroup;

  // Subscriptions
  private loadingSubscription: Subscription;
  private subscriptions: Subscription[] = [];

  // Queue
  private entriesQueueId = Guid.generate();
  private entriesToSave: ResourcePlanEntry[] = [];
  private entriesToSaveScale: PlanningScale;

  /** Prevents sending of entriesToSave to saving queueService. */
  public isAddToSavePrevented: boolean;

  private destroyed$ = new Subject<void>();

  constructor(
    @Inject('entityId') public projectId,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    private autosave: SavingQueueService,
    private notification: NotificationService,
    private translate: TranslateService,
    private projectResourceService: ProjectResourceService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Service initialization. */
  public init() {
    this.autosave.delayDuration = 0;

    this.autosave.save$.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.entriesToSave = this.entriesToSave.filter(
        (entryToSave: ResourcePlanEntry) =>
          !data.entries.some(
            (entry: ResourcePlanEntry) =>
              entry.date === entryToSave.date &&
              entry.taskId === entryToSave.taskId &&
              entry.teamMemberId === entryToSave.teamMemberId,
          ),
      );
      if (this.isAddToSavePrevented) {
        this.saveCurrentEntriesToSave();
      }
    });
  }

  /** Updates project task dates in the groups lines.
   *
   * @param tasks tasks for updating.
   * @param planningScale planning scale of entries view.
   */
  public updateTasksDates(tasks: TaskInfo[], planningScale: PlanningScale) {
    tasks.forEach((task) => {
      this.groups.forEach((group) => {
        group.lines.forEach((line) => {
          if (line.taskId === task.id) {
            line.entries.forEach((entry) => {
              entry.taskStartDate = task.startDate ?? entry.taskStartDate;
              entry.taskEndDate = task.endDate ?? entry.taskEndDate;
              entry.taskDurationPercent =
                ProjectResourcesHelper.getTaskDurationPercent(
                  planningScale,
                  entry.date,
                  entry.taskStartDate,
                  entry.taskEndDate,
                );
              entry.backgroundStyle = ProjectResourcesHelper.getBackgroundStyle(
                entry.taskDurationPercent,
              );
            });
          }
        });
      });
    });
  }

  /**
   * Loads data from specific interval
   *
   * @param interval - interval to load data
   * @param planningScale - data planning scale
   * @param slots - calendar slots
   * */
  public loadFrame(
    interval: Interval,
    planningScale: PlanningScale,
    slots: Slot[],
  ): Promise<ResourceViewGroup[]> {
    return this.loadResourceGroups(interval, planningScale, slots, false);
  }

  /**
   * Loads resources
   * */
  public loadResourceGroups(
    interval: Interval,
    scale: PlanningScale,
    slots: Slot[],
    rebuild = true,
  ): Promise<ResourceViewGroup[]> {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    // Сохранить состояние групп.
    const expandedGroupIds = this.groups
      .filter((g) => g.isExpanded)
      .map((g) => g.id);

    const params = {
      scale: `WP.PlanningScale'${scale}'`,
      from: interval.start.toISODate(),
      to: interval.end.toISODate(),
      isForecast: 'true',
    };
    return new Promise((resolve, reject) => {
      this.loadingSubscription = this.versionDataService
        .projectCollectionEntity(
          this.versionCardService.projectVersion,
          this.projectId,
        )
        .function('GetResourcePlan')
        .query<ResourcePlanData>(params)
        .subscribe({
          next: (planData) => {
            if (rebuild) {
              this.groups = [];
              this.otherActualGroup = null;
            }
            this.updateTeamMembers(planData);
            this.buildGroups(planData, scale, slots);

            // Вернуть состояние групп
            this.groups.forEach(
              (g) => (g.isExpanded = expandedGroupIds.includes(g.id)),
            );
            resolve(this.groups);
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            reject();
          },
        });
    });
  }

  /**
   * Adds entry to saving queue
   *
   * @param entry - entry to save
   * @param line - line entry is from
   * @param group - group entry is from
   * @param scale - current scale
   */
  public addEntryToQueue(
    entry: ResourceViewGroupLineEntry,
    line: ResourceViewGroupLine,
    group: ResourceViewGroup,
    scale: PlanningScale,
  ) {
    const queueEntry: ResourcePlanEntry = {
      date: entry.date,
      hours: entry.hours ?? null,
      cost: entry.cost,
      taskId: line.taskId,
      teamMemberId: group.teamMember.id,
    };
    if (queueEntry.hours === null) {
      queueEntry.hours = 0;
      queueEntry.cost = 0;
      // NOTE: subtract cost of deleted entry
      line.totalCost -= entry.cost;
      entry.cost = 0;
    }

    this.entriesToSave = this.entriesToSave.filter(
      (entryToSave: ResourcePlanEntry) =>
        entryToSave.date !== queueEntry.date ||
        entryToSave.teamMemberId !== queueEntry.teamMemberId ||
        entryToSave.taskId !== queueEntry.taskId,
    );
    this.entriesToSave.push(queueEntry);
    this.entriesToSaveScale = scale;

    if (!this.isAddToSavePrevented) {
      this.autosave.addToQueue(
        this.entriesQueueId,
        this.versionDataService
          .projectCollectionEntity(
            this.versionCardService.projectVersion,
            this.projectId,
          )
          .action('UpdateResourcePlan')
          .execute(
            {
              scale,
              entries: this.entriesToSave,
            },
            undefined,
            {
              undoRedoSessionId: this.projectResourceService.undoRedoSessionId,
            },
          ),
      );
      this.isAddToSavePrevented = true;
    }
  }

  /**
   * Trigger save all entries in queue
   */
  public async save(): Promise<void> {
    await this.autosave.save();
    this.entriesToSave = [];
  }

  /** Sends current entriesToSave to saving queue service. */
  public saveCurrentEntriesToSave() {
    if (!this.autosave.isSaving) {
      this.isAddToSavePrevented = false;
      if (this.entriesToSave.length) {
        this.autosave.addToQueue(
          this.entriesQueueId,
          this.versionDataService
            .projectCollectionEntity(
              this.versionCardService.projectVersion,
              this.projectId,
            )
            .action('UpdateResourcePlan')
            .execute(
              {
                scale: this.entriesToSaveScale,
                entries: this.entriesToSave,
              },
              undefined,
              {
                undoRedoSessionId:
                  this.projectResourceService.undoRedoSessionId,
              },
            ),
        );
      }
    }
  }

  /**
   * Disposes all subscriptions
   */
  public dispose() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /**
   * Updates team members. Adds new if not present in memory
   *
   * @param planData - server-side data
   * @private
   */
  private updateTeamMembers(planData: ResourcePlanData) {
    planData.teamMembers.forEach((tm) => {
      const cachedTeamMember = this.teamMembers.find((ctm) => ctm.id === tm.id);
      if (cachedTeamMember) {
        return;
      }

      const resource = tm.resourceId
        ? planData.resources.find((t) => t.id === tm.resourceId)
        : null;
      this.teamMembers.push({
        id: tm.id,
        resource,
        name: resource?.name,
      });
    });
  }

  /**
   * Build calendar groups
   *
   * @param planData - server-side data
   * @param scale - current planning scale
   * @param slots - date slots
   * @private
   */
  private buildGroups(
    planData: ResourcePlanData,
    scale: PlanningScale,
    slots: Slot[],
  ) {
    let viewGroups: ResourceViewGroup[] = [];
    planData.groups.forEach((dataGroup) => {
      const group = this.buildGroup(planData, dataGroup);
      group.totals = this.buildTotals(planData, group, scale, slots);

      const lines = this.buildLines(planData, dataGroup, group, scale, slots);
      group.lines.push(...lines);
      group.extraTotal = sumBy(group.lines, 'extraTotal');

      const leadTaskLine = group.lines.find((l) => !l.taskNumber);
      group.lines = (leadTaskLine ? [leadTaskLine] : []).concat(
        group.lines.filter((l) => l.taskNumber).sort(naturalSort('name')),
      );
      viewGroups.push(group);
    });
    // Other Actual Group
    this.otherActualGroup = this.buildOtherGroup(planData, scale, slots);

    const unassignedGroup = viewGroups.filter((g) => !g.resource);
    const genericGroups = viewGroups
      .filter((g) => g.resource?.type === ResourceType.generic)
      .sort(naturalSort('name'));
    const userGroups = viewGroups
      .filter((g) => g.resource?.type === ResourceType.user)
      .sort(naturalSort('name'));
    viewGroups = [...unassignedGroup, ...genericGroups, ...userGroups];
    this.groups = viewGroups;
  }

  /**
   * Builds single group
   *
   * @param planData - server-side data
   * @param groupData - group server-side data
   * @private
   */
  private buildGroup(
    planData: ResourcePlanData,
    groupData: ResourcePlanGroupData,
  ): ResourceViewGroup {
    const resource = planData.resources.find(
      (r) => r.id === groupData.resourceId,
    );
    const role = planData.roles.find((r) => r.id === groupData.roleId);
    const teamMember = planData.teamMembers.find(
      (tm) => tm.id === groupData.teamMemberId,
    );

    const cachedGroup = this.groups.find(
      (g) => g.id === groupData.teamMemberId,
    );
    const group =
      cachedGroup ??
      ({
        id: teamMember.id,
        name: resource?.name,
        isEditable: true,
        isActive: groupData.isActive,
        totalHours: 0,
        totalCost: 0,
        extraTotal: 0,

        resource,
        role,
        teamMember,

        lines: [],
        totals: [],
      } as ResourceViewGroup);

    //TODO: is need resourceType for select schedule?
    const frameSchedule =
      groupData.resourceId && resource.schedule?.length
        ? resource.schedule
        : planData.fteSchedule;
    group.schedule = uniqBy(
      [...(group.schedule ?? []), ...frameSchedule],
      'date',
    );
    return group;
  }

  /**
   * Builds group with other data
   *
   * @param planData - server-side data
   * @param scale - current planning scale
   * @param slots - date slots
   * @private
   */
  private buildOtherGroup(
    planData: ResourcePlanData,
    scale: PlanningScale,
    slots: Slot[],
  ): ResourceViewGroup {
    const otherActualGroup =
      this.otherActualGroup ??
      ({
        id: Guid.generate(),
        name: this.translate.instant(
          'projects.projects.card.resources.columns.otherActual.header',
        ),
        verboseHint: this.translate.instant(
          'projects.projects.card.resources.columns.otherActual.verboseHint',
        ),
        lines: [],
        totals: [],
        schedule: planData.fteSchedule,
        totalHours: planData.otherActual.totalHours,
        totalCost: planData.otherActual.totalCost,
        isActive: true,
        isOther: true,
      } as ResourceViewGroup);
    // NOTE: Extend schedule if frame was loaded
    otherActualGroup.schedule = uniqBy(
      [...(otherActualGroup.schedule ?? []), ...planData.fteSchedule],
      'date',
    );

    otherActualGroup.totals = this.buildTotals(
      planData,
      otherActualGroup,
      scale,
      slots,
    );
    slots.forEach((slot) => {
      const actualEntries = planData.otherActual.entries.filter(
        (e) => e.date === slot.date.toISODate(),
      );
      if (!actualEntries.length) {
        return;
      }

      const actualTotals = otherActualGroup.totals.filter((t) => t.isActual);
      const actualTotalSlot = actualTotals.find(
        (at) => at.date === slot.date.toISODate(),
      );
      actualTotalSlot.hours = sumBy(actualEntries, 'hours');
      actualTotalSlot.cost = sumBy(actualEntries, 'cost');
    });
    return otherActualGroup;
  }

  /**
   * Builds totals
   *
   * @param planData - server-side data
   * @param group - group to build totals for
   * @param scale - current planning scale
   * @param slots - date slots
   * @private
   */
  private buildTotals(
    planData: ResourcePlanData,
    group: ResourceViewGroup,
    scale: PlanningScale,
    slots: Slot[],
  ): ResourceViewGroupLineTotal[] {
    const totals = [];
    // NOTE: Начинаем с isActual = true, если есть хоть один слот из прошлого, включая сегодня.
    // В обратном случае все слоты являются прогнозными, isActual начинается с false и не переключается.
    let isActual = slots.some(
      (slot) => slot.date.toISODate() <= DateTime.now().toISODate(),
    );
    slots.forEach((slot) => {
      const cachedTotal = group.totals.find(
        (ct) =>
          ct.slotId === slot.id && !totals.map((t) => t.id).includes(ct.id),
      );

      if (cachedTotal) {
        totals.push(cachedTotal);
        if (slot.today) {
          isActual = false;
        }
        return;
      }

      const slotDate = slot.date.toISODate();
      const total = {
        id: Guid.generate(),
        slotId: slot.id,
        date: slotDate,
        hours: 0,
        nonWorking: false,
        isActual,
      } as ResourceViewGroupLineTotal;
      if (slot.today) {
        isActual = false;
      }
      const viewSchedule = group.schedule.find((s) => s.date === slotDate);
      total.scheduleHours = viewSchedule?.hours ?? 0;
      if (scale === PlanningScale.Day) {
        total.nonWorking = !viewSchedule?.hours;
      }

      const slotTotal = group.totals.find((t) => t.date === slotDate);
      const dataFteSchedule = planData.fteSchedule.find(
        (s) => s.date === slotDate,
      );
      total.fteHours = slotTotal?.fteHours ?? dataFteSchedule?.hours;
      totals.push(total);
    });
    return totals;
  }

  /**
   * Builds lines for passed group
   *
   * @param planData - server-side data
   * @param dataGroup - server-side group data
   * @param group - view group
   * @param scale - current planning scale
   * @param slots - date slots
   * @private
   */
  private buildLines(
    planData: ResourcePlanData,
    dataGroup: ResourcePlanGroupData,
    group: ResourceViewGroup,
    scale: PlanningScale,
    slots: Slot[],
  ): ResourceViewGroupLine[] {
    const lines = [];
    dataGroup.tasks.forEach((taskData: ResourcePlanTaskData) => {
      const task = planData.tasks.find((t) => t.id === taskData.taskId);
      const cachedLine = group.lines.find((l) => l.taskId === taskData.taskId);
      const line =
        cachedLine ??
        ({
          id: Guid.generate(),
          totalHours: taskData.totalHours,
          taskId: taskData.taskId,
          taskNumber: task.number,
          isActive: taskData.isActive,
          extraTotal: 0, // Сумма часов по задаче, помимо суммы за выбранный период.
          totalCost: taskData.totalCost,
          name: task.number ? task.number + ' ' + task.name : task.name,
          entries: [],
          taskStartDate: task.startDate,
          taskEndDate: task.endDate,
          isSummaryTask: task.isSummary,
        } as ResourceViewGroupLine);
      if (!cachedLine) {
        lines.push(line);
      }
    });

    // NOTE: Перестроить слоты как для новых линий,
    // так и для уже отрисованных
    [...group.lines, ...lines].forEach((line) => {
      const taskData = dataGroup.tasks.find((t) => t.taskId === line.taskId);
      const entries = this.buildEntries(
        planData,
        group,
        taskData,
        line,
        scale,
        slots,
      );
      line.extraTotal = line.totalHours - sumBy(entries, 'hours');
      line.entries = entries;
    });
    return lines;
  }

  /**
   * Builds entries for passed lines
   *
   * @param planData - server-side data
   * @param group - view group task lines are from
   * @param taskData - server-side task data
   * @param line - view line entries built for
   * @param scale - current planning scale
   * @param slots - date slots
   * @private
   */
  private buildEntries(
    planData: ResourcePlanData,
    group: ResourceViewGroup,
    taskData: ResourcePlanTaskData,
    line: ResourceViewGroupLine,
    scale: PlanningScale,
    slots: Slot[],
  ): ResourceViewGroupLineEntry[] {
    const entries = [];

    // NOTE: Начинаем с isActual = true, если есть хоть один слот из прошлого, включая сегодня.
    // В обратном случае все слоты являются прогнозными, isActual начинается с false и не переключается.
    let isActual = slots.some(
      (slot) => slot.date.toISODate() <= DateTime.now().toISODate(),
    );
    slots.forEach((slot) => {
      const slotDate = slot.date.toISODate();
      const cachedEntry = line.entries.find(
        (e) => e.date === slotDate && !entries.map((x) => x.id).includes(e.id),
      );
      if (cachedEntry) {
        entries.push(cachedEntry);
        if (slot.today) {
          isActual = false;
        }
        return;
      }

      const entry = {
        id: Guid.generate(),
        date: slotDate,
        hours: 0,
        cost: 0,
        isActual,
        taskStartDate: line.taskStartDate,
        taskEndDate: line.taskEndDate,
        taskDurationPercent: null,
      } as ResourceViewGroupLineEntry;

      let plannedEntry = (taskData?.planEntries ?? []).find(
        (t) => t.date === slotDate,
      );

      // Если текущий слот, то записываем в entry данные для actual или forecast ячейки
      if (slot.today) {
        const currentPeriodEntries = (taskData?.planEntries ?? []).filter(
          (t) => t.date === slotDate,
        );

        plannedEntry = currentPeriodEntries?.find(
          (e) => !!e.isActual === isActual,
        );
      }

      if (plannedEntry) {
        entry.hours = plannedEntry.hours;
        entry.cost = plannedEntry.cost;
        entry.isActual = isActual;
      }

      if (slot.today) {
        isActual = false;
      }

      const viewSchedule = group.schedule.find((s) => s.date === slotDate);
      entry.scheduleHours = viewSchedule?.hours ?? 0;
      if (scale === PlanningScale.Day) {
        entry.nonWorking = !viewSchedule?.hours;
      }

      const slotTotal = group.totals.find((t) => t.date === slotDate);
      const dataFteSchedule = planData.fteSchedule.find(
        (s) => s.date === slotDate,
      );
      entry.fteHours = slotTotal?.fteHours ?? dataFteSchedule?.hours;
      entry.limitHours = group.resource ? 24 : 999;
      entries.push(entry);
    });

    return entries;
  }
}
