<form class="modal-form" novalidate [formGroup]="creationForm" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.timesheetPeriods.creation.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{ 'settings.timesheetPeriods.card.props.type.label' | translate }}</label>
      <wp-select-box
        formControlName="type"
        collection="TimeSheetPeriodTypes"
        placeholder="{{ 'settings.timesheetPeriods.card.props.type.ph' | translate }}"
      >
      </wp-select-box>
    </div>

    <div class="form-group">
      <label>{{ 'settings.timesheetPeriods.card.props.scope.label' | translate }}</label>
      <wp-select-box
        formControlName="scope"
        collection="TimeSheetPeriodScopes"
        placeholder="{{ 'settings.timesheetPeriods.card.props.scope.ph' | translate }}"
      >
      </wp-select-box>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
