import { Component, OnInit, inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';

import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DefaultFilterService } from 'src/app/settings-app/shared/default-filter/default-filter.service';
import { ListService } from 'src/app/shared/services/list.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { Exception } from 'src/app/shared/models/exception';

import { LegalEntityCreationComponent } from 'src/app/settings-app/legal-entity/creation/legal-entity-creation.component';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'tmt-legal-entity-list',
  template: '<wp-entity-list></wp-entity-list> ',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
})
export class LegalEntityListComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private entityListService: EntityListService,
    private notificationService: NotificationService,
    private dataService: DataService,
    private gridService: GridService,
    private stateService: StateService,
    private appService: AppService,
    private actionPanelService: ActionPanelService,
    private modalService: NgbModal,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.set([
      this.actionPanelService.getDefaultAction('create', {
        title: 'settings.legalEntity.list.actions.create',
        isVisible: this.appService.checkEntityPermission(
          'LegalEntity',
          PermissionType.Modify,
        ),
        handler: () => this.create(),
      }),
      this.actionPanelService.getDefaultAction('card', {
        handler: () =>
          this.stateService.go('settings.legalEntity', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actionPanelService.getDefaultAction('delete', {
        handler: () => this.entityListService.delete(),
      }),
      this.actionPanelService.getDefaultAction('setAsDefault', {
        handler: () => this.setAsDefault(),
      }),
    ]);

    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((row: any) => {
        this.actionPanelService.action('card').isShown = !!row;

        this.actionPanelService.action('delete').isShown =
          row &&
          this.appService.checkEntityPermission(
            'LegalEntity',
            PermissionType.Modify,
          );

        this.actionPanelService.action('setAsDefault').isShown =
          row &&
          this.appService.checkEntityPermission(
            'LegalEntity',
            PermissionType.Modify,
          ) &&
          !row.value.isDefault;
      });
  }

  private create(): void {
    this.modalService.open(LegalEntityCreationComponent);
  }

  private setAsDefault(): void {
    this.dataService
      .collection('LegalEntities')
      .entity(this.gridService.selectedGroupValue.id)
      .action('WP.SetAsDefault')
      .execute()
      .subscribe({
        next: () => {
          this.notificationService.successLocal(
            'settings.legalEntity.messages.hasBeenSetByDefault',
          );

          this.entityListService.reload();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
        },
      });
  }
}
