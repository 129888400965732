import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { WorkflowInstanceListComponent } from 'src/app/workflow-instances/list/workflow-instance-list.component';
import { WORKFLOW_INSTANCE_LIST } from 'src/app/shared/lists/workflow-instance.list';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { WorkflowInstanceEntityCellComponent } from 'src/app/workflow-instances/list/entity-cell/workflow-instance-entity-cell.component';

@NgModule({
  declarations: [
    WorkflowInstanceListComponent,
    WorkflowInstanceEntityCellComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'workflow-instances',
          url: '/workflow/{view:viewName}?{navigation}',
          params: {
            navigation: 'my.workflow',
          },
          component: WorkflowInstanceListComponent,
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: WORKFLOW_INSTANCE_LIST },
          ],
        },
      ],
    }),
    CommentsModule,
  ],
})
export class WorkflowInstancesModule {}
