<div *ngIf="!readonly" class="input-group" (clickOutside)="closeList()" #currencyBoxContainer>
  <input
    type="text"
    #editor
    (input)="handleInput()"
    class="form-control"
    [ngStyle]="{ 'padding-right': getInputRightPadding() + 'rem' }"
    [attr.title]="value.value"
    [placeholder]="placeholder"
    (blur)="onBlur()"
    (focus)="onFocus()"
    wpIntersectable
    (onIntersect)="closeList()"
  />
  <span
    #currency
    [ngClass]="{ disabled: !isCurrencyEditable }"
    (click)="openList()"
    class="currency"
    >{{ getNarrowCurrencySymbol(value.currencyCode) }}</span
  >
</div>

<p *ngIf="readonly && value.value !== null" class="trim" title="{{ getDisplayValue() }}">
  {{ getDisplayValue() }}
</p>
<p *ngIf="readonly && value.value === null" class="text-body-secondary text-uppercase">
  {{ 'shared.empty' | translate }}
</p>

<div
  #expandingArea
  class="fixed-expanding-area expanding-area-vertical-scroll"
  [ngStyle]="{ width: 'fit-content' }"
  *ngIf="listOpened"
>
  <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

  <div
    *ngIf="currencies.length === 0 && !isLoading"
    class="center-block"
    style="text-align: center; padding: 3px"
  >
    <p class="text-body-secondary">{{ 'shared.noOptions' | translate }}</p>
  </div>
  <table class="selecting-list" [hidden]="isLoading">
    <tbody>
      <tr
        *ngFor="let currency of currencies"
        (mousedown)="mousedownOnRow($event)"
        (click)="clickRow(currency)"
        id="{{ currency.id }}"
        title="{{ currency.name }}"
      >
        <td>{{ getWideCurrencySymbol(currency.alpha3Code) }}</td>
      </tr>
    </tbody>
  </table>
</div>
