import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

/** Форматирование валюты. */
@Pipe({
  name: 'wpCurrency',
})
export class WpCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(
    value?: number,
    code?: string,
    digitsInfo?: string,
    zeroIsNull = false,
  ): string {
    if (zeroIsNull && value === 0) {
      value = null;
    }

    return this.currencyPipe.transform(
      value,
      code,
      'symbol-narrow',
      digitsInfo,
    );
  }
}
