import { Injectable } from '@angular/core';
import { GridColumnType } from 'src/app/shared/models/inner/grid-column.interface';

/** Service for default column value management. */
@Injectable({
  providedIn: 'root',
})
export class ColumnDefaultValueService {
  public getDefaultValue(type: GridColumnType) {
    switch (type) {
      case GridColumnType.String:
      case GridColumnType.StringControl:
        return '';
      case GridColumnType.Boolean:
      case GridColumnType.BooleanControl:
        return false;
      default:
        return null;
    }
  }
}
