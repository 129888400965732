<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-8">
      <div class="form-group">
        <label>{{ 'resources.requests.props.resourcePool' | translate }}</label>
        <tmt-hierarchical-box
          formControlName="resourcePool"
          collection="ResourcePools"
          parentIdKey="leadResourcePoolId"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{
            'resources.resourceSummary.filter.properties.resourcePool.placeholder' | translate
          }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'resources.requests.list.filter.props.role.label' | translate }}</label>
        <wp-select-box
          collection="Roles"
          formControlName="role"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'resources.requests.list.filter.props.role.ph' | translate }}"
        >
        </wp-select-box>
      </div>

      <div class="form-group">
        <label>{{ 'resources.requests.list.filter.props.project.label' | translate }}</label>
        <wp-select-box
          collection="Projects"
          formControlName="project"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'resources.requests.list.filter.props.project.ph' | translate }}"
        >
        </wp-select-box>
      </div>

      @if (viewName !== 'myOpen') {
        <div class="form-group">
          <label>{{ 'resources.requests.list.filter.props.createdBy.label' | translate }}</label>
          <wp-user-box
            formControlName="createdBy"
            [allowInactive]="filterService.allowInactive$ | async"
            placeholder="{{ 'resources.requests.list.filter.props.createdBy.ph' | translate }}"
          >
          </wp-user-box>
        </div>
      }
    </div>

    @if (viewName !== 'open' && viewName !== 'myOpen') {
      <div class="col-4">
        <div class="col-5">
          <tmt-state-select formControlName="states" collection="ResourceRequests" />
        </div>
      </div>
    }
  </div>
</form>
