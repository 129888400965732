import { Component, Input } from '@angular/core';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';

import { ResourceRequestService } from 'src/app/resource-requests/shared/resource-request/resource-request.service';

@Component({
  selector: 'tmt-resource-request-card-main',
  templateUrl: './resource-request-card-main.component.html',
  styleUrls: ['./resource-request-card-main.component.scss'],
})
export class ResourceRequestCardMainComponent {
  @Input() public entityId: string;

  public activeTab: string;

  protected readonly commentedEntityCollectionType =
    CommentedEntityCollectionType;

  constructor(public resourceRequestService: ResourceRequestService) {}
}
