import { User } from 'src/app/shared/models/entities/settings/user.model';
import { Group } from 'src/app/shared/models/entities/settings/group.model';
import { PermissionSet } from 'src/app/shared/models/entities/settings/permission-set.model';
import { Entity } from 'src/app/shared/models/entities/entity.model';
import { IssueType } from 'src/app/settings-app/issue-types/model/issue-type.model';

export class IssueTypeMember extends Entity {
  /** Member type. */
  type: IssueTypeMemberType;
  /** Issue type id. */
  issueTypeId: string;
  /** User id. */
  userId?: string;
  /** Group id. */
  groupId?: string;
  /** Permission set id. */
  permissionSetId?: string;
  /** Role. */
  role?: string;
  /** Issue type. */
  issueType: IssueType;
  /** User. */
  user: User;
  /** Group. */
  group: Group;
  /** Permission set. */
  permissionSet: PermissionSet;
}

export enum IssueTypeMemberType {
  /** Viewer. */
  Viewer = 'Viewer',
  /** Editor. */
  Editor = 'Editor',
}
