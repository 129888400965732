import {
  Component,
  forwardRef,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';

import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { AbstractHierarchicalBoxComponent } from 'src/app/shared/components/controls/hierarchical-box/abstract-hierarchical-box.component';

import { DepartmentValue } from './department-value.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmt-department-box',
  templateUrl: './department-box.component.html',
  styleUrls: ['./department-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DepartmentBoxComponent),
      multi: true,
    },
  ],
})
export class DepartmentBoxComponent extends AbstractHierarchicalBoxComponent {
  @Input({ required: false }) public override collection = 'Departments';
  @Input({ required: false }) public override parentIdKey = 'leadDepartmentId';
  @Input() public subordinatesOption = false;
  public includeSubDeps: boolean;

  constructor(
    protected override notificationService: NotificationService,
    protected override dataService: DataService,
    protected override translateService: TranslateService,
    protected override cdr: ChangeDetectorRef,
    protected override renderer: Renderer2,
    protected override el: ElementRef<HTMLElement>,
  ) {
    super(
      notificationService,
      dataService,
      translateService,
      cdr,
      renderer,
      el,
    );
  }

  public override writeValue(value: any): void {
    if (this.subordinatesOption) {
      this.includeSubDeps = value?.includeSubordinates ?? false;
      this.selectedRow = value?.value;
      this.textControl.setValue(value?.value?.name ?? '', { emitEvent: false });
    } else {
      this.selectedRow = value;
      this.textControl.setValue(value?.name ?? '', { emitEvent: false });
    }

    this.cdr.detectChanges();
  }

  public override onSelected(value: any): void {
    this.selectedRow = value;
    this.propagateChange(this.getFormValue());
    this.textControl.setValue(value?.name ?? '', { emitEvent: false });
    this.cancel();
  }

  public setIncludingSubDeps(): void {
    this.includeSubDeps = !this.includeSubDeps;
    this.propagateChange(this.getFormValue());
  }

  private getFormValue(): DepartmentValue | NamedEntity {
    const value = this.selectedRow
      ? { id: this.selectedRow.id, name: this.selectedRow.name }
      : null;
    return this.subordinatesOption
      ? {
          value,
          includeSubordinates: this.includeSubDeps,
        }
      : value;
  }
}
