<div class="toolbar-container pt-0">
  <wp-filter></wp-filter>
  <button
    type="button"
    class="btn btn-default ms-auto"
    (click)="gridService.execute('setUserView', gridService.selectedGroup$.getValue())"
    title="{{ 'shared.actions.setView' | translate }}"
  >
    <i class="bi bi-gear" aria-hidden="true"></i>
  </button>
</div>
