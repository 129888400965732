<form class="modal-form" novalidate ngDraggable [formGroup]="form">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.projects.revenues.modal.header' | translate }}
    </h3>

    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      [disabled]="saving$ | async"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <div class="form-group" tmtIndicator>
          <label>
            {{ 'projects.projects.revenues.props.calculationMethod.title' | translate }}
          </label>
          <wp-select-box
            [values]="calculationMethods"
            [allowNull]="false"
            placeholder="{{
              'projects.projects.revenues.props.calculationMethod.title' | translate
            }}"
            formControlName="calculationMethod"
          ></wp-select-box>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group" tmtIndicator>
          <label>
            {{ 'projects.projects.revenues.props.frequency.title' | translate }}
          </label>
          <wp-select-box
            [values]="frequencies"
            [allowNull]="false"
            placeholder="{{ 'projects.projects.revenues.props.frequency.title' | translate }}"
            formControlName="accrualFrequency"
          ></wp-select-box>
        </div>
      </div>

      <ng-container *ngIf="calculationMethod === revenueEstimateMethods.Fixed">
        <div class="form-group col-8" tmtIndicator>
          <label>
            {{ 'projects.projects.revenues.props.task' | translate }}
          </label>
          <tmt-task-box
            [allowNull]="false"
            [projectId]="projectId"
            [projectVersion]="projectVersion"
            placeholder="{{ 'projects.projects.revenues.props.task' | translate }}"
            formControlName="task"
          />
        </div>
        <div class="form-group col-4" tmtIndicator>
          <label>
            {{ 'projects.projects.revenues.props.amount' | translate }}
          </label>
          <wp-number-box
            class="w-auto"
            formControlName="fixedAmount"
            type="currency"
            [currencyCode]="projectCurrencyCode"
          ></wp-number-box>
        </div>
      </ng-container>

      <div *ngIf="calculationMethod === revenueEstimateMethods.TargetProfitability">
        <div class="form-group" tmtIndicator>
          <label>
            {{ 'projects.projects.revenues.props.targetProfitability' | translate }}
          </label>
          <wp-number-box
            formControlName="targetProfitability"
            type="percent"
            [max]="99.99"
          ></wp-number-box>
        </div>
      </div>

      <div
        class="col-12"
        *ngIf="
          calculationMethod === revenueEstimateMethods.TargetProfitability ||
          calculationMethod === revenueEstimateMethods.BillingRate
        "
      >
        <div class="form-group form-check">
          <input
            formControlName="isDetailedByStages"
            type="checkbox"
            class="form-check-input"
            id="isDetailedByStages"
          />
          <label class="form-check-label" for="isDetailedByStages">
            {{ 'projects.projects.revenues.props.breakdownByStages' | translate }}
          </label>
        </div>
      </div>

      <label class="control-label group">{{
        'projects.projects.revenues.modal.section.period' | translate
      }}</label>
      <div class="d-flex align-items-center">
        <ng-container>
          <div class="form-group me-3" tmtIndicator>
            <label>
              {{ 'projects.projects.revenues.props.estimationStartDate' | translate }}
            </label>
            <wp-date-box formControlName="dateStart"></wp-date-box>
          </div>
          <div class="form-group" tmtIndicator>
            <label>
              {{ 'projects.projects.revenues.props.estimationEndDate' | translate }}
            </label>
            <wp-date-box formControlName="dateFinish"></wp-date-box>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="modal-footer d-flex">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="saving$ | async"
      (click)="ok()"
    >
      {{ 'shared.actions.perform' | translate }}
    </button>

    <button type="button" class="btn btn-default" [disabled]="saving$ | async" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
