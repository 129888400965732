import { StateService, Transition } from '@uirouter/core';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { StateBuilderService } from 'src/app/core/state-builder.service';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';

export const APP_STATES = [
  {
    name: 'start',
    url: '/',

    redirectTo: (trans) => {
      const app = trans.injector().get(AppService);
      if (
        app.checkPermission('TimeSheet', 'My', PermissionType.Read) &&
        app.checkFeature(Feature.timesheets)
      ) {
        return {
          state: 'currentTimesheet',
          params: { navigation: 'my.currentTimesheet' },
        };
      } else {
        return {
          state: 'workflowTasks',
          params: { navigation: 'my.workflowTasksAll', view: 'my-all' },
        };
      }
    },
  },

  {
    name: 'my',
    url: '/my',
    redirectTo: 'start',
  },

  {
    name: 'team',
    url: '/team',
    redirectTo: (trans) => {
      const app = trans.injector().get(AppService);
      if (app.checkFeature(Feature.timesheets)) {
        return {
          state: 'timesheets',
          params: { navigation: 'team.timesheetsAll', view: 'team-all' },
        };
      } else {
        return {
          state: 'profiles',
          params: { navigation: 'team.profiles' },
        };
      }
    },
  },

  {
    name: 'notification',
    url: '/notification-url/{entityId:guid}',
    resolve: [
      {
        token: 'settingsAppInitializer',
        deps: [DataService, StateService, Transition, StateBuilderService],
        resolveFn: StateHelper.redirectToNotificationUrl,
      },
    ],
  },
  {
    name: 'finance',
    url: '/finance',
    redirectTo: {
      state: 'accountingEntries',
      params: { navigation: 'finance.accountingEntries' },
    },
  },

  {
    name: 'billing',
    url: '/billing',
    redirectTo: {
      state: 'billingSummary',
      params: { navigation: 'billing.summary' },
    },
  },

  {
    name: 'analytics',
    url: '/analytics',
    redirectTo: {
      state: 'reportBuilder',
      params: { navigation: 'analytics.reportBuilder' },
    },
  },
];

export const ROUTE_DEFAULT_PARAMS: any = {
  type: 'json',
  dynamic: true,
  array: true,
};
