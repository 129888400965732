import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectCardService } from '../core/project-card.service';
import { ProjectVersionCardService } from '../core/project-version-card.service';
import { ProjectTeamService } from 'src/app/shared/services/project-team.service';
import { ScheduleService } from 'src/app/core/schedule.service';
import { ProjectTaskDependenciesService } from 'src/app/projects/card/project-tasks/core/project-task-dependencies.service';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { TasksGridActionsService } from 'src/app/projects/card/project-tasks/core/tasks-grid-actions.service';
import {
  ProjectTask,
  projectTaskWarnings,
} from 'src/app/shared/models/entities/projects/project-task.model';
import {
  Exception,
  SavingQueueException,
} from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo/undo-redo.service';
import { EntityState } from 'src/app/shared/models/entities/entity.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

import { ProjectTasksCommandsService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/project-tasks-commands.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { MetaEntityPropertyType } from 'src/app/shared/models/entities/settings/metamodel.model';

/** Project tasks. */
@Component({
  selector: 'wp-project-tasks',
  templateUrl: './project-tasks.component.html',
  styleUrls: ['./project-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProjectTeamService,
    ProjectTasksDataService,
    SavingQueueService,
    TasksGridActionsService,
    ProjectTasksCommandsService,
    ProjectTaskDependenciesService,
  ],
})
export class ProjectTasksComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    private service: ProjectCardService,
    private taskDataService: ProjectTasksDataService,
    private versionCardService: ProjectVersionCardService,
    private dependenciesService: ProjectTaskDependenciesService,
    private scheduleService: ScheduleService,
    private autosave: SavingQueueService,
    private notificationService: NotificationService,
    private undoRedoService: UndoRedoService,
    private cdr: ChangeDetectorRef,
    private customFieldService: CustomFieldService,
    private blockUI: BlockUIService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.taskDataService.undoRedoSessionId = await firstValueFrom(
      this.undoRedoService.startUndoRedoSession(),
    );
    this.undoRedoService.setSavingQueue(this.autosave);

    this.autosave.delayDuration = 0;
    this.initProjectTasks();
    this.initSummaryFieldLists();

    /** Подписка на перезагрузку данных */
    this.service.reloadTab$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.taskDataService.load(false));

    this.autosave.error$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((error: SavingQueueException) => {
        if (error.code === Exception.BtDeleteException.code) {
          const deletingTaskIndex =
            this.taskDataService.deletingTaskIds.findIndex(
              (id) => id === error.savingQueueId,
            );
          if (deletingTaskIndex !== -1) {
            this.taskDataService.deletingTaskIds.splice(deletingTaskIndex, 1);
          }
          return;
        }
        this.taskDataService.creatingTaskIds = [];
        this.taskDataService.load();
      });

    this.autosave.save$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: any) => {
        if (!data) return;

        const updateView = () => {
          this.taskDataService.updateView();
          this.dependenciesService.syncDependencyGraph();
          this.cdr.markForCheck();
        };

        //UndoRedo logic
        data.projectTask?.forEach((updatedTask: ProjectTask) => {
          this.taskDataService.prepareTaskPropertyForView(updatedTask);
          if (updatedTask.entityState === EntityState.added) {
            this.taskDataService.tasks.push(updatedTask);
            return;
          }
          if (updatedTask.entityState === EntityState.deleted) {
            const index = this.taskDataService.tasks.findIndex(
              (task) => task.id === updatedTask.id,
            );
            if (index !== -1) {
              this.taskDataService.tasks.splice(index, 1);
            }
            return;
          }
          this.assignUpdatedTaskProperty(updatedTask);
          updateView();
          return;
        });

        // Updated task logic
        this.updateTasksAfterSaving(data);
        updateView();
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.taskDataService.dispose();
    this.undoRedoService.abortUndoRedoSession(
      this.taskDataService.undoRedoSessionId,
    );
  }

  /** Assigned updated fields to task in the data service.
   *
   * @param updatedTask updated task.
   */
  private assignUpdatedTaskProperty(updatedTask): void {
    const task = this.taskDataService.getTask(updatedTask.id);
    Object.keys(updatedTask).forEach((key) => {
      if (key in task || key === 'rowVersion') {
        task[key] = updatedTask[key];
      }
    });
    task.percentComplete =
      task.estimatedHours && task.actualHours
        ? task.actualHours / task.estimatedHours
        : 0;
  }

  /** Initialize of project task data. */
  private initProjectTasks() {
    this.service.project$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.scheduleService.initScheduleScheme(result.schedule);
        this.taskDataService.project = result;
        this.taskDataService.readonly =
          !result.tasksEditAllowed ||
          !this.versionCardService.projectVersion.editAllowed;
        this.taskDataService.isInherited = !!result.sourceProject;

        this.taskDataService.load();
      });
  }

  /** Shows change task warnings"
   *
   * @param projectTaskDTO response from server after project task data changes
   */
  private showProjectTaskWarnings(projectTaskDTO: ProjectTask) {
    projectTaskWarnings.forEach((warning) => {
      if (projectTaskDTO[warning]) {
        this.notificationService.warningLocal(
          `components.projectTaskWarnings.${warning}`,
        );
      }
    });
  }

  /** Enriches summary field list with custom fields. */
  private initSummaryFieldLists(): void {
    // NOTE: add custom properties to collapsable columns
    const summaryCustomFields = this.customFieldService
      .getList('ProjectTask')
      .filter(
        (f) =>
          f.type === MetaEntityPropertyType.decimal ||
          f.type === MetaEntityPropertyType.integer,
      )
      .map((f) => f.name);

    this.taskDataService.summaryFields =
      this.taskDataService.summaryFields.concat(summaryCustomFields);
    this.taskDataService.summaryEditableFields =
      this.taskDataService.summaryEditableFields.concat(summaryCustomFields);
  }

  /**
   * Removes task from from data array.
   *
   * @param id removing task id.
   */
  private removeTaskFromData(id: string): void {
    const index = this.taskDataService.tasks.findIndex((t) => t.id === id);
    this.taskDataService.tasks.splice(index, 1);
  }

  /**
   * Updates tasks after saving.
   *
   * @param data Data containing updated task information.
   */
  private updateTasksAfterSaving(data: any): void {
    const currentTask = this.taskDataService.getTask(data.id);
    if (currentTask) {
      this.taskDataService.prepareTaskPropertyForView(data);
      this.assignUpdatedTaskProperty(data);
    }

    // Check is task in deleting process
    const isTaskDeleting = this.taskDataService.deletingTaskIds.includes(
      data.id,
    );
    if (isTaskDeleting) {
      const deletingTaskIdIndex =
        this.taskDataService.deletingTaskIds.findIndex((id) => id === id);
      this.taskDataService.deletingTaskIds.splice(deletingTaskIdIndex, 1);
      if (!this.taskDataService.deletingTaskIds.length) this.blockUI.stop();
      this.removeTaskFromData(data.id);
      const isSummary = this.taskDataService.checkIsLeadTask(data.id);
      if (isSummary) {
        const allChildTaskIds = this.dependenciesService
          .getAllChildTasks(data.id)
          .map((task) => task.id);
        allChildTaskIds.forEach((id) => this.removeTaskFromData(id));
      }
    }
    // Check is task in creating process
    const isTaskCreating = this.taskDataService.creatingTaskIds.includes(
      data.id,
    );
    if (isTaskCreating) {
      const creatingTaskIdIndex =
        this.taskDataService.creatingTaskIds.findIndex((id) => id === data.id);
      this.taskDataService.creatingTaskIds.splice(creatingTaskIdIndex, 1);
    }

    this.showProjectTaskWarnings(data);

    const updatedTasks = data.updatedTasks as ProjectTask[];
    updatedTasks?.forEach((updatedTask: ProjectTask) => {
      this.taskDataService.prepareTaskPropertyForView(updatedTask);

      this.assignUpdatedTaskProperty(updatedTask);
    });
  }
}
