import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { StringHelper } from 'src/app/shared/helpers/string-helper';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { PnlFilterModalComponent } from './pnl-filter-modal/pnl-filter-modal.component';
import { PnlFilter } from 'src/app/shared-features/pnl/shared/pnl-filter.model';
import { Subject } from 'rxjs';
import { PnlService } from 'src/app/shared-features/pnl/pnl.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wp-pnl-filter',
  templateUrl: './pnl-filter.component.html',
})
export class PnlFilterComponent implements OnInit, OnDestroy {
  @Input() filter: PnlFilter;
  @Output() changes = new EventEmitter<PnlFilter>();

  public conditionsCount: number;
  public conditionCountTitle: string;

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private service: PnlService,
    private translate: TranslateService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.updateConditionalCount();
    this.service.filterModal$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.open());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public open() {
    const ref = this.modalService.open(PnlFilterModalComponent, {
      size: 'lg',
    });
    const instance = ref.componentInstance as PnlFilterModalComponent;
    instance.filter = cloneDeep(this.filter);

    ref.result.then(
      (filter: PnlFilter) => {
        this.filter = filter;
        this.updateConditionalCount();
        this.changes.emit(this.filter);
      },
      () => null,
    );
  }

  private updateConditionalCount() {
    this.conditionsCount = 0;

    if (this.filter) {
      if (this.filter.project?.id) {
        this.conditionsCount++;
      }
      if (this.filter.task?.id) {
        this.conditionsCount++;
      }
      if (this.filter.client?.id) {
        this.conditionsCount++;
      }
      if (this.filter.program?.id) {
        this.conditionsCount++;
      }
      if (this.filter.period) {
        this.conditionsCount++;
      }

      if (this.filter.projectStates?.length) {
        this.conditionsCount++;
      }

      const anyBillingTypeStateSelected = Object.entries(
        this.filter.billingTypes,
      ).some(([_, type]) => type);
      if (anyBillingTypeStateSelected) {
        this.conditionsCount++;
      }
    }

    this.conditionCountTitle = StringHelper.declOfNum(this.conditionsCount, [
      this.translate.instant('shared.filterConditionDeclensions.v1'),
      this.translate.instant('shared.filterConditionDeclensions.v2'),
      this.translate.instant('shared.filterConditionDeclensions.v3'),
    ]);
  }
}
