import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { KpiCardComponent } from './kpi-card/kpi-card.component';
import { OverviewKpiComponent } from './overview-kpi.component';
import { OverviewSwitcherModule } from 'src/app/shared/components/features/overview/overview-switcher/overview-switcher.module';

@NgModule({
  declarations: [OverviewKpiComponent, KpiCardComponent],
  imports: [CommonModule, SharedModule, OverviewSwitcherModule],
  exports: [OverviewKpiComponent],
})
export class OverviewKpiModule {}
