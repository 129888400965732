<ng-container [formGroup]="form">
  <span class="text-body-secondary">{{
    'settings.validationRules.card.props.workDayDurationHelp' | translate
  }}</span>
  <br />
  <br />
  <div class="form-group">
    <label>{{ 'settings.validationRules.card.props.maxWorkDayDuration' | translate }}</label>
    <wp-number-box
      type="integer"
      formControlName="MaxDuration"
      [allowNull]="false"
      [min]="0"
      [max]="24"
    >
    </wp-number-box>
  </div>
</ng-container>
