import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { TasksGridComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/tasks-grid.component';

@NgModule({
  imports: [
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.tasks.timeline',
          url: '/timeline',
          component: TasksGridComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
  exports: [],
})
export class ProjectTasksTimelineModule {}
