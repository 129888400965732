import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { Certificate } from 'src/app/shared/models/entities/certificates/certificate.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Exception } from 'src/app/shared/models/exception';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

@Component({
  selector: 'wp-certificate-creation',
  templateUrl: './certificate-creation.component.html',
  styleUrls: ['./certificate-creation.component.scss'],
})
export class CertificateCreationComponent implements OnInit, OnDestroy {
  @Input() client: NamedEntity;
  @Input() userFromProfile: NamedEntity;

  public isSaving = false;

  public usersQuery = {};

  form = this.fb.group({
    name: [
      null,
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    owner: [null, Validators.required],
  });

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private appService: AppService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private message: MessageService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
    private customFieldService: CustomFieldService,
  ) {
    this.customFieldService.enrichFormGroup(this.form, 'Certificate', true);
    this.customFieldService.enrichFormGroupWithDefaultValues(
      this.form,
      'Certificate',
    );
  }

  ngOnInit(): void {
    if (!this.userFromProfile) {
      this.setUsersQuery();
    } else {
      this.form.controls.owner.setValue(this.userFromProfile);
      this.form.controls.owner.disable();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>,
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term.length > 2) {
          const query: Dictionary<any> = {
            $top: Constants.typeaheadListLength,
          };
          const params = {
            term: `'${term.toLowerCase()}'`,
          };
          return this.data
            .collection('Certificates')
            .function('GetNames')
            .query(params, null, query);
        } else {
          return [];
        }
      }),
      map((certificateNames) => [...new Set<string>(certificateNames)]),
      takeUntil(this.destroyed$),
    );

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  public ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }
    this.isSaving = true;

    const formRawValue = this.form.getRawValue();

    const data = {
      name: formRawValue.name,
      ownerId: formRawValue.owner.id,
    };

    this.customFieldService.assignValues(data, formRawValue, 'Certificate');

    this.data
      .collection('Certificates')
      .insert(data)
      .subscribe({
        next: (certificate: Certificate) => {
          this.notification.successLocal(
            'team.certificates.creation.messages.created',
          );

          this.state.go('certificate', {
            entityId: certificate.id,
            routeMode: RouteMode.continue,
          });

          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.message.errorDetailed(error);
          this.isSaving = false;
        },
      });
  };

  private setUsersQuery() {
    if (
      this.appService.checkPermission(
        'Certificate',
        'All',
        PermissionType.Modify,
      )
    ) {
      this.usersQuery = {
        filter: {
          isActive: true,
        },
      };
    } else {
      if (
        this.appService.checkPermission(
          'Certificate',
          'MySubordinates',
          PermissionType.Modify,
        )
      ) {
        this.usersQuery = {
          filter: [
            {
              supervisors: {
                any: {
                  id: { type: 'guid', value: this.appService.session.user.id },
                },
              },
            },
          ],
        };
      }
    }
  }
}
