import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { LocationCardComponent } from 'src/app/settings-app/locations/card/location-card.component';
import { LocationListComponent } from 'src/app/settings-app/locations/list/location-list.component';
import { LocationCreationComponent } from 'src/app/settings-app/locations/creation/location-creation.component';
import { LocationList } from 'src/app/shared/lists/location.list';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    LocationCardComponent,
    LocationListComponent,
    LocationCreationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.locations',
          url: '/locations',
          component: LocationListComponent,
          params: {
            navigation: 'settings.locations',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: LocationList },
          ],
        },

        {
          name: 'settings.location',
          params: {
            navigation: 'settings.locations',
          },
          url: '/locations/{entityId:guid}',
          component: LocationCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class LocationsModule {}
