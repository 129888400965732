<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ header | translate }}
    </h3>
    <button
      [disabled]="isSaving()"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <wp-loading-indicator [loading]="isLoading()" />
    <form class="form" [hidden]="isLoading()" [formGroup]="form">
      <div class="container-fluid">
        <div class="row">
          <div class="col d-flex">
            <div class="form-check mb-3">
              <input
                formControlName="isInitial"
                type="checkbox"
                class="form-check-input"
                id="isInitial"
              />
              <label class="form-check-label" for="isInitial">
                {{ 'settings.lifecycles.card.props.state.isInitial.label' | translate }}
              </label>
              <i
                class="group-hint bi bi-question-circle text-gray ms-auto"
                [ngbTooltip]="'settings.lifecycles.card.props.state.isInitial.hint' | translate"
              ></i>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <label class="group mb-0">{{
              'settings.lifecycles.card.props.state.transitions' | translate
            }}</label>

            <tmt-grid
              [formArray]="form.controls.transitions"
              [options]="gridOptions"
              class="d-block"
            />
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="save()"
      wpButtonSpinner
      [isBusy]="isSaving()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
