import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { TimeOffRequestFilterComponent } from './time-off-request-filter.component';

@Injectable()
export class TimeOffRequestFilterService extends FilterService {
  public override component: Type<FilterDetails> =
    TimeOffRequestFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override hasAllowInactive = true;

  public override placeholder = 'timeOff.list.filter.placeholder';

  protected override getDefaultValues = (): any => ({
    period: null,
    user: null,
    timeOffType: null,
    department: null,
    onlySubordinates: false,
    states: null,
  });

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        timeOffRequest: {
          or: [
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { 'tolower(name)': { contains: text } },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { 'tolower(description)': { contains: text } },
          ],
        },
      });
    }

    if (this.values.user) {
      result.push({
        timeOffRequest: {
          userId: { type: 'guid', value: this.values.user.id },
        },
      });
    }

    if (this.values.department) {
      result.push({
        timeOffRequest: {
          user: {
            departmentId: { type: 'guid', value: this.values.department.id },
          },
        },
      });
    }

    if (this.values.timeOffType) {
      result.push({
        timeOffRequest: {
          timeOffTypeId: { type: 'guid', value: this.values.timeOffType.id },
        },
      });
    }

    if (this.values.onlySubordinates) {
      result.push({
        timeOffRequest: {
          user: {
            supervisors: {
              any: {
                id: { eq: { type: 'guid', value: this.app.session.user.id } },
              },
            },
          },
        },
      });
    }

    if (this.values.states) {
      const conditions = [];

      Object.keys(this.values.states).forEach((code) => {
        if (this.values.states[code].selected) {
          conditions.push({
            timeOffRequest: {
              stateId: { type: 'guid', value: this.values.states[code].id },
            },
          });
        }
      });

      result.push({ or: conditions });
    }

    if (this.values.period) {
      const dates = this.dateService.getDatePairFromPeriodForFilter(
        this.values.period,
      );

      result.push({
        timeOffRequest: {
          startDate: { le: { type: 'raw', value: dates.periodFinish } },
        },
      });

      result.push({
        timeOffRequest: {
          finishDate: { ge: { type: 'raw', value: dates.periodStart } },
        },
      });
    }

    return result;
  }

  public override getDatePeriodUrlParams(): Dictionary<string> {
    return null;
  }
}
