/** Should not be included in workflow function with "task" type. */
export const WF_FUNCTION_EXCLUDED_ROLES = ['All'];

/** Should not be included in workflow function with "notification" type. */
export const WF_NOTIFICATION_EXCLUDED_ROLES = ['All'];

/** Should not be included in workflow initiators. */
export const WF_EXCLUDED_ROLES = ['Initiator'];

/** Should not be included in lifecycle performers. */
export const LC_PERFORMER_EXCLUDED_ROLES = ['Initiator'];
