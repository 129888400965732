<div [hidden]="readonly" class="input-group" ngbDropdown>
  <input
    type="text"
    class="form-control"
    (ngModelChange)="onChange($event)"
    [(ngModel)]="value"
    [attr.title]="value"
    [attr.placeholder]="placeholder"
    (blur)="onBlur()"
  />

  <button type="button" class="btn btn-default input-group-text" ngbDropdownToggle>
    &#64;{{ domain }}
  </button>
  <div ngbDropdownMenu>
    <button *ngFor="let domain of domains" ngbDropdownItem (click)="setDomain(domain)">
      {{ domain }}
    </button>
  </div>
</div>
<p *ngIf="readonly && value" class="form-control-static trim" title="{{ value }}">
  {{ value }}
</p>
