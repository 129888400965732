<wp-loading-indicator [loading]="loading"></wp-loading-indicator>

<form class="form" [hidden]="loading" novalidate autocomplete="off">
  <wp-data-not-saved-indicator [form]="substitutes"></wp-data-not-saved-indicator>
  <wp-grid
    [formArray]="substitutes"
    [options]="gridOptions"
    [readonly]="readonly"
    [loading]="loading"
    style="width: 700px; display: block"
  ></wp-grid>

  <div class="actions">
    <button
      *ngIf="!readonly && actionService.action('save').isShown"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
