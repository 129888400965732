import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Injector,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { sumBy } from 'lodash';
import { filter } from 'rxjs/operators';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { ResourceGroup } from 'src/app/projects/card/project-resource-requirements/models/view-data/resource-group.model';
import { ResourceRequirementsService } from 'src/app/projects/card/project-resource-requirements/core/resource-requirements.service';
import { UntypedFormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmtResourceRequirementsLeftGroup]',
  templateUrl: './resource-requirements-left-group.component.html',
  styleUrls: ['./resource-requirements-left-group.component.scss'],
  host: {
    class: 'group-left',
  },
})
export class ResourceRequirementsLeftGroupComponent implements OnInit {
  @Input({ required: true }) public group: ResourceGroup;

  public selectControl: UntypedFormControl = new UntypedFormControl();

  private destroyRef = inject(DestroyRef);

  trackById = (_: number, row: any): string => row.taskId;

  constructor(
    public service: ResourceRequirementsService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.selectControl.setValue(this.group.states.selected, {
      emitEvent: false,
    });

    this.selectControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((v) => {
        this.group.states.selected = v;
        this.service.selected$.next(this.group.id);
      });

    this.service.toggle$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((id) => id === this.group.id),
      )
      .subscribe(() => {
        this.cdr.detectChanges();
      });

    this.service.changes$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.selectControl.setValue(this.group.states.selected, {
          emitEvent: false,
        });
        this.recalculateTotals();
        this.cdr.detectChanges();
      });

    this.recalculateTotals();
  }

  /** Expands or collapses group. */
  public expandGroup() {
    this.group.isExpanded = !this.group.isExpanded;
    this.service.toggleGroup(this.group.id);
  }

  /** Expands or collapses group estimate. */
  public expandGroupEstimate() {
    this.group.isEstimateExpanded = !this.group.isEstimateExpanded;
    this.service.toggleGroup(this.group.id);
  }

  /** Recalculates tasks totals. */
  public recalculateTotals() {
    this.group.estimateTotal = sumBy(this.group.tasks, 'total');
  }

  /** Opens user info popup. */
  public openUserInfo(userId: string) {
    /* Привязка к элементу, у которого виден центр. По умолчанию span */
    const userNameDiv = document.getElementById(userId);
    const userNameDivWidth = userNameDiv.offsetWidth;

    const userNameSpan = document.getElementById(`${userId}-name`);
    const userNameSpanWidth = userNameSpan.offsetWidth;

    const target =
      userNameDivWidth < userNameSpanWidth ? userNameDiv : userNameSpan;

    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  protected readonly ResourceType = ResourceType;
}
