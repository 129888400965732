<div class="toolbar-container">
  <div class="group">
    @if (!(gridService.readonly$ | async)) {
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.create' | translate }}"
        [disabled]="!gridService.canBeExecuted('create')"
        (click)="gridService.execute('create')"
        data-test="create"
      >
        <span class="bi bi-plus-lg bi-15"></span>
        &nbsp;{{ 'shared.actions.addLine' | translate }}
      </button>
    }

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.edit' | translate }}"
      [disabled]="!gridService.canBeExecuted('edit', gridService.selectedGroupValue?.id)"
      (click)="gridService.execute('edit', gridService.selectedGroup$.getValue())"
      data-test="edit"
    >
      <span class="bi bi-pencil"></span>
      &nbsp;{{ 'shared.actions.edit' | translate }}
    </button>
  </div>
  <div class="ms-auto">
    <button
      type="button"
      class="btn btn-default"
      (click)="gridService.execute('setUserView')"
      title="{{ 'shared.actions.setView' | translate }}"
      data-test="viewSettings"
    >
      <i class="bi bi-gear" aria-hidden="true"></i>
    </button>
  </div>
</div>
