<div [formGroup]="form">
  <div class="row">
    <div class="col">
      <div class="toolbar-container">
        <tmt-overview-switcher></tmt-overview-switcher>
        <div class="group">
          <div ngbDropdown class="d-inline-flex" placement="bottom-left">
            <button type="button" class="btn btn-default" ngbDropdownToggle>
              {{ getTypeTitle(service.settings.type) }}
            </button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="service.setType(kpiType.Estimate)">
                {{ getTypeTitle(kpiType.Estimate) }}
              </button>
              <button ngbDropdownItem (click)="service.setType(kpiType.Plan)">
                {{ getTypeTitle(kpiType.Plan) }}
              </button>
              <button ngbDropdownItem (click)="service.setType(kpiType.Actual)">
                {{ getTypeTitle(kpiType.Actual) }}
              </button>
              <button ngbDropdownItem (click)="service.setType(kpiType.Forecast)">
                {{ getTypeTitle(kpiType.Forecast) }}
              </button>
            </div>
          </div>

          <div *ngIf="entityCollection === 'Projects'" ngbDropdown class="d-inline-flex">
            <button type="button" class="btn btn-default" ngbDropdownToggle>
              {{ getCurrencyTitle(service.settings.currency) }}
            </button>

            <div ngbDropdownMenu class="dropdown-scroll">
              <button ngbDropdownItem (click)="setOverviewCurrency(currencyModeProject)">
                {{ 'shared.pnlStatement.currency.project' | translate }}
              </button>

              <button ngbDropdownItem (click)="setOverviewCurrency(currencyModeBase)">
                {{ 'shared.pnlStatement.currency.base' | translate }}
              </button>
            </div>
          </div>

          <div ngbDropdown class="d-inline-flex" placement="bottom-left">
            <button type="button" class="btn btn-default" ngbDropdownToggle>
              {{ getPeriodTypeTitle(service.settings.periodType) }}
            </button>
            <div ngbDropdownMenu>
              <button
                ngbDropdownItem
                (click)="service.setPeriodType(kpiPeriodType.onTheCurrentDate)"
              >
                {{ getPeriodTypeTitle(kpiPeriodType.onTheCurrentDate) }}
              </button>

              <button
                ngbDropdownItem
                (click)="service.setPeriodType(kpiPeriodType.onTheEndOfProject)"
              >
                {{ getPeriodTypeTitle(kpiPeriodType.onTheEndOfProject) }}
              </button>

              <button ngbDropdownItem (click)="service.setPeriodType(kpiPeriodType.onTheDate)">
                {{ getPeriodTypeTitle(kpiPeriodType.onTheDate) }}
              </button>

              <button ngbDropdownItem (click)="service.setPeriodType(kpiPeriodType.forThePeriod)">
                {{ getPeriodTypeTitle(kpiPeriodType.forThePeriod) }}
              </button>
            </div>
          </div>

          <wp-date-period-box
            class="d-inline-block"
            *ngIf="service.settings.periodType === kpiPeriodType.forThePeriod"
            formControlName="period"
            style="width: 300px"
            placeholder="{{ 'projects.overview.filter.period' | translate }}"
          ></wp-date-period-box>

          <wp-date-box
            *ngIf="service.settings.periodType === kpiPeriodType.onTheDate"
            formControlName="date"
            class="d-inline-block"
          ></wp-date-box>
        </div>
        <tmt-task-box
          *ngIf="entityCollection === 'Projects'"
          formControlName="task"
          [projectId]="service.entityId"
          [projectVersion]="projectVersion"
          [showMainTask]="false"
          style="width: 350px"
          placeholder="{{ 'shared.pnlStatement.filter.properties.task.placeholder' | translate }}"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-3 g-3" *ngFor="let card of service.cards">
      <wp-kpi-card [model]="card"></wp-kpi-card>
    </div>
  </div>
</div>
