import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ScheduleNavigationComponent } from './schedule-navigation.component/schedule-navigation.component';

@NgModule({
  declarations: [ScheduleNavigationComponent],
  providers: [],
  imports: [CommonModule, SharedModule],
  exports: [ScheduleNavigationComponent],
})
export class ScheduleNavigationModule {}
