import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppName } from 'src/app/shared/globals/app-name';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { StateService, TransitionService } from '@uirouter/core';
import { AppService } from 'src/app/core/app.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { DataService } from 'src/app/core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { Exception } from 'src/app/shared/models/exception';
import { DateTime } from 'luxon';
import { ExpensesRequestService } from 'src/app/expenses-requests/expenses-request.service';
import { Subject } from 'rxjs';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

import { ExpensesRequest } from 'src/app/shared/models/entities/base/expenses-request.model';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntil } from 'rxjs/operators';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

@Component({
  selector: 'wp-expenses-request-creation',
  templateUrl: './expenses-request-creation.component.html',
  styleUrls: ['./expenses-request-creation.component.scss'],
})
export class ExpensesRequestCreationComponent implements OnInit, OnDestroy {
  public isSaving = false;
  public userIsAllowed: boolean;
  public userQuery: any;

  public form = this.fb.group({
    user: [this.app.session.user, Validators.required],
    project: [null, Validators.required],
  });

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public expensesService: ExpensesRequestService,
    private fb: UntypedFormBuilder,
    private navigation: NavigationService,
    transitionService: TransitionService,
    private app: AppService,
    private notification: NotificationService,
    private message: MessageService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
    private customFieldService: CustomFieldService,
  ) {
    transitionService.onSuccess({}, () => this.cancel());
    this.customFieldService.enrichFormGroup(this.form, 'ExpenseRequest', true);
    this.customFieldService.enrichFormGroupWithDefaultValues(
      this.form,
      'ExpenseRequest',
    );
  }

  ngOnInit(): void {
    this.setUserAllowed();
    this.expensesService.loadUserInfoProjects(this.form.value.user);

    this.form.controls.user.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.form.controls.project.setValue(null);
        this.expensesService.loadUserInfoProjects(this.form.value.user);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    const formData = this.form.value as ExpensesRequest;

    this.isSaving = true;

    const data = {
      date: DateTime.now().toISODate(),
      name: 'new',
      projectId: this.form.value.project.id,
      userId: this.form.value.user.id,
    };

    this.customFieldService.assignValues(data, formData, 'ExpenseRequest');

    this.data
      .collection('ExpenseRequests')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notification.successLocal('expenses.creation.messages.created');
          this.isSaving = false;
          this.state.go(`expensesRequest`, {
            entityId: response.id,
            routeMode: RouteMode.continue,
          });
          this.activeModal.close(response);
        },
        error: (error: Exception) => {
          this.message.error(error.message);
          this.isSaving = false;
        },
      });
  };

  cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  /**
   * Checks if Applicant change to other than the current User is allowed or not.
   * */
  private setUserAllowed(): void {
    if (this.navigation.getAppName() === AppName.My) {
      this.userIsAllowed = false;
      return;
    }

    if (
      this.app.checkPermission('ExpenseRequest', 'All', PermissionType.Modify)
    ) {
      this.userIsAllowed = true;
    } else {
      if (
        this.app.checkPermission(
          'ExpenseRequest',
          'MySubordinates',
          PermissionType.Modify,
        )
      ) {
        this.userIsAllowed = true;
        this.userQuery = {
          filter: [
            {
              supervisors: {
                any: {
                  id: { type: 'guid', value: this.app.session.user.id },
                },
              },
            },
          ],
        };
      }
    }
  }
}
