import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { sumBy } from 'lodash';
import { filter, takeUntil } from 'rxjs/operators';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { CellsOrchestratorService } from 'src/app/shared/services/cell-orhestrator/cells-orchestrator.service';
import { ResourceGroup } from 'src/app/projects/card/project-resource-requirements/models/view-data/resource-group.model';
import { ResourceRequirementsService } from 'src/app/projects/card/project-resource-requirements/core/resource-requirements.service';
import { ResourceRequirementsDataService } from 'src/app/projects/card/project-resource-requirements/core/resource-requirements-data.service';
import { Subject } from 'rxjs';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmtResourceRequirementsRightGroup]',
  templateUrl: './resource-requirements-right-group.component.html',
  styleUrls: ['./resource-requirements-right-group.component.scss'],
  providers: [CellsOrchestratorService],
})
export class ResourceRequirementsRightGroupComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) group: ResourceGroup;
  @Input({ required: true }) valueMode: ValueMode;
  @Input({ required: true }) scale: PlanningScale;
  @Input() isShowTaskDuration: boolean;

  public formLines: UntypedFormArray = this.fb.array([]);
  public formBooking: UntypedFormArray = this.fb.array([]);
  public propagationMode = PropagationMode;

  private wasBuilt = false;
  private destroyed$ = new Subject<void>();

  public trackById = (_: number, row: any): string => row.id;
  public trackByValueId = (_: number, row: any): string => row.value.id;

  constructor(
    public service: ResourceRequirementsService,
    public projectCardService: ProjectCardService,
    private dataService: ResourceRequirementsDataService,
    private cellsOrchestrator: CellsOrchestratorService,
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private cellsOrchestratorService: CellsOrchestratorService,
  ) {}

  ngOnInit(): void {
    this.service.toggle$
      .pipe(
        takeUntil(this.destroyed$),
        filter((id) => id === this.group.id),
      )
      .subscribe(() => this.rebuild());
    this.service.changes$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.rebuild();
      this.recalculateTotals();
    });

    this.cellsOrchestratorService.massValueChangeInProgress$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((inProgress: boolean) => {
        if (inProgress) {
          this.dataService.isAddToSavePrevented = inProgress;
          return;
        }
        if (!inProgress && this.dataService.isAddToSavePrevented) {
          this.dataService.saveCurrentEntriesToSave();
        }
      });

    this.rebuild();
    this.recalculateTotals();
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * Indicates that requirement hours exceeds available resource capacity.
   *
   * @param hours hours of total cell.
   * @param index cell's number in row.
   * @returns `true` if hours exceeds, `false` otherwise.
   */
  public isRequirementOverAvailable(hours: number, index: number): boolean {
    return (
      this.group.resource &&
      hours > 0 &&
      hours > this.group.availabilityHours[index]?.hours
    );
  }

  /** Calculates totals by lines & slots.  */
  private recalculateTotals() {
    //Estimate totals
    this.group.estimateTotals.forEach((t) => {
      t.hours = 0;
    });
    this.group.tasks.forEach((line) => {
      line.total = line.extraTotal;
      for (let entryIndex = 0; entryIndex < line.entries.length; entryIndex++) {
        const entry = line.entries[entryIndex];
        if (entry.hours > 0) {
          this.group.estimateTotals[entryIndex].hours += entry.hours;
          line.total += entry.hours;
        }
      }
    });
    //Totals
    this.group.totals.forEach((t, i) => {
      t.hours = this.group.estimateTotals[i].hours;
    });
    if (this.group.resource?.type === 'User') {
      this.group.estimateTotals.forEach((t, index) => {
        this.group.totals[index].hours =
          this.group.estimateTotals[index].hours -
          this.group.bookedHours[index].hours;
      });
    }
    this.group.estimateTotal = sumBy(this.group.tasks, 'total');
    this.changeDetector.detectChanges();
  }

  /** Rebuilds form controls. */
  private rebuild() {
    if (!this.group.isExpanded || !this.group.isEstimateExpanded) {
      return;
    }
    if (!this.wasBuilt) {
      this.wasBuilt = true;
      this.rebuildBooking();
      this.rebuildLines();
    }
    if (this.wasBuilt) {
      this.updateBooking();
      this.updateLines();
    }
    this.recalculateTotals();
  }

  /** Rebuilds tasks form controls. */
  private rebuildLines() {
    this.formLines = this.fb.array([]);
    for (const line of this.group.tasks) {
      const array = this.fb.array([]);
      this.formLines.push(array);

      if (this.projectCardService.isLineBlocked(line)) {
        array.disable({ emitEvent: false });
      }
      for (const entry of line.entries) {
        const control = this.fb.control(entry);
        if (this.projectCardService.isEntryBlocked(entry)) {
          control.disable();
        }
        array.push(control);
        control.valueChanges.subscribe(() => {
          this.dataService.addEntryToQueue(
            control.value,
            line.taskId,
            this.group.teamMemberId,
            this.scale,
          );
        });
      }
    }
    this.cellsOrchestrator.init();

    this.formLines.valueChanges.subscribe(() => {
      this.recalculateTotals();
    });

    //TODO: c этим определенно нужно что-то придумать
    // if (!this.group.isEditable || this.service.readonly) {
    //   this.formLines.disable({ emitEvent: false });
    // }

    this.changeDetector.detectChanges();
  }

  /** Updates tasks form controls. */
  private updateLines() {
    this.group.tasks.forEach((line, lineIndex) => {
      let formLine = this.formLines.at(lineIndex) as UntypedFormArray;
      if (!formLine) {
        this.wasBuilt = false;
        this.rebuildLines();
        formLine = this.formLines.at(lineIndex) as UntypedFormArray;
      }
      const isLineBlocked = this.projectCardService.isLineBlocked(line);
      isLineBlocked
        ? formLine.disable({ emitEvent: false })
        : formLine.enable({ emitEvent: false });

      line.entries.forEach((entry, entryIndex) => {
        const formEntry = formLine.at(entryIndex);
        if (formEntry) {
          formEntry.setValue(entry, { emitEvent: false });
          if (!isLineBlocked) {
            this.projectCardService.isEntryBlocked(entry)
              ? formEntry.disable({ emitEvent: false })
              : formEntry.enable({ emitEvent: false });
          }
        } else {
          const control = this.fb.control(entry);
          if (this.projectCardService.isEntryBlocked(entry)) {
            control.disable({ emitEvent: false });
          }
          formLine.push(control, { emitEvent: false });
          control.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
              this.dataService.addEntryToQueue(
                control.value,
                line.taskId,
                this.group.teamMemberId,
                this.service.planningScale,
              );
            });
        }
      });
    });
  }

  /** Rebuilds booking form controls. */
  private updateBooking() {
    this.group.bookedHours.forEach((book, bookIndex) => {
      const formEntry = this.formBooking.at(bookIndex);
      if (formEntry) {
        formEntry.setValue(book, { emitEvent: false });
      } else {
        const control = this.fb.control(book);
        this.formBooking.push(control, { emitEvent: false });
        //TODO: сохранение и права на редактирование букинга
        // control.valueChanges.subscribe(() => {
        //   this.dataService.addEntryToQueue(
        //     control.value,
        //     book.taskId,
        //     this.group.teamMemberId,
        //     this.service.planningScale
        //   );
        // });
      }
    });
  }

  /** Updates booking form controls. */
  private rebuildBooking() {
    this.formBooking = this.fb.array([]);
    for (const book of this.group.bookedHours) {
      const control = this.fb.control(book);
      this.formBooking.push(control);
      control.valueChanges.subscribe(() => {
        this.dataService.addEntryToQueue(
          control.value,
          book.taskId,
          this.group.teamMemberId,
          this.scale,
        );
      });
    }
    this.cellsOrchestrator.init();

    this.formLines.valueChanges.subscribe(() => {
      this.recalculateTotals();
    });

    //TODO: c этим определенно нужно что-то придумать
    // if (!this.group.isEditable || this.service.readonly) {
    //   this.formLines.disable({ emitEvent: false });
    // }

    this.changeDetector.detectChanges();
  }
}
