import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ProjectSummaryResourcePlanViewSettings } from 'src/app/project-summary/shared/project-summary-view-settings/models/project-summary-resource-plan-view-settings.settings';

/** Provides methods to work with Project Summary View Settings. */
@Injectable()
export class ProjectSummaryViewSettingsService {
  /** Gets View Settings. */
  public get settings(): ProjectSummaryResourcePlanViewSettings {
    return this._settings;
  }

  private settingsSubject =
    new Subject<ProjectSummaryResourcePlanViewSettings>();
  public settings$ = this.settingsSubject.asObservable();

  private _settings: ProjectSummaryResourcePlanViewSettings;

  constructor(private localConfigService: LocalConfigService) {
    this._settings = this.localConfigService.getConfig(
      ProjectSummaryResourcePlanViewSettings,
    );
  }

  /**
   * Saves the settings and propagates the change to other components.
   *
   * @param settings Settings.
   * */
  public propagateSettingsChange(
    settings: ProjectSummaryResourcePlanViewSettings,
  ): void {
    this._settings = settings;
    this.settingsSubject.next(settings);
    this.saveSettings();
  }

  /** Saves the settings. */
  private saveSettings(): void {
    this.localConfigService.setConfig(
      ProjectSummaryResourcePlanViewSettings,
      this.settings,
    );
  }
}
