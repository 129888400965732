import { EnumEntity } from './enum-entity';

export class ProjectBillingType extends EnumEntity {
  public static get nonBillable(): ProjectBillingType {
    return {
      id: '4d1a525f-3abc-4871-a64a-349c1dd3cabf',
      code: 'NonBillable',
      name: 'enums.projectBillingType.nonBillable',
    };
  }

  public static get tm(): ProjectBillingType {
    return {
      id: '584dddc1-94df-43b2-b3f3-372c02fcb016',
      code: 'TM',
      name: 'enums.projectBillingType.TM',
    };
  }

  public static get fixedBid(): ProjectBillingType {
    return {
      id: 'e87e0e6b-c034-45ac-8b74-bd0256f3f535',
      code: 'FixedBid',
      name: 'enums.projectBillingType.fixedBid',
    };
  }
}

export const ProjectBillingTypes: ProjectBillingType[] = [
  ProjectBillingType.fixedBid,
  ProjectBillingType.tm,
  ProjectBillingType.nonBillable,
];
