import { NamedEntity } from '../named-entity.model';
import { CodedEntity } from '../coded-entity.model';
import { Entity } from '../entity.model';
import { ProjectTask } from '../projects/project-task.model';
import { CustomizableEntity } from '../customizable-entity.interface';
import { CurrencyEntity } from '../currency-entity.model';
import { Currency } from '../settings/currency.model';

/** Заявка на затраты. */
export class ExpensesRequest extends NamedEntity implements CustomizableEntity {
  static approvedId = 'dc2ab1ca-a20f-48c7-8832-3bea459436fc';

  /** Описание */
  description: string;

  /** Проект */
  project: CurrencyEntity;

  /** Project cost center */
  projectCostCenter: NamedEntity;

  /** Project cost center id */
  projectCostCenterId: string;

  /** Дата заявки */
  date: string;

  /** Общая сумма заявки */
  totalAmount: number;

  /** Общая сумма возмещения (автору заявки) */
  totalReimbursement: number;

  /** Общая оплачиваемая (клиентом) сумма */
  totalBillable: number;

  /** Автор заявки */
  user: NamedEntity;

  /** Expenses legal entity */
  legalEntity: NamedEntity;

  /** Автор заявки */
  state: CodedEntity;

  submitted: string;
  approved: string;

  lines: ExpenseRequestLine[];

  /** Reimbursement currency. */
  reimbursementCurrency: Currency;

  // Customizable properties
  stringValue1?: string;
  stringValue2?: string;
  stringValue3?: string;
  stringValue4?: string;
  stringValue5?: string;

  decimalValue1?: number;
  decimalValue2?: number;
  decimalValue3?: number;
  decimalValue4?: number;
  decimalValue5?: number;

  dateValue1?: string;
  dateValue2?: string;
  dateValue3?: string;
  dateValue4?: string;
  dateValue5?: string;

  lookupValue1?: NamedEntity;
  lookupValue2?: NamedEntity;
  lookupValue3?: NamedEntity;
  lookupValue4?: NamedEntity;
  lookupValue5?: NamedEntity;

  integerValue1?: number;
  integerValue2?: number;
  integerValue3?: number;
  integerValue4?: number;
  integerValue5?: number;
}

/** Линии заявки на затраты. */
export class ExpenseRequestLine extends Entity implements CustomizableEntity {
  /** Число единиц */
  quantity: number;

  /** Единица измерения */
  unit: string;

  /** Валюта строки ЗнЗ */
  currency: Currency;

  /** ID валюты строки ЗнЗ */
  currencyId: string;

  /** Обменный курс строки ЗрЗ */
  exchangeRate: number;

  /** Цена единицы */
  rate: number;

  /** Сумма в валюте затраты */
  amount: number;

  /** Сумма в базовой валюте */
  amountBC: number;

  /** Сумма в валюте проекта */
  amountPC: number;

  /** Sum in reimbursement currency. */
  amountRC: number;

  /** Дата, на которую были понесены затраты */
  date: string;

  /** Тип затрат */
  expenseType: CodedEntity;

  /** Задача проекта */
  projectTask: ProjectTask;

  /** Описание */
  description: string;

  /** Требуется возмещение */
  reimburse: boolean;

  /** Оплачиваемое */
  billable: boolean;

  /** Вложение */
  attachment: any;

  // Customizable properties
  stringValue1?: string;
  stringValue2?: string;
  stringValue3?: string;
  stringValue4?: string;
  stringValue5?: string;

  decimalValue1?: number;
  decimalValue2?: number;
  decimalValue3?: number;
  decimalValue4?: number;
  decimalValue5?: number;

  dateValue1?: string;
  dateValue2?: string;
  dateValue3?: string;
  dateValue4?: string;
  dateValue5?: string;

  lookupValue1?: NamedEntity;
  lookupValue2?: NamedEntity;
  lookupValue3?: NamedEntity;
  lookupValue4?: NamedEntity;
  lookupValue5?: NamedEntity;

  integerValue1?: number;
  integerValue2?: number;
  integerValue3?: number;
  integerValue4?: number;
  integerValue5?: number;
}
