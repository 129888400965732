export interface TenantOverview {
  state: TenantState;
  billingType: TenantBillingType;
  currentBalance: number;
  trialExpiryDate: Date;
  ownerName: string;
  ownerEmail: string;
  ownerPhone: string;
  countryCode: string;
  currencyCode: string;
}

export enum TenantState {
  active = 'Active',
  suspended = 'Suspended',
  archived = 'Archived',
}

export enum TenantBillingType {
  trial = 'Trial',
  license = 'License',
  freemium = 'Freemium',
}
