import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { merge } from 'rxjs';

import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { AbstractToolbar } from 'src/app/shared-features/grid2/abstract-toolbar.directive';

import { ProjectTariffsService } from 'src/app/projects/card/project-tariffs/services/project-tariffs.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';

@Component({
  selector: 'tmt-project-tariffs-toolbar',
  templateUrl: './project-tariffs-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectTariffsToolbarComponent
  extends AbstractToolbar
  implements OnInit
{
  public isActiveControl = new UntypedFormControl(false);

  constructor(
    public projectCardService: ProjectCardService,
    protected override gridService: GridService,
    protected override cdr: ChangeDetectorRef,
    private projectTariffService: ProjectTariffsService,
  ) {
    super(gridService, cdr);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    merge(this.gridService.detectChanges$, this.gridService.selectedGroup$)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cdr.detectChanges());

    this.isActiveControl.setValue(
      this.projectTariffService.settings.onlyActive,
      {
        emitEvent: false,
      },
    );

    this.isActiveControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.projectTariffService.saveSettings({ onlyActive: value });
        this.projectTariffService.load();
      });
  }
}
