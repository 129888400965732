import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { UserSettingsModalService } from './user-settings-modal.service';
import { AppService } from 'src/app/core/app.service';
import { AuthProviderType } from 'src/app/shared/models/enums/auth-provider-type.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthProvider } from 'src/app/shared/models/entities/settings/auth-provider.model';
import {
  TabItem,
  TabName,
} from 'src/app/shared/components/features/user-settings-modal/model/user-settings-modal.interface';

/** Диалог личных настроек пользователя. */
@Component({
  selector: 'wp-user-settings-modal',
  templateUrl: './user-settings-modal.component.html',
  providers: [UserSettingsModalService],
})
export class UserSettingsModalComponent implements OnInit, OnDestroy {
  @Input() public activeTab: TabName;

  public isLoading = true;
  private destroyed$ = new Subject<void>();

  public tabs: TabItem[] = [
    {
      header: 'shared.userSettings.tabs.profile',
      name: 'profile',
      isVisible: true,
    },
    {
      header: 'shared.userSettings.tabs.security',
      name: 'security',
      isVisible: true,
    },
    {
      header: 'shared.userSettings.tabs.settings',
      name: 'properties',
      isVisible: true,
    },
    {
      header: 'shared.userSettings.tabs.notifications',
      name: 'notifications',
      isVisible: true,
    },
    {
      header: 'shared.userSettings.tabs.substitutes',
      name: 'substitutes',
      isVisible: true,
    },
  ];

  private _externalUserTabNamesToExclude = ['security'];

  get visibleTabs() {
    return this.tabs.filter((t) => t.isVisible === true);
  }

  constructor(
    public cardService: UserSettingsModalService,
    private app: AppService,
    private activeModal: NgbActiveModal,
    private notification: NotificationService,
  ) {}

  public onNavChange(changeEvent: NgbNavChangeEvent) {
    this.activeTab = changeEvent.nextId;
  }

  public ok() {
    this.cardService.saveCurrentTab();
  }

  public cancel() {
    this.activeModal.dismiss();
  }

  /** Устанавливает видимые вкладки в зависимости от провайдера. */
  private setVisibleTabsByAuthProvider() {
    this.cardService
      .getUserAuthProviderObservable(this.app.session.user.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (authProvider: AuthProvider) => {
          if (authProvider.type !== AuthProviderType.Local) {
            this.tabs
              .filter(
                (t) =>
                  this._externalUserTabNamesToExclude.find(
                    (x) => x === t.name,
                  ) !== undefined,
              )
              .forEach((tab) => {
                tab.isVisible = false;
              });
          }

          this.isLoading = false;
        },
        error: (error: Exception) => {
          this.isLoading = false;
          this.notification.error(error.message);
        },
      });
  }

  ngOnInit() {
    this.setVisibleTabsByAuthProvider();

    if (this.visibleTabs.length > 0) {
      this.activeTab ??= this.visibleTabs[0].name;
    }
    this.cardService.close$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.cancel());
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
