import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'wp-rule-empty-lines',
  templateUrl: './rule-empty-lines.component.html',
})
export class RuleEmptyLinesComponent implements OnInit {
  @Output() changes = new EventEmitter<string>();
  @Input() data: string;
  @Input() readonly: boolean;
  public form = this.fb.group({});

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.form.patchValue(JSON.parse(this.data));

    this.form.valueChanges.subscribe(() => {
      const data = JSON.stringify(this.form.value);
      this.changes.emit(data);
    });
  }
}
