import { Pipe, PipeTransform } from '@angular/core';

/** Приведение числа к значению без знака. */
@Pipe({
  name: 'unsigned',
})
export class UnsignedPipe implements PipeTransform {
  transform(value: number): number {
    return Math.abs(value);
  }
}
