import { Component, OnInit, Input } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { cloneDeep } from 'lodash';
import { NavigationService } from 'src/app/core/navigation.service';
import {
  GridNavigationColumn,
  NavigationType,
} from 'src/app/shared-features/grid2/models/grid-column.interface';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

@Component({
  selector: 'tmt-resource-navigation-cell',
  templateUrl: './resource-navigation-cell.component.html',
  styleUrls: ['./resource-navigation-cell.component.scss'],
})
export class ResourceNavigationCellComponent implements OnInit {
  @Input() row: any;
  @Input() column: GridNavigationColumn;

  public uiParams: any;
  public resourceType = ResourceType;
  public navigationType = NavigationType;

  constructor(
    private navigationService: NavigationService,
    private uiRouterGlobals: UIRouterGlobals,
  ) {}

  public ngOnInit(): void {
    this.uiParams = {
      entityId: this.row.id,
      routeMode: RouteMode.continue,
    };

    if (!this.uiParams.navigation) {
      this.uiParams.navigation = this.uiRouterGlobals.params?.navigation;
    }

    if (!this.uiParams.route && this.navigationService.storedRoute.length) {
      this.uiParams.route = cloneDeep(this.navigationService.storedRoute);
    }
  }
}
