import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { ResourceSummaryFilterSettings } from 'src/app/resource-summary/shared/resource-summary-filter/models/resource-summary-filter-settings';
import { catchError, map } from 'rxjs/operators';
import { Interval } from 'luxon';
import { ResourceSummaryRepresentationService } from 'src/app/resource-summary/shared/resource-summary-representation-settings/core/resource-summary-representation.service';
import { DataService } from 'src/app/core/data.service';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { Exception } from 'src/app/shared/models/exception';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import {
  ResourceSummaryEntryMap,
  ResourceSummaryEntryTypeMap,
} from 'src/app/resource-summary/models/resource-summary-view.model';
import {
  ResourceSummaryPageDto,
  ResourceSummaryResourceEntryDto,
  ResourceSummaryResourceFilter,
} from 'src/app/resource-summary/models/resource-summary-data.model';

/** Provides methods to work with Resource Summary REST API. */
@Injectable()
export class ResourceSummaryDataService {
  /** Gets Value Mode. */
  public get valueMode(): ValueMode {
    return this._valueMode;
  }

  /** Sets Value Mode. */
  public set valueMode(value: ValueMode) {
    this._valueMode = value;
  }

  /** Gets Resource Summary Pages. */
  public get summaryPages(): ResourceSummaryPageDto[] {
    return this._summaryPages;
  }

  /** Gets Resource Summary Entries. */
  public get entries(): ResourceSummaryEntryMap {
    return this._entries;
  }

  /** Gets Resource Summary Entry types. */
  public get entryTypes(): ResourceSummaryEntryTypeMap {
    return this._entryTypes;
  }

  private _valueMode: ValueMode;
  private _summaryPages: ResourceSummaryPageDto[];
  private _entries: ResourceSummaryEntryMap;
  private _entryTypes: ResourceSummaryEntryTypeMap = {
    timeOff: 'timeOff',
    schedule: 'schedule',
  };

  constructor(
    private data: DataService,
    private summaryRepresentationService: ResourceSummaryRepresentationService,
  ) {}

  /** Inits Service. */
  public init(): void {
    this._summaryPages = [];
    this._entries = {};
  }

  /**
   * Loads Resource Summary page.
   *
   * @param pageNumber Page Number.
   * @param pageSize Page Size.
   * @param filter Filter.
   * @param kpiTypes KPI Types.
   * @param valueMode Value Mode.
   * @returns {ResourceSummaryPageDto} list observable.
   * */
  public loadResourceSummaryPage(
    pageNumber: number,
    pageSize: number,
    filter: ResourceSummaryFilterSettings,
    kpiTypes: KpiType[],
    valueMode: ValueMode,
  ): Observable<ResourceSummaryPageDto[]> {
    const params: Record<string, any> = {
      pageNumber,
      pageSize,
      kpiTypes: '@kpiTypes',
      valueMode: `'${valueMode}'`,
      filter: '@filter',
    };
    const filterObject = <ResourceSummaryResourceFilter>{
      projectId: filter.project?.id ?? null,
      programId: filter.program?.id ?? null,
      organizationId: filter.client?.id ?? null,
      resourcePoolId: filter.resourcePool?.id ?? null,
      departmentId: filter.department?.value?.id ?? null,
      roleId: filter.role?.id ?? null,
      levelId: filter.level?.id ?? null,
      locationId: filter.location?.id ?? null,
      searchText: filter.searchText,
      mySubordinates: filter.isMySubordinates,
      teamOfMyProjects: filter.isMyProjects,
      includeSubDepartments: filter.department?.includeSubordinates,
      includeNonActiveResources:
        this.summaryRepresentationService.settings.show?.inactiveResources ??
        false,
      includeNonActiveProjects:
        this.summaryRepresentationService.settings.show?.inactiveProjects ??
        false,
    };

    const urlParams: Record<string, string> = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@filter': JSON.stringify(filterObject),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@kpiTypes': JSON.stringify(kpiTypes),
    };

    return this.data.model
      .function('GetResourceSummaryPage')
      .query<ResourceSummaryPageDto[]>(params, null, urlParams)
      .pipe(
        catchError((error: Exception) => throwError(() => error)),
        map((val) => {
          const resources = val as ResourceSummaryPageDto[];
          this._summaryPages = this._summaryPages.concat(resources);
          return resources;
        }),
      );
  }

  /**
   * Loads Resource Summary entries frame.
   *
   * @param resourceIds Resource IDs.
   * @param interval Interval.
   * @param planningScale Planning Scale.
   * @param kpiTypes KPI Types.
   * @param valueMode Value Mode.
   * @returns {Observable} after list merged with new frame.
   * */
  public loadFrame = (
    resourceIds: string[],
    interval: Interval,
    planningScale: PlanningScale,
    kpiTypes: KpiType[],
    valueMode: ValueMode,
  ): Observable<void> =>
    this.loadResourceSummaryEntries(
      resourceIds,
      interval,
      planningScale,
      kpiTypes,
      valueMode,
    ).pipe(map(() => void 0));

  /**
   * Loads Resource Summary entries.
   *
   * @param resourceIds Resource IDs.
   * @param interval Interval.
   * @param planningScale Planning Scale.
   * @param kpiTypes KPI Types.
   * @param valueMode Value Mode.
   * @returns {ResourceSummaryResourceEntryDto} list observable.
   * */
  public loadResourceSummaryEntries(
    resourceIds: string[],
    interval: Interval,
    planningScale: PlanningScale,
    kpiTypes: KpiType[],
    valueMode: ValueMode,
  ): Observable<ResourceSummaryResourceEntryDto[]> {
    if (!resourceIds.length || !kpiTypes.length) {
      return of([]);
    }

    const params: Record<string, any> = {
      resourcesIds: '@resourcesIds',
      from: interval.start.toISODate(),
      to: interval.end.toISODate(),
      planningScale: `'${planningScale}'`,
      kpiTypes: '@kpiTypes',
      includeNonActiveProjects: `${
        this.summaryRepresentationService.settings.show?.inactiveProjects ??
        false
      }`,
      valueMode: `'${valueMode}'`,
    };

    const urlParams: Record<string, string> = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@resourcesIds': JSON.stringify(resourceIds),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@kpiTypes': JSON.stringify(kpiTypes),
    };

    return this.data.model
      .function('GetResourceSummaryEntries')
      .query<ResourceSummaryResourceEntryDto[]>(params, null, urlParams)
      .pipe(
        catchError((error: Exception) => throwError(() => error)),
        map((val) => {
          const entries = val;
          entries.forEach((entry) => {
            if (!entry.projectId) {
              entry.projectId = entry.isTimeOffValue
                ? this.entryTypes.timeOff
                : this.entryTypes.schedule;
            }
            this.entries[entry.resourceId] ??= {};
            this.entries[entry.resourceId][entry.projectId] ??= [];
            const existedEntry = this.entries[entry.resourceId][
              entry.projectId
            ].find((e) => JSON.stringify(e) === JSON.stringify(entry));
            if (!existedEntry) {
              this.entries[entry.resourceId][entry.projectId].push(entry);
            }
          });
          return entries;
        }),
      );
  }

  /**
   * Gets Resource Summary entries.
   *
   * @param resourceId Resource ID.
   * @param [projectId] Project ID.
   * @returns {ResourceSummaryResourceEntryDto} list.
   * */
  public getResourceSummaryEntries(
    resourceId: string,
    projectId: string | null,
  ): ResourceSummaryResourceEntryDto[] {
    const projectIdWithTimeOff = projectId ?? this.entryTypes.timeOff;
    if (
      !this.entries[resourceId] ||
      !this.entries[resourceId][projectIdWithTimeOff]
    ) {
      return [];
    }
    return this.entries[resourceId][projectIdWithTimeOff];
  }
}
