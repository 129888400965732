<wp-loading-indicator [loading]="loading"></wp-loading-indicator>
<form class="form col-xl-8 col-lg-10 px-0" [hidden]="loading" [formGroup]="form" novalidate>
  <wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>
  <div style="width: 400px">
    <div class="form-group">
      <label>{{ 'settings.users.card.timesheets.props.template.label' | translate }}</label>
      <wp-select-box
        collection="TimeSheetTemplates"
        formControlName="timeSheetTemplate"
        placeholder="{{ 'settings.users.card.timesheets.props.template.ph' | translate }}"
      >
      </wp-select-box>
      <span class="form-text text-body-secondary">{{
        'settings.users.card.timesheets.props.template.help' | translate
      }}</span>
    </div>
  </div>
  <div *ngIf="activitiesAreVisible">
    <label class="group">
      {{ 'settings.users.card.timesheets.sectionActivity' | translate }}
    </label>

    <div class="form-check form-group">
      <input
        formControlName="restrictActivities"
        type="checkbox"
        class="form-check-input"
        id="restrictActivities"
      />
      <label class="form-check-label" for="restrictActivities">
        {{ 'settings.users.card.timesheets.props.restrictActivities' | translate }}
      </label>
    </div>

    <wp-grid
      [hidden]="!form.getRawValue().restrictActivities"
      #grid
      [formArray]="form.controls['activities']"
      [options]="gridOptions"
      [readonly]="!activitiesAreEditable"
      style="width: 400px; display: block"
    ></wp-grid>

    <div class="actions">
      <button
        *ngIf="!readonly || activitiesAreEditable"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="isSaving"
        (click)="save()"
      >
        {{ 'shared.actions.save' | translate }}
      </button>
    </div>
  </div>
</form>
