import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { PermissionSetCardComponent } from './card/permission-set-card.component';
import { PermissionSetCreationComponent } from './creation/permission-set-creation.component';
import { PermissionSetListComponent } from './list/permission-set-list.component';
import { PermissionSetList } from 'src/app/shared/lists/permission-set.list';
import { PermissionSetProjectManagerComponent } from './card/permission-set-project-manager/permission-set-project-manager.component';
import { PermissionSetTeamManagerComponent } from './card/permission-set-team-manager/permission-set-team-manager.component';
import { PermissionSetResourceManagerComponent } from './card/permission-set-resource-manager/permission-set-resource-manager.component';
import { PermissionSetUserComponent } from './card/permission-set-user/permission-set-user.component';
import { PermissionSetAdministratorComponent } from './card/permission-set-administrator/permission-set-administrator.component';
import { PermissionSetBillingManagerComponent } from './card/permission-set-billing-manager/permission-set-billing-manager.component';
import { PermissionSetFinanceManagerComponent } from './card/permission-set-finance-manager/permission-set-finance-manager.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    PermissionSetListComponent,
    PermissionSetCardComponent,
    PermissionSetCreationComponent,
    PermissionSetProjectManagerComponent,
    PermissionSetTeamManagerComponent,
    PermissionSetResourceManagerComponent,
    PermissionSetBillingManagerComponent,
    PermissionSetUserComponent,
    PermissionSetAdministratorComponent,
    PermissionSetFinanceManagerComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.permissionSets',
          url: '/permission-sets',
          component: PermissionSetListComponent,
          params: {
            navigation: 'settings.permissionSets',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: PermissionSetList },
          ],
        },

        {
          name: 'settings.permissionSet',
          params: {
            navigation: 'settings.permissionSets',
          },
          url: '/permission-sets/{entityId:guid}',
          component: PermissionSetCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class PermissionSetsModule {}
