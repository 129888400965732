import { Component, Input } from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tmt-booking-mode-change-dialog',
  templateUrl: './booking-mode-change-dialog.component.html',
})
export class BookingModeChangeDialogComponent {
  @Input() public currentBookingMode: string;
  @Input() public newBookingMode: string;

  public isSaving = false;
  constructor(
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
  ) {}

  /** Sends request to change booking mode. */
  public ok = (): void => {
    this.isSaving = true;

    this.data
      .collection('TenantSettings')
      .action('ChangeBookingMode')
      .execute()
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.settings.tenantSettings.booking.modeChanged',
          );
          this.isSaving = false;
          location.reload();
          this.activeModal.close();
        },
        error: (error: any) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
