import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { BookingScheduleComponent } from 'src/app/booking/booking-schedule/booking-schedule.component';
import { BookingModule } from 'src/app/booking/booking/booking.module';
import { FILTER } from 'src/app/shared/tokens';

@NgModule({
  declarations: [BookingScheduleComponent],

  imports: [
    CommonModule,
    SharedModule,
    BookingModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'booking',
          url: '/booking?{navigation}&{route}',
          component: BookingScheduleComponent,
          params: {
            routeMode: null,
            navigation: 'resources.booking',
            route: ROUTE_DEFAULT_PARAMS,
            routeHeader: 'resources.navigation.resources.booking.header',
          },
        },
        {
          name: 'filteredBooking',
          url: '/booking/filtered?{filter:json}&{navigation}&{route}',
          component: BookingScheduleComponent,
          params: {
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
            routeHeader: 'resources.navigation.resources.booking.header',
          },
          resolve: [
            {
              token: FILTER,
              deps: [Transition],
              resolveFn: StateHelper.resolveFilter,
            },
          ],
        },
      ],
    }),
  ],
  exports: [],
})
export class BookingScheduleModule {}
