<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'analytics.dashboards.widgetSettings.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
      <li [ngbNavItem]>
        <a ngbNavLink>{{ 'analytics.dashboards.widgetSettings.tabs.main' | translate }}</a>
        <ng-template ngbNavContent>
          <wp-widget-properties [form]="form" [types]="types"></wp-widget-properties>
        </ng-template>
      </li>

      <li [ngbNavItem] *ngIf="hasDataTab">
        <a ngbNavLink>{{ 'analytics.dashboards.widgetSettings.tabs.data' | translate }}</a>
        <ng-template ngbNavContent>
          <wp-report-view-settings
            [viewSettings]="widget.viewSettings"
            [type]="widget.type"
            (changes)="onViewSettingChanged($event)"
            (typeChanges)="onReportTypeChanged($event)"
          >
          </wp-report-view-settings>
        </ng-template>
      </li>

      <li [ngbNavItem] *ngIf="hasFilterTab">
        <a ngbNavLink>{{ 'analytics.dashboards.widgetSettings.tabs.filter' | translate }}</a>
        <ng-template ngbNavContent>
          <wp-report-filters
            [reportTypeCode]="widget.viewSettings.sourceName"
            [filters]="widget.filters"
            (onFiltersChanged)="onFiltersChanged($event)"
          ></wp-report-filters>
        </ng-template>
      </li>

      <li [ngbNavItem] *ngIf="hasPeriodTab">
        <a ngbNavLink>{{ 'analytics.dashboards.widgetSettings.tabs.period' | translate }}</a>
        <ng-template ngbNavContent>
          <wp-report-period> </wp-report-period>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="pt-3"></div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
