import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { TemplateService } from 'src/app/core/template.service';

@Component({
  selector: 'wp-quick-start',
  templateUrl: './quick-start.component.html',
  styleUrls: ['./quick-start.component.scss'],
})
export class QuickStartComponent implements OnInit {
  @Input() name: string;

  public content$: Observable<SafeHtml>;

  constructor(
    private activeModal: NgbActiveModal,
    public templateService: TemplateService,
  ) {}

  public close() {
    this.activeModal.dismiss();
  }

  public ok() {
    this.activeModal.close();
  }

  ngOnInit(): void {
    this.content$ = this.templateService.getHtml('quick-start-' + this.name);
  }
}
