import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { AttachmentsManagerService } from 'src/app/shared-features/comments/core/attachments-manager.service';
import { CommentsSaveService } from 'src/app/shared-features/comments/core/comments-save.service';
import { CommentsDataService } from 'src/app/shared-features/comments/core/comments-data.service';
import { CommentsService } from 'src/app/shared-features/comments/core/comments.service';

@Component({
  selector: 'wp-attachments-tab',
  templateUrl: './attachments-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CommentsSaveService,
    AttachmentsManagerService,
    CommentsDataService,
    CommentsService,
  ],
})
export class AttachmentsTabComponent implements OnInit {
  @Input() entityId: string;
  @Input() collection: string;

  constructor(public attachmentsManager: AttachmentsManagerService) {
    this.attachmentsManager.attachmentDeleted$
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.attachmentsManager.reload();
      });
  }

  public ngOnInit(): void {
    this.attachmentsManager.init(this.collection, this.entityId);
    this.attachmentsManager.reload();
  }
}
