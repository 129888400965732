<wp-loading-indicator [loading]="loading$ | async"></wp-loading-indicator>

<form class="modal-form" novalidate ngDraggable [hidden]="loading$ | async">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'shared.selectingOfSubstitute.header' | translate }}
    </h3>

    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body" style="min-height: 210px">
    <wp-grid [formArray]="formArray" [options]="gridOptions"></wp-grid>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="gridService.selectedRow$ | async"
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      style="min-width: 110px"
    >
      {{ 'shared.actions.ok' | translate }}
    </button>

    <button type="button" class="btn btn-default" (click)="cancel()" style="min-width: 110px">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
