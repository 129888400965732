import {
  DestroyRef,
  inject,
  Inject,
  Injectable,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { ResourceSummaryDataService } from 'src/app/resource-summary/core/resource-summary-data.service';
import { DateTime, DurationLike, Interval } from 'luxon';
import { ResourceSummaryViewSettingsService } from 'src/app/resource-summary/shared/resource-summary-view-settings/core/resource-summary-view-settings.service';
import { ResourceSummaryFilterService } from 'src/app/resource-summary/shared/resource-summary-filter/core/resource-summary-filter.service';
import { ResourceSummaryViewSettings } from 'src/app/resource-summary/models/resource-summary-view.settings';
import { filter, switchMap, takeUntil, throttleTime } from 'rxjs/operators';
import { ResourceSummaryRepresentationService } from 'src/app/resource-summary/shared/resource-summary-representation-settings/core/resource-summary-representation.service';
import {
  Slot,
  SlotGroup,
} from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ChromeService } from 'src/app/core/chrome.service';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { Exception } from 'src/app/shared/models/exception';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Provides methods to work with Resource Summary component. */
@Injectable()
export class ResourceSummaryService implements OnDestroy {
  /**
   * Indicates whether all rows are expanded or collapsed.
   *
   * @returns `true` if all rows are expanded, `false` otherwise.
   * */
  public get isAllExpanded(): boolean {
    return (
      this.expandedResourceIds.size ===
      this.summaryDataService.summaryPages.length
    );
  }

  /** Gets Selected KPI Types. */
  public get selectedKpiTypes(): KpiType[] {
    return Object.keys(this.summaryViewSettingsService.settings.show).filter(
      (key) => this.summaryViewSettingsService.settings.show[key],
    ) as KpiType[];
  }

  /** Gets Selected KPI Types without Schedule data. */
  public get selectedKpiTypesWithoutSchedule(): KpiType[] {
    return this.selectedKpiTypes.filter(
      (key) => KpiType[key] !== KpiType.Scheduled,
    ) as KpiType[];
  }

  /**
   * Indicates whether Actual KPI type is selected or not.
   *
   * @returns `true` if selected, `false` otherwise.
   * */
  public get isActualKpiTypeSelected(): boolean {
    return this.selectedKpiTypes.some((key) => KpiType[key] === KpiType.Actual);
  }

  /** Gets Summary Slot Groups. */
  public get slotGroups(): SlotGroup[] {
    return this._slotGroups;
  }

  /** Gets Summary Slots. */
  public get slots(): Slot[] {
    return this._slots;
  }

  /** Gets Summary View Settings. */
  public get settings(): ResourceSummaryViewSettings {
    return this._settings;
  }

  /** Gets Slot width. */
  public get slotWidth(): number {
    return this.getSlotWidth(this._settings.planningScale);
  }

  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  private detectChangesSubject = new Subject<string>();
  public detectChanges$ = this.detectChangesSubject.asObservable();

  private recalculateGroupSubject = new Subject<{
    id: string;
    rebuild?: boolean;
  }>();
  public recalculateGroup$ = this.recalculateGroupSubject.asObservable();

  private toggleGroupSubject = new BehaviorSubject<{
    id: string;
    state: boolean;
  }>(null);
  public toggleGroup$ = this.toggleGroupSubject.asObservable();

  private readonly scrollThrottlingDuration = 100;
  private readonly pageSize = 30;
  private pageNumber: number;
  private expandedResourceIds: Set<string> = new Set();

  private interval: Interval;
  private interval$ = new Subject<Interval>();

  private _allAreLoaded: boolean;
  private _slots: Slot[];
  private _slotGroups: SlotGroup[];
  private _settings: ResourceSummaryViewSettings;
  private _settingsCopy: Record<KpiType, boolean>;

  private pageReloaded$ = new Subject<void>();

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private summaryDataService: ResourceSummaryDataService,
    private summaryViewSettingsService: ResourceSummaryViewSettingsService,
    private summaryRepresentationService: ResourceSummaryRepresentationService,
    private summaryFilterService: ResourceSummaryFilterService,
    private scheduleNavigationService: ScheduleNavigationService,
    private localConfigService: LocalConfigService,
    private freezeTableService: FreezeTableService,
    private chrome: ChromeService,
    private blockUI: BlockUIService,
    private notification: NotificationService,
  ) {}

  ngOnDestroy(): void {
    this.pageReloaded$.next();
  }

  /** Inits Service. */
  public init(): void {
    this._settingsCopy = { ...this.summaryViewSettingsService.settings.show };
    this._settingsCopy[KpiType.Scheduled] = null;
    this._settings = this.localConfigService.getConfig(
      ResourceSummaryViewSettings,
    );
    this.interval = this.scheduleNavigationService.getInterval(
      this._settings.planningScale,
    );
    this.summaryDataService.valueMode = this._settings.valueMode;

    this.initSubscriptions();
    this.enableLoadingOnScroll();
    this.reload();
  }

  /**
   * Reloads the data.
   *
   * @param [toDate] End Date.
   * */
  public reload(toDate?: DateTime): void {
    this.loadingSubject.next(true);
    this.pageReloaded$.next();
    this.toggleGroupSubject.next(null);

    this.summaryDataService.init();

    this.interval = this.scheduleNavigationService.getInterval(
      this._settings.planningScale,
      toDate,
    );
    this.updateDates();
    this._allAreLoaded = false;
    this.pageNumber = 0;

    this.loadResourceSummaryPage();
  }

  /** Toggles all Resource rows. */
  public toggleAllResources(): void {
    const state = !this.isAllExpanded;
    this.summaryDataService.summaryPages.forEach((item) => {
      if (state && !this.expandedResourceIds.has(item.id)) {
        this.toggleGroup(item.id, state);
      }

      if (!state && this.expandedResourceIds.has(item.id)) {
        this.toggleGroup(item.id, state);
      }
    });

    this.detectChangesSubject.next(null);
  }

  /**
   * Toggles Resource Group.
   *
   * @param id Group ID.
   * @param state State flag.
   * */
  public toggleGroup(id: string, state: boolean): void {
    if (state) {
      this.expandedResourceIds.add(id);
    } else {
      this.expandedResourceIds.delete(id);
    }

    this.toggleGroupSubject.next({ id, state });
  }

  /**
   * Recalculates Resource Group.
   *
   * @param id Group ID.
   * @param [rebuild=false] Indicates whether Group rebuild is required or not.
   * */
  public recalculateGroup(id: string, rebuild = false): void {
    this.recalculateGroupSubject.next({ id, rebuild });
  }

  /**
   * Triggers Resource Group change detection.
   *
   * @param id Group ID.
   * */
  public detectGroupChanges(id: string): void {
    this.detectChangesSubject.next(id);
  }

  /**
   * Sets Value Mode.
   *
   * @param valueMode New Value Mode.
   * */
  public setValueMode(valueMode: ValueMode): void {
    this._settings.valueMode = valueMode;
    this.summaryDataService.valueMode = this._settings.valueMode;
    this.reload();
  }

  /**
   * Sets Planning Scale.
   *
   * @param planningScale New Planning Scale.
   * */
  public setPlanningScale(planningScale: PlanningScale): void {
    this._settings.planningScale = planningScale;
    this.interval = this.scheduleNavigationService.getInterval(
      this._settings.planningScale,
    );

    this.updateDates();
    this.reload();
  }

  /**
   * Calculates slot width by planning scale.
   *
   * @returns Slot width.
   * @throws Error if scale is invalid.
   * */
  public getSlotWidth(scale: PlanningScale): number {
    switch (scale) {
      case PlanningScale.Day:
        return 75;
      case PlanningScale.Week:
        return 85;
      case PlanningScale.Month:
        return 90;
      default:
        throw new Error(`Invalid scale: ${scale}`);
    }
  }

  /**
   * Calculates minimal table width.
   *
   * @returns Minimal table width.
   * */
  public getDataTableWidth = (): number | null =>
    this.slots ? this.slotWidth * this.slots.length + 1 : null;

  /** Inits subscriptions. */
  private initSubscriptions(): void {
    this.scheduleNavigationService.previous$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.loadFrame('left');
      });

    this.scheduleNavigationService.next$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.loadFrame('right');
      });

    this.scheduleNavigationService.jump$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((date) => {
        this.reload(date);
      });

    this.scheduleNavigationService.planningScale$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((planningScale) => {
        this.setPlanningScale(planningScale);
      });

    this.scheduleNavigationService.valueMode$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((valueMode) => {
        this.setValueMode(valueMode);
      });

    this.summaryViewSettingsService.settings$
      .pipe(
        filter((viewSettings) => {
          const oldSettings = {
            ...this._settingsCopy,
          };

          const newSettings = {
            ...viewSettings.show,
          };

          oldSettings[KpiType.Scheduled] = null;
          newSettings[KpiType.Scheduled] = null;

          this._settingsCopy = {
            ...viewSettings.show,
          };

          const shouldReload =
            JSON.stringify(oldSettings) !== JSON.stringify(newSettings);

          if (!shouldReload) {
            // Update component if non-reloadable KPI types have been updated.
            this.detectChangesSubject.next(null);
          }

          return shouldReload;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.reload();
      });

    this.summaryRepresentationService.settings$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reload();
      });

    this.summaryFilterService.filter$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reload();
      });
  }

  /** Enables endless page scrolling. */
  private enableLoadingOnScroll(): void {
    const container = this.document.getElementById('main-area');
    const lengthThreshold = 150;
    let lastRemaining = 9999;
    this.chrome.scroll$
      .pipe(
        throttleTime(this.scrollThrottlingDuration, null, {
          leading: true,
          trailing: true,
        }),
        filter(
          () =>
            this._allAreLoaded === false &&
            this.loadingSubject.getValue() === false,
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        const remaining =
          container.scrollHeight -
          (container.clientHeight + container.scrollTop);
        if (remaining < lengthThreshold && remaining - lastRemaining < 0) {
          this.pageNumber++;

          this.loadResourceSummaryPage();
        }
        lastRemaining = remaining;
      });
  }

  /** Loads Resource Summary page. */
  private loadResourceSummaryPage(): void {
    if (!this.selectedKpiTypes.length) {
      this.loadingSubject.next(false);
      this.detectChangesSubject.next(null);
      return;
    }

    this.loadingSubject.next(true);
    this.detectChangesSubject.next(null);
    this.summaryDataService
      .loadResourceSummaryPage(
        this.pageNumber,
        this.pageSize,
        this.summaryFilterService.currentFilter,
        this.selectedKpiTypes,
        this._settings.valueMode,
      )
      .pipe(
        switchMap((resources) => {
          this._allAreLoaded = this.pageSize > resources.length;
          this.loadingSubject.next(false);
          this.blockUI.start();

          this.detectChangesSubject.next(null);

          if (resources.length) {
            return this.summaryDataService.loadResourceSummaryEntries(
              resources.map((r) => r.id),
              this.interval,
              this._settings.planningScale,
              this.selectedKpiTypes,
              this._settings.valueMode,
            );
          } else {
            return of([]);
          }
        }),
        takeUntil(this.pageReloaded$),
      )
      .subscribe({
        next: () => {
          this.summaryDataService.summaryPages.forEach((r) => {
            this.recalculateGroup(r.id, true);
          });

          for (const id of this.expandedResourceIds) {
            if (
              !this.summaryDataService.summaryPages.find((r) => r.id === id)
            ) {
              this.expandedResourceIds.delete(id);
              continue;
            }
            this.toggleGroup(id, true);
          }

          this.blockUI.stop();
          this.detectChangesSubject.next(null);
        },
        error: (error: Exception) => {
          this.loadingSubject.next(false);
          this.blockUI.stop();
          this.notification.error(error.message);
        },
      });
  }

  /**
   * Loads Resource Summary entries frame.
   *
   * @param direction Frame Direction.
   * */
  private loadFrame(direction: 'left' | 'right'): void {
    this.blockUI.start();

    const shift = this.getFrameShift();

    let loadingInterval =
      direction === 'left'
        ? Interval.fromDateTimes(
            this.interval.start.minus(shift),
            this.interval.start.minus({ days: 1 }),
          )
        : Interval.fromDateTimes(
            this.interval.end.plus({ days: 1 }),
            this.interval.end.plus(shift),
          );

    this.interval =
      direction === 'left'
        ? this.interval.set({
            start: this.interval.start.minus(shift),
          })
        : this.interval.set({
            end: this.interval.end.plus(shift),
          });

    if (
      this._settings.planningScale === PlanningScale.Month &&
      direction === 'right'
    ) {
      loadingInterval = loadingInterval.set({
        end: loadingInterval.end.endOf('month'),
      });
      this.interval = this.interval.set({
        end: this.interval.end.endOf('month'),
      });
    }

    this.summaryDataService
      .loadFrame(
        this.summaryDataService.summaryPages.map((r) => r.id),
        loadingInterval,
        this._settings.planningScale,
        this.selectedKpiTypes,
        this._settings.valueMode,
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.updateDates();

          this.summaryDataService.summaryPages.forEach((p) => {
            this.recalculateGroup(p.id, true);
          });

          this.blockUI.stop();
          this.detectChangesSubject.next(null);
          setTimeout(() => {
            this.freezeTableService.disableMutationObserver();
            if (direction === 'left') {
              this.freezeTableService.scrollToLeft();
            } else {
              this.freezeTableService.scrollToRight();
            }
            setTimeout(() => {
              this.freezeTableService.enableMutationObserver();
            }, 500);
          }, 10);
        },
        error: (error: Exception) => {
          this.blockUI.stop();
          this.notification.error(error.message);
        },
      });
  }

  /**
   * Gets Frame duration shift.
   *
   * @returns {DurationLike} Frame duration shift.
   * @throws Error if scale is invalid.
   * */
  private getFrameShift(): DurationLike {
    const scale = this._settings.planningScale;
    switch (scale) {
      case PlanningScale.Day:
        return { weeks: 2 };
      case PlanningScale.Week:
        return { weeks: 10 };
      case PlanningScale.Month:
        return { month: 5 };
      default:
        throw new Error(`Invalid scale: ${scale}`);
    }
  }

  /** Updates Slots dates. */
  private updateDates(): void {
    const slotInfo = this.scheduleNavigationService.getSlots(
      this.interval,
      this._settings.planningScale,
    );

    this._slotGroups = slotInfo.groups;
    this._slots = slotInfo.slots;

    this.interval$.next(this.interval);
  }
}
