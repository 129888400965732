<ng-container *ngIf="form">
  <label class="group"> {{ 'settings.permissionSets.granules.groupProjects' | translate }}</label>
  <table class="table table-bordered compact-table" style="table-layout: fixed; width: 850px">
    <thead>
      <tr>
        <th style="width: 250px; vertical-align: middle" rowspan="2">
          {{ 'settings.permissionSets.granules.granular' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.myProjects' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.myProjectsOfMyClients' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.projectsOfMyPrograms' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.allProjects' | translate }}
        </th>
      </tr>
      <tr>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ 'settings.permissionSets.granules.projects' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Project.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Project.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Project.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Project.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Project.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Project.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Project.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Project.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.projectCoManagers' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCoManager.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCoManager.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCoManager.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCoManager.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCoManager.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCoManager.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCoManager.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCoManager.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.projectTasks' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTask.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTask.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTask.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTask.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTask.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTask.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTask.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTask.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.projectTariff' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTariff.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTariff.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTariff.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTariff.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTariff.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTariff.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTariff.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTariff.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.projectTeam' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeam.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeam.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeam.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeam.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeam.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeam.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeam.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeam.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.projectTeamCost' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeamCost.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeamCost.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeamCost.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeamCost.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeamCost.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeamCost.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeamCost.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectTeamCost.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>{{ 'settings.permissionSets.granules.projectExpenseEstimation' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectExpenseEstimate.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectExpenseEstimate.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectExpenseEstimate.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectExpenseEstimate.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectExpenseEstimate.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectExpenseEstimate.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectExpenseEstimate.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectExpenseEstimate.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>{{ 'settings.permissionSets.granules.projectBillingEstimation' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectBillingEstimate.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectBillingEstimate.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectBillingEstimate.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectBillingEstimate.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectBillingEstimate.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectBillingEstimate.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectBillingEstimate.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectBillingEstimate.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>{{ 'settings.permissionSets.granules.projectRevenueEstimate' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectRevenueEstimate.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectRevenueEstimate.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectRevenueEstimate.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectRevenueEstimate.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectRevenueEstimate.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectRevenueEstimate.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectRevenueEstimate.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectRevenueEstimate.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.projectResourcePlan' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourcePlanEntry.MyProjects.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourcePlanEntry.MyProjects.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourcePlanEntry.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourcePlanEntry.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourcePlanEntry.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourcePlanEntry.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourcePlanEntry.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourcePlanEntry.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.userSkillEstimationByProject' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('UserSkillEstimationByProject.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('UserSkillEstimationByProject.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('UserSkillEstimationByProject.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('UserSkillEstimationByProject.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('UserSkillEstimationByProject.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('UserSkillEstimationByProject.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('UserSkillEstimationByProject.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('UserSkillEstimationByProject.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>{{ 'settings.permissionSets.granules.projectVersions' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersion.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersion.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersion.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersion.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersion.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersion.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersion.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersion.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>
      <tr>
        <td>{{ 'settings.permissionSets.granules.projectCostCenter' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCostCenter.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCostCenter.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCostCenter.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCostCenter.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCostCenter.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCostCenter.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCostCenter.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectCostCenter.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.projectFinancial' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectFinancial.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center"></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectFinancial.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center"></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectFinancial.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center"></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectFinancial.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center"></td>
      </tr>
    </tbody>
  </table>

  <label class="group"> {{ 'settings.permissionSets.granules.groupPrograms' | translate }}</label>
  <table class="table table-bordered compact-table" style="table-layout: fixed; width: 650px">
    <thead>
      <tr>
        <th style="width: 250px; vertical-align: middle" rowspan="2">
          {{ 'settings.permissionSets.granules.granular' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.myPrograms' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.allPrograms' | translate }}
        </th>
      </tr>
      <tr>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ 'settings.permissionSets.granules.programs' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Program.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Program.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Program.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Program.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.projectCoManagers' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProgramCoManager.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProgramCoManager.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProgramCoManager.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProgramCoManager.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>
    </tbody>
  </table>

  <label class="group">{{ 'settings.permissionSets.granules.groupClients' | translate }}</label>
  <table class="table table-bordered compact-table" style="table-layout: fixed; width: 650px">
    <thead>
      <tr>
        <th style="width: 250px; vertical-align: middle" rowspan="2">
          {{ 'settings.permissionSets.granules.granular' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.myClients' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.allClients' | translate }}
        </th>
      </tr>
      <tr>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ 'settings.permissionSets.granules.clients' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Client.My.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Client.My.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Client.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Client.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.organizationTariff' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('OrganizationTariff.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('OrganizationTariff.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('OrganizationTariff.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('OrganizationTariff.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>
    </tbody>
  </table>

  <label class="group">
    {{ 'settings.permissionSets.granules.groupTimesheetLines' | translate }}
  </label>
  <table class="table table-bordered compact-table" style="table-layout: fixed; width: 450px">
    <thead>
      <tr>
        <th style="width: 250px; vertical-align: middle" rowspan="2">
          {{ 'settings.permissionSets.granules.granular' | translate }}
        </th>
        <th style="width: 200px; text-align: center">
          {{ 'settings.permissionSets.scopes.timesheetsWithMyProjects' | translate }}
        </th>
      </tr>
      <tr>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          {{ 'settings.permissionSets.granules.timeSheetLinePm' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('TimeSheetLinePm.TsWithMyProjects.viewEnabled')"
            type="checkbox"
          />
        </td>
      </tr>
    </tbody>
  </table>

  <label class="group">
    {{ 'settings.permissionSets.granules.groupFinance' | translate }}
  </label>
  <table class="table table-bordered compact-table" style="table-layout: fixed; width: 850px">
    <thead>
      <tr>
        <th style="width: 250px; vertical-align: middle" rowspan="2">
          {{ 'settings.permissionSets.granules.granular' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.myProjects' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.myProjectsOfMyClients' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.projectsOfMyPrograms' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.allProjects' | translate }}
        </th>
      </tr>
      <tr>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ 'settings.permissionSets.granules.expenseRequest' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ExpenseRequest.MyProjects.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ExpenseRequest.MyProjects.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ExpenseRequest.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ExpenseRequest.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ExpenseRequest.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ExpenseRequest.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ExpenseRequest.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ExpenseRequest.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>
      <tr>
        <td>{{ 'settings.permissionSets.granules.actOfAcceptance' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ActOfAcceptance.MyProjects.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ActOfAcceptance.MyProjects.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ActOfAcceptance.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ActOfAcceptance.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ActOfAcceptance.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ActOfAcceptance.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ActOfAcceptance.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ActOfAcceptance.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>{{ 'settings.permissionSets.granules.invoices' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Invoice.MyProjects.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Invoice.MyProjects.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Invoice.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Invoice.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Invoice.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Invoice.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Invoice.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Invoice.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>{{ 'settings.permissionSets.granules.accountingEntries' | translate }}</td>

        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('AccountingEntry.MyProjects.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('AccountingEntry.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('AccountingEntry.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('AccountingEntry.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>

  <label class="group">
    {{ 'settings.permissionSets.granules.groupResources' | translate }}
  </label>

  <table class="table table-bordered compact-table" style="table-layout: fixed; width: 850px">
    <thead>
      <tr>
        <th style="width: 250px; vertical-align: middle" rowspan="2">
          {{ 'settings.permissionSets.granules.granular' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.myProjects' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.myProjectsOfMyClients' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.projectsOfMyPrograms' | translate }}
        </th>
        <th style="width: 200px; text-align: center" colspan="2">
          {{ 'settings.permissionSets.scopes.allProjects' | translate }}
        </th>
      </tr>
      <tr>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.view' | translate }}
        </td>
        <td class="text-center">
          {{ 'settings.permissionSets.permissions.edit' | translate }}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ 'settings.permissionSets.granules.booking' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.MyProjects.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.MyProjects.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>&nbsp;&nbsp;&nbsp;Soft</td>
        <td class="text-center"></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.MyProjectsS.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center"></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.MyClientsS.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center"></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.MyProgramsS.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center"></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.AllS.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>
      <tr>
        <td>&nbsp;&nbsp;&nbsp;Hard</td>
        <td class="text-center"></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.MyProjectsH.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center"></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.MyClientsH.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center"></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.MyProgramsH.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center"></td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('Booking.AllH.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td colspan="9">
          {{ 'settings.permissionSets.granules.resourceRequest' | translate }}
        </td>
      </tr>

      <tr>
        <td>&nbsp;&nbsp;&nbsp;{{ 'settings.permissionSets.granules.request' | translate }}</td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequest.MyProjects.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequest.MyProjects.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequest.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequest.MyClients.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequest.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequest.MyPrograms.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequest.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequest.All.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          &nbsp;&nbsp;&nbsp;{{
            'settings.permissionSets.granules.resourceRequestResult' | translate
          }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequestResult.MyProjects.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequestResult.MyProjectsO.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequestResult.MyClients.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequestResult.MyClientsO.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequestResult.MyPrograms.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequestResult.MyProgramsO.editEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequestResult.All.viewEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ResourceRequestResult.AllO.editEnabled')"
            type="checkbox"
          />
        </td>
      </tr>
    </tbody>
  </table>

  <label class="group">
    {{ 'settings.permissionSets.granules.groupActions' | translate }}
  </label>

  <table class="table table-bordered compact-table" style="table-layout: fixed; width: 850px">
    <thead>
      <tr>
        <th style="width: 250px; vertical-align: middle">
          {{ 'settings.permissionSets.granules.granular' | translate }}
        </th>
        <th style="width: 200px; text-align: center">
          {{ 'settings.permissionSets.scopes.myProjects' | translate }}
        </th>
        <th style="width: 200px; text-align: center">
          {{ 'settings.permissionSets.scopes.myProjectsOfMyClients' | translate }}
        </th>
        <th style="width: 200px; text-align: center">
          {{ 'settings.permissionSets.scopes.projectsOfMyPrograms' | translate }}
        </th>
        <th style="width: 200px; text-align: center">
          {{ 'settings.permissionSets.scopes.allProjects' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          {{ 'settings.permissionSets.granules.changingBillingType' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ChangingBillingType.My.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ChangingBillingType.MyClients.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ChangingBillingType.MyPrograms.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ChangingBillingType.All.actionEnabled')"
            type="checkbox"
          />
        </td>
      </tr>
      <tr>
        <td>
          {{ 'settings.permissionSets.granules.bookingSwitchToHard' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('BookingSwitchToHard.MyProjects.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('BookingSwitchToHard.MyClients.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('BookingSwitchToHard.MyPrograms.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('BookingSwitchToHard.All.actionEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.bookingSwitchToSoft' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('BookingSwitchToSoft.MyProjects.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('BookingSwitchToSoft.MyClients.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('BookingSwitchToSoft.MyPrograms.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('BookingSwitchToSoft.All.actionEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.projectVersionSetMasterFlag' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersionSetMasterFlag.MyA.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersionSetMasterFlag.MyClientsA.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersionSetMasterFlag.MyProgramsA.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersionSetMasterFlag.AllA.actionEnabled')"
            type="checkbox"
          />
        </td>
      </tr>

      <tr>
        <td>
          {{ 'settings.permissionSets.granules.projectVersionMerge' | translate }}
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersionMerge.My.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersionMerge.MyClients.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersionMerge.MyPrograms.actionEnabled')"
            type="checkbox"
          />
        </td>
        <td class="text-center">
          <input
            class="form-check-input"
            [formControl]="form.get('ProjectVersionMerge.All.actionEnabled')"
            type="checkbox"
          />
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
