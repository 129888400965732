<ng-container [formGroup]="statesForm">
  <label class="control-label">
    {{ 'shared.props.state' | translate }}
  </label>

  @for (state of allStates; track state.id) {
    <div class="form-check">
      <input
        [id]="state.code"
        [formControlName]="state.code"
        (change)="toggleState($event, state.code)"
        type="checkbox"
        class="form-check-input"
      />
      <label class="form-check-label" for="{{ state.code }}">
        {{ state.name }}
      </label>
    </div>
  }
</ng-container>
