<wp-loading-indicator [loading]="loading"></wp-loading-indicator>
<form class="form col-xl-8 col-lg-10 px-0" [hidden]="loading" [formGroup]="form" novalidate>
  <wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>
  <div style="width: 400px">
    <div class="form-group">
      <label>{{ 'shared.userSettings.timeZone' | translate }}</label>
      <wp-select-box
        [allowNull]="false"
        collection="TimeZones"
        [loadLimit]="500"
        formControlName="timeZone"
      >
      </wp-select-box>
    </div>
    <div class="form-group">
      <label>{{ 'shared.userSettings.language' | translate }}</label>
      <wp-select-box
        [allowNull]="false"
        [values]="languages"
        formControlName="language"
      ></wp-select-box>
    </div>

    <div class="form-group">
      <label>{{ 'shared.userSettings.culture' | translate }}</label>
      <wp-select-box
        [allowNull]="false"
        [values]="cultures"
        [filterOnServer]="false"
        formControlName="culture"
      ></wp-select-box>
    </div>
    <div class="form-group">
      <label>{{ 'shared.userSettings.timeInputType' | translate }}</label>
      <wp-select-box [allowNull]="false" [values]="timeInputTypes" formControlName="timeInputType">
      </wp-select-box>
    </div>

    <div class="form-group form-check" style="padding-top: 15px">
      <input
        formControlName="useStopwatch"
        type="checkbox"
        class="form-check-input"
        id="useStopwatch"
      />
      <label class="form-check-label" for="useStopwatch">
        {{ 'shared.userSettings.useStopwatch' | translate }}
      </label>
    </div>
  </div>

  <div class="actions">
    <button
      *ngIf="!readonly"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
