<tmt-form-header [name]="service.taskForm.value.name" />
<wp-loading-indicator [state]="service.state$ | async" />
<tmt-not-found [state]="service.state$ | async" [urlMustContain]="entityId" />
<wp-data-not-saved-indicator [form]="service.taskForm" />

@if ((service.state$ | async) === 'Ready') {
  <form [formGroup]="service.taskForm" class="form mb-5">
    <div class="d-flex mb-4 mt-2">
      <wp-state-indicator class="me-4" />

      <div class="trim me-4">
        <span class="text-gray">{{ 'workflowTasks.card.props.created' | translate }}:</span>&nbsp;
        <div class="d-inline-block">{{ service.task?.created | date: 'short' }}</div>
      </div>

      @if (service.task?.assigned?.name) {
        <div class="trim">
          <span class="text-gray">{{ 'workflowTasks.card.props.assigned' | translate }}:</span
          >&nbsp;
          <div class="d-inline-block" id="assigned">
            <button type="button" (click)="openUserInfo()" class="btn btn-link btn-link-content">
              {{ service.task.assigned?.name }}
            </button>
          </div>
        </div>
      }
    </div>

    <div class="content">
      <div class="main">
        @if (isShowEntity && (service.entityState$ | async)) {
          <div class="alert alert-primary task-container" role="alert">
            <div class="form-group mb-0">
              <label>{{ 'workflowTasks.card.props.entity' | translate }}</label
              ><br />
              <a
                [uiSref]="(service.entityState$ | async)?.state"
                [uiParams]="(service.entityState$ | async)?.params"
                >{{ service.task.entity.name }}</a
              >
            </div>
          </div>
        }

        <div class="form-group">
          <label>{{ 'workflowTasks.card.props.description' | translate }}</label>
          <wp-multiline-text-box
            formControlName="description"
            placeholder="{{ 'workflowTasks.card.props.description' | translate }}"
          />
        </div>
      </div>

      @if (!service.task?.isActive) {
        <div class="resolution" ngbAccordion>
          <div ngbAccordionItem [collapsed]="false">
            <h4 ngbAccordionHeader>
              <button ngbAccordionButton>{{ 'base.result' | translate }}</button>
            </h4>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <div class="resolution">
                    @if (service.task?.workflowActionLabel) {
                      <div class="form-group">
                        <label>{{ 'workflowTasks.card.props.resolution' | translate }}</label>
                        <div class="form-control-plaintext trim">
                          {{ service.task?.workflowActionLabel }}
                        </div>
                      </div>

                      <div class="form-group">
                        <label>{{
                          'workflowTasks.card.props.resolutionComment' | translate
                        }}</label>
                        <wp-text-box formControlName="resolutionComment" [readonly]="true" />
                      </div>
                    }
                    <div class="form-group">
                      <label>{{ 'workflowTasks.card.props.performer' | translate }}</label>
                      <wp-user-box formControlName="performer" [readonly]="true" />
                    </div>
                    <div class="form-group">
                      <label>{{ 'workflowTasks.card.props.performed' | translate }}</label>
                      <div class="form-control-plaintext">
                        {{ service.task?.performed | date: 'short' }}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      }

      @if ((service.state$ | async) === 'Ready') {
        <div class="comments task-container mb-4">
          <ul ngbNav #nav="ngbNav" [activeId]="'comments'" class="nav nav-underline">
            <li [ngbNavItem]="'comments'">
              <a ngbNavLink>
                {{ 'shared.comments.tabs.comments' | translate }}
              </a>
              <div *ngbNavContent class="wide-tab-container mt-3">
<!--                <wp-comments-tab-->
<!--                  [entityId]="entityId"-->
<!--                  [collection]="commentedEntityCollectionType.Tasks"-->
<!--                />-->
              </div>
            </li>
            <li [ngbNavItem]="'attachments'">
              <a ngbNavLink>
                {{ 'shared.comments.tabs.attachments' | translate }}
              </a>
              <div *ngbNavContent class="wide-tab-container mt-3">
<!--                <wp-attachments-tab-->
<!--                  [entityId]="entityId"-->
<!--&lt;!&ndash;                  [collection]="commentedEntityCollectionType.WorkflowTasks"&ndash;&gt;-->
<!--                />-->
              </div>
            </li>
            <wp-sort class="ms-auto" />
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      }
    </div>
  </form>
}
