import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  forwardRef,
  inject,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Task } from '../models/task.model';
import { RoleService } from '../../core/role.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Селектор тарифа в строке таймшита. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wpTimesheetRole]',
  templateUrl: './timesheet-role.component.html',
  styleUrls: ['./timesheet-role.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimesheetRoleComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetRoleComponent implements ControlValueAccessor {
  @Input() get task(): Task {
    return this._task;
  }
  set task(value: Task) {
    this._task = value;
    this.allowed = !!value?.project;
  }

  public loading$ = new BehaviorSubject<boolean>(false);
  private destroyRef = inject(DestroyRef);

  constructor(
    public roleService: RoleService,
    private cdr: ChangeDetectorRef,
  ) {}
  @Input() timesheetId: string;

  private _task: Task;

  public allowed = false;

  public role: NamedEntity;
  public readonly: boolean;
  public areaExpanded = false;

  public roles: readonly NamedEntity[];
  public selectedRow: NamedEntity;

  public propagateChange = (_: any) => null;

  writeValue(obj: any): void {
    this.role = obj;
    this.cdr.markForCheck();
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched = (fn: any) => null;

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  /** Открыть область выбора. */
  public openArea() {
    if (this.readonly) {
      return;
    }
    this.areaExpanded = !this.areaExpanded;

    if (this.task) {
      this.loading$.next(true);
      this.roleService
        .getRoles(this.task.project.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((roles) => {
          this.roles = _.sortBy(roles, 'name');
          this.loading$.next(false);
        });
    }
  }

  public cancel() {
    if (this.areaExpanded) {
      this.areaExpanded = false;
    }
  }

  selectRow(row: NamedEntity) {
    this.selectedRow = row;
  }

  public clickRow(role: NamedEntity) {
    this.role = role;
    this.propagateChange(role);
    this.cancel();
  }
}
