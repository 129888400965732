<form [hidden]="!rateMatrixService.isLinesExisting || (isBuilding$ | async)" class="form">
  <tmt-grid [formArray]="formArray" [options]="gridOptions" [loading]="isGridLoading$ | async" />
</form>

@if (!rateMatrixService.isLinesExisting && !(isBuilding$ | async)) {
  <div class="empty-lines">
    @if (!rateMatrixService.readonly) {
      <p class="text-center">
        {{ 'settings.rateMatrices.card.empty' | translate | translateCut: 0 }}<br />
        <button class="btn btn-link btn-link-content" (click)="openMatrixRateModal()">
          <span class="fw-semibold">{{
            'settings.rateMatrices.card.empty' | translate | translateCut: 1
          }}</span>
        </button>
        {{ 'settings.rateMatrices.card.empty' | translate | translateCut: 2 }}
        <button class="btn btn-link btn-link-content" (click)="buildMatrixLines()">
          <span class="fw-semibold">{{
            'settings.rateMatrices.card.empty' | translate | translateCut: 3
          }}</span>
        </button>
        {{ 'settings.rateMatrices.card.empty' | translate | translateCut: 4 }}
      </p>
    } @else {
      <p>
        {{ 'shared.noDisplayData' | translate }}
      </p>
    }
  </div>
}

<wp-loading-indicator [loading]="isBuilding$ | async" />
