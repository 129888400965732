import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';

import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TranslateService } from '@ngx-translate/core';
import { Exception } from 'src/app/shared/models/exception';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import _ from 'lodash';
import { MetaEntityPropertyType } from 'src/app/shared/models/entities/settings/metamodel.model';
import { AppService } from 'src/app/core/app.service';

@Component({
  selector: 'wp-custom-field-creation',
  templateUrl: './custom-field-creation.component.html',
})
export class CustomFieldCreationComponent implements OnInit {
  isSaving = false;
  form: UntypedFormGroup;

  public types: NamedEntity[] = [];
  public entityTypes: NamedEntity[] = [];

  public readonly customFieldType = MetaEntityPropertyType;

  private availableCustomFieldsTypes = [
    MetaEntityPropertyType.dateOnly,
    MetaEntityPropertyType.string,
    MetaEntityPropertyType.decimal,
    MetaEntityPropertyType.integer,
    MetaEntityPropertyType.directory,
  ];

  constructor(
    fb: UntypedFormBuilder,
    translate: TranslateService,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
    private appService: AppService,
  ) {
    this.form = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      entityType: [null, [Validators.required]],
      type: [null, [Validators.required]],
      directoryId: [null, [Validators.required]],
    });

    // Заполнить список типов дополнительных полей.
    this.availableCustomFieldsTypes.forEach((type) => {
      this.types.push({
        name: translate.instant(
          'enums.metaEntityPropertyType.' + _.lowerFirst(type),
        ),
        id: type,
      });
    });

    this.types.sort(naturalSort('name'));

    this.appService.session.configuration.metamodel?.entities
      .filter((entity) => entity.customizable)
      .forEach((entity) => {
        this.entityTypes.push({
          name: translate.instant('enums.customFieldEntityType.' + entity.name),
          id: entity.name,
        });
      });

    this.entityTypes.sort(naturalSort('name'));
  }

  public ngOnInit(): void {
    this.form.controls.type.valueChanges.subscribe((type) => {
      if (type?.id === this.customFieldType.directory) {
        this.form.controls.directoryId.setValidators(Validators.required);
      } else {
        this.form.controls.directoryId.removeValidators(Validators.required);
        this.form.controls.directoryId.setValue(null);
      }
    });
  }

  public ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    if (
      this.form.value.entityType.id === 'TimeSheetLine' &&
      this.form.value.type.id !== MetaEntityPropertyType.string
    ) {
      this.notification.warningLocal(
        'settings.customFields.creation.messages.typesForLine',
      );
      return;
    }

    this.isSaving = true;

    const data = {
      name: this.form.value.name,
      type: this.form.value.type.id,
      entityType: this.form.value.entityType.id,
      directoryId: this.form.value.directoryId,
    };

    this.data
      .collection('CustomFields')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notification.successLocal(
            'settings.customFields.creation.messages.created',
          );
          this.state.go('settings.customField', { entityId: response.id });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
