import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid2/models/grid-component-cell.interface';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { GridOrchestratorService } from 'src/app/shared-features/grid2/core/grid-orchestrator.service';
import { SelectionType } from 'src/app/shared-features/grid2/models/grid-options.model';

import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import {
  defaultResourceTypes,
  ResourceType,
} from 'src/app/shared/models/enums/resource-type.enum';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { LIST } from 'src/app/shared/tokens';
import { List } from 'src/app/shared/models/inner/list';

@Component({
  selector: 'tmt-members-cell',
  templateUrl: './members-cell.component.html',
})
export class MembersCellComponent implements GridComponentCell, OnInit {
  @Input() public column: GridColumn;
  @Input() public formGroup: UntypedFormGroup;
  @Input() public initialValue: unknown;

  public autofocus =
    this.gridOrchestratorService?.options?.selectionType !== SelectionType.row;

  public get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  public get projectVersion(): ProjectVersion {
    return this.versionCardService.projectVersion;
  }

  public resourceTypes = defaultResourceTypes;
  public isAllTeamAvailable = true;

  private destroyRef = inject(DestroyRef);

  constructor(
    public gridService: GridService,
    public cdr: ChangeDetectorRef,
    private versionCardService: ProjectVersionCardService,
    private gridOrchestratorService: GridOrchestratorService,
    @Optional() private projectCardService: ProjectCardService,
    @Optional() @Inject(LIST) public list: List,
  ) {}

  public ngOnInit(): void {
    if (
      this.projectCardService?.project.isAutoPlanning &&
      this.list.name !== 'projectTariffs'
    ) {
      this.resourceTypes = defaultResourceTypes.filter(
        (type) => type !== ResourceType.department,
      );
      this.isAllTeamAvailable = false;
    }

    this.gridService.detectChanges$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }
}
