/* eslint-disable @typescript-eslint/naming-convention */
export enum KpiKind {
  Hours = 'Hours',
  Cost = 'Cost',
  Revenue = 'Revenue',
  Expenses = 'Expenses',
  Profit = 'Profit',
  Profitability = 'Profitability',
  Duration = 'Duration',
  Billing = 'Billing',
}
