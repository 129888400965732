<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'components.massOperationComponent.props.header' | translate }}
    </h3>

    <button
      [disabled]="operationInProgress"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <div>
      <wp-loading-indicator [loading]="operationInProgress" [progress]="progress" />

      @if (!operationInProgress && !massOperationCompleted) {
        {{
          'components.massOperationComponent.messages.actionsCount'
            | translate: { count: actionsCount }
        }}
      }

      @if (massOperationCompleted) {
        <div class="row">
          <div class="col-md-12">
            {{
              'components.massOperationComponent.messages.successActionsCount'
                | translate: { count: successActionsCount }
            }}
          </div>
        </div>
      }

      @if (massOperationCompleted && errorGroups.length) {
        <div class="row mt-3">
          <div class="col-md-12 mb-1 text-danger">
            {{
              'components.massOperationComponent.messages.failActionsCount'
                | translate: { count: failActionsCount }
            }}
          </div>
          <ul class="mb-1 ms-3">
            @for (group of errorGroups; track $index) {
              <li>
                {{ group.title }}
                <ul>
                  @for (el of group.items; track el) {
                    <li>
                      <div class="trim" [style.max-width]="'500px'">
                        @if (el.error.details) {
                          <i
                            class="bi bi-question-circle"
                            [ngbTooltip]="this.detailsMessage(el.error)"
                            tooltipClass="tooltip-break-n"
                          >
                          </i>
                        }
                        <a
                          uiSref="{{ state }}"
                          [uiParams]="{
                            entityId: el.item.id,
                            routeMode: stateParams.routeMode,
                            navigation: stateParams.navigation
                          }"
                        >
                          {{ el.item[entityPropertyName]?.name ?? el.item.name ?? el.item.id }}
                        </a>
                      </div>
                    </li>
                  }
                </ul>
              </li>
            }
          </ul>
        </div>
      }
    </div>
  </div>

  <div class="modal-footer">
    @if (!massOperationCompleted) {
      <button
        type="button"
        class="btn btn-primary"
        (click)="apply()"
        wpButtonSpinner
        [isBusy]="operationInProgress"
      >
        {{ 'components.massOperationComponent.actions.label' | translate }}
      </button>
    }

    @if (massOperationCompleted) {
      <button type="button" class="btn btn-primary" (click)="ok()" wpButtonSpinner>
        {{ 'shared.actions.close' | translate }}
      </button>
    }

    @if (!massOperationCompleted) {
      <button
        type="button"
        class="btn btn-default"
        (click)="cancel()"
        [disabled]="operationInProgress"
      >
        {{ 'shared.actions.cancel' | translate }}
      </button>
    }
  </div>
</form>
