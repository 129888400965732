<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'resources.booking.notifyForm.header' | translate }}
    </h3>
    <button
      [disabled]="isSending"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label class="control-label">{{
        'resources.booking.notifyForm.properties.comment' | translate
      }}</label>
      <wp-multiline-text-box
        formControlName="comment"
        placeholder="{{ 'shared.props.name' | translate }}"
      >
      </wp-multiline-text-box>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSending"
    >
      {{ 'resources.booking.notifyForm.actions.send' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSending">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
