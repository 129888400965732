import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SettingsCardService {
  private reloadTabSubject = new Subject<void>();
  public reloadTab$ = this.reloadTabSubject.asObservable();

  /**
   * Обновление данных вкладки.
   */
  public reloadTab() {
    this.reloadTabSubject.next();
  }
}
