import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {
  MenuAction,
  MenuActionsGroup,
  SubAction,
} from 'src/app/shared/models/inner/menu-action';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { isObject } from 'lodash';
import { NavigationService } from 'src/app/core/navigation.service';
import { AutosaveStateService } from 'src/app/shared/services/autosave-state.service';

/** Панель действий. */
@Component({
  selector: 'tmt-action-panel',
  templateUrl: './action-panel.component.html',
  styleUrls: ['./action-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionPanelComponent implements OnInit, OnDestroy {
  public groups: MenuActionsGroup[] = [];
  public additionalActions: SubAction[] = [];
  public isDisabled = false;
  public isSaving = false;
  public hasAutosave = false;
  public hasReloader = true;

  constructor(
    public navigationService: NavigationService,
    public autosaveStateService: AutosaveStateService,
    public actionPanelService: ActionPanelService,
    private cdr: ChangeDetectorRef,
  ) {
    actionPanelService.updated$.subscribe((groups: MenuActionsGroup[]) => {
      if (groups.flatMap((g) => g.actions).find((a) => a.isBusy)) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }

      this.groups = groups.filter((g) => g.actions.length);
      this.cdr.markForCheck();
    });

    actionPanelService.additionalUpdated$.subscribe(() => {
      this.additionalActions = actionPanelService.additionalActions;
      this.cdr.markForCheck();
    });
  }

  public ngOnInit(): void {
    this.actionPanelService.hasAutosave$.subscribe((hasAutosave) => {
      this.hasAutosave = hasAutosave;
      this.cdr.markForCheck();
    });

    this.actionPanelService.hasReloader$.subscribe((hasReloader) => {
      this.hasReloader = hasReloader;
      this.cdr.markForCheck();
    });

    this.autosaveStateService.isSaving$.subscribe((isSaving) => {
      this.isSaving = isSaving;
      this.cdr.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this.actionPanelService.setHasAutosave(false);
  }

  /** Returns `true` if action or sub-actions is visible. */
  public isVisibleElement(value: MenuAction | SubAction[]): boolean {
    if (Array.isArray(value)) {
      return !!value.find((item) => item.isVisible);
    }

    if (!value.isDropDown) {
      return value.isVisible;
    }

    if (value.isVisible) {
      return !!value.actions.find(
        (subAction) => isObject(subAction) && subAction.isVisible,
      );
    }

    return false;
  }

  /** Run action event on click. */
  public click(action: MenuAction): void {
    if (this.isDisabled || action.isDropDown) {
      return;
    }

    this.actionPanelService.runAction(action);
  }

  /** Send command to reload for `form-header`. */
  public reload(): void {
    this.actionPanelService.reload();
  }

  public isNeedDivider(index: number, actions: SubAction[]): boolean {
    return !!actions
      .slice(0, index)
      .reverse()
      .find((a) => a.isVisible && a.group !== actions[index].group);
  }
}
