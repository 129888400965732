import { Component, OnInit, OnDestroy } from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DefaultFilterService } from '../../shared/default-filter/default-filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { Subscription } from 'rxjs';
import { StateService } from '@uirouter/core';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { RoleCreationComponent } from '../creation/role-creation.component';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'wp-role-list',
  template: '<wp-entity-list></wp-entity-list> ',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
})
export class RoleListComponent implements OnInit, OnDestroy {
  selectedRowListener: Subscription;

  constructor(
    private service: EntityListService,
    private notification: NotificationService,
    private data: DataService,
    private gridService: GridService,
    private state: StateService,
    private app: AppService,
    private actions: ActionPanelService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.actions.set([
      this.actions.getDefaultAction('create', {
        title: 'settings.roles.list.actions.create',
        isVisible: this.app.checkEntityPermission(
          'Role',
          PermissionType.Modify,
        ),
        handler: this.create,
      }),
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go('settings.role', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actions.getDefaultAction('delete', {
        handler: this.service.delete,
      }),
      this.actions.getDefaultAction('setAsDefault', {
        handler: () => this.setAsDefault(),
      }),
    ]);

    this.selectedRowListener = this.gridService.selectedGroup$.subscribe(
      (row: any) => {
        this.actions.action('card').isShown = !!row;
        this.actions.action('delete').isShown =
          row && this.app.checkEntityPermission('Role', PermissionType.Modify);

        this.actions.action('setAsDefault').isShown =
          row &&
          this.app.checkEntityPermission('Role', PermissionType.Modify) &&
          !row.value.isDefault;
      },
    );
  }

  private create = () => {
    this.modalService.open(RoleCreationComponent);
  };

  private setAsDefault() {
    this.data
      .collection('Roles')
      .entity(this.gridService.selectedGroupValue.id)
      .action('WP.SetAsDefault')
      .execute()
      .subscribe({
        next: () => {
          this.notification.successLocal('settings.roles.card.setAsDefault');
          this.service.reload();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  ngOnDestroy(): void {
    this.selectedRowListener.unsubscribe();
  }
}
