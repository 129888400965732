import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';

/** Represents Project Summary Toolbar content. */
@Component({
  selector: 'tmt-project-summary-toolbar',
  templateUrl: './project-summary-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSummaryToolbarComponent {
  /** Gets Used Value Modes. */
  public get usedValueModes(): ValueMode[] {
    return [ValueMode.Hours, ValueMode.Cost];
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly PlanningScale = PlanningScale;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly ValueMode = ValueMode;
}
