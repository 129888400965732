import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

/** Настройка команды проекта. */
export class TeamSettings implements BaseSettings {
  public readonly name = 'TeamSettings';
  version: number;
  onlyActive: boolean;

  getDefault(): TeamSettings {
    return {
      version: 1,
      onlyActive: true,
    } as TeamSettings;
  }
}
