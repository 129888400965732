import { NgModule } from '@angular/core';
import { OverviewSwitcherComponent } from 'src/app/shared/components/features/overview/overview-switcher/overview-switcher.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [OverviewSwitcherComponent],
  imports: [CommonModule, SharedModule],
  exports: [OverviewSwitcherComponent],
})
export class OverviewSwitcherModule {}
