<wp-loading-indicator [state]="state$ | async" />
<tmt-not-found [state]="state$ | async" />
<wp-data-not-saved-indicator [form]="rateMatrixForm" class="mt-3" />

@if ((state$ | async) == 'Ready') {
  <form [formGroup]="rateMatrixForm" class="form matrix-form">
    <div class="form-group" tmtIndicator>
      <label>{{ 'shared.props.name' | translate }}</label>
      <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}" />
    </div>

    <div class="d-flex">
      <div class="form-group w-75">
        <label> {{ 'settings.rateMatrices.changeStructure.label' | translate }}</label>
        <div class="input-group">
          <p
            type="text trim"
            class="form-control trim m-0"
            [class.placeholder]="!rateMatrixStructure?.length"
            [title]="rateMatrixStructureTitle"
          >
            {{ rateMatrixStructureTitle }}
          </p>
          <button
            class="btn btn-default input-group-text"
            type="button"
            (click)="openMatrixRatesSettings()"
          >
            <i class="bi bi-pencil"></i>
          </button>
        </div>
      </div>

      <div class="form-group ps-4">
        <label class="control-label">{{
          'settings.rateMatrices.card.rateMatrixType' | translate
        }}</label>
        <wp-select-box formControlName="type" />
      </div>
    </div>

    <div class="d-flex">
      <div class="form-group">
        <label class="control-label">{{ 'shared.columns.effectiveDate.title' | translate }}</label>
        <wp-date-box formControlName="effectiveDate" />
      </div>

      <div class="form-group ps-4">
        <label class="control-label">{{ 'shared.columns.expiryDate.title' | translate }}</label>
        <wp-date-box formControlName="expiryDate" />
      </div>
    </div>

    <div class="form-group">
      <label>{{ 'shared.props.description' | translate }}</label>
      <wp-multiline-text-box
        formControlName="description"
        placeholder="{{ 'shared.props.description' | translate }}"
      />
    </div>

    <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
      <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
      <label class="form-check-label" for="isActive">
        {{ 'shared.props.isActive.label' | translate }}
      </label>
    </div>

    @if (!readonly) {
      <div class="actions">
        <button
          type="button"
          class="btn btn-primary"
          wpButtonSpinner
          [isBusy]="actionService.action('save').isBusy"
          (click)="save()"
        >
          {{ 'shared.actions.save' | translate }}
        </button>
      </div>
    }
  </form>
}
<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-underline mt-5">
  <li [ngbNavItem]="'activity'">
    <a ngbNavLink>
      {{ 'shared.comments.tabs.activity' | translate }}
    </a>
    <div *ngbNavContent class="wide-tab-container mt-3" style="width: 800px">
      <wp-comments-tab
        [entityId]="entityId"
        [collection]="commentedEntityCollectionType.RateMatrices"
      />
    </div>
  </li>
  <li [ngbNavItem]="'attachments'">
    <a ngbNavLink>
      {{ 'shared.comments.tabs.attachments' | translate }}
    </a>
    <div *ngbNavContent class="wide-tab-container mt-3" style="width: 800px">
      <wp-attachments-tab
        [entityId]="entityId"
        [collection]="commentedEntityCollectionType.RateMatrices"
      />
    </div>
  </li>
  <li [ngbNavItem]="'linkedTasks'">
    <a ngbNavLink>
      {{ 'base.workflow.tab' | translate }}
    </a>
    <tmt-entity-workflow-tasks *ngbNavContent [entityId]="entityId" class="mt-2 d-block" />
  </li>
  <li [ngbNavItem]="'stateHistory'">
    <a ngbNavLink>
      {{ 'base.stateHistory.tab' | translate }}
    </a>
    <tmt-state-history [entityId]="entityId" *ngbNavContent class="mt-2 d-block" />
  </li>
  <wp-sort class="ms-auto" />
</ul>

<div [ngbNavOutlet]="nav"></div>
