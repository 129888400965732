import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { RateMatrixService } from 'src/app/settings-app/rate-matrix/card/rate-matrix.service';
import { AbstractToolbar } from 'src/app/shared-features/grid2/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'tmt-tariff-rate-toolbar',
  templateUrl: './matrix-rate-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatrixRateToolbarComponent
  extends AbstractToolbar
  implements OnInit
{
  constructor(
    gridService: GridService,
    cdr: ChangeDetectorRef,
    public service: RateMatrixService,
  ) {
    super(gridService, cdr);
  }
}
