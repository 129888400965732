import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DatePipe, FormatWidth, getLocaleDateFormat } from '@angular/common';
import { DateTime } from 'luxon';

@Injectable()
export class WpParserFormatter implements NgbDateParserFormatter {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private datePipe: DatePipe,
  ) {}

  /**
   *
   * @param value {string} значение, полученное из текстового поля у dateBox
   * @returns отформатированное значение даты
   */
  parse(value: string): NgbDateStruct {
    /**
     * Удаляем лишние символы, такие как: ./-
     */

    const valueWithoutSymbols = this.replaceSymbols(value);
    if (!valueWithoutSymbols) {
      return null;
    }

    /**
     * Получаем формат даты для текущих региональных настроек
     */
    const format = getLocaleDateFormat(this.locale, FormatWidth.Short);

    /**
     * Делаем "умный" парсинг полученного значения в дату
     * Если пользователь ввел данные в формате: 22 03 2003 или 22-03-2003, 22/03/2003
     * данные преобразуютсяв 22*03*2003, где * - разделитель
     * который принят в текущих региональный настройках для
     * разделения дат
     *
     * Если пользователь ввел только день, вместо месяца и года подставляются текущие
     * Если ввел день и месяц, то подставляется текущий год
     *
     * Если пользователь ввел некорректную дату, возвращается предыдущее значение
     */
    const autocompletedValue = this.autocompleteByDateFormat(
      valueWithoutSymbols,
      format,
    );

    if (!autocompletedValue) {
      return null;
    }

    /**
     * Преобразование даты в текущий региональный формат
     */
    const date = DateTime.fromFormat(autocompletedValue, format);
    if (!date.isValid) {
      return null;
    }

    return <NgbDateStruct>{
      day: date.day,
      month: date.month,
      year: date.year,
    };
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const lDate = DateTime.utc(date.year, date.month, date.day);
    return this.datePipe.transform(lDate.toISODate(), 'shortDate');
  }

  /**
   * Функция заменяет символы: (.), (-), (/), (space) на указанный разделитель
   *
   * @param value{string} строка, в которой нужно произвести замену
   * @param replaceWith{string} разделитель
   * @returns{string} модифицированная строка
   */
  private replaceSymbols(value: string, replaceWith = '/'): string {
    const dateRegexp = /\.| |\/|-/gm;
    return value.replace(dateRegexp, replaceWith);
  }

  /**
   * @param value{string} строка для форматирования
   * @param format{string} региональный формат даты
   * @returns{string} отформатированная по региональному формату дата
   */
  private autocompleteByDateFormat(
    value: string,
    format: string,
  ): string | null {
    const newValue = value.split('/');
    const newFormat = this.unifyFormat(format).split('');

    if (newValue.length === 1 && newValue[0] === '') {
      return null;
    }

    let monthIndex = null;
    let yearIndex = null;
    let dayIndex = null;

    newFormat.forEach((formatValue, index) => {
      switch (formatValue) {
        case DateFormat.month:
          if (!newValue[index] || newValue[index] === '') {
            newValue[index] = String(new Date().getMonth() + 1);
          }
          monthIndex = index;
          break;
        case DateFormat.day:
          if (!newValue[index] || newValue[index] === '') {
            newValue[index] = String(new Date().getDate());
          }
          dayIndex = index;
          break;
        case DateFormat.year:
          if (!newValue[index] || newValue[index] === '') {
            newValue[index] = String(new Date().getFullYear());
          }
          yearIndex = index;
          break;
      }
    });

    if (newValue[yearIndex].length < 4) {
      const prevCenturyStart = 70;
      const prevCenturyEnd = 999;
      const isPrevCentury =
        +newValue[yearIndex] >= prevCenturyStart &&
        +newValue[yearIndex] <= prevCenturyEnd;

      let yearPrefix = isPrevCentury ? '1' : '2';
      const yearCurrentLength = newValue[yearIndex].length;
      for (let i = 0; i < 4 - yearCurrentLength - 1; i++) {
        yearPrefix += isPrevCentury ? '9' : '0';
      }
      newValue[yearIndex] = yearPrefix + newValue[yearIndex];
    }

    if (newValue[dayIndex].length === 1) {
      newValue[dayIndex] = '0' + newValue[dayIndex];
    }

    if (newValue[monthIndex].length === 1) {
      newValue[monthIndex] = '0' + newValue[monthIndex];
    }

    const isDayCorrect = +newValue[dayIndex] <= 31 && +newValue[dayIndex] >= 1;
    const isMonthCorrect =
      +newValue[monthIndex] <= 12 && +newValue[monthIndex] >= 1;
    const isYearCorrect =
      +newValue[yearIndex] >= 1900 && +newValue[yearIndex] <= 9999;
    if (!isDayCorrect || !isMonthCorrect || !isYearCorrect) {
      return null;
    }
    const dateSeparator = this.getDateSeparator(format);
    return newValue.join(dateSeparator);
  }

  /**
   * @returns{string} разделитель для дат в текущем региональном формате
   */
  private getDateSeparator(format: string): string {
    return format.match(/[^A-Za-z0-9]/)[0];
  }

  /**
   * @param format{string} текущий региональный формат
   * @returns{string} возвращает порядок следования частей даты
   * в текущем региональном формате
   */

  private unifyFormat(format: string) {
    format = this.replaceSymbols(format, '').toLowerCase();
    return [...new Set(format)].join('');
  }
}

export enum DateFormat {
  day = 'd',
  month = 'm',
  year = 'y',
}
