import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { NotificationService } from 'src/app/core/notification.service';
import { ExpensesTypesService } from 'src/app/core/expenses-types.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ProjectExpenseEstimate } from 'src/app/shared/models/entities/projects/project-expense-estimate.model';

@Component({
  selector: 'wp-expense-estimate-modal',
  templateUrl: './expense-estimate-modal.component.html',
})
export class ExpenseEstimateModalComponent implements OnInit {
  @Input() entry: ProjectExpenseEstimate;
  @Input() projectId: string;
  @Input() projectVersion: ProjectVersion;
  @Input() readonly: boolean;
  @Input() projectCurrencyCode: string;

  public form = this.fb.group({
    id: null,
    expenseType: [null, Validators.required],
    expenseRule: null,
    date: [null, Validators.required],
    amount: [null, Validators.required],
    description: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
    projectTask: [null, Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    public expensesTypesService: ExpensesTypesService,
    private notification: NotificationService,
  ) {}

  ngOnInit() {
    this.form.patchValue(this.entry);

    if (this.readonly) {
      this.form.disable();
    } else if (this.entry.expenseRule) {
      this.form.controls.expenseType.disable();
      this.form.controls.projectTask.disable();
    }
  }

  public ok() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.activeModal.close(this.form.getRawValue());
  }

  /** Закрыть диалог. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }
}
