<wp-loading-indicator [loading]="mergeModalService.isLoading$ | async"> </wp-loading-indicator>

<div *ngIf="!(mergeModalService.isLoading$ | async)" [formGroup]="form">
  <div class="container-fluid">
    <div class="row">
      <div class="col-auto" *ngFor="let mergeOption of mergeOptions">
        <div class="merge-option-group form-check">
          <input
            class="form-check-input"
            type="radio"
            [id]="mergeOption.id"
            formControlName="option"
            [value]="mergeOption.code"
            [attr.data-test]="mergeOption.code"
          />
          <label class="form-check-label text-nowrap" [for]="mergeOption.id">
            {{ mergeOption.name | translate }}
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        class="col-3"
        *ngFor="let formGroup of sourceFormArray?.controls; index as idx"
        [formGroup]="formGroup"
      >
        <div class="form-group">
          <label>
            {{
              (isDefaultMergeOption
                ? 'projects.projectVersions.projectVersionsMergeModal.steps.paramSelection.sourceVersion.label'
                : 'projects.projectVersions.projectVersionsMergeModal.steps.paramSelection.sourceVersions.' +
                  (idx + 1) +
                  '.label'
              ) | translate
            }}
          </label>
          <wp-select-box
            id="{{ formGroup.value.id }}"
            [formControl]="formGroup.controls.version"
            [values]="sourceControlValues[idx]"
            placeholder="{{
              (isDefaultMergeOption
                ? 'projects.projectVersions.projectVersionsMergeModal.steps.paramSelection.sourceVersion.ph'
                : 'projects.projectVersions.projectVersionsMergeModal.steps.paramSelection.sourceVersions.' +
                  (idx + 1) +
                  '.ph'
              ) | translate
            }}"
          >
          </wp-select-box>
        </div>
      </div>

      <div class="source-target-separator col-auto col-xl-auto">
        <i class="bi bi-arrow-right"></i>
      </div>

      <div class="col-3" formGroupName="target">
        <div *ngIf="isDefaultMergeOption" class="form-group">
          <label>
            {{
              'projects.projectVersions.projectVersionsMergeModal.steps.paramSelection.target.project.label'
                | translate
            }}
          </label>
          <div class="target-project-label">
            {{
              'projects.projectVersions.projectVersionsMergeModal.steps.paramSelection.target.project.ph'
                | translate
            }}
          </div>
        </div>
        <div *ngIf="isCombineMergeOption" class="form-group">
          <label>
            {{
              'projects.projectVersions.projectVersionsMergeModal.steps.paramSelection.target.version.label'
                | translate
            }}
          </label>
          <wp-text-box
            formControlName="versionName"
            [maxLength]="versionNameValidators.maxLength"
            placeholder="{{
              'projects.projectVersions.projectVersionsMergeModal.steps.paramSelection.target.version.ph'
                | translate
            }}"
          ></wp-text-box>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-auto" *ngFor="let mergeType of mergeTypes">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            [id]="mergeType.id"
            formControlName="type"
            [attr.disabled]="mergeTypeStates[mergeType.code]"
            [value]="mergeType.code"
          />
          <label class="form-check-label text-nowrap" [for]="mergeType.id">
            {{ mergeType.name | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
