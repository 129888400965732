import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { MessageService } from 'src/app/core/message.service';
import { Entity } from 'src/app/shared/models/entities/entity.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { ActOfAcceptance } from 'src/app/shared/models/entities/projects/act-of-acceptance.model';
import { ProjectBillingType } from 'src/app/shared/models/enums/project-billing-type';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wp-act-creation',
  templateUrl: './act-creation.component.html',
  styleUrls: ['./act-creation.component.scss'],
})
export class ActCreationComponent implements OnInit, OnDestroy {
  @Input() client: NamedEntity;
  @Input() project: NamedEntity;

  public isSaving = false;

  public projectsQuery = {
    filter: {
      billingTypeId: {
        ne: { type: 'guid', value: ProjectBillingType.nonBillable.id },
      },
      isActive: true,
    },
  };

  form = this.fb.group({
    project: [null, Validators.required],
    assignAutoNumber: true,
    number: ['', [Validators.maxLength(50), Validators.required]],
  });

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private message: MessageService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
    private customFieldService: CustomFieldService,
  ) {
    this.customFieldService.enrichFormGroup(this.form, 'ActOfAcceptance', true);
    this.customFieldService.enrichFormGroupWithDefaultValues(
      this.form,
      'ActOfAcceptance',
    );
  }

  ngOnInit(): void {
    this.form.controls.number.disable();
    this.form.controls.assignAutoNumber.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.form.controls.assignAutoNumber.value) {
          this.form.controls.number.disable();
        } else {
          this.form.controls.number.enable();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }
    this.isSaving = true;

    const actOfAcceptance = this.form.getRawValue() as ActOfAcceptance;

    const data = {
      projectId: this.form.value.project.id,
      number: this.form.value.assignAutoNumber ? '' : this.form.value.number,
    };

    this.customFieldService.assignValues(
      data,
      actOfAcceptance,
      'ActOfAcceptance',
    );

    this.data
      .collection('ActsOfAcceptance')
      .insert(data)
      .subscribe({
        next: (response: Entity) => {
          this.notification.successLocal('acts.creation.messages.created');

          this.state.go(`actOfAcceptance`, {
            entityId: response.id,
          });

          this.isSaving = false;
          this.activeModal.close(response);
        },
        error: (error: Exception) => {
          this.message.errorDetailed(error);
          this.isSaving = false;
        },
      });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
