import { Component, OnInit, Input, DestroyRef, inject } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { ExpenseType } from 'src/app/shared/models/entities/settings/expense-type.model';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-expense-type-card',
  templateUrl: './expense-type-card.component.html',
})
export class ExpenseTypeCardComponent implements OnInit {
  @Input() private entityId: string;

  public isLoading = false;
  public isSaving = false;

  public readonly: boolean;

  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: [
      '',
      [Validators.required, Validators.maxLength(Constants.formCodeMaxLength)],
    ],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    group: ['', Validators.maxLength(Constants.formNameMaxLength)],
    isActive: [false],
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    private navigationService: NavigationService,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
    ]);

    this.load();

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  /** Saves Data. */
  public save = (): void => {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const expenseType = <ExpenseType>this.form.value;

    const data: any = {
      name: expenseType.name,
      code: expenseType.code,
      description: expenseType.description,
      group: expenseType.group,
      accountId: expenseType.account ? expenseType.account.id : null,
      isActive: expenseType.isActive,
    };

    this.data
      .collection('ExpenseTypes')
      .entity(this.entityId)
      .patch(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal('settings.expenseTypes.card.saved');
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  };

  private load = (): void => {
    this.isLoading = true;
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.data
      .collection('ExpenseTypes')
      .entity(this.entityId)
      .get<ExpenseType>()
      .subscribe((expenseType: ExpenseType) => {
        this.readonly = !expenseType.editAllowed;
        this.form.patchValue(expenseType);

        this.actionService.action('save').isShown = !this.readonly;
        this.readonly ? this.form.disable() : this.form.enable();
        this.isLoading = false;

        this.navigationService.addRouteSegment({
          id: expenseType.id,
          title: expenseType.name,
        });
      });
  };

  private reload(): void {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }
}
