<tmt-form-header [name]="request?.name"> </tmt-form-header>
<wp-loading-indicator [state]="state$ | async"></wp-loading-indicator>
<tmt-not-found [state]="state$ | async" [urlMustContain]="entityId"></tmt-not-found>

<ng-container *ngIf="(state$ | async) === 'Ready'">
  <form [formGroup]="form" class="form">
    <div class="subheader">
      <div class="subheader__props">
        <div class="subheader__prop">
          <wp-state-indicator></wp-state-indicator>
        </div>

        <div class="subheader__prop">
          <div class="subheader__label">{{ 'timeOff.card.props.applicant' | translate }}:</div>
          &nbsp;
          <div class="subheader__value" id="request-user">
            <button type="button" (click)="openUserInfo()" class="btn btn-link btn-link-content">
              {{ request?.user?.name }}
            </button>
          </div>
        </div>

        <div class="subheader__prop">
          <tmt-workflow-indicator></tmt-workflow-indicator>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-7 col-lg-5">
          <div class="form-group">
            <label>{{ 'timeOff.card.props.timeOffType' | translate }}</label>
            <wp-select-box
              [allowNull]="false"
              collection="TimeOffTypes"
              [query]="timeOffTypeQuery"
              placeholder="{{ 'timeOff.card.props.timeOffType' | translate }}"
              formControlName="timeOffType"
            >
            </wp-select-box>
          </div>

          <div class="form-group">
            <label>{{ 'timeOff.card.props.period' | translate }}</label>
            <p
              class="m-0 pt-1"
              [attr.data-test-start]="request.startDate"
              [attr.data-test-finish]="request.finishDate"
            >
              {{ periodTitle }}
            </p>
            <button
              *ngIf="!readonly"
              type="button"
              (click)="setPeriod()"
              class="btn btn-link btn-link-content"
            >
              {{ 'timeOff.card.actions.setDates' | translate }}
            </button>
          </div>

          <div class="form-group">
            <label>{{ 'shared.props.description' | translate }}</label>
            <wp-multiline-text-box
              placeholder="{{ 'timeOff.card.props.description.ph' | translate }}"
              formControlName="description"
              [rows]="5"
            >
            </wp-multiline-text-box>
          </div>

          <div class="form-group">
            <label>{{ 'shared.props.attachment' | translate }}</label>
            <ngx-dropzone
              #drop
              class="dropzone"
              [accept]="'image/*,.doc,.docx,.pdf,.zip,.rar,.7z'"
              [multiple]="false"
              [maxFileSize]="maxAttachmentSize"
              (change)="onFileAdded($event)"
              *ngIf="!request?.attachment && !readonly"
            >
              <ngx-dropzone-label class="dropzone-label">
                <button class="btn btn-default">
                  {{ 'shared.actions.select' | translate }}
                </button>
                <span> {{ 'shared.orDragFileHere' | translate }}</span>
              </ngx-dropzone-label>
            </ngx-dropzone>

            <p *ngIf="request && request.attachment" class="form-text">
              <button class="btn btn-link p-0" (click)="openAttachment()">
                {{ request.attachment.fileName }}
              </button>
              <span *ngIf="!readonly" class="position-relative">
                <button
                  type="button"
                  class="close remove-attachment"
                  title="{{ 'shared.actions.removeAttachment' | translate }}"
                  (click)="removeAttachment()"
                >
                  &times;
                </button>
              </span>
            </p>

            <p *ngIf="readonly && !request?.attachment" class="form-text text-body-secondary">
              {{ 'shared.valueNotSelected' | translate }}
            </p>
          </div>
        </div>
        <div class="col-xs-5 col-lg-7">
          <wp-time-off-request-info
            *ngIf="form.value.timeOffType"
            [isApproved]="this.request?.state?.code === 'Approved'"
            [currentBalance]="currentBalance"
            [currentDuration]="currentDuration"
            [balance]="this.request?.balance"
            [duration]="this.request?.duration"
            [unitName]="form.value.timeOffType?.unit?.name"
          ></wp-time-off-request-info>
        </div>
      </div>
    </div>
    <wp-custom-fields
      class="d-block custom-fields-content"
      [formGroup]="form"
      entityType="TimeOffRequest"
      [isVisibleHr]="true"
    ></wp-custom-fields>
  </form>
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-underline mt-5">
    <li [ngbNavItem]="'activity'">
      <a ngbNavLink>{{ 'shared.comments.tabs.activity' | translate }}</a>
      <div class="nav-tab-content mt-3" *ngbNavContent>
        <wp-comments-tab
          [entityId]="entityId"
          [collection]="CommentedEntityCollectionType.TimeOffRequests"
        ></wp-comments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'attachments'">
      <a ngbNavLink>{{ 'shared.comments.tabs.attachments' | translate }}</a>
      <div class="nav-tab-content mt-3" *ngbNavContent>
        <wp-attachments-tab
          [entityId]="entityId"
          [collection]="CommentedEntityCollectionType.TimeOffRequests"
        ></wp-attachments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'linkedTasks'">
      <a ngbNavLink>{{ 'base.workflow.tab' | translate }}</a>
      <tmt-entity-workflow-tasks
        [entityId]="entityId"
        class="mt-2 d-block"
        *ngbNavContent
      ></tmt-entity-workflow-tasks>
    </li>
    <li [ngbNavItem]="'stateHistory'">
      <a ngbNavLink>{{ 'base.stateHistory.tab' | translate }}</a>
      <tmt-state-history
        [entityId]="entityId"
        *ngbNavContent
        class="mt-2 d-block"
      ></tmt-state-history>
    </li>
    <wp-sort class="ms-auto"></wp-sort>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
</ng-container>
