import { NavigationView } from 'src/app/shared/models/navigation/navigation';

import { Dictionary } from '../dictionary';
import { UserView } from '../inner/user-view';

/** Клиентский профиль. */
export class ClientProfile {
  /** Версия профиля. */
  public version: string;

  /** Пользовательские настройки представлений. */
  public userViews: Dictionary<Dictionary<UserView>>;

  public navigationViews: NavigationView[];

  /** Завершенные страницы обучения (быстрого старта). */
  public completedQuickStartPages?: string[];

  constructor() {
    this.userViews = {};
    this.completedQuickStartPages = [];
  }
}
