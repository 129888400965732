import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { UserListComponent } from './list/user-list.component';
import {
  LIST,
  MASS_OPERATION_PARAMETERS,
  MULTI_SELECT_LIST,
  VIEW_NAME,
} from 'src/app/shared/tokens';
import { USER_LIST } from 'src/app/shared/lists/user.list';
import { UserCardComponent } from './card/user-card.component';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { UserProfileComponent } from './card/user-profile/user-profile.component';
import { UserAccessComponent } from './card/user-access/user-access.component';
import { UserScheduleComponent } from './card/user-schedule/user-schedule.component';
import { UserTimesheetsComponent } from './card/user-timesheets/user-timesheets.component';
import { UserCostComponent } from './card/user-cost/user-cost.component';
import { UserSubstitutesComponent } from './card/user-substitutes/user-substitutes.component';
import { UserFilterComponent } from './list/user-filter/user-filter.component';
import { UserCreationComponent } from './creation/user-creation.component';
import { UserSettingsComponent } from './card/user-settings/user-settings.component';
import { UserScheduleToolbarComponent } from './card/user-schedule/user-schedule-toolbar/user-schedule-toolbar.component';
import { UserActivityToolbarComponent } from './card/user-timesheets/user-activity-toolbar/user-activity-toolbar.component';
import { UserSubstitutesToolbarComponent } from './card/user-substitutes/user-substitutes-toolbar/user-substitutes-toolbar.component';
import { PasswordResetModalComponent } from './card/user-profile/password-reset-modal/password-reset-modal.component';
import { UserPersonalScheduleModalComponent } from './card/user-schedule/user-personal-schedule-modal/user-personal-schedule-modal.component';
import { UserPersonalScheduleComponent } from './card/user-schedule/user-personal-schedule/user-personal-schedule.component';
import { UsersEditingComponent } from './list/users-editing/users-editing.component';
import { UserRolesComponent } from './card/user-roles/user-roles.component';
import { ResourceRolesModule } from 'src/app/shared-features/resource-roles/resource-roles.module';
import { UserCostRatesComponent } from './card/user-cost/user-cost-rates/user-cost-rates.component';
import { UserCostRatesToolbarComponent } from './card/user-cost/user-cost-rates/user-cost-rates-toolbar/user-cost-rates-toolbar.component';
import { UsersGroupsComponent } from './card/users-groups/users-groups.component';
import { ResourceGroupsComponent } from './card/users-groups/resource-groups/resource-groups.component';
import { AddingResourceGroupModalComponent } from './card/users-groups/adding-resource-group-modal/adding-resource-group-modal.component';
import { UserNotificationsComponent } from 'src/app/settings-app/users/card/user-notifications/user-notifications.component';
import { UserProductsComponent } from 'src/app/settings-app/users/card/user-products/user-products.component';
import { UserProductsToolbarComponent } from 'src/app/settings-app/users/card/user-products/user-products-toolbar/user-products-toolbar.component';
import { ProfileResourceControlsComponent } from 'src/app/shared/components/features/profile-resource-controls/profile-resource-controls.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';

@NgModule({
  declarations: [
    UserCardComponent,
    UserProfileComponent,
    UserAccessComponent,
    UserRolesComponent,
    UserProductsComponent,
    UserProductsToolbarComponent,
    UserFilterComponent,
    UserCreationComponent,
    UserCostComponent,
    UserCostRatesComponent,
    UserCostRatesToolbarComponent,
    UserListComponent,
    UserScheduleComponent,
    UserTimesheetsComponent,
    UserSettingsComponent,
    UserNotificationsComponent,
    UserSubstitutesComponent,
    UserScheduleToolbarComponent,
    UserActivityToolbarComponent,
    UserSubstitutesToolbarComponent,
    PasswordResetModalComponent,
    UserPersonalScheduleModalComponent,
    UserPersonalScheduleComponent,
    UsersEditingComponent,
    ResourceGroupsComponent,
    AddingResourceGroupModalComponent,
    UsersGroupsComponent,
  ],
  imports: [
    ProfileResourceControlsComponent,
    CommonModule,
    SharedModule,
    ResourceRolesModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.users',
          url: '/users',
          component: UserListComponent,
          params: {
            navigation: 'settings.users',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: USER_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'settings.user',
              } as MassOperationParameters,
            },
          ],
        },

        {
          name: 'settings.user',
          params: {
            navigation: 'settings.users',
          },
          url: '/users/{entityId:guid}',
          component: UserCardComponent,
          redirectTo: 'settings.user.profile',
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },

        {
          name: 'settings.user.profile',
          url: '/profile',
          component: UserProfileComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.user.licenses',
          url: '/licenses',
          component: UserProductsComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.user.access',
          url: '/access',
          component: UserAccessComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.user.roles',
          url: '/roles',
          component: UserRolesComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.user.schedule',
          url: '/schedule',
          component: UserScheduleComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.user.timesheets',
          url: '/timesheets',
          component: UserTimesheetsComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.user.settings',
          url: '/settings',
          component: UserSettingsComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.user.notifications',
          url: '/notifications',
          component: UserNotificationsComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.user.substitutes',
          url: '/substitutes',
          component: UserSubstitutesComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.user.cost',
          url: '/cost',
          component: UserCostComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.user.groups',
          url: '/groups',
          component: UsersGroupsComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class UsersModule {}
