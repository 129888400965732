<table class="table">
  <tbody>
    @if (slotsInfoHard.length) {
      <tr class="table-secondary">
        <td>Hard</td>
        <td>
          <div class="slot-info__unit">
            {{
              slotsInfoHardTotal | plannerFormat: valueMode : total.scheduleHours : total.fteHours
            }}
          </div>
        </td>
      </tr>
    }

    @for (slot of slotsInfoHard; track slot) {
      <tr class="slot-info__row" [class.slot-info__row--last]="$last">
        <td [title]="slot.project.name">
          <div class="trim slot-info__title">
            {{ slot.project.name }}
          </div>
        </td>
        <td>
          <div class="slot-info__unit">
            {{ slot.hours | plannerFormat: valueMode : total.scheduleHours : total.fteHours }}
          </div>
        </td>
      </tr>
    }

    @if (slotsInfoSoft.length) {
      <tr class="table-secondary">
        <td>Soft</td>
        <td>
          <div class="slot-info__unit">
            {{
              slotsInfoSoftTotal | plannerFormat: valueMode : total.scheduleHours : total.fteHours
            }}
          </div>
        </td>
      </tr>
    }

    @for (slot of slotsInfoSoft; track slot) {
      <tr class="slot-info__row" [class.slot-info__row--last]="$last">
        <td [title]="slot.project.name">
          <div class="trim slot-info__title">
            {{ slot.project.name }}
          </div>
        </td>
        <td>
          <div class="slot-info__unit">
            {{ slot.hours | plannerFormat: valueMode : total.scheduleHours : total.fteHours }}
          </div>
        </td>
      </tr>
    }
  </tbody>
</table>
