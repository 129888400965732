import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { AuthProvider } from 'src/app/shared/models/entities/settings/auth-provider.model';
import { User } from 'src/app/shared/models/entities/settings/user.model';

@Injectable()
export class UserSettingsModalService {
  private saveSubject = new Subject<void>();
  public save$ = this.saveSubject.asObservable();
  public savingState$ = new BehaviorSubject<boolean>(false);
  public close$ = new Subject<void>();

  constructor(private data: DataService) {}

  /** Сохранить даннные активной вкладки. */
  public saveCurrentTab() {
    this.saveSubject.next();
  }

  /** Закрыть диалог. */
  public close() {
    this.close$.next();
  }

  /** Возвращает провайдера аутентификации пользователя */
  public getUserAuthProviderObservable(
    userId: string,
  ): Observable<AuthProvider> {
    return this.data
      .collection('Users')
      .entity(userId)
      .get<User>({ select: ['id', 'name'], expand: ['AuthProvider'] })
      .pipe(switchMap((user) => of(user.authProvider)));
  }
}
