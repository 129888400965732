import { Pipe, PipeTransform } from '@angular/core';
import hotkeys from './hotkey-combinations.json';

@Pipe({
  name: 'hotkey',
  standalone: true,
})
/** Modifies hotkey name to current platform keyboard combination. */
export class HotkeyPipe implements PipeTransform {
  transform(value: string, bracket?: boolean): unknown {
    const os = this.getOS();
    const osKey = os === 'Mac OS' || os === 'iOS' ? 'mac' : 'win';
    let combination = hotkeys[value][osKey] as string;
    if (bracket) combination = `(${combination})`;
    return combination;
  }

  private getOS(): string | null {
    const userAgent = window.navigator.userAgent,
      platform =
        (window.navigator as any).userAgentData?.platform ||
        window.navigator.platform,
      macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }
}
