<wp-form-header
  [name]="service.cardName$ | async"
  (reloaded)="reload()"
  [hasAutosave]="true"
  [isSaving]="autosave.isSaving$ | async"
  [isEditable]="!buildMode && service.report?.editAllowed"
  [saveFn]="saveName"
>
</wp-form-header>
<wp-loading-indicator [state]="service.state$ | async"></wp-loading-indicator>

<tmt-not-found [state]="service.state$ | async"></tmt-not-found>

<div [hidden]="(service.state$ | async) !== 'Ready'">
  <div style="margin-top: 15px; margin-bottom: 15px" [hidden]="!service.report?.reportType">
    <wp-report-user-settings></wp-report-user-settings>
  </div>

  <div [hidden]="!readyToRun || hasNotColumns">
    <button
      [hidden]="!isRunShown"
      type="button"
      class="btn btn-default"
      style="margin-bottom: 15px"
      title="{{ 'analytics.pivotTable.run.hint' | translate }}"
      (click)="reRun()"
    >
      <i class="bi bi-play-fill" aria-hidden="true"></i>&nbsp;{{
        'analytics.pivotTable.run.label' | translate
      }}
    </button>
    <wp-loading-indicator [loading]="pivotTableService.loading$ | async"></wp-loading-indicator>
    <wp-pivot-table [hidden]="(pivotTableService.loading$ | async) || isRunShown"></wp-pivot-table>
  </div>

  <div class="text-center" *ngIf="periodIsRequired" style="padding-top: 50px">
    <button
      class="text-uppercase btn btn-link"
      (click)="reportUserSettingsService.openPeriodArea()"
    >
      {{ 'analytics.reports.card.labels.selectPeriod' | translate }}
    </button>
  </div>

  <div class="text-center" *ngIf="!service.report?.reportType" style="padding-top: 50px">
    <button class="text-uppercase btn btn-link" (click)="service.openSettings()">
      {{ 'analytics.reports.card.labels.mustBeConfigured' | translate }}
    </button>
  </div>
</div>
