import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { ProjectPnlComponent } from './project-pnl.component';
import { PnlModule } from 'src/app/shared-features/pnl/pnl.module';

@NgModule({
  declarations: [ProjectPnlComponent],
  imports: [
    CommonModule,
    SharedModule,
    PnlModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.pnl',
          url: '/pnl',
          component: ProjectPnlComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
  exports: [],
})
export class ProjectPnlModule {}
