<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label>{{ 'projects.projects.list.filter.props.period.label' | translate }}</label>
        <wp-date-period-box
          formControlName="period"
          placeholder="{{ 'projects.projects.list.filter.props.period.ph' | translate }}"
        >
        </wp-date-period-box>
      </div>

      <div class="form-group" *ngIf="viewName !== 'client'">
        <label>{{ 'projects.projects.list.filter.props.client.label' | translate }}</label>
        <wp-select-box
          formControlName="organization"
          collection="Organizations"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'projects.projects.list.filter.props.client.ph' | translate }}"
        >
        </wp-select-box>
      </div>

      <div class="form-group" *ngIf="viewName !== 'program'">
        <label>{{ 'projects.projects.list.filter.props.program.label' | translate }}</label>

        <wp-select-box
          formControlName="program"
          collection="Programs"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'projects.projects.list.filter.props.program.ph' | translate }}"
        >
        </wp-select-box>
      </div>

      <div class="form-group" *ngIf="viewName !== 'my'">
        <label>{{ 'projects.projects.list.filter.props.manager.label' | translate }}</label>
        <wp-user-box
          formControlName="manager"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'projects.projects.list.filter.props.manager.ph' | translate }}"
        >
        </wp-user-box>
      </div>

      <div class="form-group">
        <label>{{ 'projects.projects.list.filter.props.currency.label' | translate }}</label>
        <wp-select-box
          formControlName="currency"
          collection="Currencies"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'projects.projects.list.filter.props.currency.ph' | translate }}"
        >
        </wp-select-box>
      </div>
    </div>

    <div class="col-3" *ngIf="viewName !== 'active' && viewName !== 'monitoring'">
      <tmt-state-select formControlName="states" collection="Projects" />
    </div>

    <div class="col-3">
      <label class="control-label fw-semibold">{{
        'projects.projects.list.filter.props.billingType' | translate
      }}</label>
      <ng-container formGroupName="billingTypes">
        <div class="form-check" *ngFor="let billingType of billingTypes">
          <input
            [formControlName]="billingType.code"
            type="checkbox"
            class="form-check-input"
            id="{{ billingType.code }}"
          />
          <label class="form-check-label" for="{{ billingType.code }}">
            {{ billingType.name | translate }}
          </label>
        </div>
      </ng-container>
    </div>
  </div>
</form>
