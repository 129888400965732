import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { CustomFieldCardComponent } from './card/custom-field-card.component';
import { CustomFieldListComponent } from './list/custom-field-list.component';
import { CustomFieldCreationComponent } from './creation/custom-field-creation.component';
import { CustomFieldList } from 'src/app/shared/lists/custom-field.list';
import { FieldLocalizationComponent } from './card/field-localization/field-localization.component';
import { FieldConfigurationComponent } from './card/field-configuration/field-configuration.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { NamingLocalizationModule } from 'src/app/settings-app/shared/naming-localization/naming-localization.module';

@NgModule({
  declarations: [
    CustomFieldCardComponent,
    CustomFieldListComponent,
    CustomFieldCreationComponent,
    FieldLocalizationComponent,
    FieldConfigurationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    NamingLocalizationModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.customFields',
          url: '/custom-fields',
          component: CustomFieldListComponent,
          params: {
            navigation: 'settings.customFields',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: CustomFieldList },
          ],
        },

        {
          name: 'settings.customField',
          params: {
            navigation: 'settings.customFields',
          },
          url: '/custom-fields/{entityId:guid}',
          component: CustomFieldCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class CustomFieldsModule {}
