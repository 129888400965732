import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CalculatedFieldsModule } from './calculated-fields/calculated-fields.module';
import { DashboardsModule } from './dashboards/dashboards.module';
import { ReportsModule } from './reports/reports.module';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

/** Модуль приложения Аналитика. */
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReportsModule,
    CalculatedFieldsModule,
    DashboardsModule,
    EntityListComponent,
  ],
})
export class AnalyticsModule {}
