import { DateTime } from 'luxon';
import { BookingType } from '../../enums/booking-type.enum';
import { PlanningMethod } from '../../enums/planning-method.enum';
import { Entity } from '../entity.model';
import { NamedEntity } from '../named-entity.model';
import { BookingDetailEntry } from './booking-detail-entry.model';
import { DateHours } from 'src/app/shared/models/entities/date-hours.model';
import { ResourceRequestChangeEntry } from 'src/app/shared/models/entities/resources/change-entry.model';
import { ResourceRequest } from 'src/app/shared/models/entities/resources/resource-request.model';

/** Бронь. */
export interface BookingEntry extends Entity {
  /** Row version. */
  rowVersion?: string;

  /** Resource ID. */
  resourceId: string;

  /** Resource with schedule. */
  resource?: BookingResource;

  /** Project ID. */
  projectId?: string;

  /** Project. */
  project: NamedEntity;

  /** From date of booking period. */
  from: string;

  /** To date of booking period. */
  to: string;

  /** Total booked hours. */
  bookedHours?: number;

  /** Total required hours. */
  requiredHours: number;

  /** Total required schedule percent. */
  requiredSchedulePercent: number;

  /** Planning mode. */
  planningMethod: PlanningMethod;

  detailEntries: BookingDetailEntry[];

  /** TimeOff attribute. */
  isTimeOff?: boolean;

  /** Hidden projects attribute. */
  isOther?: boolean;

  /** Line index. */
  lineIndex?: number;

  /** Client property. */
  fromLx?: DateTime;

  /** Client property. */
  toLx?: DateTime;

  /** User who created. */
  createdBy?: NamedEntity;

  /** User who modified. */
  modifiedBy?: NamedEntity;

  /** `BookingType`. */
  type?: BookingType;

  /** Resource request. */
  resourceRequest?: Partial<ResourceRequest>;

  /** Change entries (only for `BookingType.resourceRequest`). */
  changeEntries?: Partial<ResourceRequestChangeEntry>[];
}

export interface BookingResource extends NamedEntity {
  schedule?: DateHours[];
}

export interface ProjectRow extends NamedEntity {
  /**  Resource Request Bookings. */
  requestBookings: BookingEntry[];
  /** Booking entry id. */
  bookingEntryId: string;
  /** Indicates to available to edit booking entries. */
  bookingEntryEditAllowed: boolean;
  /** Details of booking entry */
  detailEntries?: BookingDetailEntry[];
  /* Indicates that it isn't project but time-off entry (or entries if booking mode is detailed). */
  isTimeOff?: boolean;
  /** Indicates that Resource Request rows are shown.  */
  isExpanded?: boolean;
}

export interface BookingEntryReqBody {
  /** Detail's hours. */
  hours: number;
  /** Detail's date. */
  date: string;
  /** Booking entry id. */
  bookingId: string;
  // TODO useless?
  resourceId?: string;
}

export enum BookingEntryType {
  booking = 'Booking',
  resourceRequest = 'ResourceRequest',
}
