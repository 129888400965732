import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { BookingService } from 'src/app/booking/booking/core/booking.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmt-booking-header-left]',
  templateUrl: './booking-header-left.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingHeaderLeftComponent implements OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    public bookingService: BookingService,
    private cdr: ChangeDetectorRef,
  ) {
    bookingService.detectChanges$
      .pipe(
        filter((value) => value === null),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.cdr.markForCheck();
      });

    bookingService.toggleGroup$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
