import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { UserCardService } from '../user-card.service';
import { UntypedFormBuilder } from '@angular/forms';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { MessageService } from 'src/app/core/message.service';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { Exception } from 'src/app/shared/models/exception';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { DataService } from 'src/app/core/data.service';
import { UserCostRatesService } from './user-cost-rates/user-cost-rates.service';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wp-user-cost',
  templateUrl: './user-cost.component.html',
  providers: [GridService, UserCostRatesService],
})
export class UserCostComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  readonly = !this.app.checkEntityPermission(
    'UserCostValue',
    PermissionType.Modify,
  );

  public costForm = this.fb.group({
    costNormalizationRule: [null],
  });

  private _loading: boolean;
  destroyed$ = new Subject<void>();
  get loading() {
    return this._loading;
  }

  set loading(value: boolean) {
    this.actionService.action('save').isShown = !value && !this.readonly;
    this._loading = value;
  }

  private _isSaving: boolean;
  private changesListener: Subscription;

  get isSaving() {
    return this._isSaving;
  }
  set isSaving(value: boolean) {
    this._isSaving = value;

    if (value) {
      this.blockUI.start();
      this.actionService.action('save').start();
    } else {
      this.actionService.action('save').stop();
      this.blockUI.stop();
    }
  }

  reloadListener: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    public userCostRatesService: UserCostRatesService,
    private blockUI: BlockUIService,
    private app: AppService,
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
  ) {}

  private reload() {
    if (!this.costForm.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  private load() {
    this.loading = true;

    const params = {
      select: ['id'],
      expand: ['costNormalizationRule'],
    };

    forkJoin({
      cost: this.userCostRatesService.load(this.entityId),
      rule: this.data.collection('Users').entity(this.entityId).get(params),
    }).subscribe((response) => {
      this.costForm.patchValue(response.rule);
      this.loading = false;
    });
  }

  /** Сохранение сущности. */
  public save() {
    this.isSaving = true;
    forkJoin({
      cost: this.userCostRatesService.save(),
      user: this.data
        .collection('Users')
        .entity(this.entityId)
        .patch({
          costNormalizationRuleId:
            this.costForm.value.costNormalizationRule?.id ?? null,
        }),
    }).subscribe({
      next: () => {
        this.notification.successLocal('shared.messages.saved');
        this.costForm.markAsPristine();
        this.isSaving = false;
      },
      error: (error: Exception) => {
        if (error.code !== Exception.ClientValidationException.code) {
          this.notification.error(error.message);
        }
        this.isSaving = false;
      },
    });
  }

  ngOnInit() {
    this.actionService.run$
      .pipe(
        filter((x) => x.name === 'save'),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.save();
      });

    this.reloadListener = this.actionService.reload$.subscribe(() => {
      this.reload();
    });

    this.changesListener = this.userCostRatesService.changes$.subscribe(() =>
      this.costForm.markAsDirty(),
    );

    this.load();
  }

  ngOnDestroy(): void {
    this.reloadListener.unsubscribe();
    this.changesListener.unsubscribe();

    this.destroyed$.next();
  }
}
