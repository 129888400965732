<tmt-form-header
  name="{{ (isOnPrem ? 'settings.license.header' : 'settings.account.header') | translate }}"
></tmt-form-header>

<wp-loading-indicator [state]="cardState$ | async"></wp-loading-indicator>

<div class="container-fluid p-0 mt-3" *ngIf="(cardState$ | async) == cardState.Ready">
  <div class="row">
    <table class="table table-bordered overview">
      <tr>
        <td style="width: 250px">{{ 'settings.account.overview.tenant' | translate }}:</td>
        <td>
          {{ app.session.configuration.displayName }} ({{
            app.session.configuration.primaryDomain
          }})
        </td>
      </tr>
      <tr *ngIf="!isOnPrem">
        <td>{{ 'settings.account.overview.state' | translate }}:</td>
        <td>{{ tenant?.state }}</td>
      </tr>
      <tr *ngIf="!isOnPrem">
        <td>{{ 'settings.account.overview.billingType' | translate }}:</td>
        <td>
          {{ tenant?.billingType }}
        </td>
      </tr>
      <tr *ngIf="tenant?.billingType === billingType.trial">
        <td>{{ 'settings.account.overview.trialExhaustForecast' | translate }}:</td>
        <td>{{ tenant?.trialExpiryDate | date: 'shortDate' }}</td>
      </tr>

      <tr
        *ngIf="tenant?.billingType === billingType.license && tenant?.state === tenantState.active"
      >
        <td>{{ 'settings.account.overview.licenseExpiryDate' | translate }}:</td>
        <td>{{ licenseInfo?.expiryDate | date: 'shortDate' }}</td>
      </tr>

      <tr *ngIf="tenant?.billingType === billingType.license && tenant?.currentBalance > 0">
        <td>{{ 'settings.account.overview.bonusAccount' | translate }}:</td>
        <td>{{ tenant?.currentBalance | wpCurrency: tenant?.currencyCode }}</td>
      </tr>
      <tr>
        <td>{{ 'settings.account.overview.license' | translate }}:</td>
        <td>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{ 'settings.account.overview.product' | translate }}</th>
                <th>{{ 'settings.account.overview.limit' | translate }}</th>
                <th>{{ 'settings.account.overview.used' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let product of licenseProducts">
                <td>{{ product.displayName }}</td>
                <td>{{ product.limit }}</td>
                <td>{{ product.used }}</td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="!licenseProducts.length" class="text-body-secondary text-uppercase">
            {{ 'shared.noDisplayData' | translate }}
          </div>
        </td>
      </tr>
    </table>

    <div class="actions" *ngIf="!isOnPrem">
      <button *ngIf="allowBillingManage" class="btn btn-primary" (click)="buy()">
        {{ 'settings.account.actions.buy' | translate }}
      </button>

      <button
        *ngIf="tenant?.billingType === billingType.trial"
        class="btn btn-default ms-2"
        (click)="selectProducts()"
      >
        {{ 'settings.account.actions.selectProducts' | translate }}
      </button>
    </div>
  </div>
  <div class="row" *ngIf="!isOnPrem">
    <div class="col" *ngIf="allowBillingManage && tenant?.currencyCode" style="padding-top: 30px">
      <ul class="nav nav-tabs" ngbNav #nav="ngbNav">
        <li [ngbNavItem]="1" class="nav-item">
          <a ngbNavLink class="nav-link">{{ 'settings.account.documents.header' | translate }}</a>
          <ng-template ngbNavContent>
            <wp-account-documents
              [currencyCode]="tenant?.currencyCode"
              [countryCode]="tenant?.countryCode"
            ></wp-account-documents>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
