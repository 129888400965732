import {
  Component,
  OnInit,
  Input,
  Inject,
  inject,
  DestroyRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder } from '@angular/forms';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'wp-request-filter',
  templateUrl: './request-filter.component.html',
})
export class RequestFilterComponent implements OnInit, FilterDetails {
  @Input() public values: Record<string, any>;

  public detailsForm = this.fb.group({
    createdBy: [null],
    project: [null],
    role: [null],
    states: [null],
    resourcePool: [null],
  });

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    @Inject(VIEW_NAME) public viewName: string,
    public filterService: FilterService,
    private fb: UntypedFormBuilder,
  ) {}

  public ngOnInit(): void {
    this.detailsForm.patchValue(this.filterService.values);

    this.filterService.resetValues$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.detailsForm.patchValue(this.filterService.values);
      });

    this.detailsForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const values = Object.assign(
          this.filterService.values,
          this.detailsForm.getRawValue(),
        );

        this.filterService.changeValues(values);
      });
  }
}
