import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { SCHEDULE_EXCEPTION_LIST } from 'src/app/shared/lists/schedule-exception.list';
import { ScheduleExceptionCreationComponent } from './creation/schedule-exception-creation.component';
import { ScheduleExceptionListComponent } from './list/schedule-exception-list.component';
import { ScheduleExceptionCardComponent } from './card/schedule-exception-card.component';
import { ScheduleExceptionsToolbarComponent } from './card/exceptions-toolbar/schedule-exceptions-toolbar.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    ScheduleExceptionListComponent,
    ScheduleExceptionCardComponent,
    ScheduleExceptionCreationComponent,
    ScheduleExceptionsToolbarComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.scheduleExceptions',
          url: '/schedule-exceptions',
          component: ScheduleExceptionListComponent,
          params: {
            navigation: 'settings.scheduleExceptions',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: SCHEDULE_EXCEPTION_LIST },
          ],
        },
        {
          name: 'settings.scheduleException',
          params: {
            navigation: 'settings.scheduleExceptions',
          },
          url: '/schedule-exceptions/{entityId:guid}',
          component: ScheduleExceptionCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class ScheduleExceptionsModule {}
