import { Subject } from 'rxjs';
import { Command } from '../models/command.model';

export class PlannerCommandService {
  private commandSubject = new Subject<Command>();
  public command$ = this.commandSubject.asObservable();

  public readonly: boolean;
  public execute(command: Command) {
    this.commandSubject.next(command);
  }
}
