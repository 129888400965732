import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollToService {
  /**
   * Ищет указанный элемент в контейнере и скроллит к верхней границы его родительского скролл элемента.
   * @param {string} targetId - Идентификатор элемента, который необходимо проскролить.
   * @param {HTMLElement | string} container - Контейнер, в котором ищем элемент с targetId.
   *
   */

  public scrollTo(targetId: string, container: HTMLElement | string) {
    if (!targetId) {
      throw Error('scrollTargetId is required.');
    }

    if (!container) {
      throw Error('container is required.');
    }

    if (typeof container === 'string') {
      container = document.getElementById(container);
    }

    targetId = this.escapingFirstDigit(targetId);
    const scrollTarget: HTMLElement = container.querySelector(`#${targetId}`);

    if (scrollTarget) {
      if (!this.isScrollable(container)) {
        container = this.getFirstScrollableParent(scrollTarget);
      }

      if (container) {
        container.scrollTo({
          behavior: 'smooth',
          top: this.calculateScrollDistance(scrollTarget, container),
        });
      } else {
        throw Error('Parent position is fixed.');
      }
    } else {
      throw Error('scrollTarget not found.');
    }
  }

  // Проверяет есть ли у контейнера вертикальный скролл.

  private isScrollable(container: HTMLElement) {
    const style: CSSStyleDeclaration = window.getComputedStyle(container);

    const overflowRegex = /(auto|scroll)/;

    return overflowRegex.test(style.overflow + style.overflowY) ? true : false;
  }

  // Вычисляет расстояние на которое нужно проскролить элемент.

  private calculateScrollDistance(target: HTMLElement, parent: HTMLElement) {
    const targetRectTop = target.getBoundingClientRect().top;
    const parentRectTop = parent.getBoundingClientRect().top;

    if (
      (targetRectTop < 0 && parentRectTop >= 0) ||
      (targetRectTop >= 0 && parentRectTop < 0)
    ) {
      return Math.abs(targetRectTop) + Math.abs(parentRectTop);
    } else {
      return Math.abs(Math.abs(targetRectTop) - Math.abs(parentRectTop));
    }
  }

  // Находит ближайшего родителя со скроллом.

  private getFirstScrollableParent(nativeElement: HTMLElement) {
    let style: CSSStyleDeclaration = window.getComputedStyle(nativeElement);

    const overflowRegex = /(auto|scroll)/;

    if (style.position === 'fixed') {
      return null;
    }

    let parent = nativeElement;
    while (parent.parentElement) {
      parent = parent.parentElement;
      style = getComputedStyle(parent);

      if (
        style.overflow === 'hidden' ||
        style.overflowY === 'hidden' ||
        style.overflow === 'visible' ||
        style.overflowY === 'visible'
      ) {
        continue;
      }

      if (
        overflowRegex.test(style.overflow + style.overflowY) ||
        parent.tagName === 'BODY'
      ) {
        return parent;
      }
    }
  }

  // Если id начинается c цифры, экранирует ее для передачи в querySelector.

  private escapingFirstDigit(id: string): string {
    return !isNaN(Number(id.charAt(0)))
      ? `\\3${id.charAt(0)} ` + id.substring(1)
      : id;
  }
}
