export enum FilterOperator {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  Greater = 'Greater',
  Less = 'Less',
  GreaterOrEqual = 'GreaterOrEqual',
  LessOrEqual = 'LessOrEqual',
  Contains = 'Contains',
  DoesNotContain = 'DoesNotContain',
  Between = 'Between',
  InPeriod = 'InPeriod',
  InHierarchy = 'InHierarchy',
  IsNull = 'IsNull',
  IsNotNull = 'IsNotNull',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
}
