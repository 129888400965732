import {
  DestroyRef,
  Inject,
  Injectable,
  Injector,
  inject,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { filter, forkJoin } from 'rxjs';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { LocalConfigService } from 'src/app/core/local-config.service';

import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { ListService } from 'src/app/shared/services/list.service';
import { CurrenciesService } from 'src/app/shared/services/currencies.service';
import { Currency } from 'src/app/shared/models/entities/settings/currency.model';
import { CurrencyValue } from 'src/app/shared/models/entities/settings/currency-value.model';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { ProjectTeamService } from 'src/app/shared/services/project-team.service';
import {
  TariffRate,
  TariffRatesHelper,
} from 'src/app/shared/modules/tariff-rates/helpers/tariff-rates.helper';

import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectTariffsSettings } from 'src/app/projects/card/project-tariffs/models/project-tariffs.settings';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';

import {
  ProjectTariff,
  ProjectTariffAssignment,
  ProjectTariffDTO,
} from '../models/project-tariff.model';
import { ProjectTariffModalComponent } from '../project-tariffs-modal/project-tariff-modal.component';
import { ProjectTariffsToolbarComponent } from '../project-tariffs-toolbar/project-tariffs-toolbar.component';
import { ProjectTariffsClientTariffModalComponent } from '../project-tariffs-client-tariff-modal/project-tariffs-client-tariff-modal.component';
import { ProjectTariffsRateMatricesModalComponent } from 'src/app/projects/card/project-tariffs/project-tariffs-rate-matrices-modal/project-tariffs-rate-matrices-modal.component';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';

@Injectable()
export class ProjectTariffsService {
  public settings: ProjectTariffsSettings;
  public currencies: Currency[];
  public formArray = this.fb.array([]);
  public gridOptions: Grid2Options = {
    sorting: false,
    clientTotals: true,
    resizableColumns: true,
    selectionType: SelectionType.row,
    toolbar: ProjectTariffsToolbarComponent,
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          this.createTariff();
        },
      },
      {
        name: 'openAddTariffsFromClientModal',
        handlerFn: () => {
          this.openAddTariffsFromClientModal();
        },
      },
      {
        name: 'openAddTariffsFromRateMatricesModal',
        handlerFn: () => {
          this.openAddTariffsFromRateMatricesModal();
        },
      },
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: (formGroup: UntypedFormGroup) => !!formGroup,
        handlerFn: () => {
          this.editTariff(this.gridService.selectedGroupValue);
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (formGroup: UntypedFormGroup) => !!formGroup,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.deleteTariff(formGroup.getRawValue()),
      },
      { name: 'setUserView', handlerFn: () => this.setUserView() },
    ],
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: () => true,
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.editTariff(formGroup.getRawValue());
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.deleteTariff(formGroup.getRawValue()),
      },
    ],
    view: this.listService.getGridView(),
  };

  private destroyRef = inject(DestroyRef);
  /** Prevents autosaving. */
  private isStopSaving = false;

  public get readonly(): boolean {
    return (
      !this.projectCardService.project.tariffsEditAllowed ||
      !this.projectVersionCardService.projectVersion.editAllowed
    );
  }

  constructor(
    @Inject('entityId') public projectId: string,
    private projectCardService: ProjectCardService,
    private projectTeamService: ProjectTeamService,
    private projectVersionCardService: ProjectVersionCardService,
    private projectVersionDataService: ProjectVersionDataService,
    private currenciesService: CurrenciesService,
    private listService: ListService,
    private gridService: GridService,
    private dataService: DataService,
    private savingQueueService: SavingQueueService,
    private notificationService: NotificationService,
    private localConfigService: LocalConfigService,
    private fb: UntypedFormBuilder,
    private modal: NgbModal,
    private injector: Injector,
  ) {
    this.settings = localConfigService.getConfig(ProjectTariffsSettings);
    this.projectTeamService.assignmentTitleWithoutUnits = true;
  }

  /** Loads project tariffs. */
  public load(): void {
    this.savingQueueService.save().then(
      () => {
        this.gridService.setLoadingState(true);
        this.formArray.clear();

        const query: any = {
          expand: [
            {
              assignments: {
                select: ['*'],
              },
            },
          ],
          filter: [],
        };

        if (this.settings.onlyActive) {
          query.filter.push({
            isActive: true,
          });
        }

        forkJoin([
          this.projectVersionDataService
            .projectCollectionEntity(
              this.projectVersionCardService.projectVersion,
              this.projectId,
            )
            .collection('ProjectTariffs')
            .query<ProjectTariffDTO[]>(query),
          this.projectTeamService.getTeamMembers(
            this.projectVersionCardService.projectVersion,
          ),
          this.currenciesService.currencies$,
        ]).subscribe({
          next: (value) => {
            const [tariffs, teamMembers, currencies] = value;
            this.currencies = currencies as Currency[];

            tariffs.sort(naturalSort('name'));

            tariffs.forEach((tariff) => {
              tariff.assignments.forEach((assignment) => {
                assignment.projectTeamMember = teamMembers.find(
                  (t) => t.id === assignment.projectTeamMemberId,
                );
              });

              this.formArray.push(this.getTariffFormGroup(tariff));
            });

            this.gridService.setLoadingState(false);
          },
          error: (error: Exception) => {
            this.notificationService.error(error.message);
            this.gridService.setLoadingState(false);
          },
        });
      },
      () => null,
    );
  }

  /** Opens modal window for creating tariff. */
  public createTariff(): void {
    const modalRef = this.modal.open(ProjectTariffModalComponent, {
      injector: this.injector,
    });

    modalRef.result.then(
      () => this.load(),
      () => null,
    );
  }

  /** Opens modal window for adding tariffs from client. */
  public openAddTariffsFromClientModal(): void {
    const ref = this.modal.open(ProjectTariffsClientTariffModalComponent, {
      size: 'xxl',
      injector: this.injector,
    });
    const instance =
      ref.componentInstance as ProjectTariffsClientTariffModalComponent;
    instance.projectId = this.projectId;
    instance.organizationId = this.projectCardService.project.organization.id;
    ref.result.then(
      () => this.load(),
      () => null,
    );
  }

  /** Opens modal window for adding tariffs from rate matrices. */
  public openAddTariffsFromRateMatricesModal(): void {
    const ref = this.modal.open(ProjectTariffsRateMatricesModalComponent, {
      size: 'xxl',
      injector: this.injector,
    });
    const instance =
      ref.componentInstance as ProjectTariffsRateMatricesModalComponent;
    instance.projectId = this.projectId;
    ref.result.then(
      () => this.load(),
      () => null,
    );
  }

  /**
   * Opens modal window for editing tariff.
   *
   * @param tariff editing tariff.
   */
  public editTariff(tariff: ProjectTariff): void {
    const modalRef = this.modal.open(ProjectTariffModalComponent, {
      injector: this.injector,
    });

    (modalRef.componentInstance as ProjectTariffModalComponent).tariff = tariff;

    modalRef.result.then(
      (tariff) => {
        const actualRate = TariffRatesHelper.getActualRate(tariff.rates);
        const tariffFormGroup = this.formArray.controls.find(
          (control) => control.getRawValue().id === tariff.id,
        ) as UntypedFormGroup;

        this.gridService.clearSelectedGroups();

        this.isStopSaving = true;
        tariffFormGroup.patchValue({
          ...tariff,
          value: this.getCurrencyValue(actualRate),
          effectiveDate: actualRate?.effectiveDate,
        });
        this.isStopSaving = false;

        this.gridService.detectChanges();
      },
      () => null,
    );
  }

  /**
   * Deletes tariff.
   *
   * @param tariff tariff.
   */
  public deleteTariff(tariff: ProjectTariff): void {
    this.dataService
      .collection('ProjectTariffs')
      .entity(tariff.id)
      .delete()
      .subscribe({
        next: () => {
          const index = this.formArray.controls.findIndex(
            (control) => control.value.id === tariff.id,
          );
          this.formArray.removeAt(index);

          this.gridService.selectGroup(
            (this.formArray.controls[index] ??
              this.formArray.controls[index - 1] ??
              null) as UntypedFormGroup,
          );

          this.gridService.detectChanges();
          this.notificationService.successLocal(
            'projects.projects.tariffs.messages.deleted',
          );
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
        },
      });
  }

  /**
   * Saves settings.
   *
   * @param value Project tariffs settings.
   */
  public saveSettings(value: Partial<ProjectTariffsSettings>): void {
    this.settings = Object.assign(this.settings, value);
    this.localConfigService.setConfig(ProjectTariffsSettings, this.settings);
  }

  /** Transforms data to project tariff.
   *
   * @param formData `formGroup` value.
   *
   * @returns project tariff.
   */
  public prepareTariffData(formData: any): ProjectTariff {
    const assignments: ProjectTariffAssignment[] =
      formData.assignments?.map((memberValue: any) => ({
        id: memberValue?.id,
        projectTariffId: formData.id,
        projectTeamMemberId: memberValue.projectTeamMember?.id,
        projectTeamMember: memberValue.projectTeamMember,
        isAllTeamRole: memberValue.isAllTeamRole,
      })) ?? [];

    const projectTariff: Partial<ProjectTariff> = {
      id: formData.id,
      name: formData.name,
      description: formData.description,
      isActive: formData.isActive,
      assignments,
    };

    if (formData.rates) {
      projectTariff.rates = formData.rates
        .concat([formData.initialRate])
        .map((rateValue: any) => ({
          id: rateValue.id,
          currencyId:
            this.currencies.find(
              (currency) =>
                currency.alpha3Code === rateValue.value?.currencyCode,
            )?.id ?? this.projectCardService.project.currency.id,
          value: rateValue.value?.value ?? 0,
          effectiveDate: rateValue.effectiveDate,
        }));
    }

    ProjectVersionUtil.setEntityRootPropertyId(
      this.projectVersionCardService.projectVersion,
      projectTariff,
      this.projectId,
    );

    return projectTariff as ProjectTariff;
  }

  private getTariffFormGroup(tariff: ProjectTariffDTO): UntypedFormGroup {
    const tariffGroup = this.fb.group({
      id: tariff.id,
      name: [
        tariff.name,
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      value: {
        value: tariff.rate ?? null,
        currencyCode: tariff.currency?.alpha3Code,
      },
      effectiveDate: tariff?.effectiveDate,
      expiryDate: tariff?.expiryDate,
      description: tariff.description,
      isActive: tariff.isActive,
      assignments: [tariff.assignments],
    });

    tariffGroup.valueChanges
      .pipe(
        filter(() => !this.isStopSaving),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((formData) => {
        if (tariffGroup.invalid) {
          this.notificationService.warningLocal(
            'shared.messages.entityNotSaved',
          );
          return;
        }

        const projectTariff = this.prepareTariffData(formData);
        delete projectTariff.id;

        this.savingQueueService.addToQueue(formData.id, () =>
          this.dataService
            .collection('ProjectTariffs')
            .entity(formData.id)
            .patch(projectTariff),
        );
      });

    return tariffGroup;
  }

  private getCurrencyValue(rate: TariffRate): CurrencyValue {
    return {
      value: rate?.value ?? null,
      currencyCode:
        this.currencies.find((c) => c.id === rate?.currencyId)?.alpha3Code ??
        null,
    };
  }

  private setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
        this.load();
      },
      () => null,
    );
  }
}
