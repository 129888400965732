import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';

@Pipe({
  name: 'resourceSummaryTotal',
})
export class ResourceSummaryTotalPipe implements PipeTransform {
  private readonly templates = {
    decimal: '1.0-2',
    currency: '1.0-0',
  };

  constructor(
    private currencyPipe: WpCurrencyPipe,
    private decimalPipe: DecimalPipe,
  ) {}

  transform(value: number, mode: ValueMode): string {
    if (value == null || value === 0) return null;

    switch (mode) {
      case ValueMode.Hours:
        return this.decimalPipe.transform(value, this.templates.decimal);
      case ValueMode.Cost:
        return this.currencyPipe.transform(
          value,
          null,
          this.templates.currency,
          true,
        );
      default:
        return null;
    }
  }
}
