@if (entity) {
  <div class="w-100 text-body-secondary mb-2">
    {{ 'components.boardMiniCardBuilderComponent.messages.about' | translate }}
  </div>

  <div class="list-container disable-selecting">
    <div class="list-header">
      <span title="{{ 'components.boardMiniCardBuilderComponent.props.properties' | translate }}">
        {{ 'components.boardMiniCardBuilderComponent.props.properties' | translate }}
      </span>
    </div>

    <ul
      class="list list--available"
      [id]="availableListId"
      [nxtSortablejs]="properties"
      [config]="propertiesSortableOptions"
      [cloneFunction]="cloneFieldHandler"
    >
      @for (property of properties; track property.name) {
        <li class="list__item draggable" [attr.data-key]="property.name">
          <div class="wp-checkbox">
            <label>
              <input
                type="checkbox"
                [(ngModel)]="property.selected"
                (ngModelChange)="onSelected(property)"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="property.required"
              />
              <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
            </label>
          </div>
          <div class="handle trim">
            <span class="trim">
              {{ property.title }}
            </span>

            @if (property.hint) {
              <i
                class="group-hint bi bi-question-circle text-gray ms-auto d-flex"
                [ngbTooltip]="property.hint"
              ></i>
            }
          </div>
        </li>
      }

      @if (!properties?.length) {
        <ng-container [ngTemplateOutlet]="nodata" />
      }
    </ul>
  </div>

  <div class="list-container disable-selecting">
    <div class="list-header">
      <span
        title="{{
          'components.boardMiniCardBuilderComponent.props.selectedProperties' | translate
        }}"
      >
        {{ 'components.boardMiniCardBuilderComponent.props.selectedProperties' | translate }}
      </span>
    </div>
    <ul
      class="list list--selected"
      [nxtSortablejs]="selectedProperties"
      [config]="selectedPropertiesSortableOptions"
    >
      @for (property of selectedProperties; track property.name) {
        <li class="list__item draggable" [attr.data-key]="property.name">
          <div class="trim handle" title="{{ property.title }}">
            <span class="trim"> {{ property.title }} </span>

            @if (property.hint) {
              <i
                class="group-hint bi bi-question-circle text-gray ms-auto d-flex"
                [ngbTooltip]="property.hint"
              ></i>
            }

            @if (!property.required) {
              <button
                type="button"
                class="list-item-delete"
                title="{{ 'shared2.actions.delete' | translate }}"
                (click)="removeItem($index)"
              >
                &times;
              </button>
            }
          </div>
        </li>
      }

      @if (!selectedProperties?.length) {
        <ng-container [ngTemplateOutlet]="nodata" />
      }
    </ul>
  </div>
} @else {
  <wp-loading-indicator class="w-100" [loading]="true" />
}

<ng-template #nodata>
  <div class="list__empty">
    <p>
      {{ 'shared.dragColumn' | translate }}
    </p>
  </div>
</ng-template>
