<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label>{{ 'projects.programs.list.filter.props.period.label' | translate }}</label>
        <wp-date-period-box
          formControlName="period"
          placeholder="{{ 'projects.programs.list.filter.props.period.ph' | translate }}"
        >
        </wp-date-period-box>
      </div>

      <div class="form-group" *ngIf="viewName !== 'my'">
        <label>{{ 'projects.programs.list.filter.props.manager.label' | translate }}</label>
        <wp-user-box
          formControlName="manager"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'projects.programs.list.filter.props.manager.ph' | translate }}"
        >
        </wp-user-box>
      </div>
    </div>
  </div>
</form>
