/** Функция агрегации. */
export enum TotalFunction {
  None = 'None',
  Count = 'Count',
  Sum = 'Sum',
  Average = 'Average',
  Max = 'Max',
  Min = 'Min',
  Calculate = 'Calculate',
  CountAndSum = 'CountAndSum',
  All = 'All',
}
