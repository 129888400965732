import { NgModule } from '@angular/core';
import { LevelListComponent } from './list/level-list.component';
import { LevelCreationComponent } from './creation/level-creation.component';
import { LevelCardComponent } from './card/level-card.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { LIST, VIEW_NAME } from '../../shared/tokens';
import { LEVEL_LIST } from '../../shared/lists/level.list';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { Transition } from '@uirouter/core';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    LevelListComponent,
    LevelCreationComponent,
    LevelCardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.levels',
          url: '/levels',
          component: LevelListComponent,
          params: {
            navigation: 'settings.levels',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: LEVEL_LIST },
          ],
        },

        {
          name: 'settings.level',
          params: {
            navigation: 'settings.levels',
          },
          url: '/levels/{entityId:guid}',
          component: LevelCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class LevelsModule {}
