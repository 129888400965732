<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label>{{ 'shared2.filters.supervisor.value' | translate }}</label>

        <wp-user-box
          formControlName="supervisor"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.supervisor.ph' | translate }}"
        >
        </wp-user-box>
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.department.value' | translate }}</label>
        <tmt-department-box
          formControlName="department"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.department.ph' | translate }}"
        >
        </tmt-department-box>
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.resourcePool.value' | translate }}</label>
        <tmt-hierarchical-box
          formControlName="resourcePool"
          collection="ResourcePools"
          parentIdKey="leadResourcePoolId"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.resourcePool.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.level.value' | translate }}</label>
        <wp-select-box
          collection="Levels"
          formControlName="level"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.level.ph' | translate }}"
        >
        </wp-select-box>
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.location.value' | translate }}</label>
        <wp-select-box
          collection="Locations"
          formControlName="location"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.location.ph' | translate }}"
        >
        </wp-select-box>
      </div>
    </div>
    <div class="col-6" formGroupName="products">
      <label class="control-label fw-semibold">
        {{ 'settings.users.list.filter.licenses' | translate }}
      </label>
      <div class="form-check" *ngFor="let product of products">
        <input
          [id]="product.product"
          [formControlName]="product.product"
          type="checkbox"
          class="form-check-input"
        />
        <label class="form-check-label" for="{{ product.product }}">
          {{ product.displayName }}
        </label>
      </div>
    </div>
  </div>
</form>
