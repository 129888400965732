import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import {
  ProjectBillingType,
  ProjectBillingTypes,
} from 'src/app/shared/models/enums/project-billing-type';
import { VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'wp-project-filter',
  templateUrl: './project-filter.component.html',
})
export class ProjectFilterComponent implements OnInit, FilterDetails {
  @Input() values: any;

  detailsForm = this.fb.group({
    organization: [null],
    program: [null],
    period: [null],
    manager: [null],
    currency: [null],
    states: [null],
    billingTypes: this.fb.group({
      [ProjectBillingType.nonBillable.code]: [false],
      [ProjectBillingType.tm.code]: [false],
      [ProjectBillingType.fixedBid.code]: [false],
    }),
  });

  billingTypes = ProjectBillingTypes;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(VIEW_NAME) public viewName: string,
    public service: FilterService,
  ) {}

  ngOnInit() {
    this.service.resetValues$.subscribe(() => {
      this.detailsForm.reset();
    });

    this.detailsForm.patchValue(this.service.values);
    this.detailsForm.valueChanges.subscribe(() => {
      const values = Object.assign(this.service.values, this.detailsForm.value);
      this.service.changeValues(values);
    });
  }
}
