<div name="left">
  <div name="scroll-table-header" [hidden]="!hasHeader">
    <table
      tmt-booking-header-left
      class="table wp-nested-table"
      [style.width]="leftTableWidth + 'px'"
    ></table>
  </div>

  <div name="scroll-table-body">
    <table class="table wp-nested-table" [style.width]="leftTableWidth + 'px'">
      <tbody *ngIf="!dataService.resources.length && !service.loading">
        <tr>
          <td class="no-data" [attr.colspan]="2"></td>
        </tr>
      </tbody>
      <tbody
        *ngFor="let resource of dataService.resources; trackBy: trackById"
        tmt-booking-left-group
        [resource]="resource"
      ></tbody>
    </table>
  </div>

  <div name="scroll-table-footer"></div>
</div>

<div name="right" [style.margin-left]="leftTableWidth + 'px'">
  <div name="scroll-table-header" [hidden]="!hasHeader">
    <table
      tmt-booking-header-right
      class="table wp-nested-table"
      [style.width]="bookingRenderingService.getDataTableWidth() + 'px'"
    ></table>
  </div>

  <div name="scroll-table-body">
    <table
      #board
      class="table wp-nested-table"
      [style.width]="bookingRenderingService.getDataTableWidth() + 'px'"
    >
      <colgroup>
        <col *ngFor="let slot of service.slots" />
      </colgroup>

      <tbody>
        <tr *ngIf="!dataService.resources.length && !service.loading">
          <td
            class="text-body-secondary no-data text-uppercase"
            [attr.colspan]="service.slots.length"
          >
            {{ noDataMessage | translate }}
          </td>
        </tr>
      </tbody>

      <tbody
        *ngFor="let resource of dataService.resources; trackBy: trackById"
        tmt-booking-right-group
        [resourceId]="resource.id"
        [componentLine]="componentLine"
      ></tbody>
    </table>
  </div>

  <div name="scroll-table-footer"></div>
</div>
