import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'wp-currency-box-test',
  templateUrl: './currency-box-test.component.html',
})
export class CurrencyBoxTestComponent {
  form = this.fb.group({
    currency: [null, Validators.required],
  });

  constructor(private fb: UntypedFormBuilder) {}

  setValue() {
    this.form.setValue({
      currency: {
        value: 5,
        valueBC: null,
        currencyCode: 'MXN',
      },
    });
  }
}
