import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertificateListComponent } from './list/certificate-list.component';
import { CertificateCardComponent } from 'src/app/certificates/card/certificate-card.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { Transition } from '@uirouter/core';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import {
  VIEW_NAME,
  LIST,
  MULTI_SELECT_LIST,
  MASS_OPERATION_PARAMETERS,
  META_ENTITY_TYPE,
} from 'src/app/shared/tokens';
import { CERTIFICATES_LIST } from 'src/app/shared/lists/certificates.list';
import { CertificateCreationComponent } from './creation/certificate-creation.component';
import { SharedFeaturesModule } from 'src/app/shared-features/shared-features.module';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { CertificatesFilterComponent } from './list/certificates-filter/certificates-filter.component';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';

@NgModule({
  declarations: [
    CertificateCardComponent,
    CertificateListComponent,
    CertificateCreationComponent,
    CertificatesFilterComponent,
  ],
  imports: [
    CommonModule,
    BaseSharedModule,
    SharedModule,
    SharedFeaturesModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'certificates',
          url: '/certificates/{view:viewName}?{navigation}&{route}',
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
            routerHeader: 'team.certificates.list.header',
          },
          component: CertificateListComponent,
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: META_ENTITY_TYPE, useValue: 'Certificate' },
            { provide: LIST, useValue: CERTIFICATES_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'certificate',
              } as MassOperationParameters,
            },
          ],
        },

        {
          name: 'certificate',
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          url: '/certificates/{entityId:guid}?{navigation}&{route}',
          component: CertificateCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class CertificatesModule {}
