import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ViewRef,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/app/shared/globals/constants';
import { AttachmentsManagerService } from 'src/app/shared-features/comments/core/attachments-manager.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid2/models/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid2/models/grid-component-cell.interface';

@Component({
  selector: 'tmt-attachment-cell',
  templateUrl: './attachment-cell.component.html',
  styleUrls: ['./attachment-cell.component.scss'],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class AttachmentCellComponent
  implements OnInit, OnDestroy, GridComponentCell
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() public initialValue: unknown;

  public maxAttachmentSize = Constants.maxAttachmentSize;

  get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  public isShow: boolean;

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public service: GridService,
    public ref: ChangeDetectorRef,
    private notification: NotificationService,
    private attachmentsManagerService: AttachmentsManagerService,
  ) {}

  ngOnInit(): void {
    this.isShow = this.service.getCommand('uploadAttachment').allowedFn();

    this.service.detectChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public openAttachment() {
    this.service.execute('openAttachment', {
      lineId: this.formGroup.value.id,
      fileName: this.formGroup.value.attachment.fileName,
    });
  }

  public onFileAdded(event: any) {
    const file = event.addedFiles[0];
    if (
      !this.attachmentsManagerService.isValidFormat({
        listFiles: (event.source._fileInput.nativeElement as HTMLInputElement)
          .files,
        file: event.addedFiles[0],
      })
    ) {
      return;
    }
    if (!file) {
      this.notification.warningLocal('shared.messages.attachmentIsWrong');
      return;
    }

    this.service.execute('uploadAttachment', {
      formGroup: this.formGroup,
      file,
    });
  }
}
