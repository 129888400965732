import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function currencyValueRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && !control.value.value && control.value.value !== 0) {
      return { required: true };
    } else {
      return null;
    }
  };
}
