<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-5">
      <div class="form-group">
        <label>{{ 'shared2.filters.role.value' | translate }}</label>
        <wp-select-box
          #cascadeControl
          formControlName="role"
          collection="Roles"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.role.ph' | translate }}"
        >
        </wp-select-box>
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.competence.value' | translate }}</label>
        <wp-select-box
          #cascadeControl
          formControlName="competence"
          collection="Competences"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.competence.ph' | translate }}"
        >
        </wp-select-box>
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.level.value' | translate }}</label>
        <wp-select-box
          #cascadeControl
          formControlName="level"
          collection="Levels"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.level.ph' | translate }}"
        >
        </wp-select-box>
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.grade.value' | translate }}</label>
        <wp-select-box
          #cascadeControl
          formControlName="grade"
          collection="Grades"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.grade.ph' | translate }}"
        >
        </wp-select-box>
      </div>
    </div>

    <div class="col-5">
      <div class="form-group">
        <label>{{ 'shared2.filters.location.value' | translate }}</label>
        <wp-select-box
          formControlName="location"
          collection="Locations"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.location.ph' | translate }}"
        >
        </wp-select-box>
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.resourcePool.value' | translate }}</label>
        <tmt-hierarchical-box
          formControlName="resourcePool"
          collection="ResourcePools"
          [allowInactive]="service.allowInactive$ | async"
          parentIdKey="leadResourcePoolId"
          placeholder="{{ 'shared2.filters.resourcePool.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.legalEntity.value' | translate }}</label>
        <wp-select-box
          formControlName="legalEntity"
          collection="LegalEntities"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.legalEntity.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.department.value' | translate }}</label>
        <tmt-department-box
          formControlName="department"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.department.ph' | translate }}"
        >
        </tmt-department-box>
      </div>
    </div>

    <div class="col-2">
      <div formGroupName="resourceTypes">
        <label class="control-label fw-semibold">{{
          'projects.projects.card.team.addResourcesModal.filter.props.resourceType' | translate
        }}</label>
        <div class="form-check" *ngFor="let resourceType of filterResourceTypes">
          <input
            [formControlName]="resourceType"
            type="checkbox"
            class="form-check-input"
            id="{{ resourceType }}"
          />
          <label class="form-check-label" for="{{ resourceType }}">
            {{ 'components.resourceType.' + resourceType | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
</form>
