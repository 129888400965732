import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewRef,
  Optional,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Wrapper } from '../../wrapper.interface';
import { TextBoxComponent } from 'src/app/shared/components/controls/text-box/text-box.component';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';

@Component({
  selector: 'wp-text-box-wrapper',
  template: `<wp-text-box
    [formControl]="control"
    placeholder="{{ placeholder | translate }}"
    [autofocus]="autofocus"
    [readonly]="readonly"
    [propagationMode]="propagationMode"
    [initialValue]="initialValue"
    #component
  ></wp-text-box>`,
})
export class TextBoxWrapperComponent implements Wrapper {
  @Input() control: UntypedFormControl;

  @Input() placeholder?: string;
  @Input() autofocus?: boolean;
  @Input() readonly?: boolean;
  /** Initial value for input element after rendering. */
  @Input() initialValue?: unknown;
  @Input() propagationMode: PropagationMode = PropagationMode.onInput;

  @ViewChild('component') component: TextBoxComponent;

  constructor(
    @Optional() gridService: GridService,
    private ref: ChangeDetectorRef,
  ) {
    if (gridService) {
      gridService.detectChanges$.subscribe(() => {
        if (!(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    }
  }
}
