import { Inject, Injectable } from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { NotificationService } from 'src/app/core/notification.service';
import { KpiKind } from 'src/app/shared/models/enums/kpi-kind.model';
import { Exception } from 'src/app/shared/models/exception';
import { KpiTrends } from '../shared/kpi-trends.model';
import { TrendsKpiSettings } from '../shared/trends-kpi.settings';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { Dictionary } from 'lodash';

@Injectable()
export class TrendsKpiService {
  public loading$ = new ReplaySubject<boolean>();
  public kpiTrends$ = new ReplaySubject<KpiTrends>();
  public settings: TrendsKpiSettings;
  public entityCollection: string;
  public projectVersion: ProjectVersion;

  public financeViewAllowed: boolean;

  private loadingSubscription: Subscription;

  constructor(
    @Inject('entityId') public entityId,
    private localConfigService: LocalConfigService,
    private data: DataService,
    private notification: NotificationService,
  ) {
    this.settings = localConfigService.getConfig(TrendsKpiSettings);
  }

  public reload() {
    this.loading$.next(true);

    this.loadingSubscription?.unsubscribe();

    const urlParams = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@request': JSON.stringify({
        kind: this.settings.kind,
        projectVersionId: this.projectVersion?.id ?? null,
      }),
    };

    const params: Dictionary<any> = {
      request: '@request',
    };

    this.loadingSubscription = this.data
      .collection(this.entityCollection)
      .entity(this.entityId)
      .function('GetTrends')
      .query(params, null, urlParams)
      .subscribe({
        next: (kpiTrends: KpiTrends) => {
          this.loading$.next(false);
          this.kpiTrends$.next(kpiTrends);
        },
        error: (error: Exception) => {
          this.loading$.next(false);
          this.notification.error(error.message);
        },
      });
  }

  saveSettings() {
    this.localConfigService.setConfig(TrendsKpiSettings, this.settings);
  }

  public setKind(kind: KpiKind) {
    this.settings.kind = kind;
    this.saveSettings();
    this.reload();
  }
}
