@if (allowed) {
  <div (clickOutside)="cancel()" [attachOutsideOnClick]="true">
    <div
      class="face"
      (click)="openArea()"
      title="{{ role?.name }}"
      [ngClass]="{ readonly: readonly }"
    >
      @if (role) {
        <div>
          {{ role?.name }}
        </div>
      } @else {
        <div class="trim placeholder">
          {{ 'timesheets.card.columns.role.header' | translate }}
        </div>
      }
      <i class="bi bi-chevron-down expander" aria-hidden="true"></i>
    </div>
    @if (areaExpanded) {
      <div class="expanding-area expanding-area-vertical-scroll">
        <wp-loading-indicator [loading]="loading$ | async"></wp-loading-indicator>

        @if (!roles?.length && !(loading$ | async)) {
          <div class="center-block" style="text-align: center; padding: 3px">
            <p class="text-body-secondary" style="margin: 0">
              {{ 'shared.noOptions' | translate }}
            </p>
          </div>
        }
        <table class="selecting-list" [hidden]="loading$ | async">
          <tbody>
            @for (roleItem of roles; track roleItem.id) {
              <tr
                (mouseenter)="selectRow(roleItem)"
                (click)="clickRow(roleItem)"
                id="{{ roleItem.id }}"
                title="{{ roleItem.name }}"
                class="select-tem"
                [ngClass]="{ 'selected-row': roleItem.id == role?.id }"
              >
                <td class="trim">{{ roleItem.name }}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }
  </div>
}
