<label class="group"> {{ 'settings.permissionSets.granules.groupData' | translate }}</label>
<table class="table table-bordered compact-table" style="table-layout: fixed; width: 1000px">
  <thead>
    <tr>
      <th style="width: 250px; vertical-align: middle" rowspan="2">
        {{ 'settings.permissionSets.granules.granular' | translate }}
      </th>
      <th class="text-center" colspan="3">
        {{ 'settings.permissionSets.scopes.mySubordinates' | translate }}
      </th>
      <th style="vertical-align: middle; text-align: center" colspan="3">
        {{ 'settings.permissionSets.scopes.allUser' | translate }}
      </th>
    </tr>
    <tr>
      <td
        style="text-align: center; vertical-align: middle"
        title="{{ 'settings.permissionSets.permissions.view' | translate }}"
      >
        {{ 'settings.permissionSets.permissions.view' | translate }}
      </td>
      <td
        style="text-align: center; vertical-align: middle"
        title="{{ 'settings.permissionSets.permissions.edit' | translate }}"
      >
        {{ 'settings.permissionSets.permissions.edit' | translate }}
      </td>
      <td
        style="text-align: center; vertical-align: middle"
        title="{{ 'settings.permissionSets.permissions.delete' | translate }}"
      >
        {{ 'settings.permissionSets.permissions.delete' | translate }}
      </td>
      <td
        style="text-align: center; vertical-align: middle"
        title="{{ 'settings.permissionSets.permissions.view' | translate }}"
      >
        {{ 'settings.permissionSets.permissions.view' | translate }}
      </td>
      <td
        style="text-align: center; vertical-align: middle"
        title="{{ 'settings.permissionSets.permissions.edit' | translate }}"
      >
        {{ 'settings.permissionSets.permissions.edit' | translate }}
      </td>
      <td
        style="text-align: center; vertical-align: middle"
        title="{{ 'settings.permissionSets.permissions.delete' | translate }}"
      >
        {{ 'settings.permissionSets.permissions.delete' | translate }}
      </td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ 'settings.permissionSets.granules.timeSheets' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeSheet.MySubordinates.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeSheet.MySubordinates.editEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeSheet.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeSheet.All.editEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeSheet.All.deleteEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.timeOffRequests' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeOffRequest.MySubordinates.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeOffRequest.MySubordinates.editEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeOffRequest.MySubordinates.deleteEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeOffRequest.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeOffRequest.All.editEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeOffRequest.All.deleteEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.timeOffBalanceEntries' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeOffBalanceEntry.MySubordinates.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeOffBalanceEntry.MySubordinates.editEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeOffBalanceEntry.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeOffBalanceEntry.All.editEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.expenseRequests' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ExpenseRequest.MySubordinates.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ExpenseRequest.MySubordinates.editEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ExpenseRequest.MySubordinates.deleteEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ExpenseRequest.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ExpenseRequest.All.editEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ExpenseRequest.All.deleteEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.userSkills' | translate }}</td>

      <td></td>

      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserSkill.MySubordinates.editEnabled')"
          type="checkbox"
        />
      </td>

      <td></td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserSkill.All.editEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.workflowTasks' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('WorkflowTask.MySubordinates.viewEnabled')"
          type="checkbox"
        />
      </td>

      <td></td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('WorkflowTask.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
      <td></td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.certificates' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Certificate.MySubordinates.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Certificate.MySubordinates.editEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Certificate.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Certificate.All.editEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.employees' | translate }}</td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('User.MySubordinates.editEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('User.All.editEnabled')"
          type="checkbox"
        />
      </td>

      <td></td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.employeeIndicators' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserTotal.MySubordinates.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserTotal.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
      <td></td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.employeeFinancialIndicators' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserFinancial.MySubordinates.viewEnabled')"
          type="checkbox"
        />
      </td>

      <td></td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserFinancial.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
      <td></td>
    </tr>
  </tbody>
</table>
