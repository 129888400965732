import {
  AfterViewInit,
  ContentChild,
  DestroyRef,
  Directive,
  ElementRef,
  inject,
} from '@angular/core';
import { FormControl, FormControlName, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Wrapper } from 'src/app/shared/components/features/grid/grid-cell/wrapper.interface';

@Directive({
  selector: '[tmtIndicator]',
})
export class LabelIndicatorDirective implements AfterViewInit {
  @ContentChild(FormControlName, { static: false })
  private formControlName: FormControlName;

  @ContentChild('controlWrapper', { static: false })
  private controlWrapper: Wrapper;

  private destroyRef = inject(DestroyRef);

  private get control(): FormControl | undefined {
    return this.formControlName?.control ?? this.controlWrapper?.control;
  }

  constructor(private el: ElementRef<HTMLElement>) {}

  public ngAfterViewInit(): void {
    if (!this.control) {
      return;
    }

    this.toggleRequiredIndicator();

    this.control.statusChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.toggleRequiredIndicator();
      });
  }

  private toggleRequiredIndicator(): void {
    if (this.control.hasValidator(Validators.required)) {
      this.el.nativeElement
        .querySelector('label')
        ?.classList.add('required-indicator');
    } else {
      this.el.nativeElement
        .querySelector('label')
        ?.classList.remove('required-indicator');
    }
  }
}
