import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { RequestFilterComponent } from './request-filter.component';

@Injectable()
export class RequestFilterService extends FilterService {
  public override component: Type<FilterDetails> = RequestFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override placeholder = 'resources.requests.list.filter.placeholder';

  protected override getDefaultValues = (): any => {
    const value = {
      text: '',
      role: null,
      project: null,
      createdBy: null,
      states: null,
      resourcePool: null,
    };

    return value;
  };

  public override getODataFilter() {
    const result: any[] = [];

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        or: [
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(name)': { contains: text } },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { project: { 'tolower(name)': { contains: text } } },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { role: { 'tolower(name)': { contains: text } } },
        ],
      });
    }

    if (this.values.project) {
      result.push({
        projectId: { type: 'guid', value: this.values.project.id },
      });
    }

    if (this.values.role) {
      result.push({
        roleId: { type: 'guid', value: this.values.role.id },
      });
    }

    if (this.values.resourcePool) {
      result.push({
        resourcePoolId: { type: 'guid', value: this.values.resourcePool.id },
      });
    }

    if (this.values.createdBy) {
      result.push({
        createdById: { type: 'guid', value: this.values.createdBy.id },
      });
    }

    if (this.values.states) {
      const conditions = [];

      Object.keys(this.values.states).forEach((code) => {
        if (this.values.states[code].selected) {
          conditions.push({
            stateId: { type: 'guid', value: this.values.states[code].id },
          });
        }
      });

      result.push({ or: conditions });
    }

    return result;
  }

  public override getDatePeriodUrlParams(): Dictionary<string> {
    return this.dateService.getDatePairFromPeriodForFilter(this.values.period);
  }
}
