import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class OverviewKpiSettings implements BaseSettings {
  public readonly name = 'OverviewKpiSettings';
  version: number;
  date?: string;
  period: DatePeriod;
  task: NamedEntity;
  type: KpiType;
  periodType: KpiPeriodType;
  currency: OverviewKpiCurrencyMode;

  getDefault(): OverviewKpiSettings {
    return {
      version: 1,
      period: null,
      type: KpiType.Actual,
      periodType: KpiPeriodType.onTheCurrentDate,
      currency: OverviewKpiCurrencyMode.project,
    } as OverviewKpiSettings;
  }
}

export enum KpiPeriodType {
  onTheCurrentDate = 'onTheCurrentDate',
  onTheDate = 'onTheDate',
  onTheEndOfProject = 'onTheEndOfProject',
  forThePeriod = 'forThePeriod',
}

export enum OverviewKpiCurrencyMode {
  base = 'base',
  project = 'project',
}
