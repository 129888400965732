<form [formGroup]="form" class="form" novalidate>
  <div class="row">
    <div class="col-12">
      <wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>{{ 'shared.userSettings.security.password.oldPassword' | translate }}</label>
        <wp-text-box
          [password]="true"
          formControlName="oldPassword"
          placeholder="{{ 'shared.userSettings.security.password.oldPassword' | translate }}"
        ></wp-text-box>
      </div>
      <div class="form-group">
        <label>{{ 'shared.userSettings.security.password.newPassword' | translate }}</label>
        <wp-text-box
          [password]="true"
          placeholder="{{ 'shared.userSettings.security.password.newPassword' | translate }}"
          formControlName="newPassword"
        ></wp-text-box>
      </div>
      <div class="form-group">
        <label>{{ 'shared.userSettings.security.password.newPasswordRepeat' | translate }}</label>
        <wp-text-box
          [password]="true"
          placeholder="{{ 'shared.userSettings.security.password.newPasswordRepeat' | translate }}"
          formControlName="newPasswordRepeat"
        >
        </wp-text-box>
      </div>
    </div>
    <div class="col-6">
      <span class="text-body-secondary">{{ passwordRequirements$$ | async }}</span>
    </div>
  </div>
</form>
