import { Component, ElementRef } from '@angular/core';
import { UserNotificationService } from 'src/app/core/user-notification.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserNotificationPopupComponent } from 'src/app/shared/components/features/user-notification-indicator/user-notification-popup/user-notification-popup.component';

/** User notification indicator. */
@Component({
  selector: 'wp-user-notification-indicator',
  templateUrl: './user-notification-indicator.component.html',
  styleUrls: ['./user-notification-indicator.component.scss'],
})
export class UserNotificationIndicatorComponent {
  constructor(
    public service: UserNotificationService,
    private popupService: InfoPopupService,
    private el: ElementRef,
  ) {}

  /** Open user notification popup. */
  public openPopup(): void {
    this.popupService.open({
      target: this.el.nativeElement,
      data: {
        component: UserNotificationPopupComponent,
        params: null,
      },
      containerStyles: { padding: '0' },
    });
  }
}
