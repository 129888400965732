import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GridOptions } from '../grid/grid-options.model';
import { GridService } from '../grid/core/grid.service';
import { TranslateService } from '@ngx-translate/core';
import { GridColumnType } from 'src/app/shared/models/inner/grid-column.interface';
import { LogService } from 'src/app/core/log.service';
import { AuthService } from 'src/app/core/auth.service';
import { Exception } from 'src/app/shared/models/exception';
import { BehaviorSubject } from 'rxjs';

/** Диалог входа в режим замещения. */
@Component({
  selector: 'wp-selecting-of-substitute-modal',
  templateUrl: './selecting-of-substitute-modal.component.html',
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectingOfSubstituteModalComponent implements OnInit {
  public loading$ = new BehaviorSubject<boolean>(false);
  public formArray = this.fb.array([]);
  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    view: {
      name: 'substitutes',
      columns: [
        {
          name: 'user',
          header: 'shared.selectingOfSubstitute.columns.user',
          hint: 'shared.selectingOfSubstitute.columns.user',
          type: GridColumnType.User,
          width: '100%',
        },
        {
          name: 'periodStart',
          header: this.translate.instant(
            'shared.selectingOfSubstitute.columns.periodStart',
          ),
          hint: this.translate.instant(
            'shared.selectingOfSubstitute.columns.periodStart',
          ),
          type: GridColumnType.Date,
          width: '130px',
        },
        {
          name: 'periodFinish',
          header: this.translate.instant(
            'shared.selectingOfSubstitute.columns.periodFinish',
          ),
          hint: this.translate.instant(
            'shared.selectingOfSubstitute.columns.periodFinish',
          ),
          type: GridColumnType.Date,
          width: '130px',
        },
      ],
    },
  };

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private log: LogService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
    public gridService: GridService,
  ) {}

  public load() {
    this.loading$.next(true);
    this.log.debug('Loading co-managers list...');

    this.data.model
      .function('GetUsersToSubstitute')
      .query<any[]>()
      .subscribe({
        next: (data) => {
          data.forEach((row) => {
            this.formArray.push(
              this.fb.group({
                id: row.id,
                user: {
                  name: row.name,
                  email: row.email,
                  id: row.id,
                },
                periodStart: row.periodStart,
                periodFinish: row.periodFinish,
              }),
            );
          });

          this.loading$.next(false);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.loading$.next(false);
        },
      });
  }

  public ok() {
    this.notification.successLocal(
      'shared.selectingOfSubstitute.messages.substitutingStarted',
    );
    this.authService.startSubstituting(
      this.gridService.selectedGroup.value.user.email,
    );
  }

  public cancel() {
    this.activeModal.dismiss();
  }
  ngOnInit() {
    this.load();
  }
}
