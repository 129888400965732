import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Dictionary } from 'src/app/shared/models/dictionary';

@Injectable()
export class DashboardFilterService extends FilterService {
  public override component: Type<FilterDetails> = null;
  public override hasDetails = false;
  public override hasViewSelector = false;
  public override placeholder = 'analytics.dashboards.list.filter.ph';

  protected override getDefaultValues = (): any => ({
    text: '',
    period: null,
    manager: null,
  });

  public override getODataFilter() {
    const result: any[] = [];
    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        or: [
          { 'tolower(name)': { contains: text } },
          { 'tolower(description)': { contains: text } },
        ],
      });
    }
    return result;
  }

  public override getCriteriaCount(): number {
    return 0;
  }

  public override getDatePeriodUrlParams(): Dictionary<string> {
    return null;
  }
}
