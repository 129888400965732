import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Timesheet } from 'src/app/shared/models/entities/base/timesheet.model';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/core/app.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeOffCreationComponent } from 'src/app/time-off-requests/creation/time-off-creation.component';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { Line } from 'src/app/timesheets/card/shared/models/line.model';
import { StopwatchService } from 'src/app/core/stopwatch.service';
import { AllocationInfoService } from 'src/app/timesheets/card/table-view/allocation-info/allocation-info.service';
import { Task } from 'src/app/timesheets/card/shared/models/task.model';
import { StateService, UIRouterGlobals } from '@uirouter/angular';
import {
  EntityFilter,
  NavigationService,
} from 'src/app/core/navigation.service';
import { HeaderIndicator } from 'src/app/shared/components/chrome/form-header2/header-indicator.model';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { MetaEntityBaseProperty } from 'src/app/shared/models/entities/settings/metamodel.model';

@Injectable()
export class TimesheetCardService {
  public name$ = new Subject<string>();

  public collection = this.data.collection('TimeSheets');

  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  private timesheetSubject = new BehaviorSubject<Timesheet>(null);

  private indicatorsSubject = new BehaviorSubject<HeaderIndicator[]>([]);
  public indicators$ = this.indicatorsSubject.asObservable();

  private recalculateSubject = new BehaviorSubject<void>(null);
  public recalculate$ = this.recalculateSubject.asObservable();

  public timesheet$ = this.timesheetSubject
    .asObservable()
    .pipe(filter((p) => !!p));

  /** Загруженный (текущий) таймшит. */
  public timesheet: Timesheet;

  /** Обновленные данные для сохранения. */
  private dataSubject = new BehaviorSubject<Line[]>(null);
  public data$ = this.dataSubject.pipe(filter((p) => !!p));

  public activities$ = this.getAvailableActivities().pipe(shareReplay());

  public allocationCustomFields: MetaEntityBaseProperty[];
  public lineCustomFields: MetaEntityBaseProperty[];

  public timesheetLinesStorageName = 'timesheetLines';

  private destroyed$ = new Subject<void>();

  constructor(
    @Inject('entityId') private entityId: string,
    private app: AppService,
    stopwatchService: StopwatchService,
    private allocationInfoService: AllocationInfoService,
    private translate: TranslateService,
    private data: DataService,
    private notification: NotificationService,
    public blockUI: BlockUIService,
    public autosave: SavingQueueService,
    private modalService: NgbModal,
    private customFieldService: CustomFieldService,
    private state: StateService,
    private navigation: NavigationService,
    private lifecycleService: LifecycleService,
    private uiRouterGlobals: UIRouterGlobals,
  ) {
    stopwatchService.externalUpdate$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.load();
      });

    stopwatchService.stop$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.load(true);
    });

    this.load();

    this.autosave.error$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.load());

    this.autosave.save$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((timesheet: any) => {
        if (timesheet) {
          this.timesheet.rowVersion = timesheet.rowVersion;
          this.timesheet.billableDuration = timesheet.billableDuration;
          this.recalculateSubject.next();
        }
      });
  }

  /** Загрузка таймшита. */
  public load(silent = false): Promise<void> {
    return new Promise((resolve, reject) => {
      this.allocationInfoService.close();
      this.autosave.save().then(() => {
        if (!silent) {
          this.state$.next(CardState.Loading);
        } else {
          this.blockUI.start();
        }

        const query = {
          expand: {
            template: {
              select: [
                'showActivity',
                'showRole',
                'showClient',
                'showProjectCostCenter',
                'showTariff',
              ],
              expand: { customFields: { select: ['customFieldId'] } },
            },
            user: { select: ['id', 'name'] },
            legalEntity: { select: ['id', 'name'] },
            state: { select: ['id', 'name', 'code'] },
            timeSheetLines: {
              select: ['id', 'date', 'orderNumber', 'rowVersion'],
              expand: {
                project: {
                  select: ['id', 'name'],
                  expand: {
                    billingType: { select: ['code'] },
                    organization: { select: ['id', 'name'] },
                  },
                },
                projectTask: { select: ['id', 'name', 'leadTaskId'] },
                projectCostCenter: { select: ['id', 'name'] },
                projectTariff: { select: ['id', 'name'] },
                activity: { select: ['id', 'name'] },
                role: { select: ['id', 'name'] },
                timeAllocations: {
                  orderBy: 'date',
                  select: ['id', 'duration', 'comments', 'date'],
                },
              },
              orderBy: 'orderNumber',
            },
            timeOffRequests: {
              select: ['id'],
              expand: {
                state: { select: ['id', 'name', 'code', 'style'] },
                timeOffType: { select: ['id', 'name'] },
                timeAllocations: { orderBy: 'date' },
              },
            },
          },
        };

        this.customFieldService.enrichQuery(
          query.expand.timeSheetLines.expand.timeAllocations,
          'TimeAllocation',
        );
        this.customFieldService.enrichQuery(
          query.expand.timeSheetLines,
          'TimeSheetLine',
        );

        const observable = this.collection
          .entity(this.entityId)
          .get<Timesheet>(query);

        observable.subscribe({
          next: (timesheet: Timesheet) => {
            this.lifecycleService.entityId = timesheet.id;

            this.timesheet = timesheet;
            this.fillCustomFieldsOut();
            const name =
              this.uiRouterGlobals.current.name === 'currentTimesheet'
                ? this.translate.instant('timesheets.current')
                : timesheet.name;
            this.name$.next(name);

            if (this.uiRouterGlobals.current.name !== 'currentTimesheet') {
              this.navigation.addRouteSegment({
                id: timesheet.id,
                title: timesheet.name,
              });
            }

            this.timesheetSubject.next(timesheet);
            this.blockUI.stop();
            this.state$.next(CardState.Ready);
            resolve();
          },
          error: (error: Exception) => {
            this.state$.next(CardState.Error);
            this.blockUI.stop();
            if (error.code !== Exception.BtEntityNotFoundException.code) {
              this.notification.error(error.message);
            }
          },
        });
      });
    });
  }

  public createTimeOffRequest() {
    const ref = this.modalService.open(TimeOffCreationComponent, {
      size: 'lg',
    });
  }

  public copyLines(withHours?: boolean) {
    this.autosave.save().then(() => {
      this.blockUI.start();

      this.collection
        .entity(this.timesheet.id)
        .action('WP.CopyLinesFromPrevious')
        .execute({ copyHours: withHours })
        .subscribe({
          next: (response: number) => {
            if (response === 0) {
              this.notification.warningLocal(
                'timesheets.card.messages.linesNotAdded',
              );
            } else {
              this.notification.successLocal(
                'timesheets.card.messages.linesAdded',
                { count: response },
              );
              this.load();
            }
            this.blockUI.stop();
          },
          error: (error: Exception) => {
            this.blockUI.stop();
            this.notification.error(error.message);
          },
        });
    });
  }

  public createLinesFromResourcePlan(withHours?: boolean) {
    this.autosave.save().then(() => {
      this.blockUI.start();

      this.collection
        .entity(this.timesheet.id)
        .action('WP.CreateLinesFromResourcePlan')
        .execute({ copyHours: withHours })
        .subscribe({
          next: (response: number) => {
            if (response === 0) {
              this.notification.warningLocal(
                'timesheets.card.messages.linesNotAdded',
              );
            } else {
              this.notification.successLocal(
                'timesheets.card.messages.linesAdded',
                { count: response },
              );
              this.load();
            }
            this.blockUI.stop();
          },
          error: (error: Exception) => {
            this.blockUI.stop();
            this.notification.error(error.message);
          },
        });
    });
  }

  /**
   * Navigates to Timesheet Accounting entries.
   * */
  public goToAccountingEntry(): void {
    this.state.go(`accountingEntries`, {
      routeMode: RouteMode.continue,
      filter: JSON.stringify(<EntityFilter>{
        name: this.timesheet.name,
        filter: [{ documentId: { type: 'guid', value: this.timesheet.id } }],
      }),
    });
  }

  /** Возвращает список доступных видов работ.  */
  private getAvailableActivities(): Observable<ReadonlyArray<NamedEntity>> {
    return new Observable((subscriber) => {
      this.data
        .collection('Users')
        .entity(this.timesheet.user.id)
        .get<any>({
          select: 'restrictActivities',
          expand: {
            activities: {
              orderBy: 'activity/name',
              expand: {
                activity: {
                  select: ['name', 'id'],
                  filter: { isActive: true },
                },
              },
            },
          },
        })
        .subscribe((user) => {
          if (user.restrictActivities) {
            const activities = user.activities.map((ua) => ua.activity);
            subscriber.next(activities);
          } else {
            this.data
              .collection('Activities')
              .query<NamedEntity[]>({
                select: ['name', 'id'],
                orderBy: 'name',
                filter: { isActive: true },
              })
              .subscribe((data) => {
                subscriber.next(data);
              });
          }
        });
    });
  }

  /** Вычленить дополнительные поля строк и ячеек. */
  private fillCustomFieldsOut() {
    this.lineCustomFields = [];
    this.allocationCustomFields = [];
    const allocationFields = this.app.getCustomFields('TimeAllocation');
    const lineCustomFields = this.app.getCustomFields('TimeSheetLine');

    this.timesheet.template.customFields.forEach((templateField) => {
      const allocationField = allocationFields.find(
        (f) =>
          f.customFieldId === templateField.customFieldId &&
          f.viewConfiguration.isShownInEntityForms,
      );
      const lineCustomField = lineCustomFields.find(
        (f) =>
          f.customFieldId === templateField.customFieldId &&
          f.viewConfiguration.isShownInEntityForms,
      );

      if (allocationField) this.allocationCustomFields.push(allocationField);
      if (lineCustomField) this.lineCustomFields.push(lineCustomField);
    });
  }

  /** Команда на обновление данных из UI. */
  public changeData(lines: Line[]) {
    this.dataSubject.next(lines);
  }

  /** Проверяет возможность добавления строки ТШ с указанной задачей. */
  public checkIfTaskCanBeUsed(task: Task): Observable<boolean> {
    return this.collection
      .entity(this.timesheet.id)
      .function('WP.GetProjectTasks')
      .query<NamedEntity[]>(
        { projectId: task.project.id },
        {
          filter: { id: { type: 'guid', value: task.projectTask.id } },
        },
      )
      .pipe(map((response) => response.length === 1));
  }

  /** Update indicators. */
  public updateIndicators(headerIndicators: HeaderIndicator[]) {
    this.indicatorsSubject.next(headerIndicators);
  }

  public dispose() {
    this.destroyed$.next();
  }
}
