import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'tmt-issue-resolution-creation',
  templateUrl: './issue-resolution-creation.component.html',
})
export class IssueResolutionCreationComponent {
  public isSaving = false;
  public form: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  /** Submits data. */
  public ok(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    const data = this.form.value;

    this.data
      .collection('IssueResolutions')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notification.successLocal(
            'components.issueResolutionCreationComponent.messages.created',
          );
          this.state.go('settings.issueResolution', { entityId: response.id });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }

  /** Dismisses data. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
