<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'billing.invoices.creation.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group" tmtIndicator>
      <label class="control-label">
        {{ 'billing.invoices.creation.client' | translate }}
      </label>
      <wp-select-box
        formControlName="client"
        collection="Organizations"
        placeholder="{{ 'billing.invoices.creation.client' | translate }}"
      ></wp-select-box>
    </div>

    <div class="form-group" tmtIndicator>
      <label class="control-label">
        {{ 'billing.invoices.creation.project' | translate }}
      </label>
      <wp-select-box
        formControlName="project"
        collection="Projects"
        [query]="projectsQuery"
        placeholder="{{ 'billing.invoices.creation.project' | translate }}"
      ></wp-select-box>
    </div>

    <div class="form-group form-check">
      <input
        formControlName="assignAutoNumber"
        type="checkbox"
        class="form-check-input"
        id="assignAutoNumber"
      />
      <label class="form-check-label" for="assignAutoNumber">
        {{ 'billing.invoices.creation.assignAutoNumber' | translate }}
      </label>
    </div>
    <div *ngIf="!form.value.assignAutoNumber" class="form-group number-form-group">
      <label class="control-label">
        {{ 'billing.invoices.creation.number' | translate }}
      </label>
      <wp-text-box formControlName="number" placeholder="#"></wp-text-box>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
