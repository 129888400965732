import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { CurrencyCardComponent } from './card/currency-card.component';
import { CurrencyListComponent } from './list/currency-list.component';
import { CurrencyCreationComponent } from './creation/currency-creation.component';
import { CURRENCY_LIST } from 'src/app/shared/lists/currency.list';
import { CurrencyExchangeRatesComponent } from 'src/app/settings-app/currencies/card/currency-exchange-rates/currency-exchange-rates.component';
import { CurrencyExchangeRateSettingComponent } from 'src/app/settings-app/currencies/card/currency-exchange-rates/currency-exchange-rate-setting/currency-exchange-rate-setting.component';
import { CurrencyMainComponent } from './card/currency-main/currency-main.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    CurrencyCardComponent,
    CurrencyListComponent,
    CurrencyCreationComponent,
    CurrencyExchangeRatesComponent,
    CurrencyExchangeRateSettingComponent,
    CurrencyMainComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.currencies',
          url: '/currencies',
          component: CurrencyListComponent,
          params: {
            navigation: 'settings.currencies',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: CURRENCY_LIST },
          ],
        },

        {
          name: 'settings.currency',
          params: {
            navigation: 'settings.currencies',
          },
          url: '/currencies/{entityId:guid}',
          component: CurrencyCardComponent,
          redirectTo: 'settings.currency.main',
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
        {
          name: 'settings.currency.main',
          url: '/main',
          component: CurrencyMainComponent,
          params: {
            routeMode: null,
          },
        },
        {
          name: 'settings.currency.exchangeRates',
          url: '/exchange-rates',
          component: CurrencyExchangeRatesComponent,
          params: {
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class CurrenciesModule {}
