<ng-container *ngIf="!isLoading && !notFound">
  <div class="project-info-container">
    <div class="w-100 trim-blue">
      <a
        class="project-name"
        *ngIf="hasProjectRole()"
        title="{{ project?.name }}"
        (click)="close()"
        uiSref="project"
        [uiParams]="{ entityId: project?.id }"
        >{{ project?.name }}</a
      >
    </div>

    <div class="d-flex mt-2">
      <div class="me-5">
        <small class="text-body-secondary">{{ 'shared.projectInfo.status' | translate }}</small>
        <p>{{ project?.state.name }}</p>
      </div>
      <div>
        <small class="text-body-secondary">{{ 'shared.projectInfo.period' | translate }}</small>
        <p>
          {{ project?.startDate | date: 'shortDate' }}&nbsp;&mdash;&nbsp;{{
            project?.endDate | date: 'shortDate'
          }}
        </p>
      </div>
    </div>

    <div class="d-flex mt-2">
      <div class="trim-multiline">
        <small class="text-body-secondary">{{ 'shared.projectInfo.manager' | translate }}</small>
        <p>{{ project?.manager.name }}</p>
      </div>
    </div>

    <div class="d-flex mt-2" *ngIf="project?.description">
      <div>
        <small class="text-body-secondary">{{
          'shared.projectInfo.description' | translate
        }}</small>
        <div
          class="trim-multiline"
          title="{{ project?.description }}"
          [innerHTML]="project?.description | linky | nl2br"
        ></div>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="notFound" class="text-center text-body-secondary text-uppercase">
  {{ 'shared.entityNotFound.title' | translate }}
</div>

<div class="spinner" [hidden]="!isLoading"></div>
