<div class="name" [ngClass]="{ editable: isEditable }">
  <div
    class="h1"
    *ngIf="hasName"
    [hidden]="isEditMode"
    title="{{ cardName }}"
    (click)="onClickHeader()"
  >
    {{ cardName }}
  </div>

  <div class="editor" *ngIf="isEditMode">
    <div class="form-group">
      <wp-text-box
        [formControl]="textControl"
        style="width: 300px"
        placeholder="{{ 'shared.props.name' | translate }}"
      ></wp-text-box>
    </div>
    <button
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isNameSaving"
      [disabled]="textControl.invalid"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>

    <button class="btn btn-default" [disabled]="isNameSaving" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</div>

<div *ngIf="formHeaderService?.indicators$ | async" class="indicators">
  <div *ngFor="let indicator of formHeaderService?.indicators$ | async">
    <div class="value">{{ indicator.value }}</div>
    <div class="description text-gray">{{ indicator.description | translate }}</div>
  </div>
</div>
