<div
  class="popover-box"
  *ngIf="isShown"
  [ngStyle]="containerStyles"
  (clickOutside)="close()"
  [delayClickOutsideInit]="true"
>
  <wp-loading-indicator [loading]="loading"></wp-loading-indicator>

  <ng-container *ngIf="!loading">
    <div *ngIf="!stopwatch.project" style="height: 40px" class="text-body-secondary text-uppercase">
      {{ 'stopwatch.withoutProject' | translate }}
    </div>

    <div *ngIf="stopwatch.project" style="height: 40px">
      <div style="width: 100%; color: var(--bs-text-minor); font-size: smaller" class="trim">
        {{ clientAndProjectName }}
      </div>
      <div class="trim">{{ taskName }}</div>
    </div>

    <hr />

    <div class="actions">
      <button
        *ngIf="!isPaused"
        [disabled]="swExecuted"
        type="button"
        class="btn btn-default"
        title="{{ 'stopwatch.actions.pause.hint' | translate }}"
        (click)="pause()"
        data-test="pause"
      >
        <i class="bi bi-pause-fill" aria-hidden="true"></i>&nbsp;{{
          'stopwatch.actions.pause.label' | translate
        }}
      </button>
      <button
        *ngIf="isPaused"
        [disabled]="swExecuted"
        type="button"
        class="btn btn-default"
        title="{{ 'stopwatch.actions.resume.hint' | translate }}"
        (click)="resume()"
        data-test="resume"
      >
        <i class="bi bi-play-fill" aria-hidden="true"></i>&nbsp;{{
          'stopwatch.actions.resume.label' | translate
        }}
      </button>

      <div class="btn-group">
        <button
          [disabled]="swExecuted"
          type="button"
          class="btn btn-default"
          title="{{ 'stopwatch.actions.stop.hint' | translate }}"
          (click)="stop('Increment')"
          data-test="stop"
        >
          <i class="bi bi-stop-fill" aria-hidden="true"></i>&nbsp;{{
            'stopwatch.actions.stop.label' | translate
          }}
        </button>

        <div class="btn-group" ngbDropdown role="group">
          <div>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button
                ngbDropdownItem
                (click)="stop('Rewrite')"
                title="{{ 'stopwatch.actions.stopAndOverwrite.hint' | translate }}"
              >
                {{ 'stopwatch.actions.stopAndOverwrite.label' | translate }}
              </button>

              <button
                ngbDropdownItem
                (click)="stop('Cancel')"
                title="{{ 'stopwatch.actions.stopAndCancel.hint' | translate }}"
              >
                {{ 'stopwatch.actions.stopAndCancel.label' | translate }}
              </button>
            </div>
          </div>
          <button class="btn btn-default dropdown-toggle-split" ngbDropdownToggle></button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
