<div *ngIf="!disabled" class="input-group">
  <input
    #editor
    class="form-control"
    [ngClass]="{ clearable: allowNull }"
    (input)="handleInput()"
    [attr.title]="viewValue"
    [attr.placeholder]="placeholder"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [attr.spellcheck]="false"
  />
  <span
    *ngIf="allowNull"
    class="input-clear"
    title="{{ 'shared.hintClearControlButton' | translate }}"
    (click)="clear()"
    [hidden]="!viewValue"
    >&times;</span
  >
</div>
<p *ngIf="disabled && (value || value === 0)" class="trim" title="{{ viewValue }}">
  {{ viewValue }}
</p>
<p *ngIf="disabled && !value && value !== 0" class="text-body-secondary text-uppercase">
  {{ 'shared.empty' | translate }}
</p>
