<div *ngIf="!(service.readonly$ | async)" class="toolbar-container pt-0">
  <div ngbDropdown class="d-inline-block" (openChange)="openChange($event)" placement="bottom-left">
    <button type="button" class="btn btn-default" ngbDropdownToggle>
      <span class="bi bi-plus-lg bi-15"></span>
      &nbsp;{{ 'settings.costNormalizationRules.card.actions.addTimeOffType' | translate }}
    </button>
    <div ngbDropdownMenu class="dropdown-scroll">
      <wp-loading-indicator [loading]="loading"></wp-loading-indicator>
      <div
        [hidden]="loading || timeOffTypes.length !== 0"
        hidden
        class="text-center text-body-secondary"
      >
        {{ 'shared.noOptions' | translate }}
      </div>
      <button
        *ngFor="let timeOffType of timeOffTypes"
        ngbDropdownItem
        (click)="selectTimeOffType(timeOffType)"
        title="{{ timeOffType.name }}"
      >
        {{ timeOffType.name }}
      </button>
    </div>
  </div>
</div>
