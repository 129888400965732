import { NgModule } from '@angular/core';
import { ValidationRuleListComponent } from './list/validation-rule-list.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { ValidationRuleList } from 'src/app/shared/lists/validation-rule.list';
import { ValidationRuleCreationComponent } from './creation/validation-rule-creation.component';
import { ValidationRuleCardComponent } from './card/validation-rule-card.component';
import { RuleWorkDayDurationComponent } from './card/rule-work-day-duration/rule-work-day-duration.component';
import { RuleScheduleWorkDayPercentageComponent } from './card/rule-schedule-work-day-percentage/rule-schedule-work-day-percentage.component';
import { RuleScheduleWorkDayDurationComponent } from './card/rule-schedule-work-day-duration/rule-schedule-work-day-duration.component';
import { RuleLinesCountComponent } from './card/rule-lines-count/rule-lines-count.component';
import { RuleSubmitDateComponent } from './card/rule-submit-date/rule-submit-date.component';
import { RuleRequiredFieldsComponent } from './card/rule-required-fields/rule-required-fields.component';
import { RuleProjectPlannedHoursComponent } from './card/rule-project-planned-hours/rule-project-planned-hours.component';
import { RuleEmptyLinesComponent } from './card/rule-empty-lines/rule-empty-lines.component';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    ValidationRuleListComponent,
    ValidationRuleCreationComponent,
    ValidationRuleCardComponent,
    RuleWorkDayDurationComponent,
    RuleScheduleWorkDayPercentageComponent,
    RuleScheduleWorkDayDurationComponent,
    RuleLinesCountComponent,
    RuleSubmitDateComponent,
    RuleRequiredFieldsComponent,
    RuleProjectPlannedHoursComponent,
    RuleEmptyLinesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.validationRules',
          url: '/validation-rules',
          component: ValidationRuleListComponent,
          params: {
            navigation: 'settings.validationRules',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: ValidationRuleList },
          ],
        },
        {
          name: 'settings.validationRule',
          params: {
            navigation: 'settings.validationRules',
          },
          url: '/validation-rules/{entityId:guid}',
          component: ValidationRuleCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class ValidationRulesModule {}
