<div class="toolbar-container">
  <div class="btn-group" role="group">
    <button class="btn btn-default" (click)="form.disable()">Disable</button>
    <button class="btn btn-default" (click)="form.enable()">Enable</button>
  </div>

  <button class="btn btn-default" (click)="setValue()">Set value</button>
</div>

<hr />

<div [formGroup]="form" style="width: 400px">
  <h2>Base</h2>
  <tmt-task-box
    formControlName="department"
    placeholder="placeholder"
    projectId="6ce1f7e7-b88c-4106-8833-d4ac30d6ba15"
  />
  <p>
    Form value:<br />
    <code>
      {{ form.get('department').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('department').valid }}</p>
  <br />
  <hr />
</div>
