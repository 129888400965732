import { Component, Input } from '@angular/core';
import { GridNavigationColumn } from 'src/app/shared/models/inner/grid-column.interface';

@Component({
  selector: 'wp-user-cell',
  templateUrl: './user-cell.component.html',
  styleUrls: ['./user-cell.component.scss'],
})
export class UserCellComponent {
  @Input() row: any;
  @Input() column: GridNavigationColumn;
}
