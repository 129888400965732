<div class="toolbar-container">
  <div class="group">
    <button
      [disabled]="!gridService.canBeExecuted('add')"
      (click)="gridService.execute('add')"
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.addLine' | translate }}"
    >
      <span class="bi bi-plus-lg bi-15"></span>
      {{ 'shared.actions.create' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-default"
      [disabled]="!gridService.canBeExecuted('edit', gridService.selectedGroupValue?.id)"
      title="{{ 'projects.clients.card.tariffs.actions.edit' | translate }}"
      (click)="gridService.execute('edit', gridService.selectedGroupValue?.id)"
    >
      {{ 'shared.actions.edit' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.delete' | translate }}"
      [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroupValue?.id)"
      (click)="gridService.execute('delete', gridService.selectedGroupValue?.id)"
    >
      <span class="bi bi-trash3" aria-hidden="true"></span>
    </button>
  </div>

  <div class="group ms-auto">
    <div ngbDropdown class="d-inline-block">
      <button
        type="button"
        class="btn btn-default without-caret"
        ngbDropdownToggle
        title="{{ 'shared.actions.options' | translate }}"
      >
        <i class="bi bi-sliders2 bi-15" aria-hidden="true"></i>
      </button>
      <div ngbDropdownMenu class="p-3">
        <div class="form-check text-nowrap">
          <input
            [formControl]="isActiveControl"
            type="checkbox"
            class="form-check-input"
            id="onlyWithData"
          />
          <label class="form-check-label" for="onlyWithData">
            {{ 'projects.clients.card.tariffs.actions.hideNotActive' | translate }}
          </label>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-default ms-auto"
      (click)="gridService.execute('setUserView')"
      title="{{ 'shared.actions.setView' | translate }}"
    >
      <i class="bi bi-gear" aria-hidden="true"></i>
    </button>
  </div>
</div>
