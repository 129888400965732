import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import {
  Schedule,
  ScheduleDay,
} from 'src/app/shared/models/entities/settings/schedule.model';

/** Service for work with infinity schedule.  */
@Injectable()
export class ScheduleService {
  public get firstDay() {
    return DateTime.fromISO(this._scheduleScheme?.firstDay) ?? null;
  }

  public get patternDays() {
    return this._scheduleScheme?.patternDays ?? null;
  }

  private _workDaysCountInPattern: number;
  public get workDaysCountInPattern() {
    return this._workDaysCountInPattern;
  }
  public get exceptionDays() {
    return this._scheduleScheme?.scheduleException?.exceptionDays ?? null;
  }

  private _scheduleScheme: Schedule;

  /**Initialize project schedule.
   *
   * @param scheme schedule
   */
  public initScheduleScheme(scheme: Schedule) {
    this._scheduleScheme = scheme;
    this._workDaysCountInPattern = this._scheduleScheme?.patternDays.filter(
      (d) => d.dayLength,
    ).length;
    if (this._scheduleScheme.daysCount === 7) {
      this._scheduleScheme.firstDay = DateTime.now()
        .minus({ days: DateTime.now().weekday - 1 })
        .toISODate();
    }
  }

  /**
   * Returns work date closest to target date.
   *
   * @param date target date
   * @param direction direction of day searching.
   * @returns closest work date
   */
  public getClosestWorkDay(
    date: DateTime,
    direction: 'left' | 'right' = 'right',
  ): DateTime | null {
    if (this.checkIsExceptionDay(date, 'working')) {
      return date;
    }
    const adaptedPattern = this.getPatternForDate(date);
    if (
      adaptedPattern[0].dayLength &&
      !this.checkIsExceptionDay(date, 'nonWorking')
    ) {
      return date;
    }
    let patternDayIterator: number;
    switch (direction) {
      case 'right':
        patternDayIterator = 0;
        while (patternDayIterator < adaptedPattern.length) {
          const newDate = date.plus({ days: patternDayIterator });
          if (
            (adaptedPattern[patternDayIterator].dayLength ||
              this.checkIsExceptionDay(newDate, 'working')) &&
            !this.checkIsExceptionDay(newDate, 'nonWorking')
          ) {
            return newDate;
          }
          patternDayIterator++;
        }
        return this.getClosestWorkDay(
          date.plus({ days: this._scheduleScheme.daysCount }),
        );
      case 'left':
        patternDayIterator = adaptedPattern.length - 1;
        while (patternDayIterator >= 0) {
          const newDate = date.minus({
            days: adaptedPattern.length - patternDayIterator,
          });
          if (
            (adaptedPattern[patternDayIterator].dayLength ||
              this.checkIsExceptionDay(newDate, 'working')) &&
            !this.checkIsExceptionDay(newDate, 'nonWorking')
          ) {
            return newDate;
          }
          patternDayIterator--;
        }
        return this.getClosestWorkDay(
          date.minus({ days: this._scheduleScheme.daysCount }),
          'left',
        );
    }
  }

  /**
   * Indicates is target date non working day.
   *
   * @param date target date
   * @returns is current date non working day
   */
  public isNonWorkingDay(date: DateTime): boolean {
    const adaptedPattern = this.getPatternForDate(date);
    return (
      (!adaptedPattern[0].dayLength ||
        this.checkIsExceptionDay(date, 'nonWorking')) &&
      !this.checkIsExceptionDay(date, 'working')
    );
  }

  /** Returns a count of working days (work duration) in the requested interval.
   *
   * @param startDate checking start date
   * @param endDate checking end date
   * @returns count of working days
   */
  public getDuration(startDate: DateTime, endDate: DateTime): number {
    const diff = endDate.diff(startDate, 'days').days;
    const intCountPatterns = Math.floor(
      (diff + 1) / this._scheduleScheme.daysCount,
    );
    const intPatternWorkDaysCount =
      intCountPatterns * this.workDaysCountInPattern;

    let firstRemainderDate = startDate.plus({
      days: intCountPatterns * this._scheduleScheme.daysCount,
    });

    const adaptedPattern = this.getPatternForDate(firstRemainderDate);

    let remainderPatternWorkDays = 0;
    let remainderPatternIterator = 0;
    while (+firstRemainderDate <= +endDate) {
      if (adaptedPattern[remainderPatternIterator].dayLength) {
        remainderPatternWorkDays++;
      }
      remainderPatternIterator++;
      firstRemainderDate = firstRemainderDate.plus({ days: 1 });
    }
    let duration = intPatternWorkDaysCount + remainderPatternWorkDays;

    this.exceptionDays?.forEach((day) => {
      const date = DateTime.fromISO(day.date);
      if (+date >= +startDate && +date <= +endDate) {
        const isWorkDayByPattern = !!this.getPatternForDate(date)[0].dayLength;
        if (isWorkDayByPattern && !day.dayLength) {
          duration--;
        }
        if (!isWorkDayByPattern && day.dayLength) {
          duration++;
        }
      }
    });

    return duration;
  }

  /** Returns end date by start date and tasks work duration.
   *
   * @param startDate checking start date
   * @param duration task work days count
   * @returns endDate
   */
  public getEndDate(startDate: DateTime, targetDuration: number): DateTime {
    if (this.isNonWorkingDay(startDate)) {
      startDate = this.getClosestWorkDay(startDate, 'right');
    }
    const intCountPatterns = Math.floor(
      targetDuration / this.workDaysCountInPattern,
    );
    let currentDuration = intCountPatterns
      ? intCountPatterns * this.workDaysCountInPattern
      : 1;
    const daysByPatterns = intCountPatterns
      ? intCountPatterns * this._scheduleScheme.daysCount - 1
      : 0;
    let endDate = startDate.plus({
      days: daysByPatterns,
    });
    endDate = this.getClosestWorkDay(endDate, 'left');

    this.exceptionDays?.forEach((day) => {
      const date = DateTime.fromISO(day.date);
      if (+date >= +startDate && +date <= +endDate) {
        const isWorkDayByPattern = !!this.getPatternForDate(date)[0].dayLength;
        if (isWorkDayByPattern && !day.dayLength) {
          currentDuration--;
        }
        if (!isWorkDayByPattern && day.dayLength) {
          currentDuration++;
        }
      }
    });
    const adaptedPattern = this.getPatternForDate(endDate);

    let patternDayIndex = 1;
    while (currentDuration < targetDuration) {
      endDate = endDate.plus({ days: 1 });

      if (
        (adaptedPattern[patternDayIndex].dayLength &&
          !this.checkIsExceptionDay(endDate, 'nonWorking')) ||
        (!adaptedPattern[patternDayIndex].dayLength &&
          this.checkIsExceptionDay(endDate, 'working'))
      ) {
        currentDuration++;
      }
      patternDayIndex =
        patternDayIndex === adaptedPattern.length - 1 ? 0 : patternDayIndex + 1;
    }
    return endDate;
  }

  /** Check is date non working
   *
   * @param date checking date
   * @returns is date non working
   */
  public checkNonWorkingDay = (date: DateTime): boolean => {
    const adaptedPattern = this.getPatternForDate(date);
    return (
      !adaptedPattern[0].dayLength ||
      this.checkIsExceptionDay(date, 'nonWorking')
    );
  };

  /**Returns the pattern adapted for target date.
   * Returned pattern is equal to original pattern if first date of pattern is target date.
   *
   * @param date first date for pattern
   * @returns adapted pattern
   */
  private getPatternForDate(date: DateTime): ScheduleDay[] {
    const patternDays = [...this.patternDays];
    let adaptedPattern = [];
    if (+date >= +this.firstDay) {
      const diff = date.diff(this.firstDay, 'days');
      const delta = diff.days % this._scheduleScheme.daysCount;
      const firstPart = patternDays.splice(0, delta);
      adaptedPattern = [...patternDays, ...firstPart];
    } else {
      const diff = this.firstDay.diff(date, 'days');
      const delta = diff.days % this._scheduleScheme.daysCount;
      const lastPart = patternDays.splice(
        patternDays.length - delta,
        patternDays.length,
      );
      adaptedPattern = [...lastPart, ...patternDays];
    }
    let adaptedPatternIterator = 0;
    while (adaptedPatternIterator < adaptedPattern.length) {
      adaptedPattern[adaptedPatternIterator].dayNumber =
        adaptedPatternIterator + 1;
      adaptedPatternIterator++;
    }
    return adaptedPattern;
  }

  /** Check is date exception
   *
   * @param date checking date
   * @returns is date exception working day
   */
  private checkIsExceptionDay(
    date: DateTime,
    checkingType: 'working' | 'nonWorking',
  ): boolean {
    switch (checkingType) {
      case 'working':
        return !!this.exceptionDays?.find(
          (day) => day.dayLength && day.date === date.toISODate(),
        );
      case 'nonWorking':
        return !!this.exceptionDays?.find(
          (day) => !day.dayLength && day.date === date.toISODate(),
        );
    }
  }
}
