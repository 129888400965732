<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">{{ 'timeOff.periodModal.header' | translate }}</h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <wp-time-off-period [formGroup]="form" [creatingMode]="false"></wp-time-off-period>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.save' | translate }}
    </button>

    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
