<div class="toolbar-container flex-nowrap">
  <wp-tasks-view-switcher></wp-tasks-view-switcher>
  @if (tasksView === projectTasksDataService.projectTaskView.timeline) {
    <wp-schedule-navigation
      [maxScale]="planningScale.Month"
      [visibleParts]="visibleParts"
    ></wp-schedule-navigation>
  }

  <div class="group fix">
    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.create' | translate }}"
      [disabled]="!createCanBeExecuted || (gridService.loading$ | async)"
      (click)="gridService.execute('create', gridService.selectedGroupValue?.id)"
      data-test="create"
    >
      <span class="bi bi-plus-lg bi-15"></span>&nbsp;{{ 'shared.actions.create' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.edit' | translate }}"
      [disabled]="!editCanBeExecuted"
      (click)="gridService.execute('edit', gridService.selectedGroupValue?.id)"
      data-test="edit"
    >
      {{ 'shared.actions.edit' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.delete' | translate }}"
      [disabled]="!deleteCanBeExecuted"
      (click)="gridService.execute('delete', gridService.selectedGroupValue?.id)"
      data-test="delete"
    >
      <span class="bi bi-trash3" aria-hidden="true"></span>
    </button>
  </div>
  <div class="group fix positioning">
    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared2.actions.increaseLevel' | translate }}"
      [disabled]="!increaseLevelCanBeExecuted"
      (click)="gridService.execute('increaseLevel', gridService.selectedGroupValue?.id)"
      data-test="increaseLevel"
    >
      <i class="bi bi-arrow-bar-left" aria-hidden="true"></i>
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared2.actions.decreaseLevel' | translate }}"
      [disabled]="!decreaseLevelCanBeExecuted"
      (click)="gridService.execute('decreaseLevel', gridService.selectedGroupValue?.id)"
      data-test="decreaseLevel"
    >
      <i class="bi bi-arrow-bar-right" aria-hidden="true"></i>
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared2.actions.moveUp' | translate }}"
      [disabled]="!upCanBeExecuted"
      (click)="gridService.execute('up', gridService.selectedGroupValue?.id)"
      data-test="moveUp"
    >
      <i class="bi bi-arrow-bar-up" aria-hidden="true"></i>
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared2.actions.moveDown' | translate }}"
      [disabled]="!downCanBeExecuted"
      (click)="gridService.execute('down', gridService.selectedGroupValue?.id)"
      data-test="moveDown"
    >
      <i class="bi bi-arrow-bar-down" aria-hidden="true"></i>
    </button>
  </div>

  <div
    class="d-inline-block"
    ngbDropdown
    title="{{ 'components.tasksToolbarComponent.actions.toggleLevel' | translate }}"
  >
    <button type="button" class="btn btn-default" ngbDropdownToggle data-test="toggleLevel">
      {{ 'shared2.actions.show' | translate }}
    </button>
    <div ngbDropdownMenu class="dropdown-scroll">
      @for (level of taskActionsService.levels; track level; let index = $index) {
        <button ngbDropdownItem (click)="gridService.execute('setLevel', level - 1)">
          {{ 'shared2.props.level' | translate }}&nbsp;{{ level }}
        </button>
      }
    </div>
  </div>

  @if (project?.sourceProject) {
    <div class="source-project trim">
      {{ 'components.tasksToolbarComponent.props.tasksInheritFrom' | translate }}:&nbsp;
      <a uiSref="project" [uiParams]="{ entityId: project.sourceProject.id }">{{
        project.sourceProject.name
      }}</a>
    </div>
  }

  <tmt-undo-redo-buttons />

  <button
    class="btn btn-default ml-auto fix"
    (click)="gridService.execute('downloadTaskReport')"
    title="{{ 'shared.pnlStatement.download' | translate }}"
    data-test="downloadTaskReport"
  >
    <i class="bi bi-cloud-arrow-down"></i>&nbsp;Excel
  </button>

  <div class="group fix">
    <tmt-tasks-view-form class="ml-auto"></tmt-tasks-view-form>
    <button
      type="button"
      class="btn btn-default"
      (click)="gridService.execute('setUserView')"
      title="{{ 'shared.actions.setView' | translate }}"
      data-test="setView"
    >
      <i class="bi bi-gear" aria-hidden="true"></i>
    </button>
  </div>
</div>
