<ng-container [formGroup]="form">
  <div class="form-group">
    <label class="d-block">{{ 'analytics.abbreviations.abbreviation' | translate }}</label>

    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        id="none"
        value="none"
        formControlName="abbreviation"
      />
      <label class="form-check-label" for="none">{{
        'analytics.abbreviations.none' | translate
      }}</label>
    </div>

    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        id="thousands"
        value="thousands"
        formControlName="abbreviation"
      />
      <label class="form-check-label" for="thousands">{{
        'analytics.abbreviations.thousands' | translate
      }}</label>
    </div>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        id="millions"
        value="millions"
        formControlName="abbreviation"
      />
      <label class="form-check-label" for="millions">{{
        'analytics.abbreviations.millions' | translate
      }}</label>
    </div>
  </div>

  <div class="form-group">
    <div class="form-check">
      <input
        formControlName="showProportion"
        type="checkbox"
        class="form-check-input"
        id="showProportion"
      />
      <label class="form-check-label" for="showProportion">
        {{ 'analytics.dashboards.widgetSettings.properties.showProportion' | translate }}
      </label>
    </div>

    <div
      class="btn-group btn-group-toggle mt-2"
      *ngIf="form.value.showProportion"
      radioGroup
      formControlName="proportion"
    >
      <label radioLabel class="btn-default fw-normal">
        <input radioButton type="radio" value="direct" />{{
          'analytics.dashboards.widgetSettings.properties.directProportion' | translate
        }}
      </label>
      <label radioLabel class="btn-default fw-normal">
        <input radioButton type="radio" value="inverse" />{{
          'analytics.dashboards.widgetSettings.properties.inverseProportion' | translate
        }}
      </label>
    </div>
  </div>
</ng-container>
