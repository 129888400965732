import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectResourceService } from 'src/app/projects/card/project-resources/project-resources.service';
@Component({
  selector: 'wp-resources-view-form',
  templateUrl: './resources-view-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesViewFormComponent implements OnInit, OnDestroy {
  public checkboxes = ['isShowTaskDuration'];
  public form: UntypedFormGroup = this.fb.group(
    this.checkboxes.reduce(
      (result: Record<string, any>, value) => ({
        ...result,
        [value]: true,
      }),
      {},
    ),
  );

  private destroyed$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private projectResourceService: ProjectResourceService,
  ) {}

  public ngOnInit(): void {
    this.projectResourceService.isShowTaskDuration$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.form.setValue({
          isShowTaskDuration: value,
        });
      });

    this.form.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.projectResourceService.setShowTaskDuration(
          data['isShowTaskDuration'],
        );
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
