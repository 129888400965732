<div class="toolbar-container">
  <div class="btn-group" role="group">
    <button class="btn btn-default" (click)="form.disable()">{{ labels.btnDisable }}</button>
    <button class="btn btn-default" (click)="form.enable()">{{ labels.btnEnable }}</button>
  </div>

  <button class="btn btn-default" (click)="setValue()">{{ labels.btnSetValue }}</button>

  <form [formGroup]="formInputParams">
    <div class="form-check">
      <input formControlName="readonly" type="checkbox" class="form-check-input" id="readonly" />
      <label class="form-check-label" for="readonly"> {{ labels.checkboxReadonly }} </label>
    </div>
  </form>
</div>

<hr />

<div [formGroup]="form" style="width: 400px">
  <h2>{{ labels.numberBoxInteger }}</h2>
  <!-- TODO Change inline params into a single object for component  -->
  <wp-number-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="integer"
    type="integer"
  ></wp-number-box>
  <p>
    {{ labels.formValue }}
    <br />
    <code>
      {{ form.get('integer').value | json }}
    </code>
  </p>
  <p>{{ labels.controlIsValid }} {{ form.get('integer').valid }}</p>
  <br />
  <hr />

  <h2>{{ labels.numberBoxDecimal }}</h2>
  <wp-number-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="decimal"
    type="decimal"
  ></wp-number-box>
  <p>
    {{ labels.formValue }}
    <br />
    <code>
      {{ form.get('decimal').value | json }}
    </code>
  </p>
  <p>{{ labels.controlIsValid }} {{ form.get('decimal').valid }}</p>
  <br />

  <h2>{{ labels.numberBoxDecimalWithPrecision }}</h2>
  <wp-number-box
    [readonly]="formInputParams.controls['readonly'].value"
    class="mb-2"
    formControlName="precision"
    type="integer"
    placeholder="Precision"
  ></wp-number-box>
  <wp-number-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="decimalWithPrecision"
    type="decimal"
    precision="{{ form.get('precision').value || 2 }}"
  ></wp-number-box>
  <p>
    {{ labels.formValue }}
    <br />
    <code>
      {{ form.get('decimalWithPrecision').value | json }}
    </code>
  </p>
  <p>{{ labels.controlIsValid }} {{ form.get('decimalWithPrecision').valid }}</p>
  <br />

  <h2>{{ labels.numberBoxCurrency }}</h2>
  <wp-number-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="currency"
    type="currency"
  ></wp-number-box>
  <p>
    {{ labels.formValue }}
    <br />
    <code>
      {{ form.get('currency').value | json }}
    </code>
  </p>
  <p>{{ labels.controlIsValid }} {{ form.get('currency').valid }}</p>
  <br />

  <h2>{{ labels.numberBoxWork }}</h2>
  <wp-number-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="work"
    type="work"
  ></wp-number-box>
  <p>
    {{ labels.formValue }}
    <br />
    <code>
      {{ form.get('work').value | json }}
    </code>
  </p>
  <p>{{ labels.controlIsValid }} {{ form.get('work').valid }}</p>
  <br />

  <h2>{{ labels.numberBoxPercent }}</h2>
  <wp-number-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="percent"
    type="percent"
  ></wp-number-box>
  <p>
    {{ labels.formValue }}
    <br />
    <code>
      {{ form.get('percent').value | json }}
    </code>
  </p>
  <p>{{ labels.controlIsValid }} {{ form.get('percent').valid }}</p>
  <br />
</div>
