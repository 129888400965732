import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { shareReplay } from 'rxjs/operators';
import { ExpenseType } from '../shared/models/entities/settings/expense-type.model';

@Injectable({ providedIn: 'root' })
export class ExpensesTypesService {
  constructor(private data: DataService) {}

  /** Загруженный список видов работ, доступных для автора текущего таймшита. */
  private types: ReadonlyArray<ExpenseType>;

  public types$ = this.getAvailableTypes().pipe(shareReplay());

  /** Возвращает список доступных типов затрат.  */
  private getAvailableTypes(): Observable<ReadonlyArray<ExpenseType>> {
    return new Observable((subscriber) => {
      if (this.types) {
        subscriber.next(this.types);
      }

      this.data
        .collection('ExpenseTypes')
        .query<ExpenseType[]>({
          select: ['id', 'name', 'code'],
          filter: { isActive: true },
        })
        .subscribe((types) => {
          this.types = types;
          subscriber.next(this.types);
        });
    });
  }
}
