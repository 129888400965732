<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-7">
      <div class="form-group">
        <label>{{ 'workflowTasks.list.filter.properties.created.label' | translate }}</label>
        <wp-date-period-box
          formControlName="created"
          placeholder="{{ 'workflowTasks.list.filter.properties.created.placeholder' | translate }}"
        >
        </wp-date-period-box>
      </div>

      <div class="form-group">
        <label>{{ 'workflowTasks.list.filter.properties.performed.label' | translate }}</label>
        <wp-date-period-box
          formControlName="performed"
          placeholder="{{
            'workflowTasks.list.filter.properties.performed.placeholder' | translate
          }}"
        >
        </wp-date-period-box>
      </div>

      <div class="form-group" *ngIf="viewName != 'my'">
        <label>{{ 'workflowTasks.list.filter.properties.assigned.label' | translate }}</label>
        <wp-user-box
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{
            'workflowTasks.list.filter.properties.assigned.placeholder' | translate
          }}"
          formControlName="assigned"
        ></wp-user-box>
      </div>
    </div>
    <div class="col-5">
      <tmt-state-select formControlName="states" collection="WorkflowTasks" />
    </div>
  </div>
</form>
