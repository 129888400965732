<div
  wpFreezeTable
  class="disable-selecting"
  leftWidthStrategy="byTable"
  rightWidthStrategy="byContainer"
>
  <wp-project-expenses-calendar-toolbar
    name="toolbar"
    [disabled]="service.frameLoading$ | async"
  ></wp-project-expenses-calendar-toolbar>

  <div name="left">
    <div name="scroll-table-header">
      <table class="table wp-nested-table" [ngStyle]="{ width: service.leftTableWidth + 'px' }">
        <colgroup>
          <!-- Наименование группы -->
          <col style="width: 100%" />

          <!-- Итог по группе -->
          <col style="width: 100px" />
        </colgroup>
        <thead>
          <tr>
            <th class="trim text-center left-table-header">
              {{ 'projects.projects.card.expenses.calendar.analytics' | translate }}
            </th>
            <th class="text-center left-table-header">
              {{ 'projects.projects.card.expenses.calendar.total' | translate }}
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table wp-nested-table" [ngStyle]="{ width: service.leftTableWidth + 'px' }">
        <colgroup>
          <col style="width: 100%" />
          <col style="width: 100px" />
        </colgroup>

        <ng-container *ngFor="let section of expensesDataService.getSections(); trackBy: trackById">
          <tbody>
            <tr>
              <td colspan="2" class="section-header-left text-uppercase">
                {{ section.title }}
              </td>
            </tr>
          </tbody>
          <ng-container
            *ngFor="let group of expensesDataService.getOrderedGroups(section); trackBy: trackById"
          >
            <tbody
              [attr.data-test]="group.type + '-header'"
              *ngIf="groupFilterService.selectedGroups.includes(group.type)"
              [group]="group"
              [showOther]="service.displayedOthers[section.type]?.includes(group.type)"
              wpProjectExpensesCalendarLeftGroup
            ></tbody>
          </ng-container>
        </ng-container>
      </table>
    </div>

    <div name="scroll-table-footer"></div>
  </div>

  <div name="right" [ngStyle]="{ 'margin-left': service.leftTableWidth - 1 + 'px' }">
    <div name="scroll-table-header">
      <table class="table wp-nested-table" [ngStyle]="{ width: service.rightTableWidth + 'px' }">
        <colgroup>
          <col *ngFor="let slot of service.slots" />
        </colgroup>
        <thead>
          <tr>
            <th
              *ngFor="let group of service.slotGroups; trackBy: trackById"
              [attr.colspan]="group.slotsCount"
              title="{{ group.hint }}"
            >
              {{ group.header }}
            </th>
          </tr>
          <tr>
            <th
              class="slot"
              *ngFor="let slot of service.slots; trackBy: trackById"
              [ngClass]="{ 'slot-active': slot.today }"
              title="{{ slot.hint }}"
            >
              {{ slot.header }}
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table wp-nested-table" [ngStyle]="{ width: service.rightTableWidth + 'px' }">
        <colgroup>
          <col *ngFor="let slot of service.slots" />
        </colgroup>

        <ng-container *ngFor="let section of expensesDataService.getSections(); trackBy: trackById">
          <tbody>
            <tr>
              <td class="section-header-right" [attr.colspan]="service.slots?.length"></td>
            </tr>
          </tbody>
          <ng-container
            *ngFor="let group of expensesDataService.getOrderedGroups(section); trackBy: trackById"
          >
            <tbody
              [attr.data-test]="group.type + '-cells'"
              *ngIf="groupFilterService.selectedGroups.includes(group.type)"
              [group]="group"
              [showOther]="service.displayedOthers[section.type]?.includes(group.type)"
              wpProjectExpensesCalendarRightGroup
            ></tbody>
          </ng-container>
        </ng-container>
      </table>
    </div>

    <div name="scroll-table-footer"></div>
  </div>
</div>

<wp-loading-indicator [loading]="service.loading$ | async"></wp-loading-indicator>
