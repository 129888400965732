import { DateTime, Interval } from 'luxon';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';

export class ProjectResourcesHelper {
  /**
   * Get background style which uses in the cell.
   *
   * @param taskDurationPercent
   *
   * @return background style
   * */
  public static getBackgroundStyle(
    taskDurationPercent: number | number[],
  ): string {
    if (Array.isArray(taskDurationPercent)) {
      return `linear-gradient(
      to right,
      #ffffff00 0%,
      #ffffff00 ${taskDurationPercent[0]}%,
      var(--cell-allowed-time-color) ${taskDurationPercent[0]}%,
      var(--cell-allowed-time-color) ${
        taskDurationPercent[0] + taskDurationPercent[1]
      }%,
      #ffffff00 ${taskDurationPercent[0] + taskDurationPercent[1]}%,
      #ffffff00 100%
    )`;
    } else {
      return `linear-gradient(
        ${taskDurationPercent > 0 ? 'to right' : 'to left'},
        var(--cell-allowed-time-color) 0%,
        var(--cell-allowed-time-color) ${Math.abs(taskDurationPercent)}%,
        #ffffff00 ${Math.abs(taskDurationPercent)}%,
        #ffffff00 100%
      )`;
    }
  }

  /**
   * Get the allowed task time from period as a percentage.
   *
   * For Day scale always return 0 or 100.
   * For Week and Month return array of 3 numbers:
   *  1. percentage of time until the task start;
   *  2. percentage of the task time;
   *  3. percentage of time after the task end.
   *
   * @param scale Day, Month or Year.
   * @param periodDate Start date of period.
   * @param taskStartDate Start date of task.
   * @param taskEndDate End date of task.
   *
   * @return percents
   * */
  public static getTaskDurationPercent(
    scale: PlanningScale,
    periodDate: string,
    taskStartDate: string,
    taskEndDate: string,
  ): number | number[] {
    const plannedDate = DateTime.fromISO(periodDate);
    const startDate = DateTime.fromISO(taskStartDate).startOf('day');
    const endDate = DateTime.fromISO(taskEndDate).endOf('day');

    if (scale === PlanningScale.Day) {
      if (plannedDate.diff(startDate, ['days']).toObject().days < 0) {
        return 0;
      }

      if (plannedDate.diff(endDate, ['days']).toObject().days <= 0) {
        return 100;
      }
    }

    if (scale === PlanningScale.Month || scale === PlanningScale.Week) {
      const plannedDateEnd = plannedDate
        .set({
          day:
            scale === PlanningScale.Month
              ? plannedDate.daysInMonth
              : plannedDate.day + 6,
        })
        .endOf('day');

      const plannedInterval = Interval.fromDateTimes(
        plannedDate,
        plannedDateEnd,
      );
      const taskInterval = Interval.fromDateTimes(startDate, endDate);
      const taskIntersection: Interval | null =
        taskInterval.intersection(plannedInterval);

      if (taskIntersection) {
        return [
          Interval.fromDateTimes(plannedDate, taskIntersection.start).splitBy({
            day: 1,
          }).length,
          taskIntersection.splitBy({ day: 1 }).length,
          Interval.fromDateTimes(taskIntersection.end, plannedDateEnd).splitBy({
            day: 1,
          }).length,
        ].map((el) =>
          Number(
            ((el / plannedInterval.splitBy({ day: 1 }).length) * 100).toFixed(
              1,
            ),
          ),
        );
      }
    }

    return 0;
  }

  /**
   * Get end date of period by planning scale.
   *
   * @param date Date should be the beginning of the period by scale
   * (e.g. Monday for a week; January, April, July, October for a quarter)
   * @param planningScale `PlanningScale`
   *
   * @return end date.
   * @throws If `date` is not valid, return exception.
   * */
  public static getEndDateByScale(
    date: string,
    planningScale: PlanningScale,
  ): string | null {
    const dateStart = DateTime.fromISO(date).startOf('day');
    let dateEnd: DateTime;

    switch (planningScale) {
      case PlanningScale.Day:
        return date;
      case PlanningScale.Week:
        if (!dateStart.equals(dateStart.startOf('week'))) {
          throw new Error(
            'The beginning of the week is invalid. It should be Monday.',
          );
        } else {
          dateEnd = dateStart.endOf('week');
          break;
        }
      case PlanningScale.Month:
        if (!dateStart.equals(dateStart.startOf('month'))) {
          throw new Error(
            'The beginning of the month is invalid. It should be first day.',
          );
        } else {
          dateEnd = dateStart.endOf('month');
          break;
        }
      case PlanningScale.Quarter:
        if (!dateStart.equals(dateStart.startOf('quarter'))) {
          throw new Error(
            'The beginning of the quarter is invalid. It should be January, April, July or October.',
          );
        } else {
          dateEnd = dateStart.endOf('quarter');
          break;
        }
      case PlanningScale.Year:
        if (!dateStart.equals(dateStart.startOf('year'))) {
          throw new Error(
            'The beginning of the year is invalid. It should be 1st January.',
          );
        } else {
          dateEnd = dateStart.endOf('year');
          break;
        }
    }

    return dateEnd.weekday > 5
      ? dateEnd.set({ weekday: 5 }).toISODate()
      : dateEnd.toISODate();
  }
}
