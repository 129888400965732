<div [formGroup]="form">
  <div class="row">
    <div class="col-3">
      <div class="form-group">
        <label style="width: 100px">{{
          'analytics.dashboards.widgetSettings.properties.min.label' | translate
        }}</label>
        <wp-number-box
          type="integer"
          [min]="-9999999999999"
          formControlName="min"
          [allowNull]="true"
        >
        </wp-number-box>
      </div>
    </div>

    <div class="col-3">
      <div class="form-group">
        <label>{{ 'analytics.dashboards.widgetSettings.properties.max.label' | translate }}</label>
        <wp-number-box
          type="integer"
          [min]="-9999999999999"
          formControlName="max"
          [allowNull]="true"
        >
        </wp-number-box>
      </div>
    </div>
  </div>

  <div
    class="row"
    formArrayName="segments"
    *ngFor="let segment of segments.controls; let i = index"
  >
    <ng-container [formGroupName]="i">
      <div class="col-3">
        <div class="form-group">
          <label>{{
            'analytics.dashboards.widgetSettings.properties.from.label' | translate
          }}</label>
          <wp-number-box
            type="integer"
            [min]="-9999999999999"
            formControlName="from"
            [allowNull]="true"
          >
          </wp-number-box>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label>{{ 'analytics.dashboards.widgetSettings.properties.to.label' | translate }}</label>
          <wp-number-box
            type="integer"
            [min]="-9999999999999"
            formControlName="to"
            [allowNull]="true"
          >
          </wp-number-box>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label>{{
            'analytics.dashboards.widgetSettings.properties.color.label' | translate
          }}</label>
          <input
            class="form-control"
            [colorPicker]="segment.value.color"
            formControlName="color"
            (colorPickerChange)="colorPickerChange(segment, $event)"
            [style.background]="segment.value.color"
            [cpPresetColors]="availableColors"
          />
        </div>
      </div>
      <div class="col-2" style="display: flex">
        <button class="btn btn-link btn-action" (click)="removeSpeedometerSegment(i)">
          {{ 'analytics.dashboards.widgetSettings.actions.remove' | translate }}
        </button>
      </div>
    </ng-container>
  </div>

  <button class="btn btn-link btn-action" (click)="addSpeedometerSegment()">
    {{ 'analytics.dashboards.widgetSettings.actions.addSegment' | translate }}
  </button>
</div>
