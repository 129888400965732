/* eslint-disable @typescript-eslint/naming-convention */

/** Провайдер аутентификации пользователя. */
export enum AuthProviderType {
  /** Локальный (по умолчанию). */
  Local = 'Local',
  /** УЗ в Azure AD. */
  AzureAd = 'AzureAd',
  OpenId = 'OpenId',
}
