<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label>{{ 'settings.historyLog.list.filter.period.label' | translate }}</label>
        <wp-date-period-box
          formControlName="period"
          placeholder="{{ 'settings.historyLog.list.filter.period.ph' | translate }}"
        >
        </wp-date-period-box>
      </div>
      <div class="form-group">
        <label>{{ 'settings.historyLog.list.filter.user.label' | translate }}</label>

        <wp-user-box
          formControlName="user"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'settings.historyLog.list.filter.user.ph' | translate }}"
        >
        </wp-user-box>
      </div>
    </div>
  </div>
</form>
