<form [formGroup]="assignmentFormGroup">
  @if (!projectCardService.project.isAutoPlanning) {
    <div class="form-check" title="{{ 'shared2.props.allTeam' | translate }}">
      <input
        formControlName="allTeamAssignment"
        type="checkbox"
        class="form-check-input"
        id="allTeamAssignment"
      />
      <label class="form-check-label" for="allTeamAssignment">
        {{ 'shared2.props.allTeam' | translate }}
      </label>
    </div>
  }

  @if (!assignmentFormGroup.value.allTeamAssignment) {
    <tmt-assignments-table [assignmentRows]="assignmentRows"></tmt-assignments-table>
  }
</form>
