import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { Transition } from '@uirouter/core';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { BoardCardComponent } from 'src/app/settings-app/boards/card/board-card.component';
import { BoardCreationComponent } from 'src/app/settings-app/boards/creation/board-creation.component';
import { BoardsListComponent } from 'src/app/settings-app/boards/list/boards-list.component';
import { PerformersModule } from 'src/app/settings-app/shared/performers/performers.module';
import { BoardCardPermissionsComponent } from 'src/app/settings-app/boards/card/permissions/permissions.component';
import { BoardCardNavigationsComponent } from 'src/app/settings-app/boards/card/navigations/navigations.component';
import { BOARDS_LIST } from 'src/app/settings-app/boards/model/boards.list';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    BoardsListComponent,
    BoardCardComponent,
    BoardCreationComponent,
    BoardCardPermissionsComponent,
    BoardCardNavigationsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PerformersModule,
    Grid2Module,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.boards',
          url: '/boards',
          component: BoardsListComponent,
          params: {
            navigation: 'settings.boards',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: BOARDS_LIST },
          ],
        },
        {
          name: 'settings.board',
          params: {
            navigation: 'settings.boards',
          },
          url: '/boards/{entityId:guid}',
          component: BoardCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class BoardsModule {}
