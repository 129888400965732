import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PROJECT_LIST } from 'src/app/shared/lists/project.list';
import { SharedModule } from 'src/app/shared/shared.module';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { ProjectListComponent } from './list/project-list.component';
import {
  VIEW_NAME,
  LIST,
  META_ENTITY_TYPE,
  MULTI_SELECT_LIST,
  MASS_OPERATION_PARAMETERS,
} from 'src/app/shared/tokens';
import { ProjectFilterComponent } from './list/project-filter/project-filter.component';
import { ProjectSettingsComponent } from './settings/project-settings.component';
import { CopyingProjectModalComponent } from './copying-project-modal/copying-project-modal.component';
import { ProjectCardModule } from './card/project-card.module';
import { ChangingBillingTypeModalComponent } from './settings/changing-billing-type-modal/changing-billing-type-modal.component';
import { ChangingProgramModalComponent } from './settings/changing-program-modal/changing-program-modal.component';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';

@NgModule({
  declarations: [
    ProjectListComponent,
    ProjectFilterComponent,
    ProjectSettingsComponent,
    ChangingBillingTypeModalComponent,
    CopyingProjectModalComponent,
    ChangingProgramModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProjectCardModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'projects',
          url: '/projects/{view:viewName}?{navigation}&{route}',
          component: ProjectListComponent,
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            navigation: 'projects.allProjects',
            routeMode: null,
            view: 'all',
          },
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: PROJECT_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            { provide: META_ENTITY_TYPE, useValue: 'Project' },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'project',
                entityPropertyName: 'project',
              } as MassOperationParameters,
            },
          ],
        },
      ],
    }),
    EntityListComponent,
  ],
})
export class ProjectsModule {}
