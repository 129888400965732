/* eslint-disable @typescript-eslint/naming-convention */
export enum RevenueEstimateFrequency {
  /** Weekly frequency. */
  Week = 'Week',
  /** Monthly frequency. */
  Month = 'Month',
  /** Quarterly frequency. */
  Quarter = 'Quarter',
  /** Yearly frequency. */
  Year = 'Year',
}

export const RevenueEstimateFrequencies = [
  RevenueEstimateFrequency.Week,
  RevenueEstimateFrequency.Month,
  RevenueEstimateFrequency.Quarter,
  RevenueEstimateFrequency.Year,
];
