import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { CommentsModule } from './comments/comments.module';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SharedModule, UIRouterModule],
  exports: [CommentsModule, Grid2Module],
})
export class SharedFeaturesModule {}
