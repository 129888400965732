import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AuthProviderType } from 'src/app/shared/models/enums/auth-provider-type.enum';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import {
  GridColumnType,
  GridComponentColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsAuthCreateProviderModalComponent } from 'src/app/settings-app/settings/settings-security/settings-auth-create-provider-modal/settings-auth-create-provider-modal.component';
import { DataService } from 'src/app/core/data.service';
import { AuthProvider } from 'src/app/shared/models/entities/settings/auth-provider.model';
import { MessageService } from 'src/app/core/message.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { SettingsCardService } from 'src/app/settings-app/settings/settings-card.service';
import { takeUntil } from 'rxjs/operators';
import { Order } from 'src/app/shared/models/inner/order';

@Component({
  selector: 'wp-settings-auth-providers',
  templateUrl: './settings-auth-providers.component.html',
  styleUrls: ['./settings-auth-providers.component.scss'],
})
export class SettingsAuthProvidersComponent implements OnInit {
  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: true,
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: (formGroup) => {
          const formGroupType = formGroup.value.type;
          return (
            formGroupType !== AuthProviderType.AzureAd &&
            formGroupType !== AuthProviderType.Local
          );
        },
        handlerFn: (formGroup: FormGroup) => {
          this.edit(formGroup);
        },
      },

      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (formGroup) => {
          const formGroupType = formGroup.value.type;
          return (
            !this.readonly &&
            formGroupType !== AuthProviderType.AzureAd &&
            formGroupType !== AuthProviderType.Local
          );
        },
        handlerFn: (formGroup: FormGroup) => {
          this.delete(formGroup);
        },
      },
    ],
    view: {
      name: 'providers',
      columns: [
        <GridComponentColumn>{
          name: 'name',
          type: GridColumnType.String,
          width: '50%',
          header: 'settings.settings.authProviders.columns.name',
          hint: 'settings.settings.authProviders.columns.name',
        },
        {
          name: 'type',
          header: 'settings.settings.authProviders.columns.type',
          hint: 'settings.settings.authProviders.columns.type',
          type: GridColumnType.String,
          width: '50%',
        },
      ],
    },
  };
  public readonly: boolean;
  public canRead: boolean;
  public isSelectedProviderEditable: boolean;
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  private sortingOrder: Order = { column: 'name', reverse: true };
  public providersArray = this.formBuilder.array([]);
  public isLoading$ = this.isLoadingSubject.asObservable();
  private destroySubject = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private ngbModal: NgbModal,
    private data: DataService,
    private notification: NotificationService,
    private message: MessageService,
    private app: AppService,
    private service: SettingsCardService,
    public gridService: GridService,
  ) {
    this.readonly = !this.app.checkPermission(
      'AuthProvider',
      'All',
      PermissionType.Modify,
    );
  }

  ngOnInit(): void {
    this.gridService.setOrder({ column: 'name', reverse: true });
    this.gridService.order$
      .pipe(takeUntil(this.destroySubject))
      .subscribe((order: Order) => {
        this.sortingOrder = order;
        this.reload();
      });
    this.service.reloadTab$
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.reload();
      });

    this.gridService.selectedRow$
      .pipe(takeUntil(this.destroySubject))
      .subscribe((row) => {
        if (!row) {
          return;
        }
        const providerType = row.type;
        if (
          providerType === AuthProviderType.Local ||
          providerType === AuthProviderType.AzureAd
        ) {
          this.isSelectedProviderEditable = false;
          return;
        }
        this.isSelectedProviderEditable = true;
      });
    this.reload();
  }

  fillForm() {
    const providers = this.providersArray as FormArray;
    let query = null;
    if (this.sortingOrder.column) {
      query = {
        orderBy: `${this.sortingOrder.column} ${
          this.sortingOrder.reverse ? 'desc' : 'asc'
        }`,
      };
    }
    this.isLoadingSubject.next(true);
    this.data
      .collection('AuthProviders')
      .query<AuthProvider[]>(query)
      .subscribe((authProviders) => {
        authProviders.forEach((authProvider) => {
          const openIdConfig = JSON.parse(
            authProvider.openIdConfigurationJsonData,
          );
          const providerGroup = this.formBuilder.group({
            id: authProvider.id,
            name: authProvider.name,
            type: authProvider.type,
            openIdConfiguration: this.formBuilder.group({
              clientSecret: openIdConfig?.clientSecret,
              authority: openIdConfig?.authority,
              clientId: openIdConfig?.clientId,
            }),
          });
          providers.push(providerGroup);
        });
        this.isLoadingSubject.next(false);
      });
  }

  create() {
    const ref = this.ngbModal.open(SettingsAuthCreateProviderModalComponent);
    ref.closed.subscribe(() => {
      this.reload();
    });
  }

  edit(formGroup: FormGroup = this.gridService.selectedGroup) {
    if (!formGroup) {
      return;
    }

    const ref = this.ngbModal.open(SettingsAuthCreateProviderModalComponent);
    const instance =
      ref.componentInstance as SettingsAuthCreateProviderModalComponent;
    instance.providerToEdit = formGroup.value;

    ref.closed.subscribe(() => {
      this.reload();
    });
  }

  delete(formGroup: FormGroup) {
    this.message
      .confirmLocal('settings.settings.authProviders.messages.deletionConfirm')
      .then(
        () => {
          this.data
            .collection('AuthProviders')
            .entity(formGroup.value.id)
            .delete()
            .subscribe({
              next: () => {
                this.reload();
                this.notification.successLocal('shared.messages.deleted');
              },
              error: (error: Exception) =>
                this.notification.error(error.message),
            });
        },
        () => null,
      );
  }

  private reload() {
    this.canRead = this.app.checkPermission(
      'AuthProvider',
      'All',
      PermissionType.Read,
    );
    this.providersArray.clear();
    this.fillForm();
  }
}
