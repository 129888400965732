import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { ProjectSummaryDataService } from 'src/app/project-summary/core/project-summary-data.service';
import { ProjectSummaryService } from 'src/app/project-summary/core/project-summary.service';
import { ProjectSummaryViewSettingsService } from 'src/app/project-summary/shared/project-summary-view-settings/core/project-summary-view-settings.service';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { Slot } from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import {
  ProjectSummaryPageDto,
  ProjectSummaryResourcePlanDto,
} from 'src/app/project-summary/models/project-summary-data.model';
import { filter } from 'rxjs/operators';
import { ProjectSummaryTotal } from 'src/app/project-summary/models/project-summary-view.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Represents Project Summary Right Grid content. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmtProjectSummaryRightGroup]',
  templateUrl: './project-summary-right-group.component.html',
  styleUrls: ['./project-summary-right-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSummaryRightGroupComponent implements OnInit {
  @Input() summaryPageDto: ProjectSummaryPageDto;

  /**
   * Indicates whether row is expanded or collapsed.
   *
   * @returns `true` if row is expanded, `false` otherwise.
   * */
  public get isExpanded(): boolean {
    return this._isExpanded;
  }

  /** Gets Selected KPI Types. */
  public get selectedKpiTypes(): KpiType[] {
    return this._selectedKpiTypes;
  }

  /** Gets Summary Totals. */
  public get totals(): ProjectSummaryTotal[] {
    return this._totals;
  }

  protected kpiType = KpiType;
  protected valueMode = ValueMode;

  private _isExpanded: boolean;
  private _selectedKpiTypes: KpiType[];
  private _totals: ProjectSummaryTotal[];

  private destroyRef = inject(DestroyRef);

  constructor(
    public summaryService: ProjectSummaryService,
    private summaryDataService: ProjectSummaryDataService,
    private summaryViewSettingsService: ProjectSummaryViewSettingsService,
    private cdRef: ChangeDetectorRef,
  ) {
    this._selectedKpiTypes = this.summaryService.selectedKpiTypes;
  }

  ngOnInit(): void {
    this.initSubscriptions();
    this.initTotals();
    this.recalculateTotals();
  }

  /**
   * Gets Summary Totals by KPI Type.
   *
   * @param kpiType KPI Type.
   * @returns Totals by KPI Type.
   * */
  public getTotalsByKpiType(kpiType: KpiType): ProjectSummaryTotal[] {
    return this._totals.filter((t) => t.kpiType === kpiType);
  }

  /**
   * Gets Summary Entries by Team Member ID.
   *
   * @param teamMemberId Team Member ID.
   * @param selectedKpiType Selected KPI Type.
   * @param slot Slot.
   * @returns Project Summary Entries.
   * */
  public getSummaryEntriesByTeamMemberId(
    teamMemberId: string,
    selectedKpiType: KpiType,
    slot: Slot,
  ): ProjectSummaryResourcePlanDto[] {
    const entries = this.summaryDataService.getProjectSummaryEntries(
      this.summaryPageDto.id,
      teamMemberId,
    );

    return entries.filter(
      (entry) =>
        entry.kpiType === selectedKpiType &&
        slot.date.toISODate() === entry.date,
    );
  }

  /** Inits subscriptions. */
  private initSubscriptions(): void {
    this.summaryViewSettingsService.settings$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this._selectedKpiTypes = this.summaryService.selectedKpiTypes;
        this.initTotals();
        this.recalculateTotals();
        this.cdRef.detectChanges();
      });

    this.summaryService.detectChanges$
      .pipe(
        filter((id) => !id || id === this.summaryPageDto.id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdRef.detectChanges();
      });

    this.summaryService.recalculateGroup$
      .pipe(
        filter((e) => e.id === this.summaryPageDto.id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((e) => {
        if (e.rebuild) {
          this.initTotals();
        }
        this.recalculateTotals();
      });

    this.summaryService.toggleGroup$
      .pipe(
        filter((event) => event?.id === this.summaryPageDto.id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event) => {
        this._isExpanded = event.state;
      });
  }

  /** Inits Summary Totals. */
  private initTotals(): void {
    this._totals = [];

    for (const slot of this.summaryService.slots) {
      for (const selectedKpiType of this.selectedKpiTypes) {
        this.totals.push({
          id: slot.id,
          kpiType: selectedKpiType,
          date: slot.date.toISODate(),
          hours: 0,
        });
      }
    }
  }

  /** Recalculates Summary Totals. */
  private recalculateTotals(): void {
    const projectEntries =
      this.summaryDataService.entries[this.summaryPageDto.id];
    if (!projectEntries) return;

    this.totals.forEach((total) => {
      total.hours = 0;
    });

    const allEntries: ProjectSummaryResourcePlanDto[] = [];
    const allMembers = Object.values(projectEntries);

    allMembers.forEach((memberEntries) => {
      memberEntries.forEach((memberEntry) => {
        allEntries.push(memberEntry);
      });
    });

    allEntries.forEach((entry) => {
      const total = this.totals.find(
        (t) => t.date === entry.date && t.kpiType === entry.kpiType,
      );
      if (total) {
        total.hours += entry.value;
      }
    });
  }
}
