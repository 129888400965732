import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { BillingContact } from 'src/app/shared/models/entities/settings/multitenant/billing-contact.model';
import { AppConfigService } from 'src/app/core/app-config.service';
import { BehaviorSubject, forkJoin, Subject, Subscription } from 'rxjs';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'src/app/core/message.service';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { LicenseOrderCalculation } from 'src/app/settings-app/account/buy-subscription-modal/shared/license-order-calculation.model';
import {
  LicenseOrderCalculationParams,
  LicenseProductConfiguration,
} from 'src/app/settings-app/account/buy-subscription-modal/shared/license-order-calculation-params.model';
import {
  LicenseInfo,
  LicenseProductInfo,
} from 'src/app/shared/models/entities/settings/license-info.model';
import { max, sortBy } from 'lodash';
import { TenantOverview } from 'src/app/shared/models/entities/settings/multitenant/tenant-overview.model';
import { LicenseProduct } from 'src/app/shared/models/session/client-session.model';

@Component({
  selector: 'tmt-buy-subscription-modal',
  templateUrl: './buy-subscription-modal.component.html',
  styleUrls: ['./buy-subscription-modal.component.scss'],
})
export class BuySubscriptionModalComponent implements OnInit {
  public modeType = ModeType;

  private discountHalfYear = 0.07;
  private discountYear = 0.15;
  public minLimits: number[] = [];
  public bonus = 0;
  invoice: any;

  private _mode: ModeType = ModeType.newLicense;
  public licenseToUpgrade: TenantLicenseDto | null;

  /** List of products visible on the form. */
  public licenseProducts: LicenseProductInfo[];

  calcSubscription: Subscription;
  expiryOfLicensingPeriod: string;

  get mode(): ModeType {
    return this._mode;
  }
  set mode(value: ModeType) {
    this._mode = value;
    this.setMinCount();
    this.calculate();
  }

  public licensePeriods: {
    id: number;
    name: string;
    discount: number;
  }[] = [
    {
      id: 1,
      name: this.translate.instant(
        'settings.account.createPayment.periods.month',
      ),
      discount: 0,
    },
    {
      id: 6,
      name: this.translate.instant(
        'settings.account.createPayment.periods.halfYear',
      ),
      discount: this.discountHalfYear,
    },
    {
      id: 12,
      name: this.translate.instant(
        'settings.account.createPayment.periods.year',
      ),
      discount: this.discountYear,
    },
  ];

  public form = this.fb.group({
    products: this.fb.array([]),
    period: [this.licensePeriods[2], Validators.required],
    upgradeDate: [DateTime.now().toISODate(), Validators.required],
  });

  public formProducts = this.form.controls.products as FormArray;

  billingContact: BillingContact;
  url: string;

  public calculation$ = new BehaviorSubject<LicenseOrderCalculation>(null);
  public calculating$ = new BehaviorSubject<boolean>(null);
  public loading$ = new BehaviorSubject<boolean>(true);
  public saving$ = new Subject<boolean>();

  constructor(
    private notification: NotificationService,
    private translate: TranslateService,
    private data: DataService,
    private state: StateService,
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private blockUI: BlockUIService,
    private httpClient: HttpClient,
    private message: MessageService,
  ) {}

  ngOnInit() {
    this.form.valueChanges.subscribe(() => {
      this.calculate();
    });
    this.load();
  }

  /** Gets effective date for new license. */
  public getOrderEffectivePeriod(): string {
    if (this.mode === ModeType.newLicense) {
      if (this.expiryOfLicensingPeriod) {
        return this.translate.instant(
          'settings.account.createPayment.periodTemplate.newInFuture',
          {
            from: DateTime.fromISO(this.expiryOfLicensingPeriod)
              .plus({ days: 1 })
              .toLocaleString(),
            period: this.form.value.period.name,
          },
        );
      } else {
        return this.translate.instant(
          'settings.account.createPayment.periodTemplate.new',
          {
            period: this.form.value.period.name,
          },
        );
      }
    }

    if (this.mode === ModeType.upgradeLicense) {
      return this.translate.instant(
        'settings.account.createPayment.periodTemplate.upgrade',
        {
          from: DateTime.fromISO(this.form.value.upgradeDate).toLocaleString(),
          to: DateTime.fromISO(
            this.licenseToUpgrade.expiryDate,
          ).toLocaleString(),
        },
      );
    }

    return null;
  }

  private setMinCount() {
    this.minLimits = [];
    this.formProducts.controls.forEach((formGroup: FormGroup) => {
      const product = formGroup.value.product as LicenseProduct;
      const productCfg = this.licenseProducts.find(
        (x) => x.product === product,
      );

      const count =
        this.mode === ModeType.newLicense
          ? 0
          : max([
              this.licenseToUpgrade?.products.find(
                (x) => x.product === productCfg.product,
              )?.count ?? 0,
              productCfg.used,
            ]);

      formGroup.controls.count.clearValidators();
      if (count > 0) {
        this.minLimits.push(count);
        formGroup.controls.count.addValidators([
          Validators.min(count),
          Validators.required,
        ]);
      } else {
        this.minLimits.push(null);
      }
    });
  }

  private load() {
    this.loading$.next(true);

    forkJoin({
      // Загружаем сведения о плательщике.
      billingContact: this.data
        .singleton('Tenant')
        .function('GetBillingContact')
        .get<BillingContact>(),

      tenantOverview: this.data
        .singleton('Tenant')
        .function('GetOverview')
        .get<TenantOverview>(),

      // Проверяем возможность создать счет.
      hasActiveInvoice: this.data
        .singleton('Tenant')
        .function('CheckActiveInvoice')
        .get<boolean>(),

      expiryOfLicensingPeriod: this.data
        .singleton('Tenant')
        .function('GetExpiryOfLicensingPeriod')
        .get<string>(),

      licenseInfo: this.data.model
        .function('GetLicenseInfo')
        .get<LicenseInfo>(),

      licenseToUpgrade: this.data
        .singleton('Tenant')
        .function('GetLicenseToUpgrade')
        .get<TenantLicenseDto>(),
    }).subscribe({
      next: (response) => {
        if (!response.billingContact.isValid) {
          this.mode = ModeType.error;
        }

        if (response.hasActiveInvoice) {
          this.mode = ModeType.activeInvoiceExists;
        }

        this.billingContact = response.billingContact;
        this.licenseToUpgrade = response.licenseToUpgrade;
        this.bonus = response.tenantOverview.currentBalance;
        this.expiryOfLicensingPeriod = response.expiryOfLicensingPeriod;

        this.licenseProducts = sortBy(
          response.licenseInfo.products,
          (x) => x.displayName,
        );

        this.licenseProducts.forEach((productCfg) => {
          const count = max([
            productCfg.used,
            this.licenseToUpgrade?.products.find(
              (x) => x.product === productCfg.product,
            )?.count ?? 0,
            productCfg.used,
          ]);

          const cfg: LicenseProductConfiguration = {
            product: productCfg.product,
            count: count === 0 ? null : count,
          };

          const formGroup = this.fb.group(cfg);

          this.formProducts.push(formGroup);
        });

        this.loading$.next(false);

        this.setMinCount();
        this.calculate();
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.loading$.next(false);
      },
    });
  }

  private calculate() {
    this.calculating$.next(true);

    if (this.licenseToUpgrade) {
      let selectedDate = DateTime.fromISO(
        this.form.controls['upgradeDate'].value,
      );
      const minDate = DateTime.max(
        DateTime.now(),
        DateTime.fromISO(this.licenseToUpgrade.effectiveDate),
      );
      const maxDate = DateTime.fromISO(this.licenseToUpgrade.expiryDate);

      if (selectedDate < minDate) {
        selectedDate = minDate;
      }

      if (selectedDate > maxDate) {
        selectedDate = maxDate;
      }
      this.form.controls['upgradeDate'].setValue(selectedDate.toISODate(), {
        emitEvent: false,
      });
    }

    const params: LicenseOrderCalculationParams = {
      products: this.formProducts.value.filter((x) => x.count > 0),
      monthsCount: this.form.value.period.id,
      upgradeDate:
        this.mode === ModeType.upgradeLicense
          ? this.form.value.upgradeDate
          : null,
    };

    this.calcSubscription?.unsubscribe();

    this.calcSubscription = this.data
      .singleton('Tenant')
      .action('CalculateLicenseOrder')
      .execute(params)
      .subscribe({
        next: (response: LicenseOrderCalculation) => {
          this.calculating$.next(false);
          const calc = new LicenseOrderCalculation();
          calc.amount = response.amount;
          calc.discount = response.discount;
          this.calculation$.next(calc);
        },
        error: () => {
          this.calculation$.next(null);
          this.calculating$.next(false);
        },
      });
  }

  public ok() {
    if (this.form.valid) {
      this.saving$.next(true);

      const params: LicenseOrderCalculationParams = {
        products: (
          this.formProducts.value as LicenseProductConfiguration[]
        ).filter((p) => p.count > 0),
        monthsCount: this.form.value.period.id,
        upgradeDate:
          this.mode === ModeType.upgradeLicense
            ? this.form.value.upgradeDate
            : null,
      };

      this.data
        .singleton('Tenant')
        .action('CreateInvoice')
        .execute(params)
        .subscribe({
          next: (invoice: any) => {
            this.invoice = invoice;
            this.invoice.name = this.translate.instant(
              'settings.account.createPayment.invoiceNameTemplate',
              {
                number: invoice.number,
                date: DateTime.fromISO(invoice.date).toLocaleString(
                  DateTime.DATE_FULL,
                ),
              },
            );

            this.saving$.next(false);
            this.mode = ModeType.completed;

            this.download();
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.saving$.next(false);
          },
        });
    } else {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
    }
  }

  public download() {
    this.blockUI.start();
    const url = `${AppConfigService.config.api.url}/tenant/license-invoice/${this.invoice.id}`;

    this.httpClient.get(url, { responseType: 'blob' }).subscribe({
      next: (data) => {
        saveAs(data, `Timetta Invoice #${this.invoice.number}.pdf`);
        this.blockUI.stop();
      },
      error: (error: Exception) => {
        this.message.error(error.message);
        this.blockUI.stop();
      },
    });
  }

  public editBillingInfo() {
    this.state.go('settings.settings.billing');
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  public close() {
    this.activeModal.close();
  }
}

interface TenantLicenseDto {
  effectiveDate: string;
  expiryDate: string;
  products: LicenseProductConfiguration[];
}

enum ModeType {
  newLicense = 'newLicense',
  upgradeLicense = 'upgradeLicense',
  error = 'error',
  completed = 'completed',
  activeInvoiceExists = 'activeInvoiceExists',
}
