@if (boardService.config) {
  <div class="board">
    <div class="board__toolbar">
      <wp-filter />
      <tmt-board-sort-button
        [properties]="boardService.sortItems"
        [storageName]="'board.' + boardService.config.id"
        (orderQuery$)="sortChange($event)"
      />
    </div>

    <div class="board__content">
      <div
        class="board__columns-header-wrap"
        [tmtDragAndDrop]="boardService.columns"
        [tmtDragAndDropOptions]="ddOptions"
        [dragDisabled]="!boardService.config.editAllowed"
      >
        @if (boardService.cardsByColumns) {
          @for (column of boardService.columns; track column.id) {
            <tmt-board-column-header [column]="column" [ngClass]="ddOptions.draggableClass" />
          }
        }

        @if (boardService.config.editAllowed && !(boardService.loading$ | async)) {
          <div>
            <button
              class="btn btn-sm btn-default board__columns-header-button"
              (click)="openColumnForm($event)"
              [ngbTooltip]="'components.boardColumnHeaderComponent.actions.addColumn' | translate"
              placement="top"
              container="body"
            >
              <i class="bi bi-plus"></i>
            </button>
          </div>
        }
      </div>

      <wp-loading-indicator [loading]="boardService.loading$ | async" />

      @if (!(boardService.loading$ | async)) {
        <div class="board__columns-content">
          @for (column of columns.length ? columns : boardService.columns; track column.id) {
            <tmt-board-track
              [cards]="boardService.cardsByColumns[column.id]"
              [column]="{
                id: column.id
              }"
            />
          }
        </div>
      }
    </div>
  </div>
} @else {
  <p class="text-center pt-3">
    {{ 'shared.entityNotFound.title' | translate }}
    <br />
    {{ 'shared.entityNotFound.details' | translate }}
  </p>
}
