import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { Transition } from '@uirouter/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { LEGAL_ENTITY_LIST } from 'src/app/shared/lists/legal-entity.list';

import { LegalEntityListComponent } from 'src/app/settings-app/legal-entity/list/legal-entity-list.component';
import { LegalEntityCreationComponent } from 'src/app/settings-app/legal-entity/creation/legal-entity-creation.component';
import { LegalEntityCardComponent } from 'src/app/settings-app/legal-entity/card/legal-entity-card.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    LegalEntityListComponent,
    LegalEntityCreationComponent,
    LegalEntityCardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.legalEntities',
          url: '/legal-entities',
          component: LegalEntityListComponent,
          params: {
            navigation: 'settings.legalEntities',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: LEGAL_ENTITY_LIST },
          ],
        },

        {
          name: 'settings.legalEntity',
          params: {
            navigation: 'settings.legalEntities',
          },
          url: '/legal-entities/{entityId:guid}',
          component: LegalEntityCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class LegalEntitiesModule {}
