import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { StateService } from '@uirouter/core';
import { InvoiceFilterService } from './invoice-filter/invoice-filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { InvoiceCreationComponent } from '../creation/invoice-creation.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LifecycleListService } from 'src/app/core/lifecycle-list.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'wp-invoice-list',
  template: '<wp-entity-list></wp-entity-list> ',
  providers: [
    { provide: FilterService, useClass: InvoiceFilterService },
    EntityListService,
    ListService,
    GridService,
    LifecycleListService,
  ],
})
export class InvoiceListComponent implements OnInit, OnDestroy {
  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private injector: Injector,
    private app: AppService,
    private state: StateService,
    private service: EntityListService,
    private gridService: GridService,
    private actions: ActionPanelService,
    private modalService: NgbModal,
    private lifecycleListService: LifecycleListService,
  ) {}

  public ngOnInit(): void {
    this.actions.set([
      this.actions.getDefaultAction('create', {
        title: 'billing.invoices.list.actions.create',
        isVisible: this.app.checkEntityPermission(
          'Invoice',
          PermissionType.Modify,
        ),
        handler: this.create,
      }),
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go('invoice', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actions.getDefaultAction('delete', {
        handler: this.service.delete,
      }),
    ]);

    this.gridService.selectedGroups$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((rows: any) => {
        this.actions.action('card').isShown = rows?.length === 1;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private create = () => {
    const ref = this.modalService.open(InvoiceCreationComponent, {
      injector: this.injector,
    });
    ref.componentInstance as InvoiceCreationComponent;
  };
}
