import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { filter } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/notification.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Program } from 'src/app/shared/models/entities/projects/program.model';
import { NavigationService } from 'src/app/core/navigation.service';
import { ProgramSettingsComponent } from 'src/app/programs/settings/program-settings.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuAction } from 'src/app/shared/models/inner/menu-action';

@Injectable()
export class ProgramCardService {
  private programId: string;
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  private reloadTabSubject = new Subject<void>();
  public reloadTab$ = this.reloadTabSubject.asObservable();

  private programTotalSubject = new BehaviorSubject<any>(null);
  public programTotal$ = this.programTotalSubject
    .asObservable()
    .pipe(filter((p) => p));

  private programSubject = new BehaviorSubject<Program>(null);
  public program$ = this.programSubject.asObservable().pipe(filter((p) => !!p));

  public settingsAction: MenuAction = {
    title: 'projects.programs.card.actions.properties.label',
    hint: 'projects.programs.card.actions.properties.hint',
    name: 'openSettings',
    isDropDown: false,
    iconClass: 'bi bi-sliders2',
    isBusy: false,
    isVisible: true,
    handler: () => this.openSettings(),
  };

  constructor(
    private data: DataService,
    private notification: NotificationService,
    private navigationService: NavigationService,
    private modal: NgbModal,
  ) {}

  public reloadTab() {
    this.reloadTabSubject.next();
  }

  load(programId: string) {
    this.programId = programId;
    this.state$.next(CardState.Loading);

    this.loadProgram();
    this.loadProgramTotal();
  }

  private loadProgramTotal() {
    const query = {
      select: ['actualHours'],
    };

    this.data
      .collection('ProgramTotals')
      .entity(this.programId)
      .get(query)
      .subscribe({
        next: (programTotal: any) => {
          this.programTotalSubject.next(programTotal);
        },
      });
  }

  private loadProgram() {
    const query = {
      select: [
        'id',
        'name',
        'isActive',
        'billingRatesViewAllowed',
        'editAllowed',
        'coManagersViewAllowed',
        'coManagersEditAllowed',
      ],
      expand: [{ manager: { select: ['name', 'id'] } }],
    };

    this.data
      .collection('Programs')
      .entity(this.programId)
      .get(query)
      .subscribe({
        next: (program: Program) => {
          this.navigationService.addRouteSegment({
            id: program.id,
            title: program.name,
          });

          this.programSubject.next(program);
          this.state$.next(CardState.Ready);
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);

          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notification.error(error.message);
          }
        },
      });
  }

  public openSettings() {
    const ref = this.modal.open(ProgramSettingsComponent, { size: 'lg' });
    (ref.componentInstance as ProgramSettingsComponent).programId =
      this.programId;
    ref.result.then(
      () => this.load(this.programId),
      () => null,
    );
  }
}
