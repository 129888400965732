<wp-loading-indicator [loading]="loading$ | async"></wp-loading-indicator>

<form class="modal-form" novalidate ngDraggable *ngIf="!(loading$ | async)">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.account.createPayment.header' | translate }}
    </h3>
    <button
      [disabled]="saving$ | async"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <!--Competed state-->
    <ng-container *ngIf="mode === modeType.completed">
      {{ 'settings.account.createPayment.completed' | translate }}<br /><br />
      <button class="btn btn-link p-0" (click)="download()">
        {{ invoice.name }}
      </button>
    </ng-container>

    <!--Toggler (new/upgrade)-->
    <ng-container
      *ngIf="(mode === modeType.newLicense || mode === modeType.upgradeLicense) && licenseToUpgrade"
    >
      <div class="btn-group btn-group-toggle mb-4" radioGroup [(ngModel)]="mode" name="mode">
        <label radioLabel class="btn-default">
          <input radioButton type="radio" [value]="modeType.newLicense" />
          {{ 'settings.account.createPayment.newLicense' | translate }}
        </label>
        <label radioLabel class="btn-default">
          <input radioButton type="radio" [value]="modeType.upgradeLicense" />
          {{ 'settings.account.createPayment.upgradeLicense' | translate }}</label
        >
      </div>
    </ng-container>

    <!--License params-->
    <div
      class="container-fluid p-0"
      *ngIf="mode === modeType.newLicense || mode === modeType.upgradeLicense"
    >
      <div class="row">
        <div class="col-6" [formGroup]="form">
          <div class="d-flex" *ngIf="mode == modeType.newLicense">
            <div class="form-group me-4" style="width: 150px">
              <label>{{ 'settings.account.createPayment.licensePeriod' | translate }}</label>
              <wp-select-box
                [allowNull]="false"
                [values]="licensePeriods"
                formControlName="period"
              ></wp-select-box>
            </div>

            <div class="form-group">
              <label>{{ 'settings.account.createPayment.discount' | translate }}</label>
              <p class="m-0" style="line-height: 34px">
                {{ this.form.value.period.discount | percent }}
              </p>
            </div>
          </div>

          <div class="form-group" style="width: 150px" *ngIf="mode === modeType.upgradeLicense">
            <label>{{ 'settings.account.createPayment.activationDate' | translate }}</label>
            <wp-date-box [allowNull]="false" formControlName="upgradeDate"></wp-date-box>
          </div>

          <div class="form-group">
            <table class="table table-sm table-striped">
              <thead>
                <tr>
                  <th>{{ 'settings.account.overview.product' | translate }}</th>
                  <th>{{ 'settings.account.overview.limit' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let product of licenseProducts; index as index"
                  [formGroup]="formProducts.at(index)"
                >
                  <td>{{ product.displayName }}</td>
                  <td>
                    <wp-number-box
                      [min]="minLimits[index]"
                      [allowNull]="minLimits[index] == null"
                      [type]="'integer'"
                      formControlName="count"
                      style="width: 75px"
                    ></wp-number-box>
                  </td>
                </tr>
              </tbody>
            </table>

            <p class="small" *ngIf="this.mode == modeType.newLicense">
              {{ 'settings.account.createPayment.newLicenseHelp' | translate }}
            </p>
          </div>

          <label class="group mt-3">{{
            'settings.account.createPayment.calculation' | translate
          }}</label>

          <div class="d-flex" *ngIf="calculation$ | async">
            <div class="form-group me-3" style="width: 150px">
              <label>{{ 'settings.account.createPayment.amount' | translate }}</label>
              <p
                class="form-control-static pt-0"
                [wpLoadingValue]="
                  (calculation$ | async)?.getAmountToPay() ?? 0
                    | wpCurrency: billingContact.currencyCode
                "
                [loading]="calculating$ | async"
              ></p>
            </div>

            <div class="form-group">
              <label>{{ 'settings.account.createPayment.period' | translate }}</label>
              <p class="form-control-static pt-0">{{ getOrderEffectivePeriod() }}</p>
            </div>
          </div>
          <p *ngIf="!(calculation$ | async)" class="form-control-static pt-0">
            {{ 'settings.account.createPayment.amountError' | translate }}
          </p>

          <div class="form-group" *ngIf="bonus > 0">
            <label>{{ 'settings.account.createPayment.bonus' | translate }}</label>
            <p class="form-control-static p-0 m-0">
              {{ bonus | wpCurrency: billingContact.currencyCode }}
            </p>
            <small>{{ 'settings.account.createPayment.bonusHelp' | translate }}</small>
          </div>
        </div>

        <div class="col-6">
          <tmt-billing-info [billingContact]="billingContact"></tmt-billing-info>
        </div>
      </div>
    </div>

    <!--Error state-->
    <div *ngIf="mode === modeType.error">
      <p>
        {{ 'settings.account.createPayment.billingContactIncorrect01' | translate }}<br /><br />
        {{ 'settings.account.createPayment.billingContactIncorrect02' | translate }}
        <br />
        <br />
        <button (click)="editBillingInfo()" type="button" class="btn btn-link p-0">
          {{ 'settings.account.createPayment.editBillingInfo' | translate }}
        </button>
      </p>
    </div>

    <!--Has invoice state-->
    <div class="col-6" *ngIf="mode === modeType.activeInvoiceExists">
      <p class="text-danger">
        {{ 'settings.account.createPayment.activeInvoiceExists' | translate }}
      </p>
    </div>
  </div>

  <div class="modal-footer">
    <button
      *ngIf="
        (this.mode == modeType.newLicense || this.mode == modeType.upgradeLicense) &&
        (calculation$ | async) &&
        formProducts.valid
      "
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="saving$ | async"
    >
      {{ 'settings.account.createPayment.create' | translate }}
    </button>

    <button
      *ngIf="mode != modeType.error && mode != modeType.completed"
      type="button"
      class="btn btn-default"
      (click)="cancel()"
    >
      {{ 'shared.actions.cancel' | translate }}
    </button>

    <button
      *ngIf="mode == modeType.error || mode == modeType.completed"
      type="button"
      class="btn btn-default"
      (click)="close()"
    >
      {{ 'shared.actions.close' | translate }}
    </button>
  </div>
</form>
