import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { RbcGroupType } from '../../models/rbc-view.model';
import { RbcSettings } from '../../models/rbc.settings';

@Injectable()
export class ProjectRbcCalendarGroupFilterService {
  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  private readonly _selectedGroups: RbcGroupType[] = [];
  public get selectedGroups(): RbcGroupType[] {
    return this._selectedGroups;
  }

  public get allGroups(): RbcGroupType[] {
    return Object.values(RbcGroupType);
  }

  constructor(private localConfigService: LocalConfigService) {
    const settings = this.localConfigService.getConfig(RbcSettings);
    this._selectedGroups = settings.displayedGroups;
  }

  /**
   * Toggles group presence in calendar
   *
   * @param group - group to show/hide
   */
  public toggleGroup(group: RbcGroupType) {
    if (this._selectedGroups.includes(group)) {
      const indexToRemove = this._selectedGroups.indexOf(group);
      this._selectedGroups.splice(indexToRemove, 1);

      this.updateConfig();
      this.changesSubject.next();
      return;
    }

    this._selectedGroups.push(group);
    this.updateConfig();
    this.changesSubject.next();
  }

  /**
   * Updates config. At the moment of writing updates only displayedGroups
   *
   * @private
   */
  private updateConfig() {
    const settings = this.localConfigService.getConfig(RbcSettings);
    settings.displayedGroups = this._selectedGroups;
    this.localConfigService.setConfig(RbcSettings, settings);
  }
}
