<wp-form-header [name]="formName" (reloaded)="service.reloadTab()"></wp-form-header>

<ul class="nav nav-tabs mb-3">
  <li class="nav-item" *ngFor="let tab of tabs">
    <a
      class="nav-link"
      [ngClass]="{ active: state?.current?.name === tab.state }"
      [uiSref]="tab.state"
      [uiParams]="{ routeMode: 'keep' }"
      >{{ tab.header | translate }}</a
    >
  </li>
</ul>
<ui-view></ui-view>
