import { DateValue } from './rbc-data.model';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';

export class RbcView {
  revenue: RbcViewSection;
  billing: RbcViewSection;
  collection: RbcViewSection;
}

export class RbcViewSection {
  name: string;
  type: RbcSectionType;
  plan: RbcViewGroup;
  estimate: RbcViewGroup;
  actual: RbcViewGroup;
  forecast: RbcViewGroup;
}

export class RbcViewGroup {
  id: string;
  name: string;
  type: RbcGroupType;
  isExpanded: boolean;
  total: number;
  entries: DateValue[];
  taskLines: RbcViewTaskLine[];
  otherLine?: RbcViewOtherLine;
}

export class RbcViewTaskLine {
  task: ProjectTask;
  total: number;
  entries: DateValue[];
  taskFullName: string;
  indent: number;
}

export class RbcViewOtherLine {
  title: string;
  verboseHint: string;
  total: number;
  entries: DateValue[];
}

export enum RbcSectionType {
  revenue = 'revenue',
  billing = 'billing',
  collection = 'collection',
}

export enum RbcGroupType {
  plan = 'plan',
  estimate = 'estimate',
  actual = 'actual',
  forecast = 'forecast',
}
