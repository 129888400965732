import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ResourceGroupsService } from './resource-groups/core/resource-groups.service';
import { Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { MenuAction } from 'src/app/shared/models/inner/menu-action';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { BlockUIService } from 'src/app/core/block-ui.service';

@Component({
  selector: 'tmt-users-groups',
  templateUrl: './users-groups.component.html',
  styleUrls: ['./users-groups.component.scss'],
  providers: [GridService, ResourceGroupsService],
})
export class UsersGroupsComponent implements OnInit, OnDestroy {
  @Input() entityId: string;
  public isLoading: boolean;

  public readonly = !this.app.checkPermission(
    'UserGroup',
    'All',
    PermissionType.Modify,
  );

  public form = new UntypedFormControl();

  public actions: MenuAction[] = [
    {
      title: 'shared.actions.save',
      hint: 'shared.actions.save',
      name: 'save',
      isDropDown: false,
      iconClass: 'bi bi-save',
      isBusy: false,
      isVisible: false,
      handler: () => this.save(),
    },
  ];
  private destroyed$ = new Subject<void>();
  private _isSaving: boolean;
  constructor(
    private notification: NotificationService,
    private message: MessageService,
    private blockUI: BlockUIService,
    public resourceGroupsService: ResourceGroupsService,
    private app: AppService,
    private actionService: ActionPanelService,
  ) {}

  ngOnInit() {
    this.actionService.run$
      .pipe(
        filter((x) => x.name === 'save'),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.save();
      });

    this.actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());

    this.resourceGroupsService.changes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.form.markAsDirty());

    this.resourceGroupsService.isLoading$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isLoading) => {
        this.actionService.action('save').isShown =
          !isLoading && !this.readonly;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Return value that signals is data saving now. */
  public get isSaving() {
    return this._isSaving;
  }

  /** Set value that signals is data saving now. */
  public set isSaving(value: boolean) {
    this._isSaving = value;

    if (value) {
      this.blockUI.start();
      this.actionService.action('save').start();
    } else {
      this.actionService.action('save').stop();
      this.blockUI.stop();
    }
  }

  /** Save entity method. */
  public save() {
    this.isSaving = true;

    this.resourceGroupsService.save().subscribe({
      next: () => {
        this.notification.successLocal('shared.messages.saved');
        this.form.markAsPristine();
        this.isSaving = false;
      },
      error: () => {
        this.isSaving = false;
      },
    });
  }
  private reload() {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  private load() {
    this.resourceGroupsService.load();
  }
}
