import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { Analytics } from 'src/app/settings-app/rate-matrix/card/structure-change-modal/rate-matrix-structure.model';
import { RateMatrix } from 'src/app/settings-app/rate-matrix/model/rate-matrix.model';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { CopyMatrixModalComponent } from 'src/app/settings-app/rate-matrix/copy-matrix-modal/copy-matrix-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { RateMatrixLine } from 'src/app/settings-app/rate-matrix/model/rate-matrix-line.model';

@Injectable()
export class RateMatrixService {
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  private nameSubject = new BehaviorSubject<string>(null);
  public name$ = this.nameSubject.asObservable();

  private saveTabSubject = new Subject<void>();
  public saveTab$ = this.saveTabSubject.asObservable();

  public readonly: boolean;
  public rateMatrix: RateMatrix;
  public rateMatrixStructure: Analytics[] = [];
  public isLinesExisting: boolean;
  public changeStructureMessage: string | null = null;

  private collection = this.data.collection('RateMatrices');
  private rateMatrixLinesCollection = this.data.collection('RateMatrixLines');
  private query = {
    expand: { type: { select: ['id', 'name'] } },
  };

  constructor(
    @Inject('entityId') private matrixId: string,
    private data: DataService,
    private modalService: NgbModal,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
  ) {}

  /**
   * Updates Name.
   *
   * @param value name of the matrix.
   */
  public updateName(value: string): void {
    this.nameSubject.next(value);
  }

  /** Saves tab. */
  public saveTab(): void {
    this.saveTabSubject.next();
  }

  /**
   * Gets rate matrices.
   *
   * @param entityId matrix guid.
   * @param query query.
   *
   * @returns Returns rateMatrices observable.
   */
  public getRateMatrices(
    entityId: string,
    query: any = this.query,
  ): Observable<RateMatrix> {
    return this.collection.entity(entityId).get<RateMatrix>(query);
  }

  /** Copies matrix. */
  public openCopyModal(): void {
    const ref = this.modalService.open(CopyMatrixModalComponent);
    (ref.componentInstance as CopyMatrixModalComponent).matrixId =
      this.matrixId;
  }

  /** Deletes entity. */
  public delete(): void {
    this.messageService.confirmLocal('shared.deleteConfirmation').then(
      () => {
        this.data
          .collection('RateMatrices')
          .entity(this.matrixId)
          .delete()
          .subscribe({
            next: () => {
              this.notificationService.successLocal(
                'settings.rateMatrices.card.messages.deleted',
              );
              this.navigationService.goToSelectedNavItem(true);
            },
            error: (error: Exception) => {
              this.messageService.error(error.message);
            },
          });
      },
      () => null,
    );
  }

  /**
   * Patches rate matrix line.
   *
   * @param id matrixId.
   * @param value Partial <RateMatrixLine>.
   *
   * @returns Observable of matrix lines.
   */
  public patchRateMatrixLine(
    id: string,
    value: Partial<RateMatrixLine>,
  ): Observable<void> {
    return this.rateMatrixLinesCollection.entity(id).patch(value);
  }

  /**
   * Patches rate matrix.
   *
   * @param id id.
   * @param value Partial <RateMatrix>.
   *
   * @returns Observable of matrix rates.
   */
  public patchRateMatrix(
    id: string,
    value: Partial<RateMatrix>,
  ): Observable<void> {
    return this.collection.entity(id).patch(value);
  }

  /** Builds matrix lines. */
  public buildMatrixLines(): Observable<void> {
    return this.collection.entity(this.matrixId).action('Build').execute();
  }
}
