import { Injectable, Type } from '@angular/core';
import { UserFilterComponent } from './user-filter.component';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { LicenseProduct } from 'src/app/shared/models/session/client-session.model';

@Injectable({
  providedIn: 'root',
})
export class UserFilterService extends FilterService {
  public override component: Type<FilterDetails> = UserFilterComponent;
  public override hasDetails = true;
  public hasActiveSelector = true;
  public override placeholder = 'settings.users.list.filter.placeholder';
  public override views = [
    { code: 'all', local: 'shared.filter.allEntities' },
    { code: 'active', local: 'shared.filter.onlyActiveEntities' },
  ];
  public override customCriteriaCount: Record<string, () => number> = {
    text: () => 0,
    view: () => 0,
    products: () => (this.getSelectedProducts().length ? 1 : 0),
  };

  protected override getDefaultValues = (): any => ({
    text: '',
    view: this.views[0],
    department: null,
    level: null,
    location: null,
    resourcePool: null,
    supervisor: null,
    products: [],
  });

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        or: [
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(name)': { contains: text } },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(code)': { contains: text } },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(email)': { contains: text } },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(position)': { contains: text } },
        ],
      });
    }
    if (this.values.resourcePool) {
      result.push({
        resourcePoolId: {
          eq: { type: 'guid', value: this.values.resourcePool.id },
        },
      });
    }

    if (this.values.level) {
      result.push({
        levelId: {
          eq: { type: 'guid', value: this.values.level.id },
        },
      });
    }

    if (this.values.location) {
      result.push({
        locationId: {
          type: 'guid',
          value: this.values.location.id,
        },
      });
    }

    if (this.values.supervisor) {
      result.push({
        supervisorId: {
          type: 'guid',
          value: this.values.supervisor.id,
        },
      });
    }

    if (this.values.department) {
      result.push({
        departmentId: {
          type: 'guid',
          value: this.values.department.id,
        },
      });
    }

    if (this.values.view && this.values.view.code === 'active') {
      result.push({ isActive: true });
    }

    if (this.getSelectedProducts().length) {
      const conditions = this.getSelectedProducts().map((product) => ({
        products: {
          any: { product },
        },
      }));

      result.push({ and: conditions });
    }

    return result;
  }

  private getSelectedProducts(): LicenseProduct[] {
    const products: LicenseProduct[] = [];

    if (!this.values.products) {
      return [];
    }

    Object.keys(this.values.products).forEach((key) => {
      if (this.values.products[key]) {
        products.push(key as LicenseProduct);
      }
    });

    return products;
  }
}
