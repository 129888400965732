<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.projects.card.team.addResourcesModal.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <tmt-grid [options]="gridOptions" [formArray]="formArray" [loading]="isLoading" />
      @if (loadedPartly) {
        <div class="text-body-secondary">
          {{ 'shared.showFirstNRecordsMessage' | translate: { limit: loadLimit } }}
          {{ 'shared.refineQueryMessage' | translate }}
        </div>
      }
    </div>
    <div class="row">
      <div class="col">
        <div class="selected-resources-container">
          @for (resource of selectedResources; track $index) {
            <div class="selected-resource-block" (click)="removeResource(resource, $index, $event)">
              <img class="avatar-bg" [src]="resource.id | avatar: 60" />
              <div class="trim" title="{{ resource.name }}">
                {{ resource.name }}
              </div>

              <button
                type="button"
                class="close"
                (click)="removeResource(resource, $index, $event)"
              >
                &times;
              </button>
            </div>
          }
          @if (!selectedResources.length) {
            <div class="text-uppercase text-body-secondary no-data">
              {{ 'projects.projects.card.team.addResourcesModal.noDisplayData' | translate }}
            </div>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    @if (selectedResources.length) {
      <button
        type="button"
        class="btn btn-primary"
        (click)="ok()"
        wpButtonSpinner
        [isBusy]="isSaving"
      >
        {{ 'shared.actions.add' | translate }}
      </button>
    }
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
