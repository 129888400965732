<wp-loading-indicator [state]="resourceRequestService.state$ | async"></wp-loading-indicator>

<form
  class="modal-form"
  novalidate
  ngDraggable
  *ngIf="(resourceRequestService.state$ | async) === 'Ready'"
>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'resources.requests.modal.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <tmt-resource-request-form-main
      [mode]="teamMemberId ? 'create' : 'menuCreate'"
      (form$)="onForm($event)"
    ></tmt-resource-request-form-main>
    <tmt-resource-request-calendar
      [mode]="teamMemberId ? 'create' : 'menuCreate'"
      [freezeTableOptions]="{ inContainerMode: true }"
    ></tmt-resource-request-calendar>
  </div>

  <div class="modal-footer">
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-primary"
        (click)="create()"
        wpButtonSpinner
        [isBusy]="isSaving"
      >
        {{ 'shared.actions.create' | translate }}
      </button>
    </div>

    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
