import { Injectable, Inject, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import {
  asyncScheduler,
  fromEvent,
  Observable,
  pipe,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import { map, takeUntil, tap, throttleTime } from 'rxjs/operators';

import {
  DirectionX,
  DirectionY,
  DragAndDropData,
} from 'src/app/shared/directives/drag-and-drop/drag-and-drop.model';

import {
  AutoScrollOptions,
  DragContext,
  DragEvent,
} from './drag-events.interface';

/** Timeline Drag&Drop service. */
@Injectable({
  providedIn: 'root', // TODO: remove root :D
})
export class DragDropService {
  public data: DragAndDropData;

  private onStartSubject = new Subject<DragAndDropData>();
  public onStart$ = this.onStartSubject.asObservable();
  private onDragOverSubject = new Subject<string>();
  public onDragOver$ = this.onDragOverSubject.asObservable();
  private onDropSubject = new Subject<DragAndDropData>();
  public onDrop$ = this.onDropSubject.asObservable();
  private onEndSubject = new Subject<DragAndDropData>();
  public onEnd$ = this.onEndSubject.asObservable();
  private onItemUpdateSubject = new Subject<DragAndDropData>();
  public onItemUpdate$ = this.onItemUpdateSubject.asObservable();
  private completedSubject = new Subject<void>();
  public completed$ = this.completedSubject.asObservable();

  public readonly dragEnd$: Observable<DragEvent> = fromEvent<PointerEvent>(
    this.document,
    'pointerup',
  ).pipe(
    map(
      ({ clientY, clientX, target }: PointerEvent): DragEvent => ({
        x: clientX,
        y: clientY,
        diffY: clientY - this.lastStartEvent?.clientY,
        diffX: clientX - this.lastStartEvent?.clientX,
        target,
      }),
    ),
  );
  public readonly pointermove$ = fromEvent<PointerEvent>( // TODO: runOutsideZone?
    this.document,
    'pointermove',
  );

  private eventThrottle = 10;
  private lastStartEvent: PointerEvent | null;
  private autoScrollingSubscription: Subscription | null;
  private lastAutoScrollParams: {
    directionY: DirectionY | undefined;
    directionX: DirectionX | undefined;
    scrollSpeed: number;
  } = {
    directionY: undefined,
    directionX: undefined,
    scrollSpeed: 0,
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private zone: NgZone,
  ) {}

  /** Return position diffs observable based at start event */
  public drag(
    startEvent: PointerEvent,
    context?: DragContext,
    autoScrollOptions?: AutoScrollOptions,
  ): Observable<DragEvent> {
    this.lastStartEvent = startEvent;
    return this.pointermove$.pipe(
      tap((event) => {
        event.preventDefault();
      }),
      throttleTime(this.eventThrottle, asyncScheduler, {
        leading: true,
        trailing: true,
      }),
      map((e: PointerEvent): DragEvent => {
        let x = e.clientX;
        let y = e.clientY;

        if (context) {
          x -= context.leftOffset;
          y -= context.topOffset;
        }

        if (autoScrollOptions) {
          this.startContainerScroll(
            {
              x,
              y,
              diffY: e.clientY - startEvent.clientY,
              diffX: e.clientX - startEvent.clientX,
            },
            autoScrollOptions,
          );
        }

        return {
          x,
          y,
          diffY: e.clientY - startEvent.clientY,
          diffX: e.clientX - startEvent.clientX,
        };
      }),
      takeUntil(this.dragEnd$),
    );
  }

  public setOnStart(): void {
    this.onStartSubject.next(this.data);
  }

  public setOnDragOver(toGroupName: string): void {
    this.onDragOverSubject.next(toGroupName);
  }

  public setOnDrop(): void {
    this.onDropSubject.next(this.data);
  }

  public setOnEnd(): void {
    this.onEndSubject.next(this.data);
  }

  public setOnItemUpdate(data: DragAndDropData): void {
    this.onItemUpdateSubject.next(data);
  }

  public setCompleted(): void {
    this.completedSubject.next();
  }

  private startContainerScroll(
    dragEvent: DragEvent,
    options: AutoScrollOptions,
  ): void {
    const scrollSpeeds = [1, 2.5, 5, 10];
    const breakpoint = 100;
    const { x, y } = dragEvent;
    const container = options.container;
    const target = options.target;
    const bodyRight =
      this.document.body.getBoundingClientRect().right + window.scrollX;
    const { width, height } = target.getBoundingClientRect();
    let { left, top, right, bottom } = container.getBoundingClientRect();

    left += window.scrollX;
    top += window.scrollY;
    right += window.scrollX;
    bottom += window.scrollY;

    let directionY: DirectionY;
    let directionX: DirectionX;
    let coordsDiff = 0;

    const scrollByDirection = (
      directionY: DirectionY | undefined,
      directionX: DirectionX | undefined,
      scrollSpeed: number,
    ) => {
      if (directionX === 'to left') {
        container.scrollLeft -= scrollSpeed;
        this.document.documentElement.scrollLeft -= scrollSpeed * 2;
      } else if (directionX === 'to right') {
        container.scrollLeft += scrollSpeed;
        this.document.documentElement.scrollLeft += scrollSpeed * 2;
      }

      if (directionY === 'to top') {
        container.scrollTop -= scrollSpeed;
      } else if (directionY === 'to bottom') {
        container.scrollTop += scrollSpeed;
      }
    };

    if (x - left < breakpoint) {
      coordsDiff = x - left;
      directionX = 'to left';
    } else if (right - (x + width) < breakpoint) {
      coordsDiff = right - (x + width);
      directionX = 'to right';
    } else if (bodyRight - (x + width) < breakpoint) {
      coordsDiff = bodyRight - (x + width);
      directionX = 'to right';
    }

    if (y - top < breakpoint) {
      coordsDiff = y - top;
      directionY = 'to top';
    } else if (bottom - (y + height) < breakpoint) {
      coordsDiff = bottom - (y + height);
      directionY = 'to bottom';
    }

    const speedIndex =
      coordsDiff < 0
        ? scrollSpeeds.length - 1
        : Math.min(
            Math.ceil(((breakpoint - coordsDiff) / 100) * scrollSpeeds.length),
            scrollSpeeds.length - 1,
          );
    const scrollSpeed = scrollSpeeds[speedIndex];

    if (
      (directionX === this.lastAutoScrollParams.directionX,
      directionY === this.lastAutoScrollParams.directionY,
      scrollSpeed === this.lastAutoScrollParams.scrollSpeed)
    ) {
      return;
    } else {
      this.lastAutoScrollParams = {
        directionX,
        directionY,
        scrollSpeed,
      };
    }

    this.autoScrollingSubscription?.unsubscribe();

    if (directionY || directionX) {
      this.zone.runOutsideAngular(() => {
        this.autoScrollingSubscription = timer(0, 10)
          .pipe(
            takeUntil(this.dragEnd$),
            options.takeUntil ? options.takeUntil : pipe(),
          )
          .subscribe(() => {
            scrollByDirection(directionY, directionX, scrollSpeed);
          });
      });
    }

    if (!directionX && !directionY) {
      this.autoScrollingSubscription = null;
    }
  }
}
