<wp-loading-indicator [loading]="(resourceRequestService.state$ | async) !== 'Ready' || !ready" />

@if ((resourceRequestService.state$ | async) === 'Ready' && ready && readonlyReasonTitle) {
  <div class="alert-top">
    {{ 'resources.requests.assistant.messages.readonly' | translate }}.
    {{ readonlyReasonTitle | translate }}.
  </div>
}

@if ((resourceRequestService.state$ | async) === 'Ready' && ready) {
  <div class="toolbar pt-3">
    <wp-booking-toolbar name="toolbar" [filterHidden]="bookingFilterService.values.resourceIds" />

    @if (!bookingService.isActualizationResourceRequest) {
      <button
        type="button"
        [title]="
          (!!filterBeforeCache
            ? 'resources.actions.returnFilter'
            : 'resources.actions.showSelected'
          ) | translate
        "
        class="btn btn-default"
        [class.active]="!!filterBeforeCache"
        [disabled]="!resourceRequestCalendarService.bookingEntries.length"
        (click)="toggleShowSelectedFilter()"
        data-test="toggleShowSelected"
      >
        {{
          (!!filterBeforeCache
            ? 'resources.actions.returnFilter'
            : 'resources.actions.showSelected'
          ) | translate
        }}
      </button>
    }

    <div role="group" class="btn-group">
      <input
        id="current-switcher"
        class="btn-check ng-untouched ng-pristine ng-valid"
        type="radio"
        [formControl]="switcherControl"
        [value]="'new'"
      />
      <label
        for="current-switcher"
        class="btn btn-default"
        [title]="'resources.actions.showNew' | translate"
      >
        {{ 'resources.actions.showNew' | translate }}
      </label>
      <input
        id="new-switcher"
        class="btn-check ng-untouched ng-pristine ng-valid"
        type="radio"
        [formControl]="switcherControl"
        [value]="'current'"
      />
      <label
        for="new-switcher"
        class="btn btn-default"
        [title]="'resources.actions.showCurrent' | translate"
      >
        {{ 'resources.actions.showCurrent' | translate }}
      </label>
    </div>

    <div class="group ms-auto">
      <button type="button" class="btn btn-warning" (click)="close()">
        {{ 'shared.actions.close' | translate }}&nbsp;<i class="bi bi-box-arrow-right"></i>
      </button>
    </div>
  </div>
}

@if ((resourceRequestService.state$ | async) === 'Ready') {
  <div
    [hidden]="!ready"
    class="main-container position-relative"
    (resized)="onMainContainerResized()"
    #mainContainer
  >
    <div #mainContainerAbsolute>
      <div tmtAreaResizing (areaResized)="onTopAreaResized($event)">
        <div
          class="scroll-container"
          [id]="requestCalendarFreezeOptions.rootContainerId"
          #plannerContainer
        >
          <div #resourceRequestCalendarContainer>
            <tmt-resource-request-calendar
              [mode]="'assistant'"
              [hasToolbar]="false"
              [freezeTableOptions]="requestCalendarFreezeOptions"
              [bookingFreezeTableOptions]="requestBookingFreezeOptions"
            />
          </div>
        </div>
      </div>

      <div class="rz-handle area-resizing">
        <div></div>
      </div>

      <div class="scroll-container" id="resources-container" #resourcesContainer>
        @if (ready) {
          <wp-booking
            frRootContainerId="resources-container"
            frName="resources"
            frSyncWith="planner"
            [hasHeader]="false"
            [hasToolbar]="false"
          />
        }
      </div>
    </div>
  </div>
}
