<div class="group d-flex flex-nowrap mr-11">
  <button
    type="button"
    title="{{ 'shared.planner.actions.previousPeriod' | translate }}"
    class="btn btn-default period"
    [disabled]="disabled"
    (click)="previousPeriod()"
    data-test="previousPeriod"
  >
    <span class="bi bi-arrow-left bi-15"></span>
  </button>
  <button
    type="button"
    title="{{ 'shared.planner.actions.nextPeriod' | translate }}"
    class="btn btn-default period"
    [disabled]="disabled"
    (click)="nextPeriod()"
    data-test="nextPeriod"
  >
    <span class="bi bi-arrow-right bi-15"></span>
  </button>

  <button
    *ngIf="isCalendarVisible"
    type="button"
    class="btn btn-default"
    [disabled]="disabled"
    (click)="calendarIsOpen = !calendarIsOpen"
    title="{{ 'shared.planner.actions.setPeriod' | translate }}"
    data-test="calendar"
  >
    <i class="bi bi-calendar3" aria-hidden="true"></i>
  </button>

  <div class="input-group" *ngIf="isCalendarVisible">
    <ngb-datepicker
      (clickOutside)="closeCalendar()"
      (dateSelect)="jump($event)"
      (clickOutsideEnabled)="(calendarIsOpen)"
      [delayClickOutsideInit]="true"
      class="dropdown-menu show p-0"
      *ngIf="calendarIsOpen"
    >
    </ngb-datepicker>
  </div>
</div>

<div class="group">
  <div *ngIf="isResourcesContext() && isValueModeVisible" ngbDropdown class="d-inline-block">
    <button
      type="button"
      class="btn btn-default"
      ngbDropdownToggle
      [disabled]="disabled"
      title="{{ 'shared.planner.actions.setValueMode' | translate }}"
      data-test="valueMode"
    >
      {{ getValueModeName(service.valueMode) }}
    </button>
    <div ngbDropdownMenu class="dropdown-scroll">
      <button
        ngbDropdownItem
        *ngFor="let valueMode of valueModes"
        (click)="service.setValueMode(valueMode)"
        [attr.data-test]="valueMode"
      >
        {{ 'shared.planner.valueMode.' + valueMode.toLowerCase() | translate }}
      </button>
    </div>
  </div>

  <div ngbDropdown class="d-inline-block" *ngIf="isPlanningScaleVisible">
    <button
      type="button"
      class="btn btn-default"
      ngbDropdownToggle
      [disabled]="disabled || service.isFixedBookingScale"
      title="{{ 'shared.planner.actions.setScale' | translate }}"
      data-test="planningScale"
    >
      {{ getPlanningScaleName(service.planningScale) }}
    </button>
    <div ngbDropdownMenu class="dropdown-scroll">
      <button
        ngbDropdownItem
        *ngFor="let scale of scales"
        (click)="service.setPlanningScale(scale)"
        [attr.data-test]="scale"
      >
        {{ 'enums.planningScale.' + scale | translate }}
      </button>
    </div>
  </div>
</div>
