/* eslint-disable @typescript-eslint/naming-convention */
/** Represents a Project version create mode. */
export enum CreateProjectVersionMode {
  /** Create empty version mode. */
  Empty = 'Empty',
  /** Copy estimates mode. */
  CopyEstimate = 'CopyEstimate',
  /** Copy forecast mode. */
  CopyForecast = 'CopyForecast',
}

export const CreateProjectVersionModes = [
  CreateProjectVersionMode.Empty,
  CreateProjectVersionMode.CopyEstimate,
  CreateProjectVersionMode.CopyForecast,
];
