<wp-loading-indicator [state]="state"></wp-loading-indicator>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" [hidden]="state !== 'Ready'" class="form form-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <label class="group">
          {{ 'settings.settings.tenantSettings.finance.header' | translate }}
        </label>

        <div class="form-group" tmtIndicator>
          <label class="control-label">{{
            'settings.settings.tenantSettings.finance.currency' | translate
          }}</label>
          <wp-select-box collection="Currencies" formControlName="currency" [allowNull]="false">
          </wp-select-box>
        </div>

        <label class="group mt-3">
          {{ 'settings.settings.tenantSettings.forecast.header' | translate }}
        </label>

        <div class="form-group">
          <label>{{
            'settings.settings.tenantSettings.forecast.forecastPeriod.label' | translate
          }}</label>
          <wp-select-box
            [allowNull]="false"
            [values]="forecastPeriods"
            [filterOnServer]="false"
            formControlName="forecastPeriod"
          ></wp-select-box>
        </div>

        <label class="group mt-3">
          {{ 'settings.settings.tenantSettings.billing.header' | translate }}
        </label>

        <div class="form-group" tmtIndicator>
          <label class="control-label">{{
            'settings.settings.tenantSettings.billing.vatSupportLabel' | translate
          }}</label>
          <div class="pt-2 form-check">
            <input formControlName="useVat" type="checkbox" class="form-check-input" id="useVat" />
            <label class="form-check-label" for="useVat">
              {{ 'settings.settings.tenantSettings.billing.vatSupport' | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="col">
        <label class="group">
          {{ 'settings.settings.tenantSettings.booking.header' | translate }}
        </label>

        <div class="form-group">
          <label
            >{{ 'settings.settings.tenantSettings.booking.mode' | translate }}
            <i
              class="bi bi-question-circle help-action"
              tmtDocsArticle="resources/components/booking-mode"
            ></i
          ></label>
          <div class="align-items-center d-flex form-control-plaintext">
            {{ form.value.bookingMode?.name
            }}<button (click)="changeBookingMode()" class="btn btn-link p-0 ms-3">
              {{ 'settings.settings.tenantSettings.booking.changeMode' | translate }}
            </button>
          </div>
        </div>
        <div class="form-group" tmtIndicator>
          <div class="form-check">
            <input
              formControlName="isBookingScaleFixed"
              type="checkbox"
              class="form-check-input"
              id="isBookingScaleFixed"
            />
            <label class="form-check-label" for="isBookingScaleFixed">
              {{ 'settings.settings.tenantSettings.booking.fixedBookingScale' | translate }}
            </label>
          </div>
        </div>
        <div
          class="form-group"
          tmtIndicator
          *ngIf="form.controls.isBookingScaleFixed.valueChanges | async"
        >
          <label class="control-label"
            >{{ 'settings.settings.tenantSettings.booking.bookingScale' | translate }}
            <i
              class="bi bi-question-circle help-action"
              tmtDocsArticle="resources/components/booking-scale"
            ></i
          ></label>
          <wp-select-box
            [values]="bookingScales"
            formControlName="bookingScale"
            [allowNull]="false"
            placeholder="{{ 'settings.settings.tenantSettings.booking.bookingScale' | translate }}"
          >
          </wp-select-box>
        </div>
      </div>
    </div>
  </div>

  <div class="actions" *ngIf="!readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="onSave()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
