<wp-loading-indicator [loading]="loading"></wp-loading-indicator>
<wp-user-settings-notifications-form [hidden]="loading" (form$)="onForm($event)">
</wp-user-settings-notifications-form>
<div class="pt-4">
  <button
    type="button"
    *ngIf="!readonly"
    class="btn btn-primary"
    wpButtonSpinner
    [isBusy]="isSaving"
    (click)="save()"
  >
    {{ 'shared.actions.save' | translate }}
  </button>
</div>
