import {
  DestroyRef,
  inject,
  Inject,
  Injectable,
  Injector,
  Optional,
} from '@angular/core';
import { DataService } from './data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  LifecycleInfo,
  LifecycleInfoTransition,
} from 'src/app/shared/models/entities/lifecycle/lifecycle-info.model';
import { BlockUIService } from './block-ui.service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  firstValueFrom,
  merge,
} from 'rxjs';
import { ActionPanelService } from './action-panel.service';
import { filter, shareReplay, switchMap } from 'rxjs/operators';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { MenuAction } from 'src/app/shared/models/inner/menu-action';
import { TransitionFormModalComponent } from 'src/app/shared/components/features/transition-form-modal/transition-form-modal.component';
import { Exception } from 'src/app/shared/models/exception';
import { MessageService } from 'src/app/core/message.service';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { WorkflowActionCommentFormComponent } from 'src/app/shared/components/features/workflow-action-comment-form/workflow-action-comment-form.component';
import { NavigationService } from './navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import _ from 'lodash';
import { AppService } from 'src/app/core/app.service';
import { MetaEntity } from 'src/app/shared/models/entities/settings/metamodel.model';
import { EntityTypesService } from 'src/app/shared/services/entity-types.service';

@Injectable()
export class LifecycleService {
  private _refreshLifecycleInfo$ = new BehaviorSubject<void>(undefined);
  public lifecycleInfo$: Observable<LifecycleInfo> =
    this._refreshLifecycleInfo$.pipe(
      switchMap(() => this.getLifecycleInfo()),
      shareReplay(1),
    );

  private reloadSubject = new Subject<void>();
  protected lifecycleInfo: LifecycleInfo;

  private destroyRef = inject(DestroyRef);

  /** Fires when the used component needs to be reloaded. */
  public reload$ = this.reloadSubject.asObservable();

  get autosavePromise(): Promise<void> {
    return (
      this.savingQueueService?.save() ??
      this.autosaveService?.save() ??
      Promise.resolve()
    );
  }

  constructor(
    @Inject('entityId') public entityId: string,
    @Optional() private autosaveService: AutosaveService,
    @Optional() private savingQueueService: SavingQueueService,
    @Inject(META_ENTITY_TYPE) private metaEntityType: string,
    protected actionPanelService: ActionPanelService,
    protected data: DataService,
    protected message: MessageService,
    protected modal: NgbModal,
    private injector: Injector,
    protected blockUI: BlockUIService,
    private navigationService: NavigationService,
    private app: AppService,
    private entityTypeService: EntityTypesService,
  ) {
    // Register actions.
    this.lifecycleInfo$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((info) => {
        this.lifecycleInfo = info;
        this.actionPanelService.registerLifecycle(info);
      });

    this.actionPanelService.run$
      .pipe(
        filter((a) => !!a.lifecycle),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((action) => {
        if (action.lifecycle.actionType === 'setState') {
          this.setState(action.lifecycle.stateId, action);
        }
        if (action.lifecycle.actionType === 'performTask') {
          this.performWorkflowTask(action);
        }
        if (action.lifecycle.actionType === 'startWorkflow') {
          this.startWorkflow(action.lifecycle.workflowId, action);
        }
        if (action.lifecycle.actionType === 'cancelWorkflow') {
          this.cancelWorkflow(action);
        }
      });
  }

  /** The hook is called on error during SetState and PerformWorkflowTask.
   *  If return true basic logic is not called.
   */
  public onActionError = (error: Exception): boolean => false;

  /** Reload information. */
  public reloadLifecycle() {
    this._refreshLifecycleInfo$.next();
  }

  /**
   * Gets collection name of current entity type.
   *
   * @returns collection name.
   */
  public getCollection(): Observable<string> {
    return this.entityTypeService.getEntityTypeCollection(this.metaEntityType);
  }

  private getLifecycleInfo(): Observable<LifecycleInfo> {
    return this.getCollection().pipe(
      switchMap((collection) =>
        this.data
          .collection(collection)
          .entity(this.entityId)
          .function('GetLifeCycleInfo')
          .get<LifecycleInfo>(),
      ),
    );
  }

  /** Set entity state. */
  public setState(
    stateId: string,
    action?: MenuAction,
    transition?: LifecycleInfoTransition,
  ) {
    this.autosavePromise.then(
      async () => {
        const collection = await firstValueFrom(this.getCollection());
        const executedTransition =
          transition ??
          this.lifecycleInfo.transitions.find(
            (transitionItem) => transitionItem.label === action.title,
          );

        let data: any = {
          stateId,
          transitionFormValue: {
            comment: null,
            propertyValues: [],
          },
        };

        if (executedTransition.hasTransitionForm) {
          const ref = this.modal.open(TransitionFormModalComponent);
          const instance =
            ref.componentInstance as TransitionFormModalComponent;

          instance.stateId = stateId;
          instance.entityId = this.entityId;
          instance.collection = collection;
          instance.transitionId = executedTransition.id;
          instance.metaEntity = this.getMetaEntity();
          data = await firstValueFrom(merge(ref.closed, ref.dismissed));
        }

        if (data && _.isObject(data)) {
          this.sendSetStateRequest(collection, this.entityId, data, action);
        }
      },
      () => null,
    );
  }

  public sendSetStateRequest(
    collection: string,
    entityId: string,
    data: object,
    action?: MenuAction,
  ) {
    this.blockUI.start();

    if (action) {
      this.actionPanelService.action(action.name).start();
    }

    return firstValueFrom(
      this.data
        .collection(collection)
        .entity(entityId)
        .action('SetState')
        .execute(data),
    ).then(
      () => {
        this.blockUI.stop();
        if (action) {
          this.actionPanelService.action(action.name).stop();
        }
        this.reloadLifecycle();
        this.reloadSubject.next();
        this.navigationService.updateIndicators();
      },
      (error: Exception) => {
        this.blockUI.stop();
        if (action) {
          this.actionPanelService.action(action.name).stop();
        }

        if (!this.onActionError(error)) {
          this.message.errorDetailed(error);
        }
      },
    );
  }

  /**
   * Send request to backend that perform workflow task
   * @param taskId Workflow task Id.
   * @param actionName Workflow action name.
   * @param data Data for request.
   * @returns Promise.
   */
  public sendPerformWorkflowTaskRequest(
    taskId: string,
    actionName: string,
    data: object,
  ): Promise<void> {
    this.actionPanelService.action(actionName).start();
    this.blockUI.start();

    return firstValueFrom(
      this.data
        .collection('WorkflowTasks')
        .entity(taskId)
        .action('PerformWorkflowTask')
        .execute(data),
    ).then(
      () => {
        this.blockUI.stop();
        this.actionPanelService.action(actionName).stop();
        this.reloadLifecycle();
        this.navigationService.updateIndicators();
        this.reloadSubject.next();
      },
      (error: Exception) => {
        this.blockUI.stop();
        this.actionPanelService.action(actionName).stop();
        if (!this.onActionError(error)) {
          this.message.errorDetailed(error);
        }
      },
    );
  }

  /** Perform Workflow task.
   *
   * @param action - Action for performing
   **/
  public performWorkflowTask(action: MenuAction) {
    const taskId = action.lifecycle.taskId;
    const actionId = action.lifecycle.workflowActionId;
    const executedTask = this.lifecycleInfo.workflowTaskActions.find(
      (workflowTask) => workflowTask.id === actionId,
    );

    this.autosavePromise.then(
      async () => {
        let data: any = {
          actionId,
          actionFormValue: {
            comment: null,
            propertyValues: [],
          },
        };
        if (executedTask.hasActionForm) {
          const ref = this.modal.open(WorkflowActionCommentFormComponent);

          const instance =
            ref.componentInstance as WorkflowActionCommentFormComponent;
          instance.actionId = actionId;
          instance.entityId = this.entityId;
          instance.collection = await firstValueFrom(this.getCollection());
          instance.taskId = taskId;
          instance.actionName = action.name;
          instance.metaEntity = this.getMetaEntity();

          data = await firstValueFrom(merge(ref.closed, ref.dismissed));
        }
        if (data && _.isObject(data)) {
          await this.sendPerformWorkflowTaskRequest(taskId, action.name, data);
        }
      },
      () => null,
    );
  }

  /** Start workflow. */
  public startWorkflow(workflowId: string, action: MenuAction) {
    this.autosavePromise.then(
      () => {
        this.actionPanelService.action(action.name).start();
        this.blockUI.start();

        const data = {
          workflowId,
        };

        this.getCollection()
          .pipe(
            switchMap((collection) =>
              this.data
                .collection(collection)
                .entity(this.entityId)
                .action('StartWorkflow')
                .execute(data),
            ),
          )
          .subscribe({
            next: () => {
              this.blockUI.stop();
              this.actionPanelService.action(action.name).stop();
              this.reloadLifecycle();
              this.reloadSubject.next();
              this.navigationService.updateIndicators();
            },
            error: (error: Exception) => {
              this.actionPanelService.action(action.name).stop();
              this.blockUI.stop();

              if (!this.onActionError(error)) {
                this.message.errorDetailed(error);
              }
            },
          });
      },
      () => null,
    );
  }

  /** Cancel workflow */
  private cancelWorkflow(action: MenuAction) {
    this.autosavePromise.then(
      () => {
        this.actionPanelService.action(action.name).start();
        this.blockUI.start();

        this.getCollection()
          .pipe(
            switchMap((collection) =>
              this.data
                .collection(collection)
                .entity(this.entityId)
                .action('CancelActiveWorkflow')
                .execute(),
            ),
          )
          .subscribe({
            next: () => {
              this.blockUI.stop();
              this.actionPanelService.action(action.name).stop();
              this.reloadLifecycle();
              this.navigationService.updateIndicators();
              this.reloadSubject.next();
            },
            error: (error: Exception) => {
              this.actionPanelService.action(action.name).stop();
              this.message.errorDetailed(error);
              this.blockUI.stop();
            },
          });
      },
      () => null,
    );
  }

  private getMetaEntity(): MetaEntity {
    return this.app.getMetaEntity(this.metaEntityType);
  }
}
