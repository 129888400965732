import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { ProjectCardService } from '../../../core/project-card.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { AppService } from 'src/app/core/app.service';
import { ProjectTaskView } from 'src/app/projects/card/project-tasks/shared/models/project-task-view.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-tasks-view-switcher',
  templateUrl: './tasks-view-switcher.component.html',
  styleUrls: ['./tasks-view-switcher.component.scss'],
})
export class TasksViewSwitcherComponent implements OnInit {
  public readonly projectTaskView = ProjectTaskView;

  public control = new FormControl<ProjectTaskView>(this.projectTaskView.table);

  private destroyRef = inject(DestroyRef);

  constructor(
    public app: AppService,
    private state: StateService,
    public projectCardService: ProjectCardService,
  ) {}

  public ngOnInit(): void {
    switch (this.state.current.name) {
      case 'project.tasks.table':
        this.control.setValue(this.projectTaskView.table);
        break;
      case 'project.tasks.timeline':
        this.control.setValue(this.projectTaskView.timeline);
        break;
    }

    this.control.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.state.go(`project.tasks.${this.control.value}`, {
          routeMode: RouteMode.keep,
        });
      });
  }
}
