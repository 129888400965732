import { Component } from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/shared/globals/constants';

/** Диалог создания правила валидации. */
@Component({
  selector: 'wp-validation-rule-creation',
  templateUrl: './validation-rule-creation.component.html',
})
export class ValidationRuleCreationComponent {
  isSaving = false;
  form: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    validationRuleType: [null, Validators.required],
  });

  constructor(
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const data = {
      name: this.form.get('name').value,
      validationRuleTypeId: this.form.get('validationRuleType').value.id,
    };

    this.data
      .collection('ValidationRules')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notification.successLocal(
            'settings.validationRules.creation.messages.created',
          );
          this.state.go('settings.validationRule', { entityId: response.id });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
