import {
  Component,
  forwardRef,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewRef,
  OnInit,
  DestroyRef,
  inject,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
} from '@angular/forms';

import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TranslateService } from '@ngx-translate/core';
import { PerformersService } from '../performers.service';
import { Performer } from 'src/app/shared/models/entities/state.model';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PerformerTypeName } from 'src/app/settings-app/shared/performers/model/performer-type-name.enum';
import {
  LC_PERFORMER_EXCLUDED_ROLES,
  WF_EXCLUDED_ROLES,
  WF_FUNCTION_EXCLUDED_ROLES,
  WF_NOTIFICATION_EXCLUDED_ROLES,
} from 'src/app/settings-app/shared/performers/performers-constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'wp-performer-box',
  templateUrl: './performer-box.component.html',
  styleUrls: ['./performer-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PerformerBoxComponent),
      multi: true,
    },
  ],
})
export class PerformerBoxComponent implements ControlValueAccessor, OnInit {
  @Input() placeholder: string;
  @ViewChild('input') inputEl: ElementRef;

  public isOpened = false;
  public performer: Performer;
  public isLoading: boolean;
  public readonly: boolean;
  public empty: boolean;

  public form = this.fb.group({
    performerControl: null,
    type: 'user',
  });

  /**Доступные к выбору роли */
  public roles$: Observable<NamedEntity[]>;

  private destroyRef = inject(DestroyRef);
  constructor(
    private translate: TranslateService,
    public performersService: PerformersService,
    private fb: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.roles$ = this.performersService.getAvailableRoles().pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap((roles) => {
        const availableRoles = roles.filter((role) => {
          let currentRoles = [];
          this.performersService.performers.map((performerLine) => {
            if (performerLine.performer.type === 'role') {
              currentRoles.push(performerLine.performer.id);
            }
          });
          switch (this.performersService.performerTypeName) {
            case PerformerTypeName.workflowFunction:
              currentRoles = currentRoles.concat(WF_FUNCTION_EXCLUDED_ROLES);
              break;
            case PerformerTypeName.workflowSetting:
              currentRoles = currentRoles.concat(WF_EXCLUDED_ROLES);
              break;
            case PerformerTypeName.lifecycle:
              currentRoles = currentRoles.concat(LC_PERFORMER_EXCLUDED_ROLES);
              break;
            case PerformerTypeName.workflowNotification:
              currentRoles = currentRoles.concat(
                WF_NOTIFICATION_EXCLUDED_ROLES,
              );
              break;
          }
          return currentRoles.find(
            (i) => i === role.id && i !== this.performer.id,
          )
            ? false
            : role;
        });
        const localizedRoles = [];
        availableRoles.forEach((role: NamedEntity) => {
          localizedRoles.push({
            id: role.id,
            name: this.performersService.getRoleName(role.name),
          });
        });
        return of(localizedRoles);
      }),
    );

    this.form.controls.type.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.form.controls.performerControl.setValue(null);
      });

    this.form.controls.performerControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.ref.markForCheck();
      });
  }

  public propagateChange = (_: Performer): void => null;
  public propagateTouch = (): void => null;

  public writeValue(performer: Performer): void {
    this.performer = performer;

    this.form.patchValue(performer);
    if (performer.id && performer.name) {
      this.form.controls.performerControl.patchValue({
        id: performer.id,
        name: performer.name,
      });
    } else {
      this.form.controls.performerControl.patchValue(null);
    }

    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;

    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  /**
   * Gets performer's name.
   *
   * @returns Name.
   */
  public getPerformerName(): string {
    this.empty = false;
    let name = this.performer.name;
    if (!name) {
      name = this.translate.instant('shared.valueNotSelected');
      this.empty = true;
    }

    return name;
  }

  /** Listens event onClick. */
  public onInputClick(): void {
    if (!this.isOpened) {
      this.openList();
    }
  }

  /** Opens List. */
  public openList(): void {
    if (!this.isOpened) {
      this.isOpened = true;
      return;
    }
  }

  /** Cancels changes. */
  public cancel(): void {
    this.isOpened = false;

    /* Репатч на случай, если был изменен тип и нажата отмена */
    this.form.patchValue(this.performer);
    if (this.performer.id && this.performer.name) {
      this.form.controls.performerControl.patchValue({
        id: this.performer.id,
        name: this.performer.name,
      });
    } else {
      this.form.controls.performerControl.patchValue(null);
    }
  }

  /** Saves changes. */
  public ok() {
    this.performer.id = this.form.value.performerControl.id ?? null;
    this.performer.name = this.form.value.performerControl.name ?? null;
    this.performer.type = this.form.value.type;

    this.cancel();
    this.propagateChange(this.performer);
  }

  /**
   * Gets filter.
   *
   * @param performerType performerType.
   *
   * @returns Filter.
   */
  public getFilter(performerType: string) {
    let idsString = '';
    this.performersService.performers.map((performerLine) => {
      if (
        performerLine.performer.type === performerType &&
        performerLine.performer.id !== this.performer.id
      ) {
        idsString =
          idsString === ''
            ? performerLine.performer.id
            : idsString + `,${performerLine.performer.id}`;
      }
    });
    return { filter: [`not (id in (${idsString}))`] };
  }

  /**
   * Gets performerTypeName.
   *
   * @param type type.
   *
   * @returns performerTypeName.
   */
  public getPerformerTypeName(type: string): string {
    return this.translate.instant(
      `settings.lifecycles.card.props.transition.performers.${type}`,
    );
  }
}
