import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { PermissionType } from '../../../../shared/models/inner/permission-type.enum';
import { MenuAction } from '../../../../shared/models/inner/menu-action';
import { NotificationService } from '../../../../core/notification.service';
import { MessageService } from '../../../../core/message.service';
import { BlockUIService } from '../../../../core/block-ui.service';
import { DataService } from '../../../../core/data.service';
import { AppService } from '../../../../core/app.service';
import { ActionPanelService } from '../../../../core/action-panel.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Exception } from '../../../../shared/models/exception';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tmt-group-permissions',
  templateUrl: './group-permissions.component.html',
  styleUrls: ['./group-permissions.component.scss'],
})
export class GroupPermissionsComponent implements OnInit {
  @Input() entityId: string;

  public isLoading: boolean;
  public get isSaving(): boolean {
    return this._isSaving;
  }
  public set isSaving(value: boolean) {
    this._isSaving = value;

    if (value) {
      this.blockUI.start();
      this.actionService.action('save').start();
    } else {
      this.actionService.action('save').stop();
      this.blockUI.stop();
    }
  }
  public readonly = !this.app.checkEntityPermission(
    'Group',
    PermissionType.Modify,
  );
  public roles = this.app.getRolesEntities();
  public accessForm = this.fb.array([]);
  public actions: MenuAction[] = [
    {
      title: 'settings.groups.card.access.actions.save',
      hint: 'settings.groups.card.access.actions.save',
      name: 'save',
      isDropDown: false,
      iconClass: 'bi bi-save',
      isBusy: false,
      isVisible: false,
      handler: () => this.save(),
    },
  ];
  private _isSaving: boolean;
  private destroyRef = inject(DestroyRef);

  constructor(
    private notification: NotificationService,
    private message: MessageService,
    private blockUI: BlockUIService,
    private data: DataService,
    private app: AppService,
    private actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
  ) {}

  public ngOnInit(): void {
    this.actionService.set(this.actions);

    if (!this.readonly) {
      this.actionService.action('save').show();
    }

    this.roles.forEach((role: any) => {
      role.query = { filter: [{ roleName: role.id }] };
      this.accessForm.push(this.fb.control(null));
    });

    this.load();

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  /** Loading method. */
  public load = (): void => {
    this.isLoading = true;
    this.actionService.action('save').hide();

    const params = {
      select: ['id'],
      expand: [{ permissionSet: { select: ['id', 'name', 'roleName'] } }],
    };

    this.data
      .collection('Groups')
      .entity(this.entityId)
      .collection('GroupPermissionSets')
      .query<object[]>(params)
      .subscribe({
        next: (data) => {
          this.roles.forEach((role: any, index: number) => {
            const loadedGroupSet: any = data.find(
              (groupSet: any) => groupSet.permissionSet.roleName === role.id,
            );
            this.accessForm
              .at(index)
              .setValue(loadedGroupSet ? loadedGroupSet.permissionSet : null);
          });

          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.readonly ? this.accessForm.disable() : this.accessForm.enable();
          this.accessForm.markAsPristine();
          this.accessForm.markAsUntouched();
          this.actionService.action('save').isShown = !this.readonly;
          this.isLoading = false;
        },
        error: (error: Exception) => {
          this.isLoading = false;
          this.notification.error(error.message);
        },
      });
  };

  /** Permission sets saving method. */
  public save(): void {
    this.isSaving = true;
    const data = { groupPermissionSets: [] as any };
    const sets = this.accessForm.value as any[];
    sets.forEach((set: any) => {
      if (!set) {
        return;
      }
      data.groupPermissionSets.push({
        groupId: this.entityId,
        permissionSetId: set.id,
      });
    });

    this.data
      .collection('Groups')
      .entity(this.entityId)
      .action('WP.UpdateGroupPermissionSets')
      .execute(data)
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.groups.card.access.messages.saved',
          );
          this.accessForm.markAsPristine();
          this.isSaving = false;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }

  private reload(): void {
    if (!this.accessForm.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }
}
