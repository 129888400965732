import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { AppService } from './app.service';

/** Сервис для получения локализуемых шаблонов (текстовых данных). */
@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(
    private httpClient: HttpClient,
    private app: AppService,
    private sanitizer: DomSanitizer,
  ) {}

  public getHtml(name: string): Observable<SafeHtml> {
    return new Observable((subscriber) => {
      const url = `/assets/templates/${this.app.session.language}/${name}.html`;
      this.httpClient
        .get(url, { responseType: 'text' })
        .subscribe((content: string) => {
          const text = this.sanitizer.bypassSecurityTrustHtml(content);
          subscriber.next(text);
        });
    });
  }
}
