import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectSummaryFilterComponent } from 'src/app/project-summary/shared/project-summary-filter/project-summary-filter.component';
import { ProjectSummaryFilterModalComponent } from 'src/app/project-summary/shared/project-summary-filter/project-summary-filter-modal/project-summary-filter-modal.component';
import { ProjectSummaryFilterService } from 'src/app/project-summary/shared/project-summary-filter/core/project-summary-filter.service';

@NgModule({
  declarations: [
    ProjectSummaryFilterComponent,
    ProjectSummaryFilterModalComponent,
  ],
  exports: [ProjectSummaryFilterComponent],
  imports: [CommonModule, SharedModule],
  providers: [ProjectSummaryFilterService],
})
export class ProjectSummaryFilterModule {}
