import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { DatePeriodType } from '../shared/models/enums/date-period-type.enum';
import { DatePeriod } from '../shared/models/entities/date-period.model';

@Injectable({ providedIn: 'root' })
export class DateService {
  public getDatePair(periodType: DatePeriodType): { from: string; to: string } {
    let from: DateTime;
    let to: DateTime;

    if (periodType === DatePeriodType.ThisWeek) {
      from = DateTime.now().startOf('week');
      to = DateTime.now().endOf('week');
    }

    if (periodType === DatePeriodType.PrevWeek) {
      from = DateTime.now().startOf('week').minus({ days: 7 });
      to = DateTime.now().startOf('week').minus({ days: 1 });
    }

    if (periodType === DatePeriodType.ThisMonth) {
      from = DateTime.now().startOf('month');
      to = DateTime.now().endOf('month');
    }

    if (periodType === DatePeriodType.PrevMonth) {
      from = DateTime.now().startOf('month').minus({ months: 1 });
      to = DateTime.now().startOf('month').minus({ days: 1 });
    }

    if (periodType === DatePeriodType.ThisQuarter) {
      from = DateTime.now().startOf('quarter');
      to = DateTime.now().endOf('quarter');
    }

    if (periodType === DatePeriodType.PrevQuarter) {
      from = DateTime.now().startOf('quarter').minus({ months: 3 });
      to = DateTime.now().startOf('quarter').minus({ days: 1 });
    }
    if (periodType === DatePeriodType.ThisYear) {
      from = DateTime.now().startOf('year');
      to = DateTime.now().endOf('year');
    }
    if (periodType === DatePeriodType.PrevYear) {
      from = DateTime.now().startOf('year').minus({ years: 1 });
      to = DateTime.now().startOf('year').minus({ days: 1 });
    }

    if (periodType === DatePeriodType.NextYear) {
      from = DateTime.now().startOf('year').plus({ years: 1 });
      to = DateTime.now().endOf('year').plus({ years: 1 });
    }

    if (periodType === DatePeriodType.NextQuarter) {
      from = DateTime.now().startOf('quarter').plus({ quarter: 1 });
      to = DateTime.now().endOf('quarter').plus({ quarter: 1 });
    }

    if (periodType === DatePeriodType.NextMonth) {
      from = DateTime.now().startOf('month').plus({ month: 1 });
      to = DateTime.now().endOf('month').plus({ month: 1 });
    }

    if (periodType === DatePeriodType.NextWeek) {
      from = DateTime.now().startOf('week').plus({ days: 7 });
      to = DateTime.now().endOf('week').plus({ days: 7 });
    }

    if (periodType === DatePeriodType.Last7Days) {
      from = DateTime.now().minus({ days: 6 });
      to = DateTime.now();
    }

    if (periodType === DatePeriodType.Last30Days) {
      from = DateTime.now().minus({ days: 29 });
      to = DateTime.now();
    }

    if (periodType && from && to) {
      return { from: from.toISODate(), to: to.toISODate() };
    } else {
      return null;
    }
  }

  /** Возвращает словарь для формирования запроса к API с периодом. */
  getDatePairFromPeriodForFilter(period: DatePeriod): {
    periodStart: string;
    periodFinish: string;
  } {
    const result: { periodStart: string; periodFinish: string } = null;
    if (period) {
      if (period.periodType !== DatePeriodType.Custom) {
        const datePair = this.getDatePair(period.periodType);
        return { periodStart: datePair.from, periodFinish: datePair.to };
      } else {
        return { periodStart: period.from, periodFinish: period.to };
      }
    }
    return result;
  }
}
