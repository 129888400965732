import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TimeOffRequest } from 'src/app/shared/models/entities/base/time-off-request.model';

@Injectable()
export class TimeOffInfoService {
  private openSubject = new Subject<{
    timeOffRequest: TimeOffRequest;
    containerId: string;
  }>();
  public open$ = this.openSubject.asObservable();

  public open(timeOffRequest: TimeOffRequest, containerId: string) {
    this.openSubject.next({
      containerId,
      timeOffRequest,
    });
  }
}
