import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { ProjectTeamComponent } from './project-team.component';
import { UserAssessmentModalComponent } from './user-assessment-modal/user-assessment-modal.component';
import { TeamToolbarComponent } from './team-toolbar/team-toolbar.component';
import { AssessmentCellComponent } from './assessment-cell/assessment-cell.component';
import { MemberModalComponent } from './member-modal/member-modal.component';
import { MemberResourceCellComponent } from './member-resource-cell/member-resource-cell.component';
import { ProjectCardSharedModule } from '../shared/project-card-shared.module';
import { GenericModalComponent } from 'src/app/projects/card/project-team/generic-modal/generic-modal.component';
import { GenericAssignmentModalComponent } from './generic-assignment-modal/generic-assignment-modal.component';
import { MemberModalCostToolbarComponent } from './member-modal/member-modal-cost-toolbar/member-modal-cost-toolbar.component';
import { ProjectTariffCellComponent } from 'src/app/projects/card/project-team/project-tariffs-cell/project-tariffs-cell.component';
import { ProjectTariffCellReadonlyComponent } from 'src/app/projects/card/project-team/project-tariffs-cell/project-tariffs-cell-readonly.component';
import { AddResourcesModalComponent } from 'src/app/projects/card/project-team/add-resources-modal/add-resources-modal.component';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';
import { AddResourceModalToolbarComponent } from 'src/app/projects/card/project-team/add-resources-modal/add-resources-modal-toolbar/add-resources-modal-toolbar.component';

@NgModule({
  declarations: [
    ProjectTeamComponent,
    AddResourcesModalComponent,
    MemberResourceCellComponent,
    MemberModalComponent,
    MemberModalCostToolbarComponent,
    UserAssessmentModalComponent,
    GenericAssignmentModalComponent,
    TeamToolbarComponent,
    ProjectTariffCellComponent,
    ProjectTariffCellReadonlyComponent,
    AssessmentCellComponent,
    GenericModalComponent,
    AddResourceModalToolbarComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProjectCardSharedModule,
    Grid2Module,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.team',
          url: '/team',
          component: ProjectTeamComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class ProjectTeamModule {}
