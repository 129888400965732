<form class="modal-form" novalidate ngDraggable autocomplete="off" [formGroup]="form">
  <div class="modal-header">
    <h3 class="modal-title">{{ 'finance.periods.creation.header' | translate }}</h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="d-flex">
      <div class="form-group">
        <label>{{ 'finance.periods.card.properties.start' | translate }}</label>
        <wp-date-box [allowNull]="false" formControlName="start"></wp-date-box>
      </div>

      <div class="form-group ms-4">
        <label>{{ 'finance.periods.card.properties.end' | translate }}</label>
        <wp-date-box [allowNull]="false" formControlName="end"></wp-date-box>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
