<tmt-form-header [name]="service.groupName$ | async"></tmt-form-header>

<tmt-not-found [state]="service.state$ | async"></tmt-not-found>
<wp-loading-indicator [state]="service.state$ | async"></wp-loading-indicator>

<ul class="nav nav-tabs mb-3 mt-3" *ngIf="(service.state$ | async) === 'Ready'">
  <li class="nav-item" *ngFor="let tab of tabs">
    <a
      class="nav-link"
      [ngClass]="{ active: uiGlobals.current?.name === tab.state }"
      [uiSref]="tab.state"
      [uiParams]="{ routeMode: 'keep' }"
      >{{ tab.header | translate }}</a
    >
  </li>
</ul>
<ui-view></ui-view>
