import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Guid } from 'src/app/shared/helpers/guid';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
} from '@angular/forms';
import { DataService } from 'src/app/core/data.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { sortBy } from 'lodash';
import { SearchTask } from './search-task.model';
import { Task } from '../models/task.model';
import { ScrollToService } from 'src/app/shared/services/scroll-to.service';
import { auditTime, first } from 'rxjs/operators';
import { TimesheetProject } from './timesheet-project.model';
import { TimesheetProjectTask } from './timesheet-project-task.model';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { TaskInfoComponent } from 'src/app/shared/components/features/task-info/task-info.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wpTimesheetTask]',
  templateUrl: './timesheet-task.component.html',
  styleUrls: ['./timesheet-task.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimesheetTaskComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetTaskComponent implements ControlValueAccessor, OnInit {
  @Input() timesheetId: string;
  @Input() showClient: boolean;

  @ViewChild('input') inputEl: ElementRef;

  public task: Task;
  public openedTaskInfo: string;
  public readonly: boolean;
  public areaExpanded = false;

  public clientsLoading: boolean;
  public projectsLoading: boolean;
  public tasksLoading: boolean;
  public searchLoading: boolean;

  public id = Guid.generate();
  public areaId = Guid.generate();
  public inputId = Guid.generate();

  public expandedTasks = [];
  public searchControl = this.fb.control('');

  public clients: NamedEntity[];
  public projects: TimesheetProject[];
  public tasks: TimesheetProjectTask[];
  public searchTasks: SearchTask[] = [];

  public selectedClient: NamedEntity;
  public selectedProject: TimesheetProject;
  public selectedTask: TimesheetProjectTask;
  public selectedSearchTask: SearchTask;

  private clientLoadingSubscription: Subscription;
  private projectsLoadingSubscription: Subscription;
  private tasksLoadingSubscription: Subscription;
  private searchSubscription: Subscription;

  public get searchPh(): string {
    return this.showClient
      ? 'timesheets.card.taskSelector.searchPlaceholder'
      : 'timesheets.card.taskSelector.searchPlaceholderWithoutClient';
  }
  public get phSelectTask(): string {
    return this.showClient
      ? 'timesheets.card.columns.task.header'
      : 'timesheets.card.columns.taskWithoutClient.header';
  }

  public propagateChange = (_: any) => null;

  constructor(
    private scrollToService: ScrollToService,
    private notification: NotificationService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private translate: TranslateService,
    private ref: ChangeDetectorRef,
    public infoPopupService: InfoPopupService,
    private injector: Injector,
  ) {}

  writeValue(obj: Task): void {
    this.task = obj;
    this.ref.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched = (fn: any) => null;

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  reset() {
    this.clients = [];
    this.projects = [];
    this.tasks = [];
    this.selectedClient = null;
    this.selectedProject = null;
    this.selectedTask = null;
    this.selectedSearchTask = null;
    this.searchControl.setValue('', { emitEvent: false });
    this.expandedTasks = [];
    this.ref.markForCheck();
  }

  /** Открыть область выбора. */
  public openArea() {
    if (this.readonly) {
      return;
    }

    this.reset();
    if (this.showClient) {
      this.clientsLoad();
    } else {
      this.projectsLoad();
    }
    this.areaExpanded = !this.areaExpanded;

    // Установить фокус на поле поиска.
    setTimeout(() => {
      this.inputEl.nativeElement.select();
    });
  }

  /** Закрыть область выбора. */
  close() {
    if (this.areaExpanded) {
      this.areaExpanded = false;
      this.infoPopupService.close();
    }
  }

  cancel() {
    if (this.areaExpanded) {
      this.areaExpanded = false;
    }
  }

  // Отменить активные загрузки.
  cancelLoading() {
    if (this.clientLoadingSubscription) {
      this.clientLoadingSubscription.unsubscribe();
    }

    if (this.projectsLoadingSubscription) {
      this.projectsLoadingSubscription.unsubscribe();
    }

    if (this.tasksLoadingSubscription) {
      this.tasksLoadingSubscription.unsubscribe();
    }

    this.projectsLoading = false;
    this.clientsLoading = false;
    this.tasksLoading = false;
  }

  /** Выбрать клиента. */
  public clientClick(id: string) {
    this.cancelLoading();
    const client = this.clients.find((c) => c.id === id);

    this.selectedClient = client;

    this.projects = [];
    this.tasks = [];

    this.selectedProject = null;
    this.selectedTask = null;

    this.projectsLoad();
  }

  /** Выбрать проект. */
  public projectClick(id: string) {
    this.cancelLoading();

    const project = this.projects.find((p) => p.id === id);

    this.selectedProject = project;

    this.tasks = [];
    this.selectedTask = null;

    this.tasksLoad();
  }

  /** Выбрать задачу. */
  public taskClick(id: string, e: any) {
    // Отключение события при клике на иконку в поле.
    if (e.toElement && e.toElement.nodeName === 'SPAN') {
      return;
    }

    this.cancelLoading();
    const task = this.tasks.find((t) => t.id === id);
    if (!task.allowEntry) {
      return;
    }

    this.selectedTask = task;
  }

  /** Выбрать задачу (из списка поиска). */
  public searchTaskClick(id: string) {
    const task = this.searchTasks.find((t) => t.id === id);
    this.selectedSearchTask = task;
  }

  /** Применить (выбрать) выбранную задачу. */
  ok() {
    if (!this.selectedSearchTask && !this.selectedTask) {
      return;
    }

    // Выбор строки из таблицы поиска.
    if (this.selectedSearchTask) {
      this.task = {
        client: this.selectedSearchTask.organization.id
          ? this.selectedSearchTask.organization
          : null,
        project: {
          id: this.selectedSearchTask.project.id,
          name: this.selectedSearchTask.project.name,
        },
        projectTask: {
          id: this.selectedSearchTask.projectTask.id,
          name: this.selectedSearchTask.projectTask.name,
        },
        isMainTask: this.selectedSearchTask.isMainTask,
        billingTypeCode: this.selectedSearchTask.billingTypeCode,
      };
    } else {
      // Выбор строки из таблицы выбора.
      this.task = {
        client: this.selectedProject.organizationId
          ? {
              id: this.selectedProject.organizationId,
              name: this.selectedProject.organizationName,
            }
          : null,
        project: this.selectedProject,
        projectTask: this.selectedTask,
        isMainTask: !this.selectedTask.leadTaskId,
        billingTypeCode: this.selectedProject.billingTypeCode,
      };
    }

    this.propagateChange(this.task);
    this.areaExpanded = false;
  }

  public selectTask(task: TimesheetProjectTask) {
    if (task.allowEntry) {
      this.selectedTask = task;
      this.ok();
    }
  }

  /** Загрузка клиентов. */
  clientsLoad() {
    this.clients = [];
    this.clientsLoading = true;

    this.clientLoadingSubscription = this.data
      .collection('TimeSheets')
      .entity(this.timesheetId)
      .function('WP.GetOrganizations')
      .query<NamedEntity[]>(null, { orderBy: 'name' })
      .subscribe({
        next: (data) => {
          this.clients = data;

          if (this.clients.length > 0 && this.clients[0].id === null) {
            this.clients[0].id = 'null';
            this.clients[0].name =
              '[' +
              this.translate.instant(
                'timesheets.card.taskSelector.withoutClient',
              ) +
              ']';
          }

          this.clients.splice(0, 0, {
            id: 'any',
            name:
              '[' +
              this.translate.instant('timesheets.card.taskSelector.anyClient') +
              ']',
          });

          this.clientsLoading = false;
          this.ref.markForCheck();

          // Если в строке выбрана организация попробуем найти ее в загруженных и выделить запись.
          if (this.task.project) {
            let selectedId;

            if (this.task.project && !this.task.client) {
              selectedId = 'null';
            } else {
              selectedId = this.task.client.id;
            }

            const currentClient = this.clients.find(
              (client) => client.id === selectedId,
            );
            if (currentClient) {
              this.selectedClient = currentClient;

              setTimeout(() => {
                this.scrollToService.scrollTo(
                  this.selectedClient.id,
                  'clients-container',
                );
              });
              this.projectsLoad();
              return;
            }
          }

          // Если не выбран проект в строке или не найден - то выделяем "Любой проект".
          this.selectedClient = { id: 'any', name: '' };
          this.projectsLoad();
        },
        error: (error: Exception) => {
          this.clientsLoading = false;
          this.notification.error(error.message);
          this.ref.markForCheck();
        },
      });
  }

  /** Загрузка проектов. */
  projectsLoad() {
    this.projectsLoading = true;

    const params: any = {};
    if (this.selectedClient && this.selectedClient.id !== 'any') {
      params.organizationId = this.selectedClient.id;
    }

    this.projectsLoadingSubscription = this.data
      .collection('TimeSheets')
      .entity(this.timesheetId)
      .function('WP.GetProjects')
      .query<TimesheetProject[]>(params, { orderBy: 'name' })
      .subscribe({
        next: (data) => {
          this.projects = data;
          this.projectsLoading = false;
          this.ref.markForCheck();

          // Если в строке выбран проект попробуем найти его в загруженных и выделить запись.
          if (this.task.project) {
            const currentProject = this.projects.find(
              (project) => this.task.project.id === project.id,
            );
            if (currentProject) {
              this.selectedProject = currentProject;

              setTimeout(() => {
                this.scrollToService.scrollTo(
                  this.selectedProject.id,
                  'projects-container',
                );
              });

              this.tasksLoad();
            }
          }
        },
        error: (error: Exception) => {
          this.projectsLoading = false;
          this.notification.error(error.message);
          this.ref.markForCheck();
        },
      });
  }

  /** Загрузка задач. */
  tasksLoad() {
    this.tasksLoading = true;
    this.tasks = [];

    const params = { projectId: this.selectedProject.id };

    this.tasksLoadingSubscription = this.data
      .collection('TimeSheets')
      .entity(this.timesheetId)
      .function('WP.GetProjectTasks')
      .query<TimesheetProjectTask[]>(params)
      .subscribe({
        next: (data) => {
          this.tasks = data;

          this.tasksLoading = false;
          this.ref.markForCheck();

          // Если в строке выбрана задача попробуем найти ее в загруженных и выделить запись
          if (this.task.projectTask) {
            const currentTask = this.tasks.find(
              (task) => task.id === this.task.projectTask.id,
            );

            if (currentTask) {
              this.selectedTask = currentTask;

              // Раскрываем все дерево до элемента
              this.expandUp(this.selectedTask.id);

              setTimeout(() => {
                this.scrollToService.scrollTo(
                  this.selectedTask.id,
                  'tasks-container',
                );
              });
            }
          }

          // Ведущую задачу раскрываем
          const leadTask = this.tasks.find((task) => !task.leadTaskId);
          this.addExpanded(leadTask.id);
          this.updateTaskView();
        },
        error: (error: Exception) => {
          this.tasksLoading = false;
          this.notification.error(error.message);
          this.ref.markForCheck();
        },
      });
  }

  /** Поиск задач (в режиме поиска). */
  search() {
    this.searchLoading = true;
    this.searchTasks = [];
    this.selectedSearchTask = null;
    this.ref.markForCheck();

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    const text = encodeURIComponent(
      // eslint-disable-next-line @typescript-eslint/quotes
      `'${this.searchControl.value.split("'").join("''")}'`,
    );

    this.searchSubscription = this.data
      .collection('TimeSheets')
      .entity(this.timesheetId)
      .function('WP.SearchProjectTasks')
      .query<SearchTask[]>(
        { text: '@text' },
        { orderBy: 'projectTask/name' },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { '@text': text },
      )
      .subscribe({
        next: (data) => {
          this.searchTasks = data;

          this.searchTasks.forEach((task) => {
            task.id = task.projectTask.id;

            if (!task.organization) {
              task.organization.name =
                '[' +
                this.translate.instant(
                  'timesheets.card.taskSelector.withoutClient',
                ) +
                ']';
            }
          });
          this.searchLoading = false;
          this.ref.markForCheck();
        },
        error: (error: Exception) => {
          this.searchLoading = false;
          this.notification.error(error.message);
          this.ref.markForCheck();
        },
      });
  }

  /** Возвращает стиль задачи с отступом. */
  public getTaskMargin(level: number, isLead: boolean) {
    let margin = 20 * level;
    // eslint-disable-next-line eqeqeq
    if (isLead == undefined) {
      margin += 27;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const value = { 'margin-left': margin + 'px' };
    return value;
  }

  /** Получение верхней строчки Клиент->Проект для отображения */
  public getClPrName() {
    if (!this.task.project) {
      return '';
    }

    let text = '';
    if (this.showClient) {
      if (!this.task.client) {
        text = `[${this.translate.instant(
          'timesheets.card.taskSelector.withoutClient',
        )}]`;
      } else {
        text = this.task.client.name;
      }

      if (!this.task.isMainTask) {
        text += ' > ';
      }
    }

    return this.task.isMainTask ? text : text + this.task.project.name;
  }

  public clickExpander(row: any) {
    const task = this.tasks.find((t) => t.id === row.id);
    task.isExpanded = !row.isExpanded;

    if (task.isExpanded) {
      this.addExpanded(task.id);
    } else {
      this.removeExpanded(task.id);
    }

    this.updateTaskView();
  }

  /** Обновить представление задач проектов. */
  private updateTaskView() {
    const createdRows = [];
    const isTaskShow = (leadTaskId) => {
      if (!leadTaskId) {
        return true;
      }

      const t = this.tasks.find((task) => task.id === leadTaskId);
      if (t.isExpanded === false) {
        return false;
      }

      if (t.leadTaskId) {
        return isTaskShow(t.leadTaskId);
      }

      return true;
    };

    const renderLevel = (
      renderTasks: TimesheetProjectTask[],
      indent: number,
    ) => {
      const rows = sortBy(renderTasks, 'number');

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < rows.length; i++) {
        const storedTask = this.tasks.find((t) => t.id === rows[i].id);
        const task = rows[i];

        task.isShow = true;

        const childTasks = this.tasks.filter(
          (t) => t.leadTaskId === rows[i].id,
        );

        // Обнаружили суммарную задачу.
        if (childTasks.length > 0) {
          storedTask.isExpanded = task.isExpanded = this.isExpanded(task.id);
        } else {
          storedTask.isExpanded = null;
        }

        // Установка видимости задачи.
        task.isShow = isTaskShow(task.leadTaskId);
        task.indent = indent;
        createdRows.push(task);
        renderLevel(childTasks, indent + 1);
      }
    };

    const tasks = this.tasks.filter((task) => !task.leadTaskId);
    renderLevel(tasks, 0);
    this.tasks = createdRows;
  }

  addExpanded(id: string) {
    if (!this.expandedTasks.find((taskId) => taskId === id)) {
      this.expandedTasks.push(id);
    }
  }

  removeExpanded(id: string) {
    for (let i = 0; i < this.expandedTasks.length; i++) {
      if (this.expandedTasks[i] === id) {
        this.expandedTasks.splice(i, 1);
        return;
      }
    }
  }

  expandUp(id: string) {
    const task = this.tasks.find((t) => t.id === id);
    this.addExpanded(id);
    if (task && task.leadTaskId) {
      this.expandUp(task.leadTaskId);
    }
  }

  isExpanded(id: string) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.expandedTasks.length; i++) {
      if (this.expandedTasks[i] === id) {
        return true;
      }
    }
    return false;
  }

  public getHint() {
    if (!this.task.project) {
      return '';
    }
    let projectName = this.getClPrName();
    if (projectName) {
      projectName += '\n';
    }
    return projectName + this.task.projectTask.name;
  }

  public trackId = (index: number, item: any) => item.id;

  public openInfo($event: MouseEvent, taskId: string): void {
    if (!this.task) {
      return;
    }

    $event.stopPropagation();
    this.infoPopupService.close();

    const popupId = this.infoPopupService.open({
      target: $event.target,
      data: {
        component: TaskInfoComponent,
        params: {
          taskId,
          timesheetId: this.timesheetId,
        },
        injector: this.injector,
      },
      popperModifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 3],
          },
        },
      ],
    });

    this.openedTaskInfo = taskId;

    this.infoPopupService.event$
      .pipe(first((v) => v.name === 'destroy' && v.popup?.id === popupId))
      .subscribe(() => {
        this.openedTaskInfo = null;
        this.ref.markForCheck();
      });
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(auditTime(500)).subscribe(() => {
      this.search();
    });
  }
}
