import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class InvoiceSettings implements BaseSettings {
  public readonly name = 'InvoiceSettings';
  version: number;
  period: DatePeriod;
  timeLinesGrouping: string;
  expenseLinesGrouping: string;

  getDefault(): InvoiceSettings {
    return {
      version: 1,
      period: null,
      timeLinesGrouping: 'Role',
      expenseLinesGrouping: 'ExpenseType',
    } as InvoiceSettings;
  }
}
