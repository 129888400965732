<div
  *ngIf="!readonly"
  class="input-group"
  [ngClass]="{ nullable: allowNull }"
  wpIntersectable
  (onIntersect)="cancel()"
>
  <ng-container *ngTemplateOutlet="avatar; context: { $implicit: value ?? null }" />

  <input
    #input
    class="form-control"
    (blur)="onBlur()"
    [formControl]="textControl"
    (keydown)="onKeyDown($event)"
    type="text"
    title="{{ value?.name }}"
    placeholder="{{ placeholder }}"
    (click)="onInputClick()"
  />

  <span
    class="input-clear ml-0"
    title="{{ 'shared.hintClearControlButton' | translate }}"
    (click)="clear()"
    [hidden]="!textControl.value"
    >&times;</span
  >

  <button type="button" class="btn btn-default input-group-text" (click)="openList()">
    <i class="bi bi-chevron-down" aria-hidden="true"></i>
  </button>
</div>

@if (readonly && !value) {
  <p class="trim readonly-text text-body-secondary text-uppercase">
    {{ 'shared.valueNotSelected' | translate }}
  </p>
}

@if (readonly && value) {
  <div class="d-flex align-items-center gap-1 readonly trim" title="{{ value.name }}">
    <ng-container *ngTemplateOutlet="avatar; context: { $implicit: value }" />{{ value.name }}
  </div>
}

<ng-template #expandingArea>
  <div class="select-expand-container">
    <div class="fixed-expanding-area expanding-area-vertical-scroll">
      <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

      <div
        *ngIf="!rows.length && !isLoading"
        class="center-block"
        style="text-align: center; padding: 3px"
      >
        <p class="text-body-secondary">{{ 'shared.noOptions' | translate }}</p>
      </div>

      <table id="selecting-list" class="selecting-list" [hidden]="isLoading">
        <tbody>
          <tr
            *ngFor="let row of rows"
            (mouseenter)="selectRow(row)"
            (click)="clickRow(row)"
            id="{{ row.id }}"
            title="{{ row.name }}"
            [ngClass]="{ 'selected-row': row.id == selectedRow?.id }"
          >
            <td style="width: 45px">
              <ng-container *ngTemplateOutlet="avatar; context: { $implicit: row }" />
            </td>
            <td class="trim">{{ row.name }}</td>
          </tr>
        </tbody>
      </table>

      <div
        *ngIf="loadedPartly && !isLoading"
        class="center-block"
        style="text-align: center; padding: 3px"
      >
        <p class="text-body-secondary">
          {{ 'shared.showFirstNRecordsMessage' | translate: { limit: loadLimit } }}<br />
          {{ 'shared.refineQueryMessage' | translate }}
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #avatar let-item>
  @if (item?.resourceType === resourceType.generic) {
    <img src="/assets/images/avatar/role.svg" />
  } @else {
    <img class="avatar-bg" [src]="item?.id | avatar: 60" />
  }
</ng-template>
