import { ResourcePool } from 'src/app/shared/models/entities/settings/resource-pool.model';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';
import { Department } from 'src/app/shared/models/entities/settings/department.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

/** Represents a Resource Summary Filter Settings. */
export class ResourceSummaryFilterSettings implements BaseSettings {
  public readonly name = 'ResourceSummaryFilterSettings';
  public version: number;
  /** Project. */
  public project: NamedEntity;
  /** Program. */
  public program: NamedEntity;
  /** Client. */
  public client: NamedEntity;
  /** Resource Pool. */
  public resourcePool: ResourcePool;
  /** Department. */
  public department: ResourceSummaryDepartment;
  /** Role. */
  public role: NamedEntity;
  /** Level. */
  public level: NamedEntity;
  /** Location. */
  public location: NamedEntity;
  /** Search Text. */
  public searchText: string;
  /** Determines whether to filter by User Subordinates or not. */
  public isMySubordinates: boolean;
  /** Determines whether to filter by User Projects Resource Team Members or not. */
  public isMyProjects: boolean;

  public getDefault(): ResourceSummaryFilterSettings {
    return {
      version: 1,
      project: null,
      program: null,
      client: null,
      resourcePool: null,
      department: null,
      role: null,
      level: null,
      location: null,
      searchText: '',
      isMySubordinates: false,
      isMyProjects: false,
    } as ResourceSummaryFilterSettings;
  }
}

/** Represents a Resource Summary Department. */
export interface ResourceSummaryDepartment extends Department {
  /** Value. */
  value: NamedEntity;
  /** Determines whether to include User Subordinates or not. */
  includeSubordinates: boolean;
}
