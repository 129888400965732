<div
  ngbDropdown
  container="body"
  *ngIf="(rowState.isHover || rowState.isMenuOpened) && rowState.commands.length > 0"
  (openChange)="openRowMenu($event)"
>
  <button class="btn btn-default without-caret" ngbDropdownToggle>
    <i class="bi bi-three-dots" aria-hidden="true"></i>
  </button>
  <div ngbDropdownMenu>
    <button
      ngbDropdownItem
      *ngFor="let command of rowState.commands"
      (click)="execute(command)"
      title="{{ command.title }}"
      [attr.data-test]="command.name"
    >
      {{ command.title }}
    </button>
  </div>
</div>
