<div tabindex="0" [attr.cells-group]="serviceId" [ngClass]="mode">
  <div
    class="spreader-control"
    (mousedown)="onSpreaderMouseDown($event)"
    (dblclick)="onSpreaderDblClick($event)"
    *ngIf="!readonly && mode.spreader"
  ></div>
  <div class="frame" (dblclick)="onDblClick()" (mousedown)="onMouseDown()" #frame></div>
  <div class="content">
    <div class="value">
      {{ entry?.hours | plannerFormat: valueMode : entry.scheduleHours : entry.fteHours }}
    </div>
    <input
      #editor
      *ngIf="mode.editing"
      name="input"
      autocomplete="off"
      [value]="viewValue"
      (mousedown)="onInputMouseDown($event)"
      (keydown)="onKeydown($event)"
      (input)="handleInput()"
    />
  </div>
</div>
