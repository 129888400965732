import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime, Interval } from 'luxon';
import { Subject } from 'rxjs';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { RbcSettings } from 'src/app/projects/card/project-rbc/models/rbc.settings';
import { ScheduleNavigationContext } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-context.enum';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import {
  Slot,
  SlotGroup,
} from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { ExpensesCalendarSettings } from 'src/app/projects/card/project-expenses/models/expenses-calendar.settings';
import { ResourcePlannerSettings } from 'src/app/projects/card/project-resources/models/resource-planner-settings.model';
import { ResourceForecastCalendarPlannerSettings } from 'src/app/projects/card/project-resources/models/resource-forecast-calendar-settings.model';
import { BookingSettings } from 'src/app/booking/booking/models/booking.settings';
import { ProjectSummaryViewSettings } from 'src/app/project-summary/models/project-summary-view.settings';
import { AppService } from 'src/app/core/app.service';
import { ResourceRequirementsSettings } from 'src/app/projects/card/project-resource-requirements/models/resource-requirements-settings.model';
import { ResourceSummaryViewSettings } from 'src/app/resource-summary/models/resource-summary-view.settings';
import { TimelineSettings } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/models/timeline.settings';

/**
 * Сервис для навигации по дате и времени в расписаниях
 */
@Injectable()
export class ScheduleNavigationService {
  public planningScale: PlanningScale;
  public valueMode: ValueMode;

  // Changing values of `ValueMode` and `planningScale` emits asynchronous action.
  // On Resolve value changes, on Reject nothing happened.
  // If property is null, value changes immediately.
  public asyncActionBeforeChange: () => Promise<boolean> | null = null;

  /** Уведомление о переходе на следующий период */
  private nextSubject = new Subject<void>();
  public next$ = this.nextSubject.asObservable();

  /** Уведомление о переходе на предыдущий период */
  private previousSubject = new Subject<void>();
  public previous$ = this.previousSubject.asObservable();

  /** Уведомление о "прыжке" на конкретную дату */
  private jumpSubject = new Subject<DateTime>();
  public jump$ = this.jumpSubject.asObservable();

  /** Уведомление о изменении planningScale */
  private planningScaleSubject = new Subject<PlanningScale>();
  public planningScale$ = this.planningScaleSubject.asObservable();

  /** Уведомление о изменении valueMode */
  private valueModeSubject = new Subject<ValueMode>();
  public valueMode$ = this.valueModeSubject.asObservable();

  /** Контекст, в котором будет работать сервис */
  public context: ScheduleNavigationContext = ScheduleNavigationContext.Booking;
  public isFixedBookingScale: boolean;

  constructor(
    private translate: TranslateService,
    private localConfigService: LocalConfigService,
    private appService: AppService,
  ) {
    /** Извлекаем сохраненные данные из localStorage */
    const config = this.localConfigService.getConfig(BookingSettings);
    this.planningScale = config.planningScale;
    this.valueMode = config.valueMode;
  }

  /**
   * Инициализирует сервис навигации.
   *
   * @param context - контекст, в котором будет работать сервис.
   * @example
   * init(ScheduleNavigationContext.Booking)
   */

  public init(context: ScheduleNavigationContext) {
    this.context = context;
    switch (this.context) {
      case ScheduleNavigationContext.ResourceRequirements: {
        const config = this.localConfigService.getConfig(
          ResourceRequirementsSettings,
        );
        const planningScaleFromSession =
          this.appService.session.configuration.bookingScale;
        if (planningScaleFromSession) {
          this.planningScale = planningScaleFromSession;
          this.isFixedBookingScale = true;
        } else {
          this.planningScale = config.planningScale;
          this.isFixedBookingScale = false;
        }
        this.valueMode = config.valueMode;
        this.localConfigService.setConfig(ResourceRequirementsSettings, config);
        break;
      }
      case ScheduleNavigationContext.Booking: {
        const config = this.localConfigService.getConfig(BookingSettings);
        const planningScaleFromSession =
          this.appService.session.configuration.bookingScale;

        if (planningScaleFromSession) {
          this.planningScale = planningScaleFromSession;
          this.isFixedBookingScale = true;
        } else {
          this.planningScale = config.planningScale;
          this.isFixedBookingScale = false;
        }
        this.valueMode = config.valueMode;
        this.localConfigService.setConfig(BookingSettings, config);
        break;
      }
      case ScheduleNavigationContext.Rbc: {
        const config = this.localConfigService.getConfig(RbcSettings);
        this.planningScale = config.planningScale;
        this.localConfigService.setConfig(RbcSettings, config);
        break;
      }
      case ScheduleNavigationContext.Expenses: {
        const config = this.localConfigService.getConfig(
          ExpensesCalendarSettings,
        );
        this.planningScale = config.planningScale;
        this.localConfigService.setConfig(ExpensesCalendarSettings, config);
        break;
      }
      case ScheduleNavigationContext.ProjectSummary: {
        const config = this.localConfigService.getConfig(
          ProjectSummaryViewSettings,
        );
        this.planningScale = config.planningScale;
        this.valueMode = config.valueMode;
        this.localConfigService.setConfig(ProjectSummaryViewSettings, config);
        break;
      }
      case ScheduleNavigationContext.Resources: {
        const config = this.localConfigService.getConfig(
          ResourcePlannerSettings,
        );
        this.planningScale = config.planningScale;
        this.valueMode = config.valueMode;
        this.localConfigService.setConfig(ResourcePlannerSettings, config);
        break;
      }
      case ScheduleNavigationContext.ResourceSummary: {
        const config = this.localConfigService.getConfig(
          ResourceSummaryViewSettings,
        );
        this.planningScale = config.planningScale;
        this.valueMode = config.valueMode;
        this.localConfigService.setConfig(ResourceSummaryViewSettings, config);
        break;
      }
      case ScheduleNavigationContext.ResourcesCalendar: {
        const config = this.localConfigService.getConfig(
          ResourceForecastCalendarPlannerSettings,
        );
        this.planningScale = config.planningScale;
        this.valueMode = config.valueMode;
        this.localConfigService.setConfig(
          ResourceForecastCalendarPlannerSettings,
          config,
        );
        break;
      }
      case ScheduleNavigationContext.ProjectTasksTimeline: {
        const config = this.localConfigService.getConfig(TimelineSettings);
        this.planningScale = config.planningScale;
        this.localConfigService.setConfig(TimelineSettings, config);
      }
    }
  }

  /**
   * Следующий период.
   *
   */

  public next() {
    this.nextSubject.next();
  }

  /**
   * Предыдущий период.
   *
   */

  public previous() {
    this.previousSubject.next();
  }

  /**
   * Переход к указанной дате.
   *
   * @param date - дата к которой необходимо перейти.
   */

  public jump(date: DateTime) {
    this.jumpSubject.next(date);
  }

  /**
   * Устанавливает масштаб планирования (Day, Week, Year)
   *
   * @example
   * setPlanningScale(planningScale: PlanningScale.Day)
   */

  public setPlanningScale(planningScale: PlanningScale) {
    const handle = () => {
      this.planningScale = planningScale;
      this.saveSettings();
      this.planningScaleSubject.next(planningScale);
    };

    if (this.asyncActionBeforeChange) {
      this.asyncActionBeforeChange().then((value) => {
        if (value) {
          handle();
        }
      });
    } else {
      handle();
    }
  }

  /**
   * Устанавливает единицы измерения для отображения (FTE, %, Hours).
   *
   * @example
   * setValueMode(ValueMode.FTE)
   */

  public setValueMode(valueMode: ValueMode) {
    const handle = () => {
      this.valueMode = valueMode;
      this.saveSettings();
      this.valueModeSubject.next(valueMode);
    };

    if (this.asyncActionBeforeChange) {
      this.asyncActionBeforeChange().then((value) => {
        if (value) {
          handle();
        }
      });
    } else {
      handle();
    }
  }

  /**
   * Сохраняет настройки навигации в текущем контексте. Контекст (context) определен внутри сервиса.
   */

  private saveSettings() {
    switch (this.context) {
      case ScheduleNavigationContext.ResourceRequirements:
        {
          const config = this.localConfigService.getConfig(
            ResourceRequirementsSettings,
          );
          config.planningScale = this.planningScale;
          config.valueMode = this.valueMode;
          this.localConfigService.setConfig(
            ResourceRequirementsSettings,
            config,
          );
        }
        break;
      case ScheduleNavigationContext.Booking:
        {
          const config = this.localConfigService.getConfig(BookingSettings);
          config.planningScale = this.planningScale;
          config.valueMode = this.valueMode;
          this.localConfigService.setConfig(BookingSettings, config);
        }
        break;
      case ScheduleNavigationContext.Rbc:
        {
          const config = this.localConfigService.getConfig(RbcSettings);
          config.planningScale = this.planningScale;
          this.localConfigService.setConfig(RbcSettings, config);
        }
        break;
      case ScheduleNavigationContext.Expenses:
        {
          const config = this.localConfigService.getConfig(
            ExpensesCalendarSettings,
          );
          config.planningScale = this.planningScale;
          this.localConfigService.setConfig(ExpensesCalendarSettings, config);
        }
        break;
      case ScheduleNavigationContext.ProjectSummary:
        {
          const config = this.localConfigService.getConfig(
            ProjectSummaryViewSettings,
          );
          config.planningScale = this.planningScale;
          config.valueMode = this.valueMode;
          this.localConfigService.setConfig(ProjectSummaryViewSettings, config);
        }
        break;
      case ScheduleNavigationContext.Resources:
        {
          const config = this.localConfigService.getConfig(
            ResourcePlannerSettings,
          );
          config.planningScale = this.planningScale;
          config.valueMode = this.valueMode;
          this.localConfigService.setConfig(ResourcePlannerSettings, config);
        }
        break;

      case ScheduleNavigationContext.ResourceSummary:
        {
          const config = this.localConfigService.getConfig(
            ResourceSummaryViewSettings,
          );
          config.planningScale = this.planningScale;
          config.valueMode = this.valueMode;
          this.localConfigService.setConfig(
            ResourceSummaryViewSettings,
            config,
          );
        }
        break;

      case ScheduleNavigationContext.ResourcesCalendar:
        {
          const config = this.localConfigService.getConfig(
            ResourceForecastCalendarPlannerSettings,
          );
          config.planningScale = this.planningScale;
          config.valueMode = this.valueMode;
          this.localConfigService.setConfig(
            ResourceForecastCalendarPlannerSettings,
            config,
          );
        }
        break;
      case ScheduleNavigationContext.ProjectTasksTimeline: {
        const config = this.localConfigService.getConfig(TimelineSettings);
        config.planningScale = this.planningScale;
        this.localConfigService.setConfig(TimelineSettings, config);
      }
    }
  }

  /**
   * Возвращает список слотов и список групп слотов.
   *
   * @param dates - интервал, из которого будут составляться списки.
   * @param planningScale - масштаб планирования.
   */

  public getSlots(
    dates: Interval,
    planningScale: PlanningScale,
  ): { slots: Slot[]; groups: SlotGroup[] } {
    const interval = this.getNormalizedInterval(dates, planningScale);

    const slots: Slot[] = [];
    const groups: SlotGroup[] = [];

    let date: DateTime;
    if (planningScale === PlanningScale.Day) {
      date = interval.start;
      while (interval.end.startOf('day') >= date.startOf('day')) {
        slots.push({
          id: date.toSeconds(),
          hint: date.toLocaleString(DateTime.DATE_HUGE),
          date,
          header: date.toFormat('EEE'),
          today: date.startOf('day').equals(DateTime.now().startOf('day')),
        });

        const groupId = date.startOf('week').toISODate();
        const slotGroup = groups.find((g) => g.id === groupId);
        if (!slotGroup) {
          groups.push({
            id: groupId,
            hint: '',
            header: date.toLocaleString(DateTime.DATE_FULL),
            slotsCount: 1,
          });
        } else {
          slotGroup.slotsCount++;
        }

        date = date.plus({ days: 1 });
      }
    }

    if (planningScale === PlanningScale.Week) {
      date = interval.start;

      while (interval.end.startOf('day') >= date.startOf('day')) {
        slots.push({
          id: date.toSeconds(),
          hint: date.toLocaleString(DateTime.DATE_HUGE),
          date,
          header: date.toFormat('W'),
          today:
            DateTime.now().startOf('day') >= date &&
            DateTime.now().startOf('day') <= date.endOf('week'),
        });

        const groupId = date.startOf('month').toISODate();
        const slotGroup = groups.find((g) => g.id === groupId);
        if (!slotGroup) {
          groups.push({
            id: groupId,
            hint: '',
            header: date.toLocaleString(DateTime.DATE_FULL),
            slotsCount: 1,
          });
        } else {
          slotGroup.slotsCount++;
        }

        date = date.plus({ days: 7 });
      }
    }

    if (planningScale === PlanningScale.Month) {
      date = interval.start;

      while (interval.end.startOf('day') >= date.startOf('day')) {
        slots.push({
          id: date.toSeconds(),
          hint: date.toLocaleString(DateTime.DATE_HUGE),
          date,
          header: date.toFormat('LLL'),
          today:
            DateTime.now().startOf('day') >= date &&
            DateTime.now().startOf('day') <= date.endOf('month'),
        });

        const groupId = date.startOf('year').toISODate();
        const slotGroup = groups.find((g) => g.id === groupId);
        if (!slotGroup) {
          groups.push({
            id: groupId,
            hint: '',
            header: date.toFormat('yyyy'),
            slotsCount: 1,
          });
        } else {
          slotGroup.slotsCount++;
        }

        date = date.plus({ months: 1 });
      }
    }

    if (planningScale === PlanningScale.Quarter) {
      date = interval.start;
      while (interval.end.startOf('day') >= date.startOf('day')) {
        slots.push({
          id: date.toSeconds(),
          hint: date.toLocaleString(DateTime.DATE_HUGE),
          date,
          header: date.toFormat('q'),
          today:
            DateTime.now().startOf('day') >= date &&
            DateTime.now().startOf('day') <= date.endOf('quarter'),
        });

        const groupId = date.startOf('year').toISODate();
        const slotGroup = groups.find((g) => g.id === groupId);
        if (!slotGroup) {
          groups.push({
            id: groupId,
            hint: '',
            header: date.toFormat('yyyy'),
            slotsCount: 1,
          });
        } else {
          slotGroup.slotsCount++;
        }

        date = date.plus({ quarters: 1 });
      }
    }

    if (planningScale === PlanningScale.Year) {
      date = interval.start;
      while (interval.end.startOf('day') >= date.startOf('day')) {
        slots.push({
          id: date.toSeconds(),
          hint: date.toLocaleString(DateTime.DATE_HUGE),
          date,
          header: date.toFormat('y'),
          today:
            DateTime.now().startOf('day') >= date &&
            DateTime.now().startOf('day') <= date.endOf('year'),
        });

        const decadeStart = date.year - (date.year % 10);
        const decadeEnd = decadeStart + 10;
        const groupId = DateTime.fromObject({ year: decadeStart }).toISODate();
        const slotGroup = groups.find((g) => g.id === groupId);
        if (!slotGroup) {
          groups.push({
            id: groupId,
            hint: '',
            header: `${decadeStart} - ${decadeEnd}`,
            slotsCount: 1,
          });
        } else {
          slotGroup.slotsCount++;
        }

        date = date.plus({ years: 1 });
      }
    }

    return { slots, groups };
  }

  /**
   * Возвращает нормализованный временной интервал (начинается с пн. заканчивается вс.).
   *
   * @param planningScale - масштаб планирования.
   * @param interval - временной интервал, который необходимо нормализовать.
   */

  public getNormalizedInterval(
    interval: Interval,
    planningScale: PlanningScale,
  ) {
    let start = interval.start;
    let end = interval.end;

    if (planningScale === PlanningScale.Week) {
      start = interval.start.startOf('week');
      end = interval.end.endOf('week');
    }

    if (planningScale === PlanningScale.Month) {
      start = interval.start.startOf('month');
      end = interval.end.endOf('month');
    }

    if (planningScale === PlanningScale.Quarter) {
      start = interval.start.startOf('quarter');
      end = interval.end.endOf('quarter');
    }

    if (planningScale === PlanningScale.Year) {
      start = interval.start.startOf('year');
      end = interval.end.endOf('year');
    }

    return Interval.fromDateTimes(start, end);
  }

  /**
   * Возвращает временной интервал.
   *
   * @param planningScale - масштаб планирования.
   * @param date  - дата отсчёта. По умолчанию: DateTime.now().
   *  */

  public getInterval(planningScale: PlanningScale, date?: DateTime): Interval {
    const now = date ?? DateTime.now().startOf('day');

    let startPeriod: DateTime = now;
    let endPeriod: DateTime = now;

    if (planningScale === PlanningScale.Day) {
      startPeriod = now.minus({ days: 7 }).startOf('week');
      endPeriod = now.plus({ days: 45 }).endOf('week');
    }

    if (planningScale === PlanningScale.Week) {
      startPeriod = now.startOf('week').minus({ weeks: 4 });
      endPeriod = now.endOf('week').plus({ weeks: 25 });
    }

    if (planningScale === PlanningScale.Month) {
      startPeriod = now.startOf('month').minus({ month: 2 });
      endPeriod = now.endOf('month').plus({ month: 25 });
    }

    if (planningScale === PlanningScale.Quarter) {
      startPeriod = now.startOf('quarter').minus({ quarter: 4 });
      endPeriod = now.endOf('quarter').plus({ quarter: 8 });
    }

    if (planningScale === PlanningScale.Year) {
      startPeriod = now.startOf('year').minus({ year: 2 });
      endPeriod = now.endOf('year').plus({ year: 18 });
    }

    return Interval.fromDateTimes(startPeriod, endPeriod);
  }

  /**
   * Возвращает наименование масштаба планирования.
   *
   * @param planningScale - масштаб планирования.
   */

  public getPlanningScaleName(planningScale: PlanningScale): string {
    return this.translate.instant(`enums.planningScale.${planningScale}`);
  }
}
