import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { ProgramOverviewComponent } from 'src/app/programs/card/program-overview/program-overview.component';
import { ProgramOverviewKpiModule } from 'src/app/programs/card/program-overview/program-overview-kpi/program-overview-kpi.module';
import { ProgramOverviewAnalysisModule } from 'src/app/programs/card/program-overview/program-overview-analysis/program-overview-analysis.module';

@NgModule({
  declarations: [ProgramOverviewComponent],
  imports: [
    CommonModule,
    SharedModule,
    ProgramOverviewKpiModule,
    ProgramOverviewAnalysisModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'program.overview',
          url: '/overview',
          component: ProgramOverviewComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
          redirectTo: 'program.overview.kpi',
          resolve: [
            {
              token: 'entityType',
              resolveFn: () => 'program',
            },
          ],
        },
      ],
    }),
    BaseSharedModule,
    CommentsModule,
  ],
})
export class ProgramOverviewModule {}
