import { Injectable } from '@angular/core';
import { PivotDataService } from './pivot-data.service';
import { ViewSettings } from '../models/view-settings/view-settings.model';
import { UserSettings, SortOrder } from '../models/user-settings.model';
import { PivotRenderService } from './pivot-render.service';
import { PivotTable } from './model/pivot-table.model';
import { Subject, BehaviorSubject } from 'rxjs';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';

@Injectable()
export class PivotTableService {
  public loading$ = new BehaviorSubject<boolean>(false);
  public isError$ = new BehaviorSubject<boolean>(false);
  public sorting$ = new Subject<Dictionary<SortOrder>>();
  public resizing$ = new Subject<[string, number]>();

  pivotTable: PivotTable;
  userSettings: UserSettings;
  viewSettings: ViewSettings;

  constructor(
    private dataService: PivotDataService,
    private renderService: PivotRenderService,
  ) {
    this.renderService.sort$.subscribe((columnName) => this.sort(columnName));
  }

  public initForReport(
    reportId: string,
    viewSettings: ViewSettings,
    userSettings: UserSettings,
  ) {
    this.dataService.initForReport(reportId, viewSettings, userSettings);
    this.renderService.init(viewSettings, userSettings);

    this.userSettings = userSettings;
    this.viewSettings = viewSettings;
  }

  public initForWidget(
    widgetId: string,
    dashboardId: string,
    viewSettings: ViewSettings,
    userSettings: UserSettings,
  ) {
    this.dataService.initForWidget(
      widgetId,
      dashboardId,
      viewSettings,
      userSettings,
    );
    this.renderService.init(viewSettings, userSettings);

    this.userSettings = userSettings;
    this.viewSettings = viewSettings;
  }

  public run(force = false, period: DatePeriod = null) {
    this.loading$.next(true);
    this.isError$.next(false);

    this.dataService.loadData(force, period).then(
      (pivotTable) => {
        this.loading$.next(false);
        this.pivotTable = pivotTable;
        this.renderService.redraw();
      },
      () => {
        this.isError$.next(true);
        this.loading$.next(false);
      },
    );
  }

  public sort(fieldName: string, sortOrder?: SortOrder) {
    const sorting = this.userSettings.sorting;

    let newSortOrder: SortOrder = SortOrder.Asc;
    if (sortOrder) {
      newSortOrder = sortOrder;
    } else {
      if (sorting && sorting[fieldName] !== undefined) {
        newSortOrder =
          sorting[fieldName] === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
      }
    }

    this.userSettings.sorting = {};
    this.userSettings.sorting[fieldName] = newSortOrder;
    this.sorting$.next(this.userSettings.sorting);
    this.renderService.userSettings = this.userSettings;
    this.renderService.redraw(true);
  }
}
