<tmt-form-header [name]="form.value.name"></tmt-form-header>
<wp-loading-indicator [state]="state"></wp-loading-indicator>
<tmt-not-found [state]="state"></tmt-not-found>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" *ngIf="state === 'Ready'" class="form mt-3" style="width: 600px">
  <div class="form-group">
    <label>{{ 'settings.validationRules.card.props.ruleType' | translate }}</label>
    <p class="pt-1">{{ form.value.validationRuleType?.name }}</p>
  </div>

  <div class="form-group">
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box
      formControlName="name"
      placeholder="{{ 'shared.props.name' | translate }}"
    ></wp-text-box>
  </div>

  <div class="form-group">
    <label>{{ 'components.validationRuleCardComponent.props.customMessage' | translate }}</label>
    <wp-multiline-text-box
      formControlName="customMessage"
      placeholder="{{ 'components.validationRuleCardComponent.props.customMessage' | translate }}"
    >
    </wp-multiline-text-box>
  </div>

  <div class="form-group">
    <label>{{ 'shared.props.description' | translate }}</label>
    <wp-multiline-text-box
      formControlName="description"
      placeholder="{{ 'shared.props.description' | translate }}"
    >
    </wp-multiline-text-box>
  </div>

  <div class="form-group">
    <label>{{ 'settings.validationRules.card.props.level' | translate }}</label>
    <br />
    <div
      class="btn-group btn-group-toggle"
      radioGroup
      name="radioBasic"
      formControlName="validationRuleLevel"
    >
      <label radioLabel class="btn-default trim fw-normal">
        <input radioButton type="radio" value="Error" />{{
          'enums.validationRuleLevel.Error' | translate
        }}
      </label>
      <label radioLabel class="btn-default trim fw-normal">
        <input radioButton type="radio" value="Warning" />{{
          'enums.validationRuleLevel.Warning' | translate
        }}
      </label>
    </div>
  </div>

  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>

  <hr />

  <ng-container [ngSwitch]="form.value.validationRuleType?.code">
    <wp-rule-work-day-duration
      *ngSwitchCase="'WorkDayDuration'"
      [data]="dataJson"
      (changes)="onChanges($event)"
      [readonly]="readonly"
    >
    </wp-rule-work-day-duration>

    <wp-rule-schedule-work-day-percentage
      *ngSwitchCase="'ScheduleWorkDayPercentage'"
      [data]="dataJson"
      (changes)="onChanges($event)"
      [readonly]="readonly"
    ></wp-rule-schedule-work-day-percentage>

    <wp-rule-schedule-work-day-duration
      *ngSwitchCase="'ScheduleWorkDayDuration'"
      [data]="dataJson"
      (changes)="onChanges($event)"
      [readonly]="readonly"
    >
    </wp-rule-schedule-work-day-duration>

    <wp-rule-lines-count
      *ngSwitchCase="'LinesCount'"
      [data]="dataJson"
      (changes)="onChanges($event)"
      [readonly]="readonly"
    >
    </wp-rule-lines-count>

    <wp-rule-submit-date
      *ngSwitchCase="'SubmitDate'"
      [data]="dataJson"
      (changes)="onChanges($event)"
      [readonly]="readonly"
    >
    </wp-rule-submit-date>

    <wp-rule-required-fields
      *ngSwitchCase="'RequiredFields'"
      [data]="dataJson"
      (changes)="onChanges($event)"
      [readonly]="readonly"
    >
    </wp-rule-required-fields>

    <wp-rule-project-planned-hours
      *ngSwitchCase="'ProjectPlannedHours'"
      [data]="dataJson"
      (changes)="onChanges($event)"
      [readonly]="readonly"
    ></wp-rule-project-planned-hours>

    <wp-rule-empty-lines
      *ngSwitchCase="'EmptyLines'"
      [data]="dataJson"
      (changes)="onChanges($event)"
      [readonly]="readonly"
    >
    </wp-rule-empty-lines>
  </ng-container>

  <div class="actions" *ngIf="!readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
