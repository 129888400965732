import { Component } from '@angular/core';
import { FinancialAccountType } from 'src/app/shared/models/entities/finance/financial-account-type.enum';
import { AccountingEntryService } from '../../core/accounting-entry.service';
import { AccountingEntryMode } from '../../../../shared/models/entities/finance/accounting-entry-mode.enum';
import { NavigationService } from 'src/app/core/navigation.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

@Component({
  selector: 'wp-accounting-entry-body',
  templateUrl: './accounting-entry-body.component.html',
  styleUrls: ['./accounting-entry-body.component.scss'],
})
export class AccountingEntryBodyComponent {
  public routeMode = RouteMode;

  constructor(
    public service: AccountingEntryService,
    public navigationService: NavigationService,
  ) {}

  protected readonly accountingEntryMode = AccountingEntryMode;
  protected readonly financialAccountType = FinancialAccountType;
}
