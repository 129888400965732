import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';

/** Uses for check circular dependency in the project tasks and task dependency chain search. */
export class TimelineGraph {
  /**
   * Adjacency list.
   * Represents adjacent nodes (dependants) of a task by key (id).
   *
   * @private
   */
  private readonly adjacency = new Map();

  constructor(tasks: ProjectTask[]) {
    for (const task of tasks) {
      this.adjacency[task.id] = tasks
        .filter((t) => t.dependencies.find((d) => d.predecessorId === task.id))
        .map((t) => t.id);
    }
  }

  /**
   * Determines whether there is a path from one node to another.
   *
   * @param from - node from which we start to find path.
   * @param to - node to which we try to find path.
   *
   * @see https://www.geeksforgeeks.org/find-if-there-is-a-path-between-two-vertices-in-a-given-graph/
   */
  public checkIfPathExists(from: string, to: string): boolean {
    if (from === to) {
      return true;
    }
    const visited = { [from]: true };
    const queue = [from];

    while (queue.length) {
      const curr = queue.shift();
      for (const adjacent of this.adjacency[curr]) {
        if (adjacent === to) {
          return true;
        }
        if (!visited[adjacent]) {
          visited[adjacent] = true;
          queue.push(adjacent);
        }
      }
    }
    return false;
  }
}
