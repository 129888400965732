import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import {
  GridNavigationColumn,
  GridColumnType,
} from '../models/inner/grid-column.interface';

export const DashboardList: List = {
  version: 1,
  name: 'dashboards',
  views: [
    {
      name: 'all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 3,
        },

        {
          column: 'isPublished',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'owner',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'lastUpdated',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'showInAnalytics',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      state: 'dashboard',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      availableTotals: [TotalType.Count],
    },
    {
      name: 'description',
      header: 'shared.columns.description',
      hint: 'shared.columns.description',
      type: GridColumnType.String,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'isActive',
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.Boolean,
    },
    {
      name: 'isPublished',
      type: GridColumnType.Boolean,
      header: 'analytics.dashboards.list.columns.isPublished',
      hint: 'analytics.dashboards.list.columns.isPublished',
    },
    {
      name: 'showInAnalytics',
      type: GridColumnType.Boolean,
      header: 'analytics.dashboards.list.columns.showInAnalytics',
      hint: 'analytics.dashboards.list.columns.showInAnalytics',
    },
    {
      name: 'owner',
      header: 'analytics.dashboards.list.columns.owner',
      hint: 'analytics.dashboards.list.columns.owner',
      type: GridColumnType.Entity,
    },
    {
      name: 'lastUpdated',
      header: 'analytics.dashboards.list.columns.lastUpdated',
      hint: 'analytics.dashboards.list.columns.lastUpdated',
      type: GridColumnType.Date,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isPublished',
      field: 'isPublished',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'showInAnalytics',
      field: 'showInAnalytics',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'owner',
      field: ['owner.name'],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'lastUpdated',
      field: 'lastUpdated',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
  dataCollection: 'Dashboards',
};
