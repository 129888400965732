<tmt-form-header [name]="levelCardService.levelName$ | async" />
<tmt-not-found [state]="levelCardService.state$ | async" />
<wp-loading-indicator [state]="levelCardService.state$ | async" />
<wp-data-not-saved-indicator [form]="levelForm" />

<form [formGroup]="levelForm" [hidden]="(levelCardService.state$| async) !== 'Ready'" class="form mt-3" style="width: 600px">
  @if (levelForm.value.isDefault) {
    <p>
      <i class="bi bi-check-circle" aria-hidden="true"></i> &nbsp;<strong>{{
        'settings.levels.card.usingByDefault' | translate
      }}</strong>
    </p>
  }
  <div class="form-group" tmtIndicator>
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box
      formControlName="name"
      placeholder="{{ 'shared.props.name' | translate }}"
    />
  </div>

  <div class="form-group" style="width: 150px">
    <label>{{ 'shared.props.code' | translate }}</label>
    <wp-text-box
      formControlName="code"
      placeholder="{{ 'shared.props.code' | translate }}"
    />
  </div>

  <div class="form-group">
    <label>{{ 'shared.props.description' | translate }}</label>
    <wp-multiline-text-box
      formControlName="description"
      placeholder="{{ 'shared.props.description' | translate }}"
    />
  </div>
  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>
  @if (!readonly) {
    <div class="actions">
      <button
        type="button"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="isSaving$ | async"
        (click)="save()"
      >
        {{ 'shared.actions.save' | translate }}
      </button>
    </div>
  }
</form>
