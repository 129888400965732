import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectCardService } from '../core/project-card.service';
import { PnlService } from 'src/app/shared-features/pnl/pnl.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';

@Component({
  selector: 'wp-project-pnl',
  templateUrl: './project-pnl.component.html',
  providers: [PnlService],
})
export class ProjectPnlComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  private reloadListener: Subscription;
  private projectSubscription: Subscription;

  public get projectVersion(): ProjectVersion {
    return !this.versionCardService.isWorkProjectVersion()
      ? this.versionCardService.projectVersion
      : null;
  }

  constructor(
    private pnlService: PnlService,
    private service: ProjectCardService,
    private versionCardService: ProjectVersionCardService,
  ) {}

  ngOnInit(): void {
    this.projectSubscription = this.service.project$.subscribe(
      (project: Project) => {
        //TODO: check why needs empty subscriber.
      },
    );

    this.reloadListener = this.service.reloadTab$.subscribe(() => {
      this.pnlService.reload();
    });
  }

  ngOnDestroy(): void {
    this.reloadListener.unsubscribe();
    this.projectSubscription.unsubscribe();
  }
}
