<div class="toolbar-container">
  <div class="group">
    <div class="btn-group" *ngIf="!(service.readonly$ | async)">
      <button
        type="button"
        class="btn btn-default"
        (click)="service.execute('addResources')"
        title="{{ 'projects.projects.card.team.actions.addResources.hint' | translate }}"
        data-test="addResources"
      >
        <span class="bi bi-plus-lg bi-15"></span>&nbsp;{{
          'projects.projects.card.team.actions.addResources.label' | translate
        }}
      </button>
    </div>

    <button
      *ngIf="!(service.readonly$ | async) && app.checkFeature(feature.genericResources)"
      type="button"
      class="btn btn-default"
      title="  {{ 'projects.projects.card.team.actions.createGeneric.hint' | translate }}"
      (click)="service.execute('createGeneric')"
      data-test="createGeneric"
    >
      {{ 'projects.projects.card.team.actions.createGeneric.label' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.edit' | translate }}"
      [disabled]="!service.canBeExecuted('edit', service.selectedRow?.id)"
      (click)="service.execute('edit', service.selectedGroup)"
      data-test="edit"
    >
      {{ 'shared.actions.edit' | translate }}
    </button>

    <button
      *ngIf="!(service.readonly$ | async)"
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.delete' | translate }}"
      [disabled]="!service.canBeExecuted('delete', service.selectedRow?.id)"
      (click)="service.execute('delete', service.selectedRow.id)"
      data-test="delete"
    >
      <i class="bi bi-trash3" aria-hidden="true"></i>
    </button>
  </div>

  <button
    type="button"
    *ngIf="app.checkFeature(feature.genericResources)"
    class="btn btn-default"
    title="{{ 'projects.projects.card.team.actions.assignUser' | translate }}"
    [disabled]="!service.canBeExecuted('assignUser', service.selectedRow)"
    (click)="service.execute('assignUser', service.selectedGroup)"
    data-test="assignUser"
  >
    <span class="bi bi-person-plus"></span>
    &nbsp;{{ 'projects.projects.card.team.actions.assignUser' | translate }}
  </button>

  <div class="group ms-auto">
    <div ngbDropdown class="d-inline-block">
      <button
        type="button"
        class="btn btn-default without-caret"
        ngbDropdownToggle
        title="{{ 'shared2.actions.options' | translate }}"
        data-test="options"
      >
        <i class="bi bi-sliders2 bi-15" aria-hidden="true"></i>
      </button>
      <div ngbDropdownMenu class="p-3">
        <div class="form-check text-nowrap">
          <input
            [formControl]="filterControl"
            type="checkbox"
            class="form-check-input"
            id="onlyWithData"
          />
          <label class="form-check-label" for="onlyWithData">
            {{ 'projects.projects.card.team.actions.hideDisabled' | translate }}
          </label>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-default"
      (click)="service.execute('setUserView')"
      title="{{ 'shared.actions.setView' | translate }}"
      data-test="viewSettings"
    >
      <i class="bi bi-gear" aria-hidden="true"></i>
    </button>
  </div>
</div>
