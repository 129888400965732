import { SettingsAppComponent } from './settings-app.component';
import { environment } from 'src/environments/environment';

const appStates: any[] = [
  {
    name: 'settings',
    url: '/settings',
    component: SettingsAppComponent,
    redirectTo: environment.onprem ? 'settings.users' : 'settings.account',
  },
  {
    name: 'settings.**',
    loadChildren: () =>
      import('../shared-features/mermaid-schema/mermaid-schema.module').then(
        (mod) => {
          /** Trigger change detection for rendering components */
          const body = document.querySelector('body');
          setTimeout(() => {
            body.click();
          }, 0);
          return mod.MermaidSchemaModule;
        },
      ),
  },
  {
    name: 'settings.users.**',
    loadChildren: () =>
      import('./users/users.module').then((mod) => mod.UsersModule),
  },
];

export const SETTINGS_STATES = appStates;
