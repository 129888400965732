import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import _ from 'lodash';
import { Observable, Subject, debounceTime } from 'rxjs';
import { State } from '@popperjs/core';

import { ChromeService } from 'src/app/core/chrome.service';
import { Guid } from 'src/app/shared/helpers/guid';

import { InfoPopupEntry, InfoPopupEvent } from './info-popup.interface';

@Injectable({
  providedIn: 'root',
})
export class InfoPopupService {
  public popups: InfoPopupEntry[] = [];
  public readonly defaultPopperModifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 11],
      },
    },
    {
      name: 'preventOverflow',
      options: {
        padding: 25,
      },
    },
  ];
  public readonly controlPopperModifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 2],
      },
    },
    {
      name: 'sameWidth',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn: ({ state }) => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
      },
      effect: ({ state }) => {
        state.elements.popper.style.width = `${
          state.elements.reference.getBoundingClientRect().width
        }px`;
      },
    },
  ];

  private eventSubject = new Subject<InfoPopupEvent>();
  private intersectionObserver: IntersectionObserver;
  private readonly closeHandler: (e: KeyboardEvent) => void = (e) =>
    this.handlerEscape(e);

  public get event$(): Observable<InfoPopupEvent> {
    return this.eventSubject.asObservable();
  }

  constructor(
    private chromeService: ChromeService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.initIntersectionObserver();
    this.initResizeSubscribe();
  }

  /**
   * Gets popup entry by id.
   *
   * @param id popup id.
   * @returns popup entry or `undefined` if entry is not found.
   */
  public getById(id: string): InfoPopupEntry | undefined {
    return this.popups.find((p) => p.id === id);
  }

  /**
   * Opens popup.
   *
   * @param entry popup config.
   *
   * @returns popup id.
   */
  public open<T>(entry: InfoPopupEntry<T>): string {
    if (!this.popups.length) {
      this.document.addEventListener('keydown', this.closeHandler);
    }

    entry.id ??= Guid.generate();

    this.popups.push(entry);
    this.eventSubject.next({ name: 'create', popup: entry });

    return entry.id;
  }

  /**
   * Closes the popup or all popups if `entry` is `null`.
   *
   * @param entry popup entry, popup id or `null`.
   */
  public close(target: InfoPopupEntry | string | null = null): void {
    const entry = typeof target === 'string' ? this.getById(target) : target;

    if (entry) {
      entry.popperInstance.destroy();
      _.remove(
        this.popups,
        entry.id
          ? (popup) => popup.id === entry.id
          : (popup) => popup.target.isEqualNode(entry.target), // TODO not correct
      );
    } else {
      this.popups.forEach((popup) => {
        popup.popperInstance.destroy();
      });
      this.popups.length = 0;
    }

    this.eventSubject.next({ name: 'destroy', popup: entry });

    if (!this.popups.length) {
      this.document.removeEventListener('keydown', this.closeHandler);
    }
  }

  /**
   * Emits popup's `forceUpdate` method or all popups if `entry` is `null`.
   *
   * @param entry popup entry, popup id or `null`.
   */
  public forceUpdate(target: InfoPopupEntry | string | null = null): void {
    const entry = typeof target === 'string' ? this.getById(target) : target;

    if (entry) {
      entry.popperInstance.forceUpdate();
    } else {
      this.popups.forEach((popup) => {
        popup.popperInstance.forceUpdate();
      });
    }

    this.eventSubject.next({ name: 'force-update', popup: entry });
  }

  /**
   * Emits popup's `update` method or all popups if `entry` is `null`.
   *
   * @param entry popup entry, popup id or `null`.
   */
  public update(target: InfoPopupEntry | string | null = null): void {
    const entry = typeof target === 'string' ? this.getById(target) : target;
    // TODO fix please.
    setTimeout(() => {
      const updating: Promise<Partial<State>>[] = [];

      if (entry) {
        updating.push(entry.popperInstance?.update());
      } else {
        this.popups.forEach((item) => {
          updating.push(item.popperInstance?.update());
        });
      }

      Promise.all(updating).then(() => {
        this.eventSubject.next({ name: 'update', popup: entry });
      });
    });
  }

  /**
   * Subscribes to the intersection event for the popup target.
   *
   * @param target HTML Element.
   */
  public observeIntersection(target: Element): void {
    this.intersectionObserver.observe(target);
  }

  private handlerEscape(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.close(this.popups.pop());
    }
  }

  private initResizeSubscribe(): void {
    this.chromeService.mainAreaSize$.pipe(debounceTime(100)).subscribe(() => {
      this.update();
    });
  }

  private initIntersectionObserver(): void {
    const intersectionOptions: IntersectionObserverInit = {
      threshold: 0.5,
    };

    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((intersectionEntry) => {
        if (!intersectionEntry.isIntersecting) {
          this.intersectionObserver.unobserve(intersectionEntry.target);

          const item = this.popups.find((item) => {
            if (_.isElement(item.target)) {
              return item.target.isEqualNode(intersectionEntry.target);
            }

            if (_.isElement(item.target.contextElement)) {
              return item.target.contextElement.isEqualNode(
                intersectionEntry.target,
              );
            }

            return false;
          });

          if (item) {
            this.close(item);
          }
        }
      });
    }, intersectionOptions);
  }
}
