<form class="form" [formGroup]="service.products" novalidate>
  <wp-data-not-saved-indicator [form]="service.products"></wp-data-not-saved-indicator>

  <p class="text-body-secondary">{{ 'settings.users.card.licenses.help' | translate }}</p>

  <wp-grid
    [formArray]="service.products"
    [options]="gridOptions"
    [readonly]="readonly"
    class="d-block"
  ></wp-grid>

  <div class="actions">
    <button
      *ngIf="!service.readonly && !(service.loading$ | async)"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="service.saving$ | async"
      (click)="service.save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
