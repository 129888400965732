<tmt-task-box
  *ngIf="!formGroup.value.isTaskGroup"
  [allowNull]="false"
  [projectId]="entityId"
  [projectVersion]="projectVersion"
  [formControl]="control"
/>

<div class="task" *ngIf="formGroup.value.isTaskGroup" [ngStyle]="getIndent()">
  <span
    class="bi"
    [ngClass]="{
      'bi-chevron-down': formGroup.value.isExpanded === true,
      'bi-chevron-right': formGroup.value.isExpanded === false
    }"
    (click)="toggle()"
  ></span>
  <div class="trim" [title]="getTaskFullName()">
    {{ getTaskFullName() }}
  </div>
</div>
