import { Injectable } from '@angular/core';

/** Helper for router links. */
@Injectable({
  providedIn: 'root',
})
export class StateBuilderService {
  public readonly entityTypeToStateName: Map<string, string> = new Map([
    ['ActOfAcceptance', 'actOfAcceptance'],
    ['ProjectVersion', 'projectVersion'],
    ['ExpenseRequest', 'expensesRequest'],
    ['ResourceRequest', 'resourceRequest'],
    ['TimeOffRequest', 'timeOffRequest'],
    ['TimeSheet', 'timesheet'],
    ['Certificate', 'certificate'],
    ['Project', 'project'],
    ['Invoice', 'invoice'],
    ['RateMatrix', 'rateMatrix'],
    ['Issue', 'issue'],
  ]);

  /** Gets state for workflow task's attachment (entity). */
  public getStateForWorkflowEntity(
    taskId: string,
    entityType: string,
    entityId,
  ): WorkflowEntityState {
    return {
      state: this.entityTypeToStateName.get(entityType),
      params: {
        taskId,
        entityId,
      },
    };
  }
}

export interface WorkflowEntityState {
  state: string;
  params: any;
}
