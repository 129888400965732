<div
  *ngIf="bookingModeDetailed !== undefined"
  wpFreezeTable
  [afterViewInitInitialization]="true"
  [name]="frName"
  [syncWith]="frSyncWith"
  [disableHorizontalScroller]="frDisableHorizontalScroller"
  [rootContainerId]="frRootContainerId"
  leftWidthStrategy="byTable"
  rightWidthStrategy="byTable"
  class="disable-selecting"
>
  <wp-booking-toolbar name="toolbar" *ngIf="hasToolbar"></wp-booking-toolbar>

  <tmt-booking-detailed
    *ngIf="bookingModeDetailed"
    [hasHeader]="hasHeader"
    [leftTableWidth]="leftTableWidth"
  ></tmt-booking-detailed>

  <tmt-booking-basic
    *ngIf="!bookingModeDetailed"
    [hasHeader]="hasHeader"
    [leftTableWidth]="leftTableWidth"
  ></tmt-booking-basic>
</div>

<wp-loading-indicator [loading]="service.loading$ | async"></wp-loading-indicator>
