export interface TreeItem {
  /** Id of any entity. */
  id: string;
  /** Name of any entity. */
  name: string;
}

export interface TreeItemNested extends TreeItem {
  /** List with any entities. */
  list: TreeItemNested[];
  /** Indicates whether item is expanded. */
  isExpanded?: boolean;
  /** Level of nesting relative to first parent. */
  level?: number;
}

export interface TreeItemPlane extends TreeItem {
  /** Any key of parent id. */
  [parentIdKey: string]: string | number;
}
