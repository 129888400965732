import { Injectable } from '@angular/core';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { BehaviorSubject } from 'rxjs';
import { filter, skip } from 'rxjs/operators';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';

/**
 * Provides methods to work with Project versions on the Project card.
 * */
@Injectable()
export class ProjectVersionCardService {
  /** The "Work" default project version to show in Project version list and Project selection to view the card. */
  private _workProjectVersion: ProjectVersion = {
    id: null,
    name: '',
    state: null,
    mainProject: null,
    masterBaseline: false,
    editAllowed: false,
  };

  private _projectVersion: ProjectVersion;
  private _projectVersions: Array<ProjectVersion>;

  /** The Project version card open event subject. */
  private openProjectVersionCard = new BehaviorSubject<ProjectVersion>(
    this._workProjectVersion,
  );

  get workProjectVersion() {
    return this._workProjectVersion;
  }

  get projectVersion() {
    return this._projectVersion;
  }

  get projectVersions() {
    return this._projectVersions;
  }

  set projectVersions(value: Array<ProjectVersion>) {
    this._projectVersions = value;
  }

  /** The Project version card open event observable. */
  public openProjectVersionCard$ = this.openProjectVersionCard
    .asObservable()
    .pipe(
      skip(1),
      filter((p) => !!p),
    );

  /**
   * Updates the "Work" Project version shown in the Project version list on the Project card.
   *
   * @param project The Project. If not passed, the properties will be reset.
   * */
  public updateWorkProjectVersion(project?: Project) {
    if (project) {
      this._workProjectVersion.id = Guid.generate();
      this._workProjectVersion.name = project.name;
      this._workProjectVersion.mainProject = {
        id: project.id,
        name: project.name,
      };
      // The Project card tab edit rights must not depend on the Project entity edit ones.
      this._workProjectVersion.editAllowed = true /*project.editAllowed*/;
    } else {
      this._workProjectVersion.id = null;
      this._workProjectVersion.name = '';
      this._workProjectVersion.mainProject = null;
      this._workProjectVersion.editAllowed = false;
    }
  }

  /**
   * Determines, whether the Project or Project version is selected in the Project version list to show the Project card content.
   * The "Work" Project version has no Project version data.
   * The "Work" Project version is selected by default.
   * */
  public isWorkProjectVersion() {
    return ProjectVersionUtil.isWorkProjectVersion(this._projectVersion);
  }

  /**
   * Initializes the selected Project version.
   * The "Work" Project version is selected by default.
   * */
  public initProjectVersion() {
    this._projectVersion = this._workProjectVersion;
  }

  /**
   * Calls the active Project card tab reload with the Project/Project version data.
   *
   * @param projectVersion The Project version.
   * */
  public openVersionCard(projectVersion: ProjectVersion) {
    this._projectVersion = projectVersion;
    this.openProjectVersionCard.next(projectVersion);
  }

  /**
   * Changes the Project version.
   *
   * @param projectVersion The Project version.
   * */
  public changeVersion(projectVersion: ProjectVersion) {
    this._projectVersion = projectVersion;
  }

  /**
   * Sorts the Project version list depending on the numeric values in their names.
   *
   * @param projectVersions The Project version list.
   * @param refreshVersions Determines whether to refresh the service Project version list.
   * Default: false.
   * @returns The sorted Project version list.
   * */
  public sortVersions(
    projectVersions: Array<ProjectVersion>,
    refreshVersions = false,
  ) {
    const versionsCopy = projectVersions.slice();
    const sorted = versionsCopy.sort(naturalSort('name'));
    if (refreshVersions) {
      this._projectVersions = sorted;
    }
    return sorted;
  }
}
