<ng-container [formGroup]="form">
  <div class="form-group">
    <label class="d-block">{{ 'analytics.abbreviations.abbreviation' | translate }}</label>

    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        id="none"
        value="none"
        formControlName="abbreviation"
      />
      <label class="form-check-label" for="none">{{
        'analytics.abbreviations.none' | translate
      }}</label>
    </div>

    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        id="thousands"
        value="thousands"
        formControlName="abbreviation"
      />
      <label class="form-check-label" for="thousands">{{
        'analytics.abbreviations.thousands' | translate
      }}</label>
    </div>
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        id="millions"
        value="millions"
        formControlName="abbreviation"
      />
      <label class="form-check-label" for="millions">{{
        'analytics.abbreviations.millions' | translate
      }}</label>
    </div>
  </div>
</ng-container>
