import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { Location } from 'src/app/shared/models/entities/settings/location.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationService } from 'src/app/core/navigation.service';

@Component({
  selector: 'wp-location-card',
  templateUrl: './location-card.component.html',
})
export class LocationCardComponent implements OnInit {
  @Input() entityId: string;

  public readonly: boolean;
  public state: CardState;
  public isSaving = false;

  public locationForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: ['', [Validators.maxLength(Constants.formCodeMaxLength)]],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: [false],
    isDefault: [false],
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    private navigationService: NavigationService,
  ) {}

  public ngOnInit(): void {
    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());

    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
      {
        title: 'shared.actions.useByDefault',
        hint: 'shared.actions.useByDefault',
        name: 'setAsDefault',
        isDropDown: false,
        isBusy: false,
        isVisible: !this.readonly,
        handler: () => this.setAsDefault(),
      },
    ]);

    this.load();
  }

  public reload(): void {
    if (!this.locationForm.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }

  /** Saves Data. */
  public save = (): void => {
    this.locationForm.markAllAsTouched();

    if (this.locationForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const location = this.locationForm.value;

    const data: any = {
      name: location.name,
      code: location.code,
      description: location.description,
      isActive: location.isActive,
    };

    this.data
      .collection('Locations')
      .entity(this.entityId)
      .patch(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.locationForm.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.locations.card.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  };

  /** Loads Data. */
  private load = (): void => {
    this.state = CardState.Loading;
    this.locationForm.markAsPristine();
    this.locationForm.markAsUntouched();
    this.data
      .collection('Locations')
      .entity(this.entityId)
      .get<Location>(null)
      .subscribe({
        next: (location: Location) => {
          this.locationForm.patchValue(location);

          this.readonly = !location.editAllowed;
          this.actionService.action('save').isShown = !this.readonly;
          this.actionService.action('setAsDefault').isShown =
            !this.readonly && !location.isDefault;
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.readonly
            ? this.locationForm.disable()
            : this.locationForm.enable();
          this.state = CardState.Ready;

          this.navigationService.addRouteSegment({
            id: location.id,
            title: location.name,
          });
        },
        error: (error: Exception) => {
          this.state = CardState.Error;
          this.notification.error(error.message);
        },
      });
  };

  /** Sets As Default. */
  private setAsDefault(): void {
    this.data
      .collection('Locations')
      .entity(this.entityId)
      .action('SetAsDefault')
      .execute()
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.locations.card.setAsDefault',
          );

          this.actionService.action('setAsDefault').isShown = false;
          this.reload();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }
}
