import { Component, OnInit, Input } from '@angular/core';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { DataService } from 'src/app/core/data.service';
import { orderBy } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/core/notification.service';
import { AccountDocumentLinkComponent } from './account-document-link/account-document-link.component';
import {
  GridColumnType,
  GridComponentColumn,
  GridCurrencyColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Exception } from 'src/app/shared/models/exception';
import { AccountDocument } from 'src/app/shared/models/entities/settings/multitenant/account-document.model';
import { AccountDocumentStatus } from 'src/app/shared/models/enums/account-document-status';
import { EAEUCountries } from 'src/app/shared/helpers/globalization.helper';

@Component({
  selector: 'wp-account-documents',
  templateUrl: './account-documents.component.html',
  styleUrls: ['./account-documents.component.scss'],
  providers: [GridService],
})
export class AccountDocumentsComponent implements OnInit {
  @Input() currencyCode: string;
  @Input() countryCode: string;
  gridOptions: GridOptions;

  public formArray = this.fb.array([]);

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private gridService: GridService,
    private data: DataService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.gridOptions = {
      css: 'wp-nested-table',
      sorting: false,
      rowCommands: [
        {
          name: 'cancel',
          handlerFn: (formGroup: UntypedFormGroup, index: number) => {
            this.cancel(formGroup.value.id);
          },

          allowedFn: (formGroup: UntypedFormGroup, index: number) => {
            const doc = formGroup.value as any;
            return doc.status.id === AccountDocumentStatus.Issued;
          },
          label: 'settings.account.documents.actions.cancel',
        },
      ],
      view: {
        name: 'documents',
        columns: [
          {
            name: 'date',
            header: 'settings.account.documents.columns.date',
            hint: 'settings.account.documents.columns.date',
            type: GridColumnType.Date,
            width: '120px',
          },
          {
            name: 'number',
            header: 'settings.account.documents.columns.number',
            hint: 'settings.account.documents.columns.number',
            type: GridColumnType.String,
            width: '120px',
          },
          <GridComponentColumn>{
            name: 'invoice',
            header: 'settings.account.documents.columns.invoice',
            hint: 'settings.account.documents.columns.invoice',
            type: GridColumnType.Component,
            component: AccountDocumentLinkComponent,
            property: 'number',
            width: '150px',
          },
          <GridComponentColumn>{
            name: 'closingDocument',
            header: 'settings.account.documents.columns.closingDocument',
            hint: 'settings.account.documents.columns.closingDocument',
            type: GridColumnType.Component,
            component: AccountDocumentLinkComponent,
            property: 'number',
            width: '150px',
          },
          <GridCurrencyColumn>{
            name: 'amount',
            header: 'settings.account.documents.columns.amount',
            hint: 'settings.account.documents.columns.amount',
            type: GridColumnType.Currency,
            width: '120px',
            currencyCode: this.currencyCode,
          },
          {
            name: 'description',
            header: 'settings.account.documents.columns.description',
            hint: 'settings.account.documents.columns.description',
            type: GridColumnType.String,
            width: '100%',
          },
          {
            name: 'status',
            header: 'settings.account.documents.columns.status',
            hint: 'settings.account.documents.columns.status',
            type: GridColumnType.Entity,
            width: '150px',
          },
        ],
      },
    };

    if (EAEUCountries.indexOf(this.countryCode) === -1) {
      this.gridOptions.view.columns.splice(3, 1);
    }

    this.reload();
  }

  /** Отмена счета. */
  cancel(id: string) {
    this.data
      .singleton('Tenant')
      .action('WP.CancelLicenseOrder')
      .execute({ documentId: id })
      .subscribe({
        next: () => {
          this.reload();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  reload() {
    this.gridService.setLoadingState(true);

    this.formArray.clear();

    this.data
      .singleton('Tenant')
      .function('WP.GetAccountDocuments')
      .query<AccountDocument[]>()
      .subscribe({
        next: (data: AccountDocument[]) => {
          const documents = orderBy(data, ['number'], ['desc']);

          documents.forEach((document: any) => {
            document.status = {
              id: document.status,
              name: this.translate.instant(
                `enums.accountDocumentStatus.${document.status}`,
              ),
            };

            document.type = {
              id: document.type,
              name: this.translate.instant(
                `enums.accountDocumentType.${document.type}`,
              ),
            };

            const group = this.gridService.getFormGroupForRow(
              document,
              this.gridOptions.view.columns.concat([
                {
                  name: 'type',
                  type: null,
                  header: null,
                  hint: null,
                },
                {
                  name: 'hasLicense',
                  type: null,
                  header: null,
                  hint: null,
                },
              ]),
            );
            this.formArray.push(group);
          });
          this.gridService.setLoadingState(false);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.gridService.setLoadingState(false);
        },
      });
  }
}
