import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { ClientOverviewComponent } from 'src/app/clients/card/client-overview/client-overview.component';
import { ClientOverviewKpiModule } from 'src/app/clients/card/client-overview/client-overview-kpi/client-overview-kpi.module';
import { ClientOverviewAnalysisModule } from 'src/app/clients/card/client-overview/client-overview-analysis/client-overview-analysis.module';

@NgModule({
  declarations: [ClientOverviewComponent],
  imports: [
    CommonModule,
    SharedModule,
    ClientOverviewKpiModule,
    ClientOverviewAnalysisModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'client.overview',
          url: '/overview',
          component: ClientOverviewComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
          redirectTo: 'client.overview.kpi',
          resolve: [
            {
              token: 'entityType',
              resolveFn: () => 'client',
            },
          ],
        },
      ],
    }),
    BaseSharedModule,
    CommentsModule,
  ],
})
export class ClientOverviewModule {}
