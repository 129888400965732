<!--Строка ресурса-->
<tr class="group" [class.disabled]="!group.isActive" id="{{ group.id }}">
  <td class="line-view-action cursor-pointer">
    @if (!group.isOther) {
      <div
        (click)="onExpand()"
        class="h-100 d-flex align-item justify-content-center align-items-center"
      >
        <span
          [ngClass]="{
            'bi bi-chevron-down': group.isExpanded,
            'bi bi-chevron-right': !group.isExpanded
          }"
        ></span>
      </div>
    }
    @if (group.isOther) {
      <div
        class="h-100 d-flex align-item justify-content-center align-items-center"
        (click)="service.toggleShowOtherActual()"
      >
        <span
          class="bi bi-eye-fill"
          [ngClass]="{ 'text-gray opacity-75': !service.showOtherActual }"
        ></span>
      </div>
    }
  </td>

  <td
    class="content"
    (mouseenter)="rowStates[group.id].isHover = true"
    (mouseleave)="rowStates[group.id].isHover = false"
    [ngClass]="{
      commands:
        rowStates[group.id].commands.length > 0 &&
        (rowStates[group.id].isHover || rowStates[group.id].isMenuOpened)
    }"
  >
    <div>
      @if (group.resource?.type === resourceType.user) {
        <img class="avatar-bg" [src]="group.resource.id | avatar: 60" />
        <div class="trim">
          <div
            id="{{ group.resource.id }}"
            class="wp-action text"
            title="{{ group.name }}"
            (click)="openUserInfo(group.resource.id)"
          >
            <span id="{{ group.resource.id }}-name">{{ group.name }}</span>
          </div>
          <div class="role" title="{{ group.role?.name }}">
            {{ group.role?.name }}
          </div>
        </div>
        @if (group.verboseHint) {
          <i class="bi bi-question-circle text-gray d-flex" [ngbTooltip]="group.verboseHint"></i>
        }
      }

      <!-- Generic -->
      @if (!group.resource || (group.resource?.type === resourceType.generic && !group.isOther)) {
        @if (group.name) {
          <img src="/assets/images/avatar/role.svg" />
        }
        <div class="trim">
          <div
            class="text"
            title="{{ group.name ?? 'projects.projects.card.resources.unassigned' | translate }}"
          >
            {{ group.name ?? 'projects.projects.card.resources.unassigned' | translate }}
          </div>
          <div class="role" title="{{ group.role?.name }}">{{ group.role?.name }}</div>
        </div>
      }

      <!-- Other Group -->
      @if (group.isOther) {
        <div
          class="trim text"
          title="{{ group.name }}"
          [ngClass]="{ 'text-gray opacity-75': !service.showOtherActual }"
        >
          {{ group.name }}
        </div>
        @if (group.verboseHint) {
          <i
            class="bi bi-question-circle text-gray d-flex ms-auto me-3"
            [ngbTooltip]="group.verboseHint"
          ></i>
        }
      }

      <wp-planner-row-commands [rowState]="rowStates[group.id]" />
    </div>
  </td>

  <td class="total-cell">
    {{ (!empty ? group.totalHours : 0) | plannerFormat }}
  </td>
</tr>

<!-- Data lines. -->
@if (group.isExpanded) {
  @for (line of group.lines; track line.id) {
    <tr [ngClass]="{ disabled: !line.isActive }">
      <td class="line-view-action"></td>
      <td
        id="{{ line.id }}"
        class="content"
        (mouseenter)="rowStates[line.id].isHover = true"
        (mouseleave)="rowStates[line.id].isHover = false"
        [ngClass]="{
          commands:
            rowStates[group.id].commands.length > 0 &&
            (rowStates[line.id].isHover || rowStates[line.id].isMenuOpened)
        }"
      >
        <div>
          <div title="{{ line.name }}" class="text">
            {{ line.name }}
          </div>

          @if (rowStates[line.id]) {
            <wp-planner-row-commands [rowState]="rowStates[line.id]" />
          }
        </div>
      </td>
      <td class="total-cell">
        {{ line.totalHours | plannerFormat }}
      </td>
    </tr>
  }

  @if (!group.lines.length) {
    <tr>
      <td class="line-view-action"></td>
      <td class="content"></td>
      <td></td>
    </tr>
  }
}
