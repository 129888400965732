import { MonoTypeOperatorFunction } from 'rxjs';

/** Cartesian coordinates */
export interface Coordinates {
  /** Coordinate by x axis */
  x: number;
  /** Coordinate by y axis  */
  y: number;
}

export interface DragEvent extends Coordinates {
  /** Difference between start event position and current event by Y axis. */
  diffY: number;
  /** Difference between start event position and current event by X axis. */
  diffX: number;
  /** Current target of event. */
  target?: EventTarget;
}

export interface DragContext {
  /** Left offset of drag container */
  leftOffset: number;
  /** Top offset of drag container */
  topOffset: number;
}

export interface AutoScrollOptions {
  /** Container to scroll. */
  container: HTMLElement;
  /** Dragged element. */
  target: HTMLElement;
  /** Function on destroy event (e.g. `takeUntil(destroyed$)`, `takeUntilDestroyed(this.destroyRef)`). */
  takeUntil?: MonoTypeOperatorFunction<unknown>;
}
