import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';

@Injectable()
export class ReportPeriodService {
  private periodSubject = new BehaviorSubject<DatePeriod>(null);
  public period$ = this.periodSubject.asObservable();

  private periodTitleSubject = new BehaviorSubject<string>(null);
  public periodTitle$ = this.periodTitleSubject.asObservable();

  public setPeriod(period: DatePeriod) {
    this.periodSubject.next(period);
  }

  public setPeriodTitle(title: string) {
    this.periodTitleSubject.next(title);
  }
}
