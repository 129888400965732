<ng-container [formGroup]="form">
  <div class="form-group">
    <label>{{ 'settings.validationRules.card.props.checkPlanTypeLabel' | translate }}</label>

    <br />
    <div class="btn-group btn-group-toggle" radioGroup name="radioBasic" formControlName="type">
      <label radioLabel class="btn-default trim fw-normal">
        <input radioButton type="radio" value="CheckTaskPlan" />{{
          'settings.validationRules.card.props.checkTaskPlan' | translate
        }}
      </label>
      <label radioLabel class="btn-default trim fw-normal">
        <input radioButton type="radio" value="CheckProjectPlan" />{{
          'settings.validationRules.card.props.checkProjectPlan' | translate
        }}
      </label>
    </div>
  </div>
</ng-container>
