import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Component({
  selector: 'wp-time-off-schedule-filters',
  templateUrl: './time-off-schedule-filters.component.html',
  styleUrls: ['./time-off-schedule-filters.component.scss'],
})
export class TimeOffScheduleFiltersComponent
  implements OnInit, OnDestroy, FilterDetails
{
  @Input() values;

  public detailsForm = this.fb.group({
    department: null,
    onlySubordinates: false,
    level: null,
    location: null,
    resourcePool: null,
  });

  private destroyed$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    public service: FilterService,
  ) {}

  ngOnInit(): void {
    this.service.resetValues$.subscribe(() => {
      this.detailsForm.reset();
    });

    this.detailsForm.patchValue(this.service.values);

    this.detailsForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        const values = Object.assign(this.service.values, value);

        this.service.changeValues(values);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
