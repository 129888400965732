import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';
import { KpiKind } from 'src/app/shared/models/enums/kpi-kind.model';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';

export class TasksReportModalSettings implements BaseSettings {
  public readonly name = 'TasksReportModalSettings';
  version: number;
  kpiKinds: KpiKind[];
  kpiTypes: KpiType[];
  getDefault = (): TasksReportModalSettings =>
    ({
      version: 1,
      kpiKinds: Object.values(KpiKind),
      kpiTypes: Object.values(KpiType),
    }) as TasksReportModalSettings;
}
