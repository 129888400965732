/* eslint-disable @typescript-eslint/naming-convention */
export enum RuleCalculationBase {
  /** Labor Cost base. */
  LaborCost = 'LaborCost',
  /** Revenue base. */
  Revenue = 'Revenue',
  /** Working Capital base. */
  WorkingCapital = 'WorkingCapital',
}

export const RuleCalculationBases = [
  RuleCalculationBase.LaborCost,
  RuleCalculationBase.Revenue,
  RuleCalculationBase.WorkingCapital,
];
