import { Component, Input } from '@angular/core';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { UntypedFormGroup } from '@angular/forms';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';

@Component({
  selector: 'wp-assessment-cell',
  templateUrl: './assessment-cell.component.html',
  styleUrls: ['./assessment-cell.component.scss'],
})
export class AssessmentCellComponent implements GridTemplateCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;

  constructor(public service: GridService) {}
}
