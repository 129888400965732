<table style="margin-bottom: 15px">
  <tr>
    <td>
      <div class="toolbar-container" style="padding: 0">
        <div class="group">
          <button
            type="button"
            class="btn btn-default"
            (click)="previousPeriod()"
            style="width: 35px"
          >
            <i class="bi bi-arrow-left bi-15" aria-hidden="true"></i>
          </button>
          <button
            type="button"
            class="btn btn-default"
            (click)="today()"
            [disabled]="currentPeriod == 0"
          >
            <i class="bi bi-circle" aria-hidden="true"></i>
          </button>
          <button type="button" class="btn btn-default" (click)="nextPeriod()" style="width: 35px">
            <i class="bi bi-arrow-right bi-15" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </td>
    <td style="vertical-align: middle">
      <span style="padding-left: 25px; font-size: large">{{ header }}</span>
    </td>
  </tr>
</table>

<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

<table class="wp-nested-table" [hidden]="isLoading" [formGroup]="formArray">
  <thead>
    <tr>
      <th
        style="width: 27px; padding: 0"
        title="{{ 'settings.users.card.schedules.personalSchedule.weekNumber' | translate }}"
      >
        #
      </th>
      <th *ngFor="let weekDay of weekDays">{{ weekDay.title }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let weekNumber of weekNumbers">
      <td>{{ weekNumber }}</td>
      <td *ngFor="let weekDay of weekDays">
        <div
          *ngIf="days[weekNumber][weekDay.number]"
          [ngClass]="{ readonly: days[weekNumber][weekDay.number].notPersonal }"
        >
          <wp-number-box
            [min]="0"
            [max]="24"
            type="work"
            placeholder=""
            [formControlName]="days[weekNumber][weekDay.number].index"
          >
          </wp-number-box>
          <p class="month-day" title="{{ days[weekNumber][weekDay.number].hint }}">
            {{ days[weekNumber][weekDay.number].title }}
          </p>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<table *ngIf="!isLoading">
  <tr>
    <td style="padding-left: 5px !important; padding-right: 5px !important">
      <div style="width: 25px; height: 25px" class="readonly"></div>
    </td>
    <td>
      &mdash;
      {{ 'settings.users.card.schedules.personalSchedule.scheduleIsNotUsed' | translate }}.
    </td>
  </tr>
</table>
