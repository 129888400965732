import { Navigation } from 'src/app/shared/models/navigation/navigation';

export const FINANCE_APP_NAVIGATION: Navigation = {
  name: 'finance',
  applicationTitle: 'shared.apps.finance',
  groups: [
    {
      name: 'entries',
      header: 'finance.navigation.entries.header',
      items: [
        {
          name: 'finance.accountingEntries',
          state: 'accountingEntries',
          header: 'finance.navigation.entries.entries.header',
          hint: 'finance.navigation.entries.entries.hint',
        },
        {
          name: 'finance.periods',
          state: 'accountingPeriods',
          header: 'finance.navigation.entries.periods.header',
          hint: 'finance.navigation.entries.periods.hint',
        },
      ],
    },
    {
      name: 'documents',
      header: 'finance.navigation.documents.header',
      items: [
        {
          name: 'finance.timesheets',
          state: 'timesheets',
          header: 'finance.navigation.documents.timesheets.header',
          hint: 'finance.navigation.documents.timesheets.hint',
          stateParams: {
            view: 'team-all',
          },
        },

        {
          name: 'finance.expenseRequests',
          state: 'expensesRequests',
          header: 'finance.navigation.documents.expenses.header',
          hint: 'finance.navigation.documents.expenses.hint',
          stateParams: {
            view: 'team-all',
          },
        },
        {
          name: 'finance.actsOfAcceptance',
          state: 'actsOfAcceptance',
          header: 'finance.navigation.documents.acts.header',
          hint: 'finance.navigation.documents.acts.hint',
        },
      ],
    },
    {
      name: 'analytics',
      header: 'finance.navigation.analytics.header',
      items: [
        {
          name: 'finance.pnl',
          state: 'pnl',
          header: 'finance.navigation.analytics.pnl.header',
          hint: 'finance.navigation.analytics.pnl.hint',
        },
      ],
    },
  ],
};
