import { Input, ElementRef, Renderer2, Directive, OnInit } from '@angular/core';

@Directive({
  selector: '[wpLoadingValue]',
})
export class LoadingValueDirective implements OnInit {
  private _loading = false;
  private _value: any;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  private updateValue(): void {
    const value = this._loading
      ? '<i class="bi bi-arrow-clockwise rotate text-body-secondary"></i>'
      : this._value;

    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', value);
  }

  ngOnInit(): void {
    this.updateValue();
  }

  @Input('wpLoadingValue') set value(value: string) {
    this._value = value;
    this.updateValue();
  }

  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.updateValue();
  }
}
