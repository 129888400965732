import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { createPopper } from '@popperjs/core';
import { filter } from 'rxjs';
import _ from 'lodash';

import { InfoPopupEntry } from '../info-popup.interface';
import { InfoPopupService } from '../info-popup.service';

@Component({
  selector: 'tmt-popup-item',
  templateUrl: './info-popup-item.component.html',
  styleUrls: ['./info-popup-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPopupEntryComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('popper', { static: true }) private popper!: ElementRef;

  @Input({ required: true }) public popup: InfoPopupEntry;

  private destroyRef = inject(DestroyRef);
  private mutationObserver: MutationObserver;

  constructor(
    public infoPopupService: InfoPopupService,
    public injector: Injector,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.popup.clickOutsideEnabled ??= true;

    // TODO: remove this after `params` refactoring
    if (this.popup.data.params !== undefined) {
      this.popup.data.componentParams = {
        inputs: this.popup.data.params
          ? {
              params: this.popup.data.params,
            }
          : null,
        injector:
          this.popup.data.componentParams?.injector ?? this.popup.data.injector,
      };
    }

    if (this.popup.data.injector) {
      this.popup.data.componentParams.injector = this.popup.data.injector;
    }
  }

  public ngAfterViewInit(): void {
    const modifiers = this.popup.popperModifiers
      ? [
          ...this.infoPopupService.defaultPopperModifiers,
          ...this.popup.popperModifiers,
        ]
      : this.infoPopupService.defaultPopperModifiers;

    this.popup.popperInstance = createPopper(
      this.popup.target,
      this.popper.nativeElement,
      {
        placement: this.popup.placement ?? 'bottom',
        modifiers,
      },
    );
    this.popup.popperInstance.update().then(() => {
      this.cdr.detectChanges();
    });

    this.initMutationObserver();

    if (
      _.isElement(this.popup.target) &&
      !this.popup.observeIntersectionDisabled
    ) {
      this.infoPopupService.observeIntersection(this.popup.target);
    }

    if (
      _.isElement(this.popup.target.contextElement) &&
      !this.popup.observeIntersectionDisabled
    ) {
      this.infoPopupService.observeIntersection(
        this.popup.target.contextElement,
      );
    }

    this.infoPopupService.event$
      .pipe(
        filter(
          (e) =>
            e.name === 'update' && (!e.popup || e.popup?.id === this.popup.id),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    if (this.popup.onDestroy) {
      this.popup.onDestroy();
    }

    this.mutationObserver.disconnect();
  }

  /**
   * Subscribes to the mutation event for the element.
   *
   * @param element needed element for observing.
   */
  private observeMutation(element: Element): void {
    if (!_.isElement(element)) {
      return;
    }

    this.mutationObserver.observe(element, {
      attributes: true,
      childList: true,
      characterData: true,
    });
  }

  /* Generates new mutation observer. */
  private initMutationObserver(): void {
    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach(() => this.infoPopupService.update());
    });

    switch (this.popup.mutationObserverElement) {
      case 'target':
        this.observeMutation(this.popup.target);
        break;
      case 'popup':
        this.observeMutation(this.popper.nativeElement);
        break;
      default:
        this.observeMutation(
          this.popup.mutationObserverElement ?? this.popup.target,
        );
        break;
    }
  }
}
