import {
  Component,
  Input,
  ChangeDetectorRef,
  DestroyRef,
  inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OnInit } from '@angular/core';

@Component({
  selector: 'wp-role-cell',
  template: `{{ control.value }}`,
  styles: [
    `
      :host {
        padding-right: 12px;
        padding-left: 12px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleCellComponent implements GridTemplateCell, OnInit {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;

  private destroyRef = inject(DestroyRef);

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  constructor(
    public service: GridService,
    public ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.ref.detectChanges());
  }
}
