import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class BookingViewSettings implements BaseSettings {
  public readonly name = 'BookingViewSettings';
  public version: number;
  public show: 'remainCapacity' | 'workload';
  public resourceRequestShown: boolean;

  public getDefault(): BookingViewSettings {
    return {
      version: 2,
      show: 'workload',
      resourceRequestShown: false,
    } as BookingViewSettings;
  }
}

export class AssistantBookingViewSettings implements BaseSettings {
  public readonly name: 'AssistantBookingViewSettings';
  public version: number;
  public show: 'remainCapacity' | 'workload';
  public resourceRequestShown: boolean;

  public getDefault(): AssistantBookingViewSettings {
    return {
      version: 2,
      show: 'remainCapacity',
    } as AssistantBookingViewSettings;
  }
}
