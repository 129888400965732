import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { uniq } from 'lodash';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';

@Component({
  selector: 'wp-generic-assignment-modal',
  templateUrl: './generic-assignment-modal.component.html',
})
export class GenericAssignmentModalComponent implements OnInit {
  @Input() memberId: string;
  @Input() projectId: string;

  currentResourceIds: string[];

  public member: any;
  public role: NamedEntity;
  public isSaving: boolean;

  public resourceFilter: any = {
    filter: [],
  };

  public loading: boolean;

  public form = this.fb.group({
    resource: [null, Validators.required],
    hasRole: true,
  });

  rolesReducingAttention: string;
  hasRolesReducing: boolean;

  private getTeamMembersCollection = () =>
    this.data.collection('ProjectTeamMembers');

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    private activeModal: NgbActiveModal,
  ) {}

  public ok() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    this.getTeamMembersCollection()
      .entity(this.member.id)
      .action('AssignUser')
      .execute({
        resourceId: this.form.value.resource.id,
        isVersion: !this.versionCardService.isWorkProjectVersion(),
      })
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.activeModal.close();
          this.notification.successLocal(
            'projects.projects.card.team.assignUser.messages.assigned',
          );
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  private updateResourceFilter() {
    this.resourceFilter = { filter: [] };

    if (this.form.controls.hasRole.value && this.role) {
      this.resourceFilter.filter.push({
        or: [
          {
            additionalUserRoles: {
              any: {
                roleId: { type: 'guid', value: this.role.id },
              },
            },
          },
          {
            roleId: { type: 'guid', value: this.role.id },
          },
        ],
      });
    }

    if (this.currentResourceIds.length > 0) {
      const inString = this.currentResourceIds.join(',');
      this.resourceFilter.filter.push([`not (id in (${inString}))`]);
    }
  }

  ngOnInit() {
    this.loading = true;

    this.form.controls.hasRole.valueChanges.subscribe(() => {
      this.updateResourceFilter();
    });

    const teamMembersQuery = {
      select: ['description', 'id'],
      expand: {
        resource: { select: ['id', 'name'] },
        role: { select: ['id', 'name'] },
      },
    };
    const resourceIdsQuery = {
      filter: { resourceId: { ne: null } },
      select: 'resourceId',
    };

    forkJoin({
      member: this.getTeamMembersCollection()
        .entity(this.memberId)
        .get(teamMembersQuery),
      resourceIds: this.versionDataService
        .projectCollectionEntity(
          this.versionCardService.projectVersion,
          this.projectId,
        )
        .collection('ProjectTeamMembers')
        .query<any[]>(resourceIdsQuery),
    }).subscribe({
      next: (result) => {
        this.member = result.member;
        this.role = this.member?.role;
        this.currentResourceIds = uniq(
          result.resourceIds.map((r) => r.resourceId),
        );
        this.updateResourceFilter();
        this.loading = false;
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.loading = false;
      },
    });
  }
}
