import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { MessageService } from 'src/app/core/message.service';
import { LogService } from 'src/app/core/log.service';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/core/notification.service';
import { Dashboard } from 'src/app/shared/models/entities/analytics/dashboard.model';
import { filter } from 'rxjs/operators';
import { DashboardConfig } from '../shared/dashboard-config.model';
import { assign, cloneDeep } from 'lodash';
import { WidgetConfig } from '../shared/widget-config.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class DashboardService {
  public dashboardId: string;
  public configuration: DashboardConfig;
  public dashboard: Dashboard;
  public widgetStorageName = 'copiedWidget';

  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  private loadingSubscription: Subscription;

  private dashboardSubject = new BehaviorSubject<Dashboard>(null);
  public dashboard$ = this.dashboardSubject
    .asObservable()
    .pipe(filter((p) => !!p));

  public cardName$ = new BehaviorSubject<string>('');

  public reloadWidgets$ = new Subject<void>();
  public reflowWidgets$ = new Subject<WidgetConfig>();

  constructor(
    private notification: NotificationService,
    private log: LogService,
    private message: MessageService,
    private data: DataService,
    private autosave: AutosaveService,
    private actionService: ActionPanelService,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    this.autosave.builderFn = () => {
      this.dashboard.configuration = JSON.stringify(this.configuration);
      return this.dashboard;
    };

    this.autosave.savingFn = (obj: any) =>
      this.data.collection('Dashboards').entity(this.dashboard.id).update(obj);
  }

  /** Загрузка панели. */
  public load(dashboardId: string) {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    this.dashboardId = dashboardId;

    this.log.debug('Dashboard is loading...');
    this.state$.next(CardState.Loading);

    this.loadingSubscription = this.data
      .collection('Dashboards')
      .entity(dashboardId)
      .get()
      .subscribe({
        next: (dashboard: Dashboard) => {
          this.dashboard = dashboard;
          this.configuration = JSON.parse(dashboard.configuration);

          this.dashboardSubject.next(dashboard);
          this.cardName$.next(dashboard.name);

          this.state$.next(CardState.Ready);
        },
        error: (error: Exception) => {
          if (error.code === Exception.BtEntityNotFoundException.code) {
            this.state$.next(CardState.Error);
            return;
          }
          this.message.error(error.message);
        },
      });
  }

  /** Создать виджет. */
  public createWidget(): void {
    this.configuration.widgets.push({
      id: Guid.generate(),
      viewSettings: {
        sourceName: null,
      },
      name: this.translate.instant('analytics.dashboards.widget.defaultName'),
      size: {},
    });

    this.autosave.saveLazy();
    this.autosave.save().then(
      () => {
        this.notification.successLocal(
          'analytics.dashboards.card.messages.widgetHasBeenCreated',
        );
      },
      () => null,
    );
  }

  /** Pastes widget. */
  public pasteWidget(): void {
    const widget: WidgetConfig = this.localStorageService.retrieve(
      this.widgetStorageName,
    );

    const emptyWidget: WidgetConfig = {
      id: Guid.generate(),
      viewSettings: {
        sourceName: null,
      },
      name: widget.name,
      size: {
        width: widget.size.width,
        height: widget.size.height,
      },
    };
    this.configuration.widgets.push(emptyWidget);

    this.autosave.saveLazy();
    this.autosave.save().then(() => {
      const pastedWidgetIndex = this.configuration.widgets.findIndex(
        (item) => item.id === emptyWidget.id,
      );
      const pastedWidget = this.configuration.widgets[pastedWidgetIndex];
      widget.id = pastedWidget.id;
      widget.size = pastedWidget.size;
      widget['cloned'] = true;
      this.configuration.widgets[pastedWidgetIndex] = cloneDeep(widget);
      this.localStorageService.clear(this.widgetStorageName);
      this.actionService.action('pasteWidgetFromStorage').isShown = false;
    });
  }

  /** Удалить виджет. */
  public removeWidget(widget: WidgetConfig): void {
    this.message
      .confirmLocal('analytics.dashboards.card.messages.widgetRemovingConfirm')
      .then(
        () => {
          const index = this.configuration.widgets.indexOf(widget);
          if (index !== -1) {
            this.configuration.widgets.splice(index, 1);
          }
          this.autosave.saveLazy();
          this.autosave.save().then(() => {
            this.notification.successLocal(
              'analytics.dashboards.card.messages.widgetWasRemoved',
            );
          });
        },
        () => null,
      );
  }

  public onWidgetChange(updatedWidget: WidgetConfig) {
    const widget = this.configuration.widgets.find(
      (w) => w.id === updatedWidget.id,
    );

    updatedWidget.size = widget.size;
    assign(widget, updatedWidget);
    this.autosave.saveLazy();
  }

  public onWidgetResize(widget: WidgetConfig) {
    this.autosave.saveLazy();
    this.reflowWidgets(widget);
  }

  /** Опубликовать панель. */
  public publishDashboard(): void {
    this.message
      .confirmLocal(
        'analytics.dashboards.card.messages.publishDashboardConfirm',
      )
      .then(
        () => {
          this.actionService.action('publishDashboard').isBusy = true;
          this.data
            .collection('Dashboards')
            .entity(this.dashboardId)
            .action('WP.Publish')
            .execute()
            .subscribe({
              next: () => {
                this.actionService.action('publishDashboard').isBusy = false;

                this.notification.successLocal(
                  'analytics.dashboards.card.messages.dashboardWasPublished',
                );
                this.dashboard.isPublished = true;
                this.load(this.dashboardId);
              },
              error: (error: Exception) => {
                this.notification.error(error.message);
              },
            });
        },
        () => null,
      );
  }

  public callOfDashboard(): void {
    this.message
      .confirmLocal('analytics.dashboards.card.messages.callOfDashboardConfirm')
      .then(
        () => {
          this.actionService.action('callOfDashboard').isBusy = true;
          this.data
            .collection('Dashboards')
            .entity(this.dashboardId)
            .action('WP.CallOff')
            .execute()
            .subscribe({
              next: () => {
                this.actionService.action('callOfDashboard').isBusy = false;

                this.notification.successLocal(
                  'analytics.dashboards.card.messages.dashboardWasCalledOf',
                );
                this.dashboard.isPublished = false;
                this.load(this.dashboardId);
              },
              error: (error: Exception) => {
                this.notification.error(error.message);
              },
            });
        },
        () => null,
      );
  }

  public reloadWidgets() {
    this.reloadWidgets$.next();
  }

  public reflowWidgets(widget: WidgetConfig) {
    this.reflowWidgets$.next(widget);
  }

  public onNameSaved(name: string) {
    this.dashboard.name = name;
  }
}
