import { DestroyRef, Inject, Injectable, inject } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { Role } from 'src/app/shared/models/entities/settings/role.model';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class RoleCardService {
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  private nameSubject = new BehaviorSubject<string>(null);
  public name$ = this.nameSubject.asObservable();

  private roleSubject = new ReplaySubject<Role>();
  public role$ = this.roleSubject.asObservable();

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public entityId: string,
    private data: DataService,
    private notification: NotificationService,
    private actionService: ActionPanelService,
    private navigationService: NavigationService,
  ) {
    this.load();
  }

  /** Loads role data. */
  public load(): void {
    this.state$.next(CardState.Loading);

    const query = {
      select: [
        'id',
        'name',
        'description',
        'isActive',
        'isDefault',
        'editAllowed',
      ],
    };

    this.data
      .collection('Roles')
      .entity(this.entityId)
      .get<Role>(query)
      .subscribe({
        next: (role: Role) => {
          this.state$.next(CardState.Ready);
          this.actionService.action('save').isShown = role.editAllowed;
          this.actionService.action('setAsDefault').isShown =
            role.editAllowed && !role.isDefault;

          this.nameSubject.next(role.name);
          this.roleSubject.next(role);

          this.navigationService.addRouteSegment({
            id: role.id,
            title: role.name,
          });
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          this.notification.error(error.message);
        },
      });
  }

  /** Sets role as default. */
  public setAsDefault(): void {
    this.data
      .collection('Roles')
      .entity(this.entityId)
      .action('WP.SetAsDefault')
      .execute()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.notification.successLocal('settings.roles.card.setAsDefault');
          this.actionService.action('setAsDefault').isShown = false;
          this.actionService.reload();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  /** Updates role name subject. */
  public updateName(value: any): void {
    this.nameSubject.next(value);
  }
}
