import { Injectable } from '@angular/core';
import { GridColumnType } from 'src/app/shared/models/inner/grid-column.interface';

/** Service for parsing text value before writing it in the grid cell control. */
@Injectable()
export class TextToControlValueParserService {
  /** Parses string value to some types by content type.
   *
   * @param text string for parsing
   * @param type requested content type
   * @returns some type depends from input type
   */
  public parse(text: string, type: GridColumnType): any {
    switch (type) {
      case GridColumnType.String:
      case GridColumnType.StringControl:
        return text;
      case GridColumnType.Integer:
      case GridColumnType.Decimal:
      case GridColumnType.Percent:
      case GridColumnType.NumberControl:
      case GridColumnType.Currency:
      case GridColumnType.CurrencyControl:
      case GridColumnType.Work:
        return this.parseToNumber(text, type);
      case GridColumnType.Boolean:
      case GridColumnType.BooleanControl:
        return this.parseToBoolean(text);
      case GridColumnType.Date:
      case GridColumnType.DateTime:
      case GridColumnType.DateControl:
        return this.parseToDate(text);
    }
    return;
  }

  /** Parses string to number
   *
   * @param text text to parsing
   * @param type target type. Used for rounding integer numbers
   * @returns number, null if text is empty string and undefined if string can not parsing
   */
  private parseToNumber(
    text: string,
    type: GridColumnType,
  ): number | null | void {
    if (text === '') {
      return null;
    }
    const number = +text.replace(',', '.').replace(/\s/g, '');
    if (isNaN(number)) {
      return;
    }
    if (type === GridColumnType.Integer) {
      return Math.round(number);
    }
    return number;
  }

  private parseToBoolean(text: string): boolean | undefined {
    const textWithoutSpaces = text.replace(/\s/g, '');
    if (textWithoutSpaces.toLowerCase() === 'true') {
      return true;
    }
    if (textWithoutSpaces.toLowerCase() === 'false') {
      return false;
    }
    return;
  }

  /** Parses string to ISO date
   *
   * @param text text to parsing
   * @returns date string in ISO format and undefined if string can not parsing
   */
  private parseToDate(text: string): string | undefined {
    const textWithLineSeparators = text.replace(/[^-\d]/g, '-');
    const dateSegments = textWithLineSeparators.split('-');
    if (dateSegments.length !== 3 || dateSegments.some((el) => isNaN(+el))) {
      return;
    }
    // set year as first
    if (
      dateSegments[2].length === 4 ||
      (dateSegments[0].length <= 2 && dateSegments[2].length === 2)
    ) {
      dateSegments.reverse();
    }
    // check year
    if (dateSegments[0].length !== 2 && dateSegments[0].length !== 4) {
      return;
    }
    if (dateSegments[0].length === 2) {
      dateSegments[0] = '20' + dateSegments[0];
    }
    // check month
    if (+dateSegments[1] > 12) {
      return;
    }
    if (dateSegments[1].length === 1) {
      dateSegments[1] = '0' + dateSegments[1];
    }
    // check day
    if (+dateSegments[2] > 31) {
      return;
    }
    if (dateSegments[2].length === 1) {
      dateSegments[2] = '0' + dateSegments[2];
    }

    return dateSegments.join('-');
  }
}
