<div
  *ngIf="!disabled"
  class="input-group"
  [ngClass]="{ nullable: allowNull }"
  #selectBoxContainer
  wpIntersectable
  (onIntersect)="closeList()"
>
  <input
    #input
    class="form-control"
    (blur)="onBlur()"
    [formControl]="textControl"
    (keydown)="onKeyDown($event)"
    type="text"
    title="{{ value && value[optionLabel] }}"
    placeholder="{{ placeholder }}"
    (click)="onInputClick()"
  />

  <span
    class="input-clear"
    title="{{ 'shared.hintClearControlButton' | translate }}"
    (click)="clear()"
    [hidden]="!textControl.value"
    >&times;</span
  >

  <button tabindex="-1" type="button" class="btn btn-default input-group-text" (click)="openList()">
    <i class="bi bi-chevron-down" aria-hidden="true"></i>
  </button>
</div>

<p
  *ngIf="disabled"
  class="trim"
  [ngClass]="{ 'text-body-secondary': !textControl.value }"
  title="{{ getReadOnlyDisplayText() }}"
>
  {{ getReadOnlyDisplayText() }}
</p>

<ng-template #expandingArea>
  <div class="select-expand-container">
    <div class="fixed-expanding-area expanding-area-vertical-scroll">
      <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

      <table id="selecting-list" class="selecting-list" [hidden]="isLoading">
        <tbody>
          @for (row of rows; track row.id) {
            @if (groupHandler && (!$index || row[groupName] !== rows[$index - 1][groupName])) {
              <tr title="{{ row[groupName] }}">
                <td class="trim group">
                  {{ row[groupName] }}
                </td>
              </tr>
            }
            <tr
              (mouseenter)="selectRow(row)"
              (mousedown)="mousedownOnRow($event)"
              (click)="clickRow(row)"
              id="{{ row.id }}"
              title="{{ row[optionLabel] }}"
              [ngClass]="{ 'selected-row': row.id && row.id === selectedRow?.id }"
            >
              <td class="trim">
                {{ row[optionLabel] }}
              </td>
            </tr>
          } @empty {
            <div class="center-block" style="text-align: center; padding: 3px">
              <p class="text-body-secondary">{{ 'shared.noOptions' | translate }}</p>
            </div>
          }
        </tbody>
      </table>
      <div
        *ngIf="loadedPartly && !isLoading"
        class="center-block"
        style="text-align: center; padding: 3px"
      >
        <p class="text-body-secondary">
          {{ 'shared.showFirstNRecordsMessage' | translate: { limit: loadLimit } }}<br />
          {{ 'shared.refineQueryMessage' | translate }}
        </p>
      </div>
    </div>
  </div>
</ng-template>
