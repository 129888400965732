import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { ExpensesRequestListComponent } from './list/expenses-request-list.component';
import { ExpensesRequestCreationComponent } from './creation/expenses-request-creation.component';
import { ExpensesRequestFilterComponent } from './list/filter/expenses-request-filter.component';
import { LineModalComponent } from './card/details/line-modal/line-modal.component';
import { ExpensesRequestDetailsToolbarComponent } from 'src/app/expenses-requests/card/details/toolbar/expenses-request-details-toolbar.component';
import { AttachmentCellComponent } from './card/details/attachment-cell/attachment-cell.component';
import { ExpensesRequestCardComponent } from './card/expenses-request-card.component';
import { ExpensesRequestDetailsComponent } from './card/details/expenses-request-details.component';
import { ExpenseTaskCellComponent } from './card/details/expense-task-cell/expense-task-cell.component';
import { ExpenseTaskReadonlyCellComponent } from './card/details/expense-task-cell/expense-task-readonly-cell.component';
import { ExpensesRequestService } from 'src/app/expenses-requests/expenses-request.service';
import { ExpensesRequestSetProjectComponent } from 'src/app/expenses-requests/card/set-project/expenses-request-set-project.component';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { EXPENSES_LIST } from 'src/app/shared/lists/expenses.list';
import {
  LIST,
  MASS_OPERATION_PARAMETERS,
  META_ENTITY_TYPE,
  MULTI_SELECT_LIST,
  VIEW_NAME,
} from 'src/app/shared/tokens';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';

@NgModule({
  declarations: [
    ExpensesRequestListComponent,
    ExpensesRequestCardComponent,
    ExpensesRequestCreationComponent,
    ExpensesRequestFilterComponent,
    ExpensesRequestDetailsComponent,
    LineModalComponent,
    ExpensesRequestDetailsToolbarComponent,
    AttachmentCellComponent,
    ExpenseTaskReadonlyCellComponent,
    ExpenseTaskCellComponent,
    ExpensesRequestSetProjectComponent,
  ],

  imports: [
    CommonModule,
    SharedModule,
    BaseSharedModule,
    EntityListComponent,
    CommentsModule,
    Grid2Module,
    UIRouterModule.forChild({
      states: [
        {
          name: 'expensesRequest',
          url: '/expenses/{entityId:guid}?{navigation}&{route}',
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          component: ExpensesRequestCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
        {
          name: 'expensesRequests',
          url: '/expenses/{view:viewName}?{navigation}',
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: META_ENTITY_TYPE, useValue: 'ExpenseRequest' },
            { provide: LIST, useValue: EXPENSES_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'expensesRequest',
              } as MassOperationParameters,
            },
          ],
          params: {
            navigation: null,
            view: null,
          },
          component: ExpensesRequestListComponent,
        },
      ],
    }),
  ],
  providers: [ExpensesRequestService],
})
export class ExpensesRequestsModule {}
