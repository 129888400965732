import { Component, Input, OnInit } from '@angular/core';
import { PlannerViewService } from '../core/planner-view.service';
import { Period } from '../models/period.model';
import { PlanningScale } from '../../../shared/models/enums/planning-scale.enum';
import { PlannerPeriodService } from '../core/planner-period.service';
import { DateTime, Interval } from 'luxon';

@Component({
  selector: 'wp-planner-period',
  templateUrl: './planner-period.component.html',
  styleUrls: ['./planner-period.component.scss'],
})
export class PlannerPeriodComponent implements OnInit {
  private _planningScale: PlanningScale;
  @Input()
  get planningScale(): PlanningScale {
    return this._planningScale;
  }
  set planningScale(value: PlanningScale) {
    this._planningScale = value;
    this.update();
  }

  private _dates: Interval;
  @Input()
  get dates(): Interval {
    return this._dates;
  }
  set dates(value: Interval) {
    this._dates = value;
    this.update();
  }

  private _periodOffset: number;
  @Input()
  get periodOffset(): number {
    return this._periodOffset;
  }
  set periodOffset(value: number) {
    this._periodOffset = value;
    this.update();
  }

  private _baseDate: DateTime;
  @Input()
  get baseDate(): DateTime {
    return this._baseDate;
  }
  set baseDate(value: DateTime) {
    this._baseDate = value;
    this.update();
  }

  public suitablePeriods: Period[];
  public periodTitle: string;

  constructor(
    private plannerViewService: PlannerViewService,
    private plannerPeriodService: PlannerPeriodService,
  ) {}

  /** Предыдущий период. */
  public previousPeriod() {
    this.plannerPeriodService.previous();
  }

  /** Последующий период. */
  public nextPeriod() {
    this.plannerPeriodService.next();
  }

  /** Перейти на выбранный период.  */
  public changePeriod(offset: number) {
    this.plannerPeriodService.setPeriod(offset);
  }

  private updateSuitablePeriods() {
    this.suitablePeriods = [];

    for (
      let suitableOffset = this.periodOffset - 3;
      suitableOffset < this.periodOffset + 4;
      suitableOffset++
    ) {
      const dates = this.plannerViewService.getPeriodDates(
        this.baseDate,
        suitableOffset,
        this.planningScale,
      );
      const title = this.plannerViewService.getPeriodTitle(
        dates,
        this.planningScale,
      );

      this.suitablePeriods.push({
        offset: suitableOffset,
        title,
        isBase: suitableOffset === 0,
      });
    }
  }

  private updateTitle() {
    if (!this.dates) {
      return;
    }

    this.periodTitle = this.plannerViewService.getPeriodTitle(
      this.dates,
      this.planningScale,
    );
  }

  private update() {
    if (!this._baseDate) return;
    this.updateTitle();
    this.updateSuitablePeriods();
  }

  ngOnInit(): void {
    this.update();
  }
}
