<div tabindex="0" [attr.cells-group]="serviceId" [attr.id]="id" [ngClass]="mode">
  <div
    class="spreader-control"
    (mousedown)="onSpreaderMouseDown($event)"
    (dblclick)="onSpreaderDblClick($event)"
    *ngIf="!readonly && !entriesOrchestrator.singleCellMode"
  ></div>
  <div
    class="frame"
    [ngClass]="mode"
    (dblclick)="onDblClick()"
    (click)="onClick()"
    (mousedown)="onMouseDown()"
    #frame
  ></div>
  <div class="content" [attr.id]="id">
    <span
      *ngIf="showIcon"
      [ngClass]="iconClass"
      title="{{ getIconTitle() }}"
      class="bi ts-al-cmt"
    ></span>
    <div class="value">
      {{ allocation?.duration | wpTimeDuration: dayScheduleDuration : true }}
    </div>
    <input
      #editor
      *ngIf="mode.editing"
      name="input"
      autocomplete="off"
      class="ts-full-cell"
      [value]="viewValue"
      (mousedown)="onInputMouseDown($event)"
      (keydown)="onKeydown($event)"
      (input)="handleInput($event)"
    />
  </div>
</div>
