<div *ngIf="!disabled" class="input-group">
  <input
    ngbDatepicker
    [popperOptions]="popperOptions"
    #d="ngbDatepicker"
    class="form-control"
    title="{{ getTitle() }}"
    name="dp"
    placeholder="{{ placeholder }}"
    autocomplete="off"
    [formControl]="dateControl"
    [markDisabled]="markDisabled"
    (blur)="onBlur()"
    wpIntersectable
    (onIntersect)="closePopper()"
    (focus)="onFocus()"
    [dayTemplate]="checkIsHighlighted ? customDay : null"
  />

  <button class="btn btn-default input-group-text" (click)="d.toggle()" type="button">
    <i class="bi bi-calendar3" aria-hidden="true"></i>
  </button>
</div>

<p *ngIf="disabled && value" class="trim" title="{{ getTitle() }}">
  {{ getTitle() }}
</p>
<p *ngIf="disabled && !value" class="text-body-secondary text-uppercase">
  {{ 'shared.empty' | translate }}
</p>

<ng-template
  #customDay
  let-date
  let-currentMonth="currentMonth"
  let-selected="selected"
  let-disabled="disabled"
>
  <div
    class="custom-day btn-light"
    [class.highlightedDay]="markHighlighted(date)"
    [class.bg-primary]="selected"
    [class.text-white]="selected"
    [class.outside]="!selected && (date.month !== currentMonth || disabled)"
  >
    {{ date.day }}
  </div>
</ng-template>
