import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StringHelper {
  constructor() {}

  /** Склонение числительных. Возвращает строку в виде "1 критерий", "3 условия". */
  public static declOfNum(number: number, titles: string[]): string {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  }

  public static toUnicode(str: string): string {
    let unicodeString = '';
    for (let i = 0; i < str.length; i++) {
      let theUnicode = str.charCodeAt(i).toString(16).toUpperCase();
      while (theUnicode.length < 4) {
        theUnicode = '0' + theUnicode;
      }
      theUnicode = '\\u' + theUnicode;
      unicodeString += theUnicode;
    }
    return unicodeString;
  }

  /** Returns crossed out string based on input string.
   *
   * @param str input string
   * @returns crossed out string
   */
  public static getCrossedOutString(str: string): string {
    return str
      .split('')
      .map((letter) => `\u{0335}${letter}\u{0335}`)
      .join('');
  }
}
