import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { Guid } from 'src/app/shared/helpers/guid';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { ProjectCardService } from '../../../../core/project-card.service';
import { ExpensesSectionType } from '../../../models/expenses-view.model';
import { ExpenseEstimateModalComponent } from '../../../project-expense-estimates/shared/expense-estimate-modal/expense-estimate-modal.component';
import {
  ProjectExpensesCalendarSlotInfoService,
  SlotInfo,
} from '../../core/project-expenses-calendar-slot-info.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectExpenseEstimate } from 'src/app/shared/models/entities/projects/project-expense-estimate.model';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { takeUntil } from 'rxjs/operators';
import { RuleCalculationMethod } from 'src/app/shared/models/enums/rule-calculation-method.enum';

@Component({
  selector: 'wp-project-expenses-calendar-slot-info',
  templateUrl: './project-expenses-calendar-slot-info.component.html',
  styleUrls: ['./project-expenses-calendar-slot-info.component.scss'],
})
export class ProjectExpensesCalendarSlotInfoComponent
  implements OnInit, OnDestroy
{
  @Input() params: any;

  public slotInfo: SlotInfo[] = [];
  public laborCostInfo: SlotInfo;

  public isShown: boolean;
  public isLoading$ = new BehaviorSubject<boolean>(true);

  public get projectCurrencyCode() {
    return this._projectCurrencyCode;
  }

  /**
   * Checks if Slot creation is allowed on popup or not.
   *
   * @returns <code>true</code> if creation is allowed, <code>false</code> otherwise.
   * */
  public get createAllowed(): boolean {
    if (this.params.expenseRule) {
      return false;
    }
    return this.editAllowed();
  }

  private project: Project;
  private _projectCurrencyCode: string;
  private projectVersion: ProjectVersion;

  private defaultTask: ProjectTask;

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private service: ProjectExpensesCalendarSlotInfoService,
    private projectTasksService: ProjectTasksService,
    private projectCardService: ProjectCardService,
    private changeDetector: ChangeDetectorRef,
    private modal: NgbModal,
    private infoPopupService: InfoPopupService,
  ) {}

  ngOnInit(): void {
    this.projectCardService.project$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((project) => {
        this.project = project;
        this._projectCurrencyCode = this.project.currency.alpha3Code;
      });

    this.open();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * Checks if Slot edit is allowed on popup or not.
   *
   * @param slot Slot to check. If not passed, general access rights will be checked.
   * @returns <code>true</code> if edit is allowed, <code>false</code> otherwise.
   * */
  public editAllowed(slot?: SlotInfo): boolean {
    if (
      !!slot?.expenseRule &&
      slot.expenseRule.calculationMethod === RuleCalculationMethod.Percent
    ) {
      return false;
    }
    if (!this.project) {
      return true;
    }

    let editAllowed: boolean;
    switch (this.params.sectionType) {
      case ExpensesSectionType.expenses:
        editAllowed = this.project.expenseEstimateEditAllowed;
        break;
    }

    return editAllowed && this.projectVersion.editAllowed;
  }

  /**
   * Opens Slot popup with loaded data.
   * */
  public open(): void {
    this.service.sectionType = this.params.sectionType;
    this.service.projectId = this.params.projectId;
    this.projectVersion = this.service.versionCardService.projectVersion;

    this.setDefaultTask();

    this.isShown = true;
    this.changeDetector.detectChanges();

    const laborCostSelected =
      this.params.expenseType && this.params.expenseType.id === null;
    if (laborCostSelected) {
      this.isLoading$.next(false);
      this.changeDetector.detectChanges();
      this.infoPopupService.update();

      return;
    }

    this.service
      .load(
        this.params.projectTaskId,
        this.params.expenseType,
        this.params.expenseRule,
        this.params.isInnerLine,
        this.params.dateFrom,
        this.params.dateTo,
      )
      .then((loaded) => {
        this.slotInfo = loaded;
        this.isLoading$.next(false);
        this.changeDetector.detectChanges();
        this.infoPopupService.update();
      });
  }

  /**
   * Opens Modal window in create mode and saves entered data.
   * */
  public onCreate(): void {
    this.isShown = false;
    this.infoPopupService.close();
    this.openModal().then(
      (filled) => {
        filled.id = Guid.generate();
        ProjectVersionUtil.setEntityRootPropertyId(
          this.projectVersion,
          filled,
          this.params.projectId,
        );
        this.slotInfo.push(filled as SlotInfo);

        const data = {
          id: filled.id,
          projectTaskId: filled.projectTask.id,
          expenseTypeId: filled.expenseType.id,
          expenseRuleId: filled.expenseRule?.id ?? null,
          date: filled.date,
          amount: filled.amount,
          description: filled.description,
        };
        ProjectVersionUtil.setEntityRootPropertyId(
          this.projectVersion,
          data,
          this.params.projectId,
        );
        this.service.insert(data).then(
          () => this.infoPopupService.close(),
          () => null,
        );
      },
      () => null,
    );
  }

  /**
   * Opens Modal window in edit mode and saves entered data.
   *
   * @param info Slot to edit.
   * */
  public onEdit(info: SlotInfo): void {
    this.isShown = false;
    this.infoPopupService.close();
    this.openModal(info).then(
      (filled) => {
        const index = this.slotInfo.findIndex((i) => i.id === filled.id);
        this.slotInfo[index] = filled as SlotInfo;

        const data = {
          id: filled.id,
          projectTaskId: filled.projectTask.id,
          expenseTypeId: filled.expenseType.id,
          expenseRuleId: filled.expenseRule?.id ?? null,
          date: filled.date,
          amount: filled.amount,
          description: filled.description,
        };
        ProjectVersionUtil.setEntityRootPropertyId(
          this.projectVersion,
          data,
          this.params.projectId,
        );

        this.service.update(data).then(
          () => this.infoPopupService.close(),
          () => null,
        );
      },
      () => null,
    );
  }

  /**
   * Removes entity from memory and on server.
   *
   * @param info Slot to remove.
   * */
  public onRemove(info: SlotInfo): void {
    if (!this.editAllowed(info)) {
      return;
    }
    this.slotInfo = this.slotInfo.filter((i) => i.id !== info.id);
    this.service.delete(info.id).then(
      () => this.infoPopupService.close(),
      () => null,
    );
  }

  private openModal(info?: SlotInfo): Promise<any> {
    const ref = (() => {
      switch (this.params.sectionType) {
        case ExpensesSectionType.expenses:
          return this.modal.open(ExpenseEstimateModalComponent);
      }
    })();
    const instance = ref.componentInstance;
    instance.projectId = this.params.projectId;
    instance.projectVersion = this.projectVersion;
    instance.projectCurrencyCode = this.projectCurrencyCode;

    if (info) {
      instance.readonly = !this.editAllowed(info) || !info.expenseType?.id;
      instance.entry = info as ProjectExpenseEstimate;
      return ref.result;
    }
    instance.entry = {
      date: this.params.dateTo,
      projectTask: this.defaultTask as NamedEntity,
      expenseType: !this.params.expenseType?.id
        ? null
        : this.params.expenseType,
      expenseRule: this.params.expenseRule ?? null,
    };
    return ref.result;
  }

  private setDefaultTask() {
    this.projectTasksService
      .getProjectTasks(this.project.id, this.projectVersion)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tasks) => {
        const currTask = tasks.find((t) => t.id === this.params.projectTaskId);
        const leadTask = tasks.find((t) => !t.leadTaskId);
        this.defaultTask = currTask ?? leadTask;
      });
  }
}
