<div (click)="toggleDirection()" class="container d-flex" *ngIf="sortService.visibility$ | async">
  <div *ngIf="(sortService.sortDirection$ | async) === sortDirection.oldest">
    <i class="bi bi-sort-up"></i>
    {{ 'shared.sort.oldest' | translate }}
  </div>
  <div *ngIf="(sortService.sortDirection$ | async) === sortDirection.newest">
    <i class="bi bi-sort-down"></i>
    {{ 'shared.sort.newest' | translate }}
  </div>
</div>
