import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { Transition } from '@uirouter/core';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { CompetenceCardComponent } from './card/competence-card.component';
import { CompetenceCreationComponent } from './creation/competence-creation.component';
import { CompetencesListComponent } from './list/competences-list.component';
import { CompetencesList } from 'src/app/shared/lists/competences.list';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    CompetencesListComponent,
    CompetenceCardComponent,
    CompetenceCreationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.competences',
          url: '/competences',
          component: CompetencesListComponent,
          params: {
            navigation: 'settings.competences',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: CompetencesList },
          ],
        },

        {
          name: 'settings.competence',
          params: {
            navigation: 'settings.competences',
          },
          url: '/competences/{entityId:guid}',
          component: CompetenceCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class CompetencesModule {}
