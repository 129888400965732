import { BookingEntryInfoComponent } from './shared/booking-entry-info/booking-entry-info.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { PlannerModule } from 'src/app/shared-features/planner/planner.module';
import { BookingComponent } from './booking.component';
import { BookingEntryFormComponent } from './shared/booking-entry-form/booking-entry-form.component';
import { BookingFilterModule } from './shared/booking-filter/booking-filter.module';
import { BookingFilterComponent } from './shared/booking-filter/booking-filter.component';
import { BookingLeftGroupComponent } from './shared/booking-left-group/booking-left-group.component';
import { BookingRightGroupComponent } from './shared/booking-right-group/booking-right-group.component';
import { BookingEntryComponent } from './shared/booking-entry/booking-entry.component';
import { ScheduleNavigationModule } from 'src/app/shared-features/schedule-navigation/schedule-navigation.module';
import { BookingToolbarComponent } from './shared/booking-toolbar/booking-toolbar.component';
import { BookingEntryFormDetailsComponent } from './shared/booking-entry-form/booking-entry-form-details/booking-entry-form-details.component';
import { UIRouterModule } from '@uirouter/angular';
import { BookingViewSettingsComponent } from './shared/booking-view-settings/booking-view-settings.component';
import { BookingNotifyComponent } from './shared/booking-notify/booking-notify.component';
import { BookingSlotInfoComponent } from './shared/booking-slot-info/booking-slot-info.component';
import { BookingBasicComponent } from 'src/app/booking/booking/shared/booking-basic/booking-basic.component';
import { BookingHeaderLeftComponent } from 'src/app/booking/booking/shared/booking-table-header/booking-header-left/booking-header-left.component';
import { BookingHeaderRightComponent } from 'src/app/booking/booking/shared/booking-table-header/booking-header-right/booking-header-right.component';
import { BookingBasicLineRightComponent } from 'src/app/booking/booking/shared/booking-basic/booking-basic-line/booking-basic-line.component';
import { BookingDetailedComponent } from 'src/app/booking/booking/shared/booking-detailed/booking-detailed.component';
import { BookingDetailedLineLeftComponent } from 'src/app/booking/booking/shared/booking-detailed/booking-detailed-line-left/booking-detailed-line-left.component';
import { BookingDetailedLineRightComponent } from 'src/app/booking/booking/shared/booking-detailed/booking-detailed-line-right/booking-detailed-line-right.component';
import { BookingProjectAddComponent } from 'src/app/booking/booking/shared/booking-project-add/booking-project-add.component';

@NgModule({
  declarations: [
    BookingLeftGroupComponent,
    BookingRightGroupComponent,
    BookingEntryFormComponent,
    BookingComponent,
    BookingEntryComponent,
    BookingToolbarComponent,
    BookingEntryFormDetailsComponent,
    BookingViewSettingsComponent,
    BookingNotifyComponent,
    BookingEntryInfoComponent,
    BookingSlotInfoComponent,
    BookingDetailedComponent,
    BookingDetailedLineLeftComponent,
    BookingDetailedLineRightComponent,
    BookingBasicComponent,
    BookingBasicLineRightComponent,
    BookingHeaderLeftComponent,
    BookingHeaderRightComponent,
    BookingProjectAddComponent,
  ],
  exports: [
    BookingComponent,
    BookingFilterComponent,
    BookingLeftGroupComponent,
    BookingRightGroupComponent,
    BookingEntryFormComponent,
    BookingComponent,
    BookingEntryComponent,
    BookingToolbarComponent,
    BookingEntryFormDetailsComponent,
    BookingEntryInfoComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PlannerModule,
    BookingFilterModule,
    ScheduleNavigationModule,
    UIRouterModule,
  ],
})
export class BookingModule {}
