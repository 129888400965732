import { NamedEntity } from '../named-entity.model';

/** Сведения о счетчике времени. */
export interface Stopwatch {
  /** Состояние. */
  state: StopwatchState;

  /** Клиент. */
  client: NamedEntity;

  /** Проект. */
  project: NamedEntity;

  /** Задача. */
  projectTask: NamedEntity;

  /** Учтенное время. */
  duration: number;

  /** Время запуска (последнего) счетчика. */
  startTime: string;

  /** Дата счетчика. */
  date: string;

  /** Признак необходимости отображения клиента. */
  showClient: boolean;

  /** Выбранная задача - главная. */
  projectTaskIsMain: boolean;

  /** ИД линии таймшита. */
  timeSheetLineId: string;
}

/** Состояние счетчика времени. */
export enum StopwatchState {
  Started = 'Started',
  Paused = 'Paused',
  Stopped = 'Stopped',
}

export enum StopwatchStopType {
  Cancel = 'Cancel',
  Increment = 'Increment',
  Rewrite = 'Rewrite',
}
