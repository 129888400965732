<form class="modal-form h-100" novalidate ngDraggable>
  <button
    type="button"
    class="btn-close"
    data-dismiss="modal"
    aria-hidden="true"
    (click)="cancel()"
  ></button>
  <iframe [src]="docUrl" class="doc-viewer"></iframe>
</form>
