<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-7">
      <div class="form-group">
        <label>
          {{ 'team.certificates.list.filter.props.period.label' | translate }}
        </label>
        <wp-date-period-box
          [advanced]="true"
          formControlName="period"
          placeholder="{{ 'team.certificates.list.filter.props.period.ph' | translate }}"
        ></wp-date-period-box>
      </div>
      <div class="form-group">
        <label>
          {{ 'team.certificates.list.filter.props.owner.label' | translate }}
        </label>
        <wp-user-box
          formControlName="owner"
          collection="Users"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'team.certificates.list.filter.props.owner.ph' | translate }}"
        ></wp-user-box>
      </div>
    </div>

    <div class="col-5">
      <tmt-state-select formControlName="states" collection="Certificates" />
    </div>
  </div>
</form>
