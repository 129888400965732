import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Constants } from 'src/app/shared/globals/constants';
import { AppService } from 'src/app/core/app.service';

@Component({
  selector: 'wp-permission-set-creation',
  templateUrl: './permission-set-creation.component.html',
})
export class PermissionSetCreationComponent {
  isSaving = false;
  creationForm: UntypedFormGroup;
  roles: NamedEntity[];

  constructor(
    app: AppService,
    fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
  ) {
    this.creationForm = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      role: [null, Validators.required],
    });

    this.roles = app.getRolesEntities();
  }

  ok = () => {
    this.creationForm.markAllAsTouched();

    if (this.creationForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const data = {
      name: this.creationForm.value.name,
      roleName: this.creationForm.value.role.id,
    };

    this.data
      .collection('PermissionSets')
      .insert(data)
      .subscribe({
        next: (response: any) => {
          this.notification.successLocal(
            'settings.permissionSets.creation.created',
          );
          this.state.go('settings.permissionSet', { entityId: response.id });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
