import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { ProjectRevenueEstimate } from 'src/app/shared/models/entities/projects/project-revenue-estimate.model';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { RbcSectionType } from '../../models/rbc-view.model';
import { ProjectBillingEstimate } from 'src/app/shared/models/entities/projects/project-billing-estimate.model';
import { map } from 'rxjs/operators';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';

export interface SlotInfo {
  id: string;
  date?: string;
  billingDate?: string;
  collectionDate?: string;
  description: string;
  amount: number;
  projectTask: NamedEntity;
  isAutomatic?: boolean;
}

@Injectable()
export class ProjectRbcCalendarSlotInfoService {
  private changesSubject = new Subject<RbcSectionType[]>();
  public changes$ = this.changesSubject.asObservable();

  public sectionType: RbcSectionType;
  public projectId: string;

  constructor(
    private data: DataService,
    public versionCardService: ProjectVersionCardService,
    private notification: NotificationService,
  ) {}

  /* Сообщает об изменениях */
  public propagateChange() {
    const types = [this.sectionType];
    if (this.sectionType === RbcSectionType.billing) {
      types.push(RbcSectionType.collection);
    }
    if (this.sectionType === RbcSectionType.collection) {
      types.push(RbcSectionType.billing);
    }
    this.changesSubject.next(types);
  }

  /* Загружает данные в формате SlotInfo */
  public load(
    projectTaskId: string | null,
    from: string,
    to: string,
  ): Promise<SlotInfo[]> {
    return new Promise((resolve) => {
      this.getLoadQuery(projectTaskId, from, to).subscribe({
        next: (loaded) => resolve(loaded),
        error: (error: Exception) => {
          this.notification.error(error.message);
          resolve([]);
        },
      });
    });
  }

  /* Создает сущность с переданными данными */
  public insert(data): Promise<SlotInfo> {
    return new Promise((resolve) => {
      this.getInsertQuery(data).subscribe({
        next: (inserted) => {
          this.notification.successLocal(this.getCreatedMessage());
          this.propagateChange();
          resolve(inserted);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          resolve(null);
        },
      });
    });
  }

  /* Модифицирует сущность с переданными данными */
  public update(data): Promise<SlotInfo> {
    return new Promise((resolve) => {
      this.getUpdateQuery(data).subscribe({
        next: (updated) => {
          this.notification.successLocal(this.getUpdatedMessage());
          this.propagateChange();
          resolve(updated);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          resolve(null);
        },
      });
    });
  }

  /* Удаляет сущность по Id */
  public delete(id: string): Promise<string> {
    return new Promise((resolve) => {
      this.getDeleteQuery(id).subscribe({
        next: (deleted) => {
          this.notification.successLocal(this.getDeletedMessage());
          this.propagateChange();
          resolve(deleted);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          resolve(null);
        },
      });
    });
  }

  private getLoadQuery(
    projectTaskId: string,
    from: string,
    to: string,
  ): Observable<SlotInfo[]> {
    const dateFieldName = this.getDateFieldName();
    const queryFilter = [];
    queryFilter.push({
      [dateFieldName]: {
        ge: { type: 'raw', value: from },
        le: { type: 'raw', value: to },
      },
    });
    if (projectTaskId) {
      queryFilter.push({
        projectTaskId: { type: 'guid', value: projectTaskId },
      });
    }
    const queryParams = {
      select: [
        'id',
        'date',
        'billingDate',
        'collectionDate',
        'number',
        'amount',
        'description',
        'isAutomatic',
      ],
      filter: queryFilter,
      expand: {
        projectTask: { select: ['id', 'name', 'structNumber'] },
      },
      orderBy: [dateFieldName, 'created'],
    };
    if (!projectTaskId) {
      ProjectVersionUtil.addProjectEntityIdFilter(
        queryParams,
        this.versionCardService.projectVersion,
        this.projectId,
      );
    }
    ProjectVersionUtil.addProjectSelectFields(
      this.versionCardService.projectVersion,
      queryParams.select,
    );

    const collection = this.getCollection();
    const query = (() => {
      switch (this.sectionType) {
        case RbcSectionType.revenue:
          return collection.query<ProjectRevenueEstimate[]>(queryParams);
        case RbcSectionType.billing:
          return collection.query<ProjectBillingEstimate[]>(queryParams);
        case RbcSectionType.collection:
          return collection.query<ProjectBillingEstimate[]>(queryParams);
        default:
          return collection.query<ProjectRevenueEstimate[]>(queryParams);
      }
    })();
    return query.pipe(
      //TODO: check and fix this code
      // @ts-ignore
      map((data: any) =>
        data.map((e) => ({
          id: e.id,
          date: e[dateFieldName],
          billingDate: e.billingDate,
          collectionDate: e.collectionDate,
          description: e.description,
          amount: e.amount,
          projectTask: e.projectTask as NamedEntity,
          isAutomatic: e.isAutomatic,
        })),
      ),
    );
  }
  private getInsertQuery(data): Observable<SlotInfo> {
    return this.getCollection().insert(data);
  }
  private getUpdateQuery(data): Observable<SlotInfo> {
    return this.getCollection().entity(data.id).update(data);
  }
  private getDeleteQuery(id): Observable<string> {
    return this.getCollection().entity(id).delete();
  }

  private getCollection() {
    switch (this.sectionType) {
      case RbcSectionType.revenue:
        return this.data.collection('ProjectRevenueEstimates');
      case RbcSectionType.billing:
        return this.data.collection('ProjectBillingEstimates');
      case RbcSectionType.collection:
        return this.data.collection('ProjectBillingEstimates');
      default:
        return this.data.collection('ProjectRevenueEstimates');
    }
  }
  private getDateFieldName() {
    switch (this.sectionType) {
      case RbcSectionType.revenue:
        return 'date';
      case RbcSectionType.billing:
        return 'billingDate';
      case RbcSectionType.collection:
        return 'collectionDate';
    }
  }

  private getCreatedMessage(): string {
    return `${this.getTranslationPrefix()}.messages.created`;
  }
  private getUpdatedMessage(): string {
    return `${this.getTranslationPrefix()}.messages.edited`;
  }
  private getDeletedMessage(): string {
    return `${this.getTranslationPrefix()}.messages.deleted`;
  }
  private getTranslationPrefix(): string {
    switch (this.sectionType) {
      case RbcSectionType.revenue:
        return 'projects.revenues.estimates';
      case RbcSectionType.billing:
        return 'projects.billings.estimates';
      case RbcSectionType.collection:
        return 'projects.billings.collections';
    }
  }
}
