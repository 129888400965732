<form class="modal-form" novalidate [formGroup]="form" ngDraggable autocomplete="off">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ getHeader() | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{
        'settings.currencies.card.props.currencyExchangeRates.columns.effectiveDate' | translate
      }}</label>
      <wp-date-box formControlName="effectiveDate"></wp-date-box>
    </div>
    <div class="form-group">
      <label>{{
        'settings.currencies.card.props.currencyExchangeRates.columns.exchangeRate' | translate
      }}</label>
      <wp-number-box formControlName="exchangeRate" precision="13" />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ getActionBtnName() | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
