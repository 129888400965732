<div
  wpFreezeTable
  class="disable-selecting"
  [class.request-group-mode]="service.currentViewMode === 'request-group'"
  leftWidthStrategy="byTable"
  rightWidthStrategy="byContainer"
>
  <div name="toolbar">
    <div class="toolbar-container">
      <wp-schedule-navigation [disabled]="service.frameLoading$ | async"></wp-schedule-navigation>
      <div class="group" *ngIf="!service.readonly">
        <button
          type="button"
          [disabled]="service.loading$ | async"
          title="{{
            'projects.projects.card.resourceRequirements.actions.requestList' | translate
          }}"
          class="btn btn-default"
          (click)="service.goToResourceRequests()"
          data-test="resourceRequests"
        >
          {{ 'projects.projects.card.resourceRequirements.actions.requestList' | translate }}
        </button>

        <button
          *ngIf="selectedControlCount"
          (click)="createResourceRequests()"
          type="button"
          [disabled]="service.loading$ | async"
          title="{{
            'projects.projects.card.resourceRequirements.actions.createRequest' | translate
          }}"
          class="btn btn-default"
          data-test="createRequest"
        >
          <span class="bi bi-plus-lg bi-15"></span>
          {{ 'projects.projects.card.resourceRequirements.actions.request' | translate }} ({{
            selectedControlCount
          }})
        </button>
      </div>
      <div class="group ms-auto">
        <div ngbDropdown class="d-inline-block" placement="bottom-right">
          <button
            type="button"
            class="btn btn-default"
            ngbDropdownToggle
            title="{{ 'projects.projects.card.finance.grouping.hint' | translate }}"
            data-test="grouping"
          >
            {{
              (service.currentViewMode === 'request-group'
                ? 'projects.projects.card.resources.view.byRequest'
                : 'projects.projects.card.resources.view.default'
              ) | translate
            }}
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="service.setViewMode('default')" data-test="default">
              {{ 'projects.projects.card.resources.view.default' | translate }}
            </button>
            <button
              ngbDropdownItem
              (click)="service.setViewMode('request-group')"
              data-test="requestGroup"
            >
              {{ 'projects.projects.card.resources.view.byRequest' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div name="left">
    <div name="scroll-table-header">
      <table class="table wp-nested-table" [ngStyle]="{ width: leftTableWidth + 'px' }">
        <colgroup>
          @if (service.currentViewMode === 'default') {
            <col style="width: 39px" />
          }
          <col style="width: 30px" />
          <col style="width: 100%" />
          <col style="width: 100px" />
          <col style="width: 80px" />
        </colgroup>
        <thead>
          <tr>
            @if (service.currentViewMode === 'default') {
              <th
                *ngIf="service.currentViewMode === 'default'"
                title="{{ 'timesheets.card.actions.selectAllLines' | translate }}"
                class="checkbox"
              >
                <div class="wp-checkbox">
                  <label>
                    <input type="checkbox" [formControl]="selectAllControl" />
                    <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
                  </label>
                </div>
              </th>
            }
            <th class="column-title trim" colspan="2">
              {{
                (service.currentViewMode === 'request-group'
                  ? 'projects.projects.card.resources.columns.resourceByRequest'
                  : 'projects.projects.card.resources.columns.resource'
                ) | translate
              }}
            </th>
            <th title="{{ 'projects.projects.card.team.columns.request.hint' | translate }}">
              {{ 'projects.projects.card.team.columns.request.header' | translate }}
            </th>
            <th title="{{ 'projects.projects.card.resources.columns.hours.hint' | translate }}">
              Σ
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table wp-nested-table" [ngStyle]="{ width: leftTableWidth + 'px' }">
        <colgroup>
          <col style="width: 39px" />
          <col style="width: 30px" />
          <col style="width: 100%" />
          <col style="width: 100px" />
          <col style="width: 80px" />
        </colgroup>
        <tbody *ngIf="!dataService.groups.length && !(service.loading$ | async)">
          <tr>
            <td class="no-data" [attr.colspan]="5"></td>
          </tr>
        </tbody>

        @if (service.currentViewMode === 'request-group') {
          @for (request of dataService.groupsByResourceRequest; track $index) {
            <tbody *ngIf="request.selectedTeamMemberIds.length">
              <tr>
                <td class="collapser cursor-pointer" (click)="toggleRequestGroupExpand(request)">
                  <span
                    [ngClass]="{
                      'bi bi-chevron-down': request.isExpanded,
                      'bi bi-chevron-right': !request.isExpanded
                    }"
                  ></span>
                </td>
                <td class="request-title trim" colspan="4">
                  {{ request.name }}
                </td>
              </tr>
            </tbody>

            <ng-container *ngIf="request.isExpanded && dataService.groups.length">
              @for (teamMemberId of request.selectedTeamMemberIds; track $index) {
                <tbody
                  tmtResourceRequirementsLeftGroup
                  [group]="dataService.getGroupByTeamMember(teamMemberId)"
                ></tbody>
              }
            </ng-container>
          }
        } @else {
          @for (group of dataService.groups; track group) {
            <tbody
              tmtResourceRequirementsLeftGroup
              [group]="group"
              [class.request-pending]="group.states.pending"
            ></tbody>
          }
        }
      </table>
    </div>

    <div name="scroll-table-footer"></div>
  </div>

  <div name="right" [ngStyle]="{ 'margin-left': leftTableWidth - 1 + 'px' }">
    <div name="scroll-table-header">
      <table
        class="table wp-nested-table"
        [ngStyle]="{ 'min-width': service.getTableWidth() + 'px' }"
      >
        <colgroup>
          <col *ngFor="let slot of service.slots; trackBy: trackById" />
        </colgroup>
        <thead>
          <tr>
            <th
              *ngFor="let group of service.slotGroups; trackBy: trackById"
              [attr.colspan]="group.slotsCount"
              title="{{ group.hint }}"
            >
              {{ group.header }}
            </th>
          </tr>
          <tr>
            <th
              class="slot"
              *ngFor="let slot of service.slots"
              [ngClass]="{ 'slot-active': slot.today }"
              title="{{ slot.hint }}"
            >
              {{ slot.header }}
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table
        class="table wp-nested-table"
        [ngStyle]="{ 'min-width': service.getTableWidth() + 'px' }"
      >
        <colgroup>
          <col *ngFor="let slot of service.slots" />
        </colgroup>
        <tbody *ngIf="!dataService.groups.length && !(service.loading$ | async)">
          <tr>
            <td class="text-body-secondary no-data" [attr.colspan]="service.slots.length">
              {{ 'shared.noDisplayData' | translate }}
            </td>
          </tr>
        </tbody>

        @if (service.currentViewMode === 'request-group') {
          @for (request of dataService.groupsByResourceRequest; track $index) {
            <tbody *ngIf="request.selectedTeamMemberIds.length">
              <tr>
                <td [attr.colspan]="service.slots.length"></td>
              </tr>
            </tbody>

            <ng-container *ngIf="request.isExpanded && dataService.groups.length">
              @for (teamMemberId of request.selectedTeamMemberIds; track $index) {
                <tbody
                  tmtResourceRequirementsRightGroup
                  [group]="dataService.getGroupByTeamMember(teamMemberId)"
                  [valueMode]="service.valueMode"
                  [scale]="service.planningScale"
                ></tbody>
              }
            </ng-container>
          }
        } @else {
          @for (group of dataService.groups; track group) {
            <tbody
              tmtResourceRequirementsRightGroup
              [group]="group"
              [valueMode]="service.valueMode"
              [scale]="service.planningScale"
            ></tbody>
          }
        }
      </table>
    </div>

    <div name="scroll-table-footer"></div>
  </div>
</div>

<wp-loading-indicator [loading]="service.loading$ | async"></wp-loading-indicator>
