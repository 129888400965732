<div class="input-group" id="{{ id }}" [ngClass]="{ 'has-details': service.hasDetails }">
  <div>
    <input
      class="form-control ellipsis"
      type="text"
      maxlength="255"
      placeholder="{{ placeholder | translate }}"
      [formControl]="textControl"
      data-test="textFilter"
    />

    <span class="input-clear" (click)="clear()" [hidden]="!textControl.value">&times;</span>
  </div>

  @if (service.hasDetails) {
    <button
      data-test="filter"
      type="button"
      [title]="'shared2.actions.openFilter' | translate"
      class="btn btn-default input-group-text"
      (click)="openDetails()"
    >
      @if (extraConditionsCount) {
        <span class="pe-1"> +{{ extraConditionsCount }} {{ extraConditionTitle }} </span>
      }

      <i class="bi bi-sliders2 bi-15" aria-hidden="true"></i>
    </button>
  }
</div>

@if (service.hasViewSelector) {
  <div ngbDropdown class="ms-2">
    <button
      data-test="view"
      type="button"
      [title]="'shared2.actions.openFilterView' | translate"
      class="btn btn-default"
      id="view-menu"
      ngbDropdownToggle
    >
      {{ service.values?.view?.local | translate }}
    </button>

    <div ngbDropdownMenu aria-labelledby="view-menu">
      @for (view of service.views; track $index) {
        <button ngbDropdownItem (click)="setView(view)">
          {{ view?.local | translate }}
        </button>
      }
    </div>
  </div>
}

@if (service.hasPeriodSelector) {
  <wp-date-period-box
    class="ms-2"
    [formControl]="periodControl"
    placeholder="{{ service.periodSelectorPlaceholder ?? 'shared2.filters.period.ph' | translate }}"
  />
}

<div
  name="area"
  class="expanding-area"
  (clickOutside)="cancel()"
  [exclude]="excludeClickOutside"
  [excludeBeforeClick]="true"
  [hidden]="!areaIsExpanded"
  hidden
>
  <div class="container-fluid" [ngStyle]="service.detailAreaStyles">
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
      title="{{ 'shared.actions.close' | translate }}"
    ></button>

    <div class="row">
      <div class="col">
        <ng-template wp-filter-details-host></ng-template>
      </div>
    </div>

    <form *ngIf="isShowCustomFieldsForm" [formGroup]="customFieldsForm" class="row form">
      <wp-custom-fields
        class="d-block custom-fields-container"
        [formGroup]="customFieldsForm"
        [isVisibleHr]="false"
        [entityType]="service.customFieldEntityType"
        [mode]="'filter'"
      ></wp-custom-fields>
    </form>

    <div class="row">
      <div class="col" class="d-flex justify-content-between align-items-center">
        @if (service.hasAllowInactive) {
          <div class="form-check d-inline-block">
            <input
              [formControl]="allowInactiveControl"
              type="checkbox"
              class="form-check-input"
              id="allowInactive"
            />
            <label class="form-check-label" for="allowInactive">
              {{ 'shared2.filters.allowNonActiveEntityToSelected' | translate }}
            </label>
          </div>
        }

        <button
          type="button"
          class="btn btn-default"
          (click)="clearValues()"
          data-test="clearValues"
        >
          {{ 'shared.actions.clear' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
