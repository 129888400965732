import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConfigService } from 'src/app/core/app-config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Language } from 'src/app/shared/models/enums/language.enum';

/** Docs modal. */
@Component({
  selector: 'tmt-doc-modal',
  templateUrl: './doc-modal.component.html',
  styleUrls: ['./doc-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocModalComponent {
  @Input() route: string;

  // TODO: Add multi-language support.
  language = Language.ru;

  public get docUrl() {
    this.route = this.route.startsWith('/')
      ? this.route.substring(1)
      : this.route;

    const url = `${AppConfigService.config.site.url}${
      this.language === Language.en ? '' : '/' + this.language.toLowerCase()
    }/embedded-docs/${this.route}`;

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  constructor(
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
  ) {}

  public cancel() {
    this.activeModal.dismiss();
  }
}
