<div name="scroll-table-header">
  <table
    class="table wp-nested-table"
    [ngStyle]="{ width: timelineRenderingService.getDataTableWidth() + 'px' }"
  >
    <colgroup>
      @for (slot of service.slots; track slot.id) {
        <col />
      }
    </colgroup>
    <thead>
      <tr>
        @for (group of service.slotGroups; track group.id) {
          <th [attr.colspan]="group.slotsCount" title="{{ group.hint }}">
            {{ group.header }}
          </th>
        }
      </tr>
      <tr>
        @for (slot of service.slots; track slot.id) {
          <th
            class="slot"
            [ngClass]="{ 'slot-active': slot.today }"
            title="{{ slot.hint }}"
            [attr.data-slot-date]="slot.date.toISODate()"
          >
            {{ slot.header }}
          </th>
        }
      </tr>
    </thead>
  </table>
</div>

<div name="scroll-table-body">
  <table
    #board
    class="table wp-nested-table z-0"
    [ngStyle]="{ width: timelineRenderingService.getDataTableWidth() + 'px' }"
  >
    @if (gridService.loading$ | async) {
      <tr>
        <td class="p-0" [attr.colspan]="service.slots.length">
          <wp-loading-indicator class="h-0 p-0" [loading]="gridService.loading$ | async" />
        </td>
      </tr>
    }
    <colgroup>
      @for (slot of service.slots; track slot.id) {
        <col />
      }
    </colgroup>
    <tbody *ngIf="taskDataService.formArray.length == 0 && !(taskDataService.isLoading$ | async)">
      <tr>
        <td class="text-body-secondary no-data" [attr.colspan]="service.slots.length">
          {{ 'shared.noDisplayData' | translate }}
        </td>
      </tr>
    </tbody>
    @for (taskFormGroup of taskFormArrayGroups; track taskFormGroup.value.id) {
      <tbody
        (contextmenu)="gridService.openContextMenu($event, taskFormGroup)"
        wpTaskTimelineRightGroup
        [taskFormGroup]="taskFormGroup"
      ></tbody>
    }
    <canvas
      #dependenciesCanvas
      id="dependenciesCanvas"
      class="canvas"
      [attr.width]="timelineRenderingService.getDataTableWidth()"
      [attr.height]="timelineRenderingService.getDataTableHeight()"
    ></canvas>
    <canvas
      #dynamicCanvas
      id="dynamicCanvas"
      class="canvas"
      [attr.width]="timelineRenderingService.getDataTableWidth()"
      [attr.height]="timelineRenderingService.getDataTableHeight()"
    ></canvas>
  </table>
</div>

<div name="scroll-table-footer">
  <table
    class="table wp-nested-table"
    [ngStyle]="{ width: timelineRenderingService.getDataTableWidth() + 'px' }"
  >
    <colgroup>
      @for (slot of service.slots; track slot.id) {
        <col />
      }
    </colgroup>
    @if (!(taskDataService.isLoading$ | async)) {
      <tbody>
        <tr>
          <td [attr.colspan]="service.slots.length"></td>
        </tr>
      </tbody>
    }
  </table>
</div>
