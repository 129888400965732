import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ProjectRbcCalendarService } from '../../core/project-rbc-calendar.service';
import { ProjectRbcCalendarDataService } from '../../core/project-rbc-calendar-data.service';
import {
  RbcGroupType,
  RbcViewGroup,
  RbcViewTaskLine,
} from '../../../models/rbc-view.model';
import { DateValue } from '../../../models/rbc-data.model';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { ProjectRbcCalendarSlotInfoComponent } from '../project-rbc-calendar-slot-info/project-rbc-calendar-slot-info.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wpProjectRbcCalendarRightGroup]',
  templateUrl: './project-rbc-calendar-right-group.component.html',
  styleUrls: ['./project-rbc-calendar-right-group.component.scss'],
})
export class ProjectRbcCalendarRightGroupComponent
  implements OnInit, OnDestroy
{
  @Input() group: RbcViewGroup;
  @Input() showOther = true;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public readonly RbcGroupType = RbcGroupType;

  private subscriptions: Subscription[] = [];

  constructor(
    public service: ProjectRbcCalendarService,
    private dataService: ProjectRbcCalendarDataService,
    private changeDetector: ChangeDetectorRef,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.service.toggle$
        .pipe(filter((id) => id === this.group.id))
        .subscribe(() => {
          this.changeDetector.detectChanges();
        }),
      this.service.changes$.subscribe(() => {
        this.recalculateEntries();
      }),
    );
    this.recalculateEntries();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  trackById = (index: number, row: any) => row.id;

  public isSlotEditable(group: RbcViewGroup, entry: DateValue) {
    return this.service.isSlotEditable(group, entry);
  }

  /**
   * Opens popup for passed entry
   *
   * @param group - group which entry is from
   * @param line - line which entry is from
   * @param entry - entry itself
   * @param event - mouse event
   */
  public openPopup(
    group: RbcViewGroup,
    line: RbcViewTaskLine,
    entry: DateValue,
    event: MouseEvent,
  ) {
    if (!this.isSlotEditable(group, entry)) {
      return;
    }
    const section = this.dataService.getSectionByGroup(group);
    const taskId = line ? line.task.id : null;
    const dateTo = this.service.getSlotEndDate(entry);

    const target = event.target;
    this.infoPopupService.open({
      target,
      data: {
        component: ProjectRbcCalendarSlotInfoComponent,
        params: {
          sectionType: section.type,
          projectId: this.dataService.projectId,
          projectTaskId: taskId,
          dateFrom: entry.date,
          dateTo,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Recalculates entries
   *
   * @private
   */
  private recalculateEntries() {
    this.group.entries = this.service.getFilledSlots();
    this.group.taskLines.forEach((line) => {
      line.entries = this.service.getFilledSlots(line.entries);
    });
    if (this.group.otherLine) {
      this.group.otherLine.entries = this.service.getFilledSlots(
        this.group.otherLine.entries,
      );
    }

    this.group.entries.forEach((entry) => {
      this.group.taskLines.forEach((line) => {
        line.entries.forEach((le) => {
          if (entry.date === le.date) {
            entry.amount += le.amount;
          }
        });
      });
      if (this.showOther) {
        this.group.otherLine?.entries.forEach((le) => {
          if (entry.date === le.date) {
            entry.amount += le.amount;
          }
        });
      }
    });
    this.changeDetector.detectChanges();
  }
}
