import { NamedEntity } from '../named-entity.model';
import { ActOfAcceptanceLine } from './act-of-acceptance-line.model';
import { CustomizableEntity } from '../customizable-entity.interface';
import { State } from '../state.model';
import { Currency } from '../settings/currency.model';
import { CurrencyEntity } from '../currency-entity.model';

/** Акт (отражение выручки). */
export class ActOfAcceptance extends NamedEntity implements CustomizableEntity {
  static recognizedId = '9acb01dd-a475-4332-9d66-81c49ea0d899';

  project: CurrencyEntity;

  /** Номер акта. */
  number: string;

  /** Фактическая сумма. */
  amount: number;

  /** Фактическая дата. */
  date: string;

  /** Дата признания. */
  dateOfAcceptance: string;

  /** Описание. */
  description: string;

  /** Состояние акта. */
  state: State;

  /** Строки акта. */
  lines: ActOfAcceptanceLine[];

  /** Вложение. */
  attachment: any;

  // Customizable properties
  stringValue1?: string;
  stringValue2?: string;
  stringValue3?: string;
  stringValue4?: string;
  stringValue5?: string;

  decimalValue1?: number;
  decimalValue2?: number;
  decimalValue3?: number;
  decimalValue4?: number;
  decimalValue5?: number;

  dateValue1?: string;
  dateValue2?: string;
  dateValue3?: string;
  dateValue4?: string;
  dateValue5?: string;

  lookupValue1?: NamedEntity;
  lookupValue2?: NamedEntity;
  lookupValue3?: NamedEntity;
  lookupValue4?: NamedEntity;
  lookupValue5?: NamedEntity;

  integerValue1?: number;
  integerValue2?: number;
  integerValue3?: number;
  integerValue4?: number;
  integerValue5?: number;
}
