import { Pipe, PipeTransform } from '@angular/core';
import * as objectPath from 'object-path';

@Pipe({
  name: 'path',
})
export class PathPipe implements PipeTransform {
  transform(value: any, path: string): any {
    if (!path) {
      return value;
    }
    return objectPath.get(value, path);
  }
}
