import { Injectable } from '@angular/core';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { MenuAction } from 'src/app/shared/models/inner/menu-action';
import { ValidationModalComponent } from '../shared/validation-modal/validation-modal.component';
import { LifecycleInfoTransition } from 'src/app/shared/models/entities/lifecycle/lifecycle-info.model';
import { Observable } from 'rxjs';
import { Exception } from 'src/app/shared/models/exception';

/** Timesheet lifecycle service. */
@Injectable()
export class TimesheetLifecycleService extends LifecycleService {
  public override startWorkflow(workflowId: string, action: MenuAction) {
    this.autosavePromise.then(() => {
      this.checkAndRun(action.name).subscribe(() =>
        super.startWorkflow(workflowId, action),
      );
    });
  }

  public override setState(
    stateId: string,
    action?: MenuAction,
    transition?: LifecycleInfoTransition,
  ) {
    this.autosavePromise.then(() => {
      if (this.lifecycleInfo.currentState.code === 'Draft') {
        this.checkAndRun(null).subscribe(() =>
          super.setState(stateId, action, transition),
        );
      } else {
        super.setState(stateId, action, transition);
      }
    });
  }

  /** Checks validation rules and run callback function.*/
  private checkAndRun(actionName: string): Observable<void> {
    return new Observable((subscriber) => {
      if (actionName) {
        this.actionPanelService.action(actionName).start();
      }

      this.blockUI.stop();

      this.data
        .collection('TimeSheets')
        .entity(this.entityId)
        .function('Check')
        .query()
        .subscribe({
          next: (errors: any[]) => {
            if (errors.length === 0) {
              subscriber.next();
              subscriber.complete();
            } else {
              if (actionName) {
                this.actionPanelService.action(actionName).stop();
              }
              this.blockUI.stop();
              const ref = this.modal.open(ValidationModalComponent);
              (ref.componentInstance as ValidationModalComponent).items =
                errors;
              ref.result.then(
                () => {
                  subscriber.next();
                  subscriber.complete();
                },
                () => null,
              );
            }
          },
          error: (error: Exception) => {
            this.message.error(error.message);
            subscriber.error(error);
          },
        });
    });
  }
}
