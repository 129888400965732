<div
  aria-labelledby="ngb-nav-0"
  class="tab-pane fade show active mb-3"
  id="ngb-nav-0-panel"
  role="tabpanel"
>
  <tmt-comments-input />
</div>

<div class="form-group form-check mb-2">
  <input
    [formControl]="checkboxControl"
    id="showSystemEvents"
    type="checkbox"
    class="form-check-input"
  />
  <label class="form-check-label text-body-secondary text-body-secondary" for="showSystemEvents">
    {{ 'shared.comments.actions.showSystemEvents' | translate }}
  </label>
</div>

<div class="comments d-flex flex-column gap-1">
  @for (comment of commentService.comments; track comment.id) {
    <tmt-comment [comment]="comment" [attr.data-test-id]="comment.id" />
  }
</div>
