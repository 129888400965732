<wp-loading-indicator [loading]="isLoading"> </wp-loading-indicator>
@if (!isLoading) {
  <form class="modal-form" novalidate [formGroup]="form" ngDraggable>
    <div class="modal-header">
      <h3 class="modal-title">
        {{ 'projects.projects.card.resources.movePlanForm.header' | translate }}
      </h3>
      <button
        [disabled]="isSaving"
        type="button"
        class="btn-close"
        data-dismiss="modal"
        aria-hidden="true"
        (click)="cancel()"
      ></button>
    </div>
    <div class="modal-body">
      @if (!thereIsNoPlan) {
        <div class="row">
          <div class="col">
            <div>
              <div class="form-group" style="display: inline-block; padding-right: 15px">
                <label>{{
                  'projects.projects.card.resources.movePlanForm.properties.from' | translate
                }}</label>
                <wp-date-box formControlName="from" />
              </div>
              <div class="form-group" style="display: inline-block">
                <label>{{
                  'projects.projects.card.resources.movePlanForm.properties.to' | translate
                }}</label>
                <wp-date-box formControlName="to" />
              </div>
              <small class="text-body-secondary ps-3">
                {{ 'projects.projects.card.resources.movePlanForm.properties.help' | translate }}
              </small>
            </div>
            @if (isMoveLeft()) {
              <p class="text-danger">
                {{
                  'projects.projects.card.resources.movePlanForm.properties.moveLeftWarning'
                    | translate
                }}
              </p>
            }
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table class="wp-nested-table wp-nested-table-hover fixed" [ngStyle]="viewTableStyle">
              <thead>
                <tr>
                  <th class="checkbox">
                    <div class="wp-checkbox">
                      <label>
                        <input type="checkbox" [formControl]="allTasksSelector" />
                        <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
                      </label>
                    </div>
                  </th>
                  <th
                    class="resource p-0"
                    style="width: 100%"
                    title="{{
                      'projects.projects.card.resources.movePlanForm.properties.task' | translate
                    }}"
                  >
                    {{
                      'projects.projects.card.resources.movePlanForm.properties.task' | translate
                    }}
                  </th>
                </tr>
              </thead>
            </table>
            <div class="list-scroll-container">
              <table
                #viewTbl
                class="wp-nested-table wp-nested-table-hover data position-relative"
                (resized)="resize($event)"
                style="width: 100%; margin: 0"
              >
                <thead>
                  <tr>
                    <th style="width: 40px"></th>
                    <th style="width: 100%"></th>
                  </tr>
                </thead>
                <tbody>
                  @for (task of taskLines.controls; track task.value.id) {
                    <tr [formGroup]="task">
                      <td class="checkbox">
                        <div class="wp-checkbox">
                          <label>
                            <input type="checkbox" formControlName="isSelected" />
                            <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
                          </label>
                        </div>
                      </td>
                      <td
                        title="{{ task.value.name }}"
                        class="task"
                        [ngStyle]="{ 'padding-left': task.value.indent * 20 + 'px' }"
                      >
                        <div class="trim">{{ task.value.name }}</div>
                      </td>
                    </tr>
                  }
                  @if (!taskLines?.length) {
                    <tr>
                      <td colspan="2" class="text-center">
                        {{ 'shared.noDisplayData' | translate }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div class="col">
            <table class="wp-nested-table wp-nested-table-hover fixed" [ngStyle]="viewTableStyle">
              <thead>
                <tr>
                  <th class="checkbox">
                    <div class="wp-checkbox">
                      <label>
                        <input type="checkbox" [formControl]="allTeamMembersSelector" />
                        <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
                      </label>
                    </div>
                  </th>
                  <th
                    class="resource"
                    style="width: 100%"
                    title="{{
                      'projects.projects.card.resources.movePlanForm.properties.resource'
                        | translate
                    }}"
                  >
                    {{
                      'projects.projects.card.resources.movePlanForm.properties.resource'
                        | translate
                    }}
                  </th>
                </tr>
              </thead>
            </table>
            <div class="list-scroll-container">
              <table
                #viewTbl
                class="wp-nested-table wp-nested-table-hover data position-relative"
                (resized)="resize($event)"
                style="width: 100%; margin: 0"
              >
                <thead>
                  <tr>
                    <th style="width: 40px"></th>
                    <th style="width: 100%"></th>
                  </tr>
                </thead>
                <tbody>
                  @for (teamMember of teamMemberLines.controls; track teamMember.value.id) {
                    <tr [formGroup]="teamMember">
                      <td class="checkbox">
                        <div class="wp-checkbox">
                          <label>
                            <input type="checkbox" formControlName="isSelected" />
                            <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
                          </label>
                        </div>
                      </td>
                      <td title="{{ teamMember.value.name }}" class="resource">
                        <div id="{{ teamMember.value.id }}" class="avatar-cell">
                          @if (teamMember.value.resource) {
                            <img
                              class="avatar-bg"
                              [src]="teamMember.value.resource.id | avatar: 60"
                            />
                            <div class="trim">{{ teamMember.value.name }}</div>
                          }
                          @if (!teamMember.value.resource) {
                            <img src="assets/images/avatar/role.svg" />
                            <div class="trim">{{ teamMember.value.name }}</div>
                          }
                        </div>
                      </td>
                    </tr>
                  }
                  @if (!teamMemberLines.length) {
                    <tr>
                      <td colspan="2" style="text-align: center">
                        {{ 'shared.noDisplayData' | translate }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
      @if (thereIsNoPlan) {
        <div class="text-uppercase text-center text-body-secondary">
          {{ 'projects.projects.card.resources.movePlanForm.thereIsNoPlan' | translate }}
        </div>
      }
    </div>
    <div class="modal-footer d-flex">
      @if (hasSelectedData()) {
        <button
          type="button"
          class="btn btn-primary"
          (click)="ok()"
          wpButtonSpinner
          [isBusy]="isSaving"
        >
          {{ 'projects.projects.card.resources.movePlanForm.actions.move' | translate }}
        </button>
      }
      <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
        {{ 'shared.actions.cancel' | translate }}
      </button>
    </div>
  </form>
}
