<div class="toolbar-container">
  <div class="btn-group" role="group">
    <button class="btn btn-default" (click)="form.disable()">Disable</button>
    <button class="btn btn-default" (click)="form.enable()">Enable</button>
  </div>

  <button class="btn btn-default" (click)="setValue(20)">Set value</button>
</div>

<hr />

<div [formGroup]="form" style="width: 400px">
  <h2>DurationBox Days</h2>
  <tmt-duration-box formControlName="duration"></tmt-duration-box>
  <p>
    Form value:<br />
    <code>
      {{ form.get('duration').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('duration').valid }}</p>
  <br />
  <hr />
</div>

<div [formGroup]="form" style="width: 400px">
  <h2>DurationBox Weeks</h2>
  <tmt-duration-box
    [dimensionItem]="dimensionItems.week"
    formControlName="durationWeeks"
  ></tmt-duration-box>
  <p>
    Form value:<br />
    <code>
      {{ form.get('durationWeeks').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('durationWeeks').valid }}</p>
  <br />
  <hr />
</div>

<div [formGroup]="form" style="width: 400px">
  <h2>DurationBox Months</h2>
  <tmt-duration-box
    [dimensionItem]="dimensionItems.month"
    formControlName="durationMonths"
  ></tmt-duration-box>
  <p>
    Form value:<br />
    <code>
      {{ form.get('durationMonths').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('durationMonths').valid }}</p>
  <br />
  <hr />
</div>

<div [formGroup]="form" style="width: 400px">
  <h2>DurationBox (null is not allowed)</h2>
  <tmt-duration-box [allowNull]="false" formControlName="duration2"></tmt-duration-box>
  <p>
    Form value:<br />
    <code>
      {{ form.get('duration2').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('duration2').valid }}</p>
  <br />
  <hr />
</div>
