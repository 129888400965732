import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Exception } from 'src/app/shared/models/exception';
import { AddingResourceRolesModalComponent } from './adding-resource-roles-modal/adding-resource-roles-modal.component';
import { EntityType } from './enums/entity-type.enum';
import { RoleCompetence } from 'src/app/shared/models/entities/settings/role.model';
import { UserRole } from 'src/app/shared/models/entities/settings/user-role.model';

@Injectable()
export class ResourceRolesService {
  public userId: string;

  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();

  public roles = this.fb.array([]);
  private loadingSubscription: Subscription;

  constructor(
    private data: DataService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private modal: NgbModal,
  ) {}

  private getGroup(): UntypedFormGroup {
    const group = this.fb.group({
      id: Guid.generate(),
      role: null,
      competences: [],
      userRoleCompetences: [],
    });

    return group;
  }

  public reload() {
    this.load();
  }

  public addRoles(resourceType: EntityType) {
    const modalRef = this.modal.open(AddingResourceRolesModalComponent, {
      size: 'lg',
    });
    const instance =
      modalRef.componentInstance as AddingResourceRolesModalComponent;

    instance.entityType = resourceType;
    instance.entityId = this.userId;
    instance.alreadyAddedIds = (this.roles.value as UserRole[]).map(
      (r) => r.role.id,
    );

    modalRef.result.then(
      (newRoles: NamedEntity[]) => {
        newRoles.forEach((role) => {
          const group = this.getGroup();
          group.controls.role.setValue(role);

          this.roles.push(group);
        });

        this.changesSubject.next();
      },
      () => null,
    );
  }

  public delete(index: number) {
    this.roles.removeAt(index);
    this.changesSubject.next();
  }

  public load() {
    this.roles.clear();
    this.isLoadingSubject.next(true);

    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription = this.data
      .collection('Users')
      .entity(this.userId)
      .collection('AdditionalUserRoles')
      .query<UserRole[]>({
        select: ['id'],
        expand: {
          role: {
            select: ['id', 'name', 'description'],
            expand: {
              competences: {
                select: ['id', 'name'],
                filter: { isActive: true },
              },
            },
            orderBy: 'competence/name',
          },
          userRoleCompetences: {
            select: ['id', 'competenceId'],
          },
        },
        orderBy: 'role/name',
      })
      .subscribe({
        next: (userRoles) => {
          userRoles.forEach((userRole: UserRole) => {
            const group = this.getGroup();
            group.patchValue(userRole, { emitEvent: false });
            const competences: RoleCompetence[] = userRole.role.competences;
            const addingCompetences: RoleCompetence[] = [];
            competences.map((item) => {
              const found = userRole.userRoleCompetences?.find(
                (assign) => assign.competenceId === item.id,
              );
              if (found) {
                addingCompetences.push(item);
              }
            });
            group.patchValue({ competences: addingCompetences });
            this.roles.push(group);
          });

          this.isLoadingSubject.next(false);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoadingSubject.next(false);
        },
      });
  }

  public save(): Observable<any> {
    this.roles.markAllAsTouched();

    if (this.roles.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return throwError(() => new Error());
    }

    const data = {
      userRoles: (this.roles.value as UserRole[]).map(
        (userRole: Record<string, any>) => {
          const result: Record<string, any> = {
            id: userRole.id,
            roleId: userRole.role.id,
            userId: this.userId,
          };
          result.userRoleCompetences = [];
          userRole.competences?.map((competence) => {
            result.userRoleCompetences.push({
              competenceId: competence.id,
              userRoleId: userRole.role.id,
            });
          });

          return result;
        },
      ),
    };

    return this.data
      .collection('Users')
      .entity(this.userId)
      .action('UpdateUserRoles')
      .execute(data)
      .pipe(
        catchError((error: Exception) => {
          this.notification.error(error.message);
          return throwError(() => new Error(error.message));
        }),
      );
  }

  /* Detects changes for saving. */
  public detectChanges(): void {
    this.changesSubject.next();
  }
}
