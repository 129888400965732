import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ElementRef,
  ChangeDetectionStrategy,
  HostBinding,
  Inject,
  inject,
  DestroyRef,
  AfterViewInit,
  NgZone,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { asyncScheduler, fromEvent, filter, throttleTime } from 'rxjs';

import { Constants } from 'src/app/shared/globals/constants';
import { CellsOrchestratorService } from 'src/app/shared/services/cell-orhestrator/cells-orchestrator.service';
import { BookingEntry } from 'src/app/shared/models/entities/resources/booking-entry.model';

import { Total } from 'src/app/booking/booking/models/total.model';
import { BookingService } from 'src/app/booking/booking/core/booking.service';
import { BookingDataService } from 'src/app/booking/booking/core/booking-data.service';
import { BookingRenderingService } from 'src/app/booking/booking/core/booking-rendering.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tr[tmt-booking-basic-line-right]',
  templateUrl: './booking-basic-line.component.html',
  styleUrls: ['./booking-basic-line.component.scss'],
  providers: [CellsOrchestratorService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingBasicLineRightComponent implements OnInit, AfterViewInit {
  @HostBinding('class') protected hostClass: string;

  @Input() public resourceId: string;
  @Input() public totals: Total[] = [];

  public height: number;
  public formLines: UntypedFormArray = this.fb.array([]);

  private readonly destroyRef = inject(DestroyRef);

  public get bookings(): BookingEntry[] {
    return this.dataService.getResourceBookings(this.resourceId);
  }

  constructor(
    public bookingService: BookingService,
    public dataService: BookingDataService,
    public bookingRenderingService: BookingRenderingService,
    public el: ElementRef,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private zone: NgZone,
  ) {}

  public ngOnInit(): void {
    this.hostClass = 'line';

    this.bookingService.detectChanges$
      .pipe(
        filter((id) => id === this.resourceId),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdr.detectChanges();
      });

    this.bookingRenderingService?.groupBoardHeight$
      .pipe(
        filter((params) => params.id === this.resourceId),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((params) => {
        this.height = params.height;
        this.cdr.detectChanges();
      });
  }

  public ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      fromEvent(this.document, 'mousemove')
        .pipe(
          throttleTime(Constants.mousemoveThrottleTime, asyncScheduler, {
            leading: true,
            trailing: true,
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((e: MouseEvent) => {
          const rect = this.el.nativeElement.getBoundingClientRect();

          if (
            rect &&
            e.clientX >= rect.left &&
            e.clientX <= rect.right &&
            e.clientY >= rect.top &&
            e.clientY <= rect.bottom
          ) {
            this.bookingRenderingService.mouseOverGroup(this.resourceId);
          }
        });
    });
  }

  /**
   * Emit `startCreate` event for line in based mode.
   *
   * @param event MouseEvent.
   *
   * */
  public startCreate(event: MouseEvent): void {
    if (event.button === 0 && !this.dataService.isReadonlyMode()) {
      this.bookingRenderingService.startCreate(this.resourceId, event);
    }
  }
}
