import { Component, Input } from '@angular/core';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { GridCommandColumn } from 'src/app/shared/models/inner/grid-column.interface';

@Component({
  selector: 'wp-command-cell',
  templateUrl: './command-cell.component.html',
  styleUrls: ['./command-cell.component.scss'],
})
export class CommandCellComponent {
  @Input() row: any;
  @Input() column: GridCommandColumn;

  constructor(public service: GridService) {}

  /**Выполнить команду */
  executeCommand() {
    /** Нулевой таймаут для того, чтобы кинуть выполнение в конец очереди(после события выделения строки) */
    setTimeout(() => this.service.execute(this.column.command), 0);
  }
}
