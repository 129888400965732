import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { InvoicesModule } from './invoices/invoices.module';
import { BillingSummaryModule } from './billing-summary/billing-summary.module';

@NgModule({
  imports: [CommonModule, SharedModule, InvoicesModule, BillingSummaryModule],
})
export class BillingModule {}
