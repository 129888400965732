import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { PredecessorBoxComponent } from 'src/app/projects/card/shared/predecessor-box/predecessor-box.component';
import { GridComponentCell } from 'src/app/shared-features/grid2/models/grid-component-cell.interface';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';

@Component({
  selector: 'tmt-task-predecessor-cell',
  styleUrls: ['./task-predecessor-cell.component.scss'],
  templateUrl: './task-predecessor-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskPredecessorCellComponent
  implements GridComponentCell, OnInit, OnDestroy
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  @ViewChild('predecessorBox') predecessorBox: PredecessorBoxComponent;

  public readonly: boolean;
  public taskId: string;
  public tasks: ProjectTask[];
  private destroyed$ = new Subject<void>();

  public get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  constructor(
    public projectTasksDataService: ProjectTasksDataService,
    public renderer: Renderer2,
    public service: GridService,
    public cdr: ChangeDetectorRef,
    public elRef: ElementRef,
  ) {}

  public ngOnInit(): void {
    this.taskId = this.formGroup.getRawValue()?.id;
    this.tasks = this.projectTasksDataService.tasks;

    this.service.detectChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
