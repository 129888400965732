import { Component, Input, ChangeDetectorRef } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { GridColumnType } from 'src/app/shared/models/inner/grid-column.interface';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';

@Component({
  selector: 'wp-validation-rules-section',
  templateUrl: './validation-rules-section.component.html',
  providers: [GridService],
})
export class ValidationRulesSectionComponent {
  @Input() formArray: UntypedFormArray;
  @Input() readonly: boolean;

  public rules: NamedEntity[] = [];
  public loading = false;

  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    clientTotals: false,
    commands: [
      {
        name: 'add',
        handlerFn: (validationRule: NamedEntity) =>
          this.addValidationRule(validationRule),
        allowedFn: () => !this.readonly,
      },
      {
        name: 'delete',
        handlerFn: (id) => null,
        allowedFn: (id) => id && !this.readonly,
      },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.formArray.removeAt(index);
          this.formArray.parent.markAsDirty();
        },
      },
    ],
    view: {
      name: 'validationRules',
      columns: [
        {
          name: 'name',
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.String,
          width: '100%',
        },
      ],
    },
  };

  constructor(
    private fb: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    public service: GridService,
    public notification: NotificationService,
    private data: DataService,
  ) {}

  openChange($event: boolean) {
    if (!$event) {
      return;
    }

    this.loading = true;
    this.rules = [];
    const params = {
      filter: { isActive: true },
      select: ['name', 'id'],
      orderBy: ['name'],
    };

    this.data
      .collection('ValidationRules')
      .query(params)
      .subscribe({
        next: (validationRules: NamedEntity[]) => {
          const currentRules = this.formArray.value as NamedEntity[];

          validationRules.forEach((rule: NamedEntity) => {
            if (
              currentRules.every(
                (currentRule: any) => currentRule.id !== rule.id,
              )
            ) {
              this.rules.push(rule);
            }
          });

          this.loading = false;
          this.ref.detectChanges();
        },
        error: (error: any) => {
          this.loading = false;
          this.notification.error(error.message);
          this.ref.detectChanges();
        },
      });
  }

  selectActivity(activity: NamedEntity) {
    this.service.execute('add', activity);
  }

  public addValidationRule(validationRule: NamedEntity) {
    this.formArray.push(
      this.fb.group({
        id: validationRule.id,
        name: validationRule.name,
      }),
    );
  }
}
