import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class CommentsSettings implements BaseSettings {
  public readonly name = 'CommentsSettings';
  public version: number;
  public showSystemEvents: boolean;

  public getDefault(): CommentsSettings {
    return {
      version: 1,
      showSystemEvents: true,
    } as CommentsSettings;
  }
}
