import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { UserInfoSettings } from './user-info.settings';
import { InfoPopupService } from '../info-popup/info-popup.service';
import { UserInfoComponentParams } from './user-info.interface';
import { firstValueFrom, map } from 'rxjs';

/** Сведения о пользователе (вплывающее окно). */
@Component({
  selector: 'wp-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  @Input() params: UserInfoComponentParams;

  public notFound: boolean;
  public user: User;
  public isLoading: boolean;
  public isShown: boolean;
  public containerId: string = Guid.generate();

  public skills = '';

  public settings: UserInfoSettings;

  constructor(
    private dataService: DataService,
    private appService: AppService,
    private notification: NotificationService,
    private cdr: ChangeDetectorRef,
    private localConfigService: LocalConfigService,
    private infoPopupService: InfoPopupService,
  ) {}

  private open(): void {
    this.load();
    this.isShown = true;
    this.cdr.markForCheck();
  }

  public close = () => {
    this.infoPopupService.close();
  };

  private async load(): Promise<void> {
    try {
      this.notFound = false;
      this.isLoading = true;

      const query: any = {
        expand: [
          {
            location: { select: 'name' },
            level: { select: 'name' },
            department: { select: 'name' },
            supervisor: { select: 'name' },
            role: { select: 'name' },
            competence: { select: 'name' },
            legalEntity: { select: 'name' },
            resourcePool: {
              select: 'name',
            },
            skills: { expand: { skill: { select: 'name' } } },
          },
        ],
        select: ['id', 'name', 'phone', 'email', 'position'],
        filter: [],
      };

      let user: User;

      if (this.params.userId) {
        query.expand.supervisors = {
          filter: {
            eq: { type: 'guid', value: this.appService.session.user.id },
          },
        };

        user = await firstValueFrom(
          this.dataService
            .collection('Users')
            .entity(this.params.userId)
            .get<User>(query),
        );
      } else {
        query.filter.push({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'tolower(email)': {
            contains:
              `${this.params.nickname.slice(1)}@${this.appService.session.configuration.primaryDomain}`.toLowerCase(),
          },
        });

        user = await firstValueFrom(
          this.dataService
            .collection('Users')
            .query<User[]>(query)
            .pipe(map((users) => users[0])),
        );
      }

      if (!user) {
        this.notFound = true;
        this.isLoading = false;
        this.cdr.markForCheck();
        return;
      }

      this.user = user;

      const skills = user.skills.map((ra) => ra.skill.name) as string[];
      const skillsCount = skills.length;
      this.skills = skills.slice(0, 5).join(', ');
      if (skillsCount > 5) {
        this.skills += ` (+${skillsCount - 5})`;
      }

      this.isLoading = false;
      this.cdr.markForCheck();
      this.infoPopupService.update();
    } catch (error) {
      if (error.code === Exception.BtEntityNotFoundException.code) {
        this.notFound = true;
      } else {
        this.notification.error(error.message);
      }
    }
  }

  public switch() {
    this.settings.collapsed = !this.settings.collapsed;
    this.localConfigService.setConfig(UserInfoSettings, this.settings);
    this.cdr.markForCheck();
    this.infoPopupService.update();
  }

  ngOnInit() {
    this.settings = this.localConfigService.getConfig(UserInfoSettings);
    this.open();
  }
}
