import { DOCUMENT } from '@angular/common';
import {
  Directive,
  Input,
  OnInit,
  OnDestroy,
  Inject,
  Renderer2,
  ElementRef,
  NgZone,
  inject,
  DestroyRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { fromEvent } from 'rxjs';

import { LocalConfigService } from 'src/app/core/local-config.service';
import { OffCanvasService } from 'src/app/core/off-canvas.service';
import { OffCanvasSettings } from '../models/off-canvas/off-canvas.settings';
import { InfoPopupService } from '../components/features/info-popup/info-popup.service';

@Directive({
  selector: '[tmtOffCanvasResize]',
})
export class OffCanvasResizeDirective implements OnInit, OnDestroy {
  @Input('element') private element: HTMLElement;
  private grabber = false;
  private minWidth = 600;
  private maxWidth = 1200;
  private currentWidth: number;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private localConfigService: LocalConfigService,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private zone: NgZone,
    private offCanvasService: OffCanvasService,
    private infoPopupService: InfoPopupService,
  ) {}

  ngOnInit(): void {
    const settings = this.localConfigService.getConfig(OffCanvasSettings);
    this.currentWidth = settings.width || this.minWidth;
    this.renderer.setStyle(this.element, 'width', `${this.currentWidth}px`);

    this.zone.runOutsideAngular(() => {
      fromEvent(this.elementRef.nativeElement, 'mousedown')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.grabber = true;
          this.renderer.setStyle(this.document.body, 'cursor', 'e-resize');
          this.renderer.setStyle(this.document.body, 'user-select', 'none');
          this.infoPopupService.close();
        });

      fromEvent(this.document.body, 'mouseup')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.grabber = false;
          this.renderer.removeStyle(this.document.body, 'cursor');
          this.renderer.removeStyle(this.document.body, 'user-select');
          this.offCanvasService.setWidth(this.currentWidth);
          this.saveWidth();
        });

      fromEvent(this.document.body, 'mousemove')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((event: MouseEvent) => {
          if (!this.grabber) {
            return;
          }
          let width = window.innerWidth - event.clientX;
          if (this.grabber) {
            width = Math.min(Math.max(width, this.minWidth), this.maxWidth);
            this.renderer.setStyle(this.element, 'width', `${width}px`);
          }
          this.currentWidth = width;
        });
    });
  }

  ngOnDestroy(): void {
    this.saveWidth();
  }

  /* Saves off canvas's width. */
  private saveWidth(): void {
    const settings = this.localConfigService.getConfig(OffCanvasSettings);
    settings.width = this.currentWidth;
    this.localConfigService.setConfig(OffCanvasSettings, settings);
  }
}
