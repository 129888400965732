import { Component, OnInit, OnDestroy } from '@angular/core';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SettingsCardService } from '../settings-card.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import { GridColumnType } from 'src/app/shared/models/inner/grid-column.interface';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { MessageService } from 'src/app/core/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomainAddingComponent } from './domain-adding/domain-adding.component';
import { DomainConfirmationComponent } from './domain-confirmation/domain-confirmation.component';
import { TenantDomain } from 'src/app/shared/models/entities/settings/multitenant/tenant-domain.model';

@Component({
  selector: 'wp-settings-domains',
  templateUrl: './settings-domains.component.html',
  providers: [GridService],
})
export class SettingsDomainsComponent implements OnInit, OnDestroy {
  public state: CardState;
  public isSaving = false;

  public readonly = !this.app.checkPermission(
    'ManageAccount',
    null,
    PermissionType.Execute,
  );

  public domains = this.fb.array([]);
  private reloadSubscription: Subscription;

  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    toolbar: null,
    rowCommands: [
      {
        name: 'confirm',
        label: 'settings.settings.domains.actions.confirm',
        allowedFn: (formGroup: UntypedFormGroup, index: number) =>
          !this.readonly && !formGroup.value.isConfirmed,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.confirmDomain(formGroup.value.domainName);
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (formGroup: UntypedFormGroup, index: number) =>
          !this.readonly && !formGroup.value.isPrimary,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.deleteDomain(formGroup.value.domainName);
        },
      },
    ],
    commands: [],
    view: {
      name: 'domains',
      columns: [
        {
          name: 'domainName',
          header: 'shared.props.name',
          hint: 'shared.props.name',
          type: GridColumnType.String,
          width: '100%',
        },
        {
          name: 'isPrimary',
          header: 'settings.settings.domains.props.isPrimary',
          hint: 'settings.settings.domains.props.isPrimary',
          type: GridColumnType.Boolean,
          width: '130px',
        },
        {
          name: 'isConfirmed',
          header: 'settings.settings.domains.props.isConfirmed',
          hint: 'settings.settings.domains.props.isConfirmed',
          type: GridColumnType.Boolean,
          width: '130px',
        },
      ],
    },
  };

  constructor(
    private app: AppService,
    private service: SettingsCardService,
    private messages: MessageService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private modalService: NgbModal,
  ) {}

  private load() {
    this.state = CardState.Loading;

    this.data
      .collection('TenantDomains')
      .query<TenantDomain[]>()
      .subscribe({
        next: (domains) => {
          this.domains.clear();
          domains.forEach((domain) => {
            this.domains.push(
              this.fb.group({
                id: domain.domainName,
                domainName: domain.domainName,
                isPrimary: domain.isPrimary,
                isConfirmed: domain.isConfirmed,
              }),
            );
          });

          this.state = CardState.Ready;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.state = CardState.Error;
        },
      });
  }

  private deleteDomain(domainName: string) {
    this.messages
      .confirmLocal('settings.settings.domains.messages.deleteConfirmation')
      .then(
        () => {
          this.data
            .collection('TenantDomains')
            .entity(`'${domainName}'`)
            .delete()
            .subscribe({
              next: () => {
                this.notification.successLocal(
                  'settings.settings.domains.messages.domainDeleted',
                );
                this.load();
              },
              error: (error: Exception) => {
                this.notification.error(error.message);
              },
            });
        },
        () => null,
      );
  }

  public addDomain() {
    const instance = this.modalService.open(DomainAddingComponent);
    instance.result.then(
      () => {
        this.load();
      },
      () => null,
    );
  }

  public confirmDomain(domainName: string) {
    const instance = this.modalService.open(DomainConfirmationComponent);
    (instance.componentInstance as DomainConfirmationComponent).domainName =
      domainName;
  }

  ngOnInit() {
    this.load();

    this.reloadSubscription = this.service.reloadTab$.subscribe(() =>
      this.load(),
    );
  }

  ngOnDestroy(): void {
    this.reloadSubscription.unsubscribe();
  }
}
