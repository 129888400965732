import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScheduleNavigationModule } from 'src/app/shared-features/schedule-navigation/schedule-navigation.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectSummaryFilterModule } from 'src/app/project-summary/shared/project-summary-filter/project-summary-filter.module';
import { ProjectSummaryToolbarComponent } from 'src/app/project-summary/shared/project-summary-toolbar/project-summary-toolbar.component';
import { ProjectSummaryViewSettingsComponent } from 'src/app/project-summary/shared/project-summary-view-settings/project-summary-view-settings.component';
import { ProjectSummaryLeftGroupComponent } from 'src/app/project-summary/shared/project-summary-left-group/project-summary-left-group.component';
import { ProjectSummaryRightGroupComponent } from 'src/app/project-summary/shared/project-summary-right-group/project-summary-right-group.component';
import { PlannerModule } from 'src/app/shared-features/planner/planner.module';
import { UIRouterModule } from '@uirouter/angular';
import { ProjectSummaryTotalPipe } from 'src/app/project-summary/shared/pipes/project-summary-total.pipe';
import { ProjectSummaryComponent } from 'src/app/project-summary/project-summary.component';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';

@NgModule({
  declarations: [
    ProjectSummaryToolbarComponent,
    ProjectSummaryViewSettingsComponent,
    ProjectSummaryComponent,
    ProjectSummaryLeftGroupComponent,
    ProjectSummaryRightGroupComponent,
    ProjectSummaryTotalPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ScheduleNavigationModule,
    ProjectSummaryFilterModule,
    PlannerModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'projectsSummary',
          url: '/projects-summary?{navigation}&{route}',
          component: ProjectSummaryComponent,
          params: {
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
        },
      ],
    }),
  ],
})
export class ProjectSummaryModule {}
