import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { RoleName } from 'src/app/shared/models/enums/role-name.enum';
import { ProjectTaskInfo } from './task-info.model';
import { InfoPopupService } from '../info-popup/info-popup.service';

@Component({
  selector: 'wp-task-info',
  templateUrl: './task-info.component.html',
  styleUrls: ['./task-info.component.scss'],
})
export class TaskInfoComponent implements OnInit {
  @Input() params: any;

  public taskInfo: ProjectTaskInfo;
  public isLoading: boolean;
  public container: HTMLElement;
  public notFound: boolean;
  public boxCss: string;

  public hoursProgress = 0;
  public hoursProgressType = '';

  constructor(
    private data: DataService,
    private app: AppService,
    private notification: NotificationService,
    private changeDetector: ChangeDetectorRef,
    private infoPopupService: InfoPopupService,
  ) {}

  private open(taskId: string, timesheetId: string) {
    this.load(timesheetId, taskId);
    this.changeDetector.detectChanges();
  }

  private load(timesheetId: string, taskId: string) {
    this.isLoading = true;
    this.notFound = false;

    this.data
      .collection('TimeSheets')
      .entity(timesheetId)
      .function('WP.GetTaskInfo')
      .get<ProjectTaskInfo>({ taskId })
      .subscribe({
        next: (taskInfo) => {
          this.taskInfo = taskInfo;

          this.hoursProgress =
            this.taskInfo.plannedHours > 0
              ? this.taskInfo.actualHours / this.taskInfo.plannedHours
              : 0;
          this.hoursProgressType = this.hoursProgress > 1 ? 'danger' : 'info';

          this.isLoading = false;
          this.changeDetector.detectChanges();
          this.infoPopupService.update();
        },
        error: (error: Exception) => {
          this.isLoading = false;
          if (error.code === Exception.BtEntityNotFoundException.code) {
            this.notFound = true;
          } else {
            this.notification.error(error.message);
          }
          this.changeDetector.detectChanges();
          this.infoPopupService.update();
        },
      });
  }

  public hasProjectRole = () => this.app.checkRole(RoleName.ProjectManager);

  ngOnInit() {
    this.open(this.params.taskId, this.params.timesheetId);
  }
}
