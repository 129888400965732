import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FileExtension } from 'src/app/shared-features/comments/model/file-extension.enum';
import {
  CommentsService,
  FileWithId,
} from 'src/app/shared-features/comments/core/comments.service';
import { saveAs } from 'file-saver';
import { Attachment } from 'src/app/shared-features/comments/model/attachment.model';
import { AttachmentsManagerService } from '../../core/attachments-manager.service';

@Component({
  selector: 'tmt-comments-attachment',
  templateUrl: './comments-attachment.component.html',
  styleUrls: ['./comments-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsAttachmentComponent {
  @Output() public onClick$ = new EventEmitter<void>();

  @Input() public canEdit = true;
  @Input() public attachment: Attachment;
  @Input() public file: FileWithId;
  @Input() public set fileName(value: string) {
    this.name = value;
    this.shortName =
      value.length > this.maxNameLength
        ? this.sliceFileName(value, this.maxNameLength)
        : value;
  }
  @Input() public set fileExt(val: string) {
    this._fileExt = Object.keys(FileExtension).includes(val) ? val : 'default';
  }
  public get fileExt(): string {
    return this._fileExt;
  }
  private _fileExt = 'default';

  public loading = false;
  public shortName = '';
  public name: string;
  public fileExtension = FileExtension;

  private maxNameLength = 50;

  constructor(
    public attachmentsManager: AttachmentsManagerService,
    public commentsService: CommentsService,
    private cdr: ChangeDetectorRef,
  ) {}

  public async download(): Promise<void> {
    if (this.loading) {
      return;
    }

    if (!this.attachment) {
      saveAs(this.file, this.file.name);
      return;
    }

    this.loading = true;
    await this.attachmentsManager.downloadAttachment(this.attachment);
    this.loading = false;
    this.cdr.markForCheck();
  }

  /** Deletes comment attachment  */
  public delete(): void {
    if (!this.attachment) {
      this.commentsService.deleteCurrentAttachment(this.file);
      return;
    }

    this.attachmentsManager.deleteAttachment(this.attachment);
  }

  public click(): void {
    this.onClick$.emit();
  }

  private sliceFileName(
    fileName: string,
    resultLength: number = this.maxNameLength,
  ): string {
    return (
      fileName.slice(0, resultLength / 2).trim() +
      '...' +
      fileName.slice(-resultLength / 2).trim()
    );
  }
}
