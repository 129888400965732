@if (!(gridService.readonly$ | async)) {
  <div class="toolbar-container">
    <div class="group">
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.create' | translate }}"
        (click)="gridService.execute('create')"
      >
        <i class="bi bi-plus-lg bi-15"></i>
        {{ 'shared.actions.create' | translate }}
      </button>

      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.edit' | translate }}"
        [disabled]="!gridService.canBeExecuted('edit', gridService.selectedGroupValue?.id)"
        (click)="gridService.execute('edit', gridService.selectedGroup$.getValue())"
      >
        {{ 'shared.actions.edit' | translate }}
      </button>

      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.delete' | translate }}"
        [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroupValue?.id)"
        (click)="gridService.execute('delete', gridService.selectedGroup$.getValue())"
      >
        <i class="bi bi-trash3 bi-15"></i>
      </button>
    </div>
  </div>
}
