import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { Order } from 'src/app/shared/models/inner/order';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Toolbar } from 'src/app/shared/components/features/grid/toolbar';
import { Command } from 'src/app/shared/components/features/grid/grid-options.model';
import { MenuService, MenuItem } from 'src/app/core/menu.service';

/** Сервис управления гридом. */
@Injectable()
export class GridService {
  /** Режим множественного выбора. */
  public multiSelect: boolean;

  private _selectedGroups: UntypedFormGroup[] = [];

  /** Выбранные группы. */
  public get selectedGroups(): UntypedFormGroup[] {
    return this._selectedGroups;
  }

  /** Выбранная группа. */
  public get selectedGroup(): UntypedFormGroup {
    return this._selectedGroups[0];
  }

  /** Выбранная строка. */
  public get selectedRow(): any {
    return this.selectedGroup?.value;
  }

  /** Выбранные строки. */
  public get selectedRows(): any[] {
    return this._selectedGroups.map((r) => r.value);
  }

  private selectedRowSubject = new BehaviorSubject<any>(null);

  /** События выделения строки. */
  public selectedRow$ = this.selectedRowSubject.asObservable();

  private selectedRowsSubject = new BehaviorSubject<any[]>([]);

  /** События выделения строк. */
  public selectedRows$ = this.selectedRowsSubject.asObservable();

  private allSelectedSubject = new BehaviorSubject<boolean>(false);

  /** События выбора всех строк. */
  public allSelected$ = this.allSelectedSubject.asObservable();
  public allSelected: boolean;

  private _order: Order;
  public get order(): Order {
    return this._order;
  }
  private orderSubject = new Subject<Order>();

  /** События изменения сортировки. */
  public order$ = this.orderSubject.asObservable();

  private loadingSubject = new BehaviorSubject<boolean>(false);

  /** События статуса загрузки грида. */
  public loading$ = this.loadingSubject.asObservable();

  private readonlySubject = new BehaviorSubject<boolean>(false);
  public readonly$ = this.readonlySubject.asObservable();
  public readonly: boolean;

  private commandSubject = new Subject<string>();
  public command$ = this.commandSubject.asObservable();

  public detectChanges$ = new Subject<void>();
  public toolbar$ = new Subject<Toolbar>();

  public commands: Command[] = [];

  constructor(private menuService: MenuService) {}

  /** Запустить обнаружение изменений. */
  public detectChanges() {
    this.detectChanges$.next();
  }

  /** Изменить состояние "только чтение".**/
  public setReadonlyState(value: boolean) {
    this.readonlySubject.next(value);
    this.readonly = value;
  }

  /** Изменить состояние загрузки. */
  public setLoadingState(value: boolean) {
    this.loadingSubject.next(value);
  }

  /** Выбрать группу (выделить строку).
   * Работает как переключатель, с остальных строк выделение снимается.
   */
  public selectGroup(group: UntypedFormGroup) {
    // Не применять, если ничего не изменилось.
    if (
      group &&
      this.selectedGroup &&
      group.value.id === this.selectedGroup.value.id
    ) {
      return;
    }

    // Если группа добавлена только что - применить изменение.
    this.detectChanges();

    this._selectedGroups = [];

    if (group) {
      this._selectedGroups.push(group);
    }

    this.selectedRowSubject.next(this.selectedRow);
    this.selectedRowsSubject.next(this.selectedRows);

    // Еще раз отследить изменения для обновления UI, зависящего от активной строки.
    this.detectChanges();
  }

  /** Добавить группу в выбранные.
   * Игнорируется для режима multiSelect.
   */
  public addGroupToSelected(group: UntypedFormGroup) {
    if (!this.multiSelect) {
      return;
    }
    if (!this.selectedGroups.find((r) => r.value.id === group.value.id)) {
      this.selectedGroups.push(group);
    }

    this.selectedRowSubject.next(this.selectedRow);
    this.selectedRowsSubject.next(this.selectedRows);
  }

  public removeGroupFromSelected(group: UntypedFormGroup) {
    this._selectedGroups = this.selectedGroups.filter(
      (r) => r.value.id !== group.value.id,
    );

    this.selectedRowSubject.next(this.selectedRow);
    this.selectedRowsSubject.next(this.selectedRows);
  }

  public setSelectedAll(state: boolean) {
    this.allSelected = state;
    this.allSelectedSubject.next(state);
  }

  public clearSelected() {
    if (!this.multiSelect) {
      return;
    }

    this._selectedGroups = [];
    this.selectedRowSubject.next(this.selectedRow);
    this.selectedRowsSubject.next(this.selectedRows);
    this.allSelectedSubject.next(false);
  }

  public addGroupsToSelected(groups: UntypedFormGroup[]) {
    if (!this.multiSelect) {
      return;
    }
    this._selectedGroups = groups;
    this.selectedRowSubject.next(this.selectedRow);
    this.selectedRowsSubject.next(this.selectedRows);
  }

  public toggleSelectedGroup(group: UntypedFormGroup) {
    if (this.selectedGroups.find((r) => r.value.id === group.value.id)) {
      this.removeGroupFromSelected(group);
    } else {
      this.addGroupToSelected(group);
    }
  }

  /** Выполнение сортировки. */
  public sort(column: GridColumn) {
    if (this._order.column === column.name) {
      if (!this._order.reverse) {
        this._order.reverse = true;
      } else {
        this._order.column = undefined;
      }
    } else {
      this._order.column = column.name;
      this._order.reverse = false;
    }

    this.orderSubject.next(this._order);
  }

  /** Установка порядка сортировки (инициализация). */
  public setOrder(order: Order) {
    this._order = order;
  }

  public getFormGroupForRow(row: any, columns: GridColumn[]): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(row.id),
    });

    columns.forEach((column: GridColumn) => {
      formGroup.addControl(
        column.name,
        new UntypedFormControl(row[column.name]),
      );
    });

    return formGroup;
  }

  /** Выполнение команды. */
  public execute(name: string, param?: any) {
    this.commandSubject.next(name);

    const command = this.commands?.find((c: Command) => c.name === name);
    if (command && command.handlerFn) {
      command.handlerFn(param);
    }
  }

  /** Проверка возможности выполнения команды. */
  public canBeExecuted(name: string, param?: any): boolean {
    const command = this.commands.find((c: Command) => c.name === name);
    if (command && command.allowedFn) {
      return command.allowedFn(param);
    }
    return true;
  }

  /** Возвращает команду для настройки. */
  public getCommand(name: string): Command {
    const command = this.commands.find((c: Command) => c.name === name);
    return command;
  }

  /** Open context menu. */
  public openContextMenu(
    event: MouseEvent,
    formGroup: UntypedFormGroup,
    rowContextMenuItems: MenuItem[],
  ) {
    this.menuService.open(event, rowContextMenuItems, formGroup);
    event.preventDefault();
    event.stopPropagation();
  }
}
