import { ProjectVersionMergeEnum } from 'src/app/projects/project-versions/project-versions-merge-modal/models/project-version-merge-enum.model';
import { Guid } from 'src/app/shared/helpers/guid';

/** Represents the Project version merge operation type. */
export class ProjectVersionMergeType extends ProjectVersionMergeEnum {
  /** The Replace merge type. */
  public static get replace(): ProjectVersionMergeType {
    return {
      id: Guid.generate(),
      code: 'Replace',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.paramSelection.type.replace',
    };
  }

  /** The Add merge type. */
  public static get add(): ProjectVersionMergeType {
    return {
      id: Guid.generate(),
      code: 'Add',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.paramSelection.type.add',
    };
  }
}

export const ProjectVersionMergeTypes: ProjectVersionMergeType[] = [
  ProjectVersionMergeType.replace,
  ProjectVersionMergeType.add,
];
