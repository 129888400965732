import { Injectable } from '@angular/core';
import { GridColumnType } from 'src/app/shared-features/grid2/models/grid-column.interface';

/** Service for default column value management. */
@Injectable()
export class ColumnDefaultValueService {
  /**
   * Returns default value for target grid column type.
   *
   * @param type column type.
   * @returns default value.
   */
  public getDefaultValue(type: GridColumnType): unknown {
    switch (type) {
      case GridColumnType.String:
      case GridColumnType.StringControl:
        return '';
      case GridColumnType.Boolean:
      case GridColumnType.BooleanControl:
        return false;
      default:
        return null;
    }
  }
}
