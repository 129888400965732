<ng-container [formGroup]="form">
  <span class="text-body-secondary">{{
    'settings.validationRules.card.props.linesCountHelp' | translate
  }}</span>
  <br />
  <br />
  <div class="form-group">
    <label>{{ 'settings.validationRules.card.props.minimumCountLinesCount' | translate }}</label>
    <wp-number-box type="integer" formControlName="MinimumCount" [allowNull]="false" [min]="0">
    </wp-number-box>
  </div>
</ng-container>
