import { Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { toggleMark } from 'prosemirror-commands';
import { Command, Plugin } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

export const menuBar = (renderer: Renderer2, translate: TranslateService) =>
  new Plugin({
    view(editorView) {
      const menuView = new MenuView(editorView, renderer, translate);
      editorView.dom.parentNode.insertBefore(menuView.dom, editorView.dom);
      return menuView;
    },
  });

export const tagMark = (tag: string): any => ({
  [tag]: {
    parseDOM: [{ tag }],
    toDOM: () => [tag, 0],
  },
});

export const markHotKey = (view: EditorView, tag: string): boolean => {
  toggleMark(view.state.schema.marks[tag])(view.state, view.dispatch);
  return true;
};

export const additionalMarks: Record<string, MarkItem> = {
  // Strikethrough
  strike: {
    open: '~~',
    close: '~~',
    markerCode: 0x7e,
  },
  // Underline
  ins: {
    open: '++',
    close: '++',
    markerCode: 0x2b,
  },
  // Monospaced
  samp: {
    open: '##',
    close: '##',
    markerCode: 0x23,
  },
};

class MenuView {
  public dom: HTMLElement;

  private editorView: EditorView;
  private items: MenuItem[];
  private renderer: Renderer2;

  constructor(
    editorView: EditorView,
    renderer: Renderer2,
    translateService: TranslateService,
  ) {
    this.editorView = editorView;
    this.renderer = renderer;
    this.items = [
      {
        command: toggleMark(this.editorView.state.schema.marks.strong),
        dom: this.createMenuItem(
          'strong',
          translateService.instant(
            'components.richEditorBoxComponent.props.bold',
          ),
          'bi-type-bold',
          '',
          'Ctrl + B',
        ),
      },
      {
        command: toggleMark(this.editorView.state.schema.marks.em),
        dom: this.createMenuItem(
          'em',
          translateService.instant(
            'components.richEditorBoxComponent.props.italic',
          ),
          'bi-type-italic',
          '',
          'Ctrl + I',
        ),
      },
      {
        command: toggleMark(this.editorView.state.schema.marks.strike),
        dom: this.createMenuItem(
          'strike',
          translateService.instant(
            'components.richEditorBoxComponent.props.strikethrough',
          ),
          'bi-type-strikethrough',
          '',
          'Ctrl + Shift + S',
        ),
      },
      {
        command: toggleMark(this.editorView.state.schema.marks.ins),
        dom: this.createMenuItem(
          'ins',
          translateService.instant(
            'components.richEditorBoxComponent.props.underline',
          ),
          'bi-type-underline',
          '',
          'Ctrl + U',
        ),
      },
      {
        command: toggleMark(this.editorView.state.schema.marks.samp),
        dom: this.createMenuItem(
          'samp',
          translateService.instant(
            'components.richEditorBoxComponent.props.monospace',
          ),
          '',
          'M',
          '',
        ),
      },
    ];

    this.dom = this.renderer.createElement('div');
    this.renderer.addClass(this.dom, 'menubar');
    this.items.forEach(({ dom }) => this.dom.appendChild(dom));
    this.update();

    this.dom.addEventListener('mousedown', (e) => {
      e.preventDefault();
      this.editorView.focus();
      this.items.forEach(({ command, dom }) => {
        if (dom.contains(e.target as HTMLElement)) {
          dom.classList.toggle('active');
          command(
            this.editorView.state,
            this.editorView.dispatch,
            this.editorView,
          );
        }
      });
    });
  }

  /** Updates menu on editor view state changed. */
  public update(): void {
    this.toggleMenuItems();
  }

  /** Destroys menu. */
  public destroy(): void {
    this.dom.remove();
  }

  /**
   * Creates menu item dom element.
   * @param tag html tag name.
   * @param title menu item title.
   * @param className icon class name.
   * @param label menu item label.
   * @param hotkey key combination for activate item.
   * @returns menu item icon element.
   */
  private createMenuItem(
    tag: string,
    title: string,
    className: string,
    label: string,
    hotkey: string,
  ): HTMLElement {
    const menuItem = this.renderer.createElement('div');
    this.renderer.setAttribute(menuItem, 'id', tag);
    this.renderer.addClass(menuItem, 'menubar-item');
    this.renderer.setProperty(menuItem, 'title', `${title} ${hotkey}`);
    let icon: any;
    if (className) {
      icon = this.renderer.createElement('i');
      this.renderer.addClass(icon, 'bi');
      this.renderer.addClass(icon, className);
    }
    if (label) {
      icon = this.renderer.createElement(tag);
      this.renderer.setProperty(icon, 'textContent', label);
    }
    this.renderer.appendChild(menuItem, icon);
    return menuItem;
  }

  /** Toggles menu items active class. */
  private toggleMenuItems(): void {
    this.items.forEach((item) => {
      const { from, $from, to, empty } = this.editorView.state.selection;
      let active = false;
      if (empty) {
        active = !!this.editorView.state.schema.marks[
          item.dom.getAttribute('id')
        ].isInSet(this.editorView.state.storedMarks || $from.marks());
      } else {
        active = this.editorView.state.doc.rangeHasMark(
          from,
          to,
          this.editorView.state.schema.marks[item.dom.getAttribute('id')],
        );
      }
      active
        ? item.dom.classList.add('active')
        : item.dom.classList.remove('active');
    });
  }
}

interface MenuItem {
  /** Menu item command. */
  command: Command;
  /** DOM element. */
  dom: HTMLElement;
}

export interface MarkItem {
  /** Mark open symbols. */
  open: string;
  /** Mark close symbols. */
  close: string;
  /** Mark char code. */
  markerCode: number;
}
