<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 col-lg-5" style="padding-top: 15px">
      <p>
        {{ 'settings.settings.dad.string1' | translate }}
      </p>
      <ul>
        <li>{{ 'settings.settings.dad.string2' | translate }}</li>
        <li>{{ 'settings.settings.dad.string3' | translate }}</li>
        <li>{{ 'settings.settings.dad.string4' | translate }}</li>
      </ul>

      <p>
        {{ 'settings.settings.dad.string5' | translate }}&nbsp;
        <input
          class="form-control d-inline"
          type="text"
          [(ngModel)]="confirmation"
          style="width: 200px"
        />
      </p>
    </div>
  </div>
</div>

<div class="actions" *ngIf="confirmation === 'DELETE ALL DATA'">
  <button
    type="button"
    class="btn btn-primary"
    wpButtonSpinner
    [isBusy]="isDeleting"
    (click)="delete()"
  >
    {{ 'settings.settings.dad.delete' | translate }}
  </button>
</div>
