export enum FileExtension {
  png = 'bi bi-filetype-png',
  jpg = 'bi bi-filetype-jpg',
  css = 'bi bi-filetype-css',
  csv = 'bi bi-filetype-csv',
  doc = 'bi bi-filetype-docx',
  exe = 'bi bi-filetype-exe',
  gif = 'bi bi-filetype-gif',
  html = 'bi bi-filetype-html',
  js = 'bi bi-filetype-js',
  json = 'bi bi-filetype-json',
  mov = 'bi bi-filetype-mov',
  mp3 = 'bi bi-filetype-mp3',
  mp4 = 'bi bi-filetype-mp4',
  pdf = 'bi bi-filetype-pdf',
  ppt = 'bi bi-filetype-ppt',
  pptx = 'bi bi-filetype-pptx',
  psd = 'bi bi-filetype-psd',
  tsx = 'bi bi-filetype-tsx',
  ttf = 'bi bi-filetype-ttf',
  txt = 'bi bi-filetype-txt',
  xls = 'bi bi-filetype-xls',
  xlsx = 'bi bi-filetype-xlsx',
  xml = 'bi bi-filetype-xml',
  default = 'bi bi-file-earmark',
}
