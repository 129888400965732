import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { RateMatricesLinesFilterComponent } from './rate-matrices-lines-filter.component';
import { RateMatrixStructureCollection } from 'src/app/settings-app/rate-matrix/model/rate-matrix-structure.enum';
import { RateMatricesFilter } from 'src/app/shared/components/features/rate-matrices-filter/rate-matrices-filter.model';

@Injectable()
export class RateMatricesLinesFilterService extends FilterService {
  public override component: Type<FilterDetails> =
    RateMatricesLinesFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override hasAllowInactive = false;
  public override placeholder = 'shared.rateMatrices.filter.placeholder';
  public rateMatrixStructureCollection = RateMatrixStructureCollection;

  public override customCriteriaCount: Record<string, () => number> = {
    text: () => 0,
    rateMatrix: () => 0,
  };

  /* Gets filter default value. */
  public override getDefaultValues(): RateMatricesFilter {
    const result: RateMatricesFilter = {
      text: '',
      rateMatrix: null,
    };
    for (const key of Object.keys(this.rateMatrixStructureCollection)) {
      result[key] = null;
    }
    return result;
  }

  /* Resets filter values. */
  public override resetValues(): void {
    const newValues = this.getDefaultValues();
    newValues.rateMatrix = this.values.rateMatrix;
    this.changeValues(newValues);
  }

  /* Gets filter in odata format. */
  public override getODataFilter() {
    const result: any[] = [];

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      const or: any[] = [];
      for (const key of this.values.rateMatrix.rateMatrixStructure) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        or.push({ [key]: { 'tolower(name)': { contains: text } } });
      }
      result.push({ or });
    }

    if (this.values.rateMatrix) {
      result.push({
        rateMatrixId: { type: 'guid', value: this.values.rateMatrix.id },
      });
    }

    for (const key of Object.keys(this.rateMatrixStructureCollection)) {
      if (this.values[key]) {
        result.push({
          [key + 'Id']: { type: 'guid', value: this.values[key].id },
        });
      }
    }

    return result;
  }
}
