import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  inject,
  DestroyRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { TimesheetTemplate } from 'src/app/shared/models/entities/settings/timesheet-template.model';
import { TimeAllocation } from 'src/app/shared/models/entities/base/timesheet.model';
import { TimesheetCardService } from '../../core/timesheet-card.service';
import { TranslateService } from '@ngx-translate/core';
import { Line } from '../../shared/models/line.model';
import { auditTime, map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SortService } from 'src/app/shared/components/features/sort/core/sort.service';
import _ from 'lodash';
import { SortDirection } from 'src/app/shared-features/comments/model/sort-direction.enum';
import {
  MetaEntityBaseProperty,
  MetaEntityDirectoryProperty,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';
import { Observable, of } from 'rxjs';
import { DirectoriesService } from 'src/app/shared/services/directories.service';
import { TimesheetDetailEntry } from 'src/app/timesheets/card/table-view/timesheet-details/models/timesheet-detail-entry..model';

@Component({
  selector: 'wp-timesheet-details',
  templateUrl: './timesheet-details.component.html',
  styleUrls: ['./timesheet-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetDetailsComponent implements OnInit {
  @Input() template: TimesheetTemplate;

  entries: TimesheetDetailEntry[] = [];

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    public timesheetCardService: TimesheetCardService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private sortService: SortService,
    private directoriesService: DirectoriesService,
  ) {}

  /**
   * Retrieves the custom field value for a given entry and field.
   *
   * This method checks if the field is of type directory. If it is, it fetches the directory entries
   * and maps the entry's allocation value to the corresponding directory entry's name. If the field
   * is not of type directory, it simply returns the allocation value for the given field name.
   *
   * @param entry The entry object containing the allocation data.
   * @param field The MetaEntityBaseProperty object representing the custom field.
   * @returns An Observable of the custom field value as a string.
   */
  public getCustomFieldValue(
    entry: TimesheetDetailEntry,
    field: MetaEntityBaseProperty,
  ): Observable<string> {
    const dataField = field.name;

    if (field.type === MetaEntityPropertyType.directory) {
      const directoryField = field as MetaEntityDirectoryProperty;

      return this.directoriesService
        .getDirectoryEntries(directoryField.directoryId)
        .pipe(
          map((entries) => {
            const a = entries.find(
              (e) => e.id === entry.allocation[directoryField.keyProperty],
            );
            return a?.name;
          }),
        );
    }
    return of(entry.allocation[dataField]);
  }

  /**
   * Checks if a given TimeAllocation has any details.
   *
   * This method checks if the allocation has any comments or if any of the custom fields have values.
   *
   * @param allocation The TimeAllocation to check.
   * @returns true if the allocation has details, false otherwise.
   */
  private hasDetails(allocation: TimeAllocation): boolean {
    if (allocation.comments) {
      return true;
    }

    let result = false;
    this.timesheetCardService.allocationCustomFields.forEach((field) => {
      let key: string;
      if (field.type === MetaEntityPropertyType.directory) {
        const directoryField = field as MetaEntityDirectoryProperty;
        key = directoryField.keyProperty;
      } else {
        key = field.name;
      }

      if (allocation[key]) {
        result = true;
      }
    });
    return result;
  }

  public trackById = (index: number, row: any): string => row.allocation.id;

  public updateView(lines: Line[]) {
    if (!this.template) {
      return;
    }
    this.entries = [];

    lines.forEach((line) => {
      line.allocations.forEach((allocation) => {
        if (this.hasDetails(allocation)) {
          const entry: TimesheetDetailEntry = {
            allocation,
            firstLine: '',
            secondLine: '',
            activityName: '',
            roleName: '',
            projectCostCenterName: '',
            projectTariffName: '',
            schedule:
              this.timesheetCardService.timesheet.schedule.find(
                (s) => s.date === allocation.date,
              )?.hours ?? 0,
          };

          this.entries.push(entry);

          // Установка строки с клиентом и проектом.
          if (line.task.project) {
            let clientAndProjectString = '';

            if (this.template.showClient) {
              if (!line.task.client) {
                clientAndProjectString = `[${this.translate.instant(
                  'timesheets.card.taskSelector.withoutClient',
                )}]`;
              } else {
                clientAndProjectString = line.task.client.name;
              }

              if (!line.task.isMainTask) {
                clientAndProjectString += ' > ';
              }
            }

            if (!line.task.isMainTask) {
              clientAndProjectString += line.task.project.name;
            }

            entry.firstLine = clientAndProjectString;

            // Установить наименование задачи.
            if (line.task.projectTask) {
              entry.secondLine = line.task.projectTask.name;
            }
          }

          // Установить наименование вида работ.
          if (line.activity) {
            entry.activityName = line.activity.name;
          }

          // Установить наименование вида работ.
          if (line.role) {
            entry.roleName = line.role.name;
          }

          // Set cost center.
          if (line.projectCostCenter) {
            entry.projectCostCenterName = line.projectCostCenter.name;
          }

          /* Set tariff. */
          if (line.projectTariff) {
            entry.projectTariffName = line.projectTariff.name;
          }
        }
      });
    });

    this.entries = _.orderBy(this.entries, 'allocation.date', [
      this.sortService.sortDirection === SortDirection.newest ? 'desc' : 'asc',
    ]);

    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    this.timesheetCardService.data$
      .pipe(auditTime(60), takeUntilDestroyed(this.destroyRef))
      .subscribe((lines) => {
        this.updateView(lines);
      });

    this.sortService.sortDirection$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((direction) => {
        this.entries = _.orderBy(this.entries, 'allocation.date', [
          direction === SortDirection.newest ? 'desc' : 'asc',
        ]);

        this.cdr.markForCheck();
      });
  }
}
