import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { NotificationService } from 'src/app/core/notification.service';
import { ExpensesTypesService } from 'src/app/core/expenses-types.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ProjectRevenueEstimate } from 'src/app/shared/models/entities/projects/project-revenue-estimate.model';

@Component({
  selector: 'wp-project-revenue-modal-modal',
  templateUrl: './project-revenue-modal.component.html',
})
export class ProjectRevenueModalComponent implements OnInit {
  @Input() entry: ProjectRevenueEstimate;
  @Input() projectId: string;
  @Input() projectVersion: ProjectVersion;
  @Input() readonly: boolean;
  @Input() projectCurrencyCode: string;

  public form = this.fb.group({
    id: null,
    date: [null, Validators.required],
    amount: [null, Validators.required],
    description: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
    projectTask: [null, Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    public expensesTypesService: ExpensesTypesService,
    private notification: NotificationService,
  ) {}

  ok() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.activeModal.close(this.form.value);
  }

  /** Закрыть диалог. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ngOnInit() {
    this.form.patchValue(this.entry);
    if (this.readonly) {
      this.form.disable();
    }
  }
}
