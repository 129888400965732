import { Component, Input, ViewRef, ChangeDetectorRef } from '@angular/core';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';

@Component({
  selector: 'wp-financial-readonly-amount-cell',
  templateUrl: './financial-amount-readonly-cell.component.html',
  styleUrls: ['./financial-amount-readonly-cell.component.scss'],
})
export class FinancialAmountReadonlyCellComponent implements GridTemplateCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() currencyCode: string;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  constructor(
    public service: GridService,
    private ref: ChangeDetectorRef,
  ) {
    this.service.detectChanges$.subscribe(() => {
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
  }
  public getCss(): string[] {
    const css = [];

    if (this.formGroup.value.type) {
      css.push(this.formGroup.value.type.code.toLowerCase());
    }

    if (this.formGroup.value.isTaskGroup) {
      css.push('task');
    }
    return css;
  }
}
