import { Injectable } from '@angular/core';
import { ViewSettings } from '../models/view-settings/view-settings.model';
import { UserSettings } from '../models/user-settings.model';
import { ReportSourceDescription } from '../models/source-description/report-source-description.model';
import { DataService } from 'src/app/core/data.service';
import { LogService } from 'src/app/core/log.service';
import { AnalyticsService } from 'src/app/core/analytics.service';
import { Subscription, combineLatest } from 'rxjs';
import { Guid } from 'src/app/shared/helpers/guid';
import { ReportField } from '../models/source-description/report-field.model';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { PivotTable } from './model/pivot-table.model';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { ReportFieldType } from '../models/report-field-type.enum';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';

/** Сервис работы с данными сводной таблицы. */
@Injectable()
export class PivotDataService {
  private sourceDescription: ReportSourceDescription;
  private viewSettings: ViewSettings;
  private userSettings: UserSettings;
  private reportId: string;

  private loadingSubscription: Subscription;

  public pivotReport: PivotTable;
  private hasColumnGrouping: boolean;
  private hasRowGrouping: boolean;

  public fieldsDescriptions: ReportField[];
  public columnGroupFieldsDictionary: Dictionary<ReportField>;
  public viewFieldDescriptions: Dictionary<ReportField>;
  public valueFieldDescriptions: ReportField[];
  dashboardId: string;
  widgetId: string;

  constructor(
    private notification: NotificationService,
    private data: DataService,
    private log: LogService,
    private analyticsService: AnalyticsService,
  ) {}

  public initForReport(
    reportId: string,
    viewSettings: ViewSettings,
    userSettings: UserSettings,
  ) {
    this.userSettings = userSettings;
    this.viewSettings = viewSettings;
    this.reportId = reportId;
    this.hasColumnGrouping = this.viewSettings.columnGroupFields?.length > 0;
    this.hasRowGrouping = this.viewSettings.rowGroupFields?.length > 0;
  }

  public initForWidget(
    widgetId: string,
    dashboardId: string,
    viewSettings: ViewSettings,
    userSettings: UserSettings,
  ) {
    this.userSettings = userSettings;
    this.viewSettings = viewSettings;
    this.widgetId = widgetId;
    this.dashboardId = dashboardId;
    this.hasColumnGrouping = this.viewSettings.columnGroupFields?.length > 0;
    this.hasRowGrouping = this.viewSettings.rowGroupFields?.length > 0;
  }

  /** Загрузка данных. */
  public loadData(
    forceRefresh?: boolean,
    period?: DatePeriod,
  ): Promise<PivotTable> {
    return new Promise<PivotTable>((resolve, reject) => {
      const start = new Date().getTime();
      this.log.debug('Pivot Report is loading...');

      const observables = [
        this.analyticsService.getSourceDescription(
          this.viewSettings.sourceName,
        ),
      ];

      if (this.reportId) {
        observables.push(
          this.data
            .collection('Reports')
            .entity(this.reportId)
            .function('WP.GetPivotData')
            .get(),
        );
      }

      if (this.widgetId) {
        const params: Dictionary<any> = {
          datasetId: this.widgetId,
          forceRefresh: forceRefresh === true,
          viewPeriod: '@viewPeriod',
        };

        observables.push(
          this.data
            .collection('Dashboards')
            .entity(this.dashboardId)
            .function('WP.GetDataset')
            .query(params, null, { '@viewPeriod': JSON.stringify(period) }),
        );
      }

      if (this.loadingSubscription) {
        this.loadingSubscription.unsubscribe();
      }

      this.loadingSubscription = combineLatest(observables).subscribe({
        next: (value: [ReportSourceDescription, any]) => {
          this.log.debug(
            `Pivot Report has been loaded in ${
              new Date().getTime() - start
            } ms.`,
          );
          this.sourceDescription = value[0];
          this.pivotReport = value[1];

          this.transform(this.pivotReport);

          resolve(this.pivotReport);
        },
        error: (error: Exception) => {
          if (error.code !== Exception.UnexpectedError.code) {
            this.notification.error(error.message);
          }
          reject();
        },
      });
    });
  }

  /** Первичное преобразование данных. */
  private transform(pivotReport: any) {
    const start = new Date().getTime();
    this.log.debug('Pivot Report is transforming...');

    this.viewFieldDescriptions = {};

    this.separateFields();

    if (this.hasColumnGrouping) {
      const expandColumnGroups = (level: any[]) => {
        level.forEach((item) => {
          item.isExpanded = true;
          if (item.pivotColumns) {
            expandColumnGroups(item.pivotColumns);
          }
        });
      };
      expandColumnGroups(pivotReport.pivotColumns);
    }

    if (this.hasRowGrouping) {
      const expandRowGroups = (groups) => {
        groups.forEach((group) => {
          group.isExpanded = true;
          group.id = Guid.generate();

          if (group.isGroup && group.pivotRows[0].isGroup) {
            expandRowGroups(group.pivotRows);
          }
        });
      };
      expandRowGroups(pivotReport.pivotRows);
    }
    this.sort();
    this.log.debug(
      `Pivot Report has been transformed in ${new Date().getTime() - start} ms.`,
    );
  }

  /** Выделяем колонки значений и аналитик. */
  private separateFields() {
    this.fieldsDescriptions = this.sourceDescription.allFields;
    this.columnGroupFieldsDictionary = {};
    this.valueFieldDescriptions = [];

    const addCollection = (list, kind) => {
      list.forEach((field) => {
        const fieldDescription = this.fieldsDescriptions.find(
          (f) => f.name === field.name,
        );

        if (field.customTitle) {
          fieldDescription.title = field.customTitle;
        }

        this.viewFieldDescriptions[field.name] = fieldDescription;

        if (kind === 'rowGroup') {
          this.columnGroupFieldsDictionary[fieldDescription.name] =
            fieldDescription;
        }

        if (kind === 'value') {
          this.valueFieldDescriptions.push(fieldDescription);
        }
      });
    };

    addCollection(this.viewSettings.columnFields, 'column');
    addCollection(this.viewSettings.valueFields, 'value');
    addCollection(this.viewSettings.rowGroupFields, 'rowGroup');
    addCollection(this.viewSettings.columnGroupFields, 'columGroup');
  }

  /** Обработчик сортировки. */
  private sortHandler(
    columnName: string,
    containerName: string,
    type: ReportFieldType,
  ) {
    let a;
    let b;
    let a1;
    let b1;
    const rx = /(\d+)|(\D+)/g;
    const rd = /\d+/;

    return (as, bs) => {
      if (containerName) {
        as = as[containerName];
        bs = bs[containerName];
      }

      if (
        type === ReportFieldType.Decimal ||
        type === ReportFieldType.Percent ||
        type === ReportFieldType.Integer
      ) {
        return as[columnName] - bs[columnName];
      }

      if (type === ReportFieldType.Date) {
        return (
          new Date(bs[columnName]).getTime() -
          new Date(as[columnName]).getTime()
        );
      }

      if (as[columnName] === '' || as[columnName] == null) {
        a = [];
      } else {
        a = String(as[columnName]).toLowerCase().match(rx);
      }

      if (bs[columnName] === '' || bs[columnName] == null) {
        b = [];
      } else {
        b = String(bs[columnName]).toLowerCase().match(rx);
      }

      while (a.length && b.length) {
        a1 = a.shift();
        b1 = b.shift();
        if (rd.test(a1) || rd.test(b1)) {
          if (!rd.test(a1)) {
            return 1;
          }
          if (!rd.test(b1)) {
            return -1;
          }
          if (a1 !== b1) {
            return a1 - b1;
          }
        } else if (a1 !== b1) {
          return a1 > b1 ? 1 : -1;
        }
      }
      return a.length - b.length;
    };
  }

  public sort() {
    let sortName: string;
    let sortDesc: boolean;

    if (
      this.userSettings.sorting &&
      Object.keys(this.userSettings.sorting).length > 0
    ) {
      sortName = Object.keys(this.userSettings.sorting)[0];
      sortDesc = this.userSettings.sorting[sortName] === 'Desc';
    } else {
      return;
    }

    // Колонки по которой идет сортировка нет в представлении.
    if (!this.viewFieldDescriptions[sortName]) {
      return;
    }

    const type = this.viewFieldDescriptions[sortName].type;

    if (this.hasRowGrouping) {
      const sortGroups = (groups, level) => {
        const groupFieldName = this.viewSettings.rowGroupFields[level].name;

        groups = groups.sort(
          this.sortHandler(
            'value',
            null,
            this.viewFieldDescriptions[groupFieldName].type,
          ),
        );

        if (sortName === groupFieldName && sortDesc) {
          groups = groups.reverse();
        }

        if (sortName !== groupFieldName) {
          groups.forEach((group) => {
            if (group.isGroup && group.pivotRows[0].isGroup) {
              group.pivotRows = sortGroups(group.pivotRows, level + 1);
            } else {
              group.pivotRows = group.pivotRows.sort(
                this.sortHandler(sortName, 'values', type),
              );
              if (sortDesc) {
                group.pivotRows = group.pivotRows.reverse();
              }
            }
          });
        }
        return groups;
      };

      this.pivotReport.pivotRows = sortGroups(this.pivotReport.pivotRows, 0);
    } else {
      this.pivotReport.pivotRows = this.pivotReport.pivotRows.sort(
        this.sortHandler(sortName, 'values', type),
      );

      if (sortDesc) {
        this.pivotReport.pivotRows = this.pivotReport.pivotRows.reverse();
      }
    }
  }
}
