<wp-form-header [name]="form.value.name" (reloaded)="reload()"></wp-form-header>
<wp-loading-indicator [state]="state"></wp-loading-indicator>
<tmt-not-found [state]="state"></tmt-not-found>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" [hidden]="state !== 'Ready'" class="form" style="width: 600px">
  <div class="d-flex">
    <div class="form-group">
      <label>{{ 'finance.periods.card.properties.start' | translate }}</label>
      <wp-date-box [allowNull]="false" formControlName="start"></wp-date-box>
    </div>

    <div class="form-group ms-4">
      <label>{{ 'finance.periods.card.properties.end' | translate }}</label>
      <wp-date-box [allowNull]="false" formControlName="end"></wp-date-box>
    </div>
  </div>
  <div class="form-check mt-3" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'finance.periods.card.properties.isOpen' | translate }}
    </label>
  </div>
  <div class="actions" *ngIf="!readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
