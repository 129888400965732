import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wp-widget-double-value-properties',
  templateUrl: './widget-double-value-properties.component.html',
})
export class WidgetDoubleValuePropertiesComponent {
  @Input() form: UntypedFormGroup;
}
