import { Navigation } from '../models/navigation/navigation';

export const CLIENTS_APP_NAVIGATION: Navigation = {
  name: 'clients',
  applicationTitle: 'shared.apps.clients',
  groups: [
    {
      name: 'clients',
      header: 'projects.navigation.clients.header',
      items: [
        {
          name: 'clients.myClients',
          state: 'clients',
          header: 'projects.navigation.clients.my.header',
          hint: 'projects.navigation.clients.my.hint',
          entityType: 'Organization',
          stateParams: {
            view: 'my',
          },
        },
        {
          name: 'clients.activeClients',
          state: 'clients',
          header: 'projects.navigation.clients.active.header',
          hint: 'projects.navigation.clients.active.hint',
          entityType: 'Organization',
          stateParams: {
            view: 'active',
          },
        },
        {
          name: 'clients.allClients',
          state: 'clients',
          header: 'projects.navigation.clients.all.header',
          hint: 'projects.navigation.clients.all.hint',
          entityType: 'Organization',
          stateParams: {
            view: 'all',
          },
        },
      ],
    },
  ],
};
