import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';

@Injectable()
export class FinancialTaskCellService {
  public projectVersion: ProjectVersion;
  public toggleTaskId$ = new Subject<string>();

  public toggleTask(taskId: string) {
    this.toggleTaskId$.next(taskId);
  }
}
