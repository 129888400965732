import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AbstractToolbar } from 'src/app/shared-features/grid2/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'tmt-grid2-test-toolbar',
  templateUrl: './grid2-test-toolbar.component.html',
  styleUrl: './grid2-test-toolbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Grid2TestToolbarComponent extends AbstractToolbar {
  constructor(gridService: GridService, cdr: ChangeDetectorRef) {
    super(gridService, cdr);
  }
}
