<tmt-form-header [name]="competenceForm.value.name" />
<wp-loading-indicator [state]="state$ | async" />
<tmt-not-found [state]="state$ | async" />
<wp-data-not-saved-indicator [form]="competenceForm" />

<form
  [formGroup]="competenceForm"
  [hidden]="(state$ | async) !== 'Ready'"
  class="form mt-3"
  style="width: 600px"
>
  <div class="form-group" tmtIndicator>
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}" />
  </div>

  <div class="form-group" tmtIndicator>
    <label class="control-label">{{ 'settings.competences.card.role' | translate }}</label>
    <wp-select-box
      formControlName="roleId"
      collection="Roles"
      [isIdMode]="true"
      [readonly]="true"
      [allowNull]="false"
      placeholder="{{ 'settings.competences.card.role' | translate }}"
    />
  </div>

  <div class="form-group" style="width: 150px">
    <label>{{ 'shared.props.code' | translate }}</label>
    <wp-text-box formControlName="code" placeholder="{{ 'shared.props.code' | translate }}" />
  </div>

  <div class="form-group">
    <label>{{ 'shared.props.description' | translate }}</label>
    <wp-multiline-text-box
      formControlName="description"
      placeholder="{{ 'shared.props.description' | translate }}"
    />
  </div>
  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>
  @if (!readonly) {
    <div class="actions">
      <button
        type="button"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="isSaving$ | async"
        (click)="save()"
      >
        {{ 'shared.actions.save' | translate }}
      </button>
    </div>
  }
</form>
