@if (sortTarget()) {
  <button
    class="btn btn-default active"
    [title]="'components.boardSortButtonComponent.actions.changeDirection' | translate"
    (click)="toggleDirection()"
  >
    <i
      class="bi"
      [class.bi-sort-down-alt]="sortDirection() === 'asc'"
      [class.bi-sort-down]="sortDirection() === 'desc'"
    ></i>
  </button>
}

<button
  class="btn btn-default trim"
  [class.active]="sortTarget()?.name"
  [title]="
    sortTarget()?.name ?? 'components.boardSortButtonComponent.actions.chooseTarget' | translate
  "
  (click)="toggleList()"
>
  @if (!sortTarget()) {
    <i class="bi bi-arrow-down-up"></i>
    <span class="d-inline-block m-auto w-85">{{ 'Сортировка' }}</span>
  } @else {
    <span>{{ sortTarget().name }}</span>
  }
</button>

<ng-template #list>
  <div class="properties">
    <div [formGroup]="form" class="properties__search">
      <wp-text-box
        formControlName="search"
        placeholder="{{ 'shared.props.name' | translate }}"
        autofocus="true"
        (keydown)="onKeyDown($event)"
      />
    </div>

    <div
      class="properties__container"
      id="selecting-list"
      tabindex="0"
      (keydown)="onKeyDown($event)"
    >
      @for (property of filteredProperties(); track $index) {
        <div
          class="properties__item trim"
          [class.properties__item--default]="property.isDefault"
          [class.properties__item--focused]="property.key === focusedProperty()?.key"
          [id]="property.key"
          [title]="property.name"
          (click)="changeTarget(property)"
          (mouseenter)="focusedProperty.set(property)"
        >
          {{ property.name }}
        </div>
      } @empty {
        <p class="properties__empty">
          {{ 'shared.noOptions' | translate }}
        </p>
      }
    </div>
  </div>
</ng-template>
