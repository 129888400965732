import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Dictionary } from '../shared/models/dictionary';
import { DateHours } from '../shared/models/entities/date-hours.model';
import { PlanningScale } from '../shared/models/enums/planning-scale.enum';
import { DataService } from './data.service';

/** Предоставляет и кэширует сведения о расписании FTE.  */
@Injectable({
  providedIn: 'root',
})
export class FteScheduleService {
  constructor(private data: DataService) {}

  private observables: Dictionary<Observable<DateHours[]>> = {};

  /** Returns default schedule.
   *
   * @param scale current scale
   * @param from start schedule date
   * @param to end schedule date
   * @param reload force return schedule from server
   * @returns Observable of schedule
   */
  public getFteSchedule(
    scale: PlanningScale,
    from: string,
    to: string,
    reload?: boolean,
  ): Observable<DateHours[]> {
    const key = scale + from + to;

    if (!reload && this.observables[key]) {
      return this.observables[key];
    }

    const params: Dictionary<any> = {
      planningScale: `WP.PlanningScale'${scale}'`,
      from,
      to,
    };

    const observable = this.data
      .collection('Schedules')
      .function('WP.GetFteSchedule')
      .query<DateHours[]>(params)
      .pipe(
        shareReplay(),
        map((it) => cloneDeep(it)),
      );

    return (this.observables[key] = observable);
  }

  /** Returns complete schedule. From (today - 10 yeas) to (today + 5 years).
   *
   * @param scale current scale
   * @param reload force return schedule from server
   * @returns Observable of schedule
   */
  public getCompleteFteSchedule(
    scale: PlanningScale,
    reload?: boolean,
  ): Observable<DateHours[]> {
    const today = DateTime.now();
    const from = today.minus({ years: 100 }).toISODate();
    const to = today.plus({ years: 100 }).toISODate();

    return this.getFteSchedule(scale, from, to, reload);
  }

  /** Returns schedule.
   *
   * @param id schedule id
   * @param scale current scale
   * @param from start schedule date
   * @param to end schedule date
   * @param reload force return schedule from server
   * @returns Observable of schedule
   */
  public getSchedule(
    scheduleId: string,
    scale: PlanningScale,
    from: string,
    to: string,
    reload?: boolean,
  ): Observable<DateHours[]> {
    const key = scheduleId + scale + from + to;

    if (!reload && this.observables[key]) {
      return this.observables[key];
    }

    const params: Dictionary<any> = {
      scheduleId,
      scale: `WP.PlanningScale'${scale}'`,
      from,
      to,
    };

    const observable = this.data
      .collection('Schedules')
      .function('WP.GetSchedule')
      .query<DateHours[]>(params)
      .pipe(
        shareReplay(),
        map((it) => cloneDeep(it)),
      );

    return (this.observables[key] = observable);
  }
}
