<button
  *ngIf="!resourceRequests.length"
  (click)="createRequest()"
  class="active btn btn-default request-cell__button"
  title="{{ 'projects.projects.card.resourceRequirements.actions.createRequest' | translate }}"
>
  <i class="bi bi-person-plus" aria-hidden="true"></i>
</button>

<div *ngIf="resourceRequests.length" ngbDropdown>
  <div ngbDropdownToggle class="request-cell__actions request-cell__actions--cell without-caret">
    <tmt-state-badge
      (click)="openResourceRequest(resourceRequests[0].id)"
      [state]="resourceRequests[0].state"
      class="state-badge"
    ></tmt-state-badge>

    <button class="btn btn-default request-cell__button">
      <span class="request-cell__counter trim" [class.hidden]="!(resourceRequests.length - 1 > 0)">
        +{{ resourceRequests.length - 1 }}
      </span>
      <i
        class="request-cell__icon bi bi-three-dots"
        [class.hidden]="!!(resourceRequests.length - 1)"
      ></i>
    </button>
  </div>

  <div ngbDropdownMenu aria-labelledby="navbarDropdown" class="dropdown-menu dropdown-menu-end">
    <button ngbDropdownItem (click)="createRequest()">
      <i class="bi bi-plus-lg bi-15"></i>
      {{ 'projects.projects.card.resourceRequirements.actions.createRequest' | translate }}
    </button>

    <div class="dropdown-divider"></div>

    <button
      *ngFor="let request of resourceRequests"
      ngbDropdownItem
      (click)="openResourceRequest(request.id)"
      class="request-cell__actions"
      [title]="getHint(request)"
    >
      <a
        uiSref="resourceRequest"
        [uiParams]="{
          entityId: request.id,
          navigation: stateParams.navigation,
          routeMode: stateParams.routeMode
        }"
      >
        {{ request.created | date: 'shortDate' }}
        <span *ngIf="request.requestedHours">
          ({{ request.requestedHours | plannerFormat }} {{ 'shared.unitHour' | translate }})
        </span>
      </a>
      <tmt-state-badge [state]="request.state" class="state-badge"></tmt-state-badge>
    </button>
  </div>
</div>
