<div [formGroup]="form" ngbDropdown class="d-inline-block">
  <button
    type="button"
    class="btn btn-default without-caret"
    ngbDropdownToggle
    title="{{ 'resources.resourceSummary.filter.properties.viewSettings.title' | translate }}"
  >
    <i class="bi bi-eye" aria-hidden="true"></i>
  </button>
  <div ngbDropdownMenu class="p-3">
    @for (control of kpiControls; track control) {
      <div class="form-check">
        <input
          [id]="control"
          [formControlName]="control"
          class="form-check-input"
          type="checkbox"
        />
        <label [for]="control" class="form-check-label text-nowrap">
          {{ 'resources.resourceSummary.filter.properties.viewSettings.' + control | translate }}
        </label>
      </div>
    }
    <hr />
    <div class="form-check">
      <input id="overtime" formControlName="overtime" class="form-check-input" type="checkbox" />
      <label for="overtime" class="form-check-label text-nowrap">
        {{ 'resources.resourceSummary.filter.properties.viewSettings.overtime' | translate }}
      </label>
    </div>
  </div>
</div>
