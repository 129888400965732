<div [formGroup]="form">
  <div class="form-group">
    <label>URL</label>
    <wp-text-box formControlName="url" placeholder="https://domain.com"></wp-text-box>
  </div>

  <div class="form-group">
    <label>{{ 'analytics.dashboards.widgetSettings.properties.content' | translate }}</label>
    <wp-multiline-text-box rows="10" formControlName="content" placeholder="<html></html>">
    </wp-multiline-text-box>
  </div>
</div>
