import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceSummaryFilterService } from 'src/app/resource-summary/shared/resource-summary-filter/core/resource-summary-filter.service';
import { ResourceSummaryFilterSettings } from 'src/app/resource-summary/shared/resource-summary-filter/models/resource-summary-filter-settings';
import { AppService } from 'src/app/core/app.service';
import { DepartmentBoxComponent } from 'src/app/shared/components/controls/department-box/department-box.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Represents Resource Summary Filter Modal Window content. */
@Component({
  selector: 'tmt-resource-summary-filter-modal',
  templateUrl: './resource-summary-filter-modal.component.html',
  styleUrls: ['./resource-summary-filter-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceSummaryFilterModalComponent implements OnInit {
  @Input() filter: ResourceSummaryFilterSettings;
  @ViewChild(DepartmentBoxComponent)
  private departmentBoxComponent: DepartmentBoxComponent;

  public form = this.fb.group({
    project: null,
    program: null,
    client: null,
    resourcePool: null,
    department: null,
    role: null,
    level: null,
    location: null,
    searchText: '',
    isMySubordinates: false,
    isMyProjects: false,
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    public app: AppService,
    public service: ResourceSummaryFilterService,
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    if (this.filter) {
      this.form.patchValue(this.filter);
    }

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.filter = this.form.getRawValue();
      });
  }

  /** Resets filter values. */
  public clearValues(): void {
    this.form.reset({
      searchText: '',
      isMySubordinates: false,
      isMyProjects: false,
    });
    this.departmentBoxComponent.clear();
  }

  /**
   * Modal Ok button click event handler.
   * Closes the active modal.
   * */
  public ok = (): void => {
    this.activeModal.close(this.filter);
  };

  /**
   * Modal Cancel/Cross button click event handler.
   * Closes the active modal.
   * */
  public cancel = (): void => {
    this.activeModal.dismiss('cancel');
  };
}
