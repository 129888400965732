import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { UntypedFormBuilder } from '@angular/forms';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { Observable, Subscription } from 'rxjs';
import { sortBy } from 'lodash';
import { AppService } from 'src/app/core/app.service';
import { Role } from 'src/app/shared/models/entities/settings/role.model';
import { EntityType } from '../enums/entity-type.enum';
import { Constants } from 'src/app/shared/globals/constants';

/** Диалог добавления ролей ресурса. */
@Component({
  selector: 'wp-adding-resource-roles-modal',
  templateUrl: './adding-resource-roles-modal.component.html',
  styleUrls: ['./adding-resource-roles-modal.component.scss'],
})
export class AddingResourceRolesModalComponent implements OnInit {
  public loadLimit = 250;
  /** Тип сущности, для которой выбираются роли */
  @Input() entityType: EntityType;
  /** Id сущности, для которой выбираются роли */
  @Input() entityId: string;

  /** ИД ранее добавленных ролей */
  @Input() alreadyAddedIds: string[];

  public isSaving: boolean;
  public isLoading: boolean;
  public loadedPartly: boolean;

  @ViewChild('leftTbl') leftTbl: ElementRef;

  public leftTableStyles: Dictionary<string> = {};
  public rightTableStyles: Dictionary<string> = {};

  public availableRoles: Role[] = [];
  public selectedRoles: Role[] = [];

  public filterForm = this.fb.group({
    name: [''],
  });

  loadingSubscription: Subscription;

  constructor(
    public app: AppService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
  ) {}

  public resizeLeftTbl() {
    this.leftTableStyles['display'] = 'table';
    this.leftTableStyles['width'] =
      (<HTMLElement>this.leftTbl.nativeElement).getBoundingClientRect().width +
      'px';
  }

  public selectRole(role: any, index: number) {
    this.selectedRoles.push(role);
    this.selectedRoles = sortBy(this.selectedRoles, ['name']);
    this.availableRoles.splice(index, 1);
  }

  removeRole(role: any, index: number) {
    this.availableRoles.push(role);
    this.selectedRoles.splice(index, 1);

    this.availableRoles = sortBy(this.availableRoles, ['name']);
  }

  public ok() {
    this.activeModal.close(this.selectedRoles);
  }

  private load() {
    this.availableRoles = [];
    this.isLoading = true;
    this.loadedPartly = false;

    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription = this.loadRoles().subscribe({
      next: (data) => {
        data = data.filter(
          (role) =>
            this.selectedRoles.find((r) => r.id === role.id) == null &&
            this.alreadyAddedIds.find((id) => id === role.id) == null,
        );

        this.availableRoles = data;
        this.isLoading = false;
        this.loadedPartly = this.availableRoles.length === this.loadLimit;
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.isLoading = false;
      },
    });
  }

  private loadRoles(): Observable<Role[]> {
    const rolesQuery = {
      orderBy: ['name'],
      select: ['id', 'name', 'description'],
      expand: {
        competences: {
          select: ['id', 'name'],
          filter: { isActive: true },
          orderBy: 'name',
        },
      },
      top: this.loadLimit,
      filter: <any[]>[{ isActive: true }],
    };
    const defaultFilter = this.filterForm.value;
    if (defaultFilter.name) {
      const name = (defaultFilter.name as string)
        .substring(0, Constants.filterInputLengthLimit)
        .toLowerCase();
      rolesQuery.filter.push({
        or: [
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(name)': { contains: name } },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(code)': { contains: name } },
        ],
      });
    }

    switch (this.entityType) {
      // case EntityType.user:
      //   const queryUserRoles = {
      //     select: ['id'],
      //     expand: [
      //       {
      //         resourcePool: {
      //           select: ['id', 'allRolesAllowed'],
      //           expand: {
      //             allowedRoles: {
      //               expand: { role: rolesQuery },
      //               orderBy: 'role/name',
      //             },
      //           },
      //         },
      //       },
      //     ],
      //   };
      //   return this.data
      //     .collection('Users')
      //     .entity(this.entityId)
      //     .get<User>(queryUserRoles)
      //     .pipe(
      //       switchMap((user) => {
      //         if (user.resourcePool.allRolesAllowed) {
      //           return this.data.collection('Roles').query<Role[]>(rolesQuery);
      //         } else {
      //           return of(
      //             user.resourcePool.allowedRoles.map(
      //               (allowedRole) => allowedRole.role as Role
      //             )
      //           );
      //         }
      //       })
      //     );
      default:
        return this.data.collection('Roles').query<Role[]>(rolesQuery);
    }
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ngOnInit() {
    this.load();
    this.filterForm.valueChanges.subscribe(() => this.load());
  }

  /**
   * Returns competences names string.
   *
   * @param role role for getting competences.
   */
  public getCompetencesString(role: Role): string {
    return role.competences.map((competence) => competence.name).join('; ');
  }
}
