<tmt-form-header [name]="form.controls.name.value"></tmt-form-header>
<wp-loading-indicator [state]="state$ | async"></wp-loading-indicator>

<form class="form" [hidden]="(state$ | async) != cardState.Ready" novalidate [formGroup]="form">
  <wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>
  <div style="width: 500px" class="mt-3">
    <div class="form-group">
      <label>{{ 'shared.props.name' | translate }}</label>
      <wp-text-box
        formControlName="name"
        placeholder="{{ 'settings.scheduleExceptions.card.props.name.ph' | translate }}"
      >
      </wp-text-box>
    </div>

    <div class="form-group">
      <label>{{ 'shared.props.description' | translate }}</label>
      <wp-multiline-text-box
        placeholder="{{ 'shared.props.description' | translate }}"
        formControlName="description"
        rows="3"
      ></wp-multiline-text-box>
    </div>

    <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
      <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
      <label class="form-check-label" for="isActive">
        {{ 'shared.props.isActive.label' | translate }}
      </label>
    </div>
  </div>

  <wp-grid
    [formArray]="form.controls.exceptionDays"
    [options]="gridOptions"
    [readonly]="readonly"
    class="d-block"
    style="width: 1000px"
  >
  </wp-grid>

  <div class="actions">
    <button
      type="button"
      *ngIf="!readonly"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'settings.scheduleExceptions.card.actions.save' | translate }}
    </button>
  </div>
</form>
