import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-currency-creation',
  templateUrl: './currency-creation.component.html',
})
export class CurrencyCreationComponent {
  isSaving = false;
  activityForm: UntypedFormGroup;

  private collection = this.data.collection('Currencies');

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
  ) {
    this.activityForm = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      alpha3Code: ['', [Validators.required, Validators.maxLength(3)]],
    });
  }

  public ok = () => {
    this.activityForm.markAllAsTouched();

    if (this.activityForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const data = this.activityForm.value;

    this.collection.insert(data).subscribe({
      next: (response) => {
        this.notification.successLocal(
          'settings.currencies.creation.messages.created',
        );
        this.state.go('settings.currency', { entityId: response.id });
        this.isSaving = false;
        this.activeModal.close();
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.isSaving = false;
      },
    });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
