import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { GroupCardComponent } from './card/group-card.component';
import { GroupListComponent } from './list/group-list.component';
import { GroupCreationComponent } from './creation/group-creation.component';
import { GROUP_LIST } from 'src/app/shared/lists/group.list';
import { GroupUsersComponent } from './card/group-users/group-users.component';
import { GroupPermissionsComponent } from './card/group-permissions/group-permissions.component';
import { GroupPropertiesComponent } from './card/group-properties/group-properties.component';
import { AddUserToGroupModalComponent } from './card/group-users/add-user-to-group-modal/add-user-to-group-modal.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    GroupCardComponent,
    GroupListComponent,
    GroupCreationComponent,
    GroupUsersComponent,
    GroupPermissionsComponent,
    GroupPropertiesComponent,
    AddUserToGroupModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.groups',
          url: '/groups',
          component: GroupListComponent,
          params: {
            navigation: 'settings.groups',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: GROUP_LIST },
          ],
        },

        {
          name: 'settings.group',
          params: {
            navigation: 'settings.groups',
          },
          url: '/groups/{entityId:guid}',
          redirectTo: 'settings.group.properties',
          component: GroupCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },

        {
          name: 'settings.group.properties',
          url: '/properties',
          component: GroupPropertiesComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.group.users',
          url: '/users',
          component: GroupUsersComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.group.permissionSets',
          url: '/permissionSets',
          component: GroupPermissionsComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class GroupsModule {}
