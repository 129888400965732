import { Subject } from 'rxjs';

export class PlannerPeriodService {
  private nextSubject = new Subject<void>();
  public next$ = this.nextSubject.asObservable();

  private previousSubject = new Subject<void>();
  public previous$ = this.previousSubject.asObservable();

  private periodSubject = new Subject<number>();
  public period$ = this.periodSubject.asObservable();

  public readonly: boolean;
  public next() {
    this.nextSubject.next();
  }

  public previous() {
    this.previousSubject.next();
  }

  public setPeriod(offset: number) {
    this.periodSubject.next(offset);
  }
}
