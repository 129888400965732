import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { Directory } from 'src/app/settings-app/directories/model/directory.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

/** Service for directories caching. */
@Injectable({
  providedIn: 'root',
})
export class DirectoriesService {
  private directoryCache: Record<string, Observable<Directory>> = {};
  private directoryEntriesCache: Record<string, Observable<NamedEntity[]>> = {};

  constructor(private dataService: DataService) {}

  /**
   * Retrieves a directory by its ID from the cache or requests it from the server if not cached.
   *
   * @param directoryId The ID of the directory to retrieve.
   * @returns An Observable of the requested Directory.
   */
  public getDirectory(directoryId: string): Observable<Directory> {
    if (this.directoryCache[directoryId]) {
      return this.directoryCache[directoryId];
    } else {
      this.directoryCache[directoryId] = this.requestDirectory(
        directoryId,
      ).pipe(shareReplay(1));
      return this.directoryCache[directoryId];
    }
  }

  /**
   * Requests a directory from the server.
   *
   * @param directoryId The ID of the directory to request.
   * @returns An Observable of the requested Directory.
   */
  private requestDirectory(directoryId: string): Observable<Directory> {
    return this.dataService
      .collection('Directories')
      .entity(directoryId)
      .get<Directory>({
        expand: {
          entries: {
            select: ['id', 'name'],
          },
        },
      });
  }

  /**
   * Retrieves directory entries by the directory ID from the cache or requests them from the server if not cached.
   *
   * @param directoryId The ID of the directory for which to retrieve entries.
   * @returns An Observable of the requested NamedEntity array.
   */
  public getDirectoryEntries(directoryId: string): Observable<NamedEntity[]> {
    if (this.directoryEntriesCache[directoryId]) {
      return this.directoryEntriesCache[directoryId];
    } else {
      this.directoryEntriesCache[directoryId] = this.requestDirectoryEntries(
        directoryId,
      ).pipe(shareReplay(1));
      return this.directoryEntriesCache[directoryId];
    }
  }

  /**
   * Requests directory entries from the server based on the provided directory ID.
   *
   * @param directoryId The ID of the directory for which to request entries.
   * @returns An Observable of the requested NamedEntity array.
   */
  private requestDirectoryEntries(
    directoryId: string,
  ): Observable<NamedEntity[]> {
    return this.dataService
      .collection('DirectoryEntries')
      .query<NamedEntity[]>({
        select: ['id', 'name'],
        filter: {
          directoryId: { type: 'guid', value: directoryId },
        },
      });
  }
}
