<form class="form" [formGroup]="form" novalidate>
  <wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>
  <div>
    <ng-container formGroupName="notification">
      <div *ngFor="let item of notifyCheckboxes" class="form-check">
        <input
          formControlName="{{ item }}"
          type="checkbox"
          class="form-check-input"
          id="{{ item }}"
        />
        <label class="form-check-label" for="{{ item }}">
          {{ 'shared.userSettings.notifications.' + item | translate }}
        </label>
      </div>
    </ng-container>

    <hr class="mt-3" />

    <div class="form-check pt-3">
      <input
        formControlName="notifyByEmail"
        type="checkbox"
        class="form-check-input"
        id="notifyByEmail"
      />
      <label class="form-check-label" for="notifyByEmail">
        {{ 'shared.userSettings.notifyByEmail' | translate }}
      </label>
    </div>

    <div class="form-check">
      <input
        formControlName="useBrowserNotifications"
        type="checkbox"
        class="form-check-input"
        id="useBrowserNotifications"
      />
      <label class="form-check-label" for="useBrowserNotifications">
        {{ 'shared.userSettings.useBrowserNotifications' | translate }}
      </label>
    </div>

    <ngb-alert
      *ngIf="isShowAlert && browserPermissionAlert"
      [dismissible]="false"
      [type]="browserPermissionAlert.type"
    >
      <p class="mb-0">{{ browserPermissionAlert.message }}</p>
    </ngb-alert>
  </div>
</form>
