import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { TranslateService } from '@ngx-translate/core';
import { Info } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class HighchartsService {
  public highcharts = Highcharts;

  constructor(private translate: TranslateService) {
    this.highcharts.setOptions({
      lang: {
        loading: this.translate.instant('shared.loading'),
        months: Info.months(),
        weekdays: Info.weekdays(),
        shortMonths: Info.monthsFormat('short'),
      },
    });
  }

  getHighcharts(): typeof Highcharts {
    return this.highcharts;
  }
}
