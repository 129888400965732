<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'analytics.reports.reportSettings.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" [hidden]="!hasTabs">
      <li [ngbNavItem]>
        <a ngbNavLink>{{ 'analytics.reports.reportSettings.labels.viewSettings' | translate }}</a>
        <ng-template ngbNavContent>
          <wp-report-view-settings
            [viewSettings]="viewSettings"
            type="Pivot"
            (changes)="onChanges($event)"
            (typeChanges)="onReportTypeChanged($event)"
          ></wp-report-view-settings>
        </ng-template>
      </li>
      <li [ngbNavItem] [hidden]="!report.isCommon">
        <a ngbNavLink>{{ 'analytics.reports.reportSettings.labels.commonFilter' | translate }}</a>
        <ng-template ngbNavContent>
          <p class="text-body-secondary">
            {{ 'analytics.reports.reportSettings.labels.filterHelp' | translate }}
          </p>
          <wp-report-filters
            [reportTypeCode]="report.reportType.code"
            [filters]="filters"
            (onFiltersChanged)="onFiltersChanged($event)"
          ></wp-report-filters>
        </ng-template>
      </li>
      <li [ngbNavItem]>
        <a ngbNavLink>{{ 'analytics.reports.reportSettings.labels.properties' | translate }}</a>
        <ng-template ngbNavContent>
          <wp-report-properties [form]="form"></wp-report-properties>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" [ngClass]="{ 'pt-3': hasTabs }"></div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="report.reportType"
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
