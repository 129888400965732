import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { AbstractToolbar } from 'src/app/shared-features/grid2/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'wp-transitions-toolbar',
  templateUrl: './transitions-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitionsToolbarComponent
  extends AbstractToolbar
  implements OnInit
{
  constructor(
    protected override gridService: GridService,
    protected override cdr: ChangeDetectorRef,
  ) {
    super(gridService, cdr);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
  }
}
