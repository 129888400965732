import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectExpenseEstimatesModule } from './project-expense-estimates/project-expense-estimates.module';
import { ProjectExpensesCalendarModule } from './project-expenses-calendar/project-expenses-calendar.module';
import { ProjectExpensesComponent } from './project-expenses.component';
import { RecurringExpenseRuleModalComponent } from 'src/app/projects/card/project-expenses/recurring-expense-rule-modal/recurring-expense-rule-modal.component';

@NgModule({
  declarations: [ProjectExpensesComponent, RecurringExpenseRuleModalComponent],
  imports: [
    CommonModule,
    SharedModule,
    ProjectExpenseEstimatesModule,
    ProjectExpensesCalendarModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.expenses',
          url: '/expenses',
          component: ProjectExpensesComponent,
          redirectTo: 'project.expenses.calendar',
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class ProjectExpensesModule {}
