import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
  inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { LocalStorageService } from 'ngx-webstorage';

import { filter } from 'rxjs';

import { AppService } from 'src/app/core/app.service';
import { DateService } from 'src/app/core/date.service';
import { HistoryService } from 'src/app/core/history.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { CreateMenuService } from 'src/app/core/create-menu.service';

import { DragDropService } from 'src/app/shared/services/drag-drop';
import { DragAndDropOptions } from 'src/app/shared/directives/drag-and-drop/drag-and-drop.model';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

import { BOARD_CONFIG } from 'src/app/boards/models/board-config.interface';
import { BoardService } from 'src/app/boards/services/board.service';
import { BoardDataService } from 'src/app/boards/services/board-data.service';
import { BoardFilterService } from 'src/app/boards/services/board-filter.service';
import { BoardColumn } from 'src/app/boards/models/board.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tmt-board',
  templateUrl: './board.component.html',
  styleUrl: './board.component.scss',
  providers: [
    CreateMenuService,
    BoardService,
    BoardDataService,
    {
      provide: FilterService,
      deps: [
        AppService,
        DateService,
        LocalStorageService,
        CustomFieldService,
        Injector,
      ],
      useFactory: (
        appService: AppService,
        dateService: DateService,
        localStorageService: LocalStorageService,
        customFieldService: CustomFieldService,
        injector: Injector,
      ) => {
        const config = injector.get(BOARD_CONFIG);
        const filterService = config?.filterService ?? BoardFilterService;

        return new filterService(
          appService,
          dateService,
          localStorageService,
          customFieldService,
          {
            version: 0,
            name: 'board',
            views: [],
            columns: [],
            customFieldEntityType: config?.customFieldEntityType,
          },
          config?.id,
          null,
        );
      },
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardComponent implements OnInit, OnDestroy {
  public ddOptions: DragAndDropOptions = {
    group: {
      name: 'columns',
      put: ['columns'],
      key: 'columns',
    },
    draggableClass: 'draggable',
    listDirection: 'horizontal',
    listGap: 8,
  };
  public columns: BoardColumn[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    public boardService: BoardService,
    private boardDataService: BoardDataService,
    private dragDropService: DragDropService,
    private historyService: HistoryService,
    private navigationService: NavigationService,
    private title: Title,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private actionPanelService: ActionPanelService,
    private createMenuService: CreateMenuService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
  ) {
    this.initCreateMenuService();
  }

  public ngOnInit(): void {
    this.initActions();
    this.initSubscribes();

    this.renderer.setStyle(
      this.document.getElementById('main-area'),
      'overflow-x',
      'auto',
    );

    const title = this.boardService.config?.title;

    if (title) {
      this.title.setTitle(title);
      this.historyService.addHistory(
        [title],
        this.navigationService.getAppName(),
      );
    }
  }

  public ngOnDestroy(): void {
    this.actionPanelService.setAdditional([]);
    this.renderer.removeStyle(
      this.document.getElementById('main-area'),
      'overflow-x',
    );
  }

  /** Opens popup with form of column header.  */
  public openColumnForm(event: MouseEvent): void {
    this.boardService.opensColumnForm(
      event.target as HTMLElement,
      null,
      'create',
    );
  }

  /**
   * Sends `orderBy` to the board service.
   *
   * @param orderBy result from `tmt-board-sort-button`.
   */
  public sortChange(orderBy: string): void {
    this.boardService.sortChange$.next(orderBy);
  }

  private initCreateMenuService(): void {
    this.createMenuService.isReloadState = false;
    this.createMenuService.routeMode = RouteMode.continue;
    this.createMenuService.customNavigation =
      this.navigationService.selectedNavigationItem?.name;
  }

  private initActions(): void {
    if (
      this.boardService.config.offCanvasComponent &&
      this.boardService.config.creationComponent
    ) {
      this.actionPanelService.set([
        this.actionPanelService.getDefaultAction('create', {
          handler: () => {
            const modalRef = this.modalService.open(
              this.boardService.config.creationComponent,
            );
            modalRef.componentInstance.openCardAfterCreation = false;
            modalRef.result.then(
              (entityId: string) => {
                this.boardService.openOffCanvas(entityId);
                this.actionPanelService.reload();
              },
              () => null,
            );
          },
          isVisible: true,
        }),
      ]);
    } else if (this.boardService.config.createMenuButton) {
      const button = this.createMenuService.items$
        .getValue()
        .find(
          (item) => item.name === this.boardService.config.createMenuButton,
        );

      if (button) {
        this.actionPanelService.set([
          this.actionPanelService.getDefaultAction('create', {
            handler: () => button.handle(),
            isVisible: true,
          }),
        ]);
      }
    }

    this.actionPanelService.setAdditional([
      {
        name: 'openCardBuilder',
        title: 'components.boardComponent.actions.openCardBuilder',
        hint: 'components.boardComponent.actions.openCardBuilder',
        isBusy: false,
        isVisible: this.boardService.config.editAllowed,
        handler: () => this.boardService.openCardBuilder(),
      },
      {
        name: 'openSettings',
        title: 'components.boardComponent.actions.openSettings',
        hint: 'components.boardComponent.actions.openSettings',
        isBusy: false,
        isVisible: this.boardService.config.editAllowed,
        handler: () => this.boardService.openSettings(),
      },
      {
        name: 'deleteBoard',
        title: 'components.boardComponent.actions.deleteBoard',
        hint: 'components.boardComponent.actions.deleteBoard',
        isBusy: false,
        isVisible: this.boardService.config.editAllowed,
        handler: () => this.boardDataService.removeBoard(),
      },
    ]);
  }

  private initSubscribes(): void {
    this.boardService.loading$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.columns = this.boardService.columns?.slice(0);
      });

    this.boardService.event$
      .pipe(
        filter(
          (event) => event.target === 'board' && event.action === 'updated',
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.columns = this.boardService.columns?.slice(0);
        this.cdr.markForCheck();
      });

    this.dragDropService.onItemUpdate$
      .pipe(
        filter((v) => !!v && v.fromGroupName === this.ddOptions.group.name),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.boardService.updateColumnsOrder();
        this.columns = this.boardService.columns?.slice(0);
        this.cdr.markForCheck();
      });
  }
}
