import { Inject, Injectable } from '@angular/core';
import { flatten, sumBy } from 'lodash';
import { DateTime, DurationLike, Interval } from 'luxon';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { ScheduleNavigationContext } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-context.enum';
import {
  Slot,
  SlotGroup,
} from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectCardService } from '../../../core/project-card.service';
import { ProjectVersionCardService } from '../../../core/project-version-card.service';
import { ResourcePlannerSettings } from '../../models/resource-planner-settings.model';
import { ProjectResourcesEstimateCalendarDataService } from './project-resources-estimate-calendar-data.service';
import { ProjectResourcesCalendarCommandService } from '../../shared/core/project-resources-calendar-command.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo/undo-redo.service';

@Injectable()
export class ProjectResourcesEstimateCalendarService {
  // Subscriptions, Observables and Subjects
  private subscriptions: Subscription[] = [];

  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  private toggleSubject = new Subject<string>();
  public toggle$ = this.toggleSubject.asObservable();

  public loading$ = new BehaviorSubject<boolean>(true);
  public frameLoading$ = new BehaviorSubject<boolean>(false);

  // View
  public leftTableWidth = 410;
  public rightTableWidth;

  // State
  public readonly: boolean;

  public slots: Slot[];
  public slotGroups: SlotGroup[];
  public slotTotals = [];

  /** Current slot width in px. */
  public slotWidth: number;

  private _settings: ResourcePlannerSettings;
  private set settings(settings: ResourcePlannerSettings) {
    this._settings = settings;
    this.slotWidth = this.getSlotWidth();
  }
  private get settings(): ResourcePlannerSettings {
    return this._settings;
  }

  private interval: Interval;

  public get planningScale(): PlanningScale {
    return this.settings.planningScale;
  }
  public get valueMode(): ValueMode {
    return this.settings.valueMode;
  }

  public get totalHours(): number {
    return sumBy(this.dataService.groups, 'totalHours');
  }
  public get totalCost(): number {
    return sumBy(this.dataService.groups, 'totalCost');
  }

  constructor(
    @Inject('entityId') public projectId,
    private dataService: ProjectResourcesEstimateCalendarDataService,
    private navigationService: ScheduleNavigationService,
    private localConfigService: LocalConfigService,
    private projectCardService: ProjectCardService,
    private versionCardService: ProjectVersionCardService,
    private commandService: ProjectResourcesCalendarCommandService,
    private autosave: SavingQueueService,
    private freezeTableService: FreezeTableService,
    private blockUI: BlockUIService,
    private undoRedoService: UndoRedoService,
  ) {}

  public init() {
    // Navigation
    this.navigationService.init(ScheduleNavigationContext.Resources);
    this.settings = this.localConfigService.getConfig(ResourcePlannerSettings);
    this.subscriptions.push(
      this.navigationService.next$.subscribe(() =>
        this.dataService.save().then(() => this.loadFrame('right')),
      ),
      this.navigationService.previous$.subscribe(() =>
        this.dataService.save().then(() => this.loadFrame('left')),
      ),
      this.navigationService.jump$.subscribe((date) =>
        this.dataService.save().then(() => this.reload(date)),
      ),
      this.navigationService.valueMode$.subscribe((mode) =>
        this.dataService.save().then(() => this.reload()),
      ),
      this.navigationService.planningScale$.subscribe((scale) =>
        this.dataService.save().then(() => this.reload()),
      ),
      this.projectCardService.reloadTab$.subscribe(() =>
        this.dataService.save().then(() => this.reload()),
      ),
    );

    // Changes
    this.subscriptions.push(
      this.projectCardService.project$.subscribe((project: Project) => {
        this.updateReadOnly(project.resourcePlanEditAllowed);
      }),
      this.commandService.changes$.subscribe(() => this.reload()),
      this.autosave.save$.subscribe((data) => {
        if (data.warnings) {
          this.commandService.showWarnings(data.warnings);
        }

        if (data.resourcePlanEntry) {
          this.load(true);
          return;
        }

        const updatedTasks = data.tasks ?? data.projectTask;
        if (updatedTasks) {
          this.dataService.updateTasksDates(updatedTasks, this.planningScale);
        }

        if (data.entries) {
          this.updateSavedEntries(data.entries);
        }
      }),
    );

    this.reload();

    this.undoRedoService.setSavingQueue(this.autosave);
  }
  public dispose() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /**
   * Сабжект раскрытия групп.
   *
   * @param id - id группы.
   */
  public toggleGroup(id: string) {
    this.toggleSubject.next(id);
  }

  /**
   * Возвращает ширину таблицы.
   *
   * @param slotWidth - ширина одной ячейки. Необязательный, иначе используется функция сервиса getSlotWidth().
   * @param countOfSlots - количество ячеек. Необязательный, иначе используется переменная сервиса slots.
   */
  public getTableWidth(slotWidth?: number, countOfSlots?: number): number {
    if (!slotWidth) {
      slotWidth = this.slotWidth;
    }
    if (!countOfSlots) {
      countOfSlots = this.slots.length;
    }

    return slotWidth * countOfSlots;
  }

  /** Открывает окно конфигурации сдвига плана. */
  public movePlan() {
    this.dataService.save().then(
      () => {
        this.commandService
          .movePlan(this.projectId, this.dataService.teamMembers as any[])
          .then(
            () => this.reload(),
            () => null,
          );
      },
      () => null,
    );
  }

  /** Обновляет ячейки итогов по слотам */
  public updateSlotTotals() {
    this.dataService.groups.forEach((group) => {
      group.totals.forEach((t) => {
        t.hours = 0;
        t.cost = 0;
      });

      group.lines.forEach((line) => {
        line.totalHours = line.extraTotal;
        for (
          let entryIndex = 0;
          entryIndex < line.entries.length;
          entryIndex++
        ) {
          const entry = line.entries[entryIndex];
          if (entry.hours > 0) {
            group.totals[entryIndex].hours += entry.hours;
            group.totals[entryIndex].cost += entry.cost;
            line.totalHours += entry.hours;
          }
        }
      });

      group.totalHours = sumBy(group.lines, 'totalHours');
      group.totalCost = sumBy(group.lines, 'totalCost');
    });

    const totals = flatten(this.dataService.groups.map((g) => g.totals));
    this.slotTotals = this.slots.map((s) => {
      const slotTotals = totals.filter((t) => t.slotId === s.id);
      return {
        id: s.id,
        hours: sumBy(slotTotals, 'hours'),
        cost: sumBy(slotTotals, 'cost'),
        nonWorking: false,
      };
    });
  }

  public clearPlan() {
    this.dataService.save().then(() => {
      this.commandService.clearPlan();
    });
  }

  /**
   * Determines and returns slot width.
   *
   * @returns current slot width.
   */
  private getSlotWidth(): number {
    if (!this.settings?.planningScale) return 0;
    switch (this.settings.planningScale) {
      case PlanningScale.Day:
        return 55;
      case PlanningScale.Week:
        return 75;
      case PlanningScale.Month:
        return 90;
      case PlanningScale.Quarter:
        return 120;
      case PlanningScale.Year:
        return 90;
    }
  }

  /** Загружает часть календаря */
  private loadFrame(direction: 'left' | 'right') {
    this.frameLoading$.next(true);
    this.blockUI.start();

    let shift: DurationLike;
    switch (this.settings.planningScale) {
      case PlanningScale.Day:
        shift = { weeks: 2 };
        break;
      case PlanningScale.Week:
        shift = { weeks: 10 };
        break;
      case PlanningScale.Month:
        shift = { month: 5 };
        break;
      case PlanningScale.Quarter:
        shift = { year: 1 };
        break;
      case PlanningScale.Year:
        shift = { year: 2 };
        break;
    }
    let loadingInterval =
      direction === 'left'
        ? Interval.fromDateTimes(
            this.interval.start.minus(shift),
            this.interval.start.minus({ days: 1 }),
          )
        : Interval.fromDateTimes(
            this.interval.end.plus({ days: 1 }),
            this.interval.end.plus(shift),
          );
    this.interval =
      direction === 'left'
        ? this.interval.set({
            start: this.interval.start.minus(shift),
          })
        : this.interval.set({
            end: this.interval.end.plus(shift),
          });
    if (
      this.settings.planningScale === PlanningScale.Month &&
      direction === 'right'
    ) {
      loadingInterval = loadingInterval.set({
        end: loadingInterval.end.endOf('month'),
      });
      this.interval = this.interval.set({
        end: this.interval.end.endOf('month'),
      });
    }
    this.updateDates();

    this.dataService
      .loadFrame(loadingInterval, this.settings.planningScale, this.slots)
      .then(() => {
        this.updateSlotTotals();
        this.frameLoading$.next(false);
        this.blockUI.stop();
        this.changesSubject.next();

        setTimeout(() => {
          this.freezeTableService.disableMutationObserver();
          if (direction === 'left') {
            this.freezeTableService.scrollToLeft();
          } else {
            this.freezeTableService.scrollToRight();
          }

          setTimeout(() => {
            this.freezeTableService.enableMutationObserver();
          }, 500);
        }, 10);
      });
  }

  /**
   * Перезагружает весь календарь.
   *
   * @param toDate - дата, к которой необходимо совершить переход.
   */
  private reload(toDate?: DateTime) {
    this.settings = this.localConfigService.getConfig(ResourcePlannerSettings);
    this.interval = this.navigationService.getInterval(
      this.settings.planningScale,
      toDate,
    );
    this.updateDates();
    this.load();
  }

  /**
   * Загружает данные для всего календаря.
   *
   * @param sectionType - тип секции.
   */
  private load(silent = false) {
    if (silent) {
      this.blockUI.start();
    } else {
      this.loading$.next(true);
    }

    this.dataService
      .loadResourceGroups(this.interval, this.planningScale, this.slots)
      .then(() => {
        this.updateSlotTotals();
        this.changesSubject.next();
        if (silent) {
          this.blockUI.stop();
        } else {
          this.loading$.next(false);
        }
      });
  }

  /** Обновляет даты используемые для отображения */
  private updateDates(toDate?: DateTime) {
    const slotInfo = this.navigationService.getSlots(
      this.interval,
      this.settings.planningScale,
    );

    this.slotGroups = slotInfo.groups;
    this.slots = slotInfo.slots;

    this.rightTableWidth = this.getTableWidth();
  }

  /** Updates saved entries in the calendar.
   *
   * @param entries saved entries.
   */
  private updateSavedEntries(entries: any[]): void {
    entries.forEach((saved) => {
      const group = this.dataService.groups.find(
        (g) => g.id === saved.teamMemberId,
      );
      if (!group) {
        return;
      }

      const taskLine = group.lines.find((l) => l.taskId === saved.taskId);
      const entry = taskLine?.entries.find((e) => e.date === saved.date);
      if (entry) {
        const prevCost = entry.cost;
        taskLine.totalCost += saved.cost - prevCost;
        entry.cost = saved.cost;
        entry.hours = saved.hours;
      }
    });
    this.updateSlotTotals();
    this.changesSubject.next();
  }

  /** Обновляет состояние "Только на чтение" */
  private updateReadOnly(resourcePlanEditAllowed: boolean) {
    this.readonly =
      !resourcePlanEditAllowed ||
      !this.versionCardService.projectVersion.editAllowed;
    this.commandService.readonly = this.readonly;
  }
}
