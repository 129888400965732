import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  Optional,
  Type,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { Grid2DataService } from 'src/app/debug-app/grid2-test/core/grid2-data.service';
import { Grid2TestRightSideTableComponent } from 'src/app/debug-app/grid2-test/grid2-test-right-side-table/grid2-test-right-side-table.component';
import { Grid2TestToolbarComponent } from 'src/app/debug-app/grid2-test/grid2-test-toolbar/grid2-test-toolbar.component';
import { GRID2_TEST_LIST } from 'src/app/debug-app/grid2-test/model/grid2-test.list';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { Grid2Component } from 'src/app/shared-features/grid2/grid.component';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation';
import { ListService } from 'src/app/shared/services/list.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'tmt-grid2-test',
  templateUrl: './grid2-test.component.html',
  styleUrl: './grid2-test.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    GridService,
    Grid2DataService,
    ListService,
    { provide: LIST, useValue: GRID2_TEST_LIST },
    { provide: VIEW_NAME, useValue: 'default' },
    ScheduleNavigationService,
  ],
})
export class Grid2TestComponent implements OnInit {
  @Input() isModalMode = false;

  public settingForm = this.fb.group({
    isShowRightSideComponent: false,
    isShowMultiselectColumn: null,
    selectionType: null,
  });

  public readonly selectionTypes = [
    { id: SelectionType.none.toString(), name: 'none' },
    { id: SelectionType.row.toString(), name: 'row' },
    { id: SelectionType.rows.toString(), name: 'rows' },
    { id: SelectionType.range.toString(), name: 'range' },
  ];

  public gridComponent: Type<unknown>;
  public gridComponentInputs: Record<string, any> = {};

  public gridOptions: Grid2Options = {
    resizableColumns: true,
    toolbar: Grid2TestToolbarComponent,
    selectionType: SelectionType.rows,
    multiSelectColumn: true,
    commands: [{ name: 'setUserView', handlerFn: () => this.setUserView() }],
    rowContextMenu: [
      {
        name: 'testLog',
        label: 'Log group value',
        handlerFn: (formGroup: UntypedFormGroup) =>
          console.log(formGroup.value),
      },
    ],
    view: this.listService.getGridView(),
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    public dataService: Grid2DataService,
    public gridService: GridService,
    private fb: FormBuilder,
    private listService: ListService,
    private actionPanelService: ActionPanelService,
    private cdr: ChangeDetectorRef,
    @Optional() private modal: NgbModal,
    @Optional() private activeModal: NgbActiveModal,
  ) {}

  public ngOnInit(): void {
    this.dataService.initMockData();
    this.initGridComponent();

    this.patchSettingForm();
    this.subscribeSettingForm();

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dataService.formArray.clear();
        this.cdr.detectChanges();
        this.gridService.detectChanges();
        this.dataService.initMockData();
      });

    this.gridService.detectChanges$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cdr.detectChanges());
  }

  /** Opens grid in the modal. */
  public showInModal(): void {
    const ref = this.modal.open(Grid2TestComponent);
    ref.componentInstance.isModalMode = true;
    ref.result.then(
      () => null,
      () => null,
    );
  }

  /** Closes modal. */
  public closeModal() {
    this.activeModal?.dismiss('cancel');
  }

  /** Opens view configuration dialog. */
  private setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
      },
      () => null,
    );
  }

  private initGridComponent() {
    this.gridComponent = Grid2Component;
    this.gridComponentInputs = {
      formArray: this.dataService.formArray,
      options: this.gridOptions,
    };
    this.cdr.detectChanges();
  }

  private rebuildGridComponent(): void {
    this.gridComponent = null;
    this.gridComponentInputs = {};
    this.cdr.detectChanges();
    this.initGridComponent();
  }

  private patchSettingForm(): void {
    this.settingForm.controls.selectionType.patchValue(
      this.selectionTypes.find(
        (t) => t.id === this.gridOptions.selectionType.toString(),
      ),
    );
    this.settingForm.controls.isShowMultiselectColumn.patchValue(
      this.gridOptions.multiSelectColumn,
    );
  }

  private subscribeSettingForm(): void {
    this.settingForm.controls.selectionType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selectionType: { id: string; name: string }) => {
        this.gridOptions.selectionType = +selectionType.id;

        this.rebuildGridComponent();
      });

    this.settingForm.controls.isShowMultiselectColumn.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isShowMultilineColumn: boolean) => {
        this.gridOptions.multiSelectColumn = isShowMultilineColumn;

        this.rebuildGridComponent();
      });

    this.settingForm.controls.isShowRightSideComponent.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isShowRightSideComponent: boolean) => {
        if (isShowRightSideComponent) {
          this.gridOptions.rightSideComponent =
            Grid2TestRightSideTableComponent;
        } else {
          this.gridOptions.rightSideComponent = null;
        }
        this.rebuildGridComponent();
      });
  }
}
