import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BuySubscriptionModalComponent } from './buy-subscription-modal/buy-subscription-modal.component';
import { Exception } from 'src/app/shared/models/exception';
import {
  TenantBillingType,
  TenantOverview,
  TenantState,
} from 'src/app/shared/models/entities/settings/multitenant/tenant-overview.model';
import { BehaviorSubject, Subject, forkJoin, of } from 'rxjs';
import {
  LicenseInfo,
  LicenseProductInfo,
} from 'src/app/shared/models/entities/settings/license-info.model';
import { ProductSelectorModalComponent } from 'src/app/shared/components/features/product-selector-modal/product-selector-modal.component';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';

@Component({
  selector: 'tmt-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
  public cardState = CardState;

  public isOnPrem = environment.onprem;
  public cardState$ = new BehaviorSubject<CardState>(CardState.Loading);

  public allowBillingManage = false;
  public tenant: TenantOverview;
  public licenseInfo: LicenseInfo;
  public licenseProducts: LicenseProductInfo[];

  public tenantState = TenantState;
  public billingType = TenantBillingType;
  private destroyed$ = new Subject<void>();

  constructor(
    public app: AppService,
    private data: DataService,
    private actionService: ActionPanelService,
    private modal: NgbModal,
    private notification: NotificationService,
  ) {
    actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  ngOnInit() {
    this.allowBillingManage = this.app.checkPermission(
      'ManageBilling',
      null,
      PermissionType.Execute,
    );
    if (!this.isOnPrem) {
      this.actionService.set([
        {
          name: 'buy',
          title: 'settings.account.actions.buy',
          hint: 'settings.account.actions.buy',
          isDropDown: false,
          iconClass: 'bi bi-cart',
          isBusy: false,
          isVisible: this.allowBillingManage,
          handler: () => {
            this.buy();
          },
        },
      ]);
    }

    this.reload();
  }

  reload() {
    this.cardState$.next(CardState.Loading);

    forkJoin({
      tenantOverview: this.isOnPrem
        ? of(null)
        : this.data
            .singleton('Tenant')
            .function('GetOverview')
            .get<TenantOverview>(),
      licenseInfo: this.data.model
        .function('GetLicenseInfo')
        .get<LicenseInfo>(),
    }).subscribe({
      next: (response) => {
        this.tenant = response.tenantOverview;
        this.licenseInfo = response.licenseInfo;
        this.licenseProducts = response.licenseInfo.products.filter(
          (x) => x.limit > 0 || x.used > 0,
        );

        this.cardState$.next(CardState.Ready);

        this.actionService.action('selectProducts').isShown =
          this.tenant?.billingType === TenantBillingType.trial &&
          this.app.checkPermission(
            'ManageBilling',
            null,
            PermissionType.Execute,
          );
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.cardState$.next(CardState.Error);
      },
    });
  }

  public selectProducts() {
    const ref = this.modal.open(ProductSelectorModalComponent, {
      backdrop: true,
    });

    const instance = ref.componentInstance as ProductSelectorModalComponent;
    instance.licenseProducts = this.licenseInfo.products;

    ref.result.then(
      () => {
        this.reload();
      },
      () => null,
    );
  }

  public buy() {
    const ref = this.modal.open(BuySubscriptionModalComponent, { size: 'lg' });
    ref.result.then(
      () => {
        this.reload();
      },
      () => null,
    );
  }
}
