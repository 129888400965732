import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleNavigationModule } from 'src/app/shared-features/schedule-navigation/schedule-navigation.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectRbcSharedModule } from '../shared/project-rbc-shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { ProjectRbcCalendarComponent } from './project-rbc-calendar.component';
import { ProjectRbcCalendarToolbarComponent } from './shared/project-rbc-calendar-toolbar/project-rbc-calendar-toolbar.component';
import { ProjectRbcCalendarRightGroupComponent } from './shared/project-rbc-calendar-right-group/project-rbc-calendar-right-group.component';
import { ProjectRbcCalendarLeftGroupComponent } from './shared/project-rbc-calendar-left-group/project-rbc-calendar-left-group.component';
import { ProjectRbcCalendarSlotInfoComponent } from './shared/project-rbc-calendar-slot-info/project-rbc-calendar-slot-info.component';
import { ProjectRbcCalendarGroupFilterComponent } from './shared/project-rbc-calendar-group-filter/project-rbc-group-filter.component';
import { RevenueEstimatesModalComponent } from './revenue-estimates-modal/revenue-estimates-modal.component';

@NgModule({
  declarations: [
    ProjectRbcCalendarComponent,
    ProjectRbcCalendarRightGroupComponent,
    ProjectRbcCalendarToolbarComponent,
    ProjectRbcCalendarLeftGroupComponent,
    ProjectRbcCalendarSlotInfoComponent,
    ProjectRbcCalendarGroupFilterComponent,
    RevenueEstimatesModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ScheduleNavigationModule,
    ProjectRbcSharedModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.rbc.calendar',
          url: '/calendar',
          component: ProjectRbcCalendarComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
  exports: [ProjectRbcCalendarComponent],
})
export class ProjectRbcCalendarModule {}
