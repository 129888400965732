import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

/** Represents a Project Summary View Settings. */
export class ProjectSummaryViewSettings implements BaseSettings {
  public readonly name = 'ProjectSummaryViewSettings';
  public version: number;
  /** Planning Scale. */
  public planningScale: PlanningScale;
  /** Value Mode. */
  public valueMode: ValueMode;

  public getDefault(): ProjectSummaryViewSettings {
    return {
      version: 1,
      planningScale: PlanningScale.Week,
      valueMode: ValueMode.Hours,
    } as ProjectSummaryViewSettings;
  }
}
