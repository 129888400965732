<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.account.productSelector.header' | translate }}
    </h3>
    <button
      [disabled]="saving$ | async"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body" [formGroup]="form">
    <label class="mb-3 text-body-secondary">{{
      'settings.account.productSelector.help' | translate
    }}</label>

    <div class="form-group">
      <label class="mb-2">{{ 'settings.account.productSelector.products' | translate }}</label>
      <div class="form-check" *ngFor="let product of licenseProducts">
        <input
          [id]="product.product"
          [formControlName]="product.product"
          type="checkbox"
          class="form-check-input"
        />
        <label class="form-check-label" for="{{ product.product }}">
          {{ product.displayName }}
        </label>
      </div>
    </div>

    <button class="btn btn-link btn-action mt-4" tmtDocsArticle="get-started/timetta-apps">
      <i class="bi bi-question-circle pe-1"></i
      >{{ 'settings.account.productSelector.helpLink' | translate }}
    </button>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      [isDisabled]="selectedProducts.length === 0"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="saving$ | async"
    >
      {{ 'settings.account.productSelector.actions.apply' | translate }}
    </button>
  </div>
</form>
