import { Directive } from '@angular/core';
import { ElementRef } from '@angular/core';

@Directive({
  selector: '[radioButton]',
})
export class RadioButtonDirective {
  constructor(private element: ElementRef) {}

  ngOnInit() {
    (this.element.nativeElement as HTMLElement).style.position = 'absolute';
    (this.element.nativeElement as HTMLElement).style.width = '0';
  }
}
