@if (isLoading$ | async) {
  <wp-loading-indicator [loading]="true" />
} @else {
  <form class="modal-form" [formGroup]="form" novalidate autocomplete="off" ngDraggable>
    <div class="modal-header">
      <h3 class="modal-title">
        {{ tariff?.name ?? 'projects.projects.tariffs.modal.header.create' | translate }}
      </h3>
      <button
        [disabled]="isSaving$ | async"
        type="button"
        class="btn-close"
        data-dismiss="modal"
        aria-hidden="true"
        (click)="cancel()"
      ></button>
    </div>

    <div class="modal-body">
      <div class="form-group" tmtIndicator>
        <label>{{ 'shared.props.name' | translate }}</label>
        <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}" />
      </div>

      <div class="form-group">
        <label>{{ 'projects.projects.tariffs.columns.assignments.header' | translate }}</label>
        <wp-members-box
          formControlName="assignments"
          placeholder="{{ 'projects.projects.tariffs.columns.assignments.header' | translate }}"
          [projectVersion]="this.projectVersionCardService.projectVersion"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared.props.description' | translate }}</label>
        <wp-multiline-text-box
          formControlName="description"
          placeholder="{{ 'shared.props.description' | translate }}"
        />
      </div>

      <hr />

      <div class="form-group" [formGroup]="form.get('initialRate')">
        <label>{{ 'projects.projects.tariffs.modal.props.tariff' | translate }}</label>
        <wp-currency-box formControlName="value" />
        <span class="form-text text-body-secondary">
          {{ 'projects.projects.tariffs.messages.initialRate' | translate }}
        </span>
      </div>

      <div class="form-group">
        <tmt-tariff-rates [rates]="rates" [readonly]="projectTariffsService.readonly" />
      </div>

      <div class="form-group form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
        <input id="isActive" formControlName="isActive" type="checkbox" class="form-check-input" />
        <label class="form-check-label" for="isActive">
          {{ 'shared.props.isActive.label' | translate }}
        </label>
      </div>
    </div>

    <div class="modal-footer">
      @if (!projectTariffsService.readonly) {
        <button
          type="button"
          class="btn btn-primary"
          (click)="ok()"
          wpButtonSpinner
          [isBusy]="isSaving$ | async"
        >
          {{ (tariff ? 'shared.actions.save' : 'shared.actions.create') | translate }}
        </button>
      }

      <button type="button" class="btn btn-default" (click)="cancel()">
        {{ 'shared.actions.cancel' | translate }}
      </button>
    </div>
  </form>
}
