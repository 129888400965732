<div class="toolbar-container">
  <div class="group">
    <button
      *ngIf="!(service.readonly$ | async)"
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.addLine' | translate }}"
      (click)="service.execute('create')"
    >
      <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
      {{ 'shared.actions.addLine' | translate }}
    </button>
  </div>
  <div class="group">
    <button
      *ngIf="service.canBeExecuted('openUserSchedule')"
      type="button"
      class="btn btn-default"
      title="{{ 'settings.users.card.schedules.actions.openPersonalSchedule' | translate }}"
      (click)="service.execute('openUserSchedule')"
    >
      <i class="bi bi-calendar3" aria-hidden="true"></i>
      {{ 'settings.users.card.schedules.actions.openPersonalSchedule' | translate }}
    </button>
  </div>
</div>
