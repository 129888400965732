import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Guid } from '../shared/helpers/guid';
import { DataService } from './data.service';

import { Exception } from '../shared/models/exception';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  Stopwatch,
  StopwatchState,
  StopwatchStopType,
} from '../shared/models/entities/base/stopwatch.model';
import { LogService } from './log.service';
import { DateTime } from 'luxon';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

/** Сервис управления счетчика времени. */
@Injectable({
  providedIn: 'root',
})
export class StopwatchService {
  private storageName = 'sharedStopwatch';

  public stopwatch: Stopwatch;

  private stopwatchSubject = new BehaviorSubject<Stopwatch>(null);
  /** Событие на любое обновление счетчика времени. */
  public stopwatch$ = this.stopwatchSubject.asObservable();

  private openPopupSubject = new Subject<void>();
  public openPopup$ = this.openPopupSubject.asObservable();

  private stopSubject = new Subject<void>();

  /** Событие на завершение счетчика. */
  public stop$ = this.stopSubject.asObservable();

  private externalUpdateSubject = new Subject<void>();
  /** Событие внешнего обновления счетчика. */
  public externalUpdate$ = this.externalUpdateSubject.asObservable();

  public autosaveService: SavingQueueService;

  /** Идентификатор сервиса (для синхронизации вкладок браузера). */
  public id: string = Guid.generate();

  constructor(
    private localStorageService: LocalStorageService,
    private data: DataService,
    private log: LogService,
  ) {
    // this.updateSharedStopwatch();
    this.updateStopwatch();
    this.stopwatch$.subscribe((stopwatch) => (this.stopwatch = stopwatch));

    this.localStorageService.observe(this.storageName).subscribe((newValue) => {
      if (
        newValue.tabId === this.id ||
        (!this.stopwatch && !newValue.stopwatch) ||
        this.stopwatch?.state === newValue.stopwatch?.state
      ) {
        return;
      }

      // Обновить таймшиты.
      this.externalUpdateSubject.next();

      this.updateStopwatch();
    });
  }

  public openPopup() {
    this.openPopupSubject.next();
  }

  /** Загрузить сведения об активном счетчике времени. */
  public updateStopwatch(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.data.model
        .function('GetStopwatchInfo')
        .get<Stopwatch>()
        .subscribe({
          next: (stopwatch) => {
            resolve();
            this.stopwatchSubject.next(stopwatch);
            this.updateSharedStopwatch();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public updateSharedStopwatch() {
    this.localStorageService.store(this.storageName, {
      tabId: this.id,
      stopwatch: this.stopwatch,
    });
  }

  /** Возвращает истекшее время в секундах. */
  private getTotalDurationValue(): number {
    if (!this.stopwatch) {
      return 0;
    }

    let duration = this.stopwatch.duration ?? 0;
    if (!duration) {
      duration = 0;
    }
    if (this.stopwatch.state !== StopwatchState.Paused) {
      duration +=
        DateTime.now().diff(
          DateTime.fromISO(this.stopwatch.startTime),
          'seconds',
        ).seconds / 3600;
    }

    if (duration < 0) {
      duration = 0;
    }

    return duration;
  }

  public getFormattedDuration(): [string, string] {
    const result: [string, string] = ['', ''];

    const duration = this.getTotalDurationValue();

    const hoursPart = Math.floor(duration);
    if (String(hoursPart).length === 1) {
      result[0] = '0' + hoursPart;
    } else {
      result[0] = String(hoursPart);
    }

    const minutesPart = Math.floor((duration % 1) * 60);
    if (String(minutesPart).length === 1) {
      result[1] = '0' + minutesPart;
    } else {
      result[1] = String(minutesPart);
    }

    return result;
  }

  // /*КОМАНДЫ*/

  /** Запуск счетчика. */
  public start(timeSheetLineId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.log.debug('Stopwatch is being started...');

      const start = () => {
        const data = {
          timeSheetLineId,
        };

        this.data.model
          .action('StartStopwatch')
          .execute(data)
          .subscribe({
            next: () => {
              this.updateStopwatch().then(
                () => {
                  resolve();
                },
                () => {
                  reject();
                },
              );

              this.log.debug('Stopwatch has been started.');
            },
            error: (error: Exception) => {
              reject(error.message);
            },
          });
      };

      if (this.autosaveService) {
        this.autosaveService.save().then(
          () => start(),
          () => null,
        );
      } else {
        start();
      }
    });
  }

  /** Приостановка счетчика. */
  public pause(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.log.debug('Stopwatch is being paused...');

      this.data.model
        .action('PauseStopwatch')
        .execute()
        .subscribe({
          next: () => {
            this.stopwatch.duration = this.getTotalDurationValue();
            this.stopwatch.state = StopwatchState.Paused;

            this.stopwatchSubject.next(this.stopwatch);

            this.log.debug('Stopwatch has been paused.');
            this.updateSharedStopwatch();
            resolve();
          },
          error: (error: Exception) => {
            this.updateStopwatch();
            reject(error.message);
          },
        });
    });
  }

  /** Возобновление счетчика. */
  public resume(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.log.debug('Stopwatch is being resumed...');

      this.data.model
        .action('ResumeStopwatch')
        .execute()
        .subscribe({
          next: () => {
            this.stopwatch.startTime = DateTime.now().toISO();
            this.stopwatch.state = StopwatchState.Started;

            this.stopwatchSubject.next(this.stopwatch);
            this.log.debug('Stopwatch has been resumed.');
            this.updateSharedStopwatch();

            resolve();
          },
          error: (error: Exception) => {
            this.updateStopwatch();
            reject(error.message);
          },
        });
    });
  }

  /** Завершение счетчика. */
  public stop(type: StopwatchStopType): Promise<void> {
    return new Promise((resolve, reject) => {
      this.log.debug('Stopwatch is being stopped...');
      const stop = () => {
        this.data.model
          .action('StopStopwatch')
          .execute({ stopwatchStopType: type })
          .subscribe({
            next: () => {
              resolve();
              this.stopSubject.next();
              this.stopwatchSubject.next(null);
              this.updateSharedStopwatch();
              this.log.debug(
                'Stopwatch has been stopped. Stopping type: ' + type,
              );
            },
            error: (error: Exception) => {
              this.updateStopwatch();

              reject(error.message);
            },
          });
      };

      this.autosaveService
        ? this.autosaveService.save().then(
            () => stop(),
            () => null,
          )
        : stop();
    });
  }
}

export interface StopwatchTabInfo {
  tabId: string;
  stopwatch: Stopwatch;
}
