import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { filter, takeUntil } from 'rxjs/operators';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { NotificationService } from 'src/app/core/notification.service';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { NavigationService } from 'src/app/core/navigation.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';

@Injectable()
export class EmployeeCardService {
  public state$ = new BehaviorSubject<CardState>(CardState.Ready);

  private userSubject = new BehaviorSubject<User>(null);
  public user$ = this.userSubject.asObservable().pipe(filter((p) => !!p));
  destroyed$ = new Subject<void>();

  constructor(
    @Inject('entityId') public entityId,
    private data: DataService,
    private notification: NotificationService,
    private navigationService: NavigationService,
    actionPanelService: ActionPanelService,
  ) {
    this.state$.next(CardState.Loading);
    this.load();

    actionPanelService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.load());
  }

  private load() {
    const query = {
      select: [
        'id',
        'name',
        'code',
        'certificatesEditAllowed',
        'certificatesViewAllowed',
        'skillsEditAllowed',
        'totalsViewAllowed',
        'userRolesEditAllowed',
      ],
    };

    this.data
      .collection('Users')
      .entity(this.entityId)
      .get(query)
      .subscribe({
        next: (user: User) => {
          this.userSubject.next(user);
          this.state$.next(CardState.Ready);

          if (
            this.navigationService.selectedNavigationItem?.name !== 'my.profile'
          ) {
            this.navigationService.addRouteSegment({
              id: this.entityId,
              title: user.name,
            });
          }
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);

          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notification.error(error.message);
          }
        },
      });
  }

  public dispose() {
    this.destroyed$.next();
  }
}
