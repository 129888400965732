<div *ngIf="(resourceRequestService.state$ | async) === 'Ready'" class="request-card__form">
  <tmt-resource-request-form-main
    [mode]="resourceRequestService.readonly ? 'result' : 'edit'"
  ></tmt-resource-request-form-main>
  <tmt-resource-request-calendar
    [mode]="resourceRequestService.readonly ? 'result' : 'edit'"
  ></tmt-resource-request-calendar>
</div>

<div *ngIf="(resourceRequestService.state$ | async) === 'Ready'" class="d-block my-4">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-underline mt-5">
    <li [ngbNavItem]="'activity'">
      <a ngbNavLink>
        {{ 'shared.comments.tabs.activity' | translate }}
      </a>
      <div *ngbNavContent class="wide-tab-container mt-3 request-card__comments">
        <wp-comments-tab
          [entityId]="entityId"
          [collection]="commentedEntityCollectionType.ResourceRequests"
        ></wp-comments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'attachments'">
      <a ngbNavLink>
        {{ 'shared.comments.tabs.attachments' | translate }}
      </a>
      <div *ngbNavContent class="wide-tab-container mt-3 request-card__comments">
        <wp-attachments-tab
          [entityId]="entityId"
          [collection]="commentedEntityCollectionType.ResourceRequests"
        ></wp-attachments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'linkedTasks'">
      <a ngbNavLink>
        {{ 'base.workflow.tab' | translate }}
      </a>
      <tmt-entity-workflow-tasks
        *ngbNavContent
        [entityId]="entityId"
        class="mt-2 d-block"
      ></tmt-entity-workflow-tasks>
    </li>
    <li [ngbNavItem]="'stateHistory'">
      <a ngbNavLink>
        {{ 'base.stateHistory.tab' | translate }}
      </a>
      <tmt-state-history
        [entityId]="entityId"
        *ngbNavContent
        class="mt-2 d-block"
      ></tmt-state-history>
    </li>
    <wp-sort class="ms-auto"></wp-sort>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
