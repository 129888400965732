<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-7">
      <div class="form-group">
        <label>
          {{ 'billing.invoices.list.filter.props.period.label' | translate }}
        </label>
        <wp-date-period-box
          formControlName="period"
          placeholder="{{ 'billing.invoices.list.filter.props.period.ph' | translate }}"
        />
      </div>
    </div>
    <div class="col-5 states-wrap">
      <tmt-state-select formControlName="states" collection="Invoices" class="states-wrap-inner" />
      <div class="form-check without-invoices">
        <input
          id="withoutInvoices"
          formControlName="withoutInvoices"
          type="checkbox"
          class="form-check-input"
        />
        <label class="form-check-label" for="withoutInvoices">
          {{ 'billing.summary.filter.withoutInvoices' | translate }}
        </label>
      </div>
    </div>
  </div>
</form>
