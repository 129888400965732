import { DestroyRef, Inject, inject, Injectable } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { Exception } from 'src/app/shared/models/exception';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { NotificationService } from 'src/app/core/notification.service';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { QueueEntry } from '../models/view-data/queue-entry.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { Total } from '../models/view-data/total.model';
import {
  ResourceGroup,
  ResourceRequestGroup,
} from '../models/view-data/resource-group.model';
import { TaskLine } from '../models/view-data/task-line.model';
import { DateHours } from 'src/app/shared/models/entities/date-hours.model';
import { Interval } from 'luxon';
import { ResourcePlanTaskData } from 'src/app/projects/card/project-resources/models/project-resources-data.model';
import { Slot } from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ResourceRequirementsData } from 'src/app/projects/card/project-resource-requirements/models/data/resource-requirements-data.model';
import { ResourceGroupData } from 'src/app/projects/card/project-resource-requirements/models/data/resource-group-data.model';
import _ from 'lodash';
import { ResourceViewGroupLineEntry } from 'src/app/projects/card/project-resources/models/project-resources-view.model';
import { ResourceRequestTemplate } from 'src/app/shared/models/entities/resources/resource-request.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';

@Injectable()
export class ResourceRequirementsDataService {
  /** Prevents sending of entriesToSave to saving queueService. */
  public isAddToSavePrevented: boolean;

  private loadingSubscription: Subscription;

  private entriesQueueId = Guid.generate();
  private entriesToSave: QueueEntry[] = [];
  private entriesToSaveScale: PlanningScale;

  public groups: ResourceGroup[] = [];
  public groupsByResourceRequest: ResourceRequestGroup[] = [];

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public projectId,
    private data: DataService,
    public autosave: SavingQueueService,
    private notification: NotificationService,
    private translateService: TranslateService,
  ) {}

  /** Service initialization. */
  public init() {
    this.autosave.delayDuration = 0;

    this.autosave.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.entriesToSave = this.entriesToSave.filter(
          (entryToSave: QueueEntry) =>
            !data.entries.some(
              (entry: QueueEntry) =>
                entry.date === entryToSave.date &&
                entry.taskId === entryToSave.taskId &&
                entry.teamMemberId === entryToSave.teamMemberId,
            ),
        );
        if (this.isAddToSavePrevented) {
          this.saveCurrentEntriesToSave();
        }
      });
  }

  public addEntryToQueue(
    entry: ResourceViewGroupLineEntry,
    taskId: string,
    teamMemberId: string,
    scale: PlanningScale,
  ) {
    let queueEntry = this.entriesToSave.find((e) => e.id === entry.id);

    if (!queueEntry) {
      queueEntry = {
        id: entry.id,
        date: entry.date,
        hours: null,
        taskId,
        teamMemberId,
      };

      this.entriesToSave = this.entriesToSave.filter(
        (entryToSave: QueueEntry) =>
          entryToSave.date !== queueEntry.date ||
          entryToSave.teamMemberId !== queueEntry.teamMemberId ||
          entryToSave.taskId !== queueEntry.taskId,
      );

      this.entriesToSave.push(queueEntry);
    }

    queueEntry.hours = entry.hours ?? null;

    const dataToSave = _.cloneDeep(this.entriesToSave);

    dataToSave.forEach((e) => {
      if (e.hours === null) {
        e.hours = 0;
      }
      delete e.id;
    });

    this.entriesToSaveScale = scale;

    if (!this.isAddToSavePrevented) {
      this.autosave.addToQueue(
        this.entriesQueueId,
        this.data
          .collection('Projects')
          .entity(this.projectId)
          .action('UpdateResourcePlan')
          .execute({
            scale,
            entries: dataToSave,
          }),
      );
      this.isAddToSavePrevented = true;
    }
  }

  /** Sends current entriesToSave to saving queue service. */
  public saveCurrentEntriesToSave() {
    if (!this.autosave.isSaving) {
      this.isAddToSavePrevented = false;
      const dataToSave = _.cloneDeep(this.entriesToSave);

      dataToSave.forEach((e) => {
        if (e.hours === null) {
          e.hours = 0;
        }
        delete e.id;
      });

      if (this.entriesToSave.length) {
        this.autosave.addToQueue(
          this.entriesQueueId,
          this.data
            .collection('Projects')
            .entity(this.projectId)
            .action('UpdateResourcePlan')
            .execute({
              scale: this.entriesToSaveScale,
              entries: dataToSave,
            }),
        );
      }
    }
  }

  public async save(): Promise<void> {
    await this.autosave.save();
    this.entriesToSave = [];
  }

  /* Загружает данные для определенного интервала */
  public loadFrame(
    interval: Interval,
    planningScale: PlanningScale,
    slots: Slot[],
  ): Promise<ResourceGroup[]> {
    return this.loadResourceGroups(interval, planningScale, slots, false);
  }

  public loadResourceGroups(
    interval: Interval,
    scale: PlanningScale,
    slots: Slot[],
    rebuild = true,
  ): Promise<ResourceGroup[]> {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    // Сохранить состояние групп.
    const expandedGroupIds = this.groups
      .filter((g) => g.isExpanded)
      .map((g) => g.id);

    const expandedEstimateGroupIds = this.groups
      .filter((g) => g.isEstimateExpanded)
      .map((g) => g.id);

    const expandedRequestsGroupIds = this.groupsByResourceRequest
      .filter((g) => g.isExpanded)
      .map((g) => g.id);

    if (rebuild) {
      this.groups = [];
    }
    const params = {
      scale: `WP.PlanningScale'${scale}'`,
      from: interval.start.toISODate(),
      to: interval.end.toISODate(),
    };

    return new Promise((resolve, reject) => {
      this.loadingSubscription = this.data
        .collection('Projects')
        .entity(this.projectId)
        .function('GetResourceRequirementsPlan')
        .query<ResourceRequirementsData>(params)
        .subscribe({
          next: (planData) => {
            this.buildGroups(planData, slots, scale);
            this.buildRequestGroups(planData);

            this.groupsByResourceRequest.forEach((r) => {
              r.isExpanded = expandedRequestsGroupIds.includes(r.id);
            });

            if (this.groupsByResourceRequest.length === 1) {
              this.groupsByResourceRequest[0].isExpanded = true;
            }

            this.groups.forEach((g) => {
              g.isExpanded = expandedGroupIds.includes(g.id);
              g.isEstimateExpanded = expandedEstimateGroupIds.includes(g.id);
            });

            resolve(this.groups);
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            reject();
          },
        });
    });
  }

  /**
   * Create resource request.
   *
   * @param teamMemberId TeamMember ID.
   * @returns Data from create request.
   *
   * */
  public async createResourceRequest(teamMemberId: string): Promise<unknown> {
    try {
      const [template, requestedHours] = await Promise.all([
        firstValueFrom(
          this.data
            .collection('ProjectTeamMembers')
            .entity(teamMemberId)
            .function('GetResourceRequestTemplate')
            .get<ResourceRequestTemplate>(),
        ),
        firstValueFrom(
          this.data
            .collection('ProjectTeamMembers')
            .entity(teamMemberId)
            .function('GetGroupedResourcePlanEntries')
            .query<DateHours[]>({
              scale: `WP.PlanningScale'${PlanningScale.Day}'`,
            }),
        ),
      ]);

      const dataToSave = {
        from: template?.from,
        to: template?.to,
        roleId: template?.role?.id ?? null,
        resourcePoolId: template?.resourcePool?.id ?? null,
        projectId: template?.project?.id ?? null,
        teamMemberId: template?.teamMemberId ?? null,
        gradeId: template?.grade?.id ?? null,
        levelId: template?.level?.id ?? null,
        locationId: template?.location?.id ?? null,
        name: '',
        note: '',
        resourceDescription: '',
        scale: PlanningScale.Day,
        requirementEntries: requestedHours,
      };

      return await firstValueFrom(
        this.data.collection('ResourceRequests').insert(dataToSave, {
          expand: 'state',
        }),
      );
    } catch (error) {
      this.notification.errorLocal('shared.unknownError');
      return null;
    }
  }

  /**
   * Gets group by team member.
   *
   * @param id Team member id.
   * @returns Group with Resource.
   */
  public getGroupByTeamMember(id: string): ResourceGroup {
    return this.groups.find((el) => el.teamMemberId === id);
  }

  private buildGroups(
    planData: ResourceRequirementsData,
    slots: Slot[],
    scale: PlanningScale,
  ): void {
    let viewGroups: ResourceGroup[] = [];
    planData.groups.forEach((dataGroup) => {
      const group = this.buildGroup(planData, dataGroup);
      group.totals = this.buildTotals(
        slots,
        scale,
        group.schedule,
        planData.fteSchedule,
        group.totals,
      );
      group.estimateTotals = this.buildTotals(
        slots,
        scale,
        group.schedule,
        planData.fteSchedule,
        group.estimateTotals,
      );
      if (group.resource.type === ResourceType.user) {
        group.availabilityHours = this.buildResourceEntries(
          group.availabilityHours,
          dataGroup.availabilityHours,
          scale,
          slots,
          group.schedule,
          planData.fteSchedule,
        );
        group.bookedHours = this.buildResourceEntries(
          group.bookedHours,
          dataGroup.bookedHours,
          scale,
          slots,
          group.schedule,
          planData.fteSchedule,
        );
      }
      const lines = this.buildLines(planData, dataGroup, group, scale, slots);
      group.tasks.push(...lines);
      const leadTaskLine = group.tasks.find((l) => !l.taskNumber);
      group.tasks = (leadTaskLine ? [leadTaskLine] : []).concat(
        group.tasks.filter((l) => l.taskNumber).sort(naturalSort('name')),
      );
      viewGroups.push(group);
    });

    const genericGroups = viewGroups
      .filter((g) => g.resource.type === ResourceType.generic)
      .sort(naturalSort('name'));
    const resourceGroups = viewGroups
      .filter((g) => g.resource.type === ResourceType.user)
      .sort(naturalSort('name'));
    viewGroups = [...genericGroups, ...resourceGroups];
    this.groups = viewGroups;
  }

  private buildGroup(
    planData: ResourceRequirementsData,
    dataGroup: ResourceGroupData,
  ): ResourceGroup {
    const resource = planData.resources.find(
      (t) => t.id === dataGroup.resourceId,
    );
    const teamMember = planData.teamMembers.find(
      (tm) => tm.id === dataGroup.teamMemberId,
    );
    const cachedGroup = this.groups.find(
      (g) => g.id === dataGroup.teamMemberId,
    );

    const group =
      cachedGroup ??
      ({
        resource,
        name: resource?.name,
        id: teamMember.id,
        teamMemberId: dataGroup.teamMemberId,
        isActive: dataGroup.isActive,
        bookedHoursTotal: dataGroup.bookedHoursTotal,
        bookedHours: [],
        estimateTotal: 0,
        totals: [],
        estimateTotals: [],
        availabilityHours: [],
        tasks: [],
        states: {
          selected: false,
          pending: false,
        },
      } as ResourceGroup);

    group.resourceRequests = dataGroup.resourceRequests;

    //TODO: check after added resources in API response for generic type groups
    const frameSchedule =
      dataGroup.resourceId && resource.type === ResourceType.user
        ? resource.schedule
        : planData.fteSchedule;

    group.schedule = _.uniqBy(
      [...(group.schedule ?? []), ...frameSchedule],
      'date',
    );

    return group;
  }

  private buildTotals(
    slots: Slot[],
    scale: PlanningScale,
    schedule: DateHours[],
    fteSchedule: DateHours[],
    totals: Total[],
  ): Total[] {
    const newTotals = [];
    slots.forEach((slot) => {
      const cachedTotal = totals.find((t) => t.slotId === slot.id);

      if (cachedTotal) {
        newTotals.push(cachedTotal);
        return;
      }

      const slotDate = slot.date.toISODate();
      const total = {
        id: Guid.generate(),
        slotId: slot.id,
        date: slotDate,
        hours: 0,
        nonWorking: false,
      } as Total;

      const scheduleDay = schedule.find((t) => t.date === slotDate);
      total.scheduleHours = scheduleDay?.hours ?? 0;

      if (scale === PlanningScale.Day) {
        total.nonWorking = !scheduleDay?.hours;
      }

      total.fteHours = fteSchedule.find((t) => t.date === slotDate).hours;

      newTotals.push(total);
    });

    return newTotals;
  }

  private buildResourceEntries(
    groupEntries: ResourceViewGroupLineEntry[],
    dataEntries: DateHours[],
    scale: PlanningScale,
    slots: Slot[],
    schedule: DateHours[],
    fteSchedule: DateHours[],
  ): ResourceViewGroupLineEntry[] {
    const entries = [];
    slots.forEach((slot) => {
      const slotDate = slot.date.toISODate();
      const groupEntry = groupEntries.find((t) => t.date === slotDate);
      const dataEntry = dataEntries.find((t) => t.date === slotDate);

      if (groupEntry) {
        if (dataEntry) {
          groupEntry.hours = dataEntry.hours;
        }
        entries.push(groupEntry);
        return;
      }

      const entry: ResourceViewGroupLineEntry = {
        id: Guid.generate(),
        date: slot.date.toISODate(),
        hours: dataEntry ? dataEntry.hours : 0,
        title: '',
        nonWorking: false,
        scheduleHours: 0,
        fteHours: 0,
        isActual: null,
      };

      const scheduleDay = schedule.find(
        (t) => t.date === slot.date.toISODate(),
      );
      if (scale === 'Day') {
        entry.nonWorking = !scheduleDay || scheduleDay.hours === 0;
      }

      entry.scheduleHours = scheduleDay ? scheduleDay.hours : 0;
      entry.fteHours = fteSchedule.find(
        (t) => t.date === slot.date.toISODate(),
      ).hours;

      entries.push(entry);
    });
    return entries;
  }

  private buildLines(
    planData: ResourceRequirementsData,
    dataGroup: ResourceGroupData,
    group: ResourceGroup,
    scale: PlanningScale,
    slots: Slot[],
  ): TaskLine[] {
    const lines = [];
    dataGroup.tasks.forEach((taskData) => {
      const task = planData.tasks.find((t) => t.id === taskData.taskId);

      const cachedLine = group.tasks.find((t) => t.taskId === taskData.taskId);

      const line =
        cachedLine ??
        ({
          id: Guid.generate(),
          total: taskData.totalHours,
          taskId: taskData.taskId,
          taskNumber: task.number,
          isActive: taskData.isActive,
          extraTotal: 0, // Сумма часов по задаче, помимо суммы за выбранный период.
          name: task.number ? task.number + ' ' + task.name : task.name,
          entries: [],
          isSummaryTask: task.isSummary,
        } as TaskLine);

      if (!cachedLine) {
        lines.push(line);
      }
    });

    [...group.tasks, ...lines].forEach((line: TaskLine) => {
      const taskData = dataGroup.tasks.find((t) => t.taskId === line.taskId);
      const entries = this.buildEntries(
        planData,
        group,
        taskData,
        line,
        scale,
        slots,
      );
      line.extraTotal = line.total - _.sumBy(entries, 'hours');
      line.entries = entries;
    });

    return lines;
  }

  private buildEntries(
    planData: ResourceRequirementsData,
    group: ResourceGroup,
    taskData: ResourcePlanTaskData,
    line: TaskLine,
    scale: PlanningScale,
    slots: Slot[],
  ) {
    const entries = [];
    slots.forEach((slot) => {
      const slotDate = slot.date.toISODate();
      const cachedEntry = line.entries.find((t) => t.date === slotDate);
      if (cachedEntry) {
        entries.push(cachedEntry);
        return;
      }

      const entry = {
        id: Guid.generate(),
        date: slotDate,
        hours: 0,
        nonWorking: false,
        scheduleHours: 0,
        fteHours: 0,
        limitHours: 24,
      } as ResourceViewGroupLineEntry;

      const plannedEntry = (taskData?.planEntries ?? []).find(
        (t) => t.date === slotDate,
      );
      if (plannedEntry) {
        entry.hours = plannedEntry.hours;
      }

      const scheduleDay = group.schedule.find((t) => t.date === slotDate);
      if (scale === PlanningScale.Day) {
        entry.nonWorking = !scheduleDay || scheduleDay.hours === 0;
      }

      entry.scheduleHours = scheduleDay?.hours ?? 0;
      entry.fteHours = planData.fteSchedule.find(
        (t) => t.date === slot.date.toISODate(),
      )?.hours;

      entries.push(entry);
    });

    return entries;
  }

  private buildRequestGroups(planData: ResourceRequirementsData): void {
    this.groupsByResourceRequest = planData.resourceRequestForGrouping;
    this.groupsByResourceRequest.forEach((g) => {
      g.selectedTeamMemberIds.unshift(g.teamMemberId);
    });
    this.groupsByResourceRequest.push({
      id: 'no-request',
      teamMemberId: null,
      name: this.translateService.instant(
        'projects.projects.card.resources.columns.withoutRequests',
      ),
      selectedTeamMemberIds: planData.groups
        .filter((g) => {
          let withoutRequest = true;

          for (const request of planData.resourceRequestForGrouping) {
            if (
              request.teamMemberId === g.teamMemberId ||
              request.selectedTeamMemberIds.includes(g.teamMemberId)
            ) {
              withoutRequest = false;
              break;
            }
          }

          return withoutRequest;
        })
        .map((g) => g.teamMemberId),
    });
  }
}
