<tr>
  <td
    *ngFor="let entry of group.entries; trackBy: trackById"
    class="group"
    (click)="openPopup(group, null, null, null, entry, $event)"
    [ngClass]="{
      'clickable-slot': isSlotEditable(group, entry),
      'expanded-group': group.isExpanded
    }"
  >
    {{ entry.amount | zeroToNull | number: '1.2-2' }}
  </td>
</tr>
<ng-container *ngIf="group.isExpanded && group.taskLines">
  <ng-container *ngFor="let taskLine of group.taskLines; trackBy: trackByTaskId">
    <tr>
      <td
        *ngFor="let taskEntry of taskLine.entries; trackBy: trackById"
        class="task-line"
        (click)="openPopup(group, taskLine, null, null, taskEntry, $event)"
        [ngClass]="{
          'clickable-slot': isSlotEditable(group, taskEntry)
        }"
      >
        {{ taskEntry.amount | zeroToNull | number: '1.2-2' }}
      </td>
    </tr>
    <ng-container *ngIf="taskLine.isExpanded">
      <ng-container *ngFor="let typeLine of taskLine.typeLines; trackBy: trackByTypeId">
        <tr>
          <td
            *ngFor="let typeEntry of typeLine.entries; trackBy: trackById"
            class="expense-line"
            (click)="openPopup(group, taskLine, typeLine, null, typeEntry, $event)"
            [ngClass]="{
              'clickable-slot': isSlotEditable(group, typeEntry),
              'expanded-group': typeLine.isExpanded && hasRules(group, typeLine),
              'expense-line-group': hasRules(group, typeLine)
            }"
          >
            {{ typeEntry.amount | zeroToNull | number: '1.2-2' }}
          </td>
        </tr>
        <ng-container *ngIf="typeLine.isExpanded && hasRules(group, typeLine)">
          <tr *ngIf="typeLine.innerLine">
            <td
              *ngFor="let typeEntry of typeLine.innerLine.entries; trackBy: trackById"
              class="inner-line expense-line"
              (click)="openPopup(group, taskLine, typeLine.innerLine, null, typeEntry, $event)"
              [ngClass]="{
                'clickable-slot': isSlotEditable(group, typeEntry)
              }"
            >
              {{ typeEntry.amount | zeroToNull | number: '1.2-2' }}
            </td>
          </tr>
          <ng-container *ngFor="let ruleLine of typeLine.rules; trackBy: trackByRuleId">
            <tr>
              <td
                *ngFor="let ruleEntry of ruleLine.entries; trackBy: trackById"
                class="rule-line"
                (click)="openPopup(group, taskLine, typeLine, ruleLine, ruleEntry, $event)"
                [ngClass]="{
                  'clickable-slot': isRuleSlotEditable(group, ruleLine.rule, ruleEntry),
                  'position-relative': ruleEntry.deviatesFromBase
                }"
              >
                <div class="rule-cell">
                  <span>{{ ruleEntry.amount | zeroToNull | number: '1.2-2' }}</span>
                  @if (ruleEntry.deviatesFromBase) {
                    <i
                      class="bi bi-exclamation-triangle text-danger fs-6"
                      [title]="ruleEntry.baseHint"
                    ></i>
                  }
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- Other -->
  <ng-container *ngIf="group.type !== ExpensesGroupType.estimate && group.otherLine.total">
    <tr>
      <td
        *ngFor="let taskEntry of group.otherLine.entries; trackBy: trackById"
        class="other-line task-line"
      >
        {{ (showOther ? taskEntry.amount : 0) | zeroToNull | number: '1.2-2' }}
      </td>
    </tr>
    <ng-container *ngFor="let typeLine of group.otherLine.typeLines; trackBy: trackByTypeId">
      <tr>
        <!-- Type line group. -->
        <td
          *ngFor="let typeEntry of typeLine.entries; trackBy: trackById"
          class="other-line expense-line"
          [ngClass]="{ 'expense-line-group': hasRules(group, typeLine) }"
        >
          {{ (showOther ? typeEntry.amount : 0) | zeroToNull | number: '1.2-2' }}
        </td>
      </tr>
      <!-- Inner type line and rules. -->
      <ng-container *ngIf="typeLine.isExpanded && hasRules(group, typeLine)">
        <tr *ngIf="typeLine.innerLine">
          <td
            *ngFor="let typeEntry of typeLine.innerLine.entries; trackBy: trackById"
            class="other-line inner-line expense-line"
          >
            {{ (showOther ? typeEntry.amount : 0) | zeroToNull | number: '1.2-2' }}
          </td>
        </tr>
        <tr *ngFor="let ruleLine of typeLine.rules; trackBy: trackByRuleId">
          <td
            *ngFor="let typeEntry of ruleLine.entries; trackBy: trackById"
            class="other-line rule-line"
          >
            {{ (showOther ? typeEntry.amount : 0) | zeroToNull | number: '1.2-2' }}
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
