import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { AppService } from 'src/app/core/app.service';
import { Exception } from 'src/app/shared/models/exception';
import { TimeOffBalanceProfileLine } from 'src/app/shared/models/entities/analytics/time-off-balance-profile-line.model';

@Injectable()
export class EmployeeTimeOffBalanceService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private timeOffBalanceLinesSubject = new BehaviorSubject<
    TimeOffBalanceProfileLine[]
  >([]);
  public timeOffBalanceLines$ = this.timeOffBalanceLinesSubject.asObservable();
  public settings: any;

  constructor(
    @Optional() @Inject('entityId') public entityId,
    private data: DataService,
    private notification: NotificationService,
    private app: AppService,
  ) {}

  public load() {
    this.loadingSubject.next(true);
    const userId = this.entityId ?? this.app.session.user.id;
    const query = {
      select: '*',
    };
    const params = {
      from: this.settings?.from ?? null,
      to: this.settings?.to ?? null,
    };

    this.data
      .collection('UserTotals')
      .entity(userId)
      .function('GetTimeOffBalance')
      .query<TimeOffBalanceProfileLine[]>(params, query)
      .subscribe({
        next: (lines: any[]) => {
          this.timeOffBalanceLinesSubject.next(lines);
          this.loadingSubject.next(false);
        },
        error: (error: Exception) => {
          this.loadingSubject.next(false);
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notification.error(error.message);
          }
        },
      });
  }
}
