import {
  ProjVersionMergeDetailsDto,
  ProjVersionMergeKpiEntry,
} from 'src/app/projects/project-versions/project-versions-merge-modal/models/proj-version-merge-details-dto.model';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Guid } from 'src/app/shared/helpers/guid';
import {
  ProjVersionMergeKpiKind,
  ProjVersionMergeKpiKinds,
} from 'src/app/projects/project-versions/project-versions-merge-modal/models/proj-version-merge-kpi-kind.model';
import { TranslateService } from '@ngx-translate/core';
import { ProjectVersionsMergeModalService } from 'src/app/projects/project-versions/project-versions-merge-modal/core/project-versions-merge-modal.service';
import { Subject } from 'rxjs';
import { WorkPipe } from 'src/app/shared/pipes/work.pipe';
import { DecimalPipe, PercentPipe } from '@angular/common';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { PercentPointPipe } from 'src/app/shared/pipes/percent-point.pipe';
import { takeUntil } from 'rxjs/operators';
import { ProjectVersionMergeOption } from '../../models/project-version-merge-option.model';

/**
 * Represents the MergeUpdateDetails Merge tool step Values tab content.
 * */
@Component({
  selector: 'wp-project-version-merge-details-kpi',
  templateUrl: './project-version-merge-details-kpi.component.html',
  styleUrls: ['./project-version-merge-details-kpi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectVersionMergeDetailsKpiComponent
  implements OnInit, OnDestroy
{
  rows = new Array<any>();

  /** Flag indicating whether the MergeUpdateDetails model has conflicts or not. */
  public hasConflicts = false;

  public readonly projectVersionsMergeModalKey =
    'projects.projectVersions.projectVersionsMergeModal';

  /** The KPI columns. */
  columns: Array<any> = [
    {
      name: 'kind',
      header: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.kpiColumns.kind`,
      hint: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.kpiColumns.kind`,
      width: '100%',
      visible: true,
    },
    {
      name: 'before',
      header: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.kpiColumns.before`,
      hint: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.kpiColumns.before`,
      align: 'text-end',
      width: '100%',
      visible: true,
    },
    {
      name: 'after',
      header: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.kpiColumns.after`,
      hint: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.kpiColumns.after`,
      align: 'text-end',
      width: '100%',
      visible: true,
    },
    {
      name: 'diff',
      header: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.kpiColumns.diff`,
      hint: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.kpiColumns.diff`,
      align: 'text-end',
      width: '100%',
      visible: true,
    },
  ];

  /** The KPI visible columns. */
  displayedColumns: Array<any> = [];

  private currencyCode: string;

  /** The available KPI kinds. */
  private kpiKinds = ProjVersionMergeKpiKinds;

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public mergeModalService: ProjectVersionsMergeModalService,
    translate: TranslateService,
    private cdRef: ChangeDetectorRef,
    private numberPipe: DecimalPipe,
    private workPipe: WorkPipe,
    private percentPipe: PercentPipe,
    private percentPointPipe: PercentPointPipe,
    private wpCurrencyPipe: WpCurrencyPipe,
  ) {
    this.kpiKinds.forEach((x) => (x.name = translate.instant(x.name)));
  }

  ngOnInit(): void {
    this.currencyCode = this.mergeModalService.currencyCode;
    this.mergeModalService.mergeUpdateDetails$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((details) => {
        this.hasConflicts = details.hasConflicts;
        this.fillForm(details.dto);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * Tracks ngFor columns by name.
   *
   * @param index The column index.
   * @param item The column.
   * @returns The column name.
   * */
  public trackColumn = (index: number, item: any) => item['name'];

  /**
   * Tracks ngFor rows by id.
   *
   * @param index The row index.
   * @param item The row.
   * @returns The row id.
   * */
  public trackRow = (index: number, item: any) => item['id'];

  /**
   * Fills the Project versions Merge Values form group.
   *
   * @param dto The Merge details DTO.
   * */
  private fillForm(dto: ProjVersionMergeDetailsDto) {
    this.rows = [];

    if (!this.hasConflicts) {
      const isCombine =
        ProjectVersionMergeOption.combine.code ===
        this.mergeModalService.paramSelectionValue.option;
      this.columns.forEach((c) => {
        c.visible = !(isCombine && ['before', 'diff'].includes(c.name));
      });
      this.displayedColumns = this.columns.filter((c) => c.visible);

      dto?.kpi.estimateEntries.forEach((entry) => {
        const kindName = this.kpiKinds.find((x) => x.code === entry.kind)?.name;
        const row: any = {
          id: Guid.generate(),
          kind: kindName,
          after: this.getEntryField(entry, 'after'),
        };
        if (!isCombine) {
          row.before = this.getEntryField(entry, 'before');
          row.diff = this.getEntryField(entry, 'diff');
        }

        this.rows.push(row);
      });
    }
    this.cdRef.detectChanges();
  }

  /**
   * Gets the KPI entry field transformed with the respective pipe depending on the KPI kind and field itself.
   *
   * @param entry The KPI entry.
   * @param fieldName The KPI entry field name.
   * @returns The KPI entry field transformed with the respective pipe.
   * */
  private getEntryField(entry: ProjVersionMergeKpiEntry, fieldName: string) {
    const fieldNameDiff = 'diff';
    switch (entry.kind) {
      case ProjVersionMergeKpiKind.hours.code:
        if (fieldName !== fieldNameDiff) {
          return this.workPipe.transform(entry[fieldName]);
        } else {
          return this.percentPipe.transform(
            this.getPercentValue(entry, fieldName),
          );
        }
      case ProjVersionMergeKpiKind.cost.code:
      case ProjVersionMergeKpiKind.revenue.code:
      case ProjVersionMergeKpiKind.billing.code:
      case ProjVersionMergeKpiKind.expenses.code:
        if (fieldName !== fieldNameDiff) {
          return this.wpCurrencyPipe.transform(
            entry[fieldName],
            this.currencyCode,
          );
        } else {
          return this.percentPipe.transform(
            this.getPercentValue(entry, fieldName),
          );
        }
      case ProjVersionMergeKpiKind.profitability.code:
        if (fieldName !== fieldNameDiff) {
          return this.percentPipe.transform(entry[fieldName]);
        } else {
          return this.percentPointPipe.transform(entry[fieldName], false);
        }
      default:
        return '';
    }
  }

  /**
   * Gets the KPI entry percent field value depending on the other KPI fields.
   *
   * @param entry The KPI entry.
   * @param fieldName The KPI entry field name.
   * @returns Infinity if before = 0, after != 0.
   * NaN if before = 0, after = 0.
   * */
  private getPercentValue(entry: ProjVersionMergeKpiEntry, fieldName: string) {
    if (entry.before === 0) {
      return entry.after !== 0 ? Infinity : NaN;
    }

    return entry[fieldName];
  }
}
