import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';

@Component({
  selector: 'wp-source-project-readonly-version-cell',
  templateUrl: './source-project-version-readonly-cell.component.html',
})
export class SourceProjectVersionReadonlyCellComponent
  implements GridTemplateCell
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  private readonly projectLabel = '';

  constructor(private translate: TranslateService) {
    this.projectLabel = this.translate.instant(
      'projects.projectVersions.list.projectLabel',
    );
  }

  getValue() {
    return this.control.value ?? this.projectLabel;
  }
}
