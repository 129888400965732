import { Injectable } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import _ from 'lodash';
import {
  MenuAction,
  MenuActionsGroup,
  SubAction,
} from 'src/app/shared/models/inner/menu-action';
import {
  LifecycleInfo,
  LifecycleInfoWorkflowStartAction,
  LifecycleInfoWorkflowTaskAction,
} from 'src/app/shared/models/entities/lifecycle/lifecycle-info.model';
import { DefaultActionType } from 'src/app/shared/components/chrome/action-panel/model/default-actions.model';

/** Action panel service. */
@Injectable({
  providedIn: 'root',
})
export class ActionPanelService {
  public defaultGroupName = 'default';
  public workflowTaskActionsGroupName = 'workflowTaskActions';
  public workflowCancelActionGroupName = 'workflowCancelAction';

  private runSubject = new Subject<MenuAction>();

  /** Emits menu action when user click "execute". */
  public run$ = this.runSubject.asObservable();

  private updatedSubject = new Subject<MenuActionsGroup[]>();
  public updated$ = this.updatedSubject.asObservable();

  private actionsVisibilitySubject = new BehaviorSubject<boolean>(true);
  public actionsVisibility$ = this.actionsVisibilitySubject.asObservable();

  /** Flat actions list.
   * Will be included in the default group. */
  public actions: MenuAction[] = [];

  /** Group of additional actions. */
  public additionalActions: SubAction[] = [];
  private additionalUpdatedSubject = new Subject<void>();
  public additionalUpdated$ = this.additionalUpdatedSubject.asObservable();

  /** Groups of actions. */
  private _groups: MenuActionsGroup[] = [];

  /** Action groups. */
  public get groups(): MenuActionsGroup[] {
    return this._groups.concat([
      { name: this.defaultGroupName, actions: this.actions },
    ]);
  }

  /** Свойство для восстановления кнопки перезагрузки после смены маршрута. */
  onSuccessTransitionEvent: any;

  constructor(private transitionService: TransitionService) {}

  /** Управление перезагрузкой форм. */
  private reloadSubject = new Subject<void>();
  public reload$ = this.reloadSubject.asObservable();
  private hasReloaderSubject = new BehaviorSubject<boolean>(true);
  public hasReloader$ = this.hasReloaderSubject.asObservable();

  /** Индикация автосохранения форм. */
  private hasAutosaveSubject = new BehaviorSubject<boolean>(false);
  public hasAutosave$ = this.hasAutosaveSubject
    .asObservable()
    .pipe(filter((el) => !!el));

  /** Сброс всех действий. */
  public reset() {
    this.actions.length = 0;
    this._groups.length = 0;
    this.additionalActions.length = 0;
    this.setHasAutosave(false);
    this.actionsVisibilitySubject.next(true);
    this.propagate();
  }

  /** Установка конфигурации панели. */
  public set(actions: MenuAction[]) {
    this._groups.length = 0;

    actions.forEach((action) => {
      if (action.actions) {
        action.actions = _.sortBy(action.actions, 'group');
      }
    });

    this.actions = actions;
    this.propagate();
  }

  /** Set additional actions in the action-panel. */
  public setAdditional(actions: SubAction[]): void {
    this.additionalActions.length = 0;
    this.additionalActions = _.sortBy(actions, 'group');
    this.additionalUpdatedSubject.next();
  }

  /** Add additional action in the action-panel. */
  public addAdditional(action: SubAction): void {
    this.additionalActions = _.sortBy(
      this.additionalActions.concat([action]),
      'group',
    );
    this.additionalUpdatedSubject.next();
  }

  /** Проталкивание обновления до UI. */
  public propagate() {
    this.updatedSubject.next(this.groups);
  }

  /** Возвращает конфигурацию действия. */
  private getAction(name: string): MenuAction {
    const flatActions: MenuAction[] = this.groups.flatMap((g) =>
      g.actions.flatMap((a) =>
        a.actions
          ? (a.actions.filter((x) => _.isObject(x)) as MenuAction[]).concat([a])
          : [a],
      ),
    );
    const action = flatActions.filter((a) => a.name === name)[0];

    if (!action) {
      return this.additionalActions.find((a) => a.name === name);
    }

    return action;
  }

  /** Возвращает объект управления действием. */
  public action(name: string): MenuActionMethods {
    const action: MenuAction = this.getAction(name);

    return new MenuActionMethods(action, this);
  }

  /** Установить Lifecycle действия.  */
  public registerLifecycle(lifecycleInfo: LifecycleInfo) {
    this.resetLifecycle();

    const getPanelActionForWorkflowTaskAction = (
      workflowAction: LifecycleInfoWorkflowTaskAction,
    ): MenuAction => ({
      hint: workflowAction.label,
      title: workflowAction.label,
      isBusy: false,
      iconClass: workflowAction.iconClass
        ? `bi ${workflowAction.iconClass}`
        : null,

      isVisible: true,
      name: 'performTask',
      lifecycle: {
        actionType: 'performTask',
        taskId: workflowAction.taskId,
        workflowActionId: workflowAction.id,
      },
    });

    const getPanelActionForWorkflowStartAction = (
      workflowAction: LifecycleInfoWorkflowStartAction,
    ): MenuAction => ({
      hint: workflowAction.label,
      title: workflowAction.label,
      isBusy: false,
      iconClass: workflowAction.iconClass
        ? `bi ${workflowAction.iconClass}`
        : 'bi bi-play-circle',
      isVisible: true,
      name: `startWorkflow`,
      lifecycle: {
        actionType: 'startWorkflow',
        workflowId: workflowAction.workflowId,
      },
    });

    // Create workflow group.
    if (
      lifecycleInfo.workflowTaskActions?.length ||
      lifecycleInfo.workflowStartActions?.length
    ) {
      const wfGroup: MenuActionsGroup = {
        name: this.workflowTaskActionsGroupName,
        actions: [],
      };
      this._groups.push(wfGroup);

      lifecycleInfo.workflowStartActions =
        lifecycleInfo.workflowStartActions.reverse();

      if (lifecycleInfo.workflowStartActions.length > 1) {
        const actions: MenuAction[] = [];

        lifecycleInfo.workflowStartActions.forEach((workflowAction) => {
          const action = getPanelActionForWorkflowStartAction(workflowAction);
          actions.splice(0, 0, action);
        });

        wfGroup.actions.push({
          title: 'shared.workflow.actionGroup',
          hint: 'shared.workflow.actionGroup',
          iconClass: 'bi bi-play-circle',
          actions,
          isBusy: false,
          isVisible: true,
          isDropDown: true,
          name: 'workflow-actions',
        });
      } else {
        lifecycleInfo.workflowStartActions
          .reverse()
          .forEach((workflowAction) => {
            const action = getPanelActionForWorkflowStartAction(workflowAction);
            wfGroup.actions.splice(0, 0, action);
          });
      }

      lifecycleInfo.workflowTaskActions.reverse().forEach((workflowAction) => {
        const action = getPanelActionForWorkflowTaskAction(workflowAction);
        wfGroup.actions.splice(0, 0, action);
      });
    }

    // Create cancel action.
    if (lifecycleInfo.canCancelWorkflow) {
      const wfGroup: MenuActionsGroup = {
        name: this.workflowCancelActionGroupName,
        actions: [],
      };
      const cancelLabel =
        lifecycleInfo.cancelLabel ?? 'shared.workflow.cancelWorkflow';
      wfGroup.actions.push({
        hint: cancelLabel,
        title: cancelLabel,
        isBusy: false,
        iconClass: 'bi bi-stop-circle',
        isVisible: true,
        name: 'cancelWorkflow',
        lifecycle: {
          actionType: 'cancelWorkflow',
        },
      });

      this._groups.push(wfGroup);
    }

    this.propagate();
  }

  /** Сбросить все Workflow действия.  */
  public resetLifecycle() {
    this._groups = this.groups.filter(
      (a) =>
        a.name !== this.workflowTaskActionsGroupName &&
        a.name !== this.workflowCancelActionGroupName &&
        a.name !== this.defaultGroupName,
    );
    this.propagate();
  }

  /** Выполнить метод действия. */
  public runAction(action: MenuAction) {
    this.runSubject.next(action);
    action.handler && action.handler();
  }

  /** Передает команду на перезагрузку для form header*/
  public reload(): void {
    this.reloadSubject.next();
  }

  /** Скрывает reloader до смены навигации */
  public hideReloader() {
    this.hasReloaderSubject.next(false);
    this.onSuccessTransitionEvent = this.transitionService.onSuccess({}, () => {
      this.hasReloaderSubject.next(true);
      this.onSuccessTransitionEvent();
    });
  }

  /** Устанавливает значение hasAutosave для наличия индикатора сохранения*/
  public setHasAutosave(hasAutosave: boolean): void {
    this.hasAutosaveSubject.next(hasAutosave);
  }

  /** Set the value of whether or not to show actions. */
  public setVisibility(value: boolean): void {
    this.actionsVisibilitySubject.next(value);
  }

  /**
   * Get a default action based on the specified action type and parameters.
   *
   * @param actionType - The type of default action to create ('create', 'card', 'delete', or 'setAsDefault').
   * @param params - Optional partial MenuAction object to override default values.
   * @returns A MenuAction object with default values set based on the action type, potentially overridden by params.
   */
  public getDefaultAction(
    actionType: DefaultActionType,
    params: Partial<MenuAction>,
  ): MenuAction {
    const action: MenuAction = {
      name: '',
      title: '',
      hint: '',
      iconClass: '',
      isBusy: false,
      isVisible: false,
      handler: null,
    };
    action.name = actionType;
    action.title = 'shared2.actions.' + actionType;
    switch (actionType) {
      case 'create':
        action.iconClass = 'bi bi-plus-lg bi-15';
        break;
    }
    if (params) {
      Object.keys(params).forEach((key) => (action[key] = params[key]));
    }
    if (!action.hint) {
      action.hint = action.title;
    }
    return action;
  }
}

export class MenuActionMethods {
  constructor(
    private action: MenuAction,
    private service: ActionPanelService,
  ) {}

  /** Статус "занято" (выполняет задачу). */
  public get isBusy(): boolean {
    return this.action ? this.action.isBusy : false;
  }
  public set isBusy(value: boolean) {
    if (value) {
      this.start();
    } else {
      this.stop();
    }
  }

  /** Статус "показывать". */
  public get isShown(): boolean {
    return this.action ? this.action.isVisible : false;
  }

  public set isShown(value: boolean) {
    if (value) {
      this.show();
    } else {
      this.hide();
    }
  }

  /** Показать действие. */
  show() {
    if (this.action) {
      this.action.isVisible = true;
      this.service.propagate();
    }
  }

  /** Выполнить действие. */
  execute() {
    if (this.action) {
      this.action.handler();
      this.service.propagate();
    }
  }

  /** Скрыть действие. */
  hide() {
    if (this.action) {
      this.action.isVisible = false;
      this.service.propagate();
    }
  }

  /** "Запустить" действие (показать индикатор). */
  start() {
    if (this.action) {
      this.action.isBusy = true;
      this.service.propagate();
    }
  }

  /** "Остановить" действие. */
  stop() {
    if (this.action) {
      this.action.isBusy = false;
      this.service.propagate();
    }
  }
}
