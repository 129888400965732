import {
  DestroyRef,
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/core';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  concatMap,
  filter,
  first,
  firstValueFrom,
  map,
  merge,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import _ from 'lodash';

import { AppService } from 'src/app/core/app.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { OffCanvasService } from 'src/app/core/off-canvas.service';
import { MenuService, MenuItem, MenuSubItem } from 'src/app/core/menu.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { BlockUIService } from 'src/app/core/block-ui.service';

import { LocalStringHelper } from 'src/app/shared/models/enums/language.enum';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DragAndDropData } from 'src/app/shared/directives/drag-and-drop/drag-and-drop.model';
import { DragDropService } from 'src/app/shared/services/drag-drop';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { MetaEntityPropertyKind } from 'src/app/shared/models/entities/settings/metamodel.model';

import { BoardCardViewProperties } from 'src/app/settings-app/boards/model/board.model';
import {
  BoardCardView,
  BoardEvent,
  BoardColumnView,
  BoardCardContext,
} from 'src/app/boards/models/board.interface';
import {
  BOARD_CONFIG,
  BoardConfig,
} from 'src/app/boards/models/board-config.interface';
import { BoardDataService } from 'src/app/boards/services/board-data.service';
import { BoardColumnHeaderFormComponent } from 'src/app/boards/components/board/column-header-form/board-column-header-form.component';
import { BoardMiniCardBuilderModalComponent } from 'src/app/boards/components/board/mini-card-builder-modal/board-mini-card-builder-modal.component';
import { SortItem } from 'src/app/boards/components/board/sort-button/board-sort-button.interface';

@Injectable()
export class BoardService implements OnDestroy {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  public event$ = new Subject<BoardEvent>();
  public columnsDependencies: Record<string, string[]> = {};
  public columns: BoardColumnView[] = [];
  public cards: BoardCardView[] = [];
  public cardsByColumns: Record<string, BoardCardView[]>;
  public sortItems: SortItem[] = [];
  public sortChange$ = new BehaviorSubject<string>(null);

  private groupBy = 'columnId';
  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(BOARD_CONFIG) public readonly config: BoardConfig | null,
    private boardDataService: BoardDataService,
    private offCanvasService: OffCanvasService,
    private notificationService: NotificationService,
    private actionPanelService: ActionPanelService,
    private filterService: FilterService,
    private dragDropService: DragDropService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private menuService: MenuService,
    private modal: NgbModal,
    private stateService: StateService,
    private navigationService: NavigationService,
    private blockUI: BlockUIService,
    private appService: AppService,
    private translateService: TranslateService,
  ) {
    this.initSubscriptions();
  }

  public ngOnDestroy(): void {
    this.infoPopupService.close();
  }

  /**
   * Opens card in `offCanvas` (aside).
   *
   * @param entityId  card entity id.
   */
  public openOffCanvas(entityId: string): void {
    this.offCanvasService.openOffCanvas(entityId, {
      data: {
        component: this.config.offCanvasComponent,
        componentParams: {
          inputs: {
            entityId,
          },
        },
      },
    });
  }

  /** Opens card builder modal. If modal result if successful, reloads board with new card structure.  */
  public openCardBuilder(): void {
    const modalRef = this.modal.open(BoardMiniCardBuilderModalComponent, {
      injector: this.injector,
    });

    modalRef.result.then(
      (result: BoardCardViewProperties[] | undefined) => {
        if (result) {
          this.loadBoard().subscribe();
        }
      },
      () => null,
    );
  }

  /** Changes state to board settings. */
  public openSettings(): void {
    this.stateService.go('settings.board', {
      entityId: this.config.id,
      routeMode: RouteMode.continue,
      navigation: this.navigationService.selectedNavigationItem?.name,
    });
  }

  /**
   * Loads board's states and tasks.
   *
   * @returns board card views.
   */
  public loadBoard(): Observable<BoardCardView<any>[]> {
    if (!this.config) {
      return of(null);
    }

    this.blockUI.start();

    return this.boardDataService.getBoardConfig().pipe(
      tap((columns) => {
        this.columns = columns;
        this.initColumnsDependencies();
        this.initColumnsActions();
        this.initSortItems();
      }),
      switchMap(() =>
        this.boardDataService.getCards(
          this.getFilter(),
          this.sortChange$.getValue(),
        ),
      ),
      tap((cards) => {
        this.cards = cards;
        this.cardsByColumns = _.groupBy(
          cards,
          (item) => `${item[this.groupBy]}`,
        );

        // TODO: not correct
        for (const column of this.columns) {
          if (!this.cardsByColumns[column.id]) {
            this.cardsByColumns[column.id] = [];
          }
        }

        this.initCardActions();
        this.event$.next({
          target: 'track',
          id: null,
          action: 'updated',
        });

        this.blockUI.stop();
      }),
      catchError((error) => {
        this.notificationService.error(error.message);
        this.blockUI.stop();
        return of(null);
      }),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  /**
   * Updates card after drop.
   *
   * @param cardId Card id.
   * @param newColumnCode New column after drop.
   */
  public async updateCard(
    cardId: string,
    data: DragAndDropData<BoardCardView>,
  ): Promise<void> {
    const card = this.cards.find((c) => c.id === cardId);

    if (data.fromGroupName === data.toGroupName) {
      if (data.oldIndex === data.newIndex) {
        return;
      }

      if (this.sortChange$.getValue()) {
        this.dragDropService.data = data;
        this.notificationService.warningLocal(
          'components.boardService.messages.sortingAlert',
        );

        this.event$.next({
          target: 'track',
          id: card.columnId,
          action: 'rollBackFrom',
          data,
        });

        this.event$.next({
          target: 'track',
          id: card.columnId,
          action: 'rollBackTo',
          data,
        });

        return;
      }

      this.boardDataService
        .updateCard(card.id, {
          columnId: card.columnId,
          index: data.newIndex,
        })
        .subscribe();

      return;
    }

    this.event$.next({
      target: 'track',
      id: null,
      action: 'disableDrag',
      data: true,
    });

    const newColumn = this.columns.find((c) => c.id === data.toGroupName);
    const stateChangeResult = await this.boardDataService.setState(
      card,
      newColumn.stateId,
    );

    if (stateChangeResult) {
      card.entity.stateId = newColumn.stateId;
      card.entity.state = newColumn.state;
      card.columnId = newColumn.id;

      this.boardDataService
        .updateCard(card.id, {
          columnId: card.columnId,
          index: data.newIndex,
        })
        .subscribe();
    } else {
      this.dragDropService.data = data;
      const oldColumn = this.columns.find((c) => c.id === data.fromGroupName);

      this.event$.next({
        target: 'track',
        id: newColumn.id,
        action: 'rollBackFrom',
        data,
      });

      this.event$.next({
        target: 'track',
        id: oldColumn.id,
        action: 'rollBackTo',
        data,
      });
    }

    this.event$.next({
      target: 'track',
      id: null,
      action: 'disableDrag',
      data: false,
    });

    this.boardDataService
      .getCards(
        {
          id: { type: 'guid', value: card.entity.id },
        },
        '',
      )
      .pipe(
        map((cards) => cards.pop()),
        tap((data) => Object.assign(card, data)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.event$.next({
          target: 'card',
          id: cardId,
          action: 'updated',
        });
      });
  }

  /**
   * Adds new column.
   *
   * @param data column properties.
   * @returns updated column and cards if save succeeded, otherwise `false`.
   */
  public addColumn(
    data: Partial<BoardColumnView>,
  ): Promise<BoardCardView[] | boolean> {
    const updatedColumns = this.columns.slice(0);
    const state = this.boardDataService.states.find(
      (state) => state.id === data.state.id,
    );

    updatedColumns.push({
      actions: [],
      id: state.id,
      stateId: state.id,
      header: data.header ?? state.name,
      style: state.style,
      index: this.columns.length,
    });

    return firstValueFrom(
      this.boardDataService.saveUpdatedColumns(updatedColumns).pipe(
        switchMap((v) => (v ? this.loadBoard() : of(v))),
        tap(() => {
          this.event$.next({ target: 'board', action: 'updated' });
        }),
      ),
    );
  }

  /**
   * Removes column.
   *
   * @param column Board column view.
   */
  public removeColumn(column: BoardColumnView): void {
    this.columns.splice(
      this.columns.findIndex((c) => c.id === column.id),
      1,
    );

    firstValueFrom(
      this.boardDataService.saveUpdatedColumns(this.columns).pipe(
        tap(() => {
          this.event$.next({ target: 'board', action: 'updated' });
        }),
      ),
    );
  }

  /**
   * Updates column.
   *
   * @param id column id.
   * @param data column properties.
   * @returns `true` if save succeeded, otherwise `false`.
   */
  public updateColumn(
    id: string,
    data: Partial<BoardColumnView>,
  ): Promise<boolean> {
    Object.assign(
      this.columns.find((column) => column.id === id),
      data,
    );

    this.event$.next({
      target: 'column',
      action: 'updated',
      id,
    });

    return firstValueFrom(
      this.boardDataService.saveUpdatedColumns(this.columns),
    );
  }

  /* Updates columns after drag and drop. */
  public updateColumnsOrder(): void {
    this.columns.forEach((column, index) => (column.index = index));
    firstValueFrom(this.boardDataService.saveUpdatedColumns(this.columns));
  }

  /**
   * Opens popup with column form.
   *
   * @param target popup target.
   * @param column board column view.
   * @param mode form mode.
   */
  public opensColumnForm(
    target: HTMLElement,
    column: BoardColumnView,
    mode: 'edit' | 'create',
  ): void {
    this.infoPopupService.open<BoardColumnHeaderFormComponent>({
      target,
      data: {
        component: BoardColumnHeaderFormComponent,
        componentParams: {
          inputs: {
            mode,
            column,
          },
          injector: this.injector,
        },
      },
      observeIntersectionDisabled: true,
    });
  }

  /**
   * Opens menu.
   *
   * @param event mouse event.
   * @param actions actions array.
   * @param context menu item context.
   */
  public openMenu(
    event: MouseEvent,
    actions: MenuItem[],
    context: BoardCardContext | HTMLElement,
  ): void {
    this.infoPopupService.close();
    this.menuService.open(event, actions, context);
    event.preventDefault();
    event.stopPropagation();
  }

  /** Inits card menu actions. */
  private initCardActions(): void {
    for (const cardItem of this.cards) {
      cardItem.actions = [
        {
          name: 'changeState',
          label: 'components.boardMiniCardComponent.actions.changeState',
          handlerFn: () => null,
          subActionsLazyResolver: (context) =>
            this.getChangeStateSubActions(context),
        },
        {
          name: 'moveToTop',
          label: 'components.boardMiniCardComponent.actions.moveToTop',
          iconClass: 'bi-arrow-bar-up',
          handlerFn: (card: { item: BoardCardView; oldIndex: number }) =>
            this.event$.next({
              target: 'track',
              id: card.item.columnId,
              action: 'moveThroughCardMenu',
              data: {
                oldIndex: card.oldIndex,
                newIndex: 0,
                item: card.item,
              },
            }),
          allowedFn: (card: { item: BoardCardView; oldIndex: number }) =>
            this.cardsByColumns[card.item.columnId].length > 1 &&
            !!card.oldIndex &&
            !this.sortChange$.getValue(),
        },
        {
          name: 'moveToBottom',
          label: 'components.boardMiniCardComponent.actions.moveToBottom',
          iconClass: 'bi-arrow-bar-down',
          handlerFn: (card: { item: BoardCardView; oldIndex: number }) =>
            this.event$.next({
              target: 'track',
              id: card.item.columnId,
              action: 'moveThroughCardMenu',
              data: {
                oldIndex: card.oldIndex,
                newIndex: this.cardsByColumns[card.item.columnId].length - 1,
                item: card.item,
              },
            }),
          allowedFn: (card: { item: BoardCardView; oldIndex: number }) =>
            this.cardsByColumns[card.item.columnId].length > 1 &&
            card.oldIndex <
              this.cardsByColumns[card.item.columnId].length - 1 &&
            !this.sortChange$.getValue(),
        },
      ];
    }
  }

  /** Inits card menu change state sub actions. */
  private async getChangeStateSubActions(
    context: BoardCardContext,
  ): Promise<MenuSubItem[]> {
    const actions: MenuSubItem[] = [];
    const lifecycleInfo = await firstValueFrom(
      this.boardDataService.getLifecycleInfo(context.item),
    );

    for (const transition of lifecycleInfo.transitions) {
      actions.push({
        name: transition.id,
        label: transition.label,
        handlerFn: (card: BoardCardContext) => {
          const column = this.columns.find(
            (column) => column.stateId === transition.nextStateId,
          );

          if (!column) {
            this.boardDataService
              .setState(card.item, transition.nextStateId)
              .then((value) => {
                if (value) {
                  this.cardsByColumns[card.item.columnId].splice(
                    card.oldIndex,
                    1,
                  );
                  this.dragDropService.setOnEnd();
                }
              });
          } else {
            this.event$.next({
              target: 'track',
              id: card.item.columnId,
              action: 'moveThroughCardMenu',
              data: {
                oldIndex: card.oldIndex,
                newIndex: this.cardsByColumns[column.id].length,
                item: card.item,
                toGroupName: column.id,
              },
            });
          }
        },
      });
    }

    return actions;
  }

  // TODO: not correct, it's like mock data
  private initColumnsDependencies(): void {
    const codes = this.columns.map((column) => column.id);

    codes.forEach((code) => {
      this.columnsDependencies[code] = codes;
    });
  }

  private initColumnsActions(): void {
    this.columns.forEach((column) => {
      column.actions = [
        {
          name: 'edit',
          label: 'shared2.actions.edit',
          iconClass: 'bi bi-pencil',
          handlerFn: (target) => this.opensColumnForm(target, column, 'edit'),
        },
        {
          name: 'remove',
          label: 'shared2.actions.delete',
          iconClass: 'bi bi-trash',
          handlerFn: () => this.removeColumn(column),
        },
      ];
    });
  }

  private initSortItems(): void {
    this.sortItems.length = 0;

    this.sortItems.push({
      key: 'sort-default',
      name: this.translateService.instant(
        'components.boardService.props.manual',
      ),
      isNavigation: false,
      isDefault: true,
    });

    this.boardDataService.metaEntity.primitiveProperties
      .concat(this.boardDataService.metaEntity.navigationProperties)
      .filter((property) => property.type.toLowerCase() !== 'text')
      .forEach((property) => {
        this.sortItems.push({
          key: _.camelCase(property.name),
          name: LocalStringHelper.getTranslate(
            property.displayNames,
            this.appService.getLanguage(),
          ),
          isNavigation: property.kind === MetaEntityPropertyKind.navigation,
        });
      });

    this.sortItems = _.orderBy(
      this.sortItems,
      ['isDefault', 'name'],
      ['asc', 'asc'],
    );
  }

  private initSubscriptions(): void {
    this.loadingSubject.next(true);

    merge(
      this.filterService?.values$,
      this.actionPanelService.reload$,
      this.sortChange$.pipe(filter((v) => !!v || v === '')),
    )
      .pipe(
        switchMap(() => this.loadBoard()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (this.loadingSubject.getValue()) {
          this.loadingSubject.next(false);
        }
      });

    this.offCanvasService.entityUpdated$
      .pipe(
        filter((v) => !!v),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((entity: { [key: string]: any; changedStateId?: string }) => {
        if (entity.changedStateId) {
          this.actionPanelService.reload();
        } else {
          const card = this.cards.find((card) => card.entity.id === entity.id);
          if (card) {
            Object.keys(card.entity).forEach((key) => {
              card.entity[key] = entity[key] ?? card.entity[key];
            });
            this.event$.next({
              target: 'card',
              id: card.id,
              action: 'updated',
            });
          }
        }
      });

    this.dragDropService.onStart$
      .pipe(
        tap(() => {
          this.infoPopupService.close();
        }),
        filter((data) => data.fromGroupKey === 'cards'),
        concatMap((data) => {
          const lifecycleInfo = this.boardDataService.entityLifecycleInfo.get(
            data.item.entity.id,
          );

          return lifecycleInfo
            ? of(lifecycleInfo)
            : this.boardDataService
                .getLifecycleInfo(data.item)
                .pipe(
                  takeUntil(
                    merge(
                      this.dragDropService.onDrop$,
                      this.dragDropService.onEnd$,
                    ).pipe(first()),
                  ),
                );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((lifecycleInfo) => {
        this.columns
          .filter(
            (column) =>
              !lifecycleInfo.transitions.some(
                (t) => t.nextStateId === column.stateId,
              ) && column.stateId !== lifecycleInfo.currentState.id,
          )
          .forEach((column) => {
            this.event$.next({
              target: 'track',
              id: column.id,
              action: 'restrictTransition',
              data: true,
            });
          });
      });

    this.dragDropService.completed$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.event$.next({
          target: 'track',
          id: null,
          action: 'restrictTransition',
          data: false,
        });
      });
  }

  private getFilter(): { and: Array<any> } | any | null {
    const originalFilter = this.filterService?.getODataFilter();

    if (!originalFilter) {
      return null;
    }

    if (!this.config?.filterService || this.config?.isSimpleFilterQuery) {
      return originalFilter;
    }

    const newFilter = {
      and: [],
    };

    for (const value of originalFilter) {
      const item = Object.values(value).pop();

      if (!Array.isArray(item)) {
        newFilter.and.push(item);
      } else if (Array.isArray(item) && item.length) {
        newFilter.and.push({
          or: item.map((i) => Object.values(i).pop()),
        });
      }
    }

    return newFilter.and.length ? newFilter : null;
  }
}
