<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.projects.tariffs.addRateMatricesTariffsModal.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving$ | async"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <wp-loading-indicator [loading]="isLoadingMatrices$ | async" />
    @if (!(isLoadingMatrices$ | async) && form.value.rateMatrix) {
      <div class="toolbar-container">
        <div class="col-4" [formGroup]="form">
          <wp-select-box
            formControlName="rateMatrix"
            [values]="rateMatrices"
            [allowNull]="false"
            [placeholder]="'shared.rateMatrices.rateMatrix' | translate"
          />
        </div>
        <div class="col-4">
          <wp-filter />
        </div>
      </div>
    }
    @if (!(isLoadingMatrices$ | async) && !form.value.rateMatrix) {
      <div class="text-uppercase text-body-secondary no-data text-center">
        {{ 'projects.projects.tariffs.addRateMatricesTariffsModal.emptyMatrices' | translate }}
      </div>
    }
    @if (form.value.rateMatrix) {
      <div class="row">
        <div class="col d-relative">
          <table
            class="wp-nested-table wp-nested-table-hover fix-table"
            [ngStyle]="leftTableStyles"
          >
            <thead>
              <tr>
                @for (item of form.value.rateMatrix?.rateMatrixStructure; track item) {
                  <th title="{{ 'shared.rateMatrices.' + item | translate }}">
                    {{ 'shared.rateMatrices.' + item | translate }}
                  </th>
                }
                <th title="{{ 'shared.rateMatrices.rate' | translate }}" class="rate text-end">
                  {{ 'shared.rateMatrices.rate' | translate }}
                </th>
              </tr>
            </thead>
          </table>
          <div class="scroll-container">
            <table
              #leftTbl
              (resized)="resizeLeftTbl()"
              class="wp-nested-table wp-nested-table-hover position-relative"
            >
              <thead>
                <tr>
                  @for (item of form.value.rateMatrix?.rateMatrixStructure; track item) {
                    <th title="{{ 'shared.rateMatrices.' + item | translate }}">
                      {{ 'shared.rateMatrices.' + item | translate }}
                    </th>
                  }
                  <th title="{{ 'shared.rateMatrices.rate' | translate }}" class="rate text-end">
                    {{ 'shared.rateMatrices.rate' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                @for (line of availableLines; track line.id) {
                  <tr (click)="selectLine(line, $index)">
                    @for (item of form.value.rateMatrix?.rateMatrixStructure; track item) {
                      <td class="trim" [title]="line[item]?.name ?? ''">
                        {{ line[item]?.name }}
                      </td>
                    }
                    <td
                      class="trim text-end rate"
                      title="{{ +line.rate | wpCurrency: baseCurrency?.alpha3Code }}"
                    >
                      {{ +line.rate | wpCurrency: baseCurrency?.alpha3Code }}
                    </td>
                  </tr>
                } @empty {
                  @if (!(isLoading$ | async)) {
                    <tr>
                      <td
                        [attr.colspan]="form.value.rateMatrix?.rateMatrixStructure?.length + 1"
                        class="text-center text-body-secondary"
                      >
                        {{ 'shared.noDisplayData' | translate }}
                      </td>
                    </tr>
                  }
                }
                @if (loadedPartly) {
                  <tr>
                    <td
                      [attr.colspan]="form.value.rateMatrix?.rateMatrixStructure?.length + 1"
                      class="text-body-secondary text-center text-uppercase"
                    >
                      {{ 'shared.showFirstNRecordsMessage' | translate: { limit: loadLimit }
                      }}<br />
                      {{ 'shared.refineQueryMessage' | translate }}
                    </td>
                  </tr>
                }
              </tbody>
            </table>
            <wp-loading-indicator [loading]="isLoading$ | async" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="selected-resources-container">
            @for (line of selectedLines; track line.id) {
              <div class="selected-resource-block" (click)="removeLine(line, $index)">
                <div class="trim" title="{{ line.uniqueName }}">
                  {{ line.uniqueName }}
                </div>

                <button type="button" class="close">&times;</button>
              </div>
            } @empty {
              <div class="text-uppercase text-body-secondary no-data">
                {{
                  'projects.projects.tariffs.addRateMatricesTariffsModal.noDisplayData' | translate
                }}
              </div>
            }
          </div>
        </div>
      </div>
    }
  </div>
  <div class="modal-footer">
    @if (selectedLines.length) {
      <button
        type="button"
        class="btn btn-primary"
        (click)="addTariffsFromRateMatrixLines()"
        wpButtonSpinner
        [isBusy]="isSaving$ | async"
      >
        {{ 'shared.actions.add' | translate }}
      </button>
    }
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving$ | async">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
