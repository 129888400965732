import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/core/data.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Exception } from 'src/app/shared/models/exception';
import { ProjectCardService } from '../../core/project-card.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import {
  BehaviorSubject,
  forkJoin,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProjectTeamMember } from 'src/app/shared/models/entities/projects/project-team-member.model';
import { TranslateService } from '@ngx-translate/core';
import { StringHelper } from 'src/app/shared/helpers/string-helper';
import { ProjectTeamService } from 'src/app/projects/card/project-team/project-team.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RateMatrix } from 'src/app/settings-app/rate-matrix/model/rate-matrix.model';
import { DateTime } from 'luxon';
import {
  Analytics,
  analytics,
} from 'src/app/settings-app/rate-matrix/card/structure-change-modal/rate-matrix-structure.model';
import _ from 'lodash';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import {
  BoxControlComponent,
  FormHelper,
} from 'src/app/shared/helpers/form-helper';

@Component({
  selector: 'wp-generic-modal',
  templateUrl: './generic-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericModalComponent implements OnInit {
  @ViewChildren('cascadeControl')
  private cascadeControls: QueryList<BoxControlComponent>;

  @Input() editAllowed = true;

  @Input() resourceId: string;
  @Input() teamMemberId: string;
  @Input() existingNames: string[];

  public isLoading$ = new BehaviorSubject(false);
  private _hasResourceRequest = false;
  public get hasResourceRequest() {
    return this._hasResourceRequest;
  }
  public set hasResourceRequest(val) {
    this._hasResourceRequest = val;
    if (val) {
      this.form.disable();
      this.form.controls.name.enable();
    }
  }
  public isEditing: boolean;

  /** Observables, передающиеся в контролы, по которым при открытии контрола загружаются значения. */
  public levels$: Observable<NamedEntity[]>;
  public grades$: Observable<NamedEntity[]>;

  public isSaving: boolean;

  public form = this.fb.group({
    role: null,
    level: null,
    name: [null, [Validators.required, Validators.maxLength(100)]],
    resourcePool: null,
    grade: null,
    location: null,
    costRate: 0,
    staffCount: null,
    primaryTariff: null,
    competence: null,
    legalEntity: null,
  });
  public matrixAnalytics: Analytics[] = [];
  public get otherAnalytics(): Analytics[] {
    return analytics.filter(
      (analytic) => !this.matrixAnalytics.includes(analytic),
    );
  }

  private stopAutoName$ = new Subject<void>();
  private destroyRef = inject(DestroyRef);
  private autoNameSubscription: Subscription[] = [];

  constructor(
    @Inject('entityId') public projectId: string,
    public projectCardService: ProjectCardService,
    public projectTeamService: ProjectTeamService,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.isLoading$.next(true);
    this.initModal();
    this.initUpdateGenericCalculatedInfoSubscriptions();

    this.form.controls.name.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        if (!val) {
          this.subToAutoName();
        } else {
          this.stopAutoName$.next();
        }
      });
  }

  /**
   * Handles the 'OK' action of the modal which involves form submission.
   * Depending on the context (add more or just save), it either creates a new generic resource
   * or updates an existing one.
   *
   * @param {boolean} addMore - Determines whether to add more items after saving or just save and close.
   */
  public ok(addMore: boolean): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const formValues = this.form.getRawValue();
    const genericResource = {
      roleId: formValues.role?.id ?? null,
      levelId: formValues.level?.id ?? null,
      resourcePoolId: formValues.resourcePool?.id ?? null,
      name: formValues.name,
      gradeId: formValues.grade?.id ?? null,
      locationId: formValues.location?.id ?? null,
      competenceId: formValues.competence?.id ?? null,
      legalEntityId: formValues.legalEntity?.id ?? null,
    };

    ProjectVersionUtil.setEntityRootPropertyId(
      this.versionCardService.projectVersion,
      genericResource,
      this.projectId,
    );

    if (!this.isEditing) {
      this.versionDataService
        .projectCollectionEntity(
          this.versionCardService.projectVersion,
          this.projectId,
        )
        .action('CreateGeneric')
        .execute({
          generic: genericResource,
          primaryTariffId: formValues.primaryTariff?.id,
        })
        .subscribe({
          next: () => {
            this.isSaving = false;
            this.notification.successLocal(
              'projects.projects.card.team.messages.membersWereAdded',
            );

            if (addMore) {
              this.projectCardService.reloadTab();
              this.existingNames.push(this.form.controls.name.value);
              this.cdr.detectChanges();
            } else {
              this.activeModal.close();
            }
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.isSaving = false;
            this.cdr.detectChanges();
          },
        });
    } else {
      const teamMemberPatchRequest = this.data
        .collection('ProjectTeamMembers')
        .entity(this.teamMemberId)
        .patch({ primaryTariffId: formValues.primaryTariff?.id });

      const genericPatchRequest = this.data
        .collection('Generics')
        .entity(this.resourceId)
        .patch(genericResource);

      forkJoin({
        teamMemberPatchRequest,
        genericPatchRequest,
      }).subscribe({
        next: () => {
          this.isSaving = false;
          this.notification.successLocal('shared.messages.saved');
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
          this.cdr.detectChanges();
        },
      });
    }
  }

  /** Dismisses the active modal without saving any changes. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  /**
   * Initializes the modal with default values or existing project team member data.
   * If not in editing mode, it loads default values for the form controls.
   * If in editing mode, it loads the existing project team member data into the form controls.
   */
  private initModal(): void {
    if (!this.isEditing) {
      this.getActualMatrixStructure()
        .pipe(switchMap(() => this.loadDefaults()))
        .subscribe({
          next: (response) => {
            if (response.pools.length === 1) {
              this.form.controls.resourcePool.patchValue(response.pools[0]);
            }

            if (response.levels.length === 1) {
              this.form.controls.level.patchValue(response.levels[0]);
            }

            this.isLoading$.next(false);

            this.setCascadeControls();

            this.subToAutoName();
          },
          error: () => {
            this.isLoading$.next(false);
          },
        });
    } else {
      this.getActualMatrixStructure()
        .pipe(
          switchMap(() => this.getProjectTeamMember()),
          switchMap((teamMember) => {
            this.form.patchValue(teamMember.resource);
            this.form.controls.primaryTariff.patchValue(
              teamMember.primaryTariff,
            );
            if (!this.editAllowed) {
              this.form.disable();
            }

            return this.generateName(true);
          }),
        )
        .subscribe({
          next: (name) => {
            if (name && this.form.controls.name.value.includes(name)) {
              this.subToAutoName();
            }
            this.isLoading$.next(false);
            this.setCascadeControls();
          },
          error: () => {
            this.isLoading$.next(false);
          },
        });
    }
  }

  /** Initializes subscriptions to form control value changes to update generic calculated information. */
  private initUpdateGenericCalculatedInfoSubscriptions() {
    merge(
      this.form.controls.location.valueChanges,
      this.form.controls.grade.valueChanges,
      this.form.controls.level.valueChanges,
      this.form.controls.role.valueChanges,
      this.form.controls.resourcePool.valueChanges,
      this.form.controls.competence.valueChanges,
      this.form.controls.legalEntity.valueChanges,
    )
      .pipe(debounceTime(100), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateGenericCalculatedInfo();
      });
  }

  private updateGenericCalculatedInfo(): void {
    const location = this.form.controls.location.value;
    const level = this.form.controls.level.value;
    const grade = this.form.controls.grade.value;
    const role = this.form.controls.role.value;
    const resourcePool = this.form.controls.resourcePool.value;
    const competence = this.form.controls.competence.value;
    const legalEntity = this.form.controls.legalEntity.value;

    const params: Record<string, any> = {
      filter: '@filter',
    };
    const filterObject = {
      roleId: role?.id || null,
      levelId: level?.id || null,
      gradeId: grade?.id || null,
      locationId: location?.id || null,
      resourcePoolId: resourcePool?.id || null,
      competenceId: competence?.id || null,
      legalEntityId: legalEntity?.id || null,
    };
    const urlParams: Record<string, string> = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@filter': JSON.stringify(filterObject),
    };

    this.data
      .collection('ProjectTeamMembers')
      .function('GetGenericCalculatedInfo')
      .get<{ staffCount: number | null; costRate: number | null }>(
        params,
        null,
        urlParams,
      )
      .subscribe((val) => {
        const staffCount = val.staffCount;
        const staffCountTitle = StringHelper.declOfNum(staffCount, [
          this.translate.instant('shared.employeeDeclensions.v1'),
          this.translate.instant('shared.employeeDeclensions.v2'),
          this.translate.instant('shared.employeeDeclensions.v3'),
        ]);
        const parsedStaffCount = staffCount + ' ' + staffCountTitle;

        this.form.controls.staffCount.patchValue(parsedStaffCount);
        this.form.controls.costRate.patchValue(val.costRate);
      });
  }

  private loadDefaults(): Observable<{
    pools: NamedEntity[];
    levels: NamedEntity[];
  }> {
    return forkJoin({
      pools: this.data
        .collection('ResourcePools')
        .query<NamedEntity[]>({ filter: { isDefault: true, isActive: true } }),
      levels: this.data
        .collection('Levels')
        .query<NamedEntity[]>({ filter: { isActive: true } }),
    });
  }

  private getActualMatrixStructure(): Observable<Partial<RateMatrix[]>> {
    const today = DateTime.now().toISODate();
    const query = {
      filter: {
        typeId: { type: 'guid', value: RateMatrix.costRateTypeId },
        and: [
          {
            or: [
              { effectiveDate: { type: 'raw', value: 'null' } },
              { effectiveDate: { le: { type: 'raw', value: today } } },
            ],
          },
          {
            or: [
              { expiryDate: { ge: { type: 'raw', value: today } } },
              { expiryDate: { type: 'raw', value: 'null' } },
            ],
          },
        ],
        stateId: { type: 'guid', value: RateMatrix.activeStateId },
      },
      orderBy: 'effectiveDate',
    };

    return this.data
      .collection('RateMatrices')
      .query(query)
      .pipe(
        tap(
          (matrices: RateMatrix[]) =>
            (this.matrixAnalytics = matrices.length
              ? matrices[0].rateMatrixStructure.map(
                  (item) => (item = _.lowerFirst(item) as Analytics),
                )
              : []),
        ),
      );
  }

  private getProjectTeamMember(): Observable<ProjectTeamMember> {
    const query = {
      expand: {
        resource: {
          select: ['*'],
          expand: {
            grade: {
              select: ['id', 'name', 'levelId', 'code'],
            },
            competence: {
              select: ['id', 'name', 'code'],
            },
            legalEntity: {
              select: ['id', 'name', 'code'],
            },
            level: {
              select: ['id', 'name', 'code'],
            },
            resourcePool: {
              select: ['id', 'name', 'code'],
            },
            location: {
              select: ['id', 'name', 'code'],
            },
            role: {
              select: ['id', 'name', 'code'],
              filter: [{ isActive: true }],
            },
          },
        },
        primaryTariff: {
          select: ['id', 'name'],
        },
      },
    };

    return this.data
      .collection('ProjectTeamMembers')
      .entity(this.teamMemberId)
      .get<ProjectTeamMember>(query);
  }

  /** Sets up cascading dependencies between form controls. */
  private setCascadeControls(): void {
    this.cdr.detectChanges();
    FormHelper.cascadeDependency(
      this.form,
      this.cascadeControls,
      [
        [
          {
            controlName: 'role',
          },
          {
            controlName: 'competence',
            dependedProperty: 'roleId',
          },
        ],
        [
          {
            controlName: 'level',
          },
          { controlName: 'grade', dependedProperty: 'levelId' },
        ],
      ],
      takeUntilDestroyed(this.destroyRef),
    );
  }

  /**
   * Generates name depending on filled matrix analytics.
   *
   * @param initEditing  shows whether first init of modal editing.
   * @returns String observable for switchMap.
   */
  private generateName(initEditing?: boolean): Observable<string> {
    const filledControls = [];

    this.matrixAnalytics.forEach((analytic) => {
      if (this.form.controls[analytic].value) {
        filledControls.push(this.form.controls[analytic]);
      }
    });

    let generatedName = '';

    filledControls.forEach((a, index) => {
      const analytic = filledControls[index]?.value;
      if (!index) {
        generatedName = analytic?.name;
        return;
      }
      generatedName += ` ${analytic.code?.length ? analytic?.code : analytic?.name}`;
    });

    if (initEditing) {
      return of(generatedName);
    }

    this.form.controls.name.patchValue(this.getUniqueName(generatedName), {
      emitEvent: false,
    });

    const uniqueName = this.getUniqueName(generatedName);

    return of(uniqueName);
  }

  /** Subscribes to controls of matrix analytics and calls generateName function. */
  private subToAutoName(): void {
    this.autoNameSubscription.forEach((subscription) =>
      subscription.unsubscribe(),
    );
    this.matrixAnalytics.forEach((a, index) => {
      this.autoNameSubscription.push(
        this.form.controls[this.matrixAnalytics[index]].valueChanges
          .pipe(
            takeUntil(this.stopAutoName$),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe(() => {
            this.generateName();
          }),
      );
    });
  }

  /**
   * Function returns newGeneratedName if generated name is not unique.
   *
   * @param generatedName name generated by function generateName.
   * @returns newGeneratedName or generatedName.
   */
  private getUniqueName(generatedName: string): string {
    let nameExists = !!this.existingNames.find(
      (name) => name === generatedName,
    );

    let newGeneratedName;
    let i = 2;
    while (nameExists) {
      newGeneratedName = `${generatedName} (${i})`;
      nameExists = !!this.existingNames.find(
        (name) => name === newGeneratedName,
      );
      i++;
    }

    return newGeneratedName ?? generatedName;
  }
}
