import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectRbcComponent } from './project-rbc.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { ProjectRbcCalendarModule } from './project-rbc-calendar/project-rbc-calendar.module';
import { ProjectRevenueEstimatesModule } from './project-revenue-estimates/project-revenue-estimates.module';
import { ProjectBillingModule } from './project-billing-estimates/project-billing.module';

@NgModule({
  declarations: [ProjectRbcComponent],
  imports: [
    CommonModule,
    SharedModule,
    ProjectRevenueEstimatesModule,
    ProjectBillingModule,
    ProjectRbcCalendarModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.rbc',
          url: '/rbc',
          component: ProjectRbcComponent,
          redirectTo: 'project.rbc.calendar',
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class ProjectRbcModule {}
