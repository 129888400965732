import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { Subject, pipe } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';

/** Сообщение "Сущность не найдена". */
@Component({
  selector: 'tmt-not-found',
  templateUrl: './not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public state: CardState;
  /** Get the `statusCode` if the request url contains this value */
  @Input() public urlMustContain: string | null = null;

  public statusCode: number;

  private destroyed$ = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private actionPanelService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.dataService.requestStatus$
      .pipe(
        this.urlMustContain
          ? filter((v) => v.url.includes(this.urlMustContain))
          : pipe(),
        takeUntil(this.destroyed$),
      )
      .subscribe((v) => {
        this.statusCode = v.code;
        this.cdr.markForCheck();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['state']) {
      this.actionPanelService.setVisibility(this.state === CardState.Ready);
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
