import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';
import { RbcGroupType, RbcSectionType } from './rbc-view.model';

export class RbcSettings implements BaseSettings {
  public readonly name = 'RbcSettings';
  public version: number;

  /**
   * Calendar planning scale
   */
  public planningScale: PlanningScale;

  /**
   * Calendar displayed groups. e.g. ['estimate', 'plan']
   */
  public displayedGroups: RbcGroupType[];

  /**
   * Calendar displayed others. e.g. ['expenses': ['estimate', 'plan']]
   * e.g. {'revenue': ['estimate', 'plan']}
   */
  public displayedOthers: Record<RbcSectionType, RbcGroupType[]>;

  public getDefault(): RbcSettings {
    const defaultDisplayedOthers = {} as Record<RbcSectionType, RbcGroupType[]>;
    Object.values(RbcSectionType).forEach((sectionType) => {
      defaultDisplayedOthers[sectionType] = Object.values(RbcGroupType);
    });

    return {
      version: 4,
      planningScale: PlanningScale.Week,
      displayedGroups: Object.values(RbcGroupType),
      displayedOthers: defaultDisplayedOthers,
    } as RbcSettings;
  }
}
