import { EnumEntity } from '../../enums/enum-entity';

export class FinancialAccountType extends EnumEntity {
  public static get revenue(): FinancialAccountType {
    return {
      id: 'b27bf8ed-9569-4414-9208-ddaf36ac136e',
      code: 'Revenue',
      name: 'enums.financialAccountType.revenue',
    };
  }

  public static get expenses(): FinancialAccountType {
    return {
      id: 'ac1dc1aa-dac0-4e48-9682-aabc39866d8c',
      code: 'Expenses',
      name: 'enums.financialAccountType.expenses',
    };
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FinancialAccountTypes: FinancialAccountType[] = [
  FinancialAccountType.revenue,
  FinancialAccountType.expenses,
];
