import { Injectable } from '@angular/core';
import { TimeOffType } from '../../../shared/models/entities/settings/time-off-type.model';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class TimeOffTypeCardService {
  private timeOffTypeSubject = new BehaviorSubject<TimeOffType>(null);
  public timeOffType$ = this.timeOffTypeSubject
    .asObservable()
    .pipe(filter((x) => !!x));

  public updateTimeOffType = (value: TimeOffType) => {
    this.timeOffTypeSubject.next(value);
  };
}
