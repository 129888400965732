import { Component, OnInit, Input, DestroyRef, inject } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { Skill } from 'src/app/shared/models/entities/settings/skill.model';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Constants } from 'src/app/shared/globals/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationService } from 'src/app/core/navigation.service';
import { BehaviorSubject } from 'rxjs';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'tmt-skill-card',
  templateUrl: './skill-card.component.html',
})
export class SkillCardComponent implements OnInit {
  @Input() private entityId: string;

  public readonly: boolean;
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public isSaving = false;

  public skillForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: [false],
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    private navigationService: NavigationService,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
    ]);

    this.load();

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  /** Saves Data. */
  public save() {
    this.skillForm.markAllAsTouched();

    if (this.skillForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const skill = this.skillForm.value;

    const data: any = {
      name: skill.name,
      description: skill.description,
      isActive: skill.isActive,
    };

    this.data
      .collection('Skills')
      .entity(this.entityId)
      .patch(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.skillForm.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal('settings.skills.card.messages.saved');
        },
        error: (error: any) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  }

  private load = (): void => {
    this.state$.next(CardState.Loading);
    this.skillForm.markAsPristine();
    this.skillForm.markAsUntouched();
    this.data
      .collection('Skills')
      .entity(this.entityId)
      .get<Skill>(null)
      .subscribe({
        next: (skill: Skill) => {
          this.skillForm.patchValue(skill);

          this.readonly = !skill.editAllowed;
          this.actionService.action('save').isShown = !this.readonly;
          this.readonly ? this.skillForm.disable() : this.skillForm.enable();
          this.state$.next(CardState.Ready);

          this.navigationService.addRouteSegment({
            id: skill.id,
            title: skill.name,
          });
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          this.notification.error(error.message);
        },
      });
  };

  private reload() {
    if (!this.skillForm.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }
}
