import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceSummaryLeftGroupComponent } from 'src/app/resource-summary/shared/resource-summary-left-group/resource-summary-left-group.component';
import { ResourceSummaryRightGroupComponent } from 'src/app/resource-summary/shared/resource-summary-right-group/resource-summary-right-group.component';
import { ResourceSummaryToolbarComponent } from 'src/app/resource-summary/shared/resource-summary-toolbar/resource-summary-toolbar.component';
import { ResourceSummaryViewSettingsComponent } from 'src/app/resource-summary/shared/resource-summary-view-settings/resource-summary-view-settings.component';
import { ResourceSummaryFilterModule } from 'src/app/resource-summary/shared/resource-summary-filter/resource-summary-filter.module';
import { ResourceSummaryRepresentationSettingsComponent } from 'src/app/resource-summary/shared/resource-summary-representation-settings/resource-summary-representation-settings.component';
import { ResourceSummaryTotalPipe } from 'src/app/resource-summary/shared/pipes/resource-summary-total.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { ScheduleNavigationModule } from 'src/app/shared-features/schedule-navigation/schedule-navigation.module';
import { UIRouterModule } from '@uirouter/angular';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { ResourceSummaryEntryPipe } from 'src/app/resource-summary/shared/pipes/resource-summary-entry.pipe';
import { ResourceSummaryComponent } from 'src/app/resource-summary/resource-summary.component';

@NgModule({
  declarations: [
    ResourceSummaryComponent,
    ResourceSummaryLeftGroupComponent,
    ResourceSummaryRightGroupComponent,
    ResourceSummaryToolbarComponent,
    ResourceSummaryViewSettingsComponent,
    ResourceSummaryRepresentationSettingsComponent,
    ResourceSummaryTotalPipe,
    ResourceSummaryEntryPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ScheduleNavigationModule,
    ResourceSummaryFilterModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'usersSummary',
          url: '/users-summary?{navigation}&{route}',
          component: ResourceSummaryComponent,
          params: { routeMode: null, route: ROUTE_DEFAULT_PARAMS },
        },
      ],
    }),
  ],
})
export class ResourceSummaryModule {}
