import { Inject, Injectable, OnDestroy } from '@angular/core';
import { FormArray, UntypedFormBuilder } from '@angular/forms';
import { sortBy } from 'lodash';
import { Subject, forkJoin } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { UserProduct } from 'src/app/settings-app/users/card/user-products/user-product.model';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { LicenseProductInfo } from 'src/app/shared/models/entities/settings/license-info.model';
import { Exception } from 'src/app/shared/models/exception';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';

@Injectable()
export class UserProductsService implements OnDestroy {
  public licenseInfo: LicenseProductInfo[] = [];
  public saving$ = new Subject<boolean>();
  public loading$ = new Subject<boolean>();

  products: FormArray = this.fb.array([]);
  private destroyed$ = new Subject<void>();

  public readonly = !this.app.checkEntityPermission(
    'UserProduct',
    PermissionType.Modify,
  );

  constructor(
    @Inject('entityId') public entityId: string,
    private app: AppService,
    private notification: NotificationService,
    private gridService: GridService,
    private blockUI: BlockUIService,
    private data: DataService,
    private actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
  ) {
    this.actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.reload();
      });

    this.actionService.action('save').isShown = !this.readonly;

    this.actionService.run$
      .pipe(
        filter((x) => x.name === 'save'),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.save();
      });

    this.loading$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.gridService.setLoadingState(value);
      this.actionService.action('save').isShown = !this.readonly && !value;
    });

    this.saving$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      if (value) {
        this.blockUI.start();
        this.actionService.action('save').start();
      } else {
        this.actionService.action('save').stop();
        this.blockUI.stop();
      }
    });

    this.load();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Saves data. */
  public save(): void {
    this.products.markAllAsTouched();

    if (this.products.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
    }

    this.saving$.next(true);
    const data = { products: [] };

    this.products.value.forEach((userProduct: RowModel) => {
      data.products.push({
        id: userProduct.id,
        userId: this.entityId,
        product: userProduct.product,
      });
    });

    this.data
      .collection('Users')
      .entity(this.entityId)
      .action('UpdateProducts')
      .execute(data)
      .subscribe({
        next: () => {
          this.notification.successLocal('shared.messages.saved');
          this.products.markAsPristine();
          this.products.markAsUntouched();
          this.saving$.next(false);
        },
        error: (error: Exception) => {
          this.message.errorDetailed(error);
          this.saving$.next(false);
        },
      });
  }

  /** Loads data. */
  public load() {
    this.products.clear();
    this.loading$.next(true);

    const entity = this.data.collection('Users').entity(this.entityId);

    forkJoin({
      licenseInfo: entity
        .function('GetAvailableProducts')
        .get<LicenseProductInfo[]>(),
      userProducts: entity.collection('Products').query<UserProduct[]>(),
    }).subscribe({
      next: (response) => {
        sortBy(
          response.userProducts.map((userProduct: UserProduct) => {
            const productInfo = response.licenseInfo.find(
              (x) => x.product === userProduct.product,
            );
            return this.fb.group({
              name: [productInfo?.displayName ?? userProduct.product], // Possible on trial transition.
              product: [userProduct.product],
              id: [userProduct.id],
            });
          }),
          (group) => group.value.name,
        ).forEach((group) => this.products.push(group));

        this.licenseInfo = response.licenseInfo;
        this.loading$.next(false);

        this.products.markAsPristine();
        this.products.markAsUntouched();
      },
      error: (error: Exception) => {
        this.loading$.next(false);
        this.notification.error(error.message);
      },
    });
  }

  /** Handles tab-reload event. */
  public reload() {
    if (!this.products.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => {
          this.load();
        },
        () => null,
      );
    }
  }
}

export interface RowModel {
  product: LicenseProductInfo;
  name: string;
  id: string;
}
