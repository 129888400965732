<wp-form-header [name]="form.getRawValue().name" (reloaded)="reload()"></wp-form-header>
<wp-loading-indicator [state]="state"></wp-loading-indicator>
<tmt-not-found [state]="state"></tmt-not-found>

<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" [hidden]="state !== 'Ready'" class="form">
  <div class="row">
    <div class="col-xs-12 col-lg-10">
      <label class="group">
        {{ 'analytics.calculatedFields.card.labels.main' | translate }}
      </label>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-5 col-xs-6">
      <div class="form-group">
        <label>{{ 'shared.props.name' | translate }}</label>
        <wp-text-box
          formControlName="name"
          placeholder="{{ 'shared.props.name' | translate }}"
        ></wp-text-box>
      </div>

      <div class="form-group">
        <label>{{
          'analytics.calculatedFields.card.properties.description.label' | translate
        }}</label>
        <wp-multiline-text-box
          formControlName="description"
          placeholder="{{
            'analytics.calculatedFields.card.properties.description.placeholder' | translate
          }}"
        >
        </wp-multiline-text-box>
      </div>

      <div class="form-group">
        <label>{{ 'analytics.calculatedFields.card.properties.type.label' | translate }}</label>
        <wp-select-box
          style="width: 250px"
          [values]="fieldTypes"
          placeholder="{{
            'analytics.calculatedFields.card.properties.type.placeholder' | translate
          }}"
          formControlName="fieldType"
        ></wp-select-box>
      </div>
    </div>
    <div class="col-lg-5 col-xs-6">
      <div class="form-group">
        <label>{{
          'analytics.calculatedFields.card.properties.reportType.label' | translate
        }}</label>
        <p class="form-control-static">
          {{ form.getRawValue().reportType?.name }}
        </p>
      </div>

      <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
        <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
        <label class="form-check-label" for="isActive">
          {{ 'shared.props.isActive.label' | translate }}
        </label>
      </div>
    </div>
  </div>

  <div class="row pt-3">
    <div class="col-xs-12 col-lg-10">
      <label class="group">
        {{ 'analytics.calculatedFields.card.labels.title' | translate }}
      </label>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6 col-lg-5">
      <div class="toolbar-container" style="padding-top: 0">
        <button
          *ngIf="!readonly"
          type="button"
          class="btn btn-default"
          title="{{ 'shared.actions.add' | translate }}"
          (click)="addString()"
        >
          <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
          {{ 'shared.actions.add' | translate }}
        </button>
      </div>

      <wp-grid [formArray]="localStrings" [options]="gridOptions" [readonly]="readonly"></wp-grid>
    </div>
  </div>

  <div class="row pt-4">
    <div class="col-xs-12 col-lg-10">
      <label class="group">
        {{ 'analytics.calculatedFields.card.labels.expression' | translate }}
      </label>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6 col-lg-5">
      @if (!readonly) {
        <div style="width: 400px; margin-bottom: 15px">
          <wp-select-box
            [values]="sourceFields"
            groupHandler="groupTitle"
            optionLabel="title"
            [filterOnServer]="false"
            formControlName="sourceField"
            [allowNull]="false"
            [placeholder]="'analytics.reportFilters.filterFieldPicker.placeholder' | translate"
          />
        </div>
      }
      <div class="form-group">
        <textarea
          #editor
          maxlength="10240"
          class="form-control"
          style="resize: vertical; min-height: 39px"
          placeholder="{{
            'analytics.calculatedFields.card.properties.expression.placeholder' | translate
          }}"
          formControlName="expression"
          rows="5"
          spellcheck="false"
        ></textarea>
      </div>

      <button
        class="btn btn-link btn-action"
        tmtDocsArticle="analytics/expression-of-calculated-fields"
      >
        {{ 'analytics.calculatedFields.card.properties.expression.help' | translate }}
        <i class="bi bi-question-circle" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div class="actions" *ngIf="!readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
