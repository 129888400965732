<ng-container [formGroup]="form">
  <span class="text-body-secondary">{{
    'settings.validationRules.card.props.scheduleWorkDayPercentageHelp' | translate
  }}</span>
  <br />
  <br />
  <div class="form-group">
    <label>{{ 'settings.validationRules.card.props.minPercentage' | translate }}</label>

    <wp-number-box
      type="integer"
      formControlName="MinPercentage"
      [allowNull]="false"
      [min]="0"
      [max]="1000"
    >
    </wp-number-box>
  </div>

  <div class="form-group">
    <label>{{ 'settings.validationRules.card.props.maxPercentage' | translate }}</label>
    <wp-number-box
      type="integer"
      formControlName="MaxPercentage"
      [allowNull]="false"
      [min]="0"
      [max]="1000"
    >
    </wp-number-box>
  </div>
</ng-container>
