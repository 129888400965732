<tmt-form-header [name]="form.value.name" />
<div class="grid-width">
  <wp-loading-indicator [loading]="isLoading" />
</div>
@if (!isLoading) {
  <form class="form mt-3" novalidate [formGroup]="form">
    <div class="grid-width">
      <label class="group mb-0">
        {{ 'components.lifecycleCardComponent.groups.states' | translate }}
      </label>

      <tmt-grid [formArray]="states" [options]="gridOptions" [readonly]="readonly" />
    </div>

    <label class="group mb-0 mt-4 grid-width">
      {{ 'components.lifecycleCardComponent.groups.transitionRules' | translate }}
    </label>

    <tmt-kind-settings
      [lifecycleData]="lifecycleData"
      [entityType]="lifecycleData.entityType"
      [readonly]="readonly"
    />
  </form>
}
