import {
  BehaviorSubject,
  Subject,
  MonoTypeOperatorFunction,
  Observable,
  catchError,
  delay,
  firstValueFrom,
  forkJoin,
  of,
  tap,
  finalize,
} from 'rxjs';
import _ from 'lodash';

import { Exception } from 'src/app/shared/models/exception';

export interface MassOperationSettings {
  /** Number of requests sent at one time */
  chunkSize?: number;
  /** How many times each failed request will be repeated */
  retry?: number;
  /** Delay before new chunk with requests will be sent */
  delay?: number;
  /** For preventing rest of chunks  */
  takeUntil?: MonoTypeOperatorFunction<unknown>;
}

export interface MassOperationResult<T> {
  /** Index from original array with requests */
  originalIndex: number;
  /** Result (e.g. `Exception`) */
  result: T;
}

export type ChainCollection = Observable<any>[];

/**
 * Helper is for working with a large number of API requests.
 * You must set requests as observables in the class constructor.
 *
 * @example
 * const massOperationHelper = new MassOperationHelper(
 *    observables,
 *    {chunksize: 15, retry: 1}
 * ).start();
 */
export class MassOperationHelper {
  private chunk: ChainCollection[];
  private currentChunk = 0;
  private failedObservables: MassOperationResult<Observable<any>>[] = [];
  private _errors: MassOperationResult<Exception>[] = [];
  private _completedCount = 0;
  private progressIncrement: number;
  private readonly progress$ = new BehaviorSubject<number>(0);
  private readonly preventRequesting$ = new Subject<void>();
  private readonly defaultSettings: MassOperationSettings = {
    chunkSize: 10,
    retry: 1,
    delay: 0,
  };

  /** Array with errors after all requests were completed. */
  public get errors(): MassOperationResult<Exception>[] {
    return _.uniqBy(this._errors, 'originalIndex');
  }

  /** Number of successful requests. */
  public get completedCount(): number {
    return this._completedCount;
  }

  constructor(
    private observables: ChainCollection,
    private settings?: MassOperationSettings,
  ) {
    this.progressIncrement = (1 / this.observables.length) * 100;
    _.merge(settings ?? {}, this.defaultSettings);
    this.init();
  }

  /**
   * Starts!
   *
   * @return execution progress.
   */
  public start(): Observable<number> {
    this.handler();
    return this.progress$.asObservable();
  }

  private init(): void {
    this.chunk = _.chunk(
      this.observables.map((el, index) => this.buildObservable(el, index)),
      this.settings.chunkSize,
    );

    if (this.settings.takeUntil) {
      this.preventRequesting$
        .pipe(
          this.settings.takeUntil,
          finalize(() => {
            this.preventRequesting$.unsubscribe();
            this.progress$.next(100);
          }),
        )
        .subscribe();
    }
  }

  private async handler(): Promise<void> {
    if (this.preventRequesting$.closed) {
      return;
    }

    await firstValueFrom(
      forkJoin(this.chunk[this.currentChunk]).pipe(
        delay(this.currentChunk ? this.settings.delay : 0),
      ),
    );

    if (
      this.currentChunk === this.chunk.length - 1 &&
      (!this.settings.retry || !this.failedObservables.length)
    ) {
      this.progress$.next(100);
      return;
    }

    if (
      this.currentChunk === this.chunk.length - 1 &&
      this.settings.retry > 0 &&
      this.failedObservables.length
    ) {
      this.chunk = _.chunk(
        this.failedObservables.map((el) =>
          this.buildObservable(el.result, el.originalIndex),
        ),
        this.settings.chunkSize,
      );

      this.currentChunk = -1;
      this.settings.retry -= 1;
    }

    this.currentChunk += 1;
    await this.handler();
  }

  private buildObservable(
    observable: Observable<any>,
    index: number,
  ): Observable<any> {
    return observable.pipe(
      catchError((err: Exception) => {
        if (err?.code === 'Network' && this.settings.retry > 0) {
          this.failedObservables.push({
            originalIndex: index,
            result: observable,
          });
        }

        this._errors.push({
          originalIndex: index,
          result: err,
        });

        return of(undefined);
      }),
      tap((value) => {
        if (value !== undefined) {
          this.progress$.next(
            this.progress$.getValue() + this.progressIncrement,
          );
          this._completedCount++;
        }
      }),
    );
  }
}
