import { Component, OnInit } from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ProgramCardService } from 'src/app/programs/card/program-card.service';

@Component({
  selector: 'wp-program-overview',
  templateUrl: './program-overview.component.html',
})
export class ProgramOverviewComponent implements OnInit {
  constructor(
    private actionService: ActionPanelService,
    public service: ProgramCardService,
  ) {}

  ngOnInit(): void {
    this.actionService.set([this.service.settingsAction]);
  }
}
