import { EnumEntity } from '../../enums/enum-entity';

export class VatRate extends EnumEntity {
  rate: number;

  public static get vat20(): VatRate {
    return {
      id: '43ec67a7-edf4-476b-adf7-5ca284df064e',
      code: 'Vat20',
      name: '20%',
      rate: 0.2,
    };
  }

  public static get vat18(): VatRate {
    return {
      id: '00e5d896-5107-4bfd-bad2-79a1ff6a88da',
      code: 'Vat18',
      name: '18%',
      rate: 0.18,
    };
  }

  public static get vat10(): VatRate {
    return {
      id: '484ddfe3-4565-422a-a06c-53b1d4297bce',
      code: 'Vat10',
      name: '10%',
      rate: 0.1,
    };
  }

  public static get vat0(): VatRate {
    return {
      id: '598834d5-8d71-4d67-a524-e8c12d74a4c0',
      code: 'Vat0',
      name: '0%',
      rate: 0.0,
    };
  }

  public static get notApply(): VatRate {
    return {
      id: '970e32bc-eca7-43c7-9410-1fe9268a7fd4',
      code: 'NotApply',
      name: '0%',
      rate: null,
    };
  }
}
