import { ProjectVersionMergeEnum } from 'src/app/projects/project-versions/project-versions-merge-modal/models/project-version-merge-enum.model';
import { Guid } from 'src/app/shared/helpers/guid';

/** Represents the Project version merge detail diff entity type. */
export class ProjVersionMergeDetailEntityType extends ProjectVersionMergeEnum {
  /** Team member. */
  public static get teamMember(): ProjVersionMergeDetailEntityType {
    return {
      id: Guid.generate(),
      code: 'TeamMember',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.entityType.teamMember',
    };
  }

  /** Generic. */
  public static get generic(): ProjVersionMergeDetailEntityType {
    return {
      id: Guid.generate(),
      code: 'Generic',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.entityType.generic',
    };
  }

  /** Team member cost value. */
  public static get teamMemberCostValue(): ProjVersionMergeDetailEntityType {
    return {
      id: Guid.generate(),
      code: 'TeamMemberCostValue',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.entityType.teamMemberCostValue',
    };
  }

  /** Tariff. */
  public static get tariff(): ProjVersionMergeDetailEntityType {
    return {
      id: Guid.generate(),
      code: 'Tariff',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.entityType.tariff',
    };
  }

  /** Tariff rate. */
  public static get tariffRate(): ProjVersionMergeDetailEntityType {
    return {
      id: Guid.generate(),
      code: 'TariffRate',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.entityType.tariffRate',
    };
  }

  /** Tariff assignment. */
  public static get tariffAssignment(): ProjVersionMergeDetailEntityType {
    return {
      id: Guid.generate(),
      code: 'TariffAssignment',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.entityType.tariffAssignment',
    };
  }

  /** Task. */
  public static get task(): ProjVersionMergeDetailEntityType {
    return {
      id: Guid.generate(),
      code: 'Task',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.entityType.task',
    };
  }

  /** Task assignment. */
  public static get taskAssignment(): ProjVersionMergeDetailEntityType {
    return {
      id: Guid.generate(),
      code: 'TaskAssignment',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.entityType.taskAssignment',
    };
  }

  /** Resource plan entry. */
  public static get resourcePlanEntry(): ProjVersionMergeDetailEntityType {
    return {
      id: Guid.generate(),
      code: 'ResourcePlanEntry',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.entityType.resourcePlanEntry',
    };
  }

  /** Recurring Expense rule. */
  public static get recurringExpenseRule(): ProjVersionMergeDetailEntityType {
    return {
      id: Guid.generate(),
      code: 'RecurringExpenseRule',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.entityType.recurringExpenseRule',
    };
  }
}

export const ProjVersionMergeDetailEntityTypes: ProjVersionMergeDetailEntityType[] =
  [
    ProjVersionMergeDetailEntityType.teamMember,
    ProjVersionMergeDetailEntityType.generic,
    ProjVersionMergeDetailEntityType.teamMemberCostValue,
    ProjVersionMergeDetailEntityType.tariff,
    ProjVersionMergeDetailEntityType.tariffRate,
    ProjVersionMergeDetailEntityType.tariffAssignment,
    ProjVersionMergeDetailEntityType.task,
    ProjVersionMergeDetailEntityType.taskAssignment,
    ProjVersionMergeDetailEntityType.resourcePlanEntry,
    ProjVersionMergeDetailEntityType.recurringExpenseRule,
  ];
