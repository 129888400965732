<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-5">
      <div class="form-group">
        <label>{{ 'shared2.filters.created.value' | translate }}</label>
        <wp-date-period-box
          formControlName="created"
          placeholder="{{ 'shared2.filters.created.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.priority.value' | translate }}</label>
        <wp-select-box
          [allowInactive]="filterService.allowInactive$ | async"
          collection="IssuePriorities"
          placeholder="{{ 'shared2.filters.priority.ph' | translate }}"
          formControlName="priority"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.type.value' | translate }}</label>
        <wp-select-box
          [allowInactive]="filterService.allowInactive$ | async"
          collection="IssueTypes"
          placeholder="{{ 'shared2.filters.type.ph' | translate }}"
          formControlName="type"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.assigned.value' | translate }}</label>
        <wp-user-box
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.assigned.ph' | translate }}"
          formControlName="assigned"
        />
      </div>
    </div>
    <div class="col-5">
      <div class="form-group">
        <label>{{ 'shared2.filters.dueDate.value' | translate }}</label>
        <wp-date-period-box
          formControlName="dueDate"
          placeholder="{{ 'shared2.filters.dueDate.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.resolution.value' | translate }}</label>
        <wp-select-box
          [allowInactive]="filterService.allowInactive$ | async"
          directoryId="4d1a525f-3abc-4871-a64a-349c1dd3cabf"
          placeholder="{{ 'shared2.filters.resolution.ph' | translate }}"
          formControlName="resolution"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.project.value' | translate }}</label>
        <wp-select-box
          [allowInactive]="filterService.allowInactive$ | async"
          collection="Projects"
          placeholder="{{ 'shared2.filters.project.ph' | translate }}"
          formControlName="project"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.initiator.value' | translate }}</label>
        <wp-user-box
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.initiator.ph' | translate }}"
          formControlName="initiator"
        />
      </div>
    </div>
    <div class="col-2">
      <tmt-state-select formControlName="states" collection="Issues" />
    </div>
  </div>
</form>
