<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

<form class="modal-form" novalidate ngDraggable [hidden]="isLoading">
  <div class="modal-header">
    <h3 class="modal-title">{{ 'shared.userSettings.header' | translate }}</h3>

    <button
      [disabled]="cardService.savingState$ | async"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <ul class="nav nav-tabs mb-3" ngbNav (navChange)="onNavChange($event)" [(activeId)]="activeTab">
      <li class="nav-item" *ngFor="let tab of visibleTabs" [ngbNavItem]="tab.name">
        <a ngbNavLink class="nav-link">{{ tab.header | translate }}</a>
      </li>
    </ul>

    <ng-container [ngSwitch]="activeTab">
      <wp-user-settings-profile *ngSwitchCase="'profile'"> </wp-user-settings-profile>
      <wp-user-settings-properties *ngSwitchCase="'properties'"> </wp-user-settings-properties>
      <wp-user-settings-notifications *ngSwitchCase="'notifications'">
      </wp-user-settings-notifications>
      <wp-user-settings-security *ngSwitchCase="'security'"> </wp-user-settings-security>
      <wp-user-settings-substitutes *ngSwitchCase="'substitutes'"> </wp-user-settings-substitutes>
    </ng-container>
  </div>

  <div class="modal-footer">
    <button
      *ngIf="activeTab === 'security'"
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="cardService.savingState$ | async"
    >
      {{ 'shared.actions.apply' | translate }}
    </button>

    <button
      *ngIf="
        activeTab === 'properties' || activeTab === 'substitutes' || activeTab === 'notifications'
      "
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="cardService.savingState$ | async"
    >
      {{ 'shared.actions.save' | translate }}
    </button>

    <button
      *ngIf="activeTab === 'profile'"
      type="button"
      class="btn btn-default"
      (click)="cancel()"
      [disabled]="cardService.savingState$ | async"
    >
      {{ 'shared.actions.close' | translate }}
    </button>

    <button
      *ngIf="activeTab !== 'profile'"
      type="button"
      class="btn btn-default"
      (click)="cancel()"
      [disabled]="cardService.savingState$ | async"
    >
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
