<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'employees.card.addingSkills.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="toolbar-container" [formGroup]="filterForm">
      <wp-text-box
        style="width: 250px"
        formControlName="name"
        placeholder="{{ 'shared.filter.defaultPlaceholder' | translate }}"
      ></wp-text-box>
    </div>
    <div class="row">
      <div class="col-6" style="position: relative">
        <table class="wp-nested-table wp-nested-table-hover fix-table" [ngStyle]="leftTableStyles">
          <thead>
            <tr>
              <th
                style="width: 100%"
                title="{{ 'employees.card.addingSkills.columns.availableSkill.hint' | translate }}"
              >
                {{ 'employees.card.addingSkills.columns.availableSkill.header' | translate }}
              </th>
              <th style="width: 35px"></th>
            </tr>
          </thead>
        </table>
        <div class="scroll-container">
          <table
            #leftTbl
            (resized)="resizeLeftTbl()"
            class="wp-nested-table wp-nested-table-hover position-relative"
            style="width: 100%; margin: 0"
          >
            <thead>
              <tr>
                <th style="width: 100%">
                  {{ 'employees.card.addingSkills.columns.availableSkill.header' | translate }}
                </th>
                <th style="width: 35px"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let skill of availableSkills; index as index">
                <td class="text-cell" title="{{ skill.name }}">
                  {{ skill.name }}
                </td>
                <td
                  style="width: 35px"
                  class="control-cell"
                  title="{{ 'shared.actions.select' | translate }}"
                >
                  <button class="btn btn-default remove-button" (click)="selectSkill(skill, index)">
                    <span class="bi-arrow-right bi-15" aria-hidden="true"></span>
                  </button>
                </td>
              </tr>
              <tr *ngIf="availableSkills.length == 0 && !isLoading">
                <td colspan="3" class="text-center text-body-secondary">
                  {{ 'shared.noDisplayData' | translate }}
                </td>
              </tr>
              <tr *ngIf="loadedPartly">
                <td colspan="3" class="text-body-secondary text-center text-uppercase">
                  {{ 'shared.showFirstNRecordsMessage' | translate: { limit: loadLimit } }}<br />
                  {{ 'shared.refineQueryMessage' | translate }}
                </td>
              </tr>
            </tbody>
          </table>
          <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
        </div>
      </div>

      <div class="col-6" style="padding-left: 0">
        <table class="wp-nested-table wp-nested-table-hover fix-table" [ngStyle]="rightTableStyles">
          <thead>
            <tr>
              <th
                style="width: 100%"
                title="{{ 'employees.card.addingSkills.columns.selectedSkill.hint' | translate }}"
              >
                {{ 'employees.card.addingSkills.columns.selectedSkill.header' | translate }}
              </th>
              <th style="width: 35px"></th>
            </tr>
          </thead>
        </table>
        <div class="scroll-container">
          <table
            #rightTbl
            (resized)="resizeRightTbl()"
            class="wp-nested-table wp-nested-table-hover position-relative"
            style="width: 100%; margin: 0"
          >
            <thead>
              <tr>
                <th style="width: 100%">
                  {{ 'employees.card.addingSkills.columns.selectedSkill.header' | translate }}
                </th>
                <th style="width: 35px"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let skill of selectedSkills; index as index">
                <td class="trim" title="{{ skill.name }}">
                  {{ skill.name }}
                </td>
                <td
                  style="width: 35px"
                  class="control-cell"
                  title="{{ 'shared.actions.delete' | translate }}"
                >
                  <button
                    class="btn btn-default btn-nested-table-remove-button"
                    (click)="removeSkill(skill, index)"
                  >
                    <span class="bi bi-trash3" aria-hidden="true"></span>
                  </button>
                </td>
              </tr>
              <tr *ngIf="selectedSkills.length == 0">
                <td colspan="2" class="text-center text-body-secondary">
                  {{ 'shared.noDisplayData' | translate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="selectedSkills.length > 0"
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.add' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
