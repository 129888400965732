<tmt-form-header [name]="directoryCardService.name$ | async"></tmt-form-header>
<tmt-not-found [state]="directoryCardService.state$ | async"></tmt-not-found>
<wp-loading-indicator [state]="directoryCardService.state$ | async"></wp-loading-indicator>

@if ((directoryCardService.state$ | async) === cardState.Ready) {
  <tmt-grid
    [formArray]="directoryCardService.formArray"
    [options]="gridOptions"
    [readonly]="directoryCardService.readonly"
    [loading]="directoryCardService.isGridLoading$ | async"
  />
}
