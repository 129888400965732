import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DefaultFilterService } from '../../shared/default-filter/default-filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { StateService } from '@uirouter/core';
import { Subscription } from 'rxjs';
import { PermissionSetCreationComponent } from '../creation/permission-set-creation.component';
import { TranslateService } from '@ngx-translate/core';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'wp-permission-set-list',
  template: '<wp-entity-list></wp-entity-list> ',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
})
export class PermissionSetListComponent implements OnInit, OnDestroy {
  selectedRowListener: Subscription;
  loadedSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private listService: EntityListService,
    private gridService: GridService,
    private state: StateService,
    private app: AppService,
    private actions: ActionPanelService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.actions.set([
      this.actions.getDefaultAction('create', {
        title: 'settings.permissionSets.list.actions.create',
        isVisible: this.app.checkEntityPermission(
          'PermissionSet',
          PermissionType.Modify,
        ),
        handler: this.create,
      }),
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go('settings.permissionSet', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actions.getDefaultAction('delete', {
        handler: this.listService.delete,
      }),
    ]);

    this.loadedSubscription = this.listService.pageLoaded$.subscribe(
      (rows: any[]) => {
        rows.forEach((row) => {
          if (row.roleName) {
            row.roleName = this.translate.instant(`enums.role.${row.roleName}`);
          }
        });
      },
    );

    this.selectedRowListener = this.gridService.selectedGroup$.subscribe(
      (row: any) => {
        this.actions.action('card').isShown = !!row;
        this.actions.action('delete').isShown =
          row &&
          this.app.checkEntityPermission(
            'PermissionSet',
            PermissionType.Modify,
          );
      },
    );
  }

  private create = () => {
    this.modalService.open(PermissionSetCreationComponent);
  };

  ngOnDestroy(): void {
    this.selectedRowListener.unsubscribe();
    this.loadedSubscription.unsubscribe();
  }
}
