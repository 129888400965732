import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ProjectCostCentersService } from 'src/app/projects/card/project-cost-centers/core/project-cost-centers.service';
import { ProjectCostCenter } from 'src/app/projects/card/project-cost-centers/models/project-cost-center.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { Guid } from 'src/app/shared/helpers/guid';

import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'tmt-project-cost-center-modal',
  templateUrl: './project-cost-center-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectCostCenterModalComponent implements OnInit {
  @Input() editingCostCenter: ProjectCostCenter;

  public isSaving: boolean;

  public form = this.fb.group({
    id: Guid.generate(),
    name: ['', Validators.required],
    legalEntity: [null, Validators.required],
    isActive: true,
  });

  constructor(
    @Inject('entityId') public projectId: string,
    public projectCostCentersService: ProjectCostCentersService,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private dataService: DataService,
    private activeModal: NgbActiveModal,
    private customFieldService: CustomFieldService,
  ) {}

  public ngOnInit(): void {
    this.customFieldService.enrichFormGroup(this.form, 'ProjectCostCenter');
    if (this.editingCostCenter) {
      this.form.patchValue(this.editingCostCenter);
      this.form.controls.legalEntity.disable();
    } else {
      this.customFieldService.enrichFormGroupWithDefaultValues(
        this.form,
        'ProjectCostCenter',
      );
    }

    if (this.projectCostCentersService.readonly) {
      this.form.disable();
    }
  }

  /** Accepts of modal window.*/
  public ok(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving = true;

    const request = this.projectCostCentersService.getCostCenterEditRequest(
      this.form.getRawValue(),
      !!this.editingCostCenter,
    );

    request.subscribe({
      next: (costCenter?: ProjectCostCenter) => {
        this.isSaving = false;
        const notificationString = this.editingCostCenter
          ? 'projects.projects.card.costCenters.messages.edited'
          : 'projects.projects.card.costCenters.messages.created';
        this.notificationService.successLocal(notificationString);
        this.activeModal.close(costCenter ?? this.form.getRawValue());
      },
      error: (error: Exception) => {
        this.notificationService.error(error.message);
        this.isSaving = false;
      },
    });
  }

  /** Closes modal window without save. */
  public cancel(): void {
    this.activeModal.dismiss();
  }
}
