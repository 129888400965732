<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

<form [hidden]="isLoading" class="form" style="width: 350px">
  <div *ngIf="!service.readonly" class="toolbar-container pt-0">
    <div class="group">
      <button
        (click)="editExchangeRate()"
        type="button"
        class="btn btn-default"
        title="{{
          'settings.currencies.card.props.currencyExchangeRates.actions.create' | translate
        }}"
        [disabled]="gridService.loading$ | async"
      >
        <span class="bi bi-plus-lg bi-15"></span>&nbsp;{{ 'shared.actions.create' | translate }}
      </button>

      <button
        (click)="editExchangeRate(gridService.selectedGroupValue)"
        type="button"
        class="btn btn-default"
        title="{{
          'settings.currencies.card.props.currencyExchangeRates.actions.edit' | translate
        }}"
        [disabled]="!(gridService.selectedGroup$ | async)"
      >
        <span class="bi bi-pencil"></span>&nbsp;{{ 'shared.actions.edit' | translate }}
      </button>

      <button
        type="button"
        class="btn btn-default"
        title="{{
          'settings.currencies.card.props.currencyExchangeRates.actions.delete' | translate
        }}"
        [disabled]="!(gridService.selectedGroup$ | async)"
        (click)="deleteExchangeRate(gridService.selectedGroupValue?.id)"
      >
        <span class="bi bi-trash3" aria-hidden="true"></span>
      </button>
    </div>
  </div>

  <wp-entity-list [nested]="true" [hasViewSettings]="false" [showCommands]="false"></wp-entity-list>
</form>
