import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';

@Component({
  selector: 'tmt-project-tariffs-cell-readonly',
  template: `<div
    class="trim"
    [title]="formGroup.getRawValue()[this.column.name]?.name ?? ''"
    [style.padding]="'6px 12px'"
  >
    {{ formGroup.getRawValue()[this.column.name]?.name ?? '' }}
  </div> `,
})
export class ProjectTariffCellReadonlyComponent implements GridTemplateCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
}
