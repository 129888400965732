import { Inject, Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { ProjectTariff } from 'src/app/projects/card/project-tariffs/models/project-tariff.model';

@Injectable()
export class TariffService {
  private cache: Record<string, Observable<ProjectTariff[]>> = {};

  constructor(
    private dataService: DataService,
    @Inject('entityId') private timesheetId: string,
  ) {}

  /**
   * Gets project tariffs from server or cache.
   *
   * @param projectId project's id.
   */
  public getTariffs(projectId: string): Observable<ProjectTariff[]> {
    if (this.cache[projectId]) {
      return this.cache[projectId];
    } else {
      this.cache[projectId] = this.requestProjectTariffs(projectId).pipe(
        shareReplay(1),
      );
      return this.cache[projectId];
    }
  }

  /**
   * Gets list of project tariffs.
   *
   * @param projectId project's id.
   */
  private requestProjectTariffs(
    projectId: string,
  ): Observable<ProjectTariff[]> {
    return this.dataService
      .collection('TimeSheets')
      .entity(this.timesheetId)
      .function('GetTariffs')
      .get<ProjectTariff[]>({ projectId });
  }
}
