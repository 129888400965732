import { Injectable } from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Injectable()
export class LoginLogEntryFilterService extends FilterService {
  public override hasDetails = false;
  public override hasViewSelector = false;
  public override placeholder = 'settings.loginLog.list.filter.placeholder';

  protected override getDefaultValues = (): any => ({
    text: '',
  });

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        or: [
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(email)': { contains: text } },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { user: { 'tolower(name)': { contains: text } } },
        ],
      });
    }

    return result;
  }
}
