import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';
import { State } from 'src/app/shared/models/entities/state.model';

/** Represents a Project Summary Filter Settings. */
export class ProjectSummaryFilterSettings implements BaseSettings {
  public readonly name = 'ProjectSummaryFilterSettings';
  public version: number;
  /** Project. */
  public project: NamedEntity;
  /** Project Manager. */
  public user: NamedEntity;
  /** Program. */
  public program: NamedEntity;
  /** Client. */
  public client: NamedEntity;
  /** Search Text. */
  public searchText: string;
  /** Project States. */
  public projectStates: State[];

  public getDefault(): ProjectSummaryFilterSettings {
    return {
      version: 2,
      project: null,
      user: null,
      program: null,
      client: null,
      searchText: '',
      projectStates: null,
    } as ProjectSummaryFilterSettings;
  }
}
