import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wp-rule-work-day-duration',
  templateUrl: './rule-work-day-duration.component.html',
})
export class RuleWorkDayDurationComponent implements OnInit {
  @Output() changes = new EventEmitter<string>();
  @Input() data: string;
  @Input() readonly: boolean;

  public form = this.fb.group({
    MaxDuration: [
      0,
      [Validators.required, Validators.min(0), Validators.max(24)],
    ],
  });

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.form.patchValue(JSON.parse(this.data));
    if (this.readonly) {
      this.form.disable();
    }
    this.form.valueChanges.subscribe(() => {
      const data = JSON.stringify(this.form.value);
      this.changes.emit(data);
    });
  }
}
