import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Guid } from '../../helpers/guid';
import { EntryPos } from './entry-pos';
import { LogService } from 'src/app/core/log.service';
import { AllocationInfoService } from 'src/app/timesheets/card/table-view/allocation-info/allocation-info.service';

/** Сервис коллективного взаимодействия ячеек таймшита и планировщика. */
@Injectable()
export class CellsOrchestratorService {
  /** Режим одной ячейки, для журнального ТШ. */
  public singleCellMode: boolean;

  /** Идентификатор экземпляра сервиса. */
  public id = Guid.generate();

  public infoBlockId = 'wp-time-entry-info';
  public deleteCode = 46;
  public escCode = 27;
  public bsCode = 8;
  public rightCode = 39;
  public leftCode = 37;
  public upCode = 38;
  public downCode = 40;
  public enterCode = 13;

  private entryDeselectedSubject = new Subject<any>();
  public entryDeselected$ = this.entryDeselectedSubject.asObservable();

  private entrySelectedSubject = new Subject<any>();
  public entrySelected$ = this.entrySelectedSubject.asObservable();

  private selectingStartedSubject = new Subject<any | null>();
  public selectingStarted$ = this.selectingStartedSubject.asObservable();

  private startEditingSubject = new Subject<any>();
  public startEditing$ = this.startEditingSubject.asObservable();

  private stopEditingSubject = new Subject<any>();
  public stopEditing$ = this.stopEditingSubject.asObservable();

  private entryDespreadedSubject = new Subject<any>();
  public entryDespreaded$ = this.entryDespreadedSubject.asObservable();

  private entrySpreadedSubject = new Subject<any>();
  public entrySpreaded$ = this.entrySpreadedSubject.asObservable();

  private valueChangedSubject = new Subject<any>();
  public valueChanged$ = this.valueChangedSubject.asObservable();

  private massValueChangeInProgressSubject = new BehaviorSubject<boolean>(
    false,
  );
  public massValueChangeInProgress$ =
    this.massValueChangeInProgressSubject.asObservable();

  private entryDeactivatedSubject = new Subject<any>();
  public entryDeactivated$ = this.entryDeactivatedSubject.asObservable();

  private entryActivatedSubject = new Subject<any>();
  public entryActivated$ = this.entryActivatedSubject.asObservable();

  private resetSubject = new Subject<any>();
  public reset$ = this.resetSubject.asObservable();

  private dataMatrix: any = {};
  private isSelecting: boolean;
  private isSpreading: boolean;

  private firstSelectedEntry: EntryPos;
  private lastSelectedEntry: EntryPos;
  private lastSpreadEntry: EntryPos;
  private activeEntry: EntryPos;

  //Note: if part of cells from initial array are not using, key can be > 0.
  private get minMatrixKey(): number {
    return Math.min(...Object.keys(this.dataMatrix).map((x) => +x));
  }

  constructor(
    private log: LogService,
    @Optional() private allocationService: AllocationInfoService,
  ) {}

  /** Выделить диапазон (исходя их граничных ячеек). */
  private selectRange() {
    // Снять выделение со всего для упрощения.
    this.entryDeselectedSubject.next(null);

    const selectedRange = this.getSelectedRange();

    if (!selectedRange) {
      return;
    }

    for (let x = selectedRange.left; x <= selectedRange.right; x++) {
      for (let y = selectedRange.top; y <= selectedRange.bottom; y++) {
        this.entrySelectedSubject.next({
          pos: { x, y },
          left: x === selectedRange.left,
          right: x === selectedRange.right,
          top: y === selectedRange.top,
          bottom: y === selectedRange.bottom,
        });
      }
    }

    if (
      selectedRange.left !== selectedRange.right ||
      selectedRange.bottom !== selectedRange.top
    ) {
      this.allocationService?.close();
    }

    this.selectingStartedSubject.next(null);
  }

  private getSelectedRange() {
    if (!this.firstSelectedEntry || !this.lastSelectedEntry) {
      return null;
    }

    return {
      left: Math.min(this.firstSelectedEntry.x, this.lastSelectedEntry.x),
      top: Math.min(this.firstSelectedEntry.y, this.lastSelectedEntry.y),
      right: Math.max(this.firstSelectedEntry.x, this.lastSelectedEntry.x),
      bottom: Math.max(this.firstSelectedEntry.y, this.lastSelectedEntry.y),
    };
  }

  // Получить диапазон распределения.
  private getSpreadRange() {
    if (!this.lastSpreadEntry) {
      return null;
    }
    const selectedRange = this.getSelectedRange();

    const getLeftRange = () => ({
      top: selectedRange.top,
      bottom: selectedRange.bottom,
      left: this.lastSpreadEntry.x,
      right: selectedRange.left - 1,
    });

    const getTopRange = () => ({
      left: selectedRange.left,
      right: selectedRange.right,
      top: this.lastSpreadEntry.y,
      bottom: selectedRange.top - 1,
    });

    const getRightRange = () => ({
      top: selectedRange.top,
      bottom: selectedRange.bottom,
      left: selectedRange.right + 1,
      right: this.lastSpreadEntry.x,
    });

    const getBottomRange = () => ({
      left: selectedRange.left,
      right: selectedRange.right,
      top: selectedRange.bottom + 1,
      bottom: this.lastSpreadEntry.y,
    });

    this.log.debug(this.lastSpreadEntry);
    this.log.debug(selectedRange);

    if (
      this.lastSpreadEntry.x >= selectedRange.left &&
      this.lastSpreadEntry.x <= selectedRange.right
    ) {
      if (this.lastSpreadEntry.y > selectedRange.bottom) {
        return getBottomRange();
      }
      if (this.lastSpreadEntry.y < selectedRange.top) {
        return getTopRange();
      }
    }

    if (
      this.lastSpreadEntry.y >= selectedRange.top &&
      this.lastSpreadEntry.y <= selectedRange.bottom
    ) {
      if (this.lastSpreadEntry.x > selectedRange.right) {
        return getRightRange();
      }
      if (this.lastSpreadEntry.x < selectedRange.left) {
        return getLeftRange();
      }
    }

    if (
      this.lastSpreadEntry.x < selectedRange.left &&
      this.lastSpreadEntry.y < selectedRange.top
    ) {
      if (
        selectedRange.left - this.lastSpreadEntry.x >=
        selectedRange.top - this.lastSpreadEntry.y
      ) {
        return getLeftRange();
      } else {
        return getTopRange();
      }
    }

    if (
      this.lastSpreadEntry.x > selectedRange.right &&
      this.lastSpreadEntry.y < selectedRange.top
    ) {
      if (
        this.lastSpreadEntry.x - selectedRange.right >=
        selectedRange.top - this.lastSpreadEntry.y
      ) {
        return getRightRange();
      } else {
        return getTopRange();
      }
    }

    if (
      this.lastSpreadEntry.x > selectedRange.right &&
      this.lastSpreadEntry.y > selectedRange.bottom
    ) {
      if (
        this.lastSpreadEntry.x - selectedRange.right >=
        this.lastSpreadEntry.y - selectedRange.bottom
      ) {
        return getRightRange();
      } else {
        return getBottomRange();
      }
    }

    if (
      this.lastSpreadEntry.x < selectedRange.left &&
      this.lastSpreadEntry.y > selectedRange.bottom
    ) {
      if (
        selectedRange.left - this.lastSpreadEntry.x >=
        this.lastSpreadEntry.y - selectedRange.bottom
      ) {
        return getLeftRange();
      } else {
        return getBottomRange();
      }
    }
  }

  private selectSpreadRange() {
    // Снять выделение со всего для упрощения.
    this.entryDespreadedSubject.next(null);

    const spreadRange = this.getSpreadRange();
    if (!spreadRange) {
      return;
    }

    for (let x = spreadRange.left; x <= spreadRange.right; x++) {
      for (let y = spreadRange.top; y <= spreadRange.bottom; y++) {
        if (
          x !== spreadRange.left &&
          x !== spreadRange.right &&
          y !== spreadRange.top &&
          y !== spreadRange.bottom
        ) {
          continue;
        }

        this.entrySpreadedSubject.next({
          pos: { x, y },
          left: x === spreadRange.left,
          right: x === spreadRange.right,
          top: y === spreadRange.top,
          bottom: y === spreadRange.bottom,
        });
      }
    }
  }

  private spreadValues(onlyForWorkDay = false) {
    const spreadRange = this.getSpreadRange();

    if (!spreadRange) {
      return;
    }

    this.entryDespreadedSubject.next(null);

    const selectedRange = this.getSelectedRange();

    let srXGap = 0;
    let srYGap = 0;

    this.massValueChangeInProgressSubject.next(true);
    for (let x = spreadRange.left; x <= spreadRange.right; x++) {
      srYGap = 0;
      for (let y = spreadRange.top; y <= spreadRange.bottom; y++) {
        this.valueChangedSubject.next({
          pos: { x, y },
          value:
            this.dataMatrix[selectedRange.left + srXGap][
              selectedRange.top + srYGap
            ],
          onlyForWorkDay,
        });
        if (x === spreadRange.right && y === spreadRange.bottom) {
          this.massValueChangeInProgressSubject.next(false);
        }

        srYGap++;
        if (srYGap > selectedRange.bottom - selectedRange.top) {
          srYGap = 0;
        }
      }
      srXGap++;
      if (srXGap > selectedRange.right - selectedRange.left) {
        srXGap = 0;
      }
    }

    this.firstSelectedEntry = {
      x: Math.min(spreadRange.left, selectedRange.left),
      y: Math.min(spreadRange.top, selectedRange.top),
    };
    this.lastSelectedEntry = {
      x: Math.max(spreadRange.right, selectedRange.right),
      y: Math.max(spreadRange.bottom, selectedRange.bottom),
    };
    this.selectRange();
  }

  public reset() {
    this.firstSelectedEntry =
      this.lastSelectedEntry =
      this.lastSpreadEntry =
      this.activeEntry =
        null;
    this.isSelecting = this.isSpreading = false;
    this.dataMatrix = {};

    this.entryDespreadedSubject.next(null);
    this.entryDeactivatedSubject.next(null);
    this.entryDeselectedSubject.next(null);
    this.resetSubject.next(null);
  }

  public mouseEnter(entryPos: EntryPos) {
    if (this.singleCellMode) {
      return;
    }

    if (this.isSelecting) {
      this.lastSelectedEntry = entryPos;
      this.selectRange();
    }

    if (this.isSpreading) {
      const selectedRange = this.getSelectedRange();

      if (!selectedRange) {
        return;
      }

      // Если курсор вернулся в зону выделения - отмена распределения.
      if (
        entryPos.x >= selectedRange.left &&
        entryPos.x <= selectedRange.right &&
        entryPos.y >= selectedRange.top &&
        entryPos.y <= selectedRange.bottom
      ) {
        this.lastSpreadEntry = null;
        this.entryDespreadedSubject.next(null);
      }

      this.lastSpreadEntry = entryPos;
      this.selectSpreadRange();
    }
  }

  public mouseDown = (entryPos: EntryPos) => {
    // Выделить текущую ячейку.
    this.entryDeactivatedSubject.next({ entryPos });
    this.entryActivatedSubject.next(entryPos);

    // Снять выбор всех областей.
    this.entryDeselectedSubject.next(null);
    this.entryDespreadedSubject.next(null);

    // Сделать ячейку активной.
    this.activeEntry = entryPos;

    if (!this.singleCellMode) {
      this.isSelecting = true;
    }

    this.isSpreading = false;

    this.lastSpreadEntry = null;
    this.firstSelectedEntry = { x: entryPos.x, y: entryPos.y };
    this.lastSelectedEntry = { x: entryPos.x, y: entryPos.y };

    // Выделить текущую ячейку.
    this.entrySelectedSubject.next({
      pos: entryPos,
      left: true,
      right: true,
      top: true,
      bottom: true,
    });
  };

  // Обновление значения в сервисе, для последующего использования.
  public setValue(entryPos: EntryPos, value: number) {
    if (!this.dataMatrix[entryPos.x]) {
      this.dataMatrix[entryPos.x] = {};
    }

    this.dataMatrix[entryPos.x][entryPos.y] = value;
  }

  // Обновление значения в сервисе и сразу событие в ячейку (для обновления извне).
  public setCellValue(entryPos, value) {
    this.setValue(entryPos, value);

    this.valueChangedSubject.next({
      pos: entryPos,
      value,
      force: true,
    });
  }

  public spreaderMouseDown(entryPos: EntryPos) {
    this.isSelecting = false;
    this.isSpreading = true;
  }

  public spreaderDblClick(entryPos: EntryPos) {
    this.isSpreading = false;
    this.lastSpreadEntry = {
      x: Object.keys(this.dataMatrix).length - 1,
      y: this.getSelectedRange().bottom,
    };
    this.spreadValues(true);
  }

  public stopEditing(closeComment: string) {
    this.stopEditingSubject.next({ closeComment });
  }

  private isActiveElementCell(withAdditionalBlocks?: boolean, event?: any) {
    let isCell = false;

    // Проверяем, что клик не модальному окну с комментарием (к таймшиту на текущий момент).
    // Вернем true, что бы считать это кликом по активному элементу.
    if (withAdditionalBlocks) {
      for (let element = event.target; element; element = element.parentNode) {
        // Объект, по которому кликнули, уже удален из DOM.
        if (element !== document && element.parentNode === null) {
          return true;
        }

        if (element.id === this.infoBlockId) {
          return true;
        }
      }
    }

    // Проверим клик по активному (с фокусом) элементу.
    for (
      let el: any = document.activeElement;
      el && !isCell;
      el = el.parentNode
    ) {
      if (
        el.attributes &&
        el.attributes['cells-group'] &&
        el.attributes['cells-group'].value === this.id
      ) {
        isCell = true;
      }
    }

    return isCell;
  }

  mouseupHandler = () => {
    if (this.singleCellMode) {
      return;
    }

    if (this.isSelecting) {
      this.isSelecting = false;
      this.selectRange();
    }

    if (this.isSpreading) {
      this.isSpreading = false;
      this.spreadValues();
    }
  };

  private copyToClipboard(cut = false) {
    const selectedRange = this.getSelectedRange();

    let data = '';
    this.massValueChangeInProgressSubject.next(true);
    for (let y = selectedRange.top; y <= selectedRange.bottom; y++) {
      for (let x = selectedRange.left; x <= selectedRange.right; x++) {
        data += (
          this.dataMatrix[x] ? this.dataMatrix[x][y] ?? 0 : 0
        ).toString();

        if (cut) {
          this.valueChangedSubject.next({
            pos: { x, y },
            value: null,
          });
          if (x === selectedRange.right && y === selectedRange.bottom) {
            this.massValueChangeInProgressSubject.next(false);
          }
        }

        if (x !== selectedRange.right) {
          data += '\t';
        }
      }

      if (y !== selectedRange.bottom) {
        data += '\r';
      }
    }
    this.massValueChangeInProgressSubject.next(false);

    navigator.clipboard?.writeText(data);
  }

  private keyDownHandler = (event: KeyboardEvent) => {
    if (!this.isActiveElementCell()) {
      return;
    }
    event.stopPropagation();

    const selectedRange = this.getSelectedRange();
    if (!selectedRange) {
      return;
    }

    // Ctrl + C
    if (event.code === 'KeyC' && (event.ctrlKey || event.metaKey)) {
      this.copyToClipboard();
      return;
    }

    // Ctrl + X
    if (event.code === 'KeyX' && (event.ctrlKey || event.metaKey)) {
      this.copyToClipboard(true);
      return;
    }

    // Ctrl + V
    if (event.code === 'KeyV' && (event.ctrlKey || event.metaKey)) {
      if (!navigator.clipboard) {
        return;
      }

      const maxX = Object.keys(this.dataMatrix).length - 1;
      const maxY = Object.keys(this.dataMatrix[this.minMatrixKey]).length - 1;

      let rangeRight = 0;
      let rangeBottom = 0;

      navigator.clipboard.readText().then((data) => {
        data = data.replace(/(\r\n|\n)/gm, '\r');
        data = data.replace(/\r$/gm, '');
        this.massValueChangeInProgressSubject.next(true);
        data.split('\r').forEach((line, lineIndex, lineArray) => {
          line.split('\t').forEach((cell, cellIndex, cellArray) => {
            cell = cell.replace(',', '.');
            const value = parseFloat(cell);
            if (!isNaN(value)) {
              const x = this.activeEntry.x + cellIndex;
              const y = this.activeEntry.y + lineIndex;
              if (x <= maxX && y <= maxY) {
                this.valueChangedSubject.next({
                  pos: { x, y },
                  value,
                });
                if (
                  lineIndex === lineArray.length - 1 &&
                  cellIndex === cellArray.length - 1
                ) {
                  this.massValueChangeInProgressSubject.next(false);
                }
                rangeRight = rangeRight < x ? x : rangeRight;
                rangeBottom = rangeBottom < y ? y : rangeBottom;
              }
            }
          });
        });

        this.lastSelectedEntry = {
          x: rangeRight,
          y: rangeBottom,
        };

        this.selectRange();
      });

      return;
    }

    // Удалить значения в выбранном диапазоне.
    if (event.keyCode === this.deleteCode || event.keyCode === this.bsCode) {
      this.massValueChangeInProgressSubject.next(true);
      for (let x = selectedRange.left; x <= selectedRange.right; x++) {
        for (let y = selectedRange.top; y <= selectedRange.bottom; y++) {
          this.valueChangedSubject.next({
            pos: { x, y },
            value: null,
          });
          if (x === selectedRange.right && y === selectedRange.bottom) {
            this.massValueChangeInProgressSubject.next(false);
          }
        }
      }
      return;
    }
    // Переместить выделение ячейки.
    if (
      this.activeEntry &&
      this.dataMatrix &&
      Object.keys(this.dataMatrix).length > 0 &&
      Object.keys(this.dataMatrix[this.minMatrixKey]).length > 0 &&
      [this.rightCode, this.leftCode, this.upCode, this.downCode].indexOf(
        event.keyCode,
      ) !== -1
    ) {
      if (event.keyCode === this.rightCode) {
        if (this.activeEntry.x < Object.keys(this.dataMatrix).length - 1) {
          this.activeEntry.x++;
        }
      }

      if (event.keyCode === this.leftCode) {
        if (this.activeEntry.x > 0) {
          this.activeEntry.x--;
        }
      }

      if (event.keyCode === this.upCode) {
        if (this.activeEntry.y > 0) {
          this.activeEntry.y--;
        }
      }

      if (event.keyCode === this.downCode) {
        if (
          this.activeEntry.y <
          Object.keys(this.dataMatrix[this.minMatrixKey]).length - 1
        ) {
          this.activeEntry.y++;
        }
      }

      this.mouseDown(this.activeEntry);
      this.isSelecting = false;
      return;
    }

    if (event.keyCode === this.escCode) {
      this.stopEditingSubject.next(null);
      return;
    }

    if (event.keyCode === this.enterCode) {
      this.startEditingSubject.next(null);
      return;
    }

    if (
      !(event.keyCode >= 48 && event.keyCode <= 57) &&
      !(event.keyCode >= 96 && event.keyCode <= 105) &&
      event.code !== 'NumpadSubtract' &&
      event.code !== 'Minus'
    ) {
      return;
    }

    this.startEditingSubject.next(event.key);
  };

  private documentClickHandler = (event: any) => {
    if (this.activeEntry && !this.isActiveElementCell(true, event)) {
      this.log.debug(
        `cellsOrchestrator: ${this.activeEntry.x}:${this.activeEntry.y} will be deactivated because of mouse down event.`,
      );
      this.activeEntry = null;
      this.entryDeactivatedSubject.next({ entryPos: null, lostFocus: true });
      this.entryDeselectedSubject.next(null);
      this.entryDespreadedSubject.next(null);
    }
  };

  public init() {
    this.reset();
    document.addEventListener('click', this.documentClickHandler);
    document.addEventListener('mouseup', this.mouseupHandler);
    document.addEventListener('keydown', this.keyDownHandler);
    this.log.debug('Cells orchestrator is initialized.');
  }

  public dispose() {
    document.removeEventListener('click', this.documentClickHandler);
    document.removeEventListener('mouseup', this.mouseupHandler);
    document.removeEventListener('keydown', this.keyDownHandler);
    this.log.debug('Cells orchestrator is disposed.');
  }
}
