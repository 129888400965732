<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.customFields.creation.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group" tmtIndicator>
      <label>{{ 'settings.customFields.card.props.entityType.label' | translate }}</label>
      <wp-select-box
        [values]="entityTypes"
        formControlName="entityType"
        placeholder="{{ 'settings.customFields.card.props.entityType.label' | translate }}"
      ></wp-select-box>
    </div>

    <div class="form-group" tmtIndicator>
      <label>{{ 'settings.customFields.card.props.type.label' | translate }}</label>
      <wp-select-box
        [values]="types"
        formControlName="type"
        placeholder="{{ 'settings.customFields.card.props.type.label' | translate }}"
      ></wp-select-box>
    </div>

    <div class="form-group" tmtIndicator>
      <label class="control-label">{{ 'shared.props.name' | translate }}</label>
      <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}">
      </wp-text-box>
    </div>

    @if (form.controls.type.value?.id === customFieldType.directory) {
      <div class="form-group" tmtIndicator>
        <label>{{ 'settings.customFields.card.props.directory' | translate }}</label>
        <wp-select-box
          formControlName="directoryId"
          [collection]="'Directories'"
          [isIdMode]="true"
          placeholder="{{ 'settings.customFields.card.props.directory' | translate }}"
        />
      </div>
    }
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
