export enum DatePeriodType {
  ThisWeek = 'ThisWeek',
  PrevWeek = 'PrevWeek',
  NextWeek = 'NextWeek',

  ThisMonth = 'ThisMonth',
  PrevMonth = 'PrevMonth',
  NextMonth = 'NextMonth',

  ThisQuarter = 'ThisQuarter',
  PrevQuarter = 'PrevQuarter',
  NextQuarter = 'NextQuarter',

  ThisYear = 'ThisYear',
  PrevYear = 'PrevYear',
  NextYear = 'NextYear',

  Last7Days = 'Last7Days',
  Last30Days = 'Last30Days',

  Custom = 'Custom',
}
