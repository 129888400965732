import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { ReportCardComponent } from './card/report-card.component';
import { ReportListComponent } from './list/report-list.component';
import { REPORT_LIST } from 'src/app/shared/lists/report.list';
import { ReportFilterComponent } from './list/report-filter/report-filter.component';
import { AnalyticsSharedModule } from '../shared/analytics-shared.module';
import { ReportSettingsComponent } from './card/modals/report-settings/report-settings.component';
import { ReportSaveAsComponent } from './card/modals/report-save-as/report-save-as.component';
import { ReportUserSettingsComponent } from './card/report-user-settings/report-user-settings.component';
import { REPORT_TEMPLATE_LIST } from 'src/app/shared/lists/report-template.list';
import { ReportPropertiesComponent } from './card/modals/report-settings/report-properties/report-properties.component';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    ReportCardComponent,
    ReportListComponent,
    ReportFilterComponent,
    ReportSettingsComponent,
    ReportSaveAsComponent,
    ReportUserSettingsComponent,
    ReportPropertiesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AnalyticsSharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'reportBuilder',
          url: '/reports/new?{navigation}&{route}',
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            routeMode: null,
            navigation: 'analytics.reportBuilder',
            viewSettings: {
              type: 'json',
              value: null,
            },
            userSettings: {
              type: 'json',
              value: null,
            },
          },
          component: ReportCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
            {
              token: 'viewSettings',
              deps: [Transition],
              resolveFn: StateHelper.resolveViewSettings,
            },
            {
              token: 'userSettings',
              deps: [Transition],
              resolveFn: StateHelper.resolveViewSettings,
            },
          ],
        },

        {
          name: 'reports',
          url: '/reports/{view:viewName}?{navigation}&{route}',
          component: ReportListComponent,
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            routeMode: null,
            view: null,
            navigation: 'analytics.allReports',
          },
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: REPORT_LIST },
          ],
        },

        {
          name: 'report',
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            routeMode: null,
            navigation: 'analytics.allReports',
          },
          url: '/reports/{entityId:guid}?{navigation}&{route}',
          component: ReportCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },

        {
          name: 'reportTemplates',
          url: '/reports/templates?{navigation}&{route}',
          component: ReportListComponent,
          resolve: [
            { provide: VIEW_NAME, useValue: 'templates' },
            { provide: LIST, useValue: REPORT_TEMPLATE_LIST },
          ],
        },

        {
          name: 'reportTemplate',
          params: {
            navigation: 'analytics.reportTemplates',
          },
          url: '/reports/templates/{entityId:guid}?{navigation}&{route}',
          component: ReportCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class ReportsModule {}
