import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

/** Настройка ресурсного плана. */
export class ResourceForecastCalendarPlannerSettings implements BaseSettings {
  public readonly name = 'ResourceForecastCalendarPlannerSettings';
  version: number;

  planningScale: PlanningScale;

  valueMode: ValueMode;

  /** Смещение периода от текущего. */
  periodOffset: number;

  showOtherActual: boolean;

  getDefault(): ResourceForecastCalendarPlannerSettings {
    return {
      version: 4,
      planningScale: PlanningScale.Day,
      valueMode: ValueMode.Hours,
      periodOffset: 0,
      showOtherActual: false,
    } as ResourceForecastCalendarPlannerSettings;
  }
}
