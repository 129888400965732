import { Injectable, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RecurringExpenseRuleModalComponent } from 'src/app/projects/card/project-expenses/recurring-expense-rule-modal/recurring-expense-rule-modal.component';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';

@Injectable()
export class ProjectExpensesService {
  private ruleModalClosedSubject = new Subject<void>();
  public ruleModalClosed$ = this.ruleModalClosedSubject.asObservable();
  private ruleEstimateUpdatedSubject = new Subject<void>();
  public ruleEstimateUpdated$ = this.ruleEstimateUpdatedSubject.asObservable();

  private _readonly: boolean;

  private project: Project;

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private injector: Injector,
    private projectService: ProjectCardService,
    private versionCardService: ProjectVersionCardService,
    private data: DataService,
    private modal: NgbModal,
  ) {}

  /**
   * Inits service subscriptions.
   */
  public init() {
    this.projectService.project$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((project) => {
        this.project = project;
        this._readonly =
          !project.expenseEstimateEditAllowed ||
          !this.versionCardService.projectVersion.editAllowed;
      });
  }

  /**
   * Disposes all subscriptions.
   * */
  public dispose() {
    this.destroyed$.next();
  }

  /**
   * Opens Recurring expense rule modal.
   *
   * @param ruleId Rule ID. If passed, the existing Rule is opened in edit mode.
   */
  public openExpenseRuleModal(ruleId: string | null = null): void {
    const ref = this.modal.open(RecurringExpenseRuleModalComponent, {
      injector: this.injector,
    });
    const instance =
      ref.componentInstance as RecurringExpenseRuleModalComponent;
    instance.ruleId = ruleId;
    instance.projectId = this.project.id;
    instance.projectVersion = this.versionCardService.projectVersion;
    instance.projectCurrencyCode = this.project.currency.alpha3Code;
    instance.readonly = this._readonly;

    ref.result.then(
      () => {
        this.ruleModalClosedSubject.next();
      },
      () => null,
    );
  }

  /**
   * Updates Recurring expense rule estimate entries.
   *
   * @param ruleId Rule ID.
   */
  public updateExpenseRuleEstimates(ruleId: string): Observable<any> {
    return this.data
      .collection('RecurringExpenseRules')
      .entity(ruleId)
      .action('UpdateRuleEstimate')
      .execute()
      .pipe(tap(() => this.ruleEstimateUpdatedSubject.next()));
  }
}
