export function getFilterByActiveWorkflowInstances(): any {
  return {
    workflowInstances: {
      any: {
        initiatorId: { type: 'guid', value: '#user' },
        isActive: true,
      },
    },
  };
}

export function getFilterByActiveWorkflowTasks(): any {
  return {
    workflowInstances: {
      any: {
        tasks: {
          any: {
            assignedId: {
              type: 'guid',
              value: '#user',
            },
            isActive: true,
          },
        },
      },
    },
  };
}
