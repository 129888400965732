import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TaskDurationCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-duration-cell/task-duration-cell.component';
import { GridComponentCell } from 'src/app/shared-features/grid2/models/grid-component-cell.interface';

@Component({
  selector: 'tmt-task-duration-readonly-cell',
  styleUrls: ['./task-duration-cell.component.scss'],
  templateUrl: './task-duration-readonly-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDurationReadonlyCellComponent
  extends TaskDurationCellComponent
  implements GridComponentCell {}
