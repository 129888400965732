<wp-currency-box
  *ngIf="isCurrencyControl; else numberBox"
  [formControl]="control"
  [allowNull]="false"
  [min]="min"
  [max]="max"
  [currencyCode]="control.value.currencyCode"
  [isCurrencyEditable]="false"
>
</wp-currency-box>

<ng-template #numberBox>
  <wp-number-box [formControl]="control" [type]="getType()" [allowNull]="false" [max]="max">
  </wp-number-box>
</ng-template>
<i *ngIf="hasInfo" class="bi bi-info-circle" aria-hidden="true" title="{{ originalHint }}"> </i>
