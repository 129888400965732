import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewRef,
  Optional,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Wrapper } from '../../wrapper.interface';
import { SelectBoxComponent } from 'src/app/shared/components/controls/select-box/select-box.component';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';

@Component({
  selector: 'wp-select-box-wrapper',
  template: `<wp-select-box
    [formControl]="control"
    [values]="values"
    [query]="query"
    [collection]="collection"
    placeholder="{{ placeholder | translate }}"
    [autofocus]="autofocus"
    [readonly]="readonly"
    [allowNull]="allowNull === false ? false : true"
    [initialValue]="initialValue"
    [directoryId]="directoryId"
    [isIdMode]="isIdMode"
    #component
  ></wp-select-box>`,
})
export class SelectBoxWrapperComponent implements Wrapper {
  @Input() control: UntypedFormControl;

  @Input() values: () => any;

  @Input() placeholder?: string;
  @Input() query?: any;
  @Input() collection?: string;
  @Input() autofocus?: boolean;
  @Input() readonly?: boolean;
  @Input() allowNull?: boolean;
  @Input() directoryId?: string = null;
  @Input() isIdMode = false;

  /** Initial value for input element after rendering. */
  @Input() initialValue?: unknown;

  @ViewChild('component') component: SelectBoxComponent;

  constructor(
    @Optional() gridService: GridService,
    private ref: ChangeDetectorRef,
  ) {
    if (gridService) {
      gridService.detectChanges$.subscribe(() => {
        if (!(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    }
  }
}
