import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class ClientTariffsSettings implements BaseSettings {
  public readonly name = 'ClientTariffsSettings';
  public version: number;
  public onlyActive: boolean;

  public getDefault(): ClientTariffsSettings {
    return {
      version: 1,
      onlyActive: false,
    } as ClientTariffsSettings;
  }
}
