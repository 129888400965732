export const projectTaskId = `ab457206-8adf-4d6e-a4c7-91b1e73a09b9`;
export const projectTasksJSON = `
[
  {
      "startDate": "2023-10-05",
      "endDate": "2023-10-05",
      "projectId": "d1fe4966-6d2a-4b15-8049-14cabd0eadf5",
      "description": "",
      "number": 1,
      "indent": 1,
      "structNumber": "2",
      "allowTimeEntry": true,
      "leadTaskId": "7c64566a-24b3-4222-884f-b758b260b32a",
      "rowVersion": 66657273,
      "name": "Second",
      "id": "028bde1d-1675-41cc-82c7-529d08c1f298",
      "isActive": true,
      "dependencies": [
          {
              "predecessorId": "a87d1767-319b-4e07-9d8a-3e081ed9a2eb",
              "type": "FinishToStart"
          },
          {
              "predecessorId": "36e628ec-e697-4491-8ef5-fdd13e52a8fe",
              "type": "FinishToStart"
          }
      ],
      "projectTaskAssignments": [
          {
              "projectTeamMemberId": null,
              "isAllTeamRole": true,
              "id": "c0406a13-e4f6-4c03-84e4-fa3ca8d1f8fb"
          }
      ]
  },
  {
      "startDate": "2023-10-05",
      "endDate": "2023-10-05",
      "projectId": "d1fe4966-6d2a-4b15-8049-14cabd0eadf5",
      "description": "",
      "number": 0,
      "indent": 2,
      "structNumber": "2.1",
      "allowTimeEntry": true,
      "leadTaskId": "028bde1d-1675-41cc-82c7-529d08c1f298",
      "rowVersion": 66657274,
      "name": "SecondChild",
      "id": "0bdb87d9-2811-4528-b528-b84b36389d9d",
      "isActive": true,
      "dependencies": [],
      "projectTaskAssignments": [
          {
              "projectTeamMemberId": null,
              "isAllTeamRole": true,
              "id": "bccd30bd-14a8-4288-82b2-2b696f8b486f"
          }
      ]
  },
  {
      "startDate": "2023-09-29",
      "endDate": "2023-10-02",
      "projectId": "d1fe4966-6d2a-4b15-8049-14cabd0eadf5",
      "description": "",
      "number": 0,
      "indent": 1,
      "structNumber": "1",
      "allowTimeEntry": true,
      "leadTaskId": "7c64566a-24b3-4222-884f-b758b260b32a",
      "rowVersion": 66657228,
      "name": "First",
      "id": "36e628ec-e697-4491-8ef5-fdd13e52a8fe",
      "isActive": true,
      "dependencies": [],
      "projectTaskAssignments": [
          {
              "projectTeamMemberId": null,
              "isAllTeamRole": true,
              "id": "e355c992-f8bd-4f2d-a788-6ba2f648a251"
          }
      ]
  },
  {
      "startDate": "2023-10-10",
      "endDate": "2023-10-10",
      "projectId": "d1fe4966-6d2a-4b15-8049-14cabd0eadf5",
      "description": "",
      "number": 3,
      "indent": 1,
      "structNumber": "4.1.1.1.1.1",
      "allowTimeEntry": true,
      "leadTaskId": "7c64566a-24b3-4222-884f-b758b260b32a",
      "rowVersion": 66657257,
      "name": "Fourth",
      "id": "77279d06-09c6-4bef-af4e-b7ca9005bb1c",
      "isActive": true,
      "dependencies": [
          {
              "predecessorId": "c5a84f8d-e970-4f61-a901-43d966f5bc77",
              "type": "FinishToStart"
          }
      ],
      "projectTaskAssignments": [
          {
              "projectTeamMemberId": null,
              "isAllTeamRole": true,
              "id": "5d9b6fe7-0b87-46a6-b3ab-4f28ffa114a8"
          }
      ]
  },
  {
      "startDate": "2023-09-29",
      "endDate": "2023-10-11",
      "projectId": "d1fe4966-6d2a-4b15-8049-14cabd0eadf5",
      "description": "",
      "number": 0,
      "indent": 0,
      "structNumber": "",
      "allowTimeEntry": true,
      "leadTaskId": null,
      "rowVersion": 66657228,
      "name": "Predecessor Test",
      "id": "7c64566a-24b3-4222-884f-b758b260b32a",
      "isActive": true,
      "dependencies": [],
      "projectTaskAssignments": [
          {
              "projectTeamMemberId": null,
              "isAllTeamRole": true,
              "id": "bf7ab4d9-dcf0-483c-8eff-f44b7d408d7f"
          }
      ]
  },
  {
      "startDate": "2023-09-29",
      "endDate": "2023-10-02",
      "projectId": "d1fe4966-6d2a-4b15-8049-14cabd0eadf5",
      "description": "",
      "number": 0,
      "indent": 2,
      "structNumber": "1.1",
      "allowTimeEntry": true,
      "leadTaskId": "36e628ec-e697-4491-8ef5-fdd13e52a8fe",
      "rowVersion": 66657227,
      "name": "FirstChild",
      "id": "a87d1767-319b-4e07-9d8a-3e081ed9a2eb",
      "isActive": true,
      "dependencies": [],
      "projectTaskAssignments": [
          {
              "projectTeamMemberId": null,
              "isAllTeamRole": true,
              "id": "b5ef6a5f-eda0-4356-9eb6-214185bb7d95"
          }
      ]
  },
  {
      "startDate": "2023-10-11",
      "endDate": "2023-10-11",
      "projectId": "d1fe4966-6d2a-4b15-8049-14cabd0eadf5",
      "description": "",
      "number": 4,
      "indent": 1,
      "structNumber": "5",
      "allowTimeEntry": true,
      "leadTaskId": "7c64566a-24b3-4222-884f-b758b260b32a",
      "rowVersion": 66657201,
      "name": "Fifth",
      "id": "ab457206-8adf-4d6e-a4c7-91b1e73a09b9",
      "isActive": true,
      "dependencies": [
          {
              "predecessorId": "028bde1d-1675-41cc-82c7-529d08c1f298",
              "type": "FinishToStart"
          },
          {
              "predecessorId": "77279d06-09c6-4bef-af4e-b7ca9005bb1c",
              "type": "FinishToStart"
          }
      ],
      "projectTaskAssignments": [
          {
              "projectTeamMemberId": null,
              "isAllTeamRole": true,
              "id": "36f87d29-7c01-40a2-83bc-6c121ce0ec9d"
          }
      ]
  },
  {
      "startDate": "2023-10-05",
      "endDate": "2023-10-05",
      "projectId": "d1fe4966-6d2a-4b15-8049-14cabd0eadf5",
      "description": "",
      "number": 0,
      "indent": 3,
      "structNumber": "2.1.1",
      "allowTimeEntry": true,
      "leadTaskId": "0bdb87d9-2811-4528-b528-b84b36389d9d",
      "rowVersion": 66657274,
      "name": "SecondChild-Child-Child-Child-Child-Child-Child-Child-Child",
      "id": "b2c65d4b-4dc4-49dd-96f6-74e05d7d1803",
      "isActive": true,
      "dependencies": [
          {
              "predecessorId": "36e628ec-e697-4491-8ef5-fdd13e52a8fe",
              "type": "FinishToStart"
          }
      ],
      "projectTaskAssignments": [
          {
              "projectTeamMemberId": null,
              "isAllTeamRole": true,
              "id": "62d90780-5480-4e42-a336-10bc72fc521c"
          }
      ]
  },
  {
      "startDate": "2023-10-09",
      "endDate": "2023-10-09",
      "projectId": "d1fe4966-6d2a-4b15-8049-14cabd0eadf5",
      "description": "",
      "number": 2,
      "indent": 1,
      "structNumber": "3",
      "allowTimeEntry": true,
      "leadTaskId": "7c64566a-24b3-4222-884f-b758b260b32a",
      "rowVersion": 66657256,
      "name": "Third",
      "id": "c5a84f8d-e970-4f61-a901-43d966f5bc77",
      "isActive": true,
      "dependencies": [
          {
              "predecessorId": "b2c65d4b-4dc4-49dd-96f6-74e05d7d1803",
              "type": "FinishToStart"
          },
          {
              "predecessorId": "028bde1d-1675-41cc-82c7-529d08c1f298",
              "type": "FinishToStart"
          }
      ],
      "projectTaskAssignments": [
          {
              "projectTeamMemberId": null,
              "isAllTeamRole": true,
              "id": "142bfc66-f5af-43ce-82de-3a12935bf931"
          }
      ]
  }
]
`;
