<tmt-form-header [name]="form.value?.name" />
<tmt-not-found [state]="state$ | async" />
<wp-loading-indicator [state]="state$ | async" />
<wp-data-not-saved-indicator [form]="form" />

<form *ngIf="(state$ | async) === 'Ready'" [formGroup]="form" class="form mt-3">
  <p *ngIf="form.value.isDefault">
    <i class="bi bi-check-circle" aria-hidden="true"></i> &nbsp;<strong>{{
      'settings.resourcePools.card.main.props.usingByDefault' | translate
    }}</strong>
  </p>

  <div class="form-group">
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}" />
  </div>

  <div class="form-group form-group--code">
    <label>{{ 'shared.props.code' | translate }}</label>
    <wp-text-box formControlName="code" placeholder="{{ 'shared.props.code' | translate }}" />
  </div>

  <div class="form-group">
    <label class="control-label">{{
      'settings.resourcePools.card.main.props.manager' | translate
    }}</label>
    <wp-user-box
      formControlName="manager"
      placeholder="{{ 'settings.resourcePools.card.main.props.manager' | translate }}"
    />
  </div>

  <div class="form-group">
    <label>{{ 'settings.resourcePools.card.main.props.leadResourcePool' | translate }}</label>
    <tmt-hierarchical-box
      formControlName="leadResourcePool"
      collection="ResourcePools"
      parentIdKey="leadResourcePoolId"
      placeholder="{{ 'settings.resourcePools.card.main.props.leadResourcePool' | translate }}"
    />
  </div>

  <div class="form-group">
    <label>{{ 'shared.props.description' | translate }}</label>
    <wp-multiline-text-box
      formControlName="description"
      placeholder="{{ 'shared.props.description' | translate }}"
    />
  </div>

  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>

  <div *ngIf="!readonly" class="actions">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
