<form [formGroup]="form" class="modal-form resource-summary__filter-modal" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'resources.resourceSummary.filter.header' | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>{{
            'resources.resourceSummary.filter.properties.resourcePool.label' | translate
          }}</label>
          <tmt-hierarchical-box
            formControlName="resourcePool"
            collection="ResourcePools"
            parentIdKey="leadResourcePoolId"
            [placeholder]="
              'resources.resourceSummary.filter.properties.resourcePool.placeholder' | translate
            "
          />
        </div>

        <div class="form-group">
          <label>{{ 'resources.resourceSummary.filter.properties.role.label' | translate }}</label>
          <wp-select-box
            formControlName="role"
            collection="Roles"
            [placeholder]="
              'resources.resourceSummary.filter.properties.role.placeholder' | translate
            "
          ></wp-select-box>
        </div>

        <div class="form-group">
          <label>{{ 'resources.resourceSummary.filter.properties.level.label' | translate }}</label>

          <wp-select-box
            formControlName="level"
            collection="Levels"
            [placeholder]="
              'resources.resourceSummary.filter.properties.level.placeholder' | translate
            "
          ></wp-select-box>
        </div>

        <div class="form-group">
          <label>{{
            'resources.resourceSummary.filter.properties.location.label' | translate
          }}</label>

          <wp-select-box
            formControlName="location"
            collection="Locations"
            [placeholder]="
              'resources.resourceSummary.filter.properties.location.placeholder' | translate
            "
          ></wp-select-box>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>{{
            'resources.resourceSummary.filter.properties.project.label' | translate
          }}</label>
          <wp-select-box
            collection="Projects"
            formControlName="project"
            [placeholder]="
              'resources.resourceSummary.filter.properties.project.placeholder' | translate
            "
          ></wp-select-box>
        </div>

        <div class="form-group">
          <label>{{
            'resources.resourceSummary.filter.properties.department.label' | translate
          }}</label>
          <tmt-department-box
            formControlName="department"
            subordinatesOption="true"
            [placeholder]="
              'resources.resourceSummary.filter.properties.department.placeholder' | translate
            "
          ></tmt-department-box>
        </div>

        <div class="form-group">
          <label>{{
            'resources.resourceSummary.filter.properties.program.label' | translate
          }}</label>
          <wp-select-box
            collection="Programs"
            formControlName="program"
            [placeholder]="
              'resources.resourceSummary.filter.properties.program.placeholder' | translate
            "
          ></wp-select-box>
        </div>

        <div class="form-group">
          <label>{{
            'resources.resourceSummary.filter.properties.client.label' | translate
          }}</label>
          <wp-select-box
            collection="Organizations"
            formControlName="client"
            [placeholder]="
              'resources.resourceSummary.filter.properties.client.placeholder' | translate
            "
          ></wp-select-box>
        </div>

        <label class="control-label">
          {{ 'resources.resourceSummary.filter.properties.restrictBy.label' | translate }}
        </label>

        <div class="form-check">
          <input
            id="mySubordinates"
            formControlName="isMySubordinates"
            type="checkbox"
            class="form-check-input"
          />
          <label class="form-check-label" for="mySubordinates">
            {{
              'resources.resourceSummary.filter.properties.restrictBy.mySubordinates.label'
                | translate
            }}
          </label>
        </div>

        <div class="form-check">
          <input
            id="myProjects"
            formControlName="isMyProjects"
            type="checkbox"
            class="form-check-input"
          />
          <label class="form-check-label" for="myProjects">
            {{
              'resources.resourceSummary.filter.properties.restrictBy.myProjects.label' | translate
            }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <button type="button" class="btn btn-default" (click)="clearValues()">
          {{ 'shared.actions.clear' | translate }}
        </button>
      </div>
      <div>
        <button type="button" class="btn btn-primary" (click)="ok()">
          {{ 'shared.actions.ok' | translate }}
        </button>
        <button type="button" class="btn btn-default ms-2" (click)="cancel()">
          {{ 'shared.actions.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
