<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'shared.resourceRoles.addingRolesModal.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="toolbar-container" [formGroup]="filterForm">
      <wp-text-box
        style="width: 250px"
        formControlName="name"
        placeholder="{{ 'shared.filter.defaultPlaceholder' | translate }}"
      ></wp-text-box>
    </div>

    <div class="row">
      <div class="col d-relative">
        <table class="wp-nested-table wp-nested-table-hover fix-table" [ngStyle]="leftTableStyles">
          <thead>
            <tr>
              <th style="width: 20%" title="{{ 'shared.resourceRoles.columns.role' | translate }}">
                {{ 'shared.resourceRoles.columns.role' | translate }}
              </th>
              <th
                style="width: 40%"
                title="{{ 'shared.resourceRoles.columns.competences' | translate }}"
              >
                {{ 'shared.resourceRoles.columns.competences' | translate }}
              </th>
              <th
                style="width: 40%"
                title="{{ 'shared.resourceRoles.columns.description' | translate }}"
              >
                {{ 'shared.resourceRoles.columns.description' | translate }}
              </th>
            </tr>
          </thead>
        </table>

        <div class="scroll-container">
          <table
            #leftTbl
            (resized)="resizeLeftTbl()"
            class="wp-nested-table wp-nested-table-hover position-relative"
            style="width: 100%; margin: 0"
          >
            <thead>
              <tr>
                <th style="width: 20%">
                  {{ 'shared.resourceRoles.columns.role' | translate }}
                </th>
                <th style="width: 40%">
                  {{ 'shared.resourceRoles.columns.competences' | translate }}
                </th>
                <th
                  style="width: 40%"
                  title="{{ 'shared.resourceRoles.columns.description' | translate }}"
                >
                  {{ 'shared.resourceRoles.columns.description' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let role of availableRoles; index as index"
                (click)="selectRole(role, index)"
              >
                <td class="trim" title="{{ role.name }}">
                  {{ role.name }}
                </td>
                <td class="trim" title="{{ getCompetencesString(role) }}">
                  {{ getCompetencesString(role) }}
                </td>
                <td class="text-cell trim" title="{{ role.description }}">
                  {{ role.description }}
                </td>
              </tr>
              <tr *ngIf="availableRoles.length == 0 && !isLoading">
                <td colspan="4" class="text-center text-body-secondary">
                  {{ 'shared.noDisplayData' | translate }}
                </td>
              </tr>
              <tr *ngIf="loadedPartly">
                <td colspan="4" class="text-body-secondary text-center text-uppercase">
                  {{ 'shared.showFirstNRecordsMessage' | translate: { limit: loadLimit } }}<br />
                  {{ 'shared.refineQueryMessage' | translate }}
                </td>
              </tr>
            </tbody>
          </table>
          <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="selected-roles-scroll-container">
          <div
            class="selected-resource-block trim"
            *ngFor="let role of selectedRoles; index as index"
            (click)="removeRole(role, index)"
          >
            <div class="trim" title="{{ role.name }}">
              {{ role.name }}
            </div>

            <button type="button" class="close" (click)="removeRole(role, index)">&times;</button>
          </div>
          <div *ngIf="selectedRoles.length == 0" class="text-uppercase text-body-secondary no-data">
            {{ 'shared.resourceRoles.addingRolesModal.noDisplayData' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="selectedRoles.length > 0"
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.add' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
