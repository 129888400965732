import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ProjectCardService } from '../core/project-card.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wp-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
})
export class ProjectOverviewComponent implements OnInit, OnDestroy {
  public activeTab: string;
  public project: Project;
  public readonly commentedEntityCollectionType = CommentedEntityCollectionType;

  private readonly destroyed$ = new Subject<void>();

  constructor(public service: ProjectCardService) {}

  ngOnInit() {
    this.service.project$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((project) => {
        this.project = project;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
