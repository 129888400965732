import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  TimeAllocation,
  Timesheet,
} from 'src/app/shared/models/entities/base/timesheet.model';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { AppName } from 'src/app/shared/globals/app-name';
import { NavigationService } from 'src/app/core/navigation.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { TimesheetCardService } from './core/timesheet-card.service';
import { Subject } from 'rxjs';
import { Line } from './shared/models/line.model';
import { TranslateService } from '@ngx-translate/core';
import { WorkPipe } from 'src/app/shared/pipes/work.pipe';
import { PercentPipe } from '@angular/common';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { AppService } from 'src/app/core/app.service';
import { StopwatchService } from 'src/app/core/stopwatch.service';
import { AllocationInfoService } from './table-view/allocation-info/allocation-info.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { HeaderIndicator } from 'src/app/shared/components/chrome/form-header2/header-indicator.model';
import { takeUntil } from 'rxjs/operators';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { TimesheetLifecycleService } from './core/timesheet-lifecycle.service';
import { RoleService } from './core/role.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { CostCenterService } from 'src/app/timesheets/card/core/cost-center.service';
import {
  MetaEntityDirectoryProperty,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';

@Component({
  selector: 'tmt-timesheet-card',
  templateUrl: './timesheet-card.component.html',
  styleUrls: ['./timesheet-card.component.scss'],
  providers: [
    SavingQueueService,
    TimesheetCardService,
    RoleService,
    AllocationInfoService,
    CostCenterService,
    { provide: META_ENTITY_TYPE, useValue: 'TimeSheet' },
    { provide: LifecycleService, useClass: TimesheetLifecycleService },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public activeTab: string;
  public readonly = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly CommentedEntityCollectionType =
    CommentedEntityCollectionType;

  private lines: Line[] = [];

  private labelHours = this.translate.instant(
    'timesheets.card.props.totals.hours',
  );
  private labelBillableHours = this.translate.instant(
    'timesheets.card.props.totals.billable',
  );

  private labelSchedule = this.translate.instant(
    'timesheets.card.props.totals.schedule',
  );

  private labelUtilization = this.translate.instant(
    'timesheets.card.props.totals.utilization',
  );

  private destroyed$ = new Subject<void>();

  constructor(
    public service: TimesheetCardService,
    public autosave: SavingQueueService,
    public navigation: NavigationService,
    public blockUI: BlockUIService,
    public actionService: ActionPanelService,
    private stopwatchService: StopwatchService,
    public app: AppService,
    private workPipe: WorkPipe,
    private percentPipe: PercentPipe,
    private translate: TranslateService,
    private message: MessageService,
    private notification: NotificationService,
    private customFieldService: CustomFieldService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private lifecycleService: LifecycleService,
  ) {
    this.stopwatchService.autosaveService = this.autosave;
  }

  ngOnInit() {
    this.actionService.setHasAutosave(true);

    // Установка главного меню.
    this.actionService.setAdditional([
      {
        title: 'shared.actions.delete',
        hint: 'timesheets.card.actions.delete.hint',
        name: 'delete',
        isBusy: false,
        isVisible: false,
        handler: () => this.delete(),
      },
      {
        title: 'finance.entries.actions.openEntries.title',
        hint: 'finance.entries.actions.openEntries.hint',
        name: 'openEntries',
        isBusy: false,
        isVisible: this.app.checkAppAccess(AppName.Finance),
        handler: () => this.service.goToAccountingEntry(),
      },
    ]);

    this.actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.service.load();
        this.lifecycleService.reloadLifecycle();
      });

    this.lifecycleService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.service.load();
      });

    this.service.recalculate$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.calculateTotals();
    });

    this.service.timesheet$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((timesheet: Timesheet) => {
        this.readonly = !timesheet.editAllowed;

        this.calculateTotals();
        this.updateUIState();
      });

    this.service.data$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: Line[]) => {
        this.lines = data;
        this.autosave.addToQueue(this.entityId, () =>
          this.service.collection
            .entity(this.entityId)
            .update(this.getTimesheetToSave(), { withResponse: true }),
        );
        this.calculateTotals();
      });
  }

  ngOnDestroy(): void {
    this.service.dispose();
    this.stopwatchService.autosaveService = null;
    this.destroyed$.next();
  }

  /** Открыть сведения о пользователе. */
  public openUserInfo() {
    const userId = this.service.timesheet.user.id;
    const target = document.getElementById('timesheet-user');
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /** Удаление таймшита. */
  public delete() {
    this.message
      .confirmLocal('timesheets.card.messages.deleteConfirmation')
      .then(
        () => {
          this.service.collection
            .entity(this.entityId)
            .delete()
            .subscribe({
              next: () => {
                this.notification.successLocal('shared.messages.deleted');
                this.navigation.goToSelectedNavItem(true);
              },
              error: (error: Exception) => {
                this.message
                  .error(error.message)
                  .then(this.service.load, this.service.load);
              },
            });
        },
        () => null,
      );
  }

  private isAllocationFilledOut = (allocation: TimeAllocation) =>
    allocation.comments?.length > 0 ||
    allocation.duration > 0 ||
    this.service.allocationCustomFields.some((field) => {
      const key =
        field.type === MetaEntityPropertyType.directory
          ? (field as MetaEntityDirectoryProperty).keyProperty
          : field.name;
      return allocation[key] !== null && allocation[key] !== undefined;
    });

  /** Рассчитать итоги по таймшиту. */
  private calculateTotals() {
    if (!this.service.timesheet) {
      return;
    }

    let hours = 0;
    let schedule = 0;
    const billableHours = this.service.timesheet.billableDuration;

    this.lines.forEach((line) =>
      line.allocations.forEach((allocation) => {
        hours += allocation.duration ?? 0;
      }),
    );

    this.service.timesheet.timeOffRequests.forEach((request) =>
      request.timeAllocations.forEach((allocation) => {
        const allocationDate = Date.parse(allocation.date);
        if (
          allocationDate >= Date.parse(this.service.timesheet.dateFrom) &&
          allocationDate <= Date.parse(this.service.timesheet.dateTo)
        ) {
          hours += allocation.duration ?? 0;
        }
      }),
    );

    this.service.timesheet.schedule.forEach((s) => (schedule += s.hours ?? 0));

    const indicators: HeaderIndicator[] = [
      {
        value: this.workPipe.transform(hours),
        description: this.labelHours,
      },
      {
        value: this.workPipe.transform(billableHours),
        description: this.labelBillableHours,
      },
      {
        value: `${this.workPipe.transform(schedule)}`,
        description: this.labelSchedule,
      },
      {
        value: this.percentPipe.transform(
          hours > 0 ? billableHours / hours : 0,
        ),
        description: this.labelUtilization,
      },
    ];

    this.service.updateIndicators(indicators);
  }

  private updateUIState() {
    this.actionService.action('delete').isShown =
      this.service.timesheet.deleteAllowed;
  }

  private getTimesheetToSave() {
    const timesheet = {
      id: this.service.timesheet.id,
      name: this.service.timesheet.name,
      rowVersion: this.service.timesheet.rowVersion,
      timeSheetLines: [],
    };

    const lines = this.lines;

    let index = 0;
    lines.forEach((line) => {
      const timeSheetLine: any = {
        id: line.id,
        timeAllocations: [],
        projectId: line.task.project?.id,
        projectTaskId: line.task.projectTask?.id,
        activityId: line.activity?.id,
        roleId: line.role?.id,
        orderNumber: index,
        date: line.date,
        projectCostCenterId: line.projectCostCenter?.id,
        projectTariffId: line.projectTariff?.id,
      };

      this.customFieldService.assignValues(
        timeSheetLine,
        line,
        'TimeSheetLine',
      );

      line.allocations.forEach((allocation) => {
        if (this.isAllocationFilledOut(allocation)) {
          const newAllocation = {
            id: allocation.id,
            timeSheetLineId: timeSheetLine.id,
            duration: allocation.duration,
            comments: allocation.comments,
            date: allocation.date,
          };

          this.customFieldService.assignValues(
            newAllocation,
            allocation,
            'TimeAllocation',
          );
          timeSheetLine.timeAllocations.push(newAllocation);
        }
      });

      timesheet.timeSheetLines.push(timeSheetLine);
      index++;
    });

    return timesheet;
  }
}
