import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { LogService } from './log.service';
import { MessageService } from './message.service';

@Injectable()
export class UpdateService {
  constructor(
    private swUpdate: SwUpdate,
    private translate: TranslateService,
    private messages: MessageService,
    private log: LogService,
  ) {}

  public run() {
    this.swUpdate.unrecoverable.subscribe((event) => {
      const message = `Update version. An error occurred that we cannot recover from: ${event.reason}.`;
      this.log.error(message);
      document.location.reload();
    });

    timer(0, AppConfigService.config.updateCheckPeriod * 1000).subscribe(() => {
      this.log.info('Check for updates...');

      this.swUpdate.checkForUpdate().then(
        (result) => {
          if (result) {
            this.log.info('New version is available.');

            const activate = () =>
              this.swUpdate
                .activateUpdate()
                .then(() => document.location.reload());

            setTimeout(() => activate(), 60000);

            this.messages
              .message(
                this.translate.instant('shared.versionUpdate.text'),
                this.translate.instant('shared.versionUpdate.header'),
                [],
                this.translate.instant('shared.versionUpdate.action'),
              )
              .then(() => activate());
          } else {
            this.log.info('Version is up to date.');
          }
        },
        (error) => {
          this.log.error(error);
        },
      );
    });
  }
}
