import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { PivotTableComponent } from './pivot-table/pivot-table.component';
import { ReportViewSettingsComponent } from './report-view-settings/report-view-settings.component';
import { FieldPropertiesModalComponent } from './report-view-settings/field-properties-modal/field-properties-modal.component';
import { ReportFiltersComponent } from './filters/report-filters/report-filters.component';
import { ReportFilterCriterionComponent } from './filters/report-filter-criterion/report-filter-criterion.component';
import { ReportPeriodComponent } from './report-period/report-period.component';

@NgModule({
  declarations: [
    ReportViewSettingsComponent,
    ReportFiltersComponent,
    PivotTableComponent,
    ReportFilterCriterionComponent,
    FieldPropertiesModalComponent,
    ReportPeriodComponent,
  ],

  imports: [CommonModule, SharedModule],
  exports: [
    ReportViewSettingsComponent,
    ReportFiltersComponent,
    ReportPeriodComponent,
    PivotTableComponent,
  ],
})
export class AnalyticsSharedModule {}
