<div class="btn-group" role="group">
  <input type="radio" class="btn-check" id="kpi-switcher" [formControl]="control" [value]="'kpi'" />
  <label class="btn btn-default" for="kpi-switcher">{{
    'projects.overview.kpi.header' | translate
  }}</label>

  <input
    type="radio"
    class="btn-check"
    id="analysis-switcher"
    [formControl]="control"
    [value]="'analysis'"
  />
  <label class="btn btn-default" for="analysis-switcher">{{
    'projects.overview.trends' | translate
  }}</label>
</div>
