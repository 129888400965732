/* eslint-disable @typescript-eslint/naming-convention */

/** Метод планирования (при бронировании или ресурсном планировании ресурса). */
export enum PlanningMethod {
  Manual = 'Manual',
  FrontLoad = 'FrontLoad',
  RemainingCapacity = 'RemainingCapacity',
  Evenly = 'Evenly',
  SchedulePercent = 'SchedulePercent',
}
