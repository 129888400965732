<div class="toolbar-container">
  <div class="btn-group" role="group">
    <button class="btn btn-default" (click)="form.disable()">Disable</button>
    <button class="btn btn-default" (click)="form.enable()">Enable</button>
  </div>

  <button class="btn btn-default" (click)="setValue()">Set value</button>
</div>

<hr />

<div [formGroup]="form" style="width: 400px">
  <h2>Date pair</h2>
  <div class="d-flex gap-4">
    <wp-date-box formControlName="dateFrom" />
    <wp-date-box formControlName="dateTo" />
  </div>

  <br />
  <hr />

  <h2>Cascade dependency</h2>
  <div class="d-flex gap-4">
    <wp-select-box
      #cascadeControl
      collection="Organizations"
      placeholder="Organizations"
      formControlName="client"
    />
    <wp-select-box
      #cascadeControl
      collection="Projects"
      placeholder="Projects"
      formControlName="project"
      [query]="{ select: ['id', 'name', 'organizationId'] }"
    />
  </div>

  <br />
  <hr />

  <h2>Cascade dependency (Disable dependent)</h2>
  <div class="d-flex gap-4">
    <wp-select-box #cascadeControl collection="Roles" formControlName="role" placeholder="Roles" />
    <tmt-multiselect-box
      #cascadeControl
      collection="Competences"
      formControlName="competence"
      placeholder="Competences"
      [query]="{ select: ['id', 'name', 'roleId'] }"
    />
  </div>
</div>
