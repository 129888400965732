import { Injectable, OnDestroy } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TimeOffScheduleFiltersService } from './time-off-schedule-filters/time-off-schedule-filters.service';
import { Dictionary, sortBy } from 'lodash';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Guid } from 'src/app/shared/helpers/guid';

@Injectable()
export class TimeOffScheduleDataService implements OnDestroy {
  public leaveSchedules: any[] = [];
  public users: NamedEntity[] = [];

  private _loading = false;
  public get loading() {
    return this._loading;
  }
  public set loading(val) {
    this._loading = val;
    this.loadingSubject.next(val);
  }
  private loadingSubject = new BehaviorSubject(this.loading);
  public loading$ = this.loadingSubject.asObservable();

  public allIsLoaded = false;
  public page = 0;
  public pageSize = 50;
  public scrollSubscription: Subscription | null;

  private requestListener: Subscription;

  constructor(
    private data: DataService,
    private filterService: FilterService,
    private app: AppService,
  ) {}

  ngOnDestroy(): void {
    if (this.requestListener) {
      this.requestListener.unsubscribe();
    }
  }

  public getLabelClass(style: string): string {
    return `bg-${style}`;
  }

  public reload() {
    this.users = [];
    this.leaveSchedules = [];
    this.allIsLoaded = false;
    this.page = 0;
  }

  /** Загрузка расписания
   *
   *  @param users пользователи, для которых загружаем расписание
   *  @param interval интервал в котором загружаем расписание
   *
   * */
  public loadSchedule(users: NamedEntity[], interval) {
    const leaveSchedulePageParams: Dictionary<any> = {
      userIds: '@userIds',
      from: interval.start.toISODate(),
      to: interval.end.toISODate(),
    };

    const query = {
      expand: [
        {
          leaveScheduleDays: {
            expand: {
              timeOffRequest: {
                expand: [
                  { state: { select: ['id', 'name', 'code', 'style'] } },
                  { timeOffType: { select: ['name', 'code'] } },
                ],
                select: ['id', 'name', 'startDate', 'finishDate'],
              },
            },
          },
        },
      ],
    };

    return this.data
      .collection('TimeOffRequests')
      .function('WP.GetLeaveSchedulePage')
      .query(leaveSchedulePageParams, query, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@userIds': JSON.stringify(users.map((user) => user.id)),
      })
      .pipe(
        map((data: any[]) => {
          const userIds = users.map((user) => user.id);
          return sortBy(data, (item) => userIds.indexOf(item.userId));
        }),
        map((data: any[]) => {
          data.forEach((leaveSchedule) => {
            (leaveSchedule.leaveScheduleDays as any[]).forEach(
              (leaveScheduleDay) => {
                leaveScheduleDay.id = Guid.generate();
                leaveScheduleDay.style = [];
                if (leaveScheduleDay.timeOffRequest) {
                  leaveScheduleDay.style.push(
                    this.getLabelClass(
                      leaveScheduleDay.timeOffRequest.state.style,
                    ),
                  );
                  leaveScheduleDay.style.push('active');
                  leaveScheduleDay.code = (
                    leaveScheduleDay.timeOffRequest.timeOffType.code as string
                  )?.substring(0, 2);
                }

                if (leaveScheduleDay.nonWorking) {
                  leaveScheduleDay.style.push('non-working');
                }
              },
            );
          });
          return data;
        }),
      );
  }

  /**
   * Загрузка фрейма
   *
   * @param direction с какой стороны загрузить фрейм
   * @param loadingInterval интервал для загрузки фрейма
   * */
  public loadFrame(direction: 'left' | 'right', loadingInterval) {
    return this.loadSchedule(this.users, loadingInterval).pipe(
      map((data) => {
        data.forEach((leaveSchedule) => {
          const createdLeaveSchedule = this.leaveSchedules.find(
            (schedule) => schedule.userId === leaveSchedule.userId,
          );

          if (direction === 'left') {
            createdLeaveSchedule.leaveScheduleDays = [
              ...leaveSchedule.leaveScheduleDays,
              ...createdLeaveSchedule.leaveScheduleDays,
            ];
            return;
          }

          createdLeaveSchedule.leaveScheduleDays = [
            ...createdLeaveSchedule.leaveScheduleDays,
            ...leaveSchedule.leaveScheduleDays,
          ];
        });
      }),
    );
  }

  /** Загрузка пользователей */
  public loadUsers(): Observable<NamedEntity[]> {
    const usersPageParams: Dictionary<any> = {
      pageNumber: this.page,
      pageSize: this.pageSize,
      filter: '@filter',
    };
    const settings = this.filterService.values;
    const searchText = (
      this.filterService as TimeOffScheduleFiltersService
    ).formatTextValue(settings.text);

    const usersPageFilter = {
      searchText: searchText ? searchText : null,
      departmentId: settings.department?.value?.id ?? null,
      supervisorId: settings.onlySubordinates ? this.app.session.user.id : null,
      levelId: settings?.level?.id ?? null,
      locationId: settings?.location?.id ?? null,
      resourcePoolId: settings?.resourcePool?.id ?? null,
      includeSubordinateDepartments:
        settings.department?.includeSubordinates ?? false,
    };

    return this.data
      .collection('TimeOffRequests')
      .function('WP.GetLeaveScheduleUsersPage')
      .query(usersPageParams, null, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@filter': JSON.stringify(usersPageFilter),
      })
      .pipe(
        map((users: NamedEntity[]) => {
          this.allIsLoaded = users.length < this.pageSize;

          if (this.allIsLoaded) {
            this.scrollSubscription?.unsubscribe();
            this.scrollSubscription = null;
          }

          this.page++;
          return users;
        }),
      );
  }

  /** Загрузка текущей страницы: пользователи и расписание для них */
  public loadPage(interval) {
    this.loading = true;
    let loadedUsers = [];

    if (this.requestListener) {
      this.requestListener.unsubscribe();
    }

    this.requestListener = this.loadUsers()
      .pipe(
        mergeMap((users) => {
          loadedUsers = users;
          return this.loadSchedule(users, interval);
        }),
      )
      .subscribe((leaveSchedule) => {
        this.loading = false;
        this.users = [...this.users, ...loadedUsers];
        this.leaveSchedules = this.leaveSchedules.concat(leaveSchedule);
      });
  }
}
