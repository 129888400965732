<form [formGroup]="form" class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'expenses.creation.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div *ngIf="userIsAllowed" class="form-group" tmtIndicator>
      <label>
        {{ 'expenses.card.props.applicant' | translate }}
      </label>
      <wp-user-box
        placeholder="{{ 'expenses.card.props.applicant' | translate }}"
        formControlName="user"
        [query]="userQuery"
      ></wp-user-box>
    </div>

    <wp-loading-indicator [loading]="expensesService.loadingState$ | async"></wp-loading-indicator>

    <div
      *ngIf="form.value.user && !(expensesService.loadingState$ | async)"
      class="form-group"
      tmtIndicator
    >
      <label>
        {{ 'expenses.card.props.project' | translate }}
      </label>
      <wp-select-box
        placeholder="{{ 'expenses.card.props.project' | translate }}"
        formControlName="project"
        [values]="expensesService.projectList$ | async"
      ></wp-select-box>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
