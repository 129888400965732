import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Group } from 'src/app/shared/models/entities/settings/group.model';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { GroupCardService } from '../group-card.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tmt-group-properties',
  templateUrl: './group-properties.component.html',
  styleUrls: ['./group-properties.component.scss'],
})
export class GroupPropertiesComponent implements OnInit, OnDestroy {
  public readonly: boolean;
  public isSaving = false;

  groupForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: [false],
  });
  state$ = new Subject<CardState>();
  destroyed$ = new Subject<void>();

  constructor(
    @Inject('entityId') private entityId: string,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    private service: GroupCardService,
  ) {
    actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());

    this.groupForm.controls.name.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() =>
        this.service.updateName(this.groupForm.controls.name.value),
      );
  }

  ngOnInit() {
    // Установка главного меню.
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
    ]);

    this.load();
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  // Загрузка данных.
  private load = () => {
    this.state$.next(CardState.Loading);
    this.groupForm.markAsPristine();
    this.groupForm.markAsUntouched();

    this.data
      .collection('Groups')
      .entity(this.entityId)
      .get<Group>(null)
      .subscribe({
        next: (group: Group) => {
          this.groupForm.patchValue(group);

          this.readonly = !group.editAllowed;
          this.actionService.action('save').isShown = !this.readonly;

          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.readonly ? this.groupForm.disable() : this.groupForm.enable();
          this.state$.next(CardState.Ready);
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          this.notification.error(error.message);
        },
      });
  };

  public reload() {
    if (!this.groupForm.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }

  // Сохранение данных.
  public save = (): void => {
    this.groupForm.markAllAsTouched();

    if (this.groupForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const group = this.groupForm.value;

    const data: any = {
      name: group.name,
      description: group.description,
      isActive: group.isActive,
    };

    this.data
      .collection('Groups')
      .entity(this.entityId)
      .patch(data)
      .subscribe({
        next: () => {
          this.groupForm.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal('settings.groups.card.messages.saved');
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  };
}
