/* eslint-disable @typescript-eslint/naming-convention */
import { TotalType } from './total-type';
import { GridTemplateCell } from '../../components/features/grid/grid-template-cell.interface';
import { Type } from '@angular/core';
import { Feature } from '../enums/feature.enum';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { Observable } from 'rxjs';
import { NamedEntity } from '../entities/named-entity.model';
import { NavigationType } from 'src/app/shared-features/grid2/models/grid-column.interface';

/** Тип колонки грида. */
export enum GridColumnType {
  Navigation,
  ResourceNavigation,
  String,
  MultilineString,
  Entity,
  Boolean,
  Date,
  DateTime,
  Decimal,
  Integer,
  Percent,
  Work,
  Currency,
  Label,
  User,
  State,

  Command,

  StringControl,
  DateControl,
  NumberControl,
  SelectControl,

  UserControl,
  BooleanControl,
  CurrencyControl,
  Component,
}

/** Колонка грида.
 * Используется для описания представлений грида и списков.
 */
export interface GridColumn {
  /** Наименование колонки для идентификации. */
  name: string;

  /** Тип колонки. Определяет используемый для визуализации контрол. */
  type: GridColumnType;

  /** Тип контента колонки. Определяет форматирование заголовка и итогов.
   * Если не определен - то используется сопоставление с типом колонки.
   */
  contentType?: GridColumnType;

  /** Enables force cell updating after change its formGroup abstract control. */
  forceCellUpdating?: boolean;

  /** Заголовок колонки (код локализации). */
  header: string;

  /** Column icon CSS class. */
  icon?: string;

  /** Подсказка колонки (код локализации). */
  hint: string;

  /** Список доступных функций итогов.
   * Используется при настройке пользовательского представления.
   */
  availableTotals?: TotalType[];

  /** Признак фиксированной ширины. Используется для расчета поля width на основании представления.
   * Если false, то в колонках-представления и колонках-пользовательского представления
   * ширина задается в виде чисел (1, 2), что задает ширину колонки в %%
   * (%% = число / сумма чисел по всем колонкам).
   * Если true, то ширина будет установлена в px.
   */
  fixedWidth?: boolean;

  /** CSS ширина колонки (25%, 220px).
   * Не имеет смысла устанавливать в декларативном описании, т.к. будет перерасчитана из настроек представления.
   * */
  width?: string;

  /** Используемая (выбранная) функция итогов. */
  total?: TotalType;

  /** Требует наличия фичи. */
  requiredFeature?: Feature | null;

  getDefaultValue?: () => any;
}

export interface GridNavigationColumn extends GridColumn {
  state: string;
  stateParams?: StateParams;
  navigationType?: NavigationType;
}

/** Перечень кастомных параметров для состояния */
export interface StateParams {
  fromRow: {
    [key: string]: string;
  };
}

/** Ячейки в виде ссылок, выполняющие команду сервиса грида .execute */
export interface GridCommandColumn extends GridColumn {
  command: string;
}

export interface GridComponentColumn extends GridColumn {
  component: Type<GridTemplateCell>;
  readonlyComponent?: Type<GridTemplateCell>;
  currencyCode?: string;
  allowNull?: boolean;
}

export interface GridDateControlColumn extends GridColumn {
  allowNull?: boolean;
}

export interface GridSelectControlColumn extends GridColumn {
  values?: any;
  placeholder?: string;
  collection?: string;
  query?: any;
  allowNull?: boolean;
}

export interface GridStringControlColumn extends GridColumn {
  placeholder?: string;
  propagationMode?: PropagationMode;
}

export interface GridUserControlColumn extends GridColumn {
  placeholder?: string;

  /** Запрос OData для контрола. */
  query?: any;
}

export interface GridNumberControlColumn extends GridColumn {
  controlType: 'integer' | 'decimal' | 'work' | 'currency' | 'percent';
  min?: number;
  max?: number;
  allowNull?: boolean;
  precision?: number;
  propagationMode?: PropagationMode;
}

export interface GridCurrencyColumn extends GridColumn {
  currencyCodeProperty?: string;
  currencyCode?: string;
}

export interface GridEntityColumn extends GridColumn {
  values?: Observable<NamedEntity[]> | NamedEntity[];
}

export interface GridCurrencyControlColumn extends GridColumn {
  min?: number;
  max?: number;
  allowNull?: boolean;
  currencyCode?: string;
  isCurrencyEditable?: boolean;
  currencyCodeProperty?: string;
}
