<ng-container *ngIf="!isLoading && !notFound">
  <div class="task-info-container">
    <div class="trim fw-semibold">{{ taskInfo?.name }}</div>

    <div class="d-flex mt-2" *ngIf="taskInfo?.description">
      <div>
        <small class="text-body-secondary">{{
          'shared.projectInfo.description' | translate
        }}</small>
        <div
          class="trim-multiline"
          title="{{ taskInfo?.description }}"
          [innerHTML]="taskInfo?.description | linky | nl2br"
        ></div>
      </div>
    </div>

    <div class="d-flex mt-2">
      <div>
        <small class="text-body-secondary">{{ 'shared.props.project' | translate }}</small>
        <p>{{ taskInfo?.project.name }}</p>
      </div>
    </div>

    <div class="d-flex mt-2">
      <div>
        <small class="text-body-secondary">{{ 'shared.projectInfo.manager' | translate }}</small>
        <p>{{ taskInfo?.projectManager.name }}</p>
      </div>
    </div>

    <div class="d-flex mt-2">
      <div class="me-5" *ngIf="taskInfo.plannedHours > 0">
        <small class="text-body-secondary">{{ 'shared.taskInfo.plan' | translate }}</small>
        <p>{{ taskInfo.plannedHours | wpWork }}</p>
      </div>
      <div>
        <small class="text-body-secondary">{{ 'shared.taskInfo.actual' | translate }}</small>

        <p>{{ taskInfo.actualHours | wpWork }}</p>
      </div>
    </div>

    <div class="d-flex mt-2">
      <div *ngIf="taskInfo.plannedHours > 0" class="w-100">
        <small class="text-body-secondary">{{ 'shared.taskInfo.hoursProgress' | translate }}</small>

        <ngb-progressbar
          max="1"
          style="margin: 0"
          type="{{ hoursProgressType }}"
          [value]="hoursProgress"
        >
          {{ hoursProgress | percent: '1.0-2' }}
        </ngb-progressbar>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="notFound" class="text-center text-body-secondary text-uppercase">
  {{ 'shared.entityNotFound.title' | translate }}
</div>

<div class="spinner" [hidden]="!isLoading"></div>
