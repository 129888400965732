import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { minBy } from 'lodash';
import { Interval } from 'luxon';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';

import {
  RbcCalendarGroupData,
  RbcCalendarSectionData,
} from '../../models/rbc-data.model';
import {
  RbcGroupType,
  RbcSectionType,
  RbcViewGroup,
  RbcViewOtherLine,
  RbcViewSection,
} from '../../models/rbc-view.model';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';

@Injectable()
export class ProjectRbcCalendarDataService {
  public sectionData: Dictionary<RbcViewSection> = {
    revenue: null,
    billing: null,
    collection: null,
  };

  private loadingSubscription: Subscription;

  constructor(
    @Inject('entityId') public projectId,
    private dataService: DataService,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    private translate: TranslateService,
  ) {}

  /**
   * Loads sections.
   *
   * @param interval - period interval
   * @param planningScale - period scale
   * @param sectionTypes - sections to load
   * @param rebuild - determines if data and view should be rebuilt
   * */
  public loadSections(
    interval: Interval,
    planningScale: PlanningScale,
    sectionTypes?: RbcSectionType[],
    rebuild = true,
  ): Promise<Dictionary<RbcViewSection>> {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    // Save group state.
    const expendedGroups: Dictionary<Dictionary<boolean>> = {};
    this.getSections().forEach((section) => {
      this.getOrderedGroups(section).forEach((group) => {
        if (group.isExpanded) {
          if (!expendedGroups[section.type]) {
            expendedGroups[section.type] = {};
          }
          expendedGroups[section.type][group.type] = true;
        }
      });
    });

    const observables: any = {};

    const queryParams: Dictionary<string> = {
      periodStart: interval.start.toISODate(),
      periodFinish: interval.end.toISODate(),
      planningScale: `WP.PlanningScale'${planningScale}'`,
    };

    const fn = (st: RbcSectionType): Observable<any> =>
      this.versionDataService
        .projectCollectionEntity(
          this.versionCardService.projectVersion,
          this.projectId,
        )
        .function(`GetRbcCalendar${this.getSectionNameForQuery(st)}Section`)
        .query(queryParams);

    if (sectionTypes?.length > 0) {
      sectionTypes.forEach((sectionType) => {
        observables[sectionType] = fn(sectionType);
      });
    } else {
      observables.revenue = fn(RbcSectionType.revenue);
      observables.billing = fn(RbcSectionType.billing);
      observables.collection = fn(RbcSectionType.collection);
    }

    return new Promise((resolve, reject) => {
      this.loadingSubscription = forkJoin(observables).subscribe(
        (response: any) => {
          if (rebuild) {
            if (sectionTypes?.length > 0) {
              sectionTypes.forEach((sectionType) => {
                this.sectionData[sectionType] = null;
              });
            } else {
              Object.keys(this.sectionData).forEach(
                (section) => (this.sectionData[section] = null),
              );
            }
          }

          if (response.revenue) {
            this.updateViewModelForSection(
              response.revenue,
              RbcSectionType.revenue,
            );
          }

          if (response.billing) {
            this.updateViewModelForSection(
              response.billing,
              RbcSectionType.billing,
            );
          }

          if (response.collection) {
            this.updateViewModelForSection(
              response.collection,
              RbcSectionType.collection,
            );
          }

          // Вернуть состояние групп.
          this.getSections().forEach((section) => {
            this.getOrderedGroups(section).forEach((group) => {
              if (
                expendedGroups[section.type] &&
                expendedGroups[section.type][group.type]
              ) {
                group.isExpanded = true;
              }
            });
          });

          resolve(this.sectionData);
        },
      );
    });
  }

  /**
   * Loads data for specific period
   *
   * @param interval - period interval
   * @param planningScale - period scale
   * @param sectionTypes - sections to load
   * */
  public loadFrame(
    interval: Interval,
    planningScale: PlanningScale,
    sectionTypes?: RbcSectionType[],
  ): Promise<Dictionary<RbcViewSection>> {
    return this.loadSections(interval, planningScale, sectionTypes, false);
  }

  /**
   * Returns ordered sections for display
   * */
  public getSections(): RbcViewSection[] {
    const sections: RbcViewSection[] = [];

    if (this.sectionData.revenue) {
      sections.push(this.sectionData.revenue);
    }

    if (this.sectionData.billing) {
      sections.push(this.sectionData.billing);
    }

    if (this.sectionData.collection) {
      sections.push(this.sectionData.collection);
    }

    return sections;
  }

  /**
   * Returns ordered groups for display
   *
   * @param section - section to get groups from
   * */
  public getOrderedGroups(section: RbcViewSection): RbcViewGroup[] {
    return [section.plan, section.estimate, section.actual, section.forecast];
  }

  /**
   * Returns signed delta for forecast group
   *
   * @param group - group to get delta for.
   * At the moment of writing only supports forecast
   * */
  public getGroupSignedDelta(group: RbcViewGroup): number {
    if (group.type !== RbcGroupType.forecast) {
      return;
    }
    const currSection = this.getSectionByGroup(group);
    return group.total - currSection.plan.total;
  }

  /**
   * Determines and returns passed group section
   *
   * @param group - groups to find section by
   * */
  public getSectionByGroup(group: RbcViewGroup): RbcViewSection {
    return this.getSections().find(
      (s) =>
        s.actual.id === group.id ||
        s.estimate.id === group.id ||
        s.forecast.id === group.id ||
        s.plan.id === group.id,
    );
  }

  /**
   * Returns displayed group object
   *
   * @param dataGroup - group data
   * @param groupType - group type
   * */
  private getViewGroup(
    dataGroup: RbcCalendarGroupData,
    groupType: RbcGroupType,
  ): RbcViewGroup {
    const minTaskIndent =
      minBy(dataGroup.taskLines, (t) => t.task.indent)?.task.indent ?? 0;
    const viewGroup = {
      id: Guid.generate(),
      type: groupType,
      name: this.translate.instant(
        `projects.projects.card.rbc.calendar.groups.${groupType}`,
      ),
      isExpanded: false,
      total: 0,
      entries: [],
      taskLines: dataGroup.taskLines.map((taskLine) => ({
        task: taskLine.task,
        total: taskLine.total,
        entries: taskLine.values,
        taskFullName: `${taskLine.task.structNumber ?? ''} ${
          taskLine.task.name
        }`.trim(),
        indent: taskLine.task.indent - minTaskIndent,
      })),
      otherLine: null,
    };
    const otherType = (from: RbcGroupType) =>
      from === RbcGroupType.forecast ? RbcGroupType.actual : from;
    viewGroup.otherLine = {
      title: this.translate.instant(
        `projects.projects.card.rbc.calendar.other.${otherType(
          groupType,
        )}.title`,
      ),
      verboseHint: this.translate.instant(
        `projects.projects.card.rbc.calendar.other.${otherType(
          groupType,
        )}.verboseHint`,
      ),
      total: dataGroup.otherLine.total,
      entries: dataGroup.otherLine.values,
    } as RbcViewOtherLine;

    viewGroup.taskLines.sort(naturalSort('task.structNumber'));
    return viewGroup;
  }

  /**
   * Returns view section model to be displayed
   *
   * @param dataSection - data to compose section
   * @param sectionType - section type needed for naming
   */
  private getViewSection(
    dataSection: RbcCalendarSectionData,
    sectionType: RbcSectionType,
  ): RbcViewSection {
    return {
      name: this.translate.instant(
        `projects.projects.card.rbc.calendar.sections.${sectionType}`,
      ),
      type: sectionType,
      actual: this.getViewGroup(dataSection.actualGroup, RbcGroupType.actual),
      estimate: this.getViewGroup(
        dataSection.estimateGroup,
        RbcGroupType.estimate,
      ),
      forecast: this.getViewGroup(
        dataSection.forecastGroup,
        RbcGroupType.forecast,
      ),
      plan: this.getViewGroup(dataSection.planGroup, RbcGroupType.plan),
    };
  }

  /**
   * Update section view model to be displayed
   *
   * @param sectionData - server-side data to compose section
   * @param sectionType - section type
   */
  private updateViewModelForSection(
    sectionData: RbcCalendarSectionData,
    sectionType: RbcSectionType,
  ) {
    switch (sectionType) {
      case RbcSectionType.revenue:
        if (!this.sectionData.revenue) {
          this.sectionData[sectionType] = this.getViewSection(
            sectionData,
            sectionType,
          );
        } else {
          this.enrichSectionData(sectionType, sectionData);
        }
        break;

      case RbcSectionType.billing:
        if (!this.sectionData.billing) {
          this.sectionData[sectionType] = this.getViewSection(
            sectionData,
            sectionType,
          );
        } else {
          this.enrichSectionData(sectionType, sectionData);
        }
        break;

      case RbcSectionType.collection:
        if (!this.sectionData.collection) {
          this.sectionData[sectionType] = this.getViewSection(
            sectionData,
            sectionType,
          );
        } else {
          this.enrichSectionData(sectionType, sectionData);
        }
        break;
    }
  }

  /**
   * Enriches current section data with new
   *
   * @param sectionType - section type
   * @param sectionData - section data
   */
  private enrichSectionData(
    sectionType: RbcSectionType,
    sectionData: RbcCalendarSectionData,
  ) {
    this.enrichGroupData(sectionType, RbcGroupType.plan, sectionData.planGroup);
    this.enrichGroupData(
      sectionType,
      RbcGroupType.estimate,
      sectionData.estimateGroup,
    );
    this.enrichGroupData(
      sectionType,
      RbcGroupType.actual,
      sectionData.actualGroup,
    );
    this.enrichGroupData(
      sectionType,
      RbcGroupType.forecast,
      sectionData.forecastGroup,
    );
  }

  /**
   * Enriches current group data with new
   *
   * @param sectionType - section type
   * @param groupType - group type
   * @param dataGroup - group data
   */
  private enrichGroupData(
    sectionType: RbcSectionType,
    groupType: RbcGroupType,
    dataGroup: RbcCalendarGroupData,
  ) {
    const group = this.sectionData[sectionType][groupType];

    group.taskLines.forEach((task) => {
      const result = dataGroup.taskLines.find(
        (line) => task.task.id === line.task.id,
      );

      if (result) {
        task.entries.push(...result.values);
      }
    });
  }

  /**
   * Determines and returns section name
   *
   * @param sectionType - section type
   */
  private getSectionNameForQuery(sectionType: RbcSectionType): string {
    return sectionType.charAt(0) + sectionType.slice(1);
  }
}
