<ol class="breadcrumb flex-nowrap">
  <li class="breadcrumb-item"></li>
  @for (breadCrumb of navigationService.route$ | async; track breadCrumb; let last = $last) {
    <li class="breadcrumb-item">
      @if (breadCrumb.state && !last) {
        <a
          [uiSref]="breadCrumb.state.name"
          [uiParams]="breadCrumb.state.params"
          [ngbTooltip]="getTooltip(breadCrumb.title)"
          container="body"
          >{{ breadCrumb.title | truncate: textLimit }}</a
        >
      }
      @if (!breadCrumb.state || last) {
        <span [ngbTooltip]="getTooltip(breadCrumb.title)" container="body">{{
          breadCrumb.title | truncate: textLimit
        }}</span>
      }
    </li>
  }
</ol>
