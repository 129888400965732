import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import {
  EntityFilter,
  NavigationService,
} from 'src/app/core/navigation.service';
import {
  ExpenseRequestLine,
  ExpensesRequest,
} from 'src/app/shared/models/entities/base/expenses-request.model';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { saveAs } from 'file-saver';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { StateService } from '@uirouter/angular';

import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { AppService } from 'src/app/core/app.service';
import { CurrenciesService } from 'src/app/shared/services/currencies.service';
import { DateTime } from 'luxon';
import {
  catchError,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { MessageService } from 'src/app/core/message.service';
import { Currency } from 'src/app/shared/models/entities/settings/currency.model';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { cloneDeep } from 'lodash';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import {
  MetaEntityBaseProperty,
  MetaEntityNavigationProperty,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';

@Injectable()
export class ExpenseRequestCardService {
  public collection = this.data.collection('ExpenseRequests');
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public request$ = new Subject<ExpensesRequest>();
  public name$ = new BehaviorSubject<string>('');

  /** Component subscriptions cancel subject. */
  public entityReloaded$ = new Subject<void>();
  public modalClosed$ = new Subject<void>();

  get entityId(): string {
    return this._entityId;
  }

  set entityId(value: string) {
    this._entityId = value;
  }

  get projectId() {
    return this._projectId;
  }

  get userId() {
    return this._userId;
  }

  get currency() {
    return this._currency;
  }

  get projectCurrency() {
    return this._projectCurrency;
  }

  get baseCurrency() {
    return this._baseCurrency;
  }

  get reimbursementCurrency() {
    return this._reimbursementCurrency;
  }

  private _entityId: string;
  private _request: ExpensesRequest;
  private _projectId: string;
  private _userId: string;
  private _lineCustomFields: MetaEntityBaseProperty[];

  private _currency: Currency;
  private _projectCurrency: Currency;
  private _baseCurrency: Currency;
  private _mainTask: ProjectTask;
  private _isProgramValueChange = false;
  private _reimbursementCurrency: Currency;

  private entityQuery = {
    select: ['*'],
    expand: [
      { user: { select: ['id', 'name', 'legalEntityId'] } },
      { legalEntity: { select: ['id', 'name'] } },
      { state: { select: ['id', 'name', 'code'] } },
      { projectCostCenter: { select: ['id', 'name'] } },
      {
        project: {
          select: ['id', 'name'],
          expand: {
            currency: { select: ['id', 'name', 'alpha3Code'] },
          },
        },
      },
      {
        reimbursementCurrency: {
          select: ['id', 'name', 'alpha3Code'],
        },
      },
    ],
  };

  private entityLinesQuery = {
    select: ['*'],
    expand: [
      { expenseType: { select: ['id', 'name'] } },
      { projectTask: { select: ['id', 'name', 'structNumber'] } },
      { attachment: { select: ['id', 'fileName'] } },
      { currency: { select: ['id', 'name', 'alpha3Code'] } },
    ],
    orderBy: 'date',
  };

  private _calculateTotals = new Subject<void>();

  public calculateTotals$ = this._calculateTotals.asObservable();

  private reimbursementCurrencyChanged = new Subject<Currency>();
  public reimbursementCurrencyChanged$ =
    this.reimbursementCurrencyChanged.asObservable();

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private app: AppService,
    public blockUI: BlockUIService,
    private data: DataService,
    public navigation: NavigationService,
    @Optional() private autosave: SavingQueueService,
    private notification: NotificationService,
    private message: MessageService,
    private fb: UntypedFormBuilder,
    private projectTasksService: ProjectTasksService,
    private state: StateService,
    private customFieldService: CustomFieldService,
    private lifecycleService: LifecycleService,
    private currenciesService: CurrenciesService,
  ) {
    this.lifecycleService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.reload();
      });

    this._lineCustomFields = this.app.getCustomFields('ExpenseRequestLine');

    this.autosave.save$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((request: any) => {
        if (request) {
          this._request.rowVersion = request.rowVersion;
        }
      });
  }

  /**
   * Gets Base currency info.
   *
   * @returns Observable.
   * */
  public initBaseCurrency(): Observable<Currency> {
    const baseCurrencyCode = this.app.session.configuration.baseCurrencyCode;
    return this.currenciesService.currencies$.pipe(
      catchError((error) => {
        this._baseCurrency = {
          id: null,
          name: null,
          alpha3Code: baseCurrencyCode,
        };
        return throwError(error);
      }),
      map((currencies) =>
        currencies.find((c) => c.alpha3Code === baseCurrencyCode),
      ),
      tap((currency) => {
        this._baseCurrency = currency;
      }),
    );
  }

  /**
   * Reloads Card.
   * */
  public reload() {
    this.autosave.save().then(
      () => {
        this.load();
        this.lifecycleService.reloadLifecycle();
      },
      () => null,
    );
  }

  /**
   * Loads Expense request.
   * */
  public load = () => {
    this.state$.next(CardState.Loading);

    const linesQuery = cloneDeep(this.entityLinesQuery);

    this.customFieldService.enrichQuery(linesQuery, 'ExpenseRequestLine');

    const query = cloneDeep(this.entityQuery);

    this.customFieldService.enrichQuery(query, 'ExpenseRequest');

    query.expand.push(<any>{ lines: linesQuery });

    this.collection
      .entity(this.entityId)
      .get<ExpensesRequest>(query)
      .subscribe({
        next: (request: ExpensesRequest) => {
          this._request = request;
          this._projectId = request.project.id;
          this._userId = request.user.id;
          this._projectCurrency = this._request.project?.currency;
          this._currency = this._projectCurrency ?? this._baseCurrency;
          this._reimbursementCurrency = request.reimbursementCurrency;
          this.entityReloaded$.next();
          this.request$.next(request);
          this.name$.next(request.name);
          this.state$.next(CardState.Ready);
          this.projectTasksService.resetProjectTasks(this._projectId);
          this._mainTask = null;
          this.navigation.addRouteSegment({
            id: request.id,
            title: request.name,
          });
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notification.error(error.message);
          }
        },
      });
  };

  /**
   * Deletes Expense request.
   * */
  public deleteEntity() {
    this.message.confirmLocal('expenses.card.messages.deleteConfirmation').then(
      () => {
        this.blockUI.start();
        this.collection
          .entity(this.entityId)
          .delete()
          .subscribe({
            next: () => {
              this.notification.successLocal('shared.messages.deleted');
              this.navigation.goToSelectedNavItem();
              this.blockUI.stop();
            },
            error: (error: Exception) => {
              this.blockUI.stop();
              this.message.error(error.message).then(this.load, this.load);
            },
          });
      },
      () => null,
    );
  }

  /**
   * Gets cached Main Project task for Expense request line.
   *
   * @returns Observable.
   * */
  public getMainTaskObs(): Observable<ProjectTask> {
    if (this._mainTask) {
      return of(this._mainTask);
    }

    return this.projectTasksService
      .getAssignedProjectTasks(this._userId, this._projectId)
      .pipe(
        map((tasks: ProjectTask[]) => {
          this._mainTask =
            tasks?.length > 0 ? tasks.find((t) => !t.leadTaskId) : null;
          return this._mainTask;
        }),
      );
  }

  /**
   * Uploads Expense request line attachment.
   *
   * @param lineId Line ID.
   * @param file File.
   * @returns Promise.
   * */
  public uploadAttachment(lineId: string, file: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const formData: FormData = new FormData();
      this.blockUI.start();
      formData.append('attachment', file, file.name);

      this.data
        .collection('ExpenseRequestLines')
        .entity(lineId)
        .action('WP.UploadAttachment')
        .execute(formData)
        .subscribe({
          next: () => {
            this.blockUI.stop();
            resolve({ fileName: file.name });
            this.notification.successLocal(
              'shared.messages.attachmentWasUploaded',
            );
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.blockUI.stop();
          },
        });
    });
  }

  /**
   * Removes Expense request line attachment.
   *
   * @param lineId Line ID.
   * @returns Promise.
   * */
  public removeAttachment(lineId: string): Promise<void> {
    return new Promise<any>((resolve, reject) => {
      this.blockUI.start();

      this.data
        .collection('ExpenseRequestLines')
        .entity(lineId)
        .action('WP.DeleteAttachment')
        .execute()
        .subscribe({
          next: () => {
            this.blockUI.stop();
            this.notification.successLocal(
              'shared.messages.attachmentWasRemoved',
            );
            resolve(null);
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.blockUI.stop();
            reject();
          },
        });
    });
  }

  /**
   * Opens Expense request line attachment.
   *
   * @param lineId Line ID.
   * @param fileName File name.
   * */
  public openAttachment(lineId: string, fileName: string) {
    this.blockUI.start();
    this.data
      .collection('ExpenseRequestLines')
      .entity(lineId)
      .function('Attachment')
      .getBlob()
      .subscribe({
        next: (data: any) => {
          saveAs(data, fileName);
          this.blockUI.stop();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.blockUI.stop();
        },
      });
  }

  /**
   * Navigates to Expense request Accounting entries.
   * */
  public goToAccountingEntry() {
    this.state.go(`accountingEntries`, {
      navigation: this.navigation.selectedNavigationItem?.name,
      routeMode: RouteMode.continue,
      navGroup: 'entries',
      keepNavInState: 'finance.entry',
      filter: JSON.stringify(<EntityFilter>{
        name: this._request.name,
        filter: [{ documentId: { type: 'guid', value: this.entityId } }],
      }),
    });
  }

  /**
   * Calculates Expense request total sum values.
   * */
  public calculateTotals() {
    this._calculateTotals.next();
  }

  public lineCardBuilderFn = (lineGroup: any): ExpenseRequestLine => {
    const line = this.lineBuilderFn(lineGroup);

    line.currency = lineGroup.currency;
    line.expenseType = lineGroup.expenseType ?? null;
    line.projectTask = lineGroup.projectTask ?? null;
    line.attachment = lineGroup.attachment ?? null;

    this._lineCustomFields.forEach((field) => {
      let fieldName;
      if (field.type === MetaEntityPropertyType.directory) {
        fieldName = (field as MetaEntityNavigationProperty).keyProperty;
      } else {
        fieldName = field.name;
      }

      line[fieldName] = lineGroup[fieldName];
    });

    return line;
  };

  public lineBuilderFn = (lineGroup: any): ExpenseRequestLine =>
    <any>{
      id: lineGroup.id,
      expenseRequestId: this._request.id,
      amount: lineGroup.amount.value,
      amountBC: lineGroup.amountBC.value,
      amountPC: lineGroup.amountPC.value,
      amountRC: lineGroup.amountRC.value,
      currencyId: lineGroup.currency.id,
      date: lineGroup.date,
      expenseTypeId: lineGroup.expenseType?.id ?? null,
      projectTaskId: lineGroup.projectTask?.id ?? null,
      description: lineGroup.description,
      reimburse: lineGroup.reimburse,
      billable: lineGroup.billable,
      exchangeRate: lineGroup.exchangeRate,
    };

  /**
   * Gets Expense request line form group for Expense request lines.
   *
   * @param line Expense request line.
   * @param isModal Indicates whether Line group is created on Modal window or not.
   * @returns Expense request line form group.
   * */
  public getLineGroup(
    line?: ExpenseRequestLine,
    isModal = false,
  ): UntypedFormGroup {
    const group = this.fb.group({
      id: Guid.generate(),
      expenseType: [null, Validators.required],
      projectTask: [null, Validators.required],
      description: [null, Validators.maxLength(Constants.formTextMaxLength)],
      currency: this.currency,
      projectCurrencyXR: null,
      reimbursementCurrencyXR: null,
      amount: {
        value: 0,
        currencyCode: this.currency.alpha3Code,
      },
      exchangeRate: [1, Validators.required],
      amountBC: [
        {
          value: 0,
          currencyCode: this.baseCurrency.alpha3Code,
        },
      ],
      amountPC: [
        {
          value: 0,
          currencyCode: this.currency.alpha3Code,
        },
      ],
      amountRC: [
        {
          value: 0,
          currencyCode: this.reimbursementCurrency.alpha3Code,
        },
      ],
      billable: [false],
      reimburse: [false],
      date: [null, Validators.required],
      attachment: null,
    });

    group.controls.amountBC.disable();
    group.controls.amountPC.disable();
    group.controls.amountRC.disable();

    const destroySub = isModal ? this.modalClosed$ : this.entityReloaded$;

    this.customFieldService.enrichFormGroup(
      group,
      'ExpenseRequestLine',
      false,
      false,
    );

    if (line) {
      group.patchValue(line);
      group.controls.amount.setValue({
        value: line.amount,
        currencyCode: line.currency.alpha3Code,
      });
      group.controls.amountBC.setValue({
        value: line.amountBC,
        currencyCode: this.baseCurrency.alpha3Code,
      });
      group.controls.amountPC.setValue({
        value: line.amountPC,
        currencyCode: this.currency.alpha3Code,
      });
      group.controls.amountRC.setValue({
        value: line.amountRC,
        currencyCode: this.reimbursementCurrency.alpha3Code,
      });
    }

    if (!isModal) {
      group.controls.billable.valueChanges
        .pipe(takeUntil(this.entityReloaded$))
        .subscribe(() => {
          this.calculateTotals();
        });

      group.controls.reimburse.valueChanges
        .pipe(takeUntil(this.entityReloaded$))
        .subscribe(() => {
          this.calculateTotals();
        });
    }

    group.controls.amount.valueChanges
      .pipe(takeUntil(destroySub))
      .subscribe((value) => {
        if (
          !group.controls.projectCurrencyXR.value ||
          !group.controls.reimbursementCurrencyXR.value ||
          group.controls.currency.value.alpha3Code !== value.currencyCode
        ) {
          !group.controls.projectCurrencyXR.value ||
          group.controls.currency.value.alpha3Code !== value.currencyCode
            ? this.refreshProjectXR(group, true)
            : null;
          !group.controls.reimbursementCurrencyXR.value ||
          group.controls.currency.value.alpha3Code !== value.currencyCode
            ? this.refreshReimbursementXR(group, true)
            : null;
        } else {
          this.refreshLineXr(group);
        }
      });
    group.controls.date.valueChanges
      .pipe(takeUntil(destroySub))
      .subscribe(() => {
        if (group.controls.date.dirty) {
          this.refreshProjectXR(group, true);
          this.refreshReimbursementXR(group, true);
        }
      });

    if (
      group.controls.amount.value.currencyCode !== this.baseCurrency.alpha3Code
    ) {
      group.controls.exchangeRate.enable();
    } else {
      group.controls.exchangeRate.setValue(1);
      group.controls.exchangeRate.disable();
    }

    group.controls.exchangeRate.valueChanges
      .pipe(
        filter(() => !this._isProgramValueChange),
        takeUntil(destroySub),
      )
      .subscribe(() => {
        if (
          !group.controls.projectCurrencyXR.value ||
          !group.controls.reimbursementCurrencyXR.value
        ) {
          !group.controls.reimbursementCurrencyXR.value
            ? this.refreshReimbursementXR(group)
            : null;
          !group.controls.projectCurrencyXR.value
            ? this.refreshProjectXR(group)
            : null;
        } else {
          this.setAmounts(group);
        }
      });

    this.customFieldService.enrichFormGroup(
      group,
      'ExpenseRequestLine',
      !isModal,
    );

    return group;
  }

  /**
   * Refreshes Expense request line with Project Currency Exchange rate by line date.
   *
   * @param lineGroup Expense request line to update.
   * @param force Indicates whether to refresh line Currency Exchange rate even when it has not been changed.
   * */
  public refreshProjectXR(lineGroup: UntypedFormGroup, force?: boolean) {
    this.currenciesService
      .getExchangeRate(
        this.currency.id,
        lineGroup.controls.date.value || DateTime.now().toISODate(),
      )
      .pipe(
        switchMap((projectXr) => {
          lineGroup.controls.projectCurrencyXR.setValue(projectXr);
          return this.getLineXrObs(lineGroup, force);
        }),
        takeUntil(this.entityReloaded$),
      )
      .subscribe({
        next: (xr) => {
          this.setXr(lineGroup, xr);
        },
        error: (error: Exception) => {
          this.setXr(lineGroup, 1);
          this.notification.error(error.message);
        },
      });
  }

  /**
   * Refreshes Expense request line with Reimbursement Currency Exchange rate by line date.
   *
   * @param lineGroup Expense request line to update.
   * @param force Indicates whether to refresh line Currency Exchange rate even when it has not been changed.
   * @param currencyId Currency id for recalculation.
   * */
  public refreshReimbursementXR(
    lineGroup: UntypedFormGroup,
    force?: boolean,
    currencyId?: string,
  ) {
    this.currenciesService
      .getExchangeRate(
        currencyId ?? this.reimbursementCurrency.id,
        lineGroup.controls.date.value || DateTime.now().toISODate(),
      )
      .pipe(
        switchMap((reimbursementXr) => {
          lineGroup.controls.reimbursementCurrencyXR.setValue(reimbursementXr);
          return this.getLineXrObs(lineGroup, force);
        }),
        takeUntil(this.entityReloaded$),
      )
      .subscribe({
        next: (xr) => {
          this.setXr(lineGroup, xr);
        },
        error: (error: Exception) => {
          this.setXr(lineGroup, 1);
          this.notification.error(error.message);
        },
      });
  }

  /**
   * Refreshes Expense request lines reimbursement amount.
   */
  public refreshReimbursementAmount(currencyId: string) {
    this.currenciesService.currencies$
      .pipe(
        map((currencies) => currencies.find((c) => c.id === currencyId)),
        tap((currency) => {
          this._reimbursementCurrency = currency;
        }),
      )
      .subscribe((currency) => {
        this.reimbursementCurrencyChanged.next(currency);
      });
  }

  /**
   * Refreshes Expense request line with its Currency Exchange rate by line date.
   *
   * @param lineGroup Expense request line to update.
   * @param force Indicates whether to refresh line Currency Exchange rate even when it has not been changed.
   * */
  private refreshLineXr(lineGroup: UntypedFormGroup, force?: boolean) {
    if (!this._baseCurrency) {
      console.warn('Base Currency is not defined.');
      return;
    }
    if (!this._projectCurrency) {
      console.warn('Project Currency is not defined.');
      return;
    }

    if (!this._reimbursementCurrency) {
      console.warn('Reimbursement Currency is not defined.');
      return;
    }

    this.getLineXrObs(lineGroup, force)
      .pipe(takeUntil(this.entityReloaded$))
      .subscribe({
        next: (xr) => {
          this.setXr(lineGroup, xr);
        },
        error: (error: Exception) => {
          this.setXr(lineGroup, 1);
          this.notification.error(error.message);
        },
      });
  }

  /**
   * Disposes service active resources.
   * */
  public dispose() {
    this.destroyed$.next();
    this.modalClosed$.next();
    this.entityReloaded$.next();
  }

  /**
   * Gets Expense request line Currency Exchange rate observable.
   *
   * @param lineGroup Expense request line to update.
   * @param force Indicates whether to refresh line Currency Exchange rate even when it has not been changed.
   * */
  private getLineXrObs(lineGroup: UntypedFormGroup, force?: boolean) {
    return this.currenciesService.currencies$.pipe(
      map((currencies) =>
        currencies.find(
          (currency) =>
            currency.alpha3Code ===
            lineGroup.controls.amount.value.currencyCode,
        ),
      ),
      switchMap((lineCurrency) => {
        lineGroup.controls.currency.setValue(lineCurrency);

        if (
          !!lineCurrency &&
          (lineCurrency.id !== lineGroup.controls.currency.value.id || force)
        ) {
          return this.currenciesService.getExchangeRate(
            lineCurrency.id,
            lineGroup.controls.date.value || DateTime.now().toISODate(),
          );
        } else {
          return of(0);
        }
      }),
    );
  }

  /**
   * Sets Expense request line Exchange rate.
   *
   * @param lineGroup Expense request line to update.
   * @param xr New Exchange rate.
   * */
  private setXr(lineGroup: UntypedFormGroup, xr: number) {
    if (xr !== 0) {
      if (
        lineGroup.controls.amount.value.currencyCode ===
        this.baseCurrency.alpha3Code
      ) {
        this.setValueProgrammatically(lineGroup.controls.exchangeRate, 1, true);
      } else {
        this.setValueProgrammatically(
          lineGroup.controls.exchangeRate,
          xr,
          false,
        );
      }
    }
    this.setAmounts(lineGroup);
  }

  private setAmounts(lineGroup: UntypedFormGroup) {
    lineGroup.controls.amountBC.setValue({
      value:
        lineGroup.controls.amount.value.value *
        lineGroup.controls.exchangeRate.value,
      currencyCode: lineGroup.controls.amountBC.value.currencyCode,
    });

    let amountPC = lineGroup.controls.amount.value.value;

    if (
      lineGroup.controls.amount.value.currencyCode !== this.currency.alpha3Code
    ) {
      amountPC =
        lineGroup.controls.amountBC.value.value /
        lineGroup.controls.projectCurrencyXR.value;
    }

    lineGroup.controls.amountPC.setValue({
      value: amountPC,
      currencyCode: lineGroup.controls.amountPC.value.currencyCode,
    });

    let amountRC = lineGroup.controls.amount.value.value;

    if (
      lineGroup.controls.amount.value.currencyCode !==
      this.reimbursementCurrency.alpha3Code
    ) {
      amountRC =
        lineGroup.controls.amountBC.value.value /
        lineGroup.controls.reimbursementCurrencyXR.value;
    }

    lineGroup.controls.amountRC.setValue({
      value: amountRC,
      currencyCode: lineGroup.controls.amountRC.value.currencyCode,
    });

    this.calculateTotals();
  }

  // TODO: move to separate service and provide at component level.
  private setValueProgrammatically(
    ctrl: AbstractControl,
    value: any,
    disable: boolean = null,
  ) {
    this._isProgramValueChange = true;
    ctrl.setValue(value);
    if (disable !== null) {
      if (disable) {
        ctrl.disable();
      } else {
        ctrl.enable();
      }
    }

    this._isProgramValueChange = false;
  }
}
