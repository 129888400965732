import { Dictionary } from 'src/app/shared/models/dictionary';
import { Filter } from './filter/filter-model';

/** Пользовательские настройки отчета. */
export interface UserSettings {
  period?: any;
  filters?: Filter[];
  sorting?: Dictionary<SortOrder>;
  columns?: Dictionary<number>;
}

export enum SortOrder {
  Asc = 'Asc',
  Desc = 'Desc',
}
