<wp-number-box
  *ngIf="!formGroup.value.isTaskGroup"
  [allowNull]="false"
  type="currency"
  [formControl]="control"
  [currencyCode]="currencyCode"
></wp-number-box>

<div *ngIf="formGroup.value.isTaskGroup" title="{{ control.value | wpCurrency: currencyCode }}">
  {{ control.value | wpCurrency: currencyCode }}
</div>
