<ng-container *ngIf="!isLoading && !notFound">
  <div class="user-info-container">
    <div class="user-info-block d-flex pb-1">
      <div class="avatar-block me-3 d-flex flex-column justify-content-between">
        <img [src]="user?.id | avatar: 100" />
        <button class="btn btn-link m-0 p-0" (click)="switch()">
          <i
            class="bi"
            [ngClass]="{
              'bi-chevron-double-down': settings?.collapsed,
              'bi-chevron-double-up': !settings?.collapsed
            }"
            aria-hidden="true"
          ></i>
        </button>
      </div>

      <div class="user-details-block trim-multiline">
        <a
          class="user-name"
          title="{{ user?.name }}"
          (click)="close()"
          uiSref="employee"
          [uiParams]="{
            entityId: user.id
          }"
          >{{ user?.name }}</a
        >

        <p class="trim" *ngIf="user.position">
          {{ user.position }}
        </p>

        <p class="trim">
          <a title="{{ user?.email }}" href="mailto://{{ user?.email }}">{{ user?.email }}</a>
        </p>

        <p *ngIf="user?.phone" class="trim ps-1" title="{{ user?.phone }}">
          {{ user?.phone }}
        </p>

        <p *ngIf="user?.level" class="trim" title="{{ user?.level?.name }}">
          {{ user?.level?.name }}
        </p>
      </div>
    </div>

    <div class="row" [hidden]="settings?.collapsed">
      <div class="col-6">
        <div *ngIf="user?.department">
          <small class="text-body-secondary">{{ 'shared.props.department' | translate }}</small>
          <p class="trim" title="{{ user?.department?.name }}">
            {{ user?.department?.name }}
          </p>
        </div>

        <div *ngIf="user?.supervisor">
          <small class="text-body-secondary">{{ 'shared.props.supervisor' | translate }}</small>
          <p class="trim" title="{{ user?.supervisor?.name }}">
            {{ user?.supervisor?.name }}
          </p>
        </div>
        <div *ngIf="user?.location">
          <small class="text-body-secondary">{{ 'shared.props.location' | translate }}</small>
          <p class="trim" title="{{ user?.location?.name }}">
            {{ user?.location?.name }}
          </p>
        </div>
      </div>
      <div class="col-6">
        <div *ngIf="user?.resourcePool">
          <small class="text-body-secondary">{{ 'shared.props.resourcePool' | translate }}</small>
          <p class="trim" title="{{ user?.resourcePool?.name }}">
            {{ user?.resourcePool?.name }}
          </p>
        </div>
        <div *ngIf="user?.role">
          <small class="text-body-secondary">{{ 'shared.props.role' | translate }}</small>
          <p class="trim" title="{{ user?.role?.name }}">
            {{ user?.role?.name }}
          </p>
        </div>
        <div *ngIf="user?.competence">
          <small class="text-body-secondary">{{ 'shared.props.competence' | translate }}</small>
          <p class="trim" title="{{ user?.competence?.name }}">
            {{ user?.competence?.name }}
          </p>
        </div>
      </div>

      <div class="col-12">
        <div *ngIf="skills">
          <small class="text-body-secondary">{{ 'shared.userInfo.skills' | translate }}</small>
          <p class="trim" [title]="skills">
            {{ skills }}
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="notFound" class="text-center text-body-secondary text-uppercase">
  {{ 'shared.entityNotFound.title' | translate }}
</div>

<div class="spinner" [hidden]="!isLoading"></div>
