import {
  GridColumnType,
  GridCurrencyColumn,
  GridStringControlColumn,
} from 'src/app/shared-features/grid2/models/grid-column.interface';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { List } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';

export const CLIENT_TARIFF_LIST: List = {
  name: 'clientTariffs',
  version: 4,
  views: [
    {
      name: 'default',
      resizableColumns: true,
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 250,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'value',
          visibleByDefault: true,
          width: 250,
        },
        {
          column: 'effectiveDate',
          visibleByDefault: false,
          width: 250,
        },
        {
          column: 'expiryDate',
          visibleByDefault: false,
          width: 250,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 250,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 250,
        },
      ],
    },
  ],
  columns: [
    <GridStringControlColumn>{
      name: 'name',
      type: GridColumnType.StringControl,
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      availableTotals: [TotalType.Count],
      forceCellUpdating: true,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    <GridCurrencyColumn>{
      name: 'value',
      type: GridColumnType.Currency,
      header: 'projects.clients.card.tariffs.columns.value.header',
      hint: 'projects.clients.card.tariffs.columns.value.hint',
      fixedWidth: true,
      forceCellUpdating: true,
    },
    {
      name: 'effectiveDate',
      type: GridColumnType.Date,
      header: 'shared.columns.effectiveDate.title',
      hint: 'shared.columns.effectiveDate.hint',
      forceCellUpdating: true,
    },
    {
      name: 'expiryDate',
      header: 'shared.columns.expiryDate.title',
      hint: 'shared.columns.expiryDate.hint',
      type: GridColumnType.Date,
      forceCellUpdating: true,
    },
    <GridStringControlColumn>{
      name: 'description',
      type: GridColumnType.StringControl,
      header: 'shared.props.description',
      hint: 'shared.props.description',
      forceCellUpdating: true,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    {
      name: 'isActive',
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.BooleanControl,
      forceCellUpdating: true,
    },
  ],
};
