import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Level } from 'src/app/shared/models/entities/settings/level.model';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';

@Injectable()
export class LevelCardService {
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  private levelNameSubject = new BehaviorSubject<string>(null);
  public levelName$ = this.levelNameSubject.asObservable();

  private levelSubject = new ReplaySubject<Level>();
  public level$ = this.levelSubject.asObservable();

  constructor(
    @Inject('entityId') public levelId,
    private data: DataService,
    private notification: NotificationService,
    private actionService: ActionPanelService,
    private navigationService: NavigationService,
  ) {
    this.load();
  }

  /** Loads Data. */
  public load(): void {
    this.state$.next(CardState.Loading);

    const query = {
      select: [
        'id',
        'name',
        'code',
        'description',
        'isActive',
        'isDefault',
        'editAllowed',
      ],
    };

    this.data
      .collection('Levels')
      .entity(this.levelId)
      .get<Level>(query)
      .subscribe({
        next: (level: Level) => {
          this.state$.next(CardState.Ready);
          this.actionService.action('save').isShown = level.editAllowed;
          this.levelNameSubject.next(level.name);
          this.levelSubject.next(level);

          this.navigationService.addRouteSegment({
            id: level.id,
            title: level.name,
          });
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);

          if (error.code === Exception.BtEntityNotFoundException.code) {
            return;
          }
          this.notification.error(error.message);
        },
      });
  }

  public updateName(value: string): void {
    this.levelNameSubject.next(value);
  }
}
