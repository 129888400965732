import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { AppService } from 'src/app/core/app.service';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import { GridColumnType } from 'src/app/shared/models/inner/grid-column.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { EmployeeTimeOffBalanceService } from 'src/app/employees/card/employee-time-off-balance/employee-time-off-balance.service';

@Component({
  selector: 'tmt-employee-time-off-balance',
  templateUrl: './employee-time-off-balance.component.html',
  styleUrls: ['./employee-time-off-balance.component.scss'],
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeTimeOffBalanceComponent implements OnInit, OnDestroy {
  public lines = this.fb.array([]);
  public form = this.fb.group({
    period: null,
  });
  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    toolbar: null,
    view: {
      name: 'timeOffBalanceLines',
      columns: [
        {
          name: 'timeOffTypeName',
          header: 'employees.timeOffBalance.columns.timeOffTypeName',
          hint: 'employees.timeOffBalance.columns.timeOffTypeName',
          type: GridColumnType.String,
          width: '350px',
        },
        {
          name: 'accrued',
          header: 'employees.timeOffBalance.columns.accrued',
          hint: 'employees.timeOffBalance.columns.accrued',
          type: GridColumnType.Decimal,
          width: '100%',
        },
        {
          name: 'used',
          header: 'employees.timeOffBalance.columns.used',
          hint: 'employees.timeOffBalance.columns.used',
          type: GridColumnType.Decimal,
          width: '100%',
        },
        {
          name: 'balance',
          header: 'employees.timeOffBalance.columns.balance',
          hint: 'employees.timeOffBalance.columns.balance',
          type: GridColumnType.Decimal,
          width: '100%',
        },
        {
          name: 'timeOffTypeUnit',
          header: 'team.timeOffBalanceEntries.list.columns.unit.header',
          hint: 'team.timeOffBalanceEntries.list.columns.unit.hint',
          type: GridColumnType.String,
          width: '100%',
        },
      ],
    },
  };

  private destroyed$ = new Subject<void>();

  constructor(
    public app: AppService,
    private fb: UntypedFormBuilder,
    public service: EmployeeTimeOffBalanceService,
    private gridService: GridService,
    actionPanelService: ActionPanelService,
  ) {
    actionPanelService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => service.load());
  }

  ngOnInit() {
    this.form.controls.period.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.service.settings = value;
        this.service.load();
      });

    this.service.timeOffBalanceLines$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((lines) => {
        this.lines.clear();
        this.gridService.detectChanges();
        lines.forEach((line) => {
          const group = this.fb.group({
            timeOffTypeName: line.timeOffTypeName,
            timeOffTypeUnit: line.timeOffTypeUnit,
            accrued: line.accrued,
            used: line.used,
            balance: line.balance,
          });
          this.lines.push(group);
        });
      });
    this.service.load();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
