import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

/** Represents a Project Summary Resource Plan View Settings. */
export class ProjectSummaryResourcePlanViewSettings implements BaseSettings {
  public readonly name = 'ResourcePlanSummaryViewSettings';
  public version: number;
  /** Show KPI flag map. */
  public show: Record<KpiType, boolean>;

  public getDefault(): ProjectSummaryResourcePlanViewSettings {
    return {
      version: 1,
      show: { [KpiType.Plan]: true },
    } as ProjectSummaryResourcePlanViewSettings;
  }
}
