import { EnumEntity } from './enum-entity';

export class TimeOffTypeUnit extends EnumEntity {
  public static get hour(): TimeOffTypeUnit {
    return {
      id: '0f340f34-4cc7-4cd7-9617-d9bed5d2e7c2',
      code: 'Hour',
      name: 'enums.timeOffTypeUnit.Hour',
    };
  }

  public static get workingDay(): TimeOffTypeUnit {
    return {
      id: 'd24aec3c-119c-4a8a-a3b9-b6b721985351',
      code: 'WorkingDay',
      name: 'enums.timeOffBalanceEntryType.WorkingDay',
    };
  }

  public static get calendarDay(): TimeOffTypeUnit {
    return {
      id: '5cbfefa2-2871-4aad-a01a-e5019416cff4',
      code: 'CalendarDay',
      name: 'enums.timeOffBalanceEntryType.CalendarDay',
    };
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TimeOffTypeUnits: TimeOffTypeUnit[] = [
  TimeOffTypeUnit.hour,
  TimeOffTypeUnit.workingDay,
  TimeOffTypeUnit.calendarDay,
];
