<div
  wpFreezeTable
  [leftWidthStrategy]="options.modern ? 'byTable' : 'byContainer'"
  [ngClass]="{ modern: options.modern }"
  #mainContainer
>
  <div name="toolbar">
    <ng-template wp-toolbar-host></ng-template>
  </div>
  <div name="scroll-table-header">
    <table
      class="table"
      name="header"
      [ngClass]="options.css"
      wpResizing
      [selector]="'th[data-resizable-column]'"
      (columnResized)="onColumnResized($event)"
    >
      <colgroup>
        <col *ngIf="options.multiSelect" class="selector" />
        <col
          *ngFor="let column of options.view.columns; trackBy: trackColumn"
          [ngStyle]="{ width: column.width }"
        />
        <col *ngIf="options.rowCommands" class="menu-cell" />
      </colgroup>
      <thead name="scroll-header">
        <tr>
          <th
            *ngIf="options.multiSelect"
            class="checkbox"
            [ngClass]="{ 'checkbox-checked': selectAllControl.value }"
          >
            <div class="wp-checkbox">
              <label>
                <input type="checkbox" [formControl]="selectAllControl" />
                <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
              </label>
            </div>
          </th>
          <th
            data-resizable-column="true"
            *ngFor="let column of options.view.columns; trackBy: trackColumn"
            class="disable-selecting trim"
            [ngClass]="{
              'text-end': isRightAlign(column),
              'text-center': column.icon,
              sort: column.name === service.order?.column,
              reverse: service.order?.reverse,
              sorting: options?.sorting
            }"
            [attr.name]="column.name"
            (click)="sort(column)"
            title="{{ column.hint | translate }}"
          >
            <span *ngIf="column.icon" [ngClass]="column.icon"></span>
            <ng-container *ngIf="!column.icon">{{ column.header | translate }}</ng-container>
          </th>
          <th *ngIf="options.rowCommands" class="menu-cell"></th>
        </tr>
      </thead>
    </table>
  </div>

  <div name="scroll-table-body" (clickOutside)="onTableOutsideClick()">
    <table name="body" class="table" [ngClass]="options.css">
      <colgroup>
        <col *ngIf="options.multiSelect" class="selector" />
        <col
          *ngFor="let column of options.view.columns; trackBy: trackColumn"
          [ngStyle]="{ width: column.width }"
        />
        <col *ngIf="options.rowCommands" class="menu-cell" />
      </colgroup>

      <tbody>
        <tr
          *ngFor="let formGroup of formArray?.controls; trackBy: trackRow; let rowIndex = index"
          id="{{ formGroup.value.id }}"
          name="grid-row"
          tabindex="1"
        >
          <td *ngIf="options.multiSelect" class="checkbox">
            <div class="wp-checkbox" (click)="clickRowSelector(formGroup, $event)">
              <label>
                <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
              </label>
            </div>
          </td>
          <td
            *ngFor="let column of options.view.columns; trackBy: trackColumn; let colIndex = index"
            wp-grid-cell
            [attr.data-test]="column.name"
            [column]="column"
            [group]="formGroup"
            [editing]="isEditing(column, formGroup)"
            (dblclick)="setEditingControl(column, formGroup)"
            (mousedown)="onCellMouseDown(formGroup, rowIndex, $event, column)"
            (click)="onCellClick(formGroup, rowIndex, $event)"
            (contextmenu)="onCellRightBtnClick(formGroup, rowIndex, $event, column)"
            [ngClass]="
              cellOrchestratorService.getGridCellClasses(formGroup, column, rowIndex, colIndex)
            "
          ></td>
          <td
            class="menu-cell control-cell"
            #dropDown="ngbDropdown"
            ngbDropdown
            [popperOptions]="popperOptions"
            (onIntersect)="closeDropdown(dropDown)"
            *ngIf="options.rowCommands"
            (click)="onCellClick(formGroup, rowIndex, $event)"
          >
            <ng-container *ngIf="rowHasCommands(formGroup, rowIndex)">
              <button
                type="button"
                class="btn btn-default"
                ngbDropdownAnchor
                (click)="openMenu(formGroup.value.id, dropDown)"
              >
                <i class="bi bi-three-dots" aria-hidden="true"></i>
              </button>
              <div ngbDropdownMenu *ngIf="rowsWithCommands[formGroup.value.id]">
                <button
                  type="button"
                  ngbDropdownItem
                  *ngFor="let command of options.rowCommands; trackBy: trackColumn"
                  (click)="command.handlerFn(formGroup, rowIndex)"
                  [hidden]="command.allowedFn && !command.allowedFn(formGroup, rowIndex)"
                  [attr.data-test]="command.name"
                >
                  {{ command.label | translate }}
                </button>
              </div>
            </ng-container>
          </td>
        </tr>
        @if (service.loading$ | async) {
          <tr>
            <td class="p-0" [attr.colspan]="getColumnsCount()">
              <wp-loading-indicator class="h-0 p-0" [loading]="service.loading$ | async" />
            </td>
          </tr>
        }
        <tr *ngIf="!(service.loading$ | async) && formArray?.controls.length === 0">
          <td
            [attr.colspan]="getColumnsCount()"
            style="text-align: center"
            class="text-body-secondary"
          >
            {{ 'shared.noDisplayData' | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div name="scroll-table-footer">
    <table name="footer" class="table" [ngClass]="options.css" *ngIf="totals">
      <colgroup>
        <col *ngIf="options.multiSelect" class="selector" />
        <col
          *ngFor="let column of options.view.columns; trackBy: trackColumn"
          [ngStyle]="{ width: column.width }"
        />
        <col *ngIf="options.rowCommands" class="menu-cell" />
      </colgroup>
      <tfoot name="scroll-footer">
        <tr>
          <td *ngIf="options.multiSelect"></td>
          <td
            *ngFor="let column of options.view.columns"
            wp-grid-total-cell
            [column]="column"
            [value]="totals[column.name]"
          ></td>
          <td *ngIf="options.rowCommands" class="menu-cell"></td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
