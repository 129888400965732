<div class="d-flex flex-column align-items-start">
  <label class="mb-1 fw-semibold">{{ 'settings.timeOffTypes.accrualPolicy' | translate }}</label>

  <button
    *ngIf="!(timeOffTypeService.timeOffType$ | async)?.accrualPolicy"
    type="button"
    class="btn btn-link p-0"
    title="{{ 'shared.actions.create' | translate }}"
    (click)="service.createNewPolicy()"
  >
    <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
    {{ 'shared.actions.create' | translate }}
  </button>

  <div
    class="d-flex accrual-policy-line"
    *ngIf="(timeOffTypeService.timeOffType$ | async)?.accrualPolicy"
  >
    <button class="btn btn-link p-0 me-2" (click)="service.editPolicy()">
      {{ accrualPolicyName }}
    </button>
    <i class="bi bi-trash" (click)="service.deletePolicy()"></i>
  </div>
</div>
