import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { ProjectFilterComponent } from './project-filter.component';
import {
  ProjectBillingType,
  ProjectBillingTypes,
} from 'src/app/shared/models/enums/project-billing-type';
import { Dictionary } from 'src/app/shared/models/dictionary';

@Injectable()
export class ProjectFilterService extends FilterService {
  public override component: Type<FilterDetails> = ProjectFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override placeholder =
    'components.projectFilterService.filters.textInput';
  public override customCriteriaCount: Record<string, () => number> = {
    text: () => 0,
    billingTypes: () => (this.billingTypeConditionExist() ? 1 : 0),
    states: () => (this.stateConditionExist() ? 1 : 0),
  };

  protected override getDefaultValues = (): any => ({
    text: '',
    organization: null,
    period: null,
    program: null,
    manager: null,
    currency: null,
    states: null,
    billingTypes: {
      [ProjectBillingType.nonBillable.code]: [false],
      [ProjectBillingType.tm.code]: [false],
      [ProjectBillingType.fixedBid.code]: [false],
    },
  });

  billingTypeConditionExist(): boolean {
    let allBillingTypesIsTrue = true;
    let allBillingTypesIsFalse = true;

    for (const name in this.values.billingTypes) {
      if (this.values.billingTypes[name]) {
        allBillingTypesIsFalse = false;
      } else {
        allBillingTypesIsTrue = false;
      }
    }
    return !allBillingTypesIsTrue && !allBillingTypesIsFalse;
  }

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        project: {
          or: [
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { 'tolower(name)': { contains: text } },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { 'tolower(code)': { contains: text } },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { 'tolower(description)': { contains: text } },
          ],
        },
      });
    }
    if (this.values.organization) {
      result.push({
        project: {
          organizationId: {
            type: 'guid',
            value: this.values.organization.id,
          },
        },
      });
    }

    if (this.values.manager) {
      result.push({
        project: {
          managerId: { type: 'guid', value: this.values.manager.id },
        },
      });
    }

    if (this.values.program) {
      result.push({
        project: {
          programId: { eq: { type: 'guid', value: this.values.program.id } },
        },
      });
    }

    if (this.values.currency) {
      result.push({
        project: {
          currencyId: { type: 'guid', value: this.values.currency.id },
        },
      });
    }

    if (this.billingTypeConditionExist()) {
      const conditions = { project: { or: [] } };
      for (const name in this.values.billingTypes) {
        if (this.values.billingTypes[name]) {
          const id = ProjectBillingTypes.find(
            (x: ProjectBillingType) => x.code === name,
          ).id;
          conditions.project.or.push({
            billingTypeId: { eq: { type: 'guid', value: id } },
          });
        }
      }
      result.push(conditions);
    }

    if (this.values.states) {
      const conditions = [];

      Object.keys(this.values.states).forEach((code) => {
        if (this.values.states[code].selected) {
          conditions.push({
            project: {
              stateId: { type: 'guid', value: this.values.states[code].id },
            },
          });
        }
      });

      result.push({ or: conditions });
    }

    return result;
  }

  public override getDatePeriodUrlParams(): Dictionary<string> {
    return this.dateService.getDatePairFromPeriodForFilter(this.values.period);
  }
}
