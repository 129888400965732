<tmt-form-header [name]="timeOffTypeForm.value?.name"></tmt-form-header>
<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
<wp-data-not-saved-indicator [form]="timeOffTypeForm"></wp-data-not-saved-indicator>

<form [formGroup]="timeOffTypeForm" *ngIf="!isLoading" class="form mt-3" style="width: 600px">
  <div class="form-group">
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}">
    </wp-text-box>
  </div>

  <div class="form-group" style="width: 150px">
    <label>{{ 'shared.props.code' | translate }}</label>
    <wp-text-box formControlName="code" placeholder="{{ 'shared.props.code' | translate }}">
    </wp-text-box>
  </div>

  <div class="form-group form-check" title="{{ 'shared.props.paid' | translate }}">
    <input formControlName="paid" type="checkbox" class="form-check-input" id="paid" />
    <label class="form-check-label" for="paid">
      {{ 'shared.props.paid' | translate }}
    </label>
  </div>

  <div class="form-group" style="width: 400px">
    <label>{{ 'settings.timeOffTypes.unit' | translate }}</label>
    <wp-select-box
      formControlName="unit"
      [values]="timeOffTypeUnits"
      placeholder="{{ 'settings.timeOffTypes.unit' | translate }}"
    >
    </wp-select-box>
  </div>

  <div class="form-group">
    <label>{{ 'shared.props.description' | translate }}</label>
    <wp-multiline-text-box
      formControlName="description"
      placeholder="{{ 'shared.props.description' | translate }}"
    >
    </wp-multiline-text-box>
  </div>

  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>

  <div class="actions" [hidden]="readonly">
    <button class="btn btn-primary" wpButtonSpinner [isBusy]="isSaving" (click)="save()">
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
  <div *ngIf="timeOffType.unitId" class="pt-5">
    <label class="group">{{ 'settings.timeOffTypes.policies' | translate }}</label>
    <wp-accrual-policy-section
      class="d-block mb-3"
      *ngIf="!isLoading"
      [entityId]="entityId"
      [timeOffTypeUnit]="timeOffTypeForm.value.unit"
    ></wp-accrual-policy-section>
    <wp-using-policy-section
      class="d-block mb-3"
      *ngIf="!isLoading"
      [ngClass]="{ disabled: timeOffType.unit.code !== timeOffTypeUnitsEnum.calendarDay.code }"
      [entityId]="entityId"
      [timeOffTypeUnit]="timeOffTypeForm.value.unit"
    ></wp-using-policy-section>
  </div>
</form>
