import { ProjectVersionMergeEnum } from 'src/app/projects/project-versions/project-versions-merge-modal/models/project-version-merge-enum.model';
import { Guid } from 'src/app/shared/helpers/guid';

/** Represents the Project version merge KPI kind. */
export class ProjVersionMergeKpiKind extends ProjectVersionMergeEnum {
  /** Hours. */
  public static get hours(): ProjVersionMergeKpiKind {
    return {
      id: Guid.generate(),
      code: 'Hours',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.kpiKind.hours',
    };
  }

  /** Cost. */
  public static get cost(): ProjVersionMergeKpiKind {
    return {
      id: Guid.generate(),
      code: 'Cost',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.kpiKind.cost',
    };
  }

  /** Revenue. */
  public static get revenue(): ProjVersionMergeKpiKind {
    return {
      id: Guid.generate(),
      code: 'Revenue',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.kpiKind.revenue',
    };
  }

  /** Billing. */
  public static get billing(): ProjVersionMergeKpiKind {
    return {
      id: Guid.generate(),
      code: 'Billing',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.kpiKind.billing',
    };
  }

  /** Expenses. */
  public static get expenses(): ProjVersionMergeKpiKind {
    return {
      id: Guid.generate(),
      code: 'Expenses',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.kpiKind.expenses',
    };
  }

  /** Profitability. */
  public static get profitability(): ProjVersionMergeKpiKind {
    return {
      id: Guid.generate(),
      code: 'Profitability',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.kpiKind.profitability',
    };
  }
}

export const ProjVersionMergeKpiKinds: ProjVersionMergeKpiKind[] = [
  ProjVersionMergeKpiKind.hours,
  ProjVersionMergeKpiKind.cost,
  ProjVersionMergeKpiKind.revenue,
  ProjVersionMergeKpiKind.billing,
  ProjVersionMergeKpiKind.expenses,
  ProjVersionMergeKpiKind.profitability,
];
