import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LifecycleService } from 'src/app/core/lifecycle.service';

@Component({
  selector: 'tmt-workflow-indicator-popup',
  templateUrl: './workflow-indicator-popup.component.html',
  styleUrls: ['./workflow-indicator-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowIndicatorPopupComponent {
  constructor(public lifecycleService: LifecycleService) {}
}
