<div class="row mt-4">
  <div class="col-12">
    <label class="group mb-0">
      {{ 'components.issueTypePermissionsComponent.groups.permissions.viewer' | translate }}
    </label>
  </div>
</div>
<wp-performers
  class="p-0"
  [performerLines]="viewers"
  [readonly]="readonly"
  [performerTypes]="performerTypes"
  [performerTypeName]="performerTypeNameViewer"
  [lifecycleId]="entityId"
/>
<div class="row mt-4">
  <div class="col-12">
    <label class="group mb-0">
      {{ 'components.issueTypePermissionsComponent.groups.permissions.editor' | translate }}
    </label>
  </div>
</div>
<wp-performers
  class="p-0"
  [performerLines]="editors"
  [readonly]="readonly"
  [performerTypes]="performerTypes"
  [performerTypeName]="performerTypeNameEditor"
  [lifecycleId]="entityId"
/>
