<wp-loading-indicator
  *ngIf="!projects.length && !this.ready"
  [loading]="!projects.length && !this.ready"
></wp-loading-indicator>

<div *ngIf="!projects.length && this.ready">
  <p class="text-body-secondary text-center mb-0">
    {{ 'shared.noDisplayData' | translate | uppercase }}
  </p>
</div>

<form *ngIf="projects.length" [formGroup]="form">
  <div class="form-group">
    <label>{{ 'shared.apps.projects' | translate }}</label>
    <wp-select-box
      formControlName="projects"
      [values]="projects"
      [placeholder]="'resources.booking.filter.properties.team.placeholder' | translate"
    ></wp-select-box>
  </div>
</form>

<ngb-alert *ngIf="isShowAlert" [dismissible]="false" [type]="'warning'">
  <p class="mb-0">{{ 'resources.booking.teamMemberNotFound.alertOnAdd' | translate }}</p>
</ngb-alert>

<div *ngIf="projects.length" class="footer">
  <button
    type="button"
    class="btn btn-primary"
    [class.disabled]="!getControlValue()"
    (click)="addBookingEntry()"
    wpButtonSpinner
    [isBusy]="pending"
  >
    {{ 'shared.actions.add' | translate }}
  </button>

  <button type="button" class="btn btn-default" (click)="cancel()">
    {{ 'shared.actions.cancel' | translate }}
  </button>
</div>
