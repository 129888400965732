import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

/** Project task timeline settings. */
export class TimelineSettings implements BaseSettings {
  public readonly name = 'TimelineSettings';
  version: number;
  planningScale: PlanningScale = PlanningScale.Day;

  public getDefault(): TimelineSettings {
    return {
      version: 1,
      planningScale: PlanningScale.Day,
    } as TimelineSettings;
  }
}
