import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { MessageService } from 'src/app/core/message.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { ProjectBillingType } from 'src/app/shared/models/enums/project-billing-type';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { InvoiceSettings } from '../card/shared/invoice.settings';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wp-invoice-creation',
  templateUrl: './invoice-creation.component.html',
  styleUrls: ['./invoice-creation.component.scss'],
})
export class InvoiceCreationComponent implements OnInit, OnDestroy {
  @Input() client: NamedEntity;
  @Input() project: NamedEntity;

  public isSaving = false;

  public projectsQuery: any = {};

  form = this.fb.group({
    client: [null, Validators.required],
    project: [null, Validators.required],
    assignAutoNumber: true,
    number: ['', [Validators.maxLength(50), Validators.required]],
  });

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private configService: LocalConfigService,
    private notification: NotificationService,
    private message: MessageService,
    private data: DataService,
    private blockUI: BlockUIService,
    private state: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.form.controls.number.disable();
    this.form.controls.assignAutoNumber.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.form.controls.assignAutoNumber.value
          ? this.form.controls.number.disable()
          : this.form.controls.number.enable();
      });

    if (this.client) {
      this.form.controls.client.setValue(this.client);
    }

    if (this.project) {
      this.form.controls.project.setValue(this.project, { emitEvent: false });
    }

    this.form.controls.client.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.form.controls.project.setValue(null);
        this.updateProjectsFilter();
      });

    this.form.controls.project.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const project = this.form.controls.project.value;
        if (project) {
          this.blockUI.start();
          this.data
            .collection('Projects')
            .entity(project.id)
            .get({
              select: 'id',
              expand: { organization: { select: ['id', 'name'] } },
            })
            .subscribe({
              next: (serverProject: any) => {
                const client = this.form.controls.client.value;

                if (serverProject?.organization?.id !== client?.id) {
                  this.form.controls.client.setValue(
                    serverProject?.organization,
                    {
                      emitEvent: false,
                    },
                  );

                  this.updateProjectsFilter();
                }

                this.blockUI.stop();
              },
              error: () => {
                this.blockUI.stop();
              },
            });
        }
      });

    this.updateProjectsFilter();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const settings = this.configService.getConfig(InvoiceSettings);

    const data = {
      organizationId: this.form.value.client.id,
      projectId: this.form.value.project.id,
      number: this.form.value.assignAutoNumber ? '' : this.form.value.number,
      timeLinesGrouping: settings.timeLinesGrouping,
      expenseLinesGrouping: settings.expenseLinesGrouping,
    };

    this.data
      .collection('Invoices')
      .action('Insert')
      .execute(data)
      .subscribe({
        next: (response: string) => {
          this.notification.successLocal(
            'billing.invoices.creation.messages.created',
          );
          this.state.go('invoice', { entityId: response });
          this.isSaving = false;
          this.activeModal.close(response);
        },
        error: (error: Exception) => {
          this.message.errorDetailed(error);
          this.isSaving = false;
        },
      });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  private updateProjectsFilter() {
    const client = this.form.controls.client.value;
    if (client) {
      this.projectsQuery = {
        filter: {
          organizationId: { type: 'guid', value: client.id },
          billingTypeId: {
            ne: { type: 'guid', value: ProjectBillingType.nonBillable.id },
          },
          isActive: true,
        },
      };
    } else {
      this.projectsQuery = {
        filter: {
          organizationId: { ne: null },
          isActive: true,
        },
      };
    }
  }
}
