import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wp-permission-set-finance-manager',
  templateUrl: './permission-set-finance-manager.component.html',
})
export class PermissionSetFinanceManagerComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  ngOnInit(): void {
    this.form.get('AccountingEntry.All.viewEnabled').disable();
    this.form.get('AccountingPeriod.All.viewEnabled').disable();
    this.form.get('TimeSheet.All.viewEnabled').disable();
    this.form.get('ExpenseRequest.All.viewEnabled').disable();
    this.form.get('ActOfAcceptance.All.viewEnabled').disable();
  }
}
