import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CustomFieldCardService {
  public detectChanges$ = new Subject<void>();

  public detectChanges() {
    this.detectChanges$.next();
  }

  public isRequiredFieldVisible(entityType: string): boolean {
    switch (entityType) {
      case 'ActOfAcceptance':
      case 'ExpenseRequest':
      case 'TimeSheetLine':
      case 'ProjectTask':
      case 'TimeAllocation':
      case 'ProjectVersion':
      case 'TimeOffRequest':
      case 'Certificate':
      case 'Issue':
        return false;
      default:
        return true;
    }
  }
}
