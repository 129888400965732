import { Injectable, EventEmitter } from '@angular/core';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { TranslateService } from '@ngx-translate/core';
import { TransitionService } from '@uirouter/core';
import { Observable, Subject } from 'rxjs';
import { Exception } from '../models/exception';
import { MessageService } from 'src/app/core/message.service';
import { LogService } from 'src/app/core/log.service';
import { AutosaveStateService } from './autosave-state.service';

/** @deprecated Use `SavingQueueService` instead. */
@Injectable()
export class AutosaveService {
  private isSavingSubject = new Subject<boolean>();

  /** Состояние выполнения сохранения. */
  public isSaving$ = this.isSavingSubject.asObservable();

  /** Событие, выполняемое поcле сохранения. */
  public onSave = new EventEmitter<any>();

  /** Событие, выполняемое при возникновении ошибки в момент сохранения. */
  public onError = new EventEmitter<Exception>();

  /** Задержка (debounce) от последнего изменения до начала сохранения. */
  public delayForSave = 2000;

  /** Состояние отключение автосохранения */
  public disabled = false;

  /** Возвращает объект для сохранения.
   * Метод должен быть определен во вне сервиса.
   */
  public builderFn: () => any;

  /** Возвращает Observable, который сработает при сохранении данных (т.е. метод DataService).
   * Метод должен быть определена во вне сервиса.
   */
  public savingFn: (data: any) => Observable<any>;

  // Время последнего изменения данных.
  private lastDataChanged = new Date();

  // Признак изменения данных
  private dataChanged = false;

  // Признак процесса сохранения.
  private _isSaving = false;

  public get isSaving(): boolean {
    return this._isSaving;
  }
  public set isSaving(value: boolean) {
    this._isSaving = value;
    this.isSavingSubject.next(value);
    this.autosaveStateService.setState(value);
  }

  private transitionOnStartListener: any;
  saveAttemptTimeout: any;

  constructor(
    private messageService: MessageService,
    private autosaveStateService: AutosaveStateService,
    private transition: TransitionService,
    private log: LogService,
    private blockUI: BlockUIService,
    private translate: TranslateService,
  ) {
    window.onbeforeunload = this.onbeforeunload;

    // Слушаем изменение маршрута.
    this.transitionOnStartListener = transition.onStart(
      {},
      () =>
        new Promise<void>((resolve, reject) => {
          this.save().then(() => resolve());
        }),
    );
  }

  // Сохранение (сигнал в очередь).
  public saveLazy() {
    if (this.disabled) {
      return;
    }
    this.log.debug('Lazy saving...');
    this.dataChanged = true;

    this.lastDataChanged = new Date();

    if (this.saveAttemptTimeout) {
      clearTimeout(this.saveAttemptTimeout);
    }
    this.saveAttempt();
  }

  // Принудительное сохранение.
  public save(): Promise<void> {
    this.log.debug('Forced saving...');

    return new Promise<void>((resolve, reject) => {
      if (this.disabled) {
        resolve();
      } else {
        if (this.dataChanged) {
          if (this.saveAttemptTimeout) {
            clearTimeout(this.saveAttemptTimeout);
          }

          this.blockUI.start();
          this.saveData(resolve);
        } else {
          const savedWait = () => {
            if (!this.isSaving) {
              resolve();
            } else {
              setTimeout(savedWait, 25);
            }
          };
          savedWait();
        }
      }
    });
  }

  public dispose = function () {
    this.transitionOnStartListener();
    window.onbeforeunload = null;
  };

  private saveAttempt() {
    // Пауза с последнего обновления
    const delay = new Date().getTime() - this.lastDataChanged.getTime();

    // Если пауза меньше заданной величины, или все еще идет сохранение, нужно попробовать еще раз через 50 мс
    if (delay < this.delayForSave || this.isSaving) {
      this.saveAttemptTimeout = setTimeout(() => this.saveAttempt(), 50);
    } else {
      // Могли сохранить принудительно, поэтому еще раз проверяем
      if (this.dataChanged) {
        this.saveData();
      }
    }
  }

  // Функция сохранения.
  private saveData(resolve?: any, reject?: any) {
    if (this.disabled) {
      if (resolve) {
        resolve();
      }
      return;
    }

    // $rootScope.isSaving = true;
    this.log.debug('Saving data has been started.');

    const startSave = new Date();

    this.dataChanged = false;
    const data = this.builderFn();

    this.isSaving = true;

    this.savingFn(data).subscribe({
      next: (response) => {
        const saveDuration = new Date().getTime() - startSave.getTime();
        this.log.debug(`Data has been saved in ${saveDuration} ms.`);

        this.onSave.emit(response);

        this.isSaving = false;
        this.blockUI.stop();

        if (resolve) {
          resolve();
        }
      },
      error: (error: Exception) => {
        this.blockUI.stop();

        this.isSaving = false;
        this.log.error(error.message);
        this.errorHandler(error);

        if (reject) {
          reject();
        }
      },
    });
  }

  // Слушаем закрытие страницы.
  private onbeforeunload = (event: any) => {
    if (!this.dataChanged && !this.isSaving) {
      return;
    }
    this.save();
    const message = this.translate.instant('common.leavePageMessage');

    if (typeof event == 'undefined') {
      event = window.event;
    }
    if (event) {
      event.returnValue = message;
    }
    return message;
  };

  private errorHandler(error: Exception) {
    const reload = () => {
      this.onError.emit(error);
    };

    if (error.code === Exception.BtConcurrencyException.code) {
      const msg = this.translate.instant(
        Exception.BtConcurrencyException.message,
      );
      this.messageService
        .message(msg, '', [], this.translate.instant('shared.actions.reload'))
        .then(reload, reload);
    } else {
      this.messageService
        .message(
          error.message,
          this.translate.instant('shared.saveNotCompletedTitle'),
        )
        .then(reload, reload);
    }
  }
}
