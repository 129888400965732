<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label>
          {{ 'team.timeOffBalanceEntries.list.filter.properties.period.label' | translate }}
        </label>
        <wp-date-period-box
          formControlName="period"
          placeholder="{{
            'team.timeOffBalanceEntries.list.filter.properties.period.placeholder' | translate
          }}"
        ></wp-date-period-box>
      </div>

      <div class="form-group">
        <label>
          {{ 'team.timeOffBalanceEntries.list.filter.properties.timeOffType.label' | translate }}
        </label>
        <wp-select-box
          formControlName="timeOffType"
          collection="TimeOffTypes"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{
            'team.timeOffBalanceEntries.list.filter.properties.timeOffType.placeholder' | translate
          }}"
        ></wp-select-box>
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label>
          {{ 'team.timeOffBalanceEntries.list.filter.properties.user.label' | translate }}
        </label>
        <wp-user-box
          formControlName="user"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{
            'team.timeOffBalanceEntries.list.filter.properties.user.placeholder' | translate
          }}"
        ></wp-user-box>
      </div>
      <div class="form-group">
        <label>
          {{
            'team.timeOffBalanceEntries.list.filter.properties.timeOffBalanceEntryType.label'
              | translate
          }}
        </label>
        <wp-select-box
          formControlName="type"
          [values]="timeOffBalanceEntryTypes"
          placeholder="{{
            'team.timeOffBalanceEntries.list.filter.properties.timeOffBalanceEntryType.placeholder'
              | translate
          }}"
        ></wp-select-box>
      </div>
      <div class="form-group">
        <label>
          {{
            'team.timeOffBalanceEntries.list.filter.properties.timeOffBalanceEntryMode.label'
              | translate
          }}
        </label>
        <wp-select-box
          formControlName="mode"
          [values]="timeOffBalanceEntryModes"
          placeholder="{{
            'team.timeOffBalanceEntries.list.filter.properties.timeOffBalanceEntryMode.placeholder'
              | translate
          }}"
        ></wp-select-box>
      </div>
    </div>
  </div>
</form>
