<!--Строка ресурса-->
<tr class="group" [class.disabled]="!group.isActive" id="{{ group.id }}">
  <td class="checkbox">
    <div class="wp-checkbox">
      <label>
        <input type="checkbox" [formControl]="selectControl" />
        <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
      </label>
    </div>
  </td>
  <td class="collapser cursor-pointer" (click)="expandGroup()">
    <div></div>
    <span
      [ngClass]="{
        'bi bi-chevron-down': group.isExpanded,
        'bi bi-chevron-right': !group.isExpanded
      }"
    ></span>
  </td>

  <td class="content">
    <div>
      <ng-container *ngIf="group.resource.type === ResourceType.user">
        <img class="avatar-bg" [src]="group.resource.id | avatar: 60" />
        <div class="trim">
          <div
            id="{{ group.resource.id }}"
            class="wp-action text"
            title="{{ group.name }}"
            (click)="openUserInfo(group.resource.id)"
          >
            <span id="{{ group.resource.id }}-name">{{ group.name }}</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="group.resource.type === ResourceType.generic">
        <img src="/assets/images/avatar/role.svg" />
        <div class="trim">
          <div class="text" title="{{ group.name }}">{{ group.name }}</div>
        </div>
      </ng-container>

      <i
        class="group-hint bi bi-question-circle ms-auto me-1 text-gray"
        [ngbTooltip]="'projects.projects.card.resourceRequirements.hints.user' | translate"
      ></i>
    </div>
  </td>
  <td class="p-0">
    <tmt-request-cell
      [teamMemberId]="group.teamMemberId"
      [resourceRequests]="group.resourceRequests"
    ></tmt-request-cell>
  </td>
  <td class="total-cell"></td>
</tr>
<!--Строка доступности-->
<tr class="availability group-child" *ngIf="group.isExpanded && group.resource?.type === 'User'">
  <td></td>
  <td class="empty-cell"></td>
  <td class="name">
    <div class="d-flex">
      <span>
        {{ 'projects.projects.card.resourceRequirements.rows.availability' | translate }}
      </span>
      <i
        class="group-hint bi bi-question-circle ms-auto me-1 text-gray"
        [ngbTooltip]="'projects.projects.card.resourceRequirements.hints.availability' | translate"
      ></i>
    </div>
  </td>
  <td></td>
  <td class="total-cell"></td>
</tr>
<!--Строка брони-->
<tr class="booking group-child" *ngIf="group.isExpanded && group.resource?.type === 'User'">
  <td></td>
  <td class="empty-cell"></td>
  <td class="name">
    <div class="d-flex">
      <span>
        {{ 'projects.projects.card.resourceRequirements.rows.booked' | translate }}
      </span>
      <i
        class="group-hint bi bi-question-circle ms-auto me-1 text-gray"
        [ngbTooltip]="'projects.projects.card.resourceRequirements.hints.booked' | translate"
      ></i>
    </div>
  </td>
  <td></td>
  <td class="total-cell">{{ group.bookedHoursTotal | plannerFormat }}</td>
</tr>
<!--Оценка-->
<tr class="estimate group-child" *ngIf="group.isExpanded">
  <td></td>
  <td class="collapser cursor-pointer" (click)="expandGroupEstimate()" style="padding-left: 30px">
    <div class="d-flex"></div>
    <span
      [ngClass]="{
        'bi bi-chevron-down': group.isEstimateExpanded,
        'bi bi-chevron-right': !group.isEstimateExpanded
      }"
    ></span>
  </td>
  <td class="name" style="padding-left: 20px">
    <div class="d-flex">
      <span>
        {{ 'projects.projects.card.resourceRequirements.rows.estimate' | translate }}
      </span>
      <i
        class="group-hint bi bi-question-circle ms-auto me-1 text-gray"
        [ngbTooltip]="'projects.projects.card.resourceRequirements.hints.estimate' | translate"
      ></i>
    </div>
  </td>
  <td></td>
  <td class="total-cell">{{ group.estimateTotal | plannerFormat }}</td>
</tr>
<ng-container *ngIf="group.isExpanded && group.isEstimateExpanded">
  <!-- Строки данных -->
  <tr
    *ngFor="let line of group.tasks; trackBy: trackById"
    class="group-child"
    [ngClass]="{ disabled: !line.isActive }"
  >
    <td></td>
    <td class="collapser"></td>
    <td id="{{ line.id }}" class="content" style="padding-left: 20px">
      <div>
        <div title="{{ line.name }}" class="text">
          {{ line.name }}
        </div>
      </div>
    </td>
    <td></td>
    <td class="total-cell">
      {{ line.total | plannerFormat }}
    </td>
  </tr>

  <tr *ngIf="group.tasks.length === 0">
    <td></td>
    <td class="collapser"></td>
    <td class="content"></td>
    <td></td>
    <td></td>
  </tr>
</ng-container>
