import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import _ from 'lodash';

import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { BookingType } from 'src/app/shared/models/enums/booking-type.enum';

import { BookingDataService } from 'src/app/booking/booking/core/booking-data.service';
import { Total } from 'src/app/booking/booking/models/total.model';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';

interface SlotInfo {
  project: NamedEntity;
  type: BookingType;
  hours: number;
  bookedHours: number;
  isTimeOff: boolean;
  isOther?: boolean;
}

@Component({
  selector: 'tmt-booking-slot-info',
  templateUrl: './booking-slot-info.component.html',
  styleUrls: ['./booking-slot-info.component.scss'],
})
export class BookingSlotInfoComponent implements OnInit {
  @Input() public params: any;

  public slotsInfoSoft: SlotInfo[];
  public slotsInfoHard: SlotInfo[];
  public slotsInfoSoftTotal: number;
  public slotsInfoHardTotal: number;
  public valueMode: ValueMode;
  public total: Total;

  private slotInfo: SlotInfo[] = [];

  constructor(
    private bookingDataService: BookingDataService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.valueMode = this.params.valueMode;
    this.total = this.params.totalInfo;
    this.load(this.params.resourceId);
    this.cdr.markForCheck();
  }

  private load(resourceId: string): void {
    const bookings = this.bookingDataService.getResourceBookings(resourceId);

    if (!bookings) {
      throw Error('Bookings on resource is not found.');
    }

    bookings.forEach((b) => {
      const result = b.detailEntries.find((e) => e.date === this.total.date);

      if (result) {
        this.slotInfo.push({
          project: b.project || {
            id: null,
            name: this.translateService.instant(
              'resources.booking.common.timeOff',
            ),
          },
          type: b.type,
          hours: result.hours,
          bookedHours: b.bookedHours,
          isTimeOff: b.isTimeOff,
          isOther: b.isOther,
        });
      }
    });

    if (!this.slotInfo.length) {
      throw Error('Bookings on this slot is not found.');
    }

    this.slotInfo = this.slotInfo.filter((e) => e.hours);

    this.slotsInfoHard = _.orderBy(
      this.slotInfo.filter((e) => e.type === BookingType.Hard),
      [
        (booking) => !booking.isOther && !booking.isTimeOff,
        (booking) => booking.isOther,
        (booking) => booking.isTimeOff,
        (booking) => booking.bookedHours,
      ],
      ['desc', 'desc', 'desc', 'desc'],
    );

    this.slotsInfoHardTotal = this.slotsInfoHard.reduce(
      (result, slot) => result + slot.hours,
      0,
    );

    this.slotsInfoSoft = _.orderBy(
      this.slotInfo.filter((e) => e.type === BookingType.Soft),
      [
        (booking) => !booking.isOther && !booking.isTimeOff,
        (booking) => booking.isOther,
        (booking) => booking.isTimeOff,
        (booking) => booking.bookedHours,
      ],
      ['desc', 'desc', 'desc', 'desc'],
    );

    this.slotsInfoSoftTotal = this.slotsInfoSoft.reduce(
      (result, slot) => result + slot.hours,
      0,
    );
  }
}
