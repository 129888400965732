import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { shareReplay, switchMap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { UntypedFormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PerformerTypeName } from 'src/app/settings-app/shared/performers/model/performer-type-name.enum';
import _ from 'lodash';

@Injectable()
export class PerformersService {
  lifecycleId: string;
  performerArray: UntypedFormArray;
  performerTypes: string[];
  performerTypeName: PerformerTypeName;

  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  public get performers() {
    return this.performerArray.value;
  }

  constructor(
    private data: DataService,
    private translate: TranslateService,
  ) {}

  /* Получить доступные роли для данного ЖЦ */
  public getAvailableRoles() {
    return this.data
      .collection(
        ['issueTypeEditor', 'issueTypeViewer'].includes(this.performerTypeName)
          ? 'IssueTypeMembers'
          : 'Lifecycles',
      )
      .entity(this.lifecycleId)
      .function(
        ['issueTypeEditor', 'issueTypeViewer'].includes(this.performerTypeName)
          ? 'GetIssueTypeMemberRoles'
          : 'GetLifecycleRoles',
      )
      .get()
      .pipe(
        switchMap((roles: string[]) =>
          of(
            roles.map((role) => {
              const namedEntity = { id: role, name: role };
              return namedEntity;
            }),
          ),
        ),
        shareReplay(),
      );
  }

  /** Получить имя роли. */
  public getRoleName(role: string): string {
    return this.translate.instant(`enums.lifecycleRole.${_.camelCase(role)}`);
  }

  /** Запустить проверку изменений */
  public detectChanges() {
    this.changesSubject.next();
  }
}
