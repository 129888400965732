import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectCardSharedModule } from 'src/app/projects/card/shared/project-card-shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { ProjectCostCentersComponent } from 'src/app/projects/card/project-cost-centers/project-cost-centers.component';
import { ProjectCostCentersToolbarComponent } from 'src/app/projects/card/project-cost-centers/project-cost-centers-toolbar/project-cost-centers-toolbar.component';
import { ProjectCostCenterModalComponent } from 'src/app/projects/card/project-cost-centers/project-cost-center-modal/project-cost-center-modal.component';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';

@NgModule({
  declarations: [
    ProjectCostCentersComponent,
    ProjectCostCentersToolbarComponent,
    ProjectCostCenterModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    Grid2Module,
    ProjectCardSharedModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.costCenters',
          url: '/cost-centers',
          component: ProjectCostCentersComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class ProjectCostCentersModule {}
