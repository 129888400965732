import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Provides methods to work with PnL report.
 * */
@Injectable()
export class PnlService {
  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  private reloadSubject = new Subject<void>();
  public reload$ = this.reloadSubject.asObservable();

  private filterModalSubject = new Subject<void>();
  public filterModal$ = this.filterModalSubject.asObservable();

  public detectChanges() {
    this.changesSubject.next();
  }

  public reload() {
    this.reloadSubject.next();
  }

  public openFilterModal() {
    this.filterModalSubject.next();
  }
}
