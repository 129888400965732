import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { BookingService } from 'src/app/booking/booking/core/booking.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmt-booking-header-right]',
  templateUrl: './booking-header-right.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingHeaderRightComponent {
  private $destroyed = new Subject<void>();

  constructor(
    public service: BookingService,
    cdr: ChangeDetectorRef,
  ) {
    service.detectChanges$
      .pipe(
        filter((v) => v === null),
        takeUntil(this.$destroyed),
      )
      .subscribe(() => {
        cdr.markForCheck();
      });
  }

  public trackById(index: number, row: any): string {
    return row.id;
  }
}
