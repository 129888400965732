<div class="form-group">
  <div class="toolbar-container" *ngIf="!readonly">
    <div class="group">
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.add' | translate }}"
        (click)="addPerformer()"
      >
        <i class="bi bi-plus-lg bi-15"></i>&nbsp;{{ 'shared.actions.add' | translate }}
      </button>

      <button
        [disabled]="!(gridService.selectedRow$ | async)"
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.delete' | translate }}"
        (click)="deletePerformer()"
      >
        <span class="bi bi-trash3"></span>
      </button>
    </div>
  </div>
  <wp-grid [formArray]="performerLines" [options]="gridOptions" [readonly]="readonly"></wp-grid>
</div>
