import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/** Компонента сообщения. */
@Component({
  selector: 'wp-message',
  templateUrl: './message.component.html',
})
export class MessageComponent {
  @Input() message: string;
  @Input() header: string;
  @Input() items: string[];
  @Input() buttonOk: string;
  @Input() buttonCancel: string;

  constructor(private activeModal: NgbActiveModal) {}

  ok() {
    this.activeModal.close();
  }

  cancel() {
    this.activeModal.dismiss('cancel');
  }
}
