<div class="form-group">
  <label class="control-label">{{ 'shared.userSettings.profileImage' | translate }}</label>
</div>
<div
  class="form-group"
  style="padding-left: 13px; vertical-align: bottom; position: relative"
  id="avatar-upload"
>
  <img
    [src]="userId | avatar: null : avatarVersion"
    style="width: 133px; height: 133px"
    class="img-thumbnail"
  />
  <div style="position: absolute; left: 170px; top: 0">
    <ngx-dropzone
      #drop
      style="height: 115px"
      [multiple]="false"
      accept=".png,.jpg,.jpeg,.webp"
      maxFileSize="3145728"
      (change)="onFileAdded($event)"
    >
      <ngx-dropzone-label>
        <button class="btn btn-default">
          {{ 'shared.actions.select' | translate }}
        </button>
        <br /><br />
        <span class="text-body-secondary"> {{ 'shared.orDragFileHere' | translate }}</span>
      </ngx-dropzone-label>
    </ngx-dropzone>
    <wp-loading-indicator [loading]="avatarIsLoading"></wp-loading-indicator>
    <p class="text-body-secondary">
      {{ 'settings.users.card.profile.props.avatarRequirement' | translate }}
    </p>
  </div>
</div>
