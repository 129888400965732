import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import { UntypedFormBuilder } from '@angular/forms';
import {
  GridColumnType,
  GridComponentColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Guid } from 'src/app/shared/helpers/guid';
import { StateChangingCellComponent } from 'src/app/shared/components/features/state-changing-cell/state-changing-cell.component';
import { SortDirection } from 'src/app/shared-features/comments/model/sort-direction.enum';
import { SortService } from 'src/app/shared/components/features/sort/core/sort.service';
import { orderBy } from 'lodash';
import { SubstitutionUserCellComponent } from 'src/app/shared/components/features/substitution-user-cell/substitution-user-cell.component';

@Component({
  selector: 'tmt-state-history',
  templateUrl: './state-history.component.html',
  styleUrls: ['./state-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridService],
})
export class StateHistoryComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public formArray = this.fb.array([]);

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();
  private loadSubscription: Subscription;
  public gridOptions: GridOptions = {
    css: 'table table-striped table-layout-fixed',
    sorting: false,
    view: {
      name: 'stateHistory',
      columns: [
        <GridComponentColumn>{
          name: 'performer',
          header: 'base.stateHistory.columns.performer',
          hint: 'base.stateHistory.columns.performer',
          type: GridColumnType.Component,
          component: SubstitutionUserCellComponent,
          width: '350px',
        },
        {
          name: 'date',
          header: 'base.stateHistory.columns.date',
          hint: 'base.stateHistory.columns.date',
          type: GridColumnType.DateTime,
          width: '140px',
          fixedWidth: true,
        },
        <GridComponentColumn>{
          name: 'changing',
          header: 'base.stateHistory.columns.changing',
          hint: 'base.stateHistory.columns.changing',
          type: GridColumnType.Component,
          component: StateChangingCellComponent,
          width: '300px',
        },
        {
          name: 'comment',
          header: 'base.stateHistory.columns.comment',
          hint: 'base.stateHistory.columns.comment',
          type: GridColumnType.MultilineString,
          width: '100%',
        },
      ],
    },
  };

  constructor(
    private data: DataService,
    private notification: NotificationService,
    private fb: UntypedFormBuilder,
    private lifecycleService: LifecycleService,
    private sortService: SortService,
  ) {}

  private load(): void {
    this.isLoadingSubject.next(true);
    this.loadSubscription?.unsubscribe();
    this.loadSubscription = this.lifecycleService
      .getCollection()
      .pipe(
        switchMap((collection) =>
          this.data
            .collection(collection)
            .entity(this.entityId)
            .function('GetStateHistory')
            .query<any[]>(null, {
              expand: [
                { fromState: { select: ['name', 'style'] } },
                { toState: { select: ['name', 'style'] } },
                { performer: { select: ['name', 'id'] } },
                { realPerformer: { select: ['name', 'id'] } },
              ],
              orderBy: 'created desc',
            }),
        ),
        map((logs) =>
          orderBy(
            logs,
            ['created'],
            this.sortService.sortDirection === SortDirection.newest
              ? 'desc'
              : 'asc',
          ),
        ),
      )
      .subscribe({
        next: (stateHistoryLogs: any[]) => {
          this.formArray.clear();
          stateHistoryLogs.forEach((log) => {
            this.formArray.push(
              this.fb.group({
                id: Guid.generate(),
                performer: {
                  performer: log.performer,
                  realPerformer: log.realPerformer,
                },
                date: log.created,
                changing: { fromState: log.fromState, toState: log.toState },
                comment: log.comment,
              }),
            );
          });
          this.isLoadingSubject.next(false);
        },
        error: (error: Exception) => {
          this.isLoadingSubject.next(false);
          this.notification.error(error.message);
        },
      });
  }

  ngOnInit(): void {
    this.lifecycleService.lifecycleInfo$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.load();
      });

    this.sortService.sortDirection$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((direction) => {
        this.formArray.setValue(
          orderBy(
            this.formArray.value,
            ['date'],
            direction === SortDirection.newest ? 'desc' : 'asc',
          ),
        );
      });
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
