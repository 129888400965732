import { Injectable } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  forkJoin,
  throwError,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { UserCostValue } from 'src/app/shared/models/entities/settings/user-cost-value.model';
import { Exception } from 'src/app/shared/models/exception';
import { CurrenciesService } from 'src/app/shared/services/currencies.service';
import { currencyValueRequiredValidator } from 'src/app/shared/validators/currency-value-required';

@Injectable()
export class UserCostRatesService {
  private resourceId: string;

  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();
  private isLoading = false;

  loadingSubscription: Subscription;

  public costForm = this.fb.group({
    initialValue: this.fb.group({
      effectiveDate: [null],
      value: [null, currencyValueRequiredValidator()],
      id: [null],
    }),
    values: this.fb.array([]),
  });

  public get costValuesForm() {
    return this.costForm.controls.values as UntypedFormArray;
  }

  constructor(
    private app: AppService,
    private data: DataService,
    private gridService: GridService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private currenciesService: CurrenciesService,
  ) {
    this.costForm.valueChanges.subscribe(() => {
      if (this.isLoading) {
        return;
      }
      this.changesSubject.next();
    });
  }

  create() {
    const group = this.getGridFormGroup({});
    this.costValuesForm.insert(0, group);

    group.controls.effectiveDate.setValue(DateTime.now().toISODate());

    this.costForm.markAsDirty();
    this.gridService.selectGroup(group);
  }

  public remove(index: number) {
    this.costValuesForm.removeAt(index);
    this.costForm.markAsDirty();
    this.changesSubject.next();
  }

  private getGridFormGroup(row: any) {
    return this.fb.group({
      effectiveDate: [row.effectiveDate, Validators.required],
      value: [
        row.value ?? {
          value: null,
          currencyCode: this.app.session.configuration.baseCurrencyCode,
        },
        currencyValueRequiredValidator(),
      ],
      id: [row.id ? row.id : Guid.generate()],
    });
  }

  public load(resourceId): Observable<void> {
    this.resourceId = resourceId;

    this.isLoadingSubject.next(true);
    this.isLoading = true;

    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    this.costValuesForm.clear();

    return forkJoin({
      currencies: this.currenciesService.currencies$,
      data: this.data
        .collection('Users')
        .entity(this.resourceId)
        .collection('CostValues')
        .query<UserCostValue[]>({
          orderBy: 'effectiveDate desc',
        }),
    }).pipe(
      map(
        (response) => {
          const data: UserCostValue[] = response.data;
          const currencies = response.currencies;

          // Убрать из списка начальное значение.
          let initialValue = data.find(
            (userCost: UserCostValue) => !userCost.effectiveDate,
          );

          if (initialValue) {
            const index = data.indexOf(initialValue);
            data.splice(index, 1);
          } else {
            initialValue = {
              effectiveDate: null,
              id: Guid.generate(),
              value: null,
              user: null,
              currencyId: currencies.find(
                (c) =>
                  c.alpha3Code ===
                  this.app.session.configuration.baseCurrencyCode,
              ).id,
            };
          }

          const initValueGroup = this.costForm.get('initialValue') as FormGroup;
          initValueGroup.patchValue(initialValue);

          const initialValueFilteredCurrency = currencies.find(
            (currency) => currency.id === initialValue.currencyId,
          );

          initValueGroup.controls.value.setValue(
            {
              value: initValueGroup.value.value,
              currencyCode: initialValueFilteredCurrency.alpha3Code,
            },
            { emitEvent: false },
          );

          data.forEach((userCost: UserCostValue) => {
            const filteredCurrency = currencies.find(
              (currency) => currency.id === userCost.currencyId,
            );
            const costForm = this.getGridFormGroup(userCost);

            costForm.controls.value.setValue(
              {
                value: costForm.value.value,
                currencyCode: filteredCurrency.alpha3Code,
              },
              { emitEvent: false },
            );
            this.costValuesForm.push(costForm);
          });

          this.costForm.markAsPristine();
          this.costForm.markAsUntouched();
          this.isLoadingSubject.next(false);
          this.isLoading = false;
        },
        (error: Exception) => {
          this.isLoadingSubject.next(false);
          this.isLoading = false;
          this.notification.error(error.message);
        },
      ),
    );
  }

  public save(): Observable<any> {
    this.costForm.markAllAsTouched();
    this.gridService.detectChanges();

    if (!this.costForm.valid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return throwError(Exception.ClientValidationException);
    }

    const data = { costValues: [] };
    const value = this.costForm.value;

    this.currenciesService.currencies$.subscribe((currencies) => {
      let currencyId = currencies.find(
        (currency) =>
          currency.alpha3Code === value.initialValue.value.currencyCode,
      ).id;
      data.costValues.push({
        effectiveDate: null,
        id: value.initialValue.id,
        value: value.initialValue.value.value,
        userId: this.resourceId,
        currencyId,
      });

      (value.values as UserCostValue[]).forEach((uc: UserCostValue) => {
        currencyId = currencies.find(
          (currency) => currency.alpha3Code === uc.value.currencyCode,
        ).id;
        data.costValues.push({
          effectiveDate: uc.effectiveDate,
          id: uc.id,
          value: uc.value.value,
          userId: this.resourceId,
          currencyId,
        });
      });
    });

    return this.data
      .collection('Users')
      .entity(this.resourceId)
      .action('WP.UpdateCostValues')
      .execute(data);
  }
}
