import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { ProjectOverviewComponent } from './project-overview.component';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { ProjectOverviewKpiModule } from 'src/app/projects/card/project-overview/project-overview-kpi/project-overview-kpi.module';
import { ProjectOverviewAnalysisModule } from 'src/app/projects/card/project-overview/project-overview-analysis/project-overview-analysis.module';

@NgModule({
  declarations: [ProjectOverviewComponent],
  imports: [
    CommonModule,
    SharedModule,
    ProjectOverviewKpiModule,
    ProjectOverviewAnalysisModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.overview',
          url: '/overview',
          component: ProjectOverviewComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
          redirectTo: 'project.overview.kpi',
          resolve: [
            {
              token: 'entityType',
              resolveFn: () => 'project',
            },
          ],
        },
      ],
    }),
    BaseSharedModule,
    CommentsModule,
  ],
})
export class ProjectOverviewModule {}
