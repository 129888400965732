import { Injectable } from '@angular/core';
import { HeaderIndicator } from './header-indicator.model';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class FormHeaderService {
  private indicatorsSubject = new ReplaySubject<HeaderIndicator[]>();
  public indicators$ = this.indicatorsSubject.asObservable();

  public updateIndicators(headerIndicators: HeaderIndicator[]) {
    this.indicatorsSubject.next(headerIndicators);
  }
}
