import { DestroyRef, Inject, Injectable, inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  Slot,
  SlotGroup,
} from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { DateTime, DurationLike, Interval } from 'luxon';
import {
  RequirementsViewMode,
  ResourceRequirementsSettings,
} from 'src/app/projects/card/project-resource-requirements/models/resource-requirements-settings.model';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { ScheduleNavigationContext } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-context.enum';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { ResourceRequirementsDataService } from 'src/app/projects/card/project-resource-requirements/core/resource-requirements-data.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { StateService } from '@uirouter/core';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { EntityFilter } from 'src/app/core/navigation.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ResourceRequest } from 'src/app/shared/models/entities/resources/resource-request.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class ResourceRequirementsService {
  public slots: Slot[];
  public slotGroups: SlotGroup[];
  public readonly: boolean;

  // Observables
  private toggleSubject = new Subject<string>();
  public toggle$ = this.toggleSubject.asObservable();
  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();
  private reloadSubject = new BehaviorSubject<void>(null);
  public reload$ = this.reloadSubject.asObservable();
  public selected$ = new Subject<string>();
  public loading$ = new BehaviorSubject<boolean>(true);
  public frameLoading$ = new BehaviorSubject<boolean>(false);

  private settings: ResourceRequirementsSettings;
  private interval: Interval;
  private project: Project;
  private readonly destroyRef = inject(DestroyRef);

  public get planningScale(): PlanningScale {
    return this.settings.planningScale;
  }

  public get valueMode(): ValueMode {
    return this.settings.valueMode;
  }

  public get currentViewMode(): string {
    return this.settings.viewMode;
  }

  constructor(
    @Inject('entityId') public projectId,
    private dataService: ResourceRequirementsDataService,
    private navigationService: ScheduleNavigationService,
    private localConfigService: LocalConfigService,
    private projectCardService: ProjectCardService,
    private freezeTableService: FreezeTableService,
    private blockUI: BlockUIService,
    private state: StateService,
    private autosave: SavingQueueService,
  ) {}

  /** Emits check changes */
  public emitChanges(): void {
    this.changesSubject.next();
  }

  /** Reloads data. */
  public reload(toDate?: DateTime) {
    this.settings = this.localConfigService.getConfig(
      ResourceRequirementsSettings,
    );
    this.settings.planningScale = this.navigationService.planningScale;
    this.interval = this.navigationService.getInterval(
      this.settings.planningScale,
      toDate,
    );
    this.updateDates();
    this.load();
  }

  /**
   * Returns table width.
   *
   * @param slotWidth - width of one cell. Optional, otherwise getSlotWidth() service function is used.
   * @param countOfSlots - number of cells. Optional, otherwise slots service variable is used.
   */
  public getTableWidth(
    slotWidth: number = this.getSlotWidth(),
    countOfSlots: number = this.slots.length,
  ): number {
    return slotWidth * countOfSlots;
  }

  /**
   * Returns slot width.
   *
   * @param planningScale planning scale. Optional, otherwise service settings are used.
   *  */
  public getSlotWidth(
    planningScale: PlanningScale = this.settings.planningScale,
  ): number {
    switch (planningScale) {
      case PlanningScale.Day:
        return 55;
      case PlanningScale.Week:
        return 75;
      case PlanningScale.Month:
        return 90;
      case PlanningScale.Quarter:
        return 120;
      case PlanningScale.Year:
        return 90;
    }
  }

  /** Initializes service. */
  public init() {
    this.projectCardService.project$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((project) => {
        this.project = project;
      });
    this.autosave.save$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.updateSavedEntries(data.entries);
      });

    this.navigationService.init(ScheduleNavigationContext.ResourceRequirements);
    this.settings = this.localConfigService.getConfig(
      ResourceRequirementsSettings,
    );

    this.navigationService.next$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() =>
        this.dataService.save().then(() => this.loadFrame('right')),
      );
    this.navigationService.previous$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() =>
        this.dataService.save().then(() => this.loadFrame('left')),
      );
    this.navigationService.jump$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((date) =>
        this.dataService.save().then(() => this.reload(date)),
      );
    this.navigationService.valueMode$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.dataService.save().then(() => this.reload()));
    this.navigationService.planningScale$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.dataService.save().then(() => this.reload()));
    this.projectCardService.reloadTab$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.dataService.save().then(() => this.reload()));

    this.reload();
  }

  /**
   * Triggers toggleSubject for refreshing left & right group table component.
   *
   * @param id - group Id.
   */
  public toggleGroup(id: string): void {
    this.toggleSubject.next(id);
  }

  /**
   * Navigates to project resource requests.
   * */
  public goToResourceRequests(): void {
    this.state.go(`resourceRequests`, {
      view: 'all',
      routeMode: RouteMode.continue,
      filter: JSON.stringify(<EntityFilter>{
        name: this.project.name,
        filter: [{ projectId: { type: 'guid', value: this.project.id } }],
      }),
    });
  }

  /** Creates resource requests for selected groups */
  public async createResourceRequests(): Promise<void> {
    this.blockUI.start();

    const groups = this.dataService.groups.filter((group) => {
      if (group.states.selected) {
        group.states.pending = true;
        return true;
      } else {
        return false;
      }
    });

    this.changesSubject.next();

    for (const group of groups) {
      const result: Partial<ResourceRequest> =
        await this.dataService.createResourceRequest(group.teamMemberId);

      group.states.pending = false;
      group.states.selected = false;

      if (result) {
        group.resourceRequests = [].concat(group.resourceRequests).concat([
          {
            id: result.id,
            name: result.name,
            created: result.created,
            requestedHours: result.requestedHours,
            state: result.state,
          },
        ]);
      }
    }

    this.blockUI.stop();
    this.changesSubject.next();
  }

  /**
   * Saves view mode.
   *
   * @param viewMode 'default' or 'request-group'.
   */
  public setViewMode(viewMode: RequirementsViewMode): void {
    this.settings.viewMode = viewMode;
    this.localConfigService.setConfig(
      ResourceRequirementsSettings,
      Object.assign(this.settings, { viewMode }),
    );
  }

  /** Loads resource requirements data. */
  private load(): void {
    this.loading$.next(true);

    this.dataService
      .loadResourceGroups(this.interval, this.planningScale, this.slots)
      .then(() => {
        this.changesSubject.next();
        this.loading$.next(false);
      });
  }

  /** Updates the selected period start and end dates, slots, and period set in a quick selection. */
  private updateDates(): void {
    const slotInfo = this.navigationService.getSlots(
      this.interval,
      this.settings.planningScale,
    );
    this.slots = slotInfo.slots;
    this.slotGroups = slotInfo.groups;
  }

  /** Loads the data frame for the specified direction.
   *
   * @direction: 'left' | 'right' - direction of loading.
   * */
  private loadFrame(direction: 'left' | 'right'): void {
    this.frameLoading$.next(true);
    this.blockUI.start();

    let shift: DurationLike;
    switch (this.settings.planningScale) {
      case PlanningScale.Day:
        shift = { weeks: 2 };
        break;
      case PlanningScale.Week:
        shift = { weeks: 10 };
        break;
      case PlanningScale.Month:
        shift = { month: 5 };
        break;
      case PlanningScale.Quarter:
        shift = { year: 1 };
        break;
      case PlanningScale.Year:
        shift = { year: 2 };
        break;
    }
    let loadingInterval =
      direction === 'left'
        ? Interval.fromDateTimes(
            this.interval.start.minus(shift),
            this.interval.start.minus({ days: 1 }),
          )
        : Interval.fromDateTimes(
            this.interval.end.plus({ days: 1 }),
            this.interval.end.plus(shift),
          );
    this.interval =
      direction === 'left'
        ? this.interval.set({
            start: this.interval.start.minus(shift),
          })
        : this.interval.set({
            end: this.interval.end.plus(shift),
          });
    if (
      this.settings.planningScale === PlanningScale.Month &&
      direction === 'right'
    ) {
      loadingInterval = loadingInterval.set({
        end: loadingInterval.end.endOf('month'),
      });
      this.interval = this.interval.set({
        end: this.interval.end.endOf('month'),
      });
    }
    this.updateDates();

    this.dataService
      .loadFrame(loadingInterval, this.settings.planningScale, this.slots)
      .then(() => {
        this.frameLoading$.next(false);
        this.blockUI.stop();
        this.changesSubject.next();

        setTimeout(() => {
          this.freezeTableService.disableMutationObserver();
          if (direction === 'left') {
            this.freezeTableService.scrollToLeft();
          } else {
            this.freezeTableService.scrollToRight();
          }

          setTimeout(() => {
            this.freezeTableService.enableMutationObserver();
          }, 500);
        }, 10);
      });
  }

  /** Updates saved entries in the calendar.
   *
   * @param entries saved entries.
   */
  private updateSavedEntries(entries: any[]): void {
    entries.forEach((saved) => {
      const group = this.dataService.groups.find(
        (g) => g.id === saved.teamMemberId,
      );
      if (!group) {
        return;
      }

      const taskLine = group.tasks.find((l) => l.taskId === saved.taskId);
      const entry = taskLine?.entries.find((e) => e.date === saved.date);
      if (entry) {
        const prevHours = entry.hours;
        taskLine.total += saved.hours - prevHours;
        entry.cost = saved.cost;
        entry.hours = saved.hours;
      }
    });
    this.changesSubject.next();
  }
}
