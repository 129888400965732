<tmt-form-header [name]="roleCardService.name$ | async"></tmt-form-header>
<tmt-not-found [state]="roleCardService.state$ | async"></tmt-not-found>
<wp-loading-indicator [state]="roleCardService.state$ | async"></wp-loading-indicator>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" [hidden]="(roleCardService.state$| async) !== 'Ready'" class="form mt-3" style="width: 600px">
  @if (form.value.isDefault) {
    <p class="form-control-static">
      <strong
        ><i aria-hidden="true" class="bi bi-check-circle"></i>&nbsp;{{
          'settings.roles.card.usingByDefault' | translate
        }}</strong
      >
    </p>
  }
  <div class="form-group" tmtIndicator>
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box
      formControlName="name"
      placeholder="{{ 'shared.props.name' | translate }}"
    />
  </div>

  <div class="form-group">
    <label>{{ 'shared.props.description' | translate }}</label>
    <wp-multiline-text-box
      formControlName="description"
      placeholder="{{ 'shared.props.description' | translate }}"
    />
  </div>
  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>
  @if (!readonly) {
    <div class="actions">
      <button
        type="button"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="isSaving$ | async"
        (click)="save()"
      >
        {{ 'shared.actions.save' | translate }}
      </button>
    </div>
  }
</form>