import { Component, OnInit, Input, Renderer2, ElementRef } from '@angular/core';
import {
  GridColumn,
  GridColumnType,
  GridCurrencyColumn,
  GridNumberControlColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { isObject } from 'lodash';
import { DecimalPipe } from '@angular/common';
import { WorkPipe } from 'src/app/shared/pipes/work.pipe';
import { AppService } from 'src/app/core/app.service';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import { TranslateService } from '@ngx-translate/core';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wp-grid-total-cell]',
  template: '',
})
export class GridTotalCellComponent implements OnInit {
  @Input() column: GridColumn;

  private _value: number;

  @Input()
  set value(value: number) {
    this._value = value;
    this.render();
  }
  get value(): number {
    return this._value;
  }

  textElement: any;

  constructor(
    private translate: TranslateService,
    private app: AppService,
    private currencyPipe: WpCurrencyPipe,
    private numberPipe: DecimalPipe,
    private workPipe: WorkPipe,
    private renderer: Renderer2,
    private elRef: ElementRef,
  ) {}

  ngOnInit() {
    if (this.column.total) {
      this.renderer.addClass(this.elRef.nativeElement, 'trim');
      this.textElement = this.renderer.createText('');
      this.renderer.appendChild(this.elRef.nativeElement, this.textElement);

      this.render();
    }
  }

  private render() {
    let text: string = null;

    if (!this._value) {
      this._value = 0;
    }

    const type = this.column.contentType
      ? this.column.contentType
      : this.column.type;

    if (this.column.total === TotalType.Count) {
      text = `${this.translate.instant(
        'shared.count',
      )}: ${this.numberPipe.transform(this.value, '1.0-0')}`;
    } else {
      if (
        type === GridColumnType.Decimal ||
        (type === GridColumnType.NumberControl &&
          (<GridNumberControlColumn>this.column).controlType === 'decimal')
      ) {
        text = this.numberPipe.transform(this.value, '1.2-2');
        this.renderer.addClass(this.elRef.nativeElement, 'text-end');
      }

      if (
        type === GridColumnType.Integer ||
        (type === GridColumnType.NumberControl &&
          (<GridNumberControlColumn>this.column).controlType === 'integer')
      ) {
        text = this.numberPipe.transform(this.value, '1.0-0');
        this.renderer.addClass(this.elRef.nativeElement, 'text-end');
      }

      if (
        type === GridColumnType.Work ||
        (type === GridColumnType.NumberControl &&
          (<GridNumberControlColumn>this.column).controlType === 'work')
      ) {
        text = this.workPipe.transform(this.value);
        this.renderer.addClass(this.elRef.nativeElement, 'text-end');
      }

      if (
        type === GridColumnType.Currency ||
        (type === GridColumnType.NumberControl &&
          (<GridNumberControlColumn>this.column).controlType === 'currency')
      ) {
        const column = this.column as GridCurrencyColumn;
        if (isObject(this.value)) {
          text = this.currencyPipe.transform(
            this.value['value'],
            this.value['currencyCode'],
          );
        } else {
          text = this.currencyPipe.transform(
            this.value,
            column.currencyCode ??
              this.app.session.configuration.baseCurrencyCode,
          );
        }
        this.renderer.addClass(this.elRef.nativeElement, 'text-end');
      }
    }

    if (text && this.textElement) {
      this.renderer.setValue(this.textElement, text);
      this.renderer.setAttribute(this.elRef.nativeElement, 'title', text);
    }
  }
}
