<wp-loading-indicator [state]="state"></wp-loading-indicator>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" [hidden]="state !== 'Ready'" class="form">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <label class="group">
          {{ 'settings.settings.tenantSettings.defaults.header' | translate }}
        </label>

        <div class="form-group" tmtIndicator>
          <label class="control-label">{{
            'settings.settings.tenantSettings.defaults.timeZone' | translate
          }}</label>
          <wp-select-box
            collection="TimeZones"
            formControlName="timeZone"
            [loadLimit]="150"
            [allowNull]="false"
          ></wp-select-box>
        </div>

        <div class="form-group" tmtIndicator>
          <label>{{ 'settings.settings.tenantSettings.defaults.language' | translate }}</label>
          <wp-select-box
            [allowNull]="false"
            [values]="languages"
            formControlName="language"
          ></wp-select-box>
        </div>

        <div class="form-group" tmtIndicator>
          <label>{{ 'settings.settings.tenantSettings.defaults.culture' | translate }}</label>
          <wp-select-box
            [allowNull]="false"
            [values]="cultures"
            [filterOnServer]="false"
            formControlName="culture"
          ></wp-select-box>
        </div>
      </div>
    </div>
  </div>

  <div class="actions" *ngIf="!readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="onSave()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
