<div>
  <div class="row">
    <div class="col-12">
      <div class="toolbar-container">
        <tmt-overview-switcher></tmt-overview-switcher>
        <div class="group">
          <div ngbDropdown class="d-inline-block" placement="bottom-left">
            <button type="button" class="btn btn-default" ngbDropdownToggle>
              {{ getKindName(service.settings.kind) }}
            </button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="service.setKind(kpiKind.Hours)">
                {{ getKindName(kpiKind.Hours) }}
              </button>
              <button
                ngbDropdownItem
                (click)="service.setKind(kpiKind.Cost)"
                *ngIf="financeViewAllowed"
              >
                {{ getKindName(kpiKind.Cost) }}
              </button>
              <button
                ngbDropdownItem
                (click)="service.setKind(kpiKind.Expenses)"
                *ngIf="financeViewAllowed"
              >
                {{ getKindName(kpiKind.Expenses) }}
              </button>
              <button
                ngbDropdownItem
                (click)="service.setKind(kpiKind.Revenue)"
                *ngIf="financeViewAllowed"
              >
                {{ getKindName(kpiKind.Revenue) }}
              </button>
            </div>
          </div>

          <div ngbDropdown class="d-inline-block" placement="bottom-left">
            <button type="button" class="btn btn-default" ngbDropdownToggle>
              {{ getScaleName(service.settings.scale) }}
            </button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="setScale(planningScale.Day)">
                {{ getScaleName(planningScale.Day) }}
              </button>
              <button ngbDropdownItem (click)="setScale(planningScale.Week)">
                {{ getScaleName(planningScale.Week) }}
              </button>
              <button ngbDropdownItem (click)="setScale(planningScale.Month)">
                {{ getScaleName(planningScale.Month) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="isEmpty"
    class="container-fluid"
    style="padding-left: 0; padding-right: 0; padding-top: 100px; padding-bottom: 100px"
  >
    <div class="row">
      <h2 class="text-center">
        <i class="bi bi-reception-4"></i>&nbsp;{{ 'projects.overview.noDataForChart' | translate }}
      </h2>
    </div>
  </div>

  <div id="highcharts" class="disable-selecting" [hidden]="isEmpty"></div>
</div>
