import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { RbcViewSwitcherComponent } from './rbc-view-switcher/rbc-view-switcher.component';

@NgModule({
  declarations: [RbcViewSwitcherComponent],
  imports: [CommonModule, SharedModule],
  providers: [],
  exports: [RbcViewSwitcherComponent],
})
export class ProjectRbcSharedModule {}
