<form [formGroup]="detailsForm" class="form">
  <div class="row">
    @for (item of filterService.values.rateMatrix?.rateMatrixStructure; track item) {
      <div class="form-group col-6">
        <label>
          {{ 'shared.rateMatrices.' + item | translate }}
        </label>
        <wp-select-box
          [formControlName]="item"
          [collection]="rateMatrixStructureCollection[item]"
          [query]="dependentAnalytics[item]?.query ?? null"
          [placeholder]="'shared.rateMatrices.' + item | translate"
        />
      </div>
    }
  </div>
</form>
