import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { RoleName } from 'src/app/shared/models/enums/role-name.enum';
import { InfoPopupService } from '../info-popup/info-popup.service';

/** Сведения о пользователе (вплывающее окно). */
@Component({
  selector: 'wp-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss'],
})
export class ProjectInfoComponent implements OnInit {
  @Input() params: any;

  public project: Project;
  public isLoading: boolean;
  public notFound: boolean;

  constructor(
    private data: DataService,
    private app: AppService,
    private notification: NotificationService,
    private changeDetector: ChangeDetectorRef,
    private infoPopupService: InfoPopupService,
  ) {}

  private open(projectId: string) {
    this.load(projectId);
    this.changeDetector.detectChanges();
  }

  public close = () => {
    this.infoPopupService.close();
  };

  private load(projectId: string) {
    this.isLoading = true;
    this.notFound = false;
    const query = {
      expand: [{ state: { select: 'name' } }, { manager: { select: 'name' } }],
      select: ['id', 'name', 'startDate', 'endDate', 'description'],
    };

    this.data
      .collection('Projects')
      .entity(projectId)
      .get<Project>(query)
      .subscribe({
        next: (project) => {
          this.project = project;
          this.isLoading = false;
          this.changeDetector.detectChanges();
          this.infoPopupService.update();
        },
        error: (error: Exception) => {
          this.isLoading = false;
          if (error.code === Exception.BtEntityNotFoundException.code) {
            this.notFound = true;
          } else {
            this.notification.error(error.message);
          }
          this.changeDetector.detectChanges();
          this.infoPopupService.update();
        },
      });
  }

  public hasProjectRole = () => this.app.checkRole(RoleName.ProjectManager);

  ngOnInit() {
    this.open(this.params.projectId);
  }
}
