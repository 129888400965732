<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ header | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="!groupingMode; else groupingModeEnabled">
      <div [formGroup]="filterForm" class="toolbar-container pt-0">
        <div class="input-group">
          <p
            type="text trim"
            class="form-control trim m-0"
            [ngClass]="{ placeholder: !this.grouping }"
            title="{{ getGroupingTitle() }}"
          >
            {{ getGroupingTitle() }}
          </p>
          <button
            class="btn btn-default input-group-text"
            type="button"
            (click)="setGroupingMode(true)"
          >
            <i class="bi bi-pencil"></i>
          </button>
        </div>

        <div class="input-block">
          <wp-date-period-box
            formControlName="period"
            placeholder="{{ 'billing.invoices.card.addingLinesModal.settings.period' | translate }}"
          >
          </wp-date-period-box>
        </div>
      </div>

      <div (resized)="resize($event)" class="d-flex h-100 position-relative">
        <table class="wp-nested-table header" [ngStyle]="headerTableStyle">
          <thead>
            <tr>
              <th class="header-col checkbox">
                <div class="wp-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      [(ngModel)]="allSelected"
                      (ngModelChange)="selectAll()"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <span class="cr">
                      <i class="cr-icon bi bi-check-lg"></i>
                    </span>
                  </label>
                </div>
              </th>
              <th
                [ngStyle]="{ width: 100 / fields.length + '%' }"
                *ngFor="let field of fields"
                class="header-col dynamic-style"
              >
                {{ cardService.getFieldLabel(field) }}
              </th>
              <th class="header-col">
                {{ 'billing.invoices.card.columns.unit.header' | translate }}
              </th>
              <th class="header-col">
                {{ 'billing.invoices.card.columns.rate.header' | translate }}
              </th>
              <th class="header-col">
                {{ 'billing.invoices.card.columns.quantity.header' | translate }}
              </th>
              <th class="header-col">
                {{ 'billing.invoices.card.columns.amount.header' | translate }}
              </th>
              <th *ngIf="fields.length === 0" class="header-col empty-cell"></th>
            </tr>
          </thead>
        </table>

        <div class="scroll-container" [ngStyle]="scrollContainerStyle">
          <table class="wp-nested-table" #tbl>
            <thead>
              <tr>
                <th class="header-col checkbox"></th>
                <th
                  [ngStyle]="{ width: 100 / fields.length + '%' }"
                  *ngFor="let field of fields"
                  class="header-col dynamic-style"
                >
                  {{ cardService.getFieldLabel(field) }}
                </th>
                <th class="header-col">
                  {{ 'billing.invoices.card.columns.unit.header' | translate }}
                </th>
                <th class="header-col">
                  {{ 'billing.invoices.card.columns.rate.header' | translate }}
                </th>
                <th class="header-col">
                  {{ 'billing.invoices.card.columns.quantity.header' | translate }}
                </th>
                <th class="header-col">
                  {{ 'billing.invoices.card.columns.amount.header' | translate }}
                </th>
                <th *ngIf="fields.length === 0" class="header-col empty-cell"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let line of lines" [ngClass]="{ selected: line.isSelected }">
                <td class="trim checkbox">
                  <div class="wp-checkbox">
                    <label>
                      <input
                        type="checkbox"
                        [(ngModel)]="line.isSelected"
                        [ngModelOptions]="{ standalone: true }"
                      />
                      <span class="cr">
                        <i class="cr-icon bi bi-check-lg"></i>
                      </span>
                    </label>
                  </div>
                </td>
                <td
                  class="text-cell"
                  *ngFor="let field of fields"
                  title="{{ getFieldValue(line, field) }}"
                >
                  {{ getFieldValue(line, field) }}
                </td>
                <td class="trim text-cell" title="{{ line.unit }}">
                  {{ line.unit }}
                </td>
                <td
                  class="trim text-cell text-end"
                  title="{{ line.rate | wpCurrency: currencyCode }}"
                >
                  {{ line.rate | wpCurrency: currencyCode }}
                </td>
                <td class="trim text-cell text-end" title="{{ line.quantity | number: '1.0-2' }}">
                  {{ line.quantity | number: '1.0-2' }}
                </td>
                <td
                  class="trim text-cell text-end"
                  title="{{ line.amount | wpCurrency: currencyCode }}"
                >
                  {{ line.amount | wpCurrency: currencyCode }}
                </td>
                <td *ngIf="fields.length === 0" class="trim"></td>
              </tr>
              <tr *ngIf="lines.length === 0 && !isLoading">
                <td [attr.colspan]="getGridColumnsCount()">
                  <div class="text-body-secondary text-center">
                    {{ 'shared.noDisplayData' | translate }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div *ngIf="lines.length === 250" class="text-center text-body-secondary p-1">
            {{ 'billing.invoices.card.addingLinesModal.showFirst250' | translate }}
          </div>

          <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
        </div>
      </div>
    </ng-container>

    <ng-template #groupingModeEnabled>
      <wp-grouping-settings
        *ngIf="groupingMode"
        [type]="type"
        [grouping]="grouping"
        (canceled)="setGroupingMode(false)"
        (saved)="onSaveGrouping($event)"
      >
      </wp-grouping-settings>
    </ng-template>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-between">
      <div class="text-start"></div>
      <div>
        <button
          *ngIf="hasSelectedLines"
          type="button"
          class="btn btn-primary"
          (click)="ok()"
          wpButtonSpinner
          [isBusy]="isSaving"
        >
          {{ 'billing.invoices.card.addingLinesModal.actions.addSelectedLines' | translate }}
        </button>
        <button type="button" class="btn btn-default ms-2" (click)="cancel()" [disabled]="isSaving">
          {{ 'shared.actions.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
