<!--Строка ресурса-->
<tr *ngIf="!bookingService.isResourceLineHidden" class="resource" [ngClass]="resourceCss">
  <td class="collapser" (click)="clickExpander()">
    <div></div>
    <span
      class="bi"
      [ngClass]="{
        'bi-chevron-down': isExpanded,
        'bi-chevron-right': !isExpanded
      }"
    ></span>
  </td>

  <td class="content">
    <div>
      <img class="avatar-bg" [src]="resource.id | avatar: 60" />
      <div
        [id]="'user' + resource.id"
        class="wp-action text"
        title="{{ resource.name }}"
        (click)="openUserInfo(resource.id)"
      >
        <span [id]="'user' + resource.id + '-name'" class="user-name">
          {{ resource.name }}
        </span>
      </div>
      <button
        *ngIf="isShowAddButton"
        (click)="
          !bookingService.isAssistantBottomMode
            ? openProjectList()
            : addResourceToSelected(resource)
        "
        class="btn btn-default btn--add"
        [class.opened]="isPopupOpened || bookingService.isAssistantBottomMode"
        [title]="
          (!bookingService.isAssistantBottomMode
            ? 'resources.actions.addProject'
            : 'resources.actions.addResource'
          ) | translate
        "
        #projectBtn
      >
        <i class="bi bi-plus-lg bi-15"></i>
      </button>
      <ng-container *ngIf="isShowActionMenu; then actionMenu"> </ng-container>
    </div>
  </td>
  <td *ngIf="bookingDataService.resourceRequestMode === 'assistant'" class="total-cell">
    {{ totalHours | plannerFormat }}
  </td>
</tr>

<ng-container *ngIf="isExpanded">
  <tr *ngIf="!content">
    <td
      [attr.colspan]="bookingDataService.resourceRequestMode === 'assistant' ? 3 : 2"
      class="line"
      [style.height]="height + 'px'"
    ></td>
  </tr>
  <ng-content></ng-content>
</ng-container>

<ng-template #actionMenu>
  <div class="request__actions" ngbDropdown container="body">
    <button
      class="btn request__button request__button--actions"
      ngbDropdownToggle
      placement="bottom-right"
    >
      <i class="bi bi-three-dots"></i>
      <div ngbDropdownMenu>
        <button
          ngbDropdownItem
          title="{{ 'shared.actions.delete' | translate }}"
          (click)="removeBookingEntry(resource.id)"
        >
          {{ 'shared.actions.delete' | translate }}
        </button>
      </div>
    </button>
  </div>
</ng-template>
