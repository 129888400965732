import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  Injector,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  ContentChild,
  Optional,
} from '@angular/core';

import _ from 'lodash';
import { Subject, merge } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

import { Dictionary } from 'src/app/shared/models/dictionary';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { Command } from 'src/app/shared-features/planner/models/command.model';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';

import { BookingMode } from 'src/app/shared/models/enums/booking-mode.enum';
import { BookingService } from '../../core/booking.service';
import { BookingRenderingService } from '../../core/booking-rendering.service';
import { BookingProjectAddComponent } from '../../shared/booking-project-add/booking-project-add.component';
import { ResourceRequestCalendarService } from 'src/app/resource-requests/shared/calendar/resource-request-calendar.service';
import { BookingDataService } from 'src/app/booking/booking/core/booking-data.service';
import { MessageService } from 'src/app/core/message.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmt-booking-left-group]',
  templateUrl: './booking-left-group.component.html',
  styleUrls: ['./booking-left-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingLeftGroupComponent implements OnInit, OnDestroy {
  @ContentChild('content') content: ElementRef<unknown>;
  @ViewChild('projectBtn') projectBtn: ElementRef<HTMLElement>;

  @Input() public resource: NamedEntity;
  @Input() public valueMode: ValueMode;

  public isExpanded: boolean;
  public isPopupOpened: boolean;
  public height: number;
  public rowStates: Dictionary<{
    commands: Command[];
    isHover: boolean;
    isMenuOpened: boolean;
  }> = {};

  private destroyed$ = new Subject<void>();

  public get resourceCss(): string[] {
    const result = [];

    if (!this.resource.isActive) {
      result.push('disabled');
    }

    if (this.isExpanded) {
      result.push('expanded');
    }

    if (this.bookingDataService.resourceRequestMode) {
      result.push('resource-request-mode');
    }

    return result;
  }

  public get totalHours(): number {
    return this.bookingDataService.getTotalsByResource(this.resource.id);
  }

  public get isShowActionMenu(): boolean {
    return (
      !this.bookingService.isActualizationResourceRequest &&
      this.bookingDataService.resourceRequestMode === 'assistant' &&
      this.bookingDataService.bookingMode === BookingMode.Basic &&
      this.bookingDataService.resourceRequest.bookingEntryEditAllowed
    );
  }

  public get isShowAddButton(): boolean {
    return (
      !this.bookingService.isActualizationResourceRequest &&
      !this.bookingDataService.extraResourceIds?.has(this.resource.id) &&
      !this.bookingDataService.resourceRequestMode &&
      ((!this.bookingService.isAssistantBottomMode &&
        this.bookingService.bookingMode === BookingMode.Detailed) ||
        (this.bookingService.isAssistantBottomMode &&
          this.bookingDataService.resourceRequest?.bookingEntryEditAllowed))
    );
  }

  constructor(
    @Optional() public bookingRenderingService: BookingRenderingService,
    public bookingService: BookingService,
    public bookingDataService: BookingDataService,
    private infoPopupService: InfoPopupService,
    private cdr: ChangeDetectorRef,
    private injector: Injector,
    private messageService: MessageService,
    private el: ElementRef<HTMLElement>,
    @Optional()
    private resourceRequestCalendarService: ResourceRequestCalendarService,
  ) {}

  public ngOnInit(): void {
    if (this.bookingService.isResourceLineHidden) {
      this.bookingService.toggleGroup(this.resource.id, true);
    }

    merge(
      this.bookingService.detectChanges$.pipe(
        filter((id) => id === this.resource.id),
      ),
      this.bookingService.recalculateGroup$.pipe(
        filter((value) => value.id === this.resource.id),
      ),
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.cdr.markForCheck();
      });

    this.bookingService.toggleGroup$
      .pipe(
        filter((event) => event?.id === this.resource.id),
        takeUntil(this.destroyed$),
      )
      .subscribe((event) => {
        this.isExpanded = event.state;
        this.cdr.markForCheck();
      });

    this.bookingRenderingService?.groupBoardHeight$
      .pipe(
        filter((params) => params.id === this.resource.id),
        takeUntil(this.destroyed$),
      )
      .subscribe((params) => {
        this.height = params.height;
        this.cdr.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Открытие сведений о пользователе. */
  public openUserInfo(userId: string): void {
    /* Привязка к элементу, у которого виден центр. По умолчанию span */
    const userNameDiv: HTMLElement = this.el.nativeElement.querySelector(
      `#user${userId}`,
    );
    const userNameSpan: HTMLElement = this.el.nativeElement.querySelector(
      `#user${userId}-name`,
    );

    const target =
      userNameDiv.offsetWidth < userNameSpan.offsetWidth
        ? userNameDiv
        : userNameSpan;

    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
      },
    });
  }

  /** Open project list in popup */
  public openProjectList(): void {
    const popupId = 'project-list-' + this.resource.id;

    this.infoPopupService.open({
      id: popupId,
      target: this.projectBtn.nativeElement,
      data: {
        component: BookingProjectAddComponent,
        componentParams: {
          inputs: {
            resource: this.resource,
            popupId,
          },
          injector: this.injector,
        },
      },
    });

    this.isPopupOpened = true;

    this.infoPopupService.event$
      .pipe(
        first((e) => e.name === 'destroy' && e.popup?.id === popupId),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.isPopupOpened = false;
        this.cdr.markForCheck();
      });
  }

  /** Adds resource to resource request.
   *
   * @param resource Resource.
   * */
  public addResourceToSelected(resource: NamedEntity): void {
    this.resourceRequestCalendarService?.addBookingEntry(
      resource,
      this.bookingDataService.schedules[resource.id],
    );
  }

  /** Обработка клика по экспандеру. */
  public clickExpander(): void {
    this.isExpanded = !this.isExpanded;
    this.bookingService.toggleGroup(this.resource.id, this.isExpanded);
    this.resourceRequestCalendarService?.setLineToggledEvent(
      this.resource.id,
      this.bookingService.isAssistantBottomMode,
    );
  }

  /** Deletes booking entry (Works when ResourceRequestMode).
   *
   * @param id Resource ID.
   */
  public removeBookingEntry(id: string): void {
    if (this.bookingDataService.resourceRequestMode) {
      this.messageService
        .confirmLocal('resources.booking.entryContextMenu.deleteConfirmation')
        .then(
          () => {
            const bookings = this.bookingDataService.getResourceBookings(id);

            bookings.forEach((booking) => {
              this.bookingService.deleteBooking(booking);
              this.resourceRequestCalendarService?.removeBookingEntry(
                booking.id,
                id,
              );
            });

            if (!bookings.length) {
              this.resourceRequestCalendarService?.removeBookingEntry(null, id);
            }

            _.remove(this.bookingDataService.resources, (el) => el.id === id);
            this.cdr.markForCheck();
          },
          () => null,
        );
    }
  }
}
