import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { DayPart } from 'src/app/shared/models/enums/day-part.enum';
import { DataService } from 'src/app/core/data.service';
import { TimeOffRequest } from 'src/app/shared/models/entities/base/time-off-request.model';
import { FormHelper } from 'src/app/shared/helpers/form-helper';
import { AppName } from 'src/app/shared/globals/app-name';
import { TimeOffRequestService } from '../time-off-request.service';
import { DateHours } from 'src/app/shared/models/entities/date-hours.model';
import { DateTime } from 'luxon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { merge } from 'rxjs';

@Component({
  selector: 'wp-time-off-period',
  templateUrl: './time-off-period.component.html',
  styleUrls: ['./time-off-period.component.scss'],
})
export class TimeOffPeriodComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() creatingMode: boolean;
  @Input() appName: AppName;

  public dayParts: NamedEntity[] = this.service.getDayParts();
  public existsRequest: any[] = [];
  public days: TimeOffDayHoursInfo[] = [];
  public lineWidth: string;

  private cellWidth = 60;
  private destroyRef = inject(DestroyRef);

  get request(): TimeOffRequest {
    return this.formGroup.value as TimeOffRequest;
  }

  constructor(
    private service: TimeOffRequestService,
    private data: DataService,
  ) {}

  public isSingleDay(): boolean {
    return (
      this.formGroup.controls['startDate'].value ===
        this.formGroup.controls['finishDate'].value ||
      !this.formGroup.controls['startDate'].value ||
      !this.formGroup.controls['finishDate'].value
    );
  }

  private loadSchedule() {
    const startDate = this.formGroup.controls['startDate'].value;
    const finishDate = this.formGroup.controls['finishDate'].value;
    const user = this.formGroup.controls['user'].value as NamedEntity;

    if (startDate && finishDate && user) {
      this.data
        .collection('Schedules')
        .function('WP.GetUserSchedule')
        .query({
          userId: user.id,
          from: startDate,
          to: finishDate,
        })
        .subscribe((data: DateHours[]) => {
          this.days = data.map((d) => ({
            date: d.date,
            hours: d.hours,
            calcDuration: 0,
            header: '',
          }));
          this.reCalculatePreview();
          this.lineWidth = `${data.length * this.cellWidth}px`;
        });

      // Загрузить сведения о пересекающихся ЗнО.
      const query: any = {
        select: ['id'],
        filter: [
          { userId: { type: 'guid', value: user.id } },
          { startDate: { le: { type: 'raw', value: finishDate } } },
          { finishDate: { ge: { type: 'raw', value: startDate } } },
        ],
        expand: [
          { state: { select: ['name', 'code', 'style'] } },
          { timeOffType: { select: ['name'] } },
        ],
      };

      if (!this.creatingMode) {
        query.filter.push({
          id: { ne: { type: 'guid', value: this.request.id } },
        });
      }

      this.data
        .collection('TimeOffRequests')
        .query(query)
        .subscribe((data: any[]) => {
          this.existsRequest = data;
        });
    }
  }

  private reCalculatePreview() {
    const startDate = this.formGroup.controls['startDate'].value;
    const finishDate = this.formGroup.controls['finishDate'].value;
    let startDayHours = this.formGroup.controls['startDayHours'].value;
    let finishDayHours = this.formGroup.controls['finishDayHours'].value;

    const startDayIntervalValue =
      this.formGroup.controls['startDayIntervalValue'].value;
    const finishDayIntervalValue =
      this.formGroup.controls['finishDayIntervalValue'].value;

    if (this.days.length === 0) {
      return;
    }

    const getCalcDuration = (
      interval: DayPart,
      duration: number,
      hours: number,
    ) => {
      switch (interval) {
        case DayPart.FullDay:
          return duration;
        case DayPart.ThreeQuarters:
          return (duration / 4) * 3;
        case DayPart.Half:
          return duration / 2;
        case DayPart.Quarter:
          return duration / 4;
        case DayPart.Octet:
          return duration / 8;
        case DayPart.Hours:
          return hours;
      }
      return 0;
    };

    this.days.forEach((day) => {
      day.header = DateTime.fromISO(day.date).toFormat('dd.LL.yy');
      day.calcDuration = day.hours;
    });

    if (startDayHours > this.days[0].hours) {
      this.formGroup.controls['startDayHours'].setValue(this.days[0].hours);
      startDayHours = this.days[0].hours;
    }

    this.days[0].calcDuration = getCalcDuration(
      startDayIntervalValue.id,
      this.days[0].hours,
      startDayHours,
    );

    if (!DateTime.fromISO(startDate).equals(DateTime.fromISO(finishDate))) {
      const duration = this.days[this.days.length - 1].hours;
      if (finishDayHours > duration) {
        this.formGroup.controls['finishDayHours'].setValue(duration);
        finishDayHours = duration;
      }

      this.days[this.days.length - 1].calcDuration = getCalcDuration(
        finishDayIntervalValue.id,
        duration,
        finishDayHours,
      );
    }
  }

  ngOnInit(): void {
    if (this.formGroup.value.startDayInterval) {
      this.formGroup.controls['startDayIntervalValue'].setValue(
        this.dayParts.find(
          (d) => d.id === this.formGroup.value.startDayInterval,
        ),
        { emitEvent: false },
      );
    }

    if (this.formGroup.value.finishDayInterval) {
      this.formGroup.controls['finishDayIntervalValue'].setValue(
        this.dayParts.find(
          (d) => d.id === this.formGroup.value.finishDayInterval,
        ),
        { emitEvent: false },
      );
    }

    merge(
      this.formGroup.controls['startDate'].valueChanges,
      this.formGroup.controls['finishDate'].valueChanges,
      this.formGroup.controls['user'].valueChanges,
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => setTimeout(() => this.loadSchedule()));

    this.formGroup.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reCalculatePreview();
      });

    FormHelper.controlDatePair(
      this.formGroup,
      'startDate',
      'finishDate',
      takeUntilDestroyed(this.destroyRef),
    );

    this.loadSchedule();
  }
}

export interface TimeOffDayHoursInfo extends DateHours {
  calcDuration: number;
  header: string;
}
