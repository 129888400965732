import { DateTime } from 'luxon';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ForecastPeriod } from 'src/app/shared/models/enums/forecast-period.enum';
import { ClientSession } from 'src/app/shared/models/session/client-session.model';

/** Предоставляет методы для работы с сущностями, связанными с проектом или его версиями. */
export class ProjectVersionUtil {
  /**
   * Определяет, выбран ли проект или его версия в списке версий для отображения данных в карточке проекта.
   * У "Рабочей" версии проекта данные версии не определены.
   * По умолчанию считается выбранной "Рабочая" версия проекта.
   *
   * @param projectVersion Версия проекта.
   * */
  public static isWorkProjectVersion(projectVersion: ProjectVersion) {
    return projectVersion?.state == null ?? true;
  }

  /**
   * Устанавливает свойства сущности, относящиеся к проекту или его версии.
   *
   * @param projectVersion Версия проекта.
   * @param entity Сущность.
   * @param projectId ID проекта.
   * */
  public static setEntityRootPropertyId(
    projectVersion: ProjectVersion,
    entity: any,
    projectId: string,
  ) {
    if (ProjectVersionUtil.isWorkProjectVersion(projectVersion)) {
      Object.assign(entity, { projectId });
    } else {
      Object.assign(entity, { versionId: projectVersion.id });
    }
  }

  /**
   * Adds OData query filter to search data by Project/Version ID.
   *
   * @param query OData query.
   * @param projectVersion Project version.
   * @param projectId Project ID.
   * @param includeMaster Indicates whether to include Master Project version to filter or not.
   * */
  public static addProjectEntityIdFilter(
    query: any,
    projectVersion: ProjectVersion,
    projectId: string,
    includeMaster = false,
  ) {
    query.filter ??= [];
    const conditions = { or: [] };

    if (ProjectVersionUtil.isWorkProjectVersion(projectVersion)) {
      conditions.or.push({
        projectId: { eq: { type: 'guid', value: projectId } },
      });
      if (includeMaster) {
        conditions.or.push({
          version: {
            and: {
              mainProjectId: { eq: { type: 'guid', value: projectId } },
              masterBaseline: true,
            },
          },
        });
      }
    } else {
      conditions.or.push({
        versionId: { eq: { type: 'guid', value: projectVersion?.id } },
      });
      if (includeMaster) {
        conditions.or.push({
          version: {
            and: {
              mainProjectId: {
                eq: { type: 'guid', value: projectVersion?.mainProject.id },
              },
              masterBaseline: true,
            },
          },
        });
      }
    }

    query.filter.push(conditions);
  }

  /**
   * Добавляет элемент в фильтр OData запроса для поиска данных по ID версии проекта.
   *
   * @param urlParams OData параметры Url запроса.
   * @param projectVersion Версия проекта.
   * @param assignNull Выставить в null, если версия не выбрана
   * */
  public static addVersionIdQueryParam(
    urlParams: any,
    projectVersion: ProjectVersion,
    assignNull = false,
  ) {
    urlParams ??= {};
    if (!ProjectVersionUtil.isWorkProjectVersion(projectVersion)) {
      Object.assign(urlParams, { versionId: projectVersion.id });
    } else if (assignNull) {
      Object.assign(urlParams, { versionId: null });
    }
  }

  /**
   * Добавляет поля проекта или его версии в список OData запроса для выборки.
   *
   * @param projectVersion Версия проекта.
   * @param select Список полей для выборки в OData.
   * */
  public static addProjectSelectFields(
    projectVersion: ProjectVersion,
    select: string[],
  ) {
    if (ProjectVersionUtil.isWorkProjectVersion(projectVersion)) {
      select.push('projectId');
    } else {
      select.push('versionId');
    }
  }

  /**
   * Возвращает сохраненный в архиве forecastBreakpoint.
   * Если версия не является снапшотом - берет из сессии.
   *
   * @param projectVersion Версия проекта.
   * @param session Клиентская сессия.
   * */
  public static getForecastBreakpoint(
    projectVersion: ProjectVersion,
    session: ClientSession,
  ): string {
    if (projectVersion?.snapshot?.forecastBreakpoint) {
      return projectVersion?.snapshot?.forecastBreakpoint;
    }
    const forecastPeriod = session.forecastPeriod;
    const now = DateTime.now();
    return (() => {
      switch (forecastPeriod) {
        case ForecastPeriod.Day: {
          return now.startOf('day').toISODate();
        }
        case ForecastPeriod.Week: {
          return now.startOf('week').toISODate();
        }
        case ForecastPeriod.Month: {
          return now.startOf('month').toISODate();
        }
      }
    })();
  }
}
