<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">{{ form.value.title }}</h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{
        'analytics.reportViewSettings.fieldEditor.properties.originalTitle.label' | translate
      }}</label>
      <p class="form-control-static">{{ sourceField?.title }}</p>
    </div>

    <div class="form-group">
      <label>{{
        'analytics.reportViewSettings.fieldEditor.properties.title.label' | translate
      }}</label>
      <wp-text-box
        formControlName="title"
        placeholder="{{
          'analytics.reportViewSettings.fieldEditor.properties.title.label' | translate
        }}"
      >
      </wp-text-box>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="ok()" wpButtonSpinner>
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
