import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { EmployeeExperienceOverviewComponent } from 'src/app/employees/card/employee-experience-overview/employee-experience-overview.component';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';

@NgModule({
  declarations: [EmployeeExperienceOverviewComponent],
  imports: [CommonModule, SharedModule, Grid2Module],
  exports: [EmployeeExperienceOverviewComponent],
})
export class EmployeeExperienceOverviewModule {}
