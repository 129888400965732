export const TREE_LIST_TEST = [
  {
    name: 'Administration',
    id: '9468cd5c-ea04-4adb-a1d7-94b6b910ab2e',
    leadDepartmentId: '5afc1894-d289-44f6-aab9-0baa60864016',
  },
  {
    name: 'Assessment services',
    id: '68269edc-dba2-4a5c-83ea-6112c994b5f4',
    leadDepartmentId: '5afc1894-d289-44f6-aab9-0baa60864016',
  },
  {
    name: 'Audit',
    id: '7ab940f1-5190-451b-a329-6a99eeacca75',
    leadDepartmentId: '5afc1894-d289-44f6-aab9-0baa60864016',
  },
  {
    name: 'Education',
    id: '51deec6c-4daa-4c50-b595-2173b1f1422f',
    leadDepartmentId: '5afc1894-d289-44f6-aab9-0baa60864016',
  },
  {
    name: 'Financial',
    id: '5805d66c-a6b3-4ec0-8a19-e62f27754ba4',
    leadDepartmentId: '9468cd5c-ea04-4adb-a1d7-94b6b910ab2e',
  },
  {
    name: 'HR',
    id: '1f5a8d44-6500-45f0-92ef-464d1480eb99',
    leadDepartmentId: '9468cd5c-ea04-4adb-a1d7-94b6b910ab2e',
  },
  {
    name: 'IT',
    id: '5318b7f2-57b5-4bfb-a32f-da1969d4e595',
    leadDepartmentId: '5afc1894-d289-44f6-aab9-0baa60864016',
  },
  {
    name: 'Legal',
    id: '0a805716-a965-45df-912a-db8a296e5293',
    leadDepartmentId: '5afc1894-d289-44f6-aab9-0baa60864016',
  },
  {
    name: 'Sales',
    id: 'daf820a2-da20-405a-a147-221918324f5f',
    leadDepartmentId: '5afc1894-d289-44f6-aab9-0baa60864016',
  },
  {
    name: 'Support',
    id: '1cfca443-cda2-4763-bd5f-a72ad3e1411b',
    leadDepartmentId: '9468cd5c-ea04-4adb-a1d7-94b6b910ab2e',
  },
  {
    name: 'Test123!',
    id: '5afc1894-d289-44f6-aab9-0baa60864016',
    leadDepartmentId: null,
  },
  {
    name: 'Support - First Line',
    id: '22fca443-cda2-4763-bd5f-a72ad3e1411b',
    leadDepartmentId: '1cfca443-cda2-4763-bd5f-a72ad3e1411b',
  },
  {
    name: 'Support - Second Line',
    id: '33fca443-cda2-4763-bd5f-a72ad3e1411b',
    leadDepartmentId: '1cfca443-cda2-4763-bd5f-a72ad3e1411b',
  },
  {
    name: 'Test321!',
    id: '44fca443-cda2-4763-bd5f-a72ad3e1411b',
    leadDepartmentId: null,
  },
  {
    name: '!123tseT',
    id: '44fca443-cda2-4763-bd5f-a72ad3e1231b',
    leadDepartmentId: null,
  },
];
