import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { GridComponentCell } from 'src/app/shared-features/grid2/models/grid-component-cell.interface';
import { RoleCompetence } from 'src/app/shared/models/entities/settings/role.model';

@Component({
  selector: 'tmt-competences-cell-readonly',
  templateUrl: './competences-cell-readonly.component.html',
  styleUrl: './competences-cell-readonly.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetencesCellReadonlyComponent
  implements GridComponentCell, OnInit
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public competences: RoleCompetence[] = [];
  public title: string;

  private destroyRef = inject(DestroyRef);

  constructor(
    public gridService: GridService,
    public cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.initCompetences();

    this.gridService.detectChanges$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }

  /* Inits competences. */
  private initCompetences(): void {
    this.competences =
      (this.formGroup.value.competences as RoleCompetence[]) ?? [];
    this.title = this.competences.map((item) => item.name).join('; ');
  }
}
