import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { ProjectInfoComponent } from 'src/app/shared/components/features/project-info/project-info.component';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { ProjectSummaryService } from 'src/app/project-summary/core/project-summary.service';
import { ProjectSummaryViewSettingsService } from 'src/app/project-summary/shared/project-summary-view-settings/core/project-summary-view-settings.service';
import {
  ProjectSummaryPageDto,
  ProjectSummaryTeamMemberDto,
} from 'src/app/project-summary/models/project-summary-data.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';

/** Represents Project Summary Left Grid content. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmtProjectSummaryLeftGroup]',
  templateUrl: './project-summary-left-group.component.html',
  styleUrls: ['./project-summary-left-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSummaryLeftGroupComponent implements OnInit {
  @Input() summaryPageDto: ProjectSummaryPageDto;

  /**
   * Indicates whether row is expanded or collapsed.
   *
   * @returns `true` if row is expanded, `false` otherwise.
   * */
  public get isExpanded(): boolean {
    return this._isExpanded;
  }

  /** Gets Selected KPI Types. */
  public get selectedKpiTypes(): KpiType[] {
    return this._selectedKpiTypes;
  }

  protected kpiType = KpiType;
  protected valueMode = ValueMode;
  protected resourceType = ResourceType;

  private _isExpanded: boolean;
  private _selectedKpiTypes: KpiType[];

  private destroyRef = inject(DestroyRef);

  constructor(
    public summaryService: ProjectSummaryService,
    private summaryViewSettingsService: ProjectSummaryViewSettingsService,
    private cdRef: ChangeDetectorRef,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
  ) {
    this._selectedKpiTypes = this.summaryService.selectedKpiTypes;
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  /**
   * Project info element click handler.
   * Opens Project Info popup.
   *
   * @param container HTML element container.
   * @param projectId Project ID.
   * */
  public openProjectInfo(container: HTMLElement, projectId: string): void {
    this.infoPopupService.open({
      target: container,
      data: {
        component: ProjectInfoComponent,
        params: {
          projectId,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * User info element click handler.
   * Opens User Info popup.
   *
   * @param container HTML element container.
   * @param teamMember Team member.
   * */
  public openUserInfo(
    container: HTMLElement,
    teamMember: ProjectSummaryTeamMemberDto,
  ): void {
    if (teamMember.resourceType !== ResourceType.user) {
      return;
    }

    this.infoPopupService.open({
      target: container,
      data: {
        component: UserInfoComponent,
        params: {
          userId: teamMember.resourceId,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Row toggle click handler.
   * Propagates expand/collapse to Project Summary component.
   * */
  public onToggleClick(): void {
    this._isExpanded = !this._isExpanded;
    this.summaryService.toggleGroup(this.summaryPageDto.id, this.isExpanded);
    this.summaryService.detectGroupChanges(this.summaryPageDto.id);
  }

  /**
   * Gets Project Total value.
   *
   * @param kpiType KPI Type.
   * @returns Project Total value.
   * */
  public getProjectTotal(kpiType: KpiType): number {
    return this.summaryPageDto.teamMembers
      .map(
        (member) =>
          member.totalValues.find(
            (totalValue) => totalValue.kpiType === kpiType,
          )?.value ?? 0,
      )
      .reduce((prev, curr) => prev + curr, 0);
  }

  /**
   * Gets User Total value.
   *
   * @param userId User ID.
   * @param kpiType KPI Type.
   * @returns User Total value.
   * */
  public getUserTotal(userId: string, kpiType: KpiType): number | null {
    const teamMember = this.summaryPageDto.teamMembers.find(
      (member) => member.id === userId,
    );
    return teamMember?.totalValues.find(
      (totalValue) => totalValue.kpiType === kpiType,
    )?.value;
  }

  /** Inits subscriptions. */
  private initSubscriptions(): void {
    this.summaryService.detectChanges$
      .pipe(
        filter((id) => !id || id === this.summaryPageDto.id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdRef.detectChanges();
      });

    this.summaryService.toggleGroup$
      .pipe(
        filter((event) => event?.id === this.summaryPageDto.id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event) => {
        this._isExpanded = event.state;
      });

    this.summaryViewSettingsService.settings$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        () => (this._selectedKpiTypes = this.summaryService.selectedKpiTypes),
      );
  }
}
