import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/core/app.service';

@Injectable()
export class TransitionFormService {
  //Allowed transition form properties.
  public allowedTransitionFormProperties$ = null;

  constructor(
    private data: DataService,
    private translate: TranslateService,
    private appService: AppService,
  ) {}

  /**
   * Sets allowed transition form properties.
   * @param lifecycleId Lifecycle Id.
   * @param entityType Entity type.
   */
  public setAllowedTransitionFormProperties(
    lifecycleId: string,
    entityType: string,
  ) {
    this.allowedTransitionFormProperties$ = this.data
      .collection('Lifecycles')
      .entity(lifecycleId)
      .function('GetAllowedTransitionFormProperties')
      .get()
      .pipe(
        map((allowedProperties: string[]) =>
          allowedProperties.map((property) => {
            const namedEntity = {
              id: property,
              name: this.appService.getPropertyLocalizationName(
                entityType,
                property,
              ),
            };
            return namedEntity;
          }),
        ),
        shareReplay(),
      );
  }

  /**
   * Gets transition property name localization.
   * @param lifecycleName Lifecycle name.
   * @param property Property name.
   */
  public getTransitionPropertyName(
    lifecycleName: string,
    property: string,
  ): string {
    return this.translate.instant(
      `enums.transitionFormProperties.${lifecycleName}.${property}`,
    );
  }
}
