import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { PlannerModule } from 'src/app/shared-features/planner/planner.module';
import { ScheduleNavigationModule } from 'src/app/shared-features/schedule-navigation/schedule-navigation.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectResourcesSharedModule } from '../shared/project-resources-shared.module';
import { ProjectResourcesEstimateCalendarComponent } from './project-resources-estimate-calendar.component';
import { ProjectResourcesEstimateCalendarLeftGroupComponent } from './shared/project-resources-estimate-calendar-left-group/project-resources-estimate-calendar-left-group.component';
import { ProjectResourcesEstimateCalendarRightGroupComponent } from './shared/project-resources-estimate-calendar-right-group/project-resources-estimate-calendar-right-group.component';
import { UndoRedoButtonsComponent } from 'src/app/shared/services/undo-redo/undo-redo-buttons/undo-redo-buttons.component';

@NgModule({
  declarations: [
    ProjectResourcesEstimateCalendarComponent,
    ProjectResourcesEstimateCalendarLeftGroupComponent,
    ProjectResourcesEstimateCalendarRightGroupComponent,
  ],
  exports: [ProjectResourcesEstimateCalendarComponent],
  imports: [
    CommonModule,
    SharedModule,
    ScheduleNavigationModule,
    PlannerModule,
    ProjectResourcesSharedModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.resources.estimateCalendar',
          url: '/estimate-calendar',
          component: ProjectResourcesEstimateCalendarComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
    UndoRedoButtonsComponent,
  ],
})
export class ProjectResourcesEstimateCalendarModule {}
