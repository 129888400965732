export class Entity {
  id: string;
  created?: string | Date;
  modified?: string | Date;
  isActive?: boolean;
  editAllowed?: boolean;
  deleteAllowed?: boolean;
}

export enum EntityState {
  added = 'Added',
  modified = 'Modified',
  deleted = 'Deleted',
}
