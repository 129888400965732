<wp-loading-indicator [loading]="loading"></wp-loading-indicator>

<form class="form" [hidden]="loading" [formGroup]="scheduleForm" novalidate autocomplete="off">
  <wp-data-not-saved-indicator [form]="scheduleForm"></wp-data-not-saved-indicator>

  <div class="form-group" formGroupName="initialValue">
    <label>{{ 'settings.users.card.schedules.props.schedule.label' | translate }}</label>
    <wp-select-box
      style="width: 400px"
      formControlName="schedule"
      [values]="schedules"
      placeholder="{{ 'settings.users.card.schedules.props.schedule.ph' | translate }}"
    >
    </wp-select-box>
    <span class="form-text text-body-secondary">{{
      'settings.users.card.schedules.props.schedule.help' | translate
    }}</span>
  </div>

  <wp-grid
    [formArray]="scheduleForm.controls['values']"
    [options]="gridOptions"
    [readonly]="readonly"
    class="d-block"
    style="width: 600px"
  ></wp-grid>

  <div class="actions">
    <button
      type="button"
      *ngIf="!readonly"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
