import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { ResourcesFilterComponent } from 'src/app/shared/modules/resources-filter/resources-filter.component';
import _ from 'lodash';

@Injectable()
export class ResourcesFilterService extends FilterService {
  public override component: Type<FilterDetails> = ResourcesFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override placeholder = 'shared2.filters.name.ph';
  public override storageName = 'resources-filter';
  public override views = [
    { code: 'all', local: 'shared.filter.allEntities' },
    { code: 'active', local: 'shared.filter.onlyActiveEntities' },
  ];
  public override customCriteriaCount: Record<string, () => number> = {
    text: () => 0,
    view: () => 0,
    resourceTypes: () => (this.resourceTypeConditionExist() ? 1 : 0),
  };
  public override detailAreaStyles = { width: '1000px' };

  public readonly filterResourceTypes = [
    ResourceType.user,
    ResourceType.department,
  ];

  protected override getDefaultValues(): any {
    return {
      text: '',
      view: this.views[0],
      department: null,
      resourcePool: null,
      role: null,
      legalEntity: null,
      competence: null,
      level: null,
      grade: null,
      location: null,
      resourceTypes: null,
    };
  }

  /** Checks whether resource type condition exists. */
  public resourceTypeConditionExist(): boolean {
    let allResourceTypesIsTrue = true;
    let allResourceTypesIsFalse = true;

    for (const name in this.values.resourceTypes) {
      if (this.values.resourceTypes[name]) {
        allResourceTypesIsFalse = false;
      } else {
        allResourceTypesIsTrue = false;
      }
    }
    return !allResourceTypesIsTrue && !allResourceTypesIsFalse;
  }

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    result.push({ resourceType: 'User' }); //TODO: remove when department will be available

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        or: [{ 'tolower(name)': { contains: text } }],
      });
    }

    if (this.values.location) {
      result.push({
        locationId: {
          type: 'guid',
          value: this.values.location.id,
        },
      });
    }

    if (this.values.legalEntity) {
      result.push({
        legalEntityId: {
          type: 'guid',
          value: this.values.legalEntity.id,
        },
      });
    }

    if (this.values.role) {
      result.push({
        roleId: {
          type: 'guid',
          value: this.values.role.id,
        },
      });
    }

    if (this.values.competence) {
      result.push({
        competenceId: {
          type: 'guid',
          value: this.values.competence.id,
        },
      });
    }

    if (this.values.level) {
      result.push({
        levelId: {
          type: 'guid',
          value: this.values.level.id,
        },
      });
    }

    if (this.values.grade) {
      result.push({
        gradeId: {
          type: 'guid',
          value: this.values.grade.id,
        },
      });
    }

    if (this.values.resourcePool) {
      result.push({
        resourcePoolId: {
          type: 'guid',
          value: this.values.resourcePool.id,
        },
      });
    }

    if (this.values.department) {
      result.push({
        departmentId: {
          type: 'guid',
          value: this.values.department.id,
        },
      });
    }

    if (
      this.values.view &&
      this.values.view.code === 'active' &&
      this.hasViewSelector
    ) {
      result.push({ isActive: true });
    }

    if (this.values.resourceTypes) {
      const conditions = [];
      this.filterResourceTypes.forEach((type) => {
        if (this.values.resourceTypes && this.values.resourceTypes[type]) {
          conditions.push({
            resourceType: _.upperFirst(type),
          });
        }
      });

      result.push({ or: conditions });
    }

    return result;
  }
}
