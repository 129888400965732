<form [formGroup]="service.form" style="max-width: 600px">
  <div class="alert alert-primary" role="alert" *ngIf="service.form.value.documentId">
    <div class="form-group mb-0">
      <label>{{ 'team.timeOffBalanceEntries.card.properties.document' | translate }}</label
      ><br />
      <a [uiSref]="service.entityType" [uiParams]="getDocumentStateParams()">{{
        service.form.value.documentName
      }}</a>
    </div>
  </div>

  <div class="d-flex">
    <div class="form-group">
      <label>{{ 'team.timeOffBalanceEntries.card.properties.type' | translate }}</label>
      <div class="clearfix"></div>

      <div class="btn-group btn-group-toggle" radioGroup name="radioBasic" formControlName="typeId">
        <label radioLabel class="btn-default trim fw-normal">
          <input radioButton type="radio" [value]="timeOffBalanceEntryType.accrual.id" />
          <i class="bi-arrow-right bi-15 pe-2"></i
          >{{ timeOffBalanceEntryType.accrual.name | translate }}
        </label>

        <label radioLabel class="btn-default trim fw-normal">
          <input radioButton type="radio" [value]="timeOffBalanceEntryType.use.id" />
          <i class="bi-arrow-left bi-15 pe-2"></i>{{ timeOffBalanceEntryType.use.name | translate }}
        </label>
      </div>
    </div>
  </div>

  <!-- User -->
  <div class="form-group">
    <label>{{ 'team.timeOffBalanceEntries.card.properties.user' | translate }}</label>
    <wp-user-box
      [allowNull]="false"
      placeholder="{{ 'team.timeOffBalanceEntries.card.properties.user' | translate }}"
      formControlName="user"
    ></wp-user-box>
  </div>

  <!-- Time-off type -->
  <div class="form-group">
    <label>{{ 'team.timeOffBalanceEntries.card.properties.timeOffType' | translate }}</label>
    <wp-select-box
      [allowNull]="false"
      [values]="timeOffTypes"
      placeholder="{{ 'team.timeOffBalanceEntries.card.properties.timeOffType' | translate }}"
      formControlName="timeOffType"
    ></wp-select-box>
  </div>

  <!-- Time-off unit & Creation mode -->
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>{{ 'team.timeOffBalanceEntries.card.properties.unit' | translate }}</label>
        <wp-select-box
          [allowNull]="false"
          [readonly]="true"
          [values]="timeOffTypeUnits"
          placeholder="{{ 'team.timeOffBalanceEntries.card.properties.unit' | translate }}"
          formControlName="unit"
        ></wp-select-box>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>{{ 'team.timeOffBalanceEntries.card.properties.mode' | translate }}</label>
        <wp-select-box
          [allowNull]="false"
          [readonly]="true"
          [values]="creationMods"
          formControlName="mode"
        ></wp-select-box>
      </div>
    </div>
  </div>
  <!-- Date & Amount -->
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>{{ 'team.timeOffBalanceEntries.card.properties.date' | translate }}</label>
        <wp-date-box [allowNull]="false" formControlName="date"></wp-date-box>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>{{ 'team.timeOffBalanceEntries.card.properties.value' | translate }}</label>
        <wp-number-box
          [allowNull]="false"
          formControlName="amount"
          type="number"
          [min]="-999999999"
        ></wp-number-box>
      </div>
    </div>
  </div>
</form>
