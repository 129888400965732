import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  BoxControlComponent,
  FormHelper,
} from 'src/app/shared/helpers/form-helper';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'tmt-profile-resource-controls',
  templateUrl: './profile-resource-controls.component.html',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileResourceControlsComponent implements AfterViewInit {
  @ViewChildren('cascadeControl')
  private cascadeControls: QueryList<BoxControlComponent>;

  @Input() form: FormGroup;

  private destroyRef = inject(DestroyRef);

  public ngAfterViewInit(): void {
    FormHelper.cascadeDependency(
      this.form,
      this.cascadeControls,
      [
        [
          {
            controlName: 'role',
          },
          {
            controlName: 'competence',
            dependedProperty: 'roleId',
          },
        ],
        [
          {
            controlName: 'level',
          },
          { controlName: 'grade', dependedProperty: 'levelId' },
        ],
      ],
      takeUntilDestroyed(this.destroyRef),
    );
  }
}
