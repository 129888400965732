import { Injectable } from '@angular/core';
import { DefaultFilterService } from '../../shared/default-filter/default-filter.service';

@Injectable()
export class ImportFilterService extends DefaultFilterService {
  public override hasViewSelector = false;

  public override getODataFilter() {
    const result: any[] = [];

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);
      result.push({ 'tolower(entityName)': { contains: text } });
    }
    return result;
  }

  protected override getDefaultValues = (): any => ({
    text: '',
    view: this.views[0],
  });

  public override getDatePeriodParams(): void {}
}
