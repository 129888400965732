<div
  class="avatar-cell"
  title="{{ getName() }}"
  [ngClass]="{ disabled: !formGroup.value.isActive }"
>
  @if (formGroup.value.resource?.resourceType === resourceType.user) {
    <img class="avatar-bg" [src]="formGroup.value.resource?.id | avatar: 60" />
  }

  @if (formGroup.value.resource?.resourceType === resourceType.department) {
    <img src="/assets/images/avatar/department.svg" />
  }

  @if (formGroup.value.resource?.resourceType === resourceType.generic) {
    <img src="/assets/images/avatar/role.svg" />
  }

  <div class="trim">
    {{ getName() }}
  </div>
</div>
