<div class="btn-group" role="group">
  <input
    type="radio"
    class="btn-check"
    id="table-switcher"
    [formControl]="control"
    [value]="projectTaskView.table"
  />
  <label class="btn btn-default" for="table-switcher">{{
    'shared2.props.table' | translate
  }}</label>

  <input
    type="radio"
    class="btn-check"
    id="timeline-switcher"
    [formControl]="control"
    [value]="projectTaskView.timeline"
  />
  <label class="btn btn-default" for="timeline-switcher">{{
    'shared2.props.timeline' | translate
  }}</label>
</div>
