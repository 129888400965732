<button
  type="button"
  title="{{ 'shared.pnlStatement.filter.hint' | translate }}"
  class="btn btn-default"
  (click)="open()"
>
  <span *ngIf="conditionsCount > 0" class="pe-2">
    {{ conditionsCount }} {{ conditionCountTitle }}
  </span>
  <i class="bi bi-funnel" aria-hidden="true"></i>
</button>
