<form [formGroup]="transitionFormFormGroup">
  <div class="form-group input-group">
    <div class="form-check form-check-inline">
      <input
        formControlName="requestComment"
        type="checkbox"
        class="form-check-input"
        id="requestComment"
      />
      <label for="requestComment">{{
        'settings.lifecycles.card.props.transition.form.props.requestComment' | translate
      }}</label>
    </div>
    <div class="form-check" *ngIf="transitionFormFormGroup.value.requestComment">
      <input
        formControlName="commentIsRequired"
        type="checkbox"
        class="form-check-input"
        id="commentIsRequired"
      />
      <label for="commentIsRequired">{{
        'settings.lifecycles.card.props.transition.form.props.commentIsRequired' | translate
      }}</label>
    </div>
  </div>

  <label class="group">{{
    'settings.lifecycles.card.props.transition.form.props.properties' | translate
  }}</label>
  <div class="toolbar-container pt-0">
    <div class="group">
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.add' | translate }}"
        (click)="gridService.execute('create')"
      >
        <i class="bi bi-plus-lg bi-15"></i>
        {{ 'shared.actions.add' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.delete' | translate }}"
        [disabled]="!gridService.selectedGroupValue"
        (click)="gridService.execute('delete')"
      >
        <span class="bi bi-trash3"></span>
      </button>
    </div>
  </div>

  <tmt-grid [formArray]="requestedProperties" [options]="gridOptions" [readonly]="false" />
</form>
