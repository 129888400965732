import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { PnlService } from 'src/app/shared-features/pnl/pnl.service';

@Component({
  selector: 'tmt-general-pnl',
  templateUrl: './general-pnl.component.html',
  providers: [PnlService],
})
export class GeneralPnlComponent implements OnDestroy {
  destroyed$ = new Subject<void>();

  constructor(
    public navigation: NavigationService,
    pnlService: PnlService,
    actions: ActionPanelService,
  ) {
    actions.reload$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      pnlService.reload();
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
