import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { BillingSummaryFilterComponent } from './billing-summary-filter.component';

@Injectable()
export class BillingSummaryFilterService extends FilterService {
  public override component: Type<FilterDetails> =
    BillingSummaryFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override hasAllowInactive = false;
  public override placeholder = 'billing.summary.filter.placeholder';

  public override customCriteriaCount: Record<string, () => number> = {
    withoutInvoices: () =>
      this.stateConditionExist() ? 0 : this.withoutInvoicesCount,
    states: () => (this.stateConditionExist() ? 1 : 0),
  };

  private get withoutInvoicesCount(): number {
    if (!this.values.states) {
      return +this.values.withoutInvoices;
    }
    const isAllStatesSelected =
      this.values.states[Object.keys(this.values.states)[0]]?.selected;
    return this.values.withoutInvoices
      ? +!isAllStatesSelected
      : +isAllStatesSelected;
  }
}
