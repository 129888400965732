@if (!(gridService.readonly$ | async)) {
  <div class="toolbar-container">
    <div class="group">
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.addLine' | translate }}"
        (click)="gridService.execute('add')"
        data-test="addLine"
      >
        <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
        {{ 'shared.actions.addLine' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.delete' | translate }}"
        [disabled]="!gridService.selectedGroupValue?.id"
        (click)="gridService.execute('delete', gridService.selectedGroupValue)"
        [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroupValue?.id)"
        data-test="delete"
      >
        <span class="bi bi-trash3" aria-hidden="true"></span>
      </button>
    </div>
  </div>
}
