import _ from 'lodash';

import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

export interface TariffRate extends NamedEntity {
  /** Currency id. */
  currencyId: string;
  /** Tariff value. */
  value: number;
  /** Start date of rate. */
  effectiveDate: string;
  /** End date of rate. */
  expiryDate: string;
}

export class TariffRatesHelper {
  /**
   * Gets actual rate.
   *
   * @param rates tariffs collection.
   * @returns actual tariff rate.
   */
  public static getActualRate(rates: TariffRate[]): TariffRate {
    if (rates.length === 1) {
      return rates[0];
    }

    rates = _.orderBy(rates, ['effectiveDate'], ['desc']);

    const dateNow = new Date();
    const newRates = rates.filter((r) => r.effectiveDate);

    for (const rate of newRates) {
      if (dateNow.getTime() > new Date(rate.effectiveDate).getTime()) {
        return rate;
      }
    }

    return rates.filter((r) => !r.effectiveDate)[0];
  }
}
