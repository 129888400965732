import { Injectable } from '@angular/core';
import { UsingPolicyModalComponent } from './using-policy-modal/using-policy-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeOffTypeCardService } from '../time-off-type-card.service';
import { DataService } from 'src/app/core/data.service';
import { TimeOffType } from 'src/app/shared/models/entities/settings/time-off-type.model';
import { MessageService } from 'src/app/core/message.service';
import { TranslateService } from '@ngx-translate/core';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
@Injectable()
export class UsingPolicyService {
  private timeOffType: TimeOffType;

  constructor(
    private modalService: NgbModal,
    private timeOffTypeService: TimeOffTypeCardService,
    private data: DataService,
    private messageService: MessageService,
    private translate: TranslateService,
    private notification: NotificationService,
    private blockUI: BlockUIService,
  ) {
    timeOffTypeService.timeOffType$.subscribe((value) => {
      this.timeOffType = value;
    });
  }

  public createNewPolicy = () => {
    const modalRef = this.modalService.open(UsingPolicyModalComponent);
    const component = modalRef.componentInstance as UsingPolicyModalComponent;
    component.mode = 'create';
    component.timeOffType = this.timeOffType;

    modalRef.result.then(
      (value) => {
        const data = {
          id: this.timeOffType.id,
          excludeFromCalendarDuration: value.excludeFromCalendarDuration,
        };

        this.blockUI.start();

        this.data
          .collection('UsingPolicies')
          .insert(data)
          .subscribe({
            next: () => {
              this.timeOffType.usingPolicy = value;
              this.timeOffTypeService.updateTimeOffType(this.timeOffType);
              this.notification.successLocal(
                'settings.usingPolicies.creation.messages.created',
              );
              this.blockUI.stop();
            },
            error: (error: Exception) => {
              this.notification.error(error.message);
              this.blockUI.stop();
            },
          });
      },
      () => null,
    );
  };

  public editPolicy = () => {
    const modalRef = this.modalService.open(UsingPolicyModalComponent);
    const component = modalRef.componentInstance as UsingPolicyModalComponent;
    component.mode = 'edit';
    component.timeOffType = this.timeOffType;

    modalRef.result.then(
      (value) => {
        const data = {
          id: this.timeOffType.id,
          excludeFromCalendarDuration: value.excludeFromCalendarDuration,
        };

        this.blockUI.start();

        this.data
          .collection('UsingPolicies')
          .entity(this.timeOffType.id)
          .update(data)
          .subscribe({
            next: () => {
              this.timeOffType.usingPolicy = value;
              this.timeOffTypeService.updateTimeOffType(this.timeOffType);
              this.notification.successLocal(
                'settings.usingPolicies.card.messages.saved',
              );
              this.blockUI.stop();
            },
            error: (error: Exception) => {
              this.notification.error(error.message);
              this.blockUI.stop();
            },
          });
      },
      () => null,
    );
  };

  public deletePolicy = () => {
    this.messageService
      .confirm(
        this.translate.instant('settings.timeOffTypes.deleteConfirmation'),
      )
      .then(
        () => {
          this.blockUI.start();
          this.data
            .collection('UsingPolicies')
            .entity(this.timeOffType.id)
            .delete()
            .subscribe({
              next: () => {
                this.timeOffType.usingPolicy = null;
                this.timeOffTypeService.updateTimeOffType(this.timeOffType);
                this.notification.successLocal(
                  'settings.usingPolicies.creation.messages.deleted',
                );
                this.blockUI.stop();
              },
              error: (error: Exception) => {
                this.notification.error(error.message);
                this.blockUI.stop();
              },
            });
        },
        () => null,
      );
  };
}
