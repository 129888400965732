import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';

@Component({
  selector: 'tmt-workflow-condition-toolbar',
  templateUrl: './workflow-condition-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowConditionToolbarComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  constructor(
    public gridService: GridService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.gridService.selectedRow$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cdr.detectChanges());
  }
}
