<form class="form">
  <div class="toolbar-container pt-0" *ngIf="!readonly">
    <button
      class="btn btn-default"
      (click)="addRoles()"
      [disabled]="resourceRolesService.isLoading$ | async"
    >
      <span class="bi bi-plus-lg bi-15"></span>&nbsp;{{
        'shared.resourceRoles.actions.addRoles' | translate
      }}
    </button>
  </div>
  <wp-grid
    [loading]="resourceRolesService.isLoading$ | async"
    [formArray]="resourceRolesService.roles"
    [options]="gridOptions"
    [readonly]="readonly"
  ></wp-grid>
</form>
