<div [formGroup]="form">
  <div class="form-check mt-4">
    <input formControlName="hasLegend" type="checkbox" class="form-check-input" id="hasLegend" />
    <label class="form-check-label" for="hasLegend">
      {{ 'analytics.dashboards.widgetSettings.properties.hasLegend' | translate }}
    </label>
  </div>
  <div class="form-check">
    <input
      formControlName="hasDataLabels"
      type="checkbox"
      class="form-check-input"
      id="hasDataLabels"
    />
    <label class="form-check-label" for="hasDataLabels">
      {{ 'analytics.dashboards.widgetSettings.properties.hasDataLabels' | translate }}
    </label>
  </div>
</div>
