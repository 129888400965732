export enum DayOfWeek {
  //
  // Summary:
  //     Indicates Sunday.
  Sunday = 'Sunday',
  //
  // Summary:
  //     Indicates Monday.
  Monday = 'Monday',
  //
  // Summary:
  //     Indicates Tuesday.
  Tuesday = 'Tuesday',
  //
  // Summary:
  //     Indicates Wednesday.
  Wednesday = 'Wednesday',
  //
  // Summary:
  //     Indicates Thursday.
  Thursday = 'Thursday',
  //
  // Summary:
  //     Indicates Friday.
  Friday = 'Friday',
  //
  // Summary:
  //     Indicates Saturday.
  Saturday = 'Saturday',
}
