import { UIRouter, Category, ParamTypeDefinition } from '@uirouter/angular';
import { environment } from '../environments/environment';

export function routerConfigFn(router: UIRouter) {
  const guidParamDefinition: ParamTypeDefinition = {
    encode: (obj) => obj,
    decode: (str) => str,
    is: () => true,
    equals: (a, b) => a === b,
    pattern: /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i,
  };

  const viewNameParamDefinition: ParamTypeDefinition = {
    encode: (obj) => obj,
    decode: (str) => str,
    is: (val) => val.length < 35, // couldn't think of anything better to not-guid pattern
    equals: (a, b) => a === b,
  };

  const guidOrMyParamDefinition: ParamTypeDefinition = {
    encode: (obj) => obj,
    decode: (str) => str,
    is: () => true,
    equals: (a, b) => a === b,
    pattern: /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}|my/i,
  };

  router.urlService.config.type('guid', guidParamDefinition);
  router.urlService.config.type('guidOrMy', guidOrMyParamDefinition);
  router.urlService.config.type('viewName', viewNameParamDefinition);

  if (!environment.production) {
    router.trace.enable(Category.TRANSITION);
  }
}
