import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { UntypedFormBuilder } from '@angular/forms';
import { LocalStorageService } from 'ngx-webstorage';
import { DateService } from 'src/app/core/date.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/core/notification.service';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { DatePeriodType } from 'src/app/shared/models/enums/date-period-type.enum';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { DateTimeLabelFormats } from 'src/app/shared/globals/hightstock.cfg';
import { ChromeService } from 'src/app/core/chrome.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Exception } from 'src/app/shared/models/exception';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { HighchartsService } from 'src/app/core/highcharts.service';
import { DateTime } from 'luxon';
import { ActionPanelService } from 'src/app/core/action-panel.service';

@Component({
  selector: 'tmt-employee-work-dynamic',
  templateUrl: './employee-work-dynamic.component.html',
})
export class EmployeeWorkDynamicComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private storageName = 'resourceDynamics';
  subscriptions: Subscription[] = [];

  public highcharts = this.overviewService.getHighcharts();
  private chartSubject = new BehaviorSubject<Highcharts.Chart>(null);
  private chart$ = this.chartSubject.asObservable().pipe(filter((c) => !!c));

  public chart: Highcharts.Chart;
  public isEmptyData: boolean;
  navigatorUpdating: any;
  chartLoading: boolean;

  public form = this.fb.group({
    period: null,
    type: null,
    grouping: null,
  });
  public get settings(): TeamWorkDynamicSettings {
    return this.form.value as TeamWorkDynamicSettings;
  }

  private chartOptions: Highcharts.Options = {
    chart: {
      alignTicks: false,
    },
    loading: {
      labelStyle: {
        fontSize: '20px',
        fontFamily: 'Segoe UI',
        fontWeight: 'light',
      },
    },
    credits: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: true,
    },
    title: {
      text: '',
    },
    xAxis: {
      events: {
        setExtremes: () => this.onNavigatorChanged(),
      },
    },

    tooltip: {
      valueSuffix: '',
    },
    series: [
      {
        type: 'column',
        tooltip: {
          valueDecimals: 2,
          valueSuffix: '',
        },
        dataGrouping: {
          forced: true,
          units: [['month', [3]]],
          dateTimeLabelFormats: DateTimeLabelFormats,
        },
      },
    ],
  };

  private destroyed$ = new Subject<void>();

  constructor(
    @Inject('entityId') public entityId,
    private chrome: ChromeService,
    private data: DataService,
    private fb: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private dateService: DateService,
    private translate: TranslateService,
    private notification: NotificationService,
    private overviewService: HighchartsService,
    actionPanelService: ActionPanelService,
  ) {
    actionPanelService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());
  }

  public getSelectedTypeName(): string {
    const settings = this.form.value as TeamWorkDynamicSettings;

    switch (settings.type) {
      case 'hours':
        return this.translate.instant('shared.teamWorkDynamics.type.hours');
      case 'utilization':
        return this.translate.instant(
          'shared.teamWorkDynamics.type.utilization',
        );
      case 'paidUtilization':
        return this.translate.instant(
          'shared.teamWorkDynamics.type.paidUtilization',
        );
    }
  }

  public getGroupingName(): string {
    const settings = this.form.value as TeamWorkDynamicSettings;

    switch (settings.grouping) {
      case 'day':
        return this.translate.instant('shared.teamWorkDynamics.groupBy.day');
      case 'week':
        return this.translate.instant('shared.teamWorkDynamics.groupBy.week');
      case 'month':
        return this.translate.instant('shared.teamWorkDynamics.groupBy.month');
      case 'quarter':
        return this.translate.instant(
          'shared.teamWorkDynamics.groupBy.quarter',
        );
      case 'year':
        return this.translate.instant('shared.teamWorkDynamics.groupBy.year');
    }
  }

  public setType(type: string) {
    this.form.controls.type.setValue(type);
    this.reload(true);
  }

  public setGrouping(grouping: string) {
    this.form.controls.grouping.setValue(grouping);
    this.setGroupingInChart();
    this.reload(true);
  }

  public reload(silent?: boolean) {
    this.subscriptions.push(
      this.chart$.subscribe(() => {
        this.chartLoading = true;
        this.chart.showLoading();

        const loadSeries = (seriesParams: any[]) => {
          let isEmpty = true;
          let i = 0;

          const params: Dictionary<any> = {
            groupBy: `'${this.settings.grouping}'`,
            from: null,
            to: null,
          };

          const isPercent = this.settings.type !== 'hours';

          seriesParams.forEach((seriesParam) => {
            const series = this.chart.series[i];
            i++;
            this.data
              .collection('UserTotals')
              .entity(this.entityId)
              .function(seriesParam.func)
              .query<any[]>(params)
              .subscribe({
                next: (data) => {
                  const tdata: any[] = [];
                  data.forEach((durationByDay) => {
                    tdata.push([
                      durationByDay.d,
                      isPercent ? durationByDay.v * 100 : durationByDay.v,
                    ]);
                  });

                  if (tdata.length > 0) {
                    isEmpty = false;
                  }

                  series.setData(tdata);
                  series.name = seriesParam.name;

                  if (i === seriesParams.length) {
                    this.chart.hideLoading();
                    this.setChartPeriod();

                    let suffix = ' %';
                    if (this.settings.type === 'hours') {
                      suffix = ' ' + this.translate.instant('shared.unitHour');
                    }

                    (this.chart.series[0] as any).tooltipOptions.valueSuffix =
                      suffix;

                    this.chartLoading = false;
                    this.isEmptyData = isEmpty;
                  }
                },
                error: (error: Exception) => {
                  this.chart.hideLoading();
                  this.notification.error(error.message);
                },
              });
          });
        };

        switch (this.settings.type) {
          case 'hours':
            loadSeries([
              {
                func: 'WP.GetDurationDynamics',
                name: this.getSelectedTypeName(),
              },
            ]);
            break;
          case 'utilization':
            loadSeries([
              {
                func: 'WP.GetUtilizationDynamics',
                name: this.getSelectedTypeName(),
              },
            ]);
            break;
          case 'paidUtilization':
            loadSeries([
              {
                func: 'WP.GetPaidUtilizationDynamics',
                name: this.getSelectedTypeName(),
              },
            ]);
            break;
        }
      }),
    );
  }

  private onNavigatorChanged() {
    if (this.navigatorUpdating || this.chartLoading) {
      return;
    }

    this.navigatorUpdating = true;

    if (this.chart && this.chart.series[0]) {
      const ax = this.chart.series[0].xAxis as any;

      if (ax.userMin && ax.userMax) {
        const from = DateTime.fromMillis(ax.userMin).toFormat('yyyy-MM-dd');
        const to = DateTime.fromMillis(ax.userMax).toFormat('yyyy-MM-dd');

        const datePeriod: DatePeriod = {
          from,
          to,
          periodType: DatePeriodType.Custom,
        };

        this.form.controls.period.setValue(datePeriod, {
          emitEvent: false,
        });
      }
    }
    this.navigatorUpdating = false;
  }

  private setChartPeriod() {
    this.chart.series.forEach((series) => {
      const ax = series.xAxis as any;

      const datePeriod: DatePeriod = this.form.controls.period.value;

      if (!datePeriod || !datePeriod.from || !datePeriod.to) {
        ax.userMin = ax.dataMin;
        ax.userMax = ax.dataMax;
      } else {
        ax.userMin = DateTime.fromISO(datePeriod.from)
          .setZone('utc', {
            keepLocalTime: true,
          })
          .valueOf();
        ax.userMax = DateTime.fromISO(datePeriod.to)
          .setZone('utc', {
            keepLocalTime: true,
          })
          .valueOf();
      }
    });
    this.chart?.redraw();
  }

  /** Изменение группировки данных в гистограмме. */
  public setGroupingInChart() {
    let period = [];

    switch (this.form.controls.grouping.value) {
      case 'day':
        period = [['day', [1]]];
        break;
      case 'week':
        period = [['week', [1]]];
        break;
      case 'month':
        period = [['month', [1]]];
        break;
      case 'quarter':
        period = [['month', [3]]];
        break;
      case 'year':
        period = [['year', [1]]];
        break;
    }

    this.chart.series.forEach((series) => {
      series.xAxis.setDataGrouping(
        {
          units: period,
        },
        false,
      );
    });

    this.chart.redraw();
  }

  ngAfterViewInit(): void {
    this.chart = this.highcharts.stockChart(
      'chartContainerTrends',
      this.chartOptions,
    );
    this.setGroupingInChart();
    this.setChartPeriod();
    this.chart.reflow();
    this.chartSubject.next(this.chart);
  }

  ngOnInit(): void {
    let settings = this.localStorageService.retrieve(
      this.storageName,
    ) as TeamWorkDynamicSettings;

    if (!settings) {
      settings = {
        type: 'hours',
        grouping: 'day',
        period: {
          periodType: DatePeriodType.ThisMonth,
          from: null,
          to: null,
        },
      };
      this.localStorageService.store(this.storageName, settings);
    }

    if (
      settings.period &&
      settings.period.periodType !== DatePeriodType.Custom
    ) {
      const pair = this.dateService.getDatePair(settings.period.periodType);
      settings.period.from = pair.from;
      settings.period.to = pair.to;
    }

    this.form.patchValue(settings);

    this.subscriptions.push(
      this.form.valueChanges.subscribe(() => {
        this.localStorageService.store(this.storageName, this.form.value);
      }),
    );

    this.reload();

    this.subscriptions.push(
      this.form.controls.period.valueChanges.subscribe(() => {
        if (this.navigatorUpdating) {
          return;
        }
        this.navigatorUpdating = true;
        this.setChartPeriod();
        this.navigatorUpdating = false;
      }),
    );

    this.subscriptions.push(
      this.chrome.mainAreaSize$.subscribe(() => {
        this.chart?.reflow();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.destroyed$.next();
    this.chart.destroy();
  }
}

export interface TeamWorkDynamicSettings {
  type: 'hours' | 'utilization' | 'paidUtilization';
  period: DatePeriod;
  grouping: 'day' | 'week' | 'month' | 'quarter' | 'year';
}
