/* eslint-disable @typescript-eslint/naming-convention */
export enum RecurringExpenseFrequency {
  /** Weekly frequency. */
  Week = 'Week',
  /** Monthly frequency. */
  Month = 'Month',
  /** Quarterly frequency. */
  Quarter = 'Quarter',
  /** Yearly frequency. */
  Year = 'Year',
}

export const RecurringExpenseFrequencies = [
  RecurringExpenseFrequency.Week,
  RecurringExpenseFrequency.Month,
  RecurringExpenseFrequency.Quarter,
  RecurringExpenseFrequency.Year,
];
