import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectBillingTypes } from 'src/app/shared/models/enums/project-billing-type';
import { NotificationService } from 'src/app/core/notification.service';
import { PnlFilter } from 'src/app/shared-features/pnl/shared/pnl-filter.model';
import { StateControlItem } from 'src/app/shared/components/features/state-filter/state-select.interface';

@Component({
  selector: 'wp-pnl-filter-modal',
  templateUrl: './pnl-filter-modal.component.html',
  styleUrls: ['./pnl-filter-modal.component.scss'],
})
export class PnlFilterModalComponent implements OnInit, OnDestroy {
  @Input() filter: PnlFilter;

  billingTypes = ProjectBillingTypes;

  public form = this.fb.group({
    period: null,
    project: null,
    task: null,
    program: null,
    client: null,
    projectStates: null,
    billingTypes: this.fb.group({
      NonBillable: [false],
      TM: [false],
      FixedBid: [false],
    }),
    allowNonActiveEntity: false,
  });

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private notification: NotificationService,
  ) {}

  ngOnInit(): void {
    if (this.filter) {
      this.form.patchValue(this.filter);
    }

    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * Resets filter form.
   * */
  public clearValues() {
    this.form.reset();
  }

  /**
   * Modal window close event handler.
   * */
  public ok = () => {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.activeModal.close(this.form.value);
  };

  /**
   * Modal window cancel event handler.
   * */
  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  /**
   * Inits subscriptions.
   * */
  private initSubscriptions() {
    this.form.controls.project.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.form.controls.task.setValue(null);
      });

    this.form.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.filter = value;

        if (value.projectStates) {
          this.filter.projectStates = Object.values(
            value.projectStates as Record<string, StateControlItem>,
          ).filter((state) => state.selected);
        }
      });
  }
}
