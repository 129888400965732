<div class="toolbar-container">
  <div class="group">
    <button
      *ngIf="!(gridService.readonly$ | async)"
      type="button"
      class="btn btn-default"
      title="  {{ 'shared.actions.create' | translate }}"
      (click)="gridService.execute('create')"
      data-test="create"
    >
      <i class="bi bi-plus-lg bi-15"></i>
      {{ 'shared.actions.create' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.edit' | translate }}"
      [disabled]="!gridService.canBeExecuted('edit', gridService.selectedGroup$.getValue())"
      (click)="gridService.execute('edit', gridService.selectedGroup$.getValue())"
      data-test="edit"
    >
      {{ 'shared.actions.edit' | translate }}
    </button>

    <button
      *ngIf="!(gridService.readonly$ | async)"
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.delete' | translate }}"
      [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroup$.getValue())"
      (click)="gridService.execute('delete', gridService.selectedGroup$.getValue())"
      data-test="delete"
    >
      <i class="bi bi-trash3" aria-hidden="true"></i>
    </button>
  </div>

  <button
    type="button"
    class="btn btn-default ms-auto"
    (click)="gridService.execute('setUserView')"
    title="{{ 'shared.actions.setView' | translate }}"
  >
    <i class="bi bi-gear" aria-hidden="true"></i>
  </button>
</div>
