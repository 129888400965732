<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

<div class="toolbar-container pt-3" [hidden]="isLoading" [formGroup]="form">
  <wp-date-period-box
    formControlName="period"
    style="width: 325px"
    placeholder="{{ 'shared.teamWorkOverview.filterPeriodPh' | translate }}"
  >
  </wp-date-period-box>

  <div class="form-group">
    <div class="btn-group btn-group-toggle" radioGroup name="radioBasic" formControlName="type">
      <label radioLabel class="btn-default trim fw-normal">
        <input radioButton type="radio" value="utilization" />{{
          'shared.teamWorkOverview.typeUtilization' | translate
        }}
      </label>
      <label radioLabel class="btn-default trim fw-normal">
        <input radioButton type="radio" value="clients" />{{
          'shared.teamWorkOverview.typeClients' | translate
        }}
      </label>
      <label radioLabel class="btn-default trim fw-normal">
        <input radioButton type="radio" value="projects" />{{
          'shared.teamWorkOverview.typeProjects' | translate
        }}
      </label>
      <label radioLabel class="btn-default trim fw-normal">
        <input radioButton type="radio" value="activities" />{{
          'shared.teamWorkOverview.typeActivities' | translate
        }}
      </label>
    </div>
  </div>
</div>

<div class="container-fluid" [hidden]="isLoading">
  <div class="row">
    <div class="col-6">
      <div class="text-body-secondary text-center" *ngIf="isEmptyData">
        {{ 'shared.teamWorkOverview.noDataVisualization' | translate }}
      </div>
      <div id="chartContainer" [hidden]="isEmptyData"></div>
    </div>

    <div class="col-6">
      <table class="table table-striped">
        <thead>
          <tr>
            <th style="width: 15px"></th>
            <th>{{ 'shared.teamWorkOverview.columnTitleName' | translate }}</th>
            <th style="width: 130px">
              {{ 'shared.teamWorkOverview.columnTitleValue' | translate }}
            </th>
            <th style="width: 130px">
              {{ 'shared.teamWorkOverview.columnTitlePercentage' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            style="cursor: pointer"
            (click)="selectSlice(row)"
            *ngFor="let row of rows"
            [ngClass]="{ selected: selectedSector === row.name }"
          >
            <td [ngStyle]="{ 'background-color': row.color }"></td>
            <td class="trim" title="{{ row.name }}">{{ row.name }}</td>
            <td title="{{ row.value | wpWork }}">{{ row.value | wpWork }}</td>
            <td title="{{ row.percentage | percent }}">{{ row.percentage | percent }}</td>
          </tr>
          <tr *ngIf="isEmptyData">
            <td colspan="3">
              <div class="text-body-secondary text-center">
                {{ 'shared.teamWorkOverview.noDataRows' | translate }}
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td>{{ total | wpWork }}</td>
            <td>100%</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
