<div class="resize-handle-left"></div>
<div class="resize-handle-right"></div>

<div
  class="label"
  id="entry-{{ booking.id }}"
  (mousedown)="dragEvent($event)"
  (mouseup)="dragEvent($event)"
  (mouseleave)="dragEvent($event)"
>
  <div class="project" id="project-{{ booking.id }}">
    <i
      *ngIf="
        bookingService.resourceRequestId &&
        bookingService.resourceRequestId === booking.resourceRequest?.id
      "
      class="bi bi-star"
      aria-hidden="true"
    ></i>
    <span
      id="project-name-{{ booking.id }}"
      [ngClass]="!booking.isTimeOff && !booking.isOther ? 'project-name' : ''"
      (click)="openProjectPopup(booking.id)"
    >
      {{ booking.isTimeOff ? ('shared.planner.rowTimeOff' | translate) : booking.project?.name }}
    </span>
  </div>
  <div class="hours">{{ booking.bookedHours | wpWork }}</div>
</div>
