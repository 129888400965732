import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { camelCase } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/notification.service';
import { FormHelper } from 'src/app/shared/helpers/form-helper';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { ProjectRevenueEstimateRequest } from 'src/app/shared/models/entities/projects/project-revenue-estimate-request.model';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ProjectBillingType } from 'src/app/shared/models/enums/project-billing-type';
import {
  RevenueEstimateMethod,
  RevenueEstimateMethods,
} from 'src/app/shared/models/enums/revenue-estimate-calculation-method.enum';
import { RevenueEstimateFrequencies } from 'src/app/shared/models/enums/revenue-extimates-frequency.enum';
import { Exception } from 'src/app/shared/models/exception';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';

@Component({
  selector: 'tmt-revenue-estimates-modal',
  templateUrl: './revenue-estimates-modal.component.html',
  styleUrls: ['./revenue-estimates-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RevenueEstimatesModalComponent implements OnInit, OnDestroy {
  @Input() projectId: string;
  @Input() projectVersion: ProjectVersion;
  @Input() projectCurrencyCode: string;
  @Input() billingType: ProjectBillingType;

  /** Saving data state. */
  public saving$ = new BehaviorSubject<boolean>(false);

  /** Revenue estimate methods enum. */
  public revenueEstimateMethods = RevenueEstimateMethod;

  public form = this.fb.group({
    fixedAmount: [null, Validators.required],
    targetProfitability: [null, Validators.required],
    accrualFrequency: [null, Validators.required],
    calculationMethod: [null, Validators.required],
    task: [null, Validators.required],
    isDetailedByStages: false,
    dateStart: [null],
    dateFinish: [null],
  });

  /** Estimate revenue frequency values. */
  public get frequencies() {
    return this._frequencies;
  }

  /** Estimate revenue calculation methods values. */
  public get calculationMethods() {
    return this._calculationMethods;
  }

  /** Selected calculation method. */
  public get calculationMethod() {
    return this.form.controls.calculationMethod.value?.id;
  }

  private _frequencies: Array<NamedEntity>;
  private _calculationMethods: Array<NamedEntity>;

  private calculationMethodsControls = {
    [RevenueEstimateMethod.Fixed]: {
      enable: ['task', 'fixedAmount'],
      disable: ['isDetailedByStages', 'targetProfitability'],
    },
    [RevenueEstimateMethod.BillingRate]: {
      enable: ['isDetailedByStages'],
      disable: ['task', 'targetProfitability', 'fixedAmount'],
    },
    [RevenueEstimateMethod.TargetProfitability]: {
      enable: ['targetProfitability', 'isDetailedByStages'],
      disable: ['task', 'fixedAmount'],
    },
  };

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private notification: NotificationService,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initVars();
    this.initSubscriptions();

    FormHelper.controlDatePair(
      this.form,
      'dateStart',
      'dateFinish',
      takeUntil(this.destroyed$),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * Modal Cancel/Cross button click event handler.
   * Closes the active modal.
   * */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  /**
   * Submit modal handler.
   * Sends data for revenue estimation and closes modal.
   * */
  public ok() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.saving$.next(true);

    const formValue = this.form.value;

    const revenueEstimateRequest = {
      accrualFrequency: formValue.accrualFrequency.id,
      calculationMethod: formValue.calculationMethod.id,
      dateStart: formValue.dateStart,
      dateFinish: formValue.dateFinish,
      fixedAmount: formValue.fixedAmount,
      isDetailedByStages: formValue.isDetailedByStages,
      targetProfitability: formValue.targetProfitability,
      taskId: formValue.task?.id,
    } as ProjectRevenueEstimateRequest;

    this.versionDataService
      .projectCollectionEntity(
        this.versionCardService.projectVersion,
        this.projectId,
      )
      .action('CalculateEstimatedRevenue')
      .execute(revenueEstimateRequest)
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'projects.projects.revenues.messages.success',
          );
          this.activeModal.close();
          this.saving$.next(false);
          this.cdr.detectChanges();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.saving$.next(false);
          this.cdr.detectChanges();
        },
      });
  }

  /**
   * Inits variables.
   * */
  private initVars(): void {
    // Init enum select items.
    const enumValueKeyPrefix = 'projects.projects.revenues.props.';
    this._calculationMethods = this.getEnumSelectItems(
      RevenueEstimateMethods,
      `${enumValueKeyPrefix}calculationMethod.values.`,
    );

    if (this.billingType.code === ProjectBillingType.fixedBid.code) {
      this._calculationMethods = this._calculationMethods.filter(
        (method) => method.id !== RevenueEstimateMethod.BillingRate,
      );
    }

    this._frequencies = this.getEnumSelectItems(
      RevenueEstimateFrequencies,
      `${enumValueKeyPrefix}frequency.values.`,
    );
  }

  private initSubscriptions() {
    this.form.controls.calculationMethod.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.calculationMethodChangeHandler(value.id);
      });
    this.form.controls.task.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((task) => {
        this.form.patchValue({
          dateStart: task.startDate,
          dateFinish: task.endDate,
        });
      });
  }

  private calculationMethodChangeHandler(
    calculationMethod: RevenueEstimateMethod,
  ) {
    this.calculationMethodsControls[calculationMethod].enable.forEach(
      (controlName) => {
        this.form.controls[controlName].enable({ emitEvent: false });
      },
    );
    this.calculationMethodsControls[calculationMethod].disable.forEach(
      (controlName) => {
        this.form.controls[controlName].disable({ emitEvent: false });
      },
    );

    if (calculationMethod === RevenueEstimateMethod.Fixed) {
      this.form.controls.dateStart.setValidators(Validators.required);
      this.form.controls.dateFinish.setValidators(Validators.required);
    } else {
      this.form.controls.dateStart.clearValidators();
      this.form.controls.dateFinish.clearValidators();
    }

    this.form.controls.dateStart.updateValueAndValidity();
    this.form.controls.dateFinish.updateValueAndValidity();

    this.cdr.detectChanges();
  }

  private projectPeriodChangeHandler(value) {
    if (value) {
      this.form.controls.dateStart.disable();
      this.form.controls.dateFinish.disable();
    } else {
      this.form.controls.dateStart.enable();
      this.form.controls.dateFinish.enable();
    }
  }

  /**
   * Gets transformed enum values for select list.
   *
   * @param enumValues Enum values.
   * @param keyPrefix Translation key prefix.
   * @returns Transformed enum values.
   * */
  private getEnumSelectItems(
    enumValues: Array<any>,
    keyPrefix: string,
  ): Array<NamedEntity> {
    return enumValues.map((e) => ({
      id: e,
      name: this.translate.instant(`${keyPrefix}${camelCase(e)}`),
    }));
  }
}
