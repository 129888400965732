<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title" *ngIf="!form.value.project">
      {{ 'resources.booking.entryForm.header' | translate }}
    </h3>
    <h3 class="modal-title" *ngIf="form.value.project">
      {{ form.value.project.name }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>{{ 'resources.booking.entryForm.props.period' | translate }}</label>
          <div class="align-items-center d-flex">
            <wp-date-box formControlName="from"></wp-date-box>
            <span class="mx-2">&mdash;</span>
            <wp-date-box formControlName="to"></wp-date-box>
          </div>
        </div>

        <div class="form-group" style="width: 500px">
          <label>{{ 'resources.booking.entryForm.props.project' | translate }}</label>
          <wp-select-box
            collection="Projects"
            [query]="projectsQuery"
            formControlName="project"
            placeholder="{{ 'resources.booking.entryForm.props.project' | translate }}"
          ></wp-select-box>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>{{ 'resources.booking.entryForm.props.resource' | translate }}</label>
          <div class="trim avatar-cell" title="{{ resource.name }}" style="line-height: 34px">
            <img class="avatar-bg" [src]="resource.id | avatar: 60" />{{ resource.name }}
          </div>
        </div>

        <div class="form-group">
          <label>{{ 'resources.booking.entryForm.props.type' | translate }}</label>
          <div class="clearfix"></div>
          <div class="btn-group btn-group-toggle" radioGroup formControlName="type">
            <label radioLabel class="btn-default fw-normal">
              <input
                radioButton
                type="radio"
                value="Soft"
                [disabled]="!actionsAllowed.switchToSoftAllowed"
              />Soft
            </label>
            <label radioLabel class="btn-default fw-normal">
              <input
                radioButton
                type="radio"
                value="Hard"
                [disabled]="!actionsAllowed.switchToHardAllowed"
              />Hard
            </label>
          </div>
        </div>

        <div class="form-group" *ngIf="bookingEntry.resourceRequest">
          <label>{{ 'resources.booking.entryForm.props.resourceRequest' | translate }}</label>
          <a
            target="_blank"
            class="pt-1 d-block"
            uiSref="resourceRequest"
            [uiParams]="{
              entityId: bookingEntry.resourceRequest.id,
              navigation: 'projects.allRequests'
            }"
            >{{ bookingEntry.resourceRequest.name }}</a
          >
        </div>
      </div>
    </div>

    <hr />
    <div class="align-items-center d-flex">
      <div class="form-group pe-4" style="width: 350px">
        <label>{{ 'resources.booking.entryForm.props.method' | translate }}</label>
        <wp-select-box
          [allowNull]="false"
          [values]="planningMethods"
          formControlName="planningMethod"
        ></wp-select-box>
      </div>
      <div
        class="form-group pe-4"
        *ngIf="
          form.value.planningMethod?.id === planningMethod.Evenly ||
          form.value.planningMethod?.id === planningMethod.FrontLoad
        "
      >
        <label class="text-bold">{{
          'resources.booking.entryForm.props.requiredHours' | translate
        }}</label>
        <wp-number-box
          type="work"
          formControlName="requiredHours"
          [min]="0"
          [max]="2920"
          [allowNull]="false"
        ></wp-number-box>
      </div>
      <div
        class="form-group pe-4"
        *ngIf="form.value.planningMethod?.id === planningMethod.SchedulePercent"
      >
        <label class="text-bold">{{
          'resources.booking.entryForm.props.schedulePercent' | translate
        }}</label>
        <wp-number-box
          type="percent"
          formControlName="requiredSchedulePercent"
          [min]="0"
          [max]="1000"
          [allowNull]="false"
        ></wp-number-box>
      </div>
      <div class="form-group">
        <label class="text-bold">{{
          'resources.booking.entryForm.props.bookedHours' | translate
        }}</label>
        <wp-number-box type="work" formControlName="bookedHours"></wp-number-box>
      </div>

      <button
        class="btn btn-default ms-auto"
        (click)="bookingEntryFormService.updatePreview()"
        *ngIf="form.value.planningMethod?.id != planningMethod.Manual && !readonly"
      >
        <i class="bi bi-arrow-clockwise"></i>&nbsp;{{
          'resources.booking.entryForm.actions.updatePreview' | translate
        }}
      </button>
    </div>

    <wp-booking-entry-form-details [booking]="bookingEntry" [form]="form" [mode]="mode">
    </wp-booking-entry-form-details>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="!readonly && !bookingDataService.isReadonlyMode()"
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="ok()"
    >
      <span *ngIf="mode === 'create'"> {{ 'shared.actions.create' | translate }}</span>
      <span *ngIf="mode === 'edit'"> {{ 'shared.actions.save' | translate }}</span>
    </button>

    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
