import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Group } from 'src/app/shared/models/entities/settings/group.model';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';

@Injectable()
export class GroupCardService {
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  private groupNameSubject = new BehaviorSubject<string>(null);
  public groupName$ = this.groupNameSubject.asObservable();

  constructor(
    @Inject('entityId') private entityId: string,
    private navigationService: NavigationService,
    private data: DataService,
    private notification: NotificationService,
  ) {
    this.loadCard();
  }

  public loadCard() {
    this.state$.next(CardState.Loading);
    this.load();
  }

  public load() {
    const query = {
      select: ['name'],
    };

    this.data
      .collection('Groups')
      .entity(this.entityId)
      .get<Group>(query)
      .subscribe({
        next: (group: Group) => {
          this.state$.next(CardState.Ready);

          this.navigationService.addRouteSegment({
            id: group.id,
            title: group.name,
          });

          this.groupNameSubject.next(group.name);
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          this.notification.error(error.message);
        },
      });
  }

  public updateName(value: any) {
    this.groupNameSubject.next(value);
  }
}
