import { Injectable, Injector } from '@angular/core';
import { DateTime, DurationLike, Interval } from 'luxon';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { ScheduleNavigationContext } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-context.enum';
import {
  Slot,
  SlotGroup,
} from 'src/app/shared-features/schedule-navigation/models/slot.model';
import {
  getUnitFromPlanningScale,
  PlanningScale,
} from 'src/app/shared/models/enums/planning-scale.enum';
import { ProjectCardService } from '../../../core/project-card.service';
import { DateValue } from '../../models/rbc-data.model';
import {
  RbcGroupType,
  RbcSectionType,
  RbcViewGroup,
} from '../../models/rbc-view.model';
import { RbcSettings } from '../../models/rbc.settings';
import { ProjectRbcCalendarDataService } from './project-rbc-calendar-data.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { ProjectRbcCalendarSlotInfoService } from './project-rbc-calendar-slot-info.service';
import { AppService } from 'src/app/core/app.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectVersionCardService } from '../../../core/project-version-card.service';
import { RevenueEstimatesModalComponent } from 'src/app/projects/card/project-rbc/project-rbc-calendar/revenue-estimates-modal/revenue-estimates-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { ProjectBillingMode } from 'src/app/shared/models/enums/project-billing-mode.enum';

@Injectable()
export class ProjectRbcCalendarService {
  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  private toggleSubject = new Subject<string>();
  public toggle$ = this.toggleSubject.asObservable();

  public loading$ = new BehaviorSubject<boolean>(true);
  public frameLoading$ = new BehaviorSubject<boolean>(false);

  public leftTableWidth = 400;
  public rightTableWidth;

  public slots: Slot[];
  public slotGroups: SlotGroup[];

  private project: Project;

  private subscriptions: Subscription[] = [];
  private interval: Interval;
  private forecastBreakpoint: string;
  private settings: RbcSettings;

  private _readonly: boolean;
  public get readonly(): boolean {
    return this._readonly;
  }

  public get planningScale(): PlanningScale {
    return this.settings.planningScale;
  }
  public get displayedOthers(): Record<RbcSectionType, RbcGroupType[]> {
    return (
      this.settings.displayedOthers ??
      ({} as Record<RbcSectionType, RbcGroupType[]>)
    );
  }

  private allSectionTypes = [
    RbcSectionType.revenue,
    RbcSectionType.billing,
    RbcSectionType.collection,
  ];

  constructor(
    private dataService: ProjectRbcCalendarDataService,
    private navigationService: ScheduleNavigationService,
    private localConfigService: LocalConfigService,
    private projectService: ProjectCardService,
    private versionCardService: ProjectVersionCardService,
    private slotInfoService: ProjectRbcCalendarSlotInfoService,
    private blockUI: BlockUIService,
    private freezeTableService: FreezeTableService,
    private appService: AppService,
    private injector: Injector,
    private modal: NgbModal,
  ) {
    this.subscriptions.push(
      this.projectService.reloadTab$.subscribe(() => this.reload()),
      this.slotInfoService.changes$.subscribe((sectionTypes) => {
        if (
          this.projectService.project.billingEstimationSettings.mode ===
          ProjectBillingMode.automatic
        ) {
          sectionTypes = this.allSectionTypes;
        }

        this.load(sectionTypes, true);
      }),
    );

    this.forecastBreakpoint = ProjectVersionUtil.getForecastBreakpoint(
      this.versionCardService.projectVersion,
      this.appService.session,
    );
  }

  /**
   * Toggles group presence in calendar
   *
   * @param id - group id
   */
  public toggleGroup(id: string) {
    this.toggleSubject.next(id);
  }

  /**
   * Show/hide group other data
   *
   * @param group - group to show/hide other from
   */
  public toggleGroupOther(group: RbcViewGroup) {
    const updateOthersInSettings = (
      others: Record<RbcSectionType, RbcGroupType[]>,
    ) => {
      this.settings.displayedOthers = others;
      const settings = this.localConfigService.getConfig(RbcSettings);
      settings.displayedOthers = others;
      this.localConfigService.setConfig(RbcSettings, settings);
    };

    const section = this.dataService.getSectionByGroup(group);
    const displayedOthers =
      this.settings.displayedOthers ??
      ({} as Record<RbcSectionType, RbcGroupType[]>);
    const sectionGroups = displayedOthers[section.type] ?? [];
    if (sectionGroups.includes(group.type)) {
      const indexToRemove = sectionGroups.indexOf(group.type);
      sectionGroups.splice(indexToRemove, 1);
      displayedOthers[section.type] = sectionGroups;

      updateOthersInSettings(displayedOthers);
      this.changesSubject.next();
      return;
    }
    sectionGroups.push(group.type);
    displayedOthers[section.type] = sectionGroups;

    updateOthersInSettings(displayedOthers);
    this.changesSubject.next();
  }

  /**
   * Inits service's navigation, subscriptions and loads calendar
   */
  public init() {
    this.navigationService.init(ScheduleNavigationContext.Rbc);

    this.subscriptions.push(
      this.projectService.project$.subscribe((project) => {
        this.project = project;
        this._readonly =
          !this.versionCardService.projectVersion.editAllowed ||
          !project.revenueEstimateEditAllowed ||
          !project.financeViewAllowed ||
          !this.appService.checkFeature(Feature.finance);
      }),
    );

    this.subscriptions.push(
      this.navigationService.next$.subscribe(() => {
        this.loadFrame('right');
      }),
      this.navigationService.previous$.subscribe(() => {
        this.loadFrame('left');
      }),
      this.navigationService.jump$.subscribe((date) => {
        this.reload(date);
      }),
      this.navigationService.planningScale$.subscribe(() => {
        this.reload();
      }),
    );

    this.reload();
  }

  /**
   * Reloads whole calendar
   *
   * @param toDate - data to which calendar should transition
   */
  public reload(toDate?: DateTime) {
    this.settings = this.localConfigService.getConfig(RbcSettings);
    this.interval = this.navigationService.getInterval(
      this.settings.planningScale,
      toDate,
    );
    this.updateDates();
    this.load();
  }

  /**
   * Loads calendar frame
   *
   * @param direction - direction to load frame from
   * */
  public loadFrame(direction: 'left' | 'right') {
    this.frameLoading$.next(true);
    this.blockUI.start();

    let shift: DurationLike;

    switch (this.settings.planningScale) {
      case PlanningScale.Day:
        shift = { weeks: 2 };
        break;
      case PlanningScale.Week:
        shift = { weeks: 10 };
        break;
      case PlanningScale.Month:
        shift = { month: 5 };
        break;
      case PlanningScale.Quarter:
        shift = { year: 1 };
        break;
      case PlanningScale.Year:
        shift = { year: 2 };
        break;
    }

    let loadingInterval =
      direction === 'left'
        ? Interval.fromDateTimes(
            this.interval.start.minus(shift),
            this.interval.start.minus({ days: 1 }),
          )
        : Interval.fromDateTimes(
            this.interval.end.plus({ days: 1 }),
            this.interval.end.plus(shift),
          );

    this.interval =
      direction === 'left'
        ? this.interval.set({
            start: this.interval.start.minus(shift),
          })
        : this.interval.set({
            end: this.interval.end.plus(shift),
          });

    if (
      this.settings.planningScale === PlanningScale.Month &&
      direction === 'right'
    ) {
      loadingInterval = loadingInterval.set({
        end: loadingInterval.end.endOf('month'),
      });
      this.interval = this.interval.set({
        end: this.interval.end.endOf('month'),
      });
    }

    this.dataService
      .loadFrame(loadingInterval, this.settings.planningScale)
      .then(() => {
        this.updateDates();

        this.frameLoading$.next(false);
        this.blockUI.stop();
        this.changesSubject.next();

        setTimeout(() => {
          this.freezeTableService.disableMutationObserver();
          if (direction === 'left') {
            this.freezeTableService.scrollToLeft();
          } else {
            this.freezeTableService.scrollToRight();
          }

          setTimeout(() => {
            this.freezeTableService.enableMutationObserver();
          }, 500);
        }, 10);
      });
  }

  /**
   * Fills and returns slots
   *
   * @param data - DateValue list
   * */
  public getFilledSlots(data: DateValue[] = []): DateValue[] {
    const filledSlots = [];

    this.slots.forEach((slot) => {
      const newSlot = {
        id: slot.id,
        date: null,
        amount: 0,
      };

      data.forEach((d) => {
        if (d.date === slot.date.toISODate()) {
          newSlot.amount = d.amount;
        }
      });

      newSlot.date = slot.date.toISODate();
      filledSlots.push(newSlot);
    });

    return filledSlots;
  }

  /**
   * Determines and returns slot width
   *
   * @param planningScale - Not required. If not present, service settings are used
   * */
  public getSlotWidth(planningScale?: PlanningScale): number {
    if (!planningScale) {
      planningScale = this.settings.planningScale;
    }

    switch (planningScale) {
      case PlanningScale.Day:
        return 90;
      case PlanningScale.Week:
        return 90;
      case PlanningScale.Month:
        return 90;
      case PlanningScale.Quarter:
        return 120;
      case PlanningScale.Year:
        return 90;
    }
  }

  /**
   * Determines and returns calendar width
   *
   * @param slotWidth - Not required. If not present, service's getSlotWidth() is used
   * @param countOfSlots - Not required. If not present, service's slots are used
   */
  public getTableWidth(slotWidth?: number, countOfSlots?: number): number {
    if (!slotWidth) {
      slotWidth = this.getSlotWidth();
    }
    if (!countOfSlots) {
      countOfSlots = this.slots.length;
    }

    return slotWidth * countOfSlots;
  }

  /**
   * Determines whether it is allowed to edit entry or not
   *
   * @param group - group which entry is from
   * @param entry - entry itself
   */
  public isSlotEditable(group: RbcViewGroup, entry: DateValue): boolean {
    const isEstimate = group.type === RbcGroupType.estimate;
    const isForecast = group.type === RbcGroupType.forecast;
    const entryEndDate = this.getSlotEndDate(entry);
    const isPastBreakpoint = entryEndDate >= this.forecastBreakpoint;
    return isEstimate || (isForecast && isPastBreakpoint);
  }

  /**
   * Determines and reload entry end date
   *
   * @param entry - entry which end date is determined
   */
  public getSlotEndDate(entry: DateValue): string {
    const date = DateTime.fromISO(entry.date);
    const unit = getUnitFromPlanningScale(this.planningScale);
    return date.endOf(unit).toISODate();
  }

  public openRevenueEstimatesModal() {
    const ref = this.modal.open(RevenueEstimatesModalComponent, {
      injector: this.injector,
    });
    const instance = ref.componentInstance as RevenueEstimatesModalComponent;

    instance.projectId = this.dataService.projectId;
    instance.projectCurrencyCode = this.project.currency.alpha3Code;
    instance.projectVersion = this.versionCardService.projectVersion;
    instance.billingType = this.project.billingType;

    ref.result.then(
      () => {
        this.reload();
      },
      () => null,
    );
  }

  /**
   * Disposes all subscriptions
   * */
  public dispose() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /**
   * Loads data for whole calendar
   *
   * @param sectionTypes - section types to load
   * @param silent - determines if should block UI
   */
  private load(sectionTypes?: RbcSectionType[], silent = false) {
    if (silent) {
      this.blockUI.start();
    } else {
      this.loading$.next(true);
    }

    this.dataService
      .loadSections(this.interval, this.settings.planningScale, sectionTypes)
      .then(
        () => {
          this.changesSubject.next();
          if (silent) {
            this.blockUI.stop();
          } else {
            this.loading$.next(false);
          }
        },
        () => null,
      );
  }

  /**
   * Reloads view dates
   * */
  private updateDates() {
    const slotInfo = this.navigationService.getSlots(
      this.interval,
      this.settings.planningScale,
    );

    this.slotGroups = slotInfo.groups;
    this.slots = slotInfo.slots;

    this.rightTableWidth = this.getTableWidth();
  }
}
