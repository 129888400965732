import { Navigation } from '../models/navigation/navigation';
import { AppService } from '../../core/app.service';
import { Feature } from '../models/enums/feature.enum';
import { TIMESHEET_LIST } from '../lists/timesheet.list';

export const TEAM_APP_NAVIGATION: Navigation = {
  name: 'team',
  applicationTitle: 'team.navigation.header',
  groups: [
    {
      name: 'timesheets',
      header: 'team.navigation.timesheets.header',
      items: [
        {
          name: 'team.timesheetsAll',
          state: 'timesheets',
          header: 'team.navigation.timesheets.all.header',
          hint: 'team.navigation.timesheets.all.hint',
          stateParams: {
            view: 'team-all',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.timesheets),
        },
        {
          name: 'team.timesheetsExpired',
          state: 'timesheets',
          header: 'team.navigation.timesheets.expired.header',
          hint: 'team.navigation.timesheets.expired.hint',
          stateParams: {
            view: 'team-expired',
          },
          indicator: {
            hint: 'my.navigation.timesheets.expired.indicator',
            list: TIMESHEET_LIST,
            viewName: 'team-expired',
          },
        },
      ],
    },
    {
      name: 'timeOff',
      header: 'team.navigation.timeOff.header',
      items: [
        {
          name: 'team.timeOffRequestsAll',
          state: 'timeOffRequests',
          header: 'team.navigation.timeOff.all.header',
          hint: 'team.navigation.timeOff.all.hint',
          stateParams: {
            view: 'team-all',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.timeOff),
        },
        {
          name: 'team.timeOffSchedule',
          state: 'timeOffSchedule',
          header: 'team.navigation.timeOff.schedule.header',
          hint: 'team.navigation.timeOff.schedule.hint',
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.timeOff),
        },
        {
          name: 'team.timeOffBalanceEntriesAll',
          state: 'timeOffBalanceEntries',
          header: 'team.navigation.timeOff.balance.header',
          hint: 'team.navigation.timeOff.balance.hint',
          entityType: 'TimeOffBalanceEntry',
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.timeOff),
          stateParams: {
            view: 'all',
          },
        },
      ],
    },
    {
      name: 'expenses',
      header: 'team.navigation.expenses.header',
      items: [
        {
          name: 'team.expensesRequestsAll',
          state: 'expensesRequests',
          header: 'team.navigation.expenses.all.header',
          hint: 'team.navigation.expenses.all.hint',
          stateParams: {
            view: 'team-all',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.expenses),
        },
      ],
    },
    {
      name: 'team',
      header: 'team.navigation.team.header',
      items: [
        {
          name: 'team.employees',
          state: 'employees',
          header: 'team.navigation.team.employees.header',
          hint: 'team.navigation.team.employees.hint',
        },
        {
          name: 'team.certificates',
          state: 'certificates',
          header: 'team.navigation.team.certificates.header',
          hint: 'team.navigation.team.certificates.hint',
          stateParams: {
            view: 'all',
          },
          entityType: 'Certificate',
        },
      ],
    },
  ],
};
