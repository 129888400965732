import { Injectable } from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormGroupService {
  /**
   * Sets/clears the Form control validators, updates the control value, validity and state depending on the validators passed.
   *
   * @param ctrl The Form control.
   * @param validators The Validators to set. If null or empty, they will be cleared.
   * @param clearValue The flag to clear the control value. Will not be cleared by default.
   * */
  public toggleFormControlValidators(
    ctrl: UntypedFormControl,
    validators?: ValidatorFn | ValidatorFn[],
    clearValue = false,
  ) {
    if (!validators || (Array.isArray(validators) && validators.length === 0)) {
      ctrl.clearValidators();
      ctrl.updateValueAndValidity();
      ctrl.markAsUntouched();
      ctrl.markAsPristine();
    } else {
      ctrl.setValidators(validators);
      ctrl.updateValueAndValidity();
      ctrl.markAsUntouched();
      ctrl.markAsPristine();
    }

    if (clearValue) {
      ctrl.setValue(null);
    }
  }
}
