export enum WorkflowFunctionType {
  start = 'Start',
  task = 'Task',
  finish = 'Finish',
  condition = 'Condition',
  notification = 'Notification',
}

export enum WorkflowTransitionConditionType {
  all = 'All',
  first = 'First',
  elseAfterAll = 'ElseAfterAll',
}
