import { Injectable, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Guid } from 'src/app/shared/helpers/guid';
import { StringHelper } from 'src/app/shared/helpers/string-helper';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';

@Injectable()
export class TaskAssignmentsService implements OnDestroy {
  public task: ProjectTask;
  public readonly: boolean;

  public allTeamCode = 'allTeam';

  private destroyed$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Returns assignment form group for table.
   *
   * @returns assignment form group
   */
  public getAssignmentFormGroup(): UntypedFormGroup {
    const formGroup = this.fb.group({
      id: Guid.generate(),
      isAllTeamRole: false,
      isUnassigned: false,
      projectTeamMember: [null, Validators.required],
      units: 1,
    });

    formGroup.controls.projectTeamMember.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value.resource?.resourceType === ResourceType.department) {
          formGroup.controls.units.setValue(null, { emitEvent: false });
          formGroup.controls.units.disable({ emitEvent: false });
        } else {
          formGroup.controls.units.setValue(1, { emitEvent: false });
          formGroup.controls.units.enable({ emitEvent: false });
        }
        if (value.isActive === false) {
          value.name = StringHelper.getCrossedOutString(value.name);
        }
      });

    return formGroup;
  }
}
