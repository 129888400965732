<form [formGroup]="form">
  <div class="row">
    <div class="col-4">
      <div class="form-group">
        <label>{{ 'shared.props.name' | translate }}</label>
        <wp-text-box
          formControlName="name"
          placeholder="{{ 'shared.props.name' | translate }}"
        ></wp-text-box>
      </div>

      <div class="form-group">
        <label>{{ 'analytics.dashboards.widgetSettings.properties.type.label' | translate }}</label>
        <wp-select-box
          [values]="types"
          formControlName="type"
          [allowNull]="false"
          placeholder="{{
            'analytics.dashboards.widgetSettings.properties.type.placeholder' | translate
          }}"
        >
        </wp-select-box>
      </div>

      <button class="btn btn-link btn-action" tmtDocsArticle="analytics/widget-types">
        {{ 'analytics.dashboards.widgetSettings.helpArticle.label' | translate }}
        <i class="bi bi-question-circle" aria-hidden="true"></i>
      </button>
    </div>
    <div class="col-6">
      <wp-widget-value-properties
        *ngIf="form.value.type?.id === 'Value'"
        [form]="form.controls['properties']"
      >
      </wp-widget-value-properties>

      <wp-widget-double-value-properties
        *ngIf="form.value.type?.id === 'DoubleValue'"
        [form]="form.controls['properties']"
      >
      </wp-widget-double-value-properties>

      <wp-widget-pie-properties
        *ngIf="form.value.type?.id === 'Pie'"
        [form]="form.controls['properties']"
      >
      </wp-widget-pie-properties>

      <wp-widget-line-properties
        *ngIf="form.value.type?.id === 'Line'"
        [form]="form.controls['properties']"
      >
      </wp-widget-line-properties>

      <wp-widget-columns-properties
        *ngIf="
          form.value.type?.id === 'Column' ||
          form.value.type?.id === 'StackedColumns' ||
          form.value.type?.id === 'StackedPercentageColumns'
        "
        [form]="form.controls['properties']"
      >
      </wp-widget-columns-properties>

      <wp-widget-frame-properties
        *ngIf="form.value.type?.id === 'Frame'"
        [form]="form.controls['properties']"
      >
      </wp-widget-frame-properties>

      <wp-widget-speedometer-properties
        *ngIf="form.value.type?.id === 'Speedometer'"
        [form]="form.controls['properties']"
      >
      </wp-widget-speedometer-properties>
    </div>
  </div>
</form>
