import { List, LoadingStrategy } from '../models/inner/list';
import {
  GridColumnType,
  GridComponentColumn,
  GridNavigationColumn,
} from '../models/inner/grid-column.interface';
import { TotalType } from '../models/inner/total-type';
import { TimeOffBalanceEntriesTypeCellComponent } from '../components/features/time-off-balance-entries-type-cell/time-off-balance-entries-type-cell.component';

export const TIME_OFF_BALANCE_ENTRIES_LIST: List = {
  version: 2,
  name: 'timeOffBalanceEntries',
  views: [
    {
      name: 'all',
      columns: [
        {
          column: 'type',
          visibleByDefault: true,
          width: 70,
        },
        {
          column: 'number',
          visibleByDefault: true,
          width: 130,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'timeOffType',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'user',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'date',
          width: 130,
          visibleByDefault: true,
        },
        {
          column: 'unit',
          visibleByDefault: true,
          width: 130,
        },
        {
          column: 'value',
          visibleByDefault: true,
          width: 130,
        },
        {
          column: 'mode',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: { column: 'date', reverse: false },
    },
  ],
  columns: [
    <GridComponentColumn>{
      name: 'type',
      fixedWidth: true,
      type: GridColumnType.Component,
      component: TimeOffBalanceEntriesTypeCellComponent,
      header: 'team.timeOffBalanceEntries.list.columns.type.header',
      hint: 'team.timeOffBalanceEntries.list.columns.type.hint',
    },
    <GridNavigationColumn>{
      name: 'number',
      fixedWidth: true,
      state: 'timeOffBalanceEntry',
      type: GridColumnType.Navigation,
      header: 'team.timeOffBalanceEntries.list.columns.number.header',
      hint: 'team.timeOffBalanceEntries.list.columns.number.hint',
      availableTotals: [TotalType.Count],
    },
    {
      name: 'timeOffType',
      type: GridColumnType.String,
      header: 'team.timeOffBalanceEntries.list.columns.timeOffType.header',
      hint: 'team.timeOffBalanceEntries.list.columns.timeOffType.hint',
    },
    {
      name: 'user',
      type: GridColumnType.String,
      header: 'team.timeOffBalanceEntries.list.columns.user.header',
      hint: 'team.timeOffBalanceEntries.list.columns.user.hint',
    },
    <GridComponentColumn>{
      name: 'date',
      fixedWidth: true,
      type: GridColumnType.Date,
      header: 'team.timeOffBalanceEntries.list.columns.date.header',
      hint: 'team.timeOffBalanceEntries.list.columns.date.hint',
    },
    {
      fixedWidth: true,
      name: 'unit',
      type: GridColumnType.String,
      header: 'team.timeOffBalanceEntries.list.columns.unit.header',
      hint: 'team.timeOffBalanceEntries.list.columns.unit.hint',
    },
    <GridComponentColumn>{
      name: 'value',
      fixedWidth: true,
      type: GridColumnType.Decimal,
      header: 'team.timeOffBalanceEntries.list.columns.value.header',
      hint: 'team.timeOffBalanceEntries.list.columns.value.hint',
      availableTotals: [TotalType.Sum],
    },
    {
      name: 'mode',
      type: GridColumnType.String,
      header: 'team.timeOffBalanceEntries.list.columns.mode.header',
      hint: 'team.timeOffBalanceEntries.list.columns.mode.hint',
    },
  ],
  dataColumns: [
    {
      column: 'timeOffType',
      field: 'timeOffType.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'user',
      field: 'user.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'type',
      field: ['type.name', 'type.code'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'unit',
      field: 'timeOffType.unit.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'value',
      field: 'amount',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'number',
      field: 'number',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'date',
      field: 'date',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'mode',
      field: 'mode.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],

  dataCollection: 'TimeOffBalanceEntries',
};
