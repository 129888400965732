<label class="group"> {{ 'settings.permissionSets.granules.groupData' | translate }}</label>
<table class="table table-bordered compact-table" style="table-layout: fixed; width: 750px">
  <thead>
    <tr>
      <th style="width: 250px; vertical-align: middle" rowspan="2">
        {{ 'settings.permissionSets.granules.granular' | translate }}
      </th>
    </tr>
    <tr>
      <th style="text-align: center; vertical-align: middle">
        {{ 'settings.permissionSets.permissions.view' | translate }}
      </th>
      <th style="text-align: center; vertical-align: middle">
        {{ 'settings.permissionSets.permissions.edit' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ 'settings.permissionSets.granules.invoices' | translate }}</td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('Invoice.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('Invoice.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
  </tbody>
</table>
