import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { Comment } from 'src/app/shared-features/comments/model/comment.model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/core/app-config.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import _ from 'lodash';
import { SortDirection } from 'src/app/shared-features/comments/model/sort-direction.enum';
import { Attachment } from 'src/app/shared-features/comments/model/attachment.model';
import { BlockUIService } from 'src/app/core/block-ui.service';

@Injectable()
export class CommentsDataService {
  private currentPage = 0;
  private pageSize = 50;
  private loadedAll = false;
  private loadingPage = false;

  public get allCommentsLoaded(): boolean {
    return this.loadedAll;
  }

  constructor(
    private dataService: DataService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private blockUI: BlockUIService,
  ) {}

  /** Resets pagination settings to default. */
  public resetPagination(): void {
    this.currentPage = 0;
    this.loadedAll = false;
  }

  public deleteComment(
    collection: string,
    entityId: string,
    commentId: string,
  ) {
    return this.dataService
      .collection(collection)
      .entity(entityId)
      .action('DeleteComment')
      .execute({
        commentId,
      })
      .pipe(
        catchError((err: Exception) => {
          this.notificationService.error(err.message);
          return of([]);
        }),
      );
  }

  public deleteAttachment(
    collection: string,
    entityId: string,
    attachmentId: string,
  ) {
    return this.dataService
      .collection(collection)
      .entity(entityId)
      .action('DeleteCommentAttachment')
      .execute({ attachmentId })
      .pipe(
        catchError((err: Exception) => {
          this.notificationService.error(err.message);
          return of([]);
        }),
      );
  }

  /**
   * Gets comments.
   *
   * @param collection Entity name.
   * @param entityId Entity ID.
   * @param showSystemEvents Indicates to load system events for entity (state changes, task activity, etc).
   * @param sortDirection sort direction.
   * @returns comments.
   */
  public getComments(
    collection: string,
    entityId: string,
    showSystemEvents = false,
    sortDirection: SortDirection = SortDirection.oldest,
  ): Observable<Comment[]> {
    if (this.loadedAll || this.loadingPage) {
      return of([]);
    }

    this.loadingPage = true;

    const query = {
      top: this.pageSize,
      skip: this.pageSize * this.currentPage,
      orderBy: [
        `created ${sortDirection === SortDirection.oldest ? 'asc' : 'desc'}`,
      ],
    };

    return this.dataService
      .collection(collection)
      .entity(entityId)
      .function('GetActivityItems')
      .get<Comment[]>({ showSystemEvents: String(showSystemEvents) }, query)
      .pipe(
        tap((comments) => {
          this.loadedAll = comments.length < this.pageSize;
          this.currentPage++;
          this.loadingPage = false;

          comments.forEach((c) => {
            if (c.type === 'WorkflowStarted') {
              c.id += 'WS';
            }

            if (c.type === 'WorkflowStopped') {
              c.id += 'WF';
            }

            // TODO remove it after API fix
            if (c.metadata) {
              Object.keys(c.metadata).forEach((k) => {
                c.metadata[_.camelCase(k)] = c.metadata[k];
              });
            }
          });
        }),
        catchError(() => {
          this.loadingPage = false;
          this.notificationService.errorLocal(
            'shared.comments.notifications.requestError',
          );
          return of([]);
        }),
      );
  }

  /**
   * Gets attachments from all comments.
   *
   * @param collection Entity name.
   * @param entityId Entity ID.
   * @returns attachments.
   */
  public getAttachments(
    collection: string,
    entityId: string,
  ): Observable<Attachment[]> {
    return this.dataService
      .collection(collection)
      .entity(entityId)
      .function('GetAttachments')
      .get<Attachment[]>()
      .pipe(
        catchError((err: Exception) => {
          this.notificationService.error(err.message);
          return of([]);
        }),
      );
  }

  /**
   * Updates comment text and list of mentioned users.
   *
   * @param collection Current entity collection.
   * @param entityId
   * @param commentId
   * @param text comment text value.
   * @param mentionedUserIds list of mentioned users.
   * @returns Observable from `dataService`.
   */
  public editComment(
    collection: string,
    entityId: string,
    commentId: string,
    text: string,
    mentionedUserIds: string[],
  ): Observable<unknown> {
    return this.dataService
      .collection(collection)
      .entity(entityId)
      .action('EditComment')
      .execute({ commentId, text, mentionedUserIds })
      .pipe(
        catchError((err: Exception) => {
          this.notificationService.error(err.message);
          return of([]);
        }),
      );
  }

  /**
   * Uploads attachments.
   *
   * @param collection entity name.
   * @param entityId entity id.
   * @param commentId comment id.
   * @param attachments files to upload.
   * @returns attachments.
   */
  public async uploadAttachments(
    collection: string,
    entityId: string,
    commentId: string,
    attachments: File[],
  ): Promise<Attachment[] | null> {
    try {
      if (!attachments.length) {
        return null;
      }

      this.blockUI.start();

      const attachmentsResult: Attachment[] = [];

      for (const attachment of attachments) {
        const formData = new FormData();
        formData.append('attachment', attachment);
        formData.append('commentId', commentId);

        const result = await firstValueFrom(
          this.dataService
            .collection(collection)
            .entity(entityId)
            .action('UploadCommentAttachment')
            .execute<Attachment>(formData),
        );

        attachmentsResult.push(result);
      }

      this.blockUI.stop();
      return attachmentsResult;
    } catch (error) {
      this.notificationService.error(error.message);
      this.blockUI.stop();
      return null;
    }
  }

  /**
   * Creates new comment and adds attachments to created comment.
   *
   * @param collection Current entity collection.
   * @param entityId
   * @param text comment text value
   * @param attachments list of files.
   * @param mentionedUserIds list of mentioned users.
   * @returns Observable from `dataService`.
   */
  public createComment(
    collection: string,
    entityId: string,
    text: string,
    attachments: File[],
    mentionedUserIds: string[],
  ): Observable<unknown> {
    return this.dataService
      .collection(collection)
      .entity(entityId)
      .action('AddComment')
      .execute({
        text,
        mentionedUserIds,
      })
      .pipe(
        mergeMap((result: Comment) =>
          this.uploadAttachments(collection, entityId, result.id, attachments),
        ),
        catchError((err: Exception) => {
          this.notificationService.error(err.message);
          return of(null);
        }),
      );
  }

  public downloadAttachment(
    collection: string,
    entityId: string,
    attachmentId: string,
  ) {
    const url = `${AppConfigService.config.api.url}/odata/${collection}(${entityId})/GetCommentAttachment(attachmentId=${attachmentId})`;
    return this.httpClient.get(url, {
      responseType: 'blob',
    });
  }
}
