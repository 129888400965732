import { Component, OnInit, Input } from '@angular/core';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ImportStates } from 'src/app/shared/models/entities/settings/import.model';
import { GridColumn } from 'src/app/shared-features/grid2/models/grid-column.interface';

@Component({
  selector: 'wp-import-state-cell',
  templateUrl: './import-state-cell.component.html',
})
export class ImportStateCellComponent implements OnInit, GridTemplateCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() public initialValue: unknown;

  get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  public labelClass: string;

  ngOnInit() {
    this.labelClass = ImportStates.find(
      (as) => as.code === this.control.value.code,
    ).class;
  }
}
