import { List, LoadingStrategy } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared/models/inner/grid-column.interface';

export const LEGAL_ENTITY_LIST: List = {
  version: 2,
  name: 'legalEntity',
  customFieldEntityType: 'LegalEntity',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'code',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'currency',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'isDefault',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      state: 'settings.legalEntity',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      type: GridColumnType.Navigation,
    },
    {
      name: 'description',
      header: 'shared.columns.description',
      hint: 'shared.columns.description',
      type: GridColumnType.String,
    },
    {
      name: 'code',
      header: 'shared.columns.code',
      hint: 'shared.columns.code',
      type: GridColumnType.String,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.Date,
    },
    {
      name: 'isActive',
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.Boolean,
    },
    {
      name: 'isDefault',
      header: 'shared.columns.isDefault.title',
      hint: 'shared.columns.isDefault.hint',
      type: GridColumnType.Boolean,
    },
    {
      name: 'currency',
      header: 'settings.legalEntity.currency.title',
      hint: 'settings.legalEntity.currency.hint',
      type: GridColumnType.String,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'isDefault',
      field: 'isDefault',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'code',
      field: 'code',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'currency',
      field: 'currency.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
  dataCollection: 'LegalEntities',
};
