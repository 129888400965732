import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  Type,
} from '@angular/core';
import { DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { WorkPipe } from 'src/app/shared/pipes/work.pipe';
import { AppService } from 'src/app/core/app.service';
import {
  GridColumn,
  GridColumnType,
  GridCommandColumn,
  GridComponentColumn,
  GridCurrencyColumn,
  GridCurrencyControlColumn,
  GridDateControlColumn,
  GridEntityColumn,
  GridNavigationColumn,
  GridNumberControlColumn,
  GridSelectControlColumn,
  GridStringControlColumn,
  GridUserControlColumn,
} from 'src/app/shared-features/grid2/models/grid-column.interface';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserCellComponent } from './user-cell/user-cell.component';
import { NavigationCellComponent } from './navigation-cell/navigation-cell.component';
import { BooleanControlCellComponent } from './boolean-control-cell/boolean-control-cell.component';
import { BooleanCellComponent } from './boolean-cell/boolean-cell.component';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { StateCellComponent } from './state-cell/state-cell.component';
import { CommandCellComponent } from './command-cell/command-cell.component';
import objectPath from 'object-path';
import { Subject, isObservable } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { GridOrchestratorService } from 'src/app/shared-features/grid2/core/grid-orchestrator.service';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { NgxNl2brPipe } from 'ngx-nl2br';
import { TextBoxComponent } from 'src/app/shared/components/controls/text-box/text-box.component';
import { DateBoxComponent } from 'src/app/shared/components/controls/date-box/date-box.component';
import { UserBoxComponent } from 'src/app/shared/components/controls/user-box/user-box.component';
import { SelectBoxComponent } from 'src/app/shared/components/controls/select-box/select-box.component';
import { NumberBoxComponent } from 'src/app/shared/components/controls/number-box/number-box.component';
import { CurrencyBoxComponent } from 'src/app/shared/components/controls/currency-box/currency-box.component';
import { SelectionType } from 'src/app/shared-features/grid2/models/grid-options.model';
import _ from 'lodash';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { checkChangesDebounceTime } from 'src/app/shared-features/grid2/models/grid-constants';
import { ResourceNavigationCellComponent } from 'src/app/shared-features/grid2/grid-cell/resource-navigation-cell/resource-navigation-cell.component';
import { TranslateService } from '@ngx-translate/core';

/** Ячейка грида. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmt-grid-cell]',
  template: `<div
    *ngComponentOutlet="componentCell; inputs: componentCellInputs"
  ></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellComponent implements OnInit, OnDestroy {
  @Input() column: GridColumn;
  @Input('group') formGroup: UntypedFormGroup;
  @Input() selectionType: SelectionType;
  @Input() get editing(): boolean {
    return this._editing;
  }
  set editing(value: boolean) {
    if (this._editing !== value) {
      this.renderer.removeClass(this.elRef.nativeElement, 'editing-cell');
      this._editing = value;
      if (this.canCellBeEditable()) {
        this.render();

        // Saves table focus after cell rerendering. Necessary for correct keyboard key listens.
        if (
          this.cellOrchestratorService.options.selectionType ===
            SelectionType.range ||
          value ||
          !this.cellOrchestratorService.leftTable?.matches(':focus-within')
        ) {
          this.elRef.nativeElement.parentElement.focus();
        }
      }
    }
  }

  public componentCell: Type<unknown>;
  public componentCellInputs: Record<string, any> = {};

  private _editing = false;

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  /** Last value of cell control. Turned on with the force cell update option. */
  private lastValue: any = null;

  public get row(): any {
    return this.formGroup.getRawValue();
  }

  public get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  public get value(): any {
    return this.formGroup.get(this.column.name).value;
  }

  public textElement: string;

  constructor(
    private app: AppService,
    private renderer: Renderer2,
    private currencyPipe: WpCurrencyPipe,
    private numberPipe: DecimalPipe,
    private percentPipe: PercentPipe,
    private workPipe: WorkPipe,
    private datePipe: DatePipe,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private nl2br: NgxNl2brPipe,
    private cellOrchestratorService: GridOrchestratorService,
    private translateService: TranslateService,
  ) {}

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public ngOnInit(): void {
    if (!this.canCellBeEditable()) {
      this.formGroup.controls[this.column.name].disable({ emitEvent: false });
    }
    this.render();

    if (this.column.forceCellUpdating) {
      this.addForceCellUpdating();
    }
  }

  /** Determines can cell be editable. */
  private canCellBeEditable(): boolean {
    // New logic means that component type cell must regulate control enabling state by itself
    if (this.column.type === GridColumnType.Component) {
      return true;
    }

    //Not control type cells always are not editable
    if (
      [
        GridColumnType.Boolean,
        GridColumnType.Currency,
        GridColumnType.Date,
        GridColumnType.Decimal,
        GridColumnType.Integer,
        GridColumnType.Work,
        GridColumnType.Percent,
        GridColumnType.Label,
        GridColumnType.Navigation,
        GridColumnType.Entity,
        GridColumnType.String,
        GridColumnType.User,
        GridColumnType.State,
        GridColumnType.ResourceNavigation,
        GridColumnType.Command,
        GridColumnType.DateTime,
        GridColumnType.MultilineString,
      ].includes(this.column.type)
    ) {
      return false;
    }

    return true;
  }

  /** Determines is cell control. */
  private isControlType(): boolean {
    return [
      GridColumnType.DateControl,
      GridColumnType.UserControl,
      GridColumnType.SelectControl,
      GridColumnType.StringControl,
      GridColumnType.NumberControl,
      GridColumnType.CurrencyControl,
      GridColumnType.BooleanControl,
    ].includes(this.column.type);
  }

  /** Renders cell. */
  private render(): void {
    this.renderer.removeClass(this.elRef.nativeElement, 'control-cell');

    if (this.textElement) {
      this.renderer.removeChild(this.elRef.nativeElement, this.textElement);
      this.renderer.removeClass(this.elRef.nativeElement, 'trim');
      this.renderer.removeAttribute(this.elRef.nativeElement, 'title');
    }

    if (this.editing) {
      if (
        this.selectionType === SelectionType.range &&
        this.control.enabled &&
        this.canCellBeEditable()
      ) {
        this.renderer.addClass(this.elRef.nativeElement, 'editing-cell');
      }

      switch (this.column.type) {
        case GridColumnType.Component:
          this.renderComponent();
          return;
        case GridColumnType.DateControl:
          this.renderDateControl();
          return;
        case GridColumnType.SelectControl:
          this.renderSelectControl();
          return;
        case GridColumnType.UserControl:
          this.renderUserControl();
          return;
        case GridColumnType.NumberControl:
          this.renderNumberControl();
          return;
        case GridColumnType.CurrencyControl:
          this.renderCurrencyControl();
          return;
        case GridColumnType.StringControl:
          this.renderStringControl();
          return;
        case GridColumnType.BooleanControl:
          this.renderBooleanControl();
          return;
      }
    }

    if (this.column.type === GridColumnType.User) {
      this.renderUser();
      return;
    }

    this.renderPlainValues();
  }

  private renderPlainValues(): void {
    const renderText = (text: string): void => {
      this.textElement = this.renderer.createText(text);
      this.renderer.appendChild(this.elRef.nativeElement, this.textElement);
      this.renderer.addClass(this.elRef.nativeElement, 'trim');
      this.renderer.setAttribute(this.elRef.nativeElement, 'title', text);
    };

    this.componentCell = null;
    this.componentCellInputs = {};
    let text: string = null;

    if (this.column.type === GridColumnType.Component) {
      this.renderComponent(true);
      return;
    }

    if (this.column.type === GridColumnType.ResourceNavigation) {
      this.renderResourceNavigation();
      return;
    }

    if (this.column.type === GridColumnType.UserControl) {
      this.renderUser();
      return;
    }

    if (this.column.type === GridColumnType.Navigation) {
      this.renderNavigation();
      return;
    }

    if (this.column.type === GridColumnType.Command) {
      this.renderCommand();
      return;
    }

    if (this.column.type === GridColumnType.State) {
      this.renderState();
      return;
    }

    if (
      this.column.type === GridColumnType.Boolean ||
      this.column.type === GridColumnType.BooleanControl
    ) {
      this.renderBoolean();
      return;
    }

    if (
      this.column.type === GridColumnType.String ||
      this.column.type === GridColumnType.StringControl
    ) {
      text = this.value;
    }

    if (this.column.type === GridColumnType.MultilineString) {
      this.renderMultilineString();
      return;
    }

    if (this.column.type === GridColumnType.Entity) {
      if (typeof this.value === 'string') {
        const values = (this.column as GridEntityColumn).values;
        if (isObservable(values)) {
          values.pipe(take(1)).subscribe((val: NamedEntity[]) => {
            text = val.find((v) => v.id === this.value).name;
            renderText(text);
          });
        } else {
          text = values.find((v) => v.id === this.value).name;
        }
      } else {
        text = this.value?.name;
      }
    }

    if (this.column.type === GridColumnType.SelectControl) {
      if ((this.column as GridSelectControlColumn).isIdMode) {
        this.renderSelectControl(true);
      }
      text = this.value ? this.value.name : '';
    }

    if (
      this.column.type === GridColumnType.Date ||
      this.column.type === GridColumnType.DateControl
    ) {
      text = this.datePipe.transform(this.value, 'shortDate');
    }

    if (this.column.type === GridColumnType.DateTime) {
      text = this.datePipe.transform(this.value, 'short');
    }

    if (
      (this.column.type === GridColumnType.Decimal ||
        (this.column.type === GridColumnType.NumberControl &&
          (this.column as GridNumberControlColumn).controlType ===
            'percent')) &&
      this.column.contentType === GridColumnType.Percent
    ) {
      const column = this.column as GridNumberControlColumn;
      const precision = column.precision ?? 2;
      text = this.percentPipe.transform(this.value, `1.0-${precision}`);
      this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    } else if (
      this.column.type === GridColumnType.Decimal ||
      (this.column.type === GridColumnType.NumberControl &&
        (this.column as GridNumberControlColumn).controlType === 'decimal')
    ) {
      const column = this.column as GridNumberControlColumn;
      const precision = column.precision ?? 2;
      text = this.numberPipe.transform(this.value, `1.0-${precision}`);
      this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    }

    if (
      this.column.type === GridColumnType.Integer ||
      (this.column.type === GridColumnType.NumberControl &&
        (this.column as GridNumberControlColumn).controlType === 'integer')
    ) {
      text = this.numberPipe.transform(this.value, '1.0-0');
      this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    }

    if (
      this.column.type === GridColumnType.Work ||
      (this.column.type === GridColumnType.NumberControl &&
        (this.column as GridNumberControlColumn).controlType === 'work')
    ) {
      text = this.workPipe.transform(this.value);
      this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    }

    if (this.column.type === GridColumnType.Percent) {
      text = this.percentPipe.transform(this.value);
      this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    }

    if (
      this.column.type === GridColumnType.Currency ||
      this.column.type === GridColumnType.CurrencyControl ||
      (this.column.type === GridColumnType.NumberControl &&
        (this.column as GridNumberControlColumn).controlType === 'currency')
    ) {
      if (_.isObject(this.value)) {
        text = this.currencyPipe.transform(
          this.value['value'],
          this.value['currencyCode'],
        );
      } else {
        const column = this.column as GridCurrencyColumn;
        const currencyCode =
          (column.currencyCodeProperty &&
            objectPath.get(this.row, column.currencyCodeProperty)) ||
          column.currencyCode ||
          this.app.session.configuration.baseCurrencyCode;
        text = this.currencyPipe.transform(this.value, currencyCode);
      }
      this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    }

    if (text) {
      renderText(text);
    }
  }

  /** Renders number control. */
  private renderNumberControl(): void {
    this.componentCell = NumberBoxComponent;

    const column = this.column as GridNumberControlColumn;
    this.componentCellInputs = {
      control: this.control,
      type: column.controlType,
      min: column.min,
      max: column.max,
      precision: column.precision ?? 2,
      autofocus: this.selectionType === SelectionType.range,
      initialValue: this.cellOrchestratorService.initialControlValue,
      propagationMode: column.propagationMode ?? PropagationMode.onInput,
    };
    if (column.allowNull === false) {
      this.componentCellInputs.allowNull = false;
    }

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders currency control. */
  private renderCurrencyControl(): void {
    this.componentCell = CurrencyBoxComponent;

    const column = this.column as GridCurrencyControlColumn;
    const currencyCode =
      (column.currencyCodeProperty &&
        objectPath.get(this.row, column.currencyCodeProperty)) ||
      column.currencyCode ||
      this.app.session.configuration.baseCurrencyCode;

    this.componentCellInputs = {
      control: this.control,
      min: column.min,
      max: column.max,
      currencyCode,
      autofocus: this.selectionType === SelectionType.range,
      initialValue: this.cellOrchestratorService.initialControlValue,
    };
    if (column.allowNull === false) {
      this.componentCellInputs.allowNull = false;
    }
    if (column.isCurrencyEditable === false) {
      this.componentCellInputs.isCurrencyEditable = false;
    }

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders select control. */
  private renderSelectControl(readonly = false): void {
    this.componentCell = SelectBoxComponent;

    const column = this.column as GridSelectControlColumn;
    this.componentCellInputs = {
      control: this.control,
      values: column.values,
      collection: column.collection,
      query: column.query,
      placeholder: column.placeholder
        ? this.translateService.instant(column.placeholder)
        : '',
      autofocus: this.selectionType === SelectionType.range,
      initialValue: this.cellOrchestratorService.initialControlValue,
      isIdMode: column.isIdMode,
      directoryId: column.directoryId,
      readonly,
    };
    if (column.allowNull === false) {
      this.componentCellInputs.allowNull = false;
    }

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders string control. */
  private renderStringControl(): void {
    this.componentCell = TextBoxComponent;

    const column = this.column as GridStringControlColumn;
    this.componentCellInputs = {
      control: this.control,
      placeholder: column.placeholder
        ? this.translateService.instant(column.placeholder)
        : '',
      propagationMode: column.propagationMode ?? PropagationMode.onInput,
      autofocus: this.selectionType === SelectionType.range,
      initialValue: this.cellOrchestratorService.initialControlValue,
    };

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders boolean control. */
  private renderBooleanControl(): void {
    this.componentCell = BooleanControlCellComponent;
    this.componentCellInputs = {
      control: this.control,
      autofocus: this.selectionType === SelectionType.range,
      initialValue: this.cellOrchestratorService.initialControlValue,
    };

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders user control. */
  private renderUserControl(): void {
    this.componentCell = UserBoxComponent;

    const column = this.column as GridUserControlColumn;
    const initialControlValue =
      this.cellOrchestratorService.initialControlValue;
    this.componentCellInputs = {
      control: this.control,
      placeholder: column.placeholder
        ? this.translateService.instant(column.placeholder)
        : '',
      query: column.query,
      initialValue: initialControlValue,
      autofocus:
        this.selectionType === SelectionType.range &&
        initialControlValue === undefined,
    };

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders date control. */
  private renderDateControl(): void {
    this.componentCell = DateBoxComponent;

    const column = this.column as GridDateControlColumn;
    this.componentCellInputs = {
      control: this.control,
      initialValue: this.cellOrchestratorService.initialControlValue,
      autofocus: this.selectionType === SelectionType.range,
    };
    if (column.allowNull === false) {
      this.componentCellInputs.allowNull = false;
    }

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders component cell. */
  private renderComponent(readonlyVersion?: boolean): void {
    const gridViewComponentColumn = this.column as GridComponentColumn;
    const component =
      readonlyVersion && gridViewComponentColumn.readonlyComponent
        ? gridViewComponentColumn.readonlyComponent
        : gridViewComponentColumn.component;

    this.componentCell = component;
    this.componentCellInputs = {
      formGroup: this.formGroup,
      column: this.column,
      initialValue: this.cellOrchestratorService.initialControlValue,
    };

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders user navigation control. */
  private renderResourceNavigation(): void {
    this.componentCell = ResourceNavigationCellComponent;
    this.componentCellInputs = {
      row: this.row,
      column: this.column as GridNavigationColumn,
    };

    this.renderer.setAttribute(
      this.elRef.nativeElement,
      'title',
      this.row.name,
    );
    this.renderer.addClass(this.elRef.nativeElement, 'trim');
  }

  /** Renders user cell. */
  private renderUser(): void {
    this.componentCell = UserCellComponent;
    this.componentCellInputs = {
      row: this.row,
      column: this.column as GridNavigationColumn,
    };
  }

  /** Renders navigation cell. */
  private renderNavigation(): void {
    this.componentCell = NavigationCellComponent;
    this.componentCellInputs = {
      row: this.row,
      column: this.column as GridNavigationColumn,
    };

    this.renderer.setAttribute(
      this.elRef.nativeElement,
      'title',
      this.row[this.column.name],
    );
    this.renderer.addClass(this.elRef.nativeElement, 'trim');
  }

  /** Renders command cell. */
  private renderCommand(): void {
    this.componentCell = CommandCellComponent;
    this.componentCellInputs = {
      row: this.row,
      column: this.column as GridCommandColumn,
    };

    this.renderer.setAttribute(
      this.elRef.nativeElement,
      'title',
      this.row[this.column.name],
    );
  }

  /** Renders state cell. */
  private renderState(): void {
    this.componentCell = StateCellComponent;
    this.componentCellInputs = {
      value: this.value,
      column: this.column,
    };
  }

  /** Renders boolean cell. */
  private renderBoolean(): void {
    this.componentCell = BooleanCellComponent;
    this.componentCellInputs = {
      formGroup: this.formGroup,
      column: this.column,
    };
  }

  /** Renders multiline string. */
  private renderMultilineString(): void {
    if (!this.value) {
      return;
    }
    const text = this.nl2br.transform(this.value);
    this.renderer.setProperty(this.elRef.nativeElement, 'innerHTML', text);
    this.renderer.addClass(this.elRef.nativeElement, 'trim');
    this.renderer.setAttribute(this.elRef.nativeElement, 'title', this.value);
  }

  /** Enables force cell updating logic. */
  private addForceCellUpdating(): void {
    this.lastValue = this.formGroup.controls[this.column.name].value;
    this.formGroup.controls[this.column.name].valueChanges
      .pipe(debounceTime(checkChangesDebounceTime), takeUntil(this.destroyed$))
      .subscribe((change) => {
        if (!_.isEqual(this.lastValue, change)) {
          this.lastValue = change;
          /** No rerender for editing control cells. */
          if (
            (this.selectionType === SelectionType.range &&
              this.formGroup.controls[this.column.name] !==
                this.cellOrchestratorService.editingControl) ||
            !this.canCellBeEditable()
          ) {
            this.render();
            this.cdr.detectChanges();
          }
        }
      });
  }
}
