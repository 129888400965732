import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { ClientOverviewAnalysisComponent } from 'src/app/clients/card/client-overview/client-overview-analysis/client-overview-analysis.component';
import { OverviewModule } from 'src/app/shared/components/features/overview/overview.module';

@NgModule({
  declarations: [ClientOverviewAnalysisComponent],
  imports: [
    CommonModule,
    SharedModule,
    OverviewModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'client.overview.analysis',
          url: '/analysis',
          component: ClientOverviewAnalysisComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
    BaseSharedModule,
    CommentsModule,
  ],
})
export class ClientOverviewAnalysisModule {}
