import { Component, OnDestroy, OnInit } from '@angular/core';
import { TrendsKpiService } from 'src/app/shared/components/features/overview/trends-kpi/core/trends-kpi.service';
import { Subject } from 'rxjs';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tmt-project-overview-analysis',
  templateUrl: './project-overview-analysis.component.html',
  providers: [TrendsKpiService],
})
export class ProjectOverviewAnalysisComponent implements OnInit, OnDestroy {
  public project: Project;
  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  public get projectVersion(): ProjectVersion {
    const isWorkVersion = this.versionCardService.isWorkProjectVersion();
    const version = this.versionCardService.projectVersion;
    return !isWorkVersion ? version : null;
  }

  constructor(
    public service: ProjectCardService,
    private versionCardService: ProjectVersionCardService,
    private trendsKpiService: TrendsKpiService,
  ) {}

  private reload() {
    this.trendsKpiService.reload();
  }

  ngOnInit() {
    this.service.reloadTab$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());
    this.service.project$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((project) => {
        this.project = project;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
