<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
<form class="modal-form" novalidate ngDraggable *ngIf="!isLoading">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ member.resource.name }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" [animation]="false">
      <li [ngbNavItem]="1">
        <a ngbNavLink>{{ 'projects.projects.card.team.modal.tabs.main' | translate }}</a>
        <ng-template ngbNavContent [formGroup]="form">
          <div class="form-group">
            <div class="form-group">
              <label>{{ 'projects.projects.card.team.modal.resource' | translate }}</label>
              <p *ngIf="member.resource" class="pt-1 trim">{{ member.resource.name }}</p>
              <wp-text-box
                *ngIf="!member.resource"
                formControlName="description"
                placeholder="{{ 'shared.props.name' | translate }}"
              ></wp-text-box>
            </div>

            <label>{{ 'projects.projects.card.team.modal.role' | translate }}</label>
            <wp-select-box
              formControlName="role"
              [values]="roles$"
              placeholder="{{ 'projects.projects.card.team.modal.role' | translate }}"
            />
          </div>

          @if (
            projectCardService.isProjectBillable && member.resource?.resourceType !== 'Department'
          ) {
            <div class="form-group">
              <label>
                {{ 'projects.projects.card.team.columns.primaryTariff.header' | translate }}
              </label>
              <wp-select-box
                formControlName="primaryTariff"
                [query]="projectTeamService.getProjectTariffQuery(projectId, memberId)"
                collection="ProjectTariffs"
                [placeholder]="
                  'projects.projects.card.team.columns.primaryTariff.header' | translate
                "
                [allowNull]="false"
              />
            </div>
          }

          <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
            <input
              formControlName="isActive"
              type="checkbox"
              class="form-check-input"
              id="isActive"
            />
            <label class="form-check-label" for="isActive">
              {{ 'shared.props.isActive.label' | translate }}
            </label>
          </div>
        </ng-template>
      </li>
      <li
        [ngbNavItem]="2"
        *ngIf="member?.teamCostViewAllowed && member.resource?.resourceType === 'User'"
      >
        <a ngbNavLink>{{ 'projects.projects.card.team.modal.tabs.cost' | translate }}</a>
        <ng-template ngbNavContent [formGroup]="costForm">
          <div class="form-group" formGroupName="initialValue">
            <label>{{ 'settings.users.card.cost.props.cost.label' | translate }}</label>
            <wp-currency-box
              style="width: 150px; display: block"
              formControlName="value"
              [currencyCode]="currencyCode"
            >
            </wp-currency-box>
            <span class="form-text text-body-secondary">{{
              'settings.users.card.cost.props.cost.help' | translate
            }}</span>
          </div>

          <wp-grid
            [formArray]="costValuesForm"
            [options]="gridOptions"
            [readonly]="costReadonly"
            style="width: 450px; display: block"
          ></wp-grid>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="!readonly || !costReadonly"
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
