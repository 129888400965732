import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ClientCardService } from 'src/app/clients/card/client-card.service';
import { TrendsKpiService } from 'src/app/shared/components/features/overview/trends-kpi/core/trends-kpi.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tmt-client-overview-analysis',
  templateUrl: './client-overview-analysis.component.html',
  providers: [TrendsKpiService],
})
export class ClientOverviewAnalysisComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  constructor(
    public service: ClientCardService,
    private trendsKpiService: TrendsKpiService,
  ) {}

  private reload() {
    this.trendsKpiService.reload();
  }

  ngOnInit() {
    this.service.reloadTab$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
