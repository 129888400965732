import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AppService } from 'src/app/core/app.service';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Component({
  selector: 'tmt-history-log-filter',
  templateUrl: './history-log-filter.component.html',
})
export class HistoryLogFilterComponent implements OnInit, FilterDetails {
  @Input() values: any;

  public collectionName: string;

  detailsForm = this.fb.group({
    user: null,
    period: null,
  });

  constructor(
    private fb: UntypedFormBuilder,
    public service: FilterService,
    public app: AppService,
  ) {}

  ngOnInit() {
    this.service.resetValues$.subscribe(() => {
      this.detailsForm.reset();
    });

    this.detailsForm.patchValue(this.service.values);
    this.detailsForm.valueChanges.subscribe(() => {
      const values = Object.assign(this.service.values, this.detailsForm.value);
      this.service.changeValues(values);
    });
  }
}
