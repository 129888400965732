export enum ScheduleNavigationContext {
  Booking = 'Booking',
  Rbc = 'Rbc',
  Expenses = 'Expenses',
  ProjectSummary = 'ProjectSummary',
  Resources = 'Resources',
  ResourcesCalendar = 'ResourcesCalendar',
  ResourceSummary = 'ResourceSummary',
  ProjectTasksTimeline = 'ProjectTasksTimeline',
  ResourceRequirements = 'ResourceRequirements',
}
