import { Component } from '@angular/core';
import { SortDirection } from 'src/app/shared-features/comments/model/sort-direction.enum';
import { SortService } from './core/sort.service';

@Component({
  selector: 'wp-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
})
export class SortComponent {
  public sortDirection = SortDirection;

  constructor(public sortService: SortService) {}

  public toggleDirection() {
    this.sortService.toggleDirection();
  }
}
