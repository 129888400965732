import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

/** Настройка ресурсного плана. */
export class ResourcePlannerSettings implements BaseSettings {
  public readonly name = 'ResourcePlannerSettings';
  version: number;

  planningScale: PlanningScale;

  valueMode: ValueMode;

  /** Смещение периода от текущего. */
  periodOffset: number;

  getDefault(): ResourcePlannerSettings {
    return {
      version: 2,
      planningScale: PlanningScale.Day,
      valueMode: ValueMode.Hours,
      periodOffset: 0,
    } as ResourcePlannerSettings;
  }
}
