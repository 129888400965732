<tr>
  <td
    *ngFor="let entry of group.entries; trackBy: trackById"
    class="group"
    (click)="openPopup(group, null, entry, $event)"
    [ngClass]="{
      'clickable-slot': isSlotEditable(group, entry),
      'expanded-group': group.isExpanded
    }"
  >
    {{ entry.amount | zeroToNull | number: '1.2-2' }}
  </td>
</tr>
<ng-container *ngIf="group.isExpanded && group.taskLines">
  <tr *ngFor="let line of group.taskLines; trackBy: trackById">
    <td
      *ngFor="let entry of line.entries; trackBy: trackById"
      class="line"
      (click)="openPopup(group, line, entry, $event)"
      [ngClass]="{ 'clickable-slot': isSlotEditable(group, entry) }"
    >
      {{ entry.amount | zeroToNull | number: '1.2-2' }}
    </td>
  </tr>

  <!-- Other -->
  <tr *ngIf="group.type !== RbcGroupType.estimate && group.otherLine.total">
    <td *ngFor="let entry of group.otherLine.entries; trackBy: trackById" class="line">
      {{ (showOther ? entry.amount : 0) | zeroToNull | number: '1.2-2' }}
    </td>
  </tr>
</ng-container>
