export enum ReportFieldType {
  Undefined = 'Undefined',
  String = 'String',
  Integer = 'Integer',
  Decimal = 'Decimal',
  Date = 'Date',
  Bool = 'Bool',
  Percent = 'Percent',
  EntityId = 'EntityId',
  Enum = 'Enum',
  DateTimeOffset = 'DateTimeOffset',
  State = 'State',
}

export const CalculatedFieldTypes: ReportFieldType[] = [
  ReportFieldType.String,
  ReportFieldType.Integer,
  ReportFieldType.Decimal,
  ReportFieldType.Date,
  ReportFieldType.Percent,
  ReportFieldType.Bool,
];
