import { Component, Injector, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResizedEvent } from 'angular-resize-event';
import { cloneDeep, Dictionary } from 'lodash';
import { DateTime } from 'luxon';
import { forkJoin } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { ProjectTeamMember } from 'src/app/shared/models/entities/projects/project-team-member.model';
import { Exception } from 'src/app/shared/models/exception';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';

@Component({
  selector: 'wp-move-plan-form',
  templateUrl: './move-plan-form.component.html',
  styleUrls: ['./move-plan-form.component.scss'],
})
export class MovePlanFormComponent implements OnInit {
  private _teamMembers: ProjectTeamMember[] = [];
  @Input() set teamMembers(obj: ProjectTeamMember[]) {
    this._teamMembers = cloneDeep(obj);
  }
  get teamMembers(): ProjectTeamMember[] {
    return this._teamMembers;
  }

  @Input() projectId: string;

  thereIsNoPlan: boolean;
  isSaving = false;
  isLoading = false;

  allTeamMembersSelector = new UntypedFormControl(true);
  allTasksSelector = new UntypedFormControl(true);

  public taskLines = this.fb.array([]);
  public teamMemberLines = this.fb.array([]);

  form: UntypedFormGroup = this.fb.group({
    from: [null, Validators.required],
    to: [null, Validators.required],
  });

  public viewTableStyle: Dictionary<string> = {};

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private messages: MessageService,
    private data: DataService,
    private activeModal: NgbActiveModal,
    private tasksService: ProjectTasksService,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
  ) {}

  public openUserInfo(id: string) {
    const userId = id;
    const target = document.getElementById(`user-${id}`);
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  public resize(event: ResizedEvent) {
    this.viewTableStyle['display'] = 'table';
    this.viewTableStyle['width'] = event.newRect.width + 'px';
  }

  public selectAllTasks() {
    this.taskLines.controls.forEach((task: UntypedFormGroup) => {
      task.controls['isSelected'].setValue(this.allTasksSelector.value, {
        emitEvent: false,
      });
    });
  }

  public selectAllTeamMembers() {
    this.teamMemberLines.controls.forEach((resourceGroup: UntypedFormGroup) => {
      resourceGroup.controls['isSelected'].setValue(
        this.allTeamMembersSelector.value,
        {
          emitEvent: false,
        },
      );
    });
  }

  public hasSelectedData() {
    return (
      !this.teamMemberLines.value.every((m: any) => !m.isSelected) &&
      !this.taskLines.value.every((m: any) => !m.isSelected) &&
      !this.thereIsNoPlan
    );
  }

  public ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const moveRequest = {
      from: this.form.value.from,
      to: this.form.value.to,
      teamMemberIds: this.teamMemberLines.value
        .filter((m) => m.isSelected)
        .map((m) => m.id),
      projectTaskIds: this.taskLines.value
        .filter((t: any) => t.isSelected)
        .map((t) => t.id),
    };

    this.versionDataService
      .projectCollectionEntity(
        this.versionCardService.projectVersion,
        this.projectId,
      )
      .action('WP.MovePlan')
      .execute(moveRequest)
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'projects.projects.card.resources.movePlanForm.messages.moved',
          );
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.messages.errorDetailed(error);
          this.isSaving = false;
        },
      });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  public loadData() {
    this.tasksService.resetProjectTasks(
      this.projectId,
      this.versionCardService.projectVersion,
    );

    this.isLoading = true;

    forkJoin({
      earliestPlannedDate: this.versionDataService
        .projectCollectionEntity(
          this.versionCardService.projectVersion,
          this.projectId,
        )
        .function('GetEarliestPlannedDate')
        .get<any>(),
      tasks: this.tasksService.getProjectTasks(
        this.projectId,
        this.versionCardService.projectVersion,
        10,
      ),
    }).subscribe((result) => {
      if (!result.earliestPlannedDate) {
        this.thereIsNoPlan = true;
      }
      this.form.controls['from'].setValue(result.earliestPlannedDate);

      const tasks = result.tasks.sort(naturalSort('structNumber'));

      tasks.forEach((task) => {
        task.name = task.structNumber
          ? `${task.structNumber} ${task.name}`
          : task.name;
        (task as any).isSelected = true;
        this.taskLines.push(this.fb.group(task));
      });

      this.allTasksSelector.setValue(true);

      this.isLoading = false;
    });
  }

  public isMoveLeft(): boolean {
    return (
      this.form.value.from &&
      this.form.value.to &&
      DateTime.fromISO(this.form.value.from) >
        DateTime.fromISO(this.form.value.to)
    );
  }

  ngOnInit(): void {
    this.loadData();

    const genericMembers = this.teamMembers
      .filter((g) => g.resource.resourceType === ResourceType.generic)
      .sort(naturalSort('name'));
    const resourceMembers = this.teamMembers
      .filter((g) => g.resource.resourceType !== ResourceType.generic)
      .sort(naturalSort('name'));
    this.teamMembers = [...genericMembers, ...resourceMembers];

    this.teamMembers.forEach((teamMember) => {
      (teamMember as any).isSelected = true;
      this.teamMemberLines.push(this.fb.group(teamMember));
    });
    this.selectAllTeamMembers();

    this.allTasksSelector.valueChanges.subscribe(() => this.selectAllTasks());
    this.allTeamMembersSelector.valueChanges.subscribe(() =>
      this.selectAllTeamMembers(),
    );

    this.taskLines.valueChanges.subscribe(() => {
      this.allTasksSelector.setValue(false, { emitEvent: false });
    });

    this.teamMemberLines.valueChanges.subscribe(() => {
      this.allTeamMembersSelector.setValue(false, { emitEvent: false });
    });
  }
}
