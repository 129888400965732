import {
  DestroyRef,
  Inject,
  Injectable,
  NgZone,
  Optional,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import _ from 'lodash';
import { cloneDeep, floor, maxBy, orderBy, round } from 'lodash';
import {
  DateTime,
  DurationLikeObject,
  DurationObjectUnits,
  Interval,
} from 'luxon';
import { asyncScheduler, fromEvent, ReplaySubject, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { Slot } from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { BookingEntry } from 'src/app/shared/models/entities/resources/booking-entry.model';
import { PlanningMethod } from 'src/app/shared/models/enums/planning-method.enum';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { Position } from 'src/app/shared/models/inner/position.model';
import { BookingDataService } from './booking-data.service';
import { Constants } from 'src/app/shared/globals/constants';

@Injectable()
export class BookingRenderingService {
  private bookingHeight = 40;
  private bookingGap = 7;
  private bookingSlotPadding = 3;
  private readonly widthByScale: Record<PlanningScale, number> = {
    [PlanningScale.Day]: 45,
    [PlanningScale.Week]: 55,
    [PlanningScale.Month]: 65,
    [PlanningScale.Quarter]: null,
    [PlanningScale.Year]: null,
  };

  /** Текущий рабочий интервал доски. */
  public interval: Interval;

  /** Текущие слоты доски. */
  public slots: Slot[];

  /** Масштаб планирования.  */
  public planningScale: PlanningScale;

  private get slotDurationUnit(): keyof DurationLikeObject {
    switch (this.planningScale) {
      case PlanningScale.Day:
        return 'days';
      case PlanningScale.Week:
        return 'weeks';
      case PlanningScale.Month:
        return 'months';
    }
  }

  public getDurationLineObject(value: number): DurationObjectUnits {
    switch (this.planningScale) {
      case PlanningScale.Day:
        return { days: value };
      case PlanningScale.Week:
        return { weeks: value };
      case PlanningScale.Month:
        return { months: value };
    }
  }

  private bookingStyleSubject = new ReplaySubject<BookingStyle>();
  public bookingStyle$ = this.bookingStyleSubject.asObservable();

  private stopChangeSubject = new Subject<{
    booking: BookingEntry;
    originalBooking: BookingEntry;
  }>();
  public stopChange$ = this.stopChangeSubject.asObservable();

  private stopCreateSubject = new Subject<{
    booking: BookingEntry;
    lineChanges: BookingEntry[];
  }>();
  public stopCreate$ = this.stopCreateSubject.asObservable();

  public get slotWidth(): number {
    return this.getSlotWidth(this.planningScale);
  }

  public boardEl: HTMLDivElement;

  private dragging: {
    originalBooking: BookingEntry;
    booking: BookingEntry;
    bookingGap: Position;
    ghostEl: HTMLDivElement;
    originalFrom: DateTime;
  };

  private resizing: {
    side: 'left' | 'right';
    originalBooking: BookingEntry;
    booking: BookingEntry;
    bookingEl: HTMLDivElement;
    bookingGap: Position;
    sideX: number;
  };

  private creating: {
    booking: BookingEntry;
    rightCornerX: number;
    lineChanges: BookingEntry[];
  };

  private throttle = throttleTime(
    Constants.mousemoveThrottleTime,
    asyncScheduler,
    {
      leading: true,
      trailing: true,
    },
  );

  private groupBoardHeightSubject = new ReplaySubject<{
    id: string;
    height: number;
  }>();
  public groupBoardHeight$ = this.groupBoardHeightSubject.asObservable();

  private destroyRef = inject(DestroyRef);

  constructor(
    private dataService: BookingDataService,
    private scheduleNavigationService: ScheduleNavigationService,
    @Optional() @Inject('entity') public entityData: string,
    @Optional() @Inject('context') public context: string,
    private zone: NgZone,
  ) {}

  /** Получение минимальной ширины таблицы. */
  public getDataTableWidth() {
    if (!this.slots) {
      return null;
    }
    return this.slotWidth * this.slots.length + 1;
  }

  public getSlotWidth(scale: PlanningScale): number {
    return this.widthByScale[scale];
  }

  startResize(
    side: 'left' | 'right',
    booking: BookingEntry,
    bookingEl: HTMLDivElement,
    event: MouseEvent,
    xGap: number,
    yGap: number,
  ) {
    this.resizing = {
      side,
      originalBooking: cloneDeep(booking),
      booking,
      bookingEl,
      bookingGap: { x: xGap, y: yGap },
      sideX: 0,
    };

    event.stopPropagation();

    this.resizeMoveTo(event);
  }

  public resizeMoveTo(event: MouseEvent) {
    if (!this.resizing) {
      return;
    }

    const boardRect = this.boardEl.getBoundingClientRect();

    const x =
      this.resizing.side === 'left'
        ? round(event.clientX - boardRect.x - this.resizing.bookingGap?.x ?? 0)
        : round(event.clientX - boardRect.x + this.resizing.bookingGap?.x ?? 0);

    // Вычислить новые параметры бронирования.
    const slotIndex =
      this.resizing.side === 'left'
        ? round(x / this.slotWidth)
        : round(x / this.slotWidth) - 1;

    let newFrom: DateTime = this.resizing.booking.fromLx;
    let newTo: DateTime = this.resizing.booking.toLx;

    if (this.resizing.side === 'left') {
      newFrom = this.interval.start.plus(this.getDurationLineObject(slotIndex));
    } else {
      newTo = this.interval.start
        .plus(this.getDurationLineObject(slotIndex + 1))
        .minus({ days: 1 });
    }

    if (!Interval.fromDateTimes(newFrom, newTo).isValid) {
      return;
    }

    this.resizing.booking.fromLx = newFrom;
    this.resizing.booking.toLx = newTo;

    const lineChanges = this.updateLineIndexes(
      this.resizing.booking.resourceId,
    );

    this.resizing.sideX = event.x - boardRect.x;

    this.propagateBookingStyles(this.resizing.booking);

    lineChanges.forEach((lineChange) => {
      this.propagateBookingStyles(lineChange);
    });

    this.propagateGroupBoardHeight(this.resizing.booking.resourceId);
  }

  public stopResize(event: MouseEvent) {
    if (!this.resizing) {
      return;
    }

    const booking = this.resizing.booking;

    if (
      !this.resizing.booking.fromLx.equals(
        this.resizing.originalBooking.fromLx,
      ) ||
      !this.resizing.booking.toLx.equals(this.resizing.originalBooking.toLx)
    ) {
      this.stopChangeSubject.next({
        originalBooking: this.resizing.originalBooking,
        booking,
      });
    }
    this.resizing = null;

    this.propagateBookingStyles(booking);
  }

  public startDrag(
    booking: BookingEntry,
    xGap: number,
    yGap: number,
    bookingEl: HTMLDivElement,
    event: MouseEvent,
  ) {
    this.dragging = {
      booking,
      originalBooking: cloneDeep(booking),
      bookingGap: { x: xGap, y: yGap },
      ghostEl: bookingEl.cloneNode(true) as HTMLDivElement,
      originalFrom: booking.fromLx,
    };

    this.dragging.ghostEl.classList.add('drag');
    this.boardEl.appendChild(this.dragging.ghostEl);
    this.dragMoveTo(event);
    event.stopPropagation();
  }

  public dragMoveTo(event: MouseEvent) {
    if (!this.dragging) {
      return;
    }
    const boardRect = this.boardEl.getBoundingClientRect();
    const x = round(
      event.clientX - boardRect.x - this.dragging.bookingGap?.x ?? 0,
    );
    const y = round(
      event.clientY - boardRect.y - this.dragging.bookingGap?.y ?? 0,
    );

    this.dragging.ghostEl.style.left = x + 'px';
    this.dragging.ghostEl.style.top = y + 'px';

    // Вычислить новые параметры бронирования.

    const slotIndex = round(x / this.slotWidth);

    const previousInterval = Interval.fromDateTimes(
      this.dragging.originalBooking.fromLx,
      this.dragging.originalBooking.toLx,
    );
    const previousSlotIndex = floor(
      previousInterval.start
        .diff(this.interval.start, this.slotDurationUnit)
        .get(this.slotDurationUnit),
    );

    const shiftSlots = slotIndex - previousSlotIndex;

    switch (this.planningScale) {
      case PlanningScale.Day:
        this.dragging.booking.fromLx = previousInterval.start.plus({
          days: shiftSlots,
        });
        this.dragging.booking.toLx = previousInterval.end.plus({
          days: shiftSlots,
        });
        break;
      case PlanningScale.Week:
        this.dragging.booking.fromLx = previousInterval.start.plus({
          weeks: shiftSlots,
        });
        this.dragging.booking.toLx = previousInterval.end.plus({
          weeks: shiftSlots,
        });
        break;
      case PlanningScale.Month: {
        let to = previousInterval.end.plus({ months: shiftSlots });
        if (
          previousInterval.end
            .endOf('month')
            .startOf('day')
            .equals(previousInterval.end)
        ) {
          to = to.endOf('month');
        }
        this.dragging.booking.toLx = to;

        if (this.dragging.booking.planningMethod === PlanningMethod.Manual) {
          this.dragging.booking.fromLx = to.minus({
            days: previousInterval.end.diff(previousInterval.start, 'day').days,
          });
        } else {
          this.dragging.booking.fromLx = previousInterval.start.plus({
            months: shiftSlots,
          });
        }

        break;
      }
    }

    const lineChanges = this.updateLineIndexes(
      this.dragging.booking.resourceId,
    );
    this.propagateBookingStyles(this.dragging.booking);

    lineChanges.forEach((lineChange) => {
      this.propagateBookingStyles(lineChange);
    });

    this.propagateGroupBoardHeight(this.dragging.booking.resourceId);
  }

  public stopDrag() {
    if (!this.dragging) {
      return;
    }

    this.dragging.ghostEl.remove();
    const booking = this.dragging.booking;

    if (
      !this.dragging.booking.fromLx.equals(this.dragging.originalFrom) ||
      this.dragging.booking.resourceId !==
        this.dragging.originalBooking.resourceId
    ) {
      this.stopChangeSubject.next({
        originalBooking: this.dragging.originalBooking,
        booking,
      });
    }
    this.dragging = null;

    this.propagateBookingStyles(booking);
  }

  public startCreate(resourceId: string, mouseEvent: MouseEvent) {
    const boardRect = this.boardEl.getBoundingClientRect();
    const x = round(mouseEvent.clientX - boardRect.x);
    const slotIndex = floor(x / this.slotWidth);

    const from = this.interval.start.plus(
      this.getDurationLineObject(slotIndex),
    );

    const booking: BookingEntry = {
      id: Guid.generate(),
      created: DateTime.now().toISO(),
      from: null,
      to: null,
      fromLx: from,
      toLx: from,
      bookedHours: 0,
      requiredHours: 0,
      requiredSchedulePercent: 0,
      lineIndex: 0,
      project: null,
      detailEntries: [],
      resourceId,
      planningMethod: PlanningMethod.FrontLoad,
      editAllowed: true,
    };

    if (this.context === 'project') {
      booking.project = JSON.parse(this.entityData);
    }

    this.dataService.bookings.push(booking);
    this.creating = {
      booking,
      rightCornerX: 0,
      lineChanges: [],
    };

    this.createMoveTo(mouseEvent);
  }

  /** Обработка события "растягивания" при создании бронирования. */
  private createMoveTo(event: MouseEvent) {
    if (!this.creating) {
      return;
    }

    const left =
      this.creating.booking.fromLx
        .diff(this.interval.start, this.slotDurationUnit)
        .get(this.slotDurationUnit) * this.slotWidth;

    const boardRect = this.boardEl.getBoundingClientRect();

    if (event.clientX - boardRect.x <= left) {
      return;
    }

    this.creating.rightCornerX = event.x - boardRect.x;

    const x = round(event.clientX - boardRect.x);
    const slotIndex = round(x / this.slotWidth) - 1;

    const to = this.interval.start.plus(this.getDurationLineObject(slotIndex));
    if (
      to
        .diff(this.creating.booking.fromLx, this.slotDurationUnit)
        .get(this.slotDurationUnit) < 0
    ) {
      this.creating.booking.toLx = this.creating.booking.fromLx;
    } else {
      this.creating.booking.toLx = to;
    }

    const interval = this.scheduleNavigationService.getNormalizedInterval(
      Interval.fromDateTimes(
        this.creating.booking.fromLx,
        this.creating.booking.toLx,
      ),
      this.planningScale,
    );
    this.creating.booking.fromLx = interval.start;
    this.creating.booking.toLx = interval.end;

    const lineChanges = this.updateLineIndexes(
      this.creating.booking.resourceId,
    );
    this.propagateBookingStyles(this.creating.booking);

    // Добавить измененные бронирования к общему списку изменений.
    lineChanges.forEach((lineChange) => {
      if (!this.creating.lineChanges.find((b) => b.id === lineChange.id)) {
        this.creating.lineChanges.push(lineChange);
      }
    });

    lineChanges.forEach((lineChange) => {
      this.propagateBookingStyles(lineChange);
    });

    this.propagateGroupBoardHeight(this.creating.booking.resourceId);
  }

  /** Завершение создания бронирования. */
  public stopCreate(event: MouseEvent) {
    const booking = this.creating.booking;

    this.stopCreateSubject.next({
      booking,
      lineChanges: this.creating.lineChanges,
    });
    this.creating = null;
    this.propagateBookingStyles(booking);
  }

  private isIntersected(int1: Interval, int2: Interval): boolean {
    int1 = this.scheduleNavigationService.getNormalizedInterval(
      int1,
      this.planningScale,
    );
    int2 = this.scheduleNavigationService.getNormalizedInterval(
      int2,
      this.planningScale,
    );
    return !(int2.start > int1.end || int2.end < int1.start);
  }

  public updateLineIndexes(resourceId: string): BookingEntry[] {
    const changes: BookingEntry[] = [];
    let bookings = this.dataService.getResourceBookings(resourceId);
    bookings = orderBy(
      bookings,
      [
        (booking) => !booking.isOther && !booking.isTimeOff,
        (booking) => booking.isOther,
        (booking) => booking.isTimeOff,
        (booking) => booking.bookedHours,
        (booking) => booking.created,
      ],
      ['desc', 'desc', 'desc', 'desc', 'asc'],
    );

    bookings.forEach((booking, index) => {
      if (booking.lineIndex !== index) {
        booking.lineIndex = index;
        changes.push(booking);
      }
    });

    const maxLineIndex = maxBy(bookings, (e) => e.lineIndex)?.lineIndex ?? 0;

    for (let index = 0; index <= maxLineIndex; index++) {
      const entriesInLine = orderBy(
        bookings.filter((e) => e.lineIndex === index),
        (e) => e.fromLx.toISODate(),
      );

      while (entriesInLine.length > 0) {
        const booking = entriesInLine.splice(0, 1)[0];
        const currentInterval = Interval.fromDateTimes(
          booking.fromLx,
          booking.toLx,
        );

        // Если "сверху" пусто - поднять.
        const intersectedBookings = bookings.filter(
          (e) =>
            e.lineIndex < booking.lineIndex &&
            this.isIntersected(
              currentInterval,
              Interval.fromDateTimes(e.fromLx, e.toLx),
            ),
        );

        if (!intersectedBookings.length) {
          const toIndex = Math.max(0, booking.lineIndex - 1);
          booking.lineIndex = toIndex;

          if (toIndex > 0) {
            entriesInLine.unshift(booking);
          }

          changes.push(booking);
          continue;
        }

        const maxIndex =
          (maxBy(intersectedBookings, (e) => e.lineIndex).lineIndex ?? -1) + 1;

        if (maxIndex < booking.lineIndex) {
          booking.lineIndex = maxIndex;
          changes.push(booking);
        }
      }
    }

    return _.uniqBy(changes, 'id');
  }

  /** Перерисовать группу. */
  public redrawGroup(resourceId: string) {
    this.updateLineIndexes(resourceId);
    this.propagateGroupBoardHeight(resourceId);
    this.dataService.getResourceBookings(resourceId).forEach((booking) => {
      this.propagateBookingStyles(booking);
    });
  }

  public propagateGroupBoardHeight(resourceId: string): any {
    const bookings = this.dataService.getResourceBookings(resourceId);
    const maxLinesIndex =
      bookings.length > 0 ? maxBy(bookings, (b) => b.lineIndex)?.lineIndex : 0;

    this.groupBoardHeightSubject.next({
      id: resourceId,
      height: (maxLinesIndex + 1) * (40 + 7) + 7,
    });
  }

  public propagateBookingStyles(bookingEntry: BookingEntry) {
    const interval = this.scheduleNavigationService.getNormalizedInterval(
      Interval.fromDateTimes(bookingEntry.fromLx, bookingEntry.toLx),
      this.planningScale,
    );

    const start = interval.start;
    const end = interval.end;

    const slotIndex = floor(
      start
        .diff(this.interval.start, this.slotDurationUnit)
        .get(this.slotDurationUnit),
    );

    // 1 - граница, 3 - padding от края. Это граница без учета "растягивания".
    let x = this.slotWidth * slotIndex + this.bookingSlotPadding;
    const y =
      this.bookingGap +
      (this.bookingHeight + this.bookingGap) * bookingEntry.lineIndex;

    const slotsCount =
      floor(end.diff(start, this.slotDurationUnit).get(this.slotDurationUnit)) +
      1;
    let width = slotsCount * this.slotWidth - 6;

    const css = [];

    if (this.resizing?.booking?.id === bookingEntry.id) {
      css.push('resizing');
      if (this.resizing.side === 'left') {
        width = width - (this.resizing.sideX - x);
        x = this.resizing.sideX;
      } else {
        width = this.resizing.sideX - x + this.resizing.bookingGap.x;
      }
    }

    if (this.creating?.booking?.id === bookingEntry.id) {
      width = this.creating.rightCornerX - x;
      css.push('creating');
    }

    if (this.dragging?.booking?.id === bookingEntry.id) {
      css.push('dragging');
    }

    if (bookingEntry.isTimeOff || bookingEntry.isOther) {
      css.push('timeoff');
    }

    if (!bookingEntry.editAllowed || this.dataService.isReadonlyMode()) {
      css.push('readonly');
    }

    css.push(bookingEntry.type?.toLowerCase());

    this.bookingStyleSubject.next({
      id: bookingEntry.id,
      position: { x, y },
      width,
      css,
    });
  }

  /** Регистрация общей доски бронирования. */
  public initBoard(boardEl: HTMLDivElement) {
    this.boardEl = boardEl;

    fromEvent(document, 'mouseup')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: MouseEvent) => {
        if (this.dragging) {
          this.stopDrag();
        }

        if (this.creating) {
          this.stopCreate(event);
        }

        if (this.resizing) {
          this.stopResize(event);
        }
      });

    this.zone.runOutsideAngular(() => {
      fromEvent(this.boardEl, 'mousemove')
        .pipe(this.throttle, takeUntilDestroyed(this.destroyRef))
        .subscribe((event: MouseEvent) => {
          if (this.dragging) {
            this.dragMoveTo(event);
          }

          if (this.resizing) {
            this.resizeMoveTo(event);
          }

          if (this.creating) {
            this.createMoveTo(event);
          }
        });
    });
  }

  /** Обработать "вход" в зону ресурса. */
  public mouseOverGroup(resourceId: string) {
    if (this.dragging && this.dragging.booking.resourceId !== resourceId) {
      const oldResourceId = this.dragging.booking.resourceId;
      this.dragging.booking.resourceId = resourceId;

      this.redrawGroup(oldResourceId);
      this.redrawGroup(resourceId);
    }
  }

  public registerBooking(booking: BookingEntry) {
    this.propagateBookingStyles(booking);
  }
}

export interface GroupBoard {
  resource: NamedEntity;
}

export interface BookingStyle {
  id: string;
  position: Position;
  width: number;
  css: string[];
}
