<div
  class="input-group"
  (clickOutside)="cancel()"
  [excludeBeforeClick]="true"
  [attachOutsideOnClick]="true"
>
  <input
    #input
    class="form-control"
    (blur)="onBlur()"
    [formControl]="textControl"
    (keydown)="onKeyDown($event)"
    type="text"
    title="{{ title }}"
    placeholder="{{ placeholder }}"
    (click)="onInputClick()"
  />

  <span
    class="input-clear"
    title="{{ 'shared.hintClearControlButton' | translate }}"
    (click)="clear()"
    [hidden]="!textControl.value"
    >&times;</span
  >

  <div id="{{ areaId }}" class="expanding-area expanding-area-vertical-scroll" *ngIf="listOpened">
    <div *ngIf="rows.length === 0" class="center-block" style="text-align: center; padding: 3px">
      <p class="text-body-secondary">{{ 'shared.noOptions' | translate }}</p>
    </div>
    <table class="selecting-list">
      <tbody>
        <tr
          *ngFor="let row of rows"
          (mouseenter)="selectRow(row)"
          (click)="clickRow(row)"
          id="{{ row.id }}"
          title="{{ row.name }}"
          [ngClass]="{ 'selected-row': row.id == selectedRow?.id }"
        >
          <td class="trim">{{ row.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    name="area"
    class="expanding-area custom-period-form"
    *ngIf="customPeriodOpened"
    [formGroup]="datesForm"
  >
    <div>
      <wp-date-box formControlName="from"></wp-date-box>
      <wp-date-box formControlName="to"></wp-date-box>
    </div>

    <div>
      <button
        *ngIf="datesForm.valid"
        type="button"
        class="btn btn-primary"
        (click)="selectCustomPeriod()"
      >
        {{ 'shared.actions.ok' | translate }}
      </button>
      <button type="button" class="btn btn-default" (click)="cancel()">
        {{ 'shared.actions.cancel' | translate }}
      </button>
    </div>
  </div>

  <button tabindex="-1" type="button" class="btn btn-default input-group-text" (click)="openList()">
    <i class="bi bi-chevron-down" aria-hidden="true"></i>
  </button>
</div>
