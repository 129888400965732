import {
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Directive,
  AfterViewInit,
  inject,
  DestroyRef,
  ViewRef,
  Renderer2,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { Instance, bottom, createPopper } from '@popperjs/core';
import { firstValueFrom } from 'rxjs';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { TreeListComponent } from 'src/app/shared/components/features/tree-list';

@Directive()
export class AbstractHierarchicalBoxComponent
  implements OnChanges, AfterViewInit, ControlValueAccessor
{
  @ViewChild('input') protected inputEl: ElementRef<HTMLInputElement>;
  @ViewChild('expandingArea') private expandingArea: ElementRef<HTMLElement>;
  @ViewChild(TreeListComponent, { read: ElementRef })
  protected treeListEl: ElementRef<HTMLElement>;

  @Input() public autofocus?: boolean;
  @Input() public placeholder?: string;
  @Input() public allowNull = true;
  @Input() public allowInactive?: boolean;
  @Input() public items?: any[];
  @Input() public includeChildren?: boolean;
  @Input({ required: true }) public collection: string;
  @Input({ required: true }) public parentIdKey: string;

  public readonly = false;
  public listOpened = false;
  public isLoading = false;
  public rows: any[] = [];
  public selectedRow: any;
  public focusedRow: any;
  public textControl = new UntypedFormControl('');
  public treeListTarget: string | null;
  public propagateChange = (_: any) => null;
  public propagateTouch = () => null;

  protected popperInstance: Instance;
  protected loadLimit?: number = 500;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    protected notificationService: NotificationService,
    protected dataService?: DataService,
    protected translateService?: TranslateService,
    protected cdr?: ChangeDetectorRef,
    protected renderer?: Renderer2,
    protected el?: ElementRef<HTMLElement>,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['allowInactive'] && !changes['allowInactive'].firstChange) {
      this.rows = [];
    }
  }

  public ngAfterViewInit(): void {
    if (this.autofocus && this.inputEl) {
      this.inputEl.nativeElement.focus();
    }

    this.textControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.treeListTarget =
          (!!value.trim().length && this.textControl.dirty) ||
          (!!value.trim().length && !this.selectedRow)
            ? value
            : null;
      });
  }

  public writeValue(value: any): void {
    throw Error(`The "writeValue" method was not implemented!`);
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;

    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr?.detectChanges();
    }
  }

  /** Propagates touch on blur event. */
  public onBlur(): void {
    this.propagateTouch();
  }

  /**
   * KeyboardEvent handler.
   *
   * @param event KeyboardEvent
   *
   * */
  public onKeyDown(event: KeyboardEvent): void {
    const downCode = 'ArrowDown';
    const escCode = 'Escape';
    const enterCode = 'Enter';

    if (event.code === downCode) {
      this.treeListEl?.nativeElement
        ?.querySelector<HTMLElement>('.tree-list')
        .focus();
    }

    if (event.code === enterCode) {
      event.preventDefault();
      event.stopPropagation();

      if (this.focusedRow) {
        this.onSelected(this.focusedRow);
      }
    }

    if (event.code === escCode) {
      this.cancel();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /** Input click handler. */
  public onInputClick(): void {
    if (!this.listOpened) {
      this.toggleList();
    }
  }

  /**
   * Sets focused row from tree-list.
   *
   * @param value tree-list item.
   */
  public onFocused(value: any): void {
    this.focusedRow = value;
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr?.detectChanges();
    }
  }

  /** Drops value. */
  public clear(): void {
    this.selectedRow = null;
    this.treeListTarget = null;
    this.setTextBoxValue(null);
    this.propagateChange(null);
    this.closeList();
  }

  /**
   *  Propagates selected value.
   *
   * @param value any entity.
   */
  public onSelected(value: any): void {
    this.selectedRow = value;
    this.propagateChange({
      id: value.id,
      name: value.name,
      [this.parentIdKey]: value[this.parentIdKey],
    });
    this.setTextBoxValue(value);
    this.cancel();
  }

  /** Closes list and sets value to text input. */
  public cancel(): void {
    this.closeList();
    this.textControl.markAsPristine();

    if (this.textControl.value && this.selectedRow) {
      this.setTextBoxValue(this.selectedRow);
      return;
    }

    if (this.selectedRow) {
      this.setTextBoxValue(this.selectedRow);
    }
  }

  /** Toggles `listOpened` state. Also loads data, if `rows` is empty. */
  public toggleList(): void {
    if (this.listOpened) {
      this.cancel();
      return;
    }

    this.inputEl.nativeElement.select();
    this.propagateTouch();
    this.listOpened = true;

    if (!this.rows?.length) {
      this.loadData();
    }

    this.popperInstance = createPopper(
      this.el.nativeElement,
      this.expandingArea.nativeElement,
      {
        strategy: 'fixed',
        placement: bottom,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 2],
            },
          },
          {
            name: 'sameWidth',
            enabled: true,
            phase: 'beforeWrite',
            requires: ['computeStyles'],
            fn: ({ state }) => {
              state.styles.popper.width = `${state.rects.reference.width}px`;
            },
            effect: ({ state }) => {
              state.elements.popper.style.width = `${
                state.elements.reference.getBoundingClientRect().width
              }px`;
            },
          },
        ],
      },
    );

    this.renderer?.setAttribute(
      this.expandingArea.nativeElement,
      'data-show',
      '',
    );

    this.cdr.detectChanges();
    this.popperInstance.update();
  }

  /** Closes list. */
  public closeList(): void {
    this.listOpened = false;
  }

  /** Returns text value for readonly mode. */
  public getReadOnlyDisplayText(): string {
    if (this.textControl.value) {
      return this.textControl.value;
    }

    return this.translateService.instant('shared.valueNotSelected');
  }

  protected setTextBoxValue(value: any): void {
    this.textControl.setValue(value?.name ?? '', { emitEvent: false });
  }

  protected async loadData<T>(): Promise<void> {
    try {
      if (this.items?.length) {
        this.rows = this.items as T[];
        return;
      }

      const query: any = {
        top: this.loadLimit,
        select: ['id', 'name', this.parentIdKey],
        filter: [],
        orderBy: 'name',
      };

      if (!this.allowInactive) {
        query.filter.push({ isActive: { eq: true } });
      }

      this.isLoading = true;
      this.cdr?.markForCheck();

      const result = await firstValueFrom(
        this.dataService
          .collection(this.collection)
          .query<T[]>(query)
          .pipe(takeUntilDestroyed(this.destroyRef)),
      );

      this.rows = result;
      this.isLoading = false;
      this.cdr?.markForCheck();
    } catch (error) {
      this.errorHandler(error);
      this.isLoading = false;
      this.cdr?.markForCheck();
    }
  }

  protected errorHandler(error: any): void {
    this.notificationService.error(error.message ?? error.code);
  }
}
