import { Injectable } from '@angular/core';
import { DayPart } from 'src/app/shared/models/enums/day-part.enum';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TimeOffRequestService {
  constructor(private translate: TranslateService) {}

  public getDayParts(): NamedEntity[] {
    const dayParts: NamedEntity[] = [];
    for (const dayPart of Object.keys(DayPart)) {
      dayParts.push({
        name: this.translate.instant(`enums.dayPart.${dayPart}`),
        id: dayPart,
      });
    }
    return dayParts;
  }
}
