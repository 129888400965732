import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class OffCanvasSettings implements BaseSettings {
  public readonly name = 'OffCanvasSettings';
  public version: number;
  public width: number;

  public getDefault(): OffCanvasSettings {
    return {
      version: 1,
      width: 600,
    } as OffCanvasSettings;
  }
}
