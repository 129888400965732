<form class="modal-form" novalidate ngDraggable autocomplete="off">
  <div class="modal-header">
    <h3 class="modal-title">{{ service.name$ | async | translate }}</h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <wp-time-off-balance-entry-body></wp-time-off-balance-entry-body>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="service.saving$ | async"
    >
      {{ 'shared.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
