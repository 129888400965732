import { InjectionToken } from '@angular/core';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class BookingAssistantSettings implements BaseSettings {
  public readonly name = 'BookingAssistantSettings';
  version: number;
  topAreaHeight: number;
  getDefault(): BookingAssistantSettings {
    return {
      version: 1,
      topAreaHeight: 175,
    } as BookingAssistantSettings;
  }
}

export const ASSISTANT_TEAM_MEMBER_ID = new InjectionToken<string>(
  'assistantTeamMemberId',
);

export interface BookingDetailDto {
  bookingId: string;

  resourceId: string;

  date: string;

  hours: number;
}

export interface ResourceRequestRequirementEntry {
  resourceRequestId: string;
  date: string;
  hours: number;
}
