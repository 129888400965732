<div class="d-flex align-items-center comment-attachment">
  <a (click)="click()" class="d-flex align-items-center">
    <i class="{{ fileExtension[fileExt] }}"></i>
    <span>{{ shortName }}</span>
  </a>

  @if ((commentsService.isEditAllowed(attachment?.modifiedById) || !attachment) && canEdit) {
    <i
      class="bi bi-trash3"
      title="{{ 'shared.comments.actions.deleteAttachment' | translate }}"
      (click)="delete()"
      data-test="delete"
    ></i>
  }

  @if (loading) {
    <div class="comment-attachment__download" wpButtonSpinner [isBusy]="loading">
      <span></span>
    </div>
  } @else {
    <i
      class="bi bi-download"
      [title]="'shared.actions.download' | translate"
      (click)="download()"
      data-test="download"
    ></i>
  }
</div>
