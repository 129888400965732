import { DestroyRef, Inject, Injectable, inject } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { ProjectCostCenter } from 'src/app/projects/card/project-cost-centers/models/project-cost-center.model';
import { DataService } from 'src/app/core/data.service';

@Injectable()
export class CostCenterService {
  private cache: Record<string, Observable<ProjectCostCenter[]>> = {};
  private destroyRef = inject(DestroyRef);

  constructor(
    private dataService: DataService,
    @Inject('entityId') private timesheetId: string,
  ) {}

  /**
   * Get project cost centers from server or cache.
   *
   * @param projectId project's id.
   */
  public getProjectCostCenters(
    projectId: string,
  ): Observable<ProjectCostCenter[]> {
    if (this.cache[projectId]) {
      return this.cache[projectId];
    } else {
      this.cache[projectId] = this.requestProjectCostCenters(projectId).pipe(
        shareReplay(1),
      );
      return this.cache[projectId];
    }
  }

  /**
   * Gets list of project cost centers.
   *
   * @param projectId project's id.
   */
  private requestProjectCostCenters(
    projectId: string,
  ): Observable<ProjectCostCenter[]> {
    return this.dataService
      .collection('TimeSheets')
      .entity(this.timesheetId)
      .function('GetProjectCostCenters')
      .get<ProjectCostCenter[]>({ projectId });
  }
}
