<div class="col-5">
  <div class="info rounded-2">
    <label class="group">
      {{ 'timeOff.card.props.info' | translate }}
    </label>

    <p class="m-0 pb-3 fw-semibold" data-test="unitName">
      {{ unitName }}
    </p>

    <table class="mb-3 w-100">
      <tr>
        <td class="fw-semibold pe-3 trim">{{ 'timeOff.card.props.duration' | translate }}:</td>
        <td class="text-end" data-test="duration">
          {{ (isApproved ? duration : currentDuration) | number: '1.0-2' }}
        </td>
      </tr>

      <tr *ngIf="currentBalance !== null">
        <td class="fw-semibold pe-3 trim">{{ 'timeOff.card.props.balance' | translate }}:</td>
        <td class="text-end" data-test="balance">
          {{ (isApproved ? balance : currentBalance) | number: '1.0-2' }}
        </td>
      </tr>

      <tr *ngIf="currentBalance !== null">
        <td class="fw-semibold pe-3 trim">{{ 'timeOff.card.props.remain' | translate }}:</td>
        <td class="text-end" data-test="remain">
          {{ getRemainValue() | number: '1.0-2' }}
        </td>
      </tr>
    </table>

    <p *ngIf="isApproved">{{ 'timeOff.card.balanceDescription' | translate }}</p>
  </div>
</div>
