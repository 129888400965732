<ng-container [formGroup]="form">
  <span class="text-body-secondary">{{
    'settings.validationRules.card.props.submitDateHelp' | translate
  }}</span>
  <br />
  <br />
  <div class="form-group">
    <label>{{ 'settings.validationRules.card.props.daysBeforeEndSubmitDate' | translate }}</label>
    <wp-number-box
      type="integer"
      formControlName="DaysBeforeEnd"
      [allowNull]="false"
      [min]="0"
      [max]="31"
    >
    </wp-number-box>
  </div>
</ng-container>
