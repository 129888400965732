import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormBuilder } from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { EmployeeExperienceOverviewService } from 'src/app/employees/card/employee-experience-overview/employee-experience-overview.service';
import { EMPLOYEE_EXPERIENCE_OVERVIEW_LIST } from 'src/app/employees/card/employee-experience-overview/models/employee-experience-overview.list';
import { EmployeeExperienceOverview } from 'src/app/employees/card/employee-experience-overview/models/employee-experience-overview.model';
import { DefaultFilterService } from 'src/app/settings-app/shared/default-filter/default-filter.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Order } from 'src/app/shared/models/inner/order';
import { ListService } from 'src/app/shared/services/list.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'tmt-employee-experience-overview',
  templateUrl: './employee-experience-overview.component.html',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    { provide: VIEW_NAME, useValue: 'employeeExperienceOverviewList' },
    { provide: LIST, useValue: EMPLOYEE_EXPERIENCE_OVERVIEW_LIST },
    GridService,
    ListService,
    EmployeeExperienceOverviewService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeExperienceOverviewComponent implements OnInit {
  public lines: FormArray = this.fb.array([]);
  public gridOptions: Grid2Options = {
    resizableColumns: true,
    sorting: true,
    selectionType: SelectionType.none,
    view: this.listService.getGridView(),
  };

  private destroyRef = inject(DestroyRef);

  /** Gets filter service text value in needed format. */
  private get filter(): any {
    const text = this.filterService.values.text.toLowerCase();
    if (text && text.length >= this.filterService.minStringLengthForFilter) {
      return [
        {
          or: [
            { project: { 'tolower(name)': { contains: text } } },
            { state: { 'tolower(name)': { contains: text } } },
            { role: { 'tolower(name)': { contains: text } } },
            { organization: { 'tolower(name)': { contains: text } } },
          ],
        },
      ];
    }
    return null;
  }

  constructor(
    public appService: AppService,
    public service: EmployeeExperienceOverviewService,
    private gridService: GridService,
    private actionPanelService: ActionPanelService,
    private fb: FormBuilder,
    private listService: ListService,
    private filterService: FilterService,
    private datePipe: DatePipe,
  ) {
    this.gridService.setOrder(this.listService.getGridView().order);
    this.gridService.order$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((o: Order) => this.orderChanged(o));
  }

  ngOnInit(): void {
    this.initFilterService();
    this.load();

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.load());

    this.service.experienceOverviewLines$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((lines: EmployeeExperienceOverview[]) => {
        lines.forEach((line: EmployeeExperienceOverview) => {
          const group = this.fb.group({
            project: line.project?.name,
            projectCode: line.project?.code,
            projectId: line.project?.id,
            state: line.state,
            role: line.role,
            period: `${this.formatDate(line.projectStartDate)} - ${this.formatDate(line.projectEndDate)}`,
            organization: line.organization?.name,
            organizationId: line.organization?.id,
            actualHours: line.actualHours,
          });
          this.lines.push(group);
        });
        this.gridService.detectChanges();
      });
  }

  /**
   * Formats date string.
   *
   * @param date string date.
   * @returns formatted date.
   */
  private formatDate(date: string): string {
    return this.datePipe.transform(
      date,
      'LLLL y',
      null,
      this.appService.session.language.toLowerCase(),
    );
  }

  /** Loads data. */
  private load(): void {
    this.lines.clear();
    this.service.load(this.filter);
  }

  /** Inits filter service properties and subscription. */
  private initFilterService(): void {
    this.filterService.hasViewSelector = false;
    this.filterService.placeholder =
      'components.employeeExperienceOverviewComponent.filters.textInput';
    this.filterService.values$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.load());
  }

  /**
   * Updates the order of the user view and reloads the list.
   *
   * @param order The new order to be applied.
   */
  public orderChanged(order: Order): void {
    const userView = this.listService.getUserView();
    userView.order = order;
    this.listService.saveUserView(userView);
    this.load();
  }
}
