<form *ngIf="canRead">
  <label class="group">{{ 'settings.settings.authProviders.title' | translate }}</label>
  <div class="actions">
    <button
      type="button"
      class="btn btn-default mb-3 me-2"
      title="{{ 'shared.actions.create' | translate }}"
      (click)="create()"
      *ngIf="!readonly"
    >
      <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
      {{ 'shared.actions.create' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-default mb-3"
      title="{{ 'shared.actions.edit' | translate }}"
      (click)="edit()"
      [disabled]="!isSelectedProviderEditable"
    >
      {{ 'shared.actions.edit' | translate }}
    </button>
  </div>
  <wp-loading-indicator [loading]="isLoading$ | async"></wp-loading-indicator>
  <wp-grid
    *ngIf="!(isLoading$ | async)"
    [formArray]="providersArray"
    [options]="gridOptions"
    [readonly]="false"
  ></wp-grid>
</form>
