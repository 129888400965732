import { EnumEntity } from '../../enums/enum-entity';

export class AccountingEntryMode extends EnumEntity {
  public static get automatic(): AccountingEntryMode {
    return {
      id: 'c79b5742-322a-415e-9721-ed54692089d1',
      code: 'Automatic',
      name: 'enums.accountingEntryMode.Automatic',
    };
  }

  public static get manual(): AccountingEntryMode {
    return {
      id: '9dd0c035-85cf-4d70-a698-5505aa0829af',
      code: 'Manual',
      name: 'enums.accountingEntryMode.Manual',
    };
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AccountingEntryModes: AccountingEntryMode[] = [
  AccountingEntryMode.automatic,
  AccountingEntryMode.manual,
];
