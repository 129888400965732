import { Navigation } from '../models/navigation/navigation';

export const BILLING_APP_NAVIGATION: Navigation = {
  name: 'billing',
  applicationTitle: 'shared.apps.billing',
  groups: [
    {
      name: 'billing',
      header: 'billing.navigation.billing.header',
      items: [
        {
          name: 'billing.summary',
          state: 'billingSummary',
          header: 'billing.navigation.billing.summary.header',
          hint: 'billing.navigation.billing.summary.hint',
        },

        {
          name: 'billing.invoicesOverdue',
          state: 'invoices',
          header: 'billing.navigation.billing.overdueInvoices.header',
          hint: 'billing.navigation.billing.overdueInvoices.hint',
          entityType: 'Invoice',
          stateParams: {
            view: 'overdue',
          },
        },
        {
          name: 'billing.invoicesAll',
          state: 'invoices',
          header: 'billing.navigation.billing.allInvoices.header',
          hint: 'billing.navigation.billing.allInvoices.hint',
          entityType: 'Invoice',
          stateParams: {
            view: 'all',
          },
        },
      ],
    },
  ],
};
