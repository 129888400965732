import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule, Transition } from '@uirouter/angular';
import { VIEW_NAME, LIST, FILTER } from 'src/app/shared/tokens';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { TimeOffBalanceEntriesListComponent } from './list/time-off-balance-entries-list.component';
import { TimeOffBalanceEntryCreationComponent } from './creation/time-off-balance-entry-creation.component';
import { TimeOffBalanceEntryBodyComponent } from './shared/time-off-balance-entry-body/time-off-balance-entry-body.component';
import { TimeOffBalanceEntryCardComponent } from './card/time-off-balance-entry-card.component';
import { TimeOffBalanceEntryFilterComponent } from './list/filter/time-off-balance-entry-filter.component';
import { TimeOffBalanceEntriesTypeCellComponent } from 'src/app/shared/components/features/time-off-balance-entries-type-cell/time-off-balance-entries-type-cell.component';
import { TIME_OFF_BALANCE_ENTRIES_LIST } from 'src/app/shared/lists/time-off-balance-entries.list';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    TimeOffBalanceEntriesListComponent,
    TimeOffBalanceEntryCreationComponent,
    TimeOffBalanceEntryBodyComponent,
    TimeOffBalanceEntryCardComponent,
    TimeOffBalanceEntryFilterComponent,
    TimeOffBalanceEntriesTypeCellComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'timeOffBalanceEntries',
          params: {
            context: null,
            entity: null,
            routeMode: null,
            routeHeader: 'team.timeOffBalanceEntries.list.header',
            route: ROUTE_DEFAULT_PARAMS,
          },
          url: '/time-off-balance-entries/{view:viewName}?{route}&{navigation}&{filter:json}',
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: TIME_OFF_BALANCE_ENTRIES_LIST },
            {
              token: FILTER,
              deps: [Transition],
              resolveFn: StateHelper.resolveFilter,
            },
          ],
          component: TimeOffBalanceEntriesListComponent,
        },

        {
          name: 'timeOffBalanceEntry',
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          url: '/time-off-balance-entries/{entityId:guid}?{route}&{navigation}',
          component: TimeOffBalanceEntryCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class TimeOffBalanceEntriesModule {}
