import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { bcp47Normalize } from 'bcp-47-normalize';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { SettingsCardService } from 'src/app/settings-app/settings/settings-card.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  TenantSetting,
  TenantSettingPayload,
} from 'src/app/shared/models/entities/settings/multitenant/tenant-settings.model';
import { ForecastPeriod } from 'src/app/shared/models/enums/forecast-period.enum';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { BookingMode } from 'src/app/shared/models/enums/booking-mode.enum';
import {
  AllPlanningScales,
  PlanningScale,
} from 'src/app/shared/models/enums/planning-scale.enum';
import { BookingModeChangeDialogComponent } from 'src/app/settings-app/settings/settings-applications/booking-mode-change-dialog/booking-mode-change-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tmt-settings-applications',
  templateUrl: './settings-applications.component.html',
  styleUrls: ['./settings-applications.component.scss'],
})
export class SettingsApplicationsComponent implements OnInit, OnDestroy {
  public readonly = false;
  public isSaving = false;
  public isRecalculatingActual = false;

  public isAutoCalculationVisible;

  public state: CardState;

  tenantSettings: TenantSetting;
  cultures: NamedEntity[];
  languages: NamedEntity[];
  forecastPeriods: NamedEntity[] = Object.keys(ForecastPeriod).map(
    (k) =>
      ({
        id: k,
        name: this.translate.instant(`enums.forecastPeriod.${k}`),
      }) as NamedEntity,
  );
  bookingModes: NamedEntity[] = Object.keys(BookingMode).map(
    (bm) =>
      ({
        id: bm,
        name: this.translate.instant(`enums.bookingMode.${bm}`),
      }) as NamedEntity,
  );
  bookingScales: NamedEntity[] = Object.keys(PlanningScale)
    .filter(
      (_, index) => index <= AllPlanningScales.indexOf(PlanningScale.Month),
    )
    .map(
      (bs) =>
        ({
          id: bs,
          name: this.translate.instant(`enums.planningScale.${bs}`),
        }) as NamedEntity,
    );

  public form = this.fb.group({
    id: null,
    timeZone: [null, Validators.required],
    language: [null, Validators.required],
    culture: [null, Validators.required],
    currency: [null, Validators.required],
    useVat: [false],
    forecastPeriod: [null, Validators.required],
    bookingMode: [null],
    isBookingScaleFixed: [false],
    bookingScale: [null],
  });

  private reloaded$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  constructor(
    private service: SettingsCardService,
    private actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private message: MessageService,
    private translate: TranslateService,
    private modalService: NgbModal,
  ) {}

  public onSave() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.save();
  }

  private save() {
    this.isSaving = true;
    this.actionService.action('save').start();

    const tenantSetting = this.form.getRawValue() as TenantSetting;

    const setting: TenantSettingPayload = {
      id: tenantSetting.id,
      languageCode: this.tenantSettings.languageCode,
      cultureCode: this.tenantSettings.cultureCode,
      forecastPeriod: this.form.value.forecastPeriod.id,
      timeZoneId: this.tenantSettings.timeZone.id,
      currencyId: this.tenantSettings.currency.id,
      useVat: tenantSetting.useVat,
      passwordSetting: this.tenantSettings.passwordSetting,
      bookingScale:
        this.form.value.isBookingScaleFixed && this.form.value.bookingScale?.id
          ? this.form.value.bookingScale.id
          : null,
    };

    this.data
      .collection('TenantSettings')
      .action('UpdateSingle')
      .execute({ setting })
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.settings.messages.settingSaved',
          );
          location.reload();
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  }

  private load() {
    this.reloaded$.next();
    this.state = CardState.Loading;
    this.form.markAsPristine();
    this.form.markAsUntouched();

    forkJoin({
      cultures: this.data.model.function('GetCultures').get<NamedEntity[]>(),
      languages: this.data.model.function('GetLanguages').get<NamedEntity[]>(),
      settings: this.data
        .collection('TenantSettings')
        .function('GetSingle')
        .get<TenantSetting>(null, {
          select: '*',
          expand: {
            timeZone: { select: ['id', 'name'] },
            currency: { select: ['id', 'name'] },
          },
        }),
    })
      .pipe(takeUntil(this.reloaded$))
      .subscribe({
        next: (data) => {
          this.languages = data.languages;
          this.cultures = data.cultures;

          this.tenantSettings = data.settings;
          this.form.patchValue(data.settings);

          this.form.controls['language'].setValue(
            data.languages.find((l) => l.id === data.settings.languageCode),
          );

          this.form.controls['culture'].setValue(
            data.cultures.find(
              (l) => l.id === bcp47Normalize(data.settings.cultureCode),
            ),
          );

          this.form.controls['forecastPeriod'].setValue(
            this.forecastPeriods.find(
              (r) => r.id === data.settings.forecastPeriod,
            ),
          );

          this.form.controls['bookingMode'].setValue(
            this.bookingModes.find((r) => r.id === data.settings.bookingMode),
          );

          if (data.settings.bookingScale) {
            this.form.controls['isBookingScaleFixed'].setValue(true);
            this.form.controls['bookingScale'].setValue(
              this.bookingScales.find(
                (r) => r.id === data.settings.bookingScale,
              ),
            );
          }
          this.form.controls['currency'].disable();
          this.state = CardState.Ready;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.state = CardState.Error;
        },
      });
  }

  public reload() {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  /** Changes booking mode. */
  public changeBookingMode(): void {
    const modalRef = this.modalService.open(BookingModeChangeDialogComponent);
    const instance =
      modalRef.componentInstance as BookingModeChangeDialogComponent;
    instance.currentBookingMode = this.form.value.bookingMode.name;
    instance.newBookingMode = this.bookingModes.find(
      (x) => x.id !== this.form.value.bookingMode.id,
    ).name;
    modalRef.result.then(
      () => {
        this.load();
      },
      () => null,
    );
  }

  ngOnInit() {
    // Установка главного меню.
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: true,
        handler: () => this.save(),
      },
    ]);

    this.load();

    this.form.controls.isBookingScaleFixed.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.form.controls.bookingScale.addValidators(Validators.required);
          this.form.controls.bookingScale.updateValueAndValidity();
        } else {
          this.form.controls.bookingScale.removeValidators(Validators.required);
          this.form.controls.bookingScale.updateValueAndValidity();
        }
      });

    this.service.reloadTab$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());
  }

  ngOnDestroy(): void {
    this.reloaded$.next();
    this.destroyed$.next();
  }
}
