import { ProjectVersionMergeEnum } from 'src/app/projects/project-versions/project-versions-merge-modal/models/project-version-merge-enum.model';
import { Guid } from 'src/app/shared/helpers/guid';

/** Represents the Project version merge detail diff type. */
export class ProjVersionMergeDetailType extends ProjectVersionMergeEnum {
  /** Add */
  public static get add(): ProjVersionMergeDetailType {
    return {
      id: Guid.generate(),
      code: 'Add',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.detailType.add',
    };
  }

  /** Update */
  public static get update(): ProjVersionMergeDetailType {
    return {
      id: Guid.generate(),
      code: 'Update',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.detailType.update',
    };
  }

  /** Delete */
  public static get delete(): ProjVersionMergeDetailType {
    return {
      id: Guid.generate(),
      code: 'Delete',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.detailType.delete',
    };
  }

  /** Conflict */
  public static get conflict(): ProjVersionMergeDetailType {
    return {
      id: Guid.generate(),
      code: 'Conflict',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.detailType.conflict',
    };
  }
}

export const ProjVersionMergeDetailTypes: ProjVersionMergeDetailType[] = [
  ProjVersionMergeDetailType.add,
  ProjVersionMergeDetailType.update,
  ProjVersionMergeDetailType.delete,
  ProjVersionMergeDetailType.conflict,
];
