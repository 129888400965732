import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { GRADE_LIST } from 'src/app/shared/lists/grade.list';
import { GradeCreationComponent } from './creation/grade-creation.component';
import { GradeCardComponent } from './card/grade-card.component';
import { GradeListComponent } from './list/grades-list.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    GradeCardComponent,
    GradeListComponent,
    GradeCreationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.grades',
          url: '/grades',
          component: GradeListComponent,
          params: {
            navigation: 'settings.grades',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: GRADE_LIST },
          ],
        },

        {
          name: 'settings.grade',
          params: {
            navigation: 'settings.grades',
          },
          url: '/grades/{entityId:guid}',
          component: GradeCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class GradesModule {}
