<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.projects.card.team.assessmentModal.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="toolbar-container" [formGroup]="filterForm">
      <wp-text-box
        style="min-width: 250px; width: 250px"
        formControlName="name"
        placeholder="{{ 'shared.filter.defaultPlaceholder' | translate }}"
      ></wp-text-box>

      <div class="trim avatar-cell" title="{{ user.name }}" style="line-height: 30px">
        <img class="avatar-bg" [src]="user.id | avatar: 60" />{{ user.name }}
      </div>
    </div>
    <div class="row">
      <div class="col" style="position: relative">
        <table class="wp-nested-table wp-nested-table-hover fix-table" [ngStyle]="leftTableStyles">
          <thead>
            <tr>
              <th
                style="width: 100%"
                title="{{
                  'projects.projects.card.team.assessmentModal.columns.availableSkill.hint'
                    | translate
                }}"
              >
                {{
                  'projects.projects.card.team.assessmentModal.columns.availableSkill.header'
                    | translate
                }}
              </th>
              <th
                style="width: 150px"
                title="{{
                  'projects.projects.card.team.assessmentModal.columns.estimation.hint' | translate
                }}"
              >
                {{
                  'projects.projects.card.team.assessmentModal.columns.estimation.header'
                    | translate
                }}
              </th>
              <th style="width: 35px"></th>
            </tr>
          </thead>
        </table>
        <div class="scroll-container">
          <table
            #leftTbl
            (resized)="resizeLeftTbl()"
            class="wp-nested-table wp-nested-table-hover position-relative"
            style="width: 100%; margin: 0"
          >
            <thead>
              <tr>
                <th style="width: 100%">
                  {{
                    'projects.projects.card.team.assessmentModal.columns.availableSkill.header'
                      | translate
                  }}
                </th>
                <th style="width: 150px">
                  {{
                    'projects.projects.card.team.assessmentModal.columns.estimation.header'
                      | translate
                  }}
                </th>
                <th style="width: 35px"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let userSkill of getUserSkills(); index as index">
                <td class="text-cell" title="{{ userSkill.skill.name }}">
                  {{ userSkill.skill.name }}
                </td>
                <td class="control-cell text-center">
                  <ngb-rating [(rate)]="userSkill.level" max="5">
                    <ng-template let-fill="fill">
                      <span class="star" [class.filled]="fill">&#9733;</span>
                    </ng-template>
                  </ngb-rating>
                </td>
                <td class="control-cell" title="{{ 'shared.actions.delete' | translate }}">
                  <button
                    class="btn btn-default remove-button"
                    (click)="removeEstimation(userSkill)"
                  >
                    <span class="bi bi-trash3" aria-hidden="true"></span>
                  </button>
                </td>
              </tr>
              <tr *ngIf="getUserSkills().length == 0 && !isLoading">
                <td colspan="3" class="text-center text-body-secondary">
                  {{ 'shared.noDisplayData' | translate }}
                </td>
              </tr>
            </tbody>
          </table>
          <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
