import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

@Component({
  selector: 'wp-widget-properties',
  templateUrl: './widget-properties.component.html',
  styleUrls: ['./widget-properties.component.scss'],
})
export class WidgetPropertiesComponent {
  @Input() form: UntypedFormGroup;
  @Input() types: NamedEntity[];
}
