/* eslint-disable no-console */

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  // TRACE|DEBUG|INFO|LOG|WARN|ERROR|FATAL|OFF

  public info(message: string) {
    console.info(message);
  }

  public error(message: string) {
    console.error(message);
  }

  public log(message: string) {
    console.info(`%c${message}`, 'color: darkgreen');
  }

  public debug(message: any) {
    if (environment.production) {
      return;
    }

    if (typeof message === 'object' && message !== null) {
      message = JSON.stringify(message);
    }
    console.info(`%c${message}`, 'color: gray');
  }

  /** Tracks view page event.
   *
   * @param name - name of page
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  public trackPageView(name: string) {}
}
