@if (!this.contentLength) {
  <span class="placeholder">
    {{ placeholder | translate }}
  </span>
}

@if (readonly && !this.contentLength) {
  <p class="text-body-secondary text-uppercase">
    {{ 'shared.empty' | translate }}
  </p>
}

<ng-template #popup>
  <div
    class="suggestions"
    [class.suggestions--flex]="(suggestionsLoading$ | async) || !suggestions.length"
    id="suggestions"
  >
    @if (!(suggestionsLoading$ | async)) {
      @for (item of suggestions; track item.id) {
        <div
          class="user"
          [class.user--selected]="item.id === selectedSuggestion?.id"
          [id]="item.id"
          (click)="onSuggestionClick(item)"
          (mouseenter)="selectedSuggestion = item"
          [title]="item.name + '; ' + item.email"
        >
          <div class="user__avatar">
            <img class="avatar-bg" [src]="item.id | avatar: 60" />
          </div>
          <div class="user__name trim">{{ item.name }}</div>
          <div class="user__email trim">{{ item.email }}</div>
        </div>
      } @empty {
        <p class="text-body-secondary text-center m-0">{{ 'shared.noOptions' | translate }}</p>
      }
      @if (loadedPartly) {
        <div class="center-block text-center">
          <p class="text-body-secondary m-0">
            {{ 'shared.showFirstNRecordsMessage' | translate: { limit: loadLimit } }}<br />
            {{ 'shared.refineQueryMessage' | translate }}
          </p>
        </div>
      }
    } @else {
      <wp-loading-indicator [loading]="suggestionsLoading$ | async" class="w-100 p-2" />
    }
  </div>
</ng-template>
