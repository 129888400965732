import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlannerCommandService } from 'src/app/shared-features/planner/core/planner-command.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectCardService } from '../../core/project-card.service';
import { ProjectResourcesEstimateCalendarService } from './core/project-resources-estimate-calendar.service';
import { ProjectResourcesEstimateCalendarDataService } from './core/project-resources-estimate-calendar-data.service';
import { ProjectResourcesCalendarCommandService } from '../shared/core/project-resources-calendar-command.service';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/project-resources.service';

@Component({
  selector: 'wp-project-resources-estimate-calendar',
  templateUrl: './project-resources-estimate-calendar.component.html',
  styleUrls: ['./project-resources-estimate-calendar.component.scss'],
  providers: [
    ProjectResourcesEstimateCalendarService,
    ProjectResourcesEstimateCalendarDataService,
    ProjectResourcesCalendarCommandService,
    ScheduleNavigationService,
    PlannerCommandService,
    FreezeTableService,
    SavingQueueService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectResourcesEstimateCalendarComponent
  implements OnInit, OnDestroy
{
  public isShowTaskDuration: boolean;

  private destroyed$ = new Subject<void>();

  constructor(
    public service: ProjectResourcesEstimateCalendarService,
    public dataService: ProjectResourcesEstimateCalendarDataService,
    public projectCardService: ProjectCardService,
    private changeDetector: ChangeDetectorRef,
    private projectResourcesService: ProjectResourceService,
  ) {}

  ngOnInit(): void {
    this.service.init();
    this.dataService.init();
    this.service.changes$.subscribe(() => this.changeDetector.detectChanges());
    this.projectResourcesService.isShowTaskDuration$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.isShowTaskDuration = value;
      });
  }

  ngOnDestroy(): void {
    this.service.dispose();
    this.destroyed$.next();
  }
}
