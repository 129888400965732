<div [formGroup]="form">
  <div class="form-group">
    <label>{{
      'analytics.dashboards.widgetSettings.properties.showFirstTopElements.label' | translate
    }}</label>
    <wp-number-box
      formControlName="showTop"
      type="integer"
      placeholder="{{
        'analytics.dashboards.widgetSettings.properties.showFirstTopElements.placeholder'
          | translate
      }}"
      [allowNull]="true"
    ></wp-number-box>
  </div>

  <div class="form-group">
    <label>{{ 'analytics.dashboards.widgetSettings.properties.sortBy.label' | translate }}</label>
    <div class="clearfix"></div>
    <div class="btn-group btn-group-toggle mt-2" radioGroup formControlName="sortBy">
      <label radioLabel class="btn-default fw-normal">
        <input radioButton type="radio" value="Category" />{{
          'enums.widgetSortBy.Category' | translate
        }}
      </label>
      <label radioLabel class="btn-default fw-normal">
        <input radioButton type="radio" value="Value" />{{ 'enums.widgetSortBy.Value' | translate }}
      </label>
    </div>
  </div>

  <div class="form-check">
    <input
      formControlName="sortReverse"
      type="checkbox"
      class="form-check-input"
      id="sortReverse"
    />
    <label class="form-check-label" for="sortReverse">
      {{ 'analytics.dashboards.widgetSettings.properties.sortReverse' | translate }}
    </label>
  </div>

  <div class="form-check mt-4">
    <input formControlName="hasLegend" type="checkbox" class="form-check-input" id="hasLegend" />
    <label class="form-check-label" for="hasLegend">
      {{ 'analytics.dashboards.widgetSettings.properties.hasLegend' | translate }}
    </label>
  </div>
  <div class="form-check">
    <input
      formControlName="hasDataLabels"
      type="checkbox"
      class="form-check-input"
      id="hasDataLabels"
    />
    <label class="form-check-label" for="hasDataLabels">
      {{ 'analytics.dashboards.widgetSettings.properties.hasDataLabels' | translate }}
    </label>
  </div>
</div>
