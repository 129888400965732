<tmt-form-header [name]="form.value.name"></tmt-form-header>
<wp-loading-indicator [state]="state"></wp-loading-indicator>
<tmt-not-found [state]="state"></tmt-not-found>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" [hidden]="state !== 'Ready'" class="form mt-3" style="width: 600px">
  <div class="form-group">
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box
      formControlName="name"
      placeholder="{{ 'shared.props.name' | translate }}"
    ></wp-text-box>
  </div>

  <div class="form-group" style="width: 400px">
    <label>{{ 'settings.costNormalizationRules.card.props.method' | translate }}</label>
    <wp-select-box
      formControlName="method"
      [values]="methods"
      placeholder="{{ 'settings.costNormalizationRules.card.props.method' | translate }}"
    >
    </wp-select-box>
  </div>

  <div class="form-group pt-3" *ngIf="form.value.isDefault">
    <p class="form-control-static">
      <strong
        ><i aria-hidden="true" class="bi bi-check-circle"></i>&nbsp;{{
          'settings.costNormalizationRules.card.props.usingByDefault' | translate
        }}</strong
      >
    </p>
  </div>

  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>

  <label class="group mt-3">{{
    'settings.costNormalizationRules.card.props.unpaidTimeOff' | translate
  }}</label>
  <wp-grid
    #grid
    [formArray]="form.controls.excludedTimeOffTypes"
    [options]="gridOptions"
    [readonly]="readonly"
    style="width: 400px; display: block"
  ></wp-grid>

  <div class="actions" *ngIf="!readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
