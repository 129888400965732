import { Component, Input } from '@angular/core';
import { OverviewKpiService } from '../core/overview-kpi.service';
import { KpiCardModel } from './kpi-card.model';

@Component({
  selector: 'wp-kpi-card',
  templateUrl: './kpi-card.component.html',
  styleUrls: ['./kpi-card.component.scss'],
})
export class KpiCardComponent {
  @Input() model: KpiCardModel;

  constructor(public overviewKpiService: OverviewKpiService) {}
}
