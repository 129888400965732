import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { ProjectTaskTimelineService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/core/project-task-timeline.service';

import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';

@Injectable()
export class TimelinePositionService {
  /** Slot min date. */
  public get slotMinDate(): DateTime | null {
    return this.projectTaskTimelineService.slots[0]?.date ?? null;
  }

  /** Current planning scale for timeline. */
  public get planningScale(): PlanningScale {
    return this.projectTaskTimelineService.settings.planningScale;
  }

  /** Timeline slot width based on scale.  */
  public readonly timelineSlotWidth = {
    day: 45,
    week: 56,
    month: 90,
  };

  /** Milestone task strip size in px. */
  public readonly milestoneSize = 18;
  public get halfOfMilestoneSize(): number {
    return this.milestoneSize / 2;
  }

  /** Slot width for current planning scale. */
  public get slotWidth(): number {
    switch (this.planningScale) {
      case PlanningScale.Day:
        return this.timelineSlotWidth.day;
      case PlanningScale.Week:
        return this.timelineSlotWidth.week;
      case PlanningScale.Month:
        return this.timelineSlotWidth.month;
    }
  }

  constructor(public projectTaskTimelineService: ProjectTaskTimelineService) {}

  /**
   * Returns absolute position from left timeline side to target date.
   *
   * @param date target date.
   *
   * @returns position in px or null if have no one slot.
   */
  public getPosition(date: DateTime): number | null {
    if (!this.slotMinDate) {
      return null;
    }
    switch (this.planningScale) {
      case PlanningScale.Day:
        return (
          -this.slotMinDate.diff(date, 'day').days * this.timelineSlotWidth.day
        );
      case PlanningScale.Week:
        return (
          -this.slotMinDate.diff(date, 'week').weeks *
          this.timelineSlotWidth.week
        );
      case PlanningScale.Month:
        return (
          -this.slotMinDate.diff(date, 'month').months *
          this.timelineSlotWidth.month
        );
      default:
        throw new Error(`Invalid scale: ${this.planningScale}`);
    }
  }

  /**
   * Returns width for task strip based of it dates.
   *
   * @param startDate task start date.
   * @param endDate task end date.
   * @param isMilestone is task milestone.
   * @param isLeadTask is task lead.
   *
   * @returns task width in px.
   */
  public getWidth(
    startDate: DateTime,
    endDate: DateTime,
    isMilestone?: boolean,
    isLeadTask?: boolean,
  ): number | null {
    //endDate + 1day needs for last day full painting
    const baseWidth =
      this.getPosition(endDate.plus({ days: 1 })) - this.getPosition(startDate);
    if (!isMilestone) return baseWidth;
    return isMilestone && isLeadTask
      ? baseWidth + this.halfOfMilestoneSize
      : this.milestoneSize;
  }

  /**
   * Returns days count from period from start date to date based on target date plus strip width in px.
   *
   * @param date target date.
   * @param diffX strip width in px.
   *
   * @returns days count.
   */
  public getDays(date: DateTime, diffX: number): number {
    let newDate;
    switch (this.planningScale) {
      case PlanningScale.Day:
        newDate = date.plus({ days: diffX / this.slotWidth });
        break;
      case PlanningScale.Week:
        newDate = date.plus({ weeks: diffX / this.slotWidth });
        break;
      case PlanningScale.Month:
        newDate = date.plus({ months: diffX / this.slotWidth });
        break;
    }
    const days = newDate.diff(date, 'days').days;
    return Math.round(days);
  }

  /**
   * Returns day width in px for target date.
   *
   * @param date target date.
   *
   * @returns width in px.
   */
  public getDayWidth(date: DateTime): number {
    switch (this.planningScale) {
      case PlanningScale.Day:
        return this.timelineSlotWidth.day;
      case PlanningScale.Week:
        return this.timelineSlotWidth.week / 7;
      case PlanningScale.Month:
        return this.timelineSlotWidth.month / date.daysInMonth;
    }
  }
}
