import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewRef,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Wrapper } from '../../wrapper.interface';
import { UserBoxComponent } from 'src/app/shared/components/controls/user-box/user-box.component';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';

@Component({
  selector: 'wp-user-box-wrapper',
  template: `<wp-user-box
    [formControl]="control"
    placeholder="{{ placeholder | translate }}"
    [query]="query"
    [autofocus]="autofocus"
    [initialValue]="initialValue"
    #component
  ></wp-user-box>`,
})
export class UserBoxWrapperComponent implements Wrapper {
  @Input() control: UntypedFormControl;
  @Input() placeholder?: string;
  @Input() query?: any = null;
  @Input() autofocus?: boolean;
  /** Initial value for input element after rendering. */
  @Input() initialValue?: unknown;

  @ViewChild('component') component: UserBoxComponent;

  constructor(
    gridService: GridService,
    private ref: ChangeDetectorRef,
  ) {
    gridService.detectChanges$.subscribe(() => {
      if (!(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
  }
}
