import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { TrendsKpiModule } from 'src/app/shared/components/features/overview/trends-kpi/trends-kpi.module';
import { OverviewKpiModule } from 'src/app/shared/components/features/overview/overview-kpi/overview-kpi.module';
import { OverviewKpiComponent } from 'src/app/shared/components/features/overview/overview-kpi/overview-kpi.component';
import { TrendsKpiComponent } from 'src/app/shared/components/features/overview/trends-kpi/trends-kpi.component';

@NgModule({
  imports: [CommonModule, SharedModule, OverviewKpiModule, TrendsKpiModule],
  exports: [OverviewKpiComponent, TrendsKpiComponent],
})
export class OverviewModule {}
