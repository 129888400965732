import { Component, OnInit, Input, DestroyRef, inject } from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { RoleCardService } from './role-card.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { NotificationService } from 'src/app/core/notification.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { Role } from 'src/app/shared/models/entities/settings/role.model';
import { Exception } from 'src/app/shared/models/exception';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'wp-role-card',
  templateUrl: './role-card.component.html',
  providers: [RoleCardService],
})
export class RoleCardComponent implements OnInit {
  @Input() private entityId: string;

  public readonly = !this.appService.checkEntityPermission(
    'Role',
    PermissionType.Modify,
  );

  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: false,
    isDefault: false,
  });
  public roleResourcePools = [];
  public isSaving$ = new BehaviorSubject<boolean>(false);

  private destroyRef = inject(DestroyRef);

  constructor(
    public roleCardService: RoleCardService,
    private appService: AppService,
    private notificationService: NotificationService,
    private blockUIService: BlockUIService,
    private dataService: DataService,
    private messageService: MessageService,
    private actionPanelService: ActionPanelService,
    private fb: UntypedFormBuilder,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: !this.readonly,
        handler: () => this.save(),
      },
      {
        title: 'shared.actions.useByDefault',
        hint: 'shared.actions.useByDefault',
        name: 'setAsDefault',
        isDropDown: false,
        isBusy: false,
        isVisible: !this.readonly,
        handler: () => this.roleCardService.setAsDefault(),
      },
    ]);

    this.form.controls['name'].valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.roleCardService.updateName(value);
      });

    this.actionPanelService.action('save').isShown = !this.readonly;

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());

    this.roleCardService.role$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((role: Role) => {
        this.form.patchValue(role);
        this.readonly = !role.editAllowed;
        this.readonly ? this.form.disable() : this.form.enable();
      });
  }

  /** Saves Data. */
  public save(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving$.next(true);
    this.blockUIService.start();
    this.actionPanelService.action('save').start();
    const role = <Role>this.form.value;

    const data: any = {
      name: role.name,
      description: role.description,
      isActive: role.isActive,
    };

    this.dataService
      .collection('Roles')
      .entity(this.entityId)
      .patch(data)
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving$.next(false);
          this.blockUIService.stop();
          this.actionPanelService.action('save').stop();
          this.notificationService.successLocal('settings.roles.card.saved');
        },
        error: (error: Exception) => {
          this.isSaving$.next(false);
          this.blockUIService.stop();
          this.actionPanelService.action('save').stop();
          this.notificationService.error(error.message);
        },
      });
  }

  private reload(): void {
    if (!this.form.dirty) {
      this.roleCardService.load();
    } else {
      this.messageService.confirmLocal('shared.leavePageMessage').then(
        () => {
          this.form.markAsPristine();
          this.roleCardService.load();
        },
        () => null,
      );
    }
  }
}
