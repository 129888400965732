import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MermaidSchemaData } from './models/state.model';

@Injectable()
export class MermaidSchemaService {
  private updateSchemaSubject = new Subject<MermaidSchemaData>();
  public updateSchema$ = this.updateSchemaSubject.asObservable();

  public updateSchema = (schemaData: MermaidSchemaData) => {
    this.updateSchemaSubject.next(schemaData);
  };
}
