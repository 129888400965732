<tmt-form-header [name]="name$ | async"></tmt-form-header>
<wp-loading-indicator [state]="state"></wp-loading-indicator>
<tmt-not-found [state]="state"></tmt-not-found>

<form *ngIf="state == 'Ready'" class="form d-block mt-4" style="width: 1000px">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>{{ 'settings.historyLog.card.props.type' | translate }}</label>
        <div class="form-control-plaintext">
          {{ logEntry.type }}
        </div>
      </div>

      <div class="form-group">
        <label>{{ 'settings.historyLog.card.props.entityType' | translate }}</label>
        <div class="form-control-plaintext">
          {{ logEntry.entityType }}
        </div>
      </div>

      <div class="form-group">
        <label>{{ 'settings.historyLog.card.props.entityName' | translate }}</label>
        <div class="form-control-plaintext">
          {{ logEntry.entityName }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>{{ 'settings.historyLog.card.props.created' | translate }}</label>
        <div class="form-control-plaintext">
          {{ logEntry.created | date: 'medium' }}
        </div>
      </div>

      <div class="form-group">
        <label>{{ 'settings.historyLog.card.props.user' | translate }}</label>
        <div class="form-control-plaintext">
          {{ logEntry.user?.name }}
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <label class="group">{{ 'settings.historyLog.card.props.changes' | translate }}</label>

      <table class="table wp-nested-table">
        <thead>
          <tr>
            <th>
              {{ 'settings.historyLog.card.props.property' | translate }}
            </th>
            <th>
              {{ 'settings.historyLog.card.props.oldValue' | translate }}
            </th>
            <th>
              {{ 'settings.historyLog.card.props.newValue' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="logEntry.changes.length == 0">
            <td colspan="3">
              <div class="text-body-secondary text-center">
                {{ 'shared.noDisplayData' | translate }}
              </div>
            </td>
          </tr>
          <tr *ngFor="let change of logEntry.changes">
            <td>
              {{ change.propertyName }}
            </td>
            <td>
              {{ change.oldValue }}
            </td>
            <td>
              {{ change.newValue }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <label class="group">{{ 'settings.historyLog.card.props.attributes' | translate }}</label>
      <table class="table wp-nested-table">
        <thead>
          <tr>
            <th>
              {{ 'settings.historyLog.card.props.entityType' | translate }}
            </th>
            <th>
              {{ 'settings.historyLog.card.props.entityId' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="logEntry.attributes.length == 0">
            <td colspan="2">
              <div class="text-body-secondary text-center">
                {{ 'shared.noDisplayData' | translate }}
              </div>
            </td>
          </tr>
          <tr *ngFor="let attribute of logEntry.attributes">
            <td>
              {{ attribute.entityType }}
            </td>
            <td>
              {{ attribute.entityId }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
