import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { ReportFilterComponent } from './report-filter.component';

@Injectable()
export class ReportFilterService extends FilterService {
  public override component: Type<FilterDetails> = ReportFilterComponent;
  public override hasDetails = true;
  public override views = [
    { code: 'all', local: 'shared.filter.allEntities' },
    { code: 'active', local: 'shared.filter.onlyActiveEntities' },
  ];
  public override hasViewSelector: boolean = this.viewName === 'templates';
  public override hasAllowInactive = false;
  public override placeholder = 'analytics.reports.list.filter.ph';
  public override customCriteriaCount: Record<string, () => number> = {
    text: () => 0,
    view: () => 0,
  };

  protected override getDefaultValues = (): any => ({
    text: '',
    type: null,
    view: this.views[0],
  });

  public override getODataFilter() {
    const result: any[] = [];

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        or: [
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(name)': { contains: text } },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(description)': { contains: text } },
        ],
      });
    }

    if (
      this.values.view &&
      this.values.view.code === 'active' &&
      this.hasViewSelector
    ) {
      result.push({ isActive: true });
    }

    if (this.values.type) {
      result.push({
        reportTypeId: { type: 'guid', value: this.values.type.id },
      });
    }

    return result;
  }

  public override getDatePeriodUrlParams(): Dictionary<string> {
    return null;
  }
}
