<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.programs.card.projects.includingModal.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label>{{
        'projects.programs.card.projects.includingModal.project.label' | translate
      }}</label>
      <wp-select-box
        collection="Projects"
        [query]="query"
        placeholder="{{ 'projects.programs.card.projects.includingModal.project.ph' | translate }}"
        formControlName="project"
      >
      </wp-select-box>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.include' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
