import { Component, OnInit } from '@angular/core';
import { Toolbar } from 'src/app/shared/components/features/grid/toolbar';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { AppService } from 'src/app/core/app.service';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { ProjectTeamService } from '../project-team.service';
import { UntypedFormControl } from '@angular/forms';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';

@Component({
  selector: 'wp-team-toolbar',
  templateUrl: './team-toolbar.component.html',
})
export class TeamToolbarComponent extends Toolbar implements OnInit {
  public feature = Feature;

  public filterControl = new UntypedFormControl(false);

  constructor(
    public app: AppService,
    public service: GridService,
    private projectTeamService: ProjectTeamService,
    private versionCardService: ProjectVersionCardService,
  ) {
    super();
  }

  get isWorkProjectVersion(): boolean {
    return this.versionCardService.isWorkProjectVersion();
  }

  ngOnInit(): void {
    this.filterControl.setValue(this.projectTeamService.settings.onlyActive, {
      emitEvent: false,
    });

    this.filterControl.valueChanges.subscribe(() => {
      this.projectTeamService.setFilter(this.filterControl.value);
    });
  }
}
