import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wp-permission-set-resource-manager',
  templateUrl: './permission-set-resource-manager.component.html',
})
export class PermissionSetResourceManagerComponent {
  @Input() form: UntypedFormGroup;
}
