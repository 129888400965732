<div class="notification-list">
  <ngb-toast
    *ngFor="let notification of notifications; index as index"
    class="toast--{{ notification.type }}"
    [class.toast--link]="notification.onClick"
    [style.top]="notification.position + 'px'"
    [header]="notification.header"
    [autohide]="notification.autohide ?? true"
    [delay]="notification.delay"
    (hidden)="remove(index)"
    (shown)="setHeight(index)"
    (mouseenter)="hoverHandler($event, index)"
    (mouseleave)="hoverHandler($event, index)"
  >
    <div class="toast-content" (click)="clickHandler(notification)">
      <i class="bi {{ notification.icon }}"></i>
      <span>{{ notification.text }}</span>
      <button
        *ngIf="!notification.header"
        type="button"
        aria-label="Close"
        class="btn-close"
        (click)="close(index)"
      ></button>
    </div>
  </ngb-toast>
</div>
