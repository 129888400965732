import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { ProgramOverviewAnalysisComponent } from 'src/app/programs/card/program-overview/program-overview-analysis/program-overview-analysis.component';
import { OverviewModule } from 'src/app/shared/components/features/overview/overview.module';

@NgModule({
  declarations: [ProgramOverviewAnalysisComponent],
  imports: [
    CommonModule,
    SharedModule,
    OverviewModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'program.overview.analysis',
          url: '/analysis',
          component: ProgramOverviewAnalysisComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
    BaseSharedModule,
    CommentsModule,
  ],
})
export class ProgramOverviewAnalysisModule {}
