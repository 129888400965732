import { Type } from '@angular/core';
import { Toolbar } from './toolbar';
import { GridView } from 'src/app/shared/models/inner/grid-view.interface';
import { MenuItem } from 'src/app/core/menu.service';

export interface GridOptions {
  /** Enables mode with horizontal scrolling. */
  modern?: boolean;

  multiSelect?: boolean;

  /** Enables editing of separate cell by double click. */
  dblClickEdit?: boolean;

  css?: string;
  sorting?: boolean;
  freezingHeader?: boolean;
  freezingFooter?: boolean;
  clientTotals?: boolean;
  toolbar?: Type<Toolbar>;
  view?: GridView;
  rowCommands?: Command[];
  commands?: Command[];
  rowContextMenu?: MenuItem[];

  /** Row editing mode.
   *
   * @deprecated "Always" mode is deprecated. Prefer use the 'forceCellUpdating' options for separate column. */
  editing?: 'none' | 'always' | 'onSelect';
}

export interface Command {
  name: string;
  label?: string;
  handlerFn: (param1?: any, param2?: any) => void;
  allowedFn?: (param1?: any, param2?: any) => boolean;
  /** Keycode for attach command to keyboard key in dblClickEdit mode. */
  keyCode?: string;
}

/** Types of pressed btn. */
export enum KeyType {
  symbol,
  digit,
  action,
}
