import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormArray,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import {
  GridColumnType,
  GridSelectControlColumn,
} from 'src/app/shared/models/inner/grid-column.interface';

import { Guid } from 'src/app/shared/helpers/guid';
import { WorkflowCardService } from '../../../workflow-card.service';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowAction } from 'src/app/settings-app/workflows/model/workflow-function.model';
import { WorkflowTransitionConditionType } from 'src/app/settings-app/workflows/model/workflow.enum';

@Component({
  selector: 'wp-transition-conditions',
  templateUrl: './transition-conditions.component.html',
  providers: [GridService],
})
export class TransitionConditionsComponent implements OnInit, OnDestroy {
  @Input() readonly: boolean;
  @Input() actions: WorkflowAction[];
  @Input() functionId: string;
  @Input() localStrings: UntypedFormArray;
  @Input() conditions: UntypedFormArray;

  private destroyed$ = new Subject<void>();
  private conditionTypeValues = [
    {
      id: WorkflowTransitionConditionType.all,
      name: this.translate.instant(
        'settings.workflows.card.transitions.conditionTypes.All',
      ),
      value: WorkflowTransitionConditionType.all,
    },
    {
      id: WorkflowTransitionConditionType.first,
      name: this.translate.instant(
        'settings.workflows.card.transitions.conditionTypes.First',
      ),
      value: WorkflowTransitionConditionType.first,
    },
    {
      id: WorkflowTransitionConditionType.elseAfterAll,
      name: this.translate.instant(
        'settings.workflows.card.transitions.conditionTypes.ElseAfterAll',
      ),
      value: WorkflowTransitionConditionType.elseAfterAll,
    },
  ];

  private getActionsList() {
    return this.actions;
  }

  public get addButtonDisable(): boolean {
    return this.readonly;
  }

  private getConditionsStringsFormGroup() {
    const group = this.fb.group({
      id: Guid.generate(),
      conditionType: [null, Validators.required],
      expectedAction: [null, Validators.required],
    });
    group.controls.conditionType.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((conditionType) => {
        if (conditionType?.id !== 'ElseAfterAll') {
          group.controls.expectedAction.enable();
          group.controls.expectedAction.addValidators(Validators.required);
          group.controls.expectedAction.updateValueAndValidity();
          this.gridService.detectChanges();
        } else {
          group.controls.expectedAction.removeValidators(Validators.required);
          group.controls.expectedAction.setValue(null);
          group.controls.expectedAction.disable();
          group.controls.expectedAction.updateValueAndValidity();
          this.gridService.detectChanges();
        }
      });
    return group;
  }

  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    commands: [
      {
        name: 'create',
        handlerFn: () => this.addString(),
      },
      {
        name: 'delete',
        handlerFn: () => this.deleteString(),
      },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.deleteString(index);
        },
      },
    ],
    view: {
      name: 'strings',
      columns: [
        <GridSelectControlColumn>{
          name: 'conditionType',
          header: 'settings.workflows.card.condition.conditionType',
          hint: 'settings.workflows.card.condition.conditionType',
          type: GridColumnType.SelectControl,
          placeholder: 'settings.workflows.card.condition.conditionType',
          values: this.conditionTypeValues,
        },
        <GridSelectControlColumn>{
          name: 'expectedAction',
          header: 'settings.workflows.card.condition.expectedAction',
          hint: 'settings.workflows.card.condition.expectedAction',
          type: GridColumnType.SelectControl,
          placeholder: 'settings.workflows.card.condition.expectedAction',
          values: () => this.getActionsList(),
        },
      ],
    },
  };

  constructor(
    private fb: UntypedFormBuilder,
    public gridService: GridService,
    public service: WorkflowCardService,
    private translate: TranslateService,
  ) {}

  public load() {
    if (this.conditions.value !== null) {
      this.conditions.value.forEach((string) => {
        const group = this.getConditionsStringsFormGroup();

        group.patchValue({
          conditionType: this.conditionTypeValues.find(
            (condition) => condition.id === string.conditionType,
          ),
          expectedAction: this.actions.find(
            (action) => action.id === string?.expectedActionId,
          ),
        });
        this.localStrings.push(group);
      });
    }
  }

  private subscribeConditionsChanges() {
    this.localStrings.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((localStrings) => {
        this.conditions.setValue(null);
        localStrings.map((localString) => {
          this.conditions.setValue([
            ...(this.conditions?.value || []),
            {
              conditionType: localString?.conditionType?.value || null,
              expectedActionId: localString?.expectedAction?.id || null,
            },
          ]);
        });
      });
  }

  public addString() {
    const group = this.getConditionsStringsFormGroup();

    this.localStrings.push(group);
    this.gridService.selectGroup(group);
  }

  /** Удалить строку. */
  private deleteString(index?: number) {
    const conditions = this.localStrings as FormArray;
    index =
      index ??
      conditions.value.findIndex(
        (s) => s.id === this.gridService.selectedGroup.value.id,
      );

    conditions.removeAt(index);
    conditions.markAsDirty();
  }

  ngOnInit() {
    this.load();
    this.subscribeConditionsChanges();
    this.service.changes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.gridService.detectChanges());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
