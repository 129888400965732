<div ngbDropdown class="d-inline-block" [formGroup]="form">
  <button
    type="button"
    class="btn btn-default without-caret"
    ngbDropdownToggle
    title="{{ 'resources.booking.filter.viewSettings' | translate }}"
    data-test="viewSettings"
  >
    <i class="bi bi-gear" aria-hidden="true"></i>
  </button>

  <div ngbDropdownMenu class="p-3">
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        id="showRemainCapacity"
        formControlName="show"
        value="remainCapacity"
      />
      <label class="form-check-label text-nowrap" for="showRemainCapacity">
        {{ 'resources.booking.filter.properties.showRemainCapacity' | translate }}
      </label>
    </div>

    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        id="showWorkload"
        value="workload"
        formControlName="show"
      />
      <label class="form-check-label text-nowrap" for="showWorkload">
        {{ 'resources.booking.filter.properties.showWorkload' | translate }}
      </label>
    </div>

    @if (
      bookingService.bookingMode === 'Detailed' &&
      !(bookingService.isAssistantBottomMode || bookingService.isAssistantTopMode)
    ) {
      <hr />

      <div class="form-check text-nowrap">
        <input
          formControlName="resourceRequestShown"
          type="checkbox"
          class="form-check-input"
          id="resourceRequestShown"
        />
        <label class="form-check-label" for="resourceRequestShown">
          {{ 'resources.booking.filter.properties.resourceRequestShown' | translate }}
        </label>
      </div>
    }
  </div>
</div>
