import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  inject,
  DestroyRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs';

import { CommentsService } from 'src/app/shared-features/comments/core/comments.service';
import { CommentsSaveService } from 'src/app/shared-features/comments/core/comments-save.service';
import { AttachmentsManagerService } from 'src/app/shared-features/comments/core/attachments-manager.service';
import { CommentsDataService } from 'src/app/shared-features/comments/core/comments-data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';

@Component({
  selector: 'wp-comments-tab',
  templateUrl: './comments-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CommentsService,
    CommentsSaveService,
    AttachmentsManagerService,
    CommentsDataService,
  ],
})
export class CommentsTabComponent implements OnInit {
  @Input() public entityId: string;
  @Input() public collection: string;

  public checkboxControl = new FormControl<boolean>(
    this.commentService.showSystemEvents,
  );

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    public commentService: CommentsService,
    public attachmentsManager: AttachmentsManagerService,
    private commentSaveService: CommentsSaveService,
    private ngbNavService: NgbNav,
    private cdr: ChangeDetectorRef,
    private actionPanelService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.commentService.reload();
      });

    this.commentService.init(this.collection, this.entityId);
    this.attachmentsManager.init(this.collection, this.entityId);

    this.commentService.loading$
      .pipe(
        filter((v) => !v),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdr.detectChanges();
      });

    this.ngbNavService.navChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: NgbNavChangeEvent) => {
        if (
          this.commentSaveService.queueLength &&
          !this.commentSaveService.getConfirmResult()
        ) {
          event.preventDefault();
        }
      });

    this.checkboxControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((v) => {
        this.commentService.setShowSystemEvents(v);
      });
  }
}
