<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

<form class="modal-form" novalidate [formGroup]="form" ngDraggable [hidden]="isLoading">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.projects.copyingProject.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <div class="form-group" tmtIndicator>
      <label>{{ 'projects.projects.copyingProject.props.name' | translate }}</label>
      <wp-text-box
        placeholder="{{ 'shared.props.name' | translate }}"
        formControlName="name"
      ></wp-text-box>
    </div>

    <div class="form-group">
      <label>{{ 'projects.projects.copyingProject.props.code' | translate }}</label>
      <wp-text-box
        style="width: 150px"
        placeholder="{{ 'shared.props.code' | translate }}"
        formControlName="code"
      >
      </wp-text-box>
    </div>

    <div class="form-check">
      <input formControlName="copyTeam" type="checkbox" class="form-check-input" id="copyTeam" />
      <label class="form-check-label" for="copyTeam">
        {{ 'projects.projects.copyingProject.props.copyTeam' | translate }}
      </label>
    </div>

    <div class="form-check">
      <input formControlName="copyTasks" type="checkbox" class="form-check-input" id="copyTasks" />
      <label class="form-check-label" for="copyTasks">
        {{ 'projects.projects.copyingProject.props.copyTasks' | translate }}
      </label>
    </div>

    <div class="form-check" *ngIf="this.form.value.copyTasks && this.form.value.copyTeam">
      <input
        formControlName="copyAssignments"
        type="checkbox"
        class="form-check-input"
        id="copyAssignments"
      />
      <label class="form-check-label" for="copyAssignments">
        {{ 'projects.projects.copyingProject.props.copyAssignments' | translate }}
      </label>
    </div>

    <div class="form-check">
      <input
        formControlName="copyManagers"
        type="checkbox"
        class="form-check-input"
        id="copyManagers"
      />
      <label class="form-check-label" for="copyManagers">
        {{ 'projects.projects.copyingProject.props.copyManagers' | translate }}
      </label>
    </div>

    <div class="form-check" *ngIf="project?.billingType?.code !== 'NonBillable'">
      <input formControlName="copyRates" type="checkbox" class="form-check-input" id="copyRates" />
      <label class="form-check-label" for="copyRates">
        {{ 'projects.projects.copyingProject.props.copyRates' | translate }}
      </label>
    </div>

    <ng-container *ngIf="!project.sourceProject && form.value.copyTasks">
      <hr />
      <div class="form-check">
        <input
          formControlName="saveSourceLink"
          type="checkbox"
          class="form-check-input"
          id="saveSourceLink"
        />
        <label class="form-check-label" for="saveSourceLink">
          {{ 'projects.projects.copyingProject.props.saveSourceLink' | translate
          }}<i
            class="bi bi-question-circle text-help-action ms-1"
            tmtDocsArticle="projects/instructions/inheritance"
          ></i>
        </label>
      </div>
    </ng-container>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.copy' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
