import { DecimalPipe } from '@angular/common';
import { Pipe } from '@angular/core';

import { PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/** Formats values in Percent point. */
@Pipe({
  name: 'wpPercentPoint',
})
export class PercentPointPipe implements PipeTransform {
  private readonly percentPointSymbol = '';

  constructor(
    private translate: TranslateService,
    private decimalPipe: DecimalPipe,
  ) {
    this.percentPointSymbol = this.translate.instant('shared.percentPoint');
  }

  transform(value: any, decimal?: boolean, zeroIfNull?: boolean) {
    const template = decimal === false ? '1.0-0' : '1.0-2';
    let displayValue;

    if (value === null && zeroIfNull) {
      value = 0;
    }

    if (value || value === 0) {
      displayValue = `${this.decimalPipe.transform(value, template)} ${
        this.percentPointSymbol
      }`;
    } else {
      displayValue = null;
    }
    return displayValue;
  }
}
