import { Component, Input, OnDestroy, NgZone } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MessageService } from 'src/app/core/message.service';
import { TranslateService } from '@ngx-translate/core';
import { TransitionService, StateService, Transition } from '@uirouter/core';

/** Индикатор состояния несохраненной формы. */
@Component({
  selector: 'wp-data-not-saved-indicator',
  templateUrl: './data-not-saved-indicator.component.html',
  styleUrls: ['./data-not-saved-indicator.component.scss'],
})
export class DataNotSavedIndicatorComponent implements OnDestroy {
  private disableLeaveControl = false;
  private messageIsOpened = false;
  transitionOnStartListener: any;

  @Input() form: AbstractControl;

  constructor(
    private zone: NgZone,
    private translate: TranslateService,
    private transitionService: TransitionService,
    private state: StateService,
    private messages: MessageService,
  ) {
    // Слушаем закрытие страницы.
    window.onbeforeunload = (e: any) => {
      if (this.form.dirty) {
        const dialogText = translate.instant('shared.leavePageMessage');
        e.returnValue = dialogText;
        return dialogText;
      }
    };

    this.transitionOnStartListener = transitionService.onStart(
      null,
      (trans: Transition) => {
        if (this.form.dirty && !this.disableLeaveControl) {
          if (this.messageIsOpened) {
            return false;
          }

          this.messageIsOpened = true;
          this.messages.confirmLocal('shared.leavePageMessage').then(
            () => {
              this.disableLeaveControl = true;
              const toState = trans.to();
              this.zone.run(() => {
                state.go(toState.name, trans.params());
              });

              this.messageIsOpened = false;
            },
            () => {
              this.messageIsOpened = false;
            },
          );

          return false;
        }
      },
    );
  }
  ngOnDestroy(): void {
    this.transitionOnStartListener();
    window.onbeforeunload = null;
  }
}
