import {
  Component,
  OnInit,
  Input,
  DestroyRef,
  inject,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/core/app.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filterResourceTypes } from 'src/app/projects/card/project-team/add-resources-modal/models/filter-resource-types';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { ListService } from 'src/app/shared/services/list.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { AddResourceModalToolbarComponent } from 'src/app/projects/card/project-team/add-resources-modal/add-resources-modal-toolbar/add-resources-modal-toolbar.component';
import _ from 'lodash';
import { ResourcesFilterService } from 'src/app/shared/modules/resources-filter/resources-filter.service';
import { DataService } from 'src/app/core/data.service';
import { Resource } from 'src/app/shared/models/entities/settings/resource.model';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { RESOURCES_LIST } from 'src/app/resources/resources.list';

/** Диалог добавления пользователей. */
@Component({
  selector: 'tmt-add-resources-modal',
  templateUrl: './add-resources-modal.component.html',
  styleUrls: ['./add-resources-modal.component.scss'],
  providers: [
    { provide: FilterService, useClass: ResourcesFilterService },
    GridService,
    ListService,
    { provide: VIEW_NAME, useValue: 'addResourceModal' },
    { provide: LIST, useValue: RESOURCES_LIST },
  ],
})
export class AddResourcesModalComponent implements OnInit, AfterViewInit {
  @Input() projectId: string;
  /** Resources ids already added in the project team. */
  @Input() existingResourcesIds: string[];

  public loadLimit = 250;
  public isSaving: boolean;
  public isLoading: boolean;
  public loadedPartly: boolean;

  public formArray: UntypedFormArray = this.fb.array([]);
  public gridOptions: Grid2Options = {
    toolbar: AddResourceModalToolbarComponent,
    resizableColumns: true,
    selectionType: SelectionType.row,
    view: this.listService.getGridView(),
    commands: [{ name: 'setUserView', handlerFn: () => this.setUserView() }],
  };

  public availableResources: Resource[] = [];
  public selectedResources: Resource[] = [];

  public readonly filterResourceTypes = filterResourceTypes;

  private resourcesForTeamFilterNames = [
    'department',
    'resourcePool',
    'role',
    'legalEntity',
    'competence',
    'level',
    'grade',
    'location',
  ];
  private loadingSubscription: Subscription;
  private destroyRef = inject(DestroyRef);

  constructor(
    public gridService: GridService,
    public app: AppService,
    private filterService: FilterService,
    private notification: NotificationService,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private listService: ListService,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
  ) {}

  public ngOnInit(): void {
    this.load();

    this.filterService.values$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.load());
  }

  public ngAfterViewInit(): void {
    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((group) => {
        if (!group) return;
        const index = this.formArray.controls.findIndex(
          (control) => control.value.id === group.value.id,
        );
        const resource = this.availableResources.find(
          (resource) => resource.id === group.value.id,
        );

        this.selectResource(resource, index);
      });
  }

  /**
   * Selects resource.
   *
   * @param resource resource.
   * @param index number.
   */
  public selectResource(resource: Resource, index: number): void {
    if (this.selectedResources.length > 29) {
      this.notification.warningLocal(
        'projects.projects.card.team.messages.30limit',
      );
      return;
    }

    this.selectedResources.push(resource);
    this.selectedResources = _.sortBy(this.selectedResources, [
      'resource.name',
    ]);
    this.availableResources.splice(index, 1);
    this.formArray.controls.splice(index, 1);
  }

  /**
   * Removes resource.
   *
   * @param resource resource to unselect.
   * @param index number.
   */
  public removeResource(
    resourceTotal: Resource,
    index: number,
    event: any,
  ): void {
    event.stopPropagation();
    this.selectedResources.splice(index, 1);

    this.availableResources.push(resourceTotal);
    this.availableResources = _.sortBy(this.availableResources, ['name']);

    const group = this.getResourceFormGroup(resourceTotal);
    this.formArray.push(group);
    this.formArray.controls = _.sortBy(this.formArray.controls, [
      'value.resource.name',
    ]);
  }

  /** Saves data. */
  public ok(): void {
    this.isSaving = true;

    const team = [];
    this.selectedResources.forEach((resource: Resource) => {
      const member = {
        resourceId: resource.id,
      };

      ProjectVersionUtil.setEntityRootPropertyId(
        this.versionCardService.projectVersion,
        member,
        this.projectId,
      );

      team.push(member);
    });

    this.versionDataService
      .projectCollectionEntity(
        this.versionCardService.projectVersion,
        this.projectId,
      )
      .action('UpdateTeam')
      .execute({ team })
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.notification.successLocal(
            'projects.projects.card.team.messages.membersWereAdded',
          );
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }

  /** Dismisses changes. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  /** Opens view configuration dialog. */
  public setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
        this.load();
      },
      () => null,
    );
  }

  /** Loads data. */
  private load(): void {
    this.availableResources = [];
    this.isLoading = true;
    this.loadedPartly = false;

    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    const oDataParams = {
      select: ['id', 'name', 'resourceType', 'isActive'],
      expand: [
        { department: { select: ['id', 'name'] } },
        { role: { select: ['id', 'name'] } },
        { resourcePool: { select: ['id', 'name'] } },
        { level: { select: ['id', 'name'] } },
        { grade: { select: ['id', 'name'] } },
        { legalEntity: { select: ['id', 'name'] } },
        { location: { select: ['id', 'name'] } },
        { competence: { select: ['id', 'name'] } },
        { currentRate: { select: ['valueBC'] } },
      ],
      filter: [],
      orderBy: ['name'],
      limit: this.loadLimit.toString(),
    };

    oDataParams.filter.push({
      resourceType: { ne: ResourceType.generic },
    });
    oDataParams.filter.push({ isActive: true });

    if (this.existingResourcesIds?.length) {
      oDataParams.filter.push([
        `not (id in (${this.existingResourcesIds.join(',')}))`,
      ]);
    }

    const resourceTypeConditions = [];
    this.filterResourceTypes.forEach((type) => {
      if (
        this.filterService.values.resourceTypes &&
        this.filterService.values.resourceTypes[type]
      ) {
        resourceTypeConditions.push({
          resourceType: _.upperFirst(type),
        });
      }
    });
    oDataParams.filter.push({ or: resourceTypeConditions });

    if (this.filterService.values.text) {
      oDataParams.filter.push({
        'tolower(name)': {
          contains: this.filterService.values.text.toLowerCase(),
        },
      });
    }

    this.resourcesForTeamFilterNames.forEach((name) => {
      if (!this.filterService.values[name]?.id) return;
      oDataParams.filter.push({
        [`${name}Id`]: {
          type: 'guid',
          value: this.filterService.values[name]?.id,
        },
      });
    });

    this.dataService
      .collection('Resources')
      .query(oDataParams)
      .subscribe({
        next: (resources: Resource[]) => {
          this.formArray.clear();
          this.availableResources = resources.filter(
            (resource) =>
              !this.selectedResources.some((r) => r.id === resource.id),
          );

          this.availableResources.forEach((resource) => {
            const formGroup = this.getResourceFormGroup(resource);

            this.formArray.push(formGroup);
          });

          this.isLoading = false;
          this.loadedPartly = this.availableResources.length === this.loadLimit;
          this.cdr.detectChanges();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoading = false;
        },
      });
  }

  /**
   * Creates a form group for a given resource.
   *
   * @param resource - The resource for which the form group is created.
   * @returns A form group containing the resource details.
   */
  private getResourceFormGroup(resource: Resource): UntypedFormGroup {
    return this.fb.group({
      id: resource.id,
      resource,
      role: resource.role?.name ?? null,
      level: resource.level?.name ?? null,
      department: resource.department?.name ?? null,
      resourcePool: resource.resourcePool?.name ?? null,
      currentRate: resource.currentRate.valueBC,
      competence: resource.competence?.name ?? null,
      grade: resource.grade?.name ?? null,
      legalEntity: resource.legalEntity?.name ?? null,
      location: resource.location?.name ?? null,
      isActive: resource.isActive,
    });
  }
}
