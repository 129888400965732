/** Dependent pair. */
export type CascadeDependency = [MasterItem, SlaveItem];

export interface CascadeItem {
  /** Control name. */
  controlName: string;
  /**
   * Component reference.
   * Only `SelectBox`, `MultiSelectBox` are supported now.
   *
   * @internal
   * */
  component?: BoxControlComponent;
}

export interface MasterItem extends CascadeItem {
  /** Indicates whether to disable depended control. */
  isDisableDepended?: boolean;
}

export interface SlaveItem extends CascadeItem {
  /** Property key that changes with regard to master control. */
  dependedProperty: string;
  /**
   * Indicates whether to reset dependent controls.
   * @internal
   * */
  notReset?: boolean;
}

// TODO: change after DEV-940.
export interface BoxControlComponent {
  /** OData parameters. */
  query: any;
  /** Control name (`formControlName`).  */
  get controlName(): string;
  /** Copy of control values. */
  get allValues(): any[] | undefined | null;
  /** Changes filter in query. */
  changeFilter: (filterPart: any) => void;
  /** Updates data in control. */
  refreshRows: () => Promise<void>;
}
