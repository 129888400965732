import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TimeAllocation } from 'src/app/shared/models/entities/base/timesheet.model';
import { cloneDeep } from 'lodash';

@Injectable()
export class AllocationInfoService {
  private openSubject = new Subject<{
    containerId: string;
    readonly: boolean;
    allocation: TimeAllocation;
    lineId: string;
    dayScheduleDuration: number;
  }>();

  public open$ = this.openSubject.asObservable();

  private closeSubject = new Subject<void>();
  public close$ = this.closeSubject.asObservable();

  private allocationPatchSubject = new Subject<any>();
  public allocationPatch$ = this.allocationPatchSubject.asObservable();

  private redrawSubject = new Subject<void>();
  public redraw$ = this.redrawSubject.asObservable();

  /** Открыть блок информации. */
  public open(
    containerId: string,
    readonly: boolean,
    allocation: TimeAllocation,
    lineId: string,
    dayScheduleDuration?: number,
  ) {
    this.close();
    this.openSubject.next({
      lineId,
      containerId,
      readonly,
      allocation: cloneDeep(allocation),
      dayScheduleDuration,
    });
  }

  /** Закрыть блок информации. */
  public close = () => {
    if (this.closeSubject) {
      this.closeSubject.next();
    }
  };

  /** Изменить распределение. */
  public patchAllocation(patch: any) {
    this.allocationPatchSubject.next(patch);
  }

  public redraw() {
    this.redrawSubject.next();
  }
}
