<form class="form" [formGroup]="form" novalidate>
  <wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

  <wp-resource-roles
    [userId]="entityId"
    [readonly]="readonly"
    resourceType="user"
  ></wp-resource-roles>

  <div class="actions">
    <button
      type="button"
      *ngIf="!readonly && !(resourceRolesService.isLoading$ | async)"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
