import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Injector,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { StringHelper } from 'src/app/shared/helpers/string-helper';
import { ProjectSummaryFilterModalComponent } from 'src/app/project-summary/shared/project-summary-filter/project-summary-filter-modal/project-summary-filter-modal.component';
import { ProjectSummaryFilterService } from 'src/app/project-summary/shared/project-summary-filter/core/project-summary-filter.service';
import { ProjectSummaryFilterSettings } from 'src/app/project-summary/shared/project-summary-filter/models/project-summary-filter.settings';
import { Constants } from 'src/app/shared/globals/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Represents Project Summary Filter content. */
@Component({
  selector: 'tmt-project-summary-filter',
  templateUrl: './project-summary-filter.component.html',
  styleUrls: ['./project-summary-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSummaryFilterComponent implements OnInit {
  /** Gets Filter Conditions count. */
  public get conditionsCount(): number {
    return this._conditionsCount;
  }

  /** Gets Filter Conditions Count localized text. */
  public get conditionCountTitle(): string {
    return this._conditionCountTitle;
  }

  public textControl = this.fb.control('');

  protected readonly textInputMaxLength = Constants.formNameMaxLength;

  private readonly textInputMinLength = 3;

  private filter: ProjectSummaryFilterSettings;
  private _conditionsCount: number;
  private _conditionCountTitle: string;

  private destroyRef = inject(DestroyRef);

  constructor(
    public translate: TranslateService,
    private service: ProjectSummaryFilterService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private cdRef: ChangeDetectorRef,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  /** Opens Modal window to edit filter. */
  public openFilterModal(): void {
    const ref = this.modalService.open(ProjectSummaryFilterModalComponent, {
      size: 'lg',
      injector: this.injector,
    });

    const instance =
      ref.componentInstance as ProjectSummaryFilterModalComponent;
    instance.filter = cloneDeep(this.filter);

    ref.result.then(
      (filter: ProjectSummaryFilterSettings) => {
        this.filter = filter;
        this.filter.searchText = this.textControl.getRawValue();
        this.updateConditionalCount();
        this.service.propagateFilterChange(this.filter);
        this.cdRef.detectChanges();
      },
      () => null,
    );
  }

  /** Clears Text Input value. */
  public clearInput(): void {
    this.textControl.patchValue('');
  }

  /** Inits subscriptions. */
  private initSubscriptions(): void {
    this.service.establishFilter$
      .pipe(
        filter((settings) => !!settings),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.filter = this.service.currentFilter;
        this.textControl.setValue(this.filter.searchText);
        this.updateConditionalCount();
        this.cdRef.detectChanges();
      });

    this.textControl.valueChanges
      .pipe(
        filter((val) => !val.length || val.length >= this.textInputMinLength),
        debounceTime(Constants.textInputDebounce),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((val) => {
        this.filter.searchText = val;
        this.service.propagateFilterChange(this.filter);
      });
  }

  /** Updates Filter conditions count. */
  private updateConditionalCount(): void {
    this._conditionsCount = 0;

    if (this.filter) {
      if (this.filter.project) {
        this._conditionsCount++;
      }
      if (this.filter.user) {
        this._conditionsCount++;
      }

      if (this.filter.projectStates?.length) {
        this._conditionsCount++;
      }

      if (this.filter.program) {
        this._conditionsCount++;
      }

      if (this.filter.client) {
        this._conditionsCount++;
      }
    }

    this._conditionCountTitle = StringHelper.declOfNum(this._conditionsCount, [
      this.translate.instant('shared.filterConditionDeclensions.v1'),
      this.translate.instant('shared.filterConditionDeclensions.v2'),
      this.translate.instant('shared.filterConditionDeclensions.v3'),
    ]);
  }
}
