import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class ResourceRequirementsSettings implements BaseSettings {
  public readonly name = 'ResourceRequirementsSettings';
  version: number;
  planningScale: PlanningScale;
  valueMode: ValueMode;
  viewMode: RequirementsViewMode;

  getDefault(): ResourceRequirementsSettings {
    return {
      version: 2,
      planningScale: PlanningScale.Week,
      valueMode: ValueMode.Hours,
      viewMode: 'default',
    } as ResourceRequirementsSettings;
  }
}

export type RequirementsViewMode = 'default' | 'request-group';
