@if (!service.readonly) {
  <div class="container mx-0">
    @for (transition of transitions.value; track transition.id) {
      <div class="trim">
        <span (click)="editTransition($index)" [ngClass]="{ link: !service.readonly }">
          {{ transition.name }}
        </span>
        <i class="bi bi-arrow-right"></i>
        <span [title]="transition.nextStateName"> {{ transition.nextStateName }}: </span>

        <span [title]="transition.performersString">
          {{ transition.performersString }}
        </span>
        <br />
      </div>
    }
  </div>
}
