import { Component } from '@angular/core';
import { Guid } from 'src/app/shared/helpers/guid';
import { AppService } from 'src/app/core/app.service';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-user-settings-profile',
  templateUrl: './user-settings-profile.component.html',
})
export class UserSettingsProfileComponent {
  public avatarIsLoading: boolean;
  public avatarVersion: string = Guid.generate();
  public userId = this.app.session.user.id;

  constructor(
    private notification: NotificationService,
    private data: DataService,
    private app: AppService,
  ) {}

  public onFileAdded(event: any) {
    const file = event.addedFiles[0];

    if (!file) {
      this.notification.warningLocal(
        'settings.users.card.profile.messages.avatarIsWrong',
      );
      return;
    }

    const formData: FormData = new FormData();
    this.avatarIsLoading = true;
    formData.append('image', file, file.name);

    this.data
      .collection('Users')
      .entity(this.userId)
      .action('WP.UploadAvatar')
      .execute(formData)
      .subscribe({
        next: () => {
          this.avatarIsLoading = false;
          this.notification.successLocal(
            'settings.users.card.profile.messages.userAvatarSaved',
          );
          this.avatarVersion = Guid.generate();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.avatarIsLoading = false;
        },
      });
  }
}
