<div ngbDropdown class="d-inline-block">
  <button
    type="button"
    class="btn btn-default without-caret"
    ngbDropdownToggle
    title="{{ 'shared2.actions.options' | translate }}"
  >
    <i class="bi bi-sliders2 bi-15" aria-hidden="true"></i>
  </button>
  <div ngbDropdownMenu class="p-3" [formGroup]="form">
    @for (item of checkboxes; track item) {
      <div class="form-check text-nowrap">
        <input
          formControlName="{{ item }}"
          type="checkbox"
          class="form-check-input"
          id="{{ item }}"
        />
        <label class="form-check-label" for="{{ item }}">
          {{ 'projects.resources.' + item | translate }}
        </label>
      </div>
    }
  </div>
</div>
