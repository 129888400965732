import { Injectable } from '@angular/core';
import { ResourceSummaryFilterSettings } from 'src/app/resource-summary/shared/resource-summary-filter/models/resource-summary-filter-settings';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalConfigService } from 'src/app/core/local-config.service';

/** Provides methods to work with Resource Summary Filter. */
@Injectable()
export class ResourceSummaryFilterService {
  /** Current Filter Settings. */
  public get currentFilter(): ResourceSummaryFilterSettings {
    return this._currentFilter;
  }

  private filterSubject = new Subject<ResourceSummaryFilterSettings>();
  public filter$ = this.filterSubject.asObservable();

  private establishFilterSubject =
    new BehaviorSubject<ResourceSummaryFilterSettings>(null);
  public establishFilter$ = this.establishFilterSubject.asObservable();

  private _currentFilter: ResourceSummaryFilterSettings;

  constructor(private localConfigService: LocalConfigService) {
    const config = this.localConfigService.getConfig(
      ResourceSummaryFilterSettings,
    );

    this.establishFilter(config);
  }

  /**
   * Saves the filter settings and propagates the change to other components.
   *
   * @param filter Filter Settings.
   * */
  public propagateFilterChange(filter: ResourceSummaryFilterSettings): void {
    this._currentFilter = filter;
    this.filterSubject.next(filter);
    this.saveSettings();
  }

  /**
   * Sets Component Filter.
   * @param filter Filter Settings.
   * @private
   */
  private establishFilter(filter: ResourceSummaryFilterSettings): void {
    this._currentFilter = filter;
    this.establishFilterSubject.next(filter);
  }

  /** Saves the Filter Settings. */
  private saveSettings(): void {
    this.localConfigService.setConfig(
      ResourceSummaryFilterSettings,
      this.currentFilter,
    );
  }
}
