<div *ngIf="formGroup.value.resource?.resourceType === 'User'">
  <div
    *ngIf="formGroup.value.countOfEstimations > 0"
    title="{{ 'projects.projects.card.team.columns.assessmentExist' | translate }}"
  >
    <i
      class="fa fa-check"
      aria-hidden="true"
      [ngClass]="{ active: service.canBeExecuted('estimate') }"
      (click)="service.execute('estimate', formGroup.value)"
    ></i>
  </div>
  <div
    *ngIf="formGroup.value.countOfEstimations === 0"
    title="{{ 'projects.projects.card.team.columns.noAssessment' | translate }}"
  >
    <i
      class="fa fa-question"
      aria-hidden="true"
      [ngClass]="{ active: service.canBeExecuted('estimate') }"
      (click)="service.execute('estimate', formGroup.value)"
    ></i>
  </div>
</div>
