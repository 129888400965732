<tr>
  <td class="group-title">
    <div class="d-flex align-items-center">
      <span
        class="bi group-toggle"
        (click)="onExpand()"
        [ngClass]="{
          'bi-chevron-down': group.isExpanded && hasLines,
          'bi-chevron-right': !group.isExpanded && hasLines
        }"
        aria-hidden="true"
      ></span>
      <span>{{ group.name }}</span>
      <i
        class="group-hint bi bi-question-circle text-gray ms-auto d-flex"
        [ngbTooltip]="getGroupHintKey(group) | translate"
      ></i>
    </div>
  </td>
  <td class="group">
    <div class="d-flex align-items-center">
      <i
        *ngIf="forecastPlanDelta"
        class="bi bi-exclamation-triangle fs-6 me-auto"
        [ngClass]="{
          'text-success': forecastPlanDelta > 0,
          'text-danger': forecastPlanDelta < 0
        }"
        [ngbTooltip]="
          'projects.projects.card.rbc.calendar.groups.warning.delta'
            | translate
              : {
                  delta: forecastPlanDelta | unsigned | number: '1.2-2'
                }
        "
      ></i>
      <label class="ms-auto">
        {{ group.total | zeroToNull | number: '1.2-2' }}
      </label>
    </div>
  </td>
</tr>

<ng-container *ngIf="group.isExpanded">
  <tr *ngFor="let taskLine of group.taskLines; trackBy: trackById">
    <td
      class="trim line-title"
      [ngStyle]="{ 'padding-left': 50 + 15 * taskLine.indent + 'px' }"
      [title]="taskLine.taskFullName"
    >
      {{ taskLine.taskFullName }}
    </td>
    <td class="line">
      {{ taskLine.total | zeroToNull | number: '1.2-2' }}
    </td>
  </tr>

  <!-- Other -->
  <tr *ngIf="group.type !== RbcGroupType.estimate && group.otherLine.total">
    <td class="trim line-title" [title]="group.otherLine.title">
      <div class="d-flex align-items-center">
        <span
          class="bi bi-eye-fill d-flex other-toggle"
          [ngClass]="{ 'text-gray opacity-75': !showOther }"
          (click)="onToggleGroupOther()"
        ></span>
        <span [ngClass]="{ 'text-gray opacity-75': !showOther }">{{ group.otherLine.title }}</span>
        <span
          class="line-hint bi bi-question-circle text-gray ms-auto d-flex"
          *ngIf="group.otherLine.verboseHint"
          [ngbTooltip]="group.otherLine.verboseHint"
        ></span>
      </div>
    </td>
    <td class="line">
      {{ (showOther ? group.otherLine.total : 0) | zeroToNull | number: '1.2-2' }}
    </td>
  </tr>
</ng-container>
