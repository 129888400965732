/* eslint-disable @typescript-eslint/naming-convention */
/** Исключение (на уровне API). */
export class Exception {
  /** Ошибка оптимистической конкуренции. */
  static BtConcurrencyException: Exception = <Exception>{
    code: 'BtConcurrencyException',
    message: 'exceptions.btConcurrencyException',
  };

  /** Сущность не найдена. */
  static BtEntityNotFoundException: Exception = <Exception>{
    code: 'BtEntityNotFoundException',
  };

  /** Ошибка удаления. */
  static BtDeleteException: Exception = <Exception>{
    code: 'BtDeleteException',
  };

  /** Ошибка валидации по бизнес-правилам. */
  static BtValidationException: Exception = <Exception>{
    code: 'BtValidationException',
  };

  /** Неизвестная ошибка. */
  static UnexpectedError: Exception = <Exception>{
    code: 'UnexpectedError',
    message: 'Unexpected error.',
  };

  /** Запрос отменен. */
  static Canceled: Exception = <Exception>{
    code: 'Canceled',
  };

  /** С ЗнО есть пересекающиеся таймшиты в статусе отличном от "Черновик". */
  static TimeOff_CrossNonDraftTimesheetExists: Exception = <Exception>{
    code: 'TimeOff_CrossNonDraftTimesheetExists',
  };

  static BtSubstitutionException: Exception = <Exception>{
    code: 'BtSubstitutionException',
  };

  static ClientValidationException: Exception = <Exception>{
    code: 'ClientValidationException',
  };

  static TeamMember_IsNotTeamMemberOfProject: Exception = <Exception>{
    code: 'TeamMember_IsNotTeamMemberOfProject',
  };

  static Common_NameMustBeUnique: Exception = <Exception>{
    code: 'Common_NameMustBeUnique',
  };

  constructor(code?: string, message?: string) {
    this.code = code;
    this.message = message;
  }

  /** Код ошибки. */
  code: string;

  /** Сообщение. */
  message: string;

  /** Детализация ошибки. */
  details?: Exception[];
}

export class SavingQueueException extends Exception {
  savingQueueId: string;
}
