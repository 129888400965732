import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Wrapper } from '../../wrapper.interface';
import { DateBoxComponent } from 'src/app/shared/components/controls/date-box/date-box.component';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';

@Component({
  selector: 'wp-date-box-wrapper',
  template: `<wp-date-box
    [formControl]="control"
    [allowNull]="allowNull"
    [autofocus]="autofocus"
    [readonly]="readonly"
    [initialValue]="initialValue"
    #component
  ></wp-date-box>`,
})
export class DateBoxWrapperComponent implements Wrapper, OnInit {
  @Input() control: UntypedFormControl;
  @Input() allowNull: boolean;
  @Input() autofocus?: boolean;
  @Input() readonly?: boolean;
  /** Initial value for input element after rendering. */
  @Input() initialValue?: unknown;

  @ViewChild('component') component: DateBoxComponent;

  constructor(
    @Optional() gridService: GridService,
    private ref: ChangeDetectorRef,
  ) {
    if (gridService) {
      gridService.detectChanges$.subscribe(() => {
        if (!(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    }
  }

  ngOnInit(): void {
    if (this.allowNull === undefined) {
      this.allowNull = true;
    }
  }
}
