<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'shared.pnlStatement.filter.header' | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>
            {{ 'shared.pnlStatement.filter.properties.period.label' | translate }}
          </label>
          <wp-date-period-box
            formControlName="period"
            placeholder="{{
              'shared.pnlStatement.filter.properties.period.placeholder' | translate
            }}"
          ></wp-date-period-box>
        </div>

        <div class="form-group">
          <label>
            {{ 'shared.pnlStatement.filter.properties.project.label' | translate }}
          </label>
          <wp-select-box
            collection="Projects"
            [allowInactive]="form.value.allowNonActiveEntity"
            placeholder="{{
              'shared.pnlStatement.filter.properties.project.placeholder' | translate
            }}"
            formControlName="project"
          ></wp-select-box>
        </div>

        <div class="form-group" *ngIf="form.value.project">
          <label>
            {{ 'shared.pnlStatement.filter.properties.task.label' | translate }}
          </label>
          <tmt-task-box
            [projectId]="form.value.project.id"
            placeholder="{{ 'shared.pnlStatement.filter.properties.task.placeholder' | translate }}"
            formControlName="task"
          />
        </div>

        <div class="form-group">
          <label>
            {{ 'shared.pnlStatement.filter.properties.client.label' | translate }}
          </label>
          <wp-select-box
            collection="Organizations"
            [allowInactive]="form.value.allowNonActiveEntity"
            placeholder="{{
              'shared.pnlStatement.filter.properties.client.placeholder' | translate
            }}"
            formControlName="client"
          ></wp-select-box>
        </div>

        <div class="form-group">
          <label>
            {{ 'shared.pnlStatement.filter.properties.program.label' | translate }}
          </label>
          <wp-select-box
            collection="Programs"
            [allowInactive]="form.value.allowNonActiveEntity"
            placeholder="{{
              'shared.pnlStatement.filter.properties.program.placeholder' | translate
            }}"
            formControlName="program"
          ></wp-select-box>
        </div>
      </div>

      <div class="col-3">
        <tmt-state-select formControlName="projectStates" collection="Projects" />
      </div>

      <div class="col-3">
        <label class="control-label">
          {{ 'projects.projects.list.filter.props.billingType' | translate }}
        </label>
        <ng-container formGroupName="billingTypes">
          <div class="form-check" *ngFor="let billingType of billingTypes">
            <input
              [formControlName]="billingType.code"
              type="checkbox"
              class="form-check-input"
              id="{{ billingType.code }}"
            />
            <label class="form-check-label" for="{{ billingType.code }}">
              {{ billingType.name | translate }}
            </label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <button type="button" class="btn btn-default" (click)="clearValues()">
          {{ 'shared.actions.clear' | translate }}
        </button>

        <div class="form-check ms-4 mt-1">
          <input
            formControlName="allowNonActiveEntity"
            type="checkbox"
            class="form-check-input"
            id="allowNonActiveEntity"
          />
          <label class="form-check-label" for="allowNonActiveEntity">
            {{ 'shared2.filters.allowNonActiveEntityToSelected' | translate }}
          </label>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn-primary" (click)="ok()">
          {{ 'shared.actions.ok' | translate }}
        </button>
        <button type="button" class="btn btn-default ms-2" (click)="cancel()">
          {{ 'shared.actions.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
