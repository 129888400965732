<form [formGroup]="form" class="form" style="width: 400px">
  <div class="form-group">
    <label>{{ 'analytics.reports.reportSettings.properties.name.label' | translate }}</label>
    <wp-text-box
      formControlName="name"
      placeholder="{{ 'analytics.reports.reportSettings.properties.name.placeholder' | translate }}"
    ></wp-text-box>
  </div>
  <div class="form-group">
    <label>{{ 'analytics.reports.reportSettings.properties.description.label' | translate }}</label>
    <wp-multiline-text-box
      formControlName="description"
      placeholder="{{
        'analytics.reports.reportSettings.properties.description.placeholder' | translate
      }}"
    >
    </wp-multiline-text-box>
  </div>
  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>
</form>
