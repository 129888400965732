import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { GridColumnType } from 'src/app/shared/models/inner/grid-column.interface';
import { NotificationService } from 'src/app/core/notification.service';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { DataService } from 'src/app/core/data.service';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { UserCardService } from '../user-card.service';
import { MessageService } from 'src/app/core/message.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { UserActivityToolbarComponent } from './user-activity-toolbar/user-activity-toolbar.component';
import { Observable, Subject, Subscription, forkJoin } from 'rxjs';
import { GridComponent } from 'src/app/shared/components/features/grid/grid.component';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { Exception } from 'src/app/shared/models/exception';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wp-user-timesheets',
  templateUrl: './user-timesheets.component.html',
  providers: [GridService],
})
export class UserTimesheetsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() entityId: string;

  public loading: boolean;
  activitiesAreEditable = this.app.checkEntityPermission(
    'UserActivity',
    PermissionType.Modify,
  );
  activitiesAreVisible = this.app.checkEntityPermission(
    'UserActivity',
    PermissionType.Read,
  );
  readonly = !this.app.checkEntityPermission('User', PermissionType.Modify);
  private _isSaving: boolean;
  destroyed$ = new Subject<void>();
  get isSaving() {
    return this._isSaving;
  }
  set isSaving(value: boolean) {
    this._isSaving = value;

    if (value) {
      this.blockUI.start();
      this.actionService.action('save').start();
    } else {
      this.actionService.action('save').stop();
      this.blockUI.stop();
    }
  }
  @ViewChild('grid') grid: GridComponent;
  form: UntypedFormGroup = this.fb.group({
    timeSheetTemplate: [null, Validators.required],
    restrictActivities: [false],
    activities: this.fb.array([]),
  });

  reloadListener: Subscription;
  gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    toolbar: UserActivityToolbarComponent,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => this.activitiesAreEditable,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          (<UntypedFormArray>this.form.controls.activities).removeAt(index);
          this.form.markAsDirty();
        },
      },
    ],
    commands: [
      {
        name: 'add',
        handlerFn: (activity: NamedEntity) => {
          const group = this.fb.group({
            activityName: [activity.name, Validators.required],
            activityId: [activity.id, Validators.required],
            id: [Guid.generate()],
          });
          (<UntypedFormArray>this.form.controls.activities).push(group);
          this.form.markAsDirty();
        },
      },
    ],
    view: {
      name: 'userActivities',
      columns: [
        {
          name: 'activityName',
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.String,
          width: '100%',
        },
      ],
    },
  };

  constructor(
    private notification: NotificationService,
    private gridService: GridService,
    private blockUI: BlockUIService,
    private data: DataService,
    private app: AppService,
    private actionService: ActionPanelService,
    private service: UserCardService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
  ) {}

  public save(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.isSaving = true;

      const observables: Observable<any>[] = [];
      if (!this.readonly) {
        observables.push(
          this.data.collection('Users').entity(this.entityId).patch({
            restrictActivities: this.form.value.restrictActivities,
            timeSheetTemplateId: this.form.value.timeSheetTemplate.id,
          }),
        );
      }
      if (this.activitiesAreEditable) {
        const data = { activities: [] };
        this.form.value.activities.forEach((userActivity: any) => {
          data.activities.push({
            id: userActivity.id,
            userId: this.entityId,
            activityId: userActivity.activityId,
          });
        });

        observables.push(
          this.data
            .collection('Users')
            .entity(this.entityId)
            .action('WP.UpdateActivities')
            .execute(data),
        );
      }

      forkJoin(observables).subscribe({
        next: () => {
          this.notification.successLocal('shared.messages.saved');
          this.form.markAsPristine();
          this.form.markAsUntouched();
          this.isSaving = false;
        },
        error: (error: any) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
    } else {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
    }
  }

  loadProperties() {
    this.loading = true;
    this.actionService.action('save').isShown = false;

    const params = {
      select: ['id', 'restrictActivities'],
      expand: ['timeSheetTemplate'],
    };

    this.data
      .collection('Users')
      .entity(this.entityId)
      .get(params)
      .subscribe({
        next: (data: any) => {
          this.form.patchValue(data);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.readonly ? this.form.disable() : this.form.enable();

          this.form.markAsPristine();
          this.loading = false;

          this.actionService.action('save').isShown =
            !this.readonly || this.activitiesAreEditable;
        },
        error: (error: Exception) => {
          this.loading = false;
          this.notification.error(error.message);
        },
      });
  }

  loadUserActivities() {
    (<UntypedFormArray>this.form.controls.activities).clear();
    this.gridService.setLoadingState(true);

    const query = {
      select: ['id'],
      expand: [{ activity: { select: ['id', 'name'] } }],
      orderBy: ['activity/name'],
    };

    this.data
      .collection('Users')
      .entity(this.entityId)
      .collection('Activities')
      .query(query)
      .subscribe({
        next: (userActivities: any[]) => {
          this.gridService.setLoadingState(false);

          userActivities.forEach((userActivity: any) => {
            const group = this.fb.group({
              activityName: [userActivity.activity.name],
              activityId: [userActivity.activity.id],
              id: [userActivity.id],
            });

            (<UntypedFormArray>this.form.controls.activities).push(group);
          });
        },
        error: (error: any) => {
          this.gridService.setLoadingState(false);
          this.notification.error(error.message);
        },
      });
  }

  private reload() {
    this.form.enable();
    if (!this.form.dirty) {
      this.loadProperties();
      this.loadUserActivities();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => {
          this.loadProperties();
          this.loadUserActivities();
        },
        () => null,
      );
    }
  }

  ngOnInit() {
    this.actionService.run$
      .pipe(
        filter((x) => x.name === 'save'),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.save();
      });

    this.reloadListener = this.actionService.reload$.subscribe(() => {
      this.reload();
    });
    this.loadProperties();
    this.loadUserActivities();
  }

  ngOnDestroy(): void {
    this.reloadListener.unsubscribe();
    this.destroyed$.next();
  }

  ngAfterViewInit(): void {
    this.form.controls['activities'].valueChanges.subscribe(() => {
      (<UserActivityToolbarComponent>this.grid.toolbar).selectedActivities =
        this.form.controls['activities'].value;
    });
  }
}
