<tmt-form-header [name]="gradeCardService.gradeName$ | async" />
<tmt-not-found [state]="gradeCardService.state$ | async" />
<wp-loading-indicator [state]="gradeCardService.state$ | async" />
<wp-data-not-saved-indicator [form]="gradeForm" />

<form [formGroup]="gradeForm" [hidden]="(gradeCardService.state$| async) !== 'Ready'" class="form mt-3" style="width: 600px">
  <div class="form-group" tmtIndicator>
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box
      formControlName="name"
      placeholder="{{ 'shared.props.name' | translate }}"
    />
  </div>

  <div class="form-group" style="width: 150px">
    <label>{{ 'shared.props.code' | translate }}</label>
    <wp-text-box
      formControlName="code"
      placeholder="{{ 'shared.props.code' | translate }}"
    />
  </div>

  <div class="form-group" style="width: 400px" tmtIndicator>
    <label>{{ 'settings.grades.creation.level' | translate }}</label>
    <wp-select-box
      formControlName="level"
      collection="Levels"
      placeholder="{{ 'settings.grades.creation.level' | translate }}"
    />
  </div>

  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>
  @if (!readonly) {
    <div class="actions">
      <button
        type="button"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="isSaving$ | async"
        (click)="save()"
      >
        {{ 'shared.actions.save' | translate }}
      </button>
    </div>
  }
</form>
