import { Injectable } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable({
  providedIn: 'root',
})
export class BlockUIService {
  @BlockUI() blockInterface: NgBlockUI;

  public get isActive(): boolean {
    return this.blockInterface.isActive;
  }

  public start() {
    if (!this.blockInterface.isActive) {
      this.blockInterface.start();
    }
  }
  public stop() {
    this.blockInterface.stop();
  }
}
