<div [formGroup]="form" ngbDropdown class="d-inline-block">
  <button
    type="button"
    class="btn btn-default without-caret"
    ngbDropdownToggle
    title="{{
      'resources.resourceSummary.filter.properties.representationSettings.title' | translate
    }}"
  >
    <i class="bi bi-filter" aria-hidden="true"></i>
  </button>
  <div ngbDropdownMenu class="p-3">
    @for (control of formControls; track control) {
      <div class="form-check">
        <input
          [id]="control"
          [formControlName]="control"
          class="form-check-input"
          type="checkbox"
        />
        <label [for]="control" class="form-check-label text-nowrap">
          {{
            'resources.resourceSummary.filter.properties.representationSettings.' + control
              | translate
          }}
        </label>
      </div>
    }
  </div>
</div>
