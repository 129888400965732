import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BookingManageService {
  private reloadSubject = new Subject<string | null>();
  public reload$ = this.reloadSubject.asObservable();

  /** Обновить бронирования. */
  public reload() {
    this.reloadSubject.next(null);
  }
}
