import { List, LoadingStrategy } from '../models/inner/list';
import {
  GridColumnType,
  GridCurrencyColumn,
  GridNavigationColumn,
} from '../models/inner/grid-column.interface';
import { TotalType } from '../models/inner/total-type';
import {
  getFilterByActiveWorkflowInstances,
  getFilterByActiveWorkflowTasks,
} from 'src/app/shared/lists/navigation.helpers';

export const EXPENSES_LIST: List = {
  version: 4,
  name: 'expenses',
  dataCollection: 'ExpenseRequests',
  customFieldEntityType: 'ExpenseRequest',
  views: [
    {
      name: 'my-all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'project',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'client',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'date',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'totalAmount',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'totalReimbursementBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'totalReimbursementRC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'totalBillable',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
      ],
      order: { column: 'date', reverse: false },
      contextFilter: [{ userId: { type: 'guid', value: '#user' } }],
    },

    {
      name: 'team-all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'project',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'client',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'date',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'user',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'totalAmount',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'totalReimbursementBC',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'totalReimbursementRC',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'totalBillable',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
      ],
      order: { column: 'date', reverse: false },
    },
    {
      name: 'all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'project',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'client',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'date',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'user',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'totalAmount',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'totalReimbursementBC',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'totalReimbursementRC',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'totalBillable',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
      ],
      order: { column: 'date', reverse: false },
    },
    {
      name: 'with-my-task',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'project',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'client',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'user',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'date',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'date', reverse: false },
      contextFilter: [getFilterByActiveWorkflowTasks()],
    },

    {
      name: 'with-my-workflow',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'project',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'client',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'date',
          visibleByDefault: true,
          width: 1,
        },

        {
          column: 'description',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'date', reverse: false },
      contextFilter: [getFilterByActiveWorkflowInstances()],
    },
  ],
  columns: [
    {
      name: 'name',
      type: GridColumnType.Navigation,
      availableTotals: [TotalType.Count],
      header: 'expenses.list.columns.name.header',
      hint: 'expenses.list.columns.name.hint',
      state: 'expensesRequest',
    } as GridNavigationColumn,
    {
      name: 'state',
      header: 'shared.columns.state',
      hint: 'shared.columns.state',
      type: GridColumnType.State,
    },

    {
      name: 'created',
      type: GridColumnType.DateTime,
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
    },
    {
      name: 'project',
      header: 'expenses.list.columns.project.header',
      hint: 'expenses.list.columns.project.hint',
      type: GridColumnType.String,
    },
    {
      name: 'client',
      header: 'expenses.list.columns.client.header',
      hint: 'expenses.list.columns.client.hint',
      type: GridColumnType.String,
    },
    {
      name: 'date',
      header: 'expenses.list.columns.date.header',
      hint: 'expenses.list.columns.date.hint',
      type: GridColumnType.Date,
    },

    {
      name: 'description',
      header: 'expenses.list.columns.description.header',
      hint: 'expenses.list.columns.description.hint',
      type: GridColumnType.String,
    },

    {
      name: 'user',
      header: 'expenses.list.columns.user.header',
      hint: 'expenses.list.columns.user.hint',
      type: GridColumnType.String,
    },

    <GridCurrencyColumn>{
      name: 'totalAmount',
      header: 'expenses.list.columns.totalAmount.header',
      hint: 'expenses.list.columns.totalAmount.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'totalReimbursementBC',
      header: 'expenses.list.columns.totalReimbursementBC.header',
      hint: 'expenses.list.columns.totalReimbursementBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'totalReimbursementRC',
      header: 'expenses.list.columns.totalReimbursementRC.header',
      hint: 'expenses.list.columns.totalReimbursementRC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      currencyCodeProperty: 'reimbursementCurrency',
      type: GridColumnType.Currency,
    },
    {
      name: 'reimbursementCurrency',
      header: 'expenses.list.columns.reimbursementCurrency.header',
      hint: 'expenses.list.columns.reimbursementCurrency.hint',
      type: GridColumnType.String,
    },
    {
      name: 'totalBillable',
      header: 'expenses.list.columns.totalBillable.header',
      hint: 'expenses.list.columns.totalBillable.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
  ],
  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'state',
      field: [
        'state.name',
        'state.code',
        'state.style',
        'state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'project',
      field: 'project.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'client',
      field: 'project.organization.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'date',
      field: 'date',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'user',
      field: 'user.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'totalAmount',
      field: 'totalAmount',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'totalReimbursementBC',
      field: 'totalReimbursementBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'totalReimbursementRC',
      field: 'totalReimbursementRC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'reimbursementCurrency',
      field: 'reimbursementCurrency.alpha3Code',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'totalBillable',
      field: 'totalBillable',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
