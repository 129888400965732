import { Directive, ViewContainerRef } from '@angular/core';

/** Хост для внедрения тулбара. */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[wp-toolbar-host]',
})
export class ToolbarHostDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}
