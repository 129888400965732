import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  first,
} from 'rxjs';
import {
  AutoScrollTarget,
  FreezeTableScrollOptions,
  FreezeTableScrollParams,
} from 'src/app/shared/directives/freeze-table/freeze-table.interface';

@Injectable()
export class FreezeTableService {
  public scroll$ = new Subject<void>();
  public scrollToLeft$ = new Subject<void>();
  public scrollToRight$ = new Subject<void>();
  public scrollToSelector$ = new Subject<FreezeTableScrollOptions>();
  public mutationObserver$ = new Subject<boolean>();
  public leftTableWidth$: BehaviorSubject<number> = new BehaviorSubject(0);

  /** Current scroll position. */
  //TODO: revert to plain value when scroll will emit value after every position changing.
  public currentScrollPosition$ = new BehaviorSubject<number | null>(null);

  /** Scroll to absolute position number. Uses for remember scroll position. */
  private scrollToPositionSubject = new Subject<number>();
  public scrollToPosition$ = this.scrollToPositionSubject.asObservable();

  private redrawSubject = new Subject<void>();
  public redraw$ = this.redrawSubject.asObservable();

  private horizontalScrollSubject = new Subject<FreezeTableScrollParams>();
  public horizontalScroll$ = this.horizontalScrollSubject.asObservable();

  private verticalScrollSubject = new Subject<FreezeTableScrollParams>();
  public verticalScroll$ = this.verticalScrollSubject.asObservable();

  private destroyRef = inject(DestroyRef);

  /** Emits redraw action. */
  public redraw(): void {
    this.redrawSubject.next();
  }

  /** Emits `disableMutationObserver` action. */
  public disableMutationObserver(): void {
    this.mutationObserver$.next(false);
  }

  /** Emits `enableMutationObserver` action. */
  public enableMutationObserver(): void {
    this.mutationObserver$.next(true);
  }

  /** Scroll to left end. */
  public scrollToLeft() {
    this.scrollToLeft$.next();
  }

  /** Scroll to right end. */
  public scrollToRight() {
    this.scrollToRight$.next();
  }

  /**
   * Scroll to selector.
   *
   * @param options ScrollOptions with CSS selector.
   *
   * @example this.freezeTableService.scrollToSelector('th.slot-active');
   */
  public scrollToSelector(options: FreezeTableScrollOptions): void {
    this.scrollToSelector$.next(options);
  }

  /**
   * Table auto scrolling.
   *
   * @param goTo `'left' | 'right'` | ScrollOptions with CSS selector or absolute value in px.
   *
   * */
  public autoScroll(goTo: AutoScrollTarget): void {
    setTimeout(() => {
      this.disableMutationObserver();

      if (goTo === 'left') {
        this.scrollToLeft();
      } else if (goTo === 'right') {
        this.scrollToRight();
      } else if (typeof goTo === 'number') {
        this.scrollToPositionSubject.next(goTo);
      } else {
        this.scrollToSelector(goTo);
      }

      setTimeout(() => {
        this.redraw();
        this.enableMutationObserver();
      }, 500);
    }, 10);
  }

  /**
   * Handles loading. If all are loaded, emits scroll event.
   *
   * @param goTo `'left' | 'right'` | ScrollOptions with CSS selector or absolute value in px.
   * @param observables loading observables.
   * @param delay delay before scroll event is triggered, because component needs time for rendering.
   */
  public scrollOnLoad(
    goTo: AutoScrollTarget,
    observables: Observable<boolean>[],
    delay = 100,
  ): void {
    combineLatest(observables)
      .pipe(
        first((values) => values.every((loading) => !loading)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        setTimeout(() => {
          this.autoScroll(goTo);
        }, delay);
      });
  }

  /**
   * Handles component's horizontal scroll event.
   *
   * @param from Component name.
   * @param value x coordinate.
   */
  public onScrollHorizontal(from: string, value: number): void {
    this.horizontalScrollSubject.next({ from, value });
    this.scroll$.next();
  }

  /**
   * Handles component's vertical scroll event.
   *
   * @param from Component name.
   * @param value y coordinate.
   */
  public onScrollVertical(from: string, value?: number): void {
    this.verticalScrollSubject.next({ from, value });
    this.scroll$.next();
  }
}
