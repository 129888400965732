import { Inject, Injectable } from '@angular/core';
import { shareReplay } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { DataService } from 'src/app/core/data.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

@Injectable()
export class RoleService {
  private cache: Record<string, Observable<NamedEntity[]>> = {};

  constructor(
    private dataService: DataService,
    @Inject('entityId') private timesheetId: string,
  ) {}

  /**
   * Gets project roles from server or cache.
   *
   * @param projectId project's id.
   */
  public getRoles(projectId: string): Observable<NamedEntity[]> {
    if (this.cache[projectId]) {
      return this.cache[projectId];
    } else {
      this.cache[projectId] = this.requestProjectRoles(projectId).pipe(
        shareReplay(1),
      );
      return this.cache[projectId];
    }
  }

  /**
   * Gets list of project roles.
   *
   * @param projectId project's id.
   */
  private requestProjectRoles(projectId: string): Observable<NamedEntity[]> {
    return this.dataService
      .collection('TimeSheets')
      .entity(this.timesheetId)
      .function('GetRoles')
      .get<NamedEntity[]>({ projectId });
  }
}
