<colgroup>
  <col *ngFor="let slot of service.slots; trackBy: trackById" />
</colgroup>
<thead>
  <tr>
    <th
      [attr.colspan]="group.slotsCount"
      *ngFor="let group of service.slotGroups; trackBy: trackById"
      title="{{ group.hint }}"
    >
      {{ group.header }}
    </th>
  </tr>
  <tr>
    <th
      class="slot"
      *ngFor="let slot of service.slots; trackBy: trackById"
      [ngClass]="{ 'slot-active': slot.today }"
      title="{{ slot.hint }}"
      [attr.data-test-date]="slot.date.toISODate()"
    >
      {{ slot.header }}
    </th>
  </tr>
</thead>
