/* eslint-disable @typescript-eslint/naming-convention */
export enum RuleCalculationMethod {
  /** Fixed method type. */
  Fixed = 'Fixed',
  /** Percent method type. */
  Percent = 'Percent',
}

export const RuleCalculationMethods = [
  RuleCalculationMethod.Fixed,
  RuleCalculationMethod.Percent,
];
