import { Component, DestroyRef, Inject, OnInit, inject } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { LifecycleToolbarComponent } from './lifecycle-toolbar/lifecycle-toolbar.component';
import { Exception } from 'src/app/shared/models/exception';
import { State } from 'src/app/shared/models/entities/state.model';
import { LifecycleCardService } from './lifecycle-card.service';
import { MessageService } from 'src/app/core/message.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { Lifecycle } from 'src/app/shared/models/entities/settings/lifecycles/lifecycle.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import {
  GridColumnType,
  GridCommandColumn,
} from 'src/app/shared-features/grid2/models/grid-column.interface';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';

@Component({
  selector: 'tmt-lifecycle',
  templateUrl: './lifecycle-card.component.html',
  styleUrl: './lifecycle-card.component.scss',
  providers: [GridService],
})
export class LifecycleCardComponent implements OnInit {
  public isLoading: boolean;
  public readonly: boolean;
  public lifecycleData: Lifecycle;

  public form: FormGroup = this.fb.group({
    id: '',
    name: '',
    entityType: '',
    states: this.fb.array([]),
  });

  public gridOptions: Grid2Options = {
    toolbar: LifecycleToolbarComponent,
    selectionType: SelectionType.row,
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: (id) => !this.readonly && id,
        handlerFn: (formGroup: FormGroup) => {
          this.service.editState(this.entityId, formGroup.getRawValue()?.id);
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (id) => !this.readonly && id,
        handlerFn: (formGroup: FormGroup) => {
          this.deleteSelectedState(formGroup);
        },
      },
    ],
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          this.service.editState(this.entityId);
        },
      },
      {
        name: 'edit',
        allowedFn: (id: string) => !!id,
        handlerFn: () => {
          this.service.editState(
            this.entityId,
            this.gridService.selectedGroupValue?.id,
          );
        },
      },
      {
        name: 'delete',
        allowedFn: (id: string) => !!id,
        handlerFn: () => {
          this.deleteSelectedState(this.gridService.selectedGroup$.getValue());
        },
      },
    ],
    view: {
      name: 'states',
      columns: [
        <GridCommandColumn>{
          name: 'name',
          command: 'edit',
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.Command,
        },
        {
          name: 'code',
          width: '120px',
          header: 'shared.columns.code',
          hint: 'shared.columns.code',
          type: GridColumnType.String,
        },
        {
          name: 'style',
          width: '120px',
          header: 'settings.lifecycles.card.props.state.style',
          hint: 'settings.lifecycles.card.props.state.style',
          type: GridColumnType.State,
        },
        {
          name: 'isSystem',
          width: '150px',
          header: 'settings.lifecycles.card.props.state.isSystem.label',
          hint: 'settings.lifecycles.card.props.state.isSystem.hint',
          type: GridColumnType.Boolean,
        },
        {
          name: 'isEntityProtected',
          width: '150px',
          header:
            'settings.lifecycles.card.props.state.isEntityProtected.label',
          hint: 'settings.lifecycles.card.props.state.isEntityProtected.hint',
          type: GridColumnType.Boolean,
        },
        {
          name: 'isEntityDisabled',
          width: '150px',
          header: 'settings.lifecycles.card.props.state.isEntityDisabled.label',
          hint: 'settings.lifecycles.card.props.state.isEntityDisabled.hint',
          type: GridColumnType.Boolean,
        },
      ],
    },
  };

  private loadingSubscription;

  private destroyRef = inject(DestroyRef);

  public get states(): UntypedFormArray {
    return this.form.controls.states as UntypedFormArray;
  }

  constructor(
    @Inject('entityId') public entityId: string,
    private notification: NotificationService,
    public service: LifecycleCardService,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private navigationService: NavigationService,
    private actionService: ActionPanelService,
    private gridService: GridService,
  ) {}

  public ngOnInit(): void {
    this.load();

    this.service.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.load();
        this.gridService.selectGroup(null);
      });

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  private getStateFormGroup(): UntypedFormGroup {
    return this.fb.group({
      id: '',
      name: '',
      code: '',
      style: null,
      isSystem: false,
      isEntityProtected: false,
      isEntityDisabled: false,
    });
  }

  private reload(): void {
    this.service.reload();
  }

  private load(): void {
    this.isLoading = true;
    this.states.clear();
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription = this.service
      .getLifecycle(this.entityId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (lifecycle: Lifecycle) => {
          this.lifecycleData = lifecycle;
          this.form.patchValue(lifecycle);

          this.navigationService.addRouteSegment({
            id: this.entityId,
            title: lifecycle.name,
          });

          /* Перебираем состояния */
          (lifecycle.states.sort(naturalSort('name')) as State[]).forEach(
            (state) => {
              const group = this.getStateFormGroup();
              group.patchValue(state);

              group.controls.style.setValue({
                name: state.name,
                code: state.code,
                style: state.style,
              });

              this.states.push(group);
            },
          );

          this.readonly = !lifecycle.editAllowed;

          this.isLoading = false;
        },
        error: (error: Exception) => {
          this.isLoading = false;
          this.notification.error(error.message);
        },
      });
  }

  private deleteSelectedState(formGroup: FormGroup): void {
    this.messageService
      .confirmLocal('settings.lifecycles.card.props.state.deleteConfirmation')
      .then(
        () => {
          this.service
            .deleteState(formGroup.getRawValue()?.id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: () => {
                this.load();
              },
              error: (error: Exception) => {
                this.isLoading = false;
                this.notification.error(error.message);
              },
            });
        },
        () => null,
      );
  }
}
