/* eslint-disable @typescript-eslint/naming-convention */
export enum RuleModalSavingState {
  none = 0,
  saveRuleAndEstimate = 2,
  delete = 3,
}

export const RuleModalSavingStateMap = {
  none: RuleModalSavingState.none,
  saveRuleAndEstimate: RuleModalSavingState.saveRuleAndEstimate,
  delete: RuleModalSavingState.delete,
};
