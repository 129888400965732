import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ResourcesFilterComponent } from 'src/app/shared/modules/resources-filter/resources-filter.component';

@NgModule({
  declarations: [ResourcesFilterComponent],
  imports: [CommonModule, SharedModule],
  exports: [ResourcesFilterComponent],
})
export class ResourcesFilterModule {}
