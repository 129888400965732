<wp-form-header
  [name]="importEntity?.name"
  (reloaded)="reload()"
  [isEditable]="false"
></wp-form-header>
<wp-loading-indicator [state]="state$ | async"></wp-loading-indicator>
<tmt-not-found [state]="state$ | async"></tmt-not-found>

<div class="subheader">
  <div class="subheader__props">
    <div class="subheader__prop">
      <span class="badge" [ngClass]="labelClass">{{ importEntity?.state.name }}</span>
    </div>

    <div class="subheader__prop">
      <span class="subheader__label">{{ 'shared.props.createdBy' | translate }}:</span>
      <div class="subheader__value" id="author">
        <button type="button" (click)="openUserInfo()" class="btn btn-link btn-link-content">
          {{ importEntity?.createdBy?.name }}
        </button>
      </div>
    </div>

    <div class="subheader__prop">
      <span class="subheader__label"> {{ 'shared.props.created' | translate }}:</span>
      <span class="subheader__value">{{ importEntity?.created | date: 'short' }}</span>
    </div>
  </div>
</div>

<wp-entity-list
  [nested]="true"
  [hasViewSettings]="false"
  [hidden]="(state$ | async) !== 'Ready'"
></wp-entity-list>
