<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
<form class="form" style="width: 1100px" [hidden]="isLoading" [formGroup]="accessForm" novalidate>
  <wp-data-not-saved-indicator [form]="accessForm"></wp-data-not-saved-indicator>

  <p class="text-body-secondary">{{ 'settings.users.card.access.help' | translate }}</p>

  <table class="wp-nested-table w-50 m-0">
    <thead>
      <tr>
        <th style="width: 200px">
          {{ 'settings.users.card.access.role' | translate }}
        </th>
        <th style="width: 100%">
          {{ 'settings.users.card.access.permissionSet' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let role of roles; let i = index">
        <td class="text-cell">{{ role.name | translate }}:</td>
        <td class="control-cell">
          <wp-select-box
            collection="PermissionSets"
            [formControlName]="i"
            [query]="$any(role).query"
            placeholder="{{ 'settings.users.card.access.permissionSetNotSelected' | translate }}"
          >
          </wp-select-box>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="actions">
    <button
      type="button"
      *ngIf="!readonly"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
