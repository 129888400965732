<div
  class="request__calendar mt-3"
  [class.request__calendar--booking]="!isBookingHidden || mode === 'assistant'"
  [id]="freezeTableOptions.rootContainerId"
>
  @if (hasToolbar) {
    <div class="toolbar-container">
      <wp-schedule-navigation />

      @if (mode !== 'create' && mode !== 'menuCreate') {
        <div role="group" class="btn-group">
          <input
            id="switcher-current"
            class="btn-check ng-untouched ng-pristine ng-valid"
            type="radio"
            [formControl]="switcherControl"
            [value]="'new'"
          />
          <label
            for="switcher-current"
            class="btn btn-default"
            [title]="'resources.actions.showNew' | translate"
          >
            {{ 'resources.actions.showNew' | translate }}
          </label>
          <input
            id="switcher-new"
            class="btn-check ng-untouched ng-pristine ng-valid"
            type="radio"
            [formControl]="switcherControl"
            [value]="'current'"
          />
          <label
            for="switcher-new"
            class="btn btn-default"
            [title]="'resources.actions.showCurrent' | translate"
          >
            {{ 'resources.actions.showCurrent' | translate }}
          </label>
        </div>
      }
    </div>
  }
  <div
    wpFreezeTable
    leftWidthStrategy="byTable"
    rightWidthStrategy="byTable"
    [inContainerMode]="freezeTableOptions.inContainerMode"
    [rootContainerId]="freezeTableOptions.rootContainerId"
    [name]="freezeTableOptions.name"
    [syncWith]="freezeTableOptions.syncWith"
    [disableHorizontalScroller]="freezeTableOptions.disableHorizontalScroller"
    class="disable-selecting"
  >
    <div name="left">
      <div name="scroll-table-header">
        <table
          class="table wp-nested-table"
          [style.width]="resourceRequestCalendarService.leftTableWidth + 'px'"
        >
          <thead>
            <tr>
              <th class="trim" colspan="2" rowspan="2">
                {{ mode === 'assistant' ? request.resourceDescription ?? request.role?.name : '' }}
              </th>
              <th title="{{ 'projects.projects.card.resources.columns.hours.hint' | translate }}">
                Σ
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <div name="scroll-table-body">
        <table
          class="table wp-nested-table"
          [style.width]="resourceRequestCalendarService.leftTableWidth + 'px'"
        >
          @if (!pendingRequest) {
            <tbody>
              @for (row of lines; track row.id) {
                <tr class="request" [class.result]="row.id === 'result' && mode !== 'assistant'">
                  @if (row.id === 'result') {
                    <td class="content trim" colspan="2">
                      {{
                        (mode === 'assistant'
                          ? 'resources.requests.props.deviationHeader'
                          : 'resources.requests.props.resultHeader'
                        ) | translate
                      }}
                    </td>
                  }

                  @if (row.id === 'request') {
                    <td class="content trim" colspan="2">
                      <div class="request__title-block">
                        <div class="request__title-text">
                          <span>
                            {{ 'resources.booking.entryForm.props.resourceRequest' | translate }}
                          </span>
                        </div>

                        @if (isDeviation) {
                          <i
                            class="bi bi-exclamation-triangle-fill text-danger"
                            title="{{ 'resources.requests.hint.deviation' | translate }}"
                          ></i>
                        }

                        @if (requestWithResourcePlan) {
                          <button
                            (click)="resetData()"
                            class="btn btn-default request__button request__button--update"
                            [title]="'resources.requests.hint.update' | translate"
                          >
                            <i class="bi bi-arrow-repeat bi-15"></i>
                          </button>
                        }
                      </div>
                    </td>
                  }

                  <td class="request__total-cell">
                    {{ row.total | plannerFormat }}
                  </td>
                </tr>
              }
            </tbody>
          }
        </table>
      </div>

      <div name="scroll-table-footer"></div>
    </div>

    <div
      name="right"
      [ngStyle]="{ 'margin-left': resourceRequestCalendarService.leftTableWidth + 'px' }"
    >
      <div name="scroll-table-header">
        <table
          class="table wp-nested-table"
          [style.width]="resourceRequestCalendarService.rightTableWidth + 'px'"
        >
          <thead>
            <tr>
              @for (group of resourceRequestCalendarService.slotGroups; track group.id) {
                <th [attr.colspan]="group.slotsCount" title="{{ group.hint }}">
                  {{ group.header }}
                </th>
              }
            </tr>
            <tr>
              @for (slot of resourceRequestCalendarService.slots; track slot) {
                <th
                  class="slot"
                  [ngClass]="{ 'slot-active': slot.today }"
                  title="{{ slot.hint }}"
                  [attr.data-test-date]="slot.date.toISODate()"
                >
                  {{ slot.header }}
                </th>
              }
            </tr>
          </thead>
        </table>
      </div>

      <div name="scroll-table-body">
        <table
          #board
          class="table wp-nested-table"
          [style.width]="resourceRequestCalendarService.rightTableWidth + 'px'"
        >
          @if (!pendingRequest) {
            <tbody>
              @for (row of lines; track row.id; let rowIndex = $index) {
                <tr
                  [class.result]="row.id === 'result' && mode !== 'assistant'"
                  [class.disabled]="
                    (row.resource && mode !== 'assistant') ||
                    (!row.resource && mode === 'assistant')
                  "
                >
                  @for (
                    entry of $any(formLines.at(rowIndex))?.controls;
                    track entry;
                    let dayIndex = $index
                  ) {
                    <td
                      wpPlannerCell
                      class="cell"
                      [class.cell-valid]="isValidValue(dayIndex, row, entry.value)"
                      [valueMode]="resourceRequestCalendarService.valueMode"
                      [formControl]="entry"
                      [lineIndex]="rowIndex"
                      [dayIndex]="dayIndex"
                      [scale]="resourceRequestCalendarService.planningScale"
                      [class.non-working]="entry.value?.nonWorking"
                      [title]="
                        isValidValue(dayIndex, row, entry.value)
                          ? ('resources.requests.hint.noDeviation' | translate)
                          : entry.value.hours
                      "
                      [attr.data-test-date]="entry.value?.date"
                    ></td>
                  }
                </tr>
              }
            </tbody>
          }
        </table>
      </div>

      <div name="scroll-table-footer"></div>
    </div>
  </div>

  <div
    *ngIf="isBookingEntryViewAllowed()"
    class="request__booking"
    [hidden]="pendingBooking || (isBookingHidden && mode !== 'assistant')"
  >
    <wp-booking
      [frName]="bookingFreezeTableOptions.name"
      [frSyncWith]="bookingFreezeTableOptions.syncWith"
      [frDisableHorizontalScroller]="bookingFreezeTableOptions.disableHorizontalScroller"
      [hasHeader]="false"
      [hasToolbar]="false"
    ></wp-booking>
  </div>

  <wp-loading-indicator [loading]="pendingRequest || pendingBooking"></wp-loading-indicator>
</div>
