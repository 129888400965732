import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

@Component({
  selector: 'wp-user-box-test',
  templateUrl: './user-box-test.component.html',
})
export class UserBoxTestComponent {
  form = this.fb.group({
    user: [null, Validators.required],
    userNullNotAllowed: [null, Validators.required],
  });

  constructor(private fb: UntypedFormBuilder) {}

  setValue() {
    this.form.setValue({
      user: <NamedEntity>{ name: 'user', id: '1' },
      userNullNotAllowed: <NamedEntity>{ name: 'user', id: '1' },
    });
  }
}
