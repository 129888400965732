import { ResourceViewGroupLineEntry } from 'src/app/projects/card/project-resources/models/project-resources-view.model';
import { DateHours } from 'src/app/shared/models/entities/date-hours.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

export type ResourceRequestMode =
  | 'create'
  | 'edit'
  | 'result'
  | 'menuCreate'
  | 'assistant';

export type TotalsCalcMode = 'new' | 'current';

export interface ResourceRequestDateEntries {
  [id: 'request' | 'result' | string]: ResourceViewGroupLineEntry[];
}

export interface ResourceRequestLine {
  id: string;
  total: number;
  resource?: NamedEntity;
}

export interface BookingEntryHours extends DateHours {
  bookingEntryId?: string;
}

export interface EventItem<T = unknown> {
  name: ResourceRequestEvents;
  data?: T;
}

export enum ResourceRequestEvents {
  loadFrame = 'LOAD_FRAME',
  reloadFrame = 'RELOAD_FRAME',
  pendingFrame = 'PENDING_FRAME',
  updatedRequest = 'UPDATED_REQUEST',
  addedBookingEntry = 'ADDED_BOOKING_ENTRY',
  removedBookingEntry = 'REMOVED_BOOKING_ENTRY',
  updateBookingEntry = 'UPDATED_BOOKING_ENTRY',
  loadedBookingEntry = 'LOADED_BOOKING_ENTRY',
  bookingEntryLineToggled = 'BOOKING_ENTRY_LINE_TOGGLED',
}
