<div class="resource-summary__filter input-group">
  <div>
    <input
      class="resource-summary__filter-input form-control ellipsis"
      type="text"
      [formControl]="textControl"
      [maxLength]="textInputMaxLength"
      placeholder="{{ 'resources.resourceSummary.filter.properties.name.placeholder' | translate }}"
    />
    <span class="input-clear" [hidden]="!textControl.value" (click)="clearInput()">&times;</span>
  </div>
  <button
    type="button"
    title="{{ 'resources.resourceSummary.filter.hint' | translate }}"
    class="btn btn-default resource-summary__filter-text"
    (click)="openFilterModal()"
  >
    <span *ngIf="conditionsCount > 0"> {{ conditionsCount }}&nbsp;{{ conditionCountTitle }} </span
    ><i class="bi bi-sliders2 bi-15" aria-hidden="true"></i>
  </button>
</div>
