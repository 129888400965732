import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(private translate: TranslateService) {}

  /**
   * Returns the error tooltip message based on the provided validation errors.
   *
   * @param errors The validation errors object.
   * @returns The error tooltip message or null if no errors.
   */
  public getErrorTooltip(errors: ValidationErrors | null): string | null {
    if (!errors) return null;
    let errorString = '';
    for (const key of Object.keys(errors)) {
      errorString +=
        this.translate.instant(`shared.validatorErrors.${key}`, errors[key]) +
        '\n';
    }
    return errorString;
  }
}
