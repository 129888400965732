import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ResourceSummaryRepresentationSettings } from 'src/app/resource-summary/shared/resource-summary-representation-settings/models/resource-summary-representation-settings.settings';
import { LocalConfigService } from 'src/app/core/local-config.service';

/** Provides methods to work with Resource Summary View Settings. */
@Injectable()
export class ResourceSummaryRepresentationService {
  /** Gets View Settings. */
  public get settings(): ResourceSummaryRepresentationSettings {
    return this._settings;
  }

  private settingsSubject =
    new Subject<ResourceSummaryRepresentationSettings>();
  public settings$ = this.settingsSubject.asObservable();

  private _settings: ResourceSummaryRepresentationSettings;

  constructor(private localConfigService: LocalConfigService) {
    this._settings = this.localConfigService.getConfig(
      ResourceSummaryRepresentationSettings,
    );
  }

  /**
   * Saves the settings and propagates the change to other components.
   *
   * @param settings Settings.
   * */
  public propagateSettingsChange(
    settings: ResourceSummaryRepresentationSettings,
  ): void {
    this._settings = settings;
    this.settingsSubject.next(settings);
    this.saveSettings();
  }

  /** Saves the settings. */
  private saveSettings(): void {
    this.localConfigService.setConfig(
      ResourceSummaryRepresentationSettings,
      this.settings,
    );
  }
}
