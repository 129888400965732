import {
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ChromeService } from 'src/app/core/chrome.service';
import { TimeOffInfoService } from './time-off-info/time-off-info.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { TimeOffScheduleFiltersService } from './time-off-schedule-filters/time-off-schedule-filters.service';
import { takeUntil } from 'rxjs/operators';
import { TimeOffScheduleSettings } from './shared/time-off-schedule-settings';
import { DateTime, DurationLike, Interval } from 'luxon';
import { TimeOffScheduleDataService } from './time-off-schedule-data.service';
import { TimeOffInfoComponent } from './time-off-info/time-off-info.component';
import { TimeOffCreationComponent } from 'src/app/time-off-requests/creation/time-off-creation.component';
import { VisibleParts } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-parts.enum';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import {
  Slot,
  SlotGroup,
} from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

/** График отсутствий. */
@Component({
  selector: 'wp-time-off-schedule',
  templateUrl: './time-off-schedule.component.html',
  styleUrls: ['./time-off-schedule.component.scss'],
  providers: [
    TimeOffScheduleDataService,
    TimeOffInfoService,
    { provide: FilterService, useClass: TimeOffScheduleFiltersService },
    ScheduleNavigationService,
    FreezeTableService,
  ],
})
export class TimeOffScheduleComponent implements OnInit, OnDestroy {
  /** Видимые части scheduleNavigation компонента */
  public scheduleNavigationVisibleParts = [VisibleParts.calendar];
  /** Ширина левой части таблицы */
  public leftTableWidth = 265;
  /** Слоты таблицы */
  public slots: Slot[] = [];
  /** Группы таблицы */
  public slotGroups: SlotGroup[];
  /** Данные из ScheduleNavigation компонента */
  private settings: TimeOffScheduleSettings;
  /** Текущий интервал */
  private interval: Interval;
  /** Subject для отписки */
  private destroySubject = new Subject<void>();

  constructor(
    public actionService: ActionPanelService,
    private modalService: NgbModal,
    private chrome: ChromeService,
    private localConfigService: LocalConfigService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private filterService: FilterService,
    private scheduleNavigationService: ScheduleNavigationService,
    private blockUI: BlockUIService,
    private freezeTableService: FreezeTableService,
    public dataService: TimeOffScheduleDataService,
    private titleService: Title,
    private translate: TranslateService,
  ) {}

  /**
   * Загрузка фрейма
   *
   * @param direction - сторона с которой загружается фрейм
   * */
  private loadFrame(direction: 'left' | 'right') {
    this.blockUI.start();

    let shift: DurationLike;

    switch (this.settings.planningScale) {
      case PlanningScale.Day:
        shift = { weeks: 2 };
        break;
      case PlanningScale.Week:
        shift = { weeks: 10 };
        break;
      case PlanningScale.Month:
        shift = { month: 5 };
        break;
      case PlanningScale.Quarter:
        shift = { year: 1 };
        break;
      case PlanningScale.Year:
        shift = { year: 2 };
        break;
    }

    let loadingInterval =
      direction === 'left'
        ? Interval.fromDateTimes(
            this.interval.start.minus(shift),
            this.interval.start.minus({ days: 1 }),
          )
        : Interval.fromDateTimes(
            this.interval.end.plus({ days: 1 }),
            this.interval.end.plus(shift),
          );

    this.interval =
      direction === 'left'
        ? this.interval.set({
            start: this.interval.start.minus(shift),
          })
        : this.interval.set({
            end: this.interval.end.plus(shift),
          });

    if (
      this.settings.planningScale === PlanningScale.Month &&
      direction === 'right'
    ) {
      loadingInterval = loadingInterval.set({
        end: loadingInterval.end.endOf('month'),
      });
      this.interval = this.interval.set({
        end: this.interval.end.endOf('month'),
      });
    }

    this.dataService.loadFrame(direction, loadingInterval).subscribe(() => {
      this.updateDates();
      this.blockUI.stop();
      setTimeout(() => {
        this.freezeTableService.disableMutationObserver();
        if (direction === 'left') {
          this.freezeTableService.scrollToLeft();
        } else {
          this.freezeTableService.scrollToRight();
        }
        setTimeout(() => {
          this.freezeTableService.enableMutationObserver();
        }, 500);
      }, 10);
    });
  }

  /**
   * Загрузка текущей страницы - пользователи и расписание
   *
   * */
  private loadPage() {
    if (this.dataService.allIsLoaded || this.dataService.loading) {
      return;
    }

    this.updateDates();
    this.dataService.loadPage(this.interval);
  }

  /** Обновляет слоты */
  private updateDates() {
    const slotInfo = this.scheduleNavigationService.getSlots(
      this.interval,
      this.settings.planningScale,
    );
    this.slots = slotInfo.slots;
    this.slotGroups = slotInfo.groups;
  }

  /** Обновляет ширину таблицы */
  public getDataTableWidth() {
    const slotWidth = this.getSlotWidth(this.settings.planningScale);
    return this.leftTableWidth + slotWidth * this.slots.length;
  }

  /** Функция для получения ширины слота
   *
   * @param scale - текущий scale
   * */
  public getSlotWidth(scale: PlanningScale): number {
    switch (scale) {
      case PlanningScale.Day:
        return 45;
      case PlanningScale.Week:
        return 55;
      case PlanningScale.Month:
        return 65;
    }
  }

  /** Открытие сведений о пользователей. */
  public openUserInfo(userId: string) {
    /* Привязка к элементу, у которого виден центр. По умолчанию span */
    const userNameDiv = document.getElementById(userId);
    const userNameDivWidth = userNameDiv.offsetWidth;

    const userNameSpan = document.getElementById(`${userId}-name`);
    const userNameSpanWidth = userNameSpan.offsetWidth;

    const target =
      userNameDivWidth < userNameSpanWidth ? userNameDiv : userNameSpan;
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /** Открытие сведений о дне. */
  public openInfo(day, target: ElementRef) {
    if (day.timeOffRequest) {
      this.infoPopupService.open({
        target,
        data: {
          component: TimeOffInfoComponent,
          params: {
            request: day.timeOffRequest,
          },
          injector: this.injector,
        },
      });
    }
  }

  public createTimeOffRequest() {
    this.modalService.open(TimeOffCreationComponent, {
      size: 'lg',
    });
  }

  /** Enables lazy-loading on scroll event. */
  public enableInfinityScroll(): void {
    this.dataService.scrollSubscription = this.chrome.setInfinityScroll(() =>
      this.loadPage(),
    );
  }

  public trackId = (index: number, item: any) => item.id;

  public reload(toDate?: DateTime) {
    this.settings = this.localConfigService.getConfig(TimeOffScheduleSettings);
    this.dataService.reload();
    this.interval = this.scheduleNavigationService.getInterval(
      this.settings.planningScale,
      toDate,
    );
    this.loadPage();

    if (!this.dataService.scrollSubscription) {
      this.enableInfinityScroll();
    }
  }

  ngOnInit(): void {
    const pageTitle = this.translate.instant(
      'team.navigation.timeOff.schedule.header',
    );
    this.titleService.setTitle(pageTitle);

    this.actionService.reload$
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => this.reload());

    this.filterService.values$
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.reload();
      });

    this.scheduleNavigationService.previous$
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.loadFrame('left');
      });
    this.scheduleNavigationService.next$
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.loadFrame('right');
      });
    this.scheduleNavigationService.jump$
      .pipe(takeUntil(this.destroySubject))
      .subscribe((date) => {
        this.reload(date);
      });

    this.reload();

    this.actionService.set([
      {
        title: 'team.timeOffSchedule.actions.addTimeOff.label',
        hint: 'team.timeOffSchedule.actions.addTimeOff.hint',
        name: 'addTimeOff',
        iconClass: 'bi bi-plus-lg bi-15',
        isDropDown: false,
        isBusy: false,
        isVisible: true,
        handler: () => this.createTimeOffRequest(),
      },
    ]);
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
  }
}
