<div class="form-group">
  <label>{{ 'settings.workflows.card.function.nextFunction' | translate }}</label>
  <wp-select-box
    [values]="workflowFunctionsOptions"
    [allowNull]="allowNull"
    placeholder="{{ 'settings.workflows.card.function.nextFunction' | translate }}"
    [isIdMode]="true"
    [formControl]="nextFunctionId"
  ></wp-select-box>
</div>
