import {
  Component,
  OnInit,
  Input,
  Injector,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  DestroyRef,
  inject,
} from '@angular/core';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { IssueCardService } from 'src/app/issues/card/issue-card.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { tap } from 'rxjs';
import { FormHeaderService } from 'src/app/shared/components/chrome/form-header2/form-header.service';
import { OffCanvasService } from 'src/app/core/off-canvas.service';
import { NgbAccordionItem } from '@ng-bootstrap/ng-bootstrap';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { IssueCardSettings } from 'src/app/issues/models/issue.settings';
import { UpdatableSettings } from 'src/app/shared/models/inner/base-settings.interface';

@Component({
  selector: 'tmt-issue-card',
  templateUrl: './issue-card.component.html',
  styleUrls: ['./issue-card.component.scss'],
  providers: [
    { provide: META_ENTITY_TYPE, useValue: 'Issue' },
    LifecycleService,
    IssueCardService,
    SavingQueueService,
    FormHeaderService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueCardComponent implements OnInit {
  @ViewChild(NgbAccordionItem) private accordionComponent: NgbAccordionItem;

  @Input() public entityId: string;

  public activeTab: string;
  public settings: IssueCardSettings;
  public readonly commentedEntityCollectionType = CommentedEntityCollectionType;

  private readonly destroyRef = inject(DestroyRef);

  /**
   * Saves issue name.
   *
   * @param name issue name.
   */
  public saveName = (name: string) =>
    this.dataService
      .collection('Issues')
      .entity(this.entityId)
      .patch({ name })
      .pipe(
        tap(() => {
          this.issueCardService.issueForm
            .get('name')
            .setValue(name, { emitEvent: false });
          this.offCanvasService.onEntityUpdated({ id: this.entityId, name });
          this.cdr.markForCheck();
        }),
      );

  constructor(
    public issueCardService: IssueCardService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private actionPanelService: ActionPanelService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private offCanvasService: OffCanvasService,
    private localConfigService: LocalConfigService,
  ) {}

  public ngOnInit(): void {
    this.settings = this.localConfigService.getConfig(IssueCardSettings);

    this.actionPanelService.setHasAutosave(true);
    this.issueCardService.load();
  }

  /**
   * Opens created by user info.
   *
   * @param target target element for info popup service.
   */
  public openCreatedByUserInfo(target: ElementRef): void {
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId: this.issueCardService.issue.createdBy.id,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Updates issue card settings.
   *
   * @param key setting's property.
   * @param value
   */
  public updateSettings(
    key: UpdatableSettings<IssueCardSettings>,
    value: boolean,
  ): void {
    this.settings[key] = value;
    this.localConfigService.setConfig(IssueCardSettings, this.settings);
  }
}
