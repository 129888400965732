import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';

import { get as _get, merge as _merge } from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AppService } from 'src/app/core/app.service';
import { LogService } from 'src/app/core/log.service';
import { AppConfigService } from 'src/app/core/app-config.service';

import {
  NotificationAPI,
  NotificationEventHandler,
  NotificationPermissionAlert,
} from './browser-notification.interface';

@Injectable({
  providedIn: 'root',
})
export class BrowserNotificationService {
  private notification: NotificationAPI;
  private currentPermission: NotificationPermission;
  private isBrowserSupported = false;
  private isBrowserPermitted = false;
  private isUserPermitted = false;
  /** Debounce time for checking event from window object. */
  private readonly otherTabAwaitTime = 150;
  private readonly syncStoreName = 'showMessage';
  private otherTabDetected = false;
  private defaultOptions: Partial<NotificationOptions> = {
    icon: '/assets/logo_small.svg',
  };
  private permissionDescription: Record<
    NotificationPermission,
    NotificationPermissionAlert
  > = {
    default: {
      type: 'warning',
      isNeedAction: true,
      message: this.translateService.instant(
        'shared.userSettings.browserNotificationAlerts.default',
      ),
    },
    denied: {
      type: 'danger',
      isNeedAction: false,
      message: this.translateService.instant(
        'shared.userSettings.browserNotificationAlerts.denied',
      ),
    },
    granted: {
      type: 'success',
      isNeedAction: false,
      message: this.translateService.instant(
        'shared.userSettings.browserNotificationAlerts.granted',
      ),
    },
  };

  constructor(
    private logService: LogService,
    private translateService: TranslateService,
    private appService: AppService,
    private localStorageService: LocalStorageService,
  ) {
    this.init();
    this.initOtherTabListener();
  }

  /** Indicates whether to use service by user permission. */
  public get use(): boolean {
    return (
      this.isBrowserSupported && this.isBrowserPermitted && this.isUserPermitted
    );
  }

  /** Get current permission. */
  public get permission(): NotificationPermission {
    return this.currentPermission;
  }

  /**
   * Get a tip message for browser permission.
   *
   * @param title Type of permission. Current by default.
   *
   * @returns object with type, message and necessity of action from user.
   * */
  public getPermissionAlert(
    permission = this.currentPermission,
  ): NotificationPermissionAlert {
    return this.permissionDescription[permission];
  }

  /**
   * Send a browser notification to the user.
   *
   * @param entityId Message ID. Necessary for a single send when several tabs are open.
   * @param title Message title.
   * @param options Message options.
   * @param eventHandler Custom actions on a event ('click' | 'show' | 'close' | 'error').
   * */
  public sendMessage(
    notificationId: string,
    title: string,
    options: NotificationOptions = {},
    eventHandler?: Partial<NotificationEventHandler>,
  ): void {
    const otherTabDetecter: Subscription = this.localStorageService
      .observe(this.syncStoreName)
      .pipe(debounceTime(this.otherTabAwaitTime))
      .subscribe(() => {
        if (this.otherTabDetected) {
          otherTabDetecter.unsubscribe();
          this.otherTabDetected = false;
          return;
        }

        _merge(this.defaultOptions, options);

        const message = new this.notification(title, this.defaultOptions);

        message.onerror = (event) => {
          if (eventHandler.error) {
            eventHandler.error();
          }
        };

        if (eventHandler.click) {
          message.onclick = () => {
            eventHandler.click();
            message.close();
          };
        }

        if (eventHandler.show) {
          message.onshow = () => {
            eventHandler.show();
          };
        }

        if (eventHandler.close) {
          message.onclose = () => {
            eventHandler.close();
          };
        }

        otherTabDetecter.unsubscribe();
        this.otherTabDetected = false;
      });

    this.localStorageService.store(this.syncStoreName, notificationId);
  }

  /** Request the browser permission. */
  public requestPermission(): void {
    if (
      this.isBrowserSupported &&
      this.isUserPermitted &&
      !this.isBrowserPermitted
    ) {
      try {
        this.notification
          .requestPermission(this.callBackPermission.bind(this))
          .then((permission) => {
            this.currentPermission = permission;
          });
      } catch (e) {
        this.logService.error(e.message);
      }
    }
  }

  /**  @Deprecated left for backward compatibility with some browsers */
  private callBackPermission(permission: NotificationPermission): void {
    this.currentPermission = permission;
  }

  private init(): void {
    this.notification = window.Notification;
    this.isBrowserSupported = !!this.notification;

    if (!this.isBrowserSupported) {
      return;
    }

    this.currentPermission = window.Notification.permission;
    this.isBrowserPermitted = this.currentPermission === 'granted';
    this.isUserPermitted =
      this.appService.session.configuration.useBrowserNotifications;

    _merge(
      this.defaultOptions,
      _get(AppConfigService.config, 'notificationBrowser'),
    );

    this.requestPermission();
  }

  /** We need to use the native EventListener to synchronize with other tabs.
   *  The event triggered on the rest of the tabs, but does not emit in the current tab.
   *
   * */
  private initOtherTabListener(): void {
    window.addEventListener('storage', (event) => {
      if (event.key === `wp_${this.syncStoreName}`) {
        this.otherTabDetected = true;
      }
    });
  }
}
