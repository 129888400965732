<div ngbDropdown class="d-inline-block">
  <button
    type="button"
    class="btn btn-default without-caret"
    ngbDropdownToggle
    title="{{ 'shared2.actions.options' | translate }}"
  >
    <i class="bi bi-sliders2 bi-15" aria-hidden="true"></i>
  </button>

  <div ngbDropdownMenu class="p-3">
    <div *ngFor="let group of service.allGroups" class="form-check text-nowrap">
      <input
        [id]="group"
        (click)="onToggle(group)"
        [checked]="service.selectedGroups.includes(group)"
        type="checkbox"
        class="form-check-input"
      />
      <label class="form-check-label" [for]="group">
        {{ 'projects.projects.card.rbc.calendar.groups.' + group | translate }}
      </label>
    </div>
  </div>
</div>
