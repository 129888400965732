import { EnumEntity } from '../../enums/enum-entity';

export class TimeOffBalanceEntryType extends EnumEntity {
  public static get accrual(): TimeOffBalanceEntryType {
    return {
      id: '42a4ee7d-f46f-40e3-b411-61810e6208e7',
      code: 'Accrual',
      name: 'enums.timeOffBalanceEntryType.accrual',
    };
  }

  public static get use(): TimeOffBalanceEntryType {
    return {
      id: '12986237-83af-46e2-a208-835e71e61101',
      code: 'Use',
      name: 'enums.timeOffBalanceEntryType.use',
    };
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TimeOffBalanceEntryTypes: TimeOffBalanceEntryType[] = [
  TimeOffBalanceEntryType.accrual,
  TimeOffBalanceEntryType.use,
];
