<div class="toolbar-container m-0">
  <button
    type="button"
    class="btn btn-default"
    (click)="gridService.execute('create')"
    title="{{ 'shared.actions.create' | translate }}"
  >
    <i class="bi bi-plus-lg bi-15"></i>&nbsp;{{ 'shared.actions.create' | translate }}
  </button>
  <div class="group">
    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.edit' | translate }}"
      (click)="gridService.execute('edit')"
      [disabled]="!gridService.canBeExecuted('edit', gridService.selectedGroup)"
    >
      {{ 'shared.actions.edit' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.delete' | translate }}"
      (click)="gridService.execute('delete')"
      [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroup)"
    >
      <i class="bi bi-trash3 bi-15" aria-hidden="true"></i>
    </button>
  </div>
  <div class="group">
    <button
      type="button"
      class="btn btn-default"
      settings.workflows.list.actions.create
      title="{{ 'settings.workflows.card.actions.moveUp' | translate }}"
      (click)="gridService.execute('moveUp', gridService.selectedGroup)"
      [disabled]="!gridService.canBeExecuted('moveUp', gridService.selectedGroup)"
    >
      <i class="bi bi-arrow-bar-up" aria-hidden="true"></i>
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'settings.workflows.card.actions.moveDown' | translate }}"
      (click)="gridService.execute('moveDown', gridService.selectedGroup)"
      [disabled]="!gridService.canBeExecuted('moveDown', gridService.selectedGroup)"
    >
      <i class="bi bi-arrow-bar-down" aria-hidden="true"></i>
    </button>
  </div>
</div>
