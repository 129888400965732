/** Represents the Merge Project version DTO parameters. */
export class GetProjVersionMergeDetails {
  /** The Merge type. */
  type: string;

  /** The Merge option. */
  option: string;

  /** The Source. */
  source: ProjVersionMergeSource;

  /** The Target. */
  target: ProjVersionMergeTarget;
}

/** Represents the Project version merge source. */
export class ProjVersionMergeSource {
  /** The Source project version entries. */
  sourceEntries: Array<ProjVersionMergeSourceEntry>;
}

/** Represents the Project version merge target. */
export class ProjVersionMergeTarget {
  /**
   * The Target entity type.
   * */
  entityType: string;

  /**
   * The Target entity ID.
   * */
  id: string;

  /**
   * The Target entity name.
   *
   * @remarks If defined, the new entity with the specified name will be created.
   * */
  name?: string;
}

/** Represents the Project version merge source entity entry. */
export class ProjVersionMergeSourceEntry {
  /** The Source Project version ID. */
  versionId: string;

  /**
   * The Source Project version index.
   *
   * @remarks Defines the items order for merge operation.
   * */
  index: number;
}
