import { Navigation } from '../models/navigation/navigation';

export const HELP_DESK_APP_NAVIGATION: Navigation = {
  name: 'helpDesk',
  applicationTitle: 'shared.apps.helpDesk',
  groups: [
    {
      name: 'clients',
      header: 'projects.navigation.clients.header',
      items: [
        {
          name: 'helpDesk.allClients',
          state: 'clients',
          header: 'projects.navigation.clients.all.header',
          hint: 'projects.navigation.clients.all.hint',
          entityType: 'Organization',
          stateParams: {
            view: 'all',
          },
        },
      ],
    },
  ],
};
