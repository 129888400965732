import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddingSkillsModalComponent } from './adding-skills-modal/adding-skills-modal.component';
import { SkillEstimationCellComponent } from './skill-estimation-cell/skill-estimation-cell.component';

import { EmployeeMainComponent } from 'src/app/employees/card/employee-main/employee-main.component';
import { UserCertificatesComponent } from 'src/app/employees/card/employee-main/user-certificates/user-certificates.component';
import { ResourceRolesModule } from 'src/app/shared-features/resource-roles/resource-roles.module';

@NgModule({
  declarations: [
    EmployeeMainComponent,
    SkillEstimationCellComponent,
    AddingSkillsModalComponent,
    UserCertificatesComponent,
  ],
  providers: [],
  imports: [CommonModule, SharedModule, ResourceRolesModule, NgOptimizedImage],
  exports: [EmployeeMainComponent],
})
export class EmployeeMainModule {}
