<div class="row" style="padding-bottom: 25px">
  <div class="col-xs-9 col-lg-6">
    <label class="group mt-3">
      {{ 'settings.timesheetTemplates.card.sectionValidationRules' | translate }}
    </label>
    <div *ngIf="!readonly" class="toolbar-container" style="padding-top: 0">
      <div
        ngbDropdown
        class="d-inline-block"
        (openChange)="openChange($event)"
        placement="bottom-left"
      >
        <button type="button" class="btn btn-default" ngbDropdownToggle>
          <span class="bi bi-plus-lg bi-15"></span>
          &nbsp;{{ 'settings.timesheetTemplates.card.actions.addRule' | translate }}
        </button>
        <div ngbDropdownMenu class="dropdown-scroll">
          <wp-loading-indicator [loading]="loading"></wp-loading-indicator>
          <div
            [hidden]="loading || rules.length !== 0"
            hidden
            class="text-center text-body-secondary"
          >
            {{ 'shared.noOptions' | translate }}
          </div>
          <button *ngFor="let rule of rules" ngbDropdownItem (click)="addValidationRule(rule)">
            {{ rule.name }}
          </button>
        </div>
      </div>
    </div>

    <wp-grid
      #grid
      [formArray]="formArray"
      [options]="gridOptions"
      [readonly]="readonly"
      style="width: 400px; display: block"
    ></wp-grid>
  </div>
</div>
