import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'wpFinance',
})
export class FinancePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number): any {
    const module = value < 0 ? -1 * value : value;
    const formattedValue = this.decimalPipe.transform(module, '1.2-2');
    return value < 0 ? '(' + formattedValue + ')' : formattedValue;
  }
}
