import { Component, OnInit, Input, DestroyRef, inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Constants } from 'src/app/shared/globals/constants';
import { DataService } from 'src/app/core/data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ValidationRule } from 'src/app/shared/models/entities/settings/validation-rule.model';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-validation-rule-card',
  templateUrl: './validation-rule-card.component.html',
})
export class ValidationRuleCardComponent implements OnInit {
  @Input() private entityId: string;

  public readonly: boolean;
  public state: CardState;
  public isSaving = false;
  public dataJson: string;

  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    customMessage: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    isActive: false,
    validationRuleType: null,
    validationRuleLevel: null,
    data: '',
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    private navigationService: NavigationService,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
    ]);

    this.load();

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  /** Saves Data. */
  public save = (): void => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const rule = this.form.value;

    const data: any = {
      name: rule.name,
      description: rule.description,
      customMessage: rule.customMessage,
      isActive: rule.isActive,
      data: rule.data,
      validationRuleLevel: rule.validationRuleLevel,
    };

    this.data
      .collection('ValidationRules')
      .entity(this.entityId)
      .patch(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.validationRules.card.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  };

  public onChanges(data: string): void {
    this.form.controls['data'].setValue(data);
    this.form.markAsDirty();
  }

  private load = (): void => {
    this.state = CardState.Loading;
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.data
      .collection('ValidationRules')
      .entity(this.entityId)
      .get<ValidationRule>({
        expand: { validationRuleType: { select: ['id', 'name', 'code'] } },
      })
      .subscribe({
        next: (validationRule: ValidationRule) => {
          this.form.patchValue(validationRule);

          this.readonly = !validationRule.editAllowed;
          this.actionService.action('save').isShown = !this.readonly;
          this.readonly ? this.form.disable() : this.form.enable();
          this.state = CardState.Ready;
          this.dataJson = validationRule.data;

          this.navigationService.addRouteSegment({
            id: validationRule.id,
            title: validationRule.name,
          });
        },
        error: (error: Exception) => {
          this.state = CardState.Error;
          this.notification.error(error.message);
        },
      });
  };

  private reload(): void {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }
}
