<div class="form-group" [formGroup]="form">
  <label class="fw-semibold control-label">{{
    'analytics.reportPeriod.periodSelector.label' | translate
  }}</label>
  <wp-date-period-box
    style="width: 300px"
    formControlName="period"
    [advanced]="true"
    (onDisplayTextChanged)="periodTitleChanged($event)"
    placeholder="{{ 'analytics.reportPeriod.periodSelector.periodIsNotSelected' | translate }}"
  ></wp-date-period-box>
  <p class="text-body-secondary">
    {{ 'analytics.reportPeriod.periodSelector.help' | translate }}
  </p>
</div>
