import { DestroyRef, Inject, Injectable, inject } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Grade } from 'src/app/shared/models/entities/settings/grade.model';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';

@Injectable()
export class GradeCardService {
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  private gradeNameSubject = new BehaviorSubject<string>(null);
  public gradeName$ = this.gradeNameSubject.asObservable();

  private gradeSubject = new ReplaySubject<Grade>();
  public grade$ = this.gradeSubject.asObservable();

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public gradeId,
    private data: DataService,
    private notification: NotificationService,
    private actionService: ActionPanelService,
    private navigationService: NavigationService,
  ) {
    this.load();
  }

  /** Loads Data. */
  public load(): void {
    this.state$.next(CardState.Loading);

    const query = {
      select: ['id', 'name', 'code', 'isActive', 'editAllowed'],
      expand: {
        level: { select: ['id', 'name'] },
      },
    };

    this.data
      .collection('Grades')
      .entity(this.gradeId)
      .get<Grade>(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (grade: Grade) => {
          this.state$.next(CardState.Ready);
          this.actionService.action('save').isShown = grade.editAllowed;
          this.gradeNameSubject.next(grade.name);
          this.gradeSubject.next(grade);

          this.navigationService.addRouteSegment({
            id: grade.id,
            title: grade.name,
          });
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          this.notification.error(error.message);
        },
      });
  }

  public updateName(value: string): void {
    this.gradeNameSubject.next(value);
  }
}
