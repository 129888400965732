import { QueryList } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DateTime, Interval } from 'luxon';
import {
  MonoTypeOperatorFunction,
  Subscription,
  filter,
  map,
  pairwise,
  startWith,
  tap,
} from 'rxjs';

import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

import {
  BoxControlComponent,
  CascadeDependency,
} from './form-helper.interface';

export class FormHelper {
  public static controlDatePair(
    formGroup: UntypedFormGroup,
    fromControlName: string,
    toControlName: string,
    takeUntil: MonoTypeOperatorFunction<unknown>,
    maxDuration?: number,
  ): void {
    const handle = (activeControl: 'from' | 'to') => {
      // Проверить границу.
      const fromValue = formGroup.controls[fromControlName].value;
      const toValue = formGroup.controls[toControlName].value;

      if (fromValue && toValue) {
        const from = DateTime.fromISO(fromValue);
        const to = DateTime.fromISO(toValue);

        if (from > to) {
          if (activeControl === 'from') {
            formGroup.controls[toControlName].setValue(from.toISODate(), {
              emitEvent: false,
            });
          } else {
            formGroup.controls[fromControlName].setValue(to.toISODate(), {
              emitEvent: false,
            });
          }
        } else {
          if (maxDuration > 0) {
            const interval = Interval.fromDateTimes(from, to);
            if (interval.length('days') > maxDuration) {
              if (activeControl === 'from') {
                const maxTo = from.plus({ days: maxDuration });
                formGroup.controls[toControlName].setValue(maxTo.toISODate(), {
                  emitEvent: false,
                });
              } else {
                const maxFrom = to.minus({ days: maxDuration });
                formGroup.controls[fromControlName].setValue(
                  maxFrom.toISODate(),
                  {
                    emitEvent: false,
                  },
                );
              }
            }
          }
        }
      }
    };

    formGroup.controls[fromControlName].valueChanges
      .pipe(takeUntil)
      .subscribe(() => handle('from'));
    formGroup.controls[toControlName].valueChanges
      .pipe(takeUntil)
      .subscribe(() => handle('to'));
  }

  /**
   * Sets depends between controls.
   *
   * @param formGroup Form group.
   * @param components Component reference collection.
   * @param dependencies Dependent pair collection.
   * @param takeUntil Function on destroy event (e.g. `takeUntil(destroyed$)`, `takeUntilDestroyed(this.destroyRef)`)
   *
   * @returns Complete function. Unsubscribes all `valueChanges` of controls.
   *
   * TODO:
   * NamedEntity by Generic?
   * Add query generator for changeFilter?
   * How to solve validators problem for dependent?
   */
  public static cascadeDependency(
    formGroup: UntypedFormGroup,
    components: QueryList<BoxControlComponent>,
    dependencies: CascadeDependency[],
    takeUntil: MonoTypeOperatorFunction<unknown>,
  ): () => void {
    const controlsSubscriptions: Subscription[] = [];

    for (const item of dependencies) {
      const [master, slave] = item;
      master.component = components.find(
        (c) => c.controlName === master.controlName,
      );
      slave.component = components.find(
        (c) => c.controlName === slave.controlName,
      );

      if (!master.component || !slave.component) {
        // TODO throw error?
        continue;
      }

      // Enrich select
      if (slave.component.query?.select) {
        slave.component.query.select.push(slave.dependedProperty);
      }

      if (!slave.component.query) {
        slave.component.query = {
          select: ['id', 'name', slave.dependedProperty],
        };
      }

      const masterControl = formGroup.controls[master.component.controlName];
      const slaveControl = formGroup.controls[slave.component.controlName];

      slave.component.changeFilter(
        masterControl.getRawValue()
          ? {
              [slave.dependedProperty]: {
                type: 'guid',
                value: masterControl.getRawValue().id,
              },
            }
          : null,
      );

      controlsSubscriptions.push(
        masterControl.valueChanges
          .pipe(
            startWith(masterControl.getRawValue()),
            tap((value) => {
              if (master.isDisableDepended) {
                value
                  ? slaveControl.enable({ emitEvent: false })
                  : slaveControl.disable({ emitEvent: false });
              }
            }),
            pairwise(),
            filter(([previous, current]) => previous?.id !== current?.id),
            map(([previous, current]) => current),
            takeUntil,
          )
          .subscribe((value: NamedEntity | null) => {
            if (slave.notReset) {
              slave.notReset = false;
            } else {
              slaveControl.setValue(null, { emitEvent: false });
            }

            slave.component.changeFilter(
              value
                ? {
                    [slave.dependedProperty]: { type: 'guid', value: value.id },
                  }
                : null,
            );
          }),

        slaveControl.valueChanges
          .pipe(takeUntil)
          .subscribe((value: NamedEntity | null) => {
            if (!masterControl.getRawValue() && value) {
              master.component.refreshRows().then(() => {
                const rows = master.component.allValues.filter(
                  (v) => v.id === value[slave.dependedProperty],
                );

                if (rows.length === 1) {
                  slave.notReset = true;
                  masterControl.setValue(rows[0]);
                }
              });
            }
          }),
      );
    }

    return () => controlsSubscriptions.forEach((s) => s.unsubscribe());
  }
}
