import { InjectionToken } from '@angular/core';
import { List } from './models/inner/list';
import { EntityFilter } from 'src/app/core/navigation.service';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';

export const VIEW_NAME = new InjectionToken<string>('viewName');
export const LIST = new InjectionToken<List>('list');
export const FILTER = new InjectionToken<EntityFilter>('filter');
export const MASS_OPERATION_PARAMETERS =
  new InjectionToken<MassOperationParameters>('massOperationParameters');
export const MULTI_SELECT_LIST = new InjectionToken<boolean>('multiSelectList');
export const META_ENTITY_TYPE = new InjectionToken<string>('metaEntityType');
