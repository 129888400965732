import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { Subject, Subscription } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Guid } from 'src/app/shared/helpers/guid';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import {
  GridDateControlColumn,
  GridColumnType,
  GridUserControlColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { NotificationService } from 'src/app/core/notification.service';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { DataService } from 'src/app/core/data.service';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { UserCardService } from '../user-card.service';
import { MessageService } from 'src/app/core/message.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { UserSubstitutesToolbarComponent } from './user-substitutes-toolbar/user-substitutes-toolbar.component';
import { filter, takeUntil } from 'rxjs/operators';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-user-substitutes',
  templateUrl: './user-substitutes.component.html',
  providers: [GridService],
})
export class UserSubstitutesComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public loading: boolean;
  readonly = !this.app.checkPermission(
    'UserSubstitute',
    'All',
    PermissionType.Modify,
  );

  private _isSaving: boolean;
  destroyed$ = new Subject<void>();

  get isSaving() {
    return this._isSaving;
  }
  set isSaving(value: boolean) {
    this._isSaving = value;

    if (value) {
      this.blockUI.start();
      this.actionService.action('save').start();
    } else {
      this.actionService.action('save').stop();
      this.blockUI.stop();
    }
  }
  reloadListener: Subscription;
  substitutes: UntypedFormArray = this.fb.array([]);

  gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    toolbar: UserSubstitutesToolbarComponent,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.substitutes.removeAt(index);
          this.substitutes.markAsDirty();
        },
      },
    ],
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          const group = this.getGridFormGroup({});
          this.substitutes.push(group);
          this.gridService.selectGroup(group);
          this.substitutes.markAsDirty();
        },
      },
    ],
    view: {
      name: 'substitutes',
      columns: [
        <GridUserControlColumn>{
          name: 'substitute',
          header: 'shared.userSubstitutes.columns.substitute',
          hint: 'shared.userSubstitutes.columns.substitute',
          type: GridColumnType.UserControl,
          placeholder: 'shared.userSubstitutes.columns.substitute',
          width: '100%',
          required: true,
        },
        <GridDateControlColumn>{
          name: 'periodStart',
          header: 'shared.userSubstitutes.columns.start.header',
          hint: 'shared.userSubstitutes.columns.start.hint',
          type: GridColumnType.DateControl,
          property: 'periodStart',
          width: '150px',
          required: true,
        },
        <GridDateControlColumn>{
          name: 'periodFinish',
          header: 'shared.userSubstitutes.columns.finish.header',
          hint: 'shared.userSubstitutes.columns.finish.hint',
          type: GridColumnType.DateControl,
          property: 'periodFinish',
          width: '150px',
          required: true,
        },
      ],
    },
  };

  constructor(
    private notification: NotificationService,
    private gridService: GridService,
    private blockUI: BlockUIService,
    private data: DataService,
    private app: AppService,
    public actionService: ActionPanelService,
    private service: UserCardService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
  ) {}

  private getGridFormGroup(row: any) {
    return this.fb.group({
      substitute: [row.substitute, Validators.required],
      periodStart: [row.periodStart],
      periodFinish: [row.periodFinish],
      id: row.id ? row.id : Guid.generate(),
    });
  }

  ngOnDestroy(): void {
    this.reloadListener.unsubscribe();
    this.destroyed$.next();
  }

  private reload() {
    if (!this.substitutes.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }

  load = () => {
    this.loading = true;
    this.substitutes.clear();
    this.actionService.action('save').hide();

    this.data
      .collection('Users')
      .entity(this.entityId)
      .collection('Substitutes')
      .query<any[]>({
        expand: [{ substitute: { select: ['id', 'name'] } }],
      })
      .subscribe({
        next: (data: any[]) => {
          data.forEach((userSubstitute: any) => {
            this.substitutes.push(this.getGridFormGroup(userSubstitute));
          });

          this.readonly
            ? this.substitutes.disable()
            : this.substitutes.enable();

          this.loading = false;
          this.substitutes.markAsPristine();
          this.substitutes.markAsUntouched();
          if (!this.readonly) {
            this.actionService.action('save').show();
          }
        },
        error: (error: Exception) => {
          this.loading = false;
          this.notification.error(error.message);
        },
      });
  };

  // Сохранение сущности.
  save() {
    this.substitutes.markAllAsTouched();
    this.gridService.detectChanges();

    if (this.substitutes.valid) {
      this.isSaving = true;

      const values: any[] = this.substitutes.value;

      const data = { substitutes: [] };
      // Убираем лишние данные
      values.forEach((userSubstitute: any) => {
        data.substitutes.push({
          id: userSubstitute.id,
          substituteId: userSubstitute.substitute.id,
          userId: this.entityId,
          periodStart: userSubstitute.periodStart,
          periodFinish: userSubstitute.periodFinish,
        });
      });

      this.data
        .collection('Users')
        .entity(this.entityId)
        .action('WP.UpdateSubstitutes')
        .execute(data)
        .subscribe({
          next: () => {
            this.notification.successLocal('shared.userSubstitutes.saved');
            this.substitutes.markAsPristine();
            this.isSaving = false;
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.isSaving = false;
          },
        });
    } else {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
    }
  }

  ngOnInit() {
    this.actionService.run$
      .pipe(
        filter((x) => x.name === 'save'),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.save();
      });

    if (!this.readonly) {
      this.actionService.action('save').show();
    }

    this.reloadListener = this.actionService.reload$.subscribe(() => {
      this.reload();
    });
    this.load();
  }
}
