<div class="btn-group" role="group">
  <ng-container *ngIf="(projectCardService.project$ | async).financeViewAllowed">
    <input
      type="radio"
      class="btn-check"
      id="calendar-switcher"
      [formControl]="control"
      [value]="'calendar'"
    />
    <label class="btn btn-default" for="calendar-switcher">{{
      'projects.projects.card.expenses.view.calendar' | translate
    }}</label>
  </ng-container>

  <ng-container *ngIf="(projectCardService.project$ | async).expenseEstimateViewAllowed">
    <input
      type="radio"
      class="btn-check"
      id="estimates-switcher"
      [formControl]="control"
      [value]="'estimates'"
    />
    <label class="btn btn-default" for="estimates-switcher">{{
      'projects.projects.card.expenses.view.estimates' | translate
    }}</label>
  </ng-container>
</div>
