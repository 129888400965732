<div
  *ngIf="!readonly"
  class="input-group"
  (clickOutside)="cancel()"
  [excludeBeforeClick]="true"
  [attachOutsideOnClick]="true"
  wpIntersectable
  (onIntersect)="cancel()"
  #membersBoxContainer
>
  <input
    #input
    class="form-control input-table"
    value="{{ getAssignmentsString() }}"
    title="{{ getAssignmentsString() }}"
    type="text"
    readonly
    placeholder="{{ placeholder }}"
    (click)="onInputClick()"
  />

  <span
    *ngIf="assignments.length"
    class="input-clear"
    title="{{ 'shared.hintClearControlButton' | translate }}"
    (click)="clear()"
    data-test="clearInput"
    >&times;</span
  >

  <button type="button" class="btn btn-default input-group-text" (click)="openList()">
    <i class="bi bi-chevron-down" aria-hidden="true"></i>
  </button>

  <div class="select-expand-container" #expandingArea [hidden]="!listOpened">
    <div class="fixed-expanding-area" *ngIf="listOpened">
      <div class="p-2 position-relative" class="search">
        <input
          #inputSearch
          class="form-control ellipsis"
          type="text"
          maxlength="255"
          placeholder="{{ 'shared.filter.defaultPlaceholder' | translate }}"
          [formControl]="searchControl"
        />
        <span
          class="input-clear"
          (click)="clearSearch()"
          [hidden]="!searchControl.value"
          data-test="clearSearch"
        >
          &times;
        </span>
      </div>
      <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
      <div class="task__container expanding-area-vertical-scroll">
        <div *ngIf="!filteredMemberRows.length && !isLoading" class="text-center">
          <p class="text-body-secondary m-0 p-2">{{ 'shared.noOptions' | translate }}</p>
        </div>
        <table *ngIf="filteredMemberRows.length" class="selecting-list selecting-list-hover">
          <tbody>
            <tr
              *ngFor="let row of filteredMemberRows"
              (click)="clickItem(row)"
              id="{{ row.id }}"
              title="{{ row.name }}"
              [ngClass]="{
                'text-decoration-line-through': !row.isActive && row.id !== allTeamCode
              }"
            >
              <td class="checkbox-cell">
                <input type="checkbox" [(ngModel)]="row.use" class="form-check-input" />
              </td>
              <td class="trim">{{ row.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<p *ngIf="readonly && assignments.length" class="trim" title="{{ getAssignmentsString() }}">
  {{ getAssignmentsString() }}
</p>

<p *ngIf="readonly && !assignments.length" class="trim text-body-secondary">
  {{ 'shared.valueNotSelected' | translate }}
</p>
