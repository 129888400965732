import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { ProjectCardSharedModule } from '../shared/project-card-shared.module';
import { ProjectResourceRequirementsComponent } from 'src/app/projects/card/project-resource-requirements/project-resource-requirements.component';
import { PlannerModule } from 'src/app/shared-features/planner/planner.module';
import { ScheduleNavigationModule } from 'src/app/shared-features/schedule-navigation/schedule-navigation.module';
// eslint-disable-next-line max-len
import { ResourceRequirementsLeftGroupComponent } from 'src/app/projects/card/project-resource-requirements/resource-requirements-left-group/resource-requirements-left-group.component';
// eslint-disable-next-line max-len
import { ResourceRequirementsRightGroupComponent } from 'src/app/projects/card/project-resource-requirements/resource-requirements-right-group/resource-requirements-right-group.component';
import { RequestCellComponent } from 'src/app/projects/card/project-resource-requirements/resource-requirements-left-group/request-cell/request-cell.component';

@NgModule({
  declarations: [
    ProjectResourceRequirementsComponent,
    ResourceRequirementsLeftGroupComponent,
    RequestCellComponent,
    ResourceRequirementsRightGroupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProjectCardSharedModule,
    PlannerModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.resourceRequirements',
          url: '/resource-requirements',
          component: ProjectResourceRequirementsComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
    ScheduleNavigationModule,
  ],
})
export class ProjectResourceRequirementsModule {}
