import { Component, Input, OnInit, Inject, Optional } from '@angular/core';
import { GridNavigationColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { AppName } from 'src/app/shared/globals/app-name';
import { NavigationService } from 'src/app/core/navigation.service';
import { UIRouterGlobals } from '@uirouter/core';
import { cloneDeep } from 'lodash';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

@Component({
  selector: 'wp-navigation-cell',
  templateUrl: './navigation-cell.component.html',
  styleUrls: ['./navigation-cell.component.scss'],
})
export class NavigationCellComponent implements OnInit {
  @Input() row: any;
  @Input() column: GridNavigationColumn;

  public uiParams: any;

  public state: string;

  constructor(
    private navigationService: NavigationService,
    private uiRouterGlobals: UIRouterGlobals,
  ) {}

  ngOnInit(): void {
    // Заменить шаблонную переменную в имени состояния {app} на имя текущего приложения.
    // Используется для навигации к сущности одного списка, показываемого в разных приложениях.
    this.state = this.column.state.replace(
      '{app}',
      this.navigationService.getAppName(),
    );
    this.uiParams = {};
    if (this.column.stateParams) {
      Object.keys(this.column.stateParams).forEach((key) => {
        if (key !== 'fromRow') {
          this.uiParams[key] = this.column.stateParams[key];
        }
      });
      if (this.column.stateParams.fromRow) {
        const fromRow = this.column.stateParams.fromRow;
        Object.keys(fromRow).forEach((key) => {
          this.uiParams[key] = this.row[fromRow[key]];
        });
      }
    } else {
      this.uiParams = {
        entityId: this.row.id,
      };
    }

    this.uiParams['routeMode'] = RouteMode.continue;

    if (!this.uiParams.navigation) {
      this.uiParams.navigation = this.uiRouterGlobals.params?.navigation;
    }

    if (!this.uiParams.route && this.navigationService.storedRoute.length) {
      this.uiParams.route = cloneDeep(this.navigationService.storedRoute);
    }
  }
}
