import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { StateService } from '@uirouter/core';
import { Constants } from 'src/app/shared/globals/constants';

@Component({
  selector: 'wp-copying-project-modal',
  templateUrl: './copying-project-modal.component.html',
})
export class CopyingProjectModalComponent implements OnInit {
  @Input() project: Project;
  public isLoading: boolean;

  public form = this.fb.group({
    name: [
      null,
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: [null, [Validators.maxLength(Constants.formCodeMaxLength)]],
    copyAssignments: true,
    copyTasks: true,
    copyTeam: true,
    copyManagers: true,
    copyRates: true,
    saveSourceLink: false,
  });
  isSaving: boolean;

  constructor(
    private state: StateService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
  ) {}

  public ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    const data = this.form.value;
    if (!data.copyTeam || !data.copyTasks) {
      data.copyAssignments = false;
    }

    this.data
      .collection('Projects')
      .entity(this.project.id)
      .action('WP.Copy')
      .execute(data)
      .subscribe({
        next: (createdId) => {
          this.notification.successLocal(
            'projects.projects.copyingProject.messages.wasCopied',
          );
          this.isSaving = false;
          this.activeModal.close();
          this.state.go('project', { entityId: createdId });
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  /** Загрузка сведений о проекте. */
  public load() {
    this.isLoading = true;

    const query = {
      select: ['id', 'name', 'code'],
      expand: [
        { billingType: { select: ['code'] } },
        { sourceProject: { select: ['id'] } },
      ],
    };

    this.data
      .collection('Projects')
      .entity(this.project.id)
      .get<Project>(query)
      .subscribe({
        next: (project) => {
          this.project = project;
          this.form.patchValue(project);
          this.isLoading = false;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoading = false;
        },
      });
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ngOnInit(): void {
    this.load();
  }
}
