import { List, LoadingStrategy } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import {
  GridComponentColumn,
  GridCurrencyColumn,
  NavigationType,
} from 'src/app/shared-features/grid2/models/grid-column.interface';
import { MemberResourceCellComponent } from 'src/app/projects/card/project-team/member-resource-cell/member-resource-cell.component';

export const RESOURCES_LIST: List = {
  version: 2,
  name: 'resources',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'location',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'legalEntity',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'role',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'competence',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'level',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'grade',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'resourcePool',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'isActive',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
    {
      name: 'addResourceModal',
      resizableColumns: true,
      columns: [
        {
          column: 'resource',
          visibleByDefault: true,
          width: 260,
        },
        {
          column: 'role',
          visibleByDefault: true,
          width: 280,
        },
        {
          column: 'level',
          visibleByDefault: true,
          width: 220,
        },
        {
          column: 'department',
          visibleByDefault: true,
          width: 200,
        },
        {
          column: 'resourcePool',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'currentRate',
          width: 150,
          visibleByDefault: true,
        },
        {
          column: 'competence',
          width: 150,
          visibleByDefault: false,
        },
        {
          column: 'grade',
          width: 150,
          visibleByDefault: false,
        },
        {
          column: 'legalEntity',
          width: 150,
          visibleByDefault: false,
        },
        {
          column: 'location',
          width: 150,
          visibleByDefault: false,
        },
      ],
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      state: 'resource',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      type: GridColumnType.ResourceNavigation,
      navigationType: NavigationType.Resource,
    },
    {
      name: 'location',
      header: 'resources.team.list.columns.location',
      hint: 'resources.team.list.columns.location',
      type: GridColumnType.String,
    },
    {
      name: 'legalEntity',
      type: GridColumnType.String,
      header: 'resources.team.list.columns.legalEntity',
      hint: 'resources.team.list.columns.legalEntity',
    },
    {
      name: 'role',
      header: 'resources.team.list.columns.role',
      hint: 'resources.team.list.columns.role',
      type: GridColumnType.String,
    },
    {
      name: 'competence',
      header: 'resources.team.list.columns.competence',
      hint: 'resources.team.list.columns.competence',
      type: GridColumnType.String,
    },
    {
      name: 'level',
      header: 'resources.team.list.columns.level',
      hint: 'resources.team.list.columns.level',
      type: GridColumnType.String,
    },
    {
      name: 'grade',
      header: 'resources.team.list.columns.grade',
      hint: 'resources.team.list.columns.grade',
      type: GridColumnType.String,
    },
    {
      name: 'resourcePool',
      header: 'resources.team.list.columns.resourcePool',
      hint: 'resources.team.list.columns.resourcePool',
      type: GridColumnType.String,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'modified',
      header: 'shared.columns.modified.title',
      hint: 'shared.columns.modified.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'isActive',
      header: 'shared.columns.isActive.hint',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.Boolean,
    },
    {
      name: 'createdBy',
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'modifiedBy',
      header: 'shared.columns.modifiedBy.title',
      hint: 'shared.columns.modifiedBy.hint',
      type: GridColumnType.String,
    },
    <GridComponentColumn>(<unknown>{
      name: 'resource',
      type: GridColumnType.Component,
      component: MemberResourceCellComponent,
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
    }),
    <GridCurrencyColumn>{
      name: 'currentRate',
      type: GridColumnType.Currency,
      fixedWidth: true,
      header:
        'projects.projects.card.team.addResourcesModal.columns.currentCostRate.label',
      hint: 'projects.projects.card.team.addResourcesModal.columns.currentCostRate.ph',
    },
    {
      name: 'department',
      header:
        'projects.projects.card.team.addResourcesModal.columns.department',
      hint: 'projects.projects.card.team.addResourcesModal.columns.department',
      type: GridColumnType.String,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'location',
      field: 'location.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'legalEntity',
      field: 'legalEntity.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'role',
      field: 'role.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'competence',
      field: 'competence.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'level',
      field: 'level.name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'grade',
      field: 'grade.name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'resourcePool',
      field: 'resourcePool.name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'modified',
      field: 'modified',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'createdBy',
      field: 'createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'resourceType',
      field: 'resourceType',
      loadingStrategy: LoadingStrategy.Always,
    },
  ],
  dataCollection: 'Resources',
};
