import { Injectable, Injector } from '@angular/core';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { BehaviorSubject, of, Subject, throwError } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { CreateProjectVersionModalComponent } from 'src/app/projects/project-versions/create-project-version-modal/create-project-version-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectVersionsMergeModalComponent } from 'src/app/projects/project-versions/project-versions-merge-modal/project-versions-merge-modal.component';
import { TranslateService } from '@ngx-translate/core';

/**
 * Provides methods to work with Project versions.
 * */
@Injectable()
export class ProjectVersionsService {
  collection = this.data.collection('ProjectVersions');

  /** The Project version list subject. */
  private _projectVersions = new Subject<ProjectVersion[]>();

  /** The Project version update in progress subject. */
  private _projectVersionUpdating = new BehaviorSubject<boolean>(false);

  /** The Project version list observable. */
  public projectVersions$ = this._projectVersions.asObservable();

  /** The Project version update in progress observable. */
  public projectVersionUpdating$ = this._projectVersionUpdating.asObservable();

  /** The Project version loading state observable. */
  public loadingState$ = new BehaviorSubject<boolean>(false);

  private fnGetVersion = (versionId: string): Observable<ProjectVersion> => {
    const query: any = {
      select: [
        'id',
        'name',
        'isActive',
        'masterBaseline',
        'created',
        'modified',
        'editAllowed',
        'setMasterFlagAllowed',
      ],
      expand: [
        { state: { select: ['id', 'name', 'code', 'style'] } },
        { sourceVersion: { select: ['id', 'name'] } },
        { mainProject: { select: ['id', 'name'] } },
        { snapshot: { select: ['id', 'forecastBreakpoint'] } },
        { createdBy: { select: ['id', 'name'] } },
      ],
    };

    return this.collection.entity(versionId).get<ProjectVersion>(query);
  };

  private fnGetVersionCount = (mainProjectId?: string): Observable<number> => {
    const query = {
      filter: [],
    };
    if (mainProjectId) {
      query.filter.push({
        mainProjectId: {
          eq: { type: 'guid', value: mainProjectId },
        },
      });
    }

    return this.collection.function('$count').query<number>(null, query);
  };

  constructor(
    private versionCardService: ProjectVersionCardService,
    private data: DataService,
    private notification: NotificationService,
    private translate: TranslateService,
    private modal: NgbModal,
  ) {}

  /**
   * Loads the Project versions.
   * The Project version tree hierarchy is not currently supported.
   * The whole Project version list is loaded.
   *
   * @param projectId The Project ID.
   * */
  public load(projectId: string) {
    this.loadingState$.next(true);

    const query: any = {
      select: [
        'id',
        'name',
        'isActive',
        'masterBaseline',
        'created',
        'modified',
      ],
      expand: [
        { state: { select: ['id', 'name', 'code', 'style'] } },
        { sourceVersion: { select: ['id', 'name'] } },
        { mainProject: { select: ['id', 'name'] } },
        { snapshot: { select: ['id', 'forecastBreakpoint'] } },
        { createdBy: { select: ['id', 'name'] } },
      ],
      filter: {
        mainProject: {
          id: { eq: { type: 'guid', value: projectId } },
        },
      },
      orderBy: ['name'],
    };

    this.collection.query<ProjectVersion[]>(query).subscribe({
      next: (versions) => {
        this.versionCardService.projectVersions = versions;
        this._projectVersions.next(
          this.versionCardService.sortVersions(versions, true),
        );
        this.loadingState$.next(false);
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.loadingState$.next(false);
      },
    });
  }

  /**
   * Gets the Project version.
   *
   * @param versionId The Project version ID.
   * */
  public getVersion(versionId: string) {
    return this.fnGetVersion(versionId);
  }

  /**
   * Gets the Project version name prefix.
   *
   * @param mainProjectId The Project ID.
   * */
  public getVersionNamePrefix(mainProjectId?: string): Observable<string> {
    return this.fnGetVersionCount(mainProjectId).pipe(
      map((count) =>
        this.translate.instant(
          `projects.projectVersions.createProjectVersionModal.versionPrefix`,
          { versionCount: count + 1 },
        ),
      ),
    );
  }

  /**
   * Opens the modal dialog to create the Project version with the selected source.
   *
   * @param sourceVersion The Project version selected on the Project card.
   * @param moveToCard If true, moves to the Project version card route.
   * Opens the Project version on the Project card otherwise.
   * @default false.
   * */
  public createVersion(sourceVersion: ProjectVersion, moveToCard = false) {
    const version = sourceVersion ?? this.versionCardService.workProjectVersion;
    this.openCreateVersionModal(version, moveToCard);
  }

  /**
   * Changes the Project version.
   * Gets the Project version DTO with opened properties to check permissions.
   *
   * @param projectVersion The Project version.
   * */
  public changeVersion(projectVersion: ProjectVersion) {
    if (ProjectVersionUtil.isWorkProjectVersion(projectVersion)) {
      this.versionCardService.changeVersion(projectVersion);
      return of(projectVersion);
    } else {
      this._projectVersionUpdating.next(true);
      return this.fnGetVersion(projectVersion.id).pipe(
        tap((version) => {
          this.versionCardService.changeVersion(version);
          this._projectVersionUpdating.next(false);
        }),
        catchError((err) => {
          this._projectVersionUpdating.next(false);
          return throwError(err);
        }),
      );
    }
  }

  /**
   * Opens modal dialog to merge Project version(s) into Project/Other Project version.
   *
   * @param projectId Project ID.
   * @param currencyCode Project currency code.
   * */
  public openMergeVersionsDialog(projectId: string, currencyCode: string) {
    const injector = Injector.create({
      providers: [
        {
          provide: ProjectVersionCardService,
          useValue: this.versionCardService,
        },
        {
          provide: ProjectVersionsService,
          useValue: this,
        },
      ],
    });

    const ref = this.modal.open(ProjectVersionsMergeModalComponent, {
      size: 'xxl',
      injector,
    });

    const instance =
      ref.componentInstance as ProjectVersionsMergeModalComponent;
    instance.projectId = projectId;
    instance.currencyCode = currencyCode;
  }

  /**
   * Opens the modal dialog to create the Project version.
   *
   * @param sourceVersion The Source Project version.
   * @param moveToCard If true, moves to the Project version card route.
   * Opens the Project version on the Project card otherwise.
   * */
  private openCreateVersionModal(
    sourceVersion: ProjectVersion,
    moveToCard = false,
  ) {
    const injector = Injector.create({
      providers: [
        {
          provide: ProjectVersionsService,
          useValue: this,
        },
        {
          provide: ProjectVersionCardService,
          useValue: this.versionCardService,
        },
      ],
    });

    const ref = this.modal.open(CreateProjectVersionModalComponent, {
      size: 'md',
      injector,
    });

    // Create the Project version.
    (ref.componentInstance as CreateProjectVersionModalComponent).moveToCard =
      moveToCard;
    (
      ref.componentInstance as CreateProjectVersionModalComponent
    ).sourceVersions = [
      this.versionCardService.workProjectVersion,
      ...this.versionCardService.projectVersions,
    ];
    (
      ref.componentInstance as CreateProjectVersionModalComponent
    ).sourceVersion = sourceVersion;
  }
}
