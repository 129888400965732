import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ProjectVersionCardService } from '../../../card/core/project-version-card.service';
import { ProjectVersionsMergeModalService } from '../core/project-versions-merge-modal.service';
import { Subject } from 'rxjs';
import {
  ProjectVersionMergeType,
  ProjectVersionMergeTypes,
} from '../models/project-version-merge-type.model';
import {
  ProjectVersionMergeOption,
  ProjectVersionMergeOptions,
} from '../models/project-version-merge-option.model';
import { GetProjVersionMergeDetails } from '../models/get-proj-version-merge-details.model';
import { takeUntil } from 'rxjs/operators';
import { ProjectVersionUtil } from '../../project-version-util';
import { Constants } from 'src/app/shared/globals/constants';
import { FormGroupService } from 'src/app/shared/services/form-group.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ProjectVersionsService } from 'src/app/projects/project-versions/project-versions.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';

/**
 * Represents the Project versions ParamSelection Merge tool step content.
 * */
@Component({
  selector: 'wp-project-versions-merge-param-selection',
  templateUrl: './project-versions-merge-param-selection.component.html',
  styleUrls: ['./project-versions-merge-param-selection.component.scss'],
})
export class ProjectVersionsMergeParamSelectionComponent
  implements OnInit, OnDestroy
{
  @Input() projectId: string;

  public form = this.mergeModalService.paramSelectionForm;

  public versionNameValidators = {
    maxLength: Constants.formNameMaxLength,
  };

  /** The available merge operation types. */
  mergeTypes = ProjectVersionMergeTypes;

  /** The available merge operation options. */
  mergeOptions = ProjectVersionMergeOptions;

  /** The merge operation types states. */
  mergeTypeStates: Dictionary<string> = {
    [ProjectVersionMergeType.replace.code]: null,
    [ProjectVersionMergeType.add.code]: null,
  };

  /** The Project versions. */
  projectVersions: Array<ProjectVersion> = [];

  /** The Source Project version group controls values. */
  sourceControlValues: Array<Array<ProjectVersion>> = [];

  get sourceFormArray() {
    return this.form.controls.source as UntypedFormArray;
  }

  get isDefaultMergeOption() {
    return (
      this.form.controls.option.value === ProjectVersionMergeOption.default.code
    );
  }

  get isCombineMergeOption() {
    return (
      this.form.controls.option.value === ProjectVersionMergeOption.combine.code
    );
  }

  /**
   * Filters the Project versions in list for merge operation.
   *
   * @param version The Project version to filter.
   * */
  private fnFilterVersions = (version: ProjectVersion): boolean =>
    version.isActive === true;

  /** The Source controls values change event subscriptions cancel subject. */
  private optionChanged$ = new Subject<void>();
  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public versionCardService: ProjectVersionCardService,
    public mergeModalService: ProjectVersionsMergeModalService,
    private versionService: ProjectVersionsService,
    private formGroupService: FormGroupService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.optionChanged$.next();
  }

  /**
   * Initializes the Merge Project version parameters form group.
   * */
  private initForm() {
    this.projectVersions = this.versionCardService.projectVersions
      .slice()
      .filter(this.fnFilterVersions);

    // Subscribe to ParamSelection Merge tool external submit started event.
    this.mergeModalService.startParamSelectionStepSubmit$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.mergeModalService.submitParamSelectionForm(this.getFormValue());
      });
    this.form.controls.option.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.updateForm();
      });

    this.updateForm();
  }

  /**
   * Updates the Merge Project version parameters form group controls.
   * */
  private updateForm() {
    const sourceItemCount = this.isDefaultMergeOption ? 1 : 2;

    // Refresh the Source form group controls.
    if (this.sourceFormArray.length !== sourceItemCount) {
      this.sourceFormArray.clear();
      for (let i = 0; i < sourceItemCount; i++) {
        const group = this.fb.group({
          id: Guid.generate(),
          version: [null, Validators.required],
        });
        this.sourceFormArray.push(group);
      }
    }
    // Refresh the Source form group controls options.
    if (this.sourceControlValues.length !== sourceItemCount) {
      this.sourceControlValues = [];
      for (let i = 0; i < sourceItemCount; i++) {
        this.sourceControlValues.push(this.getSourceControlOptions());
      }
    }

    const targetCtrl = this.form.controls.target as UntypedFormGroup;
    const versionNameCtrl = targetCtrl.controls
      .versionName as UntypedFormControl;
    if (this.isDefaultMergeOption) {
      this.optionChanged$.next();
      // Clear the Target control validators.
      this.formGroupService.toggleFormControlValidators(
        versionNameCtrl,
        null,
        true,
      );
      // eslint-disable-next-line guard-for-in
      for (const key in this.mergeTypeStates) {
        this.mergeTypeStates[key] = null;
      }
    } else if (this.isCombineMergeOption) {
      this.sourceFormArray.controls.forEach((ctrl, idx) => {
        ctrl.valueChanges
          .pipe(takeUntil(this.optionChanged$))
          .subscribe((value) => {
            this.updateSourceControlValues(value, idx);
          });
        if (ctrl.value) {
          this.updateSourceControlValues(ctrl.value, idx);
        }
      });

      // Set the Target control validators.
      this.formGroupService.toggleFormControlValidators(
        versionNameCtrl,
        Validators.required,
      );

      this.mergeTypeStates[ProjectVersionMergeType.replace.code] = 'disabled';
      this.form.controls.type.patchValue(ProjectVersionMergeType.add.code, {
        emitEvent: false,
      });

      this.versionService
        .getVersionNamePrefix(this.projectId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (prefix) => {
            let name: string = versionNameCtrl.value;
            if (!name) {
              name = prefix;
            } else if (name && !name.startsWith(prefix)) {
              name = prefix.concat(name);
            }
            versionNameCtrl.patchValue(name, { emitEvent: false });
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
          },
        });
    }
  }

  /**
   * Excludes the current Source control selected option from the other Source controls options.
   * Restores the original options if the current control value has been cleared.
   *
   * @param value The changed Source control value.
   * @param index The changed Source control index.
   * */
  private updateSourceControlValues(value: any, index: number) {
    for (let i = 0; i < this.sourceControlValues.length; i++) {
      if (i !== index) {
        if (value?.version) {
          // Exclude the current control value from the options.
          this.sourceControlValues[i] = this.getSourceControlOptions().filter(
            (x) => x.id !== value?.version.id,
          );
        } else {
          // Restore the original options.
          this.sourceControlValues[i] = this.getSourceControlOptions();
        }
      }
    }
  }

  /**
   * Gets the allowed Source control options.
   * Includes the Project item at the 1st position if the Merge Combine option is selected.
   *
   * @returns The allowed Source control options..
   * */
  private getSourceControlOptions() {
    const values = this.projectVersions.slice();
    if (this.isCombineMergeOption) {
      values.unshift(this.versionCardService.workProjectVersion);
    }
    return values;
  }

  /**
   * Gets the Merge Project version parameters from the form group value.
   *
   * @returns the Merge Project version parameters.
   * */
  private getFormValue(): GetProjVersionMergeDetails {
    const value = new GetProjVersionMergeDetails();
    const formValue = this.form.getRawValue();

    value.type = formValue.type;
    value.option = formValue.option;

    value.source = {
      sourceEntries: formValue.source.map((val, index) => {
        if (ProjectVersionUtil.isWorkProjectVersion(val.version)) {
          return {
            entityType: 'Project',
            id: val.version?.mainProject?.id,
            index,
          };
        } else {
          return {
            entityType: 'ProjectVersion',
            id: val.version?.id,
            index,
          };
        }
      }),
    };

    switch (formValue.option) {
      case ProjectVersionMergeOption.default.code:
        value.target = {
          entityType: 'Project',
          id: this.projectId,
        };
        break;
      case ProjectVersionMergeOption.combine.code:
        value.target = {
          entityType: 'ProjectVersion',
          id: Guid.generate(),
          name: formValue.target.versionName,
        };
        break;
    }

    return value;
  }
}
