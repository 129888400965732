import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { SharedModule } from 'src/app/shared/shared.module';

import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { AccountingPeriodListComponent } from './list/accounting-period-list.component';
import { ACCOUNTING_PERIOD_LIST } from 'src/app/shared/lists/accounting-period.list';
import { AccountingPeriodCreationComponent } from './creation/accounting-period-creation.component';
import { AccountingPeriodCardComponent } from './card/accounting-period-card.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    AccountingPeriodListComponent,
    AccountingPeriodCreationComponent,
    AccountingPeriodCardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'accountingPeriods',
          url: '/accounting-periods?{navigation}',
          component: AccountingPeriodListComponent,
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: ACCOUNTING_PERIOD_LIST },
          ],
        },

        {
          name: 'accountingPeriod',
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          url: '/accounting-periods/{entityId:guid}?{navigation}&{route}',
          component: AccountingPeriodCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class AccountingPeriodsModule {}
