import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { MovePlanFormComponent } from './move-plan-form/move-plan-form.component';
import { ProjectResourcesCalendarTogglerComponent } from './project-resources-calendar-toggler/project-resources-calendar-toggler.component';
import { ResourcesViewFormComponent } from 'src/app/projects/card/project-resources/shared/resources-view-form/resources-view-form.component';

@NgModule({
  imports: [CommonModule, SharedModule, UIRouterModule],
  providers: [],
  declarations: [
    MovePlanFormComponent,
    ProjectResourcesCalendarTogglerComponent,
    ResourcesViewFormComponent,
  ],
  exports: [
    MovePlanFormComponent,
    ProjectResourcesCalendarTogglerComponent,
    ResourcesViewFormComponent,
  ],
})
export class ProjectResourcesSharedModule {}
