import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AccountingEntriesModule } from './accounting-entries/accounting-entries.module';
import { AccountingPeriodsModule } from './accounting-periods/accounting-period.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AccountingEntriesModule,
    AccountingPeriodsModule,
  ],
})
export class FinanceModule {}
