<div class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'components.boardMiniCardBuilderModalComponent.props.header' | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="closeModal()"
    ></button>
  </div>

  <div class="modal-body">
    <tmt-board-mini-card-builder
      [entityType]="boardService.config.entityType"
      [initialValue]="boardService.config.cardStructure"
      (result$)="result = $event"
    />
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="ok()" wpButtonSpinner [isBusy]="saving()">
      {{ 'shared2.actions.save' | translate }}
    </button>

    <button type="button" class="btn btn-default ms-2" (click)="closeModal()">
      {{ 'shared2.actions.cancel' | translate }}
    </button>
  </div>
</div>
