<tmt-form-header [name]="boardCardService.name$ | async" />
<wp-loading-indicator [state]="boardCardService.state$ | async" />
<tmt-not-found [state]="boardCardService.state$ | async" />
<wp-data-not-saved-indicator [form]="form" />

<form
  [formGroup]="form"
  [hidden]="(boardCardService.state$ | async) !== 'Ready'"
  class="form mt-3"
  style="width: 600px"
>
  <div class="form-group" tmtIndicator>
    <label>{{ 'shared2.props.name' | translate }}</label>
    <wp-text-box formControlName="name" placeholder="{{ 'shared2.props.name' | translate }}" />
  </div>

  <div class="form-group" tmtIndicator>
    <label class="control-label">{{
      'components.boards.props.entityType.value' | translate
    }}</label>
    <wp-text-box formControlName="entityType" type="text" />
  </div>
  <div class="form-check" title="{{ 'shared2.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared2.props.isActive.value' | translate }}
    </label>
  </div>

  <tmt-board-card-permissions
    [entityId]="entityId"
    [readonly]="readonly"
    [permissions]="permissions"
    [team]="team"
    [isSaving$]="isSaving$"
  />
  @if ((boardCardService.state$ | async) === 'Ready') {
    <tmt-board-card-navigations
      [readonly]="readonly"
      [navigations]="navigations"
      [navigationResponse]="navigationResponse"
    />
  }

  @if (!readonly) {
    <div class="actions">
      <button
        type="button"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="isSaving$ | async"
        (click)="save()"
      >
        {{ 'shared2.actions.save' | translate }}
      </button>
    </div>
  }
</form>
