<!--Строка суммарных значений-->
@if (!bookingService.isResourceLineHidden) {
  <tr class="totals" [attr.data-test-resource-id]="resourceId">
    @for (total of totals; track total.id) {
      <td
        [ngStyle]="getSummaryIndicatorStyle(total)"
        [ngClass]="{
          'non-working': total.nonWorking,
          'total-active': total.active
        }"
        (click)="openPopup(total, $event)"
        [attr.data-test-date]="total.date"
      >
        {{
          total.hours
            | plannerFormat
              : bookingService.settings.valueMode
              : total.scheduleHours
              : total.fteHours
        }}
      </td>
    }
  </tr>
}

<!--Строки данных-->
@if (isExpanded) {
  <ng-container
    *ngComponentOutlet="componentLine; inputs: componentLineInputs"
    #container
  ></ng-container>
}
