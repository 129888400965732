import {
  Component,
  OnInit,
  Input,
  DestroyRef,
  inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { BoardService } from 'src/app/boards/services/board.service';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Component({
  selector: 'tmt-board-filter',
  templateUrl: './board-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardFilterComponent implements OnInit, FilterDetails {
  @Input() public values: any;

  public detailsForm: FormGroup = this.fb.group({
    states: null,
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    public filterService: FilterService,
    public boardService: BoardService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.filterService.resetValues$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.detailsForm.reset();
      });

    this.detailsForm.patchValue(this.filterService.values);
    this.detailsForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.filterService.changeValues(
          Object.assign(this.filterService.values, this.detailsForm.value),
        );
      });
  }
}
