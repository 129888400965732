<form class="form" [formGroup]="form" novalidate>
  <wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

  <tmt-resource-groups
    [resourceId]="entityId"
    [readonly]="readonly"
    resourceType="user"
  ></tmt-resource-groups>

  <div class="actions">
    <button
      type="button"
      *ngIf="!readonly && !(resourceGroupsService.isLoading$ | async)"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
