import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Notification } from 'src/app/shared/models/entities/notification.model';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { BehaviorSubject, ReplaySubject, Subject, Subscription } from 'rxjs';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { takeUntil } from 'rxjs/operators';
import { StateBuilderService } from 'src/app/core/state-builder.service';
import { BrowserNotificationService } from 'src/app/shared/services/browser-notification/browser-notification.service';
import { WebSocketsService } from 'src/app/core/web-sockets.service';
import { ChromeService } from 'src/app/core/chrome.service';

@Injectable({
  providedIn: 'root',
})

/** User notification service. */
export class UserNotificationService {
  public notifications: Notification[] = [];

  private notificationsSubject = new BehaviorSubject<Notification[]>([]);
  public notifications$ = this.notificationsSubject.asObservable();

  private hasUnreadNotificationsSubject = new ReplaySubject<boolean>();
  public hasUnreadNotifications$ =
    this.hasUnreadNotificationsSubject.asObservable();

  private currentPage = 0;
  private pageSize = 50;
  private loadedAll = false;
  private loading = false;
  private scrollListener: Subscription | null;
  private requestPage$ = new Subject<void>();

  constructor(
    webSocketsService: WebSocketsService,
    public stateBuilder: StateBuilderService,
    private data: DataService,
    private notification: NotificationService,
    private blockUI: BlockUIService,
    private browserNotificationService: BrowserNotificationService,
    private stateService: StateService,
    private chromeService: ChromeService,
  ) {
    webSocketsService.connection?.on('Notify', (notification: Notification) => {
      this.notifications.unshift(notification);
      this.notificationsSubject.next(this.notifications);
      this.hasUnreadNotificationsSubject.next(true);
      this.sendNotification(notification);
    });

    this.loadNotifications();
    this.checkUnreadNotifications();
  }
  /** Mark notification as read. */
  public markAsRead(notificationId: string) {
    const notification = this.notifications.find(
      (x) => x.id === notificationId,
    );
    if (notification) {
      notification.read = true;
    }
    this.blockUI.start();
    this.data
      .collection('Notifications')
      .entity(notificationId)
      .action('MarkAsRead')
      .execute()
      .subscribe({
        next: () => {
          this.checkUnreadNotifications();
          this.blockUI.stop();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.blockUI.stop();
        },
      });
  }
  /** Mark all notifications as read. */
  public markAllAsRead() {
    this.notifications.forEach((notification) => (notification.read = true));
    this.blockUI.start();
    this.data
      .collection('Notifications')
      .action('MarkAllAsRead')
      .execute()
      .subscribe({
        next: () => {
          this.checkUnreadNotifications();
          this.blockUI.stop();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.blockUI.stop();
        },
      });
  }

  /**
   * Enables lazy-loading on scroll event.
   *
   * @param container `HTMLElement` to observe.
   */
  public enableInfinityScroll(container: HTMLElement): void {
    this.scrollListener = this.chromeService.setInfinityScroll(
      () => this.loadNotifications(),
      50,
      container,
    );
  }

  public reload() {
    this.currentPage = 0;
    this.loadedAll = false;
    this.notifications = [];
    this.loadNotifications();
    this.checkUnreadNotifications();
  }

  public dispose() {
    this.scrollListener?.unsubscribe();
  }

  private loadNotifications() {
    if (this.loadedAll || this.loading) {
      return;
    }

    const dataQuery = {
      top: this.pageSize,
      skip: this.pageSize * this.currentPage,
      orderBy: 'created desc',
    };

    this.loading = true;
    this.requestPage$.next();
    this.data
      .collection('Notifications')
      .query<Notification[]>(dataQuery)
      .pipe(takeUntil(this.requestPage$))
      .subscribe({
        next: (notifications) => {
          this.loading = false;
          this.loadedAll = notifications.length < this.pageSize;
          this.currentPage++;
          notifications.forEach((notification) =>
            this.notifications.push(notification),
          );
          this.notificationsSubject.next(this.notifications);

          if (this.loadedAll) {
            this.scrollListener?.unsubscribe();
            this.scrollListener = null;
          }
        },
        error: (error: Exception) => {
          this.loading = false;
          this.notification.error(error.message);
        },
      });
  }

  private checkUnreadNotifications() {
    const countQuery: any = {
      transform: {
        filter: 'read eq false',
        aggregate: {
          id: {
            with: 'countdistinct',
            as: 'count',
          },
        },
      },
    };

    this.data
      .collection('Notifications')
      .query<any>(countQuery)
      .subscribe((unreadCount) => {
        this.hasUnreadNotificationsSubject.next(
          unreadCount.length === 0 ? false : unreadCount[0].count > 0,
        );
      });
  }

  private sendNotification(notification: Notification): void {
    const stateSettings = this.stateBuilder.getStateForWorkflowEntity(
      notification.target.taskId,
      notification.target.entityType,
      notification.target.entityId,
    );

    if (this.browserNotificationService.use) {
      this.browserNotificationService.sendMessage(
        notification.id,
        notification.subject,
        {
          body: notification.message,
        },
        {
          click: () => {
            this.stateService.go(stateSettings.state, stateSettings.params);
            this.markAsRead(notification.id);
          },
        },
      );
    } else {
      this.notification.sendMessage({
        type: 'info',
        header: notification.subject,
        text: notification.message,
        onClick: () => {
          this.stateService.go(stateSettings.state, stateSettings.params);
        },
        onClose: () => {
          this.markAsRead(notification.id);
        },
      });
    }
  }
}
