import { Injectable } from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Injectable()
export class WorkflowInstanceFilterService extends FilterService {
  public override hasDetails = false;
  public override hasViewSelector = false;
  public override hasAllowInactive = false;

  public override placeholder = 'workflow.list.filter.placeholder';

  protected override getDefaultValues = (): any => ({
    text: '',
  });

  public override getODataFilter() {
    const result: any[] = [];

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        or: [
          {
            entity: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'tolower(name)': { contains: text },
            },
          },
          {
            workflowDeclaration: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'tolower(name)': { contains: text },
            },
          },
          {
            workflowDeclaration: {
              lifecycle: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'tolower(name)': { contains: text },
              },
            },
          },
        ],
      });
    }

    return result;
  }
}
