import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ExpensesViewSwitcherComponent } from './expenses-view-switcher/expenses-view-switcher.component';

@NgModule({
  declarations: [ExpensesViewSwitcherComponent],
  imports: [CommonModule, SharedModule],
  providers: [],
  exports: [ExpensesViewSwitcherComponent],
})
export class ProjectExpensesSharedModule {}
