import { DateTime } from 'luxon';

export interface Slot {
  id: number;
  hint: string;
  date: DateTime;
  header: string;
  today: boolean;
}

export interface SlotGroup {
  id: string;
  hint: string;
  header: string;
  slotsCount: number;
}
