<div
  class="wrap"
  (clickOutside)="offCanvasService.closeOffCanvas()"
  [delayClickOutsideInit]="true"
  [exclude]="'.notification-list, .modal-dialog, tmt-popup-container'"
  [excludeBeforeClick]="true"
  [clickOutsideEvents]="'mousedown'"
  #element
>
  <div class="grabber" tmtOffCanvasResize [element]="element">
    <div class="grabber-gutter"></div>
  </div>
  <div class="offcanvas-header">
    <tmt-action-panel class="action-panel-wrap" />
    <button
      class="btn-close"
      (click)="offCanvasService.closeOffCanvas()"
      [title]="'shared.actions.close' | translate"
    ></button>
  </div>

  <div [class]="'offcanvas-body' + ' ' + breakPointClassName">
    @if (offCanvasService.offCanvasEntry?.data?.component) {
      <ng-container
        *ngComponentOutlet="
          offCanvasService.offCanvasEntry?.data?.component;
          inputs: offCanvasService.offCanvasEntry.data.componentParams?.inputs;
          injector: offCanvasService.offCanvasEntry.data.componentParams?.injector;
          content: offCanvasService.offCanvasEntry.data.componentParams?.content
        "
      />
    } @else if (offCanvasService.offCanvasEntry?.data?.templateRef) {
      <ng-container
        *ngTemplateOutlet="
          offCanvasService.offCanvasEntry.data.templateRef;
          context: offCanvasService.offCanvasEntry.data.templateParams
        "
      />
    }
  </div>
</div>
