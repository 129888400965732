import {
  Directive,
  AfterViewInit,
  OnDestroy,
  Input,
  ElementRef,
  HostListener,
  Renderer2,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  DragDropService,
  DragEvent,
  Coordinates,
} from 'src/app/shared/services/drag-drop';

/**
 * This directive enables zooming for images.
 *
 * @example
 *
 *  <div
 *      class="image-wrapper"
 *      [imageId]="id"
 *      wpImageZoom>
 *    <img [src]="url | safeValue: 'url'" />
 *  </div>
 */
@Directive({
  selector: '[wpImageZoom]',
})
export class ImageZoomDirective implements AfterViewInit, OnDestroy {
  /**
   * Double click handler.
   *
   * @param event MouseEvent.
   *
   * */
  @HostListener('dblclick', ['$event'])
  public onClick(event: MouseEvent): void {
    if (this.zoomEnabled) {
      this.disableZoom();
    } else {
      this.enableZoom();
      this.setStartPosition(event.offsetX, event.offsetY);
    }
  }

  @Input() public imageId: string;
  @Input() public zoomScale: number;

  private zoomEnabled = false;
  private zoomMax = 4;
  private zoomStep = 1;
  private zoomDefault = 2;
  private startPosition: Coordinates = {
    x: 0,
    y: 0,
  };
  private currentPosition: Coordinates = {
    x: 0,
    y: 0,
  };
  private borderPosition: Coordinates = {
    x: 0,
    y: 0,
  };

  private pointerListener: () => void;
  private readonly destroyed$ = new Subject<void>();

  public constructor(
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private dragDropService: DragDropService,
  ) {}

  public ngAfterViewInit() {
    this.pointerListener = this.renderer.listen(
      this.el.nativeElement,
      'pointerdown',
      (event) => {
        this.dragDropService
          .drag(event)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((dragEvent) => {
            this.movePosition(dragEvent);
          });
      },
    );

    this.dragDropService.dragEnd$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.zoomEnabled) {
          this.checkOverBorder(this.currentPosition);
          this.setTransition();
          this.setTranslate(this.currentPosition.x, this.currentPosition.y);
          this.startPosition = Object.assign({}, this.currentPosition);
        }
      });
  }

  public ngOnDestroy(): void {
    this.pointerListener();
    this.destroyed$.next();
  }

  /** Zoom in! */
  public zoomIn(): void {
    if (!this.zoomEnabled) {
      this.enableZoom();
      this.setStartPosition(
        this.el.nativeElement.clientWidth * 0.5,
        this.el.nativeElement.clientHeight * 0.5,
      );
      return;
    }

    this.zoomScale += this.zoomScale >= this.zoomMax ? 0 : this.zoomStep;
    this.calcBorderPosition();
    this.setTranslate(this.currentPosition.x, this.currentPosition.y);
  }

  /** Zoom out! */
  public zoomOut(): void {
    if (!this.zoomEnabled) {
      return;
    }

    this.zoomScale -= this.zoomScale === 1 ? 0 : this.zoomStep;

    if (this.zoomScale === 1) {
      this.disableZoom();
    } else {
      this.setTranslate(this.currentPosition.x, this.currentPosition.y);
    }
  }

  private enableZoom(): void {
    this.zoomEnabled = true;
    this.zoomScale = this.zoomDefault;
  }

  private disableZoom(): void {
    this.zoomEnabled = false;
    this.zoomScale = this.zoomDefault;
    this.renderer.setStyle(this.el.nativeElement, 'transform', `scale(1)`);
  }

  private setStartPosition(x: number, y: number): void {
    this.startPosition = {
      x: -(x - this.el.nativeElement.clientWidth * 0.5),
      y: -(y - this.el.nativeElement.clientHeight * 0.5),
    };

    this.calcBorderPosition();
    this.checkOverBorder(this.startPosition);
    this.setTransition();
    this.setTranslate(this.startPosition.x, this.startPosition.y);
    this.currentPosition = Object.assign({}, this.startPosition);
  }

  private movePosition(dragEvent: DragEvent): void {
    if (this.zoomEnabled) {
      this.currentPosition = {
        x: this.startPosition.x + dragEvent.diffX,
        y: this.startPosition.y + dragEvent.diffY,
      };

      this.setTransition('none');
      this.setTranslate(this.currentPosition.x, this.currentPosition.y);
    }
  }

  private setTransition(property = 'all 300ms'): void {
    this.renderer.setStyle(this.el.nativeElement, 'transition', property);
  }

  private setTranslate(x: number, y: number): void {
    this.renderer.setStyle(
      this.el.nativeElement,
      'transform',
      `scale(${this.zoomScale}) translate3d(${x}px, ${y}px, 0)`,
    );
  }

  private calcBorderPosition(): void {
    const image = this.el.nativeElement.querySelector('img');
    const ratio = image.naturalHeight / this.el.nativeElement.clientHeight;
    const width =
      image.naturalWidth / ratio < this.el.nativeElement.clientWidth
        ? image.naturalWidth / ratio
        : this.el.nativeElement.clientWidth;

    this.borderPosition = {
      x: width * ((this.zoomScale - this.zoomStep) / this.zoomScale) * 0.5,
      y:
        this.el.nativeElement.clientHeight *
        ((this.zoomScale - this.zoomStep) / this.zoomScale) *
        0.5,
    };
  }

  private checkOverBorder(position: Coordinates): void {
    if (Math.abs(position.x) > this.borderPosition.x) {
      position.x = this.borderPosition.x * Math.sign(position.x);
    }

    if (Math.abs(position.y) > this.borderPosition.y) {
      position.y = this.borderPosition.y * Math.sign(position.y);
    }
  }
}
