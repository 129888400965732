import {
  OnInit,
  AfterViewInit,
  OnChanges,
  ChangeDetectorRef,
  ViewChild,
  ViewRef,
  Input,
  ElementRef,
  Renderer2,
  SimpleChanges,
  Directive,
  inject,
  DestroyRef,
  TemplateRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { firstValueFrom, debounceTime, filter } from 'rxjs';
import _ from 'lodash';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';

import { Constants } from 'src/app/shared/globals/constants';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { ScrollToService } from 'src/app/shared/services/scroll-to.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';

type MultiSelectTheme =
  | 'default'
  | 'tokens-trim'
  | 'tokens-scroll-list'
  | 'tokens-endless-list';

@Directive()
export class AbstractMultiSelectBoxComponent
  implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor
{
  @ViewChild('expandingArea') private expandingArea: TemplateRef<HTMLElement>;

  @Input() public theme?: MultiSelectTheme = 'default';
  @Input() public readonly?: boolean;
  @Input() public placeholder?: string;
  @Input() public autofocus?: boolean;
  @Input() public allowInactive?: boolean;
  @Input() public items?: any[] = [];
  @Input() public collection?: string;
  @Input() public query?: any;
  /* Property for choosing item by default. */
  @Input() public defaultModeProperty?: string;

  public filteredItems: any[] = [];
  public selectedItems: Map<string, any> = new Map();
  public focusedItem: any;
  public title: string | null;
  public titleShort: string | null;
  public searchControl = new FormControl('');
  public listOpened = false;
  public isLoading: boolean;
  public propagateChange = (_: any[]) => null;
  public propagateTouch = () => null;

  protected popupId: string;
  protected mouseEnterListener: () => void;
  protected readonly loadLimit = 500;
  protected readonly controlDelay = Constants.textInputClientDebounce;
  protected readonly destroyRef = inject(DestroyRef);

  public get allValues(): any[] {
    return this.items.slice(0);
  }

  public get controlName(): string {
    return this.el.nativeElement.attributes.getNamedItem('formControlName')
      .textContent;
  }

  constructor(
    protected cdr: ChangeDetectorRef,
    protected renderer: Renderer2,
    protected el: ElementRef<HTMLElement>,
    protected dataService: DataService,
    protected notificationService: NotificationService,
    protected scrollToService: ScrollToService,
    protected infoPopupService: InfoPopupService,
  ) {}

  public ngOnInit(): void {
    this.initSubscribers();
    this.updateTitle();
  }

  public ngAfterViewInit(): void {
    if (this.autofocus) {
      this.onInputClick();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['items'] && this.items?.length) {
      this.updateTitle();
      this.updateOption();
    }
  }

  public writeValue(value: any): void {
    throw Error(`The "writeValue" method was not implemented!`);
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
    this.listOpened = false;

    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  /** Propagate touch on blur event. */
  public onBlur(): void {
    this.propagateTouch();
  }

  /** Input click handler. */
  public onInputClick(): void {
    if (!this.listOpened) {
      this.toggleList();
    }
  }

  /**
   * Checkbox click handler
   *
   * @param targetItem - enabled/disabled item
   */
  public itemCLickHandler(event: PointerEvent, targetItem: any): void {
    if (event.pointerId === 1) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.focusedItem = targetItem;
    this.selectItem(targetItem);
  }

  /** Drops all selected items. */
  public clearValues(): void {
    this.selectedItems.clear();
    this.updateOption();
    this.propagateChange(this.getControlValue());
    this.cancel();
  }

  /** Drop search string in the dropdown. */
  public clearSearch(): void {
    this.searchControl.setValue('');
    this.updateOption();
  }

  /** Toggles `listOpened` state. Also loads data, if `items` is empty. */
  public toggleList(): void {
    if (this.listOpened) {
      this.cancel();
      return;
    }

    this.propagateTouch();
    this.updateOption();
    this.listOpened = true;

    if (!this.items?.length && this.collection) {
      this.loadData();
    }

    this.popupId = this.infoPopupService.open({
      target: this.el.nativeElement,
      data: {
        templateRef: this.expandingArea,
      },
      containerStyles: null,
      isHideArrow: true,
      popperModifiers: this.infoPopupService.controlPopperModifiers,
      mutationObserverElement: 'target',
    });

    if (this.defaultModeProperty) {
      this.updateFilteredItemsDefaultProperty();
    }
  }

  /** Cancel handler. */
  public cancel(): void {
    this.listOpened = false;
    this.focusedItem = false;
    this.searchControl.setValue('');
    this.infoPopupService.close(this.popupId);
    this.cdr.markForCheck();
  }

  /**
   * Keyboard handler.
   *
   * @param event
   */
  public onKeyDown(event: KeyboardEvent): void {
    const enterCode = 'Enter';
    const downCode = 'ArrowDown';
    const upCode = 'ArrowUp';
    const escCode = 'Escape';

    switch (event.code) {
      case downCode: {
        if (!this.focusedItem) {
          this.focusedItem = this.filteredItems[0];
        } else {
          const index = this.filteredItems.findIndex(
            (el) => el.id === this.focusedItem.id,
          );

          this.focusedItem = this.filteredItems[index + 1] ?? this.focusedItem;
        }
        break;
      }
      case upCode:
        {
          if (!this.focusedItem) {
            this.focusedItem = this.filteredItems[this.items.length - 1];
          } else {
            const index = this.filteredItems.findIndex(
              (el) => el.id === this.focusedItem.id,
            );

            this.focusedItem =
              this.filteredItems[index - 1] ?? this.focusedItem;
          }
        }
        break;
      case enterCode: {
        this.selectItem(this.focusedItem);
        break;
      }
      case escCode: {
        this.cancel();
        break;
      }
    }

    if ([downCode, upCode].includes(event.code)) {
      this.cdr.markForCheck();

      setTimeout(() => {
        this.scrollToFocusedItem();
      });
    }

    if ([downCode, upCode, escCode, enterCode].includes(event.code)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Unselects item.
   *
   * @param item element of items.
   */
  public removeToken(event: MouseEvent, item: any): void {
    event.preventDefault();
    event.stopPropagation();

    const itemToRemove =
      this.filteredItems.find((el) => el.id === item.id) ??
      this.items.find((el) => el.id === item.id);

    if (itemToRemove) {
      this.selectItem(itemToRemove);
    } else {
      this.selectedItems.delete(item.id);
      this.propagateChange(this.getControlValue());
    }
  }

  /**
   * Mouseenter handler.
   *
   * @param item
   */
  public focusItem(item: any): void {
    this.focusedItem = item;
  }

  public changeFilter(filterPart: any): void {
    if (!this.query) {
      this.query = {};
    }

    this.query.filter = filterPart;
    this.refreshRows();
  }

  public async refreshRows(): Promise<void> {
    this.items.length = 0;
    this.filteredItems.length = 0;
    await this.loadData();
  }

  protected selectItem(targetItem: any): void {
    targetItem.selected = !targetItem.selected;

    if (targetItem.selected) {
      this.selectedItems.set(targetItem.id, targetItem);
    } else {
      this.selectedItems.delete(targetItem.id);
    }

    if (this.defaultModeProperty) {
      targetItem[this.defaultModeProperty] = false;
    }

    this.updateTitle();
    this.propagateChange(this.getControlValue());
    this.cdr.markForCheck();
  }

  protected updateTitle(): void {
    const selectedItems: any[] = [];

    Array.from(this.selectedItems).forEach((i) => {
      selectedItems.push(i[1]);
    });

    selectedItems.sort(naturalSort('name'));

    this.titleShort = selectedItems.map((i) => i.name).join('; ');
    this.title = selectedItems.map((i) => i.name).join('; ');
    this.sortSelectedItems();
  }

  protected updateOption(): void {
    if (!this.items.length) {
      this.updateTitle();
      return;
    }

    this.focusedItem = null;
    this.filteredItems = this.items
      .filter((item) =>
        item.name
          .toLowerCase()
          .includes(this.searchControl.value.toLowerCase()),
      )
      .map((item) => ({
        ...item,
        selected: this.selectedItems.has(item.id),
      }));
    this.filteredItems.sort(naturalSort('name'));
    this.filteredItems = _.sortBy(this.filteredItems, (t) => !t.selected);

    this.updateTitle();
    this.cdr.detectChanges();
  }

  protected getControlValue(): any[] {
    return this.selectedItems.size
      ? Array.from(this.selectedItems.values())
      : null;
  }

  protected initSubscribers(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(this.controlDelay),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.updateOption();
      });

    this.infoPopupService.event$
      .pipe(
        filter((e) => e.name === 'destroy' && e.popup?.id === this.popupId),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.listOpened = false;
        this.focusedItem = false;
        this.searchControl.setValue('');
      });
  }

  protected async loadData<T>(): Promise<void> {
    try {
      if (this.items?.length) {
        this.items = this.items as T[];
        return;
      }

      const query: any = {
        top: this.loadLimit,
        select: ['id', 'name'],
        filter: [],
        orderBy: 'name',
      };

      if (!this.allowInactive) {
        query.filter.push({ isActive: { eq: true } });
      }

      if (this.query) {
        if (this.query.filter) {
          query.filter = query.filter.concat(this.query.filter);
        }

        if (this.query.select) {
          query.select = this.query.select;
        }

        if (this.query.expand) {
          query.expand = this.query.expand;
        }
      }

      this.isLoading = true;
      this.cdr?.markForCheck();

      const result = await firstValueFrom(
        this.dataService
          .collection(this.collection)
          .query<T[]>(query)
          .pipe(takeUntilDestroyed(this.destroyRef)),
      );

      this.items = result;
      this.updateOption();
      this.isLoading = false;
      this.cdr?.detectChanges();
      this.infoPopupService.update(this.popupId);
    } catch (error) {
      this.errorHandler(error);
      this.isLoading = false;
      this.cdr?.markForCheck();
    }
  }

  protected errorHandler(error: any): void {
    this.notificationService.error(error.message ?? error.code);
  }

  protected scrollToFocusedItem(): void {
    if (this.focusedItem) {
      this.scrollToService.scrollTo(
        `item-${this.focusedItem?.id}`,
        'selecting-list',
      );
    }
  }

  /**
   * Toggles option's default flag.
   *
   * @param event click event.
   * @param item option for toggling flag.
   */
  public toggleDefault(event: PointerEvent, item: any): void {
    event.stopPropagation();
    Array.from(this.selectedItems).forEach((i) => {
      if (i[0] !== item.id) {
        i[1][this.defaultModeProperty] = false;
      }
    });
    item[this.defaultModeProperty] = !item[this.defaultModeProperty];
    this.selectedItems.set(item.id, item);
    this.sortSelectedItems();
    this.updateFilteredItemsDefaultProperty();
    this.propagateChange(this.getControlValue());
    this.cdr.markForCheck();
  }

  /* Updates filtered items default property. */
  private updateFilteredItemsDefaultProperty(): void {
    if (!this.defaultModeProperty) {
      return;
    }
    this.filteredItems.map(
      (item) =>
        (item[this.defaultModeProperty] = this.selectedItems.has(item.id)
          ? this.selectedItems.get(item.id)[this.defaultModeProperty]
          : false),
    );
  }

  /* Sorts selected items by default property. */
  private sortSelectedItems(): void {
    this.selectedItems = new Map(
      [...this.selectedItems.entries()].sort(
        (a, b) =>
          b[1][this.defaultModeProperty] - a[1][this.defaultModeProperty],
      ),
    );
  }
}
