/* eslint-disable @typescript-eslint/naming-convention */
export enum RevenueEstimateMethod {
  /** Billing rate method type. */
  BillingRate = 'BillingRate',
  /** Target profitability method type. */
  TargetProfitability = 'TargetProfitability',
  /** Fixed method type. */
  Fixed = 'Fixed',
}

export const RevenueEstimateMethods = [
  RevenueEstimateMethod.Fixed,
  RevenueEstimateMethod.BillingRate,
  RevenueEstimateMethod.TargetProfitability,
];
