import { Entity } from './entity.model';

export class NamedEntity extends Entity {
  /** Наименование сущности. */
  name: string;

  /** Версия сущности. */
  rowVersion?: string;

  createdBy?: NamedEntity;

  modifiedBy?: NamedEntity;
}
