import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { ResourceListComponent } from './list/resource-list.component';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { RESOURCES_LIST } from 'src/app/resources/resources.list';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EmployeeCardComponent } from 'src/app/employees/card/employee-card.component';
import { EmployeeMainComponent } from 'src/app/employees/card/employee-main/employee-main.component';
import { EmployeeWorkOverviewComponent } from 'src/app/employees/card/employee-work-overview/employee-work-overview.component';
import { EmployeeWorkDynamicComponent } from 'src/app/employees/card/employee-work-dynamic/employee-work-dynamic.component';
import { EmployeeTimeOffBalanceComponent } from 'src/app/employees/card/employee-time-off-balance/employee-time-off-balance.component';
import { ResourcesFilterModule } from 'src/app/shared/modules/resources-filter/resources-filter.module';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { EmployeeExperienceOverviewComponent } from 'src/app/employees/card/employee-experience-overview/employee-experience-overview.component';

@NgModule({
  declarations: [ResourceListComponent],
  imports: [
    CommonModule,
    SharedModule,
    ResourcesFilterModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'resources',
          url: '/resources?{navigation}&{route}',
          params: {
            navigation: 'resources.resources',
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          component: ResourceListComponent,
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: RESOURCES_LIST },
          ],
        },

        {
          name: 'resource',
          url: '/resources/{entityId:guid}?{navigation}&{route}',
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          component: EmployeeCardComponent,

          redirectTo: 'resource.main',
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },

        {
          name: 'resource.main',
          url: '/main',
          component: EmployeeMainComponent,
        },
        {
          name: 'resource.experienceOverview',
          url: '/experience-overview',
          component: EmployeeExperienceOverviewComponent,
        },
        {
          name: 'resource.overview',
          url: '/overview',
          component: EmployeeWorkOverviewComponent,
        },
        {
          name: 'resource.dynamics',
          url: '/dynamics',
          component: EmployeeWorkDynamicComponent,
        },
        {
          name: 'resource.timeOffBalance',
          url: '/time-off-balance',
          component: EmployeeTimeOffBalanceComponent,
        },
      ],
    }),
  ],
})
export class ResourcesModule {}
