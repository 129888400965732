import { Injectable } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/angular';
import { BehaviorSubject } from 'rxjs';
import { AppName } from '../shared/globals/app-name';
import { BaseSettings } from '../shared/models/inner/base-settings.interface';
import { LocalConfigService } from './local-config.service';
import { clone } from 'lodash';

@Injectable({
  providedIn: 'root',
})

/**
 * Сервис для работы с историей навигации между приложениями
 * Позволяет сохранять историю перемещений в localStorage
 * И извлекать историю перемещений в компонентах
 */
export class HistoryService {
  /**
   * Переменная для извлечения/добавления данных из(в) localStorage
   * @private
   */
  private _historySettings: HistorySettings;

  /**
   * Переменная содержащая максимальное кол-во хранимых historyItems
   */
  private _maxItemsCount = 25;

  /**
   *  Переменная содержащая subject на изменение истории
   *  @public
   */
  public history$: BehaviorSubject<HistoryItem[]>;

  /**
   *
   * @param localService сервис для сохранения истории в localStorage
   * @param stateService сервис для извлечения текущего состояния маршрутизатора
   */
  constructor(
    private localService: LocalConfigService,
    private routerGlobals: UIRouterGlobals,
  ) {
    this._historySettings = this.localService.getConfig(HistorySettings);
    this.history$ = new BehaviorSubject(this._historySettings.historyItems);
  }
  /**
   * Возвращает кол-во сохраненных элементов истории
   */
  get savedItemsCount(): number {
    return this._historySettings.historyItems.length;
  }

  /**
   * Извлекает первые _maxItemsCount элементов из истории
   */
  sliceHistoryItems() {
    this._historySettings.historyItems =
      this._historySettings.historyItems.slice(0, this._maxItemsCount);
  }

  /**
   *
   * @param item {HistoryItem} элемент истории
   * Добавляет элемент истории в начало истории
   * Пересохраняет историю в localStorage
   */
  saveHistoryItem(item: HistoryItem) {
    this._historySettings = this.localService.getConfig(HistorySettings);
    this._historySettings.historyItems = [
      item,
      ...this._historySettings.historyItems,
    ];
    this.sliceHistoryItems();
    this.localService.setConfig(HistorySettings, this._historySettings);
  }

  private isObjectEmpty(obj: object): boolean {
    return Object.keys(obj).length === 0;
  }

  /**
   * @param itemToSave {HistoryItem} элемент истории для сохранения
   * @returns {boolean} совпадает ли новый элемент истории с последним сохраненным
   *
   */
  isItemEqualLastSaved(itemToSave: HistoryItem): boolean {
    const lastSavedItem = this._historySettings.historyItems[0];
    if (!lastSavedItem) {
      return false;
    }
    /**
     * Элементы считаются равными, если имеют одинаковые state и id сущности
     * (если id не существует, то проверка осуществляется только по state)
     */

    /**
     * Равны ли state
     */
    let isStateEqual = false;

    /**
     * Равны ли id элементов
     */
    let isIdEqual = false;

    if (itemToSave.state === lastSavedItem.state) {
      isStateEqual = true;
    }

    /**
     * Проверка на существование id сущности
     * Если id не существует то проверяем только по state
     */
    if (!this.isObjectEmpty(lastSavedItem) || !this.isObjectEmpty(itemToSave)) {
      /**
       * Если id совпадают, то элементы равны
       */
      if (
        JSON.stringify(lastSavedItem.stateParams) ===
        JSON.stringify(itemToSave.stateParams)
      ) {
        isIdEqual = true;
      }
      return isIdEqual && isStateEqual;
    }

    return isStateEqual;
  }

  /**
   * Если текущий элемент истории не совпадает с последним сохраненным,
   * то сохраняем его и отправляем данные в history subject
   *
   * @param nameSegments {string[]} сегменты имени элемента истории
   *
   */
  addHistory(nameSegments: string[], appName: AppName) {
    const state = this.routerGlobals.current.name;
    const stateParams = clone(this.routerGlobals.params);
    const itemToSave = {
      nameSegments,
      app: appName,
      state,
      stateParams,
    };

    if (this.isItemEqualLastSaved(itemToSave)) {
      return;
    }

    this.saveHistoryItem(itemToSave);
    this.history$.next(this._historySettings.historyItems);
  }

  getHistory() {
    return this.history$.asObservable();
  }
}

export interface HistoryItem {
  nameSegments: string[];
  app: AppName;
  state: string;
  stateParams: object;
}

export class HistorySettings implements BaseSettings {
  public readonly name = 'HistorySettings';
  version: number;
  historyItems: HistoryItem[];
  getDefault(): HistorySettings {
    return {
      version: 2,
      historyItems: [],
    } as HistorySettings;
  }
}
