<wp-timesheet-header
  [name]="service.name$ | async"
  [hidden]="(service.state$ | async) !== 'Ready'"
  [timesheet]="service.timesheet$ | async"
>
</wp-timesheet-header>

<wp-loading-indicator [state]="service.state$ | async"> </wp-loading-indicator>

<tmt-not-found [state]="service.state$ | async" [urlMustContain]="entityId"></tmt-not-found>

<ng-container *ngIf="(service.state$ | async) === 'Ready'">
  <form class="form">
    <div class="subheader">
      <div class="subheader__props">
        <div class="subheader__prop">
          <wp-state-indicator></wp-state-indicator>
        </div>

        <div *ngIf="service.timesheet?.state.code === 'Draft'" class="subheader__prop">
          <div class="subheader__label">{{ 'timesheets.card.props.sendDueDate' | translate }}:</div>
          <div class="subheader__value">
            {{ service.timesheet?.dueDate | date: 'shortDate' }}
          </div>
        </div>

        <div class="subheader__prop">
          <div class="subheader__label">{{ 'timesheets.card.props.user' | translate }}:</div>
          <div id="timesheet-user" class="subheader__value">
            <button (click)="openUserInfo()" class="btn btn-link btn-link-content" type="button">
              {{ service.timesheet?.user?.name }}
            </button>
          </div>
        </div>
        @if (app.session.configuration.multiLegalEntity) {
          <div class="subheader__prop">
            <div class="subheader__label">
              {{ 'timesheets.card.props.legalEntity' | translate }}:
            </div>
            <div class="subheader__value">
              <span [title]="service.timesheet?.legalEntity?.name">{{
                service.timesheet?.legalEntity?.name
              }}</span>
            </div>
          </div>
        }
        <div class="subheader__prop">
          <tmt-workflow-indicator></tmt-workflow-indicator>
        </div>
      </div>
    </div>

    <wp-table-view></wp-table-view>
  </form>

  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-underline mt-5">
    <li [ngbNavItem]="'details'">
      <a ngbNavLink>{{ 'timesheets.details.tab' | translate }}</a>
      <ng-template ngbNavContent>
        <wp-timesheet-details
          class="mt-2 d-block"
          [template]="service.timesheet?.template"
        ></wp-timesheet-details>
      </ng-template>
    </li>

    <li [ngbNavItem]="'activity'">
      <a ngbNavLink>{{ 'shared.comments.tabs.activity' | translate }}</a>
      <div class="nav-tab-content mt-3" *ngbNavContent>
        <wp-comments-tab
          [entityId]="entityId"
          [collection]="CommentedEntityCollectionType.TimeSheets"
        ></wp-comments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'attachments'">
      <a ngbNavLink>{{ 'shared.comments.tabs.attachments' | translate }}</a>
      <div class="nav-tab-content mt-3" *ngbNavContent>
        <wp-attachments-tab
          [entityId]="entityId"
          [collection]="CommentedEntityCollectionType.TimeSheets"
        ></wp-attachments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'linkedTasks'">
      <a ngbNavLink>{{ 'base.workflow.tab' | translate }}</a>
      <tmt-entity-workflow-tasks
        [entityId]="entityId"
        class="mt-2 d-block"
        *ngbNavContent
      ></tmt-entity-workflow-tasks>
    </li>
    <li [ngbNavItem]="'stateHistory'">
      <a ngbNavLink>{{ 'base.stateHistory.tab' | translate }}</a>
      <tmt-state-history
        [entityId]="entityId"
        *ngbNavContent
        class="mt-2 d-block"
      ></tmt-state-history>
    </li>
    <wp-sort class="ms-auto"></wp-sort>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
</ng-container>
