import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { filter, shareReplay, takeUntil } from 'rxjs/operators';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { NotificationService } from 'src/app/core/notification.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { Interval } from 'luxon';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import {
  Schedule,
  ScheduleException,
} from 'src/app/shared/models/entities/settings/schedule.model';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { BaseEntry } from 'src/app/shared-features/planner/planner-cell/base-entry.model';
import { ResourceViewGroupLine } from 'src/app/projects/card/project-resources/models/project-resources-view.model';
import { TaskLine } from 'src/app/projects/card/project-resource-requirements/models/view-data/task-line.model';
import { ProjectBillingType } from 'src/app/shared/models/enums/project-billing-type';

/** Сервис управления карточкой проекта. */
@Injectable()
export class ProjectCardService {
  private destroyed$ = new Subject<void>();

  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  private reloadTabSubject = new Subject<void>();
  public reloadTab$ = this.reloadTabSubject.asObservable();

  public get project(): Project {
    return this.projectSubject.getValue();
  }
  private projectSubject = new BehaviorSubject<Project>(null);
  public project$ = this.projectSubject.asObservable().pipe(filter((p) => !!p));
  private projectTotalSubject = new BehaviorSubject<any>(null);
  public projectTotal$ = this.projectTotalSubject
    .asObservable()
    .pipe(filter((p) => p));

  /** Выбранный период в ресурсном плане. */
  public plannerPeriodOffset: number;

  /** Selected interval for project task timeline. */
  public projectTaskTimelineInterval: Interval;

  /** List of expanded project task ids. Uses for saving tasks state while works with project. */
  public expandedTasks: string[] = [];

  /** Current timeline scroll position. Uses for saving scroll position while works with project. */
  public timelineScrollPosition: number | null = null;

  public get isProjectBillable(): boolean {
    return (
      this.project.billingType.code === ProjectBillingType.tm.code ||
      this.project.billingType.code === ProjectBillingType.fixedBid.code
    );
  }

  constructor(
    @Inject('entityId') public projectId: string,
    private data: DataService,
    private notification: NotificationService,
    private actionService: ActionPanelService,
    private navigationService: NavigationService,
    private projectTasksService: ProjectTasksService,
    private versionCardService: ProjectVersionCardService,
    private lifecycleService: LifecycleService,
  ) {
    actionService.reload$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.reloadTab();
      this.resetTaskCache();
      this.lifecycleService.reloadLifecycle();
    });
    lifecycleService.reload$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.load();
      this.resetTaskCache();
    });
  }

  /** Обновить активную вкладку. */
  public reloadTab() {
    this.reloadTabSubject.next();
  }

  /** Загрузить проект. */
  load() {
    this.state$.next(CardState.Loading);
    this.loadProject();
    this.loadProjectTotal();
  }

  private loadProjectTotal() {
    const query = {
      select: ['inheritedProjectsCount'],
    };

    this.data
      .collection('ProjectTotals')
      .entity(this.projectId)
      .get(query)
      .subscribe({
        next: (projectTotal: any) => {
          this.projectTotalSubject.next(projectTotal);

          this.actionService.action('updateInheritedProjects').isShown =
            projectTotal.inheritedProjectsCount > 0;
        },
        error: (error: Exception) => {
          this.projectTotalSubject.next({});
        },
      });
  }

  private loadProject() {
    const projectQuery = {
      select: [
        'id',
        'name',
        'externalUrl',
        'startDate',
        'endDate',
        'editAllowed',
        'tasksViewAllowed',
        'tasksEditAllowed',
        'versionViewAllowed',
        'versionEditAllowed',
        'versionMergeAllowed',
        'billingEstimationSettings',
        'teamViewAllowed',
        'teamEditAllowed',
        'resourcePlanViewAllowed',
        'resourcePlanEditAllowed',
        'expenseEstimateViewAllowed',
        'expenseEstimateEditAllowed',
        'revenueEstimateViewAllowed',
        'revenueEstimateEditAllowed',
        'billingEstimateViewAllowed',
        'billingEstimateEditAllowed',
        'coManagersViewAllowed',
        'coManagersEditAllowed',
        'userSkillEstimationViewAllowed',
        'userSkillEstimationEditAllowed',
        'financeViewAllowed',
        'scheduleId',
        'defaultTaskType',
        'defaultTaskPlanningMode',
        'isAutoPlanning',
        'costCentersViewAllowed',
        'costCentersEditAllowed',
        'tariffsViewAllowed',
        'tariffsEditAllowed',
      ],
      expand: [
        { billingType: { select: 'code' } },
        { manager: { select: ['name', 'id'] } },
        { sourceProject: { select: ['name', 'id'] } },
        { organization: { select: ['name', 'id'] } },
        { state: { select: ['name', 'id', 'code'] } },
        { currency: { select: ['name', 'id', 'alpha3Code'] } },
      ],
    };

    const scheduleQuery = {
      select: ['firstDay', 'daysCount', 'name', 'id', 'scheduleExceptionId'],
      expand: [
        {
          patternDays: {
            select: ['dayLength', 'dayNumber'],
            orderBy: 'dayNumber',
          },
          scheduleException: {
            select: 'id',
            expand: {
              exceptionDays: {
                select: ['date', 'dayLength'],
                orderBy: 'date',
              },
            },
          },
        },
      ],
    };

    forkJoin({
      project: this.data
        .collection('Projects')
        .entity(this.projectId)
        .get(projectQuery),
      schedule: this.data
        .collection('Projects')
        .entity(this.projectId)
        .collection('Schedule')
        .query(scheduleQuery),
    }).subscribe({
      next: (result: { project: Project; schedule: Schedule }) => {
        result.project.schedule = result.schedule;
        this.projectSubject.next(result.project);
        this.state$.next(CardState.Ready);
        this.navigationService.addRouteSegment({
          id: result.project.id,
          title: result.project.name,
        });
      },
      error: (error: Exception) => {
        this.state$.next(CardState.Error);
        if (error.code !== Exception.BtEntityNotFoundException.code) {
          this.notification.error(error.message);
        }
      },
    });
  }

  public updateInheritedProjects(): void {
    this.actionService.action('updateInheritedProjects').start();
    this.data
      .collection('Projects')
      .entity(this.projectId)
      .action('WP.UpdateInheritedProjects')
      .execute()
      .subscribe({
        next: () => {
          this.actionService.action('updateInheritedProjects').stop();
          this.notification.successLocal(
            'components.projectCardService.messages.inheritedProjectsHaveBeenUpdated',
          );
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  public dispose() {
    this.destroyed$.next();
  }

  /** Resets the task's cache by the ProjectTasksService. */
  public resetTaskCache(): void {
    this.projectTasksService.resetProjectTasks(
      this.project.id,
      this.versionCardService.projectVersion,
    );
  }

  /** Determines ability to entry editing.
   *
   * @param entry checking entry
   * @returns is entry can be editable.
   */
  public isEntryBlocked(entry: BaseEntry): boolean {
    // Entry can not be editing for non-working days for project with auto planning enabled.
    return !entry.scheduleHours && this.project?.isAutoPlanning;
  }

  /** Determines ability to edit line.
   *
   * @param line checking line
   * @returns is line can be editable.
   */
  public isLineBlocked(line: ResourceViewGroupLine | TaskLine): boolean {
    return line.isSummaryTask && this.project?.isAutoPlanning;
  }

  /** Returns schedule exception.
   *
   * @param scheduleExceptionId id of schedule exception
   * @returns Observable of schedule exception
   */
  private getProjectScheduleException(
    scheduleExceptionId: string,
  ): Observable<ScheduleException> {
    const params = {
      select: 'id',
      expand: {
        exceptionDays: { select: ['date', 'dayLength'], orderBy: 'date' },
      },
    };

    return this.data
      .collection('ScheduleExceptions')
      .entity(scheduleExceptionId)
      .get<ScheduleException>(params)
      .pipe(shareReplay());
  }
}
