<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.users.card.profile.passwordReset.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label class="control-label">{{
        'settings.users.card.profile.passwordReset.newPassword' | translate
      }}</label>
      <wp-text-box
        formControlName="password"
        [password]="true"
        placeholder="{{ 'settings.users.card.profile.passwordReset.newPassword' | translate }}"
      >
      </wp-text-box>
      <small class="text-body-secondary">{{ passwordRequirements$$ | async }}</small>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.reset' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
