import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import {
  GridColumnType,
  GridSelectControlColumn,
} from 'src/app/shared/models/inner/grid-column.interface';

import { TransitionsService } from 'src/app/settings-app/lifecycle/card/transitions.service';
import { TransitionFormService } from './transition-form.service';
import { LifecycleCardService } from 'src/app/settings-app/lifecycle/card/lifecycle-card.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-transition-form',
  templateUrl: './transition-form.component.html',
  styleUrls: ['./transition-form.component.scss'],
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitionFormComponent implements OnInit {
  @Input() transitionFormFormGroup: UntypedFormGroup;
  @Input() lifecycleId: string;

  public get requestedProperties(): UntypedFormArray {
    return this.transitionFormFormGroup.controls[
      'requestedProperties'
    ] as UntypedFormArray;
  }

  public gridOptions: Grid2Options = {
    sorting: false,
    selectionType: SelectionType.row,
    commands: [
      {
        name: 'create',
        handlerFn: () => this.addProperty(),
      },
      {
        name: 'delete',
        handlerFn: () => this.deleteProperty(),
      },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        handlerFn: (formGroup: UntypedFormGroup, index: number) =>
          this.deleteProperty(index),
      },
    ],
    view: {
      name: 'strings',
      columns: [
        <GridSelectControlColumn>{
          name: 'nameControl',
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.SelectControl,
          placeholder: 'shared.columns.name',
          values: this.transitionFormService.allowedTransitionFormProperties$,
        },
        {
          name: 'isRequired',
          header:
            'settings.lifecycles.card.props.transition.form.columns.isRequired.header',
          hint: 'settings.lifecycles.card.props.transition.form.columns.isRequired.hint',
          type: GridColumnType.BooleanControl,
          placeholder:
            'settings.lifecycles.card.props.transition.form.columns.isRequired.header',
        },
      ],
    },
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    public gridService: GridService,
    private lifecycleCardService: LifecycleCardService,
    private transitionsService: TransitionsService,
    private transitionFormService: TransitionFormService,
  ) {}

  /** Добавить свойство. */
  addProperty() {
    const newGroup = this.transitionsService.getRequestedPropertyFormGroup();
    this.requestedProperties.push(newGroup);
    this.gridService.selectGroup(newGroup);
  }

  /** Удалить свойство. */
  deleteProperty(index?: number) {
    const requestedProperties = this.transitionFormFormGroup.controls
      .requestedProperties as FormArray;
    index =
      index ??
      requestedProperties.value.findIndex(
        (s) => s.id === this.gridService.selectedGroupValue.id,
      );

    requestedProperties.removeAt(index);
    requestedProperties.markAsDirty();
  }

  ngOnInit(): void {
    this.lifecycleCardService.changes$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.gridService.detectChanges());
  }
}
