import { Component, OnInit } from '@angular/core';
import { TimeOffBalanceEntryType } from 'src/app/shared/models/entities/team/time-off-balance-entry-type.enum';
import { TimeOffBalanceEntryService } from '../../core/time-off-balance-entry.service';

import { TranslateService } from '@ngx-translate/core';

import { NavigationService } from 'src/app/core/navigation.service';
import { UIRouterGlobals } from '@uirouter/core';
import { cloneDeep } from 'lodash';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TimeOffType } from 'src/app/shared/models/entities/settings/time-off-type.model';
import { Exception } from 'src/app/shared/models/exception';
import { TimeOffTypeUnits } from 'src/app/shared/models/enums/time-off-type-unit.enum';
import {
  TimeOffBalanceEntryMode,
  TimeOffBalanceEntryModes,
} from 'src/app/shared/models/entities/team/time-off-balance-entry-mode.enum';

@Component({
  selector: 'wp-time-off-balance-entry-body',
  templateUrl: './time-off-balance-entry-body.component.html',
  styleUrls: ['./time-off-balance-entry-body.component.scss'],
})
export class TimeOffBalanceEntryBodyComponent implements OnInit {
  constructor(
    public service: TimeOffBalanceEntryService,
    private notification: NotificationService,
    private data: DataService,
    private translate: TranslateService,
    public navigationService: NavigationService,
    public uiRouterGlobals: UIRouterGlobals,
  ) {}

  public timeOffBalanceEntryType = TimeOffBalanceEntryType;
  public timeOffTypes: NamedEntity[] = [];
  public timeOffTypeUnits: NamedEntity[] = [];
  public creationMods: NamedEntity[] = [];

  ngOnInit(): void {
    this.data
      .collection('TimeOffTypes')
      .query({
        filter: { isActive: true },
        select: ['id', 'name', 'unitId'],
        expand: [{ accrualPolicy: { select: ['id'] } }],
        orderBy: ['name'],
      })
      .subscribe({
        next: (timeOffTypes: TimeOffType[]) => {
          timeOffTypes.forEach((timeOfType) =>
            this.timeOffTypes.push(timeOfType),
          );
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });

    this.timeOffTypeUnits = TimeOffTypeUnits.map((unit) => ({
      id: unit.id,
      name: this.translate.instant(`enums.timeOffTypeUnit.${unit.code}`),
    }));

    this.creationMods = TimeOffBalanceEntryModes.map((mod) => ({
      id: mod.id,
      name: this.translate.instant(`enums.timeOffBalanceEntryMode.${mod.code}`),
    }));

    this.service.form.controls.timeOffType.valueChanges.subscribe((value) => {
      this.service.form.controls.unit.setValue(
        this.timeOffTypeUnits.find((cu) => cu.id === value.unitId),
      );
    });

    if (this.service.mode === 'create') {
      this.service.form.controls.mode.setValue(
        this.creationMods.find(
          (x) => x.id === TimeOffBalanceEntryMode.manual.id,
        ),
      );
    }
  }

  // Returns state parameters for opening a document in a new tab
  public getDocumentStateParams(): object {
    return {
      entityId: this.service.form.value.documentId,
      navigation: this.navigationService.selectedNavigationItem?.name,
      route: cloneDeep(this.navigationService.storedRoute),
    };
  }
}
