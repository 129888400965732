import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BookingService } from './core/booking.service';
import { BookingDataService } from './core/booking-data.service';
import { BookingMode } from 'src/app/shared/models/enums/booking-mode.enum';

@Component({
  selector: 'wp-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['booking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingComponent implements OnInit, OnDestroy {
  @Input() frRootContainerId: string;
  @Input() frName: string;
  @Input() frSyncWith: string;
  @Input() frDisableHorizontalScroller: boolean;
  @Input() hasHeader = true;
  @Input() hasToolbar = true;

  public leftTableWidth = 300;
  public bookingModeDetailed: boolean;

  private readonly destroyed$ = new Subject<void>();

  constructor(
    public service: BookingService,
    public dataService: BookingDataService,
    private cdr: ChangeDetectorRef,
  ) {
    this.bookingModeDetailed = service.bookingMode === BookingMode.Detailed;
  }

  public trackById(index: number, row: any): string {
    return row.id;
  }

  public ngOnInit(): void {
    this.service.init();

    this.service.detectChanges$
      .pipe(
        filter((id) => !id),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
