<div [formGroup]="form" name="toolbar">
  <div class="toolbar-container">
    <div class="group d-flex">
      <div ngbDropdown class="d-inline-block">
        <button type="button" class="btn btn-default" ngbDropdownToggle>
          {{ getPnlTypeTitle(settings.pnlType) }}
        </button>

        <div ngbDropdownMenu class="dropdown-scroll">
          <button ngbDropdownItem (click)="setPnlType('Operational')">
            {{ 'shared.pnlStatement.pnlType.operational' | translate }}
          </button>

          <button ngbDropdownItem (click)="setPnlType('Financial')">
            {{ 'shared.pnlStatement.pnlType.financial' | translate }}
          </button>
        </div>
      </div>

      <div *ngIf="projectId" ngbDropdown class="d-inline-block">
        <button type="button" class="btn btn-default" ngbDropdownToggle>
          {{ getPnlCurrencyTitle(settings.currency) }}
        </button>

        <div ngbDropdownMenu class="dropdown-scroll">
          <button ngbDropdownItem (click)="setPnlCurrency('project')">
            {{ 'shared.pnlStatement.currency.project' | translate }}
          </button>

          <button ngbDropdownItem (click)="setPnlCurrency('base')">
            {{ 'shared.pnlStatement.currency.base' | translate }}
          </button>
        </div>
      </div>

      <div ngbDropdown class="d-inline-block">
        <button type="button" class="btn btn-default" ngbDropdownToggle>
          {{ getGroupTypeTitle(settings.groupType) }}
        </button>

        <div ngbDropdownMenu class="dropdown-scroll">
          <button
            ngbDropdownItem
            *ngFor="let groupType of groupTypes"
            (click)="setGroupType(groupType.value)"
          >
            {{ groupType.title }}
          </button>
        </div>
      </div>

      <wp-pnl-filter
        *ngIf="showFilter"
        (changes)="onFilterChange($event)"
        [filter]="settings.filter"
      ></wp-pnl-filter>

      <tmt-task-box
        formControlName="task"
        *ngIf="projectId"
        [projectId]="projectId"
        [projectVersion]="projectVersion"
        placeholder="{{ 'shared.pnlStatement.filter.properties.task.placeholder' | translate }}"
      />

      <wp-select-box
        class="control-project"
        formControlName="project"
        *ngIf="programId || organizationId"
        collection="Projects"
        [query]="projectsQuery"
        placeholder="{{ 'shared.pnlStatement.filter.properties.project.placeholder' | translate }}"
      ></wp-select-box>
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input" formControlName="showEstimate" />
        {{ 'shared.pnlStatement.estimate' | translate }}
      </label>
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input" formControlName="showBudget" />
        {{ 'shared.pnlStatement.plan' | translate }}
      </label>
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input" formControlName="showActual" />
        {{ 'shared.pnlStatement.actual' | translate }}
      </label>
    </div>

    <div class="form-check">
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input" formControlName="showForecast" />
        {{ 'shared.pnlStatement.forecast' | translate }}
      </label>
    </div>

    <div class="group d-flex gap-2 ms-auto">
      <div class="group">
        <button
          type="button"
          class="btn btn-default text-nowrap"
          (click)="downloadPnlReport()"
          title="{{ 'shared.pnlStatement.download' | translate }}"
        >
          <i class="bi bi-cloud-arrow-down"></i>&nbsp;Excel
        </button>
      </div>

      <div class="group">
        <div ngbDropdown class="d-inline-block">
          <button
            type="button"
            class="btn btn-default without-caret"
            ngbDropdownToggle
            title="{{ 'shared2.actions.options' | translate }}"
          >
            <i class="bi bi-sliders2 bi-15" aria-hidden="true"></i>
          </button>
          <div ngbDropdownMenu class="p-3">
            <div class="form-check text-nowrap">
              <input
                formControlName="hideEmptyExpenseTypes"
                type="checkbox"
                class="form-check-input"
                id="onlyWithData"
              />
              <label class="form-check-label" for="onlyWithData">
                {{ 'shared.pnlStatement.hideEmptyExpenseTypes' | translate }}
              </label>
            </div>
            <div class="form-check text-nowrap">
              <input
                formControlName="hideWorkingCapital"
                type="checkbox"
                class="form-check-input"
                id="hideWorkingCapital"
              />
              <label class="form-check-label" for="hideWorkingCapital">
                {{ 'shared.pnlStatement.hideWorkingCapital' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<wp-loading-indicator [loading]="loading"></wp-loading-indicator>

<div
  [hidden]="thereIsNoData || loading"
  wpFreezeTable
  leftWidthStrategy="byTable"
  rightWidthStrategy="byTable"
>
  <div name="left">
    <div name="scroll-table-header">
      <table class="table wp-nested-table">
        <thead>
          <tr *ngFor="let headerRow of headerRows; trackBy: trackRowById">
            <th></th>
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table wp-nested-table">
        <tbody>
          <tr *ngFor="let row of visibleRows; trackBy: trackRowById" [ngClass]="getRowClass(row)">
            <td class="trim" [title]="row.header">
              <ng-container *ngIf="row.type === rowType.expandableGroupItem; else rowTitle">
                <span
                  class="bi row-toggle-icon"
                  [ngClass]="getExpandableRowIconClass(row)"
                  (click)="onGroupExpand(row)"
                >
                </span>
                <span>
                  {{ row.header }}
                </span>
              </ng-container>
              <ng-template #rowTitle>
                {{ row.header }}
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div name="scroll-table-footer"></div>
  </div>

  <div name="right" [ngStyle]="{ 'margin-left': leftTableWidth + 'px' }">
    <div name="scroll-table-header">
      <table class="table wp-nested-table">
        <thead>
          <tr *ngFor="let headerRow of headerRows; trackBy: trackRowById">
            <th
              *ngFor="let headerCell of headerRow.cells; trackBy: trackCellById"
              [attr.colspan]="headerCell.colspan ?? 1"
            >
              {{ headerCell.header }}
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table wp-nested-table">
        <tbody>
          <tr *ngFor="let row of visibleRows; trackBy: trackRowById" [ngClass]="getRowClass(row)">
            <td
              *ngFor="let cell of row.cells; trackBy: trackCellById"
              class="trim"
              [ngClass]="{
                'position-relative': cell.deviatesFromRuleBase
              }"
              [title]="cell.displayValue"
            >
              <ng-container *ngIf="cell.fn; else cellPlainValue">
                <button class="btn btn-link" (click)="cell.fn()">
                  {{ cell.displayValue }}
                </button>
              </ng-container>
              <ng-template #cellPlainValue>
                <div class="plain-cell">
                  <span>{{ cell.displayValue }}</span>
                  @if (cell.deviatesFromRuleBase) {
                    <i
                      class="dbi bi-exclamation-triangle text-danger fs-6"
                      [title]="cell.baseHint"
                    ></i>
                  }
                </div>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div name="scroll-table-footer"></div>
  </div>
</div>

<div *ngIf="isPeriodRequired" class="text-center pt-4">
  <button class="btn btn-link btn-select-period text-uppercase" (click)="service.openFilterModal()">
    {{ 'shared.pnlStatement.selectPeriod' | translate }}
  </button>
</div>

<div *ngIf="thereIsNoData && !isPeriodRequired" class="text-center text-body-secondary pt-4">
  {{ 'shared.noDisplayData' | translate }}
</div>
