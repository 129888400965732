<div>
  <p class="trim text-uppercase fw-semibold" data-test="timeOffType">
    {{ request.timeOffType.name }}
  </p>
  <p>
    <tmt-state-badge [state]="request.state"></tmt-state-badge>
  </p>
  <p class="form-control-static" data-test="period">
    {{ request.startDate | date: 'longDate' }}&nbsp;&mdash;&nbsp;{{
      request.finishDate | date: 'longDate'
    }}
  </p>
  <p style="padding-top: 10px" data-test="timeOffRequest">
    <a uiSref="timeOffRequest" [uiParams]="{ entityId: request.id }">{{
      'shared.timeOffRequest' | translate
    }}</a>
  </p>
</div>
