import { Component, Input } from '@angular/core';
import { TimeOffRequest } from 'src/app/shared/models/entities/base/time-off-request.model';

@Component({
  selector: 'wp-time-off-info',
  templateUrl: './time-off-info.component.html',
  styleUrls: ['./time-off-info.component.scss'],
})
export class TimeOffInfoComponent {
  public request: TimeOffRequest;

  @Input() set params(value: { request: TimeOffRequest }) {
    this.request = value.request;
  }
}
