import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { TeamSettings } from './team-settings.model';
import { StateService } from '@uirouter/core';
import { NavigationService } from 'src/app/core/navigation.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { DataService } from 'src/app/core/data.service';
import { User } from 'src/app/shared/models/entities/settings/user.model';

@Injectable()
export class ProjectTeamService {
  public settings$ = new Subject<TeamSettings>();

  public settings: TeamSettings;

  constructor(
    private projectVersionCardService: ProjectVersionCardService,
    private localConfigService: LocalConfigService,
    private navigationService: NavigationService,
    private state: StateService,
    private dataService: DataService,
  ) {
    this.settings = this.localConfigService.getConfig(TeamSettings);
  }

  public setFilter(onlyActive: boolean) {
    this.settings.onlyActive = onlyActive;
    this.settings$.next(this.settings);
    this.localConfigService.setConfig(TeamSettings, this.settings);
  }

  /** Open resource request. */
  public openResourceRequest(requestId: string) {
    this.state.go('resourceRequest', {
      navigation: this.navigationService.selectedNavigationItem?.name,
      routeMode: RouteMode.continue,
      entityId: requestId,
    });
  }

  /**
   * Gets query with filters for `ProjectTariff` select-box.
   *
   * @param projectId project id.
   * @param teamMemberId team member id.
   * @returns ODataQuery.
   */
  public getProjectTariffQuery(
    projectId: string,
    teamMemberId: string,
  ): Record<string, any> {
    const query = {
      select: ['id', 'name'],
      filter: [],
    };

    if (teamMemberId) {
      query.filter.push({
        assignments: {
          any: {
            or: [
              {
                projectTeamMemberId: {
                  type: 'guid',
                  value: teamMemberId,
                },
              },
              {
                isAllTeamRole: true,
              },
            ],
          },
        },
      });
    }

    ProjectVersionUtil.addProjectEntityIdFilter(
      query,
      this.projectVersionCardService.projectVersion,
      projectId,
    );

    return query;
  }

  public getTeamMemberRolesObservable(
    resourceId: string,
  ): Observable<NamedEntity[]> {
    return this.dataService
      .collection('Users')
      .entity(resourceId)
      .get<User>({
        select: 'id',
        expand: {
          additionalUserRoles: {
            expand: {
              role: { select: ['id', 'name'], filter: [{ isActive: true }] },
            },
          },
          role: { select: ['id', 'name'] },
        },
      })
      .pipe(
        map((data) => {
          const result = data.additionalUserRoles.map((userRole) => ({
            id: userRole.role.id,
            name: userRole.role.name,
          }));

          if (data.role && !result.some((role) => role.id === data.role.id)) {
            result.push(data.role);
          }

          return result;
        }),
      );
  }
}
