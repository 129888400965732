import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from 'src/app/core/app.service';
import { TimeInputType } from '../models/enums/time-input-type';
import { DecimalPipe } from '@angular/common';

/** Форматирование значения в часах в соответствии с настройками пользователя на способ ввода времени. */
@Pipe({
  name: 'wpTimeDuration',
})
export class TimeDurationPipe implements PipeTransform {
  constructor(
    private app: AppService,
    private decimalPipe: DecimalPipe,
  ) {}

  /** Выполнение преобразования.
   * @param hours - время в часах.
   * @param schedule - время расписания в часах.
   * @param emptyIfZero - возвращать пустую строку если часы равны 0.
   */
  transform(hours: number, schedule: number, emptyIfZero: boolean): string {
    const isDecimalMode =
      this.app.session.timeInputType !== TimeInputType.HoursAndMinutes;
    const isSchedulePercentMode =
      this.app.session.timeInputType === TimeInputType.SchedulePercent;

    if (hours === null || isNaN(hours)) {
      return '';
    }

    if (isSchedulePercentMode) {
      hours = (hours / schedule) * 100;
    }

    if (isDecimalMode) {
      if (hours === 0 && emptyIfZero) {
        return '';
      }
      const template = isSchedulePercentMode ? '1.0-0' : '1.0-2';
      return this.decimalPipe.transform(hours, template);
    }

    if (hours === 0 && !emptyIfZero) {
      return '00:00';
    }
    if (hours === 0 && emptyIfZero) {
      return '';
    }

    let minutesPart = Math.round(hours * 60);
    const hoursPart = Math.floor(minutesPart / 60);
    minutesPart = minutesPart % 60;

    let hoursStringPart = String(hoursPart);
    if (hoursStringPart.length === 1) {
      hoursStringPart = '0' + hoursStringPart;
    }

    let minutesStringPart = String(minutesPart);
    if (minutesStringPart.length === 1) {
      minutesStringPart = '0' + minutesStringPart;
    }
    return `${hoursStringPart}:${minutesStringPart}`;
  }
}
