import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import _ from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import { Constants } from 'src/app/shared/globals/constants';
import { stateStyles } from 'src/app/shared/models/entities/state.model';
import { Exception } from 'src/app/shared/models/exception';
import { GridColumnType } from 'src/app/shared/models/inner/grid-column.interface';
import { LifecycleCardService } from 'src/app/settings-app/lifecycle/card/lifecycle-card.service';
import { TransitionsService } from 'src/app/settings-app/lifecycle/card/transitions.service';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { TransitionsToolbarComponent } from 'src/app/settings-app/lifecycle/card/state-modal/transitions-toolbar/transitions-toolbar.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateConfiguration } from 'src/app/shared/models/entities/settings/lifecycles/lifecycle.model';

@Component({
  selector: 'tmt-kind-state-modal',
  templateUrl: './kind-state-modal.component.html',
  styleUrls: ['./kind-state-modal.component.scss'],
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KindStateModalComponent implements OnInit {
  @Input() lifecycleId: string;
  @Input() stateId: string | null;
  @Input() isInitial: boolean;
  @Input() index: number;

  public stateStyles = stateStyles;
  public header = '';
  public isSaving = signal<boolean>(false);
  public isLoading = signal<boolean>(false);
  public form = this.formBuilder.group({
    id: ['', Validators.required],
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formCodeMaxLength)],
    ],
    isInitial: false,
    index: null,
    transitions: this.formBuilder.array([]),
  });
  public gridOptions: Grid2Options = {
    toolbar: TransitionsToolbarComponent,
    selectionType: SelectionType.row,
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: (id: string) => !!id,
        handlerFn: (formGroup: FormGroup) => {
          this.editTransition(formGroup);
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (id: string) => !!id,
        handlerFn: (formGroup: FormGroup) => {
          this.deleteTransition(formGroup);
        },
      },
    ],
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          this.transitionsService.editTransition(
            this.lifecycleId,
            this.stateId,
            this.form.controls.transitions.value,
          );
        },
      },
      {
        name: 'edit',
        allowedFn: (id: string) => !!id,
        handlerFn: (formGroup: FormGroup) => {
          this.editTransition(formGroup);
        },
      },
      {
        name: 'delete',
        allowedFn: (id: string) => !!id,
        handlerFn: (formGroup: FormGroup) => {
          this.deleteTransition(formGroup);
        },
      },
    ],
    view: {
      name: 'transitions',
      columns: [
        {
          name: 'name',
          forceCellUpdating: true,
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.String,
        },
        {
          name: 'nextStateName',
          forceCellUpdating: true,
          header: 'settings.lifecycles.card.columns.nextState.header',
          hint: 'settings.lifecycles.card.columns.nextState.hint',
          type: GridColumnType.String,
        },
        {
          name: 'performersString',
          forceCellUpdating: true,
          header: 'settings.lifecycles.card.columns.performers.header',
          hint: 'settings.lifecycles.card.columns.performers.hint',
          type: GridColumnType.String,
        },
      ],
    },
  };

  private destroyRef = inject(DestroyRef);

  public get transitions(): UntypedFormArray {
    return this.form.controls.transitions as UntypedFormArray;
  }

  constructor(
    private lifecycleCardService: LifecycleCardService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private gridService: GridService,
    private transitionsService: TransitionsService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.load();
    this.transitionsService.addTransition$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((newGroup: UntypedFormGroup) => {
        this.addNewTransitionGroup(newGroup);
        this.gridService.detectChanges();
      });
  }

  /** Saves kind states. */
  public save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.form.enable();
    const transitionsData = this.transitionsService.prepareTransitionsDTO(
      this.form.controls.transitions.value,
    );
    const data: StateConfiguration = {
      stateId: this.form.value.id,
      isInitial: this.form.value.isInitial,
      index: this.form.value.index,
      entityKindId: this.lifecycleCardService.kindId,
      transitions: transitionsData,
    };
    this.lifecycleCardService.prepareSavingLocalization(data.transitions);

    this.isSaving.set(true);
    this.lifecycleCardService
      .setConfiguration(this.stateId, data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.isSaving.set(false);
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.isSaving.set(false);
          this.notificationService.error(error.message);
        },
      });
  }

  /** Closes modal. */
  public cancel(): void {
    this.activeModal.dismiss();
  }

  /** Loads data. */
  private load(): void {
    this.isLoading.set(true);

    this.lifecycleCardService
      .getConfiguration(this.stateId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (transitions) => {
          const state = this.lifecycleCardService.lifecycleStates.find(
            (state) => state.id === this.stateId,
          );
          this.setHeader(state.name);
          this.form.patchValue(state);
          this.form.get('isInitial').setValue(this.isInitial);
          this.form.get('index').setValue(this.index);
          if (transitions.length) {
            transitions.map((transition) => {
              this.addNewTransitionGroup(transition);
            });
          }
          this.isLoading.set(false);
          if (this.form.controls.isInitial.value) {
            this.form.controls.isInitial.disable();
          }
          this.cdr.markForCheck();
        },
        error: (error: Exception) => {
          this.isLoading.set(false);
          this.notificationService.error(error.message);
        },
      });
  }

  /**
   * Adds new group.
   *
   * @param transition transition data.
   */
  private addNewTransitionGroup(transition: any): void {
    const transitionGroup =
      this.transitionsService.getTransitionGroup(transition);
    /* Add group to transitions form array. */
    const transitions = this.form.controls.transitions as UntypedFormArray;
    transitions.push(transitionGroup);
    this.gridService.detectChanges();
  }

  /** Sets header value.
   *
   * @param header header name.
   */
  private setHeader(header?: string): void {
    this.header = header
      ? header
      : 'settings.lifecycles.card.props.state.createHeader';
  }

  /**
   * Edits transition.
   *
   * @param formGroup editing form group.
   */
  private editTransition(formGroup: FormGroup): void {
    this.transitionsService.editTransition(
      this.lifecycleId,
      this.stateId,
      this.form.controls.transitions.value,
      formGroup,
    );
  }

  /**
   * Deletes transition from form array.
   *
   * @param formGroup deleting form group.
   */
  private deleteTransition(formGroup: FormGroup): void {
    const index = this.form.controls.transitions.value.findIndex(
      (transition) => transition.id === formGroup.value.id,
    );
    if (index || index === 0) {
      this.transitions.removeAt(index);
    }
  }
}
