<!-- Timeline string. -->
<tr class="line" (click)="gridService.selectGroup(taskFormGroup)">
  <td [attr.colspan]="(service.entries$ | async)?.length" class="w-100">
    <div class="task-board">
      <wp-timeline-entry [taskFormGroup]="taskFormGroup"></wp-timeline-entry>
    </div>
    <table class="wp-nested-table">
      <tbody>
        <tr
          [ngClass]="{
            'selected-row': (this.gridService.selectedGroups$ | async)?.includes(taskFormGroup)
          }"
        >
          @for (entry of service.entries$ | async; track entry.id) {
            <td [ngClass]="{ 'non-working': entry.nonWorking, 'entry-active': entry.active }"></td>
          }
        </tr>
      </tbody>
    </table>
  </td>
</tr>
