<form name="dialog" class="modal-form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">{{ header }}</h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <p [innerHTML]="message | linky | nl2br"></p>
    <ng-container *ngIf="items && items.length > 0">
      <ul>
        <li *ngFor="let item of items">{{ item }}</li>
      </ul>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button
      id="ok"
      type="button"
      style="min-width: 100px"
      class="btn btn-primary"
      (click)="ok()"
      tmtAutofocus
    >
      {{ buttonOk }}
    </button>
    <button
      *ngIf="buttonCancel"
      id="cancel"
      type="button"
      style="min-width: 100px"
      class="btn btn-default"
      (click)="cancel()"
    >
      {{ buttonCancel }}
    </button>
  </div>
</form>
