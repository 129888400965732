<tmt-form-header [name]="form.value.name"></tmt-form-header>
<wp-loading-indicator [state]="state"></wp-loading-indicator>
<tmt-not-found [state]="state"></tmt-not-found>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" class="form mt-3" [hidden]="state !== 'Ready'">
  <div class="row">
    <div class="col-xs-6 col-lg-4">
      <label class="group">
        {{ 'settings.timesheetTemplates.card.sectionMain' | translate }}
      </label>
      <div class="form-group" tmtIndicator>
        <label>{{ 'shared.props.name' | translate }}</label>
        <wp-text-box
          formControlName="name"
          placeholder="{{ 'shared.props.name' | translate }}"
        ></wp-text-box>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-8 col-lg-5">
      <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
        <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
        <label class="form-check-label" for="isActive">
          {{ 'shared.props.isActive.label' | translate }}
        </label>
      </div>

      <div class="form-group mt-3">
        <p *ngIf="template?.isDefault" class="form-control-static fw-semibold">
          <i aria-hidden="true" class="bi bi-check-circle"></i>&nbsp;{{
            'settings.timesheetTemplates.card.props.usingByDefault' | translate
          }}
        </p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-9 col-lg-6">
      <label class="group mt-3">
        {{ 'settings.timesheetTemplates.card.sectionAutomatization' | translate }}
      </label>
      <div class="form-group">
        <div class="form-check">
          <input
            formControlName="createAutomatically"
            type="checkbox"
            class="form-check-input"
            id="createAutomatically"
          />
          <label class="form-check-label" for="createAutomatically">
            {{ 'settings.timesheetTemplates.card.props.createAutomatically' | translate }}
          </label>
        </div>

        <div class="form-check">
          <input
            formControlName="clearEmptyLinesAutomatically"
            type="checkbox"
            class="form-check-input"
            id="clearEmptyLinesAutomatically"
          />
          <label class="form-check-label" for="clearEmptyLinesAutomatically">
            {{ 'settings.timesheetTemplates.card.props.clearEmptyLinesAutomatically' | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-9 col-lg-6">
      <label class="group mt-3">
        {{ 'settings.timesheetTemplates.card.sectionApproving' | translate }}
      </label>
      <div class="form-group">
        <label>{{ 'settings.timesheetTemplates.card.props.dueDateLag' | translate }}</label>
        <wp-number-box min="0" type="integer" [allowNull]="false" formControlName="dueDateLag">
        </wp-number-box>
        <small class="text-body-secondary">{{
          'settings.timesheetTemplates.card.props.dueDateLagHelp' | translate
        }}</small>
      </div>

      <div class="form-check">
        <input
          formControlName="timeOffAutoApproval"
          type="checkbox"
          class="form-check-input"
          id="timeOffAutoApproval"
        />
        <label class="form-check-label" for="timeOffAutoApproval">
          {{ 'settings.timesheetTemplates.card.props.timeOffAutoApproval' | translate }}
        </label>
      </div>

      <div class="form-check">
        <input
          formControlName="emptyAutoApproval"
          type="checkbox"
          class="form-check-input"
          id="emptyAutoApproval"
        />
        <label class="form-check-label" for="emptyAutoApproval">
          {{ 'settings.timesheetTemplates.card.props.emptyAutoApproval' | translate }}
        </label>
      </div>

      <label class="group mt-3">
        {{ 'settings.timesheetTemplates.card.sectionUI' | translate }}
      </label>

      <div class="form-group">
        <div class="form-check">
          <input
            formControlName="showActivity"
            type="checkbox"
            class="form-check-input"
            id="showActivity"
          />
          <label class="form-check-label" for="showActivity">
            {{ 'settings.timesheetTemplates.card.props.showActivity' | translate }}
          </label>
        </div>

        <div class="form-check">
          <input
            formControlName="showRole"
            type="checkbox"
            class="form-check-input"
            id="showRole"
          />
          <label class="form-check-label" for="showRole">
            {{ 'settings.timesheetTemplates.card.props.showRole' | translate }}
          </label>
        </div>

        <div class="form-check">
          <input
            formControlName="showTariff"
            type="checkbox"
            class="form-check-input"
            id="showTariff"
          />
          <label class="form-check-label" for="showTariff">
            {{ 'settings.timesheetTemplates.card.props.showTariff' | translate }}
          </label>
        </div>

        <div class="form-check">
          <input
            formControlName="showClient"
            type="checkbox"
            class="form-check-input"
            id="showClient"
          />
          <label class="form-check-label" for="showClient">
            {{ 'settings.timesheetTemplates.card.props.showClient' | translate }}
          </label>
        </div>

        <div class="form-check">
          <input
            formControlName="showProjectCostCenter"
            type="checkbox"
            class="form-check-input"
            id="showProjectCostCenter"
          />
          <label class="form-check-label" for="showProjectCostCenter">
            {{ 'settings.timesheetTemplates.card.props.showProjectCostCenter' | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>

  <wp-validation-rules-section
    [formArray]="form.controls['validationRules']"
    [readonly]="readonly"
  ></wp-validation-rules-section>

  <wp-custom-fields-section
    [formArray]="form.controls['customFields']"
    [readonly]="readonly"
  ></wp-custom-fields-section>

  <tmt-project-states-section
    [formArray]="form.controls['allowedProjectStates']"
    [readonly]="readonly"
  ></tmt-project-states-section>

  <div class="actions" *ngIf="!readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
