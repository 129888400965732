/* eslint-disable @typescript-eslint/naming-convention */
import { NamedEntity } from '../named-entity.model';
import { VatRate } from './vat-rate.model';
import { CurrencyEntity } from '../currency-entity.model';
import { InvoiceLine } from './invoice-line.model';
import { State } from '../state.model';

/** The Invoice. */
export class Invoice extends NamedEntity {
  public static readonly draftStateId = 'e4648fa0-f9ad-48ba-9421-17fb5b91e4bb';
  public static readonly paidStateId = '1e042723-6442-44d6-9815-b3880423f2d5';
  public static readonly voidStateId = '42f1ba6c-403c-44b7-bac9-0b46509b2d9a';
  public static readonly systemStateIds = [
    Invoice.draftStateId,
    Invoice.paidStateId,
    Invoice.voidStateId,
  ];

  /** The Author. */
  user: NamedEntity;

  /** The Project. */
  project: CurrencyEntity;

  /** The Client. */
  organization: NamedEntity;

  /** The Billing address. */
  billingAddress: string;

  /** Invoice's state. */
  state: State;

  /** The Invoice number. */
  number: string;

  /** The Invoice number (auto-incremented). */
  indexNumber: number;

  /** The Description (is reflected on print form). */
  description: string;

  /** The Planned payment date (pay before). */
  paymentDueDate: string;

  /** The Actual payment date. */
  paymentDate: string;

  /** The Issue date (Invoice date). */
  issueDate: string;

  /** The Invoice line group order. Used to group lines on Invoice form. */
  grouping: string;

  /** The Invoice Time line group order. */
  timeLinesGrouping: string;

  /** The Invoice Expense line group order. */
  expenseLinesGrouping: string;

  /** The Notes for inner usage (ARE NOT reflected on print form). */
  innerNotes: string;

  /** The Notes for outer usage (ARE reflected on print form). */
  outerNotes: string;

  /** The Total sum. */
  totalAmount: number;

  /** The Invoice lines (of any type). */
  lines: InvoiceLine[];

  /** The Template. (is used to create print form). */
  invoiceTemplate: {
    id: string;
    name: string;
    vatRate: VatRate;
  };
}
