<div (clickOutside)="cancel()" [attachOutsideOnClick]="true">
  <div
    class="face"
    (click)="openArea()"
    title="{{ activity?.name }}"
    [ngClass]="{ readonly: readonly }"
  >
    <div *ngIf="activity">
      {{ activity?.name }}
    </div>
    <div *ngIf="!activity" class="trim placeholder">
      {{ 'timesheets.card.columns.activity.header' | translate }}
    </div>
    <i class="bi bi-chevron-down expander" aria-hidden="true"></i>
  </div>
  <div *ngIf="areaExpanded" class="expanding-area expanding-area-vertical-scroll">
    <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

    <div
      *ngIf="activities?.length === 0 && !isLoading"
      class="center-block"
      style="text-align: center; padding: 3px"
    >
      <p class="text-body-secondary" style="margin: 0">
        {{ 'shared.noOptions' | translate }}
      </p>
    </div>
    <table class="selecting-list" [hidden]="isLoading">
      <tbody>
        <tr
          *ngFor="let activityItem of activities"
          (mouseenter)="selectRow(activityItem)"
          (click)="clickRow(activityItem)"
          id="{{ activityItem.id }}"
          title="{{ activityItem.name }}"
          class="select-tem"
          [ngClass]="{ 'selected-row': activityItem.id == activity?.id }"
        >
          <td class="trim">{{ activityItem.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
