<div name="indicator" *ngIf="sourceDescription">
  <button
    class="btn btn-link btn-action p-2 rounded-0 rounded-top"
    (click)="toggleFilterArea()"
    [ngClass]="{ 'gray-bk': filterAreaExpanded }"
  >
    <i class="bi bi-funnel" aria-hidden="true"></i>&nbsp;<span>{{ filterTitle }}</span
    >&nbsp;
    <i
      [ngClass]="{
        'bi-chevron-down': filterAreaExpanded,
        'bi-chevron-right': !filterAreaExpanded
      }"
      class="bi"
      aria-hidden="true"
    ></i>
  </button>

  <button
    class="btn btn-link btn-action ms-3 p-2 rounded-0 rounded-top"
    (click)="togglePeriodArea()"
    *ngIf="sourceDescription?.hasPeriod"
    [ngClass]="{ 'gray-bk': periodAreaExpanded }"
  >
    <i class="bi bi-calendar3 me-1" aria-hidden="true"></i>&nbsp;<span>{{
      reportPeriodService.periodTitle$ | async
    }}</span
    >&nbsp;
    <i
      [ngClass]="{
        'bi-chevron-down': periodAreaExpanded,
        'bi-chevron-right': !periodAreaExpanded
      }"
      class="bi"
      aria-hidden="true"
    ></i>
  </button>
</div>

<div class="area period-area rounded" [hidden]="!periodAreaExpanded">
  <wp-report-period></wp-report-period>
</div>

<div class="area filter-area rounded-bottom rounded-end" [hidden]="!filterAreaExpanded">
  <wp-report-filters
    [reportTypeCode]="reportTypeCode"
    [filters]="filters"
    (onFiltersChanged)="filtersChanged($event)"
    (onTitleChanged)="onFilterTitleChanged($event)"
  ></wp-report-filters>
</div>
