<tmt-form-header [name]="header"></tmt-form-header>
<div class="toolbar-container d-flex justify-content-between">
  <wp-filter />
  <button
    type="button"
    class="btn btn-default"
    (click)="gridService.execute('setUserView')"
    title="{{ 'shared.actions.setView' | translate }}"
  >
    <i class="bi bi-gear" aria-hidden="true"></i>
  </button>
</div>
<wp-grid #grid [options]="gridOptions" [formArray]="formArray"> </wp-grid>
