<div class="toolbar-container">
  <wp-rbc-view-switcher></wp-rbc-view-switcher>

  <wp-schedule-navigation
    [maxScale]="PlanningScale.Year"
    [disabled]="disabled"
  ></wp-schedule-navigation>

  <button
    *ngIf="!projectRbcCalendarService.readonly"
    type="button"
    class="btn btn-default"
    [disabled]="disabled"
    (click)="projectRbcCalendarService.openRevenueEstimatesModal()"
  >
    {{ 'projects.projects.revenues.actions.addRevenue' | translate }}
  </button>

  <wp-project-rbc-group-filter class="ms-auto"></wp-project-rbc-group-filter>
</div>
