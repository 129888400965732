import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { PnlComponent } from './pnl/pnl.component';
import { PnlFilterComponent } from './pnl/pnl-filter/pnl-filter.component';
import { PnlFilterModalComponent } from './pnl/pnl-filter/pnl-filter-modal/pnl-filter-modal.component';

@NgModule({
  declarations: [PnlComponent, PnlFilterComponent, PnlFilterModalComponent],
  imports: [CommonModule, SharedModule],
  exports: [PnlComponent],
})
export class PnlModule {}
