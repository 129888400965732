<form class="modal-form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.settings.tenantSettings.booking.bookingModeChanging' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="control-label">{{
            'settings.settings.tenantSettings.booking.currentMode' | translate
          }}</label>
          <div class="text-body-secondary">{{ currentBookingMode }}</div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="control-label">{{
            'settings.settings.tenantSettings.booking.newMode' | translate
          }}</label>
          <div class="text-body-secondary">{{ newBookingMode }}</div>
        </div>
      </div>
    </div>
    <div>
      <p class="mb-0">
        {{ 'settings.settings.tenantSettings.booking.changeModeWarning' | translate }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.change' | translate }}
    </button>
  </div>
</form>
