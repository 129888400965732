<div *ngIf="!(gridService.readonly$ | async)" class="toolbar-container pt-0">
  <div ngbDropdown class="d-inline-block" (openChange)="openChange($event)" placement="bottom-left">
    <button type="button" class="btn btn-default" ngbDropdownToggle>
      <span class="bi bi-plus-lg bi-15"></span>
      &nbsp;{{ 'settings.users.card.licenses.actions.addProduct' | translate }}
    </button>
    <div ngbDropdownMenu class="dropdown-scroll">
      <div [hidden]="products.length !== 0" hidden class="text-center text-body-secondary">
        {{ 'shared.noOptions' | translate }}
      </div>
      <button
        *ngFor="let product of products"
        ngbDropdownItem
        (click)="selectProduct(product)"
        title="{{ product.displayName }}"
      >
        {{ product.displayName }}
      </button>
    </div>
  </div>
</div>
