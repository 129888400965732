<tr>
  <td class="group-title">
    <div class="d-flex align-items-center">
      <span
        *ngIf="hasLines; else noGroupToggle"
        class="bi group-toggle"
        (click)="onExpand()"
        [ngClass]="getExpandableRowIconClass(group.isExpanded)"
        aria-hidden="true"
      ></span>
      <ng-template #noGroupToggle>
        <span class="no-group-toggle"></span>
      </ng-template>
      <span>{{ group.title }}</span>
      <small class="toggle-all" *ngIf="hasLines" (click)="onExpandAll()">
        <i class="bi" [ngClass]="getToggleAllIconClass(group.isAllExpanded)"></i>
        {{
          (group.isAllExpanded ? 'shared.actions.collapseAll' : 'shared.actions.expandAll')
            | translate
        }}
      </small>
      <i
        class="group-hint bi bi-question-circle text-gray ms-auto d-flex"
        [ngbTooltip]="
          'projects.projects.card.expenses.calendar.groupHints.' + group.type | translate
        "
      ></i>
    </div>
  </td>
  <td class="group">{{ group.total | zeroToNull | number: '1.2-2' }}</td>
</tr>

<ng-container *ngIf="group.isExpanded">
  <ng-container *ngFor="let taskLine of group.taskLines; trackBy: trackByTaskId">
    <tr>
      <td
        class="trim task-line line-title"
        [style.padding-left]="getLineOffsetStyle(taskLine.indent)"
        [title]="taskLine.title"
      >
        <span
          *ngIf="hasExpenseTypes(taskLine); else noTaskToggle"
          class="bi line-toggle"
          (click)="onLineExpand(taskLine)"
          [ngClass]="getExpandableRowIconClass(taskLine.isExpanded)"
          aria-hidden="true"
        ></span>
        <ng-template #noTaskToggle>
          <span class="no-line-toggle"></span>
        </ng-template>
        <span>{{ taskLine.title }}</span>
      </td>
      <td class="line">
        {{ taskLine.total | zeroToNull | number: '1.2-2' }}
      </td>
    </tr>
    <ng-container *ngIf="taskLine.isExpanded">
      <ng-container *ngFor="let typeLine of taskLine.typeLines; trackBy: trackByTypeId">
        <tr>
          <!-- Type line group. -->
          <td
            class="line-title trim expense-line fst-italic"
            [style.padding-left]="getLineOffsetStyle(taskLine.indent + 1)"
            [title]="typeLine.title"
          >
            <span
              *ngIf="hasRules(group, typeLine); else noTypeLineToggle"
              class="bi line-toggle"
              (click)="onLineExpand(typeLine)"
              [ngClass]="getExpandableRowIconClass(typeLine.isExpanded)"
              aria-hidden="true"
            ></span>
            <ng-template #noTypeLineToggle>
              <span class="no-line-toggle"></span>
            </ng-template>
            <span>{{ typeLine.title }}</span>
          </td>
          <td class="line">
            {{ typeLine.total | zeroToNull | number: '1.2-2' }}
          </td>
        </tr>
        <!-- Inner type line and rules. -->
        <ng-container *ngIf="typeLine.isExpanded && hasRules(group, typeLine)">
          <tr *ngIf="typeLine.innerLine">
            <td
              class="trim inner-line line-title expense-line"
              [style.padding-left]="getLineOffsetStyle(taskLine.indent + 2, false)"
              [title]="typeLine.innerLine.title"
            >
              {{ typeLine.innerLine.title }}
            </td>
            <td class="inner-line line">
              {{ typeLine.innerLine.total | zeroToNull | number: '1.2-2' }}
            </td>
          </tr>
          <ng-container *ngFor="let ruleLine of typeLine.rules; trackBy: trackByRuleId">
            <tr>
              <td
                class="trim rule-line line-title"
                [style.padding-left]="getLineOffsetStyle(taskLine.indent + 2, false)"
                [title]="ruleLine.title"
              >
                <div
                  *ngIf="hasDeviationIndicator(group, ruleLine); else ruleLineItem"
                  class="d-flex"
                >
                  <span class="rule-btn-container trim">
                    <button
                      type="button"
                      class="btn btn-link btn-link-content"
                      (click)="projectExpensesService.openExpenseRuleModal(ruleLine.rule.id)"
                    >
                      {{ ruleLine.title }}
                    </button>
                  </span>
                  <i
                    class="update-estimates bi bi-arrow-repeat ms-auto"
                    [ngClass]="{
                      disabled: isRuleEstimateBeingUpdated(ruleLine.rule.id)
                    }"
                    [title]="
                      'projects.projects.card.expenses.calendar.actions.updateRuleEstimates'
                        | translate
                    "
                    (click)="onUpdateExpenseRuleEstimates(ruleLine.rule.id)"
                    data-test="updateEstimates"
                  ></i>
                </div>
                <ng-template #ruleLineItem>
                  <button
                    type="button"
                    class="btn btn-link btn-link-content"
                    (click)="projectExpensesService.openExpenseRuleModal(ruleLine.rule.id)"
                  >
                    {{ ruleLine.title }}
                  </button>
                </ng-template>
              </td>
              <td class="rule-line line">
                {{ ruleLine.total | zeroToNull | number: '1.2-2' }}
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- Other -->
  <ng-container *ngIf="group.type !== ExpensesGroupType.estimate && group.otherLine.total">
    <tr>
      <td
        class="trim other-line line-title"
        [style.padding-left]="getLineOffsetStyle(0)"
        [title]="group.otherLine.title"
      >
        <div class="d-flex align-items-center">
          <span
            class="bi bi-eye-fill d-flex other-toggle"
            [ngClass]="{ 'text-gray opacity-75': !showOther }"
            (click)="onToggleGroupOther()"
          ></span>
          <span [ngClass]="{ 'text-gray opacity-75': !showOther }">{{
            group.otherLine.title
          }}</span>
          <span
            class="line-hint bi bi-question-circle text-gray ms-auto d-flex"
            *ngIf="group.otherLine.verboseHint"
            [ngbTooltip]="group.otherLine.verboseHint"
          ></span>
        </div>
      </td>
      <td class="line">
        {{ (showOther ? group.otherLine.total : 0) | zeroToNull | number: '1.2-2' }}
      </td>
    </tr>
    <ng-container *ngFor="let typeLine of group.otherLine.typeLines; trackBy: trackByTypeId">
      <tr>
        <!-- Type line group. -->
        <td
          class="other-line line-title trim expense-line fst-italic"
          [style.padding-left]="getLineOffsetStyle(1)"
          [title]="typeLine.title"
        >
          <span
            *ngIf="hasRules(group, typeLine); else noOtherTypeLineToggle"
            class="bi other-line line-toggle"
            (click)="onLineExpand(typeLine)"
            [ngClass]="getExpandableRowIconClass(typeLine.isExpanded)"
            aria-hidden="true"
          ></span>
          <ng-template #noOtherTypeLineToggle>
            <span class="no-line-toggle"></span>
          </ng-template>
          <span>{{ typeLine.title }}</span>
        </td>
        <td class="other-line line">
          {{ (showOther ? typeLine.total : 0) | zeroToNull | number: '1.2-2' }}
        </td>
      </tr>
      <!-- Inner type line and rules. -->
      <ng-container *ngIf="typeLine.isExpanded && hasRules(group, typeLine)">
        <tr *ngIf="typeLine.innerLine">
          <td
            class="other-line inner-line line-title trim expense-line"
            [style.padding-left]="getLineOffsetStyle(2, false)"
            [title]="typeLine.innerLine.title"
          >
            {{ typeLine.innerLine.title }}
          </td>
          <td class="other-line inner-line line">
            {{ (showOther ? typeLine.innerLine.total : 0) | zeroToNull | number: '1.2-2' }}
          </td>
        </tr>
        <tr *ngFor="let ruleLine of typeLine.rules; trackBy: trackByRuleId">
          <td
            class="trim other-line rule-line line-title"
            [style.padding-left]="getLineOffsetStyle(2, false)"
            [title]="ruleLine.title"
          >
            <button
              type="button"
              class="btn btn-link btn-link-content"
              (click)="projectExpensesService.openExpenseRuleModal(ruleLine.rule.id)"
            >
              {{ ruleLine.title }}
            </button>
          </td>
          <td class="other-line rule-line line">
            {{ (showOther ? ruleLine.total : 0) | zeroToNull | number: '1.2-2' }}
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
