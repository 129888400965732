import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { AppService } from 'src/app/core/app.service';
import { UserSettingsModalService } from '../user-settings-modal.service';

@Component({
  selector: 'wp-user-settings-notifications',
  templateUrl: './user-settings-notifications.component.html',
})
export class UserSettingsNotificationsComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public form: UntypedFormGroup;

  private destroyed$ = new Subject<void>();

  constructor(
    private service: UserSettingsModalService,
    private notification: NotificationService,
    private data: DataService,
    private app: AppService,
  ) {}

  public ngOnInit(): void {
    this.load();

    this.service.save$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.save());
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * Sets form
   *
   * @param form UntypedFormGroup
   */
  public onForm(form: UntypedFormGroup): void {
    this.form = form;
  }

  public load(): void {
    this.loading = true;

    const query = {
      select: ['notification', 'notifyByEmail', 'useBrowserNotifications'],
    };

    this.data
      .collection('UserSettings')
      .entity(this.app.session.user.id)
      .get<any>(query)
      .subscribe({
        next: (data) => {
          this.form.patchValue(data);
          this.form.markAsPristine();
          this.form.markAsUntouched();
          this.loading = false;
        },
        error: (error: Exception) => {
          this.loading = false;
          this.notification.error(error.message);
        },
      });
  }

  public save(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.service.savingState$.next(true);

      const value = this.form.value;

      const data = {
        notification: {
          notifyOnDueDateExpired: value.notification.notifyOnDueDateExpired,
          workflowTaskCreated: value.notification.workflowTaskCreated,
          issueAssigned: value.notification.issueAssigned,
        },
        notifyByEmail: value.notifyByEmail,
        useBrowserNotifications: value.useBrowserNotifications,
      };

      this.data
        .collection('UserSettings')
        .entity(this.app.session.user.id)
        .patch(data)
        .subscribe({
          next: () => {
            this.notification.successLocal('shared.messages.saved');
            this.form.markAsPristine();
            location.reload();
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.service.savingState$.next(false);
          },
        });
    } else {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
    }
  }
}
