<label class="group"> {{ 'settings.permissionSets.granules.groupData' | translate }}</label>
<table class="table table-bordered compact-table" style="table-layout: fixed; width: 750px">
  <thead>
    <tr>
      <th style="width: 250px; vertical-align: middle" rowspan="2">
        {{ 'settings.permissionSets.granules.granular' | translate }}
      </th>
    </tr>
    <tr>
      <th style="text-align: center; vertical-align: middle">
        {{ 'settings.permissionSets.permissions.view' | translate }}
      </th>
      <th style="text-align: center; vertical-align: middle">
        {{ 'settings.permissionSets.permissions.edit' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ 'settings.permissionSets.granules.accountingEntries' | translate }}</td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('AccountingEntry.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td style="text-align: center"></td>
    </tr>

    <tr>
      <td class="ps-4">{{ 'enums.accountingEntryMode.Manual' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('AccountingEntry.AllM.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.accountingPeriod' | translate }}</td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('AccountingPeriod.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('AccountingPeriod.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.timeSheets' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeSheet.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.expenseRequests' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ExpenseRequest.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ExpenseRequest.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.actOfAcceptance' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ActOfAcceptance.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ActOfAcceptance.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
  </tbody>
</table>

<!-- <h4>{{ 'settings.permissionSets.granules.groupActions' | translate }}</h4>
<table class="table table-bordered compact-table" style="table-layout: fixed; width: 500px">
  <thead>
    <tr>
      <th style="width: 250px; vertical-align: middle">
        {{ 'settings.permissionSets.granules.granular' | translate }}
      </th>
      <th style="width: 250px; text-align: center">
        {{ 'settings.permissionSets.scopes.allProjects' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>

  </tbody>
</table> -->
