@if (!control.value && isShow) {
  <ngx-dropzone
    #drop
    [multiple]="false"
    [maxFileSize]="maxAttachmentSize"
    (change)="onFileAdded($event)"
  >
    <ngx-dropzone-label>
      <i
        class="bi bi-cloud-upload"
        aria-hidden="true"
        title="{{ 'expenses.lineUploadAttachmentHint' | translate }}"
      ></i>
    </ngx-dropzone-label>
  </ngx-dropzone>
}

@if (control.value) {
  <div>
    <i
      class="bi bi-file-earmark"
      aria-hidden="true"
      (click)="openAttachment()"
      title="{{ control.value.fileName }}"
    ></i>
  </div>
}
