<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-7">
      <div class="form-group">
        <label>
          {{ 'billing.invoices.list.filter.props.period.label' | translate }}
        </label>
        <wp-date-period-box
          formControlName="period"
          placeholder="{{ 'billing.invoices.list.filter.props.period.ph' | translate }}"
        ></wp-date-period-box>
      </div>

      <div class="form-group">
        <label>
          {{ 'billing.invoices.list.filter.props.client.label' | translate }}
        </label>
        <wp-select-box
          formControlName="client"
          collection="Organizations"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'billing.invoices.list.filter.props.client.ph' | translate }}"
        ></wp-select-box>
      </div>
      <div class="form-group">
        <label>
          {{ 'billing.invoices.list.filter.props.project.label' | translate }}
        </label>
        <wp-select-box
          formControlName="project"
          collection="Projects"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'billing.invoices.list.filter.props.project.ph' | translate }}"
        ></wp-select-box>
      </div>
    </div>
    <div class="col-5">
      <tmt-state-select formControlName="states" collection="Invoices" />
    </div>
  </div>
</form>
