import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

/** Форматирование валюты. */
@Pipe({
  name: 'zeroToNull',
})
export class ZeroToNullPipe implements PipeTransform {
  transform(value?: any): number {
    return value === 0 ? null : value;
  }
}
