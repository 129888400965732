import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { GridCellHostDirective } from './grid-cell-host.directive';
import { DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { WorkPipe } from 'src/app/shared/pipes/work.pipe';
import { isEqual, isObject } from 'lodash';
import { AppService } from 'src/app/core/app.service';
import {
  GridColumn,
  GridColumnType,
  GridCommandColumn,
  GridComponentColumn,
  GridCurrencyColumn,
  GridCurrencyControlColumn,
  GridDateControlColumn,
  GridEntityColumn,
  GridNavigationColumn,
  GridNumberControlColumn,
  GridSelectControlColumn,
  GridStringControlColumn,
  GridUserControlColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { DateBoxWrapperComponent } from './wrappers/date-box-wrapper/date-box-wrapper.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectBoxWrapperComponent } from './wrappers/date-box-wrapper/select-box-wrapper.component';
import { NumberBoxWrapperComponent } from './wrappers/date-box-wrapper/number-box-wrapper.component';
import { UserBoxWrapperComponent } from './wrappers/date-box-wrapper/user-box-wrapper.component';
import { UserCellComponent } from './user-cell/user-cell.component';
import { TextBoxWrapperComponent } from './wrappers/date-box-wrapper/text-box-wrapper.component';
import { NavigationCellComponent } from './navigation-cell copy/navigation-cell.component';
import { BooleanControlCellComponent } from './boolean-control-cell/boolean-control-cell.component';
import { BooleanCellComponent } from './boolean-cell/boolean-cell.component';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { StateCellComponent } from './state-cell/state-cell.component';
import { CommandCellComponent } from './command-cell/command-cell.component';
import { CurrencyBoxWrapperComponent } from './wrappers/date-box-wrapper/currency-box-wrapper.component';
import objectPath from 'object-path';
import { Subject, isObservable } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { GridCellsOrchestratorService } from 'src/app/shared/components/features/grid/core/grid-cells-orchestrator.service';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { NgxNl2brPipe } from 'ngx-nl2br';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { ResourceNavigationCellComponent } from 'src/app/shared-features/grid2/grid-cell/resource-navigation-cell/resource-navigation-cell.component';

/** Ячейка грида. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wp-grid-cell]',
  template: '<ng-template wp-grid-cell-host></ng-template>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GridCellHostDirective, { static: true })
  host: GridCellHostDirective;
  @Input() column: GridColumn;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('group') formGroup: UntypedFormGroup;

  get row(): any {
    return this.formGroup.value;
  }

  get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  private _editing = false;

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  /** Last value of cell control. Turned on with the force cell update option. */
  private lastValue: any = null;
  /** Debounce time for checking cell abstract control changes. */
  readonly checkChangesDebounceTime = 10;

  /** Режим редактирования ячейки. */
  @Input() get editing(): boolean {
    return this._editing;
  }
  set editing(value: boolean) {
    if (this._editing !== value) {
      this.renderer.removeClass(this.elRef.nativeElement, 'editing-cell');
      this._editing = value;
      if (this.viewContainerRef && this.canCellBeEditable()) {
        this.render();
        // Set focus to cell parent row for save table focus after cell rerendering.
        this.viewContainerRef.element.nativeElement.parentElement.parentElement.focus();
      }
    }
  }

  get value(): any {
    return this.formGroup.get(this.column.name).value;
  }
  componentRef: any;
  textElement: any;

  viewContainerRef: ViewContainerRef;

  constructor(
    private app: AppService,
    private renderer: Renderer2,
    private currencyPipe: WpCurrencyPipe,
    private numberPipe: DecimalPipe,
    private percentPipe: PercentPipe,
    private workPipe: WorkPipe,
    private datePipe: DatePipe,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private nl2br: NgxNl2brPipe,
    private cellOrchestratorService: GridCellsOrchestratorService,
    private zone: NgZone,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
    if (this.viewContainerRef) {
      this.viewContainerRef.remove();
      this.viewContainerRef = null;
    }
  }

  ngOnInit() {
    this.viewContainerRef = this.host.hostContainer;
    if (
      this.cellOrchestratorService.dblClickEdit &&
      !this.canCellBeEditable()
    ) {
      this.formGroup.controls[this.column.name].disable({ emitEvent: false });
    }
    this.render();

    if (this.column.forceCellUpdating) {
      this.addForceCellUpdating();
    }
  }

  public ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.renderer.listen(
        this.host.hostElement.nativeElement.parentElement,
        'mouseenter',
        (event) => {
          this.cellOrchestratorService.onCellMouseEnter(
            this.formGroup,
            event,
            this.column,
          );
        },
      );
    });
  }

  /** Determines can cell be editable. */
  private canCellBeEditable(): boolean {
    // New logic means that component type cell must regulate control enabling state by itself
    if (
      this.column.type === GridColumnType.Component &&
      this.cellOrchestratorService.dblClickEdit
    ) {
      return true;
    }

    // Deprecated logic for component type cell
    if (
      this.column.type === GridColumnType.Component &&
      !(this.column as GridComponentColumn).readonlyComponent
    ) {
      return false;
    }
    //Not control type cells always are not editable
    if (
      [
        GridColumnType.Boolean,
        GridColumnType.Currency,
        GridColumnType.Date,
        GridColumnType.Decimal,
        GridColumnType.Integer,
        GridColumnType.Work,
        GridColumnType.Percent,
        GridColumnType.Label,
        GridColumnType.Navigation,
        GridColumnType.Entity,
        GridColumnType.String,
        GridColumnType.User,
        GridColumnType.State,
        GridColumnType.ResourceNavigation,
        GridColumnType.Command,
        GridColumnType.DateTime,
        GridColumnType.MultilineString,
      ].includes(this.column.type)
    ) {
      return false;
    }

    return true;
  }

  /** Определяет является ли колонка контролом. */
  private isControlType(): boolean {
    return [
      GridColumnType.DateControl,
      GridColumnType.UserControl,
      GridColumnType.SelectControl,
      GridColumnType.StringControl,
      GridColumnType.NumberControl,
      GridColumnType.CurrencyControl,
      GridColumnType.BooleanControl,
    ].includes(this.column.type);
  }

  private render() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
      this.renderer.removeClass(this.elRef.nativeElement, 'control-cell');
    }

    if (this.textElement) {
      this.renderer.removeChild(this.elRef.nativeElement, this.textElement);
      this.renderer.removeClass(this.elRef.nativeElement, 'trim');
      this.renderer.removeAttribute(this.elRef.nativeElement, 'title');
    }

    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }

    if (this.editing) {
      // Снять ошибку состояния для колонки-контрола, если переходим в режим редактирования.
      if (this.isControlType()) {
        this.renderer.removeClass(this.elRef.nativeElement, 'invalid');
      }

      if (this.cellOrchestratorService.dblClickEdit) {
        if (this.control.enabled && this.canCellBeEditable()) {
          this.renderer.addClass(this.elRef.nativeElement, 'editing-cell');
        }
      }

      switch (this.column.type) {
        case GridColumnType.Component:
          this.renderComponent();
          return;
        case GridColumnType.DateControl:
          this.renderDateControl();
          return;
        case GridColumnType.SelectControl:
          this.renderSelectControl();
          return;
        case GridColumnType.UserControl:
          this.renderUserControl();
          return;
        case GridColumnType.NumberControl:
          this.renderNumberControl();
          return;
        case GridColumnType.CurrencyControl:
          this.renderCurrencyControl();
          return;
        case GridColumnType.StringControl:
          this.renderStringControl();
          return;
        case GridColumnType.BooleanControl:
          this.renderBooleanControl();
          return;
      }
    }

    if (this.column.type === GridColumnType.User) {
      this.renderUser();
      return;
    }

    this.renderPlainValues();
  }

  private renderPlainValues() {
    const renderText = (text: string): void => {
      this.textElement = this.renderer.createText(text);
      this.renderer.appendChild(this.elRef.nativeElement, this.textElement);
      this.renderer.addClass(this.elRef.nativeElement, 'trim');
      this.renderer.setAttribute(this.elRef.nativeElement, 'title', text);
    };

    let text: string = null;

    // Установить или снять состояние ошибки (пока проверка только на обязательность).
    if (
      this.isControlType() &&
      (this.control.validator && (this.control.validator(this.control) as any))
        ?.required &&
      !text
    ) {
      this.renderer.addClass(this.elRef.nativeElement, 'invalid');
    } else {
      this.renderer.removeClass(this.elRef.nativeElement, 'invalid');
    }

    if (this.column.type === GridColumnType.Component) {
      this.renderComponent(true);
      return;
    }

    if (this.column.type === GridColumnType.ResourceNavigation) {
      this.renderResourceNavigation();
      return;
    }

    if (this.column.type === GridColumnType.UserControl) {
      this.renderUser();
      return;
    }

    if (this.column.type === GridColumnType.Navigation) {
      this.renderNavigation();
      return;
    }

    if (this.column.type === GridColumnType.Command) {
      this.renderCommand();
      return;
    }

    if (this.column.type === GridColumnType.State) {
      this.renderState();
      return;
    }

    if (
      this.column.type === GridColumnType.Boolean ||
      this.column.type === GridColumnType.BooleanControl
    ) {
      this.renderBoolean();
      return;
    }

    if (
      this.column.type === GridColumnType.String ||
      this.column.type === GridColumnType.StringControl
    ) {
      text = this.value;
    }

    if (this.column.type === GridColumnType.MultilineString) {
      this.renderMultilineString();
      return;
    }

    if (this.column.type === GridColumnType.Entity) {
      if (typeof this.value === 'string') {
        const values = (this.column as GridEntityColumn).values;
        if (isObservable(values)) {
          values.pipe(take(1)).subscribe((val: NamedEntity[]) => {
            text = val.find((v) => v.id === this.value).name;
            renderText(text);
          });
        } else {
          text = values.find((v) => v.id === this.value).name;
        }
      } else {
        text = this.value?.name;
      }
    }

    if (this.column.type === GridColumnType.SelectControl) {
      text = this.value ? this.value.name : '';
    }

    if (
      this.column.type === GridColumnType.Date ||
      this.column.type === GridColumnType.DateControl
    ) {
      text = this.datePipe.transform(this.value, 'shortDate');
    }

    if (this.column.type === GridColumnType.DateTime) {
      text = this.datePipe.transform(this.value, 'short');
    }

    if (
      (this.column.type === GridColumnType.Decimal ||
        (this.column.type === GridColumnType.NumberControl &&
          (this.column as GridNumberControlColumn).controlType ===
            'percent')) &&
      this.column.contentType === GridColumnType.Percent
    ) {
      const column = this.column as GridNumberControlColumn;
      const precision = column.precision ?? 2;
      text = this.percentPipe.transform(this.value, `1.0-${precision}`);
      this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    } else if (
      this.column.type === GridColumnType.Decimal ||
      (this.column.type === GridColumnType.NumberControl &&
        (this.column as GridNumberControlColumn).controlType === 'decimal')
    ) {
      const column = this.column as GridNumberControlColumn;
      const precision = column.precision ?? 2;
      text = this.numberPipe.transform(this.value, `1.0-${precision}`);
      this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    }

    if (
      this.column.type === GridColumnType.Integer ||
      (this.column.type === GridColumnType.NumberControl &&
        (this.column as GridNumberControlColumn).controlType === 'integer')
    ) {
      text = this.numberPipe.transform(this.value, '1.0-0');
      this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    }

    if (
      this.column.type === GridColumnType.Work ||
      (this.column.type === GridColumnType.NumberControl &&
        (this.column as GridNumberControlColumn).controlType === 'work')
    ) {
      text = this.workPipe.transform(this.value);
      this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    }

    if (this.column.type === GridColumnType.Percent) {
      text = this.percentPipe.transform(this.value);
      this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    }

    if (
      this.column.type === GridColumnType.Currency ||
      this.column.type === GridColumnType.CurrencyControl ||
      (this.column.type === GridColumnType.NumberControl &&
        (this.column as GridNumberControlColumn).controlType === 'currency')
    ) {
      if (isObject(this.value)) {
        text = this.currencyPipe.transform(
          this.value['value'],
          this.value['currencyCode'],
        );
      } else {
        const column = this.column as GridCurrencyColumn;
        const currencyCode =
          (column.currencyCodeProperty &&
            objectPath.get(this.row, column.currencyCodeProperty)) ||
          column.currencyCode ||
          this.app.session.configuration.baseCurrencyCode;
        text = this.currencyPipe.transform(this.value, currencyCode);
      }
      this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    }

    if (text) {
      renderText(text);
    }
  }

  private renderNumberControl() {
    this.componentRef = this.viewContainerRef.createComponent(
      NumberBoxWrapperComponent,
    );
    const instance = this.componentRef.instance as NumberBoxWrapperComponent;

    const column = this.column as GridNumberControlColumn;

    instance.control = this.control;
    instance.type = column.controlType;
    instance.min = column.min;
    instance.max = column.max;
    instance.precision = column.precision ?? 2;
    instance.allowNull = column.allowNull;
    instance.propagationMode =
      column.propagationMode ?? PropagationMode.onInput;
    if (this.cellOrchestratorService.dblClickEdit) {
      instance.autofocus = true;
      instance.initialValue =
        this.cellOrchestratorService.getInitialControlValue();
    }
    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  private renderCurrencyControl() {
    this.componentRef = this.viewContainerRef.createComponent(
      CurrencyBoxWrapperComponent,
    );
    const instance = this.componentRef.instance as CurrencyBoxWrapperComponent;

    const column = this.column as GridCurrencyControlColumn;

    const currencyCode =
      (column.currencyCodeProperty &&
        objectPath.get(this.row, column.currencyCodeProperty)) ||
      column.currencyCode ||
      this.app.session.configuration.baseCurrencyCode;

    instance.control = this.control;
    instance.min = column.min;
    instance.max = column.max;
    instance.isCurrencyEditable = column.isCurrencyEditable;
    instance.currencyCode = currencyCode;
    instance.allowNull = column.allowNull;
    if (this.cellOrchestratorService.dblClickEdit) {
      instance.autofocus = true;
      instance.initialValue =
        this.cellOrchestratorService.getInitialControlValue();
    }
    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  private renderSelectControl() {
    this.componentRef = this.viewContainerRef.createComponent(
      SelectBoxWrapperComponent,
    );
    const instance = this.componentRef.instance as SelectBoxWrapperComponent;
    const column = this.column as GridSelectControlColumn;
    instance.control = this.control;
    instance.values = column.values;
    instance.collection = column.collection;
    instance.query = column.query;
    instance.placeholder = column.placeholder;
    instance.allowNull = column.allowNull;

    if (this.cellOrchestratorService.dblClickEdit) {
      instance.autofocus = true;
      instance.initialValue =
        this.cellOrchestratorService.getInitialControlValue();
    }
    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  private renderStringControl() {
    this.componentRef = this.viewContainerRef.createComponent(
      TextBoxWrapperComponent,
    );
    const instance = this.componentRef.instance as TextBoxWrapperComponent;
    const column = this.column as GridStringControlColumn;
    instance.control = this.control;
    instance.placeholder = column.placeholder;
    instance.propagationMode =
      column.propagationMode ?? PropagationMode.onInput;
    if (this.cellOrchestratorService.dblClickEdit) {
      instance.autofocus = true;
      instance.initialValue =
        this.cellOrchestratorService.getInitialControlValue();
    }
    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  private renderBooleanControl() {
    this.componentRef = this.viewContainerRef.createComponent(
      BooleanControlCellComponent,
    );
    const instance = this.componentRef.instance as BooleanControlCellComponent;
    instance.control = this.control;
    if (this.cellOrchestratorService.dblClickEdit) {
      instance.autofocus = true;
      this.cdr.detectChanges();
      instance.setUserValue(
        this.cellOrchestratorService.getInitialControlValue(),
      );
    }
    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  private renderUserControl() {
    this.componentRef = this.viewContainerRef.createComponent(
      UserBoxWrapperComponent,
    );
    const instance = this.componentRef.instance as UserBoxWrapperComponent;
    const column = this.column as GridUserControlColumn;
    instance.control = this.control;
    instance.placeholder = column.placeholder;
    instance.query = column.query;
    if (this.cellOrchestratorService.dblClickEdit) {
      instance.initialValue =
        this.cellOrchestratorService.getInitialControlValue();
      if (instance.initialValue === undefined) {
        instance.autofocus = true;
      }
    }
    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  private renderDateControl() {
    this.componentRef = this.viewContainerRef.createComponent(
      DateBoxWrapperComponent,
    );
    const instance = this.componentRef.instance as DateBoxWrapperComponent;
    const column = this.column as GridDateControlColumn;
    instance.control = this.control;
    instance.allowNull = column.allowNull;
    if (this.cellOrchestratorService.dblClickEdit) {
      instance.autofocus = true;
      instance.initialValue =
        this.cellOrchestratorService.getInitialControlValue();
    }
    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  private renderComponent(readonlyVersion?: boolean) {
    const gridViewComponentColumn = this.column as GridComponentColumn;
    const component =
      readonlyVersion && gridViewComponentColumn.readonlyComponent
        ? gridViewComponentColumn.readonlyComponent
        : gridViewComponentColumn.component;

    this.componentRef = this.viewContainerRef.createComponent(component);
    const instance = this.componentRef.instance;
    instance.formGroup = this.formGroup;
    instance.column = this.column;

    //TODO: transfer from here to separate interface
    instance.currencyCode = gridViewComponentColumn.currencyCode;

    instance.initialValue =
      this.cellOrchestratorService.getInitialControlValue();

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders resource navigation control. */
  private renderResourceNavigation(): void {
    this.componentRef = this.viewContainerRef.createComponent(
      ResourceNavigationCellComponent,
    );
    const instance = this.componentRef
      .instance as ResourceNavigationCellComponent;
    instance.row = this.row;
    instance.column = this.column as GridNavigationColumn;
    this.renderer.setAttribute(
      this.elRef.nativeElement,
      'title',
      this.row.name,
    );
    this.renderer.addClass(this.elRef.nativeElement, 'trim');
  }

  private renderUser() {
    this.componentRef =
      this.viewContainerRef.createComponent(UserCellComponent);
    const instance = this.componentRef.instance as UserCellComponent;
    instance.row = this.row;
    instance.column = this.column as GridNavigationColumn;
  }

  private renderNavigation() {
    this.componentRef = this.viewContainerRef.createComponent(
      NavigationCellComponent,
    );
    const instance = this.componentRef.instance as NavigationCellComponent;
    instance.row = this.row;
    instance.column = this.column as GridNavigationColumn;
    this.renderer.setAttribute(
      this.elRef.nativeElement,
      'title',
      this.row[this.column.name],
    );
    this.renderer.addClass(this.elRef.nativeElement, 'trim');
  }

  private renderCommand() {
    this.componentRef =
      this.viewContainerRef.createComponent(CommandCellComponent);
    const instance = this.componentRef.instance as CommandCellComponent;
    instance.row = this.row;
    instance.column = this.column as GridCommandColumn;
    this.renderer.setAttribute(
      this.elRef.nativeElement,
      'title',
      this.row[this.column.name],
    );
  }

  private renderState() {
    this.componentRef =
      this.viewContainerRef.createComponent(StateCellComponent);
    const instance = this.componentRef.instance as StateCellComponent;
    instance.value = this.value;
    instance.column = this.column;
  }

  private renderBoolean() {
    this.componentRef =
      this.viewContainerRef.createComponent(BooleanCellComponent);
    const instance = this.componentRef.instance as BooleanCellComponent;
    instance.formGroup = this.formGroup;
    instance.column = this.column;
  }

  /** Enable force cell updating logic. */
  private addForceCellUpdating() {
    this.lastValue = this.formGroup.controls[this.column.name].value;
    this.formGroup.controls[this.column.name].valueChanges
      .pipe(
        debounceTime(this.checkChangesDebounceTime),
        takeUntil(this.destroyed$),
      )
      .subscribe((change) => {
        if (!isEqual(this.lastValue, change)) {
          this.lastValue = change;
          /** No rerender for editing control cells. */
          if (
            (this.cellOrchestratorService.dblClickEdit &&
              this.formGroup.controls[this.column.name] !==
                this.cellOrchestratorService.getEditingControl()) ||
            !this.canCellBeEditable()
          ) {
            this.render();
            this.cdr.detectChanges();
          }
        }
      });
  }

  private renderMultilineString() {
    if (!this.value) {
      return;
    }
    const text = this.nl2br.transform(this.value);
    this.renderer.setProperty(this.elRef.nativeElement, 'innerHTML', text);
    this.renderer.addClass(this.elRef.nativeElement, 'trim');
    this.renderer.setAttribute(this.elRef.nativeElement, 'title', this.value);
  }
}
