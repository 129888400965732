import { DecimalPipe, getCurrencySymbol, PercentPipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { ReplaySubject, Subscription } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { DatePeriodType } from 'src/app/shared/models/enums/date-period-type.enum';
import { KpiKind } from 'src/app/shared/models/enums/kpi-kind.model';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { Exception } from 'src/app/shared/models/exception';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import {
  DurationPipe,
  NullHandlerType,
} from 'src/app/shared/pipes/duration.pipe';
import { WorkPipe } from 'src/app/shared/pipes/work.pipe';
import { KpiCardModel } from '../kpi-card/kpi-card.model';
import { Kpi } from '../shared/kpi.model';
import {
  KpiPeriodType,
  OverviewKpiCurrencyMode,
  OverviewKpiSettings,
} from '../shared/overview-kpi.settings';
import { TranslateService } from '@ngx-translate/core';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { isEqual } from 'lodash';

@Injectable()
export class OverviewKpiService {
  public loading$ = new ReplaySubject<boolean>();

  private defaultSettings = new OverviewKpiSettings().getDefault();
  public projectCurrencyCode: string;
  public settings: OverviewKpiSettings;
  public cards: KpiCardModel[] = [];

  public kpi: Kpi;
  public entityCollection: 'Projects' | 'Programs' | 'Organizations';
  public projectVersion: ProjectVersion;
  public financeViewAllowed: boolean;
  public nonBillable: boolean;

  private loadingSubscription: Subscription;

  public get currencyCode(): string {
    return this.settings.currency === OverviewKpiCurrencyMode.base
      ? null
      : this.projectCurrencyCode;
  }

  constructor(
    @Inject('entityId') public entityId,
    private data: DataService,
    private workPipe: WorkPipe,
    private translate: TranslateService,
    private percentPipe: PercentPipe,
    private decimalPipe: DecimalPipe,
    private durationPipe: DurationPipe,
    private currencyPipe: WpCurrencyPipe,
    private notification: NotificationService,
  ) {
    this.settings = { ...this.defaultSettings } as OverviewKpiSettings;

    // Для обновления типа периода.
    this.setPeriodByCurrentType();
  }

  public reload() {
    this.buildCards();

    this.loading$.next(true);

    this.loadingSubscription?.unsubscribe();

    let period: DatePeriod = null;

    switch (this.settings.periodType) {
      case KpiPeriodType.onTheCurrentDate:
        period = {
          from: '1900-01-01',
          to: DateTime.now().toISODate(),
          periodType: DatePeriodType.Custom,
        };
        break;
      case KpiPeriodType.onTheEndOfProject:
        period = null;
        break;
      case KpiPeriodType.onTheDate:
        period = {
          from: '1900-01-01',
          to: this.settings.date ?? '2100-12-31',
          periodType: DatePeriodType.Custom,
        };
        break;
      case KpiPeriodType.forThePeriod:
        period = this.settings.period;
        break;
    }

    const urlParams = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@request': JSON.stringify({
        period,
        filterByTaskId: this.settings.task?.id ?? null,
        projectVersionId: this.projectVersion?.id ?? null,
        showInBaseCurrency:
          this.settings.currency === OverviewKpiCurrencyMode.base,
      }),
    };

    const params: Dictionary<any> = {
      request: '@request',
    };

    this.loadingSubscription = this.data
      .collection(this.entityCollection)
      .entity(this.entityId)
      .function('GetKpi')
      .get(params, null, urlParams)
      .subscribe({
        next: (kpi: Kpi) => {
          this.kpi = kpi;
          this.buildCards();
          this.loading$.next(false);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.loading$.next(false);
        },
      });
  }

  public setPeriodByCurrentType(): boolean {
    let newPeriodType: KpiPeriodType;
    switch (this.settings.type) {
      case KpiType.Actual:
        newPeriodType = KpiPeriodType.onTheCurrentDate;
        break;
      case KpiType.Forecast:
        newPeriodType = KpiPeriodType.onTheEndOfProject;
        break;
      case KpiType.Estimate:
        newPeriodType = KpiPeriodType.onTheEndOfProject;
        break;
      case KpiType.Plan:
        newPeriodType = KpiPeriodType.onTheEndOfProject;
        break;
    }

    if (this.settings.periodType !== newPeriodType) {
      this.settings.periodType = newPeriodType;
      return true;
    }

    return false;
  }

  public setType(type: KpiType) {
    this.settings.type = type;

    if (this.setPeriodByCurrentType()) {
      this.reload();
    }

    this.buildCards();
  }

  public setPeriodType(type: KpiPeriodType) {
    this.settings.periodType = type;
    this.reload();
  }

  public buildCards() {
    if (!this.kpi) {
      this.kpi = {};
    }

    this.cards = [];

    this.addCard(KpiKind.Hours);

    if (this.financeViewAllowed) {
      this.addCard(KpiKind.Cost);
      this.addCard(KpiKind.Expenses);

      if (!this.nonBillable) {
        this.addCard(KpiKind.Revenue);
        this.addCard(KpiKind.Profit);
        this.addCard(KpiKind.Profitability);
        this.addCard(KpiKind.Billing);
      }
    }
    if (this.entityCollection === 'Projects') {
      this.addCard(KpiKind.Duration);
    }
  }

  public assertSettingsChanged(): boolean {
    return !isEqual(this.settings, this.defaultSettings);
  }

  private addCard(kind: KpiKind) {
    const header = `projects.overview.kpi.${kind.toLowerCase()}`;

    let mainTypePrefix = '';
    let leftTypePrefix = '';
    let rightTypePrefix = '';

    let leftLabel = '';
    let rightLabel = '';

    switch (this.settings.type) {
      case KpiType.Actual:
        mainTypePrefix = 'actual';
        leftTypePrefix = 'estimated';
        rightTypePrefix = 'planned';
        leftLabel = 'projects.overview.type.estimate';
        rightLabel = 'projects.overview.type.plan';
        break;
      case KpiType.Plan:
        mainTypePrefix = 'planned';
        leftTypePrefix = 'estimated';
        rightTypePrefix = 'actual';
        leftLabel = 'projects.overview.type.estimate';
        rightLabel = 'projects.overview.type.actual';
        break;
      case KpiType.Estimate:
        mainTypePrefix = 'estimated';
        leftTypePrefix = 'actual';
        rightTypePrefix = 'planned';
        leftLabel = 'projects.overview.type.actual';
        rightLabel = 'projects.overview.type.plan';
        break;
      case KpiType.Forecast:
        mainTypePrefix = 'forecasted';
        leftTypePrefix = 'actual';
        rightTypePrefix = 'planned';
        leftLabel = 'projects.overview.type.actual';
        rightLabel = 'projects.overview.type.plan';
        break;
    }

    const value = this.kpi[`${mainTypePrefix}${kind}`];
    const leftValue = this.kpi[`${leftTypePrefix}${kind}`];
    const rightValue = this.kpi[`${rightTypePrefix}${kind}`];

    let formattedValue = '';
    let unit = '';

    let formattedLeftValue = '';
    let formattedRightValue = '';

    switch (kind) {
      case KpiKind.Hours:
        formattedValue = this.decimalPipe.transform(value, '0.0-0');
        formattedLeftValue = this.workPipe.transform(leftValue);
        formattedRightValue = this.workPipe.transform(rightValue);
        unit = this.translate.instant('shared.unitHour');
        break;

      case KpiKind.Profitability:
        formattedValue = this.decimalPipe.transform(value * 100, '0.0-0');
        formattedLeftValue = this.percentPipe.transform(leftValue);
        formattedRightValue = this.percentPipe.transform(rightValue);
        unit = '%';
        break;

      case KpiKind.Duration:
        formattedValue = this.decimalPipe.transform(value, '0.0-0') ?? '—';
        formattedLeftValue = this.durationPipe.transform(
          leftValue,
          NullHandlerType.dash,
        );
        formattedRightValue = this.durationPipe.transform(
          rightValue,
          NullHandlerType.dash,
        );
        unit = this.translate.instant('shared.unitDay');
        break;

      default:
        formattedValue = this.decimalPipe.transform(value, '0.0-0');
        formattedLeftValue = this.currencyPipe.transform(
          leftValue,
          this.currencyCode,
          '0.0-0',
        );
        formattedRightValue = this.currencyPipe.transform(
          rightValue,
          this.currencyCode,
          '0.0-0',
        );
        unit = getCurrencySymbol(this.currencyCode, 'narrow');
        break;
    }

    const card = {
      header,
      value: formattedValue,
      unit,
      leftDetail: {
        label: leftLabel,
        value: formattedLeftValue,
      },
      rightDetail: {
        label: rightLabel,
        value: formattedRightValue,
      },
    } as KpiCardModel;

    if (
      this.settings.type === KpiType.Actual ||
      this.settings.type === KpiType.Estimate ||
      this.settings.type === KpiType.Forecast
    ) {
      switch (kind) {
        case KpiKind.Profit:
          card.attention = value < rightValue;
          break;
        case KpiKind.Profitability:
          card.attention = value < rightValue;
          break;
        case KpiKind.Revenue:
          card.attention = value < rightValue;
          break;
        default:
          card.attention = value > rightValue;
          break;
      }
    }

    this.cards.push(card);
  }
}
