import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewRef,
  inject,
} from '@angular/core';
import { GridComponentCell } from 'src/app/shared-features/grid2/models/grid-component-cell.interface';
import {
  GridColumn,
  GridColumnType,
} from 'src/app/shared/models/inner/grid-column.interface';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { WorkPipe } from 'src/app/shared/pipes/work.pipe';
import { AppService } from 'src/app/core/app.service';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { DecimalPipe, PercentPipe } from '@angular/common';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-task-collapsible-readonly-cell',
  template: `<div class="trim" title="{{ getValue() }}">{{ getValue() }}</div>`,
  styleUrls: ['./task-collapsible-readonly-cell.component.scss'],
})
export class TaskCollapsibleReadonlyCellComponent
  implements GridComponentCell, OnInit
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  @Input() currencyCode: string;

  private destroyRef = inject(DestroyRef);

  public get task(): ProjectTask {
    return this.formGroup.value;
  }

  public get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  constructor(
    private app: AppService,
    public service: GridService,
    public ref: ChangeDetectorRef,
    private currencyPipe: WpCurrencyPipe,
    private workPipe: WorkPipe,
    private decimalPipe: DecimalPipe,
    private percentPipe: PercentPipe,
    private projectTasksDataService: ProjectTasksDataService,
    public projectCardService: ProjectCardService,
    private renderer: Renderer2,
    private elRef: ElementRef,
  ) {}

  public ngOnInit(): void {
    if (
      !this.projectTasksDataService.summaryEditableFields.includes(
        this.column.name,
      )
    ) {
      this.control.disable({ emitEvent: false });
    }

    this.updateStyles();

    this.service.detectChanges$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.updateStyles();
          this.ref.detectChanges();
        }
      });
    this.formGroup.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
  }

  /**
   * Retrieves the formatted value based on the column type.
   *
   * @returns {string} The formatted value as a string.
   */
  public getValue(): string {
    const value: number = this.task.isExpanded
      ? this.formGroup.getRawValue()[this.column.name]
      : this.formGroup.getRawValue()[`${this.column.name}Sum`];

    switch (this.column.contentType) {
      case GridColumnType.Currency:
        return this.currencyPipe.transform(
          value,
          this.projectCardService.project.currency.alpha3Code ??
            this.app.session.configuration.baseCurrencyCode,
        );
      case GridColumnType.Work:
        return this.workPipe.transform(value);
      case GridColumnType.Decimal:
        return this.decimalPipe.transform(value, '1.2-2');
      case GridColumnType.Integer:
        return this.decimalPipe.transform(value, '1.0');
      case GridColumnType.Percent:
        return value ? this.percentPipe.transform(value, '1.0-2') : '';
    }
  }

  /** Update custom css styles. */
  private updateStyles(): void {
    if (this.control.disabled) {
      this.renderer.addClass(this.elRef.nativeElement.firstChild, 'disabled');
    } else {
      this.renderer.removeClass(
        this.elRef.nativeElement.firstChild,
        'disabled',
      );
    }
  }
}
