<tmt-form-header [name]="form.controls.name.value"></tmt-form-header>
<wp-loading-indicator [loading]="service.cardIsLoading$ | async"></wp-loading-indicator>
<form class="form mt-3" [hidden]="service.cardIsLoading$ | async" novalidate [formGroup]="form">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-xl-5">
        <div class="form-group">
          <label>{{ 'shared.props.name' | translate }}</label>
          <wp-text-box
            formControlName="name"
            placeholder="{{ 'settings.schedules.card.props.name.ph' | translate }}"
          >
          </wp-text-box>
        </div>

        <div class="form-group">
          <label>{{ 'settings.schedules.card.props.daysCount' | translate }}</label>

          <wp-number-box
            min="1"
            [allowNull]="false"
            max="60"
            type="integer"
            formControlName="daysCount"
          >
          </wp-number-box>
          <p class="text-body-secondary">
            {{ 'settings.schedules.card.props.daysCountHelp' | translate }}
          </p>

          <button
            type="button"
            class="btn btn-default"
            [hidden]="schedule.daysCount === 7"
            *ngIf="!readonly"
            (click)="setToWeek()"
          >
            {{ 'settings.schedules.card.actions.weekSchedule' | translate }}
          </button>
        </div>

        <div class="form-group" *ngIf="schedule.daysCount !== 7">
          <label>{{ 'settings.schedules.card.props.firstDay' | translate }}</label>
          <wp-date-box formControlName="firstDay"></wp-date-box>
        </div>

        <div class="form-group" style="width: 250px">
          <label>{{ 'settings.schedules.card.props.exceptions' | translate }}</label>
          <wp-select-box
            [collection]="'ScheduleExceptions'"
            formControlName="scheduleException"
            [placeholder]="'settings.schedules.card.props.exceptions' | translate"
          ></wp-select-box>
        </div>

        <div class="form-group">
          <label>{{ 'shared.props.description' | translate }}</label>
          <wp-multiline-text-box
            placeholder="{{ 'shared.props.description' | translate }}"
            formControlName="description"
            rows="3"
          ></wp-multiline-text-box>
        </div>

        <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
          <input
            formControlName="isActive"
            type="checkbox"
            class="form-check-input"
            id="isActive"
          />
          <label class="form-check-label" for="isActive">
            {{ 'shared.props.isActive.label' | translate }}
          </label>
        </div>

        <div class="form-group" style="padding-top: 10px">
          <p *ngIf="schedule.isDefault" class="form-control-static fw-semibold">
            <i aria-hidden="true" class="bi bi-check-circle"></i>&nbsp;{{
              'settings.schedules.card.props.usingByDefault' | translate
            }}
          </p>
        </div>
      </div>

      <div class="col-6 col-xl-5">
        <table class="wp-nested-table" style="width: 400px; table-layout: fixed">
          <thead>
            <tr>
              <th style="width: 50%">
                {{ 'settings.schedules.card.columns.day' | translate }}
              </th>
              <th
                style="width: 50%"
                title="{{ 'settings.schedules.card.columns.duration.hint' | translate }}"
              >
                {{ 'settings.schedules.card.columns.duration.header' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let day of patternDays.controls; let i = index" formArrayName="patternDays">
              <td class="text-cell">
                {{ getDayTitle(i) }}
              </td>
              <td class="control-cell" [formGroup]="day">
                <wp-number-box formControlName="dayLength" [min]="0" [max]="24" [allowNull]="false">
                </wp-number-box>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="actions">
      <button
        type="button"
        *ngIf="!readonly"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="isSaving"
        (click)="save()"
      >
        {{ 'settings.schedules.card.actions.save' | translate }}
      </button>
    </div>
  </div>
</form>
