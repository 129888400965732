import { List, LoadingStrategy } from '../models/inner/list';
import {
  GridColumnType,
  GridNavigationColumn,
} from '../models/inner/grid-column.interface';
import { TotalType } from '../models/inner/total-type';

export const WORKFLOW_LIST: List = {
  version: 4,
  name: 'workflows',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'lifecycleName',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      state: 'settings.workflow',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      availableTotals: [TotalType.Count],
    },
    {
      name: 'lifecycleName',
      header: 'settings.workflows.list.columns.lifecycleName',
      hint: 'settings.workflows.list.columns.lifecycleName',
      type: GridColumnType.String,
    },
    {
      name: 'isActive',
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.Boolean,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'lifecycleName',
      field: 'lifecycle.name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.Always,
    },
  ],

  dataCollection: 'WorkflowDeclarations',
};
