@if ((boardCardService.state$ | async) === 'Ready') {
  <div class="row mt-4">
    <div class="col-12">
      <label class="group mb-0">
        {{ 'components.boards.groups.permissions' | translate }}
      </label>
    </div>
  </div>
  <wp-performers
      class="p-0"
      [performerLines]="permissions"
      [readonly]="readonly"
      [performerTypes]="performerTypes"
      [performerTypeName]="performerTypeName"
  />
}
