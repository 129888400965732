import { EnumEntity } from '../../enums/enum-entity';
import { NamedEntity } from '../named-entity.model';

export interface Import extends NamedEntity {
  state: ImportState;
}

export class ImportState extends EnumEntity {
  class: string;

  public static get importing(): ImportState {
    return {
      id: 'b12908fe-2e9a-4730-8e04-18155eac5cf8',
      code: 'Importing',
      name: 'enums.importState.Importing',
      class: 'bg-warning',
    };
  }

  public static get imported(): ImportState {
    return {
      id: '979e5d77-2f48-448e-a8d5-4a730a9afdc9',
      code: 'Imported',
      name: 'enums.importState.Imported',
      class: 'bg-success',
    };
  }

  public static get deleting(): ImportState {
    return {
      id: 'c4d996a5-f14c-4a5a-a6c4-32bdb122c0f9',
      code: 'Deleting',
      name: 'enums.importState.Deleting',
      class: 'bg-warning',
    };
  }

  public static get deleted(): ImportState {
    return {
      id: 'f16ceb3b-172e-44dd-8e45-1533328c6b12',
      code: 'Deleted',
      name: 'enums.importState.Deleted',
      class: 'bg-dark',
    };
  }

  public static get error(): ImportState {
    return {
      id: '20910be1-7358-426d-bbd7-a1cd334ebe23',
      code: 'Error',
      name: 'enums.importState.Error',
      class: 'bg-danger',
    };
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ImportStates: ImportState[] = [
  ImportState.error,
  ImportState.imported,
  ImportState.importing,
  ImportState.deleted,
  ImportState.deleting,
];
