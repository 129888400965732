<colgroup>
  <!--Стрелочка-->
  <col style="width: 30px" />

  <!--Имя группы-->
  <col style="width: 100%" />
</colgroup>
<thead>
  <tr>
    <th class="trim" colspan="2" rowspan="2">
      <div class="d-flex justify-content-between align-items-center">
        <span>{{ 'resources.booking.columns.resource' | translate }}</span>
        <button
          *ngIf="!(bookingService.loading$ | async)"
          class="btn px-1 py-1"
          (click)="bookingService.toggleAllResources()"
        >
          <small class="toggle-all text-body-secondary">
            <i
              class="bi"
              [class.bi-chevron-double-right]="bookingService.isAllExpanded"
              [class.bi-chevron-double-down]="!bookingService.isAllExpanded"
            ></i>
            {{
              (bookingService.isAllExpanded
                ? 'shared.actions.collapseAll'
                : 'shared.actions.expandAll'
              ) | translate
            }}
          </small>
        </button>
      </div>
    </th>
  </tr>
</thead>
