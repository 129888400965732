<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h1>Debug information</h1>
      Tenant: {{ app.session.configuration.displayName }},
      {{ app.session.configuration.primaryDomain }}<br />
      <hr />
      <h2>Current session</h2>
      User: {{ app.session.user.name }}, {{ app.session.user.id }}<br />

      <br />
      Roles: {{ roles }}
      <br />
      Session timezone: {{ app.session.timeZone }} <br /><br />
      <button (click)="renewSession()" class="btn btn-default">Reset Session & Tenant Cache</button>
      <br /><br />

      <h2>Client profile</h2>

      <button (click)="resetClientProfile()" class="btn btn-default">Reset Client Profile</button
      ><br />

      <br />
      <h2>Session permissions</h2>

      <table class="table table-bordered" style="width: 600px">
        <tr>
          <th style="width: 250px">Granule</th>
          <th>Access right</th>
        </tr>
        <tr *ngFor="let granule of granules | keyvalue">
          <td>{{ granule.key }}</td>
          <td>
            <p *ngFor="let permission of granule.value">
              <strong *ngIf="permission.scopeName">{{ permission.scopeName }}:&nbsp;</strong>
              <span *ngIf="permission.viewEnabled">Read&nbsp;</span>
              <span *ngIf="permission.editEnabled">Edit&nbsp;</span>
              <span *ngIf="permission.deleteEnabled">Delete&nbsp;</span>
              <span *ngIf="permission.actionEnabled">Execute&nbsp;</span>
            </p>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
