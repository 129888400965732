import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Notification } from '../shared/models/inner/notification.model';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private createdSubject = new Subject<Notification>();
  public created$ = this.createdSubject.asObservable();

  constructor(
    private translate: TranslateService,
    private logger: LogService,
  ) {}

  /**
   * Send warning notification.
   *
   * @param message Notification message.
   *
   * */
  public warning(message: string): void {
    this.logger.info(`Warning: ${message}`);
    this.createdSubject.next({
      type: 'warning',
      text: message,
    });
  }

  /**
   * Send success notification.
   *
   * @param message Notification message.
   *
   * */
  public success(message: string): void {
    this.logger.log(`Success: ${message}`);
    this.createdSubject.next({
      type: 'success',
      text: message,
    });
  }

  /**
   * Send error notification.
   *
   * @param message Notification message.
   *
   * */
  public error(message: string): void {
    this.logger.error(`${message}`);
    this.createdSubject.next({
      type: 'error',
      text: message,
    });
  }

  /**
   * Send warning notification with the translation of the message.
   *
   * @param message Notification message.
   * @param params Params for `translate.instant`.
   *
   * */
  public warningLocal(message: string, params?: object): void {
    this.warning(this.translate.instant(message, params));
  }

  /**
   * Send success notification with the translation of the message.
   *
   * @param message Notification message.
   * @param params Params for `translate.instant`.
   *
   * */
  public successLocal(message: string, params?: object): void {
    this.success(this.translate.instant(message, params));
  }

  /**
   * Send error notification with the translation of the message.
   *
   * @param message Notification message.
   * @param params Params for `translate.instant`.
   *
   * */
  public errorLocal(message: string): void {
    this.error(this.translate.instant(message));
  }

  /**
   * Send notification with custom params.
   *
   * @param params notification params.
   *
   * */
  public sendMessage(params: Notification): void {
    this.createdSubject.next(params);
  }
}
