@if ((attachmentsManager.commentsAttachments$ | async).length) {
  <div class="d-flex flex-column gap-3 mt-3">
    @for (attachment of attachmentsManager.commentsAttachments$ | async; track attachment.id) {
      <tmt-comments-attachment
        [attachment]="attachment"
        [fileExt]="attachment.fileExt"
        [fileName]="attachment.fileName"
        (onClick$)="
          attachmentsManager.openViewer(attachmentsManager.attachments, attachment.id, true)
        "
      />
    }
  </div>
} @else {
  <p class="text-body-secondary">{{ 'shared.noDisplayData' | translate }}</p>
}
