<div [hidden]="readonly" class="input-group">
  <textarea
    #editor
    rows="{{ rows }}"
    [attr.maxlength]="maxLength"
    class="form-control"
    [attr.placeholder]="placeholder"
    [attr.spellcheck]="spellcheck"
    [formControl]="formControl"
    (focus)="onFocus()"
    (blur)="onBlur()"
  ></textarea>
</div>
<p
  *ngIf="readonly && formControl.value"
  class="form-control-static text-break"
  [innerHTML]="formControl.value | linky | nl2br"
></p>
<p
  *ngIf="readonly && !formControl.value"
  class="form-control-static text-body-secondary text-uppercase"
>
  {{ 'shared.empty' | translate }}
</p>
