import { NgModule } from '@angular/core';
import { ResourceSummaryFilterComponent } from 'src/app/resource-summary/shared/resource-summary-filter/resource-summary-filter.component';
import { CommonModule } from '@angular/common';
import { ResourceSummaryFilterService } from 'src/app/resource-summary/shared/resource-summary-filter/core/resource-summary-filter.service';
import { ResourceSummaryFilterModalComponent } from 'src/app/resource-summary/shared/resource-summary-filter/resource-summary-filter-modal/resource-summary-filter-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    ResourceSummaryFilterComponent,
    ResourceSummaryFilterModalComponent,
  ],
  exports: [ResourceSummaryFilterComponent],
  imports: [CommonModule, SharedModule],
  providers: [ResourceSummaryFilterService],
})
export class ResourceSummaryFilterModule {}
