import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';
import { PlanningMethod } from 'src/app/shared/models/enums/planning-method.enum';
import { BookingType } from 'src/app/shared/models/enums/booking-type.enum';

export class BookingSettings implements BaseSettings {
  public readonly name = 'BookingSettings';
  version: number;
  planningScale: PlanningScale;
  valueMode: ValueMode;

  defaultPlanningMethod?: PlanningMethod;
  defaultBookingType?: BookingType;

  getDefault(): BookingSettings {
    return {
      version: 1,
      defaultPlanningMethod: PlanningMethod.RemainingCapacity,
      defaultBookingType: BookingType.Hard,
      planningScale: PlanningScale.Week,
      valueMode: ValueMode.Hours,
    } as BookingSettings;
  }
}
