<div class="widget" (resized)="onResized()" id="{{ widget.id }}">
  <div class="widget-header trim">
    <h3 class="trim drag" [ngClass]="{ move: !readonly }">{{ widget.name }}</h3>

    @if (!isLoading && !readonly) {
      <div class="buttons-wrap">
        <button
          class="copy"
          (click)="copyToStorage()"
          title="{{ 'shared.actions.copy' | translate }}"
        >
          <i class="bi bi-copy" aria-hidden="true"></i>
        </button>
        <button
          class="edit"
          (click)="openSettings()"
          title="{{ 'shared.actions.edit' | translate }}"
        >
          <i class="bi bi-gear" aria-hidden="true"></i>
        </button>
        <button
          class="delete"
          (click)="removeWidget()"
          title="{{ 'shared.actions.delete' | translate }}"
        >
          <i class="bi bi-trash" aria-hidden="true"></i>
        </button>
      </div>
    }
  </div>
  <div class="widget-content">
    @if (!isEmptyShown && widget.type) {
      @switch (widget.type) {
        @case ('Frame') {
          <wp-widget-frame [widget]="widget" />
        }
        @case ('Table') {
          <wp-widget-table
            [dashboardId]="dashboardId"
            [widget]="widget"
            [period]="period"
            (onChanged)="onChanges($event)"
          />
        }
        @default {
          <wp-widget-chart [dashboardId]="dashboardId" [widget]="widget" [period]="period" />
        }
      }
    }

    @if (isEmptyShown && !readonly) {
      <div class="settings-action">
        <button (click)="openSettings()" class="btn btn-link btn-action text-uppercase">
          {{ 'analytics.dashboards.widget.labels.mustBeConfigured' | translate }}
        </button>
      </div>
    }
  </div>
</div>
