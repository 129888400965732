<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ getHeader() | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <form class="form" [formGroup]="form">
      <div class="form-group w-75">
        <label>{{ 'settings.workflows.card.transitions.name' | translate }}</label>
        <wp-text-box
          formControlName="name"
          placeholder="{{ 'settings.workflows.card.transitions.name' | translate }}"
        ></wp-text-box>
      </div>

      <div class="form-group w-75">
        <wp-transition-conditions
          [actions]="actions"
          [functionId]="functionId"
          [conditions]="conditions"
          [localStrings]="localStrings"
        ></wp-transition-conditions>
      </div>

      <div class="form-group w-75">
        <label>{{ 'settings.workflows.card.function.moveToState' | translate }}</label>
        <wp-select-box
          formControlName="moveToStateId"
          [isIdMode]="true"
          [values]="states$"
          placeholder="{{ 'settings.workflows.card.function.moveToState' | translate }}"
        >
        </wp-select-box>
      </div>

      <div class="form-group w-75">
        <wp-workflow-next-function [functionId]="functionId" [nextFunctionId]="nextFunctionId">
        </wp-workflow-next-function>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      style="width: 75px"
      (click)="ok()"
      wpButtonSpinner
    >
      {{ 'shared.actions.ok' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
