<ngb-carousel
  #carousel
  class="viewer viewer__slide--{{ activeAttachmentTemplate }}"
  [class.pdf-in-frame]="params.pdfIframe"
  [interval]="0"
  [activeId]="params.activeId"
  [showNavigationArrows]="params.attachments.length > 1"
  [showNavigationIndicators]="params.attachments.length > 1"
  (slide)="onSlide($event)"
  (clickOutside)="params.outsideClickCallback()"
  [delayClickOutsideInit]="true"
>
  <ng-container *ngFor="let item of params.attachments">
    <ng-template ngbSlide id="{{ item.id }}">
      <div
        *ngIf="item.dataReadyStatus !== 'fail'; else nodata"
        class="viewer__block viewer__block--{{ item.type }}"
        [class.loading]="item.dataReadyStatus == 'awaiting' || item.dataReadyStatus == 'loading'"
      >
        <div
          #zoom
          *ngIf="item.type === 'image'"
          class="viewer__image-wrapper"
          [imageId]="item.id"
          [title]="'shared.actions.zoomToggle' | translate"
          wpImageZoom
        >
          <img [src]="item.url | safeValue: 'url'" />
        </div>

        <ng-container
          *ngIf="item.type === 'pdf'"
          [ngTemplateOutlet]="pdf"
          [ngTemplateOutletContext]="{ $implicit: item }"
        >
        </ng-container>

        <video *ngIf="item.type === 'video'" controls [src]="item.url | safeValue: 'url'"></video>

        <ng-container *ngIf="item.type === 'other'">
          <i class="bi bi-file-earmark-x"></i>
          <p>{{ 'shared.messages.viewerWrongFormat' | translate }}</p>
        </ng-container>
      </div>
      <div class="carousel-caption">
        <ng-container *ngTemplateOutlet="caption; context: { $implicit: item }"></ng-container>
      </div>
    </ng-template>
  </ng-container>
</ngb-carousel>

<div class="viewer__close">
  <i class="bi bi-x-lg"></i>
  <span>{{ 'shared.actions.close' | translate }}</span>
</div>

<ng-template #caption let-item>
  <div *ngIf="item.data" class="viewer__buttons">
    <i
      *ngIf="typeWithZoom.includes(item.type)"
      class="bi bi-zoom-in"
      (click)="zoomIn()"
      [title]="'shared.actions.zoomIn' | translate"
    ></i>
    <i
      *ngIf="typeWithZoom.includes(item.type)"
      class="bi bi-zoom-out"
      (click)="zoomOut()"
      [title]="'shared.actions.zoomOut' | translate"
    ></i>
    <i
      class="bi bi-download"
      (click)="saveFile(item)"
      [title]="'shared.actions.download' | translate"
    ></i>
    <a
      [href]="item.url | safeValue: 'url'"
      target="_blank"
      [title]="'shared.actions.open' | translate"
    >
      <i class="bi bi-box-arrow-up-right"></i>
    </a>
  </div>
  <h4 class="viewer__file-title">
    {{ item.fileName }}
  </h4>
  <p *ngIf="item.description" class="viewer__file-description">
    {{ item.description }}
  </p>
</ng-template>

<ng-template #nodata>
  <div class="viewer__block viewer__block--other">
    <i class="bi bi-file-earmark-x"></i>
    <p>{{ 'shared.unknownError' | translate }}</p>
  </div>
</ng-template>

<ng-template #pdf let-item>
  <pdf-viewer
    *ngIf="!params.pdfIframe"
    [src]="item.url"
    [render-text]="true"
    [original-size]="true"
    [zoom]="pdfZoom"
    class="w-100 h-100"
  ></pdf-viewer>
  <iframe
    *ngIf="params.pdfIframe && item.dataReadyStatus === 'success'"
    [src]="item.url | safeValue: 'resourceUrl'"
    class="pdf-iframe w-100 h-100"
  >
  </iframe>
</ng-template>
