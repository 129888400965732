<div class="toolbar-container">
  <div class="btn-group" role="group">
    <button class="btn btn-default" (click)="form.disable()">Disable</button>
    <button class="btn btn-default" (click)="form.enable()">Enable</button>
  </div>

  <button class="btn btn-default" (click)="setValue()">Set value</button>
</div>

<hr />

<div [formGroup]="form" style="width: 400px">
  <h2>Predecessor box (Old)</h2>
  @if (taskId && tasks.length) {
    <tmt-predecessor-box
      [taskId]="taskId"
      [tasks]="tasks"
      formControlName="predecessor"
    ></tmt-predecessor-box>

    <p>
      Form value:<br />
      <code>
        {{ form.get('predecessor').value | json }}
      </code>
    </p>
    <p>Form control is valid: {{ form.get('predecessor').valid }}</p>
  } @else {
    <p>Please set value.</p>
  }

  <br />
  <hr />

  <h2>MultiSelect Skills Box</h2>
  <tmt-multiselect-box formControlName="skills" placeholder="placeholder" collection="Skills" />
  <p>
    Form value:<br />
    <code>
      {{ form.get('skills').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('skills').valid }}</p>
  <br />

  <h2>MultiSelect Skills Box (Tokens Theme)</h2>
  <tmt-multiselect-box
    formControlName="skillsTokens"
    placeholder="placeholder"
    collection="Skills"
    [theme]="'tokens-trim'"
  />
  <p>
    Form value:<br />
    <code>
      {{ form.get('skillsTokens').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('skillsTokens').valid }}</p>
  <br />

  <h2>MultiSelect Roles Box (Default Value Mode)</h2>
  <tmt-multiselect-box
    formControlName="rolesDefaultMode"
    placeholder="placeholder"
    collection="Roles"
    [defaultModeProperty]="'isDefault'"
  />
  <p>
    Form value:<br />
    <code>
      {{ form.get('rolesDefaultMode').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('rolesDefaultMode').valid }}</p>
  <br />
</div>
