import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Grid2Module } from '../../shared-features/grid2/grid2.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { DirectoriesListComponent } from 'src/app/settings-app/directories/list/directories-list.component';
import { DirectoryCardComponent } from 'src/app/settings-app/directories/card/directory-card.component';
import { DirectoryEntryModalComponent } from 'src/app/settings-app/directories/card/entries/modal/directory-entry-modal.component';
import { DirectoryEntriesToolbarComponent } from 'src/app/settings-app/directories/card/entries/toolbar/directory-entries-toolbar.component';
import { DirectoryPropertiesModalComponent } from 'src/app/settings-app/directories/card/properties-modal/directory-properties-modal.component';
import { DIRECTORIES_LIST } from 'src/app/settings-app/directories/model/directories.list';

@NgModule({
  declarations: [
    DirectoriesListComponent,
    DirectoryCardComponent,
    DirectoryPropertiesModalComponent,
    DirectoryEntryModalComponent,
    DirectoryEntriesToolbarComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    Grid2Module,
    BaseSharedModule,
    CommentsModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.directories',
          url: '/directories',
          component: DirectoriesListComponent,
          params: {
            navigation: 'settings.directories',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: DIRECTORIES_LIST },
          ],
        },
        {
          name: 'settings.directory',
          params: {
            navigation: 'settings.directories',
          },
          url: '/directories/{entityId:guid}',
          component: DirectoryCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class DirectoriesModule {}
