import { Inject, Injectable, Optional } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { Certificate } from 'src/app/shared/models/entities/certificates/certificate.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { takeUntil } from 'rxjs/operators';
import { ActionPanelService } from 'src/app/core/action-panel.service';

@Injectable()
/**
 * User certificates service.
 */
export class UserCertificatesService {
  private certificatesSubject = new BehaviorSubject<Certificate[]>([]);
  public certificates$ = this.certificatesSubject.asObservable();
  private destroyed$ = new Subject<void>();

  constructor(
    @Optional() @Inject('entityId') public entityId,
    private data: DataService,
    actionPanelService: ActionPanelService,
  ) {
    actionPanelService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.load();
      });
  }

  /**
   * Loads certificates for current user.
   */
  public load() {
    this.data
      .collection('Certificates')
      .query<Certificate[]>({
        select: ['id', 'name'],
        expand: { state: { select: ['id', 'name', 'style'] } },
        filter: {
          ownerId: {
            type: 'guid',
            value: this.entityId,
          },
          isActive: true,
        },
      })
      .subscribe((certificates) => {
        this.certificatesSubject.next(certificates);
      });
  }

  public dispose() {
    this.destroyed$.next();
  }
}
