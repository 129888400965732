import { ProjectVersionMergeEnum } from 'src/app/projects/project-versions/project-versions-merge-modal/models/project-version-merge-enum.model';
import { Guid } from 'src/app/shared/helpers/guid';

/** Represents the Project version merge option. */
export class ProjectVersionMergeOption extends ProjectVersionMergeEnum {
  /**
   * The Default merge option.
   *
   * @remarks Merge source to target.
   * */
  public static get default(): ProjectVersionMergeOption {
    return {
      id: Guid.generate(),
      code: 'Default',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.paramSelection.option.default',
    };
  }

  /**
   * The Combine merge option.
   *
   * @remarks Merge several sources to target.
   * */
  public static get combine(): ProjectVersionMergeOption {
    return {
      id: Guid.generate(),
      code: 'Combine',
      name: 'projects.projectVersions.projectVersionsMergeModal.steps.paramSelection.option.combine',
    };
  }
}

export const ProjectVersionMergeOptions: ProjectVersionMergeOption[] = [
  ProjectVersionMergeOption.default,
  ProjectVersionMergeOption.combine,
];
