import { Component } from '@angular/core';
import { TransitionService, UIRouterGlobals } from '@uirouter/core';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ProjectResourceSettings } from 'src/app/projects/card/project-resources/models/project-resource-settings.model';

@Component({
  selector: 'wp-project-resources-calendar-toggler',
  templateUrl: './project-resources-calendar-toggler.component.html',
  styleUrls: ['./project-resources-calendar-toggler.component.scss'],
})
export class ProjectResourcesCalendarTogglerComponent {
  constructor(
    public routerGlobals: UIRouterGlobals,
    transitionService: TransitionService,
    localConfigService: LocalConfigService,
  ) {
    const settings = localConfigService.getConfig(ProjectResourceSettings);
    transitionService.onSuccess({}, (trans) => {
      const state = trans.targetState().identifier();

      if (state.toString().includes('project.resources')) {
        settings.toggler = trans.targetState().name();
        localConfigService.setConfig(ProjectResourceSettings, settings);
      }
    });
  }
}
