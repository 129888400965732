<ng-container [formGroup]="form">
  <span class="text-body-secondary">{{
    'settings.validationRules.card.props.scheduleWorkDayDurationHelp' | translate
  }}</span>
  <br />
  <br />
  <div class="form-group">
    <label>{{ 'settings.validationRules.card.props.lagDown' | translate }}</label>

    <wp-number-box
      type="integer"
      [allowNull]="false"
      [min]="0"
      [max]="24"
      formControlName="LagDown"
    >
    </wp-number-box>
  </div>

  <div class="form-group">
    <label>{{ 'settings.validationRules.card.props.lagUp' | translate }}</label>
    <wp-number-box type="integer" formControlName="LagUp" [allowNull]="false" [min]="0" [max]="24">
    </wp-number-box>
  </div>
</ng-container>
