import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  DestroyRef,
  inject,
} from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DefaultFilterService } from '../../shared/default-filter/default-filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { StateService } from '@uirouter/core';
import { CompetenceCreationComponent } from '../creation/competence-creation.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'tmt-competences-list',
  template: '<wp-entity-list />',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetencesListComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private entityListService: EntityListService,
    private gridService: GridService,
    private stateService: StateService,
    private appService: AppService,
    private actionPanelService: ActionPanelService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.actionPanelService.set([
      this.actionPanelService.getDefaultAction('create', {
        title: 'settings.competences.list.create',
        isVisible: this.appService.checkEntityPermission(
          'Competence',
          PermissionType.Modify,
        ),
        handler: () => this.create(),
      }),
      this.actionPanelService.getDefaultAction('card', {
        handler: () =>
          this.stateService.go('settings.competence', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actionPanelService.getDefaultAction('delete', {
        handler: this.entityListService.delete,
      }),
    ]);

    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((row: any) => {
        this.actionPanelService.action('card').isShown = !!row;
        this.actionPanelService.action('delete').isShown =
          row &&
          this.appService.checkEntityPermission(
            'Competence',
            PermissionType.Modify,
          );
      });
  }

  /* Opens competence's creation modal. */
  private create(): void {
    this.modalService.open(CompetenceCreationComponent);
  }
}
