<form [formGroup]="form" class="modal-form project-summary__filter-modal" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.projectSummary.filter.header' | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>{{ 'projects.projectSummary.filter.properties.project.label' | translate }}</label>
          <wp-select-box
            collection="Projects"
            formControlName="project"
            [placeholder]="
              'projects.projectSummary.filter.properties.project.placeholder' | translate
            "
          ></wp-select-box>
        </div>

        <div class="form-group">
          <label>{{ 'projects.projectSummary.filter.properties.manager.label' | translate }}</label>
          <wp-user-box
            formControlName="user"
            [placeholder]="
              'projects.projectSummary.filter.properties.manager.placeholder' | translate
            "
          ></wp-user-box>
        </div>

        <div class="form-group">
          <label>{{ 'projects.projectSummary.filter.properties.program.label' | translate }}</label>

          <wp-select-box
            formControlName="program"
            collection="Programs"
            [placeholder]="
              'projects.projectSummary.filter.properties.program.placeholder' | translate
            "
          ></wp-select-box>
        </div>

        <div class="form-group">
          <label>{{ 'projects.projectSummary.filter.properties.client.label' | translate }}</label>

          <wp-select-box
            formControlName="client"
            collection="Organizations"
            [placeholder]="
              'projects.projectSummary.filter.properties.client.placeholder' | translate
            "
          ></wp-select-box>
        </div>
      </div>

      <div class="col-6">
        <tmt-state-select formControlName="projectStates" collection="Projects" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <button type="button" class="btn btn-default" (click)="clearValues()">
          {{ 'shared.actions.clear' | translate }}
        </button>
      </div>
      <div>
        <button type="button" class="btn btn-primary" (click)="ok()">
          {{ 'shared.actions.ok' | translate }}
        </button>
        <button type="button" class="btn btn-default ms-2" (click)="cancel()">
          {{ 'shared.actions.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
