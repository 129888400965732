<wp-loading-indicator [loading]="loading"></wp-loading-indicator>

<form class="form" [formGroup]="costForm" novalidate [hidden]="loading" style="width: 600px">
  <wp-data-not-saved-indicator [form]="costForm"></wp-data-not-saved-indicator>

  <div class="form-group">
    <label>{{ 'settings.users.card.cost.props.rule' | translate }}</label>
    <wp-select-box
      style="width: 450px"
      formControlName="costNormalizationRule"
      collection="CostNormalizationRules"
      placeholder="{{ 'settings.users.card.cost.props.rule' | translate }}"
    ></wp-select-box>
  </div>

  <hr />
  <wp-user-cost-rates [readonly]="readonly"></wp-user-cost-rates>

  <div class="actions">
    <button
      type="button"
      *ngIf="!readonly"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
