import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { TimeOffBalanceEntryFilterComponent } from './time-off-balance-entry-filter.component';

@Injectable()
export class TimeOffBalanceEntryFilterService extends FilterService {
  public override component: Type<FilterDetails> =
    TimeOffBalanceEntryFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;

  public override placeholder =
    'team.timeOffBalanceEntries.list.filter.placeholder';

  protected override getDefaultValues = (): any => {
    const value = {
      period: null,
      timeOffType: null,
      user: null,
      type: null,
      mode: null,
    };

    return value;
  };

  public override getODataFilter() {
    const result: any[] = [];

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        or: [
          // eslint-disable-next-line @typescript-eslint/quotes
          { "cast(number, 'Edm.String')": { contains: text } },
        ],
      });
    }

    if (this.values.user) {
      result.push({
        userId: { type: 'guid', value: this.values.user.id },
      });
    }

    if (this.values.timeOffType) {
      result.push({
        timeOffTypeId: { type: 'guid', value: this.values.timeOffType.id },
      });
    }

    if (this.values.type) {
      result.push({
        typeId: { type: 'guid', value: this.values.type.id },
      });
    }

    if (this.values.mode) {
      result.push({
        modeId: { type: 'guid', value: this.values.mode.id },
      });
    }

    if (this.values.period) {
      const dates = this.dateService.getDatePairFromPeriodForFilter(
        this.values.period,
      );

      result.push({
        date: { le: { type: 'raw', value: dates.periodFinish } },
      });

      result.push({
        date: { ge: { type: 'raw', value: dates.periodStart } },
      });
    }

    return result;
  }

  public override getDatePeriodUrlParams(): Dictionary<string> {
    return null;
  }
}
