import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { ResourcePoolCardComponent } from './card/resource-pool-card.component';
import { ResourcePoolListComponent } from './list/resource-pool-list.component';
import { ResourcePoolCreationComponent } from './creation/resource-pool-creation.component';
import { RESOURCE_POOL_LIST } from 'src/app/shared/lists/resource-pool.list';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
@NgModule({
  declarations: [
    ResourcePoolCardComponent,
    ResourcePoolListComponent,
    ResourcePoolCreationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.resourcePools',
          url: '/resource-pools',
          component: ResourcePoolListComponent,
          params: {
            navigation: 'settings.resourcePools',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: RESOURCE_POOL_LIST },
          ],
        },

        {
          name: 'settings.resourcePool',
          params: {
            navigation: 'settings.resourcePools',
          },
          url: '/resource-pools/{entityId:guid}',
          component: ResourcePoolCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class ResourcePoolsModule {}
