import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import {
  GetTransitionFormPropertyValue,
  TransitionFormDescription,
} from 'src/app/shared/models/entities/lifecycle/transition-form.model';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { BehaviorSubject, forkJoin, switchMap } from 'rxjs';
import {
  MetaEntity,
  MetaEntityBaseProperty,
  MetaEntityDirectoryProperty,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';
import { EntityTypesService } from 'src/app/shared/services/entity-types.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppService } from 'src/app/core/app.service';

@Component({
  selector: 'tmt-workflow-action-comment-form',
  templateUrl: './workflow-action-comment-form.component.html',
})
export class WorkflowActionCommentFormComponent implements OnInit {
  @Input() collection: string;
  @Input() entityId: string;
  @Input() actionId: string;
  @Input() taskId: string;
  @Input() actionName: string;

  public isSaving = false;

  public actionForm: FormGroup = this.formBuilder.group({});
  public actionProperties: GetTransitionFormPropertyValue[];

  public isLoading = false;
  protected readonly MetaEntityPropertyType = MetaEntityPropertyType;

  private collectionValuesSubject = new BehaviorSubject<any>(undefined);
  public collectionValues$ = this.collectionValuesSubject.asObservable();
  public metaEntity: MetaEntity;
  private metaEntityProps: MetaEntityBaseProperty[];
  public get requestComment() {
    return this._requestComment;
  }
  private destroyRef = inject(DestroyRef);
  private _requestComment = false;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private notification: NotificationService,
    private data: DataService,
    public appService: AppService,
    private entityTypesService: EntityTypesService,
  ) {}

  ngOnInit(): void {
    const params = { actionId: this.actionId };
    this.isLoading = true;
    this.data
      .collection(this.collection)
      .entity(this.entityId)
      .function('GetWorkflowActionFormDescription')
      .query(params)
      .subscribe({
        next: (actionFormDescription: TransitionFormDescription) => {
          this.actionProperties =
            actionFormDescription.transitionFormPropertyValues;
          if (this.metaEntity) {
            this.metaEntityProps = [
              ...this.metaEntity.primitiveProperties,
              ...this.metaEntity.navigationProperties,
              ...this.metaEntity.complexProperties,
              ...this.metaEntity.directoryProperties,
            ];
          }
          this._requestComment = actionFormDescription.requestComment;
          if (this.requestComment) {
            this.actionForm.addControl(
              'comment',
              this.formBuilder.control(
                null,
                actionFormDescription.commentIsRequired
                  ? Validators.required
                  : null,
              ),
            );
          }

          this.makeForm();
        },
        error: (error: Exception) => {
          this.isLoading = false;
          if (error.code === Exception.BtEntityNotFoundException.code) {
            return;
          }
          this.notification.error(error.message);
        },
      });
  }

  public ok() {
    this.actionForm.markAllAsTouched();

    if (this.actionForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const data = {
      actionId: this.actionId,
      actionFormValue: {
        comment: this.actionForm.value.comment,
        propertyValues: [],
      },
    };

    Object.keys(this.actionForm.value).forEach((key) => {
      const isNavigationProperty = this.actionProperties.some(
        (p) =>
          (p.name === key &&
            this.getMetaEntityProperty(key)?.type ===
              MetaEntityPropertyType.navigation) ||
          this.getMetaEntityProperty(key)?.type ===
            MetaEntityPropertyType.directory,
      );
      if (isNavigationProperty) {
        data.actionFormValue.propertyValues.push({
          name: key,
          value: this.actionForm.value[key]?.id,
        });
      } else if (key !== 'comment' && !isNavigationProperty) {
        data.actionFormValue.propertyValues.push({
          name: key,
          value: this.actionForm.value[key],
        });
      }
    });

    this.activeModal.close(data);
  }
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  /**
   * Returns MetaEntity property.
   * @param propertyName Property name.
   */
  public getMetaEntityProperty(propertyName: string): MetaEntityBaseProperty {
    return this.metaEntityProps.find((x: any) => x.name === propertyName);
  }

  /**
   * Returns DirectoryId from MetaEntity property.
   * @param propertyName Property name.
   */
  public getMetaEntityPropertyDirectoryId(propertyName: string): string {
    return (
      this.metaEntityProps.find(
        (x: any) => x.name === propertyName,
      ) as MetaEntityDirectoryProperty
    ).directoryId;
  }

  private makeForm() {
    const collectionObservables = {};
    const collectionValues = {};
    const hasNavigationProperties = this.actionProperties.some(
      (prop) =>
        this.getMetaEntityProperty(prop.name).type ===
        MetaEntityPropertyType.navigation,
    );

    if (!hasNavigationProperties) {
      this.setControlValues(null);
      return;
    }
    this.entityTypesService
      .getEntityTypes()
      .pipe(
        switchMap((entityTypeDescriptions) => {
          this.actionProperties
            .filter(
              (prop) =>
                this.getMetaEntityProperty(prop.name).type ===
                  MetaEntityPropertyType.navigation ||
                this.getMetaEntityProperty(prop.name).type ===
                  MetaEntityPropertyType.directory,
            )
            .forEach((prop) => {
              const mmProperty = this.getMetaEntityProperty(prop.name);
              const query = {
                filter: { isActive: true, directoryId: undefined },
              };
              if (mmProperty.type === MetaEntityPropertyType.directory) {
                query.filter.directoryId = {
                  type: 'guid',
                  value: (mmProperty as MetaEntityDirectoryProperty)
                    .directoryId,
                };
              }
              const collection = entityTypeDescriptions.find(
                (x) =>
                  x.entityTypeName ===
                  this.getMetaEntityProperty(prop.name).clrType,
              ).collection;
              collectionObservables[prop.name] = this.data
                .collection(collection)
                .query(query);
            });
          return forkJoin(collectionObservables);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (collections) => {
          Object.keys(collections).forEach((key) => {
            collectionValues[key] = collections[key];
          });

          this.setControlValues(collectionValues);
          this.collectionValuesSubject.next(collectionValues);
        },
        error: (error: Exception) => {
          this.isLoading = false;
          if (error.code === Exception.BtEntityNotFoundException.code) {
            return;
          }
          this.notification.error(error.message);
        },
      });
  }

  private setControlValues(collectionValues: any) {
    this.actionProperties.forEach((property) => {
      let value: any;
      if (
        (this.getMetaEntityProperty(property.name).type ===
          MetaEntityPropertyType.navigation ||
          this.getMetaEntityProperty(property.name).type ===
            MetaEntityPropertyType.directory) &&
        collectionValues
      ) {
        const entity = collectionValues[property.name].find(
          (item) => item.id === property.value,
        );
        if (entity) {
          value = { name: entity.name, id: entity.value };
        }
      } else {
        value = property.value;
      }

      this.actionForm.addControl(
        property.name,
        this.formBuilder.control(
          value,
          property.isRequired ? Validators.required : null,
        ),
      );
    });
    this.isLoading = false;
  }
}
