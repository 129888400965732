import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from '@angular/core';

import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';

import _isFunction from 'lodash/isFunction';
import { saveAs } from 'file-saver';

import { ImageZoomDirective } from 'src/app/shared/directives/image-zoom/image-zoom.directive';
import {
  AttachmentItem,
  AttachmentTemplate,
  ViewerComponentParams,
} from 'src/app/shared/components/features/viewer/viewer.interface';

@Component({
  selector: 'wp-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(ImageZoomDirective)
  private zoomDirectives: QueryList<ImageZoomDirective>;

  @Input() public params: ViewerComponentParams;
  public pdfZoom = 1;
  public activeAttachmentTemplate: AttachmentTemplate = 'other';
  public activeZoomItem: ImageZoomDirective | null = null;

  public readonly typeWithZoom: AttachmentTemplate[] = ['image', 'pdf'];
  private readonly extensionsType: Record<AttachmentTemplate, string[]> = {
    image: ['jpg', 'jpeg', 'png', 'apng', 'bmp', 'gif', 'webp', 'svg'],
    pdf: ['pdf'],
    video: ['webm', 'mp4', 'mp3', 'mpga'],
    other: [],
  };

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.params.activeId = String(
      this.params.activeId || this.params.attachments[0].id,
    );
    this.prepareAttachments();
    this.lazyLoad(this.activeItem);
    this.activeAttachmentTemplate = this.activeItem.type;
  }

  public ngAfterViewInit(): void {
    this.checkZoom(this.activeItem);
  }

  public ngOnDestroy(): void {
    this.clearUrl();
  }

  /**
   * Get attachment from current slide.
   *
   * @return attachment item.
   * */
  public get activeItem(): AttachmentItem {
    return this.params.attachments.find((el) => el.id === this.params.activeId);
  }

  /**
   * Slide transition handler.
   *
   * @param event NgbSlideEvent.
   *
   * */
  public onSlide(event: NgbSlideEvent): void {
    this.params.activeId = String(event.current);
    const item: AttachmentItem = this.activeItem;
    this.activeAttachmentTemplate = item.type;
    this.pdfZoom = 1;
    this.lazyLoad(item);
    this.checkZoom(item);
    this.cdr.markForCheck();
  }

  /**
   * Download the file.
   *
   * @param file AttachmentItem.
   *
   * */
  public saveFile(file: AttachmentItem): void {
    saveAs(file.data, file.fileName);
  }

  /** Zoom in! */
  public zoomIn(): void {
    this.activeZoomItem?.zoomIn();

    if (this.activeAttachmentTemplate === 'pdf') {
      this.pdfZoom += 0.2;
    }
  }

  /** Zoom out! */
  public zoomOut(): void {
    this.activeZoomItem?.zoomOut();

    if (this.activeAttachmentTemplate === 'pdf') {
      this.pdfZoom -= 0.2;
    }
  }

  private prepareAttachments(): void {
    this.params.attachments.forEach((item) => {
      item.id = String(item.id);
      item.type = this.getTemplateType(
        item.fileExt || item.fileName.split('.').pop().toLowerCase(),
      );

      if (item.data) {
        item.url = window.URL.createObjectURL(item.data);
      }

      item.dataReadyStatus = _isFunction(item.dataLazyLoad)
        ? 'awaiting'
        : 'success';
    });
  }

  private getTemplateType(extension: string): AttachmentTemplate {
    for (const key in this.extensionsType) {
      if (this.extensionsType[key].includes(extension)) {
        return key as AttachmentTemplate;
      }
    }

    return 'other';
  }

  private lazyLoad(item: AttachmentItem): void {
    if (item.dataReadyStatus === 'awaiting') {
      item.dataReadyStatus = 'loading';

      item
        .dataLazyLoad()
        .then((response) => {
          item.data = response;
          item.url = window.URL.createObjectURL(response);
          item.dataReadyStatus = 'success';
        })
        .catch(() => {
          item.dataReadyStatus = 'fail';
        })
        .finally(() => {
          this.cdr.markForCheck();
        });
    }
  }

  private checkZoom(item: AttachmentItem): void {
    if (item.type === 'image') {
      this.activeZoomItem = this.zoomDirectives.find(
        (el) => el.imageId === item.id,
      );
    } else {
      this.activeZoomItem = null;
    }
  }

  private clearUrl(): void {
    this.params.attachments.forEach((item) =>
      window.URL.revokeObjectURL(item.url),
    );
  }
}
