import { Component, OnInit, Input, inject, DestroyRef } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { PermissionSet } from 'src/app/shared/models/entities/settings/permission-set.model';
import { Constants } from 'src/app/shared/globals/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationService } from 'src/app/core/navigation.service';

@Component({
  selector: 'wp-permission-set-card',
  templateUrl: './permission-set-card.component.html',
})
export class PermissionSetCardComponent implements OnInit {
  @Input() entityId: string;

  public isLoading = false;
  public isSaving = false;

  public readonly: boolean;

  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    id: [''],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    roleName: [''],
    isActive: [false],
    details: this.fb.group({}),
  });

  private get details(): UntypedFormGroup {
    return <UntypedFormGroup>this.form.controls['details'];
  }

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    private navigationService: NavigationService,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
    ]);

    this.load();

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  public reload(): void {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }

  /** Saves Data. */
  public save = (): void => {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const permissionSet = <PermissionSet>this.form.value;

    const data = {
      id: permissionSet.id,
      name: permissionSet.name,
      roleName: permissionSet.roleName,
      description: permissionSet.description,
      isActive: permissionSet.isActive,
      permissionSetDetails: <any>[],
    };

    const addDetails = (
      value: any,
      granularName: string,
      scopeName: string,
    ) => {
      data.permissionSetDetails.push({
        id: value.id,
        granularName,
        scopeName,
        actionEnabled: value.actionEnabled,
        editEnabled: value.editEnabled,
        viewEnabled: value.viewEnabled,
        deleteEnabled: value.deleteEnabled,
      });
    };

    const details = this.details.value;
    for (const granularName in details) {
      if (details[granularName].id) {
        addDetails(details[granularName], granularName, null);
      } else {
        for (const scopeName of Object.keys(details[granularName])) {
          addDetails(details[granularName][scopeName], granularName, scopeName);
        }
      }
    }

    this.data
      .collection('PermissionSets')
      .entity(this.entityId)
      .update(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal('settings.permissionSets.card.saved');
        },
        error: (error: any) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  };

  /** Loads Data. */
  private load = (): void => {
    this.isLoading = true;
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.data
      .collection('PermissionSets')
      .entity(this.entityId)
      .get<PermissionSet>({ expand: ['PermissionSetDetails'] })
      .subscribe((permissionSet: PermissionSet) => {
        this.readonly = !permissionSet.editAllowed;
        this.form.patchValue(permissionSet);

        this.navigationService.addRouteSegment({
          id: permissionSet.id,
          title: permissionSet.name,
        });

        permissionSet.permissionSetDetails.forEach((detail) => {
          let formGroup: UntypedFormGroup = <UntypedFormGroup>(
            this.details.controls[detail.granularName]
          );
          if (!formGroup) {
            formGroup = new UntypedFormGroup({});
            this.details.addControl(detail.granularName, formGroup);
          }

          let detailFormGroup: UntypedFormGroup;

          if (detail.scopeName) {
            detailFormGroup = <UntypedFormGroup>(
              formGroup.controls[detail.scopeName]
            );
            if (!detailFormGroup) {
              detailFormGroup = new UntypedFormGroup({});
              formGroup.addControl(detail.scopeName, detailFormGroup);
            }
          } else {
            detailFormGroup = formGroup;
          }

          detailFormGroup.reset();
          detailFormGroup.addControl('id', this.fb.control(''));
          detailFormGroup.addControl('actionEnabled', this.fb.control(false));
          detailFormGroup.addControl('editEnabled', this.fb.control(false));
          detailFormGroup.addControl('viewEnabled', this.fb.control(false));
          detailFormGroup.addControl('deleteEnabled', this.fb.control(false));
          detailFormGroup.addControl('granularType', this.fb.control(''));

          detailFormGroup.patchValue(detail);
        });

        this.actionService.action('save').isShown = !this.readonly;
        this.readonly ? this.form.disable() : this.form.enable();
        this.isLoading = false;
      });
  };
}
