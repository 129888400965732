import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { WpParserFormatter } from './wp-parser-formatter';
import { DatePipe } from '@angular/common';
import { DateTime, Interval } from 'luxon';
import { filter, takeUntil } from 'rxjs/operators';
import { customPopperOptions } from '../../../helpers/modern-grid.helper';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { StandaloneFormControlDirective } from 'src/app/shared/directives/form-control.directive';

/** Контрол ввода даты. */
@Component({
  selector: 'wp-date-box',
  templateUrl: './date-box.component.html',
  styleUrls: ['./date-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateBoxComponent),
      multi: true,
    },
    { provide: NgbDateParserFormatter, useClass: WpParserFormatter },
  ],
  hostDirectives: [
    {
      directive: StandaloneFormControlDirective,
      inputs: ['formControl: control'],
    },
  ],
})
export class DateBoxComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy, OnChanges
{
  /** Можно стирать значение. */
  @Input() allowNull = true;
  @Input() readonly: boolean;

  @Input() excludePeriod: Interval;
  @Input() autofocus?: boolean;

  /** Callback function for checking if date must be disabled. */
  @Input() checkIsDisabled?: (date: DateTime) => boolean;

  /** Callback function for checking if date must be highlighted. */
  @Input() checkIsHighlighted?: (date: DateTime) => boolean;

  /** Initial value for input element after rendering. */
  @Input() initialValue?: unknown;

  /** Angular abstract control for binding to form outside of template. */
  @Input() control?: AbstractControl;

  @ViewChild('d') datePicker;

  dateControl = new UntypedFormControl(null);
  value: string = null;
  public disabled = false;

  /** Indicates is emitting of control value blocked. */
  private isEmittingBlocked: boolean;

  /** Last input value which was before focusing on the input. */
  private controlValueBeforeFocusing: NgbDateStruct | null;

  private keyboardSubscription: Subscription;
  private destroyed$ = new Subject<void>();

  constructor(
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
  ) {
    this.dateControl.valueChanges
      .pipe(
        filter(() => !this.isEmittingBlocked),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        const newValue = this.getValueFromControl();
        if (!this.allowNull && newValue === null) {
          return;
        }
        if (this.value !== newValue) {
          this.value = newValue;
          this.propagateChange(this.value);
        }

        // Needs if input view value is not equal to real value
        this.datePicker._elRef.nativeElement.value = this.getTitle();
      });

    this.placeholder = datePipe.transform(
      DateTime.local().startOf('year').toISODate(),
      'shortDate',
    );
  }

  ngAfterViewInit(): void {
    if (this.autofocus && this.datePicker) {
      this.datePicker._elRef?.nativeElement?.select();
    }

    this.applyInitialValue();

    if (this.readonly) {
      this.disabled = this.readonly;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['readonly']) {
      this.disabled = this.readonly;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }
  }

  /** Apply initial value after rendering. */
  private applyInitialValue() {
    if (this.initialValue === undefined) {
      return;
    }
    if (this.datePicker?._elRef?.nativeElement) {
      const event = new Event('input');
      if (typeof this.initialValue === 'string') {
        this.datePicker._elRef.nativeElement.value = this.initialValue;
        this.datePicker._elRef.nativeElement.dispatchEvent(event);
      } else if (this.initialValue === null) {
        this.datePicker._elRef.nativeElement.value = '';
        this.datePicker._elRef.nativeElement.dispatchEvent(event);
      }
    }
    this.initialValue = undefined;
  }

  public popperOptions = customPopperOptions;

  public placeholder: string;
  propagateChange = (_: string) => null;
  propagateTouch = () => null;

  private getValueFromControl(): string {
    let newValue: string;
    if (!this.dateControl.value || typeof this.dateControl.value === 'string') {
      newValue = null;
    } else {
      const mDate = DateTime.fromObject({
        year: this.dateControl.value.year,
        month: this.dateControl.value.month,
        day: this.dateControl.value.day,
      });
      newValue = mDate.isValid ? mDate.toFormat('yyyy-MM-dd') : null;
    }
    return newValue;
  }

  writeValue(value: string): void {
    if (this.isEmittingBlocked) {
      return;
    }

    if (!value) {
      this.dateControl.setValue(null, { emitEvent: false });
    } else {
      const mDate = DateTime.fromISO(value);
      const ngbDate = <NgbDateStruct>{
        day: mDate.day,
        month: mDate.month,
        year: mDate.year,
      };
      this.dateControl.setValue(ngbDate, { emitEvent: false });
    }

    this.value = this.getValueFromControl();
    this.dateControl.markAsPristine();
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /** Input onFocus logic.*/
  public onFocus() {
    this.isEmittingBlocked = true;
    this.controlValueBeforeFocusing = this.dateControl.value;
    this.initKeysSubscribes();
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (!this.readonly) {
      this.disabled = isDisabled;
    }
    this.cdr.markForCheck();
  }

  closePopper() {
    this.datePicker.close();
  }

  /** Input onBlur logic.*/
  onBlur() {
    this.controlValueBeforeFocusing = null;
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }
    this.isEmittingBlocked = false;
    this.dateControl.updateValueAndValidity();
    this.propagateTouch();

    const currValue = this.getValueFromControl();
    if (!this.allowNull && currValue === null) {
      this.writeValue(this.value);
    }

    const currDateTime = DateTime.fromISO(currValue);
    const currNgbDate = this.dateTimeToNgbDate(currDateTime);
    if (
      currDateTime.isValid &&
      this.checkIsExcludedByPeriod(currNgbDate) &&
      !this.readonly
    ) {
      if (
        this.excludePeriod &&
        +this.excludePeriod.start < +currDateTime &&
        +currDateTime < +this.excludePeriod.end
      ) {
        const includedDate = this.excludePeriod.end.plus({ days: 1 });
        this.dateControl.setValue(this.dateTimeToNgbDate(includedDate));
      }
    }
  }

  public getTitle(): string {
    if (!this.value) {
      return '';
    }

    return this.datePipe.transform(new Date(this.value), 'shortDate');
  }

  /** Indicates is date needs to exclude by period.
   *
   * @param date checking date
   * @returns is date needs to exclude
   */
  public checkIsExcludedByPeriod(date: NgbDateStruct): boolean {
    let excludedByPeriod = false;

    const currDate = DateTime.fromObject(date);

    if (this.excludePeriod) {
      const periodStart = this.excludePeriod.start;
      const periodEnd = this.excludePeriod.end;
      excludedByPeriod =
        (+currDate > +periodStart && +currDate < +periodEnd) ||
        +currDate === +periodStart ||
        +currDate === +periodEnd;
    }

    return excludedByPeriod;
  }

  /** Disables date in the date-picker.
   *
   * @param date checking date
   * @returns isDisable
   */
  protected markDisabled = (date: NgbDateStruct): boolean => {
    const excluded = this.checkIsExcludedByPeriod(date);
    const luxonDate = DateTime.fromObject(date);
    let excludedByFunc = false;
    if (this.checkIsDisabled) {
      excludedByFunc = this.checkIsDisabled(luxonDate);
    }
    return excluded || excludedByFunc;
  };

  /** Highlights date in the date-picker.
   *
   * @param date checking date
   * @returns isHighlight
   */
  protected markHighlighted = (date: NgbDateStruct): boolean => {
    if (this.checkIsHighlighted) {
      const luxonDate = DateTime.fromObject(date);
      return this.checkIsHighlighted(luxonDate);
    }
    return false;
  };

  private dateTimeToNgbDate = (date: DateTime): NgbDateStruct => ({
    day: date.day,
    month: date.month,
    year: date.year,
  });

  /** Initializes keyboard listener. */
  private initKeysSubscribes() {
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }
    this.keyboardSubscription = fromEvent(window, 'keydown').subscribe(
      (event: KeyboardEvent) => {
        if (!event.repeat) {
          if (this.isEmittingBlocked) {
            switch (event.code) {
              case 'Enter':
              case 'NumpadEnter':
                this.isEmittingBlocked = false;
                this.dateControl.updateValueAndValidity();
                this.isEmittingBlocked = true;
                break;
              case 'Escape':
                this.isEmittingBlocked = false;
                this.dateControl.setValue(this.controlValueBeforeFocusing);
                this.onBlur();
                break;
            }
          }
        }
        return;
      },
    );
  }
}
