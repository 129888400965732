import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class TimeOffScheduleSettings implements BaseSettings {
  public readonly name = 'TimeOffScheduleSettings';
  version: number;
  planningScale: PlanningScale;
  getDefault(): TimeOffScheduleSettings {
    return {
      version: 1,
      planningScale: PlanningScale.Day,
    } as TimeOffScheduleSettings;
  }
}
