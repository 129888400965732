<p class="text-uppercase">
  {{ 'billing.invoices.card.addingLinesModal.grouping.header' | translate }}
</p>
<p class="text-body-secondary">
  {{ 'billing.invoices.card.addingLinesModal.grouping.help' | translate }}
</p>

<div class="controls mt-2 mb-2">
  <div class="form-group" *ngFor="let column of columns.controls; index as index">
    <select [formControl]="column" class="form-select">
      <option
        *ngFor="let availableField of availableFields[index]"
        [ngValue]="availableField.field"
      >
        {{ availableField.label }}
      </option>
    </select>

    <i
      class="remove bi bi-trash3"
      (click)="remove(index)"
      title="{{ 'shared.actions.delete' | translate }}"
    ></i>
  </div>

  <button *ngIf="hasAddButton" class="add btn btn-link" (click)="add()">
    <i class="bi bi-plus-lg bi-15"></i>
    &nbsp;{{ 'shared.actions.add' | translate }}
  </button>
</div>

<div class="action-panel">
  <button type="button" class="btn btn-primary" (click)="save()">
    {{ 'shared.actions.save' | translate }}
  </button>
  <button type="button" class="btn btn-default ms-2" (click)="cancel()">
    {{ 'shared.actions.cancel' | translate }}
  </button>
</div>
