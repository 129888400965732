<!--Тип и шаблон-->
<div class="row disable-selecting" style="padding-bottom: 10px">
  <div class="col-4">
    <div class="form-group" style="margin-bottom: 5px" [formGroup]="form">
      <wp-select-box
        collection="ReportTypes"
        [allowNull]="false"
        [query]="{ select: ['id', 'name', 'code', 'group'] }"
        formControlName="reportType"
        groupHandler="group"
        placeholder="{{
          'analytics.reportViewSettings.properties.reportType.placeholder' | translate
        }}"
      />
    </div>
  </div>
  <div class="col-3" style="padding-left: 0">
    <div *ngIf="viewSettings?.sourceName && templatesAreAllowed" class="form-group">
      <div
        ngbDropdown
        class="d-inline-block"
        (openChange)="loadTemplates($event)"
        placement="bottom-left"
      >
        <button
          type="button"
          class="btn btn-default"
          ngbDropdownToggle
          title="{{ 'analytics.reportViewSettings.actions.applyTemplate.hint' | translate }}"
        >
          {{ 'analytics.reportViewSettings.actions.applyTemplate.label' | translate }}
        </button>
        <div ngbDropdownMenu class="dropdown-scroll">
          <wp-loading-indicator [loading]="isTemplatesLoading"></wp-loading-indicator>
          <div
            [hidden]="isTemplatesLoading || templates.length !== 0"
            hidden
            class="text-center text-body-secondary"
          >
            {{ 'shared.noOptions' | translate }}
          </div>
          <button
            *ngFor="let template of templates"
            ngbDropdownItem
            (click)="applyTemplate(template.id)"
            title="{{ template.name }}"
          >
            {{ template.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row disable-selecting" [hidden]="!viewSettings?.sourceName">
  <!--Колонки источника-->
  <div class="col-4" [formGroup]="form">
    <label class="control-label">{{
      'analytics.reportViewSettings.labels.sourceFields' | translate
    }}</label>

    <wp-text-box
      formControlName="sourceFilter"
      style="padding-bottom: 15px"
      placeholder="{{ 'analytics.reportViewSettings.properties.search' | translate }}"
    ></wp-text-box>

    <div class="scroll-container source-container">
      <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

      <div
        [hidden]="isLoading"
        class="fields"
        [nxtSortablejs]="sourceFieldRows"
        [config]="sourceSortableOptions"
        [cloneFunction]="cloneFieldHandler"
        id="source-fields-container"
      >
        <div
          *ngFor="let row of sourceFieldRows.controls; trackBy: trackByName"
          [attr.name]="row.value.name"
          [ngClass]="{
            'source-field': !row.value.isGroup,
            'group-field': row.value.isGroup
          }"
          [formGroup]="row"
        >
          <div *ngIf="!row.value.isGroup" class="wp-checkbox">
            <label>
              <input type="checkbox" formControlName="selected" />
              <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
            </label>
          </div>

          <div
            class="trim"
            title="{{ row.value.title }}"
            [ngClass]="{ handle: !row.value.isGroup }"
          >
            {{ row.value.title }}
          </div>
        </div>
        <div
          *ngIf="sourceFieldRows.length == 0"
          class="text-uppercase text-body-secondary text-center"
        >
          {{ 'shared.noOptions' | translate }}
        </div>
      </div>
    </div>
  </div>

  <!--Колонки представления-->
  <div class="col-8" style="position: relative">
    <div class="row">
      <div class="col-6" *ngIf="hasRows">
        <label class="control-label">{{ rowsContainerLabel }}</label>
        <div class="scroll-container" id="rows-scroll-container">
          <div
            class="fields"
            [nxtSortablejs]="rowFields"
            [config]="fieldSortableOptions"
            id="row-fields-container"
          >
            <div
              class="view-field handle trim"
              *ngFor="let field of rowFields.controls; index as index"
              title="{{ getViewFieldTitle(field) }}"
              [ngClass]="{ calculated: field.value.sourceField.isCalculated }"
              [attr.name]="field.value.name"
            >
              <span *ngIf="field.value.sourceField.isCalculated"
                ><i class="bi bi-lightning" aria-hidden="true"></i>&nbsp;</span
              >{{ getViewFieldTitle(field) }}

              <button
                type="button"
                class="edit"
                (click)="editField(field)"
                title="{{ 'shared.actions.edit' | translate }}"
              >
                <i class="bi bi-pencil" aria-hidden="true"></i>
              </button>

              <button
                type="button"
                class="delete"
                (click)="removeRowField(index)"
                title="{{ 'shared.actions.delete' | translate }}"
              >
                &times;
              </button>
            </div>
          </div>

          <div
            class="drop-zone"
            *ngIf="rowFieldsLimit() > rowFields.length && rowFields.length === 0"
          >
            {{ 'analytics.reportViewSettings.labels.selectAvailableColumns' | translate }}
          </div>
        </div>
      </div>

      <div class="col-6" *ngIf="hasRowGroups">
        <label class="control-label">{{ rowGroupsContainerLabel }}</label>
        <div class="scroll-container" id="row-groups-scroll-container">
          <div
            class="fields"
            [nxtSortablejs]="rowGroupFields"
            [config]="fieldSortableOptions"
            id="row-group-fields-container"
          >
            <div
              class="view-field trim handle"
              *ngFor="let field of rowGroupFields.controls; index as index"
              title="{{ getViewFieldTitle(field) }}"
              [ngClass]="{ calculated: field.value.sourceField.isCalculated }"
              [attr.name]="field.value.name"
            >
              <span *ngIf="field.value.sourceField.isCalculated"
                ><i class="bi bi-lightning" aria-hidden="true"></i>&nbsp;</span
              >{{ getViewFieldTitle(field) }}
              <button
                type="button"
                class="edit"
                (click)="editField(field)"
                title="{{ 'shared.actions.edit' | translate }}"
              >
                <i class="bi bi-pencil" aria-hidden="true"></i>
              </button>
              <button
                type="button"
                class="delete"
                (click)="removeRowGroupField(index)"
                title="{{ 'shared.actions.delete' | translate }}"
              >
                &times;
              </button>
            </div>
          </div>
          <div
            class="drop-zone"
            *ngIf="rowGroupFieldsLimit() > rowGroupFields.length && rowGroupFields.length === 0"
          >
            {{ 'analytics.reportViewSettings.labels.selectAvailableColumns' | translate }}
          </div>
        </div>
      </div>

      <div class="col-6">
        <label class="control-label">{{ valuesContainerLabel }}</label>

        <div class="scroll-container" id="values-scroll-container">
          <div
            class="fields value-fields"
            [nxtSortablejs]="valueFields"
            [config]="fieldSortableOptions"
            id="value-fields-container"
          >
            <div
              class=""
              *ngFor="let field of valueFields.controls; index as index"
              title="{{ getViewFieldTitle(field) }}"
              [ngClass]="{ calculated: field.value.sourceField.isCalculated }"
              [formGroup]="field"
              [attr.name]="field.value.name"
            >
              <div class="view-field handle trim">
                <span *ngIf="field.value.sourceField.isCalculated"
                  ><i class="bi bi-lightning" aria-hidden="true"></i>&nbsp;</span
                >{{ getViewFieldTitle(field) }}

                <button
                  type="button"
                  class="edit"
                  (click)="editField(field)"
                  title="{{ 'shared.actions.edit' | translate }}"
                >
                  <i class="bi bi-pencil" aria-hidden="true"></i>
                </button>

                <button
                  type="button"
                  class="delete"
                  (click)="removeValueField(index)"
                  title="{{ 'shared.actions.delete' | translate }}"
                >
                  &times;
                </button>
              </div>
              <div class="total-block" *ngIf="totalFunctionsAreAllowed">
                <wp-select-box
                  *ngIf="field.value.sourceField.totals.length > 0"
                  class="nested"
                  [fixedPosition]="true"
                  [values]="getTotalOptions(field.value.name)"
                  formControlName="total"
                  placeholder="{{ 'analytics.reportViewSettings.labels.total' | translate }}"
                >
                </wp-select-box>
              </div>
            </div>
          </div>

          <div
            class="drop-zone"
            *ngIf="valueFieldsLimit() > valueFields.length && valueFields.length === 0"
          >
            {{ 'analytics.reportViewSettings.labels.selectAvailableColumns' | translate }}
          </div>
        </div>
      </div>

      <div class="col-6" *ngIf="hasValueGroups">
        <label class="control-label">{{ valueGroupsContainerLabel }}</label>
        <div class="scroll-container" id="value-groups-scroll-container">
          <div
            class="fields"
            [nxtSortablejs]="valueGroupFields"
            [config]="fieldSortableOptions"
            id="value-group-fields-container"
          >
            <div
              class="view-field handle trim"
              *ngFor="let field of valueGroupFields.controls; index as index"
              title="{{ getViewFieldTitle(field) }}"
              [ngClass]="{ calculated: field.value.sourceField.isCalculated }"
              [attr.name]="field.value.name"
            >
              <span *ngIf="field.value.sourceField.isCalculated"
                ><i class="bi bi-lightning" aria-hidden="true"></i>&nbsp;</span
              >{{ getViewFieldTitle(field) }}
              <button
                type="button"
                class="edit"
                (click)="editField(field)"
                title="{{ 'shared.actions.edit' | translate }}"
              >
                <i class="bi bi-pencil" aria-hidden="true"></i>
              </button>
              <button
                type="button"
                class="delete"
                (click)="removeValueGroupField(index)"
                title="{{ 'shared.actions.delete' | translate }}"
              >
                &times;
              </button>
            </div>
          </div>
          <div
            class="drop-zone"
            *ngIf="
              valueGroupFieldsLimit() > valueGroupFields.length && valueGroupFields.length === 0
            "
          >
            {{ 'analytics.reportViewSettings.labels.selectAvailableColumns' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
