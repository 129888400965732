import { NgModule } from '@angular/core';
import { MembersBoxComponent } from './members-box/members-box.component';
import { MembersCellComponent } from './members-cell/members-cell.component';
import { MembersReadonlyCellComponent } from './members-cell/members-readonly-cell.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { RolesCellComponent } from './roles-cell/roles-cell.component';
import { RolesReadonlyCellComponent } from './roles-cell/roles-readonly-cell.component';
import { FinancialAmountCellComponent } from './financial-amount-cell/financial-amount-cell.component';
import { FinancialAmountReadonlyCellComponent } from './financial-amount-cell/financial-amount-readonly-cell.component';
import { FinancialDateCellComponent } from './financial-date-cell/financial-date-cell.component';
import { FinancialDateReadonlyCellComponent } from './financial-date-cell/financial-date-readonly-cell.component';
import { FinancialDescriptionCellComponent } from './financial-description-cell/financial-description-cell.component';
import { FinancialDescriptionReadonlyCellComponent } from './financial-description-cell/financial-description-readonly-cell.component';
import { FinancialTaskCellComponent } from './financial-task-cell/financial-task-cell.component';
import { FinancialNonNullableDateCellComponent } from './financial-date-cell/financial-non-nullable-date-cell.component';
import { FinancialNonNullableAmountCellComponent } from './financial-amount-cell/financial-non-nullable-amount-cell.component';
import { RecurringExpenseRuleCellComponent } from 'src/app/projects/card/shared/recurring-expense-rule-cell/recurring-expense-rule-cell.component';
import { PredecessorBoxComponent } from 'src/app/projects/card/shared/predecessor-box/predecessor-box.component';

@NgModule({
  declarations: [
    MembersBoxComponent,
    MembersCellComponent,
    MembersReadonlyCellComponent,
    RolesCellComponent,
    RolesReadonlyCellComponent,
    FinancialDateCellComponent,
    FinancialDateReadonlyCellComponent,
    FinancialAmountCellComponent,
    FinancialAmountReadonlyCellComponent,
    FinancialTaskCellComponent,
    FinancialNonNullableDateCellComponent,
    FinancialNonNullableAmountCellComponent,
    FinancialDescriptionCellComponent,
    FinancialDescriptionReadonlyCellComponent,
    RecurringExpenseRuleCellComponent,
    PredecessorBoxComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    MembersBoxComponent,
    MembersCellComponent,
    MembersReadonlyCellComponent,
    RolesCellComponent,
    RolesReadonlyCellComponent,
    PredecessorBoxComponent,
  ],
})
export class ProjectCardSharedModule {}
