import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ExpensesCalendarSettings } from '../../models/expenses-calendar.settings';
import { ExpensesGroupType } from '../../models/expenses-view.model';

@Injectable()
export class ProjectExpensesCalendarGroupFilterService {
  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  private readonly _selectedGroups: ExpensesGroupType[] = [];
  public get selectedGroups(): ExpensesGroupType[] {
    return this._selectedGroups;
  }

  public get allGroups(): ExpensesGroupType[] {
    return Object.values(ExpensesGroupType);
  }

  constructor(private localConfigService: LocalConfigService) {
    const settings = this.localConfigService.getConfig(
      ExpensesCalendarSettings,
    );
    this._selectedGroups = settings.displayedGroups;
  }

  /**
   * Toggles passed group
   *
   * @param group - group to toggle
   */
  public toggleGroup(group: ExpensesGroupType) {
    if (this._selectedGroups.includes(group)) {
      const indexToRemove = this._selectedGroups.indexOf(group);
      this._selectedGroups.splice(indexToRemove, 1);

      this.updateConfig();
      this.changesSubject.next();
      return;
    }

    this._selectedGroups.push(group);
    this.updateConfig();
    this.changesSubject.next();
  }

  /**
   * Updates stored config
   *
   * @private
   */
  private updateConfig() {
    const settings = this.localConfigService.getConfig(
      ExpensesCalendarSettings,
    );
    settings.displayedGroups = this._selectedGroups;
    this.localConfigService.setConfig(ExpensesCalendarSettings, settings);
  }
}
