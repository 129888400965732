<div class="row mt-4">
  <div class="col-12">
    <label class="group mb-0">
      {{ 'components.boards.groups.navigation' | translate }}
    </label>
  </div>
</div>
<div class="form-group">
  @if (!readonly) {
    <div class="toolbar-container">
      <div class="group">
        <button
          type="button"
          class="btn btn-default"
          title="{{ 'shared2.actions.add' | translate }}"
          [disabled]="!gridService.canBeExecuted('add') || areas.length === navigations.length"
          (click)="gridService.execute('add')"
        >
          <i class="bi bi-plus-lg bi-15"></i>&nbsp;{{ 'shared2.actions.add' | translate }}
        </button>

        <button
          [disabled]="!(gridService.selectedGroup$ | async)"
          type="button"
          class="btn btn-default"
          title="{{ 'shared2.actions.delete' | translate }}"
          (click)="removeNavigationItem()"
        >
          <span class="bi bi-trash3"></span>
        </button>
      </div>
    </div>
  }
  <tmt-grid [formArray]="navigations" [options]="gridOptions" />
</div>
