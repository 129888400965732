<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">{{ 'shared.viewSettings.header' | translate }}</h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="row disable-selecting">
      <div class="col-4">
        <div class="list-header">
          <span title="{{ 'shared.viewSettings.columns.availableColumns' | translate }}">
            {{ 'shared.viewSettings.columns.availableColumns' | translate }}</span
          >
        </div>

        <div
          class="list"
          id="columns-container"
          [nxtSortablejs]="columns"
          [config]="columnsSortableOptions"
          [cloneFunction]="cloneFieldHandler"
        >
          <div class="list-item" *ngFor="let column of columns" [attr.data-name]="column.name">
            <div class="wp-checkbox">
              <label>
                <input
                  type="checkbox"
                  [(ngModel)]="column.isSelected"
                  (ngModelChange)="onSelected(column)"
                  [ngModelOptions]="{ standalone: true }"
                />
                <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
              </label>
            </div>

            <div class="trim handle" title="{{ column.hint }}">
              {{ column.header }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-8">
        <div class="list-header">
          <span title="{{ 'shared.viewSettings.columns.column' | translate }}">
            {{ 'shared.viewSettings.columns.column' | translate }}
          </span>
          <span *ngIf="!modern" title="{{ 'shared.viewSettings.columns.width' | translate }}">
            {{ 'shared.viewSettings.columns.width' | translate }}
          </span>
          <span title="{{ 'shared.viewSettings.columns.total' | translate }}">
            {{ 'shared.viewSettings.columns.total' | translate }}
          </span>
        </div>

        <div
          class="list"
          [nxtSortablejs]="selectedColumns"
          [config]="selectedColumnsSortableOptions"
        >
          <div
            class="list-item"
            *ngFor="let column of selectedColumns"
            [attr.data-name]="column.name"
          >
            <div class="trim handle" title="{{ column.header }}">
              <span>
                {{ column.header }}
              </span>
              <button
                type="button"
                class="list-item-delete"
                title="{{ 'shared.actions.delete' | translate }}"
                (click)="deleteItem(column)"
              >
                &times;
              </button>
            </div>
            <div class="list-item-select" *ngIf="!modern">
              <select
                *ngIf="!column.fixedWidth"
                class="form-select"
                [(ngModel)]="column.width"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="1" [selected]="column.width === 1">1</option>
                <option value="2" [selected]="column.width === 2">2</option>
                <option value="3" [selected]="column.width === 3">3</option>
                <option value="4" [selected]="column.width === 4">4</option>
                <option value="5" [selected]="column.width === 5">5</option>
              </select>
            </div>
            <div class="list-item-select">
              <select
                *ngIf="column.totals?.length > 0"
                class="form-select"
                [(ngModel)]="column.total"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="">{{ getTotalTitle(null) }}</option>
                <option
                  *ngFor="let total of column.totals"
                  value="{{ total }}"
                  [selected]="total == column.total"
                >
                  {{ getTotalTitle(total) }}
                </option>
              </select>
            </div>
          </div>
          <div class="list-empty" *ngIf="selectedColumns.length === 0">
            {{ 'shared.dragColumn' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div style="display: flex; justify-content: space-between">
      <div style="text-align: left">
        <button
          class="btn btn-default"
          (click)="toDefault()"
          title="{{ 'shared.resetViewSettings' | translate }}"
          data-test="resetViewSettings"
        >
          {{ 'shared.viewSettings.byDefault' | translate }}
        </button>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-primary"
          (click)="ok()"
          wpButtonSpinner
          [isBusy]="isSaving"
        >
          {{ 'shared.actions.save' | translate }}
        </button>
        <button type="button" class="btn btn-default ms-2" (click)="cancel()" [disabled]="isSaving">
          {{ 'shared.actions.cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
