import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { Schedule } from 'src/app/shared/models/entities/settings/schedule.model';
import { NavigationService } from 'src/app/core/navigation.service';

@Injectable()
export class ScheduleCardService {
  public cardIsLoading$ = new BehaviorSubject<boolean>(false);

  private scheduleSubject = new BehaviorSubject<Schedule>(null);
  public schedule$ = this.scheduleSubject.asObservable();

  private reloadSubject = new Subject<void>();
  public reload$ = this.reloadSubject.asObservable();

  constructor(
    @Inject('entityId') private scheduleId,
    private data: DataService,
    private notification: NotificationService,
    private navigationService: NavigationService,
  ) {}

  /**
   * Обновление данных вкладки.
   */
  public reload() {
    this.reloadSubject.next();
  }

  public load() {
    this.cardIsLoading$.next(true);
    this.data
      .collection('Schedules')
      .entity(this.scheduleId)
      .get<Schedule>({
        expand: [
          { patternDays: { orderBy: 'dayNumber' } },
          { scheduleException: { select: ['name', 'id'] } },
        ],
      })
      .subscribe({
        next: (schedule) => {
          this.cardIsLoading$.next(false);
          this.scheduleSubject.next(schedule);

          this.navigationService.addRouteSegment({
            id: schedule.id,
            title: schedule.name,
          });
        },
        error: (error: any) => {
          this.cardIsLoading$.next(false);
          this.notification.error(error.message);
        },
      });
  }
}
