<form class="modal-form" novalidate [formGroup]="form" ngDraggable autocomplete="off">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ getHeader() | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    @for (item of rateMatrixService.rateMatrixStructure; track item; let index = $index) {
      @if (form.controls[item].enabled) {
        <div class="form-group" tmtIndicator>
          <label>{{ 'shared.rateMatrices.' + item | translate }}</label>
          <wp-select-box
            [query]="queryAnalytics[item] ?? null"
            [formControlName]="item"
            [collection]="rateMatrixStructureCollection[item]"
            placeholder="{{ 'shared.rateMatrices.' + item | translate }}"
          />
        </div>
      }
    }

    <div class="form-group">
      <label>{{ 'shared.rateMatrices.rate' | translate }}</label>
      <wp-currency-box [isCurrencyEditable]="false" formControlName="rate" />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ getActionBtnName() | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
