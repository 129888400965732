<tmt-form-header [name]="(service.user$ | async)?.name"></tmt-form-header>

<tmt-not-found [state]="service.state$ | async"></tmt-not-found>
<wp-loading-indicator [state]="service.state$ | async"></wp-loading-indicator>

<ng-container *ngIf="(service.state$ | async) === 'Ready'">
  <ul class="nav nav-tabs mt-3">
    <li class="nav-item" *ngFor="let tab of tabs">
      <a
        class="nav-link"
        [ngClass]="{ active: state?.current?.name === tab.state }"
        [uiSref]="tab.state"
        [uiParams]="{ routeMode: 'keep' }"
        >{{ tab.header | translate }}</a
      >
    </li>
  </ul>
  <ui-view></ui-view>
</ng-container>
