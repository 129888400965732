import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { BehaviorSubject, fromEvent, merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { KeyType } from 'src/app/shared/components/features/grid/grid-options.model';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import _ from 'lodash';
import { ListService } from 'src/app/shared/services/list.service';
import {
  SelectionCoordinates,
  CellSwitchSetting,
} from 'src/app/shared/components/features/grid/grid-cells-orchestrator.interface';
import { TextToControlValueParserService } from 'src/app/shared/components/features/grid/core/text-to-control-value-parser.service';
import { ColumnDefaultValueService } from 'src/app/shared/components/features/grid/core/column-default-value.service';

/** Service for collective management of cells. */
@Injectable()
export class GridCellsOrchestratorService implements OnDestroy {
  public tableMainElement: HTMLElement;

  /** Separate cell editing by double click mode. */
  public dblClickEdit: boolean;

  /** Displayed data. */
  public formArray: UntypedFormArray;

  /** Based columns list. */
  public baseColumns: GridColumn[];

  /** Active control. */
  private get _activeControlColumnIndex(): number {
    return this.activeControl
      ? this.columns.findIndex(
          (col) =>
            this.activeControl.parent.controls[col.name] === this.activeControl,
        )
      : null;
  }
  private get _activeControlRowIndex(): number {
    return this.activeControl
      ? this.formArray.controls.findIndex(
          (c) => c === this.activeControl.parent,
        )
      : null;
  }
  private activeControl: AbstractControl | null = null;
  private activeControlSubject = new BehaviorSubject<AbstractControl | null>(
    null,
  );
  public activeControl$ = this.activeControlSubject.asObservable();

  /** Nodal selected control. */
  private nodalSelectedControl: AbstractControl | null = null;
  private nodalSelectedControlSubject =
    new BehaviorSubject<AbstractControl | null>(null);
  public nodalSelectedControl$ =
    this.nodalSelectedControlSubject.asObservable();

  /** Editing control. */
  private editingControl: AbstractControl | null = null;
  private editingControlSubject = new BehaviorSubject<AbstractControl | null>(
    null,
  );
  public editingControl$ = this.editingControlSubject.asObservable();

  /** Switch cell subject. */
  private switchCellSubject = new Subject<CellSwitchSetting>();
  public switchCell$ = this.switchCellSubject.asObservable();

  /** Grid view rows. */
  private selectionCoordinates: SelectionCoordinates | null;

  /** Initial value of initializing editing control. */
  private initialControlValue: unknown | undefined = undefined;

  /** Grid view rows. */
  private get formGroups(): UntypedFormGroup[] {
    return this.formArray.controls as UntypedFormGroup[];
  }

  /**Grid view columns. */
  private get columns(): GridColumn[] {
    return this.listService
      ? this.listService.getGridView().columns
      : this.baseColumns;
  }

  private destroyed$ = new Subject<void>();
  private stopSubscriptionSubject = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private gridService: GridService,
    private textParser: TextToControlValueParserService,
    @Optional() private listService: ListService,
    private columnDefaultValueService: ColumnDefaultValueService,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Set active control. */
  public setActiveControl(control: AbstractControl | null): void {
    this.activeControlSubject.next(control);
    this.activeControl = control;
    this.stopSubscriptionSubject.next();
    if (this.activeControl) {
      this.subscribeKeyboardKeys();
    }
    this.setNodalSelectedControl(control);
  }

  /** Returns active control. */
  public getActiveControl(): AbstractControl | null {
    return this.activeControl;
  }

  /** Set nodal selected control. */
  public setNodalSelectedControl(nodalControl: AbstractControl | null): void {
    this.nodalSelectedControlSubject.next(nodalControl);
    this.nodalSelectedControl = nodalControl;
    this.updateSelectionCoordinates();
  }

  /** Returns nodal selected control. */
  public getNodalSelectedControl(): AbstractControl | null {
    return this.nodalSelectedControl;
  }

  /** Set editing control.
   *
   * @param control editing control
   * @param initialValue initial value for writing into control after it rendering
   */
  public setEditingControl(
    control: AbstractControl | null,
    initialValue?: unknown,
  ): void {
    if (control) {
      this.setNodalSelectedControl(this.activeControl);
    }
    if (!this.gridService.readonly) {
      this.initialControlValue = initialValue;
      this.editingControlSubject.next(control);
      this.editingControl = control;
    }
  }

  /** Returns editing control. */
  public getEditingControl(): AbstractControl | null {
    return this.editingControl;
  }

  /** Returns initial control value for editing control. Uses for set some user value by keyboard key. */
  public getInitialControlValue(): unknown | undefined {
    return this.initialControlValue;
  }

  /** Returns grid cell classes.
   *
   * @param formGroup cell formGroup
   * @param column cell Column
   * @param rowIndex cell row index
   * @param colIndex cell column index
   * @returns list of the classes
   */
  public getGridCellClasses(
    formGroup: UntypedFormGroup,
    column: GridColumn,
    rowIndex: number,
    colIndex: number,
  ): string[] {
    if (!this.dblClickEdit) {
      return;
    }
    const result = ['disable-selecting'];

    if (this.activeControl === formGroup.controls[column.name]) {
      result.push('active-cell');
    }

    if (
      this.selectionCoordinates &&
      colIndex >= this.selectionCoordinates.colStart &&
      colIndex <= this.selectionCoordinates.colEnd &&
      rowIndex >= this.selectionCoordinates.rowStart &&
      rowIndex <= this.selectionCoordinates.rowEnd
    ) {
      result.push('selected-cell');
    }
    if (
      rowIndex === this.selectionCoordinates?.rowStart &&
      colIndex >= this.selectionCoordinates?.colStart &&
      colIndex <= this.selectionCoordinates?.colEnd
    ) {
      result.push('selected-border_top');
    }
    if (
      rowIndex === this.selectionCoordinates?.rowEnd &&
      colIndex >= this.selectionCoordinates?.colStart &&
      colIndex <= this.selectionCoordinates?.colEnd
    ) {
      result.push('selected-border_bottom');
    }
    if (
      colIndex === this.selectionCoordinates?.colStart &&
      rowIndex >= this.selectionCoordinates?.rowStart &&
      rowIndex <= this.selectionCoordinates?.rowEnd
    ) {
      result.push('selected-border_left');
    }
    if (
      colIndex === this.selectionCoordinates?.colEnd &&
      rowIndex >= this.selectionCoordinates?.rowStart &&
      rowIndex <= this.selectionCoordinates?.rowEnd
    ) {
      result.push('selected-border_right');
    }

    return result;
  }

  /**
   * Change of current selection if possible.
   *
   * @param formGroup Data.
   * @param event Event.
   * @param column Grid column.
   */
  public onCellMouseEnter(
    formGroup: UntypedFormGroup,
    event: any,
    column: GridColumn,
  ): void {
    //event.buttons === 1 is equivalent of pressed left mouse button
    if (!this.dblClickEdit || event.buttons !== 1 || this.getEditingControl()) {
      return;
    }

    const control = column ? formGroup.controls[column.name] : undefined;
    this.setNodalSelectedControl(control);
    this.gridService.detectChanges();
  }

  /** Updates selection coordinates by current selected and active cells. */
  public updateSelectionCoordinates(): void {
    if (this.nodalSelectedControl && this.activeControl) {
      const nodalSelectedControlColumnIndex = this.columns.findIndex(
        (col) =>
          this.nodalSelectedControl.parent.controls[col.name] ===
          this.nodalSelectedControl,
      );
      const activeControlColumnIndex = this.activeControl
        ? this.columns.findIndex(
            (col) =>
              this.activeControl.parent.controls[col.name] ===
              this.activeControl,
          )
        : null;
      const nodalSelectedControlRowIndex = this.formArray.controls.findIndex(
        (c) => c === this.nodalSelectedControl.parent,
      );
      const activeControlRowIndex = this._activeControlRowIndex;
      this.selectionCoordinates = {
        rowStart: Math.min(nodalSelectedControlRowIndex, activeControlRowIndex),
        rowEnd: Math.max(nodalSelectedControlRowIndex, activeControlRowIndex),
        colStart: Math.min(
          nodalSelectedControlColumnIndex,
          activeControlColumnIndex,
        ),
        colEnd: Math.max(
          nodalSelectedControlColumnIndex,
          activeControlColumnIndex,
        ),
      };
    } else {
      this.selectionCoordinates = null;
    }
  }

  /** Subscribe keyup event */
  private subscribeKeyboardKeys(): void {
    fromEvent(this.document, 'keyup')
      .pipe(
        // Listen of keyboard keys just if some of table children element has focus.
        filter(() => this.tableMainElement.matches(':focus-within')),
        takeUntil(merge(this.destroyed$, this.stopSubscriptionSubject)),
      )
      .subscribe((event: KeyboardEvent) => {
        if (!this.editingControl) {
          // if pressed some action button
          switch (event.code) {
            case 'Enter':
            case 'NumpadEnter':
              if (this.activeControl.enabled) {
                this.setEditingControl(this.activeControl);
                this.gridService.detectChanges();
              }
              break;
            //TODO: needs modify of writeValue methods of using controls
            case 'Delete': {
              const emptyMatrix = this.getEmptyDataMatrix();
              this.insertDataMatrixToTable(emptyMatrix);
              break;
            }
          }
          return;
        }

        // If editing control exist.
        switch (event.code) {
          case 'Escape':
            this.setEditingControl(null);
            this.gridService.detectChanges();
            break;
          case 'Enter':
          case 'NumpadEnter':
            this.setEditingControl(null);
            this.gridService.detectChanges();
            break;
        }
      });

    fromEvent(this.document, 'keydown')
      .pipe(
        // Listen of keyboard keys just if some of table children element has focus.
        filter(() => this.tableMainElement.matches(':focus-within')),
        takeUntil(merge(this.destroyed$, this.stopSubscriptionSubject)),
      )
      .subscribe((event: KeyboardEvent) => {
        if (!this.editingControl) {
          event.preventDefault();
          const keyType = this.getKeyType(event.code);
          // If pressed some symbol or digit
          if (
            keyType !== KeyType.action &&
            this.activeControl.enabled &&
            !(event.ctrlKey || event.metaKey)
          ) {
            this.setEditingControl(this.activeControl, event.key);
            this.gridService.detectChanges();
            return;
          }
          // if pressed some action button
          switch (event.code) {
            case 'ArrowLeft':
              if (event.shiftKey) {
                if (event.ctrlKey || event.metaKey) {
                  this.switchCell({
                    cellType: 'nodalSelected',
                    direction: 'left',
                    toEnd: true,
                  });
                  break;
                }
                this.switchCell({
                  cellType: 'nodalSelected',
                  direction: 'left',
                });
                break;
              }
              if (event.ctrlKey || event.metaKey) {
                this.switchCell({
                  cellType: 'active',
                  direction: 'left',
                  toEnd: true,
                });
                break;
              }
              this.switchCell({ cellType: 'active', direction: 'left' });
              break;
            case 'ArrowRight':
            case 'Tab':
              if (event.shiftKey) {
                if (event.ctrlKey || event.metaKey) {
                  this.switchCell({
                    cellType: 'nodalSelected',
                    direction: 'right',
                    toEnd: true,
                  });
                  break;
                }
                this.switchCell({
                  cellType: 'nodalSelected',
                  direction: 'right',
                });
                break;
              }
              if (event.ctrlKey || event.metaKey) {
                this.switchCell({
                  cellType: 'active',
                  direction: 'right',
                  toEnd: true,
                });
                break;
              }
              this.switchCell({ cellType: 'active', direction: 'right' });
              break;
            case 'ArrowUp':
              if (event.shiftKey) {
                if (event.ctrlKey || event.metaKey) {
                  this.switchCell({
                    cellType: 'nodalSelected',
                    direction: 'up',
                    toEnd: true,
                  });
                  break;
                }
                this.switchCell({
                  cellType: 'nodalSelected',
                  direction: 'up',
                });
                break;
              }
              if (event.ctrlKey || event.metaKey) {
                this.switchCell({
                  cellType: 'active',
                  direction: 'up',
                  toEnd: true,
                });
                break;
              }
              this.switchCell({ cellType: 'active', direction: 'up' });
              break;
            case 'ArrowDown':
              if (event.shiftKey) {
                if (event.ctrlKey || event.metaKey) {
                  this.switchCell({
                    cellType: 'nodalSelected',
                    direction: 'down',
                    toEnd: true,
                  });
                  break;
                }
                this.switchCell({
                  cellType: 'nodalSelected',
                  direction: 'down',
                });
                break;
              }
              if (event.ctrlKey || event.metaKey) {
                this.switchCell({
                  cellType: 'active',
                  direction: 'down',
                  toEnd: true,
                });
                break;
              }
              this.switchCell({ cellType: 'active', direction: 'down' });
              break;
            case 'Space':
              // Stop default table scrolling
              event.preventDefault();
              break;
            case 'KeyC':
              if ((event.ctrlKey || event.metaKey) && !event.repeat) {
                this.copySelectedRange();
              }
              break;
            case 'KeyV':
              if ((event.ctrlKey || event.metaKey) && !event.repeat) {
                this.pasteDataFromClipboard();
              }
          }
          return;
        }
      });
  }

  /** Return key type by it code (action by default). */
  private getKeyType(eventCode: string): KeyType {
    if (eventCode.substring(0, 3) === 'Key') {
      return KeyType.symbol;
    }

    if (eventCode.substring(0, 5) === 'Digit') {
      return KeyType.digit;
    }

    // Numpad keys check
    if (eventCode.substring(0, 6) === 'Numpad') {
      if (isFinite(+eventCode.substring(6, 7))) {
        return KeyType.digit;
      }
      if (eventCode === 'NumpadEnter') {
        return KeyType.action;
      } else {
        return KeyType.symbol;
      }
    }

    switch (eventCode) {
      case 'Equal':
      case 'Subtract':
      case 'Divide':
      case 'Multiply':
      case 'Slash':
      case 'Backslash':
      case 'Add':
      case 'Minus':
      case 'Backquote':
      case 'Quote':
      case 'Semicolon':
      case 'Period':
      case 'Comma':
      case 'BracketRight':
      case 'BracketLeft':
      case 'Space':
        return KeyType.symbol;
    }

    return KeyType.action;
  }

  /** Switch cell.
   *
   * @param switchSetting direction of switching
   * @param toEnd is switch to direction end
   */
  private switchCell(switchSetting: CellSwitchSetting): void {
    if (!this.editingControl) {
      this.switchCellSubject.next(switchSetting);
    }
  }

  /** Copies selected cells to clipboard. */
  private copySelectedRange(): void {
    let data = '';
    for (
      let y = this.selectionCoordinates.rowStart;
      y <= this.selectionCoordinates.rowEnd;
      y++
    ) {
      for (
        let x = this.selectionCoordinates.colStart;
        x <= this.selectionCoordinates.colEnd;
        x++
      ) {
        const cellValue =
          this.formGroups[y].controls[this.columns[x].name].getRawValue();
        if (cellValue === false) {
          data += 'false';
        } else {
          data +=
            cellValue && typeof cellValue !== 'object'
              ? cellValue.toString()
              : '';
        }

        if (x !== this.selectionCoordinates.colEnd) {
          data += '\t';
        }
      }
      if (y !== this.selectionCoordinates.rowEnd) {
        data += '\r';
      }
    }
    navigator.clipboard?.writeText(data);
  }

  /** Pastes data from clipboard. */
  private pasteDataFromClipboard(): void {
    navigator.clipboard?.readText().then((data) => {
      data = data.replace(/(\r\n|\n)/gm, '\r');
      data = data.replace(/\r$/gm, '');
      const dataMatrix = data.split('\r').map((row) => row.split('\t'));

      let newNodalSelectedCellRowIndex = dataMatrix.length
        ? this._activeControlRowIndex + dataMatrix.length - 1
        : this._activeControlRowIndex;
      if (newNodalSelectedCellRowIndex >= this.formArray.length) {
        newNodalSelectedCellRowIndex = this.formArray.length - 1;
      }

      const maxInputRowLength = _.max(
        dataMatrix.map((row) => (Array.isArray(row) ? row.length : 1)),
      );
      let newNodalSelectedCellColumnIndex =
        this._activeControlColumnIndex + maxInputRowLength - 1;
      if (newNodalSelectedCellColumnIndex >= this.columns.length) {
        newNodalSelectedCellColumnIndex = this.columns.length - 1;
      }

      const newNodalSelectedControl =
        this.formGroups[newNodalSelectedCellRowIndex].controls[
          this.columns[newNodalSelectedCellColumnIndex].name
        ];
      this.setNodalSelectedControl(newNodalSelectedControl);
      this.gridService.detectChanges();

      this.insertDataMatrixToTable(dataMatrix);
    });
  }

  /**Insert values in the selected range.
   *
   * @param dataMatrix matrix of the values
   */
  private insertDataMatrixToTable(dataMatrix: string[][] | null[][]): void {
    for (
      let y = this.selectionCoordinates.rowStart;
      y <= this.selectionCoordinates.rowEnd;
      y++
    ) {
      for (
        let x = this.selectionCoordinates.colStart;
        x <= this.selectionCoordinates.colEnd;
        x++
      ) {
        const control = this.formGroups[y].controls[this.columns[x].name];
        if (control.enabled) {
          const matrixValue =
            dataMatrix[y - this.selectionCoordinates.rowStart][
              x - this.selectionCoordinates.colStart
            ];
          const contentType =
            this.columns[x].contentType ?? this.columns[x].type;
          const allowNull =
            (this.columns[x] as any).allowNull === false ? false : true;
          let valueForInserting: any;
          if (matrixValue === null && !allowNull) {
            continue;
          }
          if (matrixValue === null) {
            valueForInserting = this.columns[x].getDefaultValue
              ? this.columns[x].getDefaultValue()
              : this.columnDefaultValueService.getDefaultValue(contentType);
          } else {
            valueForInserting = this.textParser.parse(matrixValue, contentType);
          }
          if (
            valueForInserting !== undefined &&
            valueForInserting !== control.value
          ) {
            control.setValue(valueForInserting);
          }
        }
      }
    }
  }

  /** Updates selection coordinates by current selected and active cells. */

  private getEmptyDataMatrix() {
    if (!this.selectionCoordinates) {
      return;
    }
    const colLength =
      this.selectionCoordinates.colEnd - this.selectionCoordinates.colStart + 1;
    const rowLength =
      this.selectionCoordinates.rowEnd - this.selectionCoordinates.rowStart + 1;
    const emptyRow: null[] = new Array(colLength).fill(null);
    const emptyMatrix: null[][] = new Array(rowLength).fill(emptyRow);
    return emptyMatrix;
  }
}
