@for (selectedKpiType of selectedKpiTypes; track selectedKpiType) {
  <tr class="resource-summary__right resource__row-total">
    @for (total of getTotalsByKpiType(selectedKpiType); track total.id) {
      <ng-container>
        <td
          class="resource__row-total-cell"
          [ngClass]="{ 'non-working': total.nonWorking }"
          [style.width]="summaryService.slotWidth + 'px'"
          [ngStyle]="getSummaryTotalIndicatorStyle(total)"
        >
          {{ total.hours | resourceSummaryTotal: summaryService.settings.valueMode }}
        </td>
      </ng-container>
    }
  </tr>
}

<ng-container *ngIf="isExpanded">
  @for (project of summaryPageDto.lines; track project.id) {
    <ng-container>
      @for (selectedKpiType of selectedKpiTypesWithoutSchedule; track selectedKpiType) {
        <tr class="resource-summary__right project__row">
          <td [attr.colspan]="summaryService.slots.length">
            <table
              class="wp-nested-table"
              [ngStyle]="{ width: summaryService.getDataTableWidth() + 'px' }"
            >
              <tbody>
                <tr>
                  @for (slot of summaryService.slots; track slot.id) {
                    <td [ngClass]="{ 'non-working': isWeekend(slot) }">
                      @for (
                        entry of getSummaryEntriesByProjectId(project.id, selectedKpiType, slot);
                        track trackByEntry
                      ) {
                        <ng-container>
                          {{ entry | resourceSummaryEntry: summaryService.settings.valueMode }}
                        </ng-container>
                      }
                    </td>
                  }
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      }
    </ng-container>
  }

  <tr
    *ngIf="summaryPageDto.lines.length && summaryPageDto.hasTimeOff && isActualKpiTypeSelected"
    class="resource-summary__right project__row"
  >
    <td [attr.colspan]="summaryService.slots.length">
      <table
        class="wp-nested-table"
        [ngStyle]="{ width: summaryService.getDataTableWidth() + 'px' }"
      >
        <tbody>
          <tr>
            @for (slot of summaryService.slots; track slot.id) {
              <td [ngClass]="{ 'non-working': isWeekend(slot) }">
                @for (
                  entry of getSummaryEntriesByProjectId(null, kpiType.Actual, slot);
                  track trackByEntry
                ) {
                  <ng-container>
                    {{ entry | resourceSummaryEntry: summaryService.settings.valueMode }}
                  </ng-container>
                }
              </td>
            }
          </tr>
        </tbody>
      </table>
    </td>
  </tr>

  <tr *ngIf="isProjectLinesEmpty" class="resource-summary__right no-data__row">
    <td [attr.colspan]="summaryService.slots.length" class="text-center no-data__row-cell"></td>
  </tr>
</ng-container>
