export enum ResourceType {
  user = 'User',
  department = 'Department',
  generic = 'Generic',
}

export const defaultResourceTypes = [
  ResourceType.user,
  ResourceType.department,
  ResourceType.generic,
];
