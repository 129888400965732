import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioGroupDirective } from './radio-group.directive';
import { RadioButtonDirective } from './radio-button.directive';
import { RadioLabelDirective } from './radio-label.directive';

@NgModule({
  declarations: [
    RadioGroupDirective,
    RadioButtonDirective,
    RadioLabelDirective,
  ],
  imports: [CommonModule],
  exports: [RadioGroupDirective, RadioButtonDirective, RadioLabelDirective],
})
export class RadioGroupModule {}
