import { DOCUMENT } from '@angular/common';
import { Injectable, Inject, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { Command } from 'src/app/shared/components/features/grid/grid-options.model';

/** Service for manage of keyboard custom actions in the grid. */
@Injectable()
export class CustomActionsService implements OnDestroy {
  private customActions = new Map<string, string>();

  private blockKeyboardListener: boolean;
  private destroyed$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private gridService: GridService,
    private modal: NgbModal,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Add custom action.
   *
   * @param keyCode keyboard key code
   * @param commandName command name
   */
  private addCustomAction(keyCode, commandName): void {
    this.customActions.set(keyCode, commandName);
  }

  /** Execute grid command if possible.
   *
   * @param keyCode keyboard key code for command executing.
   * @param param parameter for execution.
   */
  private handleCustomAction(keyCode, param?: any): void {
    const commandName = this.customActions.get(keyCode);
    if (!commandName) {
      return;
    }
    if (!this.gridService.canBeExecuted(commandName, param)) {
      return;
    }
    this.gridService.execute(commandName, param);
  }

  /** Initializes of keyboard handler for supported grid commands.
   *  NOTE: no working in the modal windows.
   *
   * @param commands grid commands.
   */
  public init(commands: Command[]): void {
    commands.forEach((command) => {
      if (command.keyCode) {
        this.addCustomAction(command.keyCode, command.name);
      }
    });

    //TODO: Think how block listener with other way.
    this.modal.activeInstances
      .pipe(takeUntil(this.destroyed$))
      .subscribe((modalInstances) => {
        this.blockKeyboardListener = !!modalInstances.length;
      });

    fromEvent(this.document, 'keyup')
      .pipe(
        filter(() => !this.blockKeyboardListener),
        takeUntil(this.destroyed$),
      )
      .subscribe((event: KeyboardEvent) => {
        this.handleCustomAction(event.code, this.gridService.selectedRow?.id);
      });
  }
}
