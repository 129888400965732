import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BookingEntryFormService {
  private updatePreviewSubject = new Subject<void>();
  public updatePreview$ = this.updatePreviewSubject.asObservable();

  /** Обновить превью. */
  public updatePreview() {
    this.updatePreviewSubject.next();
  }
}
