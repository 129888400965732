import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class UserInfoSettings implements BaseSettings {
  public readonly name = 'UserInfoSettings';
  version: number;

  collapsed: boolean;

  getDefault(): UserInfoSettings {
    return {
      version: 1,
      collapsed: false,
    } as UserInfoSettings;
  }
}
