import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';

export class ResourceSummaryResourceViewSettings implements BaseSettings {
  public readonly name = 'ResourceSummaryResourceViewSettings';
  public version: number;
  /** Show KPI flag map. */
  public show: Record<KpiType, boolean>;
  /** Show Overtime flag. */
  public overtime: boolean;

  public getDefault(): ResourceSummaryResourceViewSettings {
    return {
      version: 1,
      show: { [KpiType.Plan]: true },
      overtime: false,
    } as ResourceSummaryResourceViewSettings;
  }
}
