<div class="grid-width">
  <wp-loading-indicator [loading]="isLoading()" />
</div>
<form class="form" [hidden]="isLoading()" [formGroup]="lifecycleCardService.kindForm">
  <tmt-grid
    class="d-block grid-width"
    [formArray]="lifecycleCardService.states"
    [options]="gridOptions"
    [readonly]="readonly"
  />
  @if (lifecycleCardService.states.length) {
    <ui-view />
  }
</form>
