import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DefaultFilterService } from '../../shared/default-filter/default-filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { StateService } from '@uirouter/core';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { LocationCreationComponent } from 'src/app/settings-app/locations/creation/location-creation.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'wp-location-list',
  template: '<wp-entity-list></wp-entity-list> ',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
})
export class LocationListComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private service: EntityListService,
    private gridService: GridService,
    private state: StateService,
    private app: AppService,
    private actions: ActionPanelService,
    private modalService: NgbModal,
    private notification: NotificationService,
    private data: DataService,
  ) {}

  public ngOnInit(): void {
    this.actions.set([
      this.actions.getDefaultAction('create', {
        title: 'settings.locations.list.create',
        isVisible: this.app.checkEntityPermission(
          'Location',
          PermissionType.Modify,
        ),
        handler: this.create,
      }),
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go('settings.location', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actions.getDefaultAction('delete', {
        handler: this.service.delete,
      }),
      this.actions.getDefaultAction('setAsDefault', {
        handler: this.setAsDefault,
      }),
    ]);

    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((row: any) => {
        this.actions.action('card').isShown = !!row;
        this.actions.action('delete').isShown =
          row &&
          this.app.checkEntityPermission('Location', PermissionType.Modify);
        this.actions.action('setAsDefault').isShown =
          row &&
          this.app.checkEntityPermission('Location', PermissionType.Modify) &&
          !row.isDefault;
      });
  }

  private create = (): void => {
    this.modalService.open(LocationCreationComponent);
  };

  private setAsDefault = (): void => {
    this.data
      .collection('Locations')
      .entity(this.gridService.selectedGroupValue.id)
      .action('SetAsDefault')
      .execute()
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.locations.card.setAsDefault',
          );
          this.service.reload();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  };
}
