import { Component } from '@angular/core';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { Toolbar } from 'src/app/shared/components/features/grid/toolbar';

@Component({
  selector: 'wp-preferred-resources-toolbar',
  templateUrl: './preferred-resources-toolbar.component.html',
})
export class PreferredResourcesToolbarComponent extends Toolbar {
  constructor(public service: GridService) {
    super();
  }
}
