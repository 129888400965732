import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Injector,
  DestroyRef,
  inject,
} from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';

import {
  Import,
  ImportState,
  ImportStates,
} from 'src/app/shared/models/entities/settings/import.model';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { ImportEntityList } from 'src/app/shared/lists/import-entities.list';
import { ListService } from 'src/app/shared/services/list.service';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { ImportFilterService } from './import-filter.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { saveAs } from 'file-saver';
import { AppConfigService } from 'src/app/core/app-config.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'src/app/core/message.service';
import { BehaviorSubject, concatMap, interval, Observable } from 'rxjs';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'wp-import-card',
  templateUrl: './import-card.component.html',
  providers: [
    { provide: VIEW_NAME, useValue: 'default' },
    { provide: LIST, useValue: ImportEntityList },
    { provide: FilterService, useClass: ImportFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
})
export class ImportCardComponent implements OnInit, OnDestroy {
  @Input() private entityId: string;

  public importEntity: Import;
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  private observableTimer: Observable<number>;

  private destroyRef = inject(DestroyRef);

  /** Класс индикатора статуса. */
  public get labelClass(): string {
    return ImportStates.find((as) => as.code === this.importEntity?.state.code)
      ?.class;
  }

  constructor(
    private message: MessageService,
    private httpClient: HttpClient,
    private data: DataService,
    private blockUI: BlockUIService,
    private notification: NotificationService,
    private entityListService: EntityListService,
    private actions: ActionPanelService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
  ) {
    this.observableTimer = interval(1500);
  }

  public ngOnInit(): void {
    this.entityListService.contextFilter = [
      {
        importId: { type: 'guid', value: this.entityId },
      },
    ];

    this.actions.set([
      {
        title: 'settings.imports.card.actions.downloadLog',
        hint: 'settings.imports.card.actions.downloadLog',
        name: 'loadLog',
        isDropDown: false,
        iconClass: 'bi bi-download',
        isBusy: false,
        isVisible: false,
        handler: this.loadImportLogs,
      },
    ]);

    this.reload();
  }

  public ngOnDestroy(): void {
    this.entityListService.dispose();
  }

  /** Opens popUp with user info. */
  public openUserInfo(): void {
    const userId = this.importEntity?.createdBy.id;
    const target = document.getElementById('author');
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /** Reloads data. */
  public reload(): void {
    this.state$.next(CardState.Loading);
    this.data
      .collection('Imports')
      .entity(this.entityId)
      .get<Import>({
        expand: {
          state: { select: ['id', 'code', 'name'] },
          createdBy: { select: ['id', 'name'] },
        },
      })
      .subscribe({
        next: (importEntity: Import) => {
          this.importEntity = importEntity;
          this.state$.next(CardState.Ready);

          if (importEntity.state.id === ImportState.importing.id) {
            this.subscribeToStateUpdating();
          } else {
            this.actions.action('loadLog').show();
            this.entityListService.reload();
          }
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          this.notification.error(error.message);
        },
      });
  }

  private subscribeToStateUpdating(): void {
    const observableImport = this.observableTimer
      .pipe(
        concatMap(() =>
          this.data.collection('Imports').entity(this.entityId).get<Import>({
            select: 'stateId',
          }),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (importObj: any) => {
          if (importObj.stateId === ImportState.imported.id) {
            observableImport.unsubscribe();
            this.reload();
          }
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          this.notification.error(error.message);
        },
      });
  }

  private loadImportLogs = (): void => {
    const url = `${AppConfigService.config.api.url}/Imports/DownloadImportLog?importId=${this.entityId}`;
    this.blockUI.start();

    this.httpClient.get(url, { responseType: 'blob' }).subscribe({
      next: (data) => {
        saveAs(data, 'Report Log.xlsx');
        this.blockUI.stop();
      },
      error: async (error) => {
        const isPromise = !!error && typeof error.then === 'function';

        if (isPromise) {
          const errorText = await error;
          const errorObj = JSON.parse(errorText);
          this.message.error(errorObj.error.message);
          this.blockUI.stop();
        } else {
          this.message.error(error.message);
          this.blockUI.stop();
        }
      },
    });

    this.blockUI.stop();
  };
}
