import { GridColumnType } from 'src/app/shared-features/grid2/models/grid-column.interface';

/** Helper for parsing text value before writing it in the grid cell control. */
export class TextToControlValueParserHelper {
  /**
   * Parses string value to some types by content type.
   *
   * @param text string for parsing.
   * @param type requested content type.
   * @returns some type depends from input type.
   */
  public static parse(text: string, type: GridColumnType): any {
    switch (type) {
      case GridColumnType.String:
      case GridColumnType.StringControl:
        return text;
      case GridColumnType.Integer:
      case GridColumnType.Decimal:
      case GridColumnType.Percent:
      case GridColumnType.NumberControl:
      case GridColumnType.Currency:
      case GridColumnType.CurrencyControl:
        return this.parseToNumber(text, type);
      case GridColumnType.Boolean:
      case GridColumnType.BooleanControl:
        return this.parseToBoolean(text);
      case GridColumnType.Date:
      case GridColumnType.DateTime:
      case GridColumnType.DateControl:
        return this.parseToDate(text);
      case GridColumnType.SelectControl:
        return this.isValidJSONStructure(text, ['id', 'name'])
          ? JSON.parse(text)
          : undefined;
      default:
        if (this.isValidJSONStructure(text)) return JSON.parse(text);
    }
  }

  /**
   * Validates if the given text is a valid JSON structure and optionally checks for specific keys and array structure.
   *
   * @param text The JSON string to validate.
   * @param keys Optional array of strings representing keys that should be present in the JSON object.
   * @param expectArray Optional boolean indicating whether the JSON should parse to an array.
   * @returns A boolean indicating if the JSON is valid and meets the specified conditions.
   */
  public static isValidJSONStructure(
    text: string,
    keys?: string[],
    expectArray?: boolean,
  ): boolean {
    try {
      const result = JSON.parse(text);

      // Check if the result is an object
      const isObject = result && typeof result === 'object' && result !== null;
      if (!isObject) return false;

      // If expecting an array, check if result is an array
      if (expectArray && !Array.isArray(result)) return false;

      // Function to check if all specified keys exist in an object
      const checkKeys = (checkingObject) => {
        for (const key of keys) {
          if (!(key in checkingObject)) return false;
        }
        return true;
      };

      // If keys are specified, check for keys in the object or each object in the array
      if (keys?.length) {
        if (expectArray) {
          return result.every((element) => checkKeys(element));
        } else {
          return checkKeys(result);
        }
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Parses string to number.
   *
   * @param text text to parsing.
   * @param type target type. Used for rounding integer numbers.
   * @returns number, null if text is empty string and undefined if string can not parsing.
   */
  private static parseToNumber(
    text: string,
    type: GridColumnType,
  ): number | null | void {
    if (text === '') {
      return null;
    }
    const number = +text.replace(',', '.').replace(/\s/g, '');
    if (isNaN(number)) {
      return;
    }
    if (type === GridColumnType.Integer) {
      return Math.round(number);
    }
    return number;
  }

  private static parseToBoolean(text: string): boolean | undefined {
    const textWithoutSpaces = text.replace(/\s/g, '');
    if (textWithoutSpaces.toLowerCase() === 'true') {
      return true;
    }
    if (textWithoutSpaces.toLowerCase() === 'false') {
      return false;
    }
    return;
  }

  /**
   * Parses string to ISO date.
   *
   * @param text text to parsing.
   * @returns date string in ISO format and undefined if string can not parsing.
   */
  private static parseToDate(text: string): string | undefined {
    const textWithLineSeparators = text.replace(/[^-\d]/g, '-');
    const dateSegments = textWithLineSeparators.split('-');
    if (dateSegments.length !== 3 || dateSegments.some((el) => isNaN(+el))) {
      return;
    }
    // set year as first
    if (
      dateSegments[2].length === 4 ||
      (dateSegments[0].length <= 2 && dateSegments[2].length === 2)
    ) {
      dateSegments.reverse();
    }
    // check year
    if (dateSegments[0].length !== 2 && dateSegments[0].length !== 4) {
      return;
    }
    if (dateSegments[0].length === 2) {
      dateSegments[0] = '20' + dateSegments[0];
    }
    // check month
    if (+dateSegments[1] > 12) {
      return;
    }
    if (dateSegments[1].length === 1) {
      dateSegments[1] = '0' + dateSegments[1];
    }
    // check day
    if (+dateSegments[2] > 31) {
      return;
    }
    if (dateSegments[2].length === 1) {
      dateSegments[2] = '0' + dateSegments[2];
    }

    return dateSegments.join('-');
  }
}
