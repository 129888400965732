import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectBillingType } from 'src/app/shared/models/enums/project-billing-type';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { OverviewKpiService } from 'src/app/shared/components/features/overview/overview-kpi/core/overview-kpi.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tmt-project-overview-kpi',
  templateUrl: './project-overview-kpi.component.html',
})
export class ProjectOverviewKpiComponent implements OnInit, OnDestroy {
  public project: Project;
  public kpiType: KpiType;
  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  public get nonBillable(): boolean {
    return (
      this.project?.billingType.code === ProjectBillingType.nonBillable.code
    );
  }

  public get projectVersion(): ProjectVersion {
    const isWorkVersion = this.versionCardService.isWorkProjectVersion();
    const version = this.versionCardService.projectVersion;
    return !isWorkVersion ? version : null;
  }

  constructor(
    public service: ProjectCardService,
    private versionCardService: ProjectVersionCardService,
    private overviewKpiService: OverviewKpiService,
  ) {}

  private reload() {
    this.overviewKpiService.reload();
  }

  ngOnInit() {
    this.service.reloadTab$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());
    this.service.project$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((project) => {
        this.project = project;
        //TODO:
        // if (this.project.state.code === ProjectState.tentative.code) {
        //   this.kpiType = KpiType.Estimate;
        // } else {
        //   this.kpiType = KpiType.Actual;
        // }
        this.kpiType = KpiType.Actual;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
