import { Inject, Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { MessageService } from 'src/app/core/message.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { NotificationService } from 'src/app/core/notification.service';
import {
  StateBuilderService,
  WorkflowEntityState,
} from 'src/app/core/state-builder.service';
import { Constants } from 'src/app/shared/globals/constants';
import { WorkflowTask } from 'src/app/shared/models/entities/workflow-tasks/workflow-task.model';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

@Injectable()
export class WorkflowTaskCardServiceService {
  public task: WorkflowTask;

  public state$ = new BehaviorSubject<CardState>(null);
  public workflowTask$ = new BehaviorSubject<WorkflowTask>(null);
  public entityState$ = new BehaviorSubject<WorkflowEntityState>(null);

  private destroyed$ = new Subject<void>();

  taskForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: '',
    performer: null,
    performed: null,
    assigned: null,

    resolutionComment: '',
    isActive: [false],
  });

  constructor(
    @Inject('entityId') public workflowTaskId: string,
    private stateBuilder: StateBuilderService,
    private fb: FormBuilder,
    private actionService: ActionPanelService,
    private lifecycleService: LifecycleService,
    private data: DataService,
    private navigation: NavigationService,
    private message: MessageService,
    private notification: NotificationService,
  ) {
    this.actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());

    this.lifecycleService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());
  }

  /** Load data. */
  public load() {
    this.state$.next(CardState.Loading);
    this.taskForm.markAsPristine();
    this.taskForm.markAsUntouched();

    this.actionService.resetLifecycle();

    // Загрузка данных.
    this.data
      .collection('WorkflowTasks')
      .entity(this.workflowTaskId)
      .get<WorkflowTask>({
        expand: {
          resolution: { select: 'name' },
          assigned: { select: ['id', 'name'] },
          performer: { select: ['id', 'name'] },
          entity: { select: ['id', 'name', 'type'] },
        },
      })
      .subscribe({
        next: (workflowTask: WorkflowTask) => {
          this.taskForm.patchValue(workflowTask);
          this.taskForm.disable();

          this.navigation.addRouteSegment({
            id: workflowTask.id,
            title: workflowTask.name,
          });

          this.task = workflowTask;
          this.workflowTask$.next(workflowTask);

          const entityState = this.stateBuilder.getStateForWorkflowEntity(
            this.workflowTaskId,
            workflowTask.entity.type,
            workflowTask.entity.id,
          );
          entityState.params.routeMode = RouteMode.continue;
          this.entityState$.next(entityState);

          this.state$.next(CardState.Ready);
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notification.error(error.message);
          }
        },
      });
  }

  /** Reload page. */
  public reload() {
    if (!this.taskForm.dirty) {
      this.load();
      this.lifecycleService.reloadLifecycle();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  /** Dispose service. */
  public dispose() {
    this.destroyed$.next();
  }
}
