<div class="btn-group" role="group">
  <div
    [class.active]="(routerGlobals.current?.name ?? '').includes('forecastCalendar')"
    uiSref="project.resources.forecastCalendar"
    [uiParams]="{ routeMode: 'keep' }"
    class="btn btn-default"
  >
    {{ 'projects.projects.card.resources.view.forecast' | translate }}
  </div>
  <div
    [class.active]="(routerGlobals.current?.name ?? '').includes('estimateCalendar')"
    uiSref="project.resources.estimateCalendar"
    [uiParams]="{ routeMode: 'keep' }"
    class="btn btn-default"
  >
    {{ 'projects.projects.card.resources.view.estimate' | translate }}
  </div>
</div>
