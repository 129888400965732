<p *ngIf="service?.pivotTable?.hasMaxCount" class="form-text text-danger">
  {{
    'analytics.pivotTable.hasMaxCount' | translate: { limit: service.pivotTable.sourceRecordCount }
  }}
</p>

<p *ngIf="service.isError$ | async" class="form-text text-danger">
  {{ 'analytics.pivotTable.error' | translate }}
</p>

<div
  class="dropdown-menu"
  [attr.id]="menuId"
  [ngStyle]="headerMenuStyles"
  (clickOutside)="closeHeaderMenu()"
  [exclude]="'.bi-list'"
  [excludeBeforeClick]="true"
>
  <ng-container *ngFor="let item of headerMenuItems">
    <div *ngIf="item.isDivider" class="dropdown-divider"></div>
    <button class="dropdown-item" (click)="headerMenuClick(item)">
      <span *ngIf="item.icon"><i class="bi" [ngClass]="item.icon"></i>&nbsp;</span>{{ item.title }}
    </button>
  </ng-container>
</div>

<div
  [hidden]="service.isError$ | async"
  wpFreezeTable
  leftWidthStrategy="byTable"
  (scroll)="onScroll()"
  [rootContainerId]="rootContainerId"
  [inContainerMode]="inContainerMode"
>
  <div name="scroll-table-header" class="disable-selecting">
    <table
      name="header"
      class="table table-bordered table-striped table-sm report-table"
      wpResizing
      [selector]="'th.analytic'"
      (columnResized)="onColumnResized($event)"
    >
      <colgroup></colgroup>
      <thead></thead>
    </table>
  </div>

  <div name="scroll-table-body">
    <table name="body" class="table table-bordered table-striped table-sm report-table">
      <colgroup></colgroup>
      <tbody></tbody>
    </table>
  </div>

  <div name="scroll-table-footer">
    <table name="footer" class="table table-bordered table-striped table-sm report-table">
      <colgroup></colgroup>
      <tfoot></tfoot>
    </table>
  </div>
</div>
