import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'wp-boolean-control-cell',
  templateUrl: './boolean-control-cell.component.html',
  styleUrls: ['./boolean-control-cell.component.scss'],
})
export class BooleanControlCellComponent implements AfterViewInit {
  @Input() control: UntypedFormControl;
  @Input() autofocus?: boolean;

  @ViewChild('input') input;

  ngAfterViewInit(): void {
    if (this.autofocus && this.input) {
      this.input.nativeElement?.focus();
    }
  }

  /** Switch value of control by space symbol. */
  public setUserValue(value: unknown) {
    if (value === ' ') {
      this.control.setValue(!this.control.value);
    }
  }
}
