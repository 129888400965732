import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  LOCALE_ID,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { CellsOrchestratorService } from 'src/app/shared/services/cell-orhestrator/cells-orchestrator.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { AppService } from 'src/app/core/app.service';
import { Subscription } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TimeAllocation } from 'src/app/shared/models/entities/base/timesheet.model';
import { TimeDurationPipe } from 'src/app/shared/pipes/time-duration.pipe';
import { TimeInputType } from 'src/app/shared/models/enums/time-input-type';
import {
  DecimalPipe,
  getLocaleNumberSymbol,
  NumberSymbol,
} from '@angular/common';
import { cloneDeep, merge, round } from 'lodash';
import { AllocationInfoService } from '../../table-view/allocation-info/allocation-info.service';
import { takeUntil } from 'rxjs/operators';
import { StopwatchService } from 'src/app/core/stopwatch.service';
import { StopwatchState } from 'src/app/shared/models/entities/base/stopwatch.model';

/** Ячейка таймшита. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wpTimesheetCell]',
  templateUrl: './timesheet-cell.component.html',
  styleUrls: ['./timesheet-cell.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimesheetCellComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetCellComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('editor') private editor: ElementRef;
  @ViewChild('frame') private frame: ElementRef<HTMLElement>;

  @Input() dayIndex: number;
  @Input() lineIndex: number;
  @Input() dayScheduleDuration: number;
  @Input() lineId: string;

  public allocation: TimeAllocation;
  public viewValue: string;
  private previousViewValue: string;
  private oldValue: any;

  public readonly: boolean;
  public showIcon: boolean;
  public showStart: boolean;
  public showStop: boolean;
  public iconClass: string[];
  private enterCode = 13;
  private escCode = 27;

  public id = Guid.generate();
  public serviceId: string;

  private isDecimalMode: boolean;
  private isSchedulePercentMode: boolean;
  private delimiter: string;

  swExecuted: boolean;

  private get input() {
    if (this.readonly) {
      return null;
    }
    return this.editor.nativeElement as HTMLInputElement;
  }

  public mode = {
    active: false,
    selected: false,
    spreading: false,
    editing: false,
    left: false,
    top: false,
    right: false,
    bottom: false,
    spreader: false,
    spreadBottom: false,
  };
  contentElement: HTMLElement;
  valueElement: HTMLElement;

  eventEntrySelectedSubscription: Subscription;
  eventStartEditingSubscription: Subscription;
  eventStopEditingSubscription: Subscription;
  eventValueChangedSubscription: Subscription;
  eventEntryActivatedSubscription: Subscription;
  eventEntryDeactivatedSubscription: Subscription;
  eventEntryDespreadedSubscription: Subscription;
  eventEntryDeselectedSubscription: Subscription;
  eventEntrySpreadedSubscription: Subscription;
  eventResetSubscription: Subscription;
  allocationChangeSubscription: Subscription;
  stopwatchSubscription: Subscription;
  public mouseEnterListener: () => void;

  public propagateChange = (_: TimeAllocation) => null;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    @Optional() private allocationInfoService: AllocationInfoService,
    public stopwatchService: StopwatchService,
    public entriesOrchestrator: CellsOrchestratorService,
    private numberPipe: DecimalPipe,
    private timeDurationPipe: TimeDurationPipe,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private app: AppService,
    private zone: NgZone,
  ) {
    this.isDecimalMode =
      this.app.session.timeInputType !== TimeInputType.HoursAndMinutes;
    this.isSchedulePercentMode =
      this.app.session.timeInputType === TimeInputType.SchedulePercent;
    this.delimiter = this.isDecimalMode
      ? getLocaleNumberSymbol(locale, NumberSymbol.Decimal)
      : ':';
  }

  writeValue(obj: any): void {
    this.allocation = obj;
    this.entriesOrchestrator.setValue(
      { x: this.dayIndex, y: this.lineIndex },
      this.allocation.duration,
    );
    this.updateIcon();
    this.changeDetector.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched = (fn: any) => null;

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
    this.updateIcon();
    this.changeDetector.detectChanges();
  }

  private changeValue(allocation: TimeAllocation) {
    if (!this.entriesOrchestrator.singleCellMode) {
      // Обновление значения в сервисе, для последующего использования.
      this.entriesOrchestrator.setValue(
        { x: this.dayIndex, y: this.lineIndex },
        allocation.duration,
      );
    }

    this.propagateChange(allocation);
    this.changeDetector.detectChanges();
  }

  private getValueElement() {
    if (!this.valueElement) {
      this.valueElement = (
        this.elRef.nativeElement as HTMLElement
      ).querySelector('.value');
    }
    return this.valueElement;
  }

  private getContentElement() {
    if (!this.contentElement) {
      this.contentElement = (
        this.elRef.nativeElement as HTMLElement
      ).querySelector('.content');
    }
    return this.contentElement;
  }

  // Разбор введенного текста.
  private parseViewDuration() {
    if (this.readonly) {
      return;
    }
    const viewValue = this.input.value;
    let duration;

    if (!viewValue) {
      if (this.allocation.duration !== null) {
        this.allocation.duration = null;
        this.changeValue(this.allocation);
      }
      return;
    }
    // Разобрать ввод в %%.
    if (this.isSchedulePercentMode) {
      if (isNaN(Number(viewValue))) {
        duration = null;
      } else {
        duration = Number(viewValue);
        const maxDuration =
          this.dayScheduleDuration > 0
            ? (24 / this.dayScheduleDuration) * 100
            : 0;
        if (duration > maxDuration) {
          duration = maxDuration;
        }
        duration = (this.dayScheduleDuration * duration) / 100;
      }
      this.allocation.duration = duration;
      this.changeValue(this.allocation);
      return;
    }
    // Разобрать ввод в часах или часах-минутах.
    const parts = viewValue.split(this.delimiter);

    // Если вставили строку и там много разделителей
    if (parts.length > 2) {
      duration = 0;
    } else {
      let wholePart = '';
      let decimalPart = '';

      // Если нет разделителя
      if (parts.length === 1) {
        // Для дробного значения два правых символа - дробная часть, остальное - целая часть
        // Для натурального значения два правых символа - целая часть, остальное - дробная часть
        if (parts[0].length === 0) {
          wholePart = '0';
          decimalPart = '0';
        } else {
          if (parts[0].length <= 2) {
            if (this.isDecimalMode) {
              wholePart = parts[0];
              decimalPart = '0';
            } else {
              wholePart = '0';
              decimalPart = parts[0];
            }
          } else {
            if (this.isDecimalMode) {
              wholePart = parts[0].substring(0, 2);
              decimalPart = parts[0].substring(2);
            } else {
              wholePart = parts[0].substring(0, parts[0].length - 2);
              decimalPart = parts[0].substring(parts[0].length - 2);
            }
          }
        }
      } else {
        wholePart = parts[0];
        decimalPart = parts[1];
      }

      if (isNaN(Number(wholePart)) || wholePart.length === 0) {
        wholePart = '0';
      }

      if (isNaN(Number(decimalPart)) || decimalPart.length === 0) {
        decimalPart = '0';
      }

      if (this.isDecimalMode) {
        duration = round(Number(wholePart + '.' + decimalPart), 2);
      } else {
        duration = Number(wholePart) + Number(decimalPart) / 60;
      }
      if (duration > 24) {
        duration = 24;
      }
    }
    if (this.allocation.duration !== duration) {
      this.allocation.duration = duration;
      this.changeValue(this.allocation);
    }
  }

  // Обновление отображаемого значения.
  private updateViewValue() {
    const duration = this.allocation.duration;

    // Формирование ViewDuration (значения для редактирования).
    if (duration === null) {
      this.viewValue = '';
    } else {
      switch (this.app.session.timeInputType) {
        case TimeInputType.Decimal:
          this.viewValue = this.numberPipe
            .transform(duration, '1.2-2')
            .replace(/\s+/g, '');
          break;
        default:
          this.viewValue = this.timeDurationPipe.transform(
            this.allocation.duration,
            this.dayScheduleDuration,
            false,
          );
          break;
      }
    }
  }

  // Обновление иконки-индикатора.
  private updateIcon() {
    if (!this.allocation) {
      return;
    }

    this.showIcon = false;
    this.showStop = false;
    this.showStart = false;

    const isActiveStopwatch = () =>
      this.stopwatchService.stopwatch?.timeSheetLineId === this.lineId &&
      this.stopwatchService.stopwatch.date === this.allocation.date;

    // Режим стандартного ТШ
    if (this.allocation?.comments?.length > 0) {
      this.showIcon = true;
      this.iconClass = ['bi-chat-dots'];
    }
    if (isActiveStopwatch()) {
      this.showIcon = true;
      this.iconClass = ['green'];
      if (this.stopwatchService.stopwatch.state === StopwatchState.Started) {
        this.iconClass = ['green', 'bi-clock'];
      } else {
        this.iconClass = ['gray', 'bi-clock'];
      }
    }
  }

  // Возвращает заголовок иконки.
  public getIconTitle(): string {
    return this.allocation.comments;
  }

  /** Открыть блок информации о распределении. */
  public openAllocationInfo() {
    const commentIsReadOnly =
      this.readonly ||
      (this.isSchedulePercentMode && this.dayScheduleDuration === 0);

    this.allocationInfoService.open(
      this.id,
      commentIsReadOnly,
      cloneDeep(this.allocation),
      this.lineId,
      this.dayScheduleDuration,
    );

    this.allocationInfoService.allocationPatch$
      .pipe(takeUntil(this.allocationInfoService.close$))
      .subscribe((allocation: TimeAllocation) => {
        merge(this.allocation, allocation);
        this.changeValue(this.allocation);
        this.updateViewValue();
        this.updateIcon();
      });
  }

  /** Закрыть блок информации о распределении. */
  public closeAllocationInfo() {
    this.allocationInfoService.close();
  }

  public startEditing() {
    // Запрет редактирования на выходной день в режиме %% от расписания.
    if (
      this.readonly ||
      (this.isSchedulePercentMode && this.dayScheduleDuration === 0)
    ) {
      return;
    }
    this.oldValue = this.allocation.duration;

    this.openAllocationInfo();
    this.mode.editing = true;
    this.updateViewValue();
    this.previousViewValue = this.viewValue;

    setTimeout(() => {
      this.input.focus();
    });
    this.renderer.addClass(this.getValueElement(), 'hidden');
    this.renderer.addClass(this.getContentElement(), 'glow');
  }

  public stopEditing() {
    if (this.mode.editing) {
      this.parseViewDuration();
      this.mode.editing = false;
      if (!this.entriesOrchestrator.singleCellMode) {
        this.getContentElement().parentElement.focus();
      }
      this.renderer.removeClass(this.getValueElement(), 'hidden');
      this.renderer.removeClass(this.getContentElement(), 'glow');
    }
  }

  public onKeydown(event: KeyboardEvent) {
    if (this.readonly) {
      return;
    }
    if (event.keyCode === this.enterCode) {
      this.stopEditing();
      this.closeAllocationInfo();
    }

    if (event.keyCode === this.escCode || event.charCode === this.enterCode) {
      this.stopEditing();
      this.allocation.duration = this.oldValue;
      this.closeAllocationInfo();
    }
    event.stopPropagation();
  }

  /** Обработка события изменения значения в редакторе */
  public handleInput(event: any) {
    const selectionStart = this.input.selectionStart;
    const selectionEnd = this.input.selectionEnd;
    let value = this.input.value;

    const separators = [' ', '.', ',', ':'];

    separators
      .filter((x) => x !== this.delimiter)
      .forEach((separator: string) => {
        const newValue =
          value.indexOf(this.delimiter) === -1 ? this.delimiter : '';
        value = value.replace(separator, newValue);
      });

    if (this.app.session.timeInputType === TimeInputType.HoursAndMinutes) {
      const parts = value.split(this.delimiter);

      if (
        value.length > 5 ||
        parts.length > 2 ||
        isNaN(Number(parts[0])) ||
        (parts.length === 2 && isNaN(Number(parts[1])))
      ) {
        value = this.previousViewValue;
      }
    }

    if (this.app.session.timeInputType === TimeInputType.SchedulePercent) {
      const parts = value.split(this.delimiter);

      if (value.length > 3 || parts.length > 1 || isNaN(Number(parts[0]))) {
        value = this.previousViewValue;
      }
    }

    if (this.app.session.timeInputType === TimeInputType.Decimal) {
      const normalizedString: string = String(value).replace(
        this.delimiter,
        '.',
      );
      if (value.length > 5 || isNaN(Number(normalizedString))) {
        value = this.previousViewValue;
      }
    }

    if (value !== this.input.value) {
      this.input.value = value;
      this.input.setSelectionRange(selectionStart, selectionEnd);
    }

    this.parseViewDuration();
    this.previousViewValue = value;
  }

  public onDblClick() {
    if (!this.entriesOrchestrator.singleCellMode) {
      this.startEditing();
    }
  }

  public onClick() {
    if (this.entriesOrchestrator.singleCellMode) {
      this.startEditing();
    }
  }

  public onMouseDown() {
    this.entriesOrchestrator.mouseDown({ x: this.dayIndex, y: this.lineIndex });
  }

  public onMouseEnter() {
    this.entriesOrchestrator.mouseEnter({
      x: this.dayIndex,
      y: this.lineIndex,
    });
  }

  public onInputMouseDown(event) {
    event.stopPropagation();
  }

  public onSpreaderMouseDown(event) {
    if (this.readonly) {
      return;
    }
    this.entriesOrchestrator.spreaderMouseDown({
      x: this.dayIndex,
      y: this.lineIndex,
    });
    this.closeAllocationInfo();
    event.stopPropagation();
  }

  public onSpreaderDblClick(event) {
    if (this.readonly) {
      return;
    }
    this.entriesOrchestrator.spreaderDblClick({
      x: this.dayIndex,
      y: this.lineIndex,
    });
    this.closeAllocationInfo();
    event.stopPropagation();
  }

  private initEvents() {
    this.stopwatchSubscription = this.stopwatchService.stopwatch$.subscribe(
      () => {
        this.updateIcon();
        this.changeDetector.detectChanges();
      },
    );

    this.eventEntrySelectedSubscription =
      this.entriesOrchestrator.entrySelected$.subscribe((param) => {
        if (param.pos.x !== this.dayIndex || param.pos.y !== this.lineIndex) {
          // this.closeAllocationInfo();
          return;
        }
        this.mode.selected = true;
        this.mode.top = param.top;
        this.mode.right = param.right;
        this.mode.left = param.left;
        this.mode.bottom = param.bottom;
        if (this.mode.bottom && this.mode.right) {
          this.mode.spreader = true;
        }

        this.changeDetector.detectChanges();
      });

    this.eventEntrySpreadedSubscription =
      this.entriesOrchestrator.entrySpreaded$.subscribe((param) => {
        if (param.pos.x !== this.dayIndex || param.pos.y !== this.lineIndex) {
          return;
        }
        this.mode.spreading = true;
        this.mode.top = param.top;
        this.mode.right = param.right;
        this.mode.left = param.left;
        this.mode.bottom = param.bottom;

        this.changeDetector.detectChanges();
      });
    this.eventEntryDeselectedSubscription =
      this.entriesOrchestrator.entryDeselected$.subscribe((param) => {
        if (!this.mode.selected) {
          return;
        }
        this.mode.selected = false;
        this.mode.top = false;
        this.mode.right = false;
        this.mode.left = false;
        this.mode.bottom = false;
        this.mode.spreader = false;

        this.changeDetector.detectChanges();
      });

    this.eventEntryDespreadedSubscription =
      this.entriesOrchestrator.entryDespreaded$.subscribe(() => {
        if (!this.mode.spreading) {
          return;
        }
        this.mode.spreading = false;
        this.mode.top = false;
        this.mode.right = false;
        this.mode.left = false;
        this.mode.bottom = false;
        this.mode.spreadBottom = false;

        this.changeDetector.detectChanges();
      });

    this.eventEntryDeactivatedSubscription =
      this.entriesOrchestrator.entryDeactivated$.subscribe((exceptEntry) => {
        if (
          exceptEntry &&
          exceptEntry.x === this.dayIndex &&
          exceptEntry.y === this.lineIndex
        ) {
          return;
        }
        this.stopEditing();
        this.closeAllocationInfo();
        this.mode.active = false;

        this.changeDetector.detectChanges();
      });

    this.eventEntryActivatedSubscription =
      this.entriesOrchestrator.entryActivated$.subscribe((pos) => {
        if (pos.x !== this.dayIndex || pos.y !== this.lineIndex) {
          return;
        }
        this.mode.active = true;
        this.openAllocationInfo();

        this.changeDetector.detectChanges();
      });

    // Событие массового редактирования: растягивание или стирание значения.
    this.eventValueChangedSubscription =
      this.entriesOrchestrator.valueChanged$.subscribe((param) => {
        if (param.pos.x !== this.dayIndex || param.pos.y !== this.lineIndex) {
          return;
        }
        if (this.readonly) {
          return;
        }
        if (
          this.isSchedulePercentMode &&
          param.value !== null &&
          this.dayScheduleDuration === 0
        ) {
          return;
        }
        if (
          param.onlyForWorkDay &&
          (this.allocation.duration === 0 ||
            this.allocation.duration === null) &&
          this.dayScheduleDuration === 0
        ) {
          return;
        }
        this.allocation.duration = param.value;
        this.updateViewValue();

        this.changeValue(this.allocation);

        this.changeDetector.detectChanges();
      });

    this.eventResetSubscription = this.entriesOrchestrator.reset$.subscribe(
      () => {
        this.entriesOrchestrator.setValue(
          { x: this.dayIndex, y: this.lineIndex },
          this.allocation.duration,
        );
        this.changeDetector.detectChanges();
      },
    );

    this.eventStopEditingSubscription =
      this.entriesOrchestrator.stopEditing$.subscribe((arg) => {
        this.stopEditing();
        if (!arg || arg.closeComment !== false) {
          this.closeAllocationInfo();
        }

        this.changeDetector.detectChanges();
      });

    this.eventStartEditingSubscription =
      this.entriesOrchestrator.startEditing$.subscribe((value: string) => {
        if (this.isSchedulePercentMode && this.dayScheduleDuration === 0) {
          return;
        }

        if (this.mode.active) {
          this.startEditing();
          if (value) {
            this.viewValue = value;
            setTimeout(() => {
              this.parseViewDuration();
              this.changeDetector.detectChanges();
            });
          }
        }
        this.changeDetector.detectChanges();
      });
  }

  ngOnInit(): void {
    this.serviceId = this.entriesOrchestrator.id;
    this.initEvents();
    this.updateIcon();
  }

  public ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.mouseEnterListener = this.renderer.listen(
        this.frame.nativeElement,
        'mouseenter',
        () => {
          this.onMouseEnter();
        },
      );
    });
  }

  ngOnDestroy() {
    if (this.eventEntrySelectedSubscription) {
      this.eventEntrySelectedSubscription.unsubscribe();
    }

    if (this.eventStopEditingSubscription) {
      this.eventStopEditingSubscription.unsubscribe();
    }

    if (this.eventEntryActivatedSubscription) {
      this.eventEntryActivatedSubscription.unsubscribe();
    }

    if (this.eventEntryDeactivatedSubscription) {
      this.eventEntryDeactivatedSubscription.unsubscribe();
    }

    if (this.eventEntryDespreadedSubscription) {
      this.eventEntryDespreadedSubscription.unsubscribe();
    }

    if (this.eventEntryDeselectedSubscription) {
      this.eventEntryDeselectedSubscription.unsubscribe();
    }

    if (this.eventStartEditingSubscription) {
      this.eventStartEditingSubscription.unsubscribe();
    }

    if (this.eventValueChangedSubscription) {
      this.eventValueChangedSubscription.unsubscribe();
    }

    if (this.eventEntrySpreadedSubscription) {
      this.eventEntrySpreadedSubscription.unsubscribe();
    }

    if (this.eventResetSubscription) {
      this.eventResetSubscription.unsubscribe();
    }

    if (this.mouseEnterListener) {
      this.mouseEnterListener();
    }

    this.stopwatchSubscription.unsubscribe();
  }
}
