import { Component, Input } from '@angular/core';
import { GridColumn } from 'src/app/shared-features/grid2/models/grid-column.interface';

@Component({
  selector: 'tmt-state-cell',
  styleUrls: ['./state-cell.component.scss'],
  templateUrl: './state-cell.component.html',
})
export class StateCellComponent {
  @Input() value: any;
  @Input() column: GridColumn;
}
