import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { WidgetConfig } from '../../shared/widget-config.model';
import { WidgetSettingsComponent } from './widget-settings/widget-settings.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { WidgetType } from './widget-type.model';
import { WidgetService } from './widget.service';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { LocalStorageService } from 'ngx-webstorage';
import { NotificationService } from 'src/app/core/notification.service';
import { DashboardService } from '../dashboard.service';
import { ScrollToService } from 'src/app/shared/services/scroll-to.service';

@Component({
  selector: 'wp-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  providers: [WidgetService],
})
export class WidgetComponent implements OnInit {
  @Input() period: DatePeriod;
  @Input() dashboardId: string;
  @Input() readonly: boolean;
  @Output() onRemoved = new EventEmitter<void>();
  @Output() onChanged = new EventEmitter<WidgetConfig>();
  @Output() onCopied = new EventEmitter<void>();

  public isChartShown: boolean;
  public isLoading: boolean;
  public isEmptyShown: boolean;

  private _widget: WidgetConfig;

  get widget(): WidgetConfig {
    return this._widget;
  }

  @Input()
  set widget(newWidget: WidgetConfig) {
    if (newWidget && newWidget['cloned']) {
      this._widget = newWidget;
      delete newWidget['cloned'];
      this.onChanged.emit(newWidget);
      this.autosave.save().then(() => {
        this.scrollToService.scrollTo(newWidget.id, this.element.nativeElement);
        this.updateUI();
        this.widgetService.rebuild();
        this.notificationService.successLocal(
          'analytics.dashboards.card.messages.widgetHasBeenPasted',
        );
      });
      return;
    }

    this._widget = newWidget;
  }

  constructor(
    private widgetService: WidgetService,
    private autosave: AutosaveService,
    private modal: NgbModal,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private dashboardService: DashboardService,
    private scrollToService: ScrollToService,
    private element: ElementRef,
  ) {}

  /** Удалить виджет. */
  public removeWidget() {
    this.onRemoved.emit();
  }

  /** Открыть настройки. */
  public openSettings() {
    const ref = this.modal.open(WidgetSettingsComponent, { size: 'xxl' });
    (ref.componentInstance as WidgetSettingsComponent).widget = this.widget;

    ref.result.then(
      (widget) => {
        this.widget = cloneDeep(widget);
        this.onChanged.emit(this.widget);

        this.autosave.save().then(() => {
          this.updateUI();
          this.widgetService.rebuild();
        });
      },
      () => null,
    );
  }

  private updateUI() {
    this.isChartShown =
      this.widget?.type &&
      this.widget.type !== WidgetType.Frame &&
      this.widget.type !== WidgetType.Table;

    this.isEmptyShown =
      !this.widget ||
      !this.widget.type ||
      ((this.isChartShown || this.widget.type === WidgetType.Table) &&
        (!this.widget.viewSettings || !this.widget.viewSettings.sourceName));
  }

  public onChanges(widget: WidgetConfig) {
    this.onChanged.emit(widget);
  }

  public onResized() {
    this.widgetService.reflow();
  }

  ngOnInit(): void {
    this.updateUI();
  }

  /** Copies widget to storage. */
  public copyToStorage(): void {
    this.localStorageService.store(
      this.dashboardService.widgetStorageName,
      cloneDeep(this.widget),
    );
    this.notificationService.successLocal('shared.messages.copiedToClipboard');
    this.onCopied.emit();
  }
}
