import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { ClientFilterComponent } from './client-filter.component';

@Injectable()
export class ClientFilterService extends FilterService {
  public override component: Type<FilterDetails> = ClientFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override placeholder = 'projects.clients.list.filter.placeholder';

  protected override getDefaultValues(): any {
    return {
      text: '',
      period: null,
      manager: null,
    };
  }

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        organization: {
          or: [
            { 'tolower(name)': { contains: text } },
            { 'tolower(code)': { contains: text } },
            { 'tolower(description)': { contains: text } },
          ],
        },
      });
    }

    if (this.values.manager) {
      result.push({
        organization: {
          managerId: { eq: { type: 'guid', value: this.values.manager.id } },
        },
      });
    }

    return result;
  }

  public override getDatePeriodUrlParams(): Dictionary<string> {
    return this.dateService.getDatePairFromPeriodForFilter(this.values.period);
  }
}
