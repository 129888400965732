import { StateService, Transition } from '@uirouter/core';

import { DateTime } from 'luxon';
import { catchError, firstValueFrom, map, of, tap, throwError } from 'rxjs';

import { AppService } from 'src/app/core/app.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { StateBuilderService } from 'src/app/core/state-builder.service';

import { ENTITY_TYPE_TO_COLLECTION } from 'src/app/shared/models/entities/entity-type-description.model';
import { Notification } from 'src/app/shared/models/entities/notification.model';

import { CONFIG_MAP } from 'src/app/boards/models/board.config';
import { Board } from 'src/app/settings-app/boards/model/board.model';

export class StateHelper {
  /**
   * Gets entity id from parameters of UIRouter Transition.
   *
   * @param trans `Transition`.
   * @returns entity id.
   */
  public static resolveEntityId(trans: Transition): string {
    return trans.params().entityId;
  }

  /**
   * Gets view name from parameters of UIRouter Transition.
   *
   * @param trans `Transition`.
   * @returns view name.
   */
  public static resolveView(trans: Transition): any {
    return trans.params().view;
  }

  /**
   * Gets project id from parameters of UIRouter Transition.
   *
   * @param trans `Transition`.
   * @returns project id.
   */
  public static resolveProjectId(trans: Transition): string {
    return trans.params().projectId;
  }

  /**
   * Gets context from parameters of UIRouter Transition.
   *
   * @param trans `Transition`.
   * @returns context (e.g. `project`).
   */
  public static resolveContext(trans: Transition): string {
    return trans.params().context;
  }

  /**
   * Gets entity id from parameters of UIRouter Transition.
   * Method works in pair with `resolveContext`.
   *
   * @param trans `Transition`.
   * @returns entity id.
   */
  public static resolveEntity(trans: Transition): any {
    return trans.params().entity;
  }

  /**
   * Gets OData filter parameters.
   *
   * @param trans `Transition`.
   * @returns filter parameters.
   */
  public static resolveFilter(trans: Transition): any {
    return trans.params().filter;
  }

  /**
   * Gets view settings from parameters of UIRouter Transition.
   *
   * @param trans `Transition`.
   * @returns view settings.
   */
  public static resolveViewSettings(trans: Transition): any {
    return trans.params().viewSettings;
  }

  /**
   * Gets user settings from parameters of UIRouter Transition.
   *
   * @param trans `Transition`.
   * @returns user settings.
   */
  public static resolveUserSettings(trans: Transition): any {
    return trans.params().userSettings;
  }

  /**
   * Gets user id from session.
   *
   * @param appService
   * @returns user id.
   */
  public static resolveUserId(appService: AppService): string {
    return appService.session.user.id;
  }

  /**
   * If entityId is equal to `my`, method gets user id from session, otherwise it gets entity id.
   *
   * @param trans `Transition`.
   * @returns id.
   */
  public static resolveEntityIdOrCurrentUserId(
    trans: Transition,
    appService: AppService,
  ): string {
    return trans.params().entityId === 'my'
      ? appService.session.user.id
      : trans.params().entityId;
  }

  /**
   * Requests notification, then redirects to its url.
   *
   * @param dataService
   * @param stateService
   * @param transition `Transition`.
   * @param stateBuilder `StateBuilderService`.
   *
   */
  public static redirectToNotificationUrl(
    dataService: DataService,
    stateService: StateService,
    transition: Transition,
    stateBuilder: StateBuilderService,
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      dataService
        .collection('Notifications')
        .entity(transition.params().entityId)
        .get()
        .subscribe((notification: Notification) => {
          const { state, params } = stateBuilder.getStateForWorkflowEntity(
            notification.target.taskId,
            notification.target.entityType,
            notification.target.entityId,
          );
          stateService.go(state, params);
          resolve();
        });
    });
  }

  /**
   * Gets current timesheet id for actual period.
   *
   * @param dataService
   * @param appService
   * @param blockUI `BlockUIService`.
   * @param notificationService
   *
   */
  public static resolveCurrentId(
    dataService: DataService,
    appService: AppService,
    blockUI: BlockUIService,
    notificationService: NotificationService,
  ): Promise<unknown> {
    blockUI.start();

    return firstValueFrom(
      dataService
        .collection('TimeSheets')
        .function('GetIdForPeriod')
        .get({
          date: DateTime.now().toISODate(),
          userId: appService.session.user.id,
        })
        .pipe(
          catchError(() => {
            notificationService.errorLocal('timesheets.failedToLoadCurrent');
            blockUI.stop();
            return throwError(
              () => new Error('Failed to load the current timesheet.'),
            );
          }),
          tap(() => blockUI.stop()),
        ),
    );
  }

  /**
   * Gets project id.
   *
   * @param trans `Transition`.
   * @param dataService
   * @param blockUI `BlockUIService`.
   *
   * @returns project id.
   */
  public static resolveProjectIdForVersionForm(
    trans: Transition,
    dataService: DataService,
    blockUI: BlockUIService,
  ): string | Promise<string> {
    const projectId = trans.params().projectId;

    if (projectId) {
      return projectId;
    }

    blockUI.start();

    return firstValueFrom(
      dataService
        .collection('ProjectVersions')
        .entity(trans.params().entityId)
        .get({ select: 'mainProjectId' })
        .pipe(
          catchError(() => {
            blockUI.stop();
            return throwError(
              () => new Error('Failed to load the Project Id.'),
            );
          }),
          tap(() => {
            blockUI.stop();
          }),
          map((data: any) => data.mainProjectId),
        ),
    );
  }

  /**
   * Gets board config by entity type.
   *
   * @param trans `Transition`.
   * @param dataService
   * @param appService
   * @param blockUI `BlockUIService`.
   * @param notificationService
   *
   */
  public static resolveBoardConfig(
    trans: Transition,
    dataService: DataService,
    blockUI: BlockUIService,
    notificationService: NotificationService,
  ): Promise<unknown> {
    blockUI.start();
    const entityId = trans.params().entityId;

    return firstValueFrom(
      dataService
        .collection('Boards')
        .entity(entityId)
        .get<Board | null>({
          select: ['id', 'name', 'entityType', 'isActive', 'editAllowed'],
        })
        .pipe(
          catchError(() => {
            blockUI.stop();
            return of(null);
          }),
          tap(() => blockUI.stop()),
          map((data) => {
            if (!data?.isActive) {
              return null;
            }

            const configByEntityType = CONFIG_MAP.get(data.entityType);
            configByEntityType.id = entityId;
            configByEntityType.title = data.name;
            configByEntityType.entityType = data.entityType;
            configByEntityType.editAllowed = data.editAllowed;
            configByEntityType.collection ??= ENTITY_TYPE_TO_COLLECTION.get(
              data.entityType,
            );

            return configByEntityType;
          }),
        ),
    );
  }
}
