<wp-loading-indicator [loading]="mergeModalService.isLoading$ | async"> </wp-loading-indicator>

<div>
  <ul class="nav nav-tabs mb-3" ngbNav (navChange)="onNavChange($event)">
    <li class="nav-item" *ngFor="let tab of tabs" [ngbNavItem]="tab.name">
      <a ngbNavLink class="nav-link">
        {{ tab.header | translate }}
      </a>
    </li>
  </ul>

  <ng-container [ngSwitch]="activeTab">
    <wp-project-version-merge-details-updates *ngSwitchCase="'details'">
    </wp-project-version-merge-details-updates>
    <wp-project-version-merge-details-kpi *ngSwitchCase="'kpi'">
    </wp-project-version-merge-details-kpi>
  </ng-container>
</div>
