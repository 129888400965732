<form class="form">
  <div class="toolbar-container pt-0" *ngIf="!readonly">
    <button
      class="btn btn-default"
      (click)="addGroup()"
      [disabled]="userGroupsService.isLoading$ | async"
    >
      <span class="bi bi-plus-lg bi-15"></span>&nbsp;{{
        'shared.resourceGroups.actions.addGroups' | translate
      }}
    </button>
  </div>
  <wp-grid
    [loading]="userGroupsService.isLoading$ | async"
    [formArray]="userGroupsService.groups"
    [options]="gridOptions"
    [readonly]="readonly"
    class="d-block"
    style="width: 800px"
  ></wp-grid>
</form>
