import { Component, OnInit, DestroyRef, inject } from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DefaultFilterService } from '../../shared/default-filter/default-filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { StateService } from '@uirouter/core';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { IssueTypeCreationComponent } from '../creation/issue-type-creation.component';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'tmt-issue-type-list',
  template: '<wp-entity-list></wp-entity-list> ',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
})
export class IssueTypeListComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private service: EntityListService,
    private gridService: GridService,
    private state: StateService,
    private app: AppService,
    private actions: ActionPanelService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private dataService: DataService,
  ) {}

  public ngOnInit(): void {
    this.actions.set([
      this.actions.getDefaultAction('create', {
        title: 'components.issueTypeListComponent.actions.create',
        isVisible: this.app.checkEntityPermission(
          'IssueType',
          PermissionType.Modify,
        ),
        handler: () => this.create(),
      }),
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go('settings.issueType', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actions.getDefaultAction('delete', {
        handler: () => this.service.delete(),
      }),
      this.actions.getDefaultAction('setAsDefault', {
        handler: () => this.setAsDefault(),
      }),
    ]);

    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((row: any) => {
        this.actions.action('card').isShown = !!row;
        this.actions.action('delete').isShown =
          row &&
          this.app.checkEntityPermission('IssueType', PermissionType.Modify);
        this.actions.action('setAsDefault').isShown =
          row &&
          this.app.checkEntityPermission('IssueType', PermissionType.Modify) &&
          !row.value.isDefault;
      });
  }

  private setAsDefault(): void {
    this.dataService
      .collection('IssueTypes')
      .entity(this.gridService.selectedGroupValue.id)
      .action('SetAsDefault')
      .execute()
      .subscribe({
        next: () => {
          this.notificationService.successLocal(
            'components.issueTypeListComponent.messages.setAsDefault',
          );
          this.service.reload();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
        },
      });
  }

  private create(): void {
    this.modalService.open(IssueTypeCreationComponent);
  }
}
