import { Component } from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-import-creation',
  templateUrl: './import-creation.component.html',
  styleUrls: ['./import-creation.component.scss'],
})
export class ImportCreationComponent {
  isSaving = false;

  files: File[] = [];

  constructor(
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  onSelect(event) {
    const file = event.addedFiles[0];

    if (!file) {
      this.notification.warningLocal('shared.messages.attachmentIsWrong');
      return;
    }

    this.files = [];
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  public ok = () => {
    this.isSaving = true;

    const file = this.files[0];
    const formData: FormData = new FormData();
    formData.append('attachment', file, file.name);

    this.data
      .collection('Imports')
      .action('Import')
      .execute(formData)
      .subscribe({
        next: (response) => {
          this.state.go('settings.import', { entityId: response });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
