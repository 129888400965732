<form class="modal-form" novalidate ngDraggable [formGroup]="form">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'analytics.reports.reportSaveAs.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div [hidden]="!typesIsAllowed" class="form-group">
      <label>{{ 'analytics.reports.reportSaveAs.properties.saveAs.label' | translate }}</label>
      <div class="clearfix"></div>

      <div class="btn-group btn-group-toggle" radioGroup name="radioBasic" formControlName="type">
        <label radioLabel class="btn-default trim fw-normal">
          <input radioButton type="radio" value="my" />{{
            'analytics.reports.reportSaveAs.properties.saveAs.my' | translate
          }}
        </label>

        <label *ngIf="commonReportsIsAllowed" radioLabel class="btn-default trim fw-normal">
          <input radioButton type="radio" value="common" />{{
            'analytics.reports.reportSaveAs.properties.saveAs.common' | translate
          }}
        </label>

        <label *ngIf="templatesIsAllowed" radioLabel class="btn-default trim fw-normal">
          <input radioButton type="radio" value="template" />{{
            'analytics.reports.reportSaveAs.properties.saveAs.template' | translate
          }}
        </label>
      </div>
    </div>
    <div class="form-group">
      <label>{{ 'analytics.reports.reportSaveAs.properties.name.label' | translate }}</label>
      <wp-text-box
        formControlName="name"
        placeholder="{{ 'analytics.reports.reportSaveAs.properties.name.placeholder' | translate }}"
      >
      </wp-text-box>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
