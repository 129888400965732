<wp-loading-indicator [loading]="service.loading$ | async"></wp-loading-indicator>
<div [hidden]="service.loading$ | async">
  <form class="form pt-3 pb-3 d-flex" [formGroup]="form">
    <wp-date-period-box
      class="period-box"
      formControlName="period"
      placeholder="{{ 'shared.teamWorkOverview.filterPeriodPh' | translate }}"
    >
    </wp-date-period-box>
  </form>
  <wp-grid [formArray]="lines" [options]="gridOptions" [readonly]="true"></wp-grid>
</div>
