import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ProjectTeamService } from 'src/app/shared/services/project-team.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridComponentCell } from 'src/app/shared-features/grid2/models/grid-component-cell.interface';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'wp-members-readonly-cell',
  template:
    '<div class="trim" title="{{getAssignmentsString()}}">{{getAssignmentsString()}}</div>',
  styles: [
    `
      div {
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 12px;
        padding-right: 12px;
      }
    `,
  ],
})
export class MembersReadonlyCellComponent
  implements GridComponentCell, OnInit, OnDestroy
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  private destroyed$ = new Subject<void>();

  constructor(
    private projectTeamService: ProjectTeamService,
    public gridService: GridService,
    public cdr: ChangeDetectorRef,
  ) {}

  public getAssignmentsString() {
    return this.projectTeamService.getAssignmentsString(this.control.value);
  }

  public ngOnInit(): void {
    this.gridService.detectChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
