import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { Currency } from 'src/app/shared/models/entities/settings/currency.model';

/**
 * Provides methods to work with Currencies at root application level.
 * */
@Injectable({
  providedIn: 'root',
})
export class CurrenciesService {
  /** Available currencies. */
  public currencies$ = this.getCurrencies().pipe(shareReplay());

  constructor(private data: DataService) {}

  /**
   * Gets Available currencies.
   *
   * @returns Available currencies.
   * */
  private getCurrencies(): Observable<ReadonlyArray<Currency>> {
    return this.data.collection('Currencies').query();
  }

  /**
   * Gets Currency exchange rate.
   *
   * @param currencyId Currency ID.
   * @param date Exchange rate date.
   * @returns Currency exchange rate.
   * */
  public getExchangeRate(currencyId: string, date: string): Observable<number> {
    return this.data
      .collection('Currencies')
      .entity(currencyId)
      .function('GetExchangeRate')
      .get<number>({ date })
      .pipe(shareReplay());
  }
}
