import { DestroyRef, Injectable, inject } from '@angular/core';

import { Currency } from 'src/app/shared/models/entities/settings/currency.model';
import { ClientTariffsSettings } from '../../shared/client-tariff.settings';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { Observable, Subject, forkJoin } from 'rxjs';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { CurrenciesService } from 'src/app/shared/services/currencies.service';
import { ClientTariff } from '../../shared/client-tariff.model';
import { CurrencyValue } from 'src/app/shared/models/entities/settings/currency-value.model';
import { NotificationService } from 'src/app/core/notification.service';
import _ from 'lodash';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { Constants } from 'src/app/shared/globals/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import {
  TariffRate,
  TariffRatesHelper,
} from 'src/app/shared/modules/tariff-rates/helpers/tariff-rates.helper';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';

@Injectable()
export class ClientTariffService {
  public currencies: Currency[] = [];
  public settings: ClientTariffsSettings;
  public reloadData$ = new Subject<void>();
  public formArray: UntypedFormArray = this.fb.array([]);

  private destroyRef = inject(DestroyRef);

  constructor(
    private localConfigService: LocalConfigService,
    private gridService: GridService,
    private data: DataService,
    private fb: UntypedFormBuilder,
    private currenciesService: CurrenciesService,
    private notification: NotificationService,
    private savingQueueService: SavingQueueService,
  ) {
    this.settings = localConfigService.getConfig(ClientTariffsSettings);
    this.savingQueueService.delayDuration = 0;
  }

  /**
   * Saves clientTariffSettings.
   *
   * @param value clientTariffSettings.
   */
  public saveSettings(value: Partial<ClientTariffsSettings>): void {
    this.settings = Object.assign(this.settings, value);
    this.localConfigService.setConfig(ClientTariffsSettings, this.settings);
  }

  /**
   * Loads data.
   *
   * @param clientTariffId clientId.
   * @param organizationId organizationId.
   */
  public load(clientTariffId: string, organizationId: string): void {
    this.gridService.setLoadingState(true);
    this.formArray.clear();

    const query: any = {
      select: [
        'name',
        'isActive',
        'description',
        'id',
        'tariff',
        'organizationId',
      ],
      expand: {
        rates: {
          select: ['id', 'value', 'effectiveDate', 'currencyId', 'expiryDate'],
        },
      },
      filter: {},
    };

    if (this.settings.onlyActive) {
      query.filter.isActive = true;
    }

    const tariffRequest = this.data
      .collection('Organizations')
      .entity(clientTariffId)
      .collection('OrganizationTariffs')
      .query<ClientTariff[]>(query);

    forkJoin({
      tariffs: tariffRequest,
      currencies: this.currenciesService.currencies$,
    }).subscribe({
      next: (data) => {
        this.currencies = data.currencies as Currency[];

        data.tariffs = data.tariffs.sort(naturalSort('name'));
        data.tariffs.forEach((tariff) => {
          const group = this.getTariffsFormGroup(tariff, organizationId);
          this.formArray.push(group);
        });

        this.gridService.setLoadingState(false);
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.gridService.setLoadingState(false);
      },
    });
  }

  private getCurrencyValue(rate: TariffRate): CurrencyValue {
    return {
      value: rate?.value ?? null,
      currencyCode:
        this.currencies.find((c) => c.id === rate?.currencyId)?.alpha3Code ??
        null,
    };
  }

  private getTariffsFormGroup(
    tariff: ClientTariff,
    organizationId: string,
  ): UntypedFormGroup {
    const actualTariff = TariffRatesHelper.getActualRate(tariff.rates ?? []);
    const group = this.fb.group({
      name: [
        tariff.name,
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      description: tariff.description,
      isActive: tariff.isActive,
      id: tariff.id,
      organizationId,
      value: this.getCurrencyValue(actualTariff),
      expiryDate: actualTariff?.expiryDate,
      effectiveDate: actualTariff?.effectiveDate,
      currencyId: [tariff.rates.map((rate) => rate.currencyId)],
      rates: [tariff.rates],
    });
    group.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (group.invalid) {
          this.notification.warningLocal('shared.messages.entityNotSaved');
          return;
        }

        const tariff = group.getRawValue();

        tariff.rates.forEach((rate) => delete rate.expiryDate);
        const clientTariff: ClientTariff = {
          id: tariff.id,
          name: tariff.name,
          description: tariff.description,
          isActive: tariff.isActive,
          organizationId: tariff.organizationId,
          rates: tariff.rates,
        };

        this.savingQueueService.addToQueue(
          group.value.id,
          this.updateTariff(clientTariff, true, tariff.id),
        );
      });

    return group;
  }

  private updateTariff(
    data: Partial<ClientTariff>,
    isTariffExist: boolean,
    tariffId: string,
  ): Observable<ClientTariff> {
    return isTariffExist
      ? this.data
          .collection('OrganizationTariffs')
          .entity(tariffId)
          .update(data)
      : this.data.collection('OrganizationTariffs').insert(data);
  }
}
