<div class="group">
  <button
    type="button"
    title="{{ 'shared.planner.actions.previousPeriod' | translate }}"
    class="btn btn-default period"
    (click)="previousPeriod()"
  >
    <span class="bi bi-chevron-left"></span>
  </button>
  <button
    type="button"
    title="{{ 'shared.planner.actions.nextPeriod' | translate }}"
    class="btn btn-default period"
    (click)="nextPeriod()"
  >
    <span class="bi bi-chevron-right"></span>
  </button>

  <div ngbDropdown class="d-inline-block">
    <button
      type="button"
      class="btn btn-default"
      ngbDropdownToggle
      title="{{ 'shared.planner.actions.setPeriod' | translate }}"
    >
      {{ periodTitle }}
    </button>

    <div ngbDropdownMenu class="dropdown-scroll">
      <button
        *ngFor="let period of suitablePeriods"
        ngbDropdownItem
        (click)="changePeriod(period.offset)"
        [ngClass]="{ 'period-active': period.isBase }"
      >
        {{ period.title }}
      </button>
    </div>
  </div>
</div>
