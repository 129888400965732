import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import {
  Command,
  GridOptions,
} from 'src/app/shared/components/features/grid/grid-options.model';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { ListService } from 'src/app/shared/services/list.service';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { assign, findIndex, minBy, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { forkJoin, Subject } from 'rxjs';
import { Constants } from 'src/app/shared/globals/constants';
import { Guid } from 'src/app/shared/helpers/guid';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { Exception } from 'src/app/shared/models/exception';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ProjectBillingToolbarComponent } from './project-billing-toolbar/project-billing-toolbar.component';
import { ProjectBillingViewLine } from './shared/model/project-billing-view-line.model';
import { ProjectBillingSettings } from './shared/model/project-billing-settings.model';
import { ProjectBillingModalComponent } from './shared/project-billing-modal/project-billing-modal.component';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { StateService } from '@uirouter/core';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { InvoiceSettings } from 'src/app/billing/invoices/card/shared/invoice.settings';
import { ProjectBillingType } from 'src/app/shared/models/enums/project-billing-type';
import { FinancialTaskCellService } from '../../shared/financial-task-cell/financial-task-cell.service';
import { ProjectCardService } from '../../core/project-card.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectBillingEstimate } from 'src/app/shared/models/entities/projects/project-billing-estimate.model';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { GridCurrencyColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { MessageService } from 'src/app/core/message.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { ProjectBillingMode } from 'src/app/shared/models/enums/project-billing-mode.enum';

@Injectable()
export class ProjectBillingService implements OnDestroy {
  get isWorkProjectVersion() {
    return this._isWorkProjectVersion;
  }

  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    toolbar: ProjectBillingToolbarComponent,
    clientTotals: false,
    commands: [
      {
        name: 'addEntry',
        handlerFn: () => this.addEntry(),
        allowedFn: () => !this.readonly,
      },
      {
        name: 'edit',
        handlerFn: (group: UntypedFormGroup) => this.edit(group),
        allowedFn: (row: any) => row && !row.isTaskGroup,
      },
      {
        name: 'delete',
        handlerFn: (row: any) => this.deleteEntry(row.id),
        allowedFn: (row: any) =>
          !this.readonly && row && !row.isTaskGroup && !row.isAutomatic,
      },
      {
        name: 'createInvoice',
        handlerFn: (group: UntypedFormGroup) => this.createInvoice(group),
        allowedFn: (row: any) =>
          this._isWorkProjectVersion &&
          this.project.organization &&
          this.project.billingType.code !== ProjectBillingType.nonBillable.code,
      },
      {
        name: 'clear',
        handlerFn: (group: UntypedFormGroup) => this.clear(),
        allowedFn: (row: any) => !this.readonly,
      },
    ],
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !formGroup.value.isTaskGroup,
        handlerFn: (formGroup: UntypedFormGroup) => this.edit(formGroup),
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonly &&
          !formGroup.value.isTaskGroup &&
          !formGroup.value.isAutomatic,
        handlerFn: (formGroup: UntypedFormGroup, index: number) =>
          this.deleteEntry(formGroup.value.id),
      },
    ],
    view: this.listService.getGridView(),
  };

  public settings: ProjectBillingSettings;

  public commands: Command[];

  public totals: Dictionary<number> = {};

  public readonly: boolean;

  public formArray: UntypedFormArray = this.fb.array([]);

  project: Project;

  private isAutomaticBillingMode = false;
  private entries: ProjectBillingEstimate[] = [];
  private mainTask: ProjectTask;
  private tasks: ProjectTask[] = [];
  private allTasks: ProjectTask[] = [];

  /** Суммы по задачам */
  private tasksAmounts: Dictionary<number> = {};

  private _isWorkProjectVersion: boolean;

  private reloaded$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  private getCollection = () => this.data.collection('ProjectBillingEstimates');

  constructor(
    @Inject('entityId') public entityId: string,
    private blockUI: BlockUIService,
    private injector: Injector,
    public autosave: SavingQueueService,
    public modal: NgbModal,
    public state: StateService,
    private data: DataService,
    private fb: UntypedFormBuilder,
    public listService: ListService,
    public gridService: GridService,
    public notification: NotificationService,
    private configService: LocalConfigService,
    private translate: TranslateService,
    private projectTasksService: ProjectTasksService,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    financialTaskCellService: FinancialTaskCellService,
    private projectCardService: ProjectCardService,
    private messageService: MessageService,
  ) {
    financialTaskCellService.toggleTaskId$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((id) => {
        this.toggleTask(id);
      });
    financialTaskCellService.projectVersion =
      this.versionCardService.projectVersion;
  }

  public toggleTask(taskId: string) {
    const task = this.tasks.find((t) => t.id === taskId);
    task.isExpanded = !task.isExpanded;
    this.updateFormArray();
  }

  /** Загрузка главной задачи. */
  public loadMainTask() {
    this.projectTasksService
      .getProjectTasks(this.entityId, this.versionCardService.projectVersion)
      .subscribe({
        next: (tasks: ProjectTask[]) => {
          this.mainTask =
            tasks?.length > 0 ? tasks.find((t) => !t.leadTaskId) : null;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  /** Загрузка данных. */
  public load(silent?: boolean): void {
    this.reloaded$.next();
    this.projectTasksService.resetProjectTasks(
      this.entityId,
      this.versionCardService.projectVersion,
    );
    this.autosave.save().then(
      () => {
        if (!silent || this.isAutomaticBillingMode) {
          this.formArray.clear();
          this.totals = null;
          this.gridService.setLoadingState(true);
        }

        forkJoin({
          entries: this.getCollection().query<ProjectBillingEstimate[]>(
            this.getQuery(),
          ),
          tasks: this.projectTasksService.getProjectTasks(
            this.entityId,
            this.versionCardService.projectVersion,
          ),
        })
          .pipe(takeUntil(this.reloaded$))
          .subscribe({
            next: (value) => {
              this.entries = value.entries;
              this.allTasks = value.tasks;

              this.entries.forEach((e) => {
                e.added = 0;
              });

              this.updateTasks();
              this.calculateTotals();
              this.updateFormArray();

              this.gridService.setLoadingState(false);
            },
            error: (error: Exception) => {
              this.notification.error(error.message);
              this.gridService.setLoadingState(false);
            },
          });
      },
      () => null,
    );
  }

  public addEntry() {
    const entry: ProjectBillingEstimate = {
      created: DateTime.now().toJSDate(),
      added: new Date().getTime(),
      projectTask: this.gridService.selectedRow?.projectTask ?? this.mainTask,
      billingDate: DateTime.now().toISODate(),
      collectionDate: DateTime.now().toISODate(),
      amount: 0,
      id: Guid.generate(),
      description: '',
      isAutomatic: false,
    };
    ProjectVersionUtil.setEntityRootPropertyId(
      this.versionCardService.projectVersion,
      entry,
      this.entityId,
    );

    this.entries.unshift(entry);

    this.updateTasks();
    this.calculateTotals();
    this.updateFormArray();

    const indexOfNewEntry = (this.formArray.value as any[]).findIndex(
      (l) => l.id === entry.id,
    );

    this.gridService.selectGroup(
      this.formArray.at(indexOfNewEntry) as UntypedFormGroup,
    );

    const data = {
      id: entry.id,
      projectTaskId: entry.projectTask.id,
      amount: entry.amount,
      billingDate: entry.billingDate,
      collectionDate: entry.collectionDate,
      description: entry.description,
    };
    ProjectVersionUtil.setEntityRootPropertyId(
      this.versionCardService.projectVersion,
      data,
      this.entityId,
    );

    this.autosave.addToQueue(
      Guid.generate(),
      this.getCollection().insert(data),
    );
  }

  public deleteEntry(id: string): void {
    this.entries = this.entries.filter((l) => l.id !== id);
    this.updateTasks();
    this.calculateTotals();
    this.updateFormArray();
    this.autosave.addToQueue(id, this.getCollection().entity(id).delete());
  }

  public edit(group: UntypedFormGroup) {
    const ref = this.modal.open(ProjectBillingModalComponent, {
      injector: this.injector,
    });
    const instance = ref.componentInstance as ProjectBillingModalComponent;

    const groupValue = group.getRawValue();
    instance.entry = groupValue;
    instance.readonly = this.readonly || groupValue.isAutomatic;
    instance.projectId = this.entityId;
    instance.projectCurrencyCode = this.project.currency.alpha3Code;
    instance.projectVersion = this.versionCardService.projectVersion;

    ref.result.then(
      (result) => {
        group.patchValue(result, { emitEvent: false });
        group.controls.id.setValue(result.id);
        const task = this.tasks.find((t) => t.id === result.projectTask.id);
        this.updateTotalsByTask(task);
      },
      () => null,
    );
  }

  /** Создает группу строки. */
  public getLineGroup(): UntypedFormGroup {
    const group = this.fb.group({
      id: null,
      created: null,
      billingDate: [null, Validators.required],
      collectionDate: [null, Validators.required],
      amount: [null, Validators.required],
      description: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
      projectTask: [null, Validators.required],
      indent: 0,
      isTaskGroup: false,
      isExpanded: true,
      isAutomatic: false,
    });

    group.valueChanges
      .pipe(takeUntil(this.reloaded$))
      .subscribe((line: ProjectBillingViewLine) => {
        const entry = this.entries.find((e) => e.id === line.id);
        assign(entry, line);

        const data = {
          id: entry.id,
          projectTaskId: entry.projectTask.id,
          amount: entry.amount,
          billingDate: entry.billingDate,
          collectionDate: entry.collectionDate,
          description: entry.description,
          isAutomatic: entry.isAutomatic,
        };
        ProjectVersionUtil.setEntityRootPropertyId(
          this.versionCardService.projectVersion,
          data,
          this.entityId,
        );

        this.autosave.addToQueue(
          entry.id,
          this.getCollection().entity(entry.id).update(data),
        );
      });

    group.controls['amount'].valueChanges
      .pipe(takeUntil(this.reloaded$))
      .subscribe(() => {
        this.updateTotalsByTask(group.value.projectTask);
      });

    group.controls['projectTask'].valueChanges
      .pipe(debounceTime(0), takeUntil(this.reloaded$))
      .subscribe(() => {
        this.updateTasks();
        this.calculateTotals();
        this.updateFormArray();
      });

    return group;
  }

  private updateTotalsByTask(projectTask: ProjectTask) {
    // Find lead task.
    const getLeadTask = (task: ProjectTask): ProjectTask => {
      const leadTask = this.tasks.find((t) => t.id === task.leadTaskId);
      if (!leadTask) {
        return task;
      } else {
        return getLeadTask(leadTask);
      }
    };

    setTimeout(() => {
      this.calculateTotals();

      if (this.settings.grouping) {
        this.applyTotals([getLeadTask(projectTask)]);
      }
    });
  }

  /** Calculates Task and general totals. */
  private calculateTotals() {
    this.tasksAmounts = {};

    const calculateTasksTotal = (tasks: ProjectTask[]): number => {
      let totalAmount = 0;

      tasks.forEach((task) => {
        let amount =
          this.entries
            .filter((e) => e.projectTask.id === task.id)
            ?.reduce((total, entry) => total + entry.amount, 0) ?? 0;

        const children = this.tasks.filter((t) => t.leadTaskId === task.id);
        amount += calculateTasksTotal(children);
        totalAmount += amount;
        this.tasksAmounts[task.id] = amount;
      });

      return totalAmount;
    };

    // First level tasks.
    const topTasks = this.tasks.filter(
      (task) =>
        !task.leadTaskId ||
        !this.tasks.find((leadTask) => leadTask.id === task.leadTaskId),
    );

    this.totals = {};
    this.totals['amount'] = calculateTasksTotal(topTasks);
    this.totals['projectTask'] = this.entries.length;
  }

  /** Обновление структуры задач на основании списка строк. */
  private updateTasks() {
    this.tasks = [];

    // Соберем структуру задач.
    this.entries.forEach((entry) => {
      if (
        entry.projectTask &&
        !this.tasks.find((t) => t.id === entry.projectTask.id)
      ) {
        entry.projectTask.isExpanded = true;
        this.tasks.push(entry.projectTask);
      }
    });

    // Восстановим разорванные цепочки.
    const restoreLinks = (task: ProjectTask) => {
      if (!task.leadTaskId) {
        return;
      }
      const leadTask = this.allTasks.find((t) => t.id === task.leadTaskId);

      // Главную задачу не добавляем - если на нее нет записей, то и в UI не будет.
      if (
        leadTask?.leadTaskId &&
        !this.tasks.find((t) => t.id === leadTask.id)
      ) {
        leadTask.isExpanded = true;
        this.tasks.push(leadTask);
        restoreLinks(leadTask);
      }
    };

    this.tasks.forEach((task) => {
      restoreLinks(task);
    });
  }

  /** Обновляет форму по данным. */
  private updateFormArray() {
    this.calculateTotals();

    this.autosave.disabled = true;
    const lines: ProjectBillingViewLine[] = [];

    if (this.settings.grouping === 'byTasks') {
      const minIndent = minBy(this.tasks, (t) => t.indent)?.indent ?? 0;

      const addLevel = (tasksInLevel: ProjectTask[]) => {
        tasksInLevel
          .sort(naturalSort('structNumber'))
          .forEach((projectTask) => {
            // Add Task row.
            lines.push({
              created: projectTask.created,
              indent: projectTask.indent - minIndent,
              amount: this.tasksAmounts[projectTask.id],
              billingDate: null,
              collectionDate: null,
              description: '',
              projectTask,
              id: projectTask.id,
              isTaskGroup: true,
              isExpanded: projectTask.isExpanded,
              isAutomatic: false,
            });

            if (!projectTask.isExpanded) {
              return;
            }

            // Find all rows.
            let entries = this.entries.filter(
              (entry) => entry.projectTask?.id === projectTask.id,
            );

            entries = orderBy(
              entries,
              ['added', 'billingDate', 'created'],
              ['desc', 'asc', 'desc'],
            );

            entries.forEach((entry) => {
              lines.push({
                ...entry,
                isTaskGroup: false,
              });
            });

            addLevel(
              this.tasks.filter((task) => task.leadTaskId === projectTask.id),
            );
          });
      };

      // Move all tasks without lead ones to the top level even if it has lead one (chain breaks).
      const tasks = this.tasks.filter(
        (task) =>
          !task.leadTaskId ||
          !this.tasks.find((leadTask) => leadTask.id === task.leadTaskId),
      );

      addLevel(tasks);
    } else {
      const entries = orderBy(
        this.entries,
        ['added', 'billingDate', 'created'],
        ['desc', 'asc', 'desc'],
      );

      entries.forEach((entry) => {
        lines.push({
          ...entry,
          isTaskGroup: false,
        });
      });
    }

    // Update grid structure form.
    for (let index = 0; index < lines.length; index++) {
      const line = lines[index];

      // If Task has group at index.
      if (line.id === this.formArray.at(index)?.value.id) {
        continue;
      }

      const groupIndex = findIndex(
        this.formArray.value,
        (r: any) => r.id === line.id,
        index + 1,
      );

      // Group is missing in rows.
      if (groupIndex === -1) {
        const newGroup = this.getLineGroup();
        if (line.isAutomatic) {
          newGroup.disable({ emitEvent: false });
        }

        this.formArray.insert(index, newGroup);
        continue;
      }

      // Group exists in further rows.
      const group = this.formArray.at(groupIndex);
      this.formArray.removeAt(groupIndex);
      this.formArray.insert(index, group);
    }

    // Remove extra groups.
    while (lines.length !== this.formArray.controls.length) {
      this.formArray.removeAt(this.formArray.controls.length - 1);
    }

    // Update form value.
    this.formArray.patchValue(lines, { emitEvent: false });

    // Trigger grid change detector.
    this.gridService.detectChanges();

    if (this.readonly) {
      this.formArray.disable({ emitEvent: false });
    }

    this.autosave.disabled = false;
  }

  /** Применяет итоги по задачам без обновления структуры. */
  private applyTotals(tasksToApply: ProjectTask[]) {
    const applyTotals = (tasks: ProjectTask[]) => {
      tasks.forEach((task) => {
        const groupIndex = (this.formArray.getRawValue() as any[]).findIndex(
          (l) => l.isTaskGroup && l.projectTask?.id === task.id,
        );

        if (groupIndex !== -1) {
          const group = this.formArray.at(groupIndex) as UntypedFormGroup;

          group.controls['amount'].setValue(this.tasksAmounts[task.id], {
            emitEvent: false,
          });
        }

        const children = this.tasks.filter((t) => t.leadTaskId === task.id);
        applyTotals(children);
      });
    };

    applyTotals(tasksToApply);

    this.gridService.detectChanges();
  }

  /** Возвращает локализованный заголовок текущей группировки. */
  public getCurrentGroupingLabel(): string {
    return this.translate.instant(
      `projects.projects.card.finance.grouping.${this.settings?.grouping}`,
    );
  }

  /** Изменение группировки строк. */
  public setGrouping(grouping: any) {
    this.settings.grouping = grouping;
    this.configService.setConfig(ProjectBillingSettings, this.settings);
    this.updateFormArray();
  }

  setReadonly(readonly: boolean) {
    this.readonly =
      readonly || !this.versionCardService.projectVersion.editAllowed;
    if (this.readonly) {
      this.formArray.disable();
      this.gridService.detectChanges();
    }
  }

  updateWorkProjectVersionFlag() {
    this._isWorkProjectVersion = this.versionCardService.isWorkProjectVersion();
  }

  createInvoice(group: UntypedFormGroup) {
    this.blockUI.start();
    const data = {
      organizationId: this.project.organization.id,
      projectId: this.project.id,
      number: '',
      timeLinesGrouping:
        this.configService.getConfig(InvoiceSettings).timeLinesGrouping,
      expenseLinesGrouping:
        this.configService.getConfig(InvoiceSettings).expenseLinesGrouping,
    };

    this.data
      .collection('Invoices')
      .action('Insert')
      .execute(data)
      .subscribe({
        next: (response: any) => {
          this.notification.successLocal(
            'billing.invoices.creation.messages.created',
          );
          this.state.go('invoice', {
            entityId: response,
            routeMode: RouteMode.continue,
          });
          this.blockUI.stop();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.blockUI.stop();
        },
      });
  }

  init() {
    this.settings = this.configService.getConfig(ProjectBillingSettings);

    this.autosave.error$.subscribe(() => this.load());

    this.projectCardService.project$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((project) => {
        this.project = project;
        this.isAutomaticBillingMode =
          this.project.billingEstimationSettings?.mode ===
          ProjectBillingMode.automatic;
        const amountColumn = this.gridOptions.view.columns.find(
          (column) => column.name === 'amount',
        ) as GridCurrencyColumn;
        amountColumn.currencyCode = this.project.currency.alpha3Code;
      });

    this.load();
    this.loadMainTask();
  }

  private getQuery() {
    const query: any = {
      select: [
        'id',
        'created',
        'billingDate',
        'collectionDate',
        'amount',
        'description',
        'isAutomatic',
      ],
      expand: {
        projectTask: {
          select: [
            'id',
            'created',
            'name',
            'indent',
            'leadTaskId',
            'structNumber',
          ],
        },
      },
      orderBy: ['billingDate', 'created'],
    };

    ProjectVersionUtil.addProjectEntityIdFilter(
      query,
      this.versionCardService.projectVersion,
      this.entityId,
    );

    return query;
  }
  private clear() {
    this.messageService
      .confirmLocal('projects.actions.clearDataConfirmation')
      .then(
        () => {
          this.blockUI.start();
          this.versionDataService
            .projectCollectionEntity(
              this.versionCardService.projectVersion,
              this.entityId,
            )
            .action('ClearBillingEstimates')
            .execute()
            .subscribe({
              next: () => {
                this.blockUI.stop();
                this.projectCardService.reloadTab();
              },
              error: (error: Exception) => {
                this.blockUI.stop();
                this.notification.error(error.message);
              },
            });
        },
        () => null,
      );
  }
  ngOnDestroy(): void {
    this.reloaded$.next();
    this.destroyed$.next();
  }
}
