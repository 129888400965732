import { DestroyRef, Injectable, inject } from '@angular/core';
import { DateTime, DurationLike, Interval } from 'luxon';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import {
  Slot,
  SlotGroup,
} from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { ScheduleNavigationContext } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-context.enum';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { FteScheduleService } from 'src/app/core/fte-schedule.service';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { filter } from 'rxjs/operators';
import { ProjectTaskDependency } from 'src/app/shared/models/entities/projects/project-task.model';
import { UntypedFormGroup } from '@angular/forms';
import { FreezeTableScrollOptions } from 'src/app/shared/directives/freeze-table/freeze-table.interface';
import { TasksGridActionsService } from 'src/app/projects/card/project-tasks/core/tasks-grid-actions.service';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';

import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { TimelineCellEntry } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/models/timeline-entry.interface';
import { TimelineSettings } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/models/timeline.settings';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from 'src/app/core/menu.service';

/** Timeline data service. */
@Injectable()
export class ProjectTaskTimelineService {
  public slots: Slot[] = [];
  public slotGroups: SlotGroup[] = [];

  public settings: TimelineSettings;

  public taskHintNames: Record<string, string> = {
    taskName: '',
    dates: '',
    projectTaskDuration: '',
    percentComplete: '',
    assignments: '',
  };

  /** Cell entries. */
  private entriesSubject = new BehaviorSubject<TimelineCellEntry[]>(null);
  public entries$ = this.entriesSubject.asObservable().pipe(filter((e) => !!e));

  public interval: Interval;

  /** Task updating command. */
  private updateTasksSubject = new Subject<void>();
  public updateTasks$ = this.updateTasksSubject.asObservable();

  /** Navigation service subscriptions. */
  private scheduleNavigationSubscriptions: Subscription = new Subscription();

  /** Getting FTE schedule subscription */
  private fteScheduleSubscription: Subscription;

  /** Gaps for timeline slots. */
  private smallGap = 19;
  private bigGap = 81;

  private get projectStartDate(): DateTime | undefined {
    if (this.dataService.tasks && this.dataService.tasks.length) {
      const mainTask = this.dataService.tasks.find((task) => !task.leadTaskId);
      return DateTime.fromISO(mainTask.startDate);
    }
  }

  private get projectEndDate(): DateTime | undefined {
    if (this.dataService.tasks && this.dataService.tasks.length) {
      const mainTask = this.dataService.tasks.find((task) => !task.leadTaskId);
      return DateTime.fromISO(mainTask.endDate);
    }
  }

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    public gridService: GridService,
    private scheduleNavigationService: ScheduleNavigationService,
    private blockUI: BlockUIService,
    private freezeTableService: FreezeTableService,
    private localConfigService: LocalConfigService,
    private fteScheduleService: FteScheduleService,
    private notification: NotificationService,
    private projectCardService: ProjectCardService,
    public dataService: ProjectTasksDataService,
    public tasksGridActionsService: TasksGridActionsService,
    private menuService: MenuService,
    private translateService: TranslateService,
  ) {
    this.freezeTableService.scroll$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.menuService.close();
      });
  }

  /** Shift timeline interval. */
  public shiftTimelineInterval(direction: 'left' | 'right'): void {
    this.blockUI.start();

    let shift: DurationLike;

    switch (this.settings.planningScale) {
      case PlanningScale.Day:
        shift = { weeks: 2 };
        break;
      case PlanningScale.Week:
        shift = { weeks: 10 };
        break;
      case PlanningScale.Month:
        shift = { weeks: 16 };
        break;
    }

    switch (direction) {
      case 'left':
        this.interval = this.interval.set({
          start: this.interval.start.minus(shift),
        });
        this.interval = this.interval.set({
          end: this.interval.end.minus(shift),
        });
        break;
      case 'right':
        this.interval = this.interval.set({
          start: this.interval.start.plus(shift),
        });
        this.interval = this.interval.set({
          end: this.interval.end.plus(shift),
        });
        break;
    }

    this.projectCardService.projectTaskTimelineInterval = this.interval;

    this.updateSlots();
    this.blockUI.stop();
    this.freezeTableService.autoScroll(direction);
  }

  /**
   * Reloads the timeline based on the provided parameters.
   *
   * @param planningScale The planning scale to set for the timeline.
   * @param toDate The target date to navigate to.
   */
  public reloadTimeline(
    planningScale?: PlanningScale,
    toDate?: DateTime,
  ): void {
    this.gridService.selectGroup(null);

    if (planningScale) {
      this.projectCardService.timelineScrollPosition = null;
      this.settings.planningScale = planningScale;
      this.interval = this.getDefaultProjectInterval();
    }
    if (toDate) {
      this.interval = this.getTimelineInterval(
        this.settings.planningScale,
        toDate,
      );
    }
    if (!planningScale && !toDate) {
      this.interval = this.projectCardService.projectTaskTimelineInterval;
    } else {
      this.projectCardService.projectTaskTimelineInterval = this.interval;
    }

    this.updateSlots();
    this.updateEntries();

    if (toDate) {
      this.scrollToSelector(toDate);
    } else {
      this.initScrollPosition();
    }
  }

  /** Update tasks. */
  public updateTasks(): void {
    this.updateTasksSubject.next();
  }

  /** Unsubscribe of service subscriptions. */
  public dispose(): void {
    this.scheduleNavigationSubscriptions.unsubscribe();
    if (this.fteScheduleSubscription) {
      this.fteScheduleSubscription.unsubscribe();
    }
  }

  /** Initialize timeline. */
  public initTimeline(): void {
    if (this.projectCardService.projectTaskTimelineInterval) {
      this.interval = this.projectCardService.projectTaskTimelineInterval;
    } else {
      this.interval = this.getDefaultProjectInterval();
      this.projectCardService.projectTaskTimelineInterval = this.interval;
    }

    const slotInfo = this.scheduleNavigationService.getSlots(
      this.interval,
      this.settings.planningScale,
    );

    this.slots = slotInfo.slots;
    this.slotGroups = slotInfo.groups;

    this.updateEntries();
  }

  /** Sets timeline scroll position. */
  public initScrollPosition(): void {
    if (this.projectCardService.timelineScrollPosition !== null) {
      this.freezeTableService.autoScroll(
        this.projectCardService.timelineScrollPosition,
      );
      return;
    }

    const today = DateTime.now();
    let dateToScrolling = today;
    if (+today < +this.projectStartDate) {
      dateToScrolling = this.projectStartDate;
    }
    if (+today >= +this.projectStartDate && +today <= +this.projectEndDate) {
      dateToScrolling = today;
    }
    if (+today > +this.projectEndDate) {
      dateToScrolling = this.projectEndDate;
    }
    this.scrollToSelector(dateToScrolling);
  }

  /** Initialize service. */
  public init(): void {
    /** Take current settings. */
    this.settings = this.localConfigService.getConfig(TimelineSettings);

    /** Initialize settings context in the navigation service. */
    this.scheduleNavigationService.init(
      ScheduleNavigationContext.ProjectTasksTimeline,
    );

    /** Subscriptions. */
    this.scheduleNavigationSubscriptions.add(
      this.scheduleNavigationService.previous$.subscribe(() => {
        this.shiftTimelineInterval('left');
        this.updateEntries();
      }),
    );

    this.scheduleNavigationSubscriptions.add(
      this.scheduleNavigationService.next$.subscribe(() => {
        this.shiftTimelineInterval('right');
        this.updateEntries();
      }),
    );

    this.scheduleNavigationSubscriptions.add(
      this.scheduleNavigationService.jump$.subscribe((date) => {
        this.reloadTimeline(null, date);
      }),
    );

    this.scheduleNavigationSubscriptions.add(
      this.scheduleNavigationService.planningScale$.subscribe(
        (planningScale) => {
          this.projectCardService.timelineScrollPosition = null;
          this.reloadTimeline(planningScale);
        },
      ),
    );

    this.translateTaskHintNames();
  }

  /**
   * Adds dependency to task.
   *
   * @param taskFormGroup target task.
   * @param newDependency new task dependency.
   */
  public addDependency(
    taskFormGroup: UntypedFormGroup,
    newDependency: ProjectTaskDependency,
  ): void {
    const currentDependencies = taskFormGroup.controls.dependencies;
    if (
      currentDependencies.value.find(
        (dependency) =>
          dependency.predecessorId === newDependency.predecessorId,
      )
    ) {
      return;
    }

    currentDependencies.setValue([...currentDependencies.value, newDependency]);
  }

  /** Updates right group cells. */
  private updateEntries(): void {
    const dateFrom = this.interval.start;
    const dateTo = this.interval.end;
    const scale = this.settings.planningScale;

    if (this.fteScheduleSubscription) {
      this.fteScheduleSubscription.unsubscribe();
    }

    this.blockUI.start();
    this.fteScheduleSubscription = this.fteScheduleService
      .getSchedule(
        this.dataService.project.schedule.id,
        scale,
        dateFrom.toISODate(),
        dateTo.toISODate(),
      )
      .subscribe({
        next: (schedule) => {
          const entries = [];
          for (const slot of this.slots) {
            const fteHours =
              schedule.find((s) => s.date === slot.date.toISODate())?.hours ??
              0;
            entries.push({
              date: slot.date.toISODate(),
              id: slot.id,
              nonWorking: fteHours === 0,
              fteHours,
            });
          }
          this.entriesSubject.next(entries);
          this.updateTasks();
          this.blockUI.stop();
        },
        error: (error: Exception) => {
          this.entriesSubject.next([]);
          this.blockUI.stop();

          this.notification.error(error.message);
        },
      });
  }

  /** Updates slots. */
  private updateSlots(): void {
    const slotInfo = this.scheduleNavigationService.getSlots(
      this.interval,
      this.settings.planningScale,
    );

    this.slots = slotInfo.slots;
    this.slotGroups = slotInfo.groups;
  }

  /**
   * Returns timeline interval for project dates.
   *
   * @returns time interval.
   */
  private getDefaultProjectInterval(): Interval {
    const planningScale = this.settings.planningScale;
    return this.getTimelineInterval(planningScale);
  }

  /**
   * Returns interval for project task timeline for selected scale and interval.
   *
   * @param scale scale of interval.
   * @param date target date.
   *
   * @returns interval for timeline view.
   */
  private getTimelineInterval(scale: PlanningScale, date?: DateTime): Interval {
    const today = DateTime.now().startOf('day');
    const projectStartDate = this.projectStartDate ?? today;
    const projectEndDate = this.projectEndDate ?? today;

    let leftGap: number;
    let rightGap: number;
    if (!date) {
      if (+today < +projectStartDate) {
        date = projectStartDate;
        leftGap = this.smallGap;
        rightGap = this.bigGap;
      }
      if (+today >= +projectStartDate && +today <= +projectEndDate) {
        date = today;
        leftGap = this.smallGap;
        rightGap = this.bigGap;
      }
      if (+today > +projectEndDate) {
        date = projectEndDate;
        leftGap = this.bigGap;
        rightGap = this.smallGap;
      }
    } else {
      if (+date <= +projectEndDate) {
        leftGap = this.smallGap;
        rightGap = this.bigGap;
      } else {
        leftGap = this.bigGap;
        rightGap = this.smallGap;
      }
    }

    let startPeriod: DateTime = date;
    let endPeriod: DateTime = date;
    switch (scale) {
      case PlanningScale.Day:
        startPeriod = date.minus({ days: leftGap }).startOf('week');
        endPeriod = date.plus({ days: rightGap }).endOf('week');
        break;
      case PlanningScale.Week:
        startPeriod = date.startOf('week').minus({ weeks: leftGap });
        endPeriod = date.endOf('week').plus({ weeks: rightGap });
        break;
      case PlanningScale.Month:
        startPeriod = date.startOf('month').minus({ weeks: leftGap });
        endPeriod = date.endOf('month').plus({ weeks: rightGap });
        break;
    }
    return Interval.fromDateTimes(startPeriod, endPeriod);
  }

  /**
   * Scroll to date.
   *
   * @param toDate target date.
   * @note If auto scroll to selector will not work, possible to calculate scroll position in px by slot information.
   */
  private scrollToSelector(toDate: DateTime): void {
    switch (this.settings.planningScale) {
      case PlanningScale.Week:
        toDate = toDate.startOf('week');
        break;
      case PlanningScale.Month:
        toDate = toDate.startOf('month');
        break;
    }
    const selectorToScroll = toDate
      ? '[data-slot-date="' + toDate.toISODate() + '"]'
      : 'th.slot-active';
    const scrollOption: FreezeTableScrollOptions = {
      selector: selectorToScroll,
      behavior: 'auto',
    };
    this.freezeTableService.redraw();
    this.freezeTableService.autoScroll(scrollOption);
  }

  /** Translates task hint names. */
  private translateTaskHintNames(): void {
    Object.keys(this.taskHintNames).forEach((key) => {
      const keyToTranslate =
        key === 'projectTaskDuration' || key === 'percentComplete'
          ? key + '.value'
          : key;
      this.taskHintNames[key] = this.translateService.instant(
        `shared2.props.${keyToTranslate}`,
      );
    });
  }
}
