import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { UntypedFormBuilder } from '@angular/forms';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { sortBy } from 'lodash';
import { Constants } from 'src/app/shared/globals/constants';

@Component({
  selector: 'wp-adding-skills-modal',
  templateUrl: './adding-skills-modal.component.html',
  styleUrls: ['./adding-skills-modal.component.scss'],
})
export class AddingSkillsModalComponent implements OnInit {
  public loadLimit = 50;

  @Input() userId: string;

  /** Список ИД навыков, уже выбранных для пользователя. */
  @Input() currentSkills: string[];

  public isSaving: boolean;
  public isLoading: boolean;
  public loadedPartly: boolean;

  @ViewChild('leftTbl') leftTbl: ElementRef;
  @ViewChild('rightTbl') rightTbl: ElementRef;

  public leftTableStyles: Dictionary<string> = {};
  public rightTableStyles: Dictionary<string> = {};

  public availableSkills: NamedEntity[] = [];

  public selectedSkills: NamedEntity[] = [];

  public filterForm = this.fb.group({ name: [''] });

  loadingSubscription: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
  ) {}

  public resizeLeftTbl() {
    this.leftTableStyles['display'] = 'table';
    this.leftTableStyles['width'] =
      (this.leftTbl.nativeElement as HTMLElement).getBoundingClientRect()
        .width + 'px';
  }

  public resizeRightTbl() {
    this.rightTableStyles['display'] = 'table';
    this.rightTableStyles['width'] =
      (this.rightTbl.nativeElement as HTMLElement).getBoundingClientRect()
        .width + 'px';
  }

  public selectSkill(skill: any, index: number) {
    if (this.selectedSkills.length > 29) {
      this.notification.warningLocal(
        'employees.card.addingSkills.messages.max30ForAdding',
      );
      return;
    }

    this.selectedSkills.push(skill);
    this.selectedSkills = sortBy(this.selectedSkills, ['name']);
    this.availableSkills.splice(index, 1);
  }

  removeSkill(skill: any, index: number) {
    this.availableSkills.push(skill);
    this.selectedSkills.splice(index, 1);

    this.availableSkills = sortBy(this.availableSkills, ['name']);
  }

  public ok() {
    this.isSaving = true;

    const observables: Observable<any>[] = [];
    this.selectedSkills.forEach((skill: any) => {
      const userSkill = {
        skillId: skill.id,
        userId: this.userId,
      };

      observables.push(this.data.collection('UserSkills').insert(userSkill));
    });

    forkJoin(observables)
      .pipe()
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.notification.successLocal(
            'employees.card.addingSkills.messages.addingCompleted',
          );
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }

  private load() {
    this.availableSkills = [];
    this.isLoading = true;
    this.loadedPartly = false;

    const query = {
      orderBy: ['name'],
      select: ['id', 'name'],
      top: this.loadLimit,
      filter: [{ isActive: true }] as any[],
    };

    if (this.currentSkills && this.currentSkills.length > 0) {
      const inString = this.currentSkills.join(',');
      query.filter.push(`not (id in (${inString}))`);
    }

    const filter = this.filterForm.value;

    if (filter.name) {
      query.filter.push({
        or: [
          {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'tolower(name)': {
              contains: (filter.name as string)
                .substring(0, Constants.filterInputLengthLimit)
                .toLowerCase(),
            },
          },
        ],
      });
    }

    if (this.selectedSkills.length > 0) {
      this.selectedSkills.forEach((skill: NamedEntity) => {
        query.filter.push({
          id: { ne: { type: 'guid', value: skill.id } },
        });
      });
    }

    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription = this.data
      .collection('Skills')
      .query<NamedEntity[]>(query)
      .subscribe({
        next: (data) => {
          this.availableSkills = data;
          this.isLoading = false;
          this.loadedPartly = this.availableSkills.length === this.loadLimit;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoading = false;
        },
      });
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ngOnInit() {
    this.load();
    this.filterForm.valueChanges.subscribe(() => this.load());
  }
}
