<tmt-form-header [name]="(service.request$ | async)?.name" [isEditable]="false"></tmt-form-header>

<wp-loading-indicator [state]="service.state$ | async"></wp-loading-indicator>

<tmt-not-found [state]="service.state$ | async"></tmt-not-found>

@if ((service.state$ | async) === 'Ready') {
  <div class="subheader">
    <div class="subheader__props">
      <div class="subheader__prop">
        <wp-state-indicator />
      </div>

      <div class="subheader__prop">
        <span class="subheader__label">{{ 'resources.requests.props.project' | translate }}:</span>
        <div class="subheader__value wp-action" (click)="openProjectInfo()" id="project">
          {{ service.request.project?.name }}
        </div>
      </div>

      <div class="subheader__prop">
        <span class="subheader__label"
          >{{ 'resources.requests.props.createdBy' | translate }}:</span
        >
        <div class="subheader__value wp-action" (click)="openOwnerInfo()" id="owner">
          {{ service.request?.createdBy?.name }}
        </div>
      </div>

      <div class="subheader__prop">
        <tmt-workflow-indicator />
      </div>
    </div>
  </div>
}

<ng-container *ngIf="(service.state$ | async) === 'Ready'">
  <ul *ngIf="tabs.length" class="nav nav-tabs">
    <li *ngFor="let tab of tabs" class="nav-item">
      <a
        class="nav-link"
        [class.active]="stateService?.current?.name === tab.state"
        [uiSref]="tab.state"
        [uiParams]="{ routeMode: 'keep' }"
      >
        {{ tab.header | translate }}
      </a>
    </li>
  </ul>

  <ui-view></ui-view>
</ng-container>
