<form [formGroup]="form" class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'expenses.card.setProject.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>
        {{ 'expenses.card.props.project' | translate }}
      </label>
      <wp-select-box
        placeholder="{{ 'expenses.card.props.project' | translate }}"
        formControlName="project"
        [values]="projectList"
      ></wp-select-box>
    </div>
    <div class="form-text">
      <p>
        {{ 'expenses.card.setProject.labels.projectChangeNotes' | translate }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.ok' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
