<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

<form class="modal-form" novalidate [formGroup]="form" ngDraggable [hidden]="isLoading">
  <div class="modal-header">
    <h3 *ngIf="programId" class="modal-title">{{ form?.value.name }}</h3>
    <h3 *ngIf="!programId" class="modal-title">
      {{ 'projects.programs.settings.newProgram' | translate }}
    </h3>

    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <div class="form-group" tmtIndicator>
          <label>{{ 'projects.programs.settings.props.name.label' | translate }}</label>
          <wp-text-box
            placeholder="{{ 'projects.programs.settings.props.name.ph' | translate }}"
            formControlName="name"
          ></wp-text-box>
        </div>

        <table>
          <tr>
            <td>
              <div class="form-group">
                <label>{{ 'projects.planStart' | translate }}</label>
                <wp-date-box formControlName="startDate"></wp-date-box>
              </div>
            </td>
            <td style="padding-left: 30px">
              <div class="form-group">
                <label>{{ 'projects.planEnd' | translate }}</label>
                <wp-date-box formControlName="endDate"></wp-date-box>
              </div>
            </td>
          </tr>
        </table>

        <div class="form-group">
          <label>{{ 'projects.programs.settings.props.description.label' | translate }}</label>
          <wp-multiline-text-box
            formControlName="description"
            placeholder="{{ 'projects.programs.settings.props.description.ph' | translate }}"
            rows="4"
          >
          </wp-multiline-text-box>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>{{ 'shared.props.code' | translate }}</label>
          <wp-text-box
            placeholder="{{ 'shared.props.code' | translate }}"
            style="width: 150px"
            formControlName="code"
          ></wp-text-box>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <hr />
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group" tmtIndicator>
          <label>{{ 'projects.programs.settings.props.manager.label' | translate }}</label>
          <wp-user-box
            placeholder="{{ 'projects.programs.settings.props.manager.ph' | translate }}"
            formControlName="manager"
          ></wp-user-box>
        </div>
      </div>
      <div class="col-6">
        <div
          class="form-check"
          style="padding-top: 30px"
          title="{{ 'projects.programs.settings.props.skipManagerApprove' | translate }}"
        >
          <input
            formControlName="skipManagerApprove"
            type="checkbox"
            class="form-check-input"
            id="skipManagerApprove"
          />
          <label class="form-check-label" for="skipManagerApprove">
            {{ 'projects.programs.settings.props.skipManagerApprove' | translate }}
          </label>
        </div>
      </div>
    </div>

    <wp-custom-fields [formGroup]="form" entityType="Program"></wp-custom-fields>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      *ngIf="!readonly"
      [isBusy]="isSaving"
    >
      {{ okButtonText }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
