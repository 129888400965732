<div class="badge-container gap-2">
  <div
    class="badge badge-trim"
    [ngClass]="'text-bg-' + value.fromState.style"
    [title]="value.fromState.name"
  >
    {{ value.fromState.name }}
  </div>
  <i class="bi bi-arrow-right standard-size"></i>
  <div
    class="badge badge-trim"
    [ngClass]="'text-bg-' + value.toState.style"
    [title]="value.toState.name"
  >
    {{ value.toState.name }}
  </div>
</div>
