import { Injectable } from '@angular/core';
import { Interval } from 'luxon';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { ProjectSummaryFilterSettings } from 'src/app/project-summary/shared/project-summary-filter/models/project-summary-filter.settings';
import { Exception } from 'src/app/shared/models/exception';
import {
  ProjectSummaryFilter,
  ProjectSummaryPageDto,
  ProjectSummaryResourcePlanDto,
} from 'src/app/project-summary/models/project-summary-data.model';
import { ProjectSummaryEntryMap } from 'src/app/project-summary/models/project-summary-view.model';

/** Provides methods to work with Project Summary REST API. */
@Injectable()
export class ProjectSummaryDataService {
  /** Gets Value Mode. */
  public get valueMode(): ValueMode {
    return this._valueMode;
  }

  /** Sets Value Mode. */
  public set valueMode(value: ValueMode) {
    this._valueMode = value;
  }

  /** Gets Project Summary Pages. */
  public get summaryPages(): ProjectSummaryPageDto[] {
    return this._summaryPages;
  }

  /** Gets Project Summary Entries. */
  public get entries(): ProjectSummaryEntryMap {
    return this._entries;
  }

  private _valueMode: ValueMode;
  private _summaryPages: ProjectSummaryPageDto[];
  private _entries: ProjectSummaryEntryMap;

  constructor(private data: DataService) {}

  /** Inits Service. */
  public init(): void {
    this._summaryPages = [];
    this._entries = {};
  }

  /**
   * Loads Project Summary page.
   *
   * @param pageNumber Page Number.
   * @param pageSize Page Size.
   * @param filter Filter.
   * @param kpiTypes KPI Types.
   * @param valueMode Value Mode.
   * @returns {ProjectSummaryPageDto} list observable.
   * */
  public loadProjectSummaryPage(
    pageNumber: number,
    pageSize: number,
    filter: ProjectSummaryFilterSettings,
    kpiTypes: KpiType[],
    valueMode: ValueMode,
  ): Observable<ProjectSummaryPageDto[]> {
    const params: Record<string, any> = {
      pageNumber,
      pageSize,
      kpiTypes: '@kpiTypes',
      valueMode: `'${valueMode}'`,
      filter: '@filter',
    };

    const filterObject = <ProjectSummaryFilter>{
      projectId: filter?.project?.id || null,
      managerId: filter?.user?.id || null,
      searchText: filter?.searchText || null,
      projectStateIds: filter.projectStates?.map((state) => state.id) ?? [],
      programId: filter?.program?.id || null,
      clientId: filter?.client?.id || null,
    };

    const urlParams: Record<string, string> = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@filter': JSON.stringify(filterObject),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@kpiTypes': JSON.stringify(kpiTypes),
    };

    return this.data.model
      .function('GetProjectSummaryPage')
      .query<ProjectSummaryPageDto[]>(params, null, urlParams)
      .pipe(
        catchError((error: Exception) => throwError(() => error)),
        map((val) => {
          const projects = val as ProjectSummaryPageDto[];
          this._summaryPages = this._summaryPages.concat(projects);
          return projects;
        }),
      );
  }

  /**
   * Loads Project Summary entries frame.
   *
   * @param projectIds Project IDs.
   * @param interval Interval.
   * @param planningScale Planning Scale.
   * @param kpiTypes KPI Types.
   * @param valueMode Value Mode.
   * @returns {Observable} after list merged with new frame.
   * */
  public loadFrame = (
    projectIds: string[],
    interval: Interval,
    planningScale: PlanningScale,
    kpiTypes: KpiType[],
    valueMode: ValueMode,
  ): Observable<void> =>
    this.loadProjectSummaryEntries(
      projectIds,
      interval,
      planningScale,
      kpiTypes,
      valueMode,
    ).pipe(map(() => void 0));

  /**
   * Loads Project Summary entries.
   *
   * @param projectIds Project IDs.
   * @param interval Interval.
   * @param planningScale Planning Scale.
   * @param kpiTypes KPI Types.
   * @param valueMode Value Mode.
   * @returns {ProjectSummaryResourcePlanDto} list observable.
   * */
  public loadProjectSummaryEntries(
    projectIds: string[],
    interval: Interval,
    planningScale: PlanningScale,
    kpiTypes: KpiType[],
    valueMode: ValueMode,
  ): Observable<ProjectSummaryResourcePlanDto[]> {
    if (!projectIds.length || !kpiTypes.length) {
      return of([]);
    }

    const params: Record<string, any> = {
      projectIds: '@projectIds',
      from: interval.start.toISODate(),
      to: interval.end.toISODate(),
      planningScale: `'${planningScale}'`,
      kpiTypes: '@kpiTypes',
      valueMode: `'${valueMode}'`,
    };

    const urlParams: Record<string, string> = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@projectIds': JSON.stringify(projectIds),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@kpiTypes': JSON.stringify(kpiTypes),
    };

    return this.data.model
      .function('GetProjectSummaryEntries')
      .query<ProjectSummaryResourcePlanDto[]>(params, null, urlParams)
      .pipe(
        catchError((error: Exception) => throwError(() => error)),
        map((val) => {
          const entries = val;
          entries.forEach((entry) => {
            this.entries[entry.projectId] ??= {};
            this.entries[entry.projectId][entry.teamMemberId] ??= [];
            const existedEntry = this.entries[entry.projectId][
              entry.teamMemberId
            ].find((e) => JSON.stringify(e) === JSON.stringify(entry));
            if (!existedEntry) {
              this.entries[entry.projectId][entry.teamMemberId].push(entry);
            }
          });
          return entries;
        }),
      );
  }

  /**
   * Gets Project Summary entries.
   *
   * @param projectId Project ID.
   * @param teamMemberId Team Member ID.
   * @returns {ProjectSummaryResourcePlanDto} list.
   * */
  public getProjectSummaryEntries(
    projectId: string,
    teamMemberId: string,
  ): ProjectSummaryResourcePlanDto[] {
    if (!this.entries[projectId] || !this.entries[projectId][teamMemberId]) {
      return [];
    }
    return this.entries[projectId][teamMemberId];
  }
}
