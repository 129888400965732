import {
  Component,
  OnInit,
  Input,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { VIEW_NAME } from 'src/app/shared/tokens';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Component({
  selector: 'tmt-timesheet-filter',
  templateUrl: './timesheet-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetFilterComponent implements OnInit, FilterDetails {
  @Input() public values: any;

  public detailsForm = this.fb.group({
    period: null,
    user: null,
    department: null,
    onlySubordinates: false,
    states: null,
  });

  constructor(
    @Inject(VIEW_NAME) public viewName: string,
    public filterService: FilterService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.filterService.resetValues$.subscribe(() => {
      this.detailsForm.reset();
    });

    this.detailsForm.patchValue(this.filterService.values);
    this.detailsForm.valueChanges.subscribe(() => {
      const values = Object.assign(
        this.filterService.values,
        this.detailsForm.value,
      );
      this.filterService.changeValues(values);
    });
  }
}
