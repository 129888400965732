import {
  Component,
  OnInit,
  Input,
  Optional,
  Inject,
  ViewChildren,
  QueryList,
  AfterViewInit,
  DestroyRef,
  inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder } from '@angular/forms';
import { AppService } from 'src/app/core/app.service';
import { filterResourceTypes } from 'src/app/projects/card/project-team/add-resources-modal/models/filter-resource-types';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import {
  BoxControlComponent,
  FormHelper,
} from 'src/app/shared/helpers/form-helper';
import { VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'tmt-resources-filter',
  templateUrl: './resources-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesFilterComponent
  implements OnInit, FilterDetails, AfterViewInit
{
  @Input() values: any;
  @ViewChildren('cascadeControl')
  private cascadeControls: QueryList<BoxControlComponent>;

  public detailsForm = this.fb.group({
    department: null,
    role: null,
    resourcePool: null,
    legalEntity: null,
    competence: null,
    level: null,
    grade: null,
    location: null,
    resourceTypes: this.fb.group({
      user: false,
      department: false,
    }),
  });

  public readonly filterResourceTypes = filterResourceTypes;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Optional() @Inject(VIEW_NAME) public viewName: string,
    public app: AppService,
    private fb: UntypedFormBuilder,
    public service: FilterService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    this.service.resetValues$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.detailsForm.reset();
      });

    this.detailsForm.patchValue(this.service.values);
    this.detailsForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const values = Object.assign(
          this.service.values,
          this.detailsForm.value,
        );
        this.service.changeValues(values);
      });
  }

  public ngAfterViewInit(): void {
    FormHelper.cascadeDependency(
      this.detailsForm,
      this.cascadeControls,
      [
        [
          {
            controlName: 'role',
          },
          {
            controlName: 'competence',
            dependedProperty: 'roleId',
          },
        ],
        [
          {
            controlName: 'level',
          },
          { controlName: 'grade', dependedProperty: 'levelId' },
        ],
      ],
      takeUntilDestroyed(this.destroyRef),
    );
    this.cdr.detectChanges();
  }
}
