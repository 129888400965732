import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  asyncScheduler,
  of,
  throttleTime,
} from 'rxjs';
import { GridColumn } from 'src/app/shared-features/grid2/models/grid-column.interface';
import { Order } from 'src/app/shared/models/inner/order';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  Command,
  Grid2Options,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { checkChangesDebounceTime } from 'src/app/shared-features/grid2/models/grid-constants';
import { MenuService } from 'src/app/core/menu.service';

/** Grid management service. */
@Injectable()
export class GridService {
  private detectChangesSubject = new Subject<void>();
  public detectChanges$ = this.detectChangesSubject.asObservable().pipe(
    throttleTime(checkChangesDebounceTime, asyncScheduler, {
      leading: false,
      trailing: true,
    }),
  );

  /** Event of sorting change. */
  private _order: Order;
  public get order(): Order {
    return this._order;
  }
  private orderSubject = new Subject<Order>();
  public order$ = this.orderSubject.asObservable();

  /** Event of grid loading status. */
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  /** Event of grid readonly mode. */
  private readonlySubject = new BehaviorSubject<boolean>(false);
  public readonly$ = this.readonlySubject.asObservable();
  public readonly: boolean;

  public selectedGroup$ = new BehaviorSubject<UntypedFormGroup>(null);
  /** Raw value of current selected group. */
  public get selectedGroupValue(): any {
    return this.selectedGroup$.getValue()?.getRawValue();
  }
  public selectedGroups$ = new BehaviorSubject<UntypedFormGroup[]>([]);
  public get selectedGroupsValue(): any[] {
    return this.selectedGroups$.getValue().map((group) => group.getRawValue());
  }
  /** Checks is all group are selected. */
  public get isAllSelected(): boolean {
    return (
      this.formArray &&
      this.formArray.length &&
      this.formArray.length === this.selectedGroups$.getValue().length
    );
  }
  /** Displayed data. */
  public formArray: UntypedFormArray;
  /** Current grid option. Available to read. */
  public gridOptions: Grid2Options | null = null;

  public commands: Command[] = [];
  private commandSubject = new Subject<string>();
  public command$ = this.commandSubject.asObservable();

  constructor(private menuService: MenuService) {}

  /**
   * Selects one formGroup.
   *
   * @param group group to selection.
   */
  public selectGroup: (group: UntypedFormGroup) => void;

  /**
   * Adds formGroup to selection.
   *
   * @param group group to add in selection.
   */
  public addGroupToSelected: (group: UntypedFormGroup) => void;

  /**
   * Removes formGroup from selection.
   *
   * @param group group to add in selection.
   */
  public removeGroupFromSelected: (group: UntypedFormGroup) => void;

  /** Clears group selection. */
  public clearSelectedGroups: () => void;

  /**
   * Sets active control(cell).
   *
   * @param control control to set as active.
   */
  public setActiveControl: (control: UntypedFormControl | null) => void;

  /**
   * Sets nodal selected control(cell).
   *
   * @param control control to set as nodal selected.
   */
  public setNodalSelectedControl: (control: UntypedFormControl | null) => void;

  /** Clears selected range cells values in "range" selection mode. */
  public clearSelectedRangeCells: () => void;

  /** Inits change detection. */
  public detectChanges(): void {
    this.detectChangesSubject.next();
  }

  /**
   * Sets readonly state.
   *
   * @param value readonly or not.
   */
  public setReadonlyState(value: boolean): void {
    this.readonlySubject.next(value);
    this.readonly = value;
  }

  /**
   * Sets loading state.
   *
   * @param value loading or not.
   */
  public setLoadingState(value: boolean): void {
    this.loadingSubject.next(value);
  }

  /**
   * Sorts by column name.
   *
   * @param column column to sorting.
   */
  public sort(column: GridColumn): void {
    if (this._order.column === column.name) {
      if (!this._order.reverse) {
        this._order.reverse = true;
      } else {
        this._order.column = undefined;
      }
    } else {
      this._order.column = column.name;
      this._order.reverse = false;
    }

    this.orderSubject.next(this._order);
  }

  /**
   * Sets order of sorting.
   *
   * @param order sorting order.
   */
  public setOrder(order: Order): void {
    this._order = order;
  }

  /**
   * Executes grid command.
   *
   * @param name command name.
   * @param param optional command parameter.
   */
  public execute(name: string, param?: any): void {
    this.commandSubject.next(name);

    const command = this.commands?.find((c: Command) => c.name === name);
    if (command && command.handlerFn) {
      command.handlerFn(param);
    }
  }

  /**
   * Checks can the command be executed.
   *
   * @param name command name.
   * @param param optional command parameter.
   * @returns can or not.
   */
  public canBeExecuted(name: string, param?: any): boolean {
    const command = this.commands.find((c: Command) => c.name === name);
    if (command && command.allowedFn) {
      return command.allowedFn(param);
    }
    return true;
  }

  /**
   * Returns the command for setting.
   *
   * @param name command name.
   * @returns command.
   */
  public getCommand(name: string): Command {
    const command = this.commands.find((c: Command) => c.name === name);
    return command;
  }

  /**
   * Opens context menu.
   *
   * @param event mouse event for opens context menu.
   * @param formGroup formGroup for using as context.
   */
  public openContextMenu(event: MouseEvent, formGroup: UntypedFormGroup): void {
    if (
      !this.gridOptions?.rowContextMenu ||
      !this.gridOptions.rowContextMenu.length ||
      !this.gridOptions.selectionType
    )
      return;

    if (!this.selectedGroupsValue.includes(formGroup)) {
      this.selectGroup(formGroup);
    }

    this.menuService.open(event, this.gridOptions.rowContextMenu, formGroup);

    event.preventDefault();
    event.stopPropagation();
  }
}
