import { Navigation } from '../models/navigation/navigation';
import { AppService } from '../../core/app.service';
import { Feature } from '../models/enums/feature.enum';
import { RESOURCE_REQUEST_LIST } from '../lists/resource-request.list';

export const PROJECTS_APP_NAVIGATION: Navigation = {
  name: 'projects',
  applicationTitle: 'shared.apps.projects',
  groups: [
    {
      name: 'projects',
      header: 'projects.navigation.projects.header',
      items: [
        {
          name: 'projects.myProjects',
          state: 'projects',
          header: 'projects.navigation.projects.my.header',
          hint: 'projects.navigation.projects.my.hint',
          entityType: 'Project',
          stateParams: {
            view: 'my',
          },
        },
        {
          name: 'projects.activeProjects',
          state: 'projects',
          header: 'projects.navigation.projects.active.header',
          hint: 'projects.navigation.projects.active.hint',
          entityType: 'Project',
          stateParams: {
            view: 'active',
          },
        },
        {
          name: 'projects.monitoringProjects',
          state: 'projects',
          header: 'projects.navigation.projects.monitoring.header',
          hint: 'projects.navigation.projects.monitoring.hint',
          entityType: 'Project',
          stateParams: {
            view: 'monitoring',
          },
        },
        {
          name: 'projects.allProjects',
          state: 'projects',
          header: 'projects.navigation.projects.all.header',
          hint: 'projects.navigation.projects.all.hint',
          entityType: 'Project',
          stateParams: {
            view: 'all',
          },
        },
      ],
    },
    {
      name: 'programs',
      header: 'projects.navigation.programs.header',
      items: [
        {
          name: 'projects.myPrograms',
          state: 'programs',
          header: 'projects.navigation.programs.my.header',
          hint: 'projects.navigation.programs.my.hint',
          entityType: 'Program',
          stateParams: {
            view: 'my',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.programs),
        },
        {
          name: 'projects.activePrograms',
          state: 'programs',
          header: 'projects.navigation.programs.active.header',
          hint: 'projects.navigation.programs.active.hint',
          entityType: 'Program',
          stateParams: {
            view: 'active',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.programs),
        },
        {
          name: 'projects.allPrograms',
          state: 'programs',
          header: 'projects.navigation.programs.all.header',
          hint: 'projects.navigation.programs.all.hint',
          entityType: 'Program',
          stateParams: {
            view: 'all',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.programs),
        },
      ],
    },
    {
      name: 'tasks',
      header: 'projects.navigation.clients.header',
      items: [
        {
          name: 'projects.myClients',
          state: 'clients',
          header: 'projects.navigation.clients.my.header',
          hint: 'projects.navigation.clients.my.hint',
          entityType: 'Organization',
          stateParams: {
            view: 'my',
          },
        },
        {
          name: 'projects.activeClients',
          state: 'clients',
          header: 'projects.navigation.clients.active.header',
          hint: 'projects.navigation.clients.active.hint',
          entityType: 'Organization',
          stateParams: {
            view: 'active',
          },
        },
        {
          name: 'projects.allClients',
          state: 'clients',
          header: 'projects.navigation.clients.all.header',
          hint: 'projects.navigation.clients.all.hint',
          entityType: 'Organization',
          stateParams: {
            view: 'all',
          },
        },
      ],
    },
    {
      name: 'resources',
      header: 'projects.navigation.resources.header',
      items: [
        {
          header: 'projects.navigation.resources.booking.header',
          hint: 'projects.navigation.resources.booking.hint',
          state: 'booking',
          name: 'projects.booking',
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.booking),
        },
        {
          header: 'projects.navigation.resources.myOpen.header',
          hint: 'projects.navigation.resources.myOpen.hint',
          state: 'resourceRequests',
          name: 'projects.myOpenRequests',
          entityType: 'ResourceRequest',
          indicator: {
            hint: '',
            list: RESOURCE_REQUEST_LIST,
            viewName: 'myOpen',
          },
          stateParams: {
            view: 'myOpen',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.booking),
        },
        {
          header: 'projects.navigation.resources.all.header',
          hint: 'projects.navigation.resources.all.hint',
          state: 'resourceRequests',
          name: 'projects.allRequests',
          entityType: 'ResourceRequest',
          stateParams: {
            view: 'all',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.booking),
        },
        {
          header: 'resources.navigation.header',
          hint: 'resources.navigation.header',
          state: 'resources',
          name: 'projects.resources',
        },
      ],
    },
    {
      name: 'finance',
      header: 'projects.navigation.finance.header',
      items: [
        {
          name: 'projects.expensesRequests',
          state: 'expensesRequests',
          header: 'projects.navigation.finance.expenses.header',
          hint: 'projects.navigation.finance.expenses.hint',
          stateParams: {
            view: 'all',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.expenses),
        },
        {
          name: 'projects.actsOfAcceptance',
          state: 'actsOfAcceptance',
          header: 'projects.navigation.finance.acts.header',
          hint: 'projects.navigation.finance.acts.hint',
          entityType: 'ActOfAcceptance',
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.finance),
        },
        {
          name: 'projects.invoices',
          state: 'invoices',
          header: 'projects.navigation.finance.invoices.header',
          hint: 'projects.navigation.finance.invoices.hint',
          entityType: 'Invoice',
          stateParams: {
            view: 'projects-all',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.billing),
        },
      ],
    },
    {
      name: 'analytics',
      header: 'projects.navigation.analytics.header',
      items: [
        {
          name: 'projects.projectsSummary',
          state: 'projectsSummary',
          header: 'projects.navigation.analytics.projectSummary.header',
          hint: 'projects.navigation.analytics.projectSummary.hint',
        },
        {
          name: 'projects.usersSummary',
          state: 'usersSummary',
          header: 'projects.navigation.analytics.resourceSummary.header',
          hint: 'projects.navigation.analytics.resourceSummary.hint',
        },
      ],
    },
  ],
};
