import { Component, OnInit } from '@angular/core';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { StopwatchService } from 'src/app/core/stopwatch.service';
import { ChromeService } from 'src/app/core/chrome.service';
import { Stopwatch } from 'src/app/shared/models/entities/base/stopwatch.model';
import { TranslateService } from '@ngx-translate/core';
import { BlockUIService } from 'src/app/core/block-ui.service';

/** Сведения о счетчике времени (всплывающее окно). */
@Component({
  selector: 'wp-stopwatch-popup',
  templateUrl: './stopwatch-popup.component.html',
  styleUrls: ['./stopwatch-popup.component.scss'],
})
export class StopwatchPopupComponent implements OnInit {
  private width = 390;
  private height = 130;
  private containerId = 'wp-stopwatch-indicator';

  public hasProfileLink: boolean;
  public isShown: boolean;
  public containerStyles: Dictionary<string> = {};
  public swExecuted: boolean;
  public isPaused: boolean;
  public clientAndProjectName: string;
  public taskName: any;

  public stopwatch: Stopwatch;
  loading: boolean;

  constructor(
    private blockUI: BlockUIService,
    private translate: TranslateService,
    private chrome: ChromeService,
    private stopwatchService: StopwatchService,
  ) {}

  private updateContainer() {
    const element = document.getElementById(this.containerId);
    const rect = element.getBoundingClientRect();
    this.containerStyles['left'] =
      rect.left + rect.width / 2 - this.width + 30 + 'px';

    this.containerStyles['top'] = rect.bottom + 13 + 'px';
    this.containerStyles['width'] = this.width + 'px';
    this.containerStyles['height'] = this.height + 'px';
  }

  /** Открыть диалог. */
  private open() {
    this.loading = true;
    this.isShown = true;
    this.stopwatchService.updateStopwatch().then(() => {
      this.loading = false;
    });
    this.updateContainer();
  }

  /** Закрыть диалог. */
  public close = () => {
    this.isShown = false;
  };

  /** Приостановить счетчик. */
  public pause() {
    this.swExecuted = true;

    this.stopwatchService.pause().then(
      () => {
        this.swExecuted = false;
        this.updateUI();
      },
      () => {
        this.swExecuted = false;
        this.updateUI();
      },
    );
  }

  /** Возобновить счетчик. */
  public resume() {
    this.swExecuted = true;
    this.stopwatchService.resume().then(
      () => {
        this.swExecuted = false;
        this.updateUI();
      },
      () => {
        this.swExecuted = false;
        this.updateUI();
      },
    );
  }

  /** Завершить счетчик. */
  public stop(type) {
    this.swExecuted = true;
    this.blockUI.start();
    this.stopwatchService.stop(type).then(
      () => {
        this.swExecuted = false;
        this.blockUI.stop();
        this.close();
      },
      () => {
        this.swExecuted = false;
        this.blockUI.stop();
        this.updateUI();
      },
    );
  }

  private updateUI() {
    const stopwatch = this.stopwatch;
    if (stopwatch.project) {
      let clientAndProjectString = '';

      if (stopwatch.showClient) {
        clientAndProjectString =
          stopwatch.client?.name ??
          '[' +
            this.translate.instant(
              'timesheets.card.taskSelector.withoutClient',
            ) +
            ']';

        if (!stopwatch.projectTaskIsMain) {
          clientAndProjectString += ' > ';
        }
      }

      if (!stopwatch.projectTaskIsMain) {
        clientAndProjectString += stopwatch.project.name;
      }

      this.taskName = stopwatch.projectTask?.name;
      this.clientAndProjectName = clientAndProjectString;
    }
  }

  ngOnInit(): void {
    this.stopwatchService.openPopup$.subscribe((param) => {
      this.open();
    });

    this.chrome.mainAreaSize$.subscribe(() => {
      if (this.isShown) {
        this.updateContainer();
      }
    });

    this.chrome.scroll$.subscribe(() => {
      if (this.isShown) {
        this.updateContainer();
      }
    });

    this.stopwatchService.stopwatch$.subscribe((stopwatch) => {
      this.isPaused = stopwatch?.state === 'Paused';
      this.stopwatch = stopwatch;

      if (!stopwatch) {
        this.close();
      } else {
        this.updateUI();
      }
    });
  }
}
