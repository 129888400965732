/** View of project task module. */
export enum ProjectTaskView {
  table = 'table',
  timeline = 'timeline',
}

/** View of table for using with grid list service.  */
export enum ProjectTaskTableView {
  timelineDefault = 'timelineDefault',
  timelineNoAutoPlanning = 'timelineNoAutoPlanning',
  tableDefault = 'tableDefault',
  tableNoAutoPlanning = 'tableNoAutoPlanning',
}
