import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, tap } from 'rxjs/operators';
import { TransitionService } from '@uirouter/core';

@Injectable()
export class CommentsSaveService implements OnDestroy {
  private confirmSubject = new Subject<boolean>();
  public confirm$: Observable<boolean> = this.confirmSubject.asObservable();

  private onTransitionStartListener;
  private destroyed = new Subject<void>();
  private changingQueue: Record<'key', string>[] = [];

  public get queueLength(): number {
    return this.changingQueue.length;
  }

  constructor(
    private translate: TranslateService,
    private transitionService: TransitionService,
  ) {
    this.initSubscribes();
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.onTransitionStartListener();
  }

  /**
   * Adds entity to queue.
   *
   * @param id Any `entityId`.
   *
   * */
  public addToQueue(id: string): void {
    this.removeFromQueue(id);
    this.changingQueue.push({ key: id });
  }

  /**
   * Removes entity from queue.
   *
   * @param id Any `entityId`.
   *
   * */
  public removeFromQueue(id: string): void {
    const itemIndex = this.changingQueue.findIndex((task) => task.key === id);

    if (itemIndex > -1) {
      this.changingQueue.splice(itemIndex, 1);
    }
  }

  /**
   * Shows confirm message with "leave" warning.
   *
   * @return Result of window confirm.
   *
   * */
  public getConfirmResult(): boolean {
    return window.confirm(
      this.translate.instant('shared.comments.notifications.leave'),
    );
  }

  private initSubscribes(): void {
    fromEvent(window, 'beforeunload')
      .pipe(
        tap((event: Event) => {
          if (this.changingQueue.length) {
            event.preventDefault();
            const message = this.translate.instant('shared.leavePage');
            event.returnValue = message;
            return message;
          }
        }),
        takeUntil(this.destroyed),
      )
      .subscribe(() => null);

    this.onTransitionStartListener = this.transitionService.onStart(
      {},
      (transition) => {
        if (this.changingQueue.length) {
          this.confirmSubject.next(true);

          if (!this.getConfirmResult()) {
            transition.abort();
            this.confirmSubject.next(false);
          }
        }
      },
    );
  }
}
