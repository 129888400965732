import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';

import { ProjectTaskDependenciesService } from 'src/app/projects/card/project-tasks/core/project-task-dependencies.service';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { ProjectTeamService } from 'src/app/shared/services/project-team.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ScheduleService } from 'src/app/core/schedule.service';
import { projectTaskId, projectTasksJSON } from './mock-data';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { ProjectTasksCommandsService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/project-tasks-commands.service';

@Component({
  selector: 'tmt-predecessor-box-test',
  templateUrl: './predecessor-box-test.component.html',
  providers: [
    { provide: META_ENTITY_TYPE, useValue: 'Project' },
    LifecycleService,
    ProjectCardService,
    ProjectTasksDataService,
    ProjectTaskDependenciesService,
    ProjectTasksCommandsService,
    ProjectTeamService,
    SavingQueueService,
    ProjectVersionCardService,
    ScheduleService,
  ],
})
export class PredecessorBoxTestComponent implements OnInit {
  public form = this.fb.group({
    predecessor: [[], Validators.required],
    skills: [[], Validators.required],
    skillsTokens: [[], Validators.required],
    rolesDefaultMode: [[], Validators.required],
  });
  public formInputParams = this.fb.group({
    readonly: [false],
  });
  public taskId: string | null = projectTaskId;
  public tasks: ProjectTask[] = [];

  constructor(private fb: UntypedFormBuilder) {}

  public ngOnInit(): void {
    this.taskId = projectTaskId;
    this.tasks = JSON.parse(projectTasksJSON);
  }

  public setValue(): void {
    this.form.controls['predecessor'].setValue(
      this.tasks.find((t) => t.id === this.taskId).dependencies.slice(0),
    );

    this.form.controls['skills'].setValue([
      { name: 'Business Analysis', id: '1' },
      {
        name: 'No Analysis',
        id: '2',
      },
    ]);

    this.form.controls['skillsTokens'].setValue([
      { name: 'Business Analysis', id: '1' },
      {
        name: 'No Analysis',
        id: '2',
      },
    ]);

    this.form.controls['rolesDefaultMode'].setValue([
      { name: 'Role 1', id: '1', isDefault: true },
      { name: 'Role 2', id: '2', isDefault: false },
    ]);
  }
}
