<form class="modal-form" novalidate [formGroup]="form" ngDraggable autocomplete="off">
  <div class="modal-header">
    <h3 class="modal-title">
      {{
        editingCostCenter
          ? editingCostCenter.name
          : ('projects.projects.card.costCenters.modal.header.create' | translate)
      }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group" tmtIndicator>
      <label>{{ 'shared.props.name' | translate }}</label>
      <wp-text-box
        formControlName="name"
        placeholder="{{ 'shared.props.name' | translate }}"
      ></wp-text-box>
    </div>

    <div class="form-group" tmtIndicator>
      <label>{{ 'projects.projects.card.costCenters.modal.props.legalEntity' | translate }}</label>
      <wp-select-box
        formControlName="legalEntity"
        collection="LegalEntities"
        placeholder="{{ 'projects.projects.card.costCenters.modal.props.legalEntity' | translate }}"
      ></wp-select-box>
    </div>

    <div class="form-group form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
      <input id="isActive" formControlName="isActive" type="checkbox" class="form-check-input" />
      <label class="form-check-label" for="isActive">
        {{ 'shared.props.isActive.label' | translate }}
      </label>
    </div>

    <wp-custom-fields
      columnClasses="col-12"
      [formGroup]="form"
      [entityType]="'ProjectCostCenter'"
    ></wp-custom-fields>
  </div>
  <div class="modal-footer">
    @if (!projectCostCentersService.readonly) {
      <button type="button" class="btn btn-primary" (click)="ok()" wpButtonSpinner>
        {{
          editingCostCenter
            ? ('shared.actions.save' | translate)
            : ('shared.actions.create' | translate)
        }}
      </button>
    }
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
