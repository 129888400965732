import { Injectable } from '@angular/core';
import { DataService } from '../../core/data.service';

@Injectable({
  providedIn: 'root',
})
export class TenantSettingService {
  constructor(private data: DataService) {}

  public getPasswordRequirements() {
    return this.data.model
      .function('GetPasswordRequirementsText')
      .get<string>();
  }
}
