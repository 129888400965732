<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.projects.tariffs.addClientsTariffsModal.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving$ | async"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="toolbar-container">
      <wp-filter />
    </div>
    <div class="row">
      <div class="col d-relative">
        <table class="wp-nested-table wp-nested-table-hover fix-table" [ngStyle]="leftTableStyles">
          <thead>
            <tr>
              <th title="{{ 'shared.props.name' | translate }}">
                {{ 'shared.props.name' | translate }}
              </th>
              <th
                title="{{ 'projects.projects.tariffs.columns.value.hint' | translate }}"
                class="tariff-th"
              >
                {{ 'projects.projects.tariffs.columns.value.header' | translate }}
              </th>
              <th
                title="{{ 'shared.columns.effectiveDate.hint' | translate }}"
                class="effective-date-th"
              >
                {{ 'shared.columns.effectiveDate.title' | translate }}
              </th>
              <th title="{{ 'shared.props.description' | translate }}">
                {{ 'shared.props.description' | translate }}
              </th>
            </tr>
          </thead>
        </table>
        <div class="scroll-container">
          <table
            #leftTbl
            (resized)="resizeLeftTbl()"
            class="wp-nested-table wp-nested-table-hover position-relative"
          >
            <thead>
              <tr>
                <th title="{{ 'shared.props.name' | translate }}">
                  {{ 'shared.props.name' | translate }}
                </th>
                <th
                  title="{{ 'projects.projects.tariffs.columns.value.hint' | translate }}"
                  class="tariff-th"
                >
                  {{ 'projects.projects.tariffs.columns.value.header' | translate }}
                </th>
                <th
                  title="{{ 'shared.columns.effectiveDate.hint' | translate }}"
                  class="effective-date-th"
                >
                  {{ 'shared.columns.effectiveDate.title' | translate }}
                </th>
                <th title="{{ 'shared.props.description' | translate }}">
                  {{ 'shared.props.description' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              @for (tariff of availableTariffs; track tariff.id) {
                <tr (click)="selectTariff(tariff, $index)">
                  <td class="trim" title="{{ tariff.name }}">
                    {{ tariff.name }}
                  </td>
                  <td
                    class="text-cell"
                    title="{{
                      tariff['actualRate'].value | wpCurrency: tariff['actualRate'].currencyCode
                    }}"
                  >
                    {{ tariff['actualRate'].value | wpCurrency: tariff['actualRate'].currencyCode }}
                  </td>
                  <td
                    class="text-cell"
                    title="{{ tariff['actualRate'].effectiveDate | date: 'shortDate' }}"
                  >
                    {{ tariff['actualRate'].effectiveDate | date: 'shortDate' }}
                  </td>
                  <td class="text-cell" title="{{ tariff.description }}">
                    {{ tariff.description }}
                  </td>
                </tr>
              } @empty {
                @if (!(isLoading$ | async)) {
                  <tr>
                    <td colspan="4" class="text-center text-body-secondary">
                      {{ 'shared.noDisplayData' | translate }}
                    </td>
                  </tr>
                }
              }
            </tbody>
          </table>
          <wp-loading-indicator [loading]="isLoading$ | async" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="selected-resources-container">
          @for (tariff of selectedTariffs; track tariff.id) {
            <div class="selected-resource-block" (click)="removeTariff(tariff, $index)">
              <div class="trim" title="{{ tariff.name }}">
                {{ tariff.name }}
              </div>

              <button type="button" class="close" (click)="removeTariff(tariff, $index)">
                &times;
              </button>
            </div>
          } @empty {
            <div class="text-uppercase text-body-secondary no-data">
              {{ 'projects.projects.tariffs.addClientsTariffsModal.noDisplayData' | translate }}
            </div>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    @if (selectedTariffs.length) {
      <button
        type="button"
        class="btn btn-primary"
        (click)="addTariffsFromOrganization()"
        wpButtonSpinner
        [isBusy]="isSaving$ | async"
      >
        {{ 'shared.actions.add' | translate }}
      </button>
    }
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving$ | async">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
