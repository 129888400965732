import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { ReportPeriodService } from './report-period.service';
import { filter } from 'rxjs';

/** Компонента выбора периода отчета. */
@Component({
  selector: 'wp-report-period',
  templateUrl: './report-period.component.html',
})
export class ReportPeriodComponent implements OnInit {
  public form: UntypedFormGroup;
  public periodTitle: string;

  private destroyRef = inject(DestroyRef);

  constructor(
    private translate: TranslateService,
    private service: ReportPeriodService,
    private formBuilder: FormBuilder,
  ) {}

  public periodTitleChanged(title: string) {
    this.periodTitle = title;

    if (!this.periodTitle) {
      this.periodTitle = this.translate.instant(
        'analytics.reportPeriod.periodSelector.periodIsNotSelected',
      );
    }
    this.service.setPeriodTitle(this.periodTitle);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      period: null,
    });
    this.service.period$
      .pipe(
        filter((value) => value !== this.form.value.period),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((period) => {
        this.form.get('period').setValue(period, { emitEvent: false });
      });

    this.form
      .get('period')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.service.setPeriod(value);
      });
  }
}
