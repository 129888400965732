import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { UserInfo } from 'src/app/shared/models/entities/settings/user-info.model';
import { Exception } from 'src/app/shared/models/exception';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { ProjectCostCenter } from 'src/app/projects/card/project-cost-centers/models/project-cost-center.model';

@Injectable()
export class ExpensesRequestService {
  private projectList = new BehaviorSubject<NamedEntity[]>([]);
  public projectList$ = this.projectList.asObservable();

  public loadingState$ = new BehaviorSubject<boolean>(false);
  public setProject$ = new Subject<boolean>();

  constructor(
    private data: DataService,
    private message: MessageService,
  ) {}

  public loadProjects(user: NamedEntity) {
    const userInfo = new UserInfo();
    userInfo.id = user.id;
    userInfo.name = user.name;
    userInfo.isActive = user.isActive;
    this.loadUserInfoProjects(userInfo);
  }

  public loadUserInfoProjects(user: UserInfo) {
    if (!user) {
      return;
    }

    setTimeout(() => this.loadingState$.next(true));

    const query = {
      orderBy: 'name',
    };

    this.data
      .collection('Users')
      .entity(user.id)
      .function('WP.GetAssignedProjects')
      .query<NamedEntity[]>(null, query)
      .subscribe({
        next: (projects) => {
          this.loadingState$.next(false);
          this.projectList.next(projects);
        },
        error: (error: Exception) => {
          this.message.error(error.message);
          this.loadingState$.next(false);
        },
      });
  }

  /**
   * Load expense project cost centers.
   * @param userId id of user.
   * @param projectId id of selected project.
   * @returns project cost centers observable.
   */
  public loadUserProjectCostCenters(
    userId: string,
    projectId: string,
  ): Observable<ProjectCostCenter[]> {
    return this.data
      .collection('Users')
      .entity(userId)
      .function('GetAssignedProjectCostCenters')
      .query<ProjectCostCenter[]>({ projectId });
  }
}
