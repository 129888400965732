<div class="btn-group" role="group">
  <ng-container *ngIf="(projectCardService.project$ | async).financeViewAllowed">
    <input
      type="radio"
      class="btn-check"
      id="calendar-switcher"
      [formControl]="control"
      [value]="'calendar'"
    />
    <label class="btn btn-default" for="calendar-switcher">{{
      'projects.projects.card.rbc.view.calendar' | translate
    }}</label>
  </ng-container>

  <ng-container *ngIf="(projectCardService.project$ | async).revenueEstimateViewAllowed">
    <input
      type="radio"
      class="btn-check"
      id="revenue-switcher"
      [formControl]="control"
      [value]="'revenue'"
    />
    <label class="btn btn-default" for="revenue-switcher">{{
      'projects.projects.card.rbc.view.revenue' | translate
    }}</label>
  </ng-container>

  <ng-container *ngIf="(projectCardService.project$ | async).billingEstimateViewAllowed">
    <input
      type="radio"
      class="btn-check"
      id="billing-switcher"
      [formControl]="control"
      [value]="'billing'"
    />
    <label class="btn btn-default" for="billing-switcher">{{
      'projects.projects.card.rbc.view.billing' | translate
    }}</label></ng-container
  >
</div>
