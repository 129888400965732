<tmt-form-header [name]="invoice?.name" [isEditable]="false"></tmt-form-header>
<wp-loading-indicator [state]="service.state$ | async"></wp-loading-indicator>
<tmt-not-found [state]="service.state$ | async"></tmt-not-found>

<ng-container *ngIf="(service.state$ | async) === 'Ready'">
  <div class="subheader">
    <div class="subheader__props">
      <div class="subheader__prop">
        <wp-state-indicator />
      </div>

      <div class="subheader__prop">
        <span class="subheader__label">
          {{ 'billing.invoices.card.mainProps.client' | translate }}:
        </span>
        <a
          class="subheader__value"
          uiSref="client"
          [uiParams]="{ entityId: invoice?.organization?.id }"
          title="{{ invoice?.organization?.name }}"
        >
          {{ invoice?.organization?.name }}
        </a>
      </div>

      <div class="subheader__prop">
        <span class="subheader__label">
          {{ 'billing.invoices.card.mainProps.project' | translate }}:
        </span>
        <span id="project" class="subheader__value">
          <button
            type="button"
            (click)="openProjectInfo()"
            class="btn btn-link btn-link-content"
            title="{{ invoice?.project?.name }}"
          >
            {{ invoice?.project?.name }}
          </button>
        </span>
      </div>

      <div class="subheader__prop">
        <span class="subheader__label">
          {{ 'billing.invoices.card.mainProps.created' | translate }}:
        </span>
        <span class="subheader__value">
          {{ invoice?.created | date: 'shortDate' }}
        </span>
      </div>
    </div>
  </div>

  <form [formGroup]="form" class="form">
    <div class="block">
      <div class="row">
        <div class="col-7 first-col">
          <div class="form-group" tmtIndicator>
            <label>
              {{ 'billing.invoices.card.props.template' | translate }}
            </label>
            <wp-select-box
              formControlName="invoiceTemplate"
              collection="InvoiceTemplates"
              [query]="templateQuery"
              [allowNull]="false"
              placeholder="{{ 'billing.invoices.card.props.template' | translate }}"
            >
            </wp-select-box>
          </div>

          <div class="form-group" tmtIndicator>
            <label>
              {{ 'billing.invoices.card.props.description.label' | translate }}
            </label>
            <wp-text-box
              formControlName="description"
              placeholder="{{ 'billing.invoices.card.props.description.ph' | translate }}"
            >
            </wp-text-box>
          </div>

          <div class="form-group" tmtIndicator>
            <label>
              {{ 'billing.invoices.card.props.billingAddress.label' | translate }}
            </label>
            <wp-multiline-text-box
              formControlName="billingAddress"
              placeholder="{{ 'billing.invoices.card.props.billingAddress.ph' | translate }}"
            >
            </wp-multiline-text-box>
          </div>
        </div>
        <div class="col-5 second-col">
          <div class="d-flex">
            <div class="form-group me-4" tmtIndicator>
              <label>
                {{ 'billing.invoices.card.props.number.label' | translate }}
              </label>
              <wp-text-box
                formControlName="number"
                placeholder="{{ 'billing.invoices.card.props.number.ph' | translate }}"
              >
              </wp-text-box>
            </div>

            <div class="form-group" tmtIndicator>
              <label>
                {{ 'billing.invoices.card.props.issueDate' | translate }}
              </label>
              <wp-date-box formControlName="issueDate" [allowNull]="false"></wp-date-box>
            </div>
          </div>

          <div class="d-flex">
            <div class="form-group me-4">
              <label>
                {{ 'billing.invoices.card.props.paymentDueDate' | translate }}
              </label>
              <wp-date-box formControlName="paymentDueDate"></wp-date-box>
            </div>

            @if (invoice?.state.id === paidStateId) {
              <div class="form-group">
                <label>
                  {{ 'billing.invoices.card.props.paymentDate' | translate }}
                </label>
                <wp-date-box formControlName="paymentDate" />
              </div>
            }
          </div>
        </div>
      </div>
    </div>

    <hr class="m-0" />

    <div class="toolbar-container" *ngIf="!readonly">
      <div class="group">
        <button
          *ngIf="!invoice.project || $any(invoice.project).billingTypeId == billingType.tm.id"
          type="button"
          class="btn btn-default"
          (click)="addLines(invoiceLineTypes.time)"
        >
          <span class="bi bi-clock"></span>
          &nbsp;{{ 'billing.invoices.card.actions.addTimeLines' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="addLines(invoiceLineTypes.expense)">
          <span class="bi bi-wallet2 bi-15"></span>
          &nbsp;{{ 'billing.invoices.card.actions.addExpenseLines' | translate }}
        </button>
        <button type="button" class="btn btn-default" (click)="createCustomLine()">
          <span class="bi bi-plus-lg bi-15"></span>
          &nbsp;{{ 'billing.invoices.card.actions.createCustomLine' | translate }}
        </button>
      </div>
    </div>

    <div *ngIf="!hasLines" class="p-5 text-body-secondary text-center text-uppercase">
      {{ 'billing.invoices.card.hasNoLines' | translate }}
    </div>

    <wp-invoice-time-lines
      *ngIf="timeLines.length > 0"
      [lines]="timeLines"
      [grouping]="invoice?.timeLinesGrouping"
      [readonly]="readonly"
    >
    </wp-invoice-time-lines>

    <wp-invoice-expense-lines
      *ngIf="expenseLines.length > 0"
      [lines]="expenseLines"
      [grouping]="invoice?.expenseLinesGrouping"
      [readonly]="readonly"
    >
    </wp-invoice-expense-lines>

    <wp-invoice-custom-lines
      *ngIf="customLines.length > 0"
      [lines]="customLines"
      [readonly]="readonly"
    >
    </wp-invoice-custom-lines>

    <div class="mt-4 vat-block">
      <table class="ms-auto">
        <ng-container *ngIf="useVat">
          <tr>
            <td>{{ 'billing.invoices.card.props.totalAmount' | translate }}:</td>
            <td class="text-end trim">
              {{ invoice?.totalAmount | wpCurrency: currencyCode }}
            </td>
          </tr>
          <tr>
            <td>{{ vatLabel }}:</td>
            <td class="text-end trim">
              <span *ngIf="hasVat; else amountNoVat">
                {{ vatAmount | wpCurrency: currencyCode }}
              </span>
              <ng-template #amountNoVat> -</ng-template>
            </td>
          </tr>
        </ng-container>
        <tr>
          <td>{{ 'billing.invoices.card.props.totalToPay' | translate }}:</td>
          <td class="text-end trim">
            {{ totalAmount | wpCurrency: currencyCode }}
          </td>
        </tr>
      </table>
    </div>

    <hr class="mt-4" />

    <div class="block">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>
              {{ 'billing.invoices.card.props.outerNotes.label' | translate }}
            </label>
            <wp-multiline-text-box
              formControlName="outerNotes"
              placeholder="{{ 'billing.invoices.card.props.outerNotes.ph' | translate }}"
            >
            </wp-multiline-text-box>
            <p class="text-body-secondary">
              {{ 'billing.invoices.card.props.outerNotes.help' | translate }}
            </p>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>
              {{ 'billing.invoices.card.props.innerNotes.label' | translate }}
            </label>
            <wp-multiline-text-box
              formControlName="innerNotes"
              placeholder="{{ 'billing.invoices.card.props.innerNotes.ph' | translate }}"
            >
            </wp-multiline-text-box>
          </div>
        </div>
      </div>
    </div>
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-underline mt-5">
      <li [ngbNavItem]="'activity'">
        <a ngbNavLink>
          {{ 'shared.comments.tabs.activity' | translate }}
        </a>
        <div *ngbNavContent class="wide-tab-container mt-3">
          <wp-comments-tab
            [entityId]="entityId"
            [collection]="CommentedEntityCollectionType.Invoices"
          ></wp-comments-tab>
        </div>
      </li>
      <li [ngbNavItem]="'attachments'">
        <a ngbNavLink>
          {{ 'shared.comments.tabs.attachments' | translate }}
        </a>
        <div *ngbNavContent class="wide-tab-container mt-3">
          <wp-attachments-tab
            [entityId]="entityId"
            [collection]="CommentedEntityCollectionType.Invoices"
          ></wp-attachments-tab>
        </div>
      </li>
      <li [ngbNavItem]="'linkedTasks'">
        <a ngbNavLink>
          {{ 'base.workflow.tab' | translate }}
        </a>
        <tmt-entity-workflow-tasks
          *ngbNavContent
          [entityId]="entityId"
          class="mt-2 d-block"
        ></tmt-entity-workflow-tasks>
      </li>
      <li [ngbNavItem]="'stateHistory'">
        <a ngbNavLink>
          {{ 'base.stateHistory.tab' | translate }}
        </a>
        <tmt-state-history
          [entityId]="entityId"
          *ngbNavContent
          class="mt-2 d-block"
        ></tmt-state-history>
      </li>
      <wp-sort class="ms-auto"></wp-sort>
    </ul>

    <div [ngbNavOutlet]="nav"></div>
  </form>
</ng-container>
