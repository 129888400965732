import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectResourcesComponent } from './project-resources.component';
import { ProjectResourcesEstimateCalendarModule } from './project-resources-estimate-calendar/project-resources-estimate-calendar.module';
import { ProjectResourcesForecastCalendarModule } from './project-resources-forecast-calendar/project-resources-forecast-calendar.module';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ProjectResourceSettings } from 'src/app/projects/card/project-resources/models/project-resource-settings.model';

@NgModule({
  declarations: [ProjectResourcesComponent],
  imports: [
    CommonModule,
    SharedModule,
    ProjectResourcesEstimateCalendarModule,
    ProjectResourcesForecastCalendarModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.resources',
          url: '/resources',
          redirectTo: (trans) => {
            const localConfigService = trans.injector().get(LocalConfigService);
            return localConfigService.getConfig(ProjectResourceSettings)
              .toggler;
          },
          component: ProjectResourcesComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class ProjectResourcesModule {}
