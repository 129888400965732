import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { InvoiceCardService } from '../../invoice-card.service';
import { MessageService } from 'src/app/core/message.service';
import { GroupingField } from '../../shared/grouping-field.enum';
import { UntypedFormBuilder } from '@angular/forms';
import { NonCustomInvoiceLineType } from 'src/app/shared/models/entities/billing/invoice-card-types.type';
import { InvoiceLineType } from 'src/app/shared/models/entities/billing/invoice-line-type.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wp-grouping-settings',
  templateUrl: './grouping-settings.component.html',
  styleUrls: ['./grouping-settings.component.scss'],
})
export class GroupingSettingsComponent implements OnInit, OnDestroy {
  @Input() grouping: string;
  @Input() type: NonCustomInvoiceLineType;

  @Output() canceled = new EventEmitter<void>();
  @Output() saved = new EventEmitter<string>();

  public get allFields(): GroupingField[] {
    switch (this.type) {
      case InvoiceLineType.Time:
        return this.cardService.allTimeFields;

      case InvoiceLineType.Expense:
        return this.cardService.allExpensesFields;
    }
  }

  public get hasAddButton(): boolean {
    return this.allFields.length > this.columns.length;
  }

  public columns = this.fb.array([]);

  public availableFields: any[];

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public cardService: InvoiceCardService,
    private message: MessageService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    const fields = (
      this.grouping ? this.grouping.split(',') : []
    ) as GroupingField[];

    fields.forEach((field) => {
      this.columns.push(this.fb.control(field));
    });

    this.updateControls();

    this.columns.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.updateControls());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public trackById = (index: number, row: any): string => row.id;

  private getAvailableFields(
    includeField?: GroupingField,
  ): { field: GroupingField; label: string }[] {
    return this.allFields
      .filter(
        (field) =>
          (this.columns.value as GroupingField[]).every(
            (selectedField) => selectedField !== field,
          ) || includeField === field,
      )
      .map((f) => ({
        field: f,
        label: this.cardService.getFieldLabel(f),
      }));
  }

  public updateControls() {
    this.availableFields = [];
    (this.columns.value as GroupingField[]).forEach((field, index) => {
      this.availableFields[index] = this.getAvailableFields(field);
    });
  }

  public remove(index: number) {
    this.columns.removeAt(index);
  }

  public add() {
    const availableField = this.getAvailableFields()[0];
    this.columns.push(this.fb.control(availableField.field));
  }

  public save() {
    this.message
      .confirmLocal(
        'billing.invoices.card.addingLinesModal.setGroupingConfirmation',
      )
      .then(
        () => {
          this.grouping = this.columns.value.join(',');
          const resetGrouping = {
            grouping: this.grouping,
            type: this.type,
          };

          this.cardService.resetGrouping(resetGrouping).then(() => {
            this.saved.emit(this.grouping);
          });
        },
        () => null,
      );
  }

  public cancel() {
    this.canceled.emit();
  }
}
