// TODO: remove it?
export enum RateMatrixStructureCollection {
  role = 'Roles',
  competence = 'Competences',
  level = 'Levels',
  location = 'Locations',
  legalEntity = 'LegalEntities',
  grade = 'Grades',
  resourcePool = 'ResourcePools',
}
