<ng-template #mergeConflicts>
  {{
    'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.conflictsText'
      | translate
  }}
</ng-template>

<table *ngIf="!hasConflicts; else mergeConflicts" class="wp-nested-table mb-0">
  <thead>
    <tr>
      <th *ngIf="columns.length === 0"></th>
      <th
        *ngFor="let column of displayedColumns; trackBy: trackColumn"
        [ngStyle]="{ width: column.width }"
        class="disable-selecting trim {{ column.align ?? 'text-start' }}"
        title="{{ column.hint | translate }}"
      >
        {{ column.header | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of rows; trackBy: trackRow" id="{{ row.id }}" tabindex="1">
      <td
        *ngFor="let column of displayedColumns; trackBy: trackColumn"
        class="{{ column.align ?? 'text-start' }}"
        title="{{ row[column.name] }}"
      >
        {{ row[column.name] }}
      </td>
    </tr>
    <tr *ngIf="!(mergeModalService.isLoading$ | async) && rows?.length === 0">
      <td [attr.colspan]="displayedColumns.length" class="text-center text-body-secondary">
        {{ 'shared.noDisplayData' | translate }}
      </td>
    </tr>
  </tbody>
</table>
