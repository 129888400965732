import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { NotificationService } from 'src/app/core/notification.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ProjectBillingEstimate } from 'src/app/shared/models/entities/projects/project-billing-estimate.model';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectBillingMode } from 'src/app/shared/models/enums/project-billing-mode.enum';
import { BillingEstimationSettings } from 'src/app/shared/models/entities/projects/billing-estimation-settings.model';

@Component({
  selector: 'wp-project-billing-modal-modal',
  templateUrl: './project-billing-modal.component.html',
})
export class ProjectBillingModalComponent implements OnInit {
  @Input() entry: ProjectBillingEstimate;
  @Input() projectId: string;
  @Input() projectVersion: ProjectVersion;
  @Input() readonly: boolean;
  @Input() projectCurrencyCode: string;

  public get projectBillingEstSettings(): BillingEstimationSettings {
    return this._projectBillingEstSettings;
  }

  protected readonly projectBillingMode = ProjectBillingMode;

  private readonly _projectBillingEstSettings: BillingEstimationSettings;

  public form = this.fb.group({
    id: null,
    billingDate: [null, Validators.required],
    collectionDate: [null, Validators.required],
    amount: [null, Validators.required],
    description: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
    projectTask: [null, Validators.required],
    isAutomatic: null,
  });

  constructor(
    public projectCardService: ProjectCardService,
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private notification: NotificationService,
  ) {
    this._projectBillingEstSettings =
      this.projectCardService.project.billingEstimationSettings;
  }

  ok() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.activeModal.close(this.form.getRawValue());
  }

  /** Закрыть диалог. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ngOnInit() {
    this.form.patchValue(this.entry);
    if (this.readonly) {
      this.form.disable();
    } else {
      this.form.controls.isAutomatic.disable();
    }
  }
}
