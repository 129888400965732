<div class="toolbar-container">
  <div class="group">
    @if (!(gridService.readonly$ | async)) {
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-default"
          title="{{ 'shared.actions.create' | translate }}"
          (click)="gridService.execute('create')"
          data-test="create"
        >
          <i class="bi bi-plus-lg bi-15"></i>
          {{ 'shared.actions.create' | translate }}
        </button>

        <div class="btn-group" ngbDropdown role="group" data-test="additional">
          <div>
            <div class="dropdown-menu" ngbDropdownMenu placement="left">
              @if (projectCardService.project?.organization?.id) {
                <button
                  ngbDropdownItem
                  (click)="gridService.execute('openAddTariffsFromClientModal')"
                  data-test="addTariffsFromClient"
                >
                  {{ 'projects.projects.tariffs.actions.addTariffsFromClient' | translate }}
                </button>
              }
              <button
                ngbDropdownItem
                (click)="gridService.execute('openAddTariffsFromRateMatricesModal')"
                data-test="addTariffsFromRateMatrices"
              >
                {{ 'projects.projects.tariffs.actions.addTariffsFromRateMatrices' | translate }}
              </button>
            </div>
          </div>
          <button class="btn btn-default dropdown-toggle-split" ngbDropdownToggle></button>
        </div>
      </div>
    }

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.edit' | translate }}"
      [disabled]="!gridService.canBeExecuted('edit', gridService.selectedGroup$.getValue())"
      (click)="gridService.execute('edit', gridService.selectedGroup$.getValue())"
      data-test="edit"
    >
      {{ 'shared.actions.edit' | translate }}
    </button>

    @if (!(gridService.readonly$ | async)) {
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.delete' | translate }}"
        [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroup$.getValue())"
        (click)="gridService.execute('delete', gridService.selectedGroup$.getValue())"
        data-test="delete"
      >
        <i class="bi bi-trash3" aria-hidden="true"></i>
      </button>
    }
  </div>

  <div class="group ms-auto">
    <div ngbDropdown class="d-inline-block">
      <button
        type="button"
        class="btn btn-default without-caret"
        ngbDropdownToggle
        title="{{ 'shared2.actions.options' | translate }}"
        data-test="options"
      >
        <i class="bi bi-sliders2 bi-15" aria-hidden="true"></i>
      </button>
      <div ngbDropdownMenu class="p-3">
        <div class="form-check text-nowrap">
          <input
            [formControl]="isActiveControl"
            type="checkbox"
            class="form-check-input"
            id="onlyWithData"
          />
          <label class="form-check-label" for="onlyWithData">
            {{ 'projects.projects.tariffs.actions.hideDisabled' | translate }}
          </label>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-default"
      (click)="gridService.execute('setUserView')"
      title="{{ 'shared.actions.setView' | translate }}"
      data-test="viewSettings"
    >
      <i class="bi bi-gear" aria-hidden="true"></i>
    </button>
  </div>
</div>
