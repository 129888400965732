<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label> {{ 'team.timeOffSchedule.filters.departmentFilter.label' | translate }}</label>

        <tmt-department-box
          style="width: 350px"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{
            'team.timeOffSchedule.filters.departmentFilter.placeholder' | translate
          }}"
          formControlName="department"
          [subordinatesOption]="true"
        ></tmt-department-box>
      </div>
      <div class="form-group">
        <label> {{ 'team.timeOffSchedule.filters.location.label' | translate }}</label>

        <wp-select-box
          style="width: 350px"
          collection="locations"
          [allowInactive]="service.allowInactive$ | async"
          formControlName="location"
          placeholder="{{ 'team.timeOffSchedule.filters.location.placeholder' | translate }}"
        ></wp-select-box>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label> {{ 'team.timeOffSchedule.filters.level.label' | translate }}</label>

        <wp-select-box
          placeholder="{{ 'team.timeOffSchedule.filters.level.placeholder' | translate }}"
          style="width: 350px"
          collection="levels"
          [allowInactive]="service.allowInactive$ | async"
          formControlName="level"
        ></wp-select-box>
      </div>
      <div class="form-group">
        <label> {{ 'team.timeOffSchedule.filters.resourcePool.label' | translate }}</label>
        <tmt-hierarchical-box
          style="width: 350px"
          collection="ResourcePools"
          parentIdKey="leadResourcePoolId"
          [allowInactive]="service.allowInactive$ | async"
          formControlName="resourcePool"
          placeholder="{{ 'team.timeOffSchedule.filters.resourcePool.placeholder' | translate }}"
        />
      </div>
    </div>
  </div>

  <div class="form-check" style="display: inline-block">
    <input
      type="checkbox"
      class="form-check-input"
      id="onlySubordinates"
      formControlName="onlySubordinates"
    />
    <label class="form-check-label" for="onlySubordinates">
      {{ 'shared2.filters.onlySubordinates' | translate }}
    </label>
  </div>
</form>
