import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST, FILTER } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { StateHelper } from 'src/app/shared/helpers/state.helper';

import { LoginLogList } from 'src/app/shared/lists/login-log.list';
import { LoginLogListComponent } from 'src/app/settings-app/login-log/list/login-log-list.component';
import { LoginLogCardComponent } from 'src/app/settings-app/login-log/card/login-log-card.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [LoginLogCardComponent, LoginLogListComponent],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.loginLog',
          url: '/login-log?{navigation}&{route}&{filter:json}',
          component: LoginLogListComponent,
          params: {
            navigation: 'settings.loginLog',
            routeMode: null,
            routeHeader: 'finance.entries.list.header',
            route: ROUTE_DEFAULT_PARAMS,
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: LoginLogList },
            {
              token: FILTER,
              deps: [Transition],
              resolveFn: StateHelper.resolveFilter,
            },
          ],
        },

        {
          name: 'settings.loginLogCard',
          params: {
            navigation: 'settings.loginLog',
            route: ROUTE_DEFAULT_PARAMS,
            routeMode: null,
          },
          url: '/login-log/{entityId:guid}?{navigation}&{route}',
          component: LoginLogCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class LoginLogModule {}
