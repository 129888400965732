import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/core/notification.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Exception } from 'src/app/shared/models/exception';
import { DataService } from 'src/app/core/data.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectVersionCardService } from '../../core/project-version-card.service';
import { MassOperationHelper } from 'src/app/shared/helpers/mass-operation.helper';
import { Guid } from 'src/app/shared/helpers/guid';
import {
  ProjectTariff,
  ProjectTariffRate,
} from '../models/project-tariff.model';
import { RateMatricesLinesFilterService } from 'src/app/shared/components/features/rate-matrices-filter/rate-matrices-lines-filter.service';
import { RateMatrixLine } from 'src/app/settings-app/rate-matrix/model/rate-matrix-line.model';
import { AppService } from 'src/app/core/app.service';
import { RateMatrix } from 'src/app/settings-app/rate-matrix/model/rate-matrix.model';
import { Analytics } from 'src/app/settings-app/rate-matrix/card/structure-change-modal/rate-matrix-structure.model';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { RateMatrixStructureCollection } from 'src/app/settings-app/rate-matrix/model/rate-matrix-structure.enum';
import { RateMatricesFilter } from 'src/app/shared/components/features/rate-matrices-filter/rate-matrices-filter.model';
import { Currency } from 'src/app/shared/models/entities/settings/currency.model';
import { CurrenciesService } from 'src/app/shared/services/currencies.service';

@Component({
  selector: 'tmt-project-tariffs-rate-matrices-modal',
  templateUrl: './project-tariffs-rate-matrices-modal.component.html',
  styleUrl: './project-tariffs-rate-matrices-modal.component.scss',
  providers: [
    {
      provide: FilterService,
      useClass: RateMatricesLinesFilterService,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectTariffsRateMatricesModalComponent implements OnInit {
  @ViewChild('leftTbl') private leftTbl: ElementRef;

  @Input() projectId: string;

  public isSaving$ = new BehaviorSubject<boolean>(false);
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public isLoadingMatrices$ = new BehaviorSubject<boolean>(false);
  public leftTableStyles: Record<string, string> = {};
  public availableLines: (RateMatrixLine & {
    name?: string;
    uniqueName?: string;
  })[] = [];
  public selectedLines: (RateMatrixLine & {
    name?: string;
    uniqueName?: string;
  })[] = [];
  public loadedPartly: boolean;
  public loadLimit = 250;
  public form: UntypedFormGroup;
  public rateMatrices: RateMatrix[] = [];
  public rateMatrixStructureCollection = RateMatrixStructureCollection;
  public baseCurrency: Currency;

  private destroyed$ = new Subject<void>();

  constructor(
    public appService: AppService,
    public filterService: FilterService,
    private notificationService: NotificationService,
    private activeModal: NgbActiveModal,
    private dataService: DataService,
    private projectVersionCardService: ProjectVersionCardService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private currenciesService: CurrenciesService,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      rateMatrix: null,
    });
    this.form
      .get('rateMatrix')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.changeFilterValues(value);
      });
    this.loadMatrices();

    this.filterService.values$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.filterService.values.rateMatrix) {
          this.loadRateMatrixLines();
        }
      });

    this.currenciesService.currencies$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: Currency[]) => {
        this.baseCurrency = value.find(
          (item) =>
            item.alpha3Code ===
            this.appService.session.configuration.baseCurrencyCode,
        );
      });
  }

  /* Resizes nested table. */
  public resizeLeftTbl(): void {
    this.leftTableStyles['display'] = 'table';
    this.leftTableStyles['width'] =
      (<HTMLElement>this.leftTbl.nativeElement).getBoundingClientRect().width +
      'px';
  }

  /**
   * Adds line to selected lines list.
   *
   * @param line line for adding to selected lines list.
   * @param index line's index for removing from available lines list.
   */
  public selectLine(
    line: RateMatrixLine & { name?: string; uniqueName?: string },
    index: number,
  ): void {
    line.name = this.generateName(line);
    this.selectedLines.push(line);
    this.selectedLines = _.sortBy(this.selectedLines, ['name']);
    this.availableLines.splice(index, 1);
    this.setSelectedLinesNames(line.name);
  }

  /**
   * Removes line from selected lines list.
   *
   * @param line line for adding to available lines list.
   * @param index line's index for removing from selected lines list.
   */
  public removeLine(line: RateMatrixLine, index: number): void {
    if (line.rateMatrix.id === this.form.value.rateMatrix.id) {
      this.availableLines.push(line);
      this.availableLines = _.sortBy(this.availableLines, ['name']);
    }
    this.selectedLines.splice(index, 1);
    this.setSelectedLinesNames(this.generateName(line));
  }

  /* Adds tariffs to project from rate matrices lines. */
  public addTariffsFromRateMatrixLines(): void {
    const projectTariffs: ProjectTariff[] = [];

    this.selectedLines.map(
      (line: RateMatrixLine & { name: string; uniqueName: string }) => {
        const projectTariff: Partial<ProjectTariff> = {
          id: Guid.generate(),
          name: line.uniqueName,
          isActive: true,
          assignments: [],
          rates: [
            {
              currencyId: this.baseCurrency.id,
              effectiveDate: null,
              id: Guid.generate(),
              value: +line.rate,
            } as ProjectTariffRate,
          ],
        };

        ProjectVersionUtil.setEntityRootPropertyId(
          this.projectVersionCardService.projectVersion,
          projectTariff,
          this.projectId,
        );

        projectTariffs.push(projectTariff as ProjectTariff);
      },
    );

    this.isSaving$.next(true);

    const massOperationHelper = new MassOperationHelper(
      projectTariffs.map((tariff: ProjectTariff) =>
        this.dataService.collection('ProjectTariffs').insert(tariff),
      ),
      { takeUntil: takeUntil(this.destroyed$) },
    );
    massOperationHelper
      .start()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((progress) => {
        if (progress === 100) {
          const successActionsCount = massOperationHelper.completedCount;
          if (massOperationHelper.errors.length) {
            if (
              successActionsCount &&
              massOperationHelper.errors.find(
                (error) =>
                  error.result.code === Exception.Common_NameMustBeUnique.code,
              )
            ) {
              this.notificationService.warningLocal(
                'projects.projects.tariffs.addRateMatricesTariffsModal.messages.addedOnlyUnique',
              );
            }

            const uniqueErrors = new Set();
            massOperationHelper.errors.forEach((error) => {
              uniqueErrors.add(error.result?.message || 'shared.unknownError');
            });
            uniqueErrors.forEach((error: string) => {
              this.notificationService.error(error);
            });
          } else if (successActionsCount === projectTariffs.length) {
            this.notificationService.successLocal(
              'projects.projects.tariffs.addRateMatricesTariffsModal.messages.added',
            );
          }
          this.activeModal.close();
          this.isSaving$.next(false);
        }
      });
  }

  /* Closes modal. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  /* Loads rate matrices lines from API. */
  private loadRateMatrixLines(): void {
    this.availableLines = [];
    this.isLoading$.next(true);
    this.loadedPartly = false;

    const expand = {
      rateMatrix: {},
    };
    for (const key of this.filterService.values.rateMatrix
      .rateMatrixStructure) {
      expand[key] = { select: ['id', 'name'] };
    }

    const query: Record<string, any> = {
      select: ['id', 'rate'],
      expand,
      filter: [...this.filterService.getODataFilter(), { rate: { gt: 0 } }],
      top: this.loadLimit,
      orderBy: this.filterService.values.rateMatrix.rateMatrixStructure
        .map((item) => `${item}/name`)
        .join(', '),
    };

    this.dataService
      .collection('RateMatrixLines')
      .query<RateMatrixLine[]>(query)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data: RateMatrixLine[]) => {
          this.availableLines = data.filter(
            (item) => !this.selectedLines.find((line) => line.id === item.id),
          );
          this.loadedPartly = data.length === this.loadLimit;
          this.isLoading$.next(false);
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isLoading$.next(false);
        },
      });
  }

  /**
   * Generates line's name.
   *
   * @param line selected line.
   * @returns line's name.
   */
  private generateName(line: RateMatrixLine): string {
    return line.rateMatrix.rateMatrixStructure
      .filter((item) => line[_.lowerFirst(item)])
      .map((item) => line[_.lowerFirst(item)].name)
      .join(' > ');
  }

  /**
   * Sets unique names for selected lines.
   *
   * @param name generated name.
   */
  private setSelectedLinesNames(name: string): void {
    this.selectedLines
      .filter((item) => item.name === name)
      .map((item, i) =>
        i ? (item.uniqueName = `${name} (${i + 1})`) : (item.uniqueName = name),
      );
  }

  /* Loads rate matrices list. */
  private loadMatrices(): void {
    this.isLoadingMatrices$.next(true);
    this.dataService
      .collection('RateMatrices')
      .query<RateMatrix[]>({
        filter: {
          typeId: { type: 'guid', value: RateMatrix.billingRateTypeId },
          stateId: { type: 'guid', value: RateMatrix.activeStateId },
        },
        orderBy: 'expiryDate',
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data: RateMatrix[]) => {
          this.isLoadingMatrices$.next(false);
          data.map((item) => {
            const from = item.effectiveDate
              ? this.datePipe.transform(
                  new Date(item.effectiveDate),
                  'shortDate',
                )
              : '∞';
            const to = item.expiryDate
              ? this.datePipe.transform(new Date(item.expiryDate), 'shortDate')
              : '∞';
            if (item.effectiveDate || item.expiryDate) {
              item.name += ` (${from} — ${to})`;
            }
          });
          this.rateMatrices = data;
          const actualMatrix =
            data.length === 1
              ? data[0]
              : data.find(
                  (item) =>
                    DateTime.fromISO(item.expiryDate).endOf('day') >=
                      DateTime.now() || !item.expiryDate,
                ) ?? null;
          if (actualMatrix) {
            actualMatrix.rateMatrixStructure =
              actualMatrix.rateMatrixStructure.map(
                (item) => (item = _.lowerFirst(item) as Analytics),
              );
          }
          this.form.patchValue({ rateMatrix: actualMatrix });
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isLoadingMatrices$.next(false);
        },
      });
  }

  /**
   * Changes filter values on matrix change.
   *
   * @param value rate matrix value.
   */
  private changeFilterValues(value: RateMatrix): void {
    if (!value) {
      return;
    }
    value.rateMatrixStructure = value.rateMatrixStructure.map(
      (item) => (item = _.lowerFirst(item) as Analytics),
    );
    const values: RateMatricesFilter = {
      text: this.filterService.values.text,
      rateMatrix: value,
    };
    for (const key of value.rateMatrixStructure) {
      values[key] = this.filterService.values[key] ?? null;
    }

    this.filterService.changeValues(values);
    this.cdr.markForCheck();
  }
}
