import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { Subject } from 'rxjs';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import {
  GridColumnType,
  GridComponentColumn,
  GridNavigationColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { UntypedFormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { CertificateCreationComponent } from 'src/app/certificates/creation/certificate-creation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { UserCertificatesService } from 'src/app/employees/card/employee-main/user-certificates/user-certificates.service';
import { EmployeeCardService } from 'src/app/employees/card/employee-card.service';

@Component({
  selector: 'tmt-user-certificates',
  templateUrl: './user-certificates.component.html',
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCertificatesComponent implements OnInit, OnDestroy {
  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    toolbar: null,
    view: {
      name: 'certificates',
      columns: [
        <GridNavigationColumn>{
          name: 'name',
          header: 'shared.props.name',
          hint: 'shared.props.name',
          type: GridColumnType.Navigation,
          state: 'certificate',
          width: '100%',
        },
        {
          name: 'state',
          header: 'shared.props.state',
          hint: 'shared.props.state',
          type: GridColumnType.State,
          width: '150px',
        } as GridComponentColumn,
      ],
    },
  };
  public certificates = this.fb.array([]);
  private destroyed$ = new Subject<void>();

  private user: NamedEntity;
  constructor(
    private fb: UntypedFormBuilder,
    public cardService: EmployeeCardService,
    public service: UserCertificatesService,
    private gridService: GridService,
    private modalService: NgbModal,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.service.dispose();
  }

  ngOnInit(): void {
    this.service.certificates$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((certificates) => {
        this.certificates.clear();
        this.gridService.detectChanges();

        certificates.forEach((certificate) => {
          const group = this.fb.group({
            id: certificate.id,
            name: certificate.name,
            state: certificate.state,
          });

          this.certificates.push(group);
        });
      });
    this.cardService.user$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user) => {
        this.user = user;
      });
    this.service.load();
  }

  public addCertificate() {
    const ref = this.modalService.open(CertificateCreationComponent, {});
    const instance = ref.componentInstance as CertificateCreationComponent;
    instance.userFromProfile = this.user;
  }
}
