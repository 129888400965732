<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">{{ 'quickStart.header' | translate }}</h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="close()"
    ></button>
  </div>
  <div class="modal-body" [innerHTML]="content$ | async"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="ok()">
      {{ 'quickStart.actions.ok' | translate }}
    </button>

    <button type="button" class="btn btn-default" (click)="close()">
      {{ 'quickStart.actions.postpone' | translate }}
    </button>
  </div>
</form>
