/* eslint-disable @typescript-eslint/naming-convention */

import { DateTimeUnit } from 'luxon';

// NOTE: Enum values must be defined in ascending order
export enum PlanningScale {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Quarter = 'Quarter',
  Year = 'Year',
}

export const AllPlanningScales: PlanningScale[] = Object.values(PlanningScale);

export const getUnitFromPlanningScale = (from: PlanningScale): DateTimeUnit => {
  switch (from) {
    case PlanningScale.Day:
      return 'day';
    case PlanningScale.Week:
      return 'week';
    case PlanningScale.Month:
      return 'month';
    case PlanningScale.Quarter:
      return 'quarter';
    case PlanningScale.Year:
      return 'year';
  }
};
