import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlannerCellComponent } from './planner-cell/planner-cell.component';
import { PlannerFormatPipe } from './core/planner-format.pipe';
import { PlannerRowCommandsComponent } from './planner-row-commands/planner-row-commands.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { PlannerPeriodComponent } from './planner-period/planner-period.component';

@NgModule({
  declarations: [
    PlannerCellComponent,
    PlannerFormatPipe,
    PlannerRowCommandsComponent,
    PlannerPeriodComponent,
  ],
  providers: [PlannerFormatPipe],
  imports: [CommonModule, SharedModule, UIRouterModule.forChild()],
  exports: [
    PlannerPeriodComponent,
    PlannerRowCommandsComponent,
    PlannerFormatPipe,
    PlannerCellComponent,
  ],
})
export class PlannerModule {}
