<div class="toolbar-container">
  <div class="btn-group" role="group">
    <button class="btn btn-default" (click)="form.disable()">Disable</button>
    <button class="btn btn-default" (click)="form.enable()">Enable</button>
  </div>

  <button class="btn btn-default" (click)="setValue()">Set value</button>

  <form [formGroup]="formInputParams">
    <div class="form-check">
      <input formControlName="readonly" type="checkbox" class="form-check-input" id="readonly" />
      <label class="form-check-label" for="readonly"> Readonly </label>
    </div>
  </form>
</div>

<hr />

<div [formGroup]="form" style="width: 400px">
  <h2>SelectBox (Collection)</h2>
  <!-- TODO Change inline params into a single object for component  -->
  <wp-select-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="selectCollection"
    collection="Users"
  ></wp-select-box>
  <p>
    Form value:<br />
    <code>
      {{ form.get('selectCollection').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('selectCollection').valid }}</p>
  <br />
  <hr />

  <h2>SelectBox (Values)</h2>
  <wp-select-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="selectValues"
    [values]="values"
  ></wp-select-box>
  <p>
    Form value:<br />
    <code>
      {{ form.get('selectValues').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('selectValues').valid }}</p>
  <br />
  <hr />

  <h2>SelectBox (Values). Null is not allowed.</h2>
  <wp-select-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="selectValues2"
    [values]="values"
    [allowNull]="false"
  ></wp-select-box>
  <p>
    Form value:<br />
    <code>
      {{ form.get('selectValues2').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('selectValues2').valid }}</p>
  <br />
  <hr />

  <h2>SelectBox (Grouped values). Null is not allowed.</h2>
  <wp-select-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="selectValues3"
    [values]="groupingValues"
    [allowNull]="false"
    groupHandler="group"
    optionLabel="label"
  />
  <p>
    Form value:<br />
    <code>
      {{ form.get('selectValues3').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('selectValues3').valid }}</p>
  <br />
  <hr />

  <h2>SelectBox (Grouped values). Null is not allowed. Values load from API</h2>
  <wp-select-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="selectValues4"
    collection="ReportTypes"
    [query]="{
      select: ['id', 'name', 'code', 'group']
    }"
    [allowNull]="false"
    groupHandler="group"
  />
  <p>
    Form value:<br />
    <code>
      {{ form.get('selectValues4').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('selectValues4').valid }}</p>
  <br />
  <hr />

  <h2>SelectBox(idMode) (Collection)</h2>
  <wp-select-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="selectCollectionIdMode"
    collection="Users"
    [isIdMode]="true"
  ></wp-select-box>
  <p>
    Form value:<br />
    <code>
      {{ form.get('selectCollectionIdMode').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('selectCollectionIdMode').valid }}</p>
  <br />
  <hr />

  <h2>SelectBox(idMode) (Values)</h2>
  <wp-select-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="selectValuesIdMode"
    [values]="values"
    [isIdMode]="true"
  ></wp-select-box>
  <p>
    Form value:<br />
    <code>
      {{ form.get('selectValuesIdMode').value | json }}
    </code>
  </p>
  <p>Form control is valid: {{ form.get('selectValuesIdMode').valid }}</p>
  <br />
  <hr />
</div>
