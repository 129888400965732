export enum ValueMode {
  Hours = 'Hours',
  Percentage = 'Percentage',
  FTE = 'FTE',
  Cost = 'Cost',
  Days = 'Days',
}

/** Hours in one day for "days" value mode. */
export const hoursInDay = 8;
