import { Component, DestroyRef, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { RateMatrix } from 'src/app/settings-app/rate-matrix/model/rate-matrix.model';

@Component({
  selector: 'tmt-copy-matrix-modal',
  templateUrl: './copy-matrix-modal.component.html',
})
export class CopyMatrixModalComponent {
  @Input() matrixId: string;

  public form = this.fb.group({
    name: [
      null,
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    effectiveDate: [null, Validators.required]
  });
  public isSaving: boolean;

  private destroyRef = inject(DestroyRef);

  constructor(
    private state: StateService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
  ) {}

  /** Copies rate matrix. */
  public copy(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    const data = this.form.value;

    this.data
      .collection('RateMatrices')
      .entity(this.matrixId)
      .action('Copy')
      .execute<RateMatrix>(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (createdMatrix) => {
          this.notification.successLocal(
            'settings.navigation.finance.rateMatrices.copyingModal.messages.wasCopied',
          );
          this.isSaving = false;
          this.activeModal.close();
          this.state.go('settings.rateMatrix', { entityId: createdMatrix.id });
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }

  /** Closes modal. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
