import { RepresentationType } from 'src/app/resource-summary/shared/resource-summary-representation-settings/models/representation-type.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class ResourceSummaryRepresentationSettings implements BaseSettings {
  public readonly name = 'ResourceSummaryRepresentationSettings';
  public version: number;
  /** Show Representation flag map. */
  public show: Record<RepresentationType, boolean>;

  public getDefault(): ResourceSummaryRepresentationSettings {
    return {
      version: 2,
      show: {
        [RepresentationType.inactiveResources]: false,
        [RepresentationType.inactiveProjects]: false,
      },
    } as ResourceSummaryRepresentationSettings;
  }
}
