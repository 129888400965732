import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wp-widget-line-properties',
  templateUrl: './widget-line-properties.component.html',
})
export class WidgetLinePropertiesComponent {
  @Input() form: UntypedFormGroup;
}
