/** Обработчик сортировки. */
export function naturalSort(property: string) {
  let a;
  let b;
  let a1;
  let b1;
  const rx = /(\d+)|(\D+)/g;
  const rd = /\d+/;

  return (as, bs) => {
    const asProp = property
      .split('.')
      .reduce((curr, next) => (curr ?? {})[next], as);
    if (asProp === '' || asProp == null) {
      a = [];
    } else {
      a = String(asProp).toLowerCase().match(rx);
    }

    const bsProp = property
      .split('.')
      .reduce((curr, next) => (curr ?? {})[next], bs);
    if (bsProp === '' || bsProp == null) {
      b = [];
    } else {
      b = String(bsProp).toLowerCase().match(rx);
    }

    while (a.length && b.length) {
      a1 = a.shift();
      b1 = b.shift();
      if (rd.test(a1) || rd.test(b1)) {
        if (!rd.test(a1)) {
          return 1;
        }
        if (!rd.test(b1)) {
          return -1;
        }
        if (a1 !== b1) {
          return a1 - b1;
        }
      } else if (a1 !== b1) {
        return a1 > b1 ? 1 : -1;
      }
    }
    return a.length - b.length;
  };
}
