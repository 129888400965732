import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { InvoiceCardService } from '../invoice-card.service';

/**
 * Represents the Invoice Time lines content.
 * */
@Component({
  selector: 'wp-invoice-time-lines',
  templateUrl: './invoice-time-lines.component.html',
  styleUrls: ['./invoice-time-lines.component.scss'],
  providers: [GridService],
})
export class InvoiceTimeLinesComponent implements OnInit {
  @Input() lines: UntypedFormArray;
  @Input() readonly: boolean;
  @Input() grouping: string;

  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    modern: true,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.lines.removeAt(index);
          this.lines.markAsDirty();
          this.cardService.calculateTotals();
        },
      },
    ],
    view: {
      name: 'timeLines',
      columns: [],
    },
  };

  constructor(private cardService: InvoiceCardService) {}

  ngOnInit(): void {
    this.cardService.buildGridColumns(this.grouping, this.gridOptions);
  }
}
