<div class="spinner" [hidden]="!loading"></div>

<div class="disable-selecting" *ngIf="!loading">
  <table class="table wp-nested-table" [ngStyle]="{ width: getDataTableWidth() + 'px' }">
    <colgroup>
      <col *ngFor="let slot of slots; trackBy: trackById" />
    </colgroup>
    <thead>
      <tr>
        <th
          [attr.colspan]="group.slotsCount"
          *ngFor="let group of slotGroups; trackBy: trackById"
          title=" {{ group.header }}"
        >
          {{ group.header }}
        </th>
      </tr>
      <tr>
        <th
          class="slot"
          *ngFor="let slot of slots; trackBy: trackById"
          [ngClass]="{ 'slot-active': slot.today }"
          title="{{ slot.hint }}"
        >
          {{ slot.header }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr class="line">
        <td
          *ngFor="let entry of entries.controls; trackBy: trackByValueId; index as dayIndex"
          wpPlannerCell
          class="cell"
          [valueMode]="bookingService.settings.valueMode"
          [formControl]="entry"
          [lineIndex]="0"
          [dayIndex]="dayIndex"
          [scale]="bookingService.settings.planningScale"
          [attr.data-test-date]="entry.value?.date"
        ></td>
      </tr>
    </tbody>
  </table>
</div>
