import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppService } from 'src/app/core/app.service';
import { Feature } from 'src/app/shared/models/enums/feature.enum';

@Component({
  selector: 'wp-permission-set-user',
  templateUrl: './permission-set-user.component.html',
})
export class PermissionSetUserComponent {
  @Input() form: UntypedFormGroup;
  public feature = Feature;

  constructor(public app: AppService) {}
}
