import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewEncapsulation,
  Input,
  NgZone,
} from '@angular/core';
import { PivotRenderService } from './pivot-render.service';
import { Subscription, fromEvent } from 'rxjs';
import { LogService } from 'src/app/core/log.service';
import { auditTime } from 'rxjs/operators';
import { Guid } from 'src/app/shared/helpers/guid';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { TranslateService } from '@ngx-translate/core';
import { PivotTableService } from './pivot-table.service';
import { SortOrder } from '../models/user-settings.model';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { Constants } from 'src/app/shared/globals/constants';

/** Сводная таблица. */
@Component({
  selector: 'wp-pivot-table',
  templateUrl: './pivot-table.component.html',
  styleUrls: ['./pivot-table.component.scss'],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class PivotTableComponent implements OnInit, OnDestroy {
  private defaultRootContainerId = 'main-area';

  @Input() inContainerMode: boolean;

  private _rootContainerId: string;
  @Input() get rootContainerId(): string {
    return this._rootContainerId ?? this.defaultRootContainerId;
  }
  set rootContainerId(value: string) {
    this._rootContainerId = value;
  }

  limitReachedWarn: any;
  scrollListener: Subscription;

  public menuId = Guid.generate();
  public headerMenuItems: any[] = [];
  public headerMenuStyles: Dictionary<string> = {};

  constructor(
    private translate: TranslateService,
    private log: LogService,
    private elementRef: ElementRef,
    public service: PivotTableService,
    public renderService: PivotRenderService,
    private freezeTableService: FreezeTableService,
    private zone: NgZone,
  ) {
    this.renderService.menu$.subscribe((params) => {
      this.openHeaderMenu(params);
    });
  }

  public closeHeaderMenu() {
    this.headerMenuStyles['display'] = 'none';
  }

  public openHeaderMenu(param: [string, any]) {
    const columnName = param[0];
    const event = param[1];

    event.stopPropagation();
    const targetRect = event.target.getBoundingClientRect();
    this.headerMenuItems = [];

    this.headerMenuItems.push({
      name: 'sortAsc',
      icon: 'bi-sort-down',
      title: this.translate.instant('analytics.pivotTable.sortAscending'),
      column: columnName,
    });

    this.headerMenuItems.push({
      name: 'sortDesc',
      icon: 'bi-sort-up',
      title: this.translate.instant('analytics.pivotTable.sortDescending'),
      column: columnName,
    });

    // Есть группировка строк по колонке.
    if (
      this.service.viewSettings.rowGroupFields &&
      this.service.viewSettings.rowGroupFields.find(
        (f) => f.name === columnName,
      )
    ) {
      this.headerMenuItems.push({
        isDivider: true,
      });

      this.headerMenuItems.push({
        name: 'expand',
        title: this.translate.instant('analytics.pivotTable.expandAll'),
        column: columnName,
      });

      this.headerMenuItems.push({
        name: 'collapse',
        title: this.translate.instant('analytics.pivotTable.collapseAll'),
        column: columnName,
      });
    }

    const left = targetRect.right - 135;
    const top = targetRect.bottom + 2;

    this.headerMenuStyles['display'] = 'block';
    this.headerMenuStyles['position'] = 'fixed';
    this.headerMenuStyles['top'] = top + 'px';
    this.headerMenuStyles['left'] = left + 'px';
  }

  public onScroll() {
    this.closeHeaderMenu();
  }

  /** Событие изменения ширины колонки. */
  public onColumnResized(param: [string, number]) {
    this.service.resizing$.next(param);
    this.renderService.userSettings.columns[param[0]] = param[1];
    this.renderService.updateColumnsDefinition();
    this.freezeTableService.redraw();
  }

  public headerMenuClick(item: any) {
    switch (item.name) {
      case 'sortAsc':
        this.service.sort(item.column, SortOrder.Asc);
        break;
      case 'sortDesc':
        this.service.sort(item.column, SortOrder.Desc);
        break;
      case 'collapse':
        this.renderService.collapseAllRowGroups(item.column);
        break;
      case 'expand':
        this.renderService.expandAllRowGroups(item.column);
        break;
    }

    this.closeHeaderMenu();
  }

  // TODO: refactor this?
  public enableInfinityScroll() {
    const container = document.getElementById(this.rootContainerId);
    const lengthThreshold = 50;
    let lastRemaining = 9999;

    const handler = () => {
      const remaining =
        container.scrollHeight - (container.clientHeight + container.scrollTop);
      if (remaining < lengthThreshold && remaining - lastRemaining <= 0) {
        this.log.debug(
          `View port has been scrolled to the end and a new page must be rendered.`,
        );
        const allAreLoaded = this.renderService.renderPage();
        if (!allAreLoaded) {
          handler();
        }
      }
      lastRemaining = remaining;
    };

    this.zone.runOutsideAngular(() => {
      this.scrollListener = fromEvent(container, 'scroll')
        .pipe(auditTime(Constants.scrollThrottleTime))
        .subscribe(() => {
          if (this.renderService.tableBody) {
            handler();
          }
        });
    });
  }

  ngOnInit(): void {
    this.renderService.componentRef = this.elementRef
      .nativeElement as HTMLElement;
    this.enableInfinityScroll();
  }

  ngOnDestroy(): void {
    if (this.scrollListener) {
      this.scrollListener.unsubscribe();
    }
  }
}
