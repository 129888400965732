import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ResourceSummaryResourceViewSettings } from 'src/app/resource-summary/shared/resource-summary-view-settings/models/resource-summary-resource-view-settings.settings';
import { LocalConfigService } from 'src/app/core/local-config.service';

/** Provides methods to work with Resource Summary View Settings. */
@Injectable()
export class ResourceSummaryViewSettingsService {
  /** Gets View Settings. */
  public get settings(): ResourceSummaryResourceViewSettings {
    return this._settings;
  }

  private settingsSubject = new Subject<ResourceSummaryResourceViewSettings>();
  public settings$ = this.settingsSubject.asObservable();

  private _settings: ResourceSummaryResourceViewSettings;

  constructor(private localConfigService: LocalConfigService) {
    this._settings = this.localConfigService.getConfig(
      ResourceSummaryResourceViewSettings,
    );
  }

  /**
   * Saves the settings and propagates the change to other components.
   *
   * @param settings Settings.
   * */
  public propagateSettingsChange(
    settings: ResourceSummaryResourceViewSettings,
  ): void {
    this._settings = settings;
    this.settingsSubject.next(settings);
    this.saveSettings();
  }

  /** Saves the settings. */
  private saveSettings(): void {
    this.localConfigService.setConfig(
      ResourceSummaryResourceViewSettings,
      this.settings,
    );
  }
}
