import { ActTaskCellComponent } from 'src/app/acts-of-acceptance/card/act-task-cell/act-task-cell.component';
import {
  GridColumnType,
  GridComponentColumn,
  GridCurrencyControlColumn,
  GridNumberControlColumn,
} from '../models/inner/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';

export const ACT_LINE_LIST: List = {
  version: 3,
  name: 'actLine',
  views: [
    {
      name: 'default',
      resizableColumns: true,
      columns: [
        {
          column: 'projectTask',
          visibleByDefault: true,
          width: 300,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 300,
        },
        {
          column: 'amount',
          visibleByDefault: true,
          width: 140,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'exchangeRate',
          visibleByDefault: false,
          width: 100,
        },
        {
          column: 'amountBC',
          visibleByDefault: false,
          width: 140,
          totalByDefault: TotalType.Sum,
        },
      ],
    },
  ],
  columns: [
    <GridComponentColumn>{
      name: 'projectTask',
      type: GridColumnType.Component,
      component: ActTaskCellComponent,
      header: 'acts.card.columns.task.header',
      hint: 'acts.card.columns.task.hint',
    },
    {
      name: 'description',
      header: 'acts.card.columns.description.header',
      hint: 'acts.card.columns.description.hint',
      type: GridColumnType.StringControl,
    },
    <GridCurrencyControlColumn>{
      name: 'amount',
      header: 'acts.card.columns.amount.header',
      hint: 'acts.card.columns.amount.hint',
      type: GridColumnType.CurrencyControl,
      allowNull: false,
      controlType: 'currency',
      isEditableCurrency: false,
      forceCellUpdating: true,
    },
    <GridNumberControlColumn>{
      name: 'exchangeRate',
      header: 'acts.card.columns.exchangeRate.header',
      hint: 'acts.card.columns.exchangeRate.hint',
      type: GridColumnType.NumberControl,
      allowNull: false,
      controlType: 'decimal',
      precision: 13,
      forceCellUpdating: true,
    },
    {
      name: 'amountBC',
      header: 'acts.card.columns.amountBC.header',
      hint: 'acts.card.columns.amountBC.hint',
      type: GridColumnType.Currency,
      forceCellUpdating: true,
    },
  ],
  dataColumns: [
    {
      column: 'projectTask',
      field: 'projectTask',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'amount',
      field: 'amount',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'exchangeRate',
      field: 'exchangeRate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'amountBC',
      field: 'amountBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
