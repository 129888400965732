import { UntypedFormArray, ValidationErrors } from '@angular/forms';

import _ from 'lodash';

export function uniqByKey(
  formArray: UntypedFormArray,
  key: string,
): ValidationErrors | null {
  if (!formArray.value.length) {
    return null;
  }

  if (_.uniqBy(formArray.value, key).length !== formArray.value.length) {
    return { notUniq: true };
  }

  return null;
}
