import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService, Entity } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';

@Injectable()
export class CurrencyService {
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  private nameSubject = new BehaviorSubject<string>(null);
  public name$ = this.nameSubject.asObservable();
  public readonly: boolean;

  public get entity(): Entity {
    return this.data.collection('Currencies').entity(this.entityId);
  }

  constructor(
    @Inject('entityId') public entityId,
    private notification: NotificationService,
    private data: DataService,
  ) {
    this.reload();
  }

  /** Updates name in form. */
  public updateName(value: any) {
    this.nameSubject.next(value);
  }

  /** Reloads main properties. */
  public reload(): void {
    this.entity
      .get<NamedEntity>({ select: ['id', 'name', 'editAllowed'] })
      .subscribe({
        next: (currency) => {
          this.updateName(currency.name);
          this.readonly = !currency.editAllowed;
        },
        error: (error: Exception) => {
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notification.error(error.message);
          }
        },
      });
  }
}
