<div (clickOutside)="close()" [attachOutsideOnClick]="true" [exclude]="'wp-task-info'">
  <div
    class="face"
    [hidden]="areaExpanded"
    (click)="openArea()"
    title="{{ getHint() }}"
    [ngClass]="{ readonly: readonly }"
  >
    <div *ngIf="task.project">
      <div class="trim first-line">
        {{ getClPrName() }}
      </div>
      <div class="trim">{{ task?.projectTask.name }}</div>
    </div>
    <div *ngIf="!task.project" class="trim placeholder">
      {{ phSelectTask | translate }}
    </div>

    <i
      *ngIf="task.projectTask"
      class="bi bi-info-circle information"
      aria-hidden="true"
      [class.show]="openedTaskInfo"
      (click)="openInfo($event, task.projectTask.id)"
    ></i>
    <i class="bi bi-chevron-down expander" aria-hidden="true"></i>
  </div>
  <input
    #input
    *ngIf="areaExpanded"
    class="form-control"
    placeholder="{{ searchPh | translate }}"
    [formControl]="searchControl"
  />

  <div *ngIf="areaExpanded" class="expanding-area disable-selecting">
    <!--Headers table-->
    <table style="cursor: default" class="table table-borderless data">
      <thead>
        <tr>
          <th *ngIf="showClient" style="width: 30%">
            {{ 'timesheets.card.taskSelector.client' | translate }}
          </th>
          <th style="width: 30%">
            {{ 'timesheets.card.taskSelector.project' | translate }}
          </th>
          <th style="width: 40%">
            {{ 'timesheets.card.taskSelector.task' | translate }}
          </th>
        </tr>
      </thead>
    </table>

    <!--Search table-->
    <table class="table table-bordered data" *ngIf="searchControl.value">
      <tbody>
        <tr>
          <td style="width: 100%; height: 200px; vertical-align: top; padding: 0">
            <div id="search-container" style="width: 100%; height: 200px; overflow-y: auto">
              <wp-loading-indicator [loading]="searchLoading"></wp-loading-indicator>

              <div
                style="text-align: center; color: var(--bs-text-minor); padding: 8px"
                *ngIf="searchTasks.length === 0"
                [hidden]="searchLoading"
              >
                {{ 'shared.noDisplayData' | translate }}
              </div>

              <table class="select-task-list">
                <tbody>
                  <tr
                    *ngFor="let searchTask of searchTasks; trackBy: trackId"
                    [ngClass]="{
                      selected: searchTask.id === selectedSearchTask?.id
                    }"
                    (click)="searchTaskClick(searchTask.id)"
                    (dblclick)="ok()"
                    id="{{ searchTask.id }}"
                  >
                    <td
                      *ngIf="showClient"
                      style="width: 30%"
                      title="{{ searchTask.organization.name }}"
                    >
                      <div class="trim">{{ searchTask.organization.name }}</div>
                    </td>
                    <td style="width: 30%" title="{{ searchTask.project.name }}">
                      <div class="trim">{{ searchTask.project.name }}</div>
                    </td>
                    <td style="width: 40%" title="{{ searchTask.projectTask.name }}">
                      <div class="trim">{{ searchTask.projectTask.name }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                style="text-align: center; color: var(--bs-text-minor); padding: 8px"
                *ngIf="searchTasks.length == 50"
                ng-hide="searchLoading"
              >
                {{ 'timesheets.card.taskSelector.displayFirst50Records' | translate }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!--Select table-->

    <table class="table table-bordered data" *ngIf="!searchControl.value">
      <tbody>
        <tr>
          <td *ngIf="showClient" style="width: 30%; height: 200px; vertical-align: top; padding: 0">
            <div id="clients-container">
              <wp-loading-indicator [loading]="clientsLoading"></wp-loading-indicator>

              <table class="select-task-list">
                <tbody>
                  <tr
                    *ngFor="let client of clients; trackBy: trackId"
                    [ngClass]="{ selected: client.id === selectedClient?.id }"
                    (click)="clientClick(client.id)"
                    id="{{ client.id }}"
                  >
                    <td style="white-space: nowrap" title="{{ client.name }}">
                      <div class="trim">{{ client.name }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>

          <td style="width: 30%; height: 200px; vertical-align: top; padding: 0">
            <div id="projects-container">
              <wp-loading-indicator [loading]="projectsLoading"></wp-loading-indicator>

              <div
                style="text-align: center; color: var(--bs-text-minor); padding: 8px"
                *ngIf="projects.length == 0"
                [hidden]="projectsLoading"
              >
                {{ 'timesheets.card.taskSelector.empty' | translate }}
              </div>
              <table class="select-task-list" [hidden]="projectsLoading">
                <tbody>
                  <tr
                    *ngFor="let project of projects; trackBy: trackId"
                    [ngClass]="{ selected: project.id === selectedProject?.id }"
                    (click)="projectClick(project.id)"
                    id="{{ project.id }}"
                  >
                    <td style="white-space: nowrap" title="{{ project.name }}">
                      <div class="trim">{{ project.name }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>

          <td style="width: 40%; height: 200px; vertical-align: top; padding: 0">
            <div id="tasks-container" (scroll)="infoPopupService.close()">
              <wp-loading-indicator [loading]="tasksLoading"></wp-loading-indicator>

              <div
                style="text-align: center; color: var(--bs-text-minor); padding: 8px"
                *ngIf="tasks.length == 0"
                [hidden]="tasksLoading"
              >
                {{ 'timesheets.card.taskSelector.empty' | translate }}
              </div>
              <table class="select-task-list" [hidden]="tasksLoading">
                <tbody>
                  <tr
                    id="{{ task.id }}"
                    *ngFor="let task of tasks; trackBy: trackId"
                    [ngClass]="{ selected: task.id === selectedTask?.id }"
                    (click)="taskClick(task.id, $event)"
                    (dblclick)="selectTask(task)"
                    [hidden]="!task.isShow"
                  >
                    <td
                      style="white-space: nowrap; padding-left: 0"
                      title="{{ task.name }}"
                      [ngClass]="{ 'disabled-task': !task.allowEntry }"
                    >
                      <div class="trim" [ngStyle]="getTaskMargin(task.indent, task.isExpanded)">
                        <i
                          style="cursor: pointer"
                          *ngIf="task.isExpanded === true"
                          (click)="clickExpander(task)"
                          class="bi bi-chevron-down"
                        ></i>
                        <i
                          style="cursor: pointer"
                          *ngIf="task.isExpanded === false"
                          (click)="clickExpander(task)"
                          class="bi bi-chevron-right"
                        ></i
                        >{{ task.name }}
                      </div>

                      <i
                        class="bi bi-info-circle information"
                        aria-hidden="true"
                        [class.show]="openedTaskInfo === task.id"
                        (click)="openInfo($event, task.id)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="ts-actions">
      <button class="btn btn-primary" (click)="ok()" *ngIf="selectedTask || selectedSearchTask">
        {{ 'shared.actions.select' | translate }}
      </button>
      <button type="button" class="btn btn-default" (click)="cancel()">
        {{ 'shared.actions.cancel' | translate }}
      </button>
    </div>
  </div>
</div>
