<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'timesheets.card.verifyingRules.header' | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <table class="table table-hover" style="padding: 0">
      <tr *ngFor="let item of items">
        <td style="width: 30px; border: none">
          <span
            *ngIf="!item.canOverride"
            style="color: var(--bs-danger)"
            class="bi bi-slash-circle"
          ></span>
          <span
            *ngIf="item.canOverride"
            style="color: var(--bs-orange)"
            class="bi bi-info-circle"
          ></span>
        </td>
        <td style="border: none">
          {{ item.message }}
          @if (item?.customMessage) {
            <span class="fw-bold p-0 text-break d-block">{{ item?.customMessage }}</span>
          }
        </td>
      </tr>
    </table>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="canOverride"
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      style="min-width: 100px"
    >
      {{ 'timesheets.card.verifyingRules.ignoreAndSubmit' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" style="min-width: 100px">
      {{ 'timesheets.card.verifyingRules.correct' | translate }}
    </button>
  </div>
</form>
