<wp-loading-indicator [state]="state"></wp-loading-indicator>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" [hidden]="state !== 'Ready'" class="form">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="form-group" tmtIndicator>
          <label>{{ 'settings.settings.main.displayName' | translate }}</label>
          <wp-text-box
            formControlName="displayName"
            placeholder="{{ 'settings.settings.main.displayName' | translate }}"
          ></wp-text-box>
        </div>

        <label class="group mt-3">
          {{ 'settings.settings.main.owner.groupHeader' | translate }}
        </label>

        <div class="form-group" tmtIndicator>
          <label>{{ 'settings.settings.main.owner.properties.name.label' | translate }}</label>
          <wp-text-box
            formControlName="ownerName"
            placeholder="{{
              'settings.settings.main.owner.properties.name.placeholder' | translate
            }}"
          ></wp-text-box>
        </div>
        <div class="form-group" tmtIndicator>
          <label>{{ 'settings.settings.main.owner.properties.email.label' | translate }}</label>
          <wp-text-box
            formControlName="ownerEmail"
            placeholder="{{
              'settings.settings.main.owner.properties.email.placeholder' | translate
            }}"
          ></wp-text-box>
          <small class="text-body-secondary">{{
            'settings.settings.main.owner.properties.email.help' | translate
          }}</small>
        </div>
        <div class="form-group" tmtIndicator>
          <label>{{ 'settings.settings.main.owner.properties.phone.label' | translate }}</label>
          <wp-text-box
            formControlName="ownerPhone"
            placeholder="{{
              'settings.settings.main.owner.properties.phone.placeholder' | translate
            }}"
          ></wp-text-box>
        </div>
        <hr />
        <div class="form-check">
          <input
            formControlName="isServiceUserAllowed"
            type="checkbox"
            class="form-check-input"
            id="isServiceUserAllowed"
          />
          <label class="form-check-label" for="isServiceUserAllowed">
            {{ 'settings.settings.main.isServiceUserAllowed' | translate }}
          </label>
          <br />
        </div>
      </div>
    </div>
  </div>
  <div class="actions" *ngIf="!readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
