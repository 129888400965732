@if (column.navigationType === navigationType.User) {
  <div class="avatar-cell">
    <img class="avatar-bg" [src]="row.id | avatar: 60" />
    <a [uiSref]="column.state" [uiParams]="uiParams">{{ row.name }}</a>
  </div>
} @else {
  <div class="avatar-cell">
    @switch (row.resourceType) {
      @case (resourceType.user) {
        <img class="avatar-bg" [src]="row.id | avatar: 60" />
      }
      @case (resourceType.department) {
        <img src="/assets/images/avatar/department.svg" />
      }

      @case (resourceType.generic) {
        <img src="/assets/images/avatar/role.svg" />
      }
    }

    <a [uiSref]="column.state" [uiParams]="uiParams">{{ row.name }}</a>
  </div>
}
