import { Component, OnInit, Input, inject, DestroyRef } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { AccountingPeriod } from 'src/app/shared/models/entities/finance/accounting-period.model';
import { FormHelper } from 'src/app/shared/helpers/form-helper';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-accounting-period-card',
  templateUrl: './accounting-period-card.component.html',
})
export class AccountingPeriodCardComponent implements OnInit {
  @Input() entityId: string;

  public state: CardState;
  public isSaving = false;
  public form: UntypedFormGroup = this.fb.group({
    name: null,
    start: [null, Validators.required],
    end: [null, Validators.required],
    isActive: [],
  });
  public readonly: boolean;

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
  ) {}

  // Загрузка данных.
  private load() {
    this.state = CardState.Loading;
    this.form.markAsPristine();
    this.form.markAsUntouched();

    // Загрузка данных.
    this.data
      .collection('AccountingPeriods')
      .entity(this.entityId)
      .get<AccountingPeriod>(null)
      .subscribe({
        next: (period: AccountingPeriod) => {
          this.form.patchValue(period);
          this.readonly = !period.editAllowed;
          this.readonly ? this.form.disable() : this.form.enable();

          this.actionService.action('save').isShown = !this.readonly;
          this.state = CardState.Ready;
        },
        error: (error: Exception) => {
          this.state = CardState.Error;
          this.notification.error(error.message);
        },
      });
  }

  public reload() {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  // Сохранение данных.
  public save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const period = <AccountingPeriod>this.form.value;

    this.data
      .collection('AccountingPeriods')
      .entity(this.entityId)
      .patch(period)
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal('finance.periods.card.messages.saved');
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  }

  ngOnInit() {
    FormHelper.controlDatePair(
      this.form,
      'start',
      'end',
      takeUntilDestroyed(this.destroyRef),
    );

    // Установка главного меню.
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
    ]);

    this.load();
  }
}
