import { DatePipe } from '@angular/common';
import {
  DestroyRef,
  inject,
  Inject,
  Injectable,
  Optional,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateTime, Interval } from 'luxon';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { FinancialAccountsService } from 'src/app/core/financial-accounts.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Guid } from 'src/app/shared/helpers/guid';
import { AccountingPeriod } from 'src/app/shared/models/entities/finance/accounting-period.model';
import {
  FinancialAccountType,
  FinancialAccountTypes,
} from 'src/app/shared/models/entities/finance/financial-account-type.enum';
import { FinancialAccount } from 'src/app/shared/models/entities/finance/financial-account.model';
import { AccountingEntry } from 'src/app/shared/models/entities/finance/accounting-entry.model';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { AccountingEntryMode } from 'src/app/shared/models/entities/finance/accounting-entry-mode.enum';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { AppService } from 'src/app/core/app.service';
import { CurrenciesService } from 'src/app/shared/services/currencies.service';
import { Currency } from 'src/app/shared/models/entities/settings/currency.model';
import { NavigationService } from 'src/app/core/navigation.service';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class AccountingEntryService {
  public mode: 'create' | 'edit' = 'create';
  public minAllowedNumber = -999999999;

  public readonly = false;

  public projectsQuery = {
    select: ['id', 'name'],
    expand: {
      currency: {
        select: ['id', 'alpha3Code', 'name'],
      },
    },
  };

  public form = this.fb.group({
    id: null,
    typeId: [FinancialAccountType.revenue.id, Validators.required],
    date: [DateTime.now().toISODate(), Validators.required],
    amount: [null, Validators.required],
    exchangeRate: 1,
    amountBC: null,
    project: [null, Validators.required],
    projectTask: [null, Validators.required],
    description: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
    account: [null, Validators.required],
    timeOffType: [null, Validators.required],
    document: null,
    documentDate: null,
    created: null,
    mode: null,
    hours: null,
    costRate: null,
    expenseType: null,
    user: null,
    department: null,
    legalEntity: null,
    projectCostCenter: null,
  });

  private entityQuery = {
    expand: {
      project: {
        select: ['id', 'name'],
        expand: {
          currency: {
            select: ['id', 'alpha3Code', 'name'],
          },
        },
      },
      projectTask: {
        select: ['id', 'name', 'indent', 'leadTaskId', 'structNumber'],
      },
      account: {
        select: ['id', 'name'],
        expand: {
          type: { select: ['id', 'name', 'code'] },
          expenseType: { select: ['id', 'name', 'code'] },
        },
      },
      timeOffType: {
        select: ['id', 'name'],
      },
      mode: {
        select: ['id', 'name'],
      },
      department: {
        select: ['id', 'name'],
      },
      user: {
        select: ['id', 'name'],
      },
      legalEntity: {
        select: ['id', 'name'],
      },
      projectCostCenter: {
        select: ['id', 'name'],
      },
    },
  };

  public saving$ = new Subject<boolean>();
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  public accounts$: Observable<FinancialAccount[]>;

  public name$ = new BehaviorSubject<string>('');

  private isLaborCostSubject = new BehaviorSubject(false);
  public isLaborCost$ = this.isLaborCostSubject.asObservable();
  private isTimeOffLaborCost = new BehaviorSubject(false);
  public isTimeOffLaborCost$ = this.isTimeOffLaborCost.asObservable();

  private isUserEditable = new BehaviorSubject(true);
  public isUserEditable$ = this.isUserEditable.asObservable();

  private financialAccountType = new BehaviorSubject<string>(null);
  public financialAccountType$ = this.financialAccountType.asObservable();

  private entryMode = new BehaviorSubject<AccountingEntryMode>(
    AccountingEntryMode.manual,
  );
  public entryMode$ = this.entryMode.asObservable();
  private projectCostCenters = new Subject<NamedEntity[]>();
  public projectCostCenters$ = this.projectCostCenters.asObservable();

  private isShownProjectCostCenter = new BehaviorSubject<boolean>(false);
  public isShownProjectCostCenter$ =
    this.isShownProjectCostCenter.asObservable();

  public get closedPeriod(): Interval {
    return this._closedPeriod;
  }

  public get isLaborCost(): boolean {
    if (!this.form.value.account) {
      return false;
    }
    return (
      this.form.value.account.type.id === FinancialAccountType.expenses.id &&
      !this.form.value.account.expenseType
    );
  }

  public get documentLink(): string {
    if (this.isLaborCost) {
      return 'timesheet';
    } else if (
      this.form.value.account?.type?.id === FinancialAccountType.revenue.id
    ) {
      return 'actOfAcceptance';
    } else if (
      this.form.value.account?.type?.id === FinancialAccountType.expenses.id
    ) {
      return 'expensesRequest';
    }
  }

  public get isTimeOff(): boolean {
    return (
      this.form.controls.account.value?.id === FinancialAccount.timeOffCostId
    );
  }

  private _closedPeriod: Interval;

  private _currencyCode: string;
  private _projectCurrency: Currency;
  private _baseCurrencyCode: string;
  private _isCustomCurrency = false;

  /** Component subscriptions cancel subject. */
  private destroyRef = inject(DestroyRef);
  private entityReloaded$ = new Subject<void>();
  public modalClosed$ = new Subject<void>();

  constructor(
    @Optional() @Inject('entityId') public entryId,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    public financialAccountsService: FinancialAccountsService,
    private data: DataService,
    private navigationService: NavigationService,
    private projectTasksService: ProjectTasksService,
    private datePipe: DatePipe,
    private message: MessageService,
    private actionService: ActionPanelService,
    private app: AppService,
    private currenciesService: CurrenciesService,
  ) {}

  public init(isModal = false): void {
    this.initSubscriptions();
    this._baseCurrencyCode = this.app.session.configuration.baseCurrencyCode;
    this._currencyCode = this._baseCurrencyCode;

    if (this.entryId) {
      this.load();
    } else {
      this.initFormGroup(null, isModal);
    }
  }

  public save(): Promise<void> {
    return new Promise((resolve, reject) => {
      const isLaborCost =
        this.form.value.account?.id === FinancialAccount.laborCostId;
      const isTimeOffLaborCost =
        this.form.value.account?.id === FinancialAccount.timeOffCostId;

      if (isLaborCost || isTimeOffLaborCost) {
        this.form.controls.user.addValidators(Validators.required);
      } else {
        this.form.controls.user.removeValidators(Validators.required);
      }
      if (isTimeOffLaborCost) {
        this.form.controls.project.patchValue(null, { emitEvent: false });
        this.form.controls.projectTask.patchValue(null, { emitEvent: false });
        this.form.controls.timeOffType.addValidators(Validators.required);
        this.form.controls.project.removeValidators(Validators.required);
        this.form.controls.projectTask.removeValidators(Validators.required);
      } else {
        this.form.controls.timeOffType.patchValue(null, { emitEvent: false });
        this.form.controls.timeOffType.removeValidators(Validators.required);
        this.form.controls.project.addValidators(Validators.required);
        this.form.controls.projectTask.addValidators(Validators.required);
      }
      this.form.controls.project.updateValueAndValidity({ emitEvent: false });
      this.form.controls.projectTask.updateValueAndValidity();
      this.form.controls.user.updateValueAndValidity();
      this.form.controls.timeOffType.updateValueAndValidity();

      this.form.markAllAsTouched();
      if (this.form.invalid) {
        this.notification.warningLocal('shared.messages.requiredFieldsError');
        reject();
        return;
      }

      const formData = this.form.getRawValue();
      const entry = {
        accountId: formData.account.id,
        projectId: formData.project?.id || null,
        projectTaskId: formData.projectTask?.id || null,
        modeId: this.form.value.mode?.id ?? AccountingEntryMode.manual.id,
        documentId: formData.document?.id,
        documentDate: formData.documentDate ?? formData.date,
        description: formData.description,
        amount: formData.amount.value,
        amountBC: formData.amountBC?.value,
        date: formData.date,
        hours: formData.hours,
        costRate: formData.costRate,
        userId: formData.user?.id,
        departmentId: formData.department?.id || null,
        timeOffTypeId: formData.timeOffType?.id || null,
        id: this.entryId ?? Guid.generate(),
        legalEntityId: formData.legalEntity?.id || null,
        projectCostCenterId: formData.projectCostCenter?.id || null,
      };

      this.saving$.next(true);

      const collection = this.data.collection('AccountingEntries');
      const action = this.entryId
        ? collection.entity(this.entryId).update(entry)
        : collection.insert(entry);

      action.subscribe({
        next: () => {
          this.saving$.next(false);
          this.form.markAsPristine();
          this.notification.successLocal('finance.entries.card.messages.saved');
          resolve();
        },
        error: (error: Exception) => {
          this.saving$.next(false);
          reject();
          this.notification.error(error.message);
        },
      });
    });
  }

  /**
   * Reloads Card.
   * */
  public reload() {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  /**
   * Disposes service active resources.
   * */
  public dispose() {
    this.modalClosed$.next();
    this.entityReloaded$.next();
  }

  /**
   * Inits Service level subscriptions.
   * */
  private initSubscriptions() {
    this.form.controls.typeId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const typeId = this.form.controls.typeId.value;

        this.accounts$ = this.financialAccountsService.accounts$.pipe(
          map((accounts) => accounts.filter((a) => a.type.id === typeId)),
        );

        if (!this.entryId) {
          this.name$.next(
            typeId === FinancialAccountType.expenses.id
              ? FinancialAccountType.expenses.name
              : FinancialAccountType.revenue.name,
          );
        }

        this.accounts$
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((accounts) => {
            if (
              this.form.value.account &&
              accounts.some(
                (x) => x.id === this.form.controls.account?.value?.id,
              )
            )
              return;
            if (accounts.length === 1) {
              this.form.controls.account.setValue(accounts[0]);
            } else {
              this.form.controls.account.setValue(null);
            }
          });

        this.financialAccountType.next(typeId);
      });

    this.form.controls.user.valueChanges
      .pipe(
        switchMap((user) =>
          user
            ? this.data
                .collection('Users')
                .entity(user.id)
                .get<User>({
                  select: ['id'],
                  expand: {
                    legalEntity: { select: ['id', 'name'] },
                    department: { select: ['id', 'name'] },
                  },
                })
            : of(null),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((user) => {
        if (
          this.form.controls.legalEntity.value?.id === user?.legalEntity?.id &&
          this.form.controls.department.value?.id === user?.department?.id
        )
          return;
        this.form.controls.legalEntity.setValue(user?.legalEntity);
        this.form.controls.department.setValue(user?.department);
        this.form.controls.projectCostCenter.setValue(null, {
          emitEvent: false,
        });
        this.updateProjectCostCenters();
      });
  }

  /**
   * Loads Accounting entry.
   * */
  private load() {
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.state$.next(CardState.Loading);

    this.data
      .collection('AccountingEntries')
      .entity(this.entryId)
      .get<AccountingEntry>(this.entityQuery)
      .subscribe({
        next: (entry: AccountingEntry) => {
          this.onEntityLoaded(entry);

          this.actionService.action('save').isShown = !this.readonly;
          this.propagateName();

          this.navigationService.addRouteSegment({
            id: entry.id,
            title: this.getName(),
          });

          this.state$.next(CardState.Ready);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.state$.next(CardState.Error);
        },
      });
  }

  /** Gets dynamic card name. */
  private getName(): string {
    const typeId = this.form.controls.typeId.value;
    const type = FinancialAccountTypes.find((t) => t.id === typeId);

    const typeName = this.translate.instant(type.name);

    const projectName = this.form.controls.project.value?.name;
    let name = this.translate.instant('finance.entries.card.nameTemplate', {
      type: typeName,
      project: projectName,
      date: this.datePipe.transform(
        DateTime.fromISO(this.form.controls.date.value)?.toISODate(),
        'shortDate',
      ),
    });
    if (!projectName) {
      const timeOffTypeName = this.form.controls.timeOffType?.value?.name;
      const userName = this.form.controls.user?.value?.name;
      name = this.translate.instant('finance.entries.card.timeOffTemplate', {
        type: typeName,
        date: this.datePipe.transform(
          DateTime.fromISO(this.form.controls.date.value)?.toISODate(),
          'shortDate',
        ),
        timeOffType: timeOffTypeName,
        user: userName,
      });
    }

    return name;
  }

  private propagateName() {
    this.name$.next(this.getName());
  }

  /**
   * Accounting entry load event handler.
   *
   * @param entry Accounting entry.
   * */
  private onEntityLoaded(entry: AccountingEntry) {
    this.entityReloaded$.next();

    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.initFormGroup(entry);
  }

  /**
   * Initializes Accounting entry form group.
   *
   * @param entry Accounting entry.
   * @param isModal Indicates whether Accounting entry group is created on Modal window or not.
   * */
  private initFormGroup(entry?: AccountingEntry, isModal = false) {
    this.form.controls.projectTask.disable();
    const destroySub = isModal ? this.modalClosed$ : this.entityReloaded$;

    if (entry) {
      this.form.controls.typeId.setValue(entry.account.type.id);
      this.form.controls.account.setValue({
        id: entry.account.id,
        name: entry.account.name,
      });
      this.form.patchValue(entry, { emitEvent: false });

      this.readonly = !entry.periodIsOpen || !entry.editAllowed;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.readonly
        ? this.form.disable({ emitEvent: false })
        : this.form.enable({ emitEvent: false });

      this.refreshCurrencyCodes();

      if (
        !entry.timeOffTypeId &&
        entry.account.id !== FinancialAccount.laborCostId
      ) {
        this.form.controls.user.clearValidators();
        this.form.controls.timeOffType.clearValidators();
        this.form.updateValueAndValidity();
      }

      if (entry.mode.id === AccountingEntryMode.automatic.id) {
        this.isUserEditable.next(false);
        this.entryMode.next(AccountingEntryMode.automatic);
      } else {
        this.isUserEditable.next(true);
        this.entryMode.next(AccountingEntryMode.manual);
      }

      this.isTimeOffLaborCost.next(!!entry.timeOffTypeId);
      this.isLaborCostSubject.next(
        entry.account.id === FinancialAccount.laborCostId,
      );

      if (this.isTimeOff) {
        this._currencyCode = this._baseCurrencyCode;
        this._isCustomCurrency = false;
      }

      this.form.controls.amount.setValue({
        value: entry.amount,
        currencyCode: this._currencyCode,
      });

      this.form.controls.amountBC.setValue(entry.amountBC);

      this.form.controls.document.setValue({
        id: entry.documentId,
        name: entry.documentTitle,
      } as NamedEntity);

      this.updateProjectCostCenters();
    }

    this.form.controls.amount.valueChanges
      .pipe(takeUntil(destroySub))
      .subscribe(() => {
        this.setAmounts(false);
      });

    this.form.controls.account.valueChanges
      .pipe(takeUntil(destroySub))
      .subscribe((account) => {
        if (!account) {
          return;
        }

        this.isTimeOffLaborCost.next(
          account.id === FinancialAccount.timeOffCostId,
        );
        this.isLaborCostSubject.next(
          account.id === FinancialAccount.laborCostId,
        );

        this.form.controls.user.setValue(null, { emitEvent: false });
        this.form.controls.legalEntity.setValue(null, { emitEvent: false });
        this.form.controls.projectCostCenter.setValue(null, {
          emitEvent: false,
        });

        this.updateProjectCostCenters();
        this.refreshCurrencyCodes();
        this.refreshXr();
      });

    this.fetchClosedPeriod();

    this.form.controls.project.valueChanges
      .pipe(takeUntil(destroySub))
      .subscribe(() => {
        this.form.controls.projectTask.setValue(null);

        const projectId = this.form.controls.project.value?.id;
        if (projectId) {
          this.form.controls.projectTask.enable();
          this.projectTasksService
            .getProjectTasks(projectId)
            .subscribe((tasks) => {
              const mainTask = tasks.find((t) => !t.leadTaskId);
              this.form.controls.projectTask.setValue(mainTask);
            });
        } else {
          this.form.controls.projectTask.disable();
        }

        this.refreshCurrencyCodes();
        this.refreshXr();
        this.form.controls.projectCostCenter.setValue(null, {
          emitEvent: false,
        });
        this.updateProjectCostCenters();
      });

    if (this._isCustomCurrency) {
      this.refreshXr(false);
    }

    this.form.controls.date.valueChanges
      .pipe(takeUntil(destroySub))
      .subscribe((date) => {
        this.form.controls.documentDate.setValue(date);

        if (this._isCustomCurrency) {
          this.refreshXr();
        }
      });

    if (!this.entryId) {
      this.form.controls.typeId.setValue(FinancialAccountType.revenue.id);
    }
  }

  private fetchClosedPeriod() {
    this.data
      .collection('AccountingPeriods')
      .query<AccountingPeriod[]>({
        select: ['id', 'start', 'end'],
        filter: { isActive: false },
        orderBy: ['start', 'end'],
      })
      .subscribe({
        next: (periods: AccountingPeriod[]) => {
          if (!periods.length) {
            return;
          }
          const start = periods
            .map((p) => DateTime.fromISO(p.start))
            .reduce((curr, next) => (curr < next ? curr : next));
          const end = periods
            .map((p) => DateTime.fromISO(p.end))
            .reduce((curr, next) => (curr > next ? curr : next));
          this._closedPeriod = Interval.fromDateTimes(start, end);

          const currDate = DateTime.fromISO(this.form.value.date);
          if (this.isExcludedDate(currDate) && !this.readonly) {
            this.form.patchValue({ date: end.plus({ days: 1 }).toISODate() });
          }
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  private isExcludedDate(date: DateTime): boolean {
    const currDate = date.toISODate();
    const periodStart = this._closedPeriod.start.toISODate();
    const periodEnd = this._closedPeriod.end.toISODate();
    return (
      (currDate > periodStart && currDate < periodEnd) ||
      currDate === periodStart ||
      currDate === periodEnd
    );
  }

  /**
   * Refreshes Currency codes.
   * */
  private refreshCurrencyCodes() {
    if (this.isTimeOff) {
      this._currencyCode = this._baseCurrencyCode;
      this._isCustomCurrency = false;
    } else {
      this._projectCurrency = this.form.controls.project.value?.currency;
      this._currencyCode =
        this._projectCurrency?.alpha3Code ?? this._baseCurrencyCode;
      this._isCustomCurrency = this._currencyCode !== this._baseCurrencyCode;
    }
  }

  /**
   * Refreshes Accounting entry with Currency Exchange rate by Accounting entry date.
   *
   * @param updateAmounts Indicates to update amount field values.
   * */
  private refreshXr(updateAmounts = true) {
    if (!this.form.controls.project.value?.id) {
      this.setXr(1, false);
      return;
    }
    if (!this._projectCurrency) {
      console.warn('Project Currency is not defined.');
      return;
    }
    this.currenciesService
      .getExchangeRate(
        this._projectCurrency.id,
        this.form.controls.date.value ?? DateTime.now().toISODate(),
      )
      .subscribe({
        next: (xr) => {
          this.setXr(xr, updateAmounts);
        },
        error: (error: Exception) => {
          this.setXr(1, updateAmounts);
          this.notification.error(error.message);
        },
      });
  }

  /**
   * Sets Accounting entry Exchange rate.
   *
   * @param xr New Exchange rate.
   * @param updateAmounts Indicates to update amount field values.
   * */
  private setXr(xr: number, updateAmounts = true) {
    this.form.controls.exchangeRate.setValue(xr, { emitEvent: false });
    if (updateAmounts) {
      this.setAmounts();
    }
  }

  private setAmounts(updateAmount = true) {
    const amount = this.form.controls.amount.value;
    if (updateAmount) {
      this.form.controls.amount.setValue(
        {
          value: amount?.value,
          currencyCode: this._currencyCode,
        },
        { emitEvent: false },
      );
    }

    const amountBC = this.isTimeOff
      ? amount?.value
      : amount?.value * this.form.controls.exchangeRate.value;
    this.form.controls.amountBC.setValue(amountBC);
  }

  private updateProjectCostCenters() {
    if (
      this.form.controls.legalEntity.value &&
      this.form.controls.project.value &&
      this.form.controls.user.value &&
      this.form.controls.account.value?.id !== FinancialAccount.timeOffCostId
    ) {
      this.isShownProjectCostCenter.next(true);
    } else {
      this.isShownProjectCostCenter.next(false);
      this.form.controls.projectCostCenter.setValue(null);
      return;
    }

    const query: any = {
      select: ['id', 'name'],
      filter: {
        projectId: {
          type: 'guid',
          value: this.form.controls.project.value.id,
        },
        legalEntityId: {
          type: 'guid',
          value: this.form.controls.legalEntity.value.id,
        },
        isActive: true,
      },
    };

    this.data
      .collection('ProjectCostCenters')
      .query<NamedEntity[]>(query)
      .subscribe((costCenters) => {
        this.projectCostCenters.next(costCenters);
        if (this.entryId) return;
        if (costCenters.length === 1) {
          this.form.controls.projectCostCenter.setValue(costCenters[0]);
        } else {
          this.form.controls.projectCostCenter.setValue(null);
        }
      });
  }
}
