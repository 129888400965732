<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label>{{ 'analytics.reports.list.filter.props.type.label' | translate }}</label>
        <wp-select-box
          formControlName="type"
          collection="ReportTypes"
          placeholder="{{ 'analytics.reports.list.filter.props.type.ph' | translate }}"
          groupHandler="group"
          [query]="{
            select: ['id', 'name', 'group']
          }"
        >
        </wp-select-box>
      </div>
    </div>
  </div>
</form>
