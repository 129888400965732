import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { NavigationService } from 'src/app/core/navigation.service';
import {
  Navigation,
  NavigationGroup,
  NavigationItem,
} from 'src/app/shared/models/navigation/navigation';
import { ChromeService } from 'src/app/core/chrome.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import _ from 'lodash';

/** Панель навигации. */
@Component({
  selector: 'wp-navigation-panel',
  templateUrl: './navigation-panel.component.html',
  styleUrls: ['./navigation-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationPanelComponent implements OnInit {
  public navigation: Navigation;
  public groups: NavigationGroup[];
  public filteredGroups: NavigationGroup[];
  public selectedNavigationItem: NavigationItem;
  public isNavPanelShown: boolean;
  public isNavPanelExpanded = false;
  public mode: 'default' | 'edit' = 'default';

  constructor(
    public chromeService: ChromeService,
    public navigationService: NavigationService,
    private appService: AppService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.navigationService.initNavigationViews();

    this.navigationService.navigation$.subscribe((navigation: Navigation) => {
      this.navigation = navigation;
      this.groups = _.cloneDeep(navigation.groups);
      this.setFilteredGroups();
    });

    this.navigationService.indicator$.subscribe(() => {
      // TODO Observable for group?
      this.cdr.markForCheck();
    });

    this.navigationService.navigationItem$.subscribe(
      (selectedNavigationItem: NavigationItem) => {
        this.selectedNavigationItem = selectedNavigationItem;
        this.isNavPanelExpanded = false;
        this.cdr.markForCheck();
      },
    );

    this.chromeService.compactMode$.subscribe(() => {
      this.updateState();
    });

    this.chromeService.permanentCompactMode$.subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  /**
   * Toggles panel mode.
   * `default` - like usual, just links.
   * `edit` - allows to edit item's visibility.
   */
  public toggleMode(): void {
    this.mode = this.mode === 'default' ? 'edit' : 'default';

    if (this.mode === 'default') {
      this.groups = this.navigationService.currentNavigation.groups;
    }

    this.setFilteredGroups();
  }

  /**
   * Toggles item's visibility.
   *
   * @param item Navigation Item.
   */
  public toggleItemVisible(item: NavigationItem): void {
    item.isVisible = !item.isVisible;
  }

  /** Saves updated navigation view. */
  public saveNewGroup(): void {
    this.mode = 'default';
    this.navigationService.saveNavigationView({
      ...this.navigation,
      groups: this.filteredGroups,
    });
  }

  /** Resets navigation view to default. */
  public resetToDefault(): void {
    this.navigationService.resetNavigationView({
      ...this.navigation,
      groups: this.filteredGroups,
    });
  }

  /** Развернуть панель. */
  public expandNavPanel(): void {
    this.isNavPanelExpanded = true;
    this.updateState();
  }

  /** Свернуть панель. */
  public collapseNavPanel(): void {
    this.isNavPanelExpanded = false;
    this.updateState();
  }

  public onClickItem(): void {
    this.collapseNavPanel();
  }

  public groupIsVisible(group: NavigationGroup): boolean {
    return (
      group.items?.filter(
        (item) =>
          !item.indicator ||
          this.navigationService.indicatorValues.get(item.name) > 0 ||
          this.navigationService.selectedNavigationItem?.name === item.name,
      )?.length > 0
    );
  }

  /** Обновить состояние (показывать или нет). */
  public updateState(): void {
    this.isNavPanelShown =
      this.isNavPanelExpanded || !this.chromeService.compactMode;

    if (!this.isNavPanelShown && this.mode === 'edit') {
      this.toggleMode();
    }

    this.cdr.markForCheck();
  }

  private isItemVisible(item: NavigationItem): boolean {
    if (item.allowedFn && !item.allowedFn(this.appService)) {
      return false;
    }

    item.isVisible ??= true;

    if (
      (item.entityType &&
        !this.appService.checkEntityPermission(
          item.entityType,
          PermissionType.Read,
        )) ||
      (!item.isVisible && this.mode === 'default')
    ) {
      return false;
    }

    return true;
  }

  private setFilteredGroups(): void {
    this.filteredGroups = _.cloneDeep(this.groups);
    this.filteredGroups.forEach((group) => {
      group.items.forEach((item) => {
        if (!item.stateParams) {
          item.stateParams = {};
        }
        item.stateParams.navigation = item.name;
      });

      group.items = group.items.filter((item) => this.isItemVisible(item));
    });

    this.cdr.markForCheck();
  }
}
