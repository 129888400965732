import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { UntypedFormGroup } from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';

@Component({
  selector: 'tmt-substitution-user-cell',
  templateUrl: './substitution-user-cell.component.html',
  styleUrls: ['./substitution-user-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubstitutionUserCellComponent implements GridTemplateCell, OnInit {
  @Input() column: GridColumn;
  formGroup: UntypedFormGroup;
  public value: { performer: NamedEntity; realPerformer: NamedEntity };

  ngOnInit(): void {
    this.value = this.formGroup.controls.performer.getRawValue();
  }
}
