import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'src/app/shared/helpers/guid';

@Injectable()
export class DashboardSettingsService {
  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
  ) {}

  /** Returns grant form group.
   *
   * @returns grant form group
   */
  public getGrantFormGroup(): UntypedFormGroup {
    const group = this.fb.group({
      id: Guid.generate(),
      permissionSet: [null, Validators.required],
      roleName: null,
    });
    group.controls.permissionSet.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((permissionSet) => {
        let roleName = '';
        if (permissionSet) {
          roleName = this.translate.instant(
            `enums.role.${permissionSet.roleName}`,
          );
        }
        group.controls['roleName'].setValue(roleName);
      });
    return group;
  }
}
