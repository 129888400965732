<form class="form" *ngIf="$any(cardService.user$ | async).certificatesViewAllowed">
  <label class="group">
    {{ 'components.employeeMainComponent.groups.certificates' | translate }}
  </label>

  <div
    class="toolbar-container pt-0"
    *ngIf="$any(cardService.user$ | async).certificatesEditAllowed"
  >
    <button class="btn btn-default" (click)="addCertificate()">
      <span class="bi bi-plus-lg bi-15"></span>&nbsp;
      {{ 'components.employeeMainComponent.actions.addCertificate' | translate }}
    </button>
  </div>
  <wp-grid [formArray]="certificates" [options]="gridOptions"></wp-grid>
</form>
