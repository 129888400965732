<div class="toolbar-container pt-3" [formGroup]="form">
  <wp-date-period-box
    formControlName="period"
    style="width: 325px"
    placeholder="{{ 'shared.teamWorkOverview.filterPeriodPh' | translate }}"
  >
  </wp-date-period-box>

  <div class="group">
    <div ngbDropdown class="d-inline-block">
      <button
        type="button"
        class="btn btn-default"
        ngbDropdownToggle
        title="{{ getSelectedTypeName() }}"
      >
        {{ getSelectedTypeName() }}
      </button>
      <div ngbDropdownMenu class="dropdown-scroll">
        <button ngbDropdownItem (click)="setType('hours')">
          {{ 'shared.teamWorkDynamics.type.hours' | translate }}
        </button>
        <button ngbDropdownItem (click)="setType('utilization')">
          {{ 'shared.teamWorkDynamics.type.utilization' | translate }}
        </button>
        <!-- <button ngbDropdownItem (click)="setType('paidUtilization')">
          {{ 'shared.teamWorkDynamics.type.paidUtilization' | translate }}
        </button> -->
      </div>
    </div>

    <div ngbDropdown class="d-inline-block">
      <button
        type="button"
        class="btn btn-default"
        ngbDropdownToggle
        title="{{ getGroupingName() }}"
      >
        {{ getGroupingName() }}
      </button>
      <div ngbDropdownMenu class="dropdown-scroll">
        <button ngbDropdownItem (click)="setGrouping('day')">
          {{ 'shared.teamWorkDynamics.groupBy.day' | translate }}
        </button>
        <button ngbDropdownItem (click)="setGrouping('week')">
          {{ 'shared.teamWorkDynamics.groupBy.week' | translate }}
        </button>
        <button ngbDropdownItem (click)="setGrouping('month')">
          {{ 'shared.teamWorkDynamics.groupBy.month' | translate }}
        </button>
        <button ngbDropdownItem (click)="setGrouping('quarter')">
          {{ 'shared.teamWorkDynamics.groupBy.quarter' | translate }}
        </button>
        <button ngbDropdownItem (click)="setGrouping('year')">
          {{ 'shared.teamWorkDynamics.groupBy.year' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div id="chartContainerTrends" class="disable-selecting"></div>
