@if (isModalMode) {
  <form class="modal-form" novalidate ngDraggable>
    <div class="modal-header">
      <h3 class="modal-title">Grid in the modal window</h3>
      <button
        type="button"
        class="btn-close"
        data-dismiss="modal"
        aria-hidden="true"
        (click)="closeModal()"
      ></button>
    </div>
    <div class="modal-body">
      <ng-container [ngTemplateOutlet]="component" />
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="closeModal()">
        {{ 'shared.actions.cancel' | translate }}
      </button>
    </div>
  </form>
} @else {
  <ng-container [ngTemplateOutlet]="component" />
}

<ng-template #component>
  <form [formGroup]="settingForm">
    <div class="form-check">
      <input
        formControlName="isShowRightSideComponent"
        type="checkbox"
        class="form-check-input"
        id="isShowRightSideComponent"
      />
      <label class="form-check-label" for="isShowRightSideComponent">
        {{ 'IsShowRightSideComponent' }}
      </label>
    </div>
    <div class="form-check">
      <input
        formControlName="isShowMultiselectColumn"
        type="checkbox"
        class="form-check-input"
        id="isShowMultiselectColumn"
      />
      <label class="form-check-label" for="isShowMultiselectColumn">
        {{ 'isShowMultiselectColumn' }}
      </label>
    </div>
    <div class="form-group" tmtIndicator>
      <label class="control-label">
        {{ 'SelectionType' }}
      </label>
      <wp-select-box
        class="w-25"
        formControlName="selectionType"
        [values]="selectionTypes"
        [allowNull]="false"
      ></wp-select-box>
    </div>
    @if (!isModalMode) {
      <button type="button" class="btn btn-default" (click)="showInModal()" data-test="showInModal">
        <span>Show in the modal window</span>
      </button>
    }
  </form>
  <div [ngClass]="{ 'double-header-height': gridOptions.rightSideComponent }">
    <ng-template *ngComponentOutlet="gridComponent; inputs: gridComponentInputs"></ng-template>
  </div>

  <div class="mt-3">
    <span class="fw-bold">Selected group value:</span>
    <br />
    <span data-test="selectedGroupValue"> {{ gridService.selectedGroupValue | json }}</span>
  </div>
  <div class="mt-3">
    <span class="fw-bold">Selected groups value:</span>
    <br />
    <span data-test="selectedGroupsValue"> {{ gridService.selectedGroupsValue | json }}</span>
  </div>
</ng-template>
