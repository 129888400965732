import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import {
  GridColumnType,
  GridNumberControlColumn,
} from '../models/inner/grid-column.interface';

export const CURRENCY_EXCHANGE_RATES_LIST: List = {
  version: 2,
  name: 'currency-exchange-rates',
  dataCollection: 'CurrencyExchangeRates',
  views: [
    {
      name: 'currency',
      columns: [
        {
          column: 'effectiveDate',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'exchangeRate',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: {
        column: 'effectiveDate',
        reverse: true,
      },
    },
  ],
  columns: [
    {
      name: 'effectiveDate',
      availableTotals: [TotalType.Count],
      type: GridColumnType.Date,
      header:
        'settings.currencies.card.props.currencyExchangeRates.columns.effectiveDate',
      hint: 'settings.currencies.card.props.currencyExchangeRates.columns.effectiveDate',
    },
    <GridNumberControlColumn>{
      name: 'exchangeRate',
      type: GridColumnType.Decimal,
      precision: 13,
      header:
        'settings.currencies.card.props.currencyExchangeRates.columns.exchangeRate',
      hint: 'settings.currencies.card.props.currencyExchangeRates.columns.exchangeRate',
    },
  ],

  dataColumns: [
    {
      column: 'effectiveDate',
      field: 'effectiveDate',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'exchangeRate',
      field: 'exchangeRate',
      loadingStrategy: LoadingStrategy.Always,
    },
  ],
};
