<div class="toolbar-container" style="padding-top: 0">
  <button
    *ngIf="!readonly"
    type="button"
    class="btn btn-default"
    title="{{ 'analytics.dashboards.settings.actions.addPermissionSet' | translate }}"
    (click)="addGrant()"
  >
    <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
    {{ 'analytics.dashboards.settings.actions.addPermissionSet' | translate }}
  </button>
</div>

<wp-grid [formArray]="formArray" [options]="gridOptions" [readonly]="readonly"></wp-grid>
