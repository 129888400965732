import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';

/** Предоставляет методы для работы коллекциями, связанными с проектом или его версиями. */
@Injectable({
  providedIn: 'root',
})
export class ProjectVersionDataService {
  private projectsCollectionName = 'Projects';
  // Определяет соотношения наименований коллекций проекта к его версиям или их связанным сущностям.
  private _collectionMapping: Map<string, string> = new Map<string, string>([
    [this.projectsCollectionName, 'ProjectVersions'],
  ]);

  constructor(private data: DataService) {}

  /**
   * Возвращает коллекцию для отправки запроса по проекту или его версии.
   *
   * @param projectVersion Версия проекта.
   * @param collectionName Наименование коллекции.
   * */
  public collection(projectVersion: ProjectVersion, collectionName: string) {
    return this.data.collection(
      this.fnCollectionName(projectVersion, collectionName),
    );
  }

  /**
   * Возвращает сущность коллекции для отправки запроса по проекту или его версии.
   *
   * @param projectVersion Версия проекта.
   * @param projectId ID проекта.
   * */
  public projectCollectionEntity(
    projectVersion: ProjectVersion,
    projectId: string,
  ) {
    const entityId = this.fnProjectEntityId(projectVersion, projectId);
    return this.data
      .collection(
        this.fnCollectionName(projectVersion, this.projectsCollectionName),
      )
      .entity(entityId);
  }

  /**
   * Возвращает наименование коллекции проекта или его версии или их связанных сущностей
   * в зависимости от выбранного значения в карточке проекта.
   *
   * @param projectVersion Версия проекта.
   * @param collectionName Наименование коллекции проекта или его связанных сущностей.
   * */
  private fnCollectionName = (
    projectVersion: ProjectVersion,
    collectionName: string,
  ): string => {
    if (!this._collectionMapping.has(collectionName)) {
      throw new Error(`Not found ${collectionName} in mapping`);
    }

    return ProjectVersionUtil.isWorkProjectVersion(projectVersion)
      ? collectionName
      : this._collectionMapping.get(collectionName);
  };

  /**
   * Возвращает ID проекта или его версии в зависимости от выбранного значения в карточке проекта.
   *
   * @param projectVersion Версия проекта.
   * @param projectId ID проекта.
   * */
  private fnProjectEntityId = (
    projectVersion: ProjectVersion,
    projectId: string,
  ) =>
    ProjectVersionUtil.isWorkProjectVersion(projectVersion)
      ? projectId
      : projectVersion?.id;
}
