<ng-container *ngIf="lifecycle">
  <div ngbDropdown class="d-inline-block" *ngIf="lifecycle.transitions.length">
    <span ngbDropdownToggle class="badge" [ngClass]="'text-bg-' + lifecycle.currentState.style">{{
      lifecycle.currentState.name
    }}</span>

    <div ngbDropdownMenu>
      <button
        type="button"
        ngbDropdownItem
        *ngFor="let action of lifecycle.transitions"
        (click)="performTransition(action)"
      >
        <span *ngIf="checkHasIcon()" [class]="'icon bi ' + action.iconClass"></span
        >{{ action.label }}
      </button>
    </div>
  </div>

  <span
    *ngIf="!lifecycle.transitions.length"
    class="badge"
    [ngClass]="'text-bg-' + lifecycle.currentState.style"
    >{{ lifecycle.currentState?.name }}</span
  >
</ng-container>
