export class MassOperationParameters {
  /**
   * TODO: remove when all entities (project version) support default mass deleting checking (DEV-2033)
   * @deprecated
   */
  preventMassDeleting?: boolean;
  /** Key name of entity property. It used for wrapper collection with entity in the separate property. */
  entityPropertyName?: string;
  /** UIRouter's state name of card page */
  state: string;

  /**
   * Gets `select` part of query.
   *
   * @param params mass operations parameters.
   * @returns part of query.
   */
  public static getSelectQuery(params: MassOperationParameters): any {
    return params.entityPropertyName
      ? {
          select: ['id'],
          expand: {
            [params.entityPropertyName]: {
              select: ['id', 'name', 'stateId'],
            },
          },
        }
      : { select: ['id', 'stateId', 'name'] };
  }

  /**
   * Gets `$apply` part of query.
   *
   * @param params mass operations parameters.
   * @param kindProperty lifecycle kind property. Transforms property to `kindPropertyId`.
   * @returns part of query.
   */
  public static getGroupByStateIdQuery(
    params: MassOperationParameters,
    kindProperty: string | null,
  ): any {
    const stateProperty = params.entityPropertyName
      ? `${params.entityPropertyName}/stateId`
      : 'stateId';
    let kindIdProperty: string;

    if (kindProperty) {
      kindIdProperty = params.entityPropertyName
        ? `${params.entityPropertyName}/${kindProperty}Id`
        : `${kindProperty}Id`;
    }

    return {
      groupBy: {
        properties: kindIdProperty
          ? [stateProperty, kindIdProperty]
          : [stateProperty],
        transform: {
          aggregate: {
            [stateProperty]: {
              with: 'countdistinct',
              as: 'count',
            },
          },
        },
      },
    };
  }
}
