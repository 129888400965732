<form class="modal-form" novalidate ngDraggable [formGroup]="form">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.tasksReport.modal.headers.excelExport' | translate }}
    </h3>

    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col" formGroupName="kpiTypes">
        <label class="fw-semibold control-label">{{
          'projects.tasksReport.modal.headers.typesOfIndicators' | translate
        }}</label>
        <div
          *ngFor="let type of kpiTypes"
          class="form-check"
          title="{{ 'enums.kpiType.' + type | translate }}"
        >
          <input
            formControlName="{{ type }}"
            type="checkbox"
            class="form-check-input"
            id="{{ type }}"
          />
          <label class="form-check-label" for="{{ type }}">
            {{ 'enums.kpiType.' + type | translate }}
          </label>
        </div>
      </div>

      <div class="col" formGroupName="kpiKinds">
        <label class="fw-semibold control-label">{{
          'projects.tasksReport.modal.headers.indicators' | translate
        }}</label>

        <div
          *ngFor="let kind of kpiKinds"
          class="form-check"
          title="{{ 'enums.kpiKind.' + kind | translate }}"
        >
          <input
            formControlName="{{ kind }}"
            type="checkbox"
            class="form-check-input"
            id="{{ kind }}"
          />
          <label class="form-check-label" for="{{ kind }}">
            {{ 'enums.kpiKind.' + kind | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="form.valid" type="button" class="btn btn-primary" (click)="ok()">
      {{ 'shared.actions.download' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
