import { Injectable } from '@angular/core';
import { ReportSourceDescription } from '../analytics/shared/models/source-description/report-source-description.model';
import { shareReplay, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { Dictionary } from '../shared/models/dictionary';
import { Exception } from '../shared/models/exception';
import { LogService } from './log.service';
import { cloneDeep } from 'lodash';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public sourceDescriptionsObservables: Dictionary<
    Observable<ReportSourceDescription>
  > = {};

  constructor(
    private data: DataService,
    private log: LogService,
    private notification: NotificationService,
  ) {}

  public getSourceDescription(
    sourceName: string,
  ): Observable<ReportSourceDescription> {
    if (this.sourceDescriptionsObservables[sourceName]) {
      return this.sourceDescriptionsObservables[sourceName];
    }

    const observable = new Observable<ReportSourceDescription>((subscriber) => {
      this.log.debug(`Loading source description for ${sourceName}...`);

      this.data
        .collection('ReportSources')
        .entity(`'${sourceName}'`)
        .get<ReportSourceDescription>()
        .subscribe({
          next: (description) => {
            subscriber.next(description);
          },
          error: (error: Exception) => {
            this.notification.errorLocal('shared.unknownError');
            subscriber.error(error.message);
          },
        });
    }).pipe(
      shareReplay(),
      map((it) => cloneDeep(it)),
    );

    this.sourceDescriptionsObservables[sourceName] = observable;
    return observable;
  }
}
