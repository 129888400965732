import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

@Component({
  selector: 'wp-select-box-test',
  templateUrl: './select-box-test.component.html',
})
export class SelectBoxTestComponent {
  values: NamedEntity[] = [
    { id: '1', name: 'Text 01' },
    { id: '2', name: 'Text 02' },
    { id: '3', name: 'Text 03' },
    { id: '4', name: 'Text 04' },
    { id: '5', name: 'Text 05' },
    { id: '6', name: 'Text 06' },
    { id: '7', name: 'Text 07' },
    { id: '8', name: 'Text 08' },
    { id: '9', name: 'Text 09' },
    { id: '10', name: 'Text 10' },
    { id: '11', name: 'Text 11' },
    { id: '12', name: 'Text 12' },
    { id: '13', name: 'Text 13' },
    { id: '14', name: 'Text 14' },
    { id: '15', name: 'Text 15' },
    { id: '16', name: 'Text 16' },
    { id: '17', name: 'Text 17' },
  ];
  groupingValues: Record<string, string>[] = [
    { label: 'Number 1', id: '1', group: 'odd' },
    { label: 'Number 2', id: '2', group: 'even' },
    { label: 'Number 3', id: '3', group: 'odd' },
    { label: 'Number 4', id: '4', group: 'even' },
    { label: 'Number 5', id: '5', group: 'odd' },
    { label: 'Number -Infinity', id: '6', group: null },
    { label: 'Number Infinity', id: '7', group: null },
  ];
  form = this.fb.group({
    selectCollection: [null, Validators.required],
    selectValues: [null, Validators.required],
    selectValues2: [null, Validators.required],
    selectValues3: [null, Validators.required],
    selectValues4: [null, Validators.required],
    selectCollectionIdMode: [null, Validators.required],
    selectValuesIdMode: [null, Validators.required],
  });

  public formInputParams = this.fb.group({
    readonly: [false],
  });

  constructor(private fb: UntypedFormBuilder) {}

  setValue() {
    this.form.setValue({
      selectCollection: <NamedEntity>{ name: 'name 1', id: '1' },
      selectValues: <NamedEntity>{ name: 'name 2', id: '2' },
      selectValues2: <NamedEntity>{ name: 'name 3', id: '3' },
      selectValues3: <Record<string, string>>{
        label: 'Number 2',
        id: '2',
        group: 'even',
      },
      selectValues4: <NamedEntity>{
        code: 'Users',
        name: 'Пользователи',
        id: '9b7f5322-33dd-4912-b2fe-b1623ae92707',
        group: null,
      },
      selectCollectionIdMode: 'bcd753cc-0b80-47ae-a471-6ff7f06010e1',
      selectValuesIdMode: '1',
    });
  }
}
