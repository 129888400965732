import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { ExpenseTypeCardComponent } from './card/expense-type-card.component';
import { ExpenseTypeCreationComponent } from './creation/expense-type-creation.component';
import { ExpenseTypeListComponent } from './list/expense-type-list.component';
import { ExpenseTypeList } from 'src/app/shared/lists/expense-type.list';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    ExpenseTypeListComponent,
    ExpenseTypeCardComponent,
    ExpenseTypeCreationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.expenseTypes',
          url: '/expense-types',
          component: ExpenseTypeListComponent,
          params: {
            navigation: 'settings.expenseTypes',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: ExpenseTypeList },
          ],
        },

        {
          name: 'settings.expenseType',
          params: {
            navigation: 'settings.expenseTypes',
          },
          url: '/expense-types/{entityId:guid}',
          component: ExpenseTypeCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class ExpenseTypesModule {}
