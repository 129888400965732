import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { AppService } from 'src/app/core/app.service';
import { LocalConfigService } from 'src/app/core/local-config.service';

import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { KpiKind } from 'src/app/shared/models/enums/kpi-kind.model';
import { TasksReportModalSettings } from './tasks-report-modal.settings';

@Component({
  selector: 'wp-tasks-report-modal',
  templateUrl: './tasks-report-modal.component.html',
})
export class TasksReportModalComponent implements OnInit {
  public form = this.fb.group({
    kpiKinds: this.fb.group({}),
    kpiTypes: this.fb.group({}),
  });
  public settings: TasksReportModalSettings;

  public get kpiKinds(): string[] {
    return Object.values(KpiKind).filter(
      (x) => x !== 'Duration' && x !== 'Billing',
    );
  }
  public get kpiTypes(): string[] {
    return Object.values(KpiType);
  }

  constructor(
    public app: AppService,
    private customFieldService: CustomFieldService,
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private localConfigService: LocalConfigService,
  ) {
    this.settings = localConfigService.getConfig(TasksReportModalSettings);
    Object.values(KpiKind).forEach((x) => {
      (this.form.controls.kpiKinds as FormGroup).addControl(
        x,
        this.fb.control(false),
      );
    });
    Object.values(KpiType).forEach((x) => {
      (this.form.controls.kpiTypes as FormGroup).addControl(
        x,
        this.fb.control(false),
      );
    });
  }

  public ok() {
    const data = {
      kpiKinds: [],
      kpiTypes: [],
    } as TasksReportModalSettings;

    Object.values(KpiType).forEach((x) => {
      if ((this.form.controls.kpiTypes as FormGroup).value[x]) {
        data.kpiTypes.push(x);
      }
    });

    Object.values(KpiKind).forEach((x) => {
      if ((this.form.controls.kpiKinds as FormGroup).value[x]) {
        data.kpiKinds.push(x);
      }
    });

    this.localConfigService.setConfig(TasksReportModalSettings, data);

    this.activeModal.close(data);
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ngOnInit() {
    this.settings.kpiTypes.forEach((x) =>
      (this.form.controls.kpiTypes as FormGroup).controls[x].setValue(true),
    );
    this.settings.kpiKinds.forEach((x) =>
      (this.form.controls.kpiKinds as FormGroup).controls[x].setValue(true),
    );
  }
}
