import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';

import { merge } from 'rxjs';

import { ChromeService } from 'src/app/core/chrome.service';
import { MenuItem, MenuService, MenuSubItem } from 'src/app/core/menu.service';

import { InfoPopupService } from '../info-popup/info-popup.service';

@Component({
  selector: 'tmt-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
  @Input() menuItems: MenuItem[];
  @Input() context: any | null = null;
  /** Shows menu items sub actions collapse. */
  public isCollapsed: Record<string, boolean> = {};
  public isPending: Record<string, boolean> = {};

  private destroyRef = inject(DestroyRef);

  constructor(
    private menuService: MenuService,
    private chrome: ChromeService,
    private popupService: InfoPopupService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.menuItems.forEach((item, index) => {
      if (
        (item.subActions && item.subActions.length) ||
        item.subActionsLazyResolver
      ) {
        this.isCollapsed[index] = true;
      }
    });

    // SetTimeout needs for align popper in small right gap
    setTimeout(() => {
      this.popupService.update();
    });

    this.initSubscribers();
  }

  /**
   * Returns label string.
   *
   * @param label label element.
   * @returns label computed to string.
   */
  public getLabelString(label: string | ((context?: any) => string)): string {
    if (!label) {
      return;
    }
    if (typeof label === 'string') {
      return label;
    }
    return label(this.context);
  }

  public canExecute(item: MenuItem): boolean {
    return item.allowedFn ? item.allowedFn(this.context) : true;
  }

  /** Check have at least one icon. */
  public checkHasIcon(): boolean {
    return !!this.menuItems.find((item) => item.iconClass);
  }

  /**
   * Toggles actions list.
   *
   * @param event click event.
   * @param index action index.
   * @param item action.
   * @param collapseRef NgbCollapse directive reference.
   * @returns
   */
  public toggleSubMenu(
    event: MouseEvent,
    index: number,
    item: MenuItem,
    collapseRef: NgbCollapse,
  ): void {
    event.stopPropagation();

    if (this.isPending[index]) {
      return;
    }

    if (
      typeof item.subActionsLazyResolver === 'function' &&
      !item.subActions?.length
    ) {
      this.isPending[index] = true;
      item
        .subActionsLazyResolver(this.context)
        .then((subActions: MenuSubItem[]) => {
          item.subActions = subActions;
          this.isPending[index] = false;
          this.cdr.detectChanges();
          collapseRef.toggle();
        });

      return;
    }

    collapseRef.toggle();
  }

  /**
   * Actions after sub menu item collapse changes.
   *
   * @param collapsed boolean shows is item collapsed.
   * @param index item's with sub actions index.
   */
  public onSubActionsCollapseChange(collapsed: boolean, index: number): void {
    for (const index of Object.keys(this.isCollapsed)) {
      this.isCollapsed[index] = true;
    }

    this.isCollapsed[index] = collapsed;
  }

  private initSubscribers(): void {
    merge(this.chrome.mainAreaSize$, this.chrome.scroll$)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.menuService.close());
  }
}
