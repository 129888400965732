import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

/** Abstract class for toolbar. */
@Directive({
  host: { name: 'toolbar', class: 'd-block' },
})
export class AbstractToolbar implements OnInit {
  protected destroyRef = inject(DestroyRef);

  constructor(
    protected gridService: GridService,
    protected cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.gridService.detectChanges$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cdr.detectChanges());
  }
}
