<form class="modal-form" novalidate [formGroup]="userForm" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.users.creation.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group" tmtIndicator>
      <label class="control-label">{{
        'settings.users.card.profile.props.name.label' | translate
      }}</label>
      <wp-text-box
        formControlName="name"
        placeholder="{{ 'settings.users.card.profile.props.name.ph' | translate }}"
      >
      </wp-text-box>
    </div>

    <div class="form-group" tmtIndicator>
      <label>{{ 'settings.users.card.profile.props.email.label' | translate }}</label>
      <wp-email-box
        formControlName="email"
        placeholder="{{ 'settings.users.card.profile.props.email.ph' | translate }}"
      ></wp-email-box>
    </div>

    <div class="form-group" tmtIndicator>
      <label class="control-label">
        {{ 'settings.users.card.profile.props.authProvider' | translate }}
      </label>
      <wp-select-box
        [allowNull]="false"
        placeholder="{{ 'settings.users.card.profile.props.authProvider' | translate }}"
        [values]="authProviders$"
        formControlName="authProvider"
      >
      </wp-select-box>
    </div>

    <div class="form-group" tmtIndicator>
      <label class="control-label">{{
        'settings.users.creation.userPermissionSet.label' | translate
      }}</label>
      <wp-select-box
        formControlName="permissionSet"
        [query]="permissionSetsQuery"
        collection="PermissionSets"
        placeholder="{{ 'settings.users.creation.userPermissionSet.ph' | translate }}"
      >
      </wp-select-box>
    </div>

    <div
      *ngIf="isInvitationInputVisible"
      class="form-check"
      title="{{ 'settings.users.creation.invitation' | translate }}"
    >
      <input
        formControlName="invitation"
        type="checkbox"
        class="form-check-input"
        id="invitation"
      />
      <label class="form-check-label" for="invitation">
        {{ 'settings.users.creation.invitation' | translate }}
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
