<div class="toolbar-container">
  <div class="btn-group" role="group">
    <button class="btn btn-default" (click)="form.disable()">Disable</button>
    <button class="btn btn-default" (click)="form.enable()">Enable</button>
  </div>

  <button class="btn btn-default" (click)="setValue()">Set value</button>
</div>

<hr />

<div [formGroup]="form" style="width: 400px">
  <h2>DatePeriodBox (Standard)</h2>
  <wp-date-period-box
    style="width: 350px; display: inline-block"
    formControlName="period"
    [placeholder]="'placeholder'"
  ></wp-date-period-box>
  <br />
  <br />
  <p>
    Form value:<br />

    <code>
      {{ form.get('period').value | json }}
    </code>
  </p>

  <p>Form control is valid: {{ form.get('period').valid }}</p>
</div>
