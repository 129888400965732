import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { fromEvent, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { Exception } from 'src/app/shared/models/exception';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

/** Service for undo/redo management. */
@Injectable({
  providedIn: 'root',
})
export class UndoRedoService implements OnDestroy {
  private undoRedoUpdateDataSubject = new Subject<any>();
  public undoRedoUpdateData$ = this.undoRedoUpdateDataSubject.asObservable();

  private undoRedoInProgress = false;

  private destroyed$ = new Subject<void>();
  private stopKeyboardSubscriptionSubject = new Subject<void>();

  private undoRedoWarnings = ['noOperationToUndoRedo'];

  private savingQueueService: SavingQueueService | null;
  private abortSessionSubscription: Subscription;
  private currentSessionId: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private data: DataService,
    private notification: NotificationService,
    private blockUI: BlockUIService,
  ) {}

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * Starts a new undo/redo session. Uses for providing undoRedoSessionId.
   *
   * @returns An Observable that emits the session key.
   */
  public startUndoRedoSession(): Observable<string> {
    this.savingQueueService = null;
    this.abortSessionSubscription?.unsubscribe();

    const request = this.currentSessionId
      ? this.data.model
          .action('AbortUndoRedo')
          .execute(undefined, undefined, {
            undoRedoSessionId: this.currentSessionId,
          })
          .pipe(
            catchError((error: Exception) => of(error)),
            switchMap(() => this.data.model.action('StartUndoRedo').execute()),
          )
      : this.data.model.action('StartUndoRedo').execute();

    this.blockUI.start();
    return request.pipe(
      catchError((error: Exception) => {
        this.notification.error(error.message);
        this.blockUI.stop();
        return of(error);
      }),
      tap((sessionId: string) => {
        this.currentSessionId = sessionId;
        this.stopKeyboardSubscriptionSubject.next();
        this.initKeyboardSubscription();
        this.blockUI.stop();
      }),
    );
  }

  /**
   * Aborts the current undo/redo session if the provided session Id matches the current session key.
   *
   * @param sessionId The session Id to compare with the current session key.
   */
  public abortUndoRedoSession(sessionId: string): void {
    if (this.currentSessionId !== sessionId) return;

    this.savingQueueService = null;
    this.abortSessionSubscription?.unsubscribe();
    this.stopKeyboardSubscriptionSubject.next();

    this.abortSessionSubscription = this.data.model
      .action('AbortUndoRedo')
      .execute(undefined, undefined, {
        undoRedoSessionId: this.currentSessionId,
      })
      .subscribe({
        next: () => {
          this.currentSessionId = null;
        },
        error: (error: Exception) => {
          this.currentSessionId = null;
          this.notification.error(error.message);
        },
      });
  }

  /** Sends undo request. */
  public undoRequest(): void {
    this.blockUI.start();
    const request = this.data.model
      .action('Undo')
      .execute(undefined, undefined, {
        undoRedoSessionId: this.currentSessionId,
      })
      .pipe(
        tap((res: any) => {
          this.blockUI.stop();
          if (res.warnings) {
            this.showWarnings(res.warnings);
          }
        }),
      );
    if (this.savingQueueService) {
      this.savingQueueService.addToQueue(Guid.generate(), request);
      return;
    }

    this.undoRedoInProgress = true;
    request.subscribe({
      next: (res: any) => {
        this.blockUI.stop();
        this.undoRedoInProgress = false;
        this.undoRedoUpdateDataSubject.next(res);
      },
      error: (error: Exception) => {
        this.blockUI.stop();
        this.undoRedoInProgress = false;
        this.notification.error(error.message);
      },
    });
  }

  /** Send redo request. */
  public redoRequest(): void {
    this.blockUI.start();
    const request = this.data.model
      .action('Redo')
      .execute(undefined, undefined, {
        undoRedoSessionId: this.currentSessionId,
      })
      .pipe(
        tap((res: any) => {
          this.blockUI.stop();
          if (res.warnings) {
            this.showWarnings(res.warnings);
          }
        }),
      );
    if (this.savingQueueService) {
      this.savingQueueService.addToQueue(Guid.generate(), request);
      return;
    }

    this.undoRedoInProgress = true;
    request.subscribe({
      next: (res: any) => {
        this.blockUI.stop();
        this.undoRedoInProgress = false;

        this.undoRedoUpdateDataSubject.next(res);
      },
      error: (error: Exception) => {
        this.blockUI.stop();
        this.undoRedoInProgress = false;
        this.notification.error(error.message);
      },
    });
  }

  /**
   * Sets saving queue service for undoRedo requests.
   *
   * @param savingQueueService Saving queue service instance.
   */
  public setSavingQueue(savingQueueService: SavingQueueService | null): void {
    this.savingQueueService = savingQueueService;
  }

  /** Begins listen of ctrl+z/ctrl+y keyboard events. */
  private initKeyboardSubscription(): void {
    fromEvent(this.document, 'keydown')
      .pipe(
        filter(() => !this.undoRedoInProgress),
        takeUntil(merge(this.destroyed$, this.stopKeyboardSubscriptionSubject)),
      )
      .subscribe((event: KeyboardEvent) => {
        switch (event.code) {
          case 'KeyZ':
            if ((event.ctrlKey || event.metaKey) && !event.repeat) {
              if (this.isPreventUndoRedo()) return;
              if (event.shiftKey) {
                this.redoRequest();
              } else {
                this.undoRequest();
              }
            }
            break;
          case 'KeyY':
            if ((event.ctrlKey || event.metaKey) && !event.repeat) {
              if (this.isPreventUndoRedo()) return;
              this.redoRequest();
            }
        }
        return;
      });
  }

  /** Shows existing undo/redo warnings.
   *
   * @param warnings object with undo/redo warning keys.
   */
  private showWarnings(warnings: { [key: string]: boolean }): void {
    this.undoRedoWarnings.forEach((warning) => {
      if (warnings[warning]) {
        this.notification.warningLocal(`shared.undoRedo.warnings.${warning}`);
      }
    });
  }

  /** Is should to prevent of undo/redo command.
   *
   * @returns is should to prevent of undo/redo command.
   */
  private isPreventUndoRedo(): boolean {
    const activeElement = this.document.activeElement as unknown;
    return (
      (activeElement instanceof HTMLInputElement &&
        activeElement.type === 'text') ||
      activeElement instanceof HTMLTextAreaElement
    );
  }
}
