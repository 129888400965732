<wp-loading-indicator [state]="cardState$ | async"></wp-loading-indicator>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" *ngIf="(service.state$ | async) === 'Ready'" class="form">
  <div class="form-group">
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box
      formControlName="name"
      placeholder="{{ 'shared.props.name' | translate }}"
    ></wp-text-box>
  </div>
  <div class="form-group">
    <label>{{ 'shared.props.code' | translate }}</label>
    <wp-text-box
      formControlName="alpha3Code"
      style="width: 150px"
      placeholder="{{ 'shared.props.code' | translate }}"
    ></wp-text-box>
    <small class="text-body-secondary"
      >{{ 'settings.currencies.card.props.code.help' | translate }}&nbsp;<a
        href="https://en.wikipedia.org/wiki/ISO_4217"
        target="_blank"
        >https://en.wikipedia.org/wiki/ISO_4217</a
      ></small
    >
  </div>
  <div class="form-check pb-3" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>

  <div class="actions" *ngIf="!service.readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="actionService.action('save').isBusy"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
