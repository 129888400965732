<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'shared.resourceGroups.addingGroupsModal.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="toolbar-container" [formGroup]="filterForm">
      <wp-text-box
        style="width: 250px"
        formControlName="name"
        placeholder="{{ 'shared.filter.defaultPlaceholder' | translate }}"
      ></wp-text-box>
    </div>

    <table class="wp-nested-table wp-nested-table-hover fix-table" [ngStyle]="leftTableStyles">
      <thead>
        <tr>
          <th style="width: 40%" title="{{ 'shared.resourceGroups.columns.group' | translate }}">
            {{ 'shared.resourceGroups.columns.group' | translate }}
          </th>
          <th
            style="width: 60%"
            title="{{ 'shared.resourceGroups.columns.description' | translate }}"
          >
            {{ 'shared.resourceGroups.columns.description' | translate }}
          </th>
        </tr>
      </thead>
    </table>

    <div class="scroll-container">
      <table
        #leftTbl
        (resized)="resizeLeftTbl()"
        class="wp-nested-table wp-nested-table-hover position-relative"
        style="width: 100%; margin: 0"
      >
        <thead>
          <tr>
            <th style="width: 40%">
              {{ 'shared.resourceGroups.columns.group' | translate }}
            </th>
            <th
              style="width: 60%"
              title="{{ 'shared.resourceGroups.columns.description' | translate }}"
            >
              {{ 'shared.resourceGroups.columns.description' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let group of availableGroups; index as index"
            (click)="selectGroup(group, index)"
          >
            <td class="trim" title="{{ group.name }}">
              {{ group.name }}
            </td>
            <td class="text-cell trim" title="{{ group.description }}">
              {{ group.description }}
            </td>
          </tr>
          <tr *ngIf="availableGroups.length == 0 && !isLoading">
            <td colspan="3" class="text-center text-body-secondary">
              {{ 'shared.noDisplayData' | translate }}
            </td>
          </tr>
          <tr *ngIf="loadedPartly">
            <td colspan="3" class="text-body-secondary text-center text-uppercase">
              {{ 'shared.showFirstNRecordsMessage' | translate: { limit: loadLimit } }}<br />
              {{ 'shared.refineQueryMessage' | translate }}
            </td>
          </tr>
        </tbody>
      </table>
      <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
    </div>

    <div class="selected-roles-scroll-container">
      <div
        class="selected-resource-block trim"
        *ngFor="let group of selectedGroups; index as index"
        (click)="removeGroup(group, index)"
      >
        <div class="trim" title="{{ group.name }}">
          {{ group.name }}
        </div>

        <button type="button" class="close" (click)="removeGroup(group, index)">&times;</button>
      </div>
      <div *ngIf="selectedGroups.length == 0" class="text-uppercase text-body-secondary no-data">
        {{ 'shared.resourceGroups.addingGroupsModal.noDisplayData' | translate }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="selectedGroups.length > 0"
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.add' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
