import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  inject,
} from '@angular/core';
import { Currency } from 'src/app/shared/models/entities/settings/currency.model';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { CurrencyService } from '../currency.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';

@Component({
  selector: 'tmt-currency-main',
  templateUrl: './currency-main.component.html',
  styleUrls: ['./currency-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyMainComponent implements OnInit {
  public cardState$ = new BehaviorSubject<CardState>(CardState.Loading);

  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    alpha3Code: ['', [Validators.required, Validators.maxLength(3)]],
    isActive: new UntypedFormControl(false),
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public entityId,
    private fb: UntypedFormBuilder,
    public actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    public service: CurrencyService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
    ]);

    this.load();

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  /** Saves Data. */
  public save(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.actionService.action('save').start();

    const currency = <Currency>this.form.value;

    const data: any = {
      name: currency.name,
      isActive: currency.isActive,
      alpha3Code: currency.alpha3Code,
    };

    this.service.entity
      .patch(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.currencies.card.messages.saved',
          );
          this.service.updateName(this.form.value.name);
          this.cdr.detectChanges();
        },
        error: (error: Exception) => {
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  }

  private load(): void {
    this.cardState$.next(CardState.Loading);

    this.form.markAsPristine();
    this.form.markAsUntouched();

    const query = {
      select: ['id', 'name', 'isActive', 'alpha3Code'],
    };

    this.service.entity.get(query).subscribe({
      next: (currency) => {
        this.form.patchValue(currency);

        if (this.service.readonly) {
          this.form.disable();
        } else {
          this.form.controls.alpha3Code.disable();
        }

        this.actionService.action('save').isShown = !this.service.readonly;
        this.cardState$.next(CardState.Ready);
      },
      error: (error: Exception) => {
        this.cardState$.next(CardState.Error);
        if (error.code !== Exception.BtEntityNotFoundException.code) {
          this.notification.error(error.message);
        }
      },
    });
  }

  private reload(): void {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }
}
