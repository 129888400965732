import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MessageComponent } from '../shared/components/chrome/message/message.component';
import { Exception } from '../shared/models/exception';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  options: NgbModalOptions = {
    backdrop: false,
  };

  constructor(
    private translate: TranslateService,
    private modalService: NgbModal,
  ) {}

  /**
   * Показывает сообщение.
   *
   * @param message - текст сообщения.
   * @param header - заголовок окна.
   * @param items - дополнительные строки (вывод списком в окне сообщения).
   * @param buttonOkText - текст кнопки.
   * @param buttonCancelText - текст кнопки.
   */
  public message(
    message: string,
    header?: string,
    items?: string[],
    buttonOkText?: string,
    buttonCancelText?: string,
  ): Promise<any> {
    const refModal = this.modalService.open(MessageComponent, this.options);
    refModal.componentInstance.message = message;
    refModal.componentInstance.header = header
      ? header
      : this.translate.instant('shared.defaultMessageHeader');
    refModal.componentInstance.items = items;
    refModal.componentInstance.buttonOk = buttonOkText
      ? buttonOkText
      : this.translate.instant('shared.actions.ok');

    refModal.componentInstance.buttonCancel = buttonCancelText;
    return refModal.result;
  }

  public error(message: string): Promise<any> {
    const refModal = this.modalService.open(MessageComponent, this.options);
    refModal.componentInstance.message = message;
    refModal.componentInstance.header = this.translate.instant(
      'shared.errorMessageModalHeader',
    );
    refModal.componentInstance.buttonOk =
      this.translate.instant('shared.actions.ok');
    return refModal.result;
  }

  public errorDetailed(error: Exception, header: string = null): Promise<any> {
    const items: string[] = [];

    if (error.details && Array.isArray(error.details)) {
      error.details.forEach((errorDetail: Exception) => {
        items.push(errorDetail.message);
      });
    }

    const refModal = this.modalService.open(MessageComponent, this.options);
    refModal.componentInstance.message = error.message;
    refModal.componentInstance.header =
      header ?? this.translate.instant('shared.errorMessageModalHeader');
    refModal.componentInstance.buttonOk =
      this.translate.instant('shared.actions.ok');
    refModal.componentInstance.items = items;
    return refModal.result;
  }

  public errorLocal = function (message: string): Promise<any> {
    return this.error(this.translate.instant(message));
  };

  public confirm(
    message: string,
    header?: string,
    confirmText?: string,
    cancelText?: string,
  ): Promise<any> {
    const refModal = this.modalService.open(MessageComponent, this.options);
    refModal.componentInstance.message = message;
    refModal.componentInstance.header = header
      ? header
      : this.translate.instant('shared.confirmation');
    refModal.componentInstance.buttonOk = confirmText
      ? confirmText
      : this.translate.instant('shared.actions.confirm');
    refModal.componentInstance.buttonCancel = cancelText
      ? cancelText
      : this.translate.instant('shared.actions.cancel');

    return refModal.result;
  }

  public confirmLocal(
    message: string,
    header?: string,
    confirmText?: string,
    cancelText?: string,
  ): Promise<any> {
    return this.confirm(
      this.translate.instant(message),
      header ? this.translate.instant(header) : null,
      confirmText ? this.translate.instant(confirmText) : null,
      cancelText ? this.translate.instant(cancelText) : null,
    );
  }
}
