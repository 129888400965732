import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { StateService } from '@uirouter/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';

import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

@Component({
  selector: 'tmt-legal-entity-creation',
  templateUrl: './legal-entity-creation.component.html',
})
export class LegalEntityCreationComponent implements OnInit {
  public isSaving = false;
  public creationForm: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    manager: [null, Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private dataService: DataService,
    private stateService: StateService,
    private activeModal: NgbActiveModal,
    private appService: AppService,
    private customFieldService: CustomFieldService,
  ) {}

  public ngOnInit(): void {
    this.customFieldService.enrichFormGroup(this.creationForm, 'LegalEntity');
    this.customFieldService.enrichFormGroupWithDefaultValues(
      this.creationForm,
      'LegalEntity',
    );
    this.creationForm.patchValue({
      manager: this.appService.session.user,
    });
  }

  /** Modal submit handler. */
  public ok(): void {
    this.creationForm.markAllAsTouched();

    if (this.creationForm.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving = true;

    const data = {
      name: this.creationForm.value.name,
      managerId: this.creationForm.value.manager.id,
    };

    this.customFieldService.assignValues(
      data,
      this.creationForm.value,
      'LegalEntity',
    );

    this.dataService
      .collection('LegalEntities')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notificationService.successLocal(
            'settings.legalEntity.messages.created',
          );
          this.stateService.go('settings.legalEntity', {
            entityId: response.id,
          });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isSaving = false;
        },
      });
  }

  /** Modal cancel handler. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
