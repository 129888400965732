import { ElementRef } from '@angular/core';

export interface TimelineCellEntry {
  id: number;
  date: string;
  nonWorking: boolean;
  fteHours: number;
  hint?: string | null;
  active?: boolean;
}

export interface ProjectTaskStrip {
  id: string;
  element: ElementRef;
}
export interface ProjectTaskViewParams {
  /** Task index in the viewed task Array */
  taskIndex: number | null;
  /** Task strip width. */
  taskWidth: number;
  /** Task strip left offset. */
  taskLeftOffset: number;
  /** Is task lead(summary). */
  isLeadTask: boolean;
}

export enum TaskElementType {
  leftMarker = 'leftMarker',
  rightMarker = 'rightMarker',
}
