import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { SETTINGS_STATES } from './settings-app.states';
import { SettingsAppComponent } from './settings-app.component';
import { DefaultFilterDetailsComponent } from './shared/default-filter/default-filter-details/default-filter-details.component';
import { AccountModule } from './account/account.module';
import { SettingsModule } from './settings/settings.module';
import { UsersModule } from './users/users.module';
import { RolesModule } from './roles/roles.module';
import { TimeOffTypesModule } from './time-off-types/time-off-types.module';
import { ActivitiesModule } from './activities/activities.module';
import { DepartmentsModule } from './departments/departments.module';
import { SkillsModule } from './skills/skills.module';
import { ExpenseTypesModule } from './expense-types/expense-types.module';
import { PermissionSetsModule } from './permission-sets/permission-sets.module';
import { ResourcePoolsModule } from './resource-pools/resource-pools.module';
import { TimesheetPeriodsModule } from './timesheet-periods/timesheet-periods.module';
import { SchedulesModule } from './schedules/schedules.module';
import { ValidationRulesModule } from './validation-rules/validation-rules.module';
import { TimesheetTemplatesModule } from './timesheet-templates/timesheet-templates.module';
import { CustomFieldsModule } from './custom-fields/custom-fields.module';
import { CurrenciesModule } from './currencies/currencies.module';
import { InvoiceTemplatesModule } from './invoice-templates/invoice-templates.module';
import { CostNormalizationRuleModule } from './cost-normalization-rules/cost-normalization-rules.module';
import { ImportsModule } from './import/import.module';
import { ScheduleExceptionsModule } from './schedule-exceptions/schedule-exceptions.module';
import { LocationsModule } from 'src/app/settings-app/locations/locations.module';
import { LifecyclesModule } from './lifecycle/lifecycles.module';
import { LevelsModule } from './levels/levels.module';
import { GradesModule } from './grades/grades.module';
import { WorkflowsModule } from 'src/app/settings-app/workflows/workflows.module';
import { GroupsModule } from './groups/groups.module';
import { LoginLogModule } from 'src/app/settings-app/login-log/login-log.module';
import { HistoryLogModule } from 'src/app/settings-app/history-log/history-log.module';
import { LegalEntitiesModule } from 'src/app/settings-app/legal-entity/legal-entity.module';
import { CompetencesModule } from './competences/competences.module';
import { RateMatrixModule } from 'src/app/settings-app/rate-matrix/rate-matrix.module';
import { BoardsModule } from 'src/app/settings-app/boards/boards.module';
import { MetamodelModule } from 'src/app/settings-app/metamodel/metamodel.module';
import { IssueTypesModule } from 'src/app/settings-app/issue-types/issue-types.module';
import { IssueResolutionsModule } from 'src/app/settings-app/issue-resolutions/issue-resolutions.module';
import { DirectoriesModule } from 'src/app/settings-app/directories/directories.module';

@NgModule({
  declarations: [SettingsAppComponent, DefaultFilterDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    AccountModule,
    SettingsModule,
    UsersModule,
    SkillsModule,
    DepartmentsModule,
    ActivitiesModule,
    TimeOffTypesModule,
    ExpenseTypesModule,
    RolesModule,
    ResourcePoolsModule,
    TimesheetPeriodsModule,
    PermissionSetsModule,
    SchedulesModule,
    ScheduleExceptionsModule,
    ValidationRulesModule,
    TimesheetTemplatesModule,
    CustomFieldsModule,
    CurrenciesModule,
    InvoiceTemplatesModule,
    CostNormalizationRuleModule,
    ImportsModule,
    LevelsModule,
    LocationsModule,
    GroupsModule,
    GradesModule,
    LifecyclesModule,
    HistoryLogModule,
    WorkflowsModule,
    LoginLogModule,
    LegalEntitiesModule,
    CompetencesModule,
    BoardsModule,
    RateMatrixModule,
    MetamodelModule,
    IssueTypesModule,
    IssueResolutionsModule,
    DirectoriesModule,
    UIRouterModule.forChild({
      states: SETTINGS_STATES,
    }),
  ],
})
export class SettingsAppModule {}
