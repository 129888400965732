import { Inject, Injectable, Optional } from '@angular/core';
import { Action, DataService } from 'src/app/core/data.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { forkJoin, Observable, of, map } from 'rxjs';
import _ from 'lodash';
import { FteScheduleService } from 'src/app/core/fte-schedule.service';
import { BookingResourcesFilter } from '../models/booking-resources-filter.model';
import { DateTime, Interval } from 'luxon';
import { DateHours } from 'src/app/shared/models/entities/date-hours.model';
import {
  BookingEntry,
  BookingEntryReqBody,
  BookingEntryType,
  BookingResource,
} from 'src/app/shared/models/entities/resources/booking-entry.model';
import { ResourceInfo } from 'src/app/shared-features/planner/models/resource-info';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { ProjectItem } from 'src/app/booking/booking/shared/booking-project-add/booking-project-add.interface';
import { BookingMode } from 'src/app/shared/models/enums/booking-mode.enum';
import { AppService } from 'src/app/core/app.service';
import { ResourceRequest } from 'src/app/shared/models/entities/resources/resource-request.model';
import { ResourceRequestMode } from 'src/app/resource-requests/shared/resource-request/resource-request.interface';
import { PlanningMethod } from 'src/app/shared/models/enums/planning-method.enum';
import { BookingType } from 'src/app/shared/models/enums/booking-type.enum';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';

@Injectable()
export class BookingDataService {
  public valueMode: ValueMode;
  public bookingMode: BookingMode;
  public resourceRequestMode: ResourceRequestMode | null = null;
  public resourceRequest: ResourceRequest | null = null;

  public resources: BookingResource[] = [];
  public bookings: BookingEntry[] = [];
  public bookingsChangeEntries: BookingEntry[] = [];
  public bookingsResourceRequest: BookingEntry[] = [];
  /** Resource request booking entries as result */
  public extraBookings: BookingEntry[] = null;
  /** IDs of resource request resources */
  public extraResourceIds: Set<string>;
  public schedules: Dictionary<DateHours[]> = {};
  public fte: Dictionary<number> = {};

  public bookingQuery = {
    expand: {
      detailEntries: { select: ['date', 'hours'] },
      project: { select: ['id', 'name'] },
      resourceRequest: { select: ['id', 'name'] },
      resource: { select: ['id', 'name'] },
    },
    select: [
      'id',
      'rowVersion',
      'requiredHours',
      'requiredSchedulePercent',
      'bookedHours',
      'resourceId',
      'type',
      'from',
      'to',
      'planningMethod',
      'editAllowed',
      'isTimeOff',
      'isOther',
      'entryType',
    ],
  };

  private get bookingEntriesEndpoint(): Action {
    return this.data.collection('BookingEntries').action('GetBookings');
  }

  constructor(
    @Optional() @Inject('assistantRequestId') public resourceRequestId,
    private data: DataService,
    private fteScheduleService: FteScheduleService,
    appService: AppService,
  ) {
    this.bookingMode = appService.session.configuration.bookingMode;
  }

  private getBookingToSave(
    bookingEntry: BookingEntry,
    planningScale: PlanningScale,
  ): any {
    return {
      bookingEntry: {
        id: bookingEntry.id,
        rowVersion: bookingEntry.rowVersion,
        from: bookingEntry.fromLx.toISODate(),
        to: bookingEntry.toLx.toISODate(),
        projectId: bookingEntry.project.id,
        resourceId: bookingEntry.resourceId,
        type: bookingEntry.type,
        requiredHours: bookingEntry.requiredHours,
        requiredSchedulePercent: bookingEntry.requiredSchedulePercent,
        planningMethod: bookingEntry.planningMethod,
      },
      planningScale,
    };
  }

  /**
   * Get a list of bookings by resource.
   * If service working in assistant mode, then request `bookingEntry` adds to default booking entries.
   *
   * @param resourceId Resource ID.
   *
   * @return booking entries.
   * */
  public getResourceBookings(resourceId: string): BookingEntry[] {
    if (!this.extraBookings?.length) {
      return this.bookings.filter((b) => b.resourceId === resourceId);
    }

    if (
      this.resourceRequest.teamMember?.resource.resourceType ===
      ResourceType.user
    ) {
      return this.bookings
        .filter(
          (b) =>
            b.resourceId === resourceId &&
            b.project?.id !== this.extraBookings[0].project.id,
        )
        .concat(this.extraBookings);
    }

    if (this.bookingMode === BookingMode.Basic) {
      return this.bookings
        .filter((b) => b.resourceId === resourceId)
        .concat(this.extraBookings.filter((b) => b.resourceId === resourceId));
    }

    const result = _.cloneDeep(
      this.bookings.filter((b) => b.resourceId === resourceId),
    );

    this.extraBookings
      .filter((el) => el.resource.id === resourceId)
      .forEach((bookingEntryTemp) => {
        const bookingEntry = result.find(
          (el) => el.project?.id === bookingEntryTemp.project.id,
        );

        if (bookingEntry) {
          bookingEntryTemp.detailEntries.forEach((entryTemp) => {
            const resultEntry = bookingEntry.detailEntries.find(
              (resultEntryDetail) => entryTemp.date === resultEntryDetail.date,
            );

            if (resultEntry) {
              resultEntry.hours += entryTemp.hours;
            } else {
              bookingEntry.detailEntries.push(entryTemp);
            }
          });
        } else {
          result.push(bookingEntryTemp);
        }
      });

    return result;
  }

  /** Creates booking entry.
   *
   * @param resourceId Resource ID.
   * @param projectId Project ID.
   * @param planningScale Planning scale.
   *
   * @return Booking entry.
   * */
  public createBookingEntry(
    resourceId: string,
    projectId: string,
    planningScale: PlanningScale,
  ): Observable<BookingEntry> {
    const bookingEntry = {
      resourceId,
      projectId,
      from: null,
      to: null,
      requiredHours: 0,
      bookedHours: 0,
      planningMethod: PlanningMethod.Manual,
      type: BookingType.Hard,
      detailEntries: [],
      resourceRequestId: this.resourceRequestId ?? null,
    };

    return this.data.collection('BookingEntries').action('Create').execute(
      {
        bookingEntry,
        planningScale,
      },
      this.bookingQuery,
    );
  }

  /** Returns the sum of the `totalHours` in bookings entries.
   *
   * @param resourceId Resource ID.
   *
   * */
  public getTotalsByResource(resourceId: string): number {
    return this.getResourceBookings(resourceId).reduce(
      (total, value) => total + value.bookedHours,
      0,
    );
  }

  /** Обновить бронирование на сервере. */
  public update(booking: BookingEntry, planningScale: PlanningScale) {
    const collection = this.data.collection('BookingEntries');
    return collection
      .entity(booking.id)
      .action('Update')
      .execute(
        this.getBookingToSave(booking, planningScale),
        this.bookingQuery,
      );
  }

  /**
   * UpdateDetailsWithScaling Request.
   *
   * @param entriesToSave Prepared data with dates.
   * @param planningScale Planning scale for request.
   *
   * @return Updates's result with dates.
   * */
  public updateDetails(
    entriesToSave: BookingEntryReqBody[],
    planningScale: PlanningScale,
  ): Observable<BookingEntryReqBody[]> {
    const detailEntries = _.clone(entriesToSave);
    entriesToSave.length = 0;

    return this.data
      .collection('BookingEntries')
      .action('UpdateDetailsWithScaling')
      .execute({
        scale: planningScale,
        detailEntries,
      });
  }

  /**
   * GetAvailableProjects Request.
   *
   * @param resourceId Resource Id.
   *
   * @return Project's collection.
   * */
  public getAvailableProjects(resourceId: string): Observable<ProjectItem[]> {
    return this.data
      .collection('BookingEntries')
      .function('GetAvailableProjects')
      .query({ resourceId });
  }

  /** Загрузить страницу ресурсов. */
  public loadResourcesPage(
    page: number,
    pageSize: number,
    filter: Record<string, any>,
    interval: Interval,
    scale: PlanningScale,
  ): Observable<ResourceInfo[]> {
    const params: Dictionary<any> = {
      pageNumber: page,
      pageSize,
      from: interval.start.toISODate(),
      to: interval.end.toISODate(),
      scale: `WP.PlanningScale'${scale}'`,
      filter: '@filter',
    };

    const filterObject: BookingResourcesFilter = {
      roleId: filter.role?.id ?? null,
      competenceId: filter.competence?.id ?? null,
      legalEntityId: filter.legalEntity?.id ?? null,
      supervisorId: filter.supervisor?.id ?? null,
      resourcePoolId: filter.resourcePool?.id ?? null,
      departmentId: filter.department?.value?.id ?? null,
      includeSubdepartments: filter.department?.includeSubordinates ?? false,
      projectId: filter.project?.id ?? null,
      includeInactiveResources: filter.view?.code === 'all',
      requestPreferredResources: filter.requestPreferredResources ?? false,
      requestId: this.resourceRequestId ?? null,
      levelId: filter.level?.id ?? null,
      gradeId: filter.grade?.id ?? null,
      locationId: filter.location?.id ?? null,
      requestLinked: false,
      term: filter.text ?? null,
    };

    if (filter.resourceIds?.length) {
      filterObject.resourceIds = filter.resourceIds;
    }

    if (filter.skillIds?.length) {
      filterObject.skillIds = filter.skillIds.map((skill) => skill.id);
    }

    if (this.resourceRequestMode) {
      filterObject.requestLinked = true;
      filterObject.includeInactiveResources = true;
    }

    const urlParams: Dictionary<any> = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '@filter': JSON.stringify(filterObject),
    };

    const subs: Observable<any>[] = [
      this.data
        .collection('BookingEntries')
        .function('GetResourcesPage')
        .query<ResourceInfo[]>(params, null, urlParams),
    ];

    if (this.valueMode === ValueMode.FTE) {
      subs.push(
        this.fteScheduleService.getFteSchedule(
          scale,
          interval.start.toISODate(),
          interval.end.toISODate(),
        ),
      );
    }

    return forkJoin(subs).pipe(
      map((data: any[]) => {
        const resources = data[0] as ResourceInfo[];
        this.resources = this.resources.concat(resources);

        resources.forEach((ri) => {
          this.schedules[ri.id] = ri.schedule;
        });

        if (this.valueMode === ValueMode.FTE) {
          const fte: DateHours[] = data[1] as any[];
          fte.forEach((entry) => {
            this.fte[entry.date] = entry.hours;
          });
        }

        return resources;
      }),
    );
  }

  /** Загрузить фрагмент "доски". */
  public loadFrame(
    resourceIds: string[],
    interval: Interval,
    scale: PlanningScale,
  ): Observable<any> {
    return forkJoin({
      bookings: this.loadBookings(resourceIds, interval, scale),
      schedule: this.loadSchedules(resourceIds, interval, scale),
    });
  }

  /** Загрузить расписания. */
  public loadSchedules(
    resourceIds: string[],
    interval: Interval,
    scale: PlanningScale,
  ): Observable<void> {
    const filterObject = <any>{
      from: interval.start.toISODate(),
      to: interval.end.toISODate(),
      scale,
      resourceIds,
    };

    const subs: Observable<any>[] = [
      this.data
        .collection('BookingEntries')
        .action('GetSchedules')
        .execute(filterObject),
    ];

    if (this.valueMode === ValueMode.FTE) {
      subs.push(
        this.fteScheduleService.getFteSchedule(
          scale,
          interval.start.toISODate(),
          interval.end.toISODate(),
        ),
      );
    }

    return forkJoin(subs).pipe(
      map((data: any[]) => {
        const schedules: any[] = data[0] as any;

        schedules.forEach((entry) => {
          const existsEntry = this.schedules[entry.userId]?.find(
            (s) => s.date === entry.date,
          );
          if (!existsEntry) {
            this.schedules[entry.userId].push({
              date: entry.date,
              hours: entry.duration,
            });
          }
        });

        if (this.valueMode === ValueMode.FTE) {
          const fte: DateHours[] = data[1] as any;
          fte.forEach((entry) => {
            this.fte[entry.date] = entry.hours;
          });
        }
      }),
    );
  }

  /**
   * Loads booking entries.
   *
   * Works differently depending on the environment.
   * 1. Booking page: only `BookingEntryType.booking` is loaded;
   * 2. ResourceRequest Card: `BookingEntryType.booking` and `BookingEntryType.resourceRequest` are loaded at the same time;
   * 3. Assistant: only `BookingEntryType.resourceRequest` is loaded.
   *
   * @returns `Observable<BookingEntry[]>` with `BookingEntryType.booking`
   * (if not loaded then with `BookingEntryType.resourceRequest`).
   */
  public loadBookings(
    resourceIds: string[],
    interval: Interval,
    scale: PlanningScale,
    reload = false,
  ): Observable<BookingEntry[]> {
    const filterObject = <any>{
      from: interval.start.toISODate(),
      to: interval.end.toISODate(),
      scale,
      resourceIds,
      entryType: BookingEntryType.booking,
    };

    const bookingQuery = {
      ...this.bookingQuery,
    };

    return forkJoin({
      bookings:
        this.resourceRequestMode === 'assistant'
          ? of(null)
          : this.bookingEntriesEndpoint.execute<BookingEntry[]>(
              filterObject,
              bookingQuery,
            ),
      bookingsRequest: this.resourceRequestMode
        ? this.bookingEntriesEndpoint.execute<BookingEntry[]>(
            {
              ...filterObject,
              entryType: BookingEntryType.resourceRequest,
            },
            {
              ...bookingQuery,
              filter: {
                resourceRequestId: {
                  type: 'guid',
                  value: this.resourceRequestId,
                },
              },
            },
          )
        : of(null),
    }).pipe(
      map((data) => {
        if (reload) {
          this.bookings.length = 0;
          this.bookingsResourceRequest.length = 0;
        }

        if (data.bookings) {
          this.updateBookingCollection(data.bookings, this.bookings);
        }

        if (data.bookingsRequest) {
          this.updateBookingCollection(
            data.bookingsRequest,
            this.bookingsResourceRequest,
          );
        }

        if (!data.bookings && data.bookingsRequest) {
          this.bookings = this.bookingsResourceRequest;
        }

        return data.bookings ?? data.bookingsRequest;
      }),
    );
  }

  /**
   * Loads bookings of resource request with `changeEntries`.
   *
   * @param resourceIds Resource id collection.
   * @param interval interval.
   * @param scale Planning Scale.
   * @param reload Indicate whether to clean current `bookingsChangeEntries`.
   *
   * @returns Booking entries.
   */
  public loadChangeEntries(
    resourceIds: string[],
    interval: Interval,
    scale: PlanningScale,
    reload = false,
  ): Observable<BookingEntry[]> {
    if (this.bookingMode === BookingMode.Basic) {
      return of([]);
    }

    const filterObject = <any>{
      from: interval.start.toISODate(),
      to: interval.end.toISODate(),
      scale,
      resourceIds,
      entryType: BookingEntryType.resourceRequest,
    };

    const bookingQuery = {
      select: [
        'id',
        'rowVersion',
        'bookedHours',
        'projectId',
        'resourceId',
        'type',
        'from',
        'to',
        'planningMethod',
      ],
      expand: {
        changeEntries: { select: ['date', 'hours'] },
        detailEntries: { select: ['date', 'hours'] },
        resourceRequest: {
          select: [
            'id',
            'name',
            'bookedHours',
            'created',
            'name',
            'requestedHours',
            'stateId',
          ],
          expand: {
            state: {
              select: ['id', 'code', 'name', 'style'],
            },
            teamMember: {
              select: ['id', 'resourceId'],
              expand: {
                resource: {
                  select: ['id', 'resourceType'],
                },
              },
            },
          },
        },
      },
      filter: {
        resourceRequest: {
          and: ResourceRequest.notSavedChangesStateIds.map((id) => ({
            stateId: {
              ne: {
                type: 'guid',
                value: id,
              },
            },
          })),
        },
      },
    };

    return this.bookingEntriesEndpoint.execute(filterObject, bookingQuery).pipe(
      map((bookings: BookingEntry[]) => {
        if (reload) {
          this.bookingsChangeEntries.length = 0;
        }

        bookings.forEach((booking) => {
          const existedBooking = this.bookingsChangeEntries.find(
            (b) => b.id === booking.id,
          );

          if (existedBooking) {
            booking.changeEntries.forEach((entry) => {
              if (
                !existedBooking.changeEntries.find((v) => v.date === entry.date)
              ) {
                existedBooking.changeEntries.push(entry);
              }
            });
          } else {
            this.bookingsChangeEntries.push(booking);
          }
        });

        this.bookingsChangeEntries = _.orderBy(
          this.bookingsChangeEntries,
          [
            (b) =>
              ResourceRequest.systemStateIds.includes(
                b.resourceRequest.stateId,
              ),
            'created',
          ],
          ['asc', 'desc'],
        );

        return bookings;
      }),
    );
  }

  /** Удалить бронирование. */
  public delete(bookingId: string): Observable<void> {
    return this.data.collection('BookingEntries').entity(bookingId).delete();
  }

  /**
   * BookingEntries Clear's action Request.
   *
   * @param bookingId BookingEntryId.
   *
   * @return Just `Observable` without data.
   * */
  public clear(bookingId: string): Observable<void> {
    return this.data
      .collection('BookingEntries')
      .entity(bookingId)
      .action('Clear')
      .execute();
  }

  /** Returns true, when BookingComponent should work only for viewing data */
  public isReadonlyMode(): boolean {
    return (
      (this.resourceRequestMode &&
        (this.resourceRequestMode !== 'assistant' ||
          !this.resourceRequest?.bookingEntryEditAllowed)) ||
      (!this.resourceRequestMode && this.resourceRequestId)
    );
  }

  private updateBookingCollection(
    newBookings: BookingEntry[],
    currentBookings: BookingEntry[],
  ): void {
    newBookings.forEach((booking) => {
      const existedBooking = currentBookings.find((b) => b.id === booking.id);

      if (existedBooking) {
        booking.detailEntries.forEach((de) => {
          if (!existedBooking.detailEntries.find((x) => x.date === de.date)) {
            existedBooking.detailEntries.push(de);
          }
        });
      } else {
        booking.fromLx = DateTime.fromISO(booking.from);
        booking.toLx = DateTime.fromISO(booking.to);
        booking.resource = this.resources.find(
          (el) => el.id === booking.resourceId,
        );

        currentBookings.push(booking);
      }
    });
  }
}
