import { Component, OnDestroy, OnInit } from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { Subject } from 'rxjs';
import { StateService } from '@uirouter/core';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { ProjectFilterService } from './project-filter/project-filter.service';
import { ProjectSettingsComponent } from '../settings/project-settings.component';
import { CopyingProjectModalComponent } from '../copying-project-modal/copying-project-modal.component';
import { LifecycleListService } from 'src/app/core/lifecycle-list.service';
import { takeUntil } from 'rxjs/operators';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'wp-project-list',
  template: '<wp-entity-list></wp-entity-list> ',
  providers: [
    { provide: FilterService, useClass: ProjectFilterService },
    EntityListService,
    ListService,
    GridService,
    LifecycleListService,
  ],
})
export class ProjectListComponent implements OnInit, OnDestroy {
  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private service: EntityListService,
    private gridService: GridService,
    private state: StateService,
    private app: AppService,
    private actions: ActionPanelService,
    private modal: NgbModal,
    private lifecycleListService: LifecycleListService,
  ) {}

  ngOnInit() {
    this.actions.set([
      this.actions.getDefaultAction('create', {
        title: 'projects.projects.list.actions.create',
        isVisible: this.app.checkEntityPermission(
          'Project',
          PermissionType.Modify,
        ),
        handler: this.create,
      }),
      {
        name: 'copy',
        title: 'projects.projects.list.actions.copyProject.title',
        hint: 'projects.projects.list.actions.copyProject.hint',
        isBusy: false,
        isVisible: false,
        handler: this.copy,
      },
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go('project', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actions.getDefaultAction('delete', {
        handler: () => {
          this.service.delete();
        },
      }),
    ]);

    this.gridService.selectedGroups$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((rows: any) => {
        this.actions.action('card').isShown = rows?.length === 1;
        this.actions.action('copy').isShown = rows?.length === 1;
      });
  }

  private create = () => {
    this.modal.open(ProjectSettingsComponent, { size: 'lg' });
  };

  private copy = () => {
    const ref = this.modal.open(CopyingProjectModalComponent);
    (ref.componentInstance as CopyingProjectModalComponent).project =
      this.gridService.selectedGroupValue;
  };

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
