export const DateTimeLabelFormats: any = {
  millisecond: [
    '%A, %b %e, %H:%M:%S.%L',
    '%A, %b %e, %H:%M:%S.%L',
    '-%H:%M:%S.%L',
  ],
  second: ['%A, %b %e, %H:%M:%S', '%A, %b %e, %H:%M:%S', '-%H:%M:%S'],
  minute: ['%A, %b %e, %H:%M', '%A, %b %e, %H:%M', '-%H:%M'],
  hour: ['%A, %b %e, %H:%M', '%A, %b %e, %H:%M', '-%H:%M'],
  day: ['%A, %b %e, %Y', '%A, %b %e', '-%A, %b %e, %Y'],
  week: ['%A, %b %e, %Y', '%A, %b %e', '-%A, %b %e, %Y'],
  month: ['%B %Y', '%B', '-%B %Y'],
  year: ['%Y', '%Y', '-%Y'],
};
