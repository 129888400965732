import { KpiKind } from 'src/app/shared/models/enums/kpi-kind.model';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class TrendsKpiSettings implements BaseSettings {
  public readonly name = 'TrendsKpiSettings';
  version: number;

  scale: PlanningScale;
  kind: KpiKind;

  getDefault(): TrendsKpiSettings {
    return {
      version: 1,
      scale: PlanningScale.Day,
      kind: KpiKind.Hours,
    } as TrendsKpiSettings;
  }
}
