import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { StateService } from '@uirouter/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';

import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'tmt-rate-matrix-creation-modal',
  templateUrl: './rate-matrix-creation-modal.component.html',
})
export class RateMatrixCreationModalComponent {
  public isSaving = false;
  public creationForm: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    effectiveDate: null,
    type: [null, Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private dataService: DataService,
    private stateService: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  /** Modal submit handler. */
  public ok(): void {
    this.creationForm.markAllAsTouched();

    if (this.creationForm.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving = true;

    const data = {
      name: this.creationForm.value.name,
      effectiveDate: this.creationForm.value.effectiveDate,
      typeId: this.creationForm.value.type.id,
    };

    this.dataService
      .collection('RateMatrices')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notificationService.successLocal(
            'settings.rateMatrices.creation.messages.created',
          );
          this.stateService.go('settings.rateMatrix', {
            entityId: response.id,
          });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isSaving = false;
        },
      });
  }

  /** Modal cancel handler. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
