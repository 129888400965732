import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import {
  GridNavigationColumn,
  GridColumnType,
  GridComponentColumn,
} from '../models/inner/grid-column.interface';
import { ImportStateCellComponent } from 'src/app/settings-app/import/list/import-state-cell/import-state-cell.component';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ImportList: List = {
  version: 1,
  name: 'imports',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'createdBy',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: { column: 'created', reverse: true },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      state: 'settings.import',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      availableTotals: [TotalType.Count],
    },
    <GridComponentColumn>{
      name: 'state',
      header: 'shared.columns.state',
      hint: 'shared.columns.state',
      component: ImportStateCellComponent,
      type: GridColumnType.Component,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'createdBy',
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
      type: GridColumnType.String,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'createdBy',
      field: 'createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'state',
      field: ['state.name', 'state.code'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],

  dataCollection: 'Imports',
};
