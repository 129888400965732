@if (!(gridService.readonly$ | async)) {
  <div class="toolbar-container">
    @if (lifecycleCardService.isShowKind$ | async) {
      <div class="group">
        <div class="kind" [formGroup]="lifecycleCardService.kindForm">
          <wp-select-box
            formControlName="kind"
            [collection]="lifecycleCardService.entityTypeToCollection"
            placeholder="{{ 'components.kindToolbarComponent.props.kind' | translate }}"
            [allowInactive]="true"
            [allowNull]="false"
          />
        </div>
      </div>
    }
    <div class="group d-flex">
      <div class="states" ngbDropdown>
        <button type="button" class="btn btn-default" ngbDropdownToggle>
          <span class="bi bi-plus-lg bi-15"></span>
          &nbsp;{{ 'components.kindToolbarComponent.actions.addState' | translate }}
        </button>
        <div ngbDropdownMenu class="dropdown-scroll">
          @for (state of lifecycleCardService.availableStatesOptions; track state.id) {
            <button ngbDropdownItem (click)="selectState(state)">
              {{ state.name }}
            </button>
          } @empty {
            <div class="text-center text-body-secondary">
              {{ 'shared.noOptions' | translate }}
            </div>
          }
        </div>
      </div>

      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.edit' | translate }}"
        [disabled]="!gridService.canBeExecuted('edit', gridService.selectedGroupValue?.id)"
        (click)="gridService.execute('edit', gridService.selectedGroup$.getValue())"
      >
        {{ 'shared.actions.edit' | translate }}
      </button>

      <button
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.delete' | translate }}"
        [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroupValue?.id)"
        (click)="gridService.execute('delete', gridService.selectedGroup$.getValue())"
      >
        <i class="bi bi-trash3 bi-15"></i>
      </button>
    </div>
    <div class="group">
      <button
        type="button"
        class="btn btn-default"
        title="{{ 'settings.lifecycles.card.actions.moveUp' | translate }}"
        [disabled]="!gridService.canBeExecuted('moveUp', gridService.selectedGroupValue?.id)"
        (click)="gridService.execute('moveUp', gridService.selectedGroup$.getValue())"
      >
        <i class="bi bi-chevron-up bi-15"></i>
      </button>

      <button
        type="button"
        class="btn btn-default"
        title="{{ 'settings.lifecycles.card.actions.moveDown' | translate }}"
        [disabled]="!gridService.canBeExecuted('moveDown', gridService.selectedGroupValue?.id)"
        (click)="gridService.execute('moveDown', gridService.selectedGroup$.getValue())"
      >
        <i class="bi bi-chevron-down bi-15"></i>
      </button>
    </div>
  </div>
}
