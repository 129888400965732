import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { PlannerCommandService } from 'src/app/shared-features/planner/core/planner-command.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { VisibleParts } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-parts.enum';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectResourcesForecastCalendarService } from './core/project-resources-forecast-calendar.service';
import { ProjectResourcesForecastCalendarDataService } from './core/project-resources-forecast-calendar-data.service';
import { ProjectResourcesCalendarCommandService } from '../shared/core/project-resources-calendar-command.service';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/project-resources.service';

@Component({
  selector: 'wp-project-resources-forecast-calendar',
  templateUrl: './project-resources-forecast-calendar.component.html',
  styleUrls: [
    '../project-resources-estimate-calendar/project-resources-estimate-calendar.component.scss',
    'project-resources-forecast-calendar.component.scss',
  ],
  providers: [
    ProjectResourcesForecastCalendarService,
    ProjectResourcesForecastCalendarDataService,
    ProjectResourcesCalendarCommandService,
    ScheduleNavigationService,
    PlannerCommandService,
    FreezeTableService,
    SavingQueueService,
  ],
})
export class ProjectResourcesForecastCalendarComponent
  implements OnInit, OnDestroy
{
  public scheduleNavigationParts: VisibleParts[] = [
    VisibleParts.calendar,
    VisibleParts.valueModeSelector,
  ];
  public isShowTaskDuration: boolean;

  private destroyed$ = new Subject<void>();

  constructor(
    public service: ProjectResourcesForecastCalendarService,
    public changeDetector: ChangeDetectorRef,
    public dataService: ProjectResourcesForecastCalendarDataService,
    public projectCardService: ProjectCardService,
    private projectResourcesService: ProjectResourceService,
  ) {}

  ngOnInit(): void {
    this.service.init();
    this.dataService.init();
    this.service.changes$.subscribe(() => this.changeDetector.detectChanges());
    this.projectResourcesService.isShowTaskDuration$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.isShowTaskDuration = value;
      });
  }

  ngOnDestroy(): void {
    this.service.dispose();
    this.dataService.dispose();
    this.destroyed$.next();
  }
}
