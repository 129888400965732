import { Component, OnInit, Input, DestroyRef, inject } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { Activity } from 'src/app/shared/models/entities/settings/activity.model';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { Constants } from 'src/app/shared/globals/constants';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-activity-card',
  templateUrl: './activity-card.component.html',
})
export class ActivityCardComponent implements OnInit {
  @Input() private entityId: string;

  public state: CardState;
  public isSaving = false;

  public activityForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(Constants.formNameMaxLength),
    ]),
    description: new UntypedFormControl(
      '',
      Validators.maxLength(Constants.formTextMaxLength),
    ),
    isActive: new UntypedFormControl(false),
  });
  public readonly: boolean;

  private destroyRef = inject(DestroyRef);

  constructor(
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    private navigationService: NavigationService,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
    ]);

    this.load();

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  /** Saves Data. */
  public save = (): void => {
    this.activityForm.markAllAsTouched();

    if (this.activityForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const activity = <Activity>this.activityForm.value;

    const data: any = {
      name: activity.name,
      description: activity.description,
      isActive: activity.isActive,
    };

    this.data
      .collection('Activities')
      .entity(this.entityId)
      .patch(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.activityForm.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.activities.card.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  };

  private load = (): void => {
    this.state = CardState.Loading;
    this.activityForm.markAsPristine();
    this.activityForm.markAsUntouched();

    this.data
      .collection('Activities')
      .entity(this.entityId)
      .get<Activity>(null)
      .subscribe({
        next: (activity: Activity) => {
          this.activityForm.patchValue(activity);
          this.readonly = !activity.editAllowed;
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.readonly
            ? this.activityForm.disable()
            : this.activityForm.enable();
          this.actionService.action('save').isShown = !this.readonly;
          this.state = CardState.Ready;

          this.navigationService.addRouteSegment({
            id: activity.id,
            title: activity.name,
          });
        },
        error: (error: Exception) => {
          this.state = CardState.Error;
          this.notification.error(error.message);
        },
      });
  };

  private reload(): void {
    if (!this.activityForm.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }
}
