import { Injectable } from '@angular/core';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { AppConfigService } from 'src/app/core/app-config.service';
import { AuthService } from 'src/app/core/auth.service';

@Injectable({
  providedIn: 'root',
})

/** User notification service. */
export class WebSocketsService {
  public connection: HubConnection;

  constructor(private wpAuthService: AuthService) {
    this.initSignalR();
  }

  private initSignalR() {
    if (!AppConfigService.config.websocket.url) {
      return;
    }
    const connectionUrl = this.wpAuthService.substitutedEmail
      ? AppConfigService.config.websocket.url +
        `/websocket?login_as=${this.wpAuthService.substitutedEmail}`
      : AppConfigService.config.websocket.url + '/websocket';

    this.connection = new HubConnectionBuilder()
      .withUrl(connectionUrl, {
        accessTokenFactory: () => this.getToken(),
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect(new Array(10).fill(5000))
      .configureLogging(LogLevel.Information)
      .build();

    // Start the connection.
    this.connection.start();
  }

  private getToken(): Promise<string> {
    return new Promise<string>((resolve) => {
      this.wpAuthService.getAuthorizationHeaderValue().subscribe((token) => {
        const tokenWithoutBearer = token.replace('Bearer ', '');
        resolve(tokenWithoutBearer);
      });
    });
  }
}
