import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Exception } from 'src/app/shared/models/exception';

/**
 * Перехват запрос к API и добавление заголовка аутентификации.
 */
@Injectable()
export class ApiAuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.auth.getAuthorizationHeaderValue().pipe(
      catchError((error) =>
        throwError(
          new Exception('Authorization Header was not received.', error),
        ),
      ),
      switchMap((tokenValue) => {
        if (!tokenValue) {
          throwError(new Exception('inner', 'Authorization Header is empty.'));
        }

        let headers = request.headers.set('Authorization', tokenValue);

        if (this.auth.substitutedEmail) {
          headers = headers.set('WP-LoginAs', this.auth.substitutedEmail);
        }

        headers = headers.set('ngsw-bypass', 'true');

        const requestClone = request.clone({
          headers,
        });

        return next.handle(requestClone);
      }),
    );
  }
}
