import { Injectable, Type } from '@angular/core';

import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

import { BoardFilterComponent } from 'src/app/boards/components/board/filter/board-filter.component';

@Injectable()
export class BoardFilterService extends FilterService {
  public override component: Type<FilterDetails> = BoardFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override hasAllowInactive = true;
  public override placeholder = 'expenses.list.filter.placeholder';
  public override customCriteriaCount: Record<string, () => number> = {
    text: () => 0,
  };

  public override getODataFilter(): Record<string, object>[] {
    const result: Record<string, object>[] = [];

    const text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      result.push(
        this.getTextFilter(['name', 'description'], this.getClearText(text)),
      );
    }

    if (this.values.states) {
      const conditions = [];

      Object.keys(this.values.states).forEach((code) => {
        if (this.values.states[code].selected) {
          conditions.push({
            stateId: { type: 'guid', value: this.values.states[code].id },
          });
        }
      });

      result.push({ or: conditions });
    }
    return result;
  }

  protected override getDefaultValues(): Record<string, any> {
    return {
      states: null,
    };
  }
}
