import {
  Component,
  Input,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { UntypedFormGroup } from '@angular/forms';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { PerformersService } from '../performers.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wp-performer-readonly-cell',
  template: `<div
    class="trim text-body-secondary"
    title="{{ getPerformerName() }}"
    [ngClass]="{ invalid: invalid }"
  >
    {{ getPerformerName() }}
  </div>`,
  styles: [
    `
      div {
        height: 100%;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 12px;
        padding-right: 12px;
      }
      .invalid {
        border-left-width: 3px;
        border-left: red solid !important;
      }
    `,
  ],
})
export class PerformerReadonlyCellComponent
  implements GridTemplateCell, OnInit, OnDestroy
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;

  private destroyed$ = new Subject<void>();
  invalid: boolean;

  constructor(
    public performersService: PerformersService,
    public service: GridService,
    public ref: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.invalid = this.formGroup.invalid;

    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.invalid = this.formGroup.invalid;
        this.ref.detectChanges();
      });
  }

  public getPerformerName(): string {
    const performerValue = this.formGroup.controls[this.column.name].value;
    return performerValue.name
      ? performerValue.name
      : this.translate.instant('shared.valueNotSelected');
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
