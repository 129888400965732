<div *ngIf="!(service.readonly$ | async)" class="toolbar-container">
  <button
    type="button"
    class="btn btn-default"
    title="{{ 'shared.actions.addLine' | translate }}"
    (click)="service.execute('add')"
  >
    <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
    {{ 'shared.actions.addLine' | translate }}
  </button>
</div>
