<div class="name d-flex">
  <button
    type="button"
    class="btn wp-nav left"
    title="{{ 'timesheets.card.header.periodPrevious' | translate }}"
    (click)="previous()"
  >
    <i class="bi bi-chevron-left" aria-hidden="true"></i>
  </button>
  <div ngbDropdown #dropdown="ngbDropdown" placement="bottom-left" style="z-index: 1">
    <button type="button" class="btn wp-nav" ngbDropdownToggle (click)="openPeriodSelector()">
      <div
        class="h1"
        [attr.data-test-date-from]="timesheet?.dateFrom"
        [attr.data-test-date-to]="timesheet?.dateTo"
      >
        {{ timesheet?.dateFrom | date: 'longDate' }}
        &nbsp;&mdash;&nbsp;
        {{ timesheet?.dateTo | date: 'longDate' }}
      </div>
    </button>
    <div ngbDropdownMenu>
      <wp-loading-indicator [loading]="loading"></wp-loading-indicator>

      <button
        *ngFor="let period of periods"
        ngbDropdownItem
        (click)="setPeriod(period)"
        [ngClass]="period.css"
      >
        <table>
          <tr>
            <td [attr.data-test-date-from]="period.from">{{ period.from | date: 'longDate' }}</td>
            <td>&mdash;</td>
            <td [attr.data-test-date-to]="period.to">{{ period.to | date: 'longDate' }}</td>
          </tr>
        </table>
      </button>
    </div>
  </div>
  <button
    type="button"
    class="btn wp-nav right"
    title="{{ 'timesheets.card.header.periodNext' | translate }}"
    (click)="next()"
  >
    <i class="bi bi-chevron-right" aria-hidden="true"></i>
  </button>
</div>
<div class="indicators d-none d-xxl-flex">
  <div *ngFor="let indicator of timesheetCardService?.indicators$ | async">
    <div class="value">{{ indicator.value }}</div>
    <div class="description text-gray">{{ indicator.description | translate }}</div>
  </div>
</div>
