<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.rateMatrices.changeStructure.modalHeader' | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="closeModal()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="w-100 text-body-secondary mb-2">
      {{ 'settings.rateMatrices.changeStructure.changeAnalytic' | translate }}
    </div>

    <div class="list-container disable-selecting">
      <div class="list-header">
        <span title="{{ 'settings.rateMatrices.changeStructure.analytics' | translate }}">
          {{ 'settings.rateMatrices.changeStructure.analytics' | translate }}
        </span>
      </div>
      <ul
        class="list list--available"
        [nxtSortablejs]="analytics"
        [config]="analyticsSortableOptions"
      >
        @for (analytic of analytics; track analytic.key) {
          <li
            class="list__item"
            [attr.data-key]="analytic.key"
            [class.draggable]="!analytic.disabled"
          >
            <div class="handle">
              <span class="trim">
                {{ analytic.title }}
              </span>

              @if (analytic.hint && analytic.disabled) {
                <i
                  class="group-hint bi bi-question-circle text-gray ms-auto d-flex"
                  [ngbTooltip]="analytic.hint"
                ></i>
              }
            </div>
          </li>
        }

        @if (!analytics?.length) {
          <ng-container [ngTemplateOutlet]="nodata" />
        }
      </ul>
    </div>

    <div class="list-container disable-selecting">
      <div class="list-header">
        <span title="{{ 'settings.rateMatrices.changeStructure.selectedAnalytics' | translate }}">
          {{ 'settings.rateMatrices.changeStructure.selectedAnalytics' | translate }}
        </span>
      </div>
      <ul
        class="list list--selected"
        [nxtSortablejs]="selectedAnalytics"
        [config]="selectedColumnsSortableOptions"
      >
        @for (analytic of selectedAnalytics; track analytic.key) {
          <li
            class="list__item"
            [attr.data-key]="analytic.key"
            [class.draggable]="!analytic.disabled"
          >
            <div class="trim handle" title="{{ analytic.title }}">
              <span> {{ $index + 1 }}. {{ analytic.title }} </span>

              @if (!analytic.disabled) {
                <button
                  type="button"
                  class="list-item-delete"
                  title="{{ 'shared.actions.delete' | translate }}"
                  (click)="removeSelected(analytic)"
                >
                  &times;
                </button>
              }
            </div>
          </li>
        }

        @if (!selectedAnalytics?.length) {
          <ng-container [ngTemplateOutlet]="nodata" />
        }
      </ul>
    </div>
  </div>

  <div class="modal-footer">
    @if (!readonly) {
      <button type="button" class="btn btn-primary" (click)="ok()">
        {{ 'shared.actions.ok' | translate }}
      </button>
    }

    <button type="button" class="btn btn-default ms-2" (click)="closeModal()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>

<ng-template #nodata>
  <div class="list__empty">
    <p>
      {{ 'shared.dragColumn' | translate }}
    </p>
  </div>
</ng-template>
