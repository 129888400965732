<form class="modal-form" novalidate ngDraggable [formGroup]="providerForm">
  <div class="modal-header">
    <h3 class="modal-title">
      {{
        providerName ? providerName : ('settings.settings.authProviders.modal.title' | translate)
      }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group" tmtIndicator>
      <label>{{
        'settings.settings.authProviders.modal.properties.providerName' | translate
      }}</label>
      <wp-text-box
        formControlName="name"
        placeholder="{{
          'settings.settings.authProviders.modal.properties.providerName' | translate
        }}"
      >
      </wp-text-box>
    </div>

    <div class="form-group">
      <label>{{
        'settings.settings.authProviders.modal.properties.providerType' | translate
      }}</label>
      <wp-text-box
        formControlName="type"
        readonly="true"
        placeholder="{{
          'settings.settings.authProviders.modal.properties.providerType' | translate
        }}"
      >
      </wp-text-box>
    </div>
    <hr />
    <div formGroupName="openIdConfiguration" *ngIf="isOpenIdConfVisible">
      <div class="form-group" tmtIndicator>
        <label>{{
          'settings.settings.authProviders.modal.properties.authority' | translate
        }}</label>
        <wp-text-box
          formControlName="authority"
          placeholder="{{
            'settings.settings.authProviders.modal.properties.authority' | translate
          }}"
        >
        </wp-text-box>
      </div>
      <div class="form-group" tmtIndicator>
        <label>{{ 'settings.settings.authProviders.modal.properties.clientId' | translate }}</label>
        <wp-text-box
          formControlName="clientId"
          placeholder="{{
            'settings.settings.authProviders.modal.properties.clientId' | translate
          }}"
        >
        </wp-text-box>
      </div>
      <div class="form-group" tmtIndicator>
        <label>{{
          'settings.settings.authProviders.modal.properties.clientSecret' | translate
        }}</label>
        <wp-text-box
          formControlName="clientSecret"
          placeholder="{{
            'settings.settings.authProviders.modal.properties.clientSecret' | translate
          }}"
        >
        </wp-text-box>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
      *ngIf="!readonly"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
