import { Component, ChangeDetectorRef } from '@angular/core';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { Toolbar } from 'src/app/shared/components/features/grid/toolbar';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-normalization-rule-time-off-toolbar',
  templateUrl: './normalization-rule-time-off-toolbar.component.html',
})
export class NormalizationRuleTimeOffToolbarComponent extends Toolbar {
  public selectedTimeOffTypes: any[] = [];
  public timeOffTypes: NamedEntity[] = [];
  public loading = false;
  constructor(
    private ref: ChangeDetectorRef,
    public service: GridService,
    public notification: NotificationService,
    private data: DataService,
  ) {
    super();
  }

  openChange($event: boolean) {
    if (!$event) {
      return;
    }

    this.loading = true;
    this.timeOffTypes = [];
    const params = {
      filter: { isActive: true },
      select: ['name', 'id'],
      orderBy: ['name'],
    };

    this.data
      .collection('TimeOffTypes')
      .query(params)
      .subscribe({
        next: (timeOffTypes: NamedEntity[]) => {
          timeOffTypes.forEach((timeOffType: NamedEntity) => {
            if (
              this.selectedTimeOffTypes.every(
                (selectedTimeOffType: any) =>
                  selectedTimeOffType.timeOffTypeId !== timeOffType.id,
              )
            ) {
              this.timeOffTypes.push(timeOffType);
            }
          });
          this.loading = false;
          this.ref.detectChanges();
        },
        error: (error: Exception) => {
          this.loading = false;
          this.notification.error(error.message);
          this.ref.detectChanges();
        },
      });
  }

  selectTimeOffType(timeOffType: NamedEntity) {
    this.service.execute('add', timeOffType);
  }
}
