import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { forkJoin, ReplaySubject, Subscription } from 'rxjs';
import { SettingsCardService } from '../settings-card.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { BillingContact } from 'src/app/shared/models/entities/settings/multitenant/billing-contact.model';
import { Exception } from 'src/app/shared/models/exception';
import { MessageService } from 'src/app/core/message.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'wp-settings-billing',
  templateUrl: './settings-billing.component.html',
})
export class SettingsBillingComponent implements OnInit, OnDestroy {
  public readonly = false;
  public state: CardState;
  public isSaving = false;
  public currency: string;
  public currencyLoading$ = new ReplaySubject<boolean>();

  private reloadSubscription: Subscription;

  public billingContact: BillingContact;
  public countries: NamedEntity[];

  public form = this.fb.group({
    organization: this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      identificationNumber: [
        '',
        [Validators.required, Validators.maxLength(50)],
      ],
      bankIdentificationCode: [
        '',
        [Validators.required, Validators.maxLength(50)],
      ],
      bankAccount: ['', [Validators.required, Validators.maxLength(50)]],
    }),
    contact: this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      phone: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
    }),
    postAddress: this.fb.group({
      address: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formTextMaxLength),
        ],
      ],
      index: ['', [Validators.required, Validators.maxLength(50)]],
      city: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
    }),
    useEDM: false,
    country: null,
  });

  constructor(
    private service: SettingsCardService,
    private actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private message: MessageService,
  ) {}

  public reload() {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  private load() {
    this.state = CardState.Loading;
    this.form.markAsPristine();
    this.form.markAsUntouched();

    forkJoin({
      billingContact: this.data
        .singleton('Tenant')
        .function('GetBillingContact')
        .get<BillingContact>(),
      countries: this.data.model.function('GetCountries').get<any[]>(),
    }).subscribe({
      next: (data) => {
        this.countries = data.countries.map((c) => ({
          id: c.alpha3Code,
          name: c.name,
          alpha3Code: c.alpha3Code,
        }));

        this.billingContact = data.billingContact;
        this.billingContact.country.id = this.billingContact.country.alpha3Code;
        this.form.patchValue(this.billingContact);
        this.loadCurrency();

        if (this.billingContact.hasActiveAccount) {
          this.form.controls.country.disable();
        }

        this.state = CardState.Ready;
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.state = CardState.Error;
      },
    });
  }

  private loadCurrency() {
    const countryCode = this.form.controls.country.value?.id;
    if (!countryCode) {
      return;
    }

    this.currencyLoading$.next(true);

    this.data.model
      .function('GetCurrencyForCountry')
      .get<string>({ countryCode: `'${countryCode}'` })
      .subscribe((currency) => {
        this.currency = currency;
        this.currencyLoading$.next(false);
      });
  }

  public save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const billingContact = cloneDeep(this.form.getRawValue()) as BillingContact;
    billingContact.country.alpha3Code = billingContact.country.id;
    delete billingContact.country.id;

    this.data
      .singleton('Tenant')
      .action('WP.UpdateBillingContact')
      .execute({ billingContact })
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.settings.messages.settingSaved',
          );
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  }

  ngOnInit() {
    // Установка главного меню.
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: true,
        handler: () => this.save(),
      },
    ]);

    this.load();

    this.reloadSubscription = this.service.reloadTab$.subscribe(() =>
      this.reload(),
    );

    this.form.controls.country.valueChanges.subscribe(() => {
      this.loadCurrency();

      if (this.form.controls.country.value?.id !== 'RUS') {
        this.form.get('organization.identificationNumber').disable();
        this.form.get('organization.bankIdentificationCode').disable();
        this.form.get('organization.bankAccount').disable();
      } else {
        this.form.get('organization.identificationNumber').enable();
        this.form.get('organization.bankIdentificationCode').enable();
        this.form.get('organization.bankAccount').enable();
      }
    });
  }

  ngOnDestroy(): void {
    this.reloadSubscription.unsubscribe();
  }
}
