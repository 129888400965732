<div
  class="input-group"
  (clickOutside)="cancel()"
  [attachOutsideOnClick]="true"
  [exclude]="excludeOutsideClick"
  [ngClass]="{ nullable: allowNull }"
>
  <span class="bi state-icon" [ngClass]="getStateCircleClass(value)"> </span>

  <input
    #input
    class="form-control state-input"
    type="text"
    title="{{ value?.name }}"
    placeholder="{{ placeholder }}"
    (click)="onInputClick()"
    (keydown)="onKeyDown($event)"
    [formControl]="textControl"
  />

  <span
    class="input-clear ml-0"
    title="{{ 'shared.hintClearControlButton' | translate }}"
    (click)="clear()"
    [hidden]="!textControl.value"
    >&times;</span
  >

  <div
    id="{{ areaId }}"
    class="expanding-area expanding-area-vertical-scroll"
    *ngIf="listOpened"
    (mouseleave)="selectRow(null)"
  >
    <table class="selecting-list">
      <tbody>
        <tr class="add-version-button" (mouseenter)="selectRow(null)">
          <button
            class="btn"
            title="{{ 'projects.projectVersions.actions.createProjectVersion.hint' | translate }}"
            (click)="createProjectVersion(value)"
            [disabled]="createVersionButtonDisabled"
          >
            <span class="bi bi-plus-lg bi-15"></span>
            {{ 'projects.projectVersions.actions.createProjectVersion.label' | translate }}
          </button>
        </tr>
        <div class="divider"></div>
        <tr
          id="{{ workProjectVersion.id }}"
          class="version-dropdown-item"
          title="{{ workProjectVersion.name }}"
          *ngIf="projectVisible"
          (click)="clickRow(workProjectVersion)"
          (mouseenter)="selectRow(workProjectVersion)"
          [ngClass]="{ 'selected-row': workProjectVersion.id == selectedRow?.id }"
        >
          <div class="version-name-block">
            <div>{{ projectVersionLabel }}</div>
          </div>
          <div class="version-state">
            <span class="bi bi-house-door project-icon"></span>
          </div>
        </tr>
        <tr
          id="{{ row.id }}"
          title="{{ row.name }}"
          *ngFor="let row of rows"
          (click)="clickRow(row)"
          (mouseenter)="selectRow(row)"
          [ngClass]="{ 'selected-row': row.id == selectedRow?.id }"
        >
          <div class="version-dropdown-item">
            <div class="version-name-block">
              <span class="master-version" *ngIf="row.masterBaseline">
                {{ 'projects.projectVersions.list.columns.masterBaseline' | translate }}
              </span>
              <div class="trim">{{ row?.name }}</div>
            </div>
            <div class="version-state">
              <span class="badge" [ngClass]="getStateBadgeClass(row)">
                {{ row?.state?.name }}
              </span>
            </div>
          </div>
        </tr>
      </tbody>
    </table>
    <div
      *ngIf="rows.length === 0 && !projectVisible"
      class="center-block"
      style="text-align: center; padding: 3px"
    >
      <p class="text-body-secondary">{{ 'shared.noOptions' | translate }}</p>
    </div>
  </div>

  <button type="button" class="btn btn-default input-group-text" (click)="openList()">
    <i class="bi bi-chevron-down" aria-hidden="true"></i>
  </button>
</div>
