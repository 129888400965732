import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ResourceRolesComponent } from './resource-roles/resource-roles.component';
import { AddingResourceRolesModalComponent } from './adding-resource-roles-modal/adding-resource-roles-modal.component';
import { CompetencesCellComponent } from './resource-roles/competences-cell/competences-cell.component';
import { CompetencesCellReadonlyComponent } from './resource-roles/competences-cell/competences-cell-readonly.component';

@NgModule({
  declarations: [
    ResourceRolesComponent,
    AddingResourceRolesModalComponent,
    CompetencesCellComponent,
    CompetencesCellReadonlyComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [ResourceRolesComponent],
})
export class ResourceRolesModule {}
