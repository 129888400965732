<!--Строка ресурса-->
<tr class="group" [class.disabled]="!group.isActive" id="{{ group.id }}">
  <td class="collapser cursor-pointer" (click)="expand()">
    <span
      [ngClass]="{
        'bi bi-chevron-down': group.isExpanded,
        'bi bi-chevron-right': !group.isExpanded
      }"
    ></span>
  </td>

  <td
    class="content"
    (mouseenter)="rowStates[group.id].isHover = true"
    (mouseleave)="rowStates[group.id].isHover = false"
    [ngClass]="{
      commands:
        rowStates[group.id].commands.length > 0 &&
        (rowStates[group.id].isHover || rowStates[group.id].isMenuOpened)
    }"
  >
    <div>
      @if (group.resource?.type === resourceType.user) {
        <img class="avatar-bg" [src]="group.resource.id | avatar: 60" />
        <div class="trim">
          <div
            id="{{ group.resource.id }}"
            class="wp-action text"
            title="{{ group.name }}"
            (click)="openUserInfo(group.resource.id)"
          >
            <span id="{{ group.resource.id }}-name">{{ group.name }}</span>
          </div>
          <div class="role" title="{{ group.role?.name }}">
            {{ group.role?.name }}
          </div>
        </div>
      }

      @if (!group.resource || group.resource?.type === resourceType.generic) {
        @if (group.name) {
          <img src="/assets/images/avatar/role.svg" />
        }
        <div class="trim">
          <div
            class="text"
            title="{{ group.name ?? 'projects.projects.card.resources.unassigned' | translate }}"
          >
            {{ group.name ?? 'projects.projects.card.resources.unassigned' | translate }}
          </div>
          <div class="role" title="{{ group.role?.name }}">
            {{ group.role?.name }}
          </div>
        </div>
      }

      <wp-planner-row-commands [rowState]="rowStates[group.id]" />
    </div>
  </td>

  <td class="total-cell">
    {{ group.totalHours | plannerFormat }}
  </td>
</tr>

@if (group.isExpanded) {
  <!-- Строки данных -->
  @for (line of group.lines; track line.taskId) {
    <tr [ngClass]="{ disabled: !line.isActive }" [attr.data-test-task-id]="line.taskId">
      <td class="collapser"></td>
      <td
        id="{{ line.id }}"
        class="content"
        (mouseenter)="rowStates[line.id].isHover = true"
        (mouseleave)="rowStates[line.id].isHover = false"
        [ngClass]="{
          commands:
            rowStates[group.id].commands.length > 0 &&
            (rowStates[line.id].isHover || rowStates[line.id].isMenuOpened)
        }"
      >
        <div>
          <div title="{{ line.name }}" class="text">
            {{ line.name }}
          </div>
          @if (rowStates[line.id]) {
            <wp-planner-row-commands [rowState]="rowStates[line.id]" />
          }
        </div>
      </td>
      <td class="total-cell">
        {{ line.totalHours | plannerFormat }}
      </td>
    </tr>
  }
  @if (!group.lines.length) {
    <tr>
      <td class="collapser"></td>
      <td class="content"></td>
      <td></td>
    </tr>
  }
}
