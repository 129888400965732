import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';

/** Форматирование значений в часах. */
@Pipe({
  name: 'wpDuration',
})
export class DurationPipe implements PipeTransform {
  private readonly daySymbol = '';

  constructor(
    private translate: TranslateService,
    private decimalPipe: DecimalPipe,
  ) {
    this.daySymbol = this.translate.instant('shared.unitDay');
  }

  transform(
    value?: number,
    nullHandlerType: NullHandlerType = NullHandlerType.empty,
  ): any {
    const template = '1.0-0';
    let displayValue: string;

    if (value === null) {
      switch (nullHandlerType) {
        case NullHandlerType.zero:
          value = 0;
          break;
        case NullHandlerType.dash:
          return '—' + ' ' + this.daySymbol;
        case NullHandlerType.empty:
          return null;
      }
    }

    displayValue = this.decimalPipe.transform(value, template);
    displayValue += ' ' + this.daySymbol;

    return displayValue;
  }
}

export enum NullHandlerType {
  zero = 'zero',
  dash = 'dash',
  empty = 'empty',
}
