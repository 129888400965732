import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { EntityTypeDescription } from '../models/entities/entity-type-description.model';

@Injectable({
  providedIn: 'root',
})
export class EntityTypesService {
  constructor(private data: DataService) {}

  private observable: Observable<EntityTypeDescription[]>;

  public getEntityTypes(): Observable<EntityTypeDescription[]> {
    if (this.observable) {
      return this.observable;
    }

    const observable = this.data.model
      .function('GetEntityTypes')
      .query<EntityTypeDescription[]>()
      .pipe(
        shareReplay(),
        map((it) => cloneDeep(it)),
      );

    return (this.observable = observable);
  }

  /**
   * Gets collection of entity type.
   *
   * @param metaEntityType name of entity type.
   * @returns collection name.
   */
  public getEntityTypeCollection(metaEntityType: string): Observable<string> {
    return this.getEntityTypes().pipe(
      map(
        (entityTypeDescriptions) =>
          entityTypeDescriptions.find(
            (entityTypeDescription) =>
              entityTypeDescription.entityTypeName === metaEntityType,
          )?.collection,
      ),
    );
  }
}
