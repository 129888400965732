import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';

import {
  GetTransitionFormPropertyValue,
  TransitionFormDescription,
} from 'src/app/shared/models/entities/lifecycle/transition-form.model';
import { Exception } from 'src/app/shared/models/exception';
import { MenuAction } from 'src/app/shared/models/inner/menu-action';
import {
  MetaEntity,
  MetaEntityBaseProperty,
  MetaEntityDirectoryProperty,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';
import { BehaviorSubject, forkJoin, Observable, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityTypesService } from 'src/app/shared/services/entity-types.service';
import { AppService } from 'src/app/core/app.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

@Component({
  selector: 'tmt-transition-form-modal',
  templateUrl: './transition-form-modal.component.html',
  styleUrls: ['./transition-form-modal.component.scss'],
})
export class TransitionFormModalComponent implements OnInit {
  @Input() stateId: string;
  @Input() action: MenuAction;
  @Input() entityId: string;
  @Input() collection: string;
  @Input() transitionId: string;

  public isLoading = false;
  public transitionForm: FormGroup = this.fb.group({});
  public transitionProperties: GetTransitionFormPropertyValue[];
  public requestComment = false;
  public metaEntity: MetaEntity;
  protected readonly MetaEntityPropertyType = MetaEntityPropertyType;
  private metaEntityProps: MetaEntityBaseProperty[];

  private collectionValuesSubject = new BehaviorSubject<any>(undefined);
  public collectionValues$ = this.collectionValuesSubject.asObservable();
  private destroyRef = inject(DestroyRef);

  constructor(
    private activeModal: NgbActiveModal,
    private dataService: DataService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private entityTypesService: EntityTypesService,
    public appService: AppService,
  ) {}

  public ngOnInit(): void {
    this.isLoading = true;

    this.dataService
      .collection(this.collection)
      .entity(this.entityId)
      .function('GetTransitionFormDescription')
      .query({ transitionId: this.transitionId })
      .subscribe({
        next: (transitionFormDescription: TransitionFormDescription) => {
          this.transitionProperties =
            transitionFormDescription.transitionFormPropertyValues;
          if (this.metaEntity) {
            this.metaEntityProps = [
              ...this.metaEntity.primitiveProperties,
              ...this.metaEntity.navigationProperties,
              ...this.metaEntity.complexProperties,
              ...this.metaEntity.directoryProperties,
            ];
          }
          this.requestComment = transitionFormDescription.requestComment;

          if (this.requestComment) {
            this.transitionForm.addControl(
              'comment',
              this.fb.control(
                null,
                transitionFormDescription.commentIsRequired
                  ? Validators.required
                  : null,
              ),
            );
          }

          this.makeForm();
        },
        error: (error: Exception) => {
          this.isLoading = false;
          if (error.code === Exception.BtEntityNotFoundException.code) {
            return;
          }

          this.notificationService.error(error.message);
        },
      });
  }

  /** Resolves modal with form data. */
  public ok(): void {
    this.transitionForm.markAllAsTouched();

    if (this.transitionForm.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );

      return;
    }

    const data = {
      stateId: this.stateId,
      transitionFormValue: {
        propertyValues: [],
        comment: this.transitionForm.value.comment,
      },
    };

    Object.keys(this.transitionForm.value).forEach((key) => {
      const isNavigationProperty = this.transitionProperties.some(
        (p) =>
          (p.name === key &&
            this.getMetaEntityProperty(key)?.type ===
              MetaEntityPropertyType.navigation) ||
          this.getMetaEntityProperty(key)?.type ===
            MetaEntityPropertyType.directory,
      );
      if (isNavigationProperty) {
        data.transitionFormValue.propertyValues.push({
          name: key,
          value: this.transitionForm.value[key]?.id,
        });
      } else if (key !== 'comment' && !isNavigationProperty) {
        data.transitionFormValue.propertyValues.push({
          name: key,
          value: this.transitionForm.value[key],
        });
      }
    });

    this.activeModal.close(data);
  }

  /** Closes modal without result. */
  public cancel(): void {
    this.activeModal.dismiss(null);
  }

  /**
   * Returns MetaEntity property.
   * @param propertyName Property name.
   */
  public getMetaEntityProperty(propertyName: string): MetaEntityBaseProperty {
    return this.metaEntityProps.find((x: any) => x.name === propertyName);
  }

  /**
   * Returns DirectoryId from MetaEntity property.
   * @param propertyName Property name.
   */
  public getMetaEntityPropertyDirectoryId(propertyName: string): string {
    return (
      this.metaEntityProps.find(
        (x: any) => x.name === propertyName,
      ) as MetaEntityDirectoryProperty
    ).directoryId;
  }

  private makeForm(): void {
    const collectionObservables: Record<string, Observable<NamedEntity[]>> = {};
    const collectionValues: Record<string, NamedEntity[]> = {};
    const hasNavigationProperties = this.transitionProperties.some(
      (prop) =>
        this.getMetaEntityProperty(prop.name).type ===
        MetaEntityPropertyType.navigation,
    );

    if (!hasNavigationProperties) {
      this.setControlValues(null);
      return;
    }

    this.entityTypesService
      .getEntityTypes()
      .pipe(
        switchMap((entityTypeDescriptions) => {
          this.transitionProperties
            .filter(
              (prop) =>
                this.getMetaEntityProperty(prop.name).type ===
                  MetaEntityPropertyType.navigation ||
                this.getMetaEntityProperty(prop.name).type ===
                  MetaEntityPropertyType.directory,
            )
            .forEach((prop) => {
              const mmProperty = this.getMetaEntityProperty(prop.name);
              const query = {
                filter: { isActive: true, directoryId: undefined },
              };
              if (mmProperty.type === MetaEntityPropertyType.directory) {
                query.filter.directoryId = {
                  type: 'guid',
                  value: (mmProperty as MetaEntityDirectoryProperty)
                    .directoryId,
                };
              }
              const collection = entityTypeDescriptions.find(
                (x) =>
                  x.entityTypeName ===
                  this.getMetaEntityProperty(prop.name).clrType,
              ).collection;
              collectionObservables[prop.name] = this.dataService
                .collection(collection)
                .query(query);
            });
          return forkJoin(collectionObservables);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (collections) => {
          Object.keys(collections).forEach((key) => {
            collectionValues[key] = collections[key];
          });

          this.setControlValues(collectionValues);
          this.collectionValuesSubject.next(collectionValues);
        },
        error: (error: Exception) => {
          this.isLoading = false;
          if (error.code === Exception.BtEntityNotFoundException.code) {
            return;
          }
          this.notificationService.error(error.message);
        },
      });
  }

  private setControlValues(collectionValues: any) {
    this.transitionProperties.forEach((property) => {
      let value: any;
      if (
        (this.getMetaEntityProperty(property.name).type ===
          MetaEntityPropertyType.navigation ||
          this.getMetaEntityProperty(property.name).type ===
            MetaEntityPropertyType.directory) &&
        collectionValues
      ) {
        const entity = collectionValues[property.name].find(
          (item) => item.id === property.value,
        );
        if (entity) {
          value = { name: entity.name, id: entity.value };
        }
      } else {
        value = property.value;
      }

      this.transitionForm.addControl(
        property.name,
        this.fb.control(
          value,
          property.isRequired ? Validators.required : null,
        ),
      );
    });
    this.isLoading = false;
  }
}
