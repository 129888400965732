import { Injectable, Inject, Optional } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/core/app.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import {
  GridColumnType,
  GridComponentColumn,
  GridSelectControlColumn,
  GridStringControlColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { List } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import { View } from 'src/app/shared/models/inner/view';
import { ListService } from 'src/app/shared/services/list.service';
import { LIST, META_ENTITY_TYPE, VIEW_NAME } from 'src/app/shared/tokens';

import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { TaskCollapsibleCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-collapsible-cell/task-collapsible-cell.component';
import { TaskCollapsibleReadonlyCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-collapsible-cell/task-collapsible-readonly-cell.component';
import {
  MetaEntityBaseProperty,
  MetaEntityDirectoryProperty,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';

@Injectable()
export class ProjectTasksListService extends ListService {
  constructor(
    @Inject(LIST) list: List,
    @Inject(VIEW_NAME) viewName: string,
    @Optional() @Inject(META_ENTITY_TYPE) metaEntityType: string,
    appService: AppService,
    modalService: NgbModal,
    @Optional() filterService: FilterService,
  ) {
    super(
      list,
      viewName,
      metaEntityType,
      appService,
      filterService,
      modalService,
    );
  }

  /** Расширяет текущий список и все его представления дополнительными колонками (полями). */
  public override extendListWithCustomFields(): void {
    const fields = this.appService
      .getCustomFields('ProjectTask')
      .filter((f) => f.viewConfiguration.isShownInEntityLists);

    // Добавляем колонки данных.
    fields.forEach((field: MetaEntityBaseProperty) => {
      const name =
        field.type === MetaEntityPropertyType.directory
          ? (field as MetaEntityDirectoryProperty).keyProperty
          : field.name;

      if (this.list.columns.find((c) => c.name === name)) {
        return;
      }

      const local = field.viewConfiguration.label;

      switch (field.type) {
        case MetaEntityPropertyType.decimal:
        case MetaEntityPropertyType.integer:
          this.list.columns.push(<GridComponentColumn>{
            name,
            type: GridColumnType.Component,
            contentType:
              field.type === MetaEntityPropertyType.decimal
                ? GridColumnType.Decimal
                : GridColumnType.Integer,
            component: TaskCollapsibleCellComponent,
            readonlyComponent: TaskCollapsibleReadonlyCellComponent,
            fixedWidth: true,
            header: local,
            hint: local,
            availableTotals: [TotalType.Sum],
          });
          break;
        case MetaEntityPropertyType.string:
          this.list.columns.push(<GridStringControlColumn>{
            name,
            type: GridColumnType.StringControl,
            fixedWidth: true,
            header: local,
            hint: local,
            forceCellUpdating: true,
            propagationMode: PropagationMode.onExitFromEditing,
          });
          break;
        case MetaEntityPropertyType.dateOnly:
          this.list.columns.push({
            name,
            type: GridColumnType.DateControl,
            fixedWidth: true,
            header: local,
            hint: local,
            forceCellUpdating: true,
          });
          break;
        case MetaEntityPropertyType.directory:
          this.list.columns.push(<GridSelectControlColumn>{
            name,
            header: local,
            hint: local,
            type: GridColumnType.SelectControl,
            fixedWidth: true,
            directoryId: (field as MetaEntityDirectoryProperty).directoryId,
            isIdMode: true,
            forceCellUpdating: true,
          });
          break;
        default:
          return;
      }

      this.list.views.forEach((view: View) => {
        if (view.columns.find((c) => c.column === name)) {
          return;
        }

        view.columns.push({
          column: name,
          width: 120,
          totalByDefault: TotalType.Sum,
          visibleByDefault: false,
        });
      });
    });
  }
}
