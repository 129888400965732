import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';

import { SharedModule } from 'src/app/shared/shared.module';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';
import { TariffRatesModule } from 'src/app/shared/modules/tariff-rates/tariff-rates.module';

import { ProjectCardSharedModule } from 'src/app/projects/card/shared/project-card-shared.module';

import { ProjectTariffsComponent } from './project-tariffs.component';
import { ProjectTariffsToolbarComponent } from './project-tariffs-toolbar/project-tariffs-toolbar.component';
import { ProjectTariffModalComponent } from './project-tariffs-modal/project-tariff-modal.component';
import { ProjectTariffsClientTariffModalComponent } from './project-tariffs-client-tariff-modal/project-tariffs-client-tariff-modal.component';
import { ProjectTariffsRateMatricesModalComponent } from './project-tariffs-rate-matrices-modal/project-tariffs-rate-matrices-modal.component';

@NgModule({
  declarations: [
    ProjectTariffsComponent,
    ProjectTariffsToolbarComponent,
    ProjectTariffModalComponent,
    ProjectTariffsClientTariffModalComponent,
    ProjectTariffsRateMatricesModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProjectCardSharedModule,
    TariffRatesModule,
    Grid2Module,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.tariffs',
          url: '/tariffs',
          component: ProjectTariffsComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class ProjectTariffsModule {}
