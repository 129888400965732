import { NamedEntity } from '../named-entity.model';
import { VatRate } from '../billing/vat-rate.model';

export interface InvoiceTemplate extends NamedEntity {
  /** Логотип для вставки в счет. */
  logo: string;

  /** Признак существования логотипа */
  hasLogo: boolean;

  /** Адрес компании, используемый в счете. */
  companyAddress: string;

  /** Имя компании, используемое в счете. */
  companyName: string;

  /** Телефон компании, используемый в счете. */
  companyPhone: string;

  /** Платежные реквизиты для счета. */
  paymentRequisites: string;

  /** Должности подписантов для вывода в счет. */
  signaturePosts: string;

  /** Имена подписантов для вывода в счет. */
  signatureNames: string;

  /** Ставка НДС */
  vatRate: VatRate;

  /** Размер печатной формы счета. */
  pageSize: PageSize;

  /** Ориентация печатной формы счета. */
  pageOrientation: PageOrientation;

  /** Признак использования шаблона по умолчанию для новых счетов. */
  isDefault: boolean;
}

export enum PageSize {
  A4 = 'A4',
  Letter = 'Letter',
}

export enum PageOrientation {
  Portrait = 'Portrait',
  Landscape = 'Landscape',
}
