<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">{{ 'timeOff.creation.header' | translate }}</h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 col-lg-7">
        <div class="form-group" *ngIf="userIsAllowed" tmtIndicator>
          <label>{{ 'timeOff.card.props.applicant' | translate }}</label>
          <wp-user-box
            placeholder="{{ 'timeOff.card.props.applicant' | translate }}"
            formControlName="user"
            [query]="userQuery"
          >
          </wp-user-box>
        </div>

        <div class="form-group" tmtIndicator>
          <label>{{ 'timeOff.card.props.timeOffType' | translate }}</label>
          <wp-select-box
            [allowNull]="false"
            [query]="timeOffTypeQuery"
            collection="TimeOffTypes"
            placeholder="{{ 'timeOff.card.props.timeOffType' | translate }}"
            formControlName="timeOffType"
          >
          </wp-select-box>
        </div>

        <wp-time-off-period [formGroup]="form" [creatingMode]="true"></wp-time-off-period>
      </div>
      <div class="col-12 col-lg-5">
        <wp-time-off-request-info
          *ngIf="form.value.timeOffType"
          [isApproved]="false"
          [currentBalance]="currentBalance"
          [currentDuration]="currentDuration"
          [unitName]="form.value.timeOffType?.unit?.name"
        ></wp-time-off-request-info>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
