import { ProjectFilterService } from 'src/app/projects/list/project-filter/project-filter.service';
import { WorkflowTaskFilterService } from 'src/app/workflow-tasks/list/filter/workflow-task-filter.service';
import { RequestFilterService } from 'src/app/resource-requests/list/request-filter/request-filter.service';
import { IssueFilterService } from 'src/app/issues/list/filter/issue-filter.service';
import { IssueCardComponent } from 'src/app/issues/card/issue-card.component';

import { BoardConfig } from './board-config.interface';
import { IssueCreationComponent } from 'src/app/issues/creation/issue-creation.component';

export const REQUIRED_PROPERTIES: string[] = ['Name'];

export const BOARD_WORKFLOW_TASK: BoardConfig = {
  entityType: 'Task',
  collection: 'Tasks',
  filterService: WorkflowTaskFilterService,
  cardStructure: [],
};

export const BOARD_PROJECT: BoardConfig = {
  entityType: 'Project',
  collection: 'Projects',
  cardCollection: 'ProjectBoardCards',
  customFieldEntityType: 'Project',
  filterService: ProjectFilterService,
  cardStructure: [],
  createMenuButton: 'project',
};

export const BOARD_ISSUE: BoardConfig = {
  entityType: 'Issue',
  collection: 'Issues',
  cardCollection: 'IssueBoardCards',
  customFieldEntityType: 'Issue',
  filterService: IssueFilterService,
  isSimpleFilterQuery: true,
  cardStructure: [],
  offCanvasComponent: IssueCardComponent,
  creationComponent: IssueCreationComponent,
};

export const BOARD_RESOURCE_REQUEST: BoardConfig = {
  entityType: 'ResourceRequest',
  collection: 'ResourceRequests',
  cardCollection: 'ResourceRequestBoardCards',
  filterService: RequestFilterService,
  isSimpleFilterQuery: true,
  cardStructure: [],
  createMenuButton: 'resourceRequest',
};

export const CONFIG_MAP: ReadonlyMap<string, BoardConfig> = new Map([
  ['Project', BOARD_PROJECT],
  ['Issue', BOARD_ISSUE],
  ['ResourceRequest', BOARD_RESOURCE_REQUEST],
]);
