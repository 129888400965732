import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalConfigService } from 'src/app/core/local-config.service';
import {
  AssistantBookingViewSettings,
  BookingViewSettings,
} from '../../models/booking-view.settings';

@Injectable()
export class BookingViewSettingsService {
  public settings: BookingViewSettings | AssistantBookingViewSettings;
  public settings$ = new Subject<BookingViewSettings>();

  private _assistantMode: boolean;
  public get assistantMode(): boolean {
    return this._assistantMode;
  }
  public set assistantMode(value: boolean) {
    this._assistantMode = value;
    this.init();
  }

  constructor(private localConfigService: LocalConfigService) {
    this.init();
  }

  /** Sets view settings. */
  public init(): void {
    this.settings = this.assistantMode
      ? this.localConfigService.getConfig(AssistantBookingViewSettings)
      : this.localConfigService.getConfig(BookingViewSettings);
  }

  /**
   * Emits settings change.
   *
   * @param settings View settings.
   */
  public propagateSettingsChange(settings: BookingViewSettings): void {
    this.settings = settings;
    this.settings$.next(settings);
    this.saveSettings();
  }

  private saveSettings(): void {
    if (this.assistantMode) {
      this.localConfigService.setConfig(
        AssistantBookingViewSettings,
        this.settings,
      );
    } else {
      this.localConfigService.setConfig(BookingViewSettings, this.settings);
    }
  }
}
