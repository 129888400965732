<div (clickOutside)="stopEdit()" [attachOutsideOnClick]="true">
  <div
    [hidden]="editMode"
    class="face"
    (click)="startEdit()"
    title="{{ formControl.value }}"
    [ngClass]="{ readonly: readonly }"
  >
    <div *ngIf="formControl.value">
      {{ formControl.value }}
    </div>
    <div *ngIf="!formControl.value" class="trim placeholder">
      {{ field.viewConfiguration.placeholder }}
    </div>
  </div>
  <textarea
    #editor
    class="form-control"
    type="text"
    *ngIf="editMode"
    name="input"
    autocomplete="off"
    [formControl]="formControl"
  ></textarea>
</div>
