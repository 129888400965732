import { EnumEntity } from '../../enums/enum-entity';

export class TimeOffBalanceEntryMode extends EnumEntity {
  public static get automatic(): TimeOffBalanceEntryMode {
    return {
      id: '028b095e-32cd-410f-896f-eaa8ed649e3e',
      code: 'Automatic',
      name: 'enums.timeOffBalanceEntryMode.Automatic',
    };
  }

  public static get manual(): TimeOffBalanceEntryMode {
    return {
      id: 'a975a75f-91d3-46b2-b655-dba92bfeb3c2',
      code: 'Manual',
      name: 'enums.timeOffBalanceEntryMode.Manual',
    };
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TimeOffBalanceEntryModes: TimeOffBalanceEntryMode[] = [
  TimeOffBalanceEntryMode.automatic,
  TimeOffBalanceEntryMode.manual,
];
