import { Injectable, Type } from '@angular/core';

import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';

import { BookingFilterComponent } from 'src/app/booking/booking/shared/booking-filter/booking-filter.component';

@Injectable()
export class BookingFilterService extends FilterService {
  public override component: Type<FilterDetails> = BookingFilterComponent;
  public override placeholder =
    'components.bookingFilterService.filters.textInput';
  public override storageName = 'booking';
  public override hasDetails = true;
  public override hasViewSelector = true;
  public override views = [
    { code: 'all', local: 'shared.filter.allEntities' },
    { code: 'active', local: 'shared.filter.onlyActiveEntities' },
  ];
  public override customCriteriaCount: Record<string, () => number> = {
    text: () => 0,
    view: () => 0,
    resourceIds: () => 0,
  };

  public override getODataFilter(): any {
    const result = super.getODataFilter();

    return result;
  }

  protected override getDefaultValues(): any {
    return {
      supervisor: null,
      project: null,
      resourcePool: null,
      role: null,
      competence: null,
      resource: null,
      department: null,
      level: null,
      grade: null,
      location: null,
      skillIds: null,
      legalEntity: null,
      resourceRequestId: null,
      resourceIds: null,
      requestPreferredResources: false,
      allowNonActiveEntity: false,
      view: this.views[1],
    };
  }
}
