<wp-form-header [name]="service.name$ | async" (reloaded)="service.reload()"></wp-form-header>
<wp-loading-indicator [state]="service.state$ | async"></wp-loading-indicator>
<tmt-not-found [state]="service.state$ | async"></tmt-not-found>
<wp-data-not-saved-indicator [form]="service.form"></wp-data-not-saved-indicator>

<wp-accounting-entry-body *ngIf="(service.state$ | async) == 'Ready'"></wp-accounting-entry-body>

<div class="pt-4" *ngIf="!service.readonly && (service.state$ | async) == 'Ready'">
  <button
    type="button"
    class="btn btn-primary"
    wpButtonSpinner
    [isBusy]="service.saving$ | async"
    (click)="service.save()"
  >
    {{ 'shared.actions.save' | translate }}
  </button>
</div>
