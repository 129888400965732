import { Component, OnInit, inject, DestroyRef } from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { StateService } from '@uirouter/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { RequestFilterService } from './request-filter/request-filter.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceRequestModalComponent } from 'src/app/resource-requests/shared/resource-request/modal/resource-request-modal.component';
import { Entity } from 'src/app/shared/models/entities/entity.model';
import { LifecycleListService } from 'src/app/core/lifecycle-list.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'tmt-resource-request-list',
  template: '<wp-entity-list></wp-entity-list> ',
  providers: [
    { provide: FilterService, useClass: RequestFilterService },
    EntityListService,
    ListService,
    GridService,
    LifecycleListService,
  ],
})
export class ResourceRequestListComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private entityListService: EntityListService,
    private gridService: GridService,
    private state: StateService,
    private actionPanelService: ActionPanelService,
    private modalService: NgbModal,
    private stateService: StateService,
    private lifecycleListService: LifecycleListService,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.set([
      this.actionPanelService.getDefaultAction('create', {
        title: 'resources.requests.list.actions.create',
        isVisible: true,
        handler: () =>
          this.modalService
            .open(ResourceRequestModalComponent, { size: 'xl' })
            .result.then(
              (entity: Entity) => {
                this.stateService.go('resourceRequest', {
                  entityId: entity.id,
                });
              },
              () => null,
            ),
      }),
      this.actionPanelService.getDefaultAction('card', {
        handler: () =>
          this.state.go('resourceRequest', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actionPanelService.getDefaultAction('delete', {
        handler: this.entityListService.delete,
      }),
    ]);

    this.gridService.selectedGroups$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((rows: any) => {
        this.actionPanelService.action('card').isShown = rows?.length === 1;
      });
  }
}
