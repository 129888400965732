import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { GeneralPnlComponent } from './general-pnl.component';
import { PnlModule } from 'src/app/shared-features/pnl/pnl.module';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';

@NgModule({
  declarations: [GeneralPnlComponent],

  imports: [
    CommonModule,
    SharedModule,
    PnlModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'pnl',
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            navigation: 'analytics.pnl',
            routeMode: null,
          },
          url: '/pnl?{navigation}&{route}',
          component: GeneralPnlComponent,
        },
      ],
    }),
  ],
})
export class GeneralPnlModule {}
