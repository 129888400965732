import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NotificationService } from 'src/app/core/notification.service';

import { State } from 'src/app/shared/models/entities/state.model';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';

import { BoardDataService } from 'src/app/boards/services/board-data.service';
import { BoardColumnView } from 'src/app/boards/models/board.interface';
import { BoardService } from 'src/app/boards/services/board.service';

@Component({
  selector: 'tmt-board-column-header-form',
  templateUrl: './board-column-header-form.component.html',
  styleUrl: './board-column-header-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardColumnHeaderFormComponent implements OnInit {
  @Input() public column: BoardColumnView;
  @Input() public mode: 'edit' | 'create';

  public saving: boolean;
  public states: State[] = [];
  public form: UntypedFormGroup = this.fb.group({
    state: [null, Validators.required],
    header: [null, Validators.required],
  });

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private boardService: BoardService,
    private fb: UntypedFormBuilder,
    private infoPopupService: InfoPopupService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    boardDataService: BoardDataService,
  ) {
    this.states = boardDataService.states.filter(
      (state) =>
        !boardService.columns.some((column) => column.stateId === state.id),
    );
  }

  public ngOnInit(): void {
    if (this.column) {
      this.form.patchValue({
        state: this.column.state,
        header: this.column.header,
      });
    }

    this.form.controls.state.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state: State) => {
        this.form.patchValue(
          {
            header: state.name,
          },
          {
            emitEvent: false,
          },
        );
      });
  }

  /** Saves board column. */
  public async save(): Promise<void> {
    this.form.markAsDirty();
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.saving = true;

    const data: Partial<BoardColumnView> = {
      state: this.form.getRawValue().state,
      header: this.form.getRawValue().header?.trim(),
    };

    const result =
      this.mode === 'create'
        ? await this.boardService.addColumn(data)
        : await this.boardService.updateColumn(this.column.id, data);

    this.saving = false;
    this.cdr.markForCheck();

    if (result) {
      this.infoPopupService.close();
    }
  }

  /** Closes popup */
  public cancel(): void {
    this.infoPopupService.close();
  }
}
