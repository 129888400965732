@if (!projectRows.length && !bookingService.isResourceLineHidden) {
  <tr>
    <td colspan="2" class="line no-data text-body-secondary"></td>
  </tr>
}

@for (project of projectRows; track project) {
  <tr>
    <td
      [attr.colspan]="2"
      class="line"
      [class.line--small]="bookingService.isResourceLineHidden"
      [class.line--history]="resourceRequestShown"
    >
      <div class="project-row" [class.project-row--single]="bookingService.isResourceLineHidden">
        @if (!bookingService.isResourceLineHidden) {
          @if (resourceRequestShown) {
            <i
              (click)="bookingService.toggleRow(resource.id, project.id, !project.isExpanded)"
              class="project-row__toggle bi"
              [class.project-row__toggle--hidden]="!project.requestBookings.length"
              [class.bi-chevron-right]="!project.isExpanded"
              [class.bi-chevron-down]="project.isExpanded"
            ></i>
          }

          @if (project.id && bookingDataService.resourceRequest?.project?.id === project.id) {
            <i class="bi bi-star-fill" aria-hidden="true"></i>
          }

          @if (project.id) {
            <a
              class="project-row__name trim"
              [id]="'project' + project.id"
              (click)="openProjectInfo(project.id)"
            >
              {{ project.name }}
            </a>
          } @else {
            <span>{{ project.name }}</span>
          }
        } @else {
          <img class="avatar-bg" [src]="resource.id | avatar: 60" />
          <div
            [id]="'user' + resource.id"
            class="wp-action text trim"
            title="{{ resource.name }}"
            (click)="openUserInfo(resource.id)"
          >
            <span
              id="{{ resource.id }}-name"
              class="user-name"
              [class.disabled]="!resource.isActive"
            >
              {{ resource.name }}
            </span>
          </div>
        }

        @if (
          project.id && project.bookingEntryEditAllowed && !bookingDataService.isReadonlyMode()
        ) {
          <div class="project-row__button" ngbDropdown placement="bottom-right" container="body">
            <i class="bi bi-three-dots without-caret" ngbDropdownToggle></i>
            <div ngbDropdownMenu>
              @for (action of lineActions; track $index) {
                @if (action.isVisible) {
                  <button
                    ngbDropdownItem
                    title="{{ action.title | translate }}"
                    (click)="action.handler(project)"
                    [attr.data-test]="action.name"
                  >
                    {{ action.title | translate }}
                  </button>
                }
              }
            </div>
          </div>
        }
      </div>
    </td>

    @if (bookingDataService.resourceRequestMode === 'assistant') {
      <td class="total-cell">
        {{ totalHours | plannerFormat }}
      </td>
    }
  </tr>

  @if (project.requestBookings.length && project.isExpanded) {
    @for (booking of project.requestBookings; track $index) {
      <tr>
        <td [attr.colspan]="2" class="line line--nested">
          <div class="project-row">
            <a
              class="project-row__name"
              uiSref="resourceRequest"
              [uiParams]="{
                entityId: booking.resourceRequest.id,
                routeMode: stateParams.routeMode,
                navigation: stateParams.navigation
              }"
            >
              {{ booking.resourceRequest.created | date: 'shortDate' }}
              @if (booking.resourceRequest.requestedHours) {
                <span>
                  ({{ booking.resourceRequest.requestedHours | plannerFormat }}
                  {{ 'shared.unitHour' | translate }})
                </span>
              }
            </a>

            <tmt-state-badge
              [state]="booking.resourceRequest.state"
              (click)="openResourceRequest(booking.resourceRequest.id)"
              class="state-badge"
            />
          </div>
        </td>
      </tr>
    }
  }
}
