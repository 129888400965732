<tmt-form-header [name]="issueResolutionCardService.name$ | async" />
<tmt-not-found [state]="issueResolutionCardService.state$ | async" />
<wp-loading-indicator [state]="issueResolutionCardService.state$ | async" />
<wp-data-not-saved-indicator [form]="issueResolutionCardService.form" />

@if ((issueResolutionCardService.state$ | async) == 'Ready') {
  <form [formGroup]="issueResolutionCardService.form" class="form mt-3">
    <div class="form-group" tmtIndicator>
      <label>{{ 'shared.props.name' | translate }}</label>
      <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}" />
    </div>

    <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
      <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
      <label class="form-check-label" for="isActive">
        {{ 'shared.props.isActive.label' | translate }}
      </label>
    </div>
    @if (!readonly) {
      <div class="actions">
        <button
          type="button"
          class="btn btn-primary"
          wpButtonSpinner
          [isBusy]="issueResolutionCardService.isSaving$ | async"
          (click)="issueResolutionCardService.save()"
        >
          {{ 'shared.actions.save' | translate }}
        </button>
      </div>
    }
  </form>
}
