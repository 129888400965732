import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { Toolbar } from 'src/app/shared/components/features/grid/toolbar';
import { NotificationService } from 'src/app/core/notification.service';
import { LicenseProductInfo } from 'src/app/shared/models/entities/settings/license-info.model';
import { UserProductsService } from 'src/app/settings-app/users/card/user-products/user-products.service';
import { LicenseProduct } from 'src/app/shared/models/session/client-session.model';
import { orderBy } from 'lodash';

@Component({
  selector: 'tmt-user-products-toolbar',
  templateUrl: './user-products-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProductsToolbarComponent extends Toolbar {
  public products: LicenseProductInfo[] = [];

  constructor(
    public gridService: GridService,
    public service: UserProductsService,
    public notification: NotificationService,
  ) {
    super();
  }

  /** Event on open/close state for product selector.
   *
   * @param $event new state.   *
   */
  public openChange($event: boolean) {
    if (!$event) {
      return;
    }

    this.products = orderBy(
      this.service.licenseInfo.filter(
        (x) =>
          x.limit - x.used > 0 &&
          (this.service.products.value as { product: LicenseProduct }[]).every(
            (up) => up.product !== x.product,
          ),
      ),
      (x) => x.displayName,
    );
  }

  /** Selects product.
   *
   * @param productInfo selected license product.   *
   */
  selectProduct(productInfo: LicenseProductInfo) {
    this.gridService.execute('add', productInfo);
  }
}
