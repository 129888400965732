<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.imports.creation.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <h4>{{ 'settings.imports.creation.steps.downloadTemplate.header' | translate }}</h4>
    <a href="/assets/files/import-template.xlsx">import-template.xlsx</a>

    <h4 class="pt-3">{{ 'settings.imports.creation.steps.fillOut.header' | translate }}</h4>
    <p class="m-0">{{ 'settings.imports.creation.steps.fillOut.help' | translate }}</p>
    <button class="btn btn-link p-0 tmt-help-action" tmtDocsArticle="settings/system/data-import">
      {{ 'settings.imports.creation.steps.fillOut.instruction' | translate }}
      <i class="bi bi-question-circle" aria-hidden="true"></i>
    </button>

    <h4 class="pt-3">{{ 'settings.imports.creation.steps.selectFile' | translate }}</h4>
    <ngx-dropzone
      #drop
      [accept]="'.xlsx'"
      [multiple]="false"
      maxFileSize="262144000"
      (change)="onSelect($event)"
    >
      <ngx-dropzone-label style="margin-left: 10px">
        <button class="btn btn-default">
          {{ 'shared.actions.select' | translate }}
        </button>
        <span> {{ 'shared.orDragFileHere' | translate }}</span>
      </ngx-dropzone-label>
      <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
      </ngx-dropzone-preview>
    </ngx-dropzone>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="files.length === 1"
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'settings.imports.creation.actions.import' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
