import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectResourcesForecastCalendarComponent } from './project-resources-forecast-calendar.component';
import { ProjectResourcesForecastCalendarRightGroupComponent } from './shared/project-resources-forecast-calendar-right-group/project-resources-forecast-calendar-right-group.component';
import { ProjectResourcesForecastCalendarLeftGroupComponent } from './shared/project-resources-forecast-calendar-left-group/project-resources-forecast-calendar-left-group.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ScheduleNavigationModule } from 'src/app/shared-features/schedule-navigation/schedule-navigation.module';
import { PlannerModule } from 'src/app/shared-features/planner/planner.module';
import { UIRouterModule } from '@uirouter/angular';
import { ProjectResourcesSharedModule } from '../shared/project-resources-shared.module';
import { UndoRedoButtonsComponent } from 'src/app/shared/services/undo-redo/undo-redo-buttons/undo-redo-buttons.component';

@NgModule({
  declarations: [
    ProjectResourcesForecastCalendarComponent,
    ProjectResourcesForecastCalendarRightGroupComponent,
    ProjectResourcesForecastCalendarLeftGroupComponent,
  ],
  exports: [ProjectResourcesForecastCalendarComponent],
  imports: [
    CommonModule,
    SharedModule,
    ScheduleNavigationModule,
    PlannerModule,
    ProjectResourcesSharedModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.resources.forecastCalendar',
          url: '/forecast-calendar',
          component: ProjectResourcesForecastCalendarComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
    UndoRedoButtonsComponent,
  ],
})
export class ProjectResourcesForecastCalendarModule {}
