import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class IssueCardSettings implements BaseSettings {
  public readonly name = 'IssueCardSettings';
  public version: number;
  public parametersCollapsed: boolean;

  public getDefault(): IssueCardSettings {
    return {
      version: 1,
      parametersCollapsed: false,
    } as IssueCardSettings;
  }
}
