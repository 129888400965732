<div class="toolbar-container">
  @if (!service.readonly) {
    <div class="group d-flex flex-shrink-0">
      <button
        (click)="gridService.execute('create')"
        type="button"
        class="btn btn-default"
        title="{{ 'settings.rateMatrices.actions.create' | translate }}"
        [disabled]="!gridService.canBeExecuted('create')"
      >
        <span class="bi bi-plus-lg bi-15"></span>&nbsp;{{ 'shared.actions.create' | translate }}
      </button>

      <button
        (click)="gridService.execute('edit', gridService.selectedGroup$.getValue())"
        type="button"
        class="btn btn-default"
        title="{{ 'settings.rateMatrices.actions.edit' | translate }}"
        [disabled]="!gridService.canBeExecuted('edit', gridService.selectedGroup$.getValue())"
      >
        <span class="bi bi-pencil"></span>&nbsp;{{ 'shared.actions.edit' | translate }}
      </button>

      <button
        type="button"
        class="btn btn-default"
        title="{{ 'settings.rateMatrices.actions.delete' | translate }}"
        [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroup$.getValue())"
        (click)="
          gridService.execute('delete', gridService.selectedGroup$.getValue()?.getRawValue()?.id)
        "
      >
        <span class="bi bi-trash3" aria-hidden="true"></span>
      </button>
    </div>
  }
  <div class="group">
    <wp-filter />
  </div>
</div>
