<div class="competences-wrap trim" [title]="title">
  @for (competence of competences; track competence.id) {
    <span>
      @if (!$last) {
        {{ competence.name }};
      } @else {
        {{ competence.name }}
      }
    </span>
  }
</div>
