import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Observable } from 'rxjs';
import { ProjectTeamService } from 'src/app/projects/card/project-team/project-team.service';

@Component({
  selector: 'tmt-roles-cell',
  templateUrl: './roles-cell.component.html',
  styleUrls: ['./roles-cell.component.scss'],
})
export class RolesCellComponent implements GridTemplateCell, OnInit {
  @Input() public column: GridColumn;
  @Input() public formGroup: UntypedFormGroup;

  public roles$: Observable<NamedEntity[]>;
  public readonly = false;

  protected readonly ResourceType = ResourceType;

  public get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  constructor(private projectTeamService: ProjectTeamService) {}

  ngOnInit(): void {
    if (!this.formGroup.value?.resource?.id) {
      this.readonly = true;
      return;
    }
    if (this.formGroup.value.resource.resourceType === ResourceType.user) {
      this.roles$ = this.projectTeamService.getTeamMemberRolesObservable(
        this.formGroup.value.resource.id,
      );
    }
  }
}
