import { Component } from '@angular/core';
import {
  TreeItemPlane,
  TreeListComponent,
} from 'src/app/shared/components/features/tree-list';

import { TREE_LIST_TEST } from './tree-list.mock';

@Component({
  selector: 'tmt-tree-list-test',
  template: `<tmt-tree-list
    [itemsPlane]="list"
    [parentIdKey]="'leadDepartmentId'"
  />`,
  imports: [TreeListComponent],
  standalone: true,
})
export class TreeListTestComponent {
  public list: TreeItemPlane[] = TREE_LIST_TEST;
}
