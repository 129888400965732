import { Component, Input } from '@angular/core';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import {
  GridColumnType,
  GridSelectControlColumn,
  GridComponentColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { RoleCellComponent } from './role-cell.component';
import { DashboardSettingsService } from 'src/app/analytics/dashboards/settings/dashboard-settings.service';

@Component({
  selector: 'wp-dashboard-access',
  templateUrl: './dashboard-access.component.html',
})
export class DashboardAccessComponent {
  @Input() formArray: UntypedFormArray;
  @Input() readonly: boolean;

  gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.formArray.removeAt(index);
          this.formArray.markAsDirty();
        },
      },
    ],
    view: {
      name: 'dashboardAccess',
      columns: [
        <GridComponentColumn>{
          name: 'roleName',
          header: 'analytics.dashboards.settings.columns.role.title',
          hint: 'analytics.dashboards.settings.columns.role.title',
          type: GridColumnType.Component,
          component: RoleCellComponent,
          width: '40%',
        },
        <GridSelectControlColumn>{
          name: 'permissionSet',
          header: 'analytics.dashboards.settings.columns.permissionSet.title',
          hint: 'analytics.dashboards.settings.columns.permissionSet.title',
          type: GridColumnType.SelectControl,
          width: '60%',
          required: true,
          placeholder:
            'analytics.dashboards.settings.columns.permissionSet.title',
          query: { select: ['id', 'name', 'roleName'] },
          collection: 'PermissionSets',
        },
      ],
    },
  };

  constructor(
    private gridService: GridService,
    private dashboardSettingsService: DashboardSettingsService,
  ) {}

  /** Adds new grant group in the grid. */
  public addGrant(): void {
    const group = this.dashboardSettingsService.getGrantFormGroup();
    this.formArray.push(group);
    this.gridService.selectGroup(group);
  }
}
