import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Command, GridOptions } from './grid-options.model';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import {
  GridColumn,
  GridColumnType,
} from 'src/app/shared/models/inner/grid-column.interface';
import { ToolbarHostDirective } from './toolbar-host.directive';
import { Toolbar } from './toolbar';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import { LogService } from 'src/app/core/log.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { ListService } from 'src/app/shared/services/list.service';
import { customPopperOptions } from '../../../helpers/modern-grid.helper';
import { GridCellsOrchestratorService } from 'src/app/shared/components/features/grid/core/grid-cells-orchestrator.service';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { TextToControlValueParserService } from 'src/app/shared/components/features/grid/core/text-to-control-value-parser.service';
import { CellSwitchSetting } from 'src/app/shared/components/features/grid/grid-cells-orchestrator.interface';
import { ColumnDefaultValueService } from 'src/app/shared/components/features/grid/core/column-default-value.service';
import { CustomActionsService } from 'src/app/shared/components/features/grid/core/custom-actions.service';
import { MenuService } from 'src/app/core/menu.service';

/**
 * The component is designed to display and interact with the grid.
 * Used in entity root lists and grids in entity cards.
 * Requires a GridService used for interaction in the DI context.
 */
@Component({
  selector: 'wp-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FreezeTableService,
    GridCellsOrchestratorService,
    TextToControlValueParserService,
    ColumnDefaultValueService,
    CustomActionsService,
  ],
})
export class GridComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Grid options. */
  @Input() options: GridOptions;

  /** Displayed data. */
  @Input() formArray: UntypedFormArray;

  /** Displayed totals. */
  @Input() totals?: Dictionary<number> = null;

  /** Parameter for setting the data waiting state. */
  @Input() set loading(value: boolean) {
    this.service.setLoadingState(value);
  }

  /** The grid accessibility parameter is read-only. */
  @Input() set readonly(value: boolean) {
    this.service.setReadonlyState(value);
  }

  get readonly(): boolean {
    return this.service.readonly;
  }

  @ViewChild(ToolbarHostDirective, { static: true })
  toolbarHost: ToolbarHostDirective;

  @ViewChild('mainContainer') mainContainer: ElementRef;

  private toolbarRef: ComponentRef<Toolbar>;
  public get toolbar(): Toolbar {
    return this.toolbarRef.instance;
  }
  /** Selected row ids. */
  public selectedRowIds: string[] = [];
  public rowsWithCommands: Dictionary<boolean> = {};
  public selectAllControl = new FormControl(false);
  public popperOptions = customPopperOptions;

  /**
   * ngFor Tracking by id.
   *
   * @param index Index.
   * @param item Item.
   */
  public trackRow = (index: number, item: any) => item.value['id'];

  /**
   *  ngFor Tracking by name.
   *
   * @param index Index.
   * @param item Item.
   */
  public trackColumn = (index: number, item: any) => item['name'];

  private checkboxQuerySelector = '.wp-checkbox';
  private valueListener: Subscription;
  private changesListener: Subscription;
  private selectRowsListener: Subscription;
  private destroyed$ = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    public service: GridService,
    private hotkeysService: HotkeysService,
    private ref: ChangeDetectorRef,
    private log: LogService,
    private host: ElementRef,
    private freezeTableService: FreezeTableService,
    public cellOrchestratorService: GridCellsOrchestratorService,
    private menuService: MenuService,
    @Optional() private listService: ListService,
    private customActionsService: CustomActionsService,
  ) {}

  ngOnInit() {
    if (!this.options.dblClickEdit) {
      this.hotkeyServiceSubscribes();
    } else {
      this.cellOrchestratorService.switchCell$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((switchSettings) => {
          this.switchCell(switchSettings);
        });
      if (this.options.commands) {
        this.customActionsService.init(this.options.commands);
      }
    }
    this.service.commands = this.options.commands;
    this.service.multiSelect = this.options.multiSelect;
    this.cellOrchestratorService.dblClickEdit = this.options.dblClickEdit;
    this.cellOrchestratorService.formArray = this.formArray;
    this.cellOrchestratorService.baseColumns = this.options.view.columns;

    this.changesListener = this.service.detectChanges$.subscribe(() => {
      this.ref.detectChanges();
    });

    this.selectRowsListener = this.service.selectedRows$.subscribe(() => {
      this.updateSelectedState();
      if (
        this.service.multiSelect &&
        this.service.selectedGroups?.length === 0 &&
        this.selectAllControl.value
      ) {
        this.selectAllControl.setValue(false, { emitEvent: false });
      }
    });

    this.selectAllControl.valueChanges.subscribe(() => {
      if (this.selectAllControl.value) {
        this.service.setSelectedAll(true);
        this.service.addGroupsToSelected(
          this.formArray.controls as UntypedFormGroup[],
        );
      } else {
        this.service.setSelectedAll(false);
        this.service.clearSelected();
      }
    });

    if (this.formArray) {
      this.valueListener = this.formArray.valueChanges
        .pipe(debounceTime(10))
        .subscribe(() => {
          // Убрать из выделенных отсутствующие в наборе данных.
          this.service.selectedGroups.forEach((selectedGroup) => {
            if (
              this.formArray.controls.every(
                (group: UntypedFormGroup) =>
                  group.value.id !== selectedGroup.value.id,
              )
            ) {
              this.service.removeGroupFromSelected(selectedGroup);
              if (this.options.dblClickEdit) {
                this.cellOrchestratorService.setActiveControl(null);
              }
            }
          });

          // Если режим "выбрано все", то добавить в выбранные все отсутствующие.
          if (this.selectAllControl.value) {
            this.formArray.controls.forEach((group: UntypedFormGroup) => {
              if (
                !this.service.selectedRows.find(
                  (sr) => sr.id === group.value.id,
                )
              ) {
                this.service.addGroupToSelected(group);
              }
            });
          }

          if (this.options.clientTotals) {
            this.calculateTotals();
          }

          this.updateSelectedState();

          if (this.options.dblClickEdit) {
            this.cellOrchestratorService.updateSelectionCoordinates();
          }

          this.log.debug('Grid: data was changed.');
          this.ref.detectChanges();
        });
    }

    if (this.options.clientTotals) {
      this.calculateTotals();
      this.ref.detectChanges();
    }

    this.createToolbar();

    this.freezeTableService.scroll$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.menuService.close();
      });
  }

  ngAfterViewInit(): void {
    this.cellOrchestratorService.tableMainElement =
      this.mainContainer.nativeElement;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();

    if (this.toolbarRef) {
      this.toolbarRef.destroy();
      this.toolbarRef = null;
    }

    this.valueListener?.unsubscribe();
    this.changesListener?.unsubscribe();
    this.selectRowsListener?.unsubscribe();

    // For case when component will destroy without destroying service - clear selection.
    this.service.selectGroup(null);
  }

  /**
   * Close dropdown.
   *
   * @param dropdown Dropdown.
   */
  public closeDropdown(dropdown: NgbDropdown) {
    dropdown.close();
  }

  /**
   * Check available row commands.
   *
   * @param formGroup Data.
   * @param i Index.
   */
  public rowHasCommands(formGroup: UntypedFormGroup, i: number) {
    if (this.options.rowCommands) {
      return !this.options.rowCommands.every(
        (command: Command) =>
          command.allowedFn && !command.allowedFn(formGroup, i),
      );
    }
    return false;
  }

  /**
   * Returns the state of being able to edit a grid cell.
   *
   * @param column Grid column.
   * @param formGroup Data.
   */
  public isEditing(column: GridColumn, formGroup: UntypedFormGroup) {
    if (this.options.dblClickEdit) {
      if (formGroup.controls[column.name].disabled) {
        return false;
      }
      return (
        formGroup.controls[column.name] ===
        this.cellOrchestratorService.getEditingControl()
      );
    }

    if (this.readonly || this.options.editing === 'none') {
      return false;
    }
    if (this.options.editing === 'always') {
      return true;
    }
    return formGroup.value.id === this.selectedRowIds[0];
  }

  /**
   *
   * @param id Row id.
   */
  public isRowSelected = (id: string) => this.selectedRowIds.includes(id);

  /**
   * Open menu.
   *
   * @param id Row id.
   * @param dropdown Dropdown.
   */
  public openMenu(id: string, dropdown: NgbDropdown) {
    this.rowsWithCommands[id] = true;
    this.ref.detectChanges();
    dropdown.close();
    dropdown.open();
  }

  /**
   * Returns the state of content alignment in cells.
   *
   * @param column Grid column.
   */
  public isRightAlign(column: GridColumn): boolean {
    const types = [
      GridColumnType.Currency,
      GridColumnType.Decimal,
      GridColumnType.Integer,
      GridColumnType.Percent,
      GridColumnType.NumberControl,
      GridColumnType.Work,
    ];
    return column.contentType
      ? types.includes(column.contentType)
      : types.includes(column.type);
  }

  /** Returns columns count. */
  public getColumnsCount(): number {
    if (!this.options) {
      return 1;
    }

    let count = this.options.view.columns.length;
    if (this.options.rowCommands) {
      count++;
    }

    if (this.options.multiSelect) {
      count++;
    }

    return count;
  }

  /**
   * Select row.
   *
   * @param formGroup Data.
   * @param index Index.
   * @param $event Event.
   */
  public selectRow = (
    formGroup: UntypedFormGroup,
    index: number,
    $event: any,
  ) => {
    if ($event.target.className === 'dropdown-item') {
      return;
    }
    this.setSelectedAll(false);
    this.service.selectGroup(formGroup);
  };

  /**
   * Select row(cell in dblClickMode) and activate cell(in dblClickMode).
   *
   * @param formGroup Data.
   * @param i Index.
   * @param event Event.
   * @param column Grid column.
   */
  public onCellMouseDown(
    formGroup: UntypedFormGroup,
    i: number,
    event: MouseEvent,
    column?: GridColumn,
  ) {
    if (!this.options.dblClickEdit) {
      return;
    }

    // Reset active control if clicked on row bth
    const control = column ? formGroup.controls[column.name] : undefined;
    if (control === this.cellOrchestratorService.getEditingControl()) {
      return;
    }

    this.selectRow(formGroup, i, event);
    this.cellOrchestratorService.setActiveControl(control);
    this.cellOrchestratorService.setEditingControl(null);
  }

  /**
   * Select row if dblClick is off.
   *
   * @deprecated
   * @param formGroup Data.
   * @param i Index.
   * @param event Event.
   */
  public onCellClick(formGroup: UntypedFormGroup, i: number, event: any) {
    if (this.options.dblClickEdit) {
      return;
    }
    this.selectRow(formGroup, i, event);
  }

  /**
   * Select row, cell and open context menu if possible.
   *
   * @param formGroup Data.
   * @param i Index.
   * @param event Event.
   * @param column Grid column.
   */
  public onCellRightBtnClick(
    formGroup: UntypedFormGroup,
    i: number,
    event: any,
    column?: GridColumn,
  ) {
    if (!this.isEditing(column, formGroup) && this.options.rowContextMenu) {
      this.onCellMouseDown(formGroup, i, event, column);
      this.service.openContextMenu(
        event,
        formGroup,
        this.options.rowContextMenu,
      );
    }
  }

  /** Enables editing mode for clicked cell if possible.
   *
   * @param column column of clicked control.
   * @param formGroup formGroup of clicked control.
   */
  public setEditingControl(column: GridColumn, formGroup: UntypedFormGroup) {
    if (!this.options.dblClickEdit) {
      return;
    }
    const control = formGroup.controls[column.name];
    if (this.service.readonly || control.disabled) {
      return;
    }
    this.cellOrchestratorService.setEditingControl(control);
  }

  public clickRowSelector = (formGroup: UntypedFormGroup, event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setSelectedAll(false);
    this.service.toggleSelectedGroup(formGroup);
  };

  /**
   * Perform sorting by column.
   *
   * @param column Grid column.
   */
  public sort(column: GridColumn) {
    if (!this.options.sorting) {
      return;
    }
    this.service.sort(column);
  }

  /** On table outside click event */
  public onTableOutsideClick() {
    this.cellOrchestratorService.setEditingControl(null);
  }

  /**
   * Column resizing handler.
   *
   * @param param ColumnName + ColumnWidth.
   */
  public onColumnResized(param: [string, number]) {
    if (!this.options.modern) {
      return;
    }

    const columnName = param[0];
    const column = this.options.view.columns.find((x) => x.name === columnName);
    column.width = param[1].toString() + 'px';

    // Save changes.
    this.listService?.setColumnWidth(columnName, param[1]);

    this.freezeTableService.redraw();
    this.ref.detectChanges();
  }

  /**
   * Select next row.
   *
   * @private
   */
  private selectNextRow() {
    this.setSelectedAll(false);
    const rows = this.formArray.controls;
    if (this.service.selectedRows.length !== 1) {
      if (rows.length > 0) {
        this.service.selectGroup(
          this.formArray.controls[0] as UntypedFormGroup,
        );
      }
    } else {
      const group = this.getAdjacentRow(this.service.selectedGroup, 'down');
      if (group !== this.service.selectedRow) {
        this.service.selectGroup(group);
      }
    }
  }

  /**
   * Select previous row.
   *
   * @private
   */
  private selectPreviousRow() {
    this.setSelectedAll(false);
    const rows = this.formArray.controls;
    if (this.service.selectedRows.length !== 1) {
      if (rows.length > 0) {
        this.service.selectGroup(
          this.formArray.controls[0] as UntypedFormGroup,
        );
      }
    } else {
      const group = this.getAdjacentRow(this.service.selectedGroup, 'up');
      if (group !== this.service.selectedRow) {
        this.service.selectGroup(group);
      }
    }
  }

  /**Returns adjacent row.
   *
   * @param group current selected row
   * @param direction direction of switching
   * @param toEnd is return maximum possible row in target direction
   * @returns
   */
  private getAdjacentRow(
    group: UntypedFormGroup,
    direction: 'up' | 'down',
    toEnd?: boolean,
  ): UntypedFormGroup {
    const rows = this.formArray.controls;
    if (toEnd) {
      return direction === 'down'
        ? (rows[rows.length - 1] as UntypedFormGroup)
        : (rows[0] as UntypedFormGroup);
    }

    const index = rows.findIndex((row) => row.value.id === group.value.id);
    if (direction === 'up' && index > 0) {
      return rows[index - 1] as UntypedFormGroup;
    }
    if (direction === 'down' && index < rows.length - 1) {
      return rows[index + 1] as UntypedFormGroup;
    }
    return group;
  }

  /**
   * Calculate grid totals (local totals mode).
   */
  private calculateTotals() {
    if (!this.formArray || this.formArray.length === 0) {
      this.totals = null;
      return;
    }

    this.totals = this.options.view.columns.every((c) => !c.total) ? null : {};
    this.options.view.columns.forEach((column) => {
      if (column.total === TotalType.Count) {
        this.totals[column.name] = this.formArray.length;
      }

      if (column.total === TotalType.Sum) {
        this.totals[column.name] = (this.formArray.value as any[]).reduce(
          (acc, line) => (acc += line[column.name] ?? 0),
          0,
        );
      }
    });
  }

  /** Subscribes to default key listeners. */
  private hotkeyServiceSubscribes() {
    this.hotkeysService.add(
      new Hotkey('up', (event: KeyboardEvent): boolean => {
        if (this.isActiveElementTheGridRow(event.target)) {
          this.selectPreviousRow();
          return false;
        }
      }),
    );

    this.hotkeysService.add(
      new Hotkey('down', (event: KeyboardEvent): boolean => {
        if (this.isActiveElementTheGridRow(event.target)) {
          this.selectNextRow();
          return false;
        }
      }),
    );

    this.hotkeysService.add(
      new Hotkey('enter', (event: KeyboardEvent): boolean => {
        if (this.isActiveElementTheGridRow(event.target)) {
          this.service.execute('kbEnter');
          return false;
        }
      }),
    );

    this.hotkeysService.add(
      new Hotkey('del', (event: KeyboardEvent): boolean => {
        if (this.isActiveElementTheGridRow(event.target)) {
          this.service.execute('kbDelete');
          return false;
        }
      }),
    );

    this.hotkeysService.add(
      new Hotkey('ins', (event: KeyboardEvent): boolean => {
        if (this.isActiveElementTheGridRow(event.target)) {
          this.service.execute('kbInsert');
          return false;
        }
      }),
    );
  }

  /**
   * Turn off "All" selection sign (without event) and trigger service.
   *
   * @param state State.
   * @private
   */
  private setSelectedAll(state: boolean) {
    this.selectAllControl.setValue(state, { emitEvent: false });
    this.service.setSelectedAll(state);
  }

  /**
   * Switch cell.
   *
   * @param switchSetting Setting of cell switching.
   * @private
   */
  private switchCell(switchSetting: CellSwitchSetting) {
    if (this.options.dblClickEdit) {
      let group: UntypedFormGroup;
      if (switchSetting.cellType === 'active') {
        group = this.cellOrchestratorService.getActiveControl()
          .parent as UntypedFormGroup;
      } else {
        group = this.cellOrchestratorService.getNodalSelectedControl()
          .parent as UntypedFormGroup;
      }

      switch (switchSetting.direction) {
        case 'down': {
          const nextRow = this.getAdjacentRow(
            group,
            'down',
            switchSetting.toEnd,
          );
          this.switchAdjacentCell(nextRow, switchSetting);
          this.service.selectGroup(nextRow);
          break;
        }
        case 'up': {
          const previousRow = this.getAdjacentRow(
            group,
            'up',
            switchSetting.toEnd,
          );
          this.switchAdjacentCell(previousRow, switchSetting);
          this.service.selectGroup(previousRow);
          break;
        }
        case 'right':
          this.switchAdjacentCell(group, switchSetting);
          break;
        case 'left':
          this.switchAdjacentCell(group, switchSetting);
          break;
      }
    }
  }

  /**
   * Switch next/previous cell in the row.
   *
   * @param group Data.
   * @param switchSetting Cell switch settings.
   * @private
   */
  private switchAdjacentCell(
    group: UntypedFormGroup,
    switchSetting: CellSwitchSetting,
  ) {
    if (this.cellOrchestratorService.dblClickEdit) {
      let switchingControl;
      if (switchSetting.cellType === 'active') {
        switchingControl = this.cellOrchestratorService.getActiveControl();
      } else {
        switchingControl =
          this.cellOrchestratorService.getNodalSelectedControl();
      }

      const controls = Object.entries(switchingControl.parent.controls);

      // Change control to control in the new selected group
      if (
        switchSetting.direction === 'up' ||
        switchSetting.direction === 'down'
      ) {
        const control = controls.find((c) => c[1] === switchingControl);
        if (control) {
          const controlName = control[0];
          if (switchSetting.cellType === 'active') {
            this.cellOrchestratorService.setActiveControl(
              group.controls[controlName],
            );
          } else {
            this.cellOrchestratorService.setNodalSelectedControl(
              group.controls[controlName],
            );
          }
        }
        return;
      }

      const controlIndex = controls.findIndex((c) => c[1] === switchingControl);
      if (controlIndex) {
        const controlName = controls[controlIndex][0];
        const viewColumnNames = this.options.view.columns.map((c) => c.name);
        const viewColumnIndex = viewColumnNames.findIndex(
          (name) => name === controlName,
        );

        if (switchSetting.direction === 'left' && viewColumnIndex !== 0) {
          let nextControlName;
          if (switchSetting.toEnd) {
            nextControlName = viewColumnNames[0];
          } else {
            nextControlName = viewColumnNames[viewColumnIndex - 1];
          }
          if (switchSetting.cellType === 'active') {
            this.cellOrchestratorService.setActiveControl(
              group.controls[nextControlName],
            );
          } else {
            this.cellOrchestratorService.setNodalSelectedControl(
              group.controls[nextControlName],
            );
          }
          this.ref.detectChanges();
        }

        if (
          switchSetting.direction === 'right' &&
          viewColumnIndex < viewColumnNames.length - 1
        ) {
          let nextControlName;
          if (switchSetting.toEnd) {
            nextControlName = viewColumnNames[viewColumnNames.length - 1];
          } else {
            nextControlName = viewColumnNames[viewColumnIndex + 1];
          }
          if (switchSetting.cellType === 'active') {
            this.cellOrchestratorService.setActiveControl(
              group.controls[nextControlName],
            );
          } else {
            this.cellOrchestratorService.setNodalSelectedControl(
              group.controls[nextControlName],
            );
          }
          this.ref.detectChanges();
        }
      }
    }
  }

  /**
   *  Checks if focus is set on one of the grid lines.
   *
   * @param el Element.
   */
  private isActiveElementTheGridRow(el: any): boolean {
    return el.attributes['name'] && el.attributes['name'].value === 'grid-row';
  }

  /**
   *  Create toolbar.
   */
  private createToolbar() {
    if (this.options.toolbar) {
      const viewContainerRef = this.toolbarHost.viewContainerRef;
      viewContainerRef.clear();
      this.toolbarRef = viewContainerRef.createComponent(this.options.toolbar);
      this.service.toolbar$.next(this.toolbarRef.instance);
      this.ref.detectChanges();
    }
  }

  /** Update selectedRowIds and selected row styles. */
  private updateSelectedState() {
    const selectedRows = this.service.selectedRows;

    const getRowEl = (id: string) =>
      (this.host.nativeElement as HTMLElement).querySelector(
        `#${CSS.escape(id)}`,
      );

    if (!this.cellOrchestratorService.dblClickEdit) {
      // Убрать удаленные.
      this.selectedRowIds.forEach((id) => {
        if (!selectedRows.find((r) => r.id === id)) {
          const rowEl = getRowEl(id);
          if (rowEl) {
            this.renderer.removeClass(rowEl, 'selected');

            if (this.options.multiSelect) {
              const checkBoxEl = rowEl.querySelector(
                this.checkboxQuerySelector,
              );
              if (checkBoxEl) {
                this.renderer.removeClass(checkBoxEl, 'checked');
              }
            }
          }
        }
      });
    }

    this.selectedRowIds = selectedRows.map((r) => r.id);

    if (!this.cellOrchestratorService.dblClickEdit) {
      // Добавить новые.
      this.selectedRowIds.forEach((id) => {
        const rowEl = getRowEl(id);
        if (rowEl && !rowEl.classList.contains('selected')) {
          this.renderer.addClass(rowEl, 'selected');

          if (this.options.multiSelect) {
            const checkBoxEl = rowEl.querySelector(this.checkboxQuerySelector);
            if (checkBoxEl) {
              this.renderer.addClass(checkBoxEl, 'checked');
            }
          }
        }
      });
    }
    this.ref.detectChanges();
  }
}
