<div class="container-fluid px-0">
  @if (showCommands) {
    <div class="d-flex commands">
      <wp-filter />
      @if (entityFilter) {
        <div class="filter-context">
          {{ 'shared.filter.context' | translate }}: {{ entityFilter?.name }}
        </div>
      }
      @if ((gridService.selectedGroups$ | async).length > 1 && !gridService.isAllSelected) {
        <div class="ms-3">
          <div class="selected-count-line">
            {{ 'shared.groupEditing.selectedRecordsCount' | translate }}:&nbsp;{{
              (gridService.selectedGroups$ | async).length
            }}
          </div>
        </div>
      }
      @if (gridService.isAllSelected) {
        <div class="ms-3">
          <div class="selected-count-line">
            {{ 'shared.groupEditing.selectedRecordsCount' | translate }}:&nbsp;
            {{ 'shared.groupEditing.all' | translate }}
          </div>
        </div>
      }

      <div class="ms-auto">
        @if (hasViewSettings) {
          <button
            type="button"
            class="btn btn-default"
            (click)="setUserView()"
            title="{{ 'shared.actions.setView' | translate }}"
            data-test="viewSettings"
          >
            <i class="bi bi-gear" aria-hidden="true"></i>
          </button>
        }
      </div>
    </div>
  }

  <div class="row">
    <div class="col">
      <tmt-grid
        [options]="gridOptions"
        [formArray]="service.formArray"
        [totals]="service.totals$ | async"
      />
    </div>
  </div>
</div>
