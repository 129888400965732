import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { chain, cloneDeep, sumBy } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { forkJoin, merge, Observable, Subject, Subscription } from 'rxjs';
import { auditTime, takeUntil } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { FteScheduleService } from 'src/app/core/fte-schedule.service';
import { LogService } from 'src/app/core/log.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { BaseEntry } from 'src/app/shared-features/planner/planner-cell/base-entry.model';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import {
  Slot,
  SlotGroup,
} from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { DateHours } from 'src/app/shared/models/entities/date-hours.model';
import { BookingDetailEntry } from 'src/app/shared/models/entities/resources/booking-detail-entry.model';
import { BookingEntry } from 'src/app/shared/models/entities/resources/booking-entry.model';
import { PlanningMethod } from 'src/app/shared/models/enums/planning-method.enum';
import { Exception } from 'src/app/shared/models/exception';
import { CellsOrchestratorService } from 'src/app/shared/services/cell-orhestrator/cells-orchestrator.service';
import { BookingDataService } from '../../../core/booking-data.service';
import { BookingRenderingService } from '../../../core/booking-rendering.service';
import { BookingService } from '../../../core/booking.service';
import { BookingEntryFormService } from '../booking-entry-form.service';
import {
  ASSISTANT_TEAM_MEMBER_ID,
  BookingDetailDto,
} from 'src/app/booking/booking-assistant/models/booking-assistant-settings.model';

@Component({
  selector: 'wp-booking-entry-form-details',
  templateUrl: './booking-entry-form-details.component.html',
  styleUrls: ['./booking-entry-form-details.component.scss'],
  providers: [CellsOrchestratorService],
})
export class BookingEntryFormDetailsComponent implements OnInit, OnDestroy {
  @Input() booking: BookingEntry;
  @Input() form: UntypedFormGroup;
  @Input() mode: 'create' | 'edit';
  loadingSchedulesSubscription: Subscription;
  previewLoadingSubscriptions: Subscription;

  schedule: DateHours[] = [];
  fte: DateHours[];
  loading: boolean;

  private destroyed$ = new Subject<void>();

  get entries(): UntypedFormArray {
    return this.form.controls.entries as UntypedFormArray;
  }

  get planningMethod(): PlanningMethod {
    return this.form.controls.planningMethod.value?.id as PlanningMethod;
  }

  public slots: Slot[] = [];
  public slotGroups: SlotGroup[] = [];

  constructor(
    @Optional() @Inject('assistantRequestId') public resourceRequestId,
    @Optional() @Inject(ASSISTANT_TEAM_MEMBER_ID) public teamMemberId, // TODO check it after DEV-657
    private bookingEntryFormService: BookingEntryFormService,
    private log: LogService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    public bookingDataService: BookingDataService,
    public bookingRenderingService: BookingRenderingService,
    public bookingService: BookingService,
    private scheduleNavigationService: ScheduleNavigationService,
    private cellsOrchestrator: CellsOrchestratorService,
    private data: DataService,
    private fteScheduleService: FteScheduleService,
  ) {}

  public trackById = (index: number, row: any): string => row.id;
  public trackByValueId = (index: number, row: any): string => row.value.id;

  private updateForm() {
    const from = this.form.controls.from.value;
    const to = this.form.controls.to.value;

    if (!from || !to) {
      return;
    }

    const slotsInfo = this.scheduleNavigationService.getSlots(
      Interval.fromDateTimes(DateTime.fromISO(from), DateTime.fromISO(to)),
      this.bookingService.settings.planningScale,
    );
    this.slots = slotsInfo.slots;
    this.slotGroups = slotsInfo.groups;

    this.entries.clear({ emitEvent: false });

    for (const slot of this.slots) {
      const detailEntry = this.booking.detailEntries.find(
        (e) => e.date === slot.date.toISODate(),
      );

      const scheduleHours =
        this.schedule.find((e) => e.date === slot.date.toISODate())?.hours ?? 0;

      const fteHours =
        this.fte?.find((e) => e.date === slot.date.toISODate())?.hours ?? 0;

      let entry: BaseEntry;

      if (!detailEntry) {
        entry = {
          id: Guid.generate(),
          date: slot.date.toISODate(),
          hours: 0,
          scheduleHours,
          limitHours: 24,
          fteHours,
        } as BaseEntry;
      } else {
        entry = cloneDeep(detailEntry);
        entry.scheduleHours = scheduleHours;
        entry.fteHours = fteHours;
        entry.limitHours = 24;
      }

      const control = this.fb.control(entry);
      this.entries.push(control, { emitEvent: false });

      (slot as any)['dayOff'] = scheduleHours === 0;
    }
    this.cellsOrchestrator.reset();

    setTimeout(() => {
      const planningMethodId = this.form.controls.planningMethod.value?.id;

      if (
        planningMethodId === PlanningMethod.Manual &&
        !this.bookingDataService.isReadonlyMode() &&
        this.booking.editAllowed
      ) {
        this.entries.enable();
      } else {
        this.entries.disable();
      }
    });
  }

  public getDataTableWidth() {
    let width = this.bookingRenderingService.slotWidth;
    if (this.slots.length < 7) {
      width = 100;
    }

    if (this.slots.length < 4) {
      width = 150;
    }

    return this.slots.length * width;
  }

  private loadSchedules(): Promise<void> {
    return new Promise((resolver) => {
      const from = this.form.controls.from.value;
      const to = this.form.controls.to.value;

      if (!from || !to) {
        return;
      }

      const filterObject = <any>{
        from,
        to,
        scale: this.bookingService.settings.planningScale,
        resourceIds: [this.booking.resourceId],
      };

      const subs: Observable<any>[] = [
        this.data
          .collection('BookingEntries')
          .action('GetSchedules')
          .execute(filterObject),
      ];

      if (this.bookingService.settings.valueMode === ValueMode.FTE) {
        subs.push(
          this.fteScheduleService.getFteSchedule(
            this.bookingService.settings.planningScale,
            from,
            to,
          ),
        );
      }

      this.loadingSchedulesSubscription?.unsubscribe();
      this.loadingSchedulesSubscription = forkJoin(subs).subscribe(
        (data: any[]) => {
          this.schedule = (data[0] as any[]).map((e) => ({
            hours: e.duration,
            date: e.date,
          })) as DateHours[];

          if (this.bookingService.settings.valueMode === ValueMode.FTE) {
            this.fte = data[1] as DateHours[];
          }

          resolver();
        },
      );
    });
  }

  /** Обновить детальный раздел (превью). */
  private updatePreview(): Promise<void> {
    return new Promise((resolver) => {
      const from = this.form.controls.from.value;
      const to = this.form.controls.to.value;

      if (!from || !to) {
        resolver();
        return;
      }

      if (!this.form.controls.project.value) {
        this.loadSchedules().then(() => {
          this.updateForm();
          resolver();
        });

        return;
      }

      const data = {
        bookingEntry: {
          id: this.booking.id,
          from,
          to,
          projectId: this.form.controls.project.value.id,
          resourceId: this.booking.resourceId,
          requiredHours: this.form.controls.requiredHours.value,
          requiredSchedulePercent:
            this.form.controls.requiredSchedulePercent.value,
          planningMethod: this.planningMethod,
        },
        isUpdate: this.mode === 'edit',
        planningScale: this.bookingService.settings.planningScale,
      };

      this.previewLoadingSubscriptions?.unsubscribe();

      this.previewLoadingSubscriptions = this.data
        .collection('BookingEntries')
        .action('GetDetailEntriesPreview')
        .execute(data, this.bookingDataService.bookingQuery)
        .subscribe({
          next: (entry: BookingEntry) => {
            this.booking.detailEntries = entry.detailEntries;

            if (this.planningMethod === PlanningMethod.FrontLoad) {
              this.form.controls.to.patchValue(entry.to, {
                emitEvent: false,
              });

              this.loadSchedules().then(() => {
                this.updateForm();
                resolver();
              });
            } else {
              this.updateForm();
              resolver();
            }
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            resolver();
          },
        });
    });
  }

  private updateScheduleThenPreview() {
    this.loading = true;
    this.loadSchedules().then(() => {
      if (this.planningMethod === PlanningMethod.Manual) {
        this.updateForm();
        this.loading = false;
      } else {
        this.updatePreview().then(() => {
          this.loading = false;
        });
      }
    });
  }

  /** Вернуть заполнитель (потребность) для запроса ресурса. */
  private getFillOutForRequest() {
    if (this.resourceRequestId) {
      return forkJoin({
        bookingSummary: this.data
          .collection('ResourceRequests')
          .entity(this.resourceRequestId)
          .function('GetBookingSummary')
          .query<BookingDetailDto[]>({
            scale: `WP.PlanningScale'${this.bookingService.settings.planningScale}'`,
          }),

        requirementEntries: this.data
          .collection('ResourceRequests')
          .entity(this.resourceRequestId)
          .function('GetGroupedRequirementEntries')
          .query<DateHours[]>({
            scale: `WP.PlanningScale'${this.bookingService.settings.planningScale}'`,
          }),
      });
    }

    if (this.teamMemberId) {
      return forkJoin({
        bookingSummary: this.data
          .collection('ProjectTeamMembers')
          .entity(this.teamMemberId)
          .function('GetBookingSummary')
          .query<BookingDetailDto[]>({
            scale: `WP.PlanningScale'${this.bookingService.settings.planningScale}'`,
          }),

        requirementEntries: this.data
          .collection('ProjectTeamMembers')
          .entity(this.teamMemberId)
          .function('GetRequirementEntries')
          .query<DateHours[]>({
            scale: `WP.PlanningScale'${this.bookingService.settings.planningScale}'`,
          }),
      });
    }
  }

  ngOnInit(): void {
    this.bookingEntryFormService.updatePreview$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.loading = true;
        this.updatePreview().then(() => {
          this.loading = false;
        });
      });

    this.entries.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.log.debug('BookingDetailEntry updated.');
      this.booking.detailEntries = (
        this.entries.value as BookingDetailEntry[]
      ).map((e) => ({
        id: e.id,
        hours: e.hours,
        date: e.date,
      }));
    });

    merge(
      this.form.controls.to.valueChanges,
      this.form.controls.from.valueChanges,
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.updateScheduleThenPreview());

    merge(
      this.form.controls.planningMethod.valueChanges,
      this.form.controls.project.valueChanges,
      this.form.controls.requiredHours.valueChanges.pipe(auditTime(500)),
      this.form.controls.requiredSchedulePercent.valueChanges.pipe(
        auditTime(500),
      ),
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        // Включить/отключить ячейки на редактирование в любом случае сразу.
        this.updateForm();

        if (this.planningMethod !== PlanningMethod.Manual) {
          this.loading = true;
          this.updatePreview().then(() => {
            this.loading = false;
          });
        }
      });

    this.cellsOrchestrator.init();

    // Открыли на создание в контексте запроса ресурса.
    if (
      (this.resourceRequestId || this.teamMemberId) &&
      this.mode === 'create'
    ) {
      this.loading = true;
      this.getFillOutForRequest().subscribe((response) => {
        const allDates = response.requirementEntries.map((e) => e.date);

        const bookingEntries = chain(response.bookingSummary)
          .groupBy('date')
          .map((b, date) => ({
            date,
            hours: sumBy(b, 'hours'),
          }))
          .value();

        bookingEntries.forEach((e) => {
          if (allDates.every((d) => d !== e.date)) {
            allDates.push(e.date);
          }
        });

        this.booking.detailEntries = [];
        allDates.forEach((date) => {
          const requiredHours =
            response.requirementEntries.find((e) => e.date === date)?.hours ??
            0;
          const bookedHours =
            bookingEntries.find((e) => e.date === date)?.hours ?? 0;

          const hours = requiredHours - bookedHours;

          if (hours <= 0) {
            return;
          }

          this.booking.detailEntries.push({
            date,
            hours,
            id: Guid.generate(),
          });
        });

        // Сразу записать данные в форму, чтобы не сбросились.
        this.updateForm();

        // Всегда Manual, обновление Preview не требуется.
        this.loadSchedules().then(() => {
          this.updateForm();
          this.loading = false;
        });
      });

      return;
    }

    // Открыли на редактирование в календаре.
    if (this.mode === 'edit') {
      this.loading = true;
      this.data
        .collection('BookingEntries')
        .entity(this.booking.id)
        .function('GetDetails')
        .query<DateHours[]>({
          scale: `WP.PlanningScale'${this.bookingService.settings.planningScale}'`,
        })
        .subscribe((details) => {
          details.forEach((d) => {
            if (!this.booking.detailEntries.find((x) => x.date === d.date)) {
              this.booking.detailEntries.push({
                id: Guid.generate(),
                date: d.date,
                hours: d.hours,
              });
            }
          });
          this.loadSchedules().then(() => {
            this.updateForm();
            this.loading = false;
          });
        });

      return;
    }

    // Открыли на создание.
    this.updateScheduleThenPreview();
  }

  ngOnDestroy(): void {
    this.cellsOrchestrator.dispose();
    this.previewLoadingSubscriptions?.unsubscribe();
    this.loadingSchedulesSubscription?.unsubscribe();

    this.destroyed$.next();
  }
}
