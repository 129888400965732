@for (selectedKpiType of selectedKpiTypes; track selectedKpiType; let isFirst = $first) {
  <tr class="resource-summary__left resource__row">
    <ng-container *ngIf="isFirst">
      <td [attr.rowspan]="selectedKpiTypes.length" class="toggler-cell">
        <div class="d-flex justify-content-center">
          <span
            (click)="onToggleClick()"
            [ngClass]="{
              'bi-chevron-down': isExpanded,
              'bi-chevron-right': !isExpanded
            }"
            class="bi"
          ></span>
        </div>
      </td>
      <td [attr.rowspan]="selectedKpiTypes.length" class="resource__row-cell p-0" colspan="1">
        <div [title]="summaryPageDto.name" class="d-flex align-items-center h-100">
          <img [src]="summaryPageDto.id | avatar: 60" class="resource__row-cell--avatar" />
          <div
            #resourceContainer
            (click)="openResourceInfo(resourceContainer, summaryPageDto.id)"
            class="wp-action text w-100"
          >
            {{ summaryPageDto.name }}
          </div>
        </div>
      </td>
    </ng-container>

    <td class="resource__row-kpi-cell" colspan="2">
      {{
        'resources.resourceSummary.filter.properties.viewSettings.' + selectedKpiType | translate
      }}
    </td>
  </tr>
}

<ng-container *ngIf="isExpanded">
  @for (project of summaryPageDto.lines; track project.id) {
    <ng-container>
      @for (
        selectedKpiType of selectedKpiTypesWithoutSchedule;
        track selectedKpiType;
        let isFirst = $first
      ) {
        <tr class="resource-summary__left project__row">
          <td
            *ngIf="isFirst"
            [attr.rowspan]="selectedKpiTypesWithoutSchedule.length"
            [title]="project.name"
            class="project__row-cell"
            colspan="2"
          >
            <div
              #projectContainer
              (click)="openProjectInfo(projectContainer, project.id)"
              class="w-100"
              [ngClass]="{ text: true, 'wp-action': project.id !== null }"
            >
              {{ project.name }}
            </div>
          </td>

          <td class="project__row-kpi-cell">
            {{
              'resources.resourceSummary.filter.properties.viewSettings.' + selectedKpiType
                | translate
            }}
          </td>

          <td class="project__row-total-cell">
            {{
              getProjectTotal(project.id, selectedKpiType)
                | resourceSummaryTotal: summaryService.settings.valueMode
            }}
          </td>
        </tr>
      }
    </ng-container>
  }

  <tr
    *ngIf="summaryPageDto.lines.length && summaryPageDto.hasTimeOff && isActualKpiTypeSelected"
    class="resource-summary__left project__row"
  >
    <td class="project__row-cell" colspan="2">
      <div class="text">{{ 'resources.resourceSummary.columns.timeOff' | translate }}</div>
    </td>

    <td class="project__row-kpi-cell" colspan="2">
      {{ 'resources.resourceSummary.filter.properties.viewSettings.' + kpiType.Actual | translate }}
    </td>
  </tr>

  <tr
    *ngIf="isProjectLinesEmpty"
    class="resource-summary__left no-data__row position-relative w-100"
  >
    <td class="text-center text-body-secondary" colspan="4">
      {{ 'shared.noDisplayData' | translate }}
    </td>
  </tr>
</ng-container>
