<wp-form-header
  name="{{ 'resources.booking.header' | translate }}"
  [hasAutosave]="true"
  [isSaving]="savingQueueService.isSaving"
  [hasName]="false"
  (reloaded)="bookingManageService.reload()"
>
</wp-form-header>

<wp-booking></wp-booking>
