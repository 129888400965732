<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.timeOffTypes.usingPolicy' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group" style="width: 400px">
      <label>{{ 'settings.timeOffTypes.unit' | translate }}</label>
      <wp-select-box
        readonly="true"
        formControlName="unit"
        [values]="timeOffTypeUnits"
        placeholder="{{ 'settings.timeOffTypes.unit' | translate }}"
      >
      </wp-select-box>
    </div>
    <div
      class="form-check"
      title="{{ 'settings.usingPolicies.card.props.excludeFromCalendarDuration' | translate }}"
    >
      <input
        formControlName="excludeFromCalendarDuration"
        type="checkbox"
        class="form-check-input"
        id="excludeFromCalendarDuration"
      />
      <label class="form-check-label" for="excludeFromCalendarDuration">
        {{ 'settings.usingPolicies.card.props.excludeFromCalendarDuration' | translate }}
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
