<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.projects.changingProjectBillingType.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label>{{
        'projects.projects.changingProjectBillingType.properties.currentType.label' | translate
      }}</label>
      <p class="pt-1">{{ project.billingType.name }}</p>
    </div>

    <div class="form-group" style="width: 350px">
      <label>{{
        'projects.projects.changingProjectBillingType.properties.newType.label' | translate
      }}</label>
      <wp-select-box
        [values]="billingTypes"
        placeholder="{{
          'projects.projects.changingProjectBillingType.properties.newType.placeholder' | translate
        }}"
        formControlName="billingType"
      />
      @if (isWarningShown) {
        <div class="small text-danger mt-1">
          <i aria-hidden="true" class="bi bi-exclamation-circle"></i>
          {{ 'projects.projects.changingProjectBillingType.messages.warnText' | translate }}
        </div>
      }
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      style="width: 100px"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.ok' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-default"
      (click)="cancel()"
      style="width: 100px"
      [disabled]="isSaving"
    >
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
