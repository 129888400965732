<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

<form *ngIf="!isLoading" class="modal-form" novalidate ngDraggable [formGroup]="form">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.projectVersions.createProjectVersionModal.header.create' | translate }}
    </h3>

    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>{{
            'projects.projectVersions.createProjectVersionModal.props.name' | translate
          }}</label>
          <wp-text-box
            placeholder="{{
              'projects.projectVersions.createProjectVersionModal.props.name' | translate
            }}"
            formControlName="name"
          ></wp-text-box>
        </div>

        <div class="form-group">
          <label>{{
            'projects.projectVersions.createProjectVersionModal.props.mode.title' | translate
          }}</label>
          <wp-select-box
            [values]="modes"
            [allowNull]="false"
            placeholder="{{
              'projects.projectVersions.createProjectVersionModal.props.mode.title' | translate
            }}"
            formControlName="mode"
          ></wp-select-box>
        </div>

        <div *ngIf="isSourceVersionCtrlVisible" class="form-group">
          <label>{{
            'projects.projectVersions.createProjectVersionModal.props.sourceVersion' | translate
          }}</label>
          <wp-select-box
            [values]="sourceVersions"
            placeholder="{{
              'projects.projectVersions.createProjectVersionModal.props.sourceVersion' | translate
            }}"
            formControlName="sourceVersion"
          ></wp-select-box>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      wpButtonSpinner
      type="button"
      class="btn btn-primary"
      [isBusy]="isSaving"
      (click)="ok()"
    >
      {{ 'shared.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" [disabled]="isSaving" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
