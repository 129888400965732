import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import {
  GridColumnType,
  GridNavigationColumn,
} from '../models/inner/grid-column.interface';

export const LoginLogList: List = {
  version: 1,
  name: 'default',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'email',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'isSuccessful',
          visibleByDefault: true,
          width: 100,
        },
        {
          column: 'ipAddress',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'user',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'authProvider',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: { column: 'created', reverse: true },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'email',
      type: GridColumnType.Navigation,
      availableTotals: [TotalType.Count],
      state: 'settings.loginLogCard',
      header: 'settings.loginLog.list.columns.email',
      hint: 'settings.loginLog.list.columns.email',
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'isSuccessful',
      header: 'settings.loginLog.list.columns.isSuccessful',
      hint: 'settings.loginLog.list.columns.isSuccessful',
      type: GridColumnType.Boolean,
      fixedWidth: true,
      width: '100px',
    },
    {
      name: 'ipAddress',
      header: 'settings.loginLog.list.columns.ipAddress',
      hint: 'settings.loginLog.list.columns.ipAddress',
      type: GridColumnType.String,
    },
    {
      name: 'user',
      header: 'settings.loginLog.list.columns.user',
      hint: 'settings.loginLog.list.columns.user',
      type: GridColumnType.User,
    },
    {
      name: 'authProvider',
      header: 'settings.loginLog.list.columns.authProvider',
      hint: 'settings.loginLog.list.columns.authProvider',
      type: GridColumnType.Entity,
    },
  ],
  dataColumns: [
    {
      column: 'email',
      field: 'email',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isSuccessful',
      field: 'isSuccessful',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'ipAddress',
      field: 'ipAddress',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'user',
      field: ['user.id', 'user.name'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'authProvider',
      field: ['authProvider.id', 'authProvider.name'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],

  dataCollection: 'LoginLogEntries',
};
