import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { OverviewKpiService } from './core/overview-kpi.service';
import {
  KpiPeriodType,
  OverviewKpiCurrencyMode,
} from './shared/overview-kpi.settings';

@Component({
  selector: 'wp-overview-kpi',
  templateUrl: './overview-kpi.component.html',
  styleUrls: ['./overview-kpi.component.scss'],
  providers: [ProjectTasksService],
})
export class OverviewKpiComponent implements OnInit {
  @Input() entityCollection: 'Projects' | 'Programs' | 'Organizations';
  @Input() projectVersion: ProjectVersion;
  @Input() defaultType: KpiType;
  @Input() financeViewAllowed = false;
  @Input() nonBillable: boolean;
  @Input() currencyCode: string = null;

  public kpiType = KpiType;
  public kpiPeriodType = KpiPeriodType;

  public form = this.fb.group({
    date: null,
    task: null,
    period: null,
    currency: null,
  });

  get currencyModeBase() {
    return OverviewKpiCurrencyMode.base;
  }

  get currencyModeProject() {
    return OverviewKpiCurrencyMode.project;
  }

  constructor(
    @Inject('entityId') entityId,
    projectTasksService: ProjectTasksService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    public service: OverviewKpiService,
  ) {
    projectTasksService.resetProjectTasks(entityId, this.projectVersion);
  }

  ngOnInit(): void {
    this.form.patchValue(this.service.settings, {
      emitEvent: false,
    });

    this.service.entityCollection = this.entityCollection;
    this.service.projectVersion = this.projectVersion;
    this.service.financeViewAllowed = this.financeViewAllowed;
    this.service.nonBillable = this.nonBillable;

    this.form.valueChanges.subscribe(() => {
      this.service.settings.date = this.form.value.date;
      this.service.settings.period = this.form.value.period;
      this.service.settings.task = this.form.value.task;
      this.service.settings.currency =
        this.entityCollection !== 'Projects'
          ? OverviewKpiCurrencyMode.base
          : this.form.value.currency;

      this.service.reload();
    });

    const settingsChanged = this.service.assertSettingsChanged();
    if (this.defaultType && !settingsChanged) {
      this.service.settings.type = this.defaultType;
      this.service.setPeriodByCurrentType();
    }

    this.service.projectCurrencyCode = this.currencyCode;
    this.service.reload();
  }

  public getTypeTitle(type: KpiType) {
    switch (type) {
      case KpiType.Actual:
        return this.translate.instant('projects.overview.type.actual');
      case KpiType.Plan:
        return this.translate.instant('projects.overview.type.plan');
      case KpiType.Estimate:
        return this.translate.instant('projects.overview.type.estimate');
      case KpiType.Forecast:
        return this.translate.instant('projects.overview.type.forecast');
    }
  }

  public getPeriodTypeTitle(periodType: KpiPeriodType) {
    return this.translate.instant(`projects.overview.periodType.${periodType}`);
  }

  public getCurrencyTitle(currency: OverviewKpiCurrencyMode) {
    return this.translate.instant(`projects.overview.currency.${currency}`);
  }

  public setOverviewCurrency(currency: OverviewKpiCurrencyMode) {
    this.service.settings.currency = currency;
    this.service.reload();
  }
}
