import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { ProgramListComponent } from './list/program-list.component';
import { META_ENTITY_TYPE, LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { ProgramFilterComponent } from './list/program-filter/program-filter.component';
import { PROGRAM_LIST } from 'src/app/shared/lists/program.list';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { ProgramCardComponent } from './card/program-card.component';
import { ProgramProjectsComponent } from './card/program-projects/program-projects.component';
import { ProgramSettingsComponent } from './settings/program-settings.component';
import { IncludingProjectModalComponent } from './card/program-projects/including-project-modal/including-project-modal.component';
import { ProgramPnlComponent } from './card/program-pnl/program-pnl.component';
import { PnlModule } from 'src/app/shared-features/pnl/pnl.module';
import { ProgramOverviewModule } from 'src/app/programs/card/program-overview/program-overview.module';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    ProgramListComponent,
    ProgramFilterComponent,
    ProgramProjectsComponent,
    ProgramSettingsComponent,
    ProgramCardComponent,
    ProgramPnlComponent,
    IncludingProjectModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PnlModule,
    ProgramOverviewModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'programs',
          url: '/programs/{view:viewName}?{navigation}&{route}',
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            navigation: 'projects.allPrograms',
            routeMode: null,
            view: null,
          },
          component: ProgramListComponent,
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: PROGRAM_LIST },
            { provide: META_ENTITY_TYPE, useValue: 'Program' },
          ],
        },

        {
          name: 'program',
          params: {
            navigation: 'projects.allPrograms',
            route: ROUTE_DEFAULT_PARAMS,
            routeMode: null,
          },
          dynamic: true,
          url: '/programs/{entityId:guid}?{navigation}&{route}',
          component: ProgramCardComponent,
          redirectTo: 'program.overview',
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
        {
          name: 'program.projects',
          url: '/projects',
          component: ProgramProjectsComponent,
        },
        {
          name: 'program.pnl',
          url: '/pnl',
          component: ProgramPnlComponent,
        },
      ],
    }),
  ],
})
export class ProgramsModule {}
