import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class ProjectBillingSettings implements BaseSettings {
  public readonly name = 'ProjectBillingSettings';
  version: number;
  grouping: 'none' | 'byTasks' = 'none';

  getDefault(): ProjectBillingSettings {
    return {
      version: 1,
      grouping: 'byTasks',
    } as ProjectBillingSettings;
  }
}
