import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { Transition } from '@uirouter/core';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { ProjectOverviewModule } from './project-overview/project-overview.module';
import { ProjectTasksModule } from './project-tasks/project-tasks.module';
import { ProjectCardComponent } from './project-card.component';
import { ProjectTeamModule } from './project-team/project-team.module';
import { ProjectPnlModule } from './project-pnl/project-pnl.module';
import { ProjectExpenseEstimatesModule } from './project-expenses/project-expense-estimates/project-expense-estimates.module';
import { ProjectRbcModule } from './project-rbc/project-rbc.module';
import { ProjectExpensesModule } from './project-expenses/project-expenses.module';
import { ProjectVersionsModule } from 'src/app/projects/project-versions/project-versions.module';
import { ProjectResourcesModule } from './project-resources/project-resources.module';
import { ProjectResourceRequirementsModule } from 'src/app/projects/card/project-resource-requirements/project-resource-requirements.module';
import { ProjectCostCentersModule } from 'src/app/projects/card/project-cost-centers/project-cost-centers.module';
import { ProjectTariffsModule } from 'src/app/projects/card/project-tariffs/project-tariffs.module';

@NgModule({
  declarations: [ProjectCardComponent],
  imports: [
    CommonModule,
    SharedModule,
    ProjectOverviewModule,
    ProjectTasksModule,
    ProjectTeamModule,
    ProjectTariffsModule,
    ProjectExpenseEstimatesModule,
    ProjectResourceRequirementsModule,
    ProjectResourcesModule,
    ProjectPnlModule,
    ProjectExpenseEstimatesModule,
    ProjectExpensesModule,
    ProjectRbcModule,
    ProjectVersionsModule,
    ProjectCostCentersModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project',
          url: '/projects/{entityId:guid}?{navigation}&{route}',
          component: ProjectCardComponent,
          dynamic: true,
          params: {
            navigation: 'projects.allProjects',
            openVersionId: null,
            route: ROUTE_DEFAULT_PARAMS,
            routeMode: null,
          },
          redirectTo: (trans) => ({
            state: 'project.overview',
            params: trans.params(),
          }),
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class ProjectCardModule {}
