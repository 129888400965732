import { UserInfo } from '../entities/settings/user-info.model';
import { TimeInputType } from '../enums/time-input-type';
import { Feature } from '../enums/feature.enum';
import { ForecastPeriod } from '../enums/forecast-period.enum';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { BookingMode } from 'src/app/shared/models/enums/booking-mode.enum';
import { MetaEntity } from 'src/app/shared/models/entities/settings/metamodel.model';

/** Клиентская сессия. */
export class ClientSession {
  /** Сведения о пользователе. */
  user: UserInfo;

  /** Доступные роли. */
  roles: string[];

  /** Доступные права */
  permissions: Permission[];

  /** Доступные права с группировкой по сущностям. */
  entitiesAccess: EntityAccess[];

  /** Часовой пояс. */
  timeZone: string;

  /** Смещение часового пояса. */
  timeZoneOffset: string;

  /** Культура. */
  culture: string;

  /** Язык. */
  language: string;

  /** Способ ввода времени. */
  timeInputType: TimeInputType;

  /** Признак использования счетчиков времени. */
  useStopwatch: boolean;

  /** Конфигурация абонента. */
  configuration: ClientSessionConfiguration;

  /** Признак режима замещения. */
  isSubstituteMode: boolean;

  /** Диапазон прогнозирования системы. */
  forecastPeriod: ForecastPeriod;

  /** Assigned license products. */
  products: LicenseProduct[];
}

/** Атомарное право на гранулу в разрезе области. */
export class Permission {
  granularName: string;
  scopeName: string;
  actionEnabled: boolean;
  editEnabled: boolean;
  viewEnabled: boolean;
  deleteEnabled: boolean;
}

/** Права на сущность
 *
 * @description  Если есть разные области с разными правами,
 * то вычисляется максимальное право, т.е. тут максимально возможные права на сущность..
 */
class EntityAccess {
  /** Имя сущности. */
  entityType: string;

  /** Доступно редактирование. */
  editAllowed: boolean;

  /** Доступен просмотр. */
  viewAllowed: boolean;

  /** Доступно удаление. */
  deleteAllowed: boolean;
}

/** Конфигурация абонента. */
export class ClientSessionConfiguration {
  /** ИД абонента. */
  tenantId: string;

  /** Признак доступности копирования часов в таймшите. */
  copyHoursAllowed: boolean;

  /** Отображаемое наименование абонента. */
  displayName: string;

  /** Основной домен. */
  primaryDomain: string;

  /** Базовая валюта абонента. */
  baseCurrencyCode: string;

  /** Признак доступности абонента только на чтение. */
  tenantIsReadOnly: boolean;

  /** Доступные функции. */
  features?: Feature[];

  /** Indicates whether to show notifications by browser Notification Api. */
  useBrowserNotifications: boolean;

  /** Booking scale. */
  bookingScale?: PlanningScale;

  /** Booking mode. */
  bookingMode: BookingMode;

  /** Determines whether tenant is in trial mode or not. */
  tenantIsInTrial: boolean;

  /** Determines whatever tenant has multi-legal or not. */
  multiLegalEntity: boolean;

  /** Metamodel. */
  metamodel: Metamodel;
}

export interface Metamodel {
  entities: MetaEntity[];
}

/** Represent License Product. */
export enum LicenseProduct {
  projects = 'Projects',
  timeTracking = 'TimeTracking',
  expenses = 'Expenses',
  resources = 'Resources',
  finance = 'Finance',
  billing = 'Billing',
  legacyStarter = 'LegacyStarter',
  legacyBasic = 'LegacyBasic',
  legacyStandard = 'LegacyStandard',
  corp = 'Corp',
}
