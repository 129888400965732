import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ProjectVersionMergeStepType } from 'src/app/projects/project-versions/project-versions-merge-modal/models/project-version-merge-step-type.type';
import { GetProjVersionMergeDetails } from 'src/app/projects/project-versions/project-versions-merge-modal/models/get-proj-version-merge-details.model';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ProjectVersionMergeType } from 'src/app/projects/project-versions/project-versions-merge-modal/models/project-version-merge-type.model';
import { ProjectVersionMergeOption } from 'src/app/projects/project-versions/project-versions-merge-modal/models/project-version-merge-option.model';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { Exception } from 'src/app/shared/models/exception';
import { ProjVersionMergeDetailsDto } from 'src/app/projects/project-versions/project-versions-merge-modal/models/proj-version-merge-details-dto.model';
import { ProjVersionMergeMode } from 'src/app/projects/project-versions/project-versions-merge-modal/models/proj-version-merge-mode.enum';
import { ProjVersionMergeDetailType } from '../models/proj-version-merge-detail-type.model';

/**
 * Provides methods to work with Project versions on merge modal.
 * */
@Injectable()
export class ProjectVersionsMergeModalService {
  collection = this.data.collection('ProjectVersions');

  get currentStepType() {
    return this._currentStepType;
  }

  get paramSelectionForm() {
    return this._paramSelectionForm;
  }

  get paramSelectionValue() {
    return this._paramSelectionValue;
  }

  get currencyCode() {
    return this._currencyCode;
  }

  set currencyCode(value: string) {
    this._currencyCode = value;
  }

  /** The model value in the ParamSelection Merge tool step. */
  private _paramSelectionValue: GetProjVersionMergeDetails = null;

  /** The modal content loading state subject. */
  private _isLoading = new BehaviorSubject<boolean>(false);

  /** The ParamSelection form external submit event subject. */
  private _paramSelectionStepSubmitting = new Subject<void>();

  /** The ParamSelection form external submit started subject. */
  private _startParamSelectionStepSubmit = new Subject<void>();

  /** The ParamSelection form external submit finished subject. */
  private _finishParamSelectionStepSubmit = new Subject<void>();

  /** The MergeUpdateDetails Merge tool step model subject. */
  private _mergeUpdateDetails = new BehaviorSubject<{
    dto: ProjVersionMergeDetailsDto;
    hasConflicts: boolean;
  }>({ dto: null, hasConflicts: false });

  /** The Merge tool operation finished subject. */
  private _mergeFinished = new Subject<void>();

  /** The modal content loading state observable. */
  public isLoading$ = this._isLoading.asObservable();

  /** The ParamSelection form external submit event observable. */
  public paramSelectionStepSubmitting$ =
    this._paramSelectionStepSubmitting.asObservable();

  /** The ParamSelection form external submit started observable. */
  public startParamSelectionStepSubmit$ =
    this._startParamSelectionStepSubmit.asObservable();

  /** The MergeUpdateDetails Merge tool step model observable. */
  public mergeUpdateDetails$ = this._mergeUpdateDetails.asObservable();

  /** The Merge tool operation finished observable. */
  public mergeFinished$ = this._mergeFinished.asObservable();

  /** The ParamSelection form external submit finished subscription. */
  private _finishParamSelectionStepSubmit$$: Subscription;

  /** The available Merge tool step types. */
  private readonly _stepTypes: ProjectVersionMergeStepType[] = [];

  /** The current Merge tool step type. */
  private _currentStepType: ProjectVersionMergeStepType = 'paramSelection';

  private _currencyCode: string;

  /** The FormGroup in the ParamSelection Merge tool step. */
  private _paramSelectionForm = this.fb.group({
    type: [ProjectVersionMergeType.replace.code, Validators.required],
    option: [ProjectVersionMergeOption.default.code, Validators.required],
    source: this.fb.array([]),
    target: this.fb.group({
      version: [null],
      versionName: [null],
    }),
  });

  constructor(
    private data: DataService,
    private message: MessageService,
    private fb: UntypedFormBuilder,
  ) {
    this._stepTypes = ['paramSelection', 'mergeUpdateDetails'];
    this._currentStepType = 'paramSelection';
  }

  /**
   * Disposes all the resources related with the service.
   * */
  public dispose() {
    this._finishParamSelectionStepSubmit$$?.unsubscribe();
  }

  /**
   * Changes the Merge tool step.
   *
   * @param forward
   * The step direction flag.
   * True - forward direction.
   * False - backward direction.
   * */
  public changeStep(forward = true) {
    const currentStepIdx = this._stepTypes.indexOf(this._currentStepType);
    if (currentStepIdx !== -1) {
      const newStepIdx = forward ? currentStepIdx + 1 : currentStepIdx - 1;
      const newStep = this._stepTypes[newStepIdx];

      if (forward) {
        // Change to the next step.
        if (newStep === 'mergeUpdateDetails') {
          this._paramSelectionForm.markAllAsTouched();

          if (this._paramSelectionForm.valid) {
            // Change the step only after the respective component form submit is finished.
            this._finishParamSelectionStepSubmit$$?.unsubscribe();
            this._finishParamSelectionStepSubmit$$ =
              this._finishParamSelectionStepSubmit.subscribe(() => {
                this._currentStepType = newStep;
              });
            // Trigger the ParamSelection form external submit started.
            this._startParamSelectionStepSubmit.next();
          }
        }
      } else {
        // Change to the previous step.
        if (newStep === 'paramSelection') {
          this._paramSelectionForm.markAsUntouched();
          this._mergeUpdateDetails.next({ dto: null, hasConflicts: false });
        }
        this._currentStepType = newStep;
      }
    }
  }

  /**
   * Triggers the ParamSelection form external submit event.
   *
   * @param value The Merge Project version DTO parameters.
   * */
  public submitParamSelectionForm(value: GetProjVersionMergeDetails) {
    // Store the parameters.
    this._paramSelectionValue = value;
    this._paramSelectionStepSubmitting.next();
  }

  /**
   * Calls the Project version merge operation depending on selected mode.
   *
   * @param mode
   * The Merge mode.
   * Merge mode values:
   * 1. ShowDiff - Call Merge operation in read-only mode and return the change details.
   * 2. Apply - Call Merge operation.
   * */
  public merge(mode: ProjVersionMergeMode) {
    let actionName = '';

    switch (mode) {
      case ProjVersionMergeMode.showDiff:
        actionName = 'GetMergeDetails';
        break;
      case ProjVersionMergeMode.apply:
        actionName = 'Merge';
        break;
    }

    this._isLoading.next(true);

    this.collection
      .action(actionName)
      .execute(this._paramSelectionValue)
      .subscribe({
        next: (response) => {
          if (ProjVersionMergeMode.showDiff === mode) {
            const hasConflicts =
              (<ProjVersionMergeDetailsDto>response)?.detail.entries.find(
                (x) => x.type === ProjVersionMergeDetailType.conflict.code,
              ) !== undefined;
            this._mergeUpdateDetails.next({
              dto: <ProjVersionMergeDetailsDto>response,
              hasConflicts,
            });
            // Trigger the ParamSelection form external submit finished event.
            this._finishParamSelectionStepSubmit.next();
          }
          if (ProjVersionMergeMode.apply === mode) {
            this._mergeFinished.next();
          }
          this._isLoading.next(false);
        },
        error: (error: Exception) => {
          this.message.errorDetailed(error);
          this._isLoading.next(false);
        },
      });
  }
}
