import { Options } from '@popperjs/core';

export const customPopperOptions = (options: Partial<Options>) => {
  options.strategy = 'fixed';
  options.modifiers.push({
    name: 'offset',
    options: {
      offset: [0, 5],
    },
  });
  return options;
};
