<div
  id="navPanelLabel"
  title="{{ 'shared.navigationPanel.expand' | translate }}"
  tabindex="10"
  class="sidebar sidebar-label"
  [class.hidden]="isNavPanelShown"
  (click)="expandNavPanel()"
>
  <i class="bi bi-chevron-right" aria-hidden="true"></i>
  @if (selectedNavigationItem) {
    <span class="label-header">{{ selectedNavigationItem.header | translate }}</span>
  }
</div>

@if (isNavPanelShown) {
  <div
    id="navPanel"
    tabindex="10"
    class="sidebar sidebar-main"
    [class.sidebar--full]="chromeService.permanentCompactMode"
    (clickOutside)="collapseNavPanel()"
    [clickOutsideEnabled]="chromeService.compactMode"
    exclude="#navPanelLabel"
  >
    @if (chromeService.compactMode) {
      <button
        title="{{ 'shared.navigationPanel.collapse' | translate }}"
        type="button"
        class="close navpanel-close"
        (click)="collapseNavPanel()"
      >
        &times;
      </button>
    }

    <table class="w-100 navpanel-table">
      <tr>
        <td class="h-100 w-100">
          @for (group of filteredGroups; track $index) {
            <div class="group">
              @if (groupIsVisible(group)) {
                <div class="side-menu-group trim">{{ group.header | translate }}</div>
                <ul class="nav flex-column nav-sidebar">
                  @for (item of group.items; track $index) {
                    @if (
                      !item.indicator ||
                      navigationService.indicatorValues.get(item.name) > 0 ||
                      navigationService.selectedNavigationItem?.name === item.name
                    ) {
                      <li
                        class="nav-item trim"
                        [class.active]="
                          navigationService.selectedNavigationItem?.name === item.name
                        "
                      >
                        @if (mode === 'default') {
                          <a
                            [attr.id]="item.name"
                            [uiSref]="item.state"
                            [uiParams]="item.stateParams"
                            (click)="onClickItem()"
                            title="{{ item.hint | translate }}"
                            class="nav-link"
                          >
                            <div class="label">
                              {{ item.header | translate }}
                            </div>

                            @if (
                              item.indicator && navigationService.indicatorValues.get(item.name) > 0
                            ) {
                              <div class="indicator" title="{{ item.indicator.hint | translate }}">
                                {{ navigationService.indicatorValues.get(item.name) | number }}
                              </div>
                            }
                          </a>
                        }

                        @if (mode === 'edit') {
                          <div class="nav-link" [class.nav-link--disabled]="!item.isVisible">
                            <div class="label">
                              {{ item.header | translate }}
                            </div>
                            <i
                              class="bi"
                              [ngClass]="item.isVisible ? 'bi-eye' : 'bi-eye-slash'"
                              [title]="
                                (item.isVisible
                                  ? 'components.navigationPanelComponent.actions.makeHidden'
                                  : 'components.navigationPanelComponent.actions.makeVisible'
                                ) | translate
                              "
                              (click)="toggleItemVisible(item)"
                            ></i>
                          </div>
                        }
                      </li>
                    }
                  }
                </ul>
              }
            </div>
          }
        </td>
      </tr>
    </table>
  </div>
}

<div
  class="navpanel-toggle"
  [class.navpanel-toggle--short]="!isNavPanelShown"
  [class.navpanel-toggle--compact]="chromeService.permanentCompactMode"
>
  @if (mode === 'default') {
    <button
      class="navpanel-toggle__button navpanel-toggle__button--settings"
      [title]="'shared2.actions.setView' | translate"
      (click)="toggleMode()"
      data-test="navPanelSettings"
    >
      <i class="bi bi-gear" aria-hidden="true"></i>
    </button>
  }

  @if (mode === 'edit') {
    <button
      class="navpanel-toggle__button navpanel-toggle__button--small"
      [title]="'shared2.actions.save' | translate"
      (click)="saveNewGroup()"
      data-test="save"
    >
      <i class="bi bi-save" aria-hidden="true"></i>
    </button>
    <button
      class="navpanel-toggle__button navpanel-toggle__button--small"
      [title]="'shared2.actions.cancel' | translate"
      (click)="toggleMode()"
      data-test="cancel"
    >
      <i class="bi bi-x-square" aria-hidden="true"></i>
    </button>
    <button
      class="navpanel-toggle__button navpanel-toggle__button--small"
      [title]="'shared2.actions.resetToDefault' | translate"
      (click)="resetToDefault()"
      data-test="resetToDefault"
    >
      <i class="bi bi-arrow-clockwise" aria-hidden="true"></i>
    </button>
  }

  @if (!chromeService.permanentCompactMode) {
    <button
      class="navpanel-toggle__button"
      (click)="chromeService.toggleCompactMode()"
      data-test="navPanelToggle"
    >
      <i
        class="bi"
        [ngClass]="
          (chromeService.compactMode$ | async) ? 'bi-caret-right-fill' : 'bi-caret-left-fill'
        "
        title="{{
          (chromeService.compactMode$ | async)
            ? ('shared.navigationPanel.expand' | translate)
            : ('shared.navigationPanel.collapse' | translate)
        }}"
        aria-hidden="true"
      ></i>
    </button>
  }
</div>
