import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { ProjectCardSharedModule } from '../shared/project-card-shared.module';
import { ProjectTasksComponent } from './project-tasks.component';
import { ProjectTaskSharedModule } from './shared/project-tasks-shared.module';
import { TasksMainModule } from './tasks-main.module';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { AppService } from 'src/app/core/app.service';
import { ProjectTasksTimelineModule } from 'src/app/projects/card/project-tasks/project-tasks-timeline.module';
import { ProjectTaskSettings } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/models/project-task-settings.model';

@NgModule({
  declarations: [ProjectTasksComponent],
  imports: [
    CommonModule,
    SharedModule,
    ProjectCardSharedModule,
    ProjectTasksTimelineModule,
    ProjectTaskSharedModule,
    TasksMainModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.tasks',
          url: '/tasks',
          component: ProjectTasksComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
          dynamic: true,
          redirectTo: (trans) => {
            const app = trans.injector().get(AppService);
            const hasPermission = app.checkEntityPermission(
              'ProjectTask',
              PermissionType.Read,
            );
            const localConfigService = trans.injector().get(LocalConfigService);
            return hasPermission
              ? localConfigService.getConfig(ProjectTaskSettings).toggler
              : 'project';
          },
        },
      ],
    }),
  ],
})
export class ProjectTasksModule {}
