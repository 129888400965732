import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DefaultFilterService } from '../../shared/default-filter/default-filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { StateService } from '@uirouter/core';
import { Subscription } from 'rxjs';
import { ImportCreationComponent } from '../creation/import-creation.component';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'wp-imports-list',
  template: '<wp-entity-list></wp-entity-list> ',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
})
export class ImportListComponent implements OnInit, OnDestroy {
  selectedRowListener: Subscription;

  constructor(
    private app: AppService,
    private state: StateService,
    private gridService: GridService,
    private actions: ActionPanelService,
    private modalService: NgbModal,
    filterService: FilterService,
  ) {
    filterService.hasViewSelector = false;
  }

  ngOnInit() {
    this.actions.set([
      {
        title: 'settings.imports.list.actions.import',
        hint: 'settings.imports.list.actions.import',
        name: 'create',
        isDropDown: false,
        iconClass: 'bi bi-plus-lg bi-15',
        isBusy: false,
        isVisible: this.app.checkEntityPermission(
          'Import',
          PermissionType.Modify,
        ),
        handler: this.create,
      },
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go('settings.import', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
    ]);

    this.selectedRowListener = this.gridService.selectedGroup$.subscribe(
      (row: any) => {
        this.actions.action('card').isShown = !!row;
      },
    );
  }

  private create = () => {
    this.modalService.open(ImportCreationComponent);
  };

  ngOnDestroy(): void {
    this.selectedRowListener.unsubscribe();
  }
}
