import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { IssueTypeCardComponent } from './card/issue-type-card.component';
import { IssueTypeCreationComponent } from './creation/issue-type-creation.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { IssueTypeListComponent } from 'src/app/settings-app/issue-types/list/issue-type-list.component';
import { ISSUE_TYPE_LIST } from 'src/app/settings-app/issue-types/model/issue-type.list';
import { IssueTypePermissionsComponent } from 'src/app/settings-app/issue-types/card/permissions/issue-type-permissions.component';
import { PerformersModule } from 'src/app/settings-app/shared/performers/performers.module';

@NgModule({
  declarations: [
    IssueTypeListComponent,
    IssueTypeCardComponent,
    IssueTypeCreationComponent,
    IssueTypePermissionsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    PerformersModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.issueTypes',
          url: '/issue-types',
          component: IssueTypeListComponent,
          params: {
            navigation: 'settings.issueTypes',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: ISSUE_TYPE_LIST },
          ],
        },
        {
          name: 'settings.issueType',
          params: {
            navigation: 'settings.issueTypes',
          },
          url: '/issue-types/{entityId:guid}',
          component: IssueTypeCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class IssueTypesModule {}
