import { Navigation } from '../models/navigation/navigation';
import { AppService } from '../../core/app.service';
import { PermissionType } from '../models/inner/permission-type.enum';
import { Feature } from '../models/enums/feature.enum';

/** Analytics App Navigation. */
export const ANALYTICS_APP_NAVIGATION: Navigation = {
  name: 'analytics',
  applicationTitle: 'shared.apps.analytics',
  groups: [
    {
      name: 'dashboards',
      header: 'analytics.navigation.dashboards.header',
      dashboards: true,
      items: [
        {
          name: 'analytics.dashboards',
          state: 'dashboards',
          header: 'analytics.navigation.dashboards.items.dashboardsAll.header',
          hint: 'analytics.navigation.dashboards.items.dashboardsAll.hint',
        },
      ],
    },
    {
      name: 'reports',
      header: 'analytics.navigation.reports.header',
      items: [
        {
          name: 'analytics.reportBuilder',
          state: 'reportBuilder',
          header: 'analytics.navigation.reports.items.reportBuilder.header',
          hint: 'analytics.navigation.reports.items.reportBuilder.hint',
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('Report', 'My', PermissionType.Modify),
        },
        {
          name: 'analytics.allReports',
          state: 'reports',
          stateParams: { view: 'all' },
          header: 'analytics.navigation.reports.items.reportsAll.header',
          hint: 'analytics.navigation.reports.items.reportsAll.hint',
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('Report', 'My', PermissionType.Read) ||
            app.checkPermission('Report', 'MyAndCommon', PermissionType.Read) ||
            app.checkPermission('Report', 'Common', PermissionType.Read),
        },
        {
          name: 'analytics.myReports',
          state: 'reports',
          stateParams: {
            view: 'my',
          },
          header: 'analytics.navigation.reports.items.reportsMy.header',
          hint: 'analytics.navigation.reports.items.reportsMy.hint',
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('Report', 'My', PermissionType.Read) ||
            app.checkPermission('Report', 'MyAndCommon', PermissionType.Read),
        },
      ],
    },
    {
      name: 'finance',
      header: 'analytics.navigation.finance.header',
      items: [
        {
          name: 'analytics.pnl',
          state: 'pnl',
          header: 'analytics.navigation.finance.pnlStatement.header',
          hint: 'analytics.navigation.finance.pnlStatement.hint',
          allowedFn: (app: AppService): boolean =>
            app.checkFeature(Feature.pnl),
        },
      ],
    },
    {
      name: 'templates',
      header: 'analytics.navigation.templates.header',
      items: [
        {
          name: 'analytics.reportTemplates',
          state: 'reportTemplates',
          header: 'analytics.navigation.templates.items.templatesAll.header',
          hint: 'analytics.navigation.templates.items.templatesAll.hint',
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('Report', 'Templates', PermissionType.Read),
        },
      ],
    },
    {
      name: 'settings',
      header: 'analytics.navigation.settings.header',
      items: [
        {
          name: 'analytics.calculatedFields',
          state: 'calculatedFields',
          header: 'analytics.navigation.settings.items.calculatedFields.header',
          hint: 'analytics.navigation.settings.items.calculatedFields.hint',
          entityType: 'CalculatedField',
        },
      ],
    },
  ],
};
