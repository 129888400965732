import { Component, NgZone, OnInit, Optional } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { BookingDataService } from 'src/app/booking/booking/core/booking-data.service';
import { BookingManageService } from 'src/app/booking/booking/core/booking-manage.service';
import { BookingRenderingService } from 'src/app/booking/booking/core/booking-rendering.service';
import { BookingService } from 'src/app/booking/booking/core/booking.service';
import { BookingFilterService } from 'src/app/booking/booking/shared/booking-filter/booking-filter.service';
import { BookingViewSettingsService } from 'src/app/booking/booking/shared/booking-view-settings/booking-view-settings.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { ResourceRequestModalComponent } from 'src/app/resource-requests/shared/resource-request/modal/resource-request-modal.component';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { Entity } from 'src/app/shared/models/entities/entity.model';
import { BookingMode } from 'src/app/shared/models/enums/booking-mode.enum';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

export const bookingProviderFactory = (
  appService: AppService,
  bookingDataService: BookingDataService,
  scheduleNavigationService: ScheduleNavigationService,
  entityData: any,
  context: any,
  zone: NgZone,
): BookingRenderingService | null =>
  appService.session.configuration.bookingMode === BookingMode.Detailed
    ? null
    : new BookingRenderingService(
        bookingDataService,
        scheduleNavigationService,
        entityData,
        context,
        zone,
      );
@Component({
  selector: 'wp-booking-schedule',
  templateUrl: './booking-schedule.component.html',
  providers: [
    NgbActiveModal,
    AutosaveService,
    BookingManageService,
    ScheduleNavigationService,
    BookingDataService,
    BookingViewSettingsService,
    BookingService,
    { provide: FilterService, useClass: BookingFilterService },
    FreezeTableService,
    SavingQueueService,
    {
      provide: BookingRenderingService,
      deps: [
        AppService,
        BookingDataService,
        ScheduleNavigationService,
        [new Optional(), 'entityData'],
        [new Optional(), 'context'],
        NgZone,
      ],
      useFactory: bookingProviderFactory,
    },
  ],
})
export class BookingScheduleComponent implements OnInit {
  constructor(
    public savingQueueService: SavingQueueService,
    public bookingManageService: BookingManageService,
    private actionService: ActionPanelService,
    private modalService: NgbModal,
    private stateService: StateService,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'resources.requests.list.actions.create',
        hint: 'resources.requests.list.actions.create',
        name: 'create',
        isDropDown: false,
        iconClass: 'bi bi-plus-lg bi-15',
        isBusy: false,
        isVisible: true,
        handler: () =>
          this.modalService
            .open(ResourceRequestModalComponent, { size: 'xl' })
            .result.then(
              (entity: Entity) => {
                this.stateService.go('resourceRequest', {
                  entityId: entity.id,
                });
              },
              () => null,
            ),
      },
    ]);
  }
}
