<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.users.card.schedules.personalSchedule.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <wp-user-personal-schedule
      [readonly]="readonly"
      [userId]="userId"
      [usingSchedules]="usingSchedules"
      (changes)="dataChanges($event)"
    ></wp-user-personal-schedule>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="!readonly"
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
