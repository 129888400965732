<div class="col-6 billing-contact" *ngIf="billingContact">
  <label class="group">
    {{ 'settings.settings.tabs.billing' | translate }}
  </label>
  <br />
  <p>
    {{ 'settings.account.billingInfo.props.currency.label' | translate }}:
    {{ billingContact.currencyCode }}
  </p>
  <p>
    {{ 'settings.account.billingInfo.props.country.label' | translate }}:
    {{ billingContact.country.name }}
  </p>

  <br />
  <div>
    <p>
      {{ 'settings.account.billingInfo.props.companyName.label' | translate }}:
      {{ billingContact.organization.name }}
    </p>
    <div *ngIf="billingContact.country.alpha3Code === 'RUS'">
      <p>
        {{ 'settings.account.billingInfo.props.identificationNumber.label' | translate }}:
        {{ billingContact.organization.identificationNumber }}
      </p>
      <p>
        {{ 'settings.account.billingInfo.props.bankIdentificationCode.label' | translate }}:
        {{ billingContact.organization.bankIdentificationCode }}
      </p>
      <p>
        {{ 'settings.account.billingInfo.props.bankAccount.label' | translate }}:
        {{ billingContact.organization.bankAccount }}
      </p>
    </div>
  </div>

  <br />

  <p>
    {{ 'settings.account.billingInfo.props.contactName.label' | translate }}:
    {{ billingContact.contact.name }}
  </p>
  <p>
    {{ 'settings.account.billingInfo.props.contactEmail.label' | translate }}:
    {{ billingContact.contact.email }}
  </p>
  <p>
    {{ 'settings.account.billingInfo.props.contactPhone.label' | translate }}:
    {{ billingContact.contact.phone }}
  </p>

  <br />

  <p>
    {{ 'settings.account.billingInfo.props.city.label' | translate }}:
    {{ billingContact.postAddress.city }}
  </p>
  <p>
    {{ 'settings.account.billingInfo.props.index.label' | translate }}:
    {{ billingContact.postAddress.index }}
  </p>
  <p>
    {{ 'settings.account.billingInfo.props.address.label' | translate }}:
    {{ billingContact.postAddress.address }}
  </p>

  <div *ngIf="billingContact.country.alpha3Code === 'RUS'">
    <p>
      {{ 'settings.account.billingInfo.props.useEDM.label' | translate }}:
      {{ billingContact.useEDM | wpBoolean }}
    </p>
  </div>
  <br />
  <button (click)="editBillingInfo()" type="button" class="btn btn-link p-0">
    {{ 'shared.actions.edit' | translate }}
  </button>
</div>
