export enum GroupingField {
  Project = 'Project',
  User = 'User',
  ProjectTask = 'ProjectTask',
  Role = 'Role',
  Date = 'Date',
  Description = 'Description',
  ExpenseType = 'ExpenseType',
  ProjectCostCenter = 'ProjectCostCenter',
  ProjectTariff = 'ProjectTariff',
}
