import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';

import { Subject, merge } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import _ from 'lodash';

import { CellsOrchestratorService } from 'src/app/shared/services/cell-orhestrator/cells-orchestrator.service';

import { Total } from 'src/app/booking/booking/models/total.model';
import { ProjectRow } from 'src/app/shared/models/entities/resources/booking-entry.model';
import { BookingService } from 'src/app/booking/booking/core/booking.service';
import { BookingDataService } from 'src/app/booking/booking/core/booking-data.service';
import { BookingManageService } from 'src/app/booking/booking/core/booking-manage.service';
import { ResourceRequest } from 'src/app/shared/models/entities/resources/resource-request.model';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';

@Component({
  selector: 'tmt-booking-detailed-line-right',
  templateUrl: './booking-detailed-line-right.component.html',
  styleUrls: ['./booking-detailed-line-right.component.scss'],
  providers: [CellsOrchestratorService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingDetailedLineRightComponent implements OnInit, OnDestroy {
  @Input() public resourceId: string;
  @Input() public totals: Total[] = [];

  public projectRows: ProjectRow[] = [];
  public formLines: UntypedFormArray = this.fb.array([]);
  public requestLines: Record<string, UntypedFormArray> = {};
  public lineIndex = 0;
  public lineIndexes: Record<string, number> = {};

  private destroyed$ = new Subject<void>();

  constructor(
    public bookingService: BookingService,
    public bookingDataService: BookingDataService,
    private bookingManagerService: BookingManageService,
    private cellsOrchestratorService: CellsOrchestratorService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.initProjectRows();
    this.initSubscribers();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private rebuildLines(): void {
    this.lineIndex = 0;
    this.lineIndexes = {};
    this.requestLines = {};
    this.formLines = this.fb.array([]);

    for (const row of this.projectRows) {
      this.lineIndexes[row.bookingEntryId] = this.lineIndex;
      this.lineIndex++;

      this.fillRequestLines(row);

      const rowFormArray = this.fb.array([]);

      for (const entry of this.totals) {
        const value = row.detailEntries.find(
          (el) => el.date === entry.date,
        ) || {
          hours: 0,
          date: entry.date,
        };

        const control = this.fb.control({
          ...value,
          nonWorking: entry.nonWorking,
          scheduleHours: entry.scheduleHours,
          fteHours: entry.fteHours,
          limitHours: 24,
        });

        control.valueChanges
          .pipe(takeUntil(this.destroyed$))
          .subscribe((detail) => {
            this.bookingService.addEntryToQueue(
              row.bookingEntryId,
              detail,
              this.resourceId,
            );
          });

        rowFormArray.push(control);
      }

      if (
        row.requestBookings?.find(
          (booking) =>
            !ResourceRequest.systemStateIds.includes(
              booking.resourceRequest?.stateId,
            ),
        )
      ) {
        rowFormArray.valueChanges
          .pipe(startWith(true), takeUntil(this.destroyed$))
          .subscribe(() => {
            this.calcDeviation(row);
            this.fillRequestLines(row);
          });
      }

      this.formLines.push(rowFormArray);

      if (
        !row.id ||
        !row.bookingEntryEditAllowed ||
        this.bookingDataService.isReadonlyMode()
      ) {
        rowFormArray.disable({ emitEvent: false });
      }
    }

    this.cellsOrchestratorService.init();
  }

  private fillRequestLines(row: ProjectRow): void {
    row.requestBookings.forEach((booking) => {
      this.lineIndexes[booking.id] = this.lineIndexes[booking.id]
        ? this.lineIndexes[booking.id]
        : this.lineIndex;
      this.lineIndex++;

      this.requestLines[booking.id] = this.fb.array([]);

      for (const entry of this.totals) {
        const value = booking.changeEntries.find(
          (el) => el.date === entry.date,
        ) || {
          hours: 0,
          date: entry.date,
        };

        const control = this.fb.control({
          ...value,
          nonWorking: entry.nonWorking,
          scheduleHours: entry.scheduleHours,
          fteHours: entry.fteHours,
          limitHours: 999,
        });

        this.requestLines[booking.id].push(control);
      }

      this.requestLines[booking.id].disable({ emitEvent: false });
    });
  }

  private calcDeviation(row: ProjectRow): void {
    const bookingWithOpenRequest = row.requestBookings.find(
      (booking) =>
        !ResourceRequest.systemStateIds.includes(
          booking.resourceRequest?.stateId,
        ),
    );

    if (bookingWithOpenRequest) {
      bookingWithOpenRequest.changeEntries.length = 0;

      this.bookingService.slots.forEach((slot) => {
        const originalEntry = row.detailEntries.find(
          (el) => el.date === slot.date.toISODate(),
        );
        const changeEntry = bookingWithOpenRequest.detailEntries.find(
          (el) => el.date === slot.date.toISODate(),
        );

        bookingWithOpenRequest.changeEntries.push({
          date: slot.date.toISODate(),
          hours:
            bookingWithOpenRequest.resourceRequest?.teamMember?.resource
              ?.resourceType === ResourceType.user
              ? (changeEntry?.hours ?? 0) - (originalEntry?.hours ?? 0)
              : changeEntry?.hours ?? 0,
        });
      });
    }
  }

  private initProjectRows(): void {
    this.projectRows = this.bookingService.getsProjectRows(this.resourceId);
    this.rebuildLines();
    this.cdr.markForCheck();
  }

  private initSubscribers(): void {
    this.bookingService.toggleRow$
      .pipe(
        filter((v) => v?.id === this.resourceId),
        takeUntil(this.destroyed$),
      )
      .subscribe((toggleState) => {
        this.projectRows.forEach((row) => {
          row.isExpanded = toggleState.projectIds.has(row.id);
        });

        this.cdr.markForCheck();
      });

    merge(this.bookingService.detectChanges$, this.bookingService.toggleGroup$)
      .pipe(
        filter((v) =>
          _.isObject(v) ? v.id === this.resourceId : v === this.resourceId,
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.initProjectRows();
      });

    merge(this.bookingManagerService.reload$, this.bookingService.changes$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.initProjectRows();
      });
  }
}
