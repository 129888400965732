import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { merge, startWith } from 'rxjs';

import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { ListService } from 'src/app/shared/services/list.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { ProjectTeamService } from 'src/app/shared/services/project-team.service';

import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectTariffsService } from 'src/app/projects/card/project-tariffs/services/project-tariffs.service';
import { PROJECT_TARIFF_LIST } from 'src/app/projects/card/project-tariffs/models/project-tariff.list';

@Component({
  selector: 'tmt-project-tariffs',
  template: `<tmt-grid
    [options]="projectTariffsService.gridOptions"
    [formArray]="projectTariffsService.formArray"
    [readonly]="projectTariffsService.readonly"
  />`,
  styleUrl: './project-tariffs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProjectTeamService,
    ProjectTariffsService,
    SavingQueueService,
    GridService,
    ListService,
    { provide: VIEW_NAME, useValue: 'default' },
    { provide: LIST, useValue: PROJECT_TARIFF_LIST },
  ],
})
export class ProjectTariffsComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    public projectTariffsService: ProjectTariffsService,
    private projectCardService: ProjectCardService,
    private savingQueueService: SavingQueueService,
  ) {}

  public ngOnInit(): void {
    merge(this.projectCardService.reloadTab$, this.savingQueueService.error$)
      .pipe(startWith(true), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.projectTariffsService.load());
  }
}
