import { Component, Input } from '@angular/core';

@Component({
  selector: 'wp-time-off-request-info',
  templateUrl: './time-off-request-info.component.html',
  styleUrls: ['./time-off-request-info.component.scss'],
})
export class TimeOffRequestInfoComponent {
  /** Признак начального состояния - если диалог создания или заявка еще Draft. */
  @Input() isApproved: boolean;

  /** Баланс и длительность на момент перехода из начального состояния. */
  @Input() balance: number | null = null;
  @Input() duration: number | null = null;

  /** Текущий баланс и длительность. */
  @Input() currentBalance: number | null;
  @Input() currentDuration: number | null;

  @Input() unitName: string;

  /** Получить остаток. */
  public getRemainValue(): number {
    if (this.isApproved) {
      return (this.balance ?? 0) - (this.duration ?? 0);
    } else {
      return (this.currentBalance ?? 0) - (this.currentDuration ?? 0);
    }
  }
}
