import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Report } from 'src/app/shared/models/entities/analytics/report.model';
import { Exception } from 'src/app/shared/models/exception';
import { MessageService } from 'src/app/core/message.service';
import { LogService } from 'src/app/core/log.service';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportSettingsComponent } from './modals/report-settings/report-settings.component';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { StateService } from '@uirouter/core';
import { NotificationService } from 'src/app/core/notification.service';
import { ReportSaveAsComponent } from './modals/report-save-as/report-save-as.component';
import { ReportUserSettingsService } from './report-user-settings/report-user-settings.service';
import { AppConfigService } from 'src/app/core/app-config.service';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { AnalyticsService } from 'src/app/core/analytics.service';
import { ReportSourceDescription } from '../../shared/models/source-description/report-source-description.model';

@Injectable()
export class ReportCardService {
  public reportId: string;
  public report: Report;

  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  private loadingSubscription: Subscription;

  private reportSubject = new BehaviorSubject<Report>(null);
  public report$ = this.reportSubject.asObservable().pipe(filter((p) => !!p));

  public cardName$ = new BehaviorSubject<string>('');
  public reportUrl: string;
  public sourceDescription: ReportSourceDescription;

  constructor(
    private httpClient: HttpClient,
    private blockUI: BlockUIService,
    private actionService: ActionPanelService,
    private notification: NotificationService,
    private analyticsService: AnalyticsService,
    private log: LogService,
    private modal: NgbModal,
    private message: MessageService,
    private data: DataService,
    private autosave: AutosaveService,
    private stateService: StateService,
    private translate: TranslateService,
    private reportUserSettings: ReportUserSettingsService,
  ) {}

  /** Загрузка отчёта. */
  public load(reportId: string) {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    this.reportId = reportId;

    const reload = () => {
      this.log.debug('Report is loading...');
      this.state$.next(CardState.Loading);

      const query = {
        expand: { reportType: { select: ['id', 'name', 'code'] } },
      };

      const observable = !reportId
        ? this.data
            .collection('Reports')
            .function('GetNewReport')
            .get(null, query)
        : this.data.collection('Reports').entity(reportId).get(query);

      this.loadingSubscription = observable.subscribe({
        next: (report: Report) => {
          this.report = report;
          const cardName = !reportId
            ? this.translate.instant('analytics.reports.card.header')
            : report.name;

          this.cardName$.next(cardName);

          const propagateReport = () => {
            this.reportSubject.next(report);
            this.state$.next(CardState.Ready);
          };

          if (report.reportType) {
            this.analyticsService
              .getSourceDescription(report.reportType.code)
              .subscribe((description) => {
                this.sourceDescription = description;
                propagateReport();
              });
          } else {
            propagateReport();
          }
        },
        error: (error: Exception) => {
          if (error.code === Exception.BtEntityNotFoundException.code) {
            this.state$.next(CardState.Error);
            return;
          }
          this.message.error(error.message);
        },
      });
    };

    this.autosave.save().then(
      () => reload(),
      () => null,
    );
  }

  /** Открыть свойства отчета. */
  public openSettings() {
    const ref = this.modal.open(ReportSettingsComponent, { size: 'xxl' });
    (ref.componentInstance as ReportSettingsComponent).report = this.report;
    ref.result.then(
      () => {
        this.load(this.reportId);
      },
      () => null,
    );
  }

  /** Удалить отчет. */
  public delete() {
    this.message.confirmLocal('shared.deleteConfirmation').then(
      () => {
        this.actionService.action('delete').isBusy = true;
        this.data
          .collection('Reports')
          .entity(this.report.id)
          .delete()
          .subscribe({
            next: () => {
              this.actionService.action('delete').isBusy = false;
              if (this.report.isTemplate) {
                this.stateService.go('reportTemplates');
              } else {
                this.stateService.go('reports', { view: 'all' });
              }
            },
            error: (error: Exception) => {
              this.notification.error(error.message);
              this.actionService.action('delete').isBusy = false;
            },
          });
      },
      () => null,
    );
  }

  /** Скачать отчет в Excel. */
  public downloadExcelPivot() {
    this.download('Pivot');
  }

  /** Скачать исходные данные отчета в Excel. */
  public downloadExcelSource() {
    this.download('Source');
  }

  private download(type: 'Source' | 'Pivot') {
    this.reportUserSettings.save().then(
      () => {
        const reportUrl = `${AppConfigService.config.api.url}/reportdownload/${this.report.id}/GetExcel${type}`;

        this.blockUI.start();
        this.httpClient.get(reportUrl, { responseType: 'blob' }).subscribe({
          next: (data) => {
            saveAs(data, `${this.report.name}.xlsx`);
            this.blockUI.stop();
          },
          error: (error: Exception) => {
            this.message.error(error.message);
            this.blockUI.stop();
          },
        });
      },
      () => null,
    );
  }

  /** Сохранить отчет. */
  public saveAs() {
    const ref = this.modal.open(ReportSaveAsComponent);
    (ref.componentInstance as ReportSaveAsComponent).report = this.report;
    (ref.componentInstance as ReportSaveAsComponent).buildMode = !this.reportId;
  }
}
