import { Component } from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { Guid } from 'src/app/shared/helpers/guid';
import { Exception } from 'src/app/shared/models/exception';

/** Диалог создания правила валидации. */
@Component({
  selector: 'wp-workflow-creation',
  templateUrl: './workflow-creation.component.html',
})
export class WorkflowCreationComponent {
  isSaving = false;

  form: UntypedFormGroup = this.fb.group({
    id: Guid.generate(),
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    lifecycle: [null, Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const data = {
      id: this.form.value.id,
      name: this.form.value.name,
      lifecycleId: this.form.value.lifecycle?.id,
    };

    this.data
      .collection('WorkflowDeclarations')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notification.successLocal(
            'settings.workflows.creation.messages.created',
          );
          this.state.go('settings.workflow', {
            entityId: response.id,
          });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  /**
   * Returns the filter by LC to select state
   *
   * @returns Filter.
   */
  public getFilter() {
    const lifecycle = this.form.value.lifecycle;
    return lifecycle
      ? {
          filter: [{ lifecycleId: { type: 'guid', value: lifecycle.id } }],
        }
      : null;
  }

  cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
