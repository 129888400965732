import { Directive, HostListener, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogService } from 'src/app/core/log.service';
import { DocModalComponent } from 'src/app/shared/components/controls/doc-modal/doc-modal.component';

@Directive({
  selector: '[tmtDocsArticle]',
})
export class DocsArticleDirective {
  @Input() tmtDocsArticle: string;

  @HostListener('click', ['$event']) onClick($event) {
    this.open();
  }
  constructor(
    private modalService: NgbModal,
    private log: LogService,
  ) {}

  open() {
    this.log.debug('Open docs article: ' + this.tmtDocsArticle);
    const modalRef = this.modalService.open(DocModalComponent, {
      size: 'xl',
      modalDialogClass: 'full-height',
      backdrop: true,
      animation: false,
    });
    modalRef.componentInstance.route = this.tmtDocsArticle;
  }
}
