import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { ClientOverviewKpiComponent } from 'src/app/clients/card/client-overview/client-overview-kpi/client-overview-kpi.component';
import { OverviewModule } from 'src/app/shared/components/features/overview/overview.module';

@NgModule({
  declarations: [ClientOverviewKpiComponent],
  imports: [
    CommonModule,
    SharedModule,
    OverviewModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'client.overview.kpi',
          url: '/kpi',
          component: ClientOverviewKpiComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
    BaseSharedModule,
  ],
})
export class ClientOverviewKpiModule {}
