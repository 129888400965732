<ng-container [formGroup]="formGroup">
  <table style="width: 100%">
    <tr>
      <td style="width: 150px">
        <div class="form-group" tmtIndicator>
          <label>{{ 'timeOff.period.from' | translate }}</label>
          <wp-date-box formControlName="startDate"></wp-date-box>
        </div>
      </td>
      <td style="padding-left: 15px; padding-top: 25px; min-width: 200px; width: 200px">
        <div class="form-group" tmtIndicator>
          <wp-select-box
            [values]="dayParts"
            [allowNull]="false"
            formControlName="startDayIntervalValue"
          >
          </wp-select-box>
        </div>
      </td>
      <td style="padding-left: 15px; padding-top: 25px; width: 100%">
        <div
          class="form-group"
          *ngIf="formGroup.value.startDayIntervalValue?.id === 'Hours'"
          tmtIndicator
        >
          <wp-number-box
            style="width: 100%"
            formControlName="startDayHours"
            type="work"
            [allowNull]="false"
            [min]="0.5"
            [max]="24"
          >
          </wp-number-box>
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <div class="form-group" tmtIndicator>
          <label>{{ 'timeOff.period.to' | translate }}</label>
          <wp-date-box formControlName="finishDate"></wp-date-box>
        </div>
      </td>

      <td style="padding-left: 15px; padding-top: 25px; min-width: 200px">
        <div class="form-group" *ngIf="!isSingleDay()" tmtIndicator>
          <wp-select-box
            [values]="dayParts"
            [allowNull]="false"
            formControlName="finishDayIntervalValue"
          >
          </wp-select-box>
        </div>
      </td>
      <td style="padding-left: 15px; padding-top: 25px; width: 100%">
        <div
          class="form-group"
          tmtIndicator
          *ngIf="formGroup.value.finishDayIntervalValue?.id === 'Hours' && !isSingleDay()"
        >
          <wp-number-box
            style="width: 100%"
            formControlName="finishDayHours"
            type="work"
            [allowNull]="false"
            [min]="0.5"
            [max]="24"
          >
          </wp-number-box>
        </div>
      </td>
    </tr>
  </table>

  <p style="padding-bottom: 15px" class="text-body-secondary">
    {{ 'timeOff.period.setDatesHelp' | translate }}
  </p>

  <div [hidden]="days.length === 0" class="form-group">
    <label>{{ 'timeOff.period.timeOffDurationByDates' | translate }}</label>
    <div style="overflow-y: auto; width: 491px; margin-bottom: 20px">
      <table class="table table-sm table-bordered days">
        <thead>
          <tr>
            <th *ngFor="let day of days" style="width: 60px">
              {{ day.header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              *ngFor="let day of days"
              style="width: 60px; height: 27px"
              [ngClass]="{ 'nonworking-cell': day.hours === 0 }"
            >
              <span>{{ day.calcDuration | wpWork }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div
    class="form-group"
    [hidden]="existsRequest.length === 0"
    style="margin-top: 15px; margin-bottom: 20px"
  >
    <label>{{ 'timeOff.period.existRequestsInPeriod' | translate }}</label>
    <table style="width: 100%; table-layout: fixed">
      <tbody>
        <tr *ngFor="let request of existsRequest">
          <td class="trim" style="text-align: left; padding-bottom: 10px">
            <tmt-state-badge [state]="request.state"></tmt-state-badge>
            <a
              style="margin-left: 10px"
              uiSref="timeOffRequest"
              title="{{ request?.timeOffType?.name }}"
              [uiParams]="{ entityId: request.id }"
              >{{ request?.timeOffType?.name }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
