<form [formGroup]="form" class="form">
  <div class="form-group">
    <label>{{ 'analytics.dashboards.settings.properties.name.label' | translate }}</label>
    <wp-text-box
      formControlName="name"
      placeholder="{{ 'analytics.dashboards.settings.properties.name.placeholder' | translate }}"
    ></wp-text-box>
  </div>
  <div class="form-group">
    <label>{{ 'analytics.dashboards.settings.properties.description.label' | translate }}</label>
    <wp-multiline-text-box
      formControlName="description"
      placeholder="{{
        'analytics.dashboards.settings.properties.description.placeholder' | translate
      }}"
    >
    </wp-multiline-text-box>
  </div>
  <div class="form-check">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'analytics.dashboards.settings.properties.isActive' | translate }}
    </label>
  </div>

  <hr />
  <div class="form-group">
    <label>{{ 'analytics.dashboards.settings.properties.viewPeriod' | translate }}</label>
    <wp-date-period-box
      [advanced]="true"
      formControlName="defaultViewPeriod"
      placeholder="{{ 'analytics.dashboards.settings.properties.viewPeriod' | translate }}"
    ></wp-date-period-box>
  </div>

  <div class="form-check">
    <input
      formControlName="changeViewPeriodAllowed"
      type="checkbox"
      class="form-check-input"
      id="changeViewPeriodAllowed"
    />
    <label class="form-check-label" for="changeViewPeriodAllowed">
      {{ 'analytics.dashboards.settings.properties.changeViewPeriodAllowed' | translate }}
    </label>
  </div>
</form>
