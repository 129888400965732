import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnInit,
  Renderer2,
} from '@angular/core';
import { debounceTime } from 'rxjs';
import { NavigationService } from 'src/app/core/navigation.service';

/** Application main route viewer. */
@Component({
  selector: 'tmt-route-navigator',
  templateUrl: './route-navigator.component.html',
  styleUrls: ['./route-navigator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteNavigatorComponent implements OnInit {
  public textLimit: number | undefined;

  private navbarId = 'navbar';
  private navbarTitleId = 'navbarTitle';
  private navbarLineId = 'navbarLine';
  private breadcrumbItemClass = 'breadcrumb-item';

  constructor(
    public navigationService: NavigationService,
    @Inject(DOCUMENT) private readonly document: Document,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private zone: NgZone,
  ) {}

  public ngOnInit(): void {
    this.navigationService.route$.pipe(debounceTime(0)).subscribe(() => {
      this.calculateWidth();
    });
    this.zone.runOutsideAngular(() => {
      this.renderer.listen(window, 'resize', () => this.calculateWidth());
    });
  }

  /**
   * Returns tooltip based on textLimit.
   *
   * @param title Breadcrumb title.
   *
   * @returns title or null.
   */
  public getTooltip(title: string): string | null {
    return title.length > this.textLimit ? title : null;
  }

  private calculateWidth(): void {
    this.textLimit = undefined;
    this.cdr.detectChanges();
    const navbarWidth = this.document.getElementById(this.navbarId).clientWidth;
    const navbarTitleWidth = this.document.getElementById(
      this.navbarTitleId,
    ).clientWidth;
    let navbarLineWidth = this.document.getElementById(
      this.navbarLineId,
    ).clientWidth;

    const targetWidth = navbarWidth - navbarTitleWidth;

    const elementsArray = Array.from(
      document.querySelectorAll<HTMLElement>(`.${this.breadcrumbItemClass}`),
    );
    const maxElementNameLength = Math.max(
      ...elementsArray.map((el) => el.innerText.length),
    );

    const checkFunction = (): void => {
      this.cdr.detectChanges();
      navbarLineWidth = this.document.getElementById(
        this.navbarLineId,
      ).clientWidth;
    };

    let textLimitLength = maxElementNameLength;

    while (navbarLineWidth > targetWidth) {
      const lastNavbarLineWidth = navbarLineWidth;
      textLimitLength--;
      this.textLimit = textLimitLength;
      checkFunction();
      if (lastNavbarLineWidth === navbarLineWidth) {
        return;
      }
    }
  }
}
