import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { ImportCardComponent } from './card/import-card.component';
import { ImportListComponent } from './list/import-list.component';
import { ImportCreationComponent } from './creation/import-creation.component';
import { ImportList } from 'src/app/shared/lists/import.list';
import { ImportStateCellComponent } from './list/import-state-cell/import-state-cell.component';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    ImportCardComponent,
    ImportListComponent,
    ImportCreationComponent,
    ImportStateCellComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.imports',
          url: '/imports',
          params: {
            navigation: 'settings.imports',
          },
          component: ImportListComponent,
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: ImportList },
          ],
        },

        {
          name: 'settings.import',
          params: {
            navigation: 'settings.imports',
          },
          url: '/imports/{entityId:guid}',
          component: ImportCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class ImportsModule {}
