import { Component, OnInit } from '@angular/core';
import { CurrencyService } from './currency.service';
import { AppService } from 'src/app/core/app.service';
import { StateService } from '@uirouter/angular';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { NavigationService } from 'src/app/core/navigation.service';
import { Currency } from 'src/app/shared/models/entities/settings/currency.model';

/** Карточка валюты. */
@Component({
  selector: 'tmt-currency-card',
  templateUrl: './currency-card.component.html',
  providers: [CurrencyService],
})
export class CurrencyCardComponent implements OnInit {
  public tabs: TabLink[] = [];
  private _baseCurrencyCode: string;

  constructor(
    public app: AppService,
    public state: StateService,
    public service: CurrencyService,
    private notification: NotificationService,
    private navigationService: NavigationService,
  ) {}

  public ngOnInit(): void {
    this._baseCurrencyCode = this.app.session.configuration.baseCurrencyCode;
    this.tabs.push({
      header: 'settings.currencies.card.tabs.main',
      state: 'settings.currency.main',
    });

    const query = {
      select: ['id', 'name', 'alpha3Code'],
    };
    this.service.entity.get<Currency>(query).subscribe({
      next: (currency) => {
        if (currency.alpha3Code !== this._baseCurrencyCode) {
          this.tabs.push({
            header: 'settings.currencies.card.tabs.exchangeRates',
            state: 'settings.currency.exchangeRates',
          });
        }
        if (
          this.tabs.length > 0 &&
          !this.tabs.find((t) => t.state === this.state.current.name)
        ) {
          this.state.go(this.tabs[0].state, this.state.params);
        }

        this.service.state$.next(CardState.Ready);

        this.navigationService.addRouteSegment({
          id: currency.id,
          title: currency.name,
        });
      },
      error: (error: Exception) => {
        this.service.state$.next(CardState.Ready);
        this.notification.error(error.message);
      },
    });
  }
}
