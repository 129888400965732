import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[wpButtonSpinner]',
})
export class ButtonSpinnerDirective implements AfterViewInit {
  private _isBusy = false;
  private _isDisabled = false;
  private _text = '';

  @Input()
  set isBusy(isBusy: boolean) {
    this._isBusy = isBusy;
    this.update();
  }

  get isBusy(): boolean {
    return this._isBusy;
  }

  @Input()
  set isDisabled(isDisabled: boolean) {
    this._isDisabled = isDisabled;
    this.updateDisabled();
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._text = this.el.nativeElement.innerHTML;
      this.update();
    });
  }

  private update(): void {
    if (!this._text) {
      return;
    }
    let text = '';

    if (this.isBusy) {
      text = '<i class="bi bi-arrow-clockwise rotate"></i>&nbsp;';
    }

    text += this._text;
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', text);

    if (!this.isDisabled) {
      if (this.isBusy) {
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
      } else {
        this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
      }
    }
  }

  private updateDisabled(): void {
    if (this.isDisabled) {
      this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
    }
  }
}
