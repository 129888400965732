import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { EmployeeFilterComponent } from './list/employee-filter/employee-filter.component';
import { EmployeeListComponent } from './list/employee-list.component';
import { EMPLOYEE_LIST } from 'src/app/shared/lists/employee.list';
import { EmployeeCardModule } from 'src/app/employees/card/employee-card.module';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [EmployeeListComponent, EmployeeFilterComponent],
  imports: [
    CommonModule,
    SharedModule,
    EmployeeCardModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'employees',
          url: '/employees?{navigation}&{route}',
          component: EmployeeListComponent,
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: EMPLOYEE_LIST },
          ],
        },
      ],
    }),
  ],
})
export class EmployeesModule {}
