import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { SharedFeaturesModule } from '../shared-features/shared-features.module';
import { ScheduleNavigationModule } from '../shared-features/schedule-navigation/schedule-navigation.module';
import { TimeOffScheduleComponent } from 'src/app/time-off-schedule/time-off-schedule.component';
import { TimeOffInfoComponent } from 'src/app/time-off-schedule/time-off-info/time-off-info.component';
import { TimeOffScheduleFiltersComponent } from 'src/app/time-off-schedule/time-off-schedule-filters/time-off-schedule-filters.component';

@NgModule({
  declarations: [
    TimeOffScheduleComponent,
    TimeOffInfoComponent,
    TimeOffScheduleFiltersComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedFeaturesModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'timeOffSchedule',
          params: {
            navigation: 'team.timeOffSchedule',
          },
          url: '/time-off/schedule?{route}&{navigation}',
          component: TimeOffScheduleComponent,
        },
      ],
    }),
    ScheduleNavigationModule,
  ],
})
export class TimeOffScheduleModule {}
