@if (!projectRows.length) {
  <td class="line no-data text-body-secondary" [attr.colspan]="totals.length">
    {{ 'shared.noDisplayData' | translate }}
  </td>
}

@for (row of projectRows; track row; let lineIndex = $index) {
  <tr [attr.data-test-resource-id]="resourceId">
    @for (entry of $any(formLines.at(lineIndex))?.controls; track entry; let dayIndex = $index) {
      <td
        wpPlannerCell
        class="cell"
        [valueMode]="bookingService.settings.valueMode"
        [formControl]="entry"
        [lineIndex]="lineIndexes[row.bookingEntryId]"
        [dayIndex]="dayIndex"
        [scale]="bookingService.settings.planningScale"
        [class.non-working]="entry.value?.nonWorking"
        [attr.data-test-date]="entry.value?.date"
      ></td>
    }
  </tr>

  @for (booking of row.requestBookings; track booking; let bookingLineIndex = $index) {
    <tr [hidden]="!row.requestBookings.length || !row.isExpanded">
      @for (
        entry of requestLines[booking.id].controls;
        track entry;
        let bookingDayLineIndex = $index
      ) {
        <td
          wpPlannerCell
          class="cell"
          [valueMode]="bookingService.settings.valueMode"
          [formControl]="entry"
          [lineIndex]="lineIndexes[booking.id]"
          [dayIndex]="bookingDayLineIndex"
          [scale]="bookingService.settings.planningScale"
          [class.non-working]="entry.value?.nonWorking"
          [class.minus]="entry.value?.hours < 0"
          [class.plus]="entry.value?.hours > 0"
          [attr.data-test-date]="entry.value?.date"
        ></td>
      }
    </tr>
  }
}
