import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { BaseSettings } from '../shared/models/inner/base-settings.interface';

/** Сервис для работы с локальными настройками. */
@Injectable({
  providedIn: 'root',
})
export class LocalConfigService {
  constructor(private localStorageService: LocalStorageService) {}

  /** Возвращает конфигурацию для типа. */
  public getConfig<T extends BaseSettings>(factory: new () => T): T {
    const settings = new factory();
    const storageName = `cfg_${settings.name}`;

    const storedSettings = this.localStorageService.retrieve(storageName) as T;

    if (storedSettings?.version >= settings.getDefault().version) {
      return storedSettings;
    }

    return settings.getDefault() as T;
  }

  /** Сохраняет для типа. */
  public setConfig<T extends BaseSettings>(factory: new () => T, value: T) {
    const settings = new factory();
    const storageName = `cfg_${settings.name}`;

    value.version = settings.getDefault().version;
    this.localStorageService.store(storageName, value);
  }
}
