import { DatePipe } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { NavigationService } from 'src/app/core/navigation.service';
import { TimeOffBalanceEntryMode } from 'src/app/shared/models/entities/team/time-off-balance-entry-mode.enum';
import {
  TimeOffBalanceEntryType,
  TimeOffBalanceEntryTypes,
} from 'src/app/shared/models/entities/team/time-off-balance-entry-type.enum';
import { TimeOffBalanceEntry } from 'src/app/shared/models/entities/team/time-off-balance-entry.model';
import { TimeOffTypeUnits } from 'src/app/shared/models/enums/time-off-type-unit.enum';

@Injectable()
export class TimeOffBalanceEntryService {
  public mode: 'create' | 'edit' = 'create';

  public readonly = false;

  public saving$ = new Subject<boolean>();
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  public name$ = new BehaviorSubject<string>('');

  public form = this.fb.group({
    id: null,
    typeId: [TimeOffBalanceEntryType.accrual.id, Validators.required],
    user: [null, Validators.required],
    timeOffType: [null, Validators.required],
    unit: [null, Validators.required],
    mode: [TimeOffBalanceEntryMode.manual.id, Validators.required],
    date: [DateTime.now().toISODate(), Validators.required],
    amount: [null, Validators.required],
    documentId: null,
    documentName: null,
  });

  // Returns the entity type for building a link
  public get entityType(): string {
    if (this.form.value.typeId === TimeOffBalanceEntryType.accrual.id) {
      return 'timesheet';
    } else if (this.form.value.typeId === TimeOffBalanceEntryType.use.id) {
      return 'timeOffRequest';
    }
  }

  private timeOffTypeUnits: NamedEntity[] = [];

  constructor(
    @Optional() @Inject('entityId') public entryId,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private projectTasksService: ProjectTasksService,
    private datePipe: DatePipe,
    private message: MessageService,
    private actionService: ActionPanelService,
    private navigationService: NavigationService,
  ) {
    this.form.controls.typeId.valueChanges.subscribe(() => {
      const typeId = this.form.controls.typeId.value;

      if (!this.entryId) {
        this.name$.next(
          typeId === TimeOffBalanceEntryType.use.id
            ? TimeOffBalanceEntryType.use.name
            : TimeOffBalanceEntryType.accrual.name,
        );
      }
    });

    if (!this.entryId) {
      // Для срабатывания события.
      this.form.controls.typeId.setValue(TimeOffBalanceEntryType.accrual.id);
    }
    if (this.entryId) {
      this.load();
    }
  }

  private propagateName(): string {
    const typeId = this.form.controls.typeId.value;
    const type = TimeOffBalanceEntryTypes.find((t) => t.id === typeId);

    const typeName = this.translate.instant(type.name);
    const name = this.translate.instant(
      'team.timeOffBalanceEntries.card.nameTemplate',
      {
        type: typeName,
        user: this.form.controls.user.value?.name,
        timeOffType: this.form.controls.timeOffType.value?.name,
        date: this.datePipe.transform(
          DateTime.fromISO(this.form.controls.date.value)?.toISODate(),
          'shortDate',
        ),
      },
    );

    this.name$.next(name);
    return name;
  }

  public save(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.form.markAllAsTouched();

      if (this.form.invalid) {
        this.notification.warningLocal('shared.messages.requiredFieldsError');
        reject();
        return;
      }

      const formData = this.form.getRawValue();
      const entry = {
        id: this.entryId ?? Guid.generate(),
        typeId: formData.typeId,
        userId: formData.user?.id,
        timeOffTypeId: formData.timeOffType?.id,
        modeId: this.form.value.mode?.id ?? TimeOffBalanceEntryMode.manual.id,
        amount: formData.amount,
        date: formData.date,
      };

      this.saving$.next(true);

      const collection = this.data.collection('TimeOffBalanceEntries');
      const action = this.entryId
        ? collection.entity(this.entryId).update(entry)
        : collection.insert(entry);

      action.subscribe({
        next: () => {
          this.saving$.next(false);
          this.form.markAsPristine();
          this.notification.successLocal(
            'team.timeOffBalanceEntries.card.messages.saved',
          );
          resolve();
        },
        error: (error: Exception) => {
          this.saving$.next(false);
          reject();
          this.notification.error(error.message);
        },
      });
    });
  }

  public reload() {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  private load() {
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.state$.next(CardState.Loading);

    this.timeOffTypeUnits = TimeOffTypeUnits.map((unit) => ({
      id: unit.id,
      name: this.translate.instant(`enums.timeOffTypeUnit.${unit.code}`),
    }));

    // Загрузка данных.
    this.data
      .collection('TimeOffBalanceEntries')
      .entity(this.entryId)
      .get<TimeOffBalanceEntry>({
        expand: {
          user: { select: ['id', 'name'] },
          type: { select: ['id', 'name'] },
          timeOffType: {
            select: ['id', 'name', 'unitId'],
          },
          mode: {
            select: ['id', 'name'],
          },
        },
      })
      .subscribe({
        next: (entry: TimeOffBalanceEntry) => {
          this.form.patchValue(entry, { emitEvent: false });

          this.form.controls.typeId.setValue(entry.type.id, {
            emitEvent: false,
          });

          this.form.controls.mode.setValue(entry.mode);

          this.form.controls.unit.setValue(
            this.timeOffTypeUnits.find(
              (cu) => cu.id === entry.timeOffType.unitId,
            ),
          );

          this.readonly = !entry.editAllowed;

          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.readonly
            ? this.form.disable({ emitEvent: false })
            : this.form.enable({ emitEvent: false });

          this.actionService.action('save').isShown = !this.readonly;

          const name = this.propagateName();

          this.navigationService.addRouteSegment({ title: name, id: entry.id });

          this.state$.next(CardState.Ready);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.state$.next(CardState.Error);
        },
      });
  }
}
