import { Component, OnInit, Input, inject, DestroyRef } from '@angular/core';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import { ScheduleExceptionsToolbarComponent } from './exceptions-toolbar/schedule-exceptions-toolbar.component';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormGroup,
  FormArray,
} from '@angular/forms';
import {
  GridDateControlColumn,
  GridColumnType,
  GridNumberControlColumn,
  GridStringControlColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { uniq } from 'lodash';
import { ScheduleException } from 'src/app/shared/models/entities/settings/schedule-exception.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationService } from 'src/app/core/navigation.service';
import { BehaviorSubject } from 'rxjs';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';

@Component({
  selector: 'tmt-schedule-exceptions',
  templateUrl: './schedule-exception-card.component.html',
  providers: [GridService],
})
export class ScheduleExceptionCardComponent implements OnInit {
  @Input() entityId: string;

  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public isSaving: boolean;
  public readonly: boolean;

  public cardState = CardState;
  public form: FormGroup = this.fb.group({
    id: [''],
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    isActive: [false],
    exceptionDays: this.fb.array([]),
  });

  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    toolbar: ScheduleExceptionsToolbarComponent,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          const exceptionDays = this.form.controls.exceptionDays as FormArray;
          exceptionDays.removeAt(index);
          exceptionDays.markAsDirty();
        },
      },
    ],
    commands: [
      {
        name: 'add',
        handlerFn: () => {
          const exceptionDays = this.form.controls.exceptionDays as FormArray;
          const group = this.getExceptionDayFormGroup();
          exceptionDays.insert(0, group);
          exceptionDays.markAsDirty();
          this.gridService.selectGroup(group);
          this.gridService.detectChanges();
        },
      },
    ],
    view: {
      name: 'exceptions',
      columns: [
        <GridDateControlColumn>{
          name: 'date',
          header: 'settings.scheduleExceptions.card.columns.date',
          hint: 'settings.scheduleExceptions.card.columns.date',
          type: GridColumnType.DateControl,
          width: '150px',
        },
        <GridStringControlColumn>{
          name: 'name',
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.StringControl,
          width: '100%',
        },
        <GridNumberControlColumn>{
          name: 'dayLength',
          header: 'settings.scheduleExceptions.card.columns.duration.header',
          hint: 'settings.scheduleExceptions.card.columns.duration.hint',
          type: GridColumnType.NumberControl,
          controlType: 'decimal',
          width: '200px',
          min: 0,
          max: 24,
        },
        {
          name: 'excludeFromCalendarDuration',
          header:
            'settings.scheduleExceptions.card.columns.excludeFromCalendarDuration.header',
          hint: 'settings.scheduleExceptions.card.columns.excludeFromCalendarDuration.hint',
          type: GridColumnType.BooleanControl,
          width: '200px',
        },
      ],
    },
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    private gridService: GridService,
    private notification: NotificationService,
    private data: DataService,
    private message: MessageService,
    private actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private navigationServe: NavigationService,
  ) {
    actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'settings.scheduleExceptions.card.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
    ]);

    this.load();
  }

  private getExceptionDayFormGroup(): UntypedFormGroup {
    return this.fb.group({
      id: Guid.generate(),
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      date: [null, Validators.required],
      dayLength: [
        0,
        [Validators.required, Validators.min(0), Validators.max(24)],
      ],
      excludeFromCalendarDuration: [true],
    });
  }

  public save() {
    this.form.markAllAsTouched();
    this.gridService.detectChanges();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    const exceptionDays = this.form.controls.exceptionDays as FormArray;

    // Проверка уникальности дат.
    if (
      uniq(exceptionDays.controls.map((v) => v.value.date)).length !==
      exceptionDays.length
    ) {
      this.notification.warningLocal(
        'settings.scheduleExceptions.card.messages.dateUniqueError',
      );
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const data = this.form.value;
    this.data
      .collection('scheduleExceptions')
      .entity(this.entityId)
      .update(data)
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.scheduleExceptions.card.messages.saved',
          );
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
          this.actionService.action('save').stop();
        },
      });
  }

  private load() {
    this.state$.next(CardState.Loading);

    this.form.reset();
    const exceptionDays = this.form.controls.exceptionDays as FormArray;
    exceptionDays.clear();

    this.data
      .collection('ScheduleExceptions')
      .entity(this.entityId)
      .get<ScheduleException>({
        expand: [{ exceptionDays: { orderBy: 'date desc' } }],
      })
      .subscribe({
        next: (scheduleException: ScheduleException) => {
          this.form.patchValue(scheduleException);

          (scheduleException.exceptionDays as any[]).forEach((exceptionDay) => {
            const group = this.getExceptionDayFormGroup();
            group.patchValue(exceptionDay);

            exceptionDays.push(group);
          });

          // Доступность операций.
          this.readonly = !scheduleException.editAllowed;
          if (scheduleException.editAllowed) {
            this.actionService.action('save').show();
            this.form.enable();
          } else {
            this.form.disable();
          }

          this.navigationServe.addRouteSegment({
            id: this.entityId,
            title: scheduleException.name,
          });
          this.state$.next(CardState.Ready);
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          this.notification.error(error.message);
        },
      });
  }

  reload() {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }
}
