import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AutosaveStateService {
  private isSavingSubject = new Subject<boolean>();

  /** Состояние выполнения сохранения. */
  public isSaving$ = this.isSavingSubject.asObservable();

  public setState(isSaving: boolean) {
    this.isSavingSubject.next(isSaving);
  }
}
