import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { filter } from 'rxjs/operators';
import { Client } from 'src/app/shared/models/entities/projects/client.model';
import { NotificationService } from 'src/app/core/notification.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { NavigationService } from 'src/app/core/navigation.service';

@Injectable()
export class ClientCardService {
  private clientId: string;
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  private reloadTabSubject = new Subject<void>();
  public reloadTab$ = this.reloadTabSubject.asObservable();

  private clientTotalSubject = new BehaviorSubject<any>(null);
  public clientTotal$ = this.clientTotalSubject
    .asObservable()
    .pipe(filter((p) => p));

  private clientSubject = new BehaviorSubject<Client>(null);
  public client$ = this.clientSubject.asObservable().pipe(filter((p) => !!p));

  constructor(
    private data: DataService,
    private notification: NotificationService,
    private navigationService: NavigationService,
  ) {}

  /** Reloads tab. */
  public reloadTab() {
    this.reloadTabSubject.next();
  }

  /** Loads data. */
  public load(clientId: string): void {
    this.clientId = clientId;
    this.state$.next(CardState.Loading);

    this.loadClient();
    this.loadClientTotal();
  }

  private loadClientTotal(): void {
    const query = {
      select: ['actualHours'],
    };

    this.data
      .collection('OrganizationTotals')
      .entity(this.clientId)
      .get(query)
      .subscribe({
        next: (clientTotal: any) => {
          this.clientTotalSubject.next(clientTotal);
        },
      });
  }

  private loadClient(): void {
    const query = {
      select: [
        'id',
        'name',
        'isActive',
        'editAllowed',
        'tariffsEditAllowed',
        'tariffsViewAllowed',
      ],
      expand: [{ manager: { select: ['name', 'id'] } }],
    };

    this.data
      .collection('Organizations')
      .entity(this.clientId)
      .get(query)
      .subscribe({
        next: (client: Client) => {
          this.navigationService.addRouteSegment({
            id: client.id,
            title: client.name,
          });

          this.clientSubject.next(client);
          this.state$.next(CardState.Ready);
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notification.error(error.message);
          }
        },
      });
  }
}
