import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { UserCardService } from '../user-card.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MessageService } from 'src/app/core/message.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TimeInputType } from 'src/app/shared/models/enums/time-input-type';
import { TranslateService } from '@ngx-translate/core';
import { Exception } from 'src/app/shared/models/exception';
import { bcp47Normalize } from 'bcp-47-normalize';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wp-user-settings',
  templateUrl: './user-settings.component.html',
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  @Input() entityId: string;
  reloadListener: Subscription;
  readonly: boolean;
  loading: boolean;
  timeInputTypes: NamedEntity[];
  cultures: NamedEntity[];
  languages: NamedEntity[];

  private _isSaving: boolean;
  destroyed$ = new Subject<void>();
  get isSaving() {
    return this._isSaving;
  }
  set isSaving(value: boolean) {
    this._isSaving = value;

    if (value) {
      this.blockUI.start();
      this.actionService.action('save').start();
    } else {
      this.actionService.action('save').stop();
      this.blockUI.stop();
    }
  }

  form: UntypedFormGroup = this.fb.group({
    timeZone: [null, Validators.required],
    language: [null, Validators.required],
    culture: [null, Validators.required],
    timeInputType: [null, Validators.required],
    useStopwatch: [false],
  });

  constructor(
    private notification: NotificationService,
    private translate: TranslateService,
    private blockUI: BlockUIService,
    private data: DataService,
    private actionService: ActionPanelService,
    private service: UserCardService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
  ) {}

  load() {
    this.loading = true;
    const params = {
      expand: ['timeZone'],
    };

    forkJoin({
      cultures: this.data.model.function('GetCultures').get<NamedEntity[]>(),
      languages: this.data.model.function('GetLanguages').get<NamedEntity[]>(),
      settings: this.data
        .collection('UserSettings')
        .entity(this.entityId)
        .get<any>(params),
    }).subscribe({
      next: (data) => {
        this.languages = data.languages;
        this.cultures = data.cultures;

        this.form.patchValue(data.settings);

        // Выбор типа ввода времени
        const timeInputType = this.timeInputTypes.find(
          (x: NamedEntity) => x.id === data.settings.timeInputType,
        );
        this.form.controls['timeInputType'].setValue(timeInputType);

        this.form.controls['language'].setValue(
          data.languages.find((l) => l.id === data.settings.languageCode),
        );

        this.form.controls['culture'].setValue(
          data.cultures.find(
            (l) => l.id === bcp47Normalize(data.settings.cultureCode),
          ),
        );

        this.readonly = !data.settings.editAllowed;
        this.readonly ? this.form.disable() : this.form.enable();
        this.actionService.action('save').isShown = !this.readonly;
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.loading = false;
      },
      error: (error: any) => {
        this.loading = false;
        this.notification.error(error.message);
      },
    });
  }

  save() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.isSaving = true;

      const value = this.form.value;

      const data = {
        timeZoneId: value.timeZone.id,
        languageCode: value.language.id,
        cultureCode: value.culture.id,
        timeInputType: value.timeInputType.id,
        useStopwatch: value.useStopwatch,
      };

      this.data
        .collection('UserSettings')
        .entity(this.entityId)
        .patch(data)
        .subscribe({
          next: () => {
            this.notification.successLocal('shared.messages.saved');
            this.isSaving = false;
            this.form.markAsPristine();
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.isSaving = false;
          },
        });
    } else {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
    }
  }

  ngOnInit() {
    this.actionService.run$
      .pipe(
        filter((x) => x.name === 'save'),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.save();
      });

    this.timeInputTypes = [];
    for (const timeInputType of Object.keys(TimeInputType)) {
      this.timeInputTypes.push({
        id: timeInputType,
        name: this.translate.instant(`enums.timeInputType.${timeInputType}`),
      });
    }

    this.reloadListener = this.actionService.reload$.subscribe(() => {
      this.reload();
    });
    this.load();
  }

  private reload() {
    this.form.enable();
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  ngOnDestroy(): void {
    this.reloadListener.unsubscribe();
    this.destroyed$.next();
  }
}
