<div class="row">
  <div class="col-9">
    <div name="filterFields" style="margin-bottom: 15px" [hidden]="criteria.length == 0">
      <div *ngFor="let criterion of criteria; index as index; trackBy: trackByName">
        <wp-report-filter-criterion
          [criterion]="criterion"
          [allowInactive]="allowInactiveControl.value"
          (onRemove)="removeCriterion(criterion)"
          (onChange)="criterionChanged($event, index)"
        >
        </wp-report-filter-criterion>
      </div>
    </div>

    @if (fieldPickerIsVisible || !criteria.length) {
      <div class="col-4" [formGroup]="sourceForm">
        <wp-select-box
          [values]="sourceFieldsForPicker"
          groupHandler="groupTitle"
          optionLabel="title"
          formControlName="source"
          [filterOnServer]="false"
          [allowNull]="false"
          [placeholder]="'analytics.reportFilters.filterFieldPicker.placeholder' | translate"
        />
      </div>
    }
    <button
      *ngIf="!fieldPickerIsVisible && criteria.length > 0"
      class="btn btn-link p-0"
      (click)="fieldPickerIsVisible = true"
    >
      {{ 'analytics.reportFilters.actions.addCriterion' | translate }}
    </button>
  </div>
  <div class="col-3" style="text-align: right">
    <div class="form-check" [hidden]="criteria.length === 0" style="padding-bottom: 15px">
      <label class="form-check-label">
        <input [formControl]="allowInactiveControl" type="checkbox" class="form-check-input" />{{
          'shared2.filters.allowNonActiveEntityToSelected' | translate
        }}
      </label>
    </div>
  </div>
</div>
