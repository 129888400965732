<form class="modal-form" novalidate [formGroup]="locationForm" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'settings.locations.creation.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label class="control-label">{{ 'shared.props.name' | translate }}</label>
      <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}">
      </wp-text-box>
    </div>
    <div class="form-group" style="width: 150px">
      <label>{{ 'shared.props.code' | translate }}</label>
      <wp-text-box
        formControlName="code"
        placeholder="{{ 'shared.props.code' | translate }}"
      ></wp-text-box>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
