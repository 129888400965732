import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { TimesheetFilterService } from './filter/timesheet-filter.service';
import { StateService } from '@uirouter/core';
import { LifecycleListService } from 'src/app/core/lifecycle-list.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-timesheet-list',
  template: '<wp-entity-list></wp-entity-list>',
  providers: [
    { provide: FilterService, useClass: TimesheetFilterService },
    EntityListService,
    ListService,
    GridService,
    LifecycleListService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetListComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private state: StateService,
    private gridService: GridService,
    private actions: ActionPanelService,
    private listService: EntityListService,
    private lifecycleListService: LifecycleListService,
  ) {}

  public ngOnInit(): void {
    this.actions.set([
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go(`timesheet`, {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actions.getDefaultAction('delete', {
        handler: () => {
          this.listService.delete();
        },
      }),
    ]);

    this.gridService.selectedGroups$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((rows: UntypedFormGroup[]) => {
        this.actions.action('card').isShown = rows?.length === 1;
      });
  }
}
