import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountingEntryService } from '../core/accounting-entry.service';

@Component({
  selector: 'wp-accounting-entry-creation',
  templateUrl: './accounting-entry-creation.component.html',
  providers: [AccountingEntryService],
})
export class AccountingEntryCreationComponent implements OnInit {
  public isSaving = false;

  constructor(
    public service: AccountingEntryService,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.service.init(true);
  }

  /**
   * Modal window close event handler.
   * */
  public ok() {
    this.service.save().then(
      () => {
        this.service.modalClosed$.next();
        this.activeModal.close('reload');
      },
      () => null,
    );
  }

  /**
   * Modal window cancel event handler.
   * */
  public cancel() {
    this.service.modalClosed$.next();
    this.activeModal.dismiss('cancel');
  }
}
