<wp-loading-indicator [loading]="employeeMainService.loading$ | async" />

<div [hidden]="!(employeeMainService.user$ | async) || (employeeMainService.loading$ | async)">
  <form class="form pt-3 d-flex" [formGroup]="form">
    <div class="container-fluid mx-0">
      <div class="row">
        <wp-data-not-saved-indicator [form]="form" />

        <div class="row">
          <div class="col-12">
            <label class="group">{{
              'components.employeeMainComponent.props.employeeGroup' | translate
            }}</label>
          </div>
        </div>
        <div class="profile-col">
          <div class="row">
            @if (form.enabled) {
              <div class="form-group col-4" tmtIndicator>
                <label>{{ 'shared2.props.email' | translate }}</label>
                <wp-email-box
                  formControlName="email"
                  placeholder="{{ 'shared2.props.email' | translate }}"
                />
              </div>
            } @else {
              <div class="form-group col-4">
                <label>{{ 'shared2.props.email' | translate }}</label>
                <div class="form-control-plaintext">
                  @if (form.getRawValue().email) {
                    <a href="{{ 'mailto://' + form.getRawValue().email }}">{{
                      form.getRawValue().email
                    }}</a>
                  } @else {
                    <p class="text-body-secondary text-uppercase">
                      {{ 'shared2.empty' | translate }}
                    </p>
                  }
                </div>
              </div>
            }
            <div class="form-group col-4">
              <label>{{ 'shared2.props.code' | translate }}</label>
              <wp-text-box
                formControlName="code"
                placeholder="{{ 'shared2.props.code' | translate }}"
              />
            </div>
            <div class="form-group col-4">
              <label>{{ 'shared2.props.position' | translate }}</label>
              <wp-text-box
                formControlName="position"
                placeholder="{{ 'shared2.props.position' | translate }}"
              />
            </div>
            <div class="form-group col-4">
              <label>{{ 'shared2.props.phone' | translate }}</label>
              <wp-text-box
                formControlName="phone"
                placeholder="{{ 'shared2.props.phone' | translate }}"
              />
            </div>
            <div class="form-group col-4">
              <label>{{ 'components.employeeMainComponent.props.workPeriod' | translate }}</label>
              @if (form.enabled) {
                <div class="align-items-center d-flex justify-content-between">
                  <wp-date-box formControlName="firstWorkDay" />&nbsp;&ndash;&nbsp;<wp-date-box
                    formControlName="firstWorkDay"
                  />
                </div>
              } @else {
                <div class="form-control-plaintext">
                  {{ form.getRawValue().firstWorkDay | date: 'LLLL y' }}&nbsp;&ndash;&nbsp;{{
                    form.getRawValue().lastWorkDay | date: 'LLLL y'
                  }}
                </div>
              }
            </div>

            <div class="form-group col-4">
              <label>{{
                'components.employeeMainComponent.props.isActive.label' | translate
              }}</label>
              @if (form.enabled) {
                <div
                  class="form-check mt-1"
                  title="{{ 'components.employeeMainComponent.props.isActive.active' | translate }}"
                >
                  <input
                    formControlName="isActive"
                    type="checkbox"
                    class="form-check-input"
                    id="isActive"
                  />
                  <label class="form-check-label" for="isActive">
                    {{ 'components.employeeMainComponent.props.isActive.active' | translate }}
                  </label>
                </div>
              } @else {
                <div class="form-control-plaintext">
                  @if (form.getRawValue().isActive) {
                    {{ 'components.employeeMainComponent.props.isActive.active' | translate }}
                  } @else {
                    {{ 'components.employeeMainComponent.props.isActive.nonActive' | translate }}
                  }
                </div>
              }
            </div>
          </div>
          <wp-custom-fields
            [formGroup]="form"
            entityType="User"
            columnClasses="col-4"
            [isVisibleHr]="false"
          />
          <div class="row">
            <div class="col-12">
              <label class="group">{{
                'components.employeeMainComponent.props.resourceGroup' | translate
              }}</label>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>{{ 'shared2.props.level' | translate }}</label>
                <wp-select-box
                  #cascadeControl
                  formControlName="level"
                  collection="Levels"
                  placeholder="{{ 'shared2.props.level' | translate }}"
                />
              </div>
              <div class="form-group">
                <label>{{ 'shared2.props.grade' | translate }}</label>
                <wp-select-box
                  #cascadeControl
                  formControlName="grade"
                  collection="Grades"
                  placeholder="{{ 'shared2.props.grade' | translate }}"
                />
              </div>
              <div class="form-group" tmtIndicator>
                <label>{{ 'shared2.props.resourcePool' | translate }}</label>
                <tmt-hierarchical-box
                  collection="ResourcePools"
                  parentIdKey="leadResourcePoolId"
                  formControlName="resourcePool"
                  placeholder="{{ 'shared2.props.resourcePool' | translate }}"
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>{{ 'shared2.props.role' | translate }}</label>
                <wp-select-box
                  #cascadeControl
                  formControlName="role"
                  collection="Roles"
                  placeholder="{{ 'shared2.props.role' | translate }}"
                />
              </div>
              <div class="form-group">
                <label>{{ 'shared2.props.competence' | translate }}</label>
                <wp-select-box
                  #cascadeControl
                  formControlName="competence"
                  collection="Competences"
                  placeholder="{{ 'shared2.props.competence' | translate }}"
                />
              </div>
              <div class="form-group">
                <label>{{ 'shared2.props.location' | translate }}</label>
                <wp-select-box
                  formControlName="location"
                  collection="Locations"
                  placeholder="{{ 'shared2.props.location' | translate }}"
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>{{ 'shared2.props.supervisor' | translate }}</label>
                <wp-user-box
                  formControlName="supervisor"
                  placeholder="{{ 'shared2.props.supervisor' | translate }}"
                />
              </div>
              <div class="form-group">
                <label>{{ 'shared2.props.department' | translate }}</label>
                <tmt-department-box
                  formControlName="department"
                  placeholder="{{ 'shared2.props.department' | translate }}"
                />
              </div>
              <div class="form-group" tmtIndicator>
                <label>{{ 'shared2.props.legalEntity' | translate }}</label>
                <wp-select-box
                  formControlName="legalEntity"
                  collection="LegalEntities"
                  placeholder="{{ 'shared2.props.legalEntity' | translate }}"
                />
              </div>
            </div>
          </div>

          @if (form.disabled && isProfileEditable) {
            <div class="row">
              <div class="profile-btns-wrap">
                <button class="btn btn-default" (click)="makeProfileEditable()">
                  {{ 'components.employeeMainComponent.actions.edit' | translate }}
                </button>
              </div>
            </div>
          }
          @if (form.enabled) {
            <div class="row">
              <div class="profile-btns-wrap">
                <button class="btn btn-primary" (click)="saveProfile()">
                  {{ 'shared2.actions.save' | translate }}
                </button>
                <button class="btn btn-default" (click)="onProfileEditingCancel()">
                  {{ 'shared2.actions.cancel' | translate }}
                </button>
              </div>
            </div>
          }
        </div>
        <div class="avatar-col">
          <div class="form-group avatar">
            <img [ngSrc]="(employeeMainService.user$ | async)?.id | avatar" fill />
          </div>
        </div>
      </div>
    </div>
  </form>
  @if ((employeeCardService.user$ | async).userRolesEditAllowed) {
    @if (!isShowAdditionalRolesGrid) {
      <button class="btn btn-link p-0 mt-4" (click)="isShowAdditionalRolesGrid = true">
        {{ 'components.employeeMainComponent.actions.addMoreRolesAndCompetences' | translate }}
      </button>
    } @else {
      <form class="form mt-4">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <label class="group additional-roles-group">{{
                'components.employeeMainComponent.groups.additionalRolesAndCompetences' | translate
              }}</label>
              <wp-resource-roles [userId]="userId" resourceType="user" />
            </div>
          </div>
        </div>
      </form>
    }
  }
  <form class="form mt-4 d-flex">
    <div class="container-fluid mx-0">
      <div class="row">
        <div class="col-6">
          <label class="group">
            {{ 'components.employeeMainComponent.groups.skills' | translate }}
          </label>
          @if (!readonly) {
            <div class="toolbar-container pt-0">
              <button class="btn btn-default" (click)="addSkills()">
                <span class="bi bi-plus-lg bi-15"></span>&nbsp;
                {{ 'components.employeeMainComponent.actions.addSkills' | translate }}
              </button>
            </div>
          }
          <wp-grid [formArray]="skills" [options]="gridOptions" [readonly]="readonly" />
        </div>
        <tmt-user-certificates class="col-6" />
      </div>
    </div>
  </form>
</div>
