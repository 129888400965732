import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ProjectResourceSettings } from 'src/app/projects/card/project-resources/models/project-resource-settings.model';

@Injectable()
export class ProjectResourceService {
  public undoRedoSessionId: string;

  private isShowTaskDuration = new BehaviorSubject<boolean>(true);
  public isShowTaskDuration$ = this.isShowTaskDuration.asObservable();

  constructor(private localStorageService: LocalConfigService) {
    this.isShowTaskDuration.next(
      localStorageService.getConfig(ProjectResourceSettings).isShowTaskDuration,
    );
  }

  /**
   * Set isShowTaskDuration value.
   *
   * @param value Show or not show.
   *
   * */
  public setShowTaskDuration(value: boolean): void {
    const settings = this.localStorageService.getConfig(
      ProjectResourceSettings,
    );

    if (settings.isShowTaskDuration === value) {
      return;
    }

    settings.isShowTaskDuration = value;
    this.localStorageService.setConfig(ProjectResourceSettings, settings);

    this.isShowTaskDuration.next(settings.isShowTaskDuration);
  }
}
