<label class="group">
  {{ 'settings.permissionSets.granules.groupData' | translate }}
</label>
<table class="table table-bordered compact-table" style="table-layout: fixed; width: 450px">
  <thead>
    <tr>
      <th style="width: 250px; vertical-align: middle" rowspan="2">
        {{ 'settings.permissionSets.granules.granular' | translate }}
      </th>
      <th style="width: 200px; text-align: center" colspan="2">
        {{ 'settings.permissionSets.scopes.myPools' | translate }}
      </th>
      <th style="width: 200px; text-align: center" colspan="2">
        {{ 'settings.permissionSets.scopes.allResources' | translate }}
      </th>
    </tr>
    <tr>
      <td class="text-center">
        {{ 'settings.permissionSets.permissions.view' | translate }}
      </td>
      <td class="text-center">
        {{ 'settings.permissionSets.permissions.edit' | translate }}
      </td>
      <td class="text-center">
        {{ 'settings.permissionSets.permissions.view' | translate }}
      </td>
      <td class="text-center">
        {{ 'settings.permissionSets.permissions.edit' | translate }}
      </td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        {{ 'settings.permissionSets.granules.booking' | translate }}
      </td>

      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Booking.MyPools.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Booking.MyPools.editEnabled')"
          type="checkbox"
        />
      </td>

      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Booking.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Booking.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>&nbsp;&nbsp;&nbsp;Soft</td>

      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Booking.MyPoolsS.editEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Booking.AllS.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>&nbsp;&nbsp;&nbsp;Hard</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Booking.MyPoolsH.editEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Booking.AllH.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.resourceRequest' | translate }}
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>

    <tr>
      <td>&nbsp;&nbsp;&nbsp;{{ 'settings.permissionSets.granules.request' | translate }}</td>

      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ResourceRequest.MyResourcePools.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ResourceRequest.MyResourcePools.editEnabled')"
          type="checkbox"
        />
      </td>

      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ResourceRequest.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ResourceRequest.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>
        &nbsp;&nbsp;&nbsp;{{ 'settings.permissionSets.granules.resourceRequestResult' | translate }}
      </td>

      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ResourceRequestResult.MyResourcePools.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ResourceRequestResult.MyResourcePoolsO.editEnabled')"
          type="checkbox"
        />
      </td>

      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ResourceRequestResult.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ResourceRequestResult.AllO.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>
        {{ 'settings.permissionSets.granules.employees' | translate }}
      </td>

      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('User.MyPools.editEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('User.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>
        {{ 'settings.permissionSets.granules.employeeIndicators' | translate }}
      </td>

      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserTotal.MyPools.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserTotal.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
    </tr>
  </tbody>
</table>

<label class="group">
  {{ 'settings.permissionSets.granules.groupActions' | translate }}
</label>
<table class="table table-bordered compact-table" style="table-layout: fixed; width: 450px">
  <thead>
    <tr>
      <th style="width: 250px; vertical-align: middle" rowspan="2">
        {{ 'settings.permissionSets.granules.granular' | translate }}
      </th>
      <th style="width: 200px; text-align: center">
        {{ 'settings.permissionSets.scopes.myPools' | translate }}
      </th>
      <th style="width: 200px; text-align: center">
        {{ 'settings.permissionSets.scopes.allResources' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        {{ 'settings.permissionSets.granules.bookingSwitchToHard' | translate }}
      </td>

      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('BookingSwitchToHard.MyResourcePools.actionEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('BookingSwitchToHard.All.actionEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>
        {{ 'settings.permissionSets.granules.bookingSwitchToSoft' | translate }}
      </td>

      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('BookingSwitchToSoft.MyResourcePools.actionEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('BookingSwitchToSoft.All.actionEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
  </tbody>
</table>
