@if (projectId) {
  <div class="wrap" (clickOutside)="closeSelect()" [attachOutsideOnClick]="true">
    <div
      class="face"
      (click)="openSelect()"
      title="{{ value?.name }}"
      [ngClass]="{ readonly: readonly }"
    >
      @if (value) {
        <div class="trim">{{ value?.name }}</div>
      } @else {
        <div class="trim placeholder">
          {{ 'timesheets.card.columns.projectTariff.header' | translate }}
        </div>
      }
      <i class="bi bi-chevron-down expander" aria-hidden="true"></i>
    </div>
    @if (areaExpanded) {
      <div class="expanding-area expanding-area-vertical-scroll">
        <wp-loading-indicator [loading]="loading" />
        <div class="selecting-list" [hidden]="loading">
          @for (projectTariff of projectTariffs | async; track projectTariff.id) {
            <div
              class="options trim"
              (click)="selectNewValue(projectTariff)"
              title="{{ projectTariff.name }}"
              [ngClass]="{ 'selected-row': projectTariff.id == value?.id }"
              [attr.data-test-id]="projectTariff.id"
            >
              {{ projectTariff.name }}
            </div>
          } @empty {
            <div class="center-block text-body-secondary">
              {{ 'shared.noOptions' | translate }}
            </div>
          }
        </div>
      </div>
    }
  </div>
}
