<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.projects.changingProgram.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label>{{
        'projects.projects.changingProgram.properties.currentProgram.label' | translate
      }}</label>
      @if (project.program) {
        <p class="pt-1">{{ project.program.name }}</p>
      } @else {
        <p class="text-body-secondary">{{ 'shared.valueNotSelected' | translate }}</p>
      }
    </div>

    <div class="form-group" style="width: 350px">
      <label>{{
        'projects.projects.changingProgram.properties.newProgram.label' | translate
      }}</label>
      <wp-select-box
        [collection]="'Programs'"
        [query]="query"
        placeholder="{{
          'projects.projects.changingProgram.properties.newProgram.placeholder' | translate
        }}"
        formControlName="program"
      />
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="change()"
      style="width: 100px"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.ok' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-default"
      (click)="cancel()"
      style="width: 100px"
      [disabled]="isSaving"
    >
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
