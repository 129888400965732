import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewRef,
  Optional,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Wrapper } from '../../wrapper.interface';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { NumberBoxComponent } from 'src/app/shared/components/controls/number-box/number-box.component';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';

@Component({
  selector: 'wp-number-box-wrapper',
  template: `<wp-number-box
    [formControl]="control"
    [type]="type"
    [min]="min"
    [max]="max"
    [precision]="precision"
    [allowNull]="allowNull"
    [currencyCode]="currencyCode"
    [autofocus]="autofocus"
    [readonly]="readonly"
    [propagationMode]="propagationMode"
    [initialValue]="initialValue"
    #component
  ></wp-number-box>`,
})
export class NumberBoxWrapperComponent implements Wrapper, OnInit {
  @Input() control: UntypedFormControl;

  @Input() values: NamedEntity[];
  @Input() type: string;
  @Input() min: number;
  @Input() max: number;
  @Input() precision: number;
  @Input() allowNull: boolean;
  @Input() currencyCode: string;
  @Input() autofocus?: boolean;
  @Input() readonly?: boolean;
  /** Initial value for input element after rendering. */
  @Input() initialValue?: unknown;
  @ViewChild('component') component: NumberBoxComponent;
  @Input() propagationMode: PropagationMode = PropagationMode.onInput;

  constructor(
    @Optional() gridService: GridService,
    private ref: ChangeDetectorRef,
  ) {
    if (gridService) {
      gridService.detectChanges$.subscribe(() => {
        if (!(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    }
  }

  ngOnInit(): void {
    if (this.allowNull === undefined) {
      this.allowNull = true;
    }
  }
}
