import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { DateTime, DurationLike, Interval } from 'luxon';
import { flatten, sumBy } from 'lodash';
import { AppService } from 'src/app/core/app.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { ScheduleNavigationContext } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-context.enum';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import {
  PlanningScale,
  getUnitFromPlanningScale,
} from 'src/app/shared/models/enums/planning-scale.enum';
import { ProjectResourcesForecastCalendarDataService } from './project-resources-forecast-calendar-data.service';
import { ForecastSlot } from 'src/app/projects/card/project-resources/models/forecast-slot';
import { SlotGroup } from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { ResourceForecastCalendarPlannerSettings } from 'src/app/projects/card/project-resources/models/resource-forecast-calendar-settings.model';
import { ProjectResourcesCalendarCommandService } from 'src/app/projects/card/project-resources/shared/core/project-resources-calendar-command.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { UndoRedoService } from 'src/app/shared/services/undo-redo/undo-redo.service';
import { UndoRedoEntry } from 'src/app/projects/card/project-resources/models/project-resources-view.model';
import { EntityState } from 'src/app/shared/models/entities/entity.model';

@Injectable()
export class ProjectResourcesForecastCalendarService {
  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  private toggleSubject = new Subject<string>();
  public toggle$ = this.toggleSubject.asObservable();

  public loading$ = new BehaviorSubject<boolean>(true);
  public frameLoading$ = new BehaviorSubject<boolean>(false);

  // View
  public leftTableWidth = 410;
  public rightTableWidth;

  // State
  public readonly: boolean;

  public slots: ForecastSlot[];
  public slotGroups: SlotGroup[];
  public slotTotals = [];

  /** Current slot width in px. */
  public slotWidth: number;

  // Subscriptions, Observables and Subjects
  private subscriptions: Subscription[] = [];

  private _settings: ResourceForecastCalendarPlannerSettings;
  private set settings(settings: ResourceForecastCalendarPlannerSettings) {
    this._settings = settings;
    this.slotWidth = this.getSlotWidth();
  }
  private get settings(): ResourceForecastCalendarPlannerSettings {
    return this._settings;
  }

  private interval: Interval;

  public get planningScale(): PlanningScale {
    return this.settings.planningScale;
  }
  public get valueMode(): ValueMode {
    return this.settings.valueMode;
  }
  public get showOtherActual(): boolean {
    return this.settings.showOtherActual;
  }

  public get totalHours(): number {
    let calculatedGroups = this.dataService.groups;
    if (this.showOtherActual) {
      calculatedGroups = [
        ...calculatedGroups,
        this.dataService.otherActualGroup,
      ];
    }
    return sumBy(calculatedGroups, 'totalHours');
  }
  public get totalCost(): number {
    let calculatedGroups = this.dataService.groups;
    if (this.showOtherActual) {
      calculatedGroups = [
        ...calculatedGroups,
        this.dataService.otherActualGroup,
      ];
    }
    return sumBy(calculatedGroups, 'totalCost');
  }

  public get currentPeriodSlot() {
    return this.slots.find((slot) => slot.today);
  }

  constructor(
    @Inject('entityId') public projectId,
    private dataService: ProjectResourcesForecastCalendarDataService,
    private navigationService: ScheduleNavigationService,
    private localConfigService: LocalConfigService,
    private projectCardService: ProjectCardService,
    private versionCardService: ProjectVersionCardService,
    private commandService: ProjectResourcesCalendarCommandService,
    private autosave: SavingQueueService,
    private freezeTableService: FreezeTableService,
    private blockUI: BlockUIService,
    private appService: AppService,
    private undoRedoService: UndoRedoService,
  ) {}

  /**
   * Inits planning scale, navigation, subscription and loads
   */
  public init() {
    this.navigationService.init(ScheduleNavigationContext.ResourcesCalendar);

    // Set planning scale from session
    const forecastPeriod = this.appService.session.forecastPeriod;
    const scaleFromForecastPeriod = PlanningScale[forecastPeriod];
    this.navigationService.setPlanningScale(
      scaleFromForecastPeriod ?? PlanningScale.Day,
    );

    // Navigation
    this.settings = this.localConfigService.getConfig(
      ResourceForecastCalendarPlannerSettings,
    );
    this.subscriptions.push(
      this.navigationService.next$.subscribe(() =>
        this.dataService.save().then(() => this.loadFrame('right')),
      ),
      this.navigationService.previous$.subscribe(() =>
        this.dataService.save().then(() => this.loadFrame('left')),
      ),
      this.navigationService.jump$.subscribe((date) =>
        this.dataService.save().then(() => this.reload(date)),
      ),
      this.navigationService.valueMode$.subscribe((mode) =>
        this.dataService.save().then(() => this.reload()),
      ),

      this.projectCardService.reloadTab$.subscribe(() =>
        this.dataService.save().then(() => this.reload()),
      ),
    );

    // Changes
    this.subscriptions.push(
      this.projectCardService.project$.subscribe((project: Project) => {
        this.updateReadOnly(project.resourcePlanEditAllowed);
      }),
      this.commandService.changes$.subscribe(() => this.reload()),
      this.autosave.save$.subscribe((data) => {
        if (data.warnings) {
          this.commandService.showWarnings(data.warnings);
        }

        // Silent reload after undo/redo.
        if (data.resourcePlanEntry) {
          this.load(true);
          return;
        }

        const updatedTasks = data.tasks ?? data.projectTask;
        if (updatedTasks) {
          this.dataService.updateTasksDates(updatedTasks, this.planningScale);
        }

        if (data.entries) {
          this.updateSavedEntries(data.entries);
        }
      }),
    );

    this.reload();

    this.undoRedoService.setSavingQueue(this.autosave);
  }

  /**
   * Disposes all subscriptions
   */
  public dispose() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /**
   * Toggles group
   *
   * @param id - group id
   */
  public toggleGroup(id: string) {
    this.toggleSubject.next(id);
  }

  /**
   * Shows/hides other actual data
   */
  public toggleShowOtherActual() {
    this.settings.showOtherActual = !this.settings.showOtherActual;
    const settings = this.localConfigService.getConfig(
      ResourceForecastCalendarPlannerSettings,
    );
    settings.showOtherActual = this.settings.showOtherActual;
    this.localConfigService.setConfig(
      ResourceForecastCalendarPlannerSettings,
      settings,
    );
    this.updateSlotTotals();
  }

  /**
   * Determines and returns calendar width
   *
   * @param slotWidth - Not required. If not present, service's getSlotWidth() is used
   * @param countOfSlots - Not required. If not present, service's slots is used
   */
  public getTableWidth(slotWidth?: number, countOfSlots?: number): number {
    if (!slotWidth) {
      slotWidth = this.slotWidth;
    }
    if (!countOfSlots) {
      countOfSlots = this.slots.length;
    }

    return slotWidth * countOfSlots;
  }

  /**
   * Opens "Move Plan" modal
   * */
  public movePlan() {
    this.dataService.save().then(() => {
      this.commandService
        .movePlan(this.projectId, this.dataService.teamMembers as any[])
        .then(
          () => this.reload(),
          () => null,
        );
    });
  }

  /**
   * Updates slot totals
   * */
  public updateSlotTotals() {
    this.dataService.groups.forEach((group) => {
      group.totals.forEach((t) => {
        t.hours = 0;
        t.cost = 0;
      });

      group.lines.forEach((line) => {
        line.totalHours = line.extraTotal;
        for (
          let entryIndex = 0;
          entryIndex < line.entries.length;
          entryIndex++
        ) {
          const entry = line.entries[entryIndex];
          if (entry.hours > 0) {
            group.totals[entryIndex].hours += entry.hours;
            group.totals[entryIndex].cost += entry.cost;
            line.totalHours += entry.hours;
          }
        }
      });

      group.totalHours = sumBy(group.lines, 'totalHours');
      group.totalCost = sumBy(group.lines, 'totalCost');
    });

    let calculatedGroups = this.dataService.groups;
    if (this.showOtherActual) {
      calculatedGroups = [
        ...calculatedGroups,
        this.dataService.otherActualGroup,
      ];
    }
    const totals = flatten(calculatedGroups.map((g) => g.totals));
    this.slotTotals = this.slots.map((s) => {
      const slotTotals = totals.filter(
        (t) => t.slotId === s.id && t.isActual === s.isActual,
      );
      return {
        id: s.id,
        hours: sumBy(slotTotals, 'hours'),
        cost: sumBy(slotTotals, 'cost'),
        nonWorking: false,
        today: s.today,
      };
    });
  }

  /**
   * Clears resource plan
   * */
  public clearPlan() {
    this.dataService.save().then(() => {
      this.commandService.clearPlan();
    });
  }

  /**
   * Loads calendar frame
   *
   * @param direction - direction in which frame is loaded
   * */
  private loadFrame(direction: 'left' | 'right') {
    this.frameLoading$.next(true);
    this.blockUI.start();

    let shift: DurationLike;
    switch (this.settings.planningScale) {
      case PlanningScale.Day:
        shift = { weeks: 2 };
        break;
      case PlanningScale.Week:
        shift = { weeks: 10 };
        break;
      case PlanningScale.Month:
        shift = { month: 5 };
        break;
      case PlanningScale.Quarter:
        shift = { year: 1 };
        break;
      case PlanningScale.Year:
        shift = { year: 2 };
        break;
    }
    let loadingInterval =
      direction === 'left'
        ? Interval.fromDateTimes(
            this.interval.start.minus(shift),
            this.interval.start.minus({ days: 1 }),
          )
        : Interval.fromDateTimes(
            this.interval.end.plus({ days: 1 }),
            this.interval.end.plus(shift),
          );
    this.interval =
      direction === 'left'
        ? this.interval.set({
            start: this.interval.start.minus(shift),
          })
        : this.interval.set({
            end: this.interval.end.plus(shift),
          });
    if (
      this.settings.planningScale === PlanningScale.Month &&
      direction === 'right'
    ) {
      loadingInterval = loadingInterval.set({
        end: loadingInterval.end.endOf('month'),
      });
      this.interval = this.interval.set({
        end: this.interval.end.endOf('month'),
      });
    }
    this.updateDates();

    this.dataService
      .loadFrame(loadingInterval, this.settings.planningScale, this.slots)
      .then(() => {
        this.updateSlotTotals();
        this.frameLoading$.next(false);
        this.blockUI.stop();
        this.changesSubject.next();

        setTimeout(() => {
          this.freezeTableService.disableMutationObserver();
          if (direction === 'left') {
            this.freezeTableService.scrollToLeft();
          } else {
            this.freezeTableService.scrollToRight();
          }

          setTimeout(() => {
            this.freezeTableService.enableMutationObserver();
          }, 500);
        }, 10);
      });
  }

  /**
   * Determines and returns slot width.
   *
   * @returns current slot width.
   */
  private getSlotWidth(): number {
    if (!this.settings?.planningScale) return 0;
    switch (this.settings.planningScale) {
      case PlanningScale.Day:
        return 55;
      case PlanningScale.Week:
        return 75;
      case PlanningScale.Month:
        return 90;
      case PlanningScale.Quarter:
        return 120;
      case PlanningScale.Year:
        return 90;
    }
  }

  /**
   * Reloads whole calendar
   *
   * @param toDate - date to which calendar should transition to
   */
  private reload(toDate?: DateTime) {
    this.settings = this.localConfigService.getConfig(
      ResourceForecastCalendarPlannerSettings,
    );
    this.interval = this.navigationService.getInterval(
      this.settings.planningScale,
      toDate,
    );
    this.updateDates();
    this.load();
  }

  /**
   * Loads data for whole calendar
   *
   * @param silent - determines if UI should be blocked
   */
  private load(silent = false) {
    if (silent) {
      this.blockUI.start();
    } else {
      this.loading$.next(true);
    }

    this.dataService
      .loadResourceGroups(this.interval, this.planningScale, this.slots)
      .then(() => {
        this.updateSlotTotals();
        this.changesSubject.next();
        if (silent) {
          this.blockUI.stop();
        } else {
          this.loading$.next(false);
        }
      });
  }

  /**
   * Updates view dates
   * */
  private updateDates() {
    const slotInfo = this.navigationService.getSlots(
      this.interval,
      this.settings.planningScale,
    );
    this.slotGroups = slotInfo.groups;
    this.slots = slotInfo.slots;

    // NOTE: Начинаем с isActual = true, если есть хоть один слот из прошлого, включая сегодня.
    // В обратном случае все слоты являются прогнозными, isActual начинается с false и не переключается.
    const defaultIsActual = this.slots.some(
      (slot) => slot.date.toISODate() <= DateTime.now().toISODate(),
    );
    this.slots.forEach((slot) => (slot.isActual = defaultIsActual));

    const currentPeriodSlot = this.currentPeriodSlot;
    const currentPeriodSlotIndex = this.slots.indexOf(currentPeriodSlot);
    // Дублируем today слот, если он есть в фрейме
    if (currentPeriodSlot) {
      this.slots = [
        ...this.slots.slice(0, currentPeriodSlotIndex).map((sl) => {
          sl.isActual = true;
          return sl;
        }),
        { ...currentPeriodSlot, isActual: true },
        ...this.slots.slice(currentPeriodSlotIndex).map((sl) => {
          sl.isActual = false;
          return sl;
        }),
      ];
    }

    // Добавляем в группу с продублированным слотом ещё 1 слот,
    // если в фрейме есть today слот
    let groupsSlotsCount = 0;
    let isGroupExtended = false;
    // Итерируемся по группам.
    // Если в группе находится актианый слот,
    // то увеличиваем кол-во слотов в группе
    this.slotGroups.forEach((slotGroup) => {
      if (!isGroupExtended) {
        groupsSlotsCount += slotGroup.slotsCount;
        if (
          currentPeriodSlot &&
          currentPeriodSlotIndex + 1 <= groupsSlotsCount
        ) {
          slotGroup.slotsCount += 1;
          isGroupExtended = true;
        }
      }
    });

    this.rightTableWidth = this.getTableWidth();
  }

  /** Updates saved entries in the calendar.
   *
   * @param entries saved entries.
   */
  private updateSavedEntries(entries: any[]): void {
    entries.forEach((saved) => {
      const group = this.dataService.groups.find(
        (g) => g.id === saved.teamMemberId,
      );
      if (!group) {
        return;
      }

      const taskLine = group.lines.find((l) => l.taskId === saved.taskId);
      const entry = taskLine?.entries.find(
        (e) => e.date === saved.date && !e.isActual,
      );
      if (entry) {
        const prevCost = entry.cost;
        taskLine.totalCost += saved.cost - prevCost;
        entry.cost = saved.cost;
        entry.hours = saved.hours;
      }
    });
    this.updateSlotTotals();
    this.changesSubject.next();
  }

  /**
   * Updates readonly state
   *
   * @param resourcePlanEditAllowed - determines if edit allowed
   * */
  private updateReadOnly(resourcePlanEditAllowed: boolean) {
    this.readonly =
      !resourcePlanEditAllowed ||
      !this.versionCardService.projectVersion.editAllowed;
    this.commandService.readonly = this.readonly;
  }
}
